(function (root, factory) {
  if (typeof define === 'function' && define.amd)
    define(['exports', 'css-typed-om', 'ace-builds', 'ace-builds/webpack-resolver', 'ace-builds', 'ace-builds', 'ace-builds', 'ace-builds', 'ace-builds', 'ace-builds', 'ace-builds'], factory);
  else if (typeof exports === 'object')
    factory(module.exports, require('css-typed-om'), require('ace-builds'), require('ace-builds/webpack-resolver'), require('ace-builds'), require('ace-builds'), require('ace-builds'), require('ace-builds'), require('ace-builds'), require('ace-builds'), require('ace-builds'));
  else {
    if (typeof CSSTypedOMPolyfill === 'undefined') {
      throw new Error("Error loading module 'kchia-chia-web-playground'. Its dependency 'css-typed-om' was not found. Please, check whether 'css-typed-om' is loaded prior to 'kchia-chia-web-playground'.");
    }if (typeof ace === 'undefined') {
      throw new Error("Error loading module 'kchia-chia-web-playground'. Its dependency 'ace-builds' was not found. Please, check whether 'ace-builds' is loaded prior to 'kchia-chia-web-playground'.");
    }if (typeof resolver === 'undefined') {
      throw new Error("Error loading module 'kchia-chia-web-playground'. Its dependency 'ace-builds/webpack-resolver' was not found. Please, check whether 'ace-builds/webpack-resolver' is loaded prior to 'kchia-chia-web-playground'.");
    }if (typeof Ace === 'undefined') {
      throw new Error("Error loading module 'kchia-chia-web-playground'. Its dependency 'ace-builds' was not found. Please, check whether 'ace-builds' is loaded prior to 'kchia-chia-web-playground'.");
    }if (typeof EditorChangeEvent === 'undefined') {
      throw new Error("Error loading module 'kchia-chia-web-playground'. Its dependency 'ace-builds' was not found. Please, check whether 'ace-builds' is loaded prior to 'kchia-chia-web-playground'.");
    }if (typeof OptionProvider === 'undefined') {
      throw new Error("Error loading module 'kchia-chia-web-playground'. Its dependency 'ace-builds' was not found. Please, check whether 'ace-builds' is loaded prior to 'kchia-chia-web-playground'.");
    }if (typeof IEditSession === 'undefined') {
      throw new Error("Error loading module 'kchia-chia-web-playground'. Its dependency 'ace-builds' was not found. Please, check whether 'ace-builds' is loaded prior to 'kchia-chia-web-playground'.");
    }if (typeof Editor === 'undefined') {
      throw new Error("Error loading module 'kchia-chia-web-playground'. Its dependency 'ace-builds' was not found. Please, check whether 'ace-builds' is loaded prior to 'kchia-chia-web-playground'.");
    }if (typeof Tokenizer === 'undefined') {
      throw new Error("Error loading module 'kchia-chia-web-playground'. Its dependency 'ace-builds' was not found. Please, check whether 'ace-builds' is loaded prior to 'kchia-chia-web-playground'.");
    }if (typeof TokenInfo === 'undefined') {
      throw new Error("Error loading module 'kchia-chia-web-playground'. Its dependency 'ace-builds' was not found. Please, check whether 'ace-builds' is loaded prior to 'kchia-chia-web-playground'.");
    }root['kchia-chia-web-playground'] = factory(typeof this['kchia-chia-web-playground'] === 'undefined' ? {} : this['kchia-chia-web-playground'], CSSTypedOMPolyfill, ace, resolver, Ace, EditorChangeEvent, OptionProvider, IEditSession, Editor, Tokenizer, TokenInfo);
  }
}(this, function (_, CSSTypedOMPolyfill, ace, resolver, Ace, EditorChangeEvent, OptionProvider, IEditSession, Editor, Tokenizer, TokenInfo) {
  'use strict';
  State.prototype = Object.create(Enum.prototype);
  State.prototype.constructor = State;
  AbstractList.prototype = Object.create(AbstractCollection.prototype);
  AbstractList.prototype.constructor = AbstractList;
  SubList.prototype = Object.create(AbstractList.prototype);
  SubList.prototype.constructor = SubList;
  _no_name_provided__4.prototype = Object.create(AbstractCollection.prototype);
  _no_name_provided__4.prototype.constructor = _no_name_provided__4;
  AbstractSet.prototype = Object.create(AbstractCollection.prototype);
  AbstractSet.prototype.constructor = AbstractSet;
  SequenceBuilderIterator.prototype = Object.create(SequenceScope.prototype);
  SequenceBuilderIterator.prototype.constructor = SequenceBuilderIterator;
  _no_name_provided__12.prototype = Object.create(AbstractIterator.prototype);
  _no_name_provided__12.prototype.constructor = _no_name_provided__12;
  RingBuffer.prototype = Object.create(AbstractList.prototype);
  RingBuffer.prototype.constructor = RingBuffer;
  _no_name_provided__13.prototype = Object.create(CoroutineImpl_0.prototype);
  _no_name_provided__13.prototype.constructor = _no_name_provided__13;
  CoroutineSingletons.prototype = Object.create(Enum.prototype);
  CoroutineSingletons.prototype.constructor = CoroutineSingletons;
  Error_0.prototype = Object.create(Error.prototype);
  Error_0.prototype.constructor = Error_0;
  NotImplementedError.prototype = Object.create(Error_0.prototype);
  NotImplementedError.prototype.constructor = NotImplementedError;
  Iterator.prototype = Object.create(UByteIterator.prototype);
  Iterator.prototype.constructor = Iterator;
  Iterator_0.prototype = Object.create(UIntIterator.prototype);
  Iterator_0.prototype.constructor = Iterator_0;
  Iterator_1.prototype = Object.create(ULongIterator.prototype);
  Iterator_1.prototype.constructor = Iterator_1;
  IntProgressionIterator.prototype = Object.create(IntIterator.prototype);
  IntProgressionIterator.prototype.constructor = IntProgressionIterator;
  IntRange.prototype = Object.create(IntProgression.prototype);
  IntRange.prototype.constructor = IntRange;
  AbstractMutableCollection.prototype = Object.create(AbstractCollection.prototype);
  AbstractMutableCollection.prototype.constructor = AbstractMutableCollection;
  ListIteratorImpl.prototype = Object.create(IteratorImpl_0.prototype);
  ListIteratorImpl.prototype.constructor = ListIteratorImpl;
  AbstractMutableList.prototype = Object.create(AbstractMutableCollection.prototype);
  AbstractMutableList.prototype.constructor = AbstractMutableList;
  SubList_0.prototype = Object.create(AbstractMutableList.prototype);
  SubList_0.prototype.constructor = SubList_0;
  AbstractMutableSet.prototype = Object.create(AbstractMutableCollection.prototype);
  AbstractMutableSet.prototype.constructor = AbstractMutableSet;
  AbstractEntrySet.prototype = Object.create(AbstractMutableSet.prototype);
  AbstractEntrySet.prototype.constructor = AbstractEntrySet;
  _no_name_provided__28.prototype = Object.create(AbstractMutableSet.prototype);
  _no_name_provided__28.prototype.constructor = _no_name_provided__28;
  _no_name_provided__29.prototype = Object.create(AbstractMutableCollection.prototype);
  _no_name_provided__29.prototype.constructor = _no_name_provided__29;
  AbstractMutableMap.prototype = Object.create(AbstractMap.prototype);
  AbstractMutableMap.prototype.constructor = AbstractMutableMap;
  ArrayList.prototype = Object.create(AbstractMutableList.prototype);
  ArrayList.prototype.constructor = ArrayList;
  EntrySet.prototype = Object.create(AbstractEntrySet.prototype);
  EntrySet.prototype.constructor = EntrySet;
  HashMap.prototype = Object.create(AbstractMutableMap.prototype);
  HashMap.prototype.constructor = HashMap;
  HashSet.prototype = Object.create(AbstractMutableSet.prototype);
  HashSet.prototype.constructor = HashSet;
  ChainEntry.prototype = Object.create(SimpleEntry.prototype);
  ChainEntry.prototype.constructor = ChainEntry;
  EntrySet_0.prototype = Object.create(AbstractEntrySet.prototype);
  EntrySet_0.prototype.constructor = EntrySet_0;
  LinkedHashMap.prototype = Object.create(HashMap.prototype);
  LinkedHashMap.prototype.constructor = LinkedHashMap;
  LinkedHashSet.prototype = Object.create(HashSet.prototype);
  LinkedHashSet.prototype.constructor = LinkedHashSet;
  NodeJsOutput_0.prototype = Object.create(BaseOutput.prototype);
  NodeJsOutput_0.prototype.constructor = NodeJsOutput_0;
  BufferedOutput_0.prototype = Object.create(BaseOutput.prototype);
  BufferedOutput_0.prototype.constructor = BufferedOutput_0;
  BufferedOutputToConsoleLog_0.prototype = Object.create(BufferedOutput_0.prototype);
  BufferedOutputToConsoleLog_0.prototype.constructor = BufferedOutputToConsoleLog_0;
  PrimitiveKClassImpl.prototype = Object.create(KClassImpl.prototype);
  PrimitiveKClassImpl.prototype.constructor = PrimitiveKClassImpl;
  NothingKClassImpl.prototype = Object.create(KClassImpl.prototype);
  NothingKClassImpl.prototype.constructor = NothingKClassImpl;
  SimpleKClassImpl.prototype = Object.create(KClassImpl.prototype);
  SimpleKClassImpl.prototype.constructor = SimpleKClassImpl;
  Exception.prototype = Object.create(Error.prototype);
  Exception.prototype.constructor = Exception;
  CharacterCodingException.prototype = Object.create(Exception.prototype);
  CharacterCodingException.prototype.constructor = CharacterCodingException;
  DurationUnit.prototype = Object.create(Enum.prototype);
  DurationUnit.prototype.constructor = DurationUnit;
  _no_name_provided__54.prototype = Object.create(IntIterator.prototype);
  _no_name_provided__54.prototype.constructor = _no_name_provided__54;
  Long.prototype = Object.create(Number_0.prototype);
  Long.prototype.constructor = Long;
  _no_name_provided__56.prototype = Object.create(AbstractList.prototype);
  _no_name_provided__56.prototype.constructor = _no_name_provided__56;
  _no_name_provided__57.prototype = Object.create(AbstractList.prototype);
  _no_name_provided__57.prototype.constructor = _no_name_provided__57;
  _no_name_provided__1_3.prototype = Object.create(CoroutineImpl_0.prototype);
  _no_name_provided__1_3.prototype.constructor = _no_name_provided__1_3;
  RuntimeException.prototype = Object.create(Exception.prototype);
  RuntimeException.prototype.constructor = RuntimeException;
  IllegalArgumentException.prototype = Object.create(RuntimeException.prototype);
  IllegalArgumentException.prototype.constructor = IllegalArgumentException;
  NoSuchElementException.prototype = Object.create(RuntimeException.prototype);
  NoSuchElementException.prototype.constructor = NoSuchElementException;
  IllegalStateException.prototype = Object.create(RuntimeException.prototype);
  IllegalStateException.prototype.constructor = IllegalStateException;
  IndexOutOfBoundsException.prototype = Object.create(RuntimeException.prototype);
  IndexOutOfBoundsException.prototype.constructor = IndexOutOfBoundsException;
  NumberFormatException.prototype = Object.create(IllegalArgumentException.prototype);
  NumberFormatException.prototype.constructor = NumberFormatException;
  ArithmeticException.prototype = Object.create(RuntimeException.prototype);
  ArithmeticException.prototype.constructor = ArithmeticException;
  UnsupportedOperationException.prototype = Object.create(RuntimeException.prototype);
  UnsupportedOperationException.prototype.constructor = UnsupportedOperationException;
  ConcurrentModificationException.prototype = Object.create(RuntimeException.prototype);
  ConcurrentModificationException.prototype.constructor = ConcurrentModificationException;
  AssertionError.prototype = Object.create(Error_0.prototype);
  AssertionError.prototype.constructor = AssertionError;
  NullPointerException.prototype = Object.create(RuntimeException.prototype);
  NullPointerException.prototype.constructor = NullPointerException;
  NoWhenBranchMatchedException.prototype = Object.create(RuntimeException.prototype);
  NoWhenBranchMatchedException.prototype.constructor = NoWhenBranchMatchedException;
  ClassCastException.prototype = Object.create(RuntimeException.prototype);
  ClassCastException.prototype.constructor = ClassCastException;
  UninitializedPropertyAccessException.prototype = Object.create(RuntimeException.prototype);
  UninitializedPropertyAccessException.prototype.constructor = UninitializedPropertyAccessException;
  SignificantDecider.prototype = Object.create(Enum.prototype);
  SignificantDecider.prototype.constructor = SignificantDecider;
  ScaleOps.prototype = Object.create(Enum.prototype);
  ScaleOps.prototype.constructor = ScaleOps;
  RoundingMode.prototype = Object.create(Enum.prototype);
  RoundingMode.prototype.constructor = RoundingMode;
  Sign.prototype = Object.create(Enum.prototype);
  Sign.prototype.constructor = Sign;
  None.prototype = Object.create(TraceBase.prototype);
  None.prototype.constructor = None;
  AbstractCoroutine.prototype = Object.create(JobSupport.prototype);
  AbstractCoroutine.prototype.constructor = AbstractCoroutine;
  StandaloneCoroutine.prototype = Object.create(AbstractCoroutine.prototype);
  StandaloneCoroutine.prototype.constructor = StandaloneCoroutine;
  LazyStandaloneCoroutine.prototype = Object.create(StandaloneCoroutine.prototype);
  LazyStandaloneCoroutine.prototype.constructor = LazyStandaloneCoroutine;
  ScopeCoroutine.prototype = Object.create(AbstractCoroutine.prototype);
  ScopeCoroutine.prototype.constructor = ScopeCoroutine;
  DispatchedCoroutine.prototype = Object.create(ScopeCoroutine.prototype);
  DispatchedCoroutine.prototype.constructor = DispatchedCoroutine;
  $awaitCOROUTINE$0.prototype = Object.create(CoroutineImpl_0.prototype);
  $awaitCOROUTINE$0.prototype.constructor = $awaitCOROUTINE$0;
  DeferredCoroutine.prototype = Object.create(AbstractCoroutine.prototype);
  DeferredCoroutine.prototype.constructor = DeferredCoroutine;
  LazyDeferredCoroutine.prototype = Object.create(DeferredCoroutine.prototype);
  LazyDeferredCoroutine.prototype.constructor = LazyDeferredCoroutine;
  CancelHandler.prototype = Object.create(CancelHandlerBase.prototype);
  CancelHandler.prototype.constructor = CancelHandler;
  DisposeOnCancel.prototype = Object.create(CancelHandler.prototype);
  DisposeOnCancel.prototype.constructor = DisposeOnCancel;
  DispatchedTask.prototype = Object.create(SchedulerTask.prototype);
  DispatchedTask.prototype.constructor = DispatchedTask;
  CancellableContinuationImpl.prototype = Object.create(DispatchedTask.prototype);
  CancellableContinuationImpl.prototype.constructor = CancellableContinuationImpl;
  BeforeResumeCancelHandler.prototype = Object.create(CancelHandler.prototype);
  BeforeResumeCancelHandler.prototype.constructor = BeforeResumeCancelHandler;
  InvokeOnCancel.prototype = Object.create(CancelHandler.prototype);
  InvokeOnCancel.prototype.constructor = InvokeOnCancel;
  CancelledContinuation.prototype = Object.create(CompletedExceptionally.prototype);
  CancelledContinuation.prototype.constructor = CancelledContinuation;
  Key_1.prototype = Object.create(AbstractCoroutineContextKey.prototype);
  Key_1.prototype.constructor = Key_1;
  CoroutineDispatcher.prototype = Object.create(AbstractCoroutineContextElement.prototype);
  CoroutineDispatcher.prototype.constructor = CoroutineDispatcher;
  CoroutineStart.prototype = Object.create(Enum.prototype);
  CoroutineStart.prototype.constructor = CoroutineStart;
  EventLoop.prototype = Object.create(CoroutineDispatcher.prototype);
  EventLoop.prototype.constructor = EventLoop;
  CompletionHandlerException.prototype = Object.create(RuntimeException.prototype);
  CompletionHandlerException.prototype.constructor = CompletionHandlerException;
  CoroutinesInternalError.prototype = Object.create(Error_0.prototype);
  CoroutinesInternalError.prototype.constructor = CoroutinesInternalError;
  LinkedListHead.prototype = Object.create(LinkedListNode.prototype);
  LinkedListHead.prototype.constructor = LinkedListHead;
  NodeList_0.prototype = Object.create(LinkedListHead.prototype);
  NodeList_0.prototype.constructor = NodeList_0;
  CompletionHandlerBase.prototype = Object.create(LinkedListNode.prototype);
  CompletionHandlerBase.prototype.constructor = CompletionHandlerBase;
  JobNode.prototype = Object.create(CompletionHandlerBase.prototype);
  JobNode.prototype.constructor = JobNode;
  ChildCompletion.prototype = Object.create(JobNode.prototype);
  ChildCompletion.prototype.constructor = ChildCompletion;
  AwaitContinuation.prototype = Object.create(CancellableContinuationImpl.prototype);
  AwaitContinuation.prototype.constructor = AwaitContinuation;
  _no_name_provided__63.prototype = Object.create(CoroutineImpl_0.prototype);
  _no_name_provided__63.prototype.constructor = _no_name_provided__63;
  JobCancellingNode.prototype = Object.create(JobNode.prototype);
  JobCancellingNode.prototype.constructor = JobCancellingNode;
  ChildHandleNode.prototype = Object.create(JobCancellingNode.prototype);
  ChildHandleNode.prototype.constructor = ChildHandleNode;
  InvokeOnCancelling.prototype = Object.create(JobCancellingNode.prototype);
  InvokeOnCancelling.prototype.constructor = InvokeOnCancelling;
  InvokeOnCompletion.prototype = Object.create(JobNode.prototype);
  InvokeOnCompletion.prototype.constructor = InvokeOnCompletion;
  ResumeAwaitOnCompletion.prototype = Object.create(JobNode.prototype);
  ResumeAwaitOnCompletion.prototype.constructor = ResumeAwaitOnCompletion;
  ChildContinuation.prototype = Object.create(JobCancellingNode.prototype);
  ChildContinuation.prototype.constructor = ChildContinuation;
  JobImpl.prototype = Object.create(JobSupport.prototype);
  JobImpl.prototype.constructor = JobImpl;
  MainCoroutineDispatcher.prototype = Object.create(CoroutineDispatcher.prototype);
  MainCoroutineDispatcher.prototype.constructor = MainCoroutineDispatcher;
  SupervisorJobImpl.prototype = Object.create(JobImpl.prototype);
  SupervisorJobImpl.prototype.constructor = SupervisorJobImpl;
  CancellationException.prototype = Object.create(IllegalStateException.prototype);
  CancellationException.prototype.constructor = CancellationException;
  TimeoutCancellationException.prototype = Object.create(CancellationException.prototype);
  TimeoutCancellationException.prototype.constructor = TimeoutCancellationException;
  Unconfined.prototype = Object.create(CoroutineDispatcher.prototype);
  Unconfined.prototype.constructor = Unconfined;
  StateFlowImpl.prototype = Object.create(AbstractSharedFlow.prototype);
  StateFlowImpl.prototype.constructor = StateFlowImpl;
  DispatchedContinuation.prototype = Object.create(DispatchedTask.prototype);
  DispatchedContinuation.prototype.constructor = DispatchedContinuation;
  UndispatchedCoroutine.prototype = Object.create(ScopeCoroutine.prototype);
  UndispatchedCoroutine.prototype.constructor = UndispatchedCoroutine;
  JsMainDispatcher.prototype = Object.create(MainCoroutineDispatcher.prototype);
  JsMainDispatcher.prototype.constructor = JsMainDispatcher;
  UnconfinedEventLoop.prototype = Object.create(EventLoop.prototype);
  UnconfinedEventLoop.prototype.constructor = UnconfinedEventLoop;
  JobCancellationException.prototype = Object.create(CancellationException.prototype);
  JobCancellationException.prototype.constructor = JobCancellationException;
  SetTimeoutBasedDispatcher.prototype = Object.create(CoroutineDispatcher.prototype);
  SetTimeoutBasedDispatcher.prototype.constructor = SetTimeoutBasedDispatcher;
  NodeDispatcher.prototype = Object.create(SetTimeoutBasedDispatcher.prototype);
  NodeDispatcher.prototype.constructor = NodeDispatcher;
  SetTimeoutDispatcher.prototype = Object.create(SetTimeoutBasedDispatcher.prototype);
  SetTimeoutDispatcher.prototype.constructor = SetTimeoutDispatcher;
  MessageQueue.prototype = Object.create(ArrayQueue.prototype);
  MessageQueue.prototype.constructor = MessageQueue;
  ScheduledMessageQueue.prototype = Object.create(MessageQueue.prototype);
  ScheduledMessageQueue.prototype.constructor = ScheduledMessageQueue;
  WindowDispatcher.prototype = Object.create(CoroutineDispatcher.prototype);
  WindowDispatcher.prototype.constructor = WindowDispatcher;
  WindowMessageQueue.prototype = Object.create(MessageQueue.prototype);
  WindowMessageQueue.prototype.constructor = WindowMessageQueue;
  Fq2.prototype = Object.create(FieldExt.prototype);
  Fq2.prototype.constructor = Fq2;
  Fq6.prototype = Object.create(FieldExt.prototype);
  Fq6.prototype.constructor = Fq6;
  Fq12.prototype = Object.create(FieldExt.prototype);
  Fq12.prototype.constructor = Fq12;
  G1Element.prototype = Object.create(JacobianPoint.prototype);
  G1Element.prototype.constructor = G1Element;
  _no_name_provided__87.prototype = Object.create(Evaluator.prototype);
  _no_name_provided__87.prototype.constructor = _no_name_provided__87;
  _no_name_provided__93.prototype = Object.create(Evaluator.prototype);
  _no_name_provided__93.prototype.constructor = _no_name_provided__93;
  ClvmObjectType.prototype = Object.create(Enum.prototype);
  ClvmObjectType.prototype.constructor = ClvmObjectType;
  _no_name_provided__94.prototype = Object.create(CoroutineImpl_0.prototype);
  _no_name_provided__94.prototype.constructor = _no_name_provided__94;
  _no_name_provided__100.prototype = Object.create(CoroutineImpl_0.prototype);
  _no_name_provided__100.prototype.constructor = _no_name_provided__100;
  _no_name_provided__101.prototype = Object.create(CoroutineImpl_0.prototype);
  _no_name_provided__101.prototype.constructor = _no_name_provided__101;
  TypeAtom.prototype = Object.create(Enum.prototype);
  TypeAtom.prototype.constructor = TypeAtom;
  EvalError.prototype = Object.create(Exception.prototype);
  EvalError.prototype.constructor = EvalError;
  InvalidationResult.prototype = Object.create(Enum.prototype);
  InvalidationResult.prototype.constructor = InvalidationResult;
  ProvidableCompositionLocal.prototype = Object.create(CompositionLocal.prototype);
  ProvidableCompositionLocal.prototype.constructor = ProvidableCompositionLocal;
  StaticProvidableCompositionLocal.prototype = Object.create(ProvidableCompositionLocal.prototype);
  StaticProvidableCompositionLocal.prototype.constructor = StaticProvidableCompositionLocal;
  _no_name_provided__191.prototype = Object.create(CoroutineImpl_0.prototype);
  _no_name_provided__191.prototype.constructor = _no_name_provided__191;
  State_0.prototype = Object.create(Enum.prototype);
  State_0.prototype.constructor = State_0;
  _no_name_provided__194.prototype = Object.create(CoroutineImpl_0.prototype);
  _no_name_provided__194.prototype.constructor = _no_name_provided__194;
  _no_name_provided__195.prototype = Object.create(CoroutineImpl_0.prototype);
  _no_name_provided__195.prototype.constructor = _no_name_provided__195;
  $awaitWorkAvailableCOROUTINE$1.prototype = Object.create(CoroutineImpl_0.prototype);
  $awaitWorkAvailableCOROUTINE$1.prototype.constructor = $awaitWorkAvailableCOROUTINE$1;
  Recomposer.prototype = Object.create(CompositionContext.prototype);
  Recomposer.prototype.constructor = Recomposer;
  StateStateRecord.prototype = Object.create(StateRecord.prototype);
  StateStateRecord.prototype.constructor = StateStateRecord;
  PersistentHashMap.prototype = Object.create(AbstractMap.prototype);
  PersistentHashMap.prototype.constructor = PersistentHashMap;
  PersistentHashMapValuesIterator.prototype = Object.create(PersistentHashMapBaseIterator.prototype);
  PersistentHashMapValuesIterator.prototype.constructor = PersistentHashMapValuesIterator;
  PersistentHashMapEntriesIterator.prototype = Object.create(PersistentHashMapBaseIterator.prototype);
  PersistentHashMapEntriesIterator.prototype.constructor = PersistentHashMapEntriesIterator;
  TrieNodeValuesIterator.prototype = Object.create(TrieNodeBaseIterator.prototype);
  TrieNodeValuesIterator.prototype.constructor = TrieNodeValuesIterator;
  TrieNodeEntriesIterator.prototype = Object.create(TrieNodeBaseIterator.prototype);
  TrieNodeEntriesIterator.prototype.constructor = TrieNodeEntriesIterator;
  PersistentHashMapValues.prototype = Object.create(AbstractCollection.prototype);
  PersistentHashMapValues.prototype.constructor = PersistentHashMapValues;
  PersistentHashMapEntries.prototype = Object.create(AbstractSet.prototype);
  PersistentHashMapEntries.prototype.constructor = PersistentHashMapEntries;
  PersistentOrderedSet.prototype = Object.create(AbstractSet.prototype);
  PersistentOrderedSet.prototype.constructor = PersistentOrderedSet;
  MutableSnapshot.prototype = Object.create(Snapshot.prototype);
  MutableSnapshot.prototype.constructor = MutableSnapshot;
  Success.prototype = Object.create(SnapshotApplyResult.prototype);
  Success.prototype.constructor = Success;
  Failure_0.prototype = Object.create(SnapshotApplyResult.prototype);
  Failure_0.prototype.constructor = Failure_0;
  GlobalSnapshot.prototype = Object.create(MutableSnapshot.prototype);
  GlobalSnapshot.prototype.constructor = GlobalSnapshot;
  TransparentObserverMutableSnapshot.prototype = Object.create(MutableSnapshot.prototype);
  TransparentObserverMutableSnapshot.prototype.constructor = TransparentObserverMutableSnapshot;
  NestedMutableSnapshot.prototype = Object.create(MutableSnapshot.prototype);
  NestedMutableSnapshot.prototype.constructor = NestedMutableSnapshot;
  _no_name_provided__211.prototype = Object.create(CoroutineImpl_0.prototype);
  _no_name_provided__211.prototype.constructor = _no_name_provided__211;
  $withFrameNanosCOROUTINE$4.prototype = Object.create(CoroutineImpl_0.prototype);
  $withFrameNanosCOROUTINE$4.prototype.constructor = $withFrameNanosCOROUTINE$4;
  DomApplier.prototype = Object.create(AbstractApplier.prototype);
  DomApplier.prototype.constructor = DomApplier;
  DomElementWrapper.prototype = Object.create(DomNodeWrapper.prototype);
  DomElementWrapper.prototype.constructor = DomElementWrapper;
  _no_name_provided__218.prototype = Object.create(CoroutineImpl_0.prototype);
  _no_name_provided__218.prototype.constructor = _no_name_provided__218;
  JsMicrotasksDispatcher.prototype = Object.create(CoroutineDispatcher.prototype);
  JsMicrotasksDispatcher.prototype.constructor = JsMicrotasksDispatcher;
  _no_name_provided__223.prototype = Object.create(CoroutineImpl_0.prototype);
  _no_name_provided__223.prototype.constructor = _no_name_provided__223;
  AttrsBuilder.prototype = Object.create(EventsListenerBuilder.prototype);
  AttrsBuilder.prototype.constructor = AttrsBuilder;
  Blank.prototype = Object.create(ATarget.prototype);
  Blank.prototype.constructor = Blank;
  MouseEventListener.prototype = Object.create(WrappedEventListener.prototype);
  MouseEventListener.prototype.constructor = MouseEventListener;
  CSSRuleBuilderImpl.prototype = Object.create(StyleBuilderImpl.prototype);
  CSSRuleBuilderImpl.prototype.constructor = CSSRuleBuilderImpl;
  CSSBuilderImpl.prototype = Object.create(CSSRuleBuilderImpl.prototype);
  CSSBuilderImpl.prototype.constructor = CSSBuilderImpl;
  CSSGroupingRuleDeclaration.prototype = Object.create(CSSRuleDeclaration.prototype);
  CSSGroupingRuleDeclaration.prototype.constructor = CSSGroupingRuleDeclaration;
  CSSMediaRuleDeclaration.prototype = Object.create(CSSGroupingRuleDeclaration.prototype);
  CSSMediaRuleDeclaration.prototype.constructor = CSSMediaRuleDeclaration;
  Position.prototype = Object.create(Enum.prototype);
  Position.prototype.constructor = Position;
  DisplayStyle.prototype = Object.create(Enum.prototype);
  DisplayStyle.prototype.constructor = DisplayStyle;
  FlexDirection.prototype = Object.create(Enum.prototype);
  FlexDirection.prototype.constructor = FlexDirection;
  JustifyContent.prototype = Object.create(Enum.prototype);
  JustifyContent.prototype.constructor = JustifyContent;
  FlexWrap.prototype = Object.create(Enum.prototype);
  FlexWrap.prototype.constructor = FlexWrap;
  AlignItems.prototype = Object.create(Enum.prototype);
  AlignItems.prototype.constructor = AlignItems;
  CSSStyleRuleDeclaration.prototype = Object.create(CSSRuleDeclaration.prototype);
  CSSStyleRuleDeclaration.prototype.constructor = CSSStyleRuleDeclaration;
  Named.prototype = Object.create(Color.prototype);
  Named.prototype.constructor = Named;
  RGBA.prototype = Object.create(Color.prototype);
  RGBA.prototype.constructor = RGBA;
  Operator.prototype = Object.create(Enum.prototype);
  Operator.prototype.constructor = Operator;
  Raw.prototype = Object.create(CSSSelector.prototype);
  Raw.prototype.constructor = Raw;
  Universal.prototype = Object.create(CSSSelector.prototype);
  Universal.prototype.constructor = Universal;
  CSSClass.prototype = Object.create(CSSSelector.prototype);
  CSSClass.prototype.constructor = CSSClass;
  Attribute.prototype = Object.create(CSSSelector.prototype);
  Attribute.prototype.constructor = Attribute;
  Combine.prototype = Object.create(CSSSelector.prototype);
  Combine.prototype.constructor = Combine;
  PseudoClass.prototype = Object.create(CSSSelector.prototype);
  PseudoClass.prototype.constructor = PseudoClass;
  ElementScopeImpl.prototype = Object.create(ElementScopeBase.prototype);
  ElementScopeImpl.prototype.constructor = ElementScopeImpl;
  CodeRunState.prototype = Object.create(Enum.prototype);
  CodeRunState.prototype.constructor = CodeRunState;
  _no_name_provided__469.prototype = Object.create(CoroutineImpl_0.prototype);
  _no_name_provided__469.prototype.constructor = _no_name_provided__469;
  _no_name_provided__470.prototype = Object.create(CoroutineImpl_0.prototype);
  _no_name_provided__470.prototype.constructor = _no_name_provided__470;
  AppStylesheet.prototype = Object.create(StyleSheet_0.prototype);
  AppStylesheet.prototype.constructor = AppStylesheet;
  WtCols.prototype = Object.create(StyleSheet_0.prototype);
  WtCols.prototype.constructor = WtCols;
  WtContainer.prototype = Object.create(StyleSheet_0.prototype);
  WtContainer.prototype.constructor = WtContainer;
  WtOffsets.prototype = Object.create(StyleSheet_0.prototype);
  WtOffsets.prototype.constructor = WtOffsets;
  WtRows.prototype = Object.create(StyleSheet_0.prototype);
  WtRows.prototype.constructor = WtRows;
  WtSections.prototype = Object.create(StyleSheet_0.prototype);
  WtSections.prototype.constructor = WtSections;
  WtTexts.prototype = Object.create(StyleSheet_0.prototype);
  WtTexts.prototype.constructor = WtTexts;
  function toList(_this_) {
    var tmp0_subject = _this_.length;
    switch (tmp0_subject) {
      case 0:
        return emptyList();
      case 1:
        return listOf_0(_this_[0]);
      default:return toMutableList_0(_this_);
    }
  }
  function take(_this_, n) {
    var tmp0_require_0 = n >= 0;
    if (!tmp0_require_0) {
      var message_2 = '' + 'Requested element count ' + n + ' is less than zero.';
      throw IllegalArgumentException_init_$Create$_0(toString_2(message_2));
    }if (n === 0)
      return emptyList();
    if (n >= _this_.length)
      return toList(_this_);
    if (n === 1)
      return listOf_0(_this_[0]);
    var count_0 = 0;
    var list = ArrayList_init_$Create$_0(n);
    var indexedObject = _this_;
    var inductionVariable = 0;
    var last_2 = indexedObject.length;
    $l$break: while (inductionVariable < last_2) {
      var item = indexedObject[inductionVariable];
      inductionVariable = inductionVariable + 1 | 0;
      list.add_39(item);
      Unit_getInstance();
      count_0 = count_0 + 1 | 0;
      if (count_0 === n)
        break $l$break;
      else {
      }
    }
    return list;
  }
  function drop(_this_, n) {
    var tmp0_require_0 = n >= 0;
    if (!tmp0_require_0) {
      var message_2 = '' + 'Requested element count ' + n + ' is less than zero.';
      throw IllegalArgumentException_init_$Create$_0(toString_2(message_2));
    }return takeLast(_this_, coerceAtLeast(_this_.length - n | 0, 0));
  }
  function joinToString(_this_, separator, prefix, postfix, limit, truncated, transform) {
    return joinTo(_this_, StringBuilder_init_$Create$_1(), separator, prefix, postfix, limit, truncated, transform).toString();
  }
  function joinToString$default(_this_, separator, prefix, postfix, limit, truncated, transform, $mask0, $handler) {
    if (!(($mask0 & 1) === 0))
      separator = ', ';
    if (!(($mask0 & 2) === 0))
      prefix = '';
    if (!(($mask0 & 4) === 0))
      postfix = '';
    if (!(($mask0 & 8) === 0))
      limit = -1;
    if (!(($mask0 & 16) === 0))
      truncated = '...';
    if (!(($mask0 & 32) === 0))
      transform = null;
    return joinToString(_this_, separator, prefix, postfix, limit, truncated, transform);
  }
  function indexOf(_this_, element) {
    if (element == null) {
      var inductionVariable = 0;
      var last_2 = _this_.length - 1 | 0;
      if (inductionVariable <= last_2)
        do {
          var index = inductionVariable;
          inductionVariable = inductionVariable + 1 | 0;
          if (_this_[index] == null) {
            return index;
          }}
         while (inductionVariable <= last_2);
    } else {
      var inductionVariable_0 = 0;
      var last_3 = _this_.length - 1 | 0;
      if (inductionVariable_0 <= last_3)
        do {
          var index_0 = inductionVariable_0;
          inductionVariable_0 = inductionVariable_0 + 1 | 0;
          if (equals_0(element, _this_[index_0])) {
            return index_0;
          }}
         while (inductionVariable_0 <= last_3);
    }
    return -1;
  }
  function contains(_this_, element) {
    return indexOf_0(_this_, element) >= 0;
  }
  function reversedArray(_this_) {
    if (_this_.length === 0)
      return _this_;
    else {
    }
    var result = longArray(_this_.length);
    var lastIndex = _get_lastIndex_(_this_);
    var inductionVariable = 0;
    if (inductionVariable <= lastIndex)
      do {
        var i = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        result[lastIndex - i | 0] = _this_[i];
      }
       while (!(i === lastIndex));
    return result;
  }
  function last(_this_) {
    if (_this_.length === 0)
      throw NoSuchElementException_init_$Create$_0('Array is empty.');
    else {
    }
    return _this_[_get_lastIndex__1(_this_)];
  }
  function first(_this_) {
    if (_this_.length === 0)
      throw NoSuchElementException_init_$Create$_0('Array is empty.');
    else {
    }
    return _this_[0];
  }
  function _get_lastIndex_(_this_) {
    return _this_.length - 1 | 0;
  }
  function contains_0(_this_, element) {
    return indexOf_1(_this_, element) >= 0;
  }
  function contains_1(_this_, element) {
    return indexOf_2(_this_, element) >= 0;
  }
  function single(_this_) {
    var tmp0_subject = _this_.length;
    var tmp;
    switch (tmp0_subject) {
      case 0:
        throw NoSuchElementException_init_$Create$_0('Array is empty.');
      case 1:
        tmp = _this_[0];
        break;
      default:throw IllegalArgumentException_init_$Create$_0('Array has more than one element.');
    }
    return tmp;
  }
  function contains_2(_this_, element) {
    return indexOf(_this_, element) >= 0;
  }
  function toMutableList(_this_) {
    return ArrayList_init_$Create$_1(asCollection(_this_));
  }
  function toMutableList_0(_this_) {
    var list = ArrayList_init_$Create$_0(_this_.length);
    var indexedObject = _this_;
    var inductionVariable = 0;
    var last_2 = indexedObject.length;
    while (inductionVariable < last_2) {
      var item = indexedObject[inductionVariable];
      inductionVariable = inductionVariable + 1 | 0;
      list.add_39(item);
      Unit_getInstance();
    }
    return list;
  }
  function _get_lastIndex__0(_this_) {
    return _this_.length - 1 | 0;
  }
  function takeLast(_this_, n) {
    var tmp0_require_0 = n >= 0;
    if (!tmp0_require_0) {
      var message_2 = '' + 'Requested element count ' + n + ' is less than zero.';
      throw IllegalArgumentException_init_$Create$_0(toString_2(message_2));
    }if (n === 0)
      return emptyList();
    var size_0 = _this_.length;
    if (n >= size_0)
      return toList(_this_);
    if (n === 1)
      return listOf_0(_this_[size_0 - 1 | 0]);
    var list = ArrayList_init_$Create$_0(n);
    var inductionVariable = size_0 - n | 0;
    if (inductionVariable < size_0)
      do {
        var index = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        list.add_39(_this_[index]);
        Unit_getInstance();
      }
       while (inductionVariable < size_0);
    return list;
  }
  function joinTo(_this_, buffer, separator, prefix, postfix, limit, truncated, transform) {
    buffer.append_3(prefix);
    Unit_getInstance();
    var count_0 = 0;
    var indexedObject = _this_;
    var inductionVariable = 0;
    var last_2 = indexedObject.length;
    $l$break: while (inductionVariable < last_2) {
      var element = indexedObject[inductionVariable];
      inductionVariable = inductionVariable + 1 | 0;
      count_0 = count_0 + 1 | 0;
      if (count_0 > 1) {
        buffer.append_3(separator);
        Unit_getInstance();
      } else {
      }
      if (limit < 0 ? true : count_0 <= limit) {
        if (!(transform == null)) {
          buffer.append_3(transform(element));
          Unit_getInstance();
        } else {
          buffer.append_3(element.toString());
          Unit_getInstance();
        }
      } else
        break $l$break;
    }
    if (limit >= 0 ? count_0 > limit : false) {
      buffer.append_3(truncated);
      Unit_getInstance();
    }buffer.append_3(postfix);
    Unit_getInstance();
    return buffer;
  }
  function indexOf_0(_this_, element) {
    var inductionVariable = 0;
    var last_2 = _this_.length - 1 | 0;
    if (inductionVariable <= last_2)
      do {
        var index = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        if (element.equals(_this_[index])) {
          return index;
        }}
       while (inductionVariable <= last_2);
    return -1;
  }
  function _get_lastIndex__1(_this_) {
    return _this_.length - 1 | 0;
  }
  function indexOf_1(_this_, element) {
    var inductionVariable = 0;
    var last_2 = _this_.length - 1 | 0;
    if (inductionVariable <= last_2)
      do {
        var index = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        if (element === _this_[index]) {
          return index;
        }}
       while (inductionVariable <= last_2);
    return -1;
  }
  function indexOf_2(_this_, element) {
    var inductionVariable = 0;
    var last_2 = _this_.length - 1 | 0;
    if (inductionVariable <= last_2)
      do {
        var index = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        if (element === _this_[index]) {
          return index;
        }}
       while (inductionVariable <= last_2);
    return -1;
  }
  function lastIndexOf(_this_, element) {
    if (element == null) {
      var inductionVariable = _this_.length - 1 | 0;
      if (0 <= inductionVariable)
        do {
          var index = inductionVariable;
          inductionVariable = inductionVariable + -1 | 0;
          if (_this_[index] == null) {
            return index;
          }}
         while (0 <= inductionVariable);
    } else {
      var inductionVariable_0 = _this_.length - 1 | 0;
      if (0 <= inductionVariable_0)
        do {
          var index_0 = inductionVariable_0;
          inductionVariable_0 = inductionVariable_0 + -1 | 0;
          if (equals_0(element, _this_[index_0])) {
            return index_0;
          }}
         while (0 <= inductionVariable_0);
    }
    return -1;
  }
  function toCollection(_this_, destination) {
    var indexedObject = _this_;
    var inductionVariable = 0;
    var last_2 = indexedObject.length;
    while (inductionVariable < last_2) {
      var item = indexedObject[inductionVariable];
      inductionVariable = inductionVariable + 1 | 0;
      destination.add_39(item);
      Unit_getInstance();
    }
    return destination;
  }
  function asSequence(_this_) {
    if (_this_.length === 0)
      return emptySequence();
    else {
    }
    return new _no_name_provided__1(_this_);
  }
  function joinToString_0(_this_, separator, prefix, postfix, limit, truncated, transform) {
    return joinTo_0(_this_, StringBuilder_init_$Create$_1(), separator, prefix, postfix, limit, truncated, transform).toString();
  }
  function joinToString$default_0(_this_, separator, prefix, postfix, limit, truncated, transform, $mask0, $handler) {
    if (!(($mask0 & 1) === 0))
      separator = ', ';
    if (!(($mask0 & 2) === 0))
      prefix = '';
    if (!(($mask0 & 4) === 0))
      postfix = '';
    if (!(($mask0 & 8) === 0))
      limit = -1;
    if (!(($mask0 & 16) === 0))
      truncated = '...';
    if (!(($mask0 & 32) === 0))
      transform = null;
    return joinToString_0(_this_, separator, prefix, postfix, limit, truncated, transform);
  }
  function joinTo_0(_this_, buffer, separator, prefix, postfix, limit, truncated, transform) {
    buffer.append_3(prefix);
    Unit_getInstance();
    var count_0 = 0;
    var indexedObject = _this_;
    var inductionVariable = 0;
    var last_2 = indexedObject.length;
    $l$break: while (inductionVariable < last_2) {
      var element = indexedObject[inductionVariable];
      inductionVariable = inductionVariable + 1 | 0;
      count_0 = count_0 + 1 | 0;
      if (count_0 > 1) {
        buffer.append_3(separator);
        Unit_getInstance();
      } else {
      }
      if (limit < 0 ? true : count_0 <= limit) {
        appendElement(buffer, element, transform);
      } else
        break $l$break;
    }
    if (limit >= 0 ? count_0 > limit : false) {
      buffer.append_3(truncated);
      Unit_getInstance();
    }buffer.append_3(postfix);
    Unit_getInstance();
    return buffer;
  }
  function sliceArray(_this_, indices) {
    if (indices.isEmpty_44())
      return new Int8Array(0);
    return copyOfRange_1(_this_, indices._get_start__1(), indices._get_endInclusive_() + 1 | 0);
  }
  function filterNotNull(_this_) {
    return filterNotNullTo(_this_, ArrayList_init_$Create$());
  }
  function filterNotNullTo(_this_, destination) {
    var indexedObject = _this_;
    var inductionVariable = 0;
    var last_2 = indexedObject.length;
    while (inductionVariable < last_2) {
      var element = indexedObject[inductionVariable];
      inductionVariable = inductionVariable + 1 | 0;
      if (!(element == null)) {
        destination.add_39(element);
        Unit_getInstance();
      }}
    return destination;
  }
  function _no_name_provided__1($this_asSequence) {
    this._$this_asSequence = $this_asSequence;
  }
  _no_name_provided__1.prototype.iterator_2_2 = function () {
    return arrayIterator(this._$this_asSequence);
  };
  _no_name_provided__1.prototype.iterator_66 = function () {
    return this.iterator_2_2();
  };
  _no_name_provided__1.$metadata$ = {
    simpleName: '<no name provided>_1',
    kind: 'class',
    interfaces: [Sequence]
  };
  function plus(_this_, elements) {
    if (isInterface(elements, Collection)) {
      var result = ArrayList_init_$Create$_0(_this_._get_size__52() + elements._get_size__52() | 0);
      result.addAll_14(_this_);
      Unit_getInstance();
      result.addAll_14(elements);
      Unit_getInstance();
      return result;
    } else {
      {
        var result_0 = ArrayList_init_$Create$_1(_this_);
        addAll_0(result_0, elements);
        Unit_getInstance();
        return result_0;
      }
    }
  }
  function reversed(_this_) {
    var tmp;
    if (isInterface(_this_, Collection)) {
      tmp = _this_._get_size__52() <= 1;
    } else {
      {
        tmp = false;
      }
    }
    if (tmp)
      return toList_0(_this_);
    else {
    }
    var list = toMutableList_2(_this_);
    reverse(list);
    return list;
  }
  function chunked(_this_, size_0) {
    return windowed(_this_, size_0, size_0, true);
  }
  function joinToString_1(_this_, separator, prefix, postfix, limit, truncated, transform) {
    return joinTo_1(_this_, StringBuilder_init_$Create$_1(), separator, prefix, postfix, limit, truncated, transform).toString();
  }
  function joinToString$default_1(_this_, separator, prefix, postfix, limit, truncated, transform, $mask0, $handler) {
    if (!(($mask0 & 1) === 0))
      separator = ', ';
    if (!(($mask0 & 2) === 0))
      prefix = '';
    if (!(($mask0 & 4) === 0))
      postfix = '';
    if (!(($mask0 & 8) === 0))
      limit = -1;
    if (!(($mask0 & 16) === 0))
      truncated = '...';
    if (!(($mask0 & 32) === 0))
      transform = null;
    return joinToString_1(_this_, separator, prefix, postfix, limit, truncated, transform);
  }
  function toList_0(_this_) {
    if (isInterface(_this_, Collection)) {
      var tmp0_subject = _this_._get_size__52();
      var tmp;
      switch (tmp0_subject) {
        case 0:
          tmp = emptyList();
          break;
        case 1:
          var tmp_0;
          if (isInterface(_this_, List)) {
            tmp_0 = _this_.get_72(0);
          } else {
            {
              tmp_0 = _this_.iterator_66().next_46();
            }
          }

          tmp = listOf_0(tmp_0);
          break;
        default:tmp = toMutableList_1(_this_);
          break;
      }
      return tmp;
    } else {
    }
    return optimizeReadOnlyList(toMutableList_2(_this_));
  }
  function toHashSet(_this_) {
    return toCollection_0(_this_, HashSet_init_$Create$_0(mapCapacity(collectionSizeOrDefault(_this_, 12))));
  }
  function toByteArray(_this_) {
    var result = new Int8Array(_this_._get_size__52());
    var index = 0;
    var tmp0_iterator = _this_.iterator_66();
    while (tmp0_iterator.hasNext_41()) {
      var element = tmp0_iterator.next_46();
      var tmp1 = index;
      index = tmp1 + 1 | 0;
      result[tmp1] = element;
    }
    return result;
  }
  function asSequence_0(_this_) {
    return new _no_name_provided__1_0(_this_);
  }
  function zip(_this_, other) {
    var first_1_0 = _this_.iterator_66();
    var second_2 = other.iterator_66();
    var tmp0_minOf_0 = collectionSizeOrDefault(_this_, 10);
    var tmp1_minOf_0 = collectionSizeOrDefault(other, 10);
    var list_3 = ArrayList_init_$Create$_0(function () {
      var $externalVarargReceiverTmp = Math;
      return $externalVarargReceiverTmp.min.apply($externalVarargReceiverTmp, [].concat([].slice.call(new Int32Array([tmp0_minOf_0, tmp1_minOf_0]))));
    }.call(this));
    while (first_1_0.hasNext_41() ? second_2.hasNext_41() : false) {
      var tmp2__anonymous__4 = first_1_0.next_46();
      var tmp3__anonymous__4 = second_2.next_46();
      list_3.add_39(to(tmp2__anonymous__4, tmp3__anonymous__4));
      Unit_getInstance();
    }
    return list_3;
  }
  function toMutableList_1(_this_) {
    return ArrayList_init_$Create$_1(_this_);
  }
  function slice(_this_, indices) {
    if (indices.isEmpty_44()) {
      return emptyList();
    }return toList_0(_this_.subList_8(indices._get_start__1(), indices._get_endInclusive_() + 1 | 0));
  }
  function firstOrNull(_this_) {
    return _this_.isEmpty_44() ? null : _this_.get_72(0);
  }
  function toIntArray(_this_) {
    var result = new Int32Array(_this_._get_size__52());
    var index = 0;
    var tmp0_iterator = _this_.iterator_66();
    while (tmp0_iterator.hasNext_41()) {
      var element = tmp0_iterator.next_46();
      var tmp1 = index;
      index = tmp1 + 1 | 0;
      result[tmp1] = element;
    }
    return result;
  }
  function firstOrNull_0(_this_) {
    var tmp0_subject = _this_;
    if (isInterface(tmp0_subject, List)) {
      if (_this_.isEmpty_44())
        return null;
      else
        return _this_.get_72(0);
    } else {
      {
        var iterator_0 = _this_.iterator_66();
        if (!iterator_0.hasNext_41())
          return null;
        return iterator_0.next_46();
      }
    }
  }
  function toMutableList_2(_this_) {
    if (isInterface(_this_, Collection))
      return toMutableList_1(_this_);
    else {
    }
    return toCollection_0(_this_, ArrayList_init_$Create$());
  }
  function windowed(_this_, size_0, step_0, partialWindows) {
    checkWindowSizeStep(size_0, step_0);
    var tmp;
    if (isInterface(_this_, RandomAccess)) {
      tmp = isInterface(_this_, List);
    } else {
      {
        tmp = false;
      }
    }
    if (tmp) {
      var thisSize = _this_._get_size__52();
      var resultCapacity = (thisSize / step_0 | 0) + (thisSize % step_0 === 0 ? 0 : 1) | 0;
      var result = ArrayList_init_$Create$_0(resultCapacity);
      var index = 0;
      $l$break: while (0 <= index ? index < thisSize : false) {
        var windowSize = coerceAtMost(size_0, thisSize - index | 0);
        if (windowSize < size_0 ? !partialWindows : false)
          break $l$break;
        var list_1 = ArrayList_init_$Create$_0(windowSize);
        var inductionVariable = 0;
        if (inductionVariable < windowSize)
          do {
            var index_3 = inductionVariable;
            inductionVariable = inductionVariable + 1 | 0;
            list_1.add_39(_this_.get_72(index_3 + index | 0));
            Unit_getInstance();
          }
           while (inductionVariable < windowSize);
        result.add_39(list_1);
        Unit_getInstance();
        index = index + step_0 | 0;
      }
      return result;
    } else {
    }
    var result_0 = ArrayList_init_$Create$();
    var tmp0_forEach_0 = windowedIterator(_this_.iterator_66(), size_0, step_0, partialWindows, false);
    var tmp0_iterator_1 = tmp0_forEach_0;
    while (tmp0_iterator_1.hasNext_41()) {
      var element_2 = tmp0_iterator_1.next_46();
      result_0.add_39(element_2);
      Unit_getInstance();
    }
    return result_0;
  }
  function joinTo_1(_this_, buffer, separator, prefix, postfix, limit, truncated, transform) {
    buffer.append_3(prefix);
    Unit_getInstance();
    var count_0 = 0;
    var tmp0_iterator = _this_.iterator_66();
    $l$break: while (tmp0_iterator.hasNext_41()) {
      var element = tmp0_iterator.next_46();
      count_0 = count_0 + 1 | 0;
      if (count_0 > 1) {
        buffer.append_3(separator);
        Unit_getInstance();
      } else {
      }
      if (limit < 0 ? true : count_0 <= limit) {
        appendElement(buffer, element, transform);
      } else
        break $l$break;
    }
    if (limit >= 0 ? count_0 > limit : false) {
      buffer.append_3(truncated);
      Unit_getInstance();
    }buffer.append_3(postfix);
    Unit_getInstance();
    return buffer;
  }
  function joinTo$default(_this_, buffer, separator, prefix, postfix, limit, truncated, transform, $mask0, $handler) {
    if (!(($mask0 & 2) === 0))
      separator = ', ';
    if (!(($mask0 & 4) === 0))
      prefix = '';
    if (!(($mask0 & 8) === 0))
      postfix = '';
    if (!(($mask0 & 16) === 0))
      limit = -1;
    if (!(($mask0 & 32) === 0))
      truncated = '...';
    if (!(($mask0 & 64) === 0))
      transform = null;
    return joinTo_1(_this_, buffer, separator, prefix, postfix, limit, truncated, transform);
  }
  function last_0(_this_) {
    if (_this_.isEmpty_44())
      throw NoSuchElementException_init_$Create$_0('List is empty.');
    return _this_.get_72(_get_lastIndex__2(_this_));
  }
  function toCollection_0(_this_, destination) {
    var tmp0_iterator = _this_.iterator_66();
    while (tmp0_iterator.hasNext_41()) {
      var item = tmp0_iterator.next_46();
      destination.add_39(item);
      Unit_getInstance();
    }
    return destination;
  }
  function first_0(_this_) {
    if (_this_.isEmpty_44())
      throw NoSuchElementException_init_$Create$_0('List is empty.');
    return _this_.get_72(0);
  }
  function toSet(_this_) {
    if (isInterface(_this_, Collection)) {
      var tmp0_subject = _this_._get_size__52();
      var tmp;
      switch (tmp0_subject) {
        case 0:
          tmp = emptySet();
          break;
        case 1:
          var tmp_0;
          if (isInterface(_this_, List)) {
            tmp_0 = _this_.get_72(0);
          } else {
            {
              tmp_0 = _this_.iterator_66().next_46();
            }
          }

          tmp = setOf(tmp_0);
          break;
        default:tmp = toCollection_0(_this_, LinkedHashSet_init_$Create$_1(mapCapacity(_this_._get_size__52())));
          break;
      }
      return tmp;
    } else {
    }
    return optimizeReadOnlySet(toCollection_0(_this_, LinkedHashSet_init_$Create$()));
  }
  function minOrNull(_this_) {
    var iterator_0 = _this_.iterator_66();
    if (!iterator_0.hasNext_41())
      return null;
    var min = iterator_0.next_46();
    while (iterator_0.hasNext_41()) {
      var e = iterator_0.next_46();
      if (compareTo(min, e) > 0)
        min = e;
    }
    return min;
  }
  function single_0(_this_) {
    var tmp0_subject = _this_;
    if (isInterface(tmp0_subject, List))
      return single_1(_this_);
    else {
      {
        var iterator_0 = _this_.iterator_66();
        if (!iterator_0.hasNext_41())
          throw NoSuchElementException_init_$Create$_0('Collection is empty.');
        var single_2 = iterator_0.next_46();
        if (iterator_0.hasNext_41())
          throw IllegalArgumentException_init_$Create$_0('Collection has more than one element.');
        return single_2;
      }
    }
  }
  function single_1(_this_) {
    var tmp0_subject = _this_._get_size__52();
    var tmp;
    switch (tmp0_subject) {
      case 0:
        throw NoSuchElementException_init_$Create$_0('List is empty.');
      case 1:
        tmp = _this_.get_72(0);
        break;
      default:throw IllegalArgumentException_init_$Create$_0('List has more than one element.');
    }
    return tmp;
  }
  function filterNotNull_0(_this_) {
    return filterNotNullTo_0(_this_, ArrayList_init_$Create$());
  }
  function union(_this_, other) {
    var set = toMutableSet(_this_);
    addAll_0(set, other);
    Unit_getInstance();
    return set;
  }
  function subtract(_this_, other) {
    var set = toMutableSet(_this_);
    removeAll_1(set, other);
    Unit_getInstance();
    return set;
  }
  function intersect(_this_, other) {
    var set = toMutableSet(_this_);
    retainAll(set, other);
    Unit_getInstance();
    return set;
  }
  function plus_0(_this_, element) {
    var result = ArrayList_init_$Create$_0(_this_._get_size__52() + 1 | 0);
    result.addAll_14(_this_);
    Unit_getInstance();
    result.add_39(element);
    Unit_getInstance();
    return result;
  }
  function filterNotNullTo_0(_this_, destination) {
    var tmp0_iterator = _this_.iterator_66();
    while (tmp0_iterator.hasNext_41()) {
      var element = tmp0_iterator.next_46();
      if (!(element == null)) {
        destination.add_39(element);
        Unit_getInstance();
      }}
    return destination;
  }
  function toMutableSet(_this_) {
    var tmp0_subject = _this_;
    var tmp;
    if (isInterface(tmp0_subject, Collection)) {
      tmp = LinkedHashSet_init_$Create$_0(_this_);
    } else {
      {
        tmp = toCollection_0(_this_, LinkedHashSet_init_$Create$());
      }
    }
    return tmp;
  }
  function _no_name_provided__1_0($this_asSequence) {
    this._$this_asSequence_0 = $this_asSequence;
  }
  _no_name_provided__1_0.prototype.iterator_2_2 = function () {
    return this._$this_asSequence_0.iterator_66();
  };
  _no_name_provided__1_0.prototype.iterator_66 = function () {
    return this.iterator_2_2();
  };
  _no_name_provided__1_0.$metadata$ = {
    simpleName: '<no name provided>_1',
    kind: 'class',
    interfaces: [Sequence]
  };
  function until(_this_, to_0) {
    if (to_0 <= IntCompanionObject_getInstance()._MIN_VALUE_2)
      return Companion_getInstance_7()._EMPTY;
    return numberRangeToNumber(_this_, to_0 - 1 | 0);
  }
  function downTo(_this_, to_0) {
    return Companion_getInstance_6().fromClosedRange(_this_, to_0, -1);
  }
  function coerceAtLeast(_this_, minimumValue) {
    return _this_ < minimumValue ? minimumValue : _this_;
  }
  function step(_this_, step_0) {
    checkStepIsPositive(step_0 > 0, step_0);
    return Companion_getInstance_6().fromClosedRange(_this_._first_1, _this_._last, _this_._step_0 > 0 ? step_0 : -step_0 | 0);
  }
  function coerceAtMost(_this_, maximumValue) {
    return _this_ > maximumValue ? maximumValue : _this_;
  }
  function coerceIn(_this_, minimumValue, maximumValue) {
    if (minimumValue > maximumValue)
      throw IllegalArgumentException_init_$Create$_0('' + 'Cannot coerce value to an empty range: maximum ' + maximumValue + ' is less than minimum ' + minimumValue + '.');
    if (_this_ < minimumValue)
      return minimumValue;
    if (_this_ > maximumValue)
      return maximumValue;
    return _this_;
  }
  function coerceIn_0(_this_, minimumValue, maximumValue) {
    if (minimumValue.compareTo_75(maximumValue) > 0)
      throw IllegalArgumentException_init_$Create$_0('' + 'Cannot coerce value to an empty range: maximum ' + maximumValue + ' is less than minimum ' + minimumValue + '.');
    if (_this_.compareTo_75(minimumValue) < 0)
      return minimumValue;
    if (_this_.compareTo_75(maximumValue) > 0)
      return maximumValue;
    return _this_;
  }
  function toList_1(_this_) {
    return optimizeReadOnlyList(toMutableList_3(_this_));
  }
  function first_1(_this_) {
    var iterator_0 = _this_.iterator_66();
    if (!iterator_0.hasNext_41())
      throw NoSuchElementException_init_$Create$_0('Sequence is empty.');
    return iterator_0.next_46();
  }
  function map(_this_, transform) {
    return new TransformingSequence(_this_, transform);
  }
  function count(_this_) {
    var count_0 = 0;
    var tmp0_iterator = _this_.iterator_66();
    while (tmp0_iterator.hasNext_41()) {
      var element = tmp0_iterator.next_46();
      count_0 = count_0 + 1 | 0;
      checkCountOverflow(count_0);
      Unit_getInstance();
    }
    return count_0;
  }
  function drop_0(_this_, n) {
    var tmp0_require_0 = n >= 0;
    if (!tmp0_require_0) {
      var message_2 = '' + 'Requested element count ' + n + ' is less than zero.';
      throw IllegalArgumentException_init_$Create$_0(toString_2(message_2));
    }var tmp;
    if (n === 0) {
      tmp = _this_;
    } else {
      if (isInterface(_this_, DropTakeSequence)) {
        tmp = _this_.drop_1(n);
      } else {
        {
          tmp = new DropSequence(_this_, n);
        }
      }
    }
    return tmp;
  }
  function joinToString_2(_this_, separator, prefix, postfix, limit, truncated, transform) {
    return joinTo_2(_this_, StringBuilder_init_$Create$_1(), separator, prefix, postfix, limit, truncated, transform).toString();
  }
  function joinToString$default_2(_this_, separator, prefix, postfix, limit, truncated, transform, $mask0, $handler) {
    if (!(($mask0 & 1) === 0))
      separator = ', ';
    if (!(($mask0 & 2) === 0))
      prefix = '';
    if (!(($mask0 & 4) === 0))
      postfix = '';
    if (!(($mask0 & 8) === 0))
      limit = -1;
    if (!(($mask0 & 16) === 0))
      truncated = '...';
    if (!(($mask0 & 32) === 0))
      transform = null;
    return joinToString_2(_this_, separator, prefix, postfix, limit, truncated, transform);
  }
  function zip_0(_this_, other) {
    return new MergingSequence(_this_, other, _no_name_provided_$factory());
  }
  function toMutableList_3(_this_) {
    return toCollection_1(_this_, ArrayList_init_$Create$());
  }
  function asIterable(_this_) {
    return new _no_name_provided__1_1(_this_);
  }
  function joinTo_2(_this_, buffer, separator, prefix, postfix, limit, truncated, transform) {
    buffer.append_3(prefix);
    Unit_getInstance();
    var count_0 = 0;
    var tmp0_iterator = _this_.iterator_66();
    $l$break: while (tmp0_iterator.hasNext_41()) {
      var element = tmp0_iterator.next_46();
      count_0 = count_0 + 1 | 0;
      if (count_0 > 1) {
        buffer.append_3(separator);
        Unit_getInstance();
      } else {
      }
      if (limit < 0 ? true : count_0 <= limit) {
        appendElement(buffer, element, transform);
      } else
        break $l$break;
    }
    if (limit >= 0 ? count_0 > limit : false) {
      buffer.append_3(truncated);
      Unit_getInstance();
    }buffer.append_3(postfix);
    Unit_getInstance();
    return buffer;
  }
  function toCollection_1(_this_, destination) {
    var tmp0_iterator = _this_.iterator_66();
    while (tmp0_iterator.hasNext_41()) {
      var item = tmp0_iterator.next_46();
      destination.add_39(item);
      Unit_getInstance();
    }
    return destination;
  }
  function contains_3(_this_, element) {
    return indexOf_3(_this_, element) >= 0;
  }
  function indexOf_3(_this_, element) {
    var index = 0;
    var tmp0_iterator = _this_.iterator_66();
    while (tmp0_iterator.hasNext_41()) {
      var item = tmp0_iterator.next_46();
      checkIndexOverflow(index);
      Unit_getInstance();
      if (equals_0(element, item))
        return index;
      var tmp1 = index;
      index = tmp1 + 1 | 0;
      Unit_getInstance();
    }
    return -1;
  }
  function _no_name_provided_() {
  }
  _no_name_provided_.prototype.invoke_0 = function (t1, t2) {
    return to(t1, t2);
  };
  _no_name_provided_.prototype.invoke_915 = function (p1, p2) {
    var tmp = (p1 == null ? true : isObject(p1)) ? p1 : THROW_CCE();
    return this.invoke_0(tmp, (p2 == null ? true : isObject(p2)) ? p2 : THROW_CCE());
  };
  _no_name_provided_.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__1_1($this_asIterable) {
    this._$this_asIterable = $this_asIterable;
  }
  _no_name_provided__1_1.prototype.iterator_2_2 = function () {
    return this._$this_asIterable.iterator_66();
  };
  _no_name_provided__1_1.prototype.iterator_66 = function () {
    return this.iterator_2_2();
  };
  _no_name_provided__1_1.$metadata$ = {
    simpleName: '<no name provided>_1',
    kind: 'class',
    interfaces: [Iterable]
  };
  function _no_name_provided_$factory() {
    var i = new _no_name_provided_();
    return function (p1, p2) {
      return i.invoke_0(p1, p2);
    };
  }
  function minus(_this_, element) {
    var result = LinkedHashSet_init_$Create$_1(mapCapacity(_this_._get_size__52()));
    var removed = false;
    var tmp0_iterator_1 = _this_.iterator_66();
    while (tmp0_iterator_1.hasNext_41()) {
      var element_2 = tmp0_iterator_1.next_46();
      var tmp;
      if (!removed ? equals_0(element_2, element) : false) {
        removed = true;
        tmp = false;
      } else {
        tmp = true;
      }
      if (tmp) {
        result.add_39(element_2);
        Unit_getInstance();
      } else {
      }
    }
    return result;
  }
  function first_2(_this_) {
    if (charSequenceLength(_this_) === 0)
      throw NoSuchElementException_init_$Create$_0('Char sequence is empty.');
    else {
    }
    return charSequenceGet(_this_, 0);
  }
  function drop_1(_this_, n) {
    var tmp0_require_0 = n >= 0;
    if (!tmp0_require_0) {
      var message_2 = '' + 'Requested character count ' + n + ' is less than zero.';
      throw IllegalArgumentException_init_$Create$_0(toString_2(message_2));
    }var tmp1_substring_0 = coerceAtMost(n, _this_.length);
    return _this_.substring(tmp1_substring_0);
  }
  function slice_0(_this_, indices) {
    if (indices.isEmpty_44())
      return '';
    return substring(_this_, indices);
  }
  function take_0(_this_, n) {
    var tmp0_require_0 = n >= 0;
    if (!tmp0_require_0) {
      var message_2 = '' + 'Requested character count ' + n + ' is less than zero.';
      throw IllegalArgumentException_init_$Create$_0(toString_2(message_2));
    }var tmp1_substring_0 = 0;
    var tmp2_substring_0 = coerceAtMost(n, _this_.length);
    return _this_.substring(tmp1_substring_0, tmp2_substring_0);
  }
  function last_1(_this_) {
    if (charSequenceLength(_this_) === 0)
      throw NoSuchElementException_init_$Create$_0('Char sequence is empty.');
    else {
    }
    return charSequenceGet(_this_, _get_lastIndex__3(_this_));
  }
  function dropLast(_this_, n) {
    var tmp0_require_0 = n >= 0;
    if (!tmp0_require_0) {
      var message_2 = '' + 'Requested character count ' + n + ' is less than zero.';
      throw IllegalArgumentException_init_$Create$_0(toString_2(message_2));
    }return take_0(_this_, coerceAtLeast(_this_.length - n | 0, 0));
  }
  function takeLast_0(_this_, n) {
    var tmp0_require_0 = n >= 0;
    if (!tmp0_require_0) {
      var message_2 = '' + 'Requested character count ' + n + ' is less than zero.';
      throw IllegalArgumentException_init_$Create$_0(toString_2(message_2));
    }var length = _this_.length;
    var tmp1_substring_0 = length - coerceAtMost(n, length) | 0;
    return _this_.substring(tmp1_substring_0);
  }
  function reversed_0(_this_) {
    return StringBuilder_init_$Create$_0(_this_).reverse();
  }
  function slice_1(_this_, indices) {
    if (indices.isEmpty_44()) {
      return emptyList();
    }var tmp0_copyOfRange_0 = indices._get_start__1();
    var tmp1_copyOfRange_0 = indices._get_endInclusive_() + 1 | 0;
    return asList_0(_ULongArray___init__impl_(copyOfRange(_ULongArray___get_storage__impl_(_this_), tmp0_copyOfRange_0, tmp1_copyOfRange_0)));
  }
  function contentEquals(_this_, other) {
    var tmp1_safe_receiver = _this_;
    var tmp;
    var tmp_0 = tmp1_safe_receiver;
    if ((tmp_0 == null ? null : new ULongArray(tmp_0)) == null) {
      tmp = null;
    } else {
      {
        tmp = _ULongArray___get_storage__impl_(tmp1_safe_receiver);
      }
    }
    var tmp_1 = tmp;
    var tmp0_safe_receiver = other;
    var tmp_2;
    var tmp_3 = tmp0_safe_receiver;
    if ((tmp_3 == null ? null : new ULongArray(tmp_3)) == null) {
      tmp_2 = null;
    } else {
      {
        tmp_2 = _ULongArray___get_storage__impl_(tmp0_safe_receiver);
      }
    }
    return contentEquals_1(tmp_1, tmp_2);
  }
  function contentEquals_0(_this_, other) {
    var tmp1_safe_receiver = _this_;
    var tmp;
    var tmp_0 = tmp1_safe_receiver;
    if ((tmp_0 == null ? null : new UByteArray(tmp_0)) == null) {
      tmp = null;
    } else {
      {
        tmp = _UByteArray___get_storage__impl_(tmp1_safe_receiver);
      }
    }
    var tmp_1 = tmp;
    var tmp0_safe_receiver = other;
    var tmp_2;
    var tmp_3 = tmp0_safe_receiver;
    if ((tmp_3 == null ? null : new UByteArray(tmp_3)) == null) {
      tmp_2 = null;
    } else {
      {
        tmp_2 = _UByteArray___get_storage__impl_(tmp0_safe_receiver);
      }
    }
    return contentEquals_2(tmp_1, tmp_2);
  }
  function dropLast_0(_this_, n) {
    var tmp0_require_0 = n >= 0;
    if (!tmp0_require_0) {
      var message_2 = '' + 'Requested element count ' + n + ' is less than zero.';
      throw IllegalArgumentException_init_$Create$_0(toString_2(message_2));
    }return take_1(_this_, coerceAtLeast(_UByteArray___get_size__impl_(_this_) - n | 0, 0));
  }
  function drop_2(_this_, n) {
    var tmp0_require_0 = n >= 0;
    if (!tmp0_require_0) {
      var message_2 = '' + 'Requested element count ' + n + ' is less than zero.';
      throw IllegalArgumentException_init_$Create$_0(toString_2(message_2));
    }return takeLast_1(_this_, coerceAtLeast(_UByteArray___get_size__impl_(_this_) - n | 0, 0));
  }
  function slice_2(_this_, indices) {
    if (indices.isEmpty_44()) {
      return emptyList();
    }var tmp0_copyOfRange_0 = indices._get_start__1();
    var tmp1_copyOfRange_0 = indices._get_endInclusive_() + 1 | 0;
    return asList_1(_UByteArray___init__impl_(copyOfRange_1(_UByteArray___get_storage__impl_(_this_), tmp0_copyOfRange_0, tmp1_copyOfRange_0)));
  }
  function plus_1(_this_, elements) {
    var index = _UByteArray___get_size__impl_(_this_);
    var result = copyOf_0(_UByteArray___get_storage__impl_(_this_), _UByteArray___get_size__impl_(_this_) + elements._get_size__52() | 0);
    var tmp0_iterator = elements.iterator_66();
    while (tmp0_iterator.hasNext_41()) {
      var element = tmp0_iterator.next_46()._data;
      var tmp1 = index;
      index = tmp1 + 1 | 0;
      result[tmp1] = _UByte___get_data__impl_(element);
    }
    return _UByteArray___init__impl_(result);
  }
  function take_1(_this_, n) {
    var tmp0_require_0 = n >= 0;
    if (!tmp0_require_0) {
      var message_2 = '' + 'Requested element count ' + n + ' is less than zero.';
      throw IllegalArgumentException_init_$Create$_0(toString_2(message_2));
    }if (n === 0)
      return emptyList();
    if (n >= _UByteArray___get_size__impl_(_this_))
      return toList_0(new UByteArray(_this_));
    if (n === 1)
      return listOf_0(new UByte(UByteArray__get_impl(_this_, 0)));
    var count_0 = 0;
    var list = ArrayList_init_$Create$_0(n);
    var tmp0_iterator = UByteArray__iterator_impl(_this_);
    $l$break: while (tmp0_iterator.hasNext_41()) {
      var item = tmp0_iterator.next_46()._data;
      list.add_39(new UByte(item));
      Unit_getInstance();
      count_0 = count_0 + 1 | 0;
      if (count_0 === n)
        break $l$break;
      else {
      }
    }
    return list;
  }
  function take_2(_this_, n) {
    var tmp0_require_0 = n >= 0;
    if (!tmp0_require_0) {
      var message_2 = '' + 'Requested element count ' + n + ' is less than zero.';
      throw IllegalArgumentException_init_$Create$_0(toString_2(message_2));
    }if (n === 0)
      return emptyList();
    if (n >= _ULongArray___get_size__impl_(_this_))
      return toList_0(new ULongArray(_this_));
    if (n === 1)
      return listOf_0(new ULong(ULongArray__get_impl(_this_, 0)));
    var count_0 = 0;
    var list = ArrayList_init_$Create$_0(n);
    var tmp0_iterator = ULongArray__iterator_impl(_this_);
    $l$break: while (tmp0_iterator.hasNext_41()) {
      var item = tmp0_iterator.next_46()._data_1;
      list.add_39(new ULong(item));
      Unit_getInstance();
      count_0 = count_0 + 1 | 0;
      if (count_0 === n)
        break $l$break;
      else {
      }
    }
    return list;
  }
  function takeLast_1(_this_, n) {
    var tmp0_require_0 = n >= 0;
    if (!tmp0_require_0) {
      var message_2 = '' + 'Requested element count ' + n + ' is less than zero.';
      throw IllegalArgumentException_init_$Create$_0(toString_2(message_2));
    }if (n === 0)
      return emptyList();
    var size_0 = _UByteArray___get_size__impl_(_this_);
    if (n >= size_0)
      return toList_0(new UByteArray(_this_));
    if (n === 1)
      return listOf_0(new UByte(UByteArray__get_impl(_this_, size_0 - 1 | 0)));
    var list = ArrayList_init_$Create$_0(n);
    var inductionVariable = size_0 - n | 0;
    if (inductionVariable < size_0)
      do {
        var index = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        list.add_39(new UByte(UByteArray__get_impl(_this_, index)));
        Unit_getInstance();
      }
       while (inductionVariable < size_0);
    return list;
  }
  function toULongArray(_this_) {
    var result = _ULongArray___init__impl__0(_this_._get_size__52());
    var index = 0;
    var tmp0_iterator = _this_.iterator_66();
    while (tmp0_iterator.hasNext_41()) {
      var element = tmp0_iterator.next_46()._data_1;
      var tmp1 = index;
      index = tmp1 + 1 | 0;
      ULongArray__set_impl(result, tmp1, element);
    }
    return result;
  }
  function toUByteArray(_this_) {
    var result = _UByteArray___init__impl__0(_this_._get_size__52());
    var index = 0;
    var tmp0_iterator = _this_.iterator_66();
    while (tmp0_iterator.hasNext_41()) {
      var element = tmp0_iterator.next_46()._data;
      var tmp1 = index;
      index = tmp1 + 1 | 0;
      UByteArray__set_impl(result, tmp1, element);
    }
    return result;
  }
  function _no_name_provided__0(this$0) {
    this._this$0 = this$0;
  }
  _no_name_provided__0.prototype.invoke_33 = function (it) {
    return it === this._this$0 ? '(this Collection)' : toString_1(it);
  };
  _no_name_provided__0.prototype.invoke_1115 = function (p1) {
    return this.invoke_33((p1 == null ? true : isObject(p1)) ? p1 : THROW_CCE());
  };
  _no_name_provided__0.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function AbstractCollection() {
  }
  AbstractCollection.prototype.contains_51 = function (element) {
    var tmp$ret$0;
    l$ret$1: do {
      var tmp;
      if (isInterface(this, Collection)) {
        tmp = this.isEmpty_44();
      } else {
        {
          tmp = false;
        }
      }
      if (tmp) {
        tmp$ret$0 = false;
        break l$ret$1;
      } else {
      }
      var tmp0_iterator_1 = this.iterator_66();
      while (tmp0_iterator_1.hasNext_41()) {
        var element_2 = tmp0_iterator_1.next_46();
        if (equals_0(element_2, element)) {
          tmp$ret$0 = true;
          break l$ret$1;
        } else {
        }
      }
      tmp$ret$0 = false;
    }
     while (false);
    return tmp$ret$0;
  };
  AbstractCollection.prototype.containsAll_36 = function (elements) {
    var tmp$ret$0;
    l$ret$1: do {
      var tmp;
      if (isInterface(elements, Collection)) {
        tmp = elements.isEmpty_44();
      } else {
        {
          tmp = false;
        }
      }
      if (tmp) {
        tmp$ret$0 = true;
        break l$ret$1;
      } else {
      }
      var tmp0_iterator_1 = elements.iterator_66();
      while (tmp0_iterator_1.hasNext_41()) {
        var element_2 = tmp0_iterator_1.next_46();
        if (!this.contains_51(element_2)) {
          tmp$ret$0 = false;
          break l$ret$1;
        } else {
        }
      }
      tmp$ret$0 = true;
    }
     while (false);
    return tmp$ret$0;
  };
  AbstractCollection.prototype.isEmpty_44 = function () {
    return this._get_size__52() === 0;
  };
  AbstractCollection.prototype.toString = function () {
    return joinToString$default_1(this, ', ', '[', ']', 0, null, _no_name_provided_$factory_0(this), 24, null);
  };
  AbstractCollection.prototype.toArray = function () {
    return copyToArrayImpl_0(this);
  };
  AbstractCollection.$metadata$ = {
    simpleName: 'AbstractCollection',
    kind: 'class',
    interfaces: [Collection]
  };
  function _no_name_provided_$factory_0(this$0) {
    var i = new _no_name_provided__0(this$0);
    return function (p1) {
      return i.invoke_33(p1);
    };
  }
  function tryToComputeNext($this) {
    $this._state = State_Failed_getInstance();
    $this.computeNext_0();
    return $this._state.equals(State_Ready_getInstance());
  }
  function AbstractIterator() {
    this._state = State_NotReady_getInstance();
    this._nextValue = null;
  }
  AbstractIterator.prototype.hasNext_41 = function () {
    var tmp0_require_0 = !this._state.equals(State_Failed_getInstance());
    if (!tmp0_require_0) {
      var message_2 = 'Failed requirement.';
      throw IllegalArgumentException_init_$Create$_0(toString_2(message_2));
    }var tmp0_subject = this._state;
    return tmp0_subject.equals(State_Done_getInstance()) ? false : tmp0_subject.equals(State_Ready_getInstance()) ? true : tryToComputeNext(this);
  };
  AbstractIterator.prototype.next_46 = function () {
    if (!this.hasNext_41())
      throw NoSuchElementException_init_$Create$();
    this._state = State_NotReady_getInstance();
    var tmp = this._nextValue;
    return (tmp == null ? true : isObject(tmp)) ? tmp : THROW_CCE();
  };
  AbstractIterator.prototype.setNext_0 = function (value_0) {
    this._nextValue = value_0;
    this._state = State_Ready_getInstance();
  };
  AbstractIterator.prototype.done_0 = function () {
    this._state = State_Done_getInstance();
  };
  AbstractIterator.$metadata$ = {
    simpleName: 'AbstractIterator',
    kind: 'class',
    interfaces: [Iterator_2]
  };
  var State_Ready_instance;
  var State_NotReady_instance;
  var State_Done_instance;
  var State_Failed_instance;
  var State_entriesInitialized;
  function State_initEntries() {
    if (State_entriesInitialized)
      return Unit_getInstance();
    State_entriesInitialized = true;
    State_Ready_instance = new State('Ready', 0);
    State_NotReady_instance = new State('NotReady', 1);
    State_Done_instance = new State('Done', 2);
    State_Failed_instance = new State('Failed', 3);
  }
  function State(name, ordinal) {
    Enum.call(this, name, ordinal);
  }
  State.$metadata$ = {
    simpleName: 'State',
    kind: 'class',
    interfaces: []
  };
  function State_Ready_getInstance() {
    State_initEntries();
    return State_Ready_instance;
  }
  function State_NotReady_getInstance() {
    State_initEntries();
    return State_NotReady_instance;
  }
  function State_Done_getInstance() {
    State_initEntries();
    return State_Done_instance;
  }
  function State_Failed_getInstance() {
    State_initEntries();
    return State_Failed_instance;
  }
  function SubList(list, fromIndex, toIndex) {
    AbstractList.call(this);
    this._list = list;
    this._fromIndex = fromIndex;
    this.__size = 0;
    Companion_getInstance().checkRangeIndexes(this._fromIndex, toIndex, this._list._get_size__52());
    this.__size = toIndex - this._fromIndex | 0;
  }
  SubList.prototype.get_72 = function (index) {
    Companion_getInstance().checkElementIndex(index, this.__size);
    return this._list.get_72(this._fromIndex + index | 0);
  };
  SubList.prototype._get_size__52 = function () {
    return this.__size;
  };
  SubList.$metadata$ = {
    simpleName: 'SubList',
    kind: 'class',
    interfaces: [RandomAccess]
  };
  function IteratorImpl($outer) {
    this._$this = $outer;
    this._index = 0;
  }
  IteratorImpl.prototype.hasNext_41 = function () {
    return this._index < this._$this._get_size__52();
  };
  IteratorImpl.prototype.next_46 = function () {
    if (!this.hasNext_41())
      throw NoSuchElementException_init_$Create$();
    var tmp0_this = this;
    var tmp1 = tmp0_this._index;
    tmp0_this._index = tmp1 + 1 | 0;
    return this._$this.get_72(tmp1);
  };
  IteratorImpl.$metadata$ = {
    simpleName: 'IteratorImpl',
    kind: 'class',
    interfaces: [Iterator_2]
  };
  function Companion_0() {
    Companion_instance = this;
  }
  Companion_0.prototype.checkElementIndex = function (index, size_0) {
    if (index < 0 ? true : index >= size_0) {
      throw IndexOutOfBoundsException_init_$Create$('' + 'index: ' + index + ', size: ' + size_0);
    }};
  Companion_0.prototype.checkPositionIndex = function (index, size_0) {
    if (index < 0 ? true : index > size_0) {
      throw IndexOutOfBoundsException_init_$Create$('' + 'index: ' + index + ', size: ' + size_0);
    }};
  Companion_0.prototype.checkRangeIndexes = function (fromIndex, toIndex, size_0) {
    if (fromIndex < 0 ? true : toIndex > size_0) {
      throw IndexOutOfBoundsException_init_$Create$('' + 'fromIndex: ' + fromIndex + ', toIndex: ' + toIndex + ', size: ' + size_0);
    }if (fromIndex > toIndex) {
      throw IllegalArgumentException_init_$Create$_0('' + 'fromIndex: ' + fromIndex + ' > toIndex: ' + toIndex);
    }};
  Companion_0.prototype.checkBoundsIndexes = function (startIndex, endIndex, size_0) {
    if (startIndex < 0 ? true : endIndex > size_0) {
      throw IndexOutOfBoundsException_init_$Create$('' + 'startIndex: ' + startIndex + ', endIndex: ' + endIndex + ', size: ' + size_0);
    }if (startIndex > endIndex) {
      throw IllegalArgumentException_init_$Create$_0('' + 'startIndex: ' + startIndex + ' > endIndex: ' + endIndex);
    }};
  Companion_0.prototype.orderedHashCode = function (c) {
    var hashCode_1 = 1;
    var tmp0_iterator = c.iterator_66();
    while (tmp0_iterator.hasNext_41()) {
      var e = tmp0_iterator.next_46();
      var tmp = imul(31, hashCode_1);
      var tmp1_safe_receiver = e;
      var tmp2_elvis_lhs = tmp1_safe_receiver == null ? null : hashCode(tmp1_safe_receiver);
      hashCode_1 = tmp + (tmp2_elvis_lhs == null ? 0 : tmp2_elvis_lhs) | 0;
    }
    return hashCode_1;
  };
  Companion_0.prototype.orderedEquals = function (c, other) {
    if (!(c._get_size__52() === other._get_size__52()))
      return false;
    var otherIterator = other.iterator_66();
    var tmp0_iterator = c.iterator_66();
    while (tmp0_iterator.hasNext_41()) {
      var elem = tmp0_iterator.next_46();
      var elemOther = otherIterator.next_46();
      if (!equals_0(elem, elemOther)) {
        return false;
      }}
    return true;
  };
  Companion_0.$metadata$ = {
    simpleName: 'Companion',
    kind: 'object',
    interfaces: []
  };
  var Companion_instance;
  function Companion_getInstance() {
    if (Companion_instance == null)
      new Companion_0();
    return Companion_instance;
  }
  function AbstractList() {
    Companion_getInstance();
    AbstractCollection.call(this);
  }
  AbstractList.prototype.iterator_66 = function () {
    return new IteratorImpl(this);
  };
  AbstractList.prototype.subList_8 = function (fromIndex, toIndex) {
    return new SubList(this, fromIndex, toIndex);
  };
  AbstractList.prototype.equals = function (other) {
    if (other === this)
      return true;
    if (!(!(other == null) ? isInterface(other, List) : false))
      return false;
    else {
    }
    return Companion_getInstance().orderedEquals(this, other);
  };
  AbstractList.prototype.hashCode = function () {
    return Companion_getInstance().orderedHashCode(this);
  };
  AbstractList.$metadata$ = {
    simpleName: 'AbstractList',
    kind: 'class',
    interfaces: [List]
  };
  function _no_name_provided__2($entryIterator) {
    this._$entryIterator = $entryIterator;
  }
  _no_name_provided__2.prototype.hasNext_41 = function () {
    return this._$entryIterator.hasNext_41();
  };
  _no_name_provided__2.prototype.next_46 = function () {
    return this._$entryIterator.next_46()._get_value__33();
  };
  _no_name_provided__2.$metadata$ = {
    kind: 'class',
    interfaces: [Iterator_2]
  };
  function toString($this, o) {
    return o === $this ? '(this Map)' : toString_1(o);
  }
  function implFindEntry($this, key_0) {
    var tmp$ret$0;
    l$ret$1: do {
      var tmp0_firstOrNull_0 = $this._get_entries__5();
      var tmp0_iterator_1 = tmp0_firstOrNull_0.iterator_66();
      while (tmp0_iterator_1.hasNext_41()) {
        var element_2 = tmp0_iterator_1.next_46();
        if (equals_0(element_2._get_key__35(), key_0)) {
          tmp$ret$0 = element_2;
          break l$ret$1;
        } else {
        }
      }
      tmp$ret$0 = null;
    }
     while (false);
    return tmp$ret$0;
  }
  function Companion_1() {
    Companion_instance_0 = this;
  }
  Companion_1.prototype.entryHashCode = function (e) {
    var tmp2_safe_receiver_4 = e._get_key__35();
    var tmp3_elvis_lhs_3 = tmp2_safe_receiver_4 == null ? null : hashCode(tmp2_safe_receiver_4);
    var tmp = tmp3_elvis_lhs_3 == null ? 0 : tmp3_elvis_lhs_3;
    var tmp0_safe_receiver_6 = e._get_value__33();
    var tmp1_elvis_lhs_5 = tmp0_safe_receiver_6 == null ? null : hashCode(tmp0_safe_receiver_6);
    return tmp ^ (tmp1_elvis_lhs_5 == null ? 0 : tmp1_elvis_lhs_5);
  };
  Companion_1.prototype.entryToString = function (e) {
    return '' + e._get_key__35() + '=' + e._get_value__33();
  };
  Companion_1.prototype.entryEquals = function (e, other) {
    if (!(!(other == null) ? isInterface(other, Entry) : false))
      return false;
    else {
    }
    return equals_0(e._get_key__35(), other._get_key__35()) ? equals_0(e._get_value__33(), other._get_value__33()) : false;
  };
  Companion_1.$metadata$ = {
    simpleName: 'Companion',
    kind: 'object',
    interfaces: []
  };
  var Companion_instance_0;
  function Companion_getInstance_0() {
    if (Companion_instance_0 == null)
      new Companion_1();
    return Companion_instance_0;
  }
  function _no_name_provided__3(this$0) {
    this._this$0_0 = this$0;
  }
  _no_name_provided__3.prototype.invoke_4 = function (it) {
    return this._this$0_0.toString_0(it);
  };
  _no_name_provided__3.prototype.invoke_1115 = function (p1) {
    return this.invoke_4((!(p1 == null) ? isInterface(p1, Entry) : false) ? p1 : THROW_CCE());
  };
  _no_name_provided__3.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__4(this$0) {
    this._this$0_1 = this$0;
    AbstractCollection.call(this);
  }
  _no_name_provided__4.prototype.contains_47 = function (element) {
    return this._this$0_1.containsValue_3(element);
  };
  _no_name_provided__4.prototype.contains_51 = function (element) {
    if (!(element == null ? true : isObject(element)))
      return false;
    else {
    }
    return this.contains_47((element == null ? true : isObject(element)) ? element : THROW_CCE());
  };
  _no_name_provided__4.prototype.iterator_66 = function () {
    var entryIterator = this._this$0_1._get_entries__5().iterator_66();
    return new _no_name_provided__2(entryIterator);
  };
  _no_name_provided__4.prototype._get_size__52 = function () {
    return this._this$0_1._get_size__52();
  };
  _no_name_provided__4.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function AbstractMap() {
    Companion_getInstance_0();
    this.__keys = null;
    this.__values = null;
  }
  AbstractMap.prototype.containsKey_7 = function (key_0) {
    return !(implFindEntry(this, key_0) == null);
  };
  AbstractMap.prototype.containsValue_3 = function (value_0) {
    var tmp$ret$0;
    l$ret$1: do {
      var tmp0_any_0 = this._get_entries__5();
      var tmp;
      if (isInterface(tmp0_any_0, Collection)) {
        tmp = tmp0_any_0.isEmpty_44();
      } else {
        {
          tmp = false;
        }
      }
      if (tmp) {
        tmp$ret$0 = false;
        break l$ret$1;
      } else {
      }
      var tmp0_iterator_1 = tmp0_any_0.iterator_66();
      while (tmp0_iterator_1.hasNext_41()) {
        var element_2 = tmp0_iterator_1.next_46();
        if (equals_0(element_2._get_value__33(), value_0)) {
          tmp$ret$0 = true;
          break l$ret$1;
        } else {
        }
      }
      tmp$ret$0 = false;
    }
     while (false);
    return tmp$ret$0;
  };
  AbstractMap.prototype.containsEntry_6 = function (entry) {
    if (!(!(entry == null) ? isInterface(entry, Entry) : false))
      return false;
    else {
    }
    var key_0 = entry._get_key__35();
    var value_0 = entry._get_value__33();
    var ourValue = (isInterface(this, Map_0) ? this : THROW_CCE()).get_56(key_0);
    if (!equals_0(value_0, ourValue)) {
      return false;
    }var tmp;
    if (ourValue == null) {
      tmp = !(isInterface(this, Map_0) ? this : THROW_CCE()).containsKey_7(key_0);
    } else {
      tmp = false;
    }
    if (tmp) {
      return false;
    } else {
    }
    return true;
  };
  AbstractMap.prototype.equals = function (other) {
    if (other === this)
      return true;
    if (!(!(other == null) ? isInterface(other, Map_0) : false))
      return false;
    else {
    }
    if (!(this._get_size__52() === other._get_size__52()))
      return false;
    var tmp$ret$0;
    l$ret$1: do {
      var tmp0_all_0 = other._get_entries__5();
      var tmp;
      if (isInterface(tmp0_all_0, Collection)) {
        tmp = tmp0_all_0.isEmpty_44();
      } else {
        {
          tmp = false;
        }
      }
      if (tmp) {
        tmp$ret$0 = true;
        break l$ret$1;
      } else {
      }
      var tmp0_iterator_1 = tmp0_all_0.iterator_66();
      while (tmp0_iterator_1.hasNext_41()) {
        var element_2 = tmp0_iterator_1.next_46();
        if (!this.containsEntry_6(element_2)) {
          tmp$ret$0 = false;
          break l$ret$1;
        } else {
        }
      }
      tmp$ret$0 = true;
    }
     while (false);
    return tmp$ret$0;
  };
  AbstractMap.prototype.get_56 = function (key_0) {
    var tmp0_safe_receiver = implFindEntry(this, key_0);
    return tmp0_safe_receiver == null ? null : tmp0_safe_receiver._get_value__33();
  };
  AbstractMap.prototype.hashCode = function () {
    return hashCode(this._get_entries__5());
  };
  AbstractMap.prototype.isEmpty_44 = function () {
    return this._get_size__52() === 0;
  };
  AbstractMap.prototype._get_size__52 = function () {
    return this._get_entries__5()._get_size__52();
  };
  AbstractMap.prototype.toString = function () {
    var tmp = this._get_entries__5();
    return joinToString$default_1(tmp, ', ', '{', '}', 0, null, _no_name_provided_$factory_1(this), 24, null);
  };
  AbstractMap.prototype.toString_0 = function (entry) {
    return toString(this, entry._get_key__35()) + '=' + toString(this, entry._get_value__33());
  };
  AbstractMap.prototype._get_values__6 = function () {
    if (this.__values == null) {
      var tmp = this;
      tmp.__values = new _no_name_provided__4(this);
    }return ensureNotNull(this.__values);
  };
  AbstractMap.$metadata$ = {
    simpleName: 'AbstractMap',
    kind: 'class',
    interfaces: [Map_0]
  };
  function _no_name_provided_$factory_1(this$0) {
    var i = new _no_name_provided__3(this$0);
    return function (p1) {
      return i.invoke_4(p1);
    };
  }
  function Companion_2() {
    Companion_instance_1 = this;
  }
  Companion_2.prototype.unorderedHashCode = function (c) {
    var hashCode_1 = 0;
    var tmp0_iterator = c.iterator_66();
    while (tmp0_iterator.hasNext_41()) {
      var element = tmp0_iterator.next_46();
      var tmp = hashCode_1;
      var tmp1_safe_receiver = element;
      var tmp2_elvis_lhs = tmp1_safe_receiver == null ? null : hashCode(tmp1_safe_receiver);
      hashCode_1 = tmp + (tmp2_elvis_lhs == null ? 0 : tmp2_elvis_lhs) | 0;
    }
    return hashCode_1;
  };
  Companion_2.prototype.setEquals = function (c, other) {
    if (!(c._get_size__52() === other._get_size__52()))
      return false;
    return c.containsAll_36(other);
  };
  Companion_2.$metadata$ = {
    simpleName: 'Companion',
    kind: 'object',
    interfaces: []
  };
  var Companion_instance_1;
  function Companion_getInstance_1() {
    if (Companion_instance_1 == null)
      new Companion_2();
    return Companion_instance_1;
  }
  function AbstractSet() {
    Companion_getInstance_1();
    AbstractCollection.call(this);
  }
  AbstractSet.prototype.equals = function (other) {
    if (other === this)
      return true;
    if (!(!(other == null) ? isInterface(other, Set) : false))
      return false;
    else {
    }
    return Companion_getInstance_1().setEquals(this, other);
  };
  AbstractSet.prototype.hashCode = function () {
    return Companion_getInstance_1().unorderedHashCode(this);
  };
  AbstractSet.$metadata$ = {
    simpleName: 'AbstractSet',
    kind: 'class',
    interfaces: [Set]
  };
  function emptyList() {
    return EmptyList_getInstance();
  }
  function listOf(elements) {
    return elements.length > 0 ? asList(elements) : emptyList();
  }
  function _get_lastIndex__2(_this_) {
    return _this_._get_size__52() - 1 | 0;
  }
  function optimizeReadOnlyList(_this_) {
    var tmp0_subject = _this_._get_size__52();
    switch (tmp0_subject) {
      case 0:
        return emptyList();
      case 1:
        return listOf_0(_this_.get_72(0));
      default:return _this_;
    }
  }
  function arrayListOf(elements) {
    return elements.length === 0 ? ArrayList_init_$Create$() : ArrayList_init_$Create$_1(new ArrayAsCollection(elements, true));
  }
  function throwIndexOverflow() {
    throw ArithmeticException_init_$Create$('Index overflow has happened.');
  }
  function EmptyList() {
    EmptyList_instance = this;
    this._serialVersionUID = new Long(-1478467534, -1720727600);
  }
  EmptyList.prototype.equals = function (other) {
    var tmp;
    if (!(other == null) ? isInterface(other, List) : false) {
      tmp = other.isEmpty_44();
    } else {
      {
        tmp = false;
      }
    }
    return tmp;
  };
  EmptyList.prototype.hashCode = function () {
    return 1;
  };
  EmptyList.prototype.toString = function () {
    return '[]';
  };
  EmptyList.prototype._get_size__52 = function () {
    return 0;
  };
  EmptyList.prototype.isEmpty_44 = function () {
    return true;
  };
  EmptyList.prototype.contains_10 = function (element) {
    return false;
  };
  EmptyList.prototype.contains_51 = function (element) {
    if (!false)
      return false;
    else {
    }
    var tmp;
    if (false) {
      tmp = element;
    } else {
      {
        tmp = THROW_CCE();
      }
    }
    return this.contains_10(tmp);
  };
  EmptyList.prototype.containsAll_8 = function (elements) {
    return elements.isEmpty_44();
  };
  EmptyList.prototype.containsAll_36 = function (elements) {
    return this.containsAll_8(elements);
  };
  EmptyList.prototype.get_72 = function (index) {
    throw IndexOutOfBoundsException_init_$Create$('' + "Empty list doesn't contain element at index " + index + '.');
  };
  EmptyList.prototype.iterator_66 = function () {
    return EmptyIterator_getInstance();
  };
  EmptyList.prototype.subList_8 = function (fromIndex, toIndex) {
    if (fromIndex === 0 ? toIndex === 0 : false)
      return this;
    throw IndexOutOfBoundsException_init_$Create$('' + 'fromIndex: ' + fromIndex + ', toIndex: ' + toIndex);
  };
  EmptyList.$metadata$ = {
    simpleName: 'EmptyList',
    kind: 'object',
    interfaces: [List, Serializable, RandomAccess]
  };
  var EmptyList_instance;
  function EmptyList_getInstance() {
    if (EmptyList_instance == null)
      new EmptyList();
    return EmptyList_instance;
  }
  function ArrayAsCollection(values_0, isVarargs) {
    this._values = values_0;
    this._isVarargs = isVarargs;
  }
  ArrayAsCollection.prototype._get_size__52 = function () {
    return this._values.length;
  };
  ArrayAsCollection.prototype.isEmpty_44 = function () {
    var tmp0_isEmpty_0 = this._values;
    return tmp0_isEmpty_0.length === 0;
  };
  ArrayAsCollection.prototype.contains_44 = function (element) {
    return contains_2(this._values, element);
  };
  ArrayAsCollection.prototype.contains_51 = function (element) {
    if (!(element == null ? true : isObject(element)))
      return false;
    else {
    }
    return this.contains_44((element == null ? true : isObject(element)) ? element : THROW_CCE());
  };
  ArrayAsCollection.prototype.containsAll_32 = function (elements) {
    var tmp$ret$0;
    l$ret$1: do {
      var tmp;
      if (isInterface(elements, Collection)) {
        tmp = elements.isEmpty_44();
      } else {
        {
          tmp = false;
        }
      }
      if (tmp) {
        tmp$ret$0 = true;
        break l$ret$1;
      } else {
      }
      var tmp0_iterator_1 = elements.iterator_66();
      while (tmp0_iterator_1.hasNext_41()) {
        var element_2 = tmp0_iterator_1.next_46();
        if (!this.contains_44(element_2)) {
          tmp$ret$0 = false;
          break l$ret$1;
        } else {
        }
      }
      tmp$ret$0 = true;
    }
     while (false);
    return tmp$ret$0;
  };
  ArrayAsCollection.prototype.containsAll_36 = function (elements) {
    return this.containsAll_32(elements);
  };
  ArrayAsCollection.prototype.iterator_66 = function () {
    return arrayIterator(this._values);
  };
  ArrayAsCollection.$metadata$ = {
    simpleName: 'ArrayAsCollection',
    kind: 'class',
    interfaces: [Collection]
  };
  function EmptyIterator() {
    EmptyIterator_instance = this;
  }
  EmptyIterator.prototype.hasNext_41 = function () {
    return false;
  };
  EmptyIterator.prototype.next_46 = function () {
    throw NoSuchElementException_init_$Create$();
  };
  EmptyIterator.$metadata$ = {
    simpleName: 'EmptyIterator',
    kind: 'object',
    interfaces: [ListIterator]
  };
  var EmptyIterator_instance;
  function EmptyIterator_getInstance() {
    if (EmptyIterator_instance == null)
      new EmptyIterator();
    return EmptyIterator_instance;
  }
  function asCollection(_this_) {
    return new ArrayAsCollection(_this_, false);
  }
  function mutableListOf(elements) {
    return elements.length === 0 ? ArrayList_init_$Create$() : ArrayList_init_$Create$_1(new ArrayAsCollection(elements, true));
  }
  function throwCountOverflow() {
    throw ArithmeticException_init_$Create$('Count overflow has happened.');
  }
  function listOfNotNull(elements) {
    return filterNotNull(elements);
  }
  function flatten(_this_) {
    var result = ArrayList_init_$Create$();
    var tmp0_iterator = _this_.iterator_66();
    while (tmp0_iterator.hasNext_41()) {
      var element = tmp0_iterator.next_46();
      addAll_0(result, element);
      Unit_getInstance();
    }
    return result;
  }
  function collectionSizeOrDefault(_this_, default_0) {
    var tmp;
    if (isInterface(_this_, Collection)) {
      tmp = _this_._get_size__52();
    } else {
      {
        tmp = default_0;
      }
    }
    return tmp;
  }
  function convertToSetForSetOperationWith(_this_, source) {
    var tmp0_subject = _this_;
    var tmp;
    if (isInterface(tmp0_subject, Set)) {
      tmp = _this_;
    } else {
      if (isInterface(tmp0_subject, Collection)) {
        var tmp_0;
        var tmp_1;
        if (isInterface(source, Collection)) {
          tmp_1 = source._get_size__52() < 2;
        } else {
          {
            tmp_1 = false;
          }
        }
        if (tmp_1) {
          tmp_0 = _this_;
        } else {
          {
            tmp_0 = safeToConvertToSet(_this_) ? toHashSet(_this_) : _this_;
          }
        }
        tmp = tmp_0;
      } else {
        {
          tmp = toHashSet(_this_);
        }
      }
    }
    return tmp;
  }
  function safeToConvertToSet(_this_) {
    var tmp;
    if (_this_._get_size__52() > 2) {
      tmp = _this_ instanceof ArrayList;
    } else {
      tmp = false;
    }
    return tmp;
  }
  function mapOf(pairs) {
    return pairs.length > 0 ? toMap_0(pairs, LinkedHashMap_init_$Create$_1(mapCapacity(pairs.length))) : emptyMap();
  }
  function emptyMap() {
    var tmp = EmptyMap_getInstance();
    return isInterface(tmp, Map_0) ? tmp : THROW_CCE();
  }
  function toMap(_this_) {
    if (isInterface(_this_, Collection)) {
      var tmp0_subject = _this_._get_size__52();
      var tmp;
      switch (tmp0_subject) {
        case 0:
          tmp = emptyMap();
          break;
        case 1:
          var tmp_0;
          if (isInterface(_this_, List)) {
            tmp_0 = _this_.get_72(0);
          } else {
            {
              tmp_0 = _this_.iterator_66().next_46();
            }
          }

          tmp = mapOf_0(tmp_0);
          break;
        default:tmp = toMap_1(_this_, LinkedHashMap_init_$Create$_1(mapCapacity(_this_._get_size__52())));
          break;
      }
      return tmp;
    } else {
    }
    return optimizeReadOnlyMap(toMap_1(_this_, LinkedHashMap_init_$Create$()));
  }
  function toMap_0(_this_, destination) {
    putAll(destination, _this_);
    return destination;
  }
  function EmptyMap() {
    EmptyMap_instance = this;
    this._serialVersionUID_0 = new Long(-888910638, 1920087921);
  }
  EmptyMap.prototype.equals = function (other) {
    var tmp;
    if (!(other == null) ? isInterface(other, Map_0) : false) {
      tmp = other.isEmpty_44();
    } else {
      {
        tmp = false;
      }
    }
    return tmp;
  };
  EmptyMap.prototype.hashCode = function () {
    return 0;
  };
  EmptyMap.prototype.toString = function () {
    return '{}';
  };
  EmptyMap.prototype._get_size__52 = function () {
    return 0;
  };
  EmptyMap.prototype.isEmpty_44 = function () {
    return true;
  };
  EmptyMap.prototype.containsKey_0 = function (key_0) {
    return false;
  };
  EmptyMap.prototype.containsKey_7 = function (key_0) {
    if (!(key_0 == null ? true : isObject(key_0)))
      return false;
    else {
    }
    return this.containsKey_0((key_0 == null ? true : isObject(key_0)) ? key_0 : THROW_CCE());
  };
  EmptyMap.prototype.get_4 = function (key_0) {
    return null;
  };
  EmptyMap.prototype.get_56 = function (key_0) {
    if (!(key_0 == null ? true : isObject(key_0)))
      return null;
    else {
    }
    return this.get_4((key_0 == null ? true : isObject(key_0)) ? key_0 : THROW_CCE());
  };
  EmptyMap.prototype._get_entries__5 = function () {
    return EmptySet_getInstance();
  };
  EmptyMap.prototype._get_values__6 = function () {
    return EmptyList_getInstance();
  };
  EmptyMap.$metadata$ = {
    simpleName: 'EmptyMap',
    kind: 'object',
    interfaces: [Map_0, Serializable]
  };
  var EmptyMap_instance;
  function EmptyMap_getInstance() {
    if (EmptyMap_instance == null)
      new EmptyMap();
    return EmptyMap_instance;
  }
  function toMap_1(_this_, destination) {
    putAll_0(destination, _this_);
    return destination;
  }
  function optimizeReadOnlyMap(_this_) {
    var tmp0_subject = _this_._get_size__52();
    var tmp;
    switch (tmp0_subject) {
      case 0:
        tmp = emptyMap();
        break;
      case 1:
        tmp = _this_;
        break;
      default:tmp = _this_;
        break;
    }
    return tmp;
  }
  function putAll(_this_, pairs) {
    var indexedObject = pairs;
    var inductionVariable = 0;
    var last_2 = indexedObject.length;
    while (inductionVariable < last_2) {
      var tmp1_loop_parameter = indexedObject[inductionVariable];
      inductionVariable = inductionVariable + 1 | 0;
      var key_0 = tmp1_loop_parameter.component1_4();
      var value_0 = tmp1_loop_parameter.component2_4();
      _this_.put_6(key_0, value_0);
      Unit_getInstance();
    }
  }
  function putAll_0(_this_, pairs) {
    var tmp0_iterator = pairs.iterator_66();
    while (tmp0_iterator.hasNext_41()) {
      var tmp1_loop_parameter = tmp0_iterator.next_46();
      var key_0 = tmp1_loop_parameter.component1_4();
      var value_0 = tmp1_loop_parameter.component2_4();
      _this_.put_6(key_0, value_0);
      Unit_getInstance();
    }
  }
  function hashMapOf(pairs) {
    var tmp0_apply_0 = HashMap_init_$Create$_1(mapCapacity(pairs.length));
    putAll(tmp0_apply_0, pairs);
    return tmp0_apply_0;
  }
  function toMutableMap(_this_) {
    return LinkedHashMap_init_$Create$_2(_this_);
  }
  function addAll(_this_, elements) {
    return _this_.addAll_14(asList(elements));
  }
  function removeAll(_this_, predicate) {
    return filterInPlace(_this_, predicate, true);
  }
  function addAll_0(_this_, elements) {
    var tmp0_subject = elements;
    if (isInterface(tmp0_subject, Collection))
      return _this_.addAll_14(elements);
    else {
      {
        var result = false;
        var tmp1_iterator = elements.iterator_66();
        while (tmp1_iterator.hasNext_41()) {
          var item = tmp1_iterator.next_46();
          if (_this_.add_39(item))
            result = true;
        }
        return result;
      }
    }
  }
  function filterInPlace(_this_, predicate, predicateResultToRemove) {
    var result = false;
    var tmp0_with_0 = _this_.iterator_66();
    while (tmp0_with_0.hasNext_41())
      if (predicate(tmp0_with_0.next_46()) === predicateResultToRemove) {
        tmp0_with_0.remove_22();
        result = true;
      }return result;
  }
  function removeAll_0(_this_, predicate) {
    return filterInPlace_0(_this_, predicate, true);
  }
  function filterInPlace_0(_this_, predicate, predicateResultToRemove) {
    if (!isInterface(_this_, RandomAccess)) {
      return filterInPlace(isInterface(_this_, MutableIterable) ? _this_ : THROW_CCE(), predicate, predicateResultToRemove);
    } else {
    }
    var writeIndex = 0;
    var inductionVariable = 0;
    var last_2 = _get_lastIndex__2(_this_);
    if (inductionVariable <= last_2)
      do {
        var readIndex = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        var element = _this_.get_72(readIndex);
        if (predicate(element) === predicateResultToRemove)
          continue;
        if (!(writeIndex === readIndex)) {
          _this_.set_3(writeIndex, element);
          Unit_getInstance();
        }var tmp1 = writeIndex;
        writeIndex = tmp1 + 1 | 0;
        Unit_getInstance();
      }
       while (!(readIndex === last_2));
    if (writeIndex < _this_._get_size__52()) {
      var inductionVariable_0 = _get_lastIndex__2(_this_);
      if (writeIndex <= inductionVariable_0)
        do {
          var removeIndex = inductionVariable_0;
          inductionVariable_0 = inductionVariable_0 + -1 | 0;
          _this_.removeAt_2(removeIndex);
          Unit_getInstance();
        }
         while (!(removeIndex === writeIndex));
      return true;
    } else {
      return false;
    }
  }
  function removeAll_1(_this_, elements) {
    var tmp0_removeAll_0 = convertToSetForSetOperationWith(elements, _this_);
    return (isInterface(_this_, MutableCollection) ? _this_ : THROW_CCE()).removeAll_12(tmp0_removeAll_0);
  }
  function retainAll(_this_, elements) {
    var tmp0_retainAll_0 = convertToSetForSetOperationWith(elements, _this_);
    return (isInterface(_this_, MutableCollection) ? _this_ : THROW_CCE()).retainAll_11(tmp0_retainAll_0);
  }
  function Sequence() {
  }
  Sequence.$metadata$ = {
    simpleName: 'Sequence',
    kind: 'interface',
    interfaces: []
  };
  function sequence(block) {
    return new _no_name_provided__1_2(block);
  }
  function SequenceScope() {
  }
  SequenceScope.prototype.yieldAll_2 = function (sequence_0, $cont) {
    return this.yieldAll_1(sequence_0.iterator_66(), $cont);
  };
  SequenceScope.$metadata$ = {
    simpleName: 'SequenceScope',
    kind: 'class',
    interfaces: []
  };
  function iterator(block) {
    var iterator_0 = new SequenceBuilderIterator();
    iterator_0._nextStep = createCoroutineUnintercepted(block, iterator_0, iterator_0);
    return iterator_0;
  }
  function nextNotReady($this) {
    if (!$this.hasNext_41())
      throw NoSuchElementException_init_$Create$();
    else
      return $this.next_46();
  }
  function exceptionalState($this) {
    var tmp0_subject = $this._state_0;
    switch (tmp0_subject) {
      case 4:
        return NoSuchElementException_init_$Create$();
      case 5:
        return IllegalStateException_init_$Create$_0('Iterator has failed.');
      default:return IllegalStateException_init_$Create$_0('' + 'Unexpected state of the iterator: ' + $this._state_0);
    }
  }
  function SequenceBuilderIterator() {
    SequenceScope.call(this);
    this._state_0 = 0;
    this._nextValue_0 = null;
    this._nextIterator = null;
    this._nextStep = null;
  }
  SequenceBuilderIterator.prototype.hasNext_41 = function () {
    while (true) {
      var tmp0_subject = this._state_0;
      switch (tmp0_subject) {
        case 0:
          break;
        case 1:
          if (ensureNotNull(this._nextIterator).hasNext_41()) {
            this._state_0 = 2;
            return true;
          } else {
            this._nextIterator = null;
          }

          break;
        case 4:
          return false;
        case 3:
        case 2:
          return true;
        default:throw exceptionalState(this);
      }
      this._state_0 = 5;
      var step_0 = ensureNotNull(this._nextStep);
      this._nextStep = null;
      var tmp1_resume_0 = Unit_getInstance();
      var tmp0_success_0 = Companion_getInstance_2();
      step_0.resumeWith_21(_Result___init__impl_(Unit_getInstance()));
    }
  };
  SequenceBuilderIterator.prototype.next_46 = function () {
    var tmp0_subject = this._state_0;
    switch (tmp0_subject) {
      case 0:
      case 1:
        return nextNotReady(this);
      case 2:
        this._state_0 = 1;
        return ensureNotNull(this._nextIterator).next_46();
      case 3:
        this._state_0 = 0;
        var tmp = this._nextValue_0;
        var result = (tmp == null ? true : isObject(tmp)) ? tmp : THROW_CCE();
        this._nextValue_0 = null;
        return result;
      default:throw exceptionalState(this);
    }
  };
  SequenceBuilderIterator.prototype.yield_0 = function (value_0, $cont) {
    this._nextValue_0 = value_0;
    this._state_0 = 3;
    var tmp0__anonymous__1 = $cont;
    this._nextStep = tmp0__anonymous__1;
    return _get_COROUTINE_SUSPENDED_();
  };
  SequenceBuilderIterator.prototype.yieldAll_1 = function (iterator_0, $cont) {
    if (!iterator_0.hasNext_41())
      return Unit_getInstance();
    this._nextIterator = iterator_0;
    this._state_0 = 2;
    var tmp0__anonymous__1 = $cont;
    this._nextStep = tmp0__anonymous__1;
    return _get_COROUTINE_SUSPENDED_();
  };
  SequenceBuilderIterator.prototype.resumeWith_10 = function (result) {
    throwOnFailure(result);
    var tmp = _Result___get_value__impl_(result);
    if (tmp == null ? true : isObject(tmp))
      tmp;
    else
      THROW_CCE();
    this._state_0 = 4;
  };
  SequenceBuilderIterator.prototype.resumeWith_21 = function (result) {
    return this.resumeWith_10(result);
  };
  SequenceBuilderIterator.prototype._get_context__31 = function () {
    return EmptyCoroutineContext_getInstance();
  };
  SequenceBuilderIterator.$metadata$ = {
    simpleName: 'SequenceBuilderIterator',
    kind: 'class',
    interfaces: [Iterator_2, Continuation]
  };
  function _no_name_provided__1_2($block) {
    this._$block = $block;
  }
  _no_name_provided__1_2.prototype.iterator_2_2 = function () {
    return iterator(this._$block);
  };
  _no_name_provided__1_2.prototype.iterator_66 = function () {
    return this.iterator_2_2();
  };
  _no_name_provided__1_2.$metadata$ = {
    simpleName: '<no name provided>_1',
    kind: 'class',
    interfaces: [Sequence]
  };
  function flatten_0(_this_) {
    return flatten_2(_this_, _no_name_provided_$factory_2());
  }
  function sequenceOf(elements) {
    var tmp;
    if (elements.length === 0) {
      tmp = emptySequence();
    } else {
      {
        tmp = asSequence(elements);
      }
    }
    return tmp;
  }
  function flatten_1(_this_) {
    return flatten_2(_this_, _no_name_provided_$factory_3());
  }
  function _no_name_provided__5(this$0) {
    this._this$0_2 = this$0;
    this._iterator = this._this$0_2._sequence.iterator_66();
  }
  _no_name_provided__5.prototype.next_46 = function () {
    return this._this$0_2._transformer(this._iterator.next_46());
  };
  _no_name_provided__5.prototype.hasNext_41 = function () {
    return this._iterator.hasNext_41();
  };
  _no_name_provided__5.$metadata$ = {
    kind: 'class',
    interfaces: [Iterator_2]
  };
  function TransformingSequence(sequence_0, transformer) {
    this._sequence = sequence_0;
    this._transformer = transformer;
  }
  TransformingSequence.prototype.iterator_66 = function () {
    return new _no_name_provided__5(this);
  };
  TransformingSequence.prototype.flatten_0 = function (iterator_0) {
    return new FlatteningSequence(this._sequence, this._transformer, iterator_0);
  };
  TransformingSequence.$metadata$ = {
    simpleName: 'TransformingSequence',
    kind: 'class',
    interfaces: [Sequence]
  };
  function DropTakeSequence() {
  }
  DropTakeSequence.$metadata$ = {
    simpleName: 'DropTakeSequence',
    kind: 'interface',
    interfaces: [Sequence]
  };
  function drop_3($this) {
    while ($this._left > 0 ? $this._iterator_0.hasNext_41() : false) {
      $this._iterator_0.next_46();
      Unit_getInstance();
      var tmp0_this = $this;
      var tmp1 = tmp0_this._left;
      tmp0_this._left = tmp1 - 1 | 0;
      Unit_getInstance();
    }
  }
  function _no_name_provided__6(this$0) {
    this._this$0_3 = this$0;
    this._iterator_0 = this._this$0_3._sequence_0.iterator_66();
    this._left = this._this$0_3._count;
  }
  _no_name_provided__6.prototype.next_46 = function () {
    drop_3(this);
    return this._iterator_0.next_46();
  };
  _no_name_provided__6.prototype.hasNext_41 = function () {
    drop_3(this);
    return this._iterator_0.hasNext_41();
  };
  _no_name_provided__6.$metadata$ = {
    kind: 'class',
    interfaces: [Iterator_2]
  };
  function DropSequence(sequence_0, count_0) {
    this._sequence_0 = sequence_0;
    this._count = count_0;
    var tmp0_require_0 = this._count >= 0;
    if (!tmp0_require_0) {
      var message_2 = '' + 'count must be non-negative, but was ' + this._count + '.';
      throw IllegalArgumentException_init_$Create$_0(toString_2(message_2));
    }}
  DropSequence.prototype.drop_1 = function (n) {
    var tmp0_let_0 = this._count + n | 0;
    return tmp0_let_0 < 0 ? new DropSequence(this, n) : new DropSequence(this._sequence_0, tmp0_let_0);
  };
  DropSequence.prototype.iterator_66 = function () {
    return new _no_name_provided__6(this);
  };
  DropSequence.$metadata$ = {
    simpleName: 'DropSequence',
    kind: 'class',
    interfaces: [Sequence, DropTakeSequence]
  };
  function _no_name_provided__7(this$0) {
    this._this$0_4 = this$0;
    this._iterator1 = this._this$0_4._sequence1.iterator_66();
    this._iterator2 = this._this$0_4._sequence2.iterator_66();
  }
  _no_name_provided__7.prototype.next_46 = function () {
    return this._this$0_4._transform(this._iterator1.next_46(), this._iterator2.next_46());
  };
  _no_name_provided__7.prototype.hasNext_41 = function () {
    return this._iterator1.hasNext_41() ? this._iterator2.hasNext_41() : false;
  };
  _no_name_provided__7.$metadata$ = {
    kind: 'class',
    interfaces: [Iterator_2]
  };
  function MergingSequence(sequence1, sequence2, transform) {
    this._sequence1 = sequence1;
    this._sequence2 = sequence2;
    this._transform = transform;
  }
  MergingSequence.prototype.iterator_66 = function () {
    return new _no_name_provided__7(this);
  };
  MergingSequence.$metadata$ = {
    simpleName: 'MergingSequence',
    kind: 'class',
    interfaces: [Sequence]
  };
  function flatten_2(_this_, iterator_0) {
    if (_this_ instanceof TransformingSequence) {
      return (_this_ instanceof TransformingSequence ? _this_ : THROW_CCE()).flatten_0(iterator_0);
    } else {
    }
    return new FlatteningSequence(_this_, _no_name_provided_$factory_4(), iterator_0);
  }
  function emptySequence() {
    return EmptySequence_getInstance();
  }
  function ensureItemIterator($this) {
    var tmp0_safe_receiver = $this._itemIterator;
    if ((tmp0_safe_receiver == null ? null : tmp0_safe_receiver.hasNext_41()) === false)
      $this._itemIterator = null;
    else {
    }
    while ($this._itemIterator == null) {
      if (!$this._iterator_1.hasNext_41()) {
        return false;
      } else {
        var element = $this._iterator_1.next_46();
        var nextItemIterator = $this._this$0_5._iterator_2($this._this$0_5._transformer_0(element));
        if (nextItemIterator.hasNext_41()) {
          $this._itemIterator = nextItemIterator;
          return true;
        }}
    }
    return true;
  }
  function _no_name_provided__8(this$0) {
    this._this$0_5 = this$0;
    this._iterator_1 = this._this$0_5._sequence_1.iterator_66();
    this._itemIterator = null;
  }
  _no_name_provided__8.prototype.next_46 = function () {
    if (!ensureItemIterator(this))
      throw NoSuchElementException_init_$Create$();
    return ensureNotNull(this._itemIterator).next_46();
  };
  _no_name_provided__8.prototype.hasNext_41 = function () {
    return ensureItemIterator(this);
  };
  _no_name_provided__8.$metadata$ = {
    kind: 'class',
    interfaces: [Iterator_2]
  };
  function FlatteningSequence(sequence_0, transformer, iterator_0) {
    this._sequence_1 = sequence_0;
    this._transformer_0 = transformer;
    this._iterator_2 = iterator_0;
  }
  FlatteningSequence.prototype.iterator_66 = function () {
    return new _no_name_provided__8(this);
  };
  FlatteningSequence.$metadata$ = {
    simpleName: 'FlatteningSequence',
    kind: 'class',
    interfaces: [Sequence]
  };
  function EmptySequence() {
    EmptySequence_instance = this;
  }
  EmptySequence.prototype.iterator_66 = function () {
    return EmptyIterator_getInstance();
  };
  EmptySequence.prototype.drop_1 = function (n) {
    return EmptySequence_getInstance();
  };
  EmptySequence.$metadata$ = {
    simpleName: 'EmptySequence',
    kind: 'object',
    interfaces: [Sequence, DropTakeSequence]
  };
  var EmptySequence_instance;
  function EmptySequence_getInstance() {
    if (EmptySequence_instance == null)
      new EmptySequence();
    return EmptySequence_instance;
  }
  function _no_name_provided__9() {
  }
  _no_name_provided__9.prototype.invoke_6 = function (it) {
    return it.iterator_66();
  };
  _no_name_provided__9.prototype.invoke_1115 = function (p1) {
    return this.invoke_6((!(p1 == null) ? isInterface(p1, Iterable) : false) ? p1 : THROW_CCE());
  };
  _no_name_provided__9.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__10() {
  }
  _no_name_provided__10.prototype.invoke_8 = function (it) {
    return it.iterator_66();
  };
  _no_name_provided__10.prototype.invoke_1115 = function (p1) {
    return this.invoke_8((!(p1 == null) ? isInterface(p1, Sequence) : false) ? p1 : THROW_CCE());
  };
  _no_name_provided__10.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__11() {
  }
  _no_name_provided__11.prototype.invoke_10 = function (it) {
    return it;
  };
  _no_name_provided__11.prototype.invoke_1115 = function (p1) {
    return this.invoke_10((p1 == null ? true : isObject(p1)) ? p1 : THROW_CCE());
  };
  _no_name_provided__11.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided_$factory_2() {
    var i = new _no_name_provided__9();
    return function (p1) {
      return i.invoke_6(p1);
    };
  }
  function _no_name_provided_$factory_3() {
    var i = new _no_name_provided__10();
    return function (p1) {
      return i.invoke_8(p1);
    };
  }
  function _no_name_provided_$factory_4() {
    var i = new _no_name_provided__11();
    return function (p1) {
      return i.invoke_10(p1);
    };
  }
  function hashSetOf(elements) {
    return toCollection(elements, HashSet_init_$Create$_0(mapCapacity(elements.length)));
  }
  function EmptySet() {
    EmptySet_instance = this;
    this._serialVersionUID_1 = new Long(1993859828, 793161749);
  }
  EmptySet.prototype.equals = function (other) {
    var tmp;
    if (!(other == null) ? isInterface(other, Set) : false) {
      tmp = other.isEmpty_44();
    } else {
      {
        tmp = false;
      }
    }
    return tmp;
  };
  EmptySet.prototype.hashCode = function () {
    return 0;
  };
  EmptySet.prototype.toString = function () {
    return '[]';
  };
  EmptySet.prototype._get_size__52 = function () {
    return 0;
  };
  EmptySet.prototype.isEmpty_44 = function () {
    return true;
  };
  EmptySet.prototype.contains_10 = function (element) {
    return false;
  };
  EmptySet.prototype.contains_51 = function (element) {
    if (!false)
      return false;
    else {
    }
    var tmp;
    if (false) {
      tmp = element;
    } else {
      {
        tmp = THROW_CCE();
      }
    }
    return this.contains_10(tmp);
  };
  EmptySet.prototype.containsAll_8 = function (elements) {
    return elements.isEmpty_44();
  };
  EmptySet.prototype.containsAll_36 = function (elements) {
    return this.containsAll_8(elements);
  };
  EmptySet.prototype.iterator_66 = function () {
    return EmptyIterator_getInstance();
  };
  EmptySet.$metadata$ = {
    simpleName: 'EmptySet',
    kind: 'object',
    interfaces: [Set, Serializable]
  };
  var EmptySet_instance;
  function EmptySet_getInstance() {
    if (EmptySet_instance == null)
      new EmptySet();
    return EmptySet_instance;
  }
  function emptySet() {
    return EmptySet_getInstance();
  }
  function optimizeReadOnlySet(_this_) {
    var tmp0_subject = _this_._get_size__52();
    switch (tmp0_subject) {
      case 0:
        return emptySet();
      case 1:
        return setOf(_this_.iterator_66().next_46());
      default:return _this_;
    }
  }
  function checkWindowSizeStep(size_0, step_0) {
    var tmp0_require_0 = size_0 > 0 ? step_0 > 0 : false;
    if (!tmp0_require_0) {
      var message_2 = !(size_0 === step_0) ? '' + 'Both size ' + size_0 + ' and step ' + step_0 + ' must be greater than zero.' : '' + 'size ' + size_0 + ' must be greater than zero.';
      throw IllegalArgumentException_init_$Create$_0(toString_2(message_2));
    }}
  function windowedIterator(iterator_0, size_0, step_0, partialWindows, reuseBuffer) {
    if (!iterator_0.hasNext_41())
      return EmptyIterator_getInstance();
    return iterator(_no_name_provided_$factory_5(size_0, step_0, iterator_0, reuseBuffer, partialWindows, null));
  }
  function RingBuffer_init_$Init$(capacity, $this) {
    RingBuffer.call($this, fillArrayVal(Array(capacity), null), 0);
    return $this;
  }
  function RingBuffer_init_$Create$(capacity) {
    return RingBuffer_init_$Init$(capacity, Object.create(RingBuffer.prototype));
  }
  function _no_name_provided__12(this$0) {
    this._this$0_6 = this$0;
    AbstractIterator.call(this);
    this._count_0 = this._this$0_6._size;
    this._index_0 = this._this$0_6._startIndex;
  }
  _no_name_provided__12.prototype.computeNext_0 = function () {
    if (this._count_0 === 0) {
      this.done_0();
    } else {
      var tmp = this._this$0_6._buffer[this._index_0];
      this.setNext_0((tmp == null ? true : isObject(tmp)) ? tmp : THROW_CCE());
      var tmp_0 = this;
      var tmp0_forward_0 = this._index_0;
      var tmp1_forward_0 = 1;
      tmp_0._index_0 = (tmp0_forward_0 + tmp1_forward_0 | 0) % this._this$0_6._capacity;
      var tmp0_this = this;
      var tmp1 = tmp0_this._count_0;
      tmp0_this._count_0 = tmp1 - 1 | 0;
      Unit_getInstance();
    }
  };
  _no_name_provided__12.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function RingBuffer(buffer, filledSize) {
    AbstractList.call(this);
    this._buffer = buffer;
    var tmp0_require_0 = filledSize >= 0;
    if (!tmp0_require_0) {
      var message_2 = '' + 'ring buffer filled size should not be negative but it is ' + filledSize;
      throw IllegalArgumentException_init_$Create$_0(toString_2(message_2));
    }var tmp1_require_0 = filledSize <= this._buffer.length;
    if (!tmp1_require_0) {
      var message_2_0 = '' + 'ring buffer filled size: ' + filledSize + ' cannot be larger than the buffer size: ' + this._buffer.length;
      throw IllegalArgumentException_init_$Create$_0(toString_2(message_2_0));
    }this._capacity = this._buffer.length;
    this._startIndex = 0;
    this._size = filledSize;
  }
  RingBuffer.prototype._get_size__52 = function () {
    return this._size;
  };
  RingBuffer.prototype.get_72 = function (index) {
    Companion_getInstance().checkElementIndex(index, this._size);
    var tmp0_forward_0 = this._startIndex;
    var tmp = this._buffer[(tmp0_forward_0 + index | 0) % this._capacity];
    return (tmp == null ? true : isObject(tmp)) ? tmp : THROW_CCE();
  };
  RingBuffer.prototype.isFull = function () {
    return this._size === this._capacity;
  };
  RingBuffer.prototype.iterator_66 = function () {
    return new _no_name_provided__12(this);
  };
  RingBuffer.prototype.toArray_0 = function (array) {
    var tmp;
    if (array.length < this._size) {
      tmp = copyOf_1(array, this._size);
    } else {
      tmp = isArray(array) ? array : THROW_CCE();
    }
    var result = tmp;
    var size_0 = this._size;
    var widx = 0;
    var idx = this._startIndex;
    while (widx < size_0 ? idx < this._capacity : false) {
      var tmp_0 = widx;
      var tmp_1 = this._buffer[idx];
      result[tmp_0] = (tmp_1 == null ? true : isObject(tmp_1)) ? tmp_1 : THROW_CCE();
      var tmp0 = widx;
      widx = tmp0 + 1 | 0;
      Unit_getInstance();
      var tmp1 = idx;
      idx = tmp1 + 1 | 0;
      Unit_getInstance();
    }
    idx = 0;
    while (widx < size_0) {
      var tmp_2 = widx;
      var tmp_3 = this._buffer[idx];
      result[tmp_2] = (tmp_3 == null ? true : isObject(tmp_3)) ? tmp_3 : THROW_CCE();
      var tmp2 = widx;
      widx = tmp2 + 1 | 0;
      Unit_getInstance();
      var tmp3 = idx;
      idx = tmp3 + 1 | 0;
      Unit_getInstance();
    }
    if (result.length > this._size)
      result[this._size] = null;
    return isArray(result) ? result : THROW_CCE();
  };
  RingBuffer.prototype.toArray_3 = function () {
    var tmp0_arrayOfNulls_0 = this._size;
    return this.toArray_0(fillArrayVal(Array(tmp0_arrayOfNulls_0), null));
  };
  RingBuffer.prototype.toArray = function () {
    return this.toArray_3();
  };
  RingBuffer.prototype.expanded = function (maxCapacity) {
    var newCapacity = coerceAtMost((this._capacity + (this._capacity >> 1) | 0) + 1 | 0, maxCapacity);
    var tmp;
    if (this._startIndex === 0) {
      tmp = copyOf_1(this._buffer, newCapacity);
    } else {
      tmp = this.toArray_0(fillArrayVal(Array(newCapacity), null));
    }
    var newBuffer = tmp;
    return new RingBuffer(newBuffer, this._size);
  };
  RingBuffer.prototype.add_0 = function (element) {
    if (this.isFull()) {
      throw IllegalStateException_init_$Create$_0('ring buffer is full');
    }var tmp0_forward_0 = this._startIndex;
    var tmp1_forward_0 = this._size;
    this._buffer[(tmp0_forward_0 + tmp1_forward_0 | 0) % this._capacity] = element;
    var tmp0_this = this;
    var tmp1 = tmp0_this._size;
    tmp0_this._size = tmp1 + 1 | 0;
    Unit_getInstance();
  };
  RingBuffer.prototype.removeFirst = function (n) {
    var tmp0_require_0 = n >= 0;
    if (!tmp0_require_0) {
      var message_2 = '' + "n shouldn't be negative but it is " + n;
      throw IllegalArgumentException_init_$Create$_0(toString_2(message_2));
    }var tmp1_require_0 = n <= this._size;
    if (!tmp1_require_0) {
      var message_2_0 = '' + "n shouldn't be greater than the buffer size: n = " + n + ', size = ' + this._size;
      throw IllegalArgumentException_init_$Create$_0(toString_2(message_2_0));
    }if (n > 0) {
      var start_0 = this._startIndex;
      var end_0 = (start_0 + n | 0) % this._capacity;
      if (start_0 > end_0) {
        fill(this._buffer, null, start_0, this._capacity);
        fill(this._buffer, null, 0, end_0);
      } else {
        fill(this._buffer, null, start_0, end_0);
      }
      this._startIndex = end_0;
      var tmp0_this = this;
      tmp0_this._size = tmp0_this._size - n | 0;
    }};
  RingBuffer.$metadata$ = {
    simpleName: 'RingBuffer',
    kind: 'class',
    interfaces: [RandomAccess]
  };
  function _no_name_provided__13($size, $step, $iterator, $reuseBuffer, $partialWindows, resultContinuation) {
    this._$size = $size;
    this._$step = $step;
    this._$iterator = $iterator;
    this._$reuseBuffer = $reuseBuffer;
    this._$partialWindows = $partialWindows;
    CoroutineImpl_0.call(this, resultContinuation);
  }
  _no_name_provided__13.prototype.invoke_12 = function ($this$iterator, $cont) {
    var tmp = this.create($this$iterator, $cont);
    tmp._result_0 = Unit_getInstance();
    tmp._exception_0 = null;
    return tmp.doResume_17();
  };
  _no_name_provided__13.prototype.doResume_17 = function () {
    var suspendResult = this._result_0;
    $sm: do
      try {
        var tmp = this._state_2;
        switch (tmp) {
          case 0:
            this._exceptionState = 21;
            this._bufferInitialCapacity0 = coerceAtMost(this._$size, 1024);
            this._gap1 = this._$step - this._$size | 0;
            if (this._gap1 >= 0) {
              this._buffer2 = ArrayList_init_$Create$_0(this._bufferInitialCapacity0);
              this._skip3 = 0;
              var tmp_0 = this;
              tmp_0._tmp0_iterator4 = this._$iterator;
              this._state_2 = 12;
              continue $sm;
            } else {
              this._buffer5 = RingBuffer_init_$Create$(this._bufferInitialCapacity0);
              var tmp_1 = this;
              tmp_1._tmp1_iterator6 = this._$iterator;
              this._state_2 = 1;
              continue $sm;
            }

            break;
          case 1:
            if (!this._tmp1_iterator6.hasNext_41()) {
              this._state_2 = 5;
              continue $sm;
            }
            this._e7 = this._tmp1_iterator6.next_46();
            this._buffer5.add_0(this._e7);
            if (this._buffer5.isFull()) {
              if (this._buffer5._size < this._$size) {
                this._buffer5 = this._buffer5.expanded(this._$size);
                this._state_2 = 1;
                continue $sm;
              } else {
                this._state_2 = 2;
                continue $sm;
              }
            } else {
              this._state_2 = 4;
              continue $sm;
            }

            break;
          case 2:
            this._state_2 = 3;
            suspendResult = this._$this$iterator.yield_0(this._$reuseBuffer ? this._buffer5 : ArrayList_init_$Create$_1(this._buffer5), this);
            if (suspendResult === _get_COROUTINE_SUSPENDED_()) {
              return suspendResult;
            }
            continue $sm;
          case 3:
            suspendResult;
            this._buffer5.removeFirst(this._$step);
            this._state_2 = 4;
            continue $sm;
          case 4:
            this._state_2 = 1;
            continue $sm;
          case 5:
            if (this._$partialWindows) {
              this._state_2 = 6;
              continue $sm;
            } else {
              this._state_2 = 11;
              continue $sm;
            }

            break;
          case 6:
            if (!(this._buffer5._size > this._$step)) {
              this._state_2 = 8;
              continue $sm;
            }
            this._state_2 = 7;
            suspendResult = this._$this$iterator.yield_0(this._$reuseBuffer ? this._buffer5 : ArrayList_init_$Create$_1(this._buffer5), this);
            if (suspendResult === _get_COROUTINE_SUSPENDED_()) {
              return suspendResult;
            }
            continue $sm;
          case 7:
            suspendResult;
            this._buffer5.removeFirst(this._$step);
            this._state_2 = 6;
            continue $sm;
          case 8:
            var tmp_2 = this;
            tmp_2._tmp1_isNotEmpty_08 = this._buffer5;
            if (!this._tmp1_isNotEmpty_08.isEmpty_44()) {
              this._state_2 = 9;
              suspendResult = this._$this$iterator.yield_0(this._buffer5, this);
              if (suspendResult === _get_COROUTINE_SUSPENDED_()) {
                return suspendResult;
              }continue $sm;
            } else {
              {
                this._state_2 = 10;
                continue $sm;
              }
            }

            break;
          case 9:
            suspendResult;
            this._state_2 = 10;
            continue $sm;
          case 10:
            this._state_2 = 11;
            continue $sm;
          case 11:
            this._state_2 = 20;
            continue $sm;
          case 12:
            if (!this._tmp0_iterator4.hasNext_41()) {
              this._state_2 = 16;
              continue $sm;
            }
            this._e9 = this._tmp0_iterator4.next_46();
            if (this._skip3 > 0) {
              this._skip3 = this._skip3 - 1 | 0;
              this._state_2 = 12;
              continue $sm;
            } else {
              this._state_2 = 13;
              continue $sm;
            }

            break;
          case 13:
            this._buffer2.add_39(this._e9);
            Unit_getInstance();
            if (this._buffer2._get_size__52() === this._$size) {
              this._state_2 = 14;
              suspendResult = this._$this$iterator.yield_0(this._buffer2, this);
              if (suspendResult === _get_COROUTINE_SUSPENDED_()) {
                return suspendResult;
              }continue $sm;
            } else {
              this._state_2 = 15;
              continue $sm;
            }

            break;
          case 14:
            suspendResult;
            if (this._$reuseBuffer)
              this._buffer2.clear_9();
            else
              this._buffer2 = ArrayList_init_$Create$_0(this._$size);
            this._skip3 = this._gap1;
            this._state_2 = 15;
            continue $sm;
          case 15:
            this._state_2 = 12;
            continue $sm;
          case 16:
            var tmp_3 = this;
            tmp_3._tmp0_isNotEmpty_010 = this._buffer2;
            if (!this._tmp0_isNotEmpty_010.isEmpty_44()) {
              if (this._$partialWindows ? true : this._buffer2._get_size__52() === this._$size) {
                this._state_2 = 17;
                suspendResult = this._$this$iterator.yield_0(this._buffer2, this);
                if (suspendResult === _get_COROUTINE_SUSPENDED_()) {
                  return suspendResult;
                }continue $sm;
              } else {
                this._state_2 = 18;
                continue $sm;
              }
            } else {
              {
                this._state_2 = 19;
                continue $sm;
              }
            }

            break;
          case 17:
            suspendResult;
            this._state_2 = 18;
            continue $sm;
          case 18:
            this._state_2 = 19;
            continue $sm;
          case 19:
            this._state_2 = 20;
            continue $sm;
          case 20:
            return Unit_getInstance();
          case 21:
            throw this._exception_0;
        }
      } catch ($p) {
        if (this._exceptionState === 21) {
          throw $p;
        } else {
          this._state_2 = this._exceptionState;
          this._exception_0 = $p;
        }
      }
     while (true);
  };
  _no_name_provided__13.prototype.create = function ($this$iterator, completion) {
    var i = new _no_name_provided__13(this._$size, this._$step, this._$iterator, this._$reuseBuffer, this._$partialWindows, completion);
    i._$this$iterator = $this$iterator;
    return i;
  };
  _no_name_provided__13.$metadata$ = {
    kind: 'class',
    interfaces: [],
    suspendArity: 1
  };
  function _no_name_provided_$factory_5($size, $step, $iterator, $reuseBuffer, $partialWindows, resultContinuation) {
    var i = new _no_name_provided__13($size, $step, $iterator, $reuseBuffer, $partialWindows, resultContinuation);
    var l = function (p1, $cont) {
      return i.invoke_12(p1, $cont);
    };
    l.$arity = 1;
    return l;
  }
  function compareValues(a, b) {
    if (a === b)
      return 0;
    if (a == null)
      return -1;
    if (b == null)
      return 1;
    return compareTo((!(a == null) ? isComparable(a) : false) ? a : THROW_CCE(), b);
  }
  function Continuation() {
  }
  Continuation.$metadata$ = {
    simpleName: 'Continuation',
    kind: 'interface',
    interfaces: []
  };
  function startCoroutine(_this_, receiver, completion) {
    var tmp0_resume_0 = intercepted(createCoroutineUnintercepted(_this_, receiver, completion));
    var tmp1_resume_0 = Unit_getInstance();
    var tmp0_success_0_1 = Companion_getInstance_2();
    tmp0_resume_0.resumeWith_21(_Result___init__impl_(Unit_getInstance()));
  }
  function Key() {
    Key_instance = this;
  }
  Key.$metadata$ = {
    simpleName: 'Key',
    kind: 'object',
    interfaces: [Key_0]
  };
  var Key_instance;
  function Key_getInstance() {
    if (Key_instance == null)
      new Key();
    return Key_instance;
  }
  function ContinuationInterceptor() {
  }
  ContinuationInterceptor.prototype.releaseInterceptedContinuation_10 = function (continuation) {
  };
  ContinuationInterceptor.prototype.get_62 = function (key_0) {
    if (key_0 instanceof AbstractCoroutineContextKey) {
      var tmp;
      if (key_0.isSubKey_0(this._get_key__35())) {
        var tmp_0 = key_0.tryCast_0(this);
        tmp = (!(tmp_0 == null) ? isInterface(tmp_0, Element_0) : false) ? tmp_0 : null;
      } else {
        tmp = null;
      }
      return tmp;
    } else {
    }
    var tmp_1;
    if (Key_getInstance() === key_0) {
      tmp_1 = isInterface(this, Element_0) ? this : THROW_CCE();
    } else {
      tmp_1 = null;
    }
    return tmp_1;
  };
  ContinuationInterceptor.prototype.minusKey_28 = function (key_0) {
    if (key_0 instanceof AbstractCoroutineContextKey) {
      return (key_0.isSubKey_0(this._get_key__35()) ? !(key_0.tryCast_0(this) == null) : false) ? EmptyCoroutineContext_getInstance() : this;
    } else {
    }
    return Key_getInstance() === key_0 ? EmptyCoroutineContext_getInstance() : this;
  };
  ContinuationInterceptor.$metadata$ = {
    simpleName: 'ContinuationInterceptor',
    kind: 'interface',
    interfaces: [Element_0]
  };
  function Key_0() {
  }
  Key_0.$metadata$ = {
    simpleName: 'Key',
    kind: 'interface',
    interfaces: []
  };
  function Element_0() {
  }
  Element_0.prototype.get_62 = function (key_0) {
    var tmp;
    if (equals_0(this._get_key__35(), key_0)) {
      tmp = isInterface(this, Element_0) ? this : THROW_CCE();
    } else {
      tmp = null;
    }
    return tmp;
  };
  Element_0.prototype.fold_27 = function (initial, operation) {
    return operation(initial, this);
  };
  Element_0.prototype.minusKey_28 = function (key_0) {
    return equals_0(this._get_key__35(), key_0) ? EmptyCoroutineContext_getInstance() : this;
  };
  Element_0.$metadata$ = {
    simpleName: 'Element',
    kind: 'interface',
    interfaces: [CoroutineContext]
  };
  function _no_name_provided__14() {
  }
  _no_name_provided__14.prototype.invoke_13 = function (acc, element) {
    var removed = acc.minusKey_28(element._get_key__35());
    var tmp;
    if (removed === EmptyCoroutineContext_getInstance()) {
      tmp = element;
    } else {
      var interceptor = removed.get_62(Key_getInstance());
      var tmp_0;
      if (interceptor == null) {
        tmp_0 = new CombinedContext(removed, element);
      } else {
        var left = removed.minusKey_28(Key_getInstance());
        tmp_0 = left === EmptyCoroutineContext_getInstance() ? new CombinedContext(element, interceptor) : new CombinedContext(new CombinedContext(left, element), interceptor);
      }
      tmp = tmp_0;
    }
    return tmp;
  };
  _no_name_provided__14.prototype.invoke_915 = function (p1, p2) {
    var tmp = (!(p1 == null) ? isInterface(p1, CoroutineContext) : false) ? p1 : THROW_CCE();
    return this.invoke_13(tmp, (!(p2 == null) ? isInterface(p2, Element_0) : false) ? p2 : THROW_CCE());
  };
  _no_name_provided__14.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function CoroutineContext() {
  }
  CoroutineContext.prototype.plus_43 = function (context) {
    var tmp;
    if (context === EmptyCoroutineContext_getInstance()) {
      tmp = this;
    } else {
      tmp = context.fold_27(this, _no_name_provided_$factory_6());
    }
    return tmp;
  };
  CoroutineContext.$metadata$ = {
    simpleName: 'CoroutineContext',
    kind: 'interface',
    interfaces: []
  };
  function _no_name_provided_$factory_6() {
    var i = new _no_name_provided__14();
    return function (p1, p2) {
      return i.invoke_13(p1, p2);
    };
  }
  function EmptyCoroutineContext() {
    EmptyCoroutineContext_instance = this;
    this._serialVersionUID_2 = new Long(0, 0);
  }
  EmptyCoroutineContext.prototype.get_62 = function (key_0) {
    return null;
  };
  EmptyCoroutineContext.prototype.fold_27 = function (initial, operation) {
    return initial;
  };
  EmptyCoroutineContext.prototype.plus_43 = function (context) {
    return context;
  };
  EmptyCoroutineContext.prototype.minusKey_28 = function (key_0) {
    return this;
  };
  EmptyCoroutineContext.prototype.hashCode = function () {
    return 0;
  };
  EmptyCoroutineContext.prototype.toString = function () {
    return 'EmptyCoroutineContext';
  };
  EmptyCoroutineContext.$metadata$ = {
    simpleName: 'EmptyCoroutineContext',
    kind: 'object',
    interfaces: [CoroutineContext, Serializable]
  };
  var EmptyCoroutineContext_instance;
  function EmptyCoroutineContext_getInstance() {
    if (EmptyCoroutineContext_instance == null)
      new EmptyCoroutineContext();
    return EmptyCoroutineContext_instance;
  }
  function size($this) {
    var cur = $this;
    var size_0 = 2;
    while (true) {
      var tmp = cur._left_0;
      var tmp0_elvis_lhs = tmp instanceof CombinedContext ? tmp : null;
      var tmp_0;
      if (tmp0_elvis_lhs == null) {
        return size_0;
      } else {
        tmp_0 = tmp0_elvis_lhs;
      }
      cur = tmp_0;
      var tmp1 = size_0;
      size_0 = tmp1 + 1 | 0;
      Unit_getInstance();
    }
  }
  function contains_4($this, element) {
    return equals_0($this.get_62(element._get_key__35()), element);
  }
  function containsAll($this, context) {
    var cur = context;
    while (true) {
      if (!contains_4($this, cur._element))
        return false;
      var next = cur._left_0;
      if (next instanceof CombinedContext) {
        cur = next;
      } else {
        {
          return contains_4($this, isInterface(next, Element_0) ? next : THROW_CCE());
        }
      }
    }
  }
  function _no_name_provided__15() {
  }
  _no_name_provided__15.prototype.invoke_15 = function (acc, element) {
    var tmp;
    if (charSequenceLength(acc) === 0) {
      tmp = toString_2(element);
    } else {
      {
        tmp = '' + acc + ', ' + element;
      }
    }
    return tmp;
  };
  _no_name_provided__15.prototype.invoke_915 = function (p1, p2) {
    var tmp = (!(p1 == null) ? typeof p1 === 'string' : false) ? p1 : THROW_CCE();
    return this.invoke_15(tmp, (!(p2 == null) ? isInterface(p2, Element_0) : false) ? p2 : THROW_CCE());
  };
  _no_name_provided__15.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function CombinedContext(left, element) {
    this._left_0 = left;
    this._element = element;
  }
  CombinedContext.prototype.get_62 = function (key_0) {
    var cur = this;
    while (true) {
      var tmp0_safe_receiver = cur._element.get_62(key_0);
      if (tmp0_safe_receiver == null)
        null;
      else {
        return tmp0_safe_receiver;
      }
      Unit_getInstance();
      var next = cur._left_0;
      if (next instanceof CombinedContext) {
        cur = next;
      } else {
        {
          return next.get_62(key_0);
        }
      }
    }
  };
  CombinedContext.prototype.fold_27 = function (initial, operation) {
    return operation(this._left_0.fold_27(initial, operation), this._element);
  };
  CombinedContext.prototype.minusKey_28 = function (key_0) {
    var tmp0_safe_receiver = this._element.get_62(key_0);
    if (tmp0_safe_receiver == null)
      null;
    else {
      return this._left_0;
    }
    Unit_getInstance();
    var newLeft = this._left_0.minusKey_28(key_0);
    return newLeft === this._left_0 ? this : newLeft === EmptyCoroutineContext_getInstance() ? this._element : new CombinedContext(newLeft, this._element);
  };
  CombinedContext.prototype.equals = function (other) {
    var tmp;
    if (this === other) {
      tmp = true;
    } else {
      var tmp_0;
      var tmp_1;
      if (other instanceof CombinedContext) {
        tmp_1 = size(other) === size(this);
      } else {
        {
          tmp_1 = false;
        }
      }
      if (tmp_1) {
        tmp_0 = containsAll(other, this);
      } else {
        {
          tmp_0 = false;
        }
      }
      tmp = tmp_0;
    }
    return tmp;
  };
  CombinedContext.prototype.hashCode = function () {
    return hashCode(this._left_0) + hashCode(this._element) | 0;
  };
  CombinedContext.prototype.toString = function () {
    return '[' + this.fold_27('', _no_name_provided_$factory_7()) + ']';
  };
  CombinedContext.$metadata$ = {
    simpleName: 'CombinedContext',
    kind: 'class',
    interfaces: [CoroutineContext, Serializable]
  };
  function AbstractCoroutineContextKey(baseKey, safeCast) {
    this._safeCast = safeCast;
    var tmp = this;
    var tmp_0;
    if (baseKey instanceof AbstractCoroutineContextKey) {
      tmp_0 = baseKey._topmostKey;
    } else {
      {
        tmp_0 = baseKey;
      }
    }
    tmp._topmostKey = tmp_0;
  }
  AbstractCoroutineContextKey.prototype.tryCast_0 = function (element) {
    return this._safeCast(element);
  };
  AbstractCoroutineContextKey.prototype.isSubKey_0 = function (key_0) {
    return key_0 === this ? true : this._topmostKey === key_0;
  };
  AbstractCoroutineContextKey.$metadata$ = {
    simpleName: 'AbstractCoroutineContextKey',
    kind: 'class',
    interfaces: [Key_0]
  };
  function AbstractCoroutineContextElement(key_0) {
    this._key = key_0;
  }
  AbstractCoroutineContextElement.prototype._get_key__35 = function () {
    return this._key;
  };
  AbstractCoroutineContextElement.$metadata$ = {
    simpleName: 'AbstractCoroutineContextElement',
    kind: 'class',
    interfaces: [Element_0]
  };
  function _no_name_provided_$factory_7() {
    var i = new _no_name_provided__15();
    return function (p1, p2) {
      return i.invoke_15(p1, p2);
    };
  }
  function _get_COROUTINE_SUSPENDED_() {
    return CoroutineSingletons_COROUTINE_SUSPENDED_getInstance();
  }
  var CoroutineSingletons_COROUTINE_SUSPENDED_instance;
  var CoroutineSingletons_UNDECIDED_instance;
  var CoroutineSingletons_RESUMED_instance;
  var CoroutineSingletons_entriesInitialized;
  function CoroutineSingletons_initEntries() {
    if (CoroutineSingletons_entriesInitialized)
      return Unit_getInstance();
    CoroutineSingletons_entriesInitialized = true;
    CoroutineSingletons_COROUTINE_SUSPENDED_instance = new CoroutineSingletons('COROUTINE_SUSPENDED', 0);
    CoroutineSingletons_UNDECIDED_instance = new CoroutineSingletons('UNDECIDED', 1);
    CoroutineSingletons_RESUMED_instance = new CoroutineSingletons('RESUMED', 2);
  }
  function CoroutineSingletons(name, ordinal) {
    Enum.call(this, name, ordinal);
  }
  CoroutineSingletons.$metadata$ = {
    simpleName: 'CoroutineSingletons',
    kind: 'class',
    interfaces: []
  };
  function CoroutineSingletons_COROUTINE_SUSPENDED_getInstance() {
    CoroutineSingletons_initEntries();
    return CoroutineSingletons_COROUTINE_SUSPENDED_instance;
  }
  function CoroutineSingletons_UNDECIDED_getInstance() {
    CoroutineSingletons_initEntries();
    return CoroutineSingletons_UNDECIDED_instance;
  }
  function CoroutineSingletons_RESUMED_getInstance() {
    CoroutineSingletons_initEntries();
    return CoroutineSingletons_RESUMED_instance;
  }
  function ReadOnlyProperty() {
  }
  ReadOnlyProperty.$metadata$ = {
    simpleName: 'ReadOnlyProperty',
    kind: 'interface',
    interfaces: []
  };
  function checkStepIsPositive(isPositive, step_0) {
    if (!isPositive)
      throw IllegalArgumentException_init_$Create$_0('' + 'Step must be positive, was: ' + step_0 + '.');
  }
  function KClassifier() {
  }
  KClassifier.$metadata$ = {
    simpleName: 'KClassifier',
    kind: 'interface',
    interfaces: []
  };
  function appendElement(_this_, element, transform) {
    if (!(transform == null)) {
      _this_.append_3(transform(element));
      Unit_getInstance();
    } else {
      if (element == null ? true : isCharSequence(element)) {
        _this_.append_3(element);
        Unit_getInstance();
      } else {
        if (element instanceof Char) {
          _this_.append_2(element);
          Unit_getInstance();
        } else {
          {
            _this_.append_3(toString_1(element));
            Unit_getInstance();
          }
        }
      }
    }
  }
  function equals(_this_, other, ignoreCase) {
    if (_this_.equals(other))
      return true;
    if (!ignoreCase)
      return false;
    var thisUpper = uppercaseChar(_this_);
    var otherUpper = uppercaseChar(other);
    var tmp;
    if (thisUpper.equals(otherUpper)) {
      tmp = true;
    } else {
      var tmp0_asDynamic_0 = thisUpper.toString();
      var tmp1_unsafeCast_0 = tmp0_asDynamic_0.toLowerCase();
      var tmp_0 = charSequenceGet(tmp1_unsafeCast_0, 0);
      var tmp2_asDynamic_0 = otherUpper.toString();
      var tmp3_unsafeCast_0 = tmp2_asDynamic_0.toLowerCase();
      tmp = tmp_0.equals(charSequenceGet(tmp3_unsafeCast_0, 0));
    }
    return tmp;
  }
  function trimIndent(_this_) {
    return replaceIndent(_this_, '');
  }
  function replaceIndent(_this_, newIndent) {
    var lines_0 = lines(_this_);
    var tmp0_filterTo_0_1 = ArrayList_init_$Create$();
    var tmp0_iterator_1_2 = lines_0.iterator_66();
    while (tmp0_iterator_1_2.hasNext_41()) {
      var element_2_3 = tmp0_iterator_1_2.next_46();
      if (isNotBlank(element_2_3)) {
        tmp0_filterTo_0_1.add_39(element_2_3);
        Unit_getInstance();
      }}
    var tmp0_map_0 = tmp0_filterTo_0_1;
    var tmp0_mapTo_0_1 = ArrayList_init_$Create$_0(collectionSizeOrDefault(tmp0_map_0, 10));
    var tmp0_iterator_1_2_0 = tmp0_map_0.iterator_66();
    while (tmp0_iterator_1_2_0.hasNext_41()) {
      var item_2_3 = tmp0_iterator_1_2_0.next_46();
      tmp0_mapTo_0_1.add_39(indentWidth(item_2_3));
      Unit_getInstance();
    }
    var tmp0_elvis_lhs = minOrNull(tmp0_mapTo_0_1);
    var minCommonIndent = tmp0_elvis_lhs == null ? 0 : tmp0_elvis_lhs;
    var tmp1_reindent_0 = _this_.length + imul(newIndent.length, lines_0._get_size__52()) | 0;
    var tmp2_reindent_0 = getIndentFunction(newIndent);
    var lastIndex_1 = _get_lastIndex__2(lines_0);
    var tmp1_mapIndexedNotNullTo_0_1 = ArrayList_init_$Create$();
    var index_1_2 = 0;
    var tmp0_iterator_2_3 = lines_0.iterator_66();
    while (tmp0_iterator_2_3.hasNext_41()) {
      var item_3_4 = tmp0_iterator_2_3.next_46();
      var tmp1_4_6 = index_1_2;
      index_1_2 = tmp1_4_6 + 1 | 0;
      var tmp0__anonymous__1_5_5 = checkIndexOverflow(tmp1_4_6);
      var tmp;
      if ((tmp0__anonymous__1_5_5 === 0 ? true : tmp0__anonymous__1_5_5 === lastIndex_1) ? isBlank(item_3_4) : false) {
        tmp = null;
      } else {
        var tmp0_safe_receiver_4_10 = drop_1(item_3_4, minCommonIndent);
        var tmp_0;
        if (tmp0_safe_receiver_4_10 == null) {
          tmp_0 = null;
        } else {
          tmp_0 = tmp2_reindent_0(tmp0_safe_receiver_4_10);
        }
        var tmp1_elvis_lhs_3_9 = tmp_0;
        tmp = tmp1_elvis_lhs_3_9 == null ? item_3_4 : tmp1_elvis_lhs_3_9;
      }
      var tmp0_safe_receiver_2_6_7 = tmp;
      if (tmp0_safe_receiver_2_6_7 == null)
        null;
      else {
        tmp1_mapIndexedNotNullTo_0_1.add_39(tmp0_safe_receiver_2_6_7);
        Unit_getInstance();
      }
      Unit_getInstance();
    }
    var tmp_1 = StringBuilder_init_$Create$(tmp1_reindent_0);
    return joinTo$default(tmp1_mapIndexedNotNullTo_0_1, tmp_1, '\n', null, null, 0, null, null, 124, null).toString();
  }
  function indentWidth(_this_) {
    var tmp$ret$0;
    l$ret$1: do {
      var inductionVariable = 0;
      var last_2 = charSequenceLength(_this_) - 1 | 0;
      if (inductionVariable <= last_2)
        do {
          var index_2 = inductionVariable;
          inductionVariable = inductionVariable + 1 | 0;
          var tmp0__anonymous__3 = charSequenceGet(_this_, index_2);
          if (!isWhitespace(tmp0__anonymous__3)) {
            tmp$ret$0 = index_2;
            break l$ret$1;
          } else {
          }
        }
         while (inductionVariable <= last_2);
      tmp$ret$0 = -1;
    }
     while (false);
    var tmp1_let_0 = tmp$ret$0;
    return tmp1_let_0 === -1 ? _this_.length : tmp1_let_0;
  }
  function getIndentFunction(indent) {
    var tmp;
    if (charSequenceLength(indent) === 0) {
      tmp = _no_name_provided_$factory_8();
    } else {
      {
        tmp = _no_name_provided_$factory_9(indent);
      }
    }
    return tmp;
  }
  function _no_name_provided__16() {
  }
  _no_name_provided__16.prototype.invoke_402 = function (line) {
    return line;
  };
  _no_name_provided__16.prototype.invoke_1115 = function (p1) {
    return this.invoke_402((!(p1 == null) ? typeof p1 === 'string' : false) ? p1 : THROW_CCE());
  };
  _no_name_provided__16.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__17($indent) {
    this._$indent = $indent;
  }
  _no_name_provided__17.prototype.invoke_402 = function (line) {
    return this._$indent + line;
  };
  _no_name_provided__17.prototype.invoke_1115 = function (p1) {
    return this.invoke_402((!(p1 == null) ? typeof p1 === 'string' : false) ? p1 : THROW_CCE());
  };
  _no_name_provided__17.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided_$factory_8() {
    var i = new _no_name_provided__16();
    return function (p1) {
      return i.invoke_402(p1);
    };
  }
  function _no_name_provided_$factory_9($indent) {
    var i = new _no_name_provided__17($indent);
    return function (p1) {
      return i.invoke_402(p1);
    };
  }
  function numberFormatError(input) {
    throw NumberFormatException_init_$Create$('' + "Invalid number format: '" + input + "'");
  }
  function toLongOrNull(_this_, radix) {
    checkRadix(radix);
    Unit_getInstance();
    var length = _this_.length;
    if (length === 0)
      return null;
    var start_0;
    var isNegative_0;
    var limit;
    var firstChar = charSequenceGet(_this_, 0);
    if (firstChar.compareTo_9(new Char(48)) < 0) {
      if (length === 1)
        return null;
      start_0 = 1;
      if (firstChar.equals(new Char(45))) {
        isNegative_0 = true;
        Companion_getInstance_10();
        limit = new Long(0, -2147483648);
      } else if (firstChar.equals(new Char(43))) {
        isNegative_0 = false;
        Companion_getInstance_10();
        limit = (new Long(-1, 2147483647)).unaryMinus_5();
      } else
        return null;
    } else {
      start_0 = 0;
      isNegative_0 = false;
      Companion_getInstance_10();
      limit = (new Long(-1, 2147483647)).unaryMinus_5();
    }
    Companion_getInstance_10();
    var tmp0_div_0 = (new Long(-1, 2147483647)).unaryMinus_5();
    var tmp1_div_0 = 36;
    var limitForMaxRadix = tmp0_div_0.div_34(toLong_0(tmp1_div_0));
    var limitBeforeMul = limitForMaxRadix;
    var result = new Long(0, 0);
    var inductionVariable = start_0;
    if (inductionVariable < length)
      do {
        var i = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        var digit = digitOf(charSequenceGet(_this_, i), radix);
        if (digit < 0)
          return null;
        if (result.compareTo_75(limitBeforeMul) < 0) {
          if (limitBeforeMul.equals(limitForMaxRadix)) {
            limitBeforeMul = limit.div_34(toLong_0(radix));
            if (result.compareTo_75(limitBeforeMul) < 0) {
              return null;
            }} else {
            return null;
          }
        }var tmp2_times_0 = result;
        result = tmp2_times_0.times_40(toLong_0(radix));
        var tmp = result;
        if (tmp.compareTo_75(limit.plus_71(toLong_0(digit))) < 0)
          return null;
        else {
        }
        var tmp3_minus_0 = result;
        result = tmp3_minus_0.minus_40(toLong_0(digit));
      }
       while (inductionVariable < length);
    return isNegative_0 ? result : result.unaryMinus_5();
  }
  function contains_5(_this_, char, ignoreCase) {
    return indexOf$default(_this_, char, 0, ignoreCase, 2, null) >= 0;
  }
  function contains$default(_this_, char, ignoreCase, $mask0, $handler) {
    if (!(($mask0 & 2) === 0))
      ignoreCase = false;
    return contains_5(_this_, char, ignoreCase);
  }
  function split(_this_, delimiters, ignoreCase, limit) {
    if (delimiters.length === 1) {
      return split_0(_this_, delimiters[0].toString(), ignoreCase, limit);
    }var tmp0_map_0 = asIterable(rangesDelimitedBy$default(_this_, delimiters, 0, ignoreCase, limit, 2, null));
    var tmp0_mapTo_0_1 = ArrayList_init_$Create$_0(collectionSizeOrDefault(tmp0_map_0, 10));
    var tmp0_iterator_1_2 = tmp0_map_0.iterator_66();
    while (tmp0_iterator_1_2.hasNext_41()) {
      var item_2_3 = tmp0_iterator_1_2.next_46();
      tmp0_mapTo_0_1.add_39(substring_0(_this_, item_2_3));
      Unit_getInstance();
    }
    return tmp0_mapTo_0_1;
  }
  function split$default(_this_, delimiters, ignoreCase, limit, $mask0, $handler) {
    if (!(($mask0 & 2) === 0))
      ignoreCase = false;
    if (!(($mask0 & 4) === 0))
      limit = 0;
    return split(_this_, delimiters, ignoreCase, limit);
  }
  function substring(_this_, range) {
    var tmp0_substring_0 = range._get_start__1();
    var tmp1_substring_0 = range._get_endInclusive_() + 1 | 0;
    return _this_.substring(tmp0_substring_0, tmp1_substring_0);
  }
  function isNotBlank(_this_) {
    return !isBlank(_this_);
  }
  function padStart(_this_, length, padChar) {
    return toString_2(padStart_0(isCharSequence(_this_) ? _this_ : THROW_CCE(), length, padChar));
  }
  function _get_lastIndex__3(_this_) {
    return charSequenceLength(_this_) - 1 | 0;
  }
  function indexOf_4(_this_, char, startIndex, ignoreCase) {
    var tmp;
    var tmp_0;
    if (ignoreCase) {
      tmp_0 = true;
    } else {
      tmp_0 = !(typeof _this_ === 'string');
    }
    if (tmp_0) {
      var tmp0_charArrayOf_0 = charArrayOf([char]);
      tmp = indexOfAny(_this_, tmp0_charArrayOf_0, startIndex, ignoreCase);
    } else {
      {
        var tmp2_nativeIndexOf_0 = _this_;
        var tmp1_nativeIndexOf_0 = char.toString();
        tmp = tmp2_nativeIndexOf_0.indexOf(tmp1_nativeIndexOf_0, startIndex);
      }
    }
    return tmp;
  }
  function indexOf$default(_this_, char, startIndex, ignoreCase, $mask0, $handler) {
    if (!(($mask0 & 2) === 0))
      startIndex = 0;
    if (!(($mask0 & 4) === 0))
      ignoreCase = false;
    return indexOf_4(_this_, char, startIndex, ignoreCase);
  }
  function split_0(_this_, delimiter, ignoreCase, limit) {
    var tmp0_require_0 = limit >= 0;
    if (!tmp0_require_0) {
      var message_2 = '' + 'Limit must be non-negative, but was ' + limit + '.';
      throw IllegalArgumentException_init_$Create$_0(toString_2(message_2));
    }var currentOffset = 0;
    var nextIndex = indexOf_5(_this_, delimiter, currentOffset, ignoreCase);
    if (nextIndex === -1 ? true : limit === 1) {
      return listOf_0(toString_2(_this_));
    }var isLimited = limit > 0;
    var result = ArrayList_init_$Create$_0(isLimited ? coerceAtMost(limit, 10) : 10);
    $l$break: do {
      var tmp1_substring_0 = currentOffset;
      var tmp2_substring_0 = nextIndex;
      result.add_39(toString_2(charSequenceSubSequence(_this_, tmp1_substring_0, tmp2_substring_0)));
      Unit_getInstance();
      currentOffset = nextIndex + delimiter.length | 0;
      if (isLimited ? result._get_size__52() === (limit - 1 | 0) : false)
        break $l$break;
      nextIndex = indexOf_5(_this_, delimiter, currentOffset, ignoreCase);
    }
     while (!(nextIndex === -1));
    var tmp3_substring_0 = currentOffset;
    var tmp4_substring_0 = charSequenceLength(_this_);
    result.add_39(toString_2(charSequenceSubSequence(_this_, tmp3_substring_0, tmp4_substring_0)));
    Unit_getInstance();
    return result;
  }
  function substring_0(_this_, range) {
    return toString_2(charSequenceSubSequence(_this_, range._get_start__1(), range._get_endInclusive_() + 1 | 0));
  }
  function rangesDelimitedBy(_this_, delimiters, startIndex, ignoreCase, limit) {
    var tmp0_require_0 = limit >= 0;
    if (!tmp0_require_0) {
      var message_2 = '' + 'Limit must be non-negative, but was ' + limit + '.';
      throw IllegalArgumentException_init_$Create$_0(toString_2(message_2));
    }return new DelimitedRangesSequence(_this_, startIndex, limit, _no_name_provided_$factory_10(delimiters, ignoreCase));
  }
  function rangesDelimitedBy$default(_this_, delimiters, startIndex, ignoreCase, limit, $mask0, $handler) {
    if (!(($mask0 & 2) === 0))
      startIndex = 0;
    if (!(($mask0 & 4) === 0))
      ignoreCase = false;
    if (!(($mask0 & 8) === 0))
      limit = 0;
    return rangesDelimitedBy(_this_, delimiters, startIndex, ignoreCase, limit);
  }
  function padStart_0(_this_, length, padChar) {
    if (length < 0)
      throw IllegalArgumentException_init_$Create$_0('' + 'Desired length ' + length + ' is less than zero.');
    if (length <= charSequenceLength(_this_))
      return charSequenceSubSequence(_this_, 0, charSequenceLength(_this_));
    var sb = StringBuilder_init_$Create$(length);
    var inductionVariable = 1;
    var last_2 = length - charSequenceLength(_this_) | 0;
    if (inductionVariable <= last_2)
      do {
        var i = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        sb.append_2(padChar);
        Unit_getInstance();
      }
       while (!(i === last_2));
    sb.append_3(_this_);
    Unit_getInstance();
    return sb;
  }
  function indexOfAny(_this_, chars, startIndex, ignoreCase) {
    var tmp;
    if (!ignoreCase ? chars.length === 1 : false) {
      tmp = typeof _this_ === 'string';
    } else {
      tmp = false;
    }
    if (tmp) {
      var char = single(chars);
      var tmp1_nativeIndexOf_0 = _this_;
      var tmp0_nativeIndexOf_0 = char.toString();
      return tmp1_nativeIndexOf_0.indexOf(tmp0_nativeIndexOf_0, startIndex);
    } else {
    }
    var inductionVariable = coerceAtLeast(startIndex, 0);
    var last_2 = _get_lastIndex__3(_this_);
    if (inductionVariable <= last_2)
      do {
        var index = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        var charAtIndex = charSequenceGet(_this_, index);
        var tmp$ret$0;
        l$ret$1: do {
          var indexedObject = chars;
          var inductionVariable_0 = 0;
          var last_3 = indexedObject.length;
          while (inductionVariable_0 < last_3) {
            var element_2 = indexedObject[inductionVariable_0];
            inductionVariable_0 = inductionVariable_0 + 1 | 0;
            if (equals(element_2, charAtIndex, ignoreCase)) {
              tmp$ret$0 = true;
              break l$ret$1;
            } else {
            }
          }
          tmp$ret$0 = false;
        }
         while (false);
        if (tmp$ret$0)
          return index;
        else {
        }
      }
       while (!(index === last_2));
    return -1;
  }
  function indexOf_5(_this_, string, startIndex, ignoreCase) {
    var tmp;
    var tmp_0;
    if (ignoreCase) {
      tmp_0 = true;
    } else {
      tmp_0 = !(typeof _this_ === 'string');
    }
    if (tmp_0) {
      var tmp_1 = charSequenceLength(_this_);
      tmp = indexOf$default_1(_this_, string, startIndex, tmp_1, ignoreCase, false, 16, null);
    } else {
      {
        var tmp0_nativeIndexOf_0 = _this_;
        tmp = tmp0_nativeIndexOf_0.indexOf(string, startIndex);
      }
    }
    return tmp;
  }
  function indexOf$default_0(_this_, string, startIndex, ignoreCase, $mask0, $handler) {
    if (!(($mask0 & 2) === 0))
      startIndex = 0;
    if (!(($mask0 & 4) === 0))
      ignoreCase = false;
    return indexOf_5(_this_, string, startIndex, ignoreCase);
  }
  function calcNext($this) {
    if ($this._nextSearchIndex < 0) {
      $this._nextState = 0;
      $this._nextItem = null;
    } else {
      var tmp;
      var tmp_0;
      if ($this._this$0_7._limit > 0) {
        var tmp0_this = $this;
        tmp0_this._counter = tmp0_this._counter + 1 | 0;
        tmp_0 = tmp0_this._counter >= $this._this$0_7._limit;
      } else {
        tmp_0 = false;
      }
      if (tmp_0) {
        tmp = true;
      } else {
        {
          tmp = $this._nextSearchIndex > charSequenceLength($this._this$0_7._input);
        }
      }
      if (tmp) {
        $this._nextItem = numberRangeToNumber($this._currentStartIndex, _get_lastIndex__3($this._this$0_7._input));
        $this._nextSearchIndex = -1;
      } else {
        {
          var match_0 = $this._this$0_7._getNextMatch($this._this$0_7._input, $this._nextSearchIndex);
          if (match_0 == null) {
            $this._nextItem = numberRangeToNumber($this._currentStartIndex, _get_lastIndex__3($this._this$0_7._input));
            $this._nextSearchIndex = -1;
          } else {
            var tmp1_container = match_0;
            var index = tmp1_container.component1_4();
            var length = tmp1_container.component2_4();
            $this._nextItem = until($this._currentStartIndex, index);
            $this._currentStartIndex = index + length | 0;
            $this._nextSearchIndex = $this._currentStartIndex + (length === 0 ? 1 : 0) | 0;
          }
        }
      }
      $this._nextState = 1;
    }
  }
  function _no_name_provided__18(this$0) {
    this._this$0_7 = this$0;
    this._nextState = -1;
    this._currentStartIndex = coerceIn(this._this$0_7._startIndex_0, 0, charSequenceLength(this._this$0_7._input));
    this._nextSearchIndex = this._currentStartIndex;
    this._nextItem = null;
    this._counter = 0;
  }
  _no_name_provided__18.prototype.next_46 = function () {
    if (this._nextState === -1)
      calcNext(this);
    if (this._nextState === 0)
      throw NoSuchElementException_init_$Create$();
    var tmp = this._nextItem;
    var result = tmp instanceof IntRange ? tmp : THROW_CCE();
    this._nextItem = null;
    this._nextState = -1;
    return result;
  };
  _no_name_provided__18.prototype.hasNext_41 = function () {
    if (this._nextState === -1)
      calcNext(this);
    return this._nextState === 1;
  };
  _no_name_provided__18.$metadata$ = {
    kind: 'class',
    interfaces: [Iterator_2]
  };
  function DelimitedRangesSequence(input, startIndex, limit, getNextMatch) {
    this._input = input;
    this._startIndex_0 = startIndex;
    this._limit = limit;
    this._getNextMatch = getNextMatch;
  }
  DelimitedRangesSequence.prototype.iterator_66 = function () {
    return new _no_name_provided__18(this);
  };
  DelimitedRangesSequence.$metadata$ = {
    simpleName: 'DelimitedRangesSequence',
    kind: 'class',
    interfaces: [Sequence]
  };
  function indexOf_6(_this_, other, startIndex, endIndex, ignoreCase, last_2) {
    var indices = !last_2 ? numberRangeToNumber(coerceAtLeast(startIndex, 0), coerceAtMost(endIndex, charSequenceLength(_this_))) : downTo(coerceAtMost(startIndex, _get_lastIndex__3(_this_)), coerceAtLeast(endIndex, 0));
    var tmp;
    if (typeof _this_ === 'string') {
      tmp = typeof other === 'string';
    } else {
      {
        tmp = false;
      }
    }
    if (tmp) {
      var inductionVariable = indices._first_1;
      var last_3 = indices._last;
      var step_0 = indices._step_0;
      if ((step_0 > 0 ? inductionVariable <= last_3 : false) ? true : step_0 < 0 ? last_3 <= inductionVariable : false)
        do {
          var index = inductionVariable;
          inductionVariable = inductionVariable + step_0 | 0;
          if (regionMatches(other, 0, _this_, index, charSequenceLength(other), ignoreCase))
            return index;
        }
         while (!(index === last_3));
    } else {
      {
        var inductionVariable_0 = indices._first_1;
        var last_4 = indices._last;
        var step_1 = indices._step_0;
        if ((step_1 > 0 ? inductionVariable_0 <= last_4 : false) ? true : step_1 < 0 ? last_4 <= inductionVariable_0 : false)
          do {
            var index_0 = inductionVariable_0;
            inductionVariable_0 = inductionVariable_0 + step_1 | 0;
            if (regionMatchesImpl(other, 0, _this_, index_0, charSequenceLength(other), ignoreCase))
              return index_0;
          }
           while (!(index_0 === last_4));
      }
    }
    return -1;
  }
  function indexOf$default_1(_this_, other, startIndex, endIndex, ignoreCase, last_2, $mask0, $handler) {
    if (!(($mask0 & 16) === 0))
      last_2 = false;
    return indexOf_6(_this_, other, startIndex, endIndex, ignoreCase, last_2);
  }
  function regionMatchesImpl(_this_, thisOffset, other, otherOffset, length, ignoreCase) {
    if (((otherOffset < 0 ? true : thisOffset < 0) ? true : thisOffset > (charSequenceLength(_this_) - length | 0)) ? true : otherOffset > (charSequenceLength(other) - length | 0)) {
      return false;
    }var inductionVariable = 0;
    if (inductionVariable < length)
      do {
        var index = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        if (!equals(charSequenceGet(_this_, thisOffset + index | 0), charSequenceGet(other, otherOffset + index | 0), ignoreCase))
          return false;
      }
       while (inductionVariable < length);
    return true;
  }
  function lines(_this_) {
    return toList_1(lineSequence(_this_));
  }
  function lineSequence(_this_) {
    var tmp = ['\r\n', '\n', '\r'];
    return splitToSequence$default(_this_, tmp, false, 0, 6, null);
  }
  function splitToSequence(_this_, delimiters, ignoreCase, limit) {
    var tmp = rangesDelimitedBy$default_0(_this_, delimiters, 0, ignoreCase, limit, 2, null);
    return map(tmp, _no_name_provided_$factory_11(_this_));
  }
  function splitToSequence$default(_this_, delimiters, ignoreCase, limit, $mask0, $handler) {
    if (!(($mask0 & 2) === 0))
      ignoreCase = false;
    if (!(($mask0 & 4) === 0))
      limit = 0;
    return splitToSequence(_this_, delimiters, ignoreCase, limit);
  }
  function rangesDelimitedBy_0(_this_, delimiters, startIndex, ignoreCase, limit) {
    var tmp0_require_0 = limit >= 0;
    if (!tmp0_require_0) {
      var message_2 = '' + 'Limit must be non-negative, but was ' + limit + '.';
      throw IllegalArgumentException_init_$Create$_0(toString_2(message_2));
    }var delimitersList = asList(delimiters);
    return new DelimitedRangesSequence(_this_, startIndex, limit, _no_name_provided_$factory_12(delimitersList, ignoreCase));
  }
  function rangesDelimitedBy$default_0(_this_, delimiters, startIndex, ignoreCase, limit, $mask0, $handler) {
    if (!(($mask0 & 2) === 0))
      startIndex = 0;
    if (!(($mask0 & 4) === 0))
      ignoreCase = false;
    if (!(($mask0 & 8) === 0))
      limit = 0;
    return rangesDelimitedBy_0(_this_, delimiters, startIndex, ignoreCase, limit);
  }
  function findAnyOf(_this_, strings, startIndex, ignoreCase, last_2) {
    if (!ignoreCase ? strings._get_size__52() === 1 : false) {
      var string = single_0(strings);
      var tmp;
      if (!last_2) {
        tmp = indexOf$default_0(_this_, string, startIndex, false, 4, null);
      } else {
        tmp = lastIndexOf$default(_this_, string, startIndex, false, 4, null);
      }
      var index = tmp;
      return index < 0 ? null : to(index, string);
    }var indices = !last_2 ? numberRangeToNumber(coerceAtLeast(startIndex, 0), charSequenceLength(_this_)) : downTo(coerceAtMost(startIndex, _get_lastIndex__3(_this_)), 0);
    if (typeof _this_ === 'string') {
      var inductionVariable = indices._first_1;
      var last_3 = indices._last;
      var step_0 = indices._step_0;
      if ((step_0 > 0 ? inductionVariable <= last_3 : false) ? true : step_0 < 0 ? last_3 <= inductionVariable : false)
        do {
          var index_0 = inductionVariable;
          inductionVariable = inductionVariable + step_0 | 0;
          var tmp$ret$0;
          l$ret$1: do {
            var tmp0_iterator_1 = strings.iterator_66();
            while (tmp0_iterator_1.hasNext_41()) {
              var element_2 = tmp0_iterator_1.next_46();
              if (regionMatches(element_2, 0, _this_, index_0, element_2.length, ignoreCase)) {
                tmp$ret$0 = element_2;
                break l$ret$1;
              } else {
              }
            }
            tmp$ret$0 = null;
          }
           while (false);
          var matchingString = tmp$ret$0;
          if (!(matchingString == null))
            return to(index_0, matchingString);
        }
         while (!(index_0 === last_3));
    } else {
      {
        var inductionVariable_0 = indices._first_1;
        var last_4 = indices._last;
        var step_1 = indices._step_0;
        if ((step_1 > 0 ? inductionVariable_0 <= last_4 : false) ? true : step_1 < 0 ? last_4 <= inductionVariable_0 : false)
          do {
            var index_1 = inductionVariable_0;
            inductionVariable_0 = inductionVariable_0 + step_1 | 0;
            var tmp$ret$2;
            l$ret$3: do {
              var tmp0_iterator_1_0 = strings.iterator_66();
              while (tmp0_iterator_1_0.hasNext_41()) {
                var element_2_0 = tmp0_iterator_1_0.next_46();
                if (regionMatchesImpl(element_2_0, 0, _this_, index_1, element_2_0.length, ignoreCase)) {
                  tmp$ret$2 = element_2_0;
                  break l$ret$3;
                } else {
                }
              }
              tmp$ret$2 = null;
            }
             while (false);
            var matchingString_0 = tmp$ret$2;
            if (!(matchingString_0 == null))
              return to(index_1, matchingString_0);
          }
           while (!(index_1 === last_4));
      }
    }
    return null;
  }
  function lastIndexOf_0(_this_, string, startIndex, ignoreCase) {
    var tmp;
    var tmp_0;
    if (ignoreCase) {
      tmp_0 = true;
    } else {
      tmp_0 = !(typeof _this_ === 'string');
    }
    if (tmp_0) {
      tmp = indexOf_6(_this_, string, startIndex, 0, ignoreCase, true);
    } else {
      {
        var tmp0_nativeLastIndexOf_0 = _this_;
        tmp = tmp0_nativeLastIndexOf_0.lastIndexOf(string, startIndex);
      }
    }
    return tmp;
  }
  function lastIndexOf$default(_this_, string, startIndex, ignoreCase, $mask0, $handler) {
    if (!(($mask0 & 2) === 0))
      startIndex = _get_lastIndex__3(_this_);
    if (!(($mask0 & 4) === 0))
      ignoreCase = false;
    return lastIndexOf_0(_this_, string, startIndex, ignoreCase);
  }
  function _no_name_provided__19($delimiters, $ignoreCase) {
    this._$delimiters = $delimiters;
    this._$ignoreCase = $ignoreCase;
  }
  _no_name_provided__19.prototype.invoke_25 = function ($this$$receiver, currentIndex) {
    var tmp0_let_0 = indexOfAny($this$$receiver, this._$delimiters, currentIndex, this._$ignoreCase);
    return tmp0_let_0 < 0 ? null : to(tmp0_let_0, 1);
  };
  _no_name_provided__19.prototype.invoke_915 = function (p1, p2) {
    var tmp = (!(p1 == null) ? isCharSequence(p1) : false) ? p1 : THROW_CCE();
    return this.invoke_25(tmp, (!(p2 == null) ? typeof p2 === 'number' : false) ? p2 : THROW_CCE());
  };
  _no_name_provided__19.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__20($this_splitToSequence) {
    this._$this_splitToSequence = $this_splitToSequence;
  }
  _no_name_provided__20.prototype.invoke_23 = function (it) {
    return substring_0(this._$this_splitToSequence, it);
  };
  _no_name_provided__20.prototype.invoke_1115 = function (p1) {
    return this.invoke_23(p1 instanceof IntRange ? p1 : THROW_CCE());
  };
  _no_name_provided__20.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__21($delimitersList, $ignoreCase) {
    this._$delimitersList = $delimitersList;
    this._$ignoreCase_0 = $ignoreCase;
  }
  _no_name_provided__21.prototype.invoke_25 = function ($this$$receiver, currentIndex) {
    var tmp0_safe_receiver = findAnyOf($this$$receiver, this._$delimitersList, currentIndex, this._$ignoreCase_0, false);
    var tmp;
    if (tmp0_safe_receiver == null) {
      tmp = null;
    } else {
      tmp = to(tmp0_safe_receiver._first, tmp0_safe_receiver._second.length);
    }
    return tmp;
  };
  _no_name_provided__21.prototype.invoke_915 = function (p1, p2) {
    var tmp = (!(p1 == null) ? isCharSequence(p1) : false) ? p1 : THROW_CCE();
    return this.invoke_25(tmp, (!(p2 == null) ? typeof p2 === 'number' : false) ? p2 : THROW_CCE());
  };
  _no_name_provided__21.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided_$factory_10($delimiters, $ignoreCase) {
    var i = new _no_name_provided__19($delimiters, $ignoreCase);
    return function (p1, p2) {
      return i.invoke_25(p1, p2);
    };
  }
  function _no_name_provided_$factory_11($this_splitToSequence) {
    var i = new _no_name_provided__20($this_splitToSequence);
    return function (p1) {
      return i.invoke_23(p1);
    };
  }
  function _no_name_provided_$factory_12($delimitersList, $ignoreCase) {
    var i = new _no_name_provided__21($delimitersList, $ignoreCase);
    return function (p1, p2) {
      return i.invoke_25(p1, p2);
    };
  }
  function _Duration___init__impl_(rawValue) {
    if (isInNanos(rawValue)) {
      var containsLower = new Long(387905, -1073741824);
      var containsUpper = new Long(-387905, 1073741823);
      var containsArg = _get_value_(rawValue);
      if (!(containsLower.compareTo_75(containsArg) <= 0 ? containsArg.compareTo_75(containsUpper) <= 0 : false))
        throw AssertionError_init_$Create$('' + _get_value_(rawValue) + ' ns is out of nanoseconds range');
      else {
      }
    } else {
      var containsLower_0 = new Long(1, -1073741824);
      var containsUpper_0 = new Long(-1, 1073741823);
      var containsArg_0 = _get_value_(rawValue);
      if (!(containsLower_0.compareTo_75(containsArg_0) <= 0 ? containsArg_0.compareTo_75(containsUpper_0) <= 0 : false))
        throw AssertionError_init_$Create$('' + _get_value_(rawValue) + ' ms is out of milliseconds range');
      else {
      }
      var containsLower_1 = new Long(1108857478, -1074);
      var containsUpper_1 = new Long(-1108857478, 1073);
      var containsArg_1 = _get_value_(rawValue);
      if (containsLower_1.compareTo_75(containsArg_1) <= 0 ? containsArg_1.compareTo_75(containsUpper_1) <= 0 : false)
        throw AssertionError_init_$Create$('' + _get_value_(rawValue) + ' ms is denormalized');
      else {
      }
    }
    return rawValue;
  }
  function _get_rawValue_($this) {
    return $this;
  }
  function _get_value_($this) {
    return _get_rawValue_($this).shr_4(1);
  }
  function isInNanos($this) {
    return (_get_rawValue_($this).toInt_5() & 1) === 0;
  }
  function _Duration___get_inWholeNanoseconds__impl_(this_0) {
    var value_0 = _get_value_(this_0);
    var tmp;
    if (isInNanos(this_0)) {
      tmp = value_0;
    } else {
      Companion_getInstance_10();
      var tmp0_div_0 = new Long(-1, 2147483647);
      var tmp1_div_0 = 1000000;
      if (value_0.compareTo_75(tmp0_div_0.div_34(toLong_0(tmp1_div_0))) > 0) {
        Companion_getInstance_10();
        tmp = new Long(-1, 2147483647);
      } else {
        Companion_getInstance_10();
        var tmp2_div_0 = new Long(0, -2147483648);
        var tmp3_div_0 = 1000000;
        if (value_0.compareTo_75(tmp2_div_0.div_34(toLong_0(tmp3_div_0))) < 0) {
          Companion_getInstance_10();
          tmp = new Long(0, -2147483648);
        } else {
          {
            tmp = millisToNanos(value_0);
          }
        }
      }
    }
    return tmp;
  }
  function Duration__toLongNanoseconds_impl(this_0) {
    return _Duration___get_inWholeNanoseconds__impl_(this_0);
  }
  function toDuration(_this_, unit) {
    var valueInNs = convertDurationUnit(_this_, unit, DurationUnit_NANOSECONDS_getInstance());
    var tmp0_require_0 = !isNaN_0(valueInNs);
    if (!tmp0_require_0) {
      var message_2 = 'Duration value cannot be NaN.';
      throw IllegalArgumentException_init_$Create$_0(toString_2(message_2));
    }var nanos = numberToLong(valueInNs);
    var tmp;
    var containsLower = new Long(387905, -1073741824);
    if (nanos.compareTo_75(new Long(-387905, 1073741823)) <= 0 ? containsLower.compareTo_75(nanos) <= 0 : false) {
      tmp = durationOfNanos(nanos);
    } else {
      {
        var millis = numberToLong(convertDurationUnit(_this_, unit, DurationUnit_MILLISECONDS_getInstance()));
        tmp = durationOfMillisNormalized(millis);
      }
    }
    return tmp;
  }
  function durationOfMillis(normalMillis) {
    var tmp0_plus_0 = normalMillis.shl_4(1);
    var tmp1_plus_0 = 1;
    return _Duration___init__impl_(tmp0_plus_0.plus_71(toLong_0(tmp1_plus_0)));
  }
  function durationOfMillisNormalized(millis) {
    var tmp;
    var containsLower = new Long(1108857478, -1074);
    if (millis.compareTo_75(new Long(-1108857478, 1073)) <= 0 ? containsLower.compareTo_75(millis) <= 0 : false) {
      tmp = durationOfNanos(millisToNanos(millis));
    } else {
      {
        tmp = durationOfMillis(coerceIn_0(millis, new Long(1, -1073741824), new Long(-1, 1073741823)));
      }
    }
    return tmp;
  }
  function millisToNanos(millis) {
    var tmp0_times_0 = 1000000;
    return millis.times_40(toLong_0(tmp0_times_0));
  }
  function durationOfNanos(normalNanos) {
    return _Duration___init__impl_(normalNanos.shl_4(1));
  }
  function Lazy() {
  }
  Lazy.$metadata$ = {
    simpleName: 'Lazy',
    kind: 'interface',
    interfaces: []
  };
  function UnsafeLazyImpl(initializer) {
    this._initializer = initializer;
    this.__value = UNINITIALIZED_VALUE_getInstance();
  }
  UnsafeLazyImpl.prototype._get_value__33 = function () {
    if (this.__value === UNINITIALIZED_VALUE_getInstance()) {
      this.__value = ensureNotNull(this._initializer)();
      this._initializer = null;
    }var tmp = this.__value;
    return (tmp == null ? true : isObject(tmp)) ? tmp : THROW_CCE();
  };
  UnsafeLazyImpl.prototype.isInitialized = function () {
    return !(this.__value === UNINITIALIZED_VALUE_getInstance());
  };
  UnsafeLazyImpl.prototype.toString = function () {
    return this.isInitialized() ? toString_1(this._get_value__33()) : 'Lazy value not initialized yet.';
  };
  UnsafeLazyImpl.$metadata$ = {
    simpleName: 'UnsafeLazyImpl',
    kind: 'class',
    interfaces: [Lazy, Serializable]
  };
  function UNINITIALIZED_VALUE() {
    UNINITIALIZED_VALUE_instance = this;
  }
  UNINITIALIZED_VALUE.$metadata$ = {
    simpleName: 'UNINITIALIZED_VALUE',
    kind: 'object',
    interfaces: []
  };
  var UNINITIALIZED_VALUE_instance;
  function UNINITIALIZED_VALUE_getInstance() {
    if (UNINITIALIZED_VALUE_instance == null)
      new UNINITIALIZED_VALUE();
    return UNINITIALIZED_VALUE_instance;
  }
  function _Result___init__impl_(value_0) {
    return value_0;
  }
  function _Result___get_value__impl_(this_0) {
    return this_0;
  }
  function _Result___get_isFailure__impl_(this_0) {
    var tmp = _Result___get_value__impl_(this_0);
    return tmp instanceof Failure;
  }
  function Result__exceptionOrNull_impl(this_0) {
    var tmp0_subject = _Result___get_value__impl_(this_0);
    var tmp;
    if (tmp0_subject instanceof Failure) {
      tmp = _Result___get_value__impl_(this_0)._exception;
    } else {
      {
        tmp = null;
      }
    }
    return tmp;
  }
  function Companion_3() {
    Companion_instance_2 = this;
  }
  Companion_3.$metadata$ = {
    simpleName: 'Companion',
    kind: 'object',
    interfaces: []
  };
  var Companion_instance_2;
  function Companion_getInstance_2() {
    if (Companion_instance_2 == null)
      new Companion_3();
    return Companion_instance_2;
  }
  function Failure(exception) {
    this._exception = exception;
  }
  Failure.prototype.equals = function (other) {
    var tmp;
    if (other instanceof Failure) {
      tmp = equals_0(this._exception, other._exception);
    } else {
      {
        tmp = false;
      }
    }
    return tmp;
  };
  Failure.prototype.hashCode = function () {
    return hashCode(this._exception);
  };
  Failure.prototype.toString = function () {
    return '' + 'Failure(' + this._exception + ')';
  };
  Failure.$metadata$ = {
    simpleName: 'Failure',
    kind: 'class',
    interfaces: [Serializable]
  };
  function createFailure(exception) {
    return new Failure(exception);
  }
  function throwOnFailure(_this_) {
    var tmp = _Result___get_value__impl_(_this_);
    if (tmp instanceof Failure)
      throw _Result___get_value__impl_(_this_)._exception;
    else {
    }
  }
  function NotImplementedError_init_$Init$(message, $mask0, $marker, $this) {
    if (!(($mask0 & 1) === 0))
      message = 'An operation is not implemented.';
    NotImplementedError.call($this, message);
    return $this;
  }
  function NotImplementedError_init_$Create$(message, $mask0, $marker) {
    var tmp = NotImplementedError_init_$Init$(message, $mask0, $marker, Object.create(NotImplementedError.prototype));
    captureStack(tmp, NotImplementedError_init_$Create$);
    return tmp;
  }
  function NotImplementedError(message) {
    Error_init_$Init$(message, this);
    captureStack(this, NotImplementedError);
  }
  NotImplementedError.$metadata$ = {
    simpleName: 'NotImplementedError',
    kind: 'class',
    interfaces: []
  };
  function Pair(first_4, second) {
    this._first = first_4;
    this._second = second;
  }
  Pair.prototype.toString = function () {
    return '' + '(' + this._first + ', ' + this._second + ')';
  };
  Pair.prototype.component1_4 = function () {
    return this._first;
  };
  Pair.prototype.component2_4 = function () {
    return this._second;
  };
  Pair.prototype.hashCode = function () {
    var result = this._first == null ? 0 : hashCode(this._first);
    result = imul(result, 31) + (this._second == null ? 0 : hashCode(this._second)) | 0;
    return result;
  };
  Pair.prototype.equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof Pair))
      return false;
    else {
    }
    var tmp0_other_with_cast = other instanceof Pair ? other : THROW_CCE();
    if (!equals_0(this._first, tmp0_other_with_cast._first))
      return false;
    if (!equals_0(this._second, tmp0_other_with_cast._second))
      return false;
    return true;
  };
  Pair.$metadata$ = {
    simpleName: 'Pair',
    kind: 'class',
    interfaces: [Serializable]
  };
  function Triple(first_4, second, third) {
    this._first_0 = first_4;
    this._second_0 = second;
    this._third = third;
  }
  Triple.prototype.toString = function () {
    return '' + '(' + this._first_0 + ', ' + this._second_0 + ', ' + this._third + ')';
  };
  Triple.prototype.component1_4 = function () {
    return this._first_0;
  };
  Triple.prototype.component2_4 = function () {
    return this._second_0;
  };
  Triple.prototype.component3_1 = function () {
    return this._third;
  };
  Triple.prototype.hashCode = function () {
    var result = this._first_0 == null ? 0 : hashCode(this._first_0);
    result = imul(result, 31) + (this._second_0 == null ? 0 : hashCode(this._second_0)) | 0;
    result = imul(result, 31) + (this._third == null ? 0 : hashCode(this._third)) | 0;
    return result;
  };
  Triple.prototype.equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof Triple))
      return false;
    else {
    }
    var tmp0_other_with_cast = other instanceof Triple ? other : THROW_CCE();
    if (!equals_0(this._first_0, tmp0_other_with_cast._first_0))
      return false;
    if (!equals_0(this._second_0, tmp0_other_with_cast._second_0))
      return false;
    if (!equals_0(this._third, tmp0_other_with_cast._third))
      return false;
    return true;
  };
  Triple.$metadata$ = {
    simpleName: 'Triple',
    kind: 'class',
    interfaces: [Serializable]
  };
  function to(_this_, that) {
    return new Pair(_this_, that);
  }
  function toList_2(_this_) {
    return listOf([_this_._first, _this_._second]);
  }
  function toList_3(_this_) {
    return listOf([_this_._first_0, _this_._second_0, _this_._third]);
  }
  function _UByte___init__impl_(data) {
    return data;
  }
  function _UByte___get_data__impl_(this_0) {
    return this_0;
  }
  function Companion_4() {
    Companion_instance_3 = this;
    this._MIN_VALUE = _UByte___init__impl_(0);
    this._MAX_VALUE = _UByte___init__impl_(-1);
    this._SIZE_BYTES = 1;
    this._SIZE_BITS = 8;
  }
  Companion_4.$metadata$ = {
    simpleName: 'Companion',
    kind: 'object',
    interfaces: []
  };
  var Companion_instance_3;
  function Companion_getInstance_3() {
    if (Companion_instance_3 == null)
      new Companion_4();
    return Companion_instance_3;
  }
  function UByte__compareTo_impl(this_0, other) {
    var tmp = _UByte___get_data__impl_(this_0) & 255;
    return compareTo(tmp, _UByte___get_data__impl_(other) & 255);
  }
  function UByte__compareTo_impl_0(this_0, other) {
    var tmp = this_0._data;
    return UByte__compareTo_impl(tmp, other instanceof UByte ? other._data : THROW_CCE());
  }
  function UByte__toString_impl(this_0) {
    return (_UByte___get_data__impl_(this_0) & 255).toString();
  }
  function UByte__hashCode_impl(this_0) {
    return this_0;
  }
  function UByte__equals_impl(this_0, other) {
    if (!(other instanceof UByte))
      return false;
    else {
    }
    var tmp0_other_with_cast = other instanceof UByte ? other._data : THROW_CCE();
    if (!(this_0 === tmp0_other_with_cast))
      return false;
    return true;
  }
  function UByte(data) {
    Companion_getInstance_3();
    this._data = data;
  }
  UByte.prototype.compareTo_1 = function (other) {
    return UByte__compareTo_impl(this._data, other);
  };
  UByte.prototype.compareTo_34 = function (other) {
    return UByte__compareTo_impl_0(this, other);
  };
  UByte.prototype.toString = function () {
    return UByte__toString_impl(this._data);
  };
  UByte.prototype.hashCode = function () {
    return UByte__hashCode_impl(this._data);
  };
  UByte.prototype.equals = function (other) {
    return UByte__equals_impl(this._data, other);
  };
  UByte.$metadata$ = {
    simpleName: 'UByte',
    kind: 'class',
    interfaces: [Comparable]
  };
  function _UByteArray___init__impl_(storage) {
    return storage;
  }
  function _UByteArray___get_storage__impl_(this_0) {
    return this_0;
  }
  function _UByteArray___init__impl__0(size_0) {
    var tmp = _UByteArray___init__impl_(new Int8Array(size_0));
    return tmp;
  }
  function UByteArray__get_impl(this_0, index) {
    var tmp0_toUByte_0 = _UByteArray___get_storage__impl_(this_0)[index];
    return _UByte___init__impl_(tmp0_toUByte_0);
  }
  function UByteArray__set_impl(this_0, index, value_0) {
    var tmp = _UByteArray___get_storage__impl_(this_0);
    tmp[index] = _UByte___get_data__impl_(value_0);
  }
  function _UByteArray___get_size__impl_(this_0) {
    return _UByteArray___get_storage__impl_(this_0).length;
  }
  function UByteArray__iterator_impl(this_0) {
    return new Iterator(_UByteArray___get_storage__impl_(this_0));
  }
  function Iterator(array) {
    UByteIterator.call(this);
    this._array = array;
    this._index_1 = 0;
  }
  Iterator.prototype.hasNext_41 = function () {
    return this._index_1 < this._array.length;
  };
  Iterator.prototype.nextUByte_0 = function () {
    var tmp;
    if (this._index_1 < this._array.length) {
      var tmp0_this = this;
      var tmp1 = tmp0_this._index_1;
      tmp0_this._index_1 = tmp1 + 1 | 0;
      var tmp0_toUByte_0 = this._array[tmp1];
      tmp = _UByte___init__impl_(tmp0_toUByte_0);
    } else {
      throw NoSuchElementException_init_$Create$_0(this._index_1.toString());
    }
    return tmp;
  };
  Iterator.$metadata$ = {
    simpleName: 'Iterator',
    kind: 'class',
    interfaces: []
  };
  function UByteArray__contains_impl(this_0, element) {
    var tmp = isObject(new UByte(element)) ? new UByte(element) : THROW_CCE();
    if (!(tmp instanceof UByte))
      return false;
    else {
    }
    var tmp_0 = _UByteArray___get_storage__impl_(this_0);
    return contains_1(tmp_0, _UByte___get_data__impl_(element));
  }
  function UByteArray__contains_impl_0(this_0, element) {
    if (!(element instanceof UByte))
      return false;
    else {
    }
    var tmp = this_0._storage;
    return UByteArray__contains_impl(tmp, element instanceof UByte ? element._data : THROW_CCE());
  }
  function UByteArray__containsAll_impl(this_0, elements) {
    var tmp$ret$0;
    l$ret$1: do {
      var tmp0_all_0 = isInterface(elements, Collection) ? elements : THROW_CCE();
      var tmp;
      if (isInterface(tmp0_all_0, Collection)) {
        tmp = tmp0_all_0.isEmpty_44();
      } else {
        {
          tmp = false;
        }
      }
      if (tmp) {
        tmp$ret$0 = true;
        break l$ret$1;
      } else {
      }
      var tmp0_iterator_1 = tmp0_all_0.iterator_66();
      while (tmp0_iterator_1.hasNext_41()) {
        var element_2 = tmp0_iterator_1.next_46();
        var tmp_0;
        if (element_2 instanceof UByte) {
          var tmp_1 = _UByteArray___get_storage__impl_(this_0);
          var tmp0_toByte_0_4 = element_2._data;
          tmp_0 = contains_1(tmp_1, _UByte___get_data__impl_(tmp0_toByte_0_4));
        } else {
          {
            tmp_0 = false;
          }
        }
        if (!tmp_0) {
          tmp$ret$0 = false;
          break l$ret$1;
        } else {
        }
      }
      tmp$ret$0 = true;
    }
     while (false);
    return tmp$ret$0;
  }
  function UByteArray__containsAll_impl_0(this_0, elements) {
    return UByteArray__containsAll_impl(this_0._storage, elements);
  }
  function UByteArray__isEmpty_impl(this_0) {
    return _UByteArray___get_storage__impl_(this_0).length === 0;
  }
  function UByteArray__toString_impl(this_0) {
    return '' + 'UByteArray(storage=' + toString_2(this_0) + ')';
  }
  function UByteArray__hashCode_impl(this_0) {
    return hashCode(this_0);
  }
  function UByteArray__equals_impl(this_0, other) {
    if (!(other instanceof UByteArray))
      return false;
    else {
    }
    var tmp0_other_with_cast = other instanceof UByteArray ? other._storage : THROW_CCE();
    if (!equals_0(this_0, tmp0_other_with_cast))
      return false;
    return true;
  }
  function UByteArray(storage) {
    this._storage = storage;
  }
  UByteArray.prototype._get_size__52 = function () {
    return _UByteArray___get_size__impl_(this._storage);
  };
  UByteArray.prototype.iterator_66 = function () {
    return UByteArray__iterator_impl(this._storage);
  };
  UByteArray.prototype.contains_42 = function (element) {
    return UByteArray__contains_impl(this._storage, element);
  };
  UByteArray.prototype.contains_51 = function (element) {
    return UByteArray__contains_impl_0(this, element);
  };
  UByteArray.prototype.containsAll_31 = function (elements) {
    return UByteArray__containsAll_impl(this._storage, elements);
  };
  UByteArray.prototype.containsAll_36 = function (elements) {
    return UByteArray__containsAll_impl_0(this, elements);
  };
  UByteArray.prototype.isEmpty_44 = function () {
    return UByteArray__isEmpty_impl(this._storage);
  };
  UByteArray.prototype.toString = function () {
    return UByteArray__toString_impl(this._storage);
  };
  UByteArray.prototype.hashCode = function () {
    return UByteArray__hashCode_impl(this._storage);
  };
  UByteArray.prototype.equals = function (other) {
    return UByteArray__equals_impl(this._storage, other);
  };
  UByteArray.$metadata$ = {
    simpleName: 'UByteArray',
    kind: 'class',
    interfaces: [Collection]
  };
  function _UInt___init__impl_(data) {
    return data;
  }
  function _UInt___get_data__impl_(this_0) {
    return this_0;
  }
  function Companion_5() {
    Companion_instance_4 = this;
    this._MIN_VALUE_0 = _UInt___init__impl_(0);
    this._MAX_VALUE_0 = _UInt___init__impl_(-1);
    this._SIZE_BYTES_0 = 4;
    this._SIZE_BITS_0 = 32;
  }
  Companion_5.$metadata$ = {
    simpleName: 'Companion',
    kind: 'object',
    interfaces: []
  };
  var Companion_instance_4;
  function Companion_getInstance_4() {
    if (Companion_instance_4 == null)
      new Companion_5();
    return Companion_instance_4;
  }
  function UInt__compareTo_impl(this_0, other) {
    return uintCompare(_UInt___get_data__impl_(this_0), _UInt___get_data__impl_(other));
  }
  function UInt__compareTo_impl_0(this_0, other) {
    var tmp = this_0._data_0;
    return UInt__compareTo_impl(tmp, other instanceof UInt ? other._data_0 : THROW_CCE());
  }
  function UInt__toString_impl(this_0) {
    return toLong_0(_UInt___get_data__impl_(this_0)).and(new Long(-1, 0)).toString();
  }
  function UInt__hashCode_impl(this_0) {
    return this_0;
  }
  function UInt__equals_impl(this_0, other) {
    if (!(other instanceof UInt))
      return false;
    else {
    }
    var tmp0_other_with_cast = other instanceof UInt ? other._data_0 : THROW_CCE();
    if (!(this_0 === tmp0_other_with_cast))
      return false;
    return true;
  }
  function UInt(data) {
    Companion_getInstance_4();
    this._data_0 = data;
  }
  UInt.prototype.compareTo_3 = function (other) {
    return UInt__compareTo_impl(this._data_0, other);
  };
  UInt.prototype.compareTo_34 = function (other) {
    return UInt__compareTo_impl_0(this, other);
  };
  UInt.prototype.toString = function () {
    return UInt__toString_impl(this._data_0);
  };
  UInt.prototype.hashCode = function () {
    return UInt__hashCode_impl(this._data_0);
  };
  UInt.prototype.equals = function (other) {
    return UInt__equals_impl(this._data_0, other);
  };
  UInt.$metadata$ = {
    simpleName: 'UInt',
    kind: 'class',
    interfaces: [Comparable]
  };
  function _UIntArray___init__impl_(storage) {
    return storage;
  }
  function _UIntArray___get_storage__impl_(this_0) {
    return this_0;
  }
  function _UIntArray___init__impl__0(size_0) {
    var tmp = _UIntArray___init__impl_(new Int32Array(size_0));
    return tmp;
  }
  function UIntArray__get_impl(this_0, index) {
    var tmp0_toUInt_0 = _UIntArray___get_storage__impl_(this_0)[index];
    return _UInt___init__impl_(tmp0_toUInt_0);
  }
  function UIntArray__set_impl(this_0, index, value_0) {
    var tmp = _UIntArray___get_storage__impl_(this_0);
    tmp[index] = _UInt___get_data__impl_(value_0);
  }
  function _UIntArray___get_size__impl_(this_0) {
    return _UIntArray___get_storage__impl_(this_0).length;
  }
  function UIntArray__iterator_impl(this_0) {
    return new Iterator_0(_UIntArray___get_storage__impl_(this_0));
  }
  function Iterator_0(array) {
    UIntIterator.call(this);
    this._array_0 = array;
    this._index_2 = 0;
  }
  Iterator_0.prototype.hasNext_41 = function () {
    return this._index_2 < this._array_0.length;
  };
  Iterator_0.prototype.nextUInt_0 = function () {
    var tmp;
    if (this._index_2 < this._array_0.length) {
      var tmp0_this = this;
      var tmp1 = tmp0_this._index_2;
      tmp0_this._index_2 = tmp1 + 1 | 0;
      var tmp0_toUInt_0 = this._array_0[tmp1];
      tmp = _UInt___init__impl_(tmp0_toUInt_0);
    } else {
      throw NoSuchElementException_init_$Create$_0(this._index_2.toString());
    }
    return tmp;
  };
  Iterator_0.$metadata$ = {
    simpleName: 'Iterator',
    kind: 'class',
    interfaces: []
  };
  function UIntArray__contains_impl(this_0, element) {
    var tmp = isObject(new UInt(element)) ? new UInt(element) : THROW_CCE();
    if (!(tmp instanceof UInt))
      return false;
    else {
    }
    var tmp_0 = _UIntArray___get_storage__impl_(this_0);
    return contains_0(tmp_0, _UInt___get_data__impl_(element));
  }
  function UIntArray__contains_impl_0(this_0, element) {
    if (!(element instanceof UInt))
      return false;
    else {
    }
    var tmp = this_0._storage_0;
    return UIntArray__contains_impl(tmp, element instanceof UInt ? element._data_0 : THROW_CCE());
  }
  function UIntArray__containsAll_impl(this_0, elements) {
    var tmp$ret$0;
    l$ret$1: do {
      var tmp0_all_0 = isInterface(elements, Collection) ? elements : THROW_CCE();
      var tmp;
      if (isInterface(tmp0_all_0, Collection)) {
        tmp = tmp0_all_0.isEmpty_44();
      } else {
        {
          tmp = false;
        }
      }
      if (tmp) {
        tmp$ret$0 = true;
        break l$ret$1;
      } else {
      }
      var tmp0_iterator_1 = tmp0_all_0.iterator_66();
      while (tmp0_iterator_1.hasNext_41()) {
        var element_2 = tmp0_iterator_1.next_46();
        var tmp_0;
        if (element_2 instanceof UInt) {
          var tmp_1 = _UIntArray___get_storage__impl_(this_0);
          var tmp0_toInt_0_4 = element_2._data_0;
          tmp_0 = contains_0(tmp_1, _UInt___get_data__impl_(tmp0_toInt_0_4));
        } else {
          {
            tmp_0 = false;
          }
        }
        if (!tmp_0) {
          tmp$ret$0 = false;
          break l$ret$1;
        } else {
        }
      }
      tmp$ret$0 = true;
    }
     while (false);
    return tmp$ret$0;
  }
  function UIntArray__containsAll_impl_0(this_0, elements) {
    return UIntArray__containsAll_impl(this_0._storage_0, elements);
  }
  function UIntArray__isEmpty_impl(this_0) {
    return _UIntArray___get_storage__impl_(this_0).length === 0;
  }
  function UIntArray__toString_impl(this_0) {
    return '' + 'UIntArray(storage=' + toString_2(this_0) + ')';
  }
  function UIntArray__hashCode_impl(this_0) {
    return hashCode(this_0);
  }
  function UIntArray__equals_impl(this_0, other) {
    if (!(other instanceof UIntArray))
      return false;
    else {
    }
    var tmp0_other_with_cast = other instanceof UIntArray ? other._storage_0 : THROW_CCE();
    if (!equals_0(this_0, tmp0_other_with_cast))
      return false;
    return true;
  }
  function UIntArray(storage) {
    this._storage_0 = storage;
  }
  UIntArray.prototype._get_size__52 = function () {
    return _UIntArray___get_size__impl_(this._storage_0);
  };
  UIntArray.prototype.iterator_66 = function () {
    return UIntArray__iterator_impl(this._storage_0);
  };
  UIntArray.prototype.contains_15 = function (element) {
    return UIntArray__contains_impl(this._storage_0, element);
  };
  UIntArray.prototype.contains_51 = function (element) {
    return UIntArray__contains_impl_0(this, element);
  };
  UIntArray.prototype.containsAll_13 = function (elements) {
    return UIntArray__containsAll_impl(this._storage_0, elements);
  };
  UIntArray.prototype.containsAll_36 = function (elements) {
    return UIntArray__containsAll_impl_0(this, elements);
  };
  UIntArray.prototype.isEmpty_44 = function () {
    return UIntArray__isEmpty_impl(this._storage_0);
  };
  UIntArray.prototype.toString = function () {
    return UIntArray__toString_impl(this._storage_0);
  };
  UIntArray.prototype.hashCode = function () {
    return UIntArray__hashCode_impl(this._storage_0);
  };
  UIntArray.prototype.equals = function (other) {
    return UIntArray__equals_impl(this._storage_0, other);
  };
  UIntArray.$metadata$ = {
    simpleName: 'UIntArray',
    kind: 'class',
    interfaces: [Collection]
  };
  function UIntIterator() {
  }
  UIntIterator.prototype.next_12 = function () {
    return this.nextUInt_0();
  };
  UIntIterator.prototype.next_46 = function () {
    return new UInt(this.next_12());
  };
  UIntIterator.$metadata$ = {
    simpleName: 'UIntIterator',
    kind: 'class',
    interfaces: [Iterator_2]
  };
  function ULongIterator() {
  }
  ULongIterator.prototype.next_18 = function () {
    return this.nextULong_0();
  };
  ULongIterator.prototype.next_46 = function () {
    return new ULong(this.next_18());
  };
  ULongIterator.$metadata$ = {
    simpleName: 'ULongIterator',
    kind: 'class',
    interfaces: [Iterator_2]
  };
  function UByteIterator() {
  }
  UByteIterator.prototype.next_16 = function () {
    return this.nextUByte_0();
  };
  UByteIterator.prototype.next_46 = function () {
    return new UByte(this.next_16());
  };
  UByteIterator.$metadata$ = {
    simpleName: 'UByteIterator',
    kind: 'class',
    interfaces: [Iterator_2]
  };
  function _ULong___init__impl_(data) {
    return data;
  }
  function _ULong___get_data__impl_(this_0) {
    return this_0;
  }
  function Companion_6() {
    Companion_instance_5 = this;
    this._MIN_VALUE_1 = _ULong___init__impl_(new Long(0, 0));
    this._MAX_VALUE_1 = _ULong___init__impl_(new Long(-1, -1));
    this._SIZE_BYTES_1 = 8;
    this._SIZE_BITS_1 = 64;
  }
  Companion_6.$metadata$ = {
    simpleName: 'Companion',
    kind: 'object',
    interfaces: []
  };
  var Companion_instance_5;
  function Companion_getInstance_5() {
    if (Companion_instance_5 == null)
      new Companion_6();
    return Companion_instance_5;
  }
  function ULong__compareTo_impl(this_0, other) {
    return ulongCompare(_ULong___get_data__impl_(this_0), _ULong___get_data__impl_(other));
  }
  function ULong__compareTo_impl_0(this_0, other) {
    var tmp = this_0._data_1;
    return ULong__compareTo_impl(tmp, other instanceof ULong ? other._data_1 : THROW_CCE());
  }
  function ULong__toString_impl(this_0) {
    return ulongToString(_ULong___get_data__impl_(this_0));
  }
  function ULong__hashCode_impl(this_0) {
    return this_0.hashCode();
  }
  function ULong__equals_impl(this_0, other) {
    if (!(other instanceof ULong))
      return false;
    else {
    }
    var tmp0_other_with_cast = other instanceof ULong ? other._data_1 : THROW_CCE();
    if (!this_0.equals(tmp0_other_with_cast))
      return false;
    return true;
  }
  function ULong(data) {
    Companion_getInstance_5();
    this._data_1 = data;
  }
  ULong.prototype.compareTo_5 = function (other) {
    return ULong__compareTo_impl(this._data_1, other);
  };
  ULong.prototype.compareTo_34 = function (other) {
    return ULong__compareTo_impl_0(this, other);
  };
  ULong.prototype.toString = function () {
    return ULong__toString_impl(this._data_1);
  };
  ULong.prototype.hashCode = function () {
    return ULong__hashCode_impl(this._data_1);
  };
  ULong.prototype.equals = function (other) {
    return ULong__equals_impl(this._data_1, other);
  };
  ULong.$metadata$ = {
    simpleName: 'ULong',
    kind: 'class',
    interfaces: [Comparable]
  };
  function _ULongArray___init__impl_(storage) {
    return storage;
  }
  function _ULongArray___get_storage__impl_(this_0) {
    return this_0;
  }
  function _ULongArray___init__impl__0(size_0) {
    var tmp = _ULongArray___init__impl_(longArray(size_0));
    return tmp;
  }
  function ULongArray__get_impl(this_0, index) {
    var tmp0_toULong_0 = _ULongArray___get_storage__impl_(this_0)[index];
    return _ULong___init__impl_(tmp0_toULong_0);
  }
  function ULongArray__set_impl(this_0, index, value_0) {
    var tmp = _ULongArray___get_storage__impl_(this_0);
    tmp[index] = _ULong___get_data__impl_(value_0);
  }
  function _ULongArray___get_size__impl_(this_0) {
    return _ULongArray___get_storage__impl_(this_0).length;
  }
  function ULongArray__iterator_impl(this_0) {
    return new Iterator_1(_ULongArray___get_storage__impl_(this_0));
  }
  function Iterator_1(array) {
    ULongIterator.call(this);
    this._array_1 = array;
    this._index_3 = 0;
  }
  Iterator_1.prototype.hasNext_41 = function () {
    return this._index_3 < this._array_1.length;
  };
  Iterator_1.prototype.nextULong_0 = function () {
    var tmp;
    if (this._index_3 < this._array_1.length) {
      var tmp0_this = this;
      var tmp1 = tmp0_this._index_3;
      tmp0_this._index_3 = tmp1 + 1 | 0;
      var tmp0_toULong_0 = this._array_1[tmp1];
      tmp = _ULong___init__impl_(tmp0_toULong_0);
    } else {
      throw NoSuchElementException_init_$Create$_0(this._index_3.toString());
    }
    return tmp;
  };
  Iterator_1.$metadata$ = {
    simpleName: 'Iterator',
    kind: 'class',
    interfaces: []
  };
  function ULongArray__contains_impl(this_0, element) {
    var tmp = isObject(new ULong(element)) ? new ULong(element) : THROW_CCE();
    if (!(tmp instanceof ULong))
      return false;
    else {
    }
    var tmp_0 = _ULongArray___get_storage__impl_(this_0);
    return contains(tmp_0, _ULong___get_data__impl_(element));
  }
  function ULongArray__contains_impl_0(this_0, element) {
    if (!(element instanceof ULong))
      return false;
    else {
    }
    var tmp = this_0._storage_1;
    return ULongArray__contains_impl(tmp, element instanceof ULong ? element._data_1 : THROW_CCE());
  }
  function ULongArray__containsAll_impl(this_0, elements) {
    var tmp$ret$0;
    l$ret$1: do {
      var tmp0_all_0 = isInterface(elements, Collection) ? elements : THROW_CCE();
      var tmp;
      if (isInterface(tmp0_all_0, Collection)) {
        tmp = tmp0_all_0.isEmpty_44();
      } else {
        {
          tmp = false;
        }
      }
      if (tmp) {
        tmp$ret$0 = true;
        break l$ret$1;
      } else {
      }
      var tmp0_iterator_1 = tmp0_all_0.iterator_66();
      while (tmp0_iterator_1.hasNext_41()) {
        var element_2 = tmp0_iterator_1.next_46();
        var tmp_0;
        if (element_2 instanceof ULong) {
          var tmp_1 = _ULongArray___get_storage__impl_(this_0);
          var tmp0_toLong_0_4 = element_2._data_1;
          tmp_0 = contains(tmp_1, _ULong___get_data__impl_(tmp0_toLong_0_4));
        } else {
          {
            tmp_0 = false;
          }
        }
        if (!tmp_0) {
          tmp$ret$0 = false;
          break l$ret$1;
        } else {
        }
      }
      tmp$ret$0 = true;
    }
     while (false);
    return tmp$ret$0;
  }
  function ULongArray__containsAll_impl_0(this_0, elements) {
    return ULongArray__containsAll_impl(this_0._storage_1, elements);
  }
  function ULongArray__isEmpty_impl(this_0) {
    return _ULongArray___get_storage__impl_(this_0).length === 0;
  }
  function ULongArray__toString_impl(this_0) {
    return '' + 'ULongArray(storage=' + toString_2(this_0) + ')';
  }
  function ULongArray__hashCode_impl(this_0) {
    return hashCode(this_0);
  }
  function ULongArray__equals_impl(this_0, other) {
    if (!(other instanceof ULongArray))
      return false;
    else {
    }
    var tmp0_other_with_cast = other instanceof ULongArray ? other._storage_1 : THROW_CCE();
    if (!equals_0(this_0, tmp0_other_with_cast))
      return false;
    return true;
  }
  function ULongArray(storage) {
    this._storage_1 = storage;
  }
  ULongArray.prototype._get_size__52 = function () {
    return _ULongArray___get_size__impl_(this._storage_1);
  };
  ULongArray.prototype.iterator_66 = function () {
    return ULongArray__iterator_impl(this._storage_1);
  };
  ULongArray.prototype.contains_40 = function (element) {
    return ULongArray__contains_impl(this._storage_1, element);
  };
  ULongArray.prototype.contains_51 = function (element) {
    return ULongArray__contains_impl_0(this, element);
  };
  ULongArray.prototype.containsAll_30 = function (elements) {
    return ULongArray__containsAll_impl(this._storage_1, elements);
  };
  ULongArray.prototype.containsAll_36 = function (elements) {
    return ULongArray__containsAll_impl_0(this, elements);
  };
  ULongArray.prototype.isEmpty_44 = function () {
    return ULongArray__isEmpty_impl(this._storage_1);
  };
  ULongArray.prototype.toString = function () {
    return ULongArray__toString_impl(this._storage_1);
  };
  ULongArray.prototype.hashCode = function () {
    return ULongArray__hashCode_impl(this._storage_1);
  };
  ULongArray.prototype.equals = function (other) {
    return ULongArray__equals_impl(this._storage_1, other);
  };
  ULongArray.$metadata$ = {
    simpleName: 'ULongArray',
    kind: 'class',
    interfaces: [Collection]
  };
  function _UShort___init__impl_(data) {
    return data;
  }
  function _UShort___get_data__impl_(this_0) {
    return this_0;
  }
  function UShort() {
  }
  UShort.$metadata$ = {
    simpleName: 'UShort',
    kind: 'class',
    interfaces: [Comparable]
  };
  function toString_0(_this_, radix) {
    return ulongToString_0(_ULong___get_data__impl_(_this_), checkRadix(radix));
  }
  function uintCompare(v1, v2) {
    return compareTo(v1 ^ IntCompanionObject_getInstance()._MIN_VALUE_2, v2 ^ IntCompanionObject_getInstance()._MIN_VALUE_2);
  }
  function uintDivide(v1, v2) {
    var tmp = toLong_0(_UInt___get_data__impl_(v1)).and(new Long(-1, 0));
    var tmp0_toUInt_0 = tmp.div_34(toLong_0(_UInt___get_data__impl_(v2)).and(new Long(-1, 0)));
    return _UInt___init__impl_(tmp0_toUInt_0.toInt_5());
  }
  function uintRemainder(v1, v2) {
    var tmp = toLong_0(_UInt___get_data__impl_(v1)).and(new Long(-1, 0));
    var tmp0_toUInt_0 = tmp.rem_33(toLong_0(_UInt___get_data__impl_(v2)).and(new Long(-1, 0)));
    return _UInt___init__impl_(tmp0_toUInt_0.toInt_5());
  }
  function ulongCompare(v1, v2) {
    Companion_getInstance_10();
    var tmp = v1.xor(new Long(0, -2147483648));
    Companion_getInstance_10();
    return tmp.compareTo_75(v2.xor(new Long(0, -2147483648)));
  }
  function ulongDivide(v1, v2) {
    var dividend = _ULong___get_data__impl_(v1);
    var divisor = _ULong___get_data__impl_(v2);
    if (divisor.compareTo_75(new Long(0, 0)) < 0) {
      var tmp;
      if (ulongCompare(_ULong___get_data__impl_(v1), _ULong___get_data__impl_(v2)) < 0) {
        tmp = _ULong___init__impl_(new Long(0, 0));
      } else {
        {
          tmp = _ULong___init__impl_(new Long(1, 0));
        }
      }
      return tmp;
    }if (dividend.compareTo_75(new Long(0, 0)) >= 0) {
      return _ULong___init__impl_(dividend.div_34(divisor));
    }var quotient = dividend.ushr_0(1).div_34(divisor).shl_4(1);
    var rem = dividend.minus_40(quotient.times_40(divisor));
    var tmp_0;
    var tmp0_compareTo_0 = _ULong___init__impl_(rem);
    var tmp1_compareTo_0 = _ULong___init__impl_(divisor);
    if (ulongCompare(_ULong___get_data__impl_(tmp0_compareTo_0), _ULong___get_data__impl_(tmp1_compareTo_0)) >= 0) {
      tmp_0 = 1;
    } else {
      {
        tmp_0 = 0;
      }
    }
    var tmp2_plus_0 = tmp_0;
    return _ULong___init__impl_(quotient.plus_71(toLong_0(tmp2_plus_0)));
  }
  function ulongRemainder(v1, v2) {
    var dividend = _ULong___get_data__impl_(v1);
    var divisor = _ULong___get_data__impl_(v2);
    if (divisor.compareTo_75(new Long(0, 0)) < 0) {
      var tmp;
      if (ulongCompare(_ULong___get_data__impl_(v1), _ULong___get_data__impl_(v2)) < 0) {
        tmp = v1;
      } else {
        {
          tmp = _ULong___init__impl_(_ULong___get_data__impl_(v1).minus_40(_ULong___get_data__impl_(v2)));
        }
      }
      return tmp;
    }if (dividend.compareTo_75(new Long(0, 0)) >= 0) {
      return _ULong___init__impl_(dividend.rem_33(divisor));
    }var quotient = dividend.ushr_0(1).div_34(divisor).shl_4(1);
    var rem = dividend.minus_40(quotient.times_40(divisor));
    var tmp_0;
    var tmp0_compareTo_0 = _ULong___init__impl_(rem);
    var tmp1_compareTo_0 = _ULong___init__impl_(divisor);
    if (ulongCompare(_ULong___get_data__impl_(tmp0_compareTo_0), _ULong___get_data__impl_(tmp1_compareTo_0)) >= 0) {
      tmp_0 = divisor;
    } else {
      {
        tmp_0 = new Long(0, 0);
      }
    }
    return _ULong___init__impl_(rem.minus_40(tmp_0));
  }
  function ulongToString(v) {
    return ulongToString_0(v, 10);
  }
  function ulongToString_0(v, base) {
    if (v.compareTo_75(new Long(0, 0)) >= 0)
      return toString_3(v, base);
    var tmp0_div_0 = v.ushr_0(1);
    var quotient = tmp0_div_0.div_34(toLong_0(base)).shl_4(1);
    var tmp1_times_0 = quotient;
    var rem = v.minus_40(tmp1_times_0.times_40(toLong_0(base)));
    if (rem.compareTo_75(toLong_0(base)) >= 0) {
      var tmp2_minus_0 = rem;
      rem = tmp2_minus_0.minus_40(toLong_0(base));
      var tmp3_plus_0 = quotient;
      var tmp4_plus_0 = 1;
      quotient = tmp3_plus_0.plus_71(toLong_0(tmp4_plus_0));
    }return toString_3(quotient, base) + toString_3(rem, base);
  }
  function CharSequence() {
  }
  CharSequence.$metadata$ = {
    simpleName: 'CharSequence',
    kind: 'interface',
    interfaces: []
  };
  function Comparable() {
  }
  Comparable.$metadata$ = {
    simpleName: 'Comparable',
    kind: 'interface',
    interfaces: []
  };
  function Iterator_2() {
  }
  Iterator_2.$metadata$ = {
    simpleName: 'Iterator',
    kind: 'interface',
    interfaces: []
  };
  function MutableIterator() {
  }
  MutableIterator.$metadata$ = {
    simpleName: 'MutableIterator',
    kind: 'interface',
    interfaces: [Iterator_2]
  };
  function ListIterator() {
  }
  ListIterator.$metadata$ = {
    simpleName: 'ListIterator',
    kind: 'interface',
    interfaces: [Iterator_2]
  };
  function MutableListIterator() {
  }
  MutableListIterator.$metadata$ = {
    simpleName: 'MutableListIterator',
    kind: 'interface',
    interfaces: [ListIterator, MutableIterator]
  };
  function Number_0() {
  }
  Number_0.$metadata$ = {
    simpleName: 'Number',
    kind: 'class',
    interfaces: []
  };
  function IntIterator() {
  }
  IntIterator.prototype.next_46 = function () {
    return this.nextInt_1();
  };
  IntIterator.$metadata$ = {
    simpleName: 'IntIterator',
    kind: 'class',
    interfaces: [Iterator_2]
  };
  function IntProgressionIterator(first_4, last_2, step_0) {
    IntIterator.call(this);
    this._step = step_0;
    this._finalElement = last_2;
    this._hasNext = this._step > 0 ? first_4 <= last_2 : first_4 >= last_2;
    this._next_0 = this._hasNext ? first_4 : this._finalElement;
  }
  IntProgressionIterator.prototype.hasNext_41 = function () {
    return this._hasNext;
  };
  IntProgressionIterator.prototype.nextInt_1 = function () {
    var value_0 = this._next_0;
    if (value_0 === this._finalElement) {
      if (!this._hasNext)
        throw NoSuchElementException_init_$Create$();
      this._hasNext = false;
    } else {
      var tmp0_this = this;
      tmp0_this._next_0 = tmp0_this._next_0 + this._step | 0;
    }
    return value_0;
  };
  IntProgressionIterator.$metadata$ = {
    simpleName: 'IntProgressionIterator',
    kind: 'class',
    interfaces: []
  };
  function Companion_7() {
    Companion_instance_6 = this;
  }
  Companion_7.prototype.fromClosedRange = function (rangeStart, rangeEnd, step_0) {
    return new IntProgression(rangeStart, rangeEnd, step_0);
  };
  Companion_7.$metadata$ = {
    simpleName: 'Companion',
    kind: 'object',
    interfaces: []
  };
  var Companion_instance_6;
  function Companion_getInstance_6() {
    if (Companion_instance_6 == null)
      new Companion_7();
    return Companion_instance_6;
  }
  function IntProgression(start_0, endInclusive, step_0) {
    Companion_getInstance_6();
    if (step_0 === 0)
      throw IllegalArgumentException_init_$Create$_0('Step must be non-zero.');
    if (step_0 === IntCompanionObject_getInstance()._MIN_VALUE_2)
      throw IllegalArgumentException_init_$Create$_0('Step must be greater than Int.MIN_VALUE to avoid overflow on negation.');
    this._first_1 = start_0;
    this._last = getProgressionLastElement(start_0, endInclusive, step_0);
    this._step_0 = step_0;
  }
  IntProgression.prototype._get_first__1 = function () {
    return this._first_1;
  };
  IntProgression.prototype._get_last__0 = function () {
    return this._last;
  };
  IntProgression.prototype.iterator_66 = function () {
    return new IntProgressionIterator(this._first_1, this._last, this._step_0);
  };
  IntProgression.prototype.isEmpty_44 = function () {
    return this._step_0 > 0 ? this._first_1 > this._last : this._first_1 < this._last;
  };
  IntProgression.prototype.equals = function (other) {
    var tmp;
    if (other instanceof IntProgression) {
      tmp = (this.isEmpty_44() ? other.isEmpty_44() : false) ? true : (this._first_1 === other._first_1 ? this._last === other._last : false) ? this._step_0 === other._step_0 : false;
    } else {
      {
        tmp = false;
      }
    }
    return tmp;
  };
  IntProgression.prototype.hashCode = function () {
    return this.isEmpty_44() ? -1 : imul(31, imul(31, this._first_1) + this._last | 0) + this._step_0 | 0;
  };
  IntProgression.prototype.toString = function () {
    return this._step_0 > 0 ? '' + this._first_1 + '..' + this._last + ' step ' + this._step_0 : '' + this._first_1 + ' downTo ' + this._last + ' step ' + (-this._step_0 | 0);
  };
  IntProgression.$metadata$ = {
    simpleName: 'IntProgression',
    kind: 'class',
    interfaces: [Iterable]
  };
  function ClosedRange() {
  }
  ClosedRange.$metadata$ = {
    simpleName: 'ClosedRange',
    kind: 'interface',
    interfaces: []
  };
  function Companion_8() {
    Companion_instance_7 = this;
    this._EMPTY = new IntRange(1, 0);
  }
  Companion_8.$metadata$ = {
    simpleName: 'Companion',
    kind: 'object',
    interfaces: []
  };
  var Companion_instance_7;
  function Companion_getInstance_7() {
    if (Companion_instance_7 == null)
      new Companion_8();
    return Companion_instance_7;
  }
  function IntRange(start_0, endInclusive) {
    Companion_getInstance_7();
    IntProgression.call(this, start_0, endInclusive, 1);
  }
  IntRange.prototype._get_start__1 = function () {
    return this._get_first__1();
  };
  IntRange.prototype._get_endInclusive_ = function () {
    return this._get_last__0();
  };
  IntRange.prototype.isEmpty_44 = function () {
    return this._get_first__1() > this._get_last__0();
  };
  IntRange.prototype.equals = function (other) {
    var tmp;
    if (other instanceof IntRange) {
      tmp = (this.isEmpty_44() ? other.isEmpty_44() : false) ? true : this._get_first__1() === other._get_first__1() ? this._get_last__0() === other._get_last__0() : false;
    } else {
      {
        tmp = false;
      }
    }
    return tmp;
  };
  IntRange.prototype.hashCode = function () {
    return this.isEmpty_44() ? -1 : imul(31, this._get_first__1()) + this._get_last__0() | 0;
  };
  IntRange.prototype.toString = function () {
    return '' + this._get_first__1() + '..' + this._get_last__0();
  };
  IntRange.$metadata$ = {
    simpleName: 'IntRange',
    kind: 'class',
    interfaces: [ClosedRange]
  };
  function Unit() {
    Unit_instance = this;
  }
  Unit.prototype.toString = function () {
    return 'kotlin.Unit';
  };
  Unit.$metadata$ = {
    simpleName: 'Unit',
    kind: 'object',
    interfaces: []
  };
  var Unit_instance;
  function Unit_getInstance() {
    if (Unit_instance == null)
      new Unit();
    return Unit_instance;
  }
  function getProgressionLastElement(start_0, end_0, step_0) {
    var tmp;
    if (step_0 > 0) {
      tmp = start_0 >= end_0 ? end_0 : end_0 - differenceModulo(end_0, start_0, step_0) | 0;
    } else if (step_0 < 0) {
      tmp = start_0 <= end_0 ? end_0 : end_0 + differenceModulo(start_0, end_0, -step_0 | 0) | 0;
    } else {
      throw IllegalArgumentException_init_$Create$_0('Step is zero.');
    }
    return tmp;
  }
  function differenceModulo(a, b, c) {
    return mod(mod(a, c) - mod(b, c) | 0, c);
  }
  function mod(a, b) {
    var mod_0 = a % b;
    return mod_0 >= 0 ? mod_0 : mod_0 + b | 0;
  }
  function IntCompanionObject_0() {
    IntCompanionObject_instance = this;
    this._MIN_VALUE_2 = -2147483648;
    this._MAX_VALUE_2 = 2147483647;
    this._SIZE_BYTES_2 = 4;
    this._SIZE_BITS_2 = 32;
  }
  IntCompanionObject_0.prototype._get_MIN_VALUE__1 = function () {
    return this._MIN_VALUE_2;
  };
  IntCompanionObject_0.prototype._get_MAX_VALUE__1 = function () {
    return this._MAX_VALUE_2;
  };
  IntCompanionObject_0.prototype._get_SIZE_BYTES__1 = function () {
    return this._SIZE_BYTES_2;
  };
  IntCompanionObject_0.prototype._get_SIZE_BITS__1 = function () {
    return this._SIZE_BITS_2;
  };
  IntCompanionObject_0.$metadata$ = {
    simpleName: 'IntCompanionObject',
    kind: 'object',
    interfaces: []
  };
  Object.defineProperty(IntCompanionObject_0.prototype, 'MIN_VALUE', {
    configurable: true,
    get: IntCompanionObject_0.prototype._get_MIN_VALUE__1
  });
  Object.defineProperty(IntCompanionObject_0.prototype, 'MAX_VALUE', {
    configurable: true,
    get: IntCompanionObject_0.prototype._get_MAX_VALUE__1
  });
  Object.defineProperty(IntCompanionObject_0.prototype, 'SIZE_BYTES', {
    configurable: true,
    get: IntCompanionObject_0.prototype._get_SIZE_BYTES__1
  });
  Object.defineProperty(IntCompanionObject_0.prototype, 'SIZE_BITS', {
    configurable: true,
    get: IntCompanionObject_0.prototype._get_SIZE_BITS__1
  });
  var IntCompanionObject_instance;
  function IntCompanionObject_getInstance() {
    if (IntCompanionObject_instance == null)
      new IntCompanionObject_0();
    return IntCompanionObject_instance;
  }
  function FloatCompanionObject_0() {
    FloatCompanionObject_instance = this;
    this._MIN_VALUE_3 = 1.4E-45;
    this._MAX_VALUE_3 = 3.4028235E38;
    this._POSITIVE_INFINITY = Infinity;
    this._NEGATIVE_INFINITY = -Infinity;
    this._NaN = NaN;
    this._SIZE_BYTES_3 = 4;
    this._SIZE_BITS_3 = 32;
  }
  FloatCompanionObject_0.prototype._get_MIN_VALUE__1 = function () {
    return this._MIN_VALUE_3;
  };
  FloatCompanionObject_0.prototype._get_MAX_VALUE__1 = function () {
    return this._MAX_VALUE_3;
  };
  FloatCompanionObject_0.prototype._get_POSITIVE_INFINITY__0 = function () {
    return this._POSITIVE_INFINITY;
  };
  FloatCompanionObject_0.prototype._get_NEGATIVE_INFINITY__0 = function () {
    return this._NEGATIVE_INFINITY;
  };
  FloatCompanionObject_0.prototype._get_NaN__0 = function () {
    return this._NaN;
  };
  FloatCompanionObject_0.prototype._get_SIZE_BYTES__1 = function () {
    return this._SIZE_BYTES_3;
  };
  FloatCompanionObject_0.prototype._get_SIZE_BITS__1 = function () {
    return this._SIZE_BITS_3;
  };
  FloatCompanionObject_0.$metadata$ = {
    simpleName: 'FloatCompanionObject',
    kind: 'object',
    interfaces: []
  };
  Object.defineProperty(FloatCompanionObject_0.prototype, 'MIN_VALUE', {
    configurable: true,
    get: FloatCompanionObject_0.prototype._get_MIN_VALUE__1
  });
  Object.defineProperty(FloatCompanionObject_0.prototype, 'MAX_VALUE', {
    configurable: true,
    get: FloatCompanionObject_0.prototype._get_MAX_VALUE__1
  });
  Object.defineProperty(FloatCompanionObject_0.prototype, 'POSITIVE_INFINITY', {
    configurable: true,
    get: FloatCompanionObject_0.prototype._get_POSITIVE_INFINITY__0
  });
  Object.defineProperty(FloatCompanionObject_0.prototype, 'NEGATIVE_INFINITY', {
    configurable: true,
    get: FloatCompanionObject_0.prototype._get_NEGATIVE_INFINITY__0
  });
  Object.defineProperty(FloatCompanionObject_0.prototype, 'NaN', {
    configurable: true,
    get: FloatCompanionObject_0.prototype._get_NaN__0
  });
  Object.defineProperty(FloatCompanionObject_0.prototype, 'SIZE_BYTES', {
    configurable: true,
    get: FloatCompanionObject_0.prototype._get_SIZE_BYTES__1
  });
  Object.defineProperty(FloatCompanionObject_0.prototype, 'SIZE_BITS', {
    configurable: true,
    get: FloatCompanionObject_0.prototype._get_SIZE_BITS__1
  });
  var FloatCompanionObject_instance;
  function FloatCompanionObject_getInstance() {
    if (FloatCompanionObject_instance == null)
      new FloatCompanionObject_0();
    return FloatCompanionObject_instance;
  }
  function DoubleCompanionObject_0() {
    DoubleCompanionObject_instance = this;
    this._MIN_VALUE_4 = 4.9E-324;
    this._MAX_VALUE_4 = 1.7976931348623157E308;
    this._POSITIVE_INFINITY_0 = Infinity;
    this._NEGATIVE_INFINITY_0 = -Infinity;
    this._NaN_0 = NaN;
    this._SIZE_BYTES_4 = 8;
    this._SIZE_BITS_4 = 64;
  }
  DoubleCompanionObject_0.prototype._get_MIN_VALUE__1 = function () {
    return this._MIN_VALUE_4;
  };
  DoubleCompanionObject_0.prototype._get_MAX_VALUE__1 = function () {
    return this._MAX_VALUE_4;
  };
  DoubleCompanionObject_0.prototype._get_POSITIVE_INFINITY__0 = function () {
    return this._POSITIVE_INFINITY_0;
  };
  DoubleCompanionObject_0.prototype._get_NEGATIVE_INFINITY__0 = function () {
    return this._NEGATIVE_INFINITY_0;
  };
  DoubleCompanionObject_0.prototype._get_NaN__0 = function () {
    return this._NaN_0;
  };
  DoubleCompanionObject_0.prototype._get_SIZE_BYTES__1 = function () {
    return this._SIZE_BYTES_4;
  };
  DoubleCompanionObject_0.prototype._get_SIZE_BITS__1 = function () {
    return this._SIZE_BITS_4;
  };
  DoubleCompanionObject_0.$metadata$ = {
    simpleName: 'DoubleCompanionObject',
    kind: 'object',
    interfaces: []
  };
  Object.defineProperty(DoubleCompanionObject_0.prototype, 'MIN_VALUE', {
    configurable: true,
    get: DoubleCompanionObject_0.prototype._get_MIN_VALUE__1
  });
  Object.defineProperty(DoubleCompanionObject_0.prototype, 'MAX_VALUE', {
    configurable: true,
    get: DoubleCompanionObject_0.prototype._get_MAX_VALUE__1
  });
  Object.defineProperty(DoubleCompanionObject_0.prototype, 'POSITIVE_INFINITY', {
    configurable: true,
    get: DoubleCompanionObject_0.prototype._get_POSITIVE_INFINITY__0
  });
  Object.defineProperty(DoubleCompanionObject_0.prototype, 'NEGATIVE_INFINITY', {
    configurable: true,
    get: DoubleCompanionObject_0.prototype._get_NEGATIVE_INFINITY__0
  });
  Object.defineProperty(DoubleCompanionObject_0.prototype, 'NaN', {
    configurable: true,
    get: DoubleCompanionObject_0.prototype._get_NaN__0
  });
  Object.defineProperty(DoubleCompanionObject_0.prototype, 'SIZE_BYTES', {
    configurable: true,
    get: DoubleCompanionObject_0.prototype._get_SIZE_BYTES__1
  });
  Object.defineProperty(DoubleCompanionObject_0.prototype, 'SIZE_BITS', {
    configurable: true,
    get: DoubleCompanionObject_0.prototype._get_SIZE_BITS__1
  });
  var DoubleCompanionObject_instance;
  function DoubleCompanionObject_getInstance() {
    if (DoubleCompanionObject_instance == null)
      new DoubleCompanionObject_0();
    return DoubleCompanionObject_instance;
  }
  function Comparator() {
  }
  Comparator.$metadata$ = {
    simpleName: 'Comparator',
    kind: 'interface',
    interfaces: []
  };
  function listOf_0(element) {
    return arrayListOf([element]);
  }
  function setOf(element) {
    return hashSetOf([element]);
  }
  function checkIndexOverflow(index) {
    if (index < 0) {
      throwIndexOverflow();
    }return index;
  }
  function mapCapacity(expectedSize) {
    return expectedSize;
  }
  function sortWith(_this_, comparator) {
    collectionsSort(_this_, comparator);
  }
  function copyToArray_0(collection) {
    var tmp;
    if (collection.toArray !== undefined) {
      var tmp0_unsafeCast_0 = collection.toArray();
      tmp = tmp0_unsafeCast_0;
    } else {
      {
        var tmp1_unsafeCast_0 = copyToArrayImpl_0(collection);
        tmp = tmp1_unsafeCast_0;
      }
    }
    return tmp;
  }
  function collectionsSort(list, comparator) {
    if (list._get_size__52() <= 1)
      return Unit_getInstance();
    var array = copyToArray_0(list);
    sortArrayWith(array, comparator);
    var inductionVariable = 0;
    var last_2 = array.length;
    if (inductionVariable < last_2)
      do {
        var i = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        list.set_3(i, array[i]);
        Unit_getInstance();
      }
       while (inductionVariable < last_2);
  }
  function copyToArrayImpl_0(collection) {
    var array = [];
    var iterator_0 = collection.iterator_66();
    while (iterator_0.hasNext_41()) {
      array.push(iterator_0.next_46());
    }
    return array;
  }
  function mapOf_0(pair) {
    return hashMapOf([pair]);
  }
  function arrayCopy_0(source, destination, destinationOffset, startIndex, endIndex) {
    Companion_getInstance().checkRangeIndexes(startIndex, endIndex, source.length);
    var rangeSize = endIndex - startIndex | 0;
    Companion_getInstance().checkRangeIndexes(destinationOffset, destinationOffset + rangeSize | 0, destination.length);
    if (ArrayBuffer.isView(destination) && ArrayBuffer.isView(source)) {
      var subrange = source.subarray(startIndex, endIndex);
      destination.set(subrange, destinationOffset);
    } else {
      if (!(source === destination) ? true : destinationOffset <= startIndex) {
        var inductionVariable = 0;
        if (inductionVariable < rangeSize)
          do {
            var index = inductionVariable;
            inductionVariable = inductionVariable + 1 | 0;
            destination[destinationOffset + index | 0] = source[startIndex + index | 0];
          }
           while (inductionVariable < rangeSize);
      } else {
        var inductionVariable_0 = rangeSize - 1 | 0;
        if (0 <= inductionVariable_0)
          do {
            var index_0 = inductionVariable_0;
            inductionVariable_0 = inductionVariable_0 + -1 | 0;
            destination[destinationOffset + index_0 | 0] = source[startIndex + index_0 | 0];
          }
           while (0 <= inductionVariable_0);
      }
    }
  }
  function checkCountOverflow(count_0) {
    if (count_0 < 0) {
      throwCountOverflow();
    }return count_0;
  }
  function _no_name_provided__22($elements) {
    this._$elements = $elements;
  }
  _no_name_provided__22.prototype.invoke_33 = function (it) {
    return this._$elements.contains_51(it);
  };
  _no_name_provided__22.prototype.invoke_1115 = function (p1) {
    return this.invoke_33((p1 == null ? true : isObject(p1)) ? p1 : THROW_CCE());
  };
  _no_name_provided__22.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__23($elements) {
    this._$elements_0 = $elements;
  }
  _no_name_provided__23.prototype.invoke_33 = function (it) {
    return !this._$elements_0.contains_51(it);
  };
  _no_name_provided__23.prototype.invoke_1115 = function (p1) {
    return this.invoke_33((p1 == null ? true : isObject(p1)) ? p1 : THROW_CCE());
  };
  _no_name_provided__23.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function AbstractMutableCollection() {
    AbstractCollection.call(this);
  }
  AbstractMutableCollection.prototype.remove_46 = function (element) {
    this.checkIsMutable_13();
    var iterator_0 = this.iterator_66();
    while (iterator_0.hasNext_41()) {
      if (equals_0(iterator_0.next_46(), element)) {
        iterator_0.remove_22();
        return true;
      }}
    return false;
  };
  AbstractMutableCollection.prototype.addAll_14 = function (elements) {
    this.checkIsMutable_13();
    var modified = false;
    var tmp0_iterator = elements.iterator_66();
    while (tmp0_iterator.hasNext_41()) {
      var element = tmp0_iterator.next_46();
      if (this.add_39(element))
        modified = true;
    }
    return modified;
  };
  AbstractMutableCollection.prototype.removeAll_12 = function (elements) {
    this.checkIsMutable_13();
    var tmp = isInterface(this, MutableIterable) ? this : THROW_CCE();
    return removeAll(tmp, _no_name_provided_$factory_13(elements));
  };
  AbstractMutableCollection.prototype.retainAll_11 = function (elements) {
    this.checkIsMutable_13();
    var tmp = isInterface(this, MutableIterable) ? this : THROW_CCE();
    return removeAll(tmp, _no_name_provided_$factory_14(elements));
  };
  AbstractMutableCollection.prototype.toJSON = function () {
    return this.toArray();
  };
  AbstractMutableCollection.prototype.checkIsMutable_13 = function () {
  };
  AbstractMutableCollection.$metadata$ = {
    simpleName: 'AbstractMutableCollection',
    kind: 'class',
    interfaces: [MutableCollection]
  };
  function _no_name_provided_$factory_13($elements) {
    var i = new _no_name_provided__22($elements);
    return function (p1) {
      return i.invoke_33(p1);
    };
  }
  function _no_name_provided_$factory_14($elements) {
    var i = new _no_name_provided__23($elements);
    return function (p1) {
      return i.invoke_33(p1);
    };
  }
  function IteratorImpl_0($outer) {
    this._$this_0 = $outer;
    this._index_4 = 0;
    this._last_0 = -1;
  }
  IteratorImpl_0.prototype._set_index__3 = function (_set___) {
    this._index_4 = _set___;
  };
  IteratorImpl_0.prototype.hasNext_41 = function () {
    return this._index_4 < this._$this_0._get_size__52();
  };
  IteratorImpl_0.prototype.next_46 = function () {
    if (!this.hasNext_41())
      throw NoSuchElementException_init_$Create$();
    var tmp = this;
    var tmp0_this = this;
    var tmp1 = tmp0_this._index_4;
    tmp0_this._index_4 = tmp1 + 1 | 0;
    tmp._last_0 = tmp1;
    return this._$this_0.get_72(this._last_0);
  };
  IteratorImpl_0.prototype.remove_22 = function () {
    var tmp0_check_0 = !(this._last_0 === -1);
    if (!tmp0_check_0) {
      var message_1 = 'Call next() or previous() before removing element from the iterator.';
      throw IllegalStateException_init_$Create$_0(toString_2(message_1));
    }this._$this_0.removeAt_2(this._last_0);
    Unit_getInstance();
    this._index_4 = this._last_0;
    this._last_0 = -1;
  };
  IteratorImpl_0.$metadata$ = {
    simpleName: 'IteratorImpl',
    kind: 'class',
    interfaces: [MutableIterator]
  };
  function ListIteratorImpl($outer, index) {
    this._$this_1 = $outer;
    IteratorImpl_0.call(this, $outer);
    Companion_getInstance().checkPositionIndex(index, this._$this_1._get_size__52());
    this._set_index__3(index);
  }
  ListIteratorImpl.$metadata$ = {
    simpleName: 'ListIteratorImpl',
    kind: 'class',
    interfaces: [MutableListIterator]
  };
  function SubList_0(list, fromIndex, toIndex) {
    AbstractMutableList.call(this);
    this._list_0 = list;
    this._fromIndex_0 = fromIndex;
    this.__size_0 = 0;
    Companion_getInstance().checkRangeIndexes(this._fromIndex_0, toIndex, this._list_0._get_size__52());
    this.__size_0 = toIndex - this._fromIndex_0 | 0;
  }
  SubList_0.prototype.add_21 = function (index, element) {
    Companion_getInstance().checkPositionIndex(index, this.__size_0);
    this._list_0.add_21(this._fromIndex_0 + index | 0, element);
    var tmp0_this = this;
    var tmp1 = tmp0_this.__size_0;
    tmp0_this.__size_0 = tmp1 + 1 | 0;
    Unit_getInstance();
  };
  SubList_0.prototype.get_72 = function (index) {
    Companion_getInstance().checkElementIndex(index, this.__size_0);
    return this._list_0.get_72(this._fromIndex_0 + index | 0);
  };
  SubList_0.prototype.removeAt_2 = function (index) {
    Companion_getInstance().checkElementIndex(index, this.__size_0);
    var result = this._list_0.removeAt_2(this._fromIndex_0 + index | 0);
    var tmp0_this = this;
    var tmp1 = tmp0_this.__size_0;
    tmp0_this.__size_0 = tmp1 - 1 | 0;
    Unit_getInstance();
    return result;
  };
  SubList_0.prototype.set_3 = function (index, element) {
    Companion_getInstance().checkElementIndex(index, this.__size_0);
    return this._list_0.set_3(this._fromIndex_0 + index | 0, element);
  };
  SubList_0.prototype._get_size__52 = function () {
    return this.__size_0;
  };
  SubList_0.prototype.checkIsMutable_13 = function () {
    return this._list_0.checkIsMutable_13();
  };
  SubList_0.$metadata$ = {
    simpleName: 'SubList',
    kind: 'class',
    interfaces: [RandomAccess]
  };
  function _no_name_provided__24($elements) {
    this._$elements_1 = $elements;
  }
  _no_name_provided__24.prototype.invoke_33 = function (it) {
    return this._$elements_1.contains_51(it);
  };
  _no_name_provided__24.prototype.invoke_1115 = function (p1) {
    return this.invoke_33((p1 == null ? true : isObject(p1)) ? p1 : THROW_CCE());
  };
  _no_name_provided__24.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__25($elements) {
    this._$elements_2 = $elements;
  }
  _no_name_provided__25.prototype.invoke_33 = function (it) {
    return !this._$elements_2.contains_51(it);
  };
  _no_name_provided__25.prototype.invoke_1115 = function (p1) {
    return this.invoke_33((p1 == null ? true : isObject(p1)) ? p1 : THROW_CCE());
  };
  _no_name_provided__25.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function AbstractMutableList() {
    AbstractMutableCollection.call(this);
    this._modCount = 0;
  }
  AbstractMutableList.prototype._set_modCount__0 = function (_set___) {
    this._modCount = _set___;
  };
  AbstractMutableList.prototype._get_modCount__0 = function () {
    return this._modCount;
  };
  AbstractMutableList.prototype.add_39 = function (element) {
    this.checkIsMutable_13();
    this.add_21(this._get_size__52(), element);
    return true;
  };
  AbstractMutableList.prototype.clear_9 = function () {
    this.checkIsMutable_13();
    this.removeRange_1(0, this._get_size__52());
  };
  AbstractMutableList.prototype.removeAll_12 = function (elements) {
    this.checkIsMutable_13();
    return removeAll_0(this, _no_name_provided_$factory_15(elements));
  };
  AbstractMutableList.prototype.retainAll_11 = function (elements) {
    this.checkIsMutable_13();
    return removeAll_0(this, _no_name_provided_$factory_16(elements));
  };
  AbstractMutableList.prototype.iterator_66 = function () {
    return new IteratorImpl_0(this);
  };
  AbstractMutableList.prototype.contains_51 = function (element) {
    return this.indexOf_1(element) >= 0;
  };
  AbstractMutableList.prototype.indexOf_1 = function (element) {
    var inductionVariable = 0;
    var last_2 = _get_lastIndex__2(this);
    if (inductionVariable <= last_2)
      do {
        var index = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        if (equals_0(this.get_72(index), element)) {
          return index;
        }}
       while (!(index === last_2));
    return -1;
  };
  AbstractMutableList.prototype.lastIndexOf_2 = function (element) {
    var inductionVariable = _get_lastIndex__2(this);
    if (0 <= inductionVariable)
      do {
        var index = inductionVariable;
        inductionVariable = inductionVariable + -1 | 0;
        if (equals_0(this.get_72(index), element)) {
          return index;
        }}
       while (0 <= inductionVariable);
    return -1;
  };
  AbstractMutableList.prototype.listIterator_1 = function (index) {
    return new ListIteratorImpl(this, index);
  };
  AbstractMutableList.prototype.subList_8 = function (fromIndex, toIndex) {
    return new SubList_0(this, fromIndex, toIndex);
  };
  AbstractMutableList.prototype.removeRange_1 = function (fromIndex, toIndex) {
    var iterator_0 = this.listIterator_1(fromIndex);
    var tmp0_repeat_0 = toIndex - fromIndex | 0;
    var inductionVariable = 0;
    if (inductionVariable < tmp0_repeat_0)
      do {
        var index_2 = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        iterator_0.next_46();
        Unit_getInstance();
        iterator_0.remove_22();
      }
       while (inductionVariable < tmp0_repeat_0);
  };
  AbstractMutableList.prototype.equals = function (other) {
    if (other === this)
      return true;
    if (!(!(other == null) ? isInterface(other, List) : false))
      return false;
    else {
    }
    return Companion_getInstance().orderedEquals(this, other);
  };
  AbstractMutableList.prototype.hashCode = function () {
    return Companion_getInstance().orderedHashCode(this);
  };
  AbstractMutableList.$metadata$ = {
    simpleName: 'AbstractMutableList',
    kind: 'class',
    interfaces: [MutableList]
  };
  function _no_name_provided_$factory_15($elements) {
    var i = new _no_name_provided__24($elements);
    return function (p1) {
      return i.invoke_33(p1);
    };
  }
  function _no_name_provided_$factory_16($elements) {
    var i = new _no_name_provided__25($elements);
    return function (p1) {
      return i.invoke_33(p1);
    };
  }
  function _no_name_provided__26($entryIterator) {
    this._$entryIterator_0 = $entryIterator;
  }
  _no_name_provided__26.prototype.hasNext_41 = function () {
    return this._$entryIterator_0.hasNext_41();
  };
  _no_name_provided__26.prototype.next_46 = function () {
    return this._$entryIterator_0.next_46()._get_key__35();
  };
  _no_name_provided__26.prototype.remove_22 = function () {
    return this._$entryIterator_0.remove_22();
  };
  _no_name_provided__26.$metadata$ = {
    kind: 'class',
    interfaces: [MutableIterator]
  };
  function _no_name_provided__27($entryIterator) {
    this._$entryIterator_1 = $entryIterator;
  }
  _no_name_provided__27.prototype.hasNext_41 = function () {
    return this._$entryIterator_1.hasNext_41();
  };
  _no_name_provided__27.prototype.next_46 = function () {
    return this._$entryIterator_1.next_46()._get_value__33();
  };
  _no_name_provided__27.prototype.remove_22 = function () {
    return this._$entryIterator_1.remove_22();
  };
  _no_name_provided__27.$metadata$ = {
    kind: 'class',
    interfaces: [MutableIterator]
  };
  function SimpleEntry(key_0, value_0) {
    this._key_0 = key_0;
    this.__value_0 = value_0;
  }
  SimpleEntry.prototype._get_key__35 = function () {
    return this._key_0;
  };
  SimpleEntry.prototype._get_value__33 = function () {
    return this.__value_0;
  };
  SimpleEntry.prototype.setValue_2 = function (newValue) {
    var oldValue = this.__value_0;
    this.__value_0 = newValue;
    return oldValue;
  };
  SimpleEntry.prototype.hashCode = function () {
    return Companion_getInstance_0().entryHashCode(this);
  };
  SimpleEntry.prototype.toString = function () {
    return Companion_getInstance_0().entryToString(this);
  };
  SimpleEntry.prototype.equals = function (other) {
    return Companion_getInstance_0().entryEquals(this, other);
  };
  SimpleEntry.$metadata$ = {
    simpleName: 'SimpleEntry',
    kind: 'class',
    interfaces: [MutableEntry]
  };
  function AbstractEntrySet() {
    AbstractMutableSet.call(this);
  }
  AbstractEntrySet.prototype.contains_51 = function (element) {
    return this.containsEntry_4(element);
  };
  AbstractEntrySet.$metadata$ = {
    simpleName: 'AbstractEntrySet',
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__28(this$0) {
    this._this$0_8 = this$0;
    AbstractMutableSet.call(this);
  }
  _no_name_provided__28.prototype.add_7 = function (element) {
    throw UnsupportedOperationException_init_$Create$_0('Add is not supported on keys');
  };
  _no_name_provided__28.prototype.add_39 = function (element) {
    return this.add_7((element == null ? true : isObject(element)) ? element : THROW_CCE());
  };
  _no_name_provided__28.prototype.contains_32 = function (element) {
    return this._this$0_8.containsKey_7(element);
  };
  _no_name_provided__28.prototype.contains_51 = function (element) {
    if (!(element == null ? true : isObject(element)))
      return false;
    else {
    }
    return this.contains_32((element == null ? true : isObject(element)) ? element : THROW_CCE());
  };
  _no_name_provided__28.prototype.iterator_66 = function () {
    var entryIterator = this._this$0_8._get_entries__5().iterator_66();
    return new _no_name_provided__26(entryIterator);
  };
  _no_name_provided__28.prototype.remove_44 = function (element) {
    this.checkIsMutable_13();
    if (this._this$0_8.containsKey_7(element)) {
      this._this$0_8.remove_44(element);
      Unit_getInstance();
      return true;
    }return false;
  };
  _no_name_provided__28.prototype.remove_46 = function (element) {
    if (!(element == null ? true : isObject(element)))
      return false;
    else {
    }
    return this.remove_44((element == null ? true : isObject(element)) ? element : THROW_CCE());
  };
  _no_name_provided__28.prototype._get_size__52 = function () {
    return this._this$0_8._get_size__52();
  };
  _no_name_provided__28.prototype.checkIsMutable_13 = function () {
    return this._this$0_8.checkIsMutable_13();
  };
  _no_name_provided__28.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__29(this$0) {
    this._this$0_9 = this$0;
    AbstractMutableCollection.call(this);
  }
  _no_name_provided__29.prototype.add_9 = function (element) {
    throw UnsupportedOperationException_init_$Create$_0('Add is not supported on values');
  };
  _no_name_provided__29.prototype.add_39 = function (element) {
    return this.add_9((element == null ? true : isObject(element)) ? element : THROW_CCE());
  };
  _no_name_provided__29.prototype.contains_47 = function (element) {
    return this._this$0_9.containsValue_3(element);
  };
  _no_name_provided__29.prototype.contains_51 = function (element) {
    if (!(element == null ? true : isObject(element)))
      return false;
    else {
    }
    return this.contains_47((element == null ? true : isObject(element)) ? element : THROW_CCE());
  };
  _no_name_provided__29.prototype.iterator_66 = function () {
    var entryIterator = this._this$0_9._get_entries__5().iterator_66();
    return new _no_name_provided__27(entryIterator);
  };
  _no_name_provided__29.prototype._get_size__52 = function () {
    return this._this$0_9._get_size__52();
  };
  _no_name_provided__29.prototype.equals = function (other) {
    if (this === other)
      return true;
    if (!(!(other == null) ? isInterface(other, Collection) : false))
      return false;
    else {
    }
    return Companion_getInstance().orderedEquals(this, other);
  };
  _no_name_provided__29.prototype.hashCode = function () {
    return Companion_getInstance().orderedHashCode(this);
  };
  _no_name_provided__29.prototype.checkIsMutable_13 = function () {
    return this._this$0_9.checkIsMutable_13();
  };
  _no_name_provided__29.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function AbstractMutableMap() {
    AbstractMap.call(this);
    this.__keys_0 = null;
    this.__values_0 = null;
  }
  AbstractMutableMap.prototype._get_keys__1 = function () {
    if (this.__keys_0 == null) {
      var tmp = this;
      tmp.__keys_0 = new _no_name_provided__28(this);
    }return ensureNotNull(this.__keys_0);
  };
  AbstractMutableMap.prototype.putAll_2 = function (from) {
    this.checkIsMutable_13();
    var tmp0_iterator = from._get_entries__5().iterator_66();
    while (tmp0_iterator.hasNext_41()) {
      var tmp1_loop_parameter = tmp0_iterator.next_46();
      var key_0 = tmp1_loop_parameter._get_key__35();
      var value_0 = tmp1_loop_parameter._get_value__33();
      this.put_6(key_0, value_0);
      Unit_getInstance();
    }
  };
  AbstractMutableMap.prototype._get_values__6 = function () {
    if (this.__values_0 == null) {
      var tmp = this;
      tmp.__values_0 = new _no_name_provided__29(this);
    }return ensureNotNull(this.__values_0);
  };
  AbstractMutableMap.prototype.remove_44 = function (key_0) {
    this.checkIsMutable_13();
    var iter = this._get_entries__5().iterator_66();
    while (iter.hasNext_41()) {
      var entry = iter.next_46();
      var k = entry._get_key__35();
      if (equals_0(key_0, k)) {
        var value_0 = entry._get_value__33();
        iter.remove_22();
        return value_0;
      }}
    return null;
  };
  AbstractMutableMap.prototype.checkIsMutable_13 = function () {
  };
  AbstractMutableMap.$metadata$ = {
    simpleName: 'AbstractMutableMap',
    kind: 'class',
    interfaces: [MutableMap]
  };
  function AbstractMutableSet() {
    AbstractMutableCollection.call(this);
  }
  AbstractMutableSet.prototype.equals = function (other) {
    if (other === this)
      return true;
    if (!(!(other == null) ? isInterface(other, Set) : false))
      return false;
    else {
    }
    return Companion_getInstance_1().setEquals(this, other);
  };
  AbstractMutableSet.prototype.hashCode = function () {
    return Companion_getInstance_1().unorderedHashCode(this);
  };
  AbstractMutableSet.$metadata$ = {
    simpleName: 'AbstractMutableSet',
    kind: 'class',
    interfaces: [MutableSet]
  };
  function ArrayList_init_$Init$($this) {
    ArrayList.call($this, []);
    return $this;
  }
  function ArrayList_init_$Create$() {
    return ArrayList_init_$Init$(Object.create(ArrayList.prototype));
  }
  function ArrayList_init_$Init$_0(initialCapacity, $this) {
    ArrayList.call($this, []);
    return $this;
  }
  function ArrayList_init_$Create$_0(initialCapacity) {
    return ArrayList_init_$Init$_0(initialCapacity, Object.create(ArrayList.prototype));
  }
  function ArrayList_init_$Init$_1(elements, $this) {
    ArrayList.call($this, copyToArray_0(elements));
    return $this;
  }
  function ArrayList_init_$Create$_1(elements) {
    return ArrayList_init_$Init$_1(elements, Object.create(ArrayList.prototype));
  }
  function rangeCheck($this, index) {
    Companion_getInstance().checkElementIndex(index, $this._get_size__52());
    return index;
  }
  function insertionRangeCheck($this, index) {
    Companion_getInstance().checkPositionIndex(index, $this._get_size__52());
    return index;
  }
  function ArrayList(array) {
    AbstractMutableList.call(this);
    this._array_2 = array;
    this._isReadOnly = false;
  }
  ArrayList.prototype._get_size__52 = function () {
    return this._array_2.length;
  };
  ArrayList.prototype.get_72 = function (index) {
    var tmp = this._array_2[rangeCheck(this, index)];
    return (tmp == null ? true : isObject(tmp)) ? tmp : THROW_CCE();
  };
  ArrayList.prototype.set_3 = function (index, element) {
    this.checkIsMutable_13();
    rangeCheck(this, index);
    Unit_getInstance();
    var tmp0_apply_0 = this._array_2[index];
    this._array_2[index] = element;
    var tmp = tmp0_apply_0;
    return (tmp == null ? true : isObject(tmp)) ? tmp : THROW_CCE();
  };
  ArrayList.prototype.add_39 = function (element) {
    this.checkIsMutable_13();
    var tmp0_asDynamic_0 = this._array_2;
    tmp0_asDynamic_0.push(element);
    var tmp0_this = this;
    var tmp1 = tmp0_this._get_modCount__0();
    tmp0_this._set_modCount__0(tmp1 + 1 | 0);
    Unit_getInstance();
    return true;
  };
  ArrayList.prototype.add_21 = function (index, element) {
    this.checkIsMutable_13();
    var tmp0_asDynamic_0 = this._array_2;
    tmp0_asDynamic_0.splice(insertionRangeCheck(this, index), 0, element);
    var tmp0_this = this;
    var tmp1 = tmp0_this._get_modCount__0();
    tmp0_this._set_modCount__0(tmp1 + 1 | 0);
    Unit_getInstance();
  };
  ArrayList.prototype.addAll_14 = function (elements) {
    this.checkIsMutable_13();
    if (elements.isEmpty_44())
      return false;
    var tmp0_this = this;
    var tmp = tmp0_this;
    var tmp0_plus_0 = tmp0_this._array_2;
    var tmp1_plus_0 = copyToArray_0(elements);
    tmp._array_2 = tmp0_plus_0.concat(tmp1_plus_0);
    var tmp1_this = this;
    var tmp2 = tmp1_this._get_modCount__0();
    tmp1_this._set_modCount__0(tmp2 + 1 | 0);
    Unit_getInstance();
    return true;
  };
  ArrayList.prototype.addAll_8 = function (index, elements) {
    this.checkIsMutable_13();
    insertionRangeCheck(this, index);
    Unit_getInstance();
    if (index === this._get_size__52())
      return this.addAll_14(elements);
    if (elements.isEmpty_44())
      return false;
    var tmp0_subject = index;
    if (tmp0_subject === this._get_size__52())
      return this.addAll_14(elements);
    else if (tmp0_subject === 0) {
      var tmp = this;
      var tmp0_plus_0 = copyToArray_0(elements);
      var tmp1_plus_0 = this._array_2;
      tmp._array_2 = tmp0_plus_0.concat(tmp1_plus_0);
    } else {
      var tmp_0 = this;
      var tmp2_asDynamic_0 = copyOfRange_2(this._array_2, 0, index);
      tmp_0._array_2 = tmp2_asDynamic_0.concat(copyToArray_0(elements), copyOfRange_2(this._array_2, index, this._get_size__52()));
    }
    var tmp1_this = this;
    var tmp2 = tmp1_this._get_modCount__0();
    tmp1_this._set_modCount__0(tmp2 + 1 | 0);
    Unit_getInstance();
    return true;
  };
  ArrayList.prototype.removeAt_2 = function (index) {
    this.checkIsMutable_13();
    rangeCheck(this, index);
    Unit_getInstance();
    var tmp0_this = this;
    var tmp1 = tmp0_this._get_modCount__0();
    tmp0_this._set_modCount__0(tmp1 + 1 | 0);
    Unit_getInstance();
    var tmp;
    if (index === _get_lastIndex__2(this)) {
      var tmp0_asDynamic_0 = this._array_2;
      tmp = tmp0_asDynamic_0.pop();
    } else {
      var tmp1_asDynamic_0 = this._array_2;
      tmp = tmp1_asDynamic_0.splice(index, 1)[0];
    }
    return tmp;
  };
  ArrayList.prototype.remove_46 = function (element) {
    this.checkIsMutable_13();
    var inductionVariable = 0;
    var last_2 = this._array_2.length - 1 | 0;
    if (inductionVariable <= last_2)
      do {
        var index = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        if (equals_0(this._array_2[index], element)) {
          var tmp0_asDynamic_0 = this._array_2;
          tmp0_asDynamic_0.splice(index, 1);
          var tmp1_this = this;
          var tmp2 = tmp1_this._get_modCount__0();
          tmp1_this._set_modCount__0(tmp2 + 1 | 0);
          Unit_getInstance();
          return true;
        }}
       while (inductionVariable <= last_2);
    return false;
  };
  ArrayList.prototype.removeRange_1 = function (fromIndex, toIndex) {
    this.checkIsMutable_13();
    var tmp0_this = this;
    var tmp1 = tmp0_this._get_modCount__0();
    tmp0_this._set_modCount__0(tmp1 + 1 | 0);
    Unit_getInstance();
    var tmp0_asDynamic_0 = this._array_2;
    tmp0_asDynamic_0.splice(fromIndex, toIndex - fromIndex | 0);
  };
  ArrayList.prototype.clear_9 = function () {
    this.checkIsMutable_13();
    var tmp = this;
    tmp._array_2 = [];
    var tmp0_this = this;
    var tmp1 = tmp0_this._get_modCount__0();
    tmp0_this._set_modCount__0(tmp1 + 1 | 0);
    Unit_getInstance();
  };
  ArrayList.prototype.indexOf_1 = function (element) {
    return indexOf(this._array_2, element);
  };
  ArrayList.prototype.lastIndexOf_2 = function (element) {
    return lastIndexOf(this._array_2, element);
  };
  ArrayList.prototype.toString = function () {
    return arrayToString(this._array_2);
  };
  ArrayList.prototype.toArray_3 = function () {
    return [].slice.call(this._array_2);
  };
  ArrayList.prototype.toArray = function () {
    return this.toArray_3();
  };
  ArrayList.prototype.checkIsMutable_13 = function () {
    if (this._isReadOnly)
      throw UnsupportedOperationException_init_$Create$();
  };
  ArrayList.$metadata$ = {
    simpleName: 'ArrayList',
    kind: 'class',
    interfaces: [MutableList, RandomAccess]
  };
  var _stableSortingIsSupported;
  function sortArrayWith(array, comparator) {
    if (getStableSortingIsSupported()) {
      var comparison = _no_name_provided_$factory_17(comparator);
      array.sort(comparison);
    } else {
      mergeSort(array, 0, _get_lastIndex__0(array), comparator);
    }
  }
  function getStableSortingIsSupported() {
    var tmp0_safe_receiver = _stableSortingIsSupported;
    if (tmp0_safe_receiver == null)
      null;
    else {
      return tmp0_safe_receiver;
    }
    Unit_getInstance();
    _stableSortingIsSupported = false;
    var tmp0_unsafeCast_0 = [];
    var array = tmp0_unsafeCast_0;
    var inductionVariable = 0;
    if (inductionVariable < 600)
      do {
        var index = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        array.push(index);
      }
       while (inductionVariable < 600);
    var comparison = _no_name_provided_$factory_18();
    array.sort(comparison);
    var inductionVariable_0 = 1;
    var last_2 = array.length;
    if (inductionVariable_0 < last_2)
      do {
        var index_0 = inductionVariable_0;
        inductionVariable_0 = inductionVariable_0 + 1 | 0;
        var a = array[index_0 - 1 | 0];
        var b = array[index_0];
        if ((a & 3) === (b & 3) ? a >= b : false)
          return false;
      }
       while (inductionVariable_0 < last_2);
    _stableSortingIsSupported = true;
    return true;
  }
  function mergeSort(array, start_0, endInclusive, comparator) {
    var tmp0_arrayOfNulls_0 = array.length;
    var tmp1_unsafeCast_0 = fillArrayVal(Array(tmp0_arrayOfNulls_0), null);
    var buffer = tmp1_unsafeCast_0;
    var result = mergeSort_0(array, buffer, start_0, endInclusive, comparator);
    if (!(result === array)) {
      var inductionVariable = start_0;
      if (inductionVariable <= endInclusive)
        do {
          var i = inductionVariable;
          inductionVariable = inductionVariable + 1 | 0;
          array[i] = result[i];
        }
         while (!(i === endInclusive));
    }}
  function mergeSort_0(array, buffer, start_0, end_0, comparator) {
    if (start_0 === end_0) {
      return array;
    }var median = (start_0 + end_0 | 0) / 2 | 0;
    var left = mergeSort_0(array, buffer, start_0, median, comparator);
    var right = mergeSort_0(array, buffer, median + 1 | 0, end_0, comparator);
    var target_0 = left === buffer ? array : buffer;
    var leftIndex = start_0;
    var rightIndex = median + 1 | 0;
    var inductionVariable = start_0;
    if (inductionVariable <= end_0)
      do {
        var i = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        if (leftIndex <= median ? rightIndex <= end_0 : false) {
          var leftValue = left[leftIndex];
          var rightValue = right[rightIndex];
          if (comparator.compare(leftValue, rightValue) <= 0) {
            target_0[i] = leftValue;
            var tmp1 = leftIndex;
            leftIndex = tmp1 + 1 | 0;
            Unit_getInstance();
          } else {
            target_0[i] = rightValue;
            var tmp2 = rightIndex;
            rightIndex = tmp2 + 1 | 0;
            Unit_getInstance();
          }
        } else if (leftIndex <= median) {
          target_0[i] = left[leftIndex];
          var tmp3 = leftIndex;
          leftIndex = tmp3 + 1 | 0;
          Unit_getInstance();
        } else {
          target_0[i] = right[rightIndex];
          var tmp4 = rightIndex;
          rightIndex = tmp4 + 1 | 0;
          Unit_getInstance();
          Unit_getInstance();
        }
      }
       while (!(i === end_0));
    return target_0;
  }
  function _no_name_provided__30($comparator) {
    this._$comparator = $comparator;
  }
  _no_name_provided__30.prototype.invoke_35 = function (a, b) {
    return this._$comparator.compare(a, b);
  };
  _no_name_provided__30.prototype.invoke_915 = function (p1, p2) {
    var tmp = (p1 == null ? true : isObject(p1)) ? p1 : THROW_CCE();
    return this.invoke_35(tmp, (p2 == null ? true : isObject(p2)) ? p2 : THROW_CCE());
  };
  _no_name_provided__30.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__31() {
  }
  _no_name_provided__31.prototype.invoke_37 = function (a, b) {
    return (a & 3) - (b & 3) | 0;
  };
  _no_name_provided__31.prototype.invoke_915 = function (p1, p2) {
    var tmp = (!(p1 == null) ? typeof p1 === 'number' : false) ? p1 : THROW_CCE();
    return this.invoke_37(tmp, (!(p2 == null) ? typeof p2 === 'number' : false) ? p2 : THROW_CCE());
  };
  _no_name_provided__31.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided_$factory_17($comparator) {
    var i = new _no_name_provided__30($comparator);
    return function (p1, p2) {
      return i.invoke_35(p1, p2);
    };
  }
  function _no_name_provided_$factory_18() {
    var i = new _no_name_provided__31();
    return function (p1, p2) {
      return i.invoke_37(p1, p2);
    };
  }
  function HashCode() {
    HashCode_instance = this;
  }
  HashCode.prototype.equals_1 = function (value1, value2) {
    return equals_0(value1, value2);
  };
  HashCode.prototype.getHashCode_0 = function (value_0) {
    var tmp0_safe_receiver = value_0;
    var tmp1_elvis_lhs = tmp0_safe_receiver == null ? null : hashCode(tmp0_safe_receiver);
    return tmp1_elvis_lhs == null ? 0 : tmp1_elvis_lhs;
  };
  HashCode.$metadata$ = {
    simpleName: 'HashCode',
    kind: 'object',
    interfaces: [EqualityComparator]
  };
  var HashCode_instance;
  function HashCode_getInstance() {
    if (HashCode_instance == null)
      new HashCode();
    return HashCode_instance;
  }
  function EqualityComparator() {
  }
  EqualityComparator.$metadata$ = {
    simpleName: 'EqualityComparator',
    kind: 'interface',
    interfaces: []
  };
  function EntrySet($outer) {
    this._$this_2 = $outer;
    AbstractEntrySet.call(this);
  }
  EntrySet.prototype.add_17 = function (element) {
    throw UnsupportedOperationException_init_$Create$_0('Add is not supported on entries');
  };
  EntrySet.prototype.add_39 = function (element) {
    return this.add_17((!(element == null) ? isInterface(element, MutableEntry) : false) ? element : THROW_CCE());
  };
  EntrySet.prototype.containsEntry_4 = function (element) {
    return this._$this_2.containsEntry_6(element);
  };
  EntrySet.prototype.iterator_66 = function () {
    return this._$this_2._internalMap.iterator_66();
  };
  EntrySet.prototype.remove_23 = function (element) {
    if (this.contains_51(element)) {
      this._$this_2.remove_44(element._get_key__35());
      Unit_getInstance();
      return true;
    }return false;
  };
  EntrySet.prototype.remove_46 = function (element) {
    if (!(!(element == null) ? isInterface(element, MutableEntry) : false))
      return false;
    else {
    }
    return this.remove_23((!(element == null) ? isInterface(element, MutableEntry) : false) ? element : THROW_CCE());
  };
  EntrySet.prototype._get_size__52 = function () {
    return this._$this_2._get_size__52();
  };
  EntrySet.$metadata$ = {
    simpleName: 'EntrySet',
    kind: 'class',
    interfaces: []
  };
  function HashMap_init_$Init$(internalMap, $this) {
    AbstractMutableMap.call($this);
    HashMap.call($this);
    $this._internalMap = internalMap;
    $this._equality = internalMap._get_equality__0();
    return $this;
  }
  function HashMap_init_$Init$_0($this) {
    HashMap_init_$Init$(new InternalHashCodeMap(HashCode_getInstance()), $this);
    return $this;
  }
  function HashMap_init_$Create$() {
    return HashMap_init_$Init$_0(Object.create(HashMap.prototype));
  }
  function HashMap_init_$Init$_1(initialCapacity, loadFactor, $this) {
    HashMap_init_$Init$_0($this);
    var tmp0_require_0 = initialCapacity >= 0;
    if (!tmp0_require_0) {
      var message_1 = '' + 'Negative initial capacity: ' + initialCapacity;
      throw IllegalArgumentException_init_$Create$_0(toString_2(message_1));
    }var tmp1_require_0 = loadFactor >= 0;
    if (!tmp1_require_0) {
      var message_1_0 = '' + 'Non-positive load factor: ' + loadFactor;
      throw IllegalArgumentException_init_$Create$_0(toString_2(message_1_0));
    }return $this;
  }
  function HashMap_init_$Create$_0(initialCapacity, loadFactor) {
    return HashMap_init_$Init$_1(initialCapacity, loadFactor, Object.create(HashMap.prototype));
  }
  function HashMap_init_$Init$_2(initialCapacity, $this) {
    HashMap_init_$Init$_1(initialCapacity, 0.0, $this);
    return $this;
  }
  function HashMap_init_$Create$_1(initialCapacity) {
    return HashMap_init_$Init$_2(initialCapacity, Object.create(HashMap.prototype));
  }
  function HashMap_init_$Init$_3(original, $this) {
    HashMap_init_$Init$_0($this);
    $this.putAll_2(original);
    return $this;
  }
  function HashMap_init_$Create$_2(original) {
    return HashMap_init_$Init$_3(original, Object.create(HashMap.prototype));
  }
  HashMap.prototype.clear_9 = function () {
    this._internalMap.clear_9();
  };
  HashMap.prototype.containsKey_7 = function (key_0) {
    return this._internalMap.contains_32(key_0);
  };
  HashMap.prototype.containsValue_3 = function (value_0) {
    var tmp$ret$0;
    l$ret$1: do {
      var tmp0_any_0 = this._internalMap;
      var tmp;
      if (isInterface(tmp0_any_0, Collection)) {
        tmp = tmp0_any_0.isEmpty_44();
      } else {
        {
          tmp = false;
        }
      }
      if (tmp) {
        tmp$ret$0 = false;
        break l$ret$1;
      } else {
      }
      var tmp0_iterator_1 = tmp0_any_0.iterator_66();
      while (tmp0_iterator_1.hasNext_41()) {
        var element_2 = tmp0_iterator_1.next_46();
        if (this._equality.equals_1(element_2._get_value__33(), value_0)) {
          tmp$ret$0 = true;
          break l$ret$1;
        } else {
        }
      }
      tmp$ret$0 = false;
    }
     while (false);
    return tmp$ret$0;
  };
  HashMap.prototype._get_entries__5 = function () {
    if (this.__entries == null) {
      this.__entries = this.createEntrySet_0();
    }return ensureNotNull(this.__entries);
  };
  HashMap.prototype.createEntrySet_0 = function () {
    return new EntrySet(this);
  };
  HashMap.prototype.get_56 = function (key_0) {
    return this._internalMap.get_56(key_0);
  };
  HashMap.prototype.put_6 = function (key_0, value_0) {
    return this._internalMap.put_6(key_0, value_0);
  };
  HashMap.prototype.remove_44 = function (key_0) {
    return this._internalMap.remove_44(key_0);
  };
  HashMap.prototype._get_size__52 = function () {
    return this._internalMap._get_size__52();
  };
  function HashMap() {
    this.__entries = null;
  }
  HashMap.$metadata$ = {
    simpleName: 'HashMap',
    kind: 'class',
    interfaces: [MutableMap]
  };
  function HashSet_init_$Init$($this) {
    AbstractMutableSet.call($this);
    HashSet.call($this);
    $this._map = HashMap_init_$Create$();
    return $this;
  }
  function HashSet_init_$Create$() {
    return HashSet_init_$Init$(Object.create(HashSet.prototype));
  }
  function HashSet_init_$Init$_0(initialCapacity, loadFactor, $this) {
    AbstractMutableSet.call($this);
    HashSet.call($this);
    $this._map = HashMap_init_$Create$_0(initialCapacity, loadFactor);
    return $this;
  }
  function HashSet_init_$Init$_1(initialCapacity, $this) {
    HashSet_init_$Init$_0(initialCapacity, 0.0, $this);
    return $this;
  }
  function HashSet_init_$Create$_0(initialCapacity) {
    return HashSet_init_$Init$_1(initialCapacity, Object.create(HashSet.prototype));
  }
  function HashSet_init_$Init$_2(map_0, $this) {
    AbstractMutableSet.call($this);
    HashSet.call($this);
    $this._map = map_0;
    return $this;
  }
  HashSet.prototype._get_map__0 = function () {
    return this._map;
  };
  HashSet.prototype.add_39 = function (element) {
    var old = this._map.put_6(element, this);
    return old == null;
  };
  HashSet.prototype.contains_51 = function (element) {
    return this._map.containsKey_7(element);
  };
  HashSet.prototype.isEmpty_44 = function () {
    return this._map.isEmpty_44();
  };
  HashSet.prototype.iterator_66 = function () {
    return this._map._get_keys__1().iterator_66();
  };
  HashSet.prototype.remove_46 = function (element) {
    return !(this._map.remove_44(element) == null);
  };
  HashSet.prototype._get_size__52 = function () {
    return this._map._get_size__52();
  };
  function HashSet() {
  }
  HashSet.$metadata$ = {
    simpleName: 'HashSet',
    kind: 'class',
    interfaces: [MutableSet]
  };
  function computeNext($this) {
    if ($this._chainOrEntry != null ? $this._isChain : false) {
      var tmp0_unsafeCast_0 = $this._chainOrEntry;
      var chainSize = tmp0_unsafeCast_0.length;
      var tmp0_this = $this;
      tmp0_this._itemIndex = tmp0_this._itemIndex + 1 | 0;
      if (tmp0_this._itemIndex < chainSize)
        return 0;
      else {
      }
    }var tmp1_this = $this;
    tmp1_this._keyIndex = tmp1_this._keyIndex + 1 | 0;
    if (tmp1_this._keyIndex < $this._keys.length) {
      $this._chainOrEntry = $this._this$0_10._backingMap[$this._keys[$this._keyIndex]];
      var tmp = $this;
      var tmp_0 = $this._chainOrEntry;
      tmp._isChain = !(tmp_0 == null) ? isArray(tmp_0) : false;
      $this._itemIndex = 0;
      return 0;
    } else {
      {
        $this._chainOrEntry = null;
        return 1;
      }
    }
  }
  function getEntry($this, key_0) {
    var tmp0_elvis_lhs = getChainOrEntryOrNull($this, $this._equality_0.getHashCode_0(key_0));
    var tmp;
    if (tmp0_elvis_lhs == null) {
      return null;
    } else {
      tmp = tmp0_elvis_lhs;
    }
    var chainOrEntry = tmp;
    if (!(!(chainOrEntry == null) ? isArray(chainOrEntry) : false)) {
      var entry = chainOrEntry;
      if ($this._equality_0.equals_1(entry._get_key__35(), key_0)) {
        return entry;
      } else {
        return null;
      }
    } else {
      {
        var chain = chainOrEntry;
        return findEntryInChain(chain, $this, key_0);
      }
    }
  }
  function findEntryInChain(_this_, $this, key_0) {
    var tmp$ret$0;
    l$ret$1: do {
      var indexedObject = _this_;
      var inductionVariable = 0;
      var last_2 = indexedObject.length;
      while (inductionVariable < last_2) {
        var element_2 = indexedObject[inductionVariable];
        inductionVariable = inductionVariable + 1 | 0;
        if ($this._equality_0.equals_1(element_2._get_key__35(), key_0)) {
          tmp$ret$0 = element_2;
          break l$ret$1;
        } else {
        }
      }
      tmp$ret$0 = null;
    }
     while (false);
    return tmp$ret$0;
  }
  function getChainOrEntryOrNull($this, hashCode_1) {
    var chainOrEntry = $this._backingMap[hashCode_1];
    return chainOrEntry === undefined ? null : chainOrEntry;
  }
  function _no_name_provided__32(this$0) {
    this._this$0_10 = this$0;
    this._state_1 = -1;
    this._keys = Object.keys(this._this$0_10._backingMap);
    this._keyIndex = -1;
    this._chainOrEntry = null;
    this._isChain = false;
    this._itemIndex = -1;
    this._lastEntry = null;
  }
  _no_name_provided__32.prototype.hasNext_41 = function () {
    if (this._state_1 === -1)
      this._state_1 = computeNext(this);
    return this._state_1 === 0;
  };
  _no_name_provided__32.prototype.next_46 = function () {
    if (!this.hasNext_41())
      throw NoSuchElementException_init_$Create$();
    var tmp;
    if (this._isChain) {
      var tmp0_unsafeCast_0 = this._chainOrEntry;
      tmp = tmp0_unsafeCast_0[this._itemIndex];
    } else {
      var tmp1_unsafeCast_0 = this._chainOrEntry;
      tmp = tmp1_unsafeCast_0;
    }
    var lastEntry = tmp;
    this._lastEntry = lastEntry;
    this._state_1 = -1;
    return lastEntry;
  };
  _no_name_provided__32.prototype.remove_22 = function () {
    var tmp0_checkNotNull_0 = this._lastEntry;
    var tmp$ret$0;
    l$ret$1: do {
      if (tmp0_checkNotNull_0 == null) {
        var message_2_1 = 'Required value was null.';
        throw IllegalStateException_init_$Create$_0(toString_2(message_2_1));
      } else {
        tmp$ret$0 = tmp0_checkNotNull_0;
        break l$ret$1;
      }
    }
     while (false);
    Unit_getInstance();
    this._this$0_10.remove_44(ensureNotNull(this._lastEntry)._get_key__35());
    Unit_getInstance();
    this._lastEntry = null;
    var tmp0_this = this;
    var tmp1 = tmp0_this._itemIndex;
    tmp0_this._itemIndex = tmp1 - 1 | 0;
    Unit_getInstance();
  };
  _no_name_provided__32.$metadata$ = {
    kind: 'class',
    interfaces: [MutableIterator]
  };
  function InternalHashCodeMap(equality) {
    this._equality_0 = equality;
    this._backingMap = this.createJsMap_0();
    this._size_0 = 0;
  }
  InternalHashCodeMap.prototype._get_equality__0 = function () {
    return this._equality_0;
  };
  InternalHashCodeMap.prototype._get_size__52 = function () {
    return this._size_0;
  };
  InternalHashCodeMap.prototype.put_6 = function (key_0, value_0) {
    var hashCode_1 = this._equality_0.getHashCode_0(key_0);
    var chainOrEntry = getChainOrEntryOrNull(this, hashCode_1);
    if (chainOrEntry == null) {
      this._backingMap[hashCode_1] = new SimpleEntry(key_0, value_0);
    } else {
      if (!(!(chainOrEntry == null) ? isArray(chainOrEntry) : false)) {
        var entry = chainOrEntry;
        if (this._equality_0.equals_1(entry._get_key__35(), key_0)) {
          return entry.setValue_2(value_0);
        } else {
          var tmp0_arrayOf_0 = [entry, new SimpleEntry(key_0, value_0)];
          this._backingMap[hashCode_1] = tmp0_arrayOf_0;
          var tmp0_this = this;
          var tmp1 = tmp0_this._size_0;
          tmp0_this._size_0 = tmp1 + 1 | 0;
          Unit_getInstance();
          return null;
        }
      } else {
        {
          var chain = chainOrEntry;
          var entry_0 = findEntryInChain(chain, this, key_0);
          if (!(entry_0 == null)) {
            return entry_0.setValue_2(value_0);
          }chain.push(new SimpleEntry(key_0, value_0));
        }
      }
    }
    var tmp2_this = this;
    var tmp3 = tmp2_this._size_0;
    tmp2_this._size_0 = tmp3 + 1 | 0;
    Unit_getInstance();
    return null;
  };
  InternalHashCodeMap.prototype.remove_44 = function (key_0) {
    var hashCode_1 = this._equality_0.getHashCode_0(key_0);
    var tmp0_elvis_lhs = getChainOrEntryOrNull(this, hashCode_1);
    var tmp;
    if (tmp0_elvis_lhs == null) {
      return null;
    } else {
      tmp = tmp0_elvis_lhs;
    }
    var chainOrEntry = tmp;
    if (!(!(chainOrEntry == null) ? isArray(chainOrEntry) : false)) {
      var entry = chainOrEntry;
      if (this._equality_0.equals_1(entry._get_key__35(), key_0)) {
        jsDeleteProperty(this._backingMap, hashCode_1);
        var tmp1_this = this;
        var tmp2 = tmp1_this._size_0;
        tmp1_this._size_0 = tmp2 - 1 | 0;
        Unit_getInstance();
        return entry._get_value__33();
      } else {
        return null;
      }
    } else {
      {
        var chain = chainOrEntry;
        var inductionVariable = 0;
        var last_2 = chain.length - 1 | 0;
        if (inductionVariable <= last_2)
          do {
            var index = inductionVariable;
            inductionVariable = inductionVariable + 1 | 0;
            var entry_0 = chain[index];
            if (this._equality_0.equals_1(key_0, entry_0._get_key__35())) {
              if (chain.length === 1) {
                chain.length = 0;
                jsDeleteProperty(this._backingMap, hashCode_1);
              } else {
                chain.splice(index, 1);
              }
              var tmp4_this = this;
              var tmp5 = tmp4_this._size_0;
              tmp4_this._size_0 = tmp5 - 1 | 0;
              Unit_getInstance();
              return entry_0._get_value__33();
            }}
           while (inductionVariable <= last_2);
      }
    }
    return null;
  };
  InternalHashCodeMap.prototype.clear_9 = function () {
    this._backingMap = this.createJsMap_0();
    this._size_0 = 0;
  };
  InternalHashCodeMap.prototype.contains_32 = function (key_0) {
    return !(getEntry(this, key_0) == null);
  };
  InternalHashCodeMap.prototype.get_56 = function (key_0) {
    var tmp0_safe_receiver = getEntry(this, key_0);
    return tmp0_safe_receiver == null ? null : tmp0_safe_receiver._get_value__33();
  };
  InternalHashCodeMap.prototype.iterator_66 = function () {
    return new _no_name_provided__32(this);
  };
  InternalHashCodeMap.$metadata$ = {
    simpleName: 'InternalHashCodeMap',
    kind: 'class',
    interfaces: [InternalMap]
  };
  function InternalMap() {
  }
  InternalMap.prototype.createJsMap_0 = function () {
    var result = Object.create(null);
    result['foo'] = 1;
    jsDeleteProperty(result, 'foo');
    return result;
  };
  InternalMap.$metadata$ = {
    simpleName: 'InternalMap',
    kind: 'interface',
    interfaces: [MutableIterable]
  };
  function EntryIterator($outer) {
    this._$this_3 = $outer;
    this._last_1 = null;
    this._next_1 = null;
    this._next_1 = this._$this_3._$this_5._head;
  }
  EntryIterator.prototype.hasNext_41 = function () {
    return !(this._next_1 === null);
  };
  EntryIterator.prototype.next_46 = function () {
    if (!this.hasNext_41())
      throw NoSuchElementException_init_$Create$();
    var current_0 = ensureNotNull(this._next_1);
    this._last_1 = current_0;
    var tmp = this;
    var tmp0_takeIf_0 = current_0._next_2;
    var tmp_0;
    if (!(tmp0_takeIf_0 === this._$this_3._$this_5._head)) {
      tmp_0 = tmp0_takeIf_0;
    } else {
      {
        tmp_0 = null;
      }
    }
    tmp._next_1 = tmp_0;
    return current_0;
  };
  EntryIterator.prototype.remove_22 = function () {
    var tmp0_check_0 = !(this._last_1 == null);
    if (!tmp0_check_0) {
      var message_2_1 = 'Check failed.';
      throw IllegalStateException_init_$Create$_0(toString_2(message_2_1));
    }this._$this_3.checkIsMutable_13();
    remove(ensureNotNull(this._last_1), this._$this_3._$this_5);
    this._$this_3._$this_5._map_0.remove_44(ensureNotNull(this._last_1)._get_key__35());
    Unit_getInstance();
    this._last_1 = null;
  };
  EntryIterator.$metadata$ = {
    simpleName: 'EntryIterator',
    kind: 'class',
    interfaces: [MutableIterator]
  };
  function ChainEntry($outer, key_0, value_0) {
    this._$this_4 = $outer;
    SimpleEntry.call(this, key_0, value_0);
    this._next_2 = null;
    this._prev = null;
  }
  ChainEntry.prototype.setValue_2 = function (newValue) {
    this._$this_4.checkIsMutable_13();
    return SimpleEntry.prototype.setValue_2.call(this, newValue);
  };
  ChainEntry.$metadata$ = {
    simpleName: 'ChainEntry',
    kind: 'class',
    interfaces: []
  };
  function EntrySet_0($outer) {
    this._$this_5 = $outer;
    AbstractEntrySet.call(this);
  }
  EntrySet_0.prototype.add_17 = function (element) {
    throw UnsupportedOperationException_init_$Create$_0('Add is not supported on entries');
  };
  EntrySet_0.prototype.add_39 = function (element) {
    return this.add_17((!(element == null) ? isInterface(element, MutableEntry) : false) ? element : THROW_CCE());
  };
  EntrySet_0.prototype.containsEntry_4 = function (element) {
    return this._$this_5.containsEntry_6(element);
  };
  EntrySet_0.prototype.iterator_66 = function () {
    return new EntryIterator(this);
  };
  EntrySet_0.prototype.remove_23 = function (element) {
    this.checkIsMutable_13();
    if (this.contains_51(element)) {
      this._$this_5.remove_44(element._get_key__35());
      Unit_getInstance();
      return true;
    }return false;
  };
  EntrySet_0.prototype.remove_46 = function (element) {
    if (!(!(element == null) ? isInterface(element, MutableEntry) : false))
      return false;
    else {
    }
    return this.remove_23((!(element == null) ? isInterface(element, MutableEntry) : false) ? element : THROW_CCE());
  };
  EntrySet_0.prototype._get_size__52 = function () {
    return this._$this_5._get_size__52();
  };
  EntrySet_0.prototype.checkIsMutable_13 = function () {
    return this._$this_5.checkIsMutable_13();
  };
  EntrySet_0.$metadata$ = {
    simpleName: 'EntrySet',
    kind: 'class',
    interfaces: []
  };
  function addToEnd(_this_, $this) {
    var tmp0_check_0 = _this_._next_2 == null ? _this_._prev == null : false;
    if (!tmp0_check_0) {
      var message_2_1 = 'Check failed.';
      throw IllegalStateException_init_$Create$_0(toString_2(message_2_1));
    }var _head = $this._head;
    if (_head == null) {
      $this._head = _this_;
      _this_._next_2 = _this_;
      _this_._prev = _this_;
    } else {
      var tmp1_checkNotNull_0 = _head._prev;
      var tmp$ret$0;
      l$ret$1: do {
        if (tmp1_checkNotNull_0 == null) {
          var message_2_1_0 = 'Required value was null.';
          throw IllegalStateException_init_$Create$_0(toString_2(message_2_1_0));
        } else {
          tmp$ret$0 = tmp1_checkNotNull_0;
          break l$ret$1;
        }
      }
       while (false);
      var _tail = tmp$ret$0;
      _this_._prev = _tail;
      _this_._next_2 = _head;
      _head._prev = _this_;
      _tail._next_2 = _this_;
    }
  }
  function remove(_this_, $this) {
    if (_this_._next_2 === _this_) {
      $this._head = null;
    } else {
      if ($this._head === _this_) {
        $this._head = _this_._next_2;
      }ensureNotNull(_this_._next_2)._prev = _this_._prev;
      ensureNotNull(_this_._prev)._next_2 = _this_._next_2;
    }
    _this_._next_2 = null;
    _this_._prev = null;
  }
  function LinkedHashMap_init_$Init$($this) {
    HashMap_init_$Init$_0($this);
    LinkedHashMap.call($this);
    $this._map_0 = HashMap_init_$Create$();
    return $this;
  }
  function LinkedHashMap_init_$Create$() {
    return LinkedHashMap_init_$Init$(Object.create(LinkedHashMap.prototype));
  }
  function LinkedHashMap_init_$Init$_0(initialCapacity, loadFactor, $this) {
    HashMap_init_$Init$_1(initialCapacity, loadFactor, $this);
    LinkedHashMap.call($this);
    $this._map_0 = HashMap_init_$Create$();
    return $this;
  }
  function LinkedHashMap_init_$Create$_0(initialCapacity, loadFactor) {
    return LinkedHashMap_init_$Init$_0(initialCapacity, loadFactor, Object.create(LinkedHashMap.prototype));
  }
  function LinkedHashMap_init_$Init$_1(initialCapacity, $this) {
    LinkedHashMap_init_$Init$_0(initialCapacity, 0.0, $this);
    return $this;
  }
  function LinkedHashMap_init_$Create$_1(initialCapacity) {
    return LinkedHashMap_init_$Init$_1(initialCapacity, Object.create(LinkedHashMap.prototype));
  }
  function LinkedHashMap_init_$Init$_2(original, $this) {
    HashMap_init_$Init$_0($this);
    LinkedHashMap.call($this);
    $this._map_0 = HashMap_init_$Create$();
    $this.putAll_2(original);
    return $this;
  }
  function LinkedHashMap_init_$Create$_2(original) {
    return LinkedHashMap_init_$Init$_2(original, Object.create(LinkedHashMap.prototype));
  }
  LinkedHashMap.prototype.clear_9 = function () {
    this.checkIsMutable_13();
    this._map_0.clear_9();
    this._head = null;
  };
  LinkedHashMap.prototype.containsKey_7 = function (key_0) {
    return this._map_0.containsKey_7(key_0);
  };
  LinkedHashMap.prototype.containsValue_3 = function (value_0) {
    var tmp0_elvis_lhs = this._head;
    var tmp;
    if (tmp0_elvis_lhs == null) {
      return false;
    } else {
      tmp = tmp0_elvis_lhs;
    }
    var node_0 = tmp;
    do {
      if (equals_0(node_0._get_value__33(), value_0)) {
        return true;
      }node_0 = ensureNotNull(node_0._next_2);
    }
     while (!(node_0 === this._head));
    return false;
  };
  LinkedHashMap.prototype.createEntrySet_0 = function () {
    return new EntrySet_0(this);
  };
  LinkedHashMap.prototype.get_56 = function (key_0) {
    var tmp0_safe_receiver = this._map_0.get_56(key_0);
    return tmp0_safe_receiver == null ? null : tmp0_safe_receiver._get_value__33();
  };
  LinkedHashMap.prototype.put_6 = function (key_0, value_0) {
    this.checkIsMutable_13();
    var old = this._map_0.get_56(key_0);
    if (old == null) {
      var newEntry = new ChainEntry(this, key_0, value_0);
      this._map_0.put_6(key_0, newEntry);
      Unit_getInstance();
      addToEnd(newEntry, this);
      return null;
    } else {
      return old.setValue_2(value_0);
    }
  };
  LinkedHashMap.prototype.remove_44 = function (key_0) {
    this.checkIsMutable_13();
    var entry = this._map_0.remove_44(key_0);
    if (!(entry == null)) {
      remove(entry, this);
      return entry._get_value__33();
    }return null;
  };
  LinkedHashMap.prototype._get_size__52 = function () {
    return this._map_0._get_size__52();
  };
  LinkedHashMap.prototype.checkIsMutable_13 = function () {
    if (this._isReadOnly_0)
      throw UnsupportedOperationException_init_$Create$();
  };
  function LinkedHashMap() {
    this._head = null;
    this._isReadOnly_0 = false;
  }
  LinkedHashMap.$metadata$ = {
    simpleName: 'LinkedHashMap',
    kind: 'class',
    interfaces: [MutableMap]
  };
  function LinkedHashSet_init_$Init$($this) {
    HashSet_init_$Init$_2(LinkedHashMap_init_$Create$(), $this);
    LinkedHashSet.call($this);
    return $this;
  }
  function LinkedHashSet_init_$Create$() {
    return LinkedHashSet_init_$Init$(Object.create(LinkedHashSet.prototype));
  }
  function LinkedHashSet_init_$Init$_0(elements, $this) {
    HashSet_init_$Init$_2(LinkedHashMap_init_$Create$(), $this);
    LinkedHashSet.call($this);
    $this.addAll_14(elements);
    Unit_getInstance();
    return $this;
  }
  function LinkedHashSet_init_$Create$_0(elements) {
    return LinkedHashSet_init_$Init$_0(elements, Object.create(LinkedHashSet.prototype));
  }
  function LinkedHashSet_init_$Init$_1(initialCapacity, loadFactor, $this) {
    HashSet_init_$Init$_2(LinkedHashMap_init_$Create$_0(initialCapacity, loadFactor), $this);
    LinkedHashSet.call($this);
    return $this;
  }
  function LinkedHashSet_init_$Init$_2(initialCapacity, $this) {
    LinkedHashSet_init_$Init$_1(initialCapacity, 0.0, $this);
    return $this;
  }
  function LinkedHashSet_init_$Create$_1(initialCapacity) {
    return LinkedHashSet_init_$Init$_2(initialCapacity, Object.create(LinkedHashSet.prototype));
  }
  LinkedHashSet.prototype.checkIsMutable_13 = function () {
    return this._get_map__0().checkIsMutable_13();
  };
  function LinkedHashSet() {
  }
  LinkedHashSet.$metadata$ = {
    simpleName: 'LinkedHashSet',
    kind: 'class',
    interfaces: [MutableSet]
  };
  function RandomAccess() {
  }
  RandomAccess.$metadata$ = {
    simpleName: 'RandomAccess',
    kind: 'interface',
    interfaces: []
  };
  var output;
  function BaseOutput() {
  }
  BaseOutput.prototype.println_5 = function () {
    this.print_3('\n');
  };
  BaseOutput.prototype.println_6 = function (message) {
    this.print_3(message);
    this.println_5();
  };
  BaseOutput.$metadata$ = {
    simpleName: 'BaseOutput',
    kind: 'class',
    interfaces: []
  };
  function NodeJsOutput_0(outputStream) {
    BaseOutput.call(this);
    this._outputStream = outputStream;
  }
  NodeJsOutput_0.prototype.print_3 = function (message) {
    var messageString = String(message);
    this._outputStream.write(messageString);
  };
  NodeJsOutput_0.$metadata$ = {
    simpleName: 'NodeJsOutput',
    kind: 'class',
    interfaces: []
  };
  function BufferedOutputToConsoleLog_0() {
    BufferedOutput_0.call(this);
  }
  BufferedOutputToConsoleLog_0.prototype.print_3 = function (message) {
    var s = String(message);
    var tmp0_nativeLastIndexOf_0 = s;
    var tmp1_nativeLastIndexOf_0 = '\n';
    var tmp2_nativeLastIndexOf_0 = 0;
    var i = tmp0_nativeLastIndexOf_0.lastIndexOf(tmp1_nativeLastIndexOf_0, tmp2_nativeLastIndexOf_0);
    if (i >= 0) {
      var tmp0_this = this;
      var tmp = tmp0_this._get_buffer__6();
      var tmp3_substring_0 = s;
      var tmp4_substring_0 = 0;
      tmp0_this._set_buffer__0(tmp + tmp3_substring_0.substring(tmp4_substring_0, i));
      this.flush();
      var tmp5_substring_0 = s;
      var tmp6_substring_0 = i + 1 | 0;
      s = tmp5_substring_0.substring(tmp6_substring_0);
    }var tmp1_this = this;
    tmp1_this._set_buffer__0(tmp1_this._get_buffer__6() + s);
  };
  BufferedOutputToConsoleLog_0.prototype.flush = function () {
    (function () {
      var $externalVarargReceiverTmp = console;
      return $externalVarargReceiverTmp.log.apply($externalVarargReceiverTmp, [].concat([this._get_buffer__6()]));
    }.call(this));
    this._set_buffer__0('');
  };
  BufferedOutputToConsoleLog_0.$metadata$ = {
    simpleName: 'BufferedOutputToConsoleLog',
    kind: 'class',
    interfaces: []
  };
  function BufferedOutput_0() {
    BaseOutput.call(this);
    this._buffer_0 = '';
  }
  BufferedOutput_0.prototype._set_buffer__0 = function (_set___) {
    this._buffer_0 = _set___;
  };
  BufferedOutput_0.prototype._get_buffer__6 = function () {
    return this._buffer_0;
  };
  BufferedOutput_0.prototype.print_3 = function (message) {
    var tmp0_this = this;
    var tmp = tmp0_this;
    var tmp_0 = tmp0_this._buffer_0;
    tmp._buffer_0 = tmp_0 + String(message);
  };
  BufferedOutput_0.$metadata$ = {
    simpleName: 'BufferedOutput',
    kind: 'class',
    interfaces: []
  };
  function println(message) {
    output.println_6(message);
  }
  function output$init$() {
    var isNode_2 = typeof process !== 'undefined' && process.versions && !!process.versions.node;
    return isNode_2 ? new NodeJsOutput_0(process.stdout) : new BufferedOutputToConsoleLog_0();
  }
  function SafeContinuation_init_$Init$(delegate, $this) {
    SafeContinuation.call($this, delegate, CoroutineSingletons_UNDECIDED_getInstance());
    return $this;
  }
  function SafeContinuation_init_$Create$(delegate) {
    return SafeContinuation_init_$Init$(delegate, Object.create(SafeContinuation.prototype));
  }
  function SafeContinuation(delegate, initialResult) {
    this._delegate = delegate;
    this._result = initialResult;
  }
  SafeContinuation.prototype._get_context__31 = function () {
    return this._delegate._get_context__31();
  };
  SafeContinuation.prototype.resumeWith_21 = function (result) {
    var cur = this._result;
    if (cur === CoroutineSingletons_UNDECIDED_getInstance()) {
      this._result = _Result___get_value__impl_(result);
    } else if (cur === _get_COROUTINE_SUSPENDED_()) {
      this._result = CoroutineSingletons_RESUMED_getInstance();
      this._delegate.resumeWith_21(result);
    } else
      throw IllegalStateException_init_$Create$_0('Already resumed');
  };
  SafeContinuation.prototype.getOrThrow = function () {
    if (this._result === CoroutineSingletons_UNDECIDED_getInstance()) {
      this._result = _get_COROUTINE_SUSPENDED_();
      return _get_COROUTINE_SUSPENDED_();
    }var result = this._result;
    var tmp;
    if (result === CoroutineSingletons_RESUMED_getInstance()) {
      tmp = _get_COROUTINE_SUSPENDED_();
    } else {
      if (result instanceof Failure) {
        throw result._exception;
      } else {
        {
          tmp = result;
        }
      }
    }
    return tmp;
  };
  SafeContinuation.$metadata$ = {
    simpleName: 'SafeContinuation',
    kind: 'class',
    interfaces: [Continuation]
  };
  function Serializable() {
  }
  Serializable.$metadata$ = {
    simpleName: 'Serializable',
    kind: 'interface',
    interfaces: []
  };
  function abs(n) {
    return n.compareTo_75(new Long(0, 0)) < 0 ? n.unaryMinus_5() : n;
  }
  function abs_0(n) {
    return n < 0 ? -n | 0 | 0 : n;
  }
  function isNaN_0(_this_) {
    return !(_this_ === _this_);
  }
  function takeHighestOneBit(_this_) {
    var tmp;
    if (_this_ === 0) {
      tmp = 0;
    } else {
      IntCompanionObject_getInstance();
      var tmp_0 = 32 - 1 | 0;
      tmp = 1 << (tmp_0 - Math.clz32(_this_) | 0);
    }
    return tmp;
  }
  function countOneBits(_this_) {
    var v = _this_;
    v = (v & 1431655765) + (v >>> 1 & 1431655765) | 0;
    v = (v & 858993459) + (v >>> 2 & 858993459) | 0;
    v = (v & 252645135) + (v >>> 4 & 252645135) | 0;
    v = (v & 16711935) + (v >>> 8 & 16711935) | 0;
    v = (v & 65535) + (v >>> 16) | 0;
    return v;
  }
  function rotateLeft(_this_, bitCount_1) {
    var tmp = _this_ << bitCount_1;
    IntCompanionObject_getInstance();
    return tmp | _this_ >>> (32 - bitCount_1 | 0);
  }
  function rotateRight(_this_, bitCount_1) {
    IntCompanionObject_getInstance();
    return _this_ << (32 - bitCount_1 | 0) | _this_ >>> bitCount_1;
  }
  function _get_js_(_this_) {
    return (_this_ instanceof KClassImpl ? _this_ : THROW_CCE())._get_jClass__2();
  }
  function KCallable() {
  }
  KCallable.$metadata$ = {
    simpleName: 'KCallable',
    kind: 'interface',
    interfaces: []
  };
  function KClass() {
  }
  KClass.$metadata$ = {
    simpleName: 'KClass',
    kind: 'interface',
    interfaces: [KClassifier]
  };
  function KClassImpl(jClass) {
    this._jClass = jClass;
  }
  KClassImpl.prototype._get_jClass__2 = function () {
    return this._jClass;
  };
  KClassImpl.prototype.equals = function (other) {
    var tmp;
    if (other instanceof KClassImpl) {
      tmp = equals_0(this._get_jClass__2(), other._get_jClass__2());
    } else {
      {
        tmp = false;
      }
    }
    return tmp;
  };
  KClassImpl.prototype.hashCode = function () {
    var tmp0_safe_receiver = this._get_simpleName__4();
    var tmp1_elvis_lhs = tmp0_safe_receiver == null ? null : getStringHashCode(tmp0_safe_receiver);
    return tmp1_elvis_lhs == null ? 0 : tmp1_elvis_lhs;
  };
  KClassImpl.prototype.toString = function () {
    return '' + 'class ' + this._get_simpleName__4();
  };
  KClassImpl.$metadata$ = {
    simpleName: 'KClassImpl',
    kind: 'class',
    interfaces: [KClass]
  };
  function PrimitiveKClassImpl(jClass, givenSimpleName, isInstanceFunction) {
    KClassImpl.call(this, jClass);
    this._givenSimpleName = givenSimpleName;
    this._isInstanceFunction = isInstanceFunction;
  }
  PrimitiveKClassImpl.prototype.equals = function (other) {
    if (!(other instanceof PrimitiveKClassImpl))
      return false;
    else {
    }
    return KClassImpl.prototype.equals.call(this, other) ? this._givenSimpleName === other._givenSimpleName : false;
  };
  PrimitiveKClassImpl.prototype._get_simpleName__4 = function () {
    return this._givenSimpleName;
  };
  PrimitiveKClassImpl.prototype.isInstance_4 = function (value_0) {
    return this._isInstanceFunction(value_0);
  };
  PrimitiveKClassImpl.$metadata$ = {
    simpleName: 'PrimitiveKClassImpl',
    kind: 'class',
    interfaces: []
  };
  function NothingKClassImpl() {
    NothingKClassImpl_instance = this;
    KClassImpl.call(this, Object);
    this._simpleName = 'Nothing';
  }
  NothingKClassImpl.prototype._get_simpleName__4 = function () {
    return this._simpleName;
  };
  NothingKClassImpl.prototype.isInstance_4 = function (value_0) {
    return false;
  };
  NothingKClassImpl.prototype._get_jClass__2 = function () {
    throw UnsupportedOperationException_init_$Create$_0("There's no native JS class for Nothing type");
  };
  NothingKClassImpl.prototype.equals = function (other) {
    return other === this;
  };
  NothingKClassImpl.prototype.hashCode = function () {
    return 0;
  };
  NothingKClassImpl.$metadata$ = {
    simpleName: 'NothingKClassImpl',
    kind: 'object',
    interfaces: []
  };
  var NothingKClassImpl_instance;
  function NothingKClassImpl_getInstance() {
    if (NothingKClassImpl_instance == null)
      new NothingKClassImpl();
    return NothingKClassImpl_instance;
  }
  function ErrorKClass() {
  }
  ErrorKClass.prototype._get_simpleName__4 = function () {
    var tmp0_error_0 = 'Unknown simpleName for ErrorKClass';
    throw IllegalStateException_init_$Create$_0(toString_2(tmp0_error_0));
  };
  ErrorKClass.prototype.isInstance_4 = function (value_0) {
    var tmp0_error_0 = "Can's check isInstance on ErrorKClass";
    throw IllegalStateException_init_$Create$_0(toString_2(tmp0_error_0));
  };
  ErrorKClass.prototype.equals = function (other) {
    return other === this;
  };
  ErrorKClass.prototype.hashCode = function () {
    return 0;
  };
  ErrorKClass.$metadata$ = {
    simpleName: 'ErrorKClass',
    kind: 'class',
    interfaces: [KClass]
  };
  function SimpleKClassImpl(jClass) {
    KClassImpl.call(this, jClass);
    var tmp = this;
    var tmp0_safe_receiver = jClass.$metadata$;
    var tmp0_unsafeCast_0 = tmp0_safe_receiver == null ? null : tmp0_safe_receiver.simpleName;
    tmp._simpleName_0 = tmp0_unsafeCast_0;
  }
  SimpleKClassImpl.prototype._get_simpleName__4 = function () {
    return this._simpleName_0;
  };
  SimpleKClassImpl.prototype.isInstance_4 = function (value_0) {
    return jsIsType(value_0, this._get_jClass__2());
  };
  SimpleKClassImpl.$metadata$ = {
    simpleName: 'SimpleKClassImpl',
    kind: 'class',
    interfaces: []
  };
  function KFunction() {
  }
  KFunction.$metadata$ = {
    simpleName: 'KFunction',
    kind: 'interface',
    interfaces: [KCallable]
  };
  function KProperty1() {
  }
  KProperty1.$metadata$ = {
    simpleName: 'KProperty1',
    kind: 'interface',
    interfaces: [KProperty]
  };
  function KMutableProperty1() {
  }
  KMutableProperty1.$metadata$ = {
    simpleName: 'KMutableProperty1',
    kind: 'interface',
    interfaces: [KProperty1, KMutableProperty]
  };
  function KProperty() {
  }
  KProperty.$metadata$ = {
    simpleName: 'KProperty',
    kind: 'interface',
    interfaces: [KCallable]
  };
  function KMutableProperty0() {
  }
  KMutableProperty0.$metadata$ = {
    simpleName: 'KMutableProperty0',
    kind: 'interface',
    interfaces: [KProperty0, KMutableProperty]
  };
  function KMutableProperty() {
  }
  KMutableProperty.$metadata$ = {
    simpleName: 'KMutableProperty',
    kind: 'interface',
    interfaces: [KProperty]
  };
  function KProperty0() {
  }
  KProperty0.$metadata$ = {
    simpleName: 'KProperty0',
    kind: 'interface',
    interfaces: [KProperty]
  };
  var functionClasses;
  function _no_name_provided__33() {
  }
  _no_name_provided__33.prototype.invoke_79 = function (it) {
    return isObject(it);
  };
  _no_name_provided__33.prototype.invoke_1115 = function (p1) {
    return this.invoke_79((p1 == null ? true : isObject(p1)) ? p1 : THROW_CCE());
  };
  _no_name_provided__33.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__34() {
  }
  _no_name_provided__34.prototype.invoke_79 = function (it) {
    return isNumber(it);
  };
  _no_name_provided__34.prototype.invoke_1115 = function (p1) {
    return this.invoke_79((p1 == null ? true : isObject(p1)) ? p1 : THROW_CCE());
  };
  _no_name_provided__34.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__35() {
  }
  _no_name_provided__35.prototype.invoke_79 = function (it) {
    return !(it == null) ? typeof it === 'boolean' : false;
  };
  _no_name_provided__35.prototype.invoke_1115 = function (p1) {
    return this.invoke_79((p1 == null ? true : isObject(p1)) ? p1 : THROW_CCE());
  };
  _no_name_provided__35.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__36() {
  }
  _no_name_provided__36.prototype.invoke_79 = function (it) {
    return !(it == null) ? typeof it === 'number' : false;
  };
  _no_name_provided__36.prototype.invoke_1115 = function (p1) {
    return this.invoke_79((p1 == null ? true : isObject(p1)) ? p1 : THROW_CCE());
  };
  _no_name_provided__36.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__37() {
  }
  _no_name_provided__37.prototype.invoke_79 = function (it) {
    return !(it == null) ? typeof it === 'number' : false;
  };
  _no_name_provided__37.prototype.invoke_1115 = function (p1) {
    return this.invoke_79((p1 == null ? true : isObject(p1)) ? p1 : THROW_CCE());
  };
  _no_name_provided__37.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__38() {
  }
  _no_name_provided__38.prototype.invoke_79 = function (it) {
    return !(it == null) ? typeof it === 'number' : false;
  };
  _no_name_provided__38.prototype.invoke_1115 = function (p1) {
    return this.invoke_79((p1 == null ? true : isObject(p1)) ? p1 : THROW_CCE());
  };
  _no_name_provided__38.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__39() {
  }
  _no_name_provided__39.prototype.invoke_79 = function (it) {
    return !(it == null) ? typeof it === 'number' : false;
  };
  _no_name_provided__39.prototype.invoke_1115 = function (p1) {
    return this.invoke_79((p1 == null ? true : isObject(p1)) ? p1 : THROW_CCE());
  };
  _no_name_provided__39.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__40() {
  }
  _no_name_provided__40.prototype.invoke_79 = function (it) {
    return !(it == null) ? typeof it === 'number' : false;
  };
  _no_name_provided__40.prototype.invoke_1115 = function (p1) {
    return this.invoke_79((p1 == null ? true : isObject(p1)) ? p1 : THROW_CCE());
  };
  _no_name_provided__40.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__41() {
  }
  _no_name_provided__41.prototype.invoke_79 = function (it) {
    return !(it == null) ? isArray(it) : false;
  };
  _no_name_provided__41.prototype.invoke_1115 = function (p1) {
    return this.invoke_79((p1 == null ? true : isObject(p1)) ? p1 : THROW_CCE());
  };
  _no_name_provided__41.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__42() {
  }
  _no_name_provided__42.prototype.invoke_79 = function (it) {
    return !(it == null) ? typeof it === 'string' : false;
  };
  _no_name_provided__42.prototype.invoke_1115 = function (p1) {
    return this.invoke_79((p1 == null ? true : isObject(p1)) ? p1 : THROW_CCE());
  };
  _no_name_provided__42.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__43() {
  }
  _no_name_provided__43.prototype.invoke_79 = function (it) {
    return it instanceof Error;
  };
  _no_name_provided__43.prototype.invoke_1115 = function (p1) {
    return this.invoke_79((p1 == null ? true : isObject(p1)) ? p1 : THROW_CCE());
  };
  _no_name_provided__43.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__44() {
  }
  _no_name_provided__44.prototype.invoke_79 = function (it) {
    return !(it == null) ? isBooleanArray(it) : false;
  };
  _no_name_provided__44.prototype.invoke_1115 = function (p1) {
    return this.invoke_79((p1 == null ? true : isObject(p1)) ? p1 : THROW_CCE());
  };
  _no_name_provided__44.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__45() {
  }
  _no_name_provided__45.prototype.invoke_79 = function (it) {
    return !(it == null) ? isCharArray(it) : false;
  };
  _no_name_provided__45.prototype.invoke_1115 = function (p1) {
    return this.invoke_79((p1 == null ? true : isObject(p1)) ? p1 : THROW_CCE());
  };
  _no_name_provided__45.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__46() {
  }
  _no_name_provided__46.prototype.invoke_79 = function (it) {
    return !(it == null) ? isByteArray(it) : false;
  };
  _no_name_provided__46.prototype.invoke_1115 = function (p1) {
    return this.invoke_79((p1 == null ? true : isObject(p1)) ? p1 : THROW_CCE());
  };
  _no_name_provided__46.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__47() {
  }
  _no_name_provided__47.prototype.invoke_79 = function (it) {
    return !(it == null) ? isShortArray(it) : false;
  };
  _no_name_provided__47.prototype.invoke_1115 = function (p1) {
    return this.invoke_79((p1 == null ? true : isObject(p1)) ? p1 : THROW_CCE());
  };
  _no_name_provided__47.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__48() {
  }
  _no_name_provided__48.prototype.invoke_79 = function (it) {
    return !(it == null) ? isIntArray(it) : false;
  };
  _no_name_provided__48.prototype.invoke_1115 = function (p1) {
    return this.invoke_79((p1 == null ? true : isObject(p1)) ? p1 : THROW_CCE());
  };
  _no_name_provided__48.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__49() {
  }
  _no_name_provided__49.prototype.invoke_79 = function (it) {
    return !(it == null) ? isLongArray(it) : false;
  };
  _no_name_provided__49.prototype.invoke_1115 = function (p1) {
    return this.invoke_79((p1 == null ? true : isObject(p1)) ? p1 : THROW_CCE());
  };
  _no_name_provided__49.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__50() {
  }
  _no_name_provided__50.prototype.invoke_79 = function (it) {
    return !(it == null) ? isFloatArray(it) : false;
  };
  _no_name_provided__50.prototype.invoke_1115 = function (p1) {
    return this.invoke_79((p1 == null ? true : isObject(p1)) ? p1 : THROW_CCE());
  };
  _no_name_provided__50.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__51() {
  }
  _no_name_provided__51.prototype.invoke_79 = function (it) {
    return !(it == null) ? isDoubleArray(it) : false;
  };
  _no_name_provided__51.prototype.invoke_1115 = function (p1) {
    return this.invoke_79((p1 == null ? true : isObject(p1)) ? p1 : THROW_CCE());
  };
  _no_name_provided__51.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__52($arity) {
    this._$arity = $arity;
  }
  _no_name_provided__52.prototype.invoke_79 = function (it) {
    var tmp;
    if (typeof it === 'function') {
      tmp = it.length === this._$arity;
    } else {
      tmp = false;
    }
    return tmp;
  };
  _no_name_provided__52.prototype.invoke_1115 = function (p1) {
    return this.invoke_79((p1 == null ? true : isObject(p1)) ? p1 : THROW_CCE());
  };
  _no_name_provided__52.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function PrimitiveClasses_0() {
    PrimitiveClasses_instance = this;
    var tmp = this;
    var tmp0_unsafeCast_0 = Object;
    var tmp_0 = tmp0_unsafeCast_0;
    tmp._anyClass = new PrimitiveKClassImpl(tmp_0, 'Any', _no_name_provided_$factory_19());
    var tmp_1 = this;
    var tmp0_unsafeCast_0_0 = Number;
    var tmp_2 = tmp0_unsafeCast_0_0;
    tmp_1._numberClass = new PrimitiveKClassImpl(tmp_2, 'Number', _no_name_provided_$factory_20());
    this._nothingClass = NothingKClassImpl_getInstance();
    var tmp_3 = this;
    var tmp0_unsafeCast_0_1 = Boolean;
    var tmp_4 = tmp0_unsafeCast_0_1;
    tmp_3._booleanClass = new PrimitiveKClassImpl(tmp_4, 'Boolean', _no_name_provided_$factory_21());
    var tmp_5 = this;
    var tmp0_unsafeCast_0_2 = Number;
    var tmp_6 = tmp0_unsafeCast_0_2;
    tmp_5._byteClass = new PrimitiveKClassImpl(tmp_6, 'Byte', _no_name_provided_$factory_22());
    var tmp_7 = this;
    var tmp0_unsafeCast_0_3 = Number;
    var tmp_8 = tmp0_unsafeCast_0_3;
    tmp_7._shortClass = new PrimitiveKClassImpl(tmp_8, 'Short', _no_name_provided_$factory_23());
    var tmp_9 = this;
    var tmp0_unsafeCast_0_4 = Number;
    var tmp_10 = tmp0_unsafeCast_0_4;
    tmp_9._intClass = new PrimitiveKClassImpl(tmp_10, 'Int', _no_name_provided_$factory_24());
    var tmp_11 = this;
    var tmp0_unsafeCast_0_5 = Number;
    var tmp_12 = tmp0_unsafeCast_0_5;
    tmp_11._floatClass = new PrimitiveKClassImpl(tmp_12, 'Float', _no_name_provided_$factory_25());
    var tmp_13 = this;
    var tmp0_unsafeCast_0_6 = Number;
    var tmp_14 = tmp0_unsafeCast_0_6;
    tmp_13._doubleClass = new PrimitiveKClassImpl(tmp_14, 'Double', _no_name_provided_$factory_26());
    var tmp_15 = this;
    var tmp0_unsafeCast_0_7 = Array;
    var tmp_16 = tmp0_unsafeCast_0_7;
    tmp_15._arrayClass = new PrimitiveKClassImpl(tmp_16, 'Array', _no_name_provided_$factory_27());
    var tmp_17 = this;
    var tmp0_unsafeCast_0_8 = String;
    var tmp_18 = tmp0_unsafeCast_0_8;
    tmp_17._stringClass = new PrimitiveKClassImpl(tmp_18, 'String', _no_name_provided_$factory_28());
    var tmp_19 = this;
    var tmp0_unsafeCast_0_9 = Error;
    var tmp_20 = tmp0_unsafeCast_0_9;
    tmp_19._throwableClass = new PrimitiveKClassImpl(tmp_20, 'Throwable', _no_name_provided_$factory_29());
    var tmp_21 = this;
    var tmp0_unsafeCast_0_10 = Array;
    var tmp_22 = tmp0_unsafeCast_0_10;
    tmp_21._booleanArrayClass = new PrimitiveKClassImpl(tmp_22, 'BooleanArray', _no_name_provided_$factory_30());
    var tmp_23 = this;
    var tmp0_unsafeCast_0_11 = Uint16Array;
    var tmp_24 = tmp0_unsafeCast_0_11;
    tmp_23._charArrayClass = new PrimitiveKClassImpl(tmp_24, 'CharArray', _no_name_provided_$factory_31());
    var tmp_25 = this;
    var tmp0_unsafeCast_0_12 = Int8Array;
    var tmp_26 = tmp0_unsafeCast_0_12;
    tmp_25._byteArrayClass = new PrimitiveKClassImpl(tmp_26, 'ByteArray', _no_name_provided_$factory_32());
    var tmp_27 = this;
    var tmp0_unsafeCast_0_13 = Int16Array;
    var tmp_28 = tmp0_unsafeCast_0_13;
    tmp_27._shortArrayClass = new PrimitiveKClassImpl(tmp_28, 'ShortArray', _no_name_provided_$factory_33());
    var tmp_29 = this;
    var tmp0_unsafeCast_0_14 = Int32Array;
    var tmp_30 = tmp0_unsafeCast_0_14;
    tmp_29._intArrayClass = new PrimitiveKClassImpl(tmp_30, 'IntArray', _no_name_provided_$factory_34());
    var tmp_31 = this;
    var tmp0_unsafeCast_0_15 = Array;
    var tmp_32 = tmp0_unsafeCast_0_15;
    tmp_31._longArrayClass = new PrimitiveKClassImpl(tmp_32, 'LongArray', _no_name_provided_$factory_35());
    var tmp_33 = this;
    var tmp0_unsafeCast_0_16 = Float32Array;
    var tmp_34 = tmp0_unsafeCast_0_16;
    tmp_33._floatArrayClass = new PrimitiveKClassImpl(tmp_34, 'FloatArray', _no_name_provided_$factory_36());
    var tmp_35 = this;
    var tmp0_unsafeCast_0_17 = Float64Array;
    var tmp_36 = tmp0_unsafeCast_0_17;
    tmp_35._doubleArrayClass = new PrimitiveKClassImpl(tmp_36, 'DoubleArray', _no_name_provided_$factory_37());
  }
  PrimitiveClasses_0.prototype._get_anyClass_ = function () {
    return this._anyClass;
  };
  PrimitiveClasses_0.prototype._get_numberClass_ = function () {
    return this._numberClass;
  };
  PrimitiveClasses_0.prototype._get_nothingClass_ = function () {
    return this._nothingClass;
  };
  PrimitiveClasses_0.prototype._get_booleanClass_ = function () {
    return this._booleanClass;
  };
  PrimitiveClasses_0.prototype._get_byteClass_ = function () {
    return this._byteClass;
  };
  PrimitiveClasses_0.prototype._get_shortClass_ = function () {
    return this._shortClass;
  };
  PrimitiveClasses_0.prototype._get_intClass_ = function () {
    return this._intClass;
  };
  PrimitiveClasses_0.prototype._get_floatClass_ = function () {
    return this._floatClass;
  };
  PrimitiveClasses_0.prototype._get_doubleClass_ = function () {
    return this._doubleClass;
  };
  PrimitiveClasses_0.prototype._get_arrayClass_ = function () {
    return this._arrayClass;
  };
  PrimitiveClasses_0.prototype._get_stringClass_ = function () {
    return this._stringClass;
  };
  PrimitiveClasses_0.prototype._get_throwableClass_ = function () {
    return this._throwableClass;
  };
  PrimitiveClasses_0.prototype._get_booleanArrayClass_ = function () {
    return this._booleanArrayClass;
  };
  PrimitiveClasses_0.prototype._get_charArrayClass_ = function () {
    return this._charArrayClass;
  };
  PrimitiveClasses_0.prototype._get_byteArrayClass_ = function () {
    return this._byteArrayClass;
  };
  PrimitiveClasses_0.prototype._get_shortArrayClass_ = function () {
    return this._shortArrayClass;
  };
  PrimitiveClasses_0.prototype._get_intArrayClass_ = function () {
    return this._intArrayClass;
  };
  PrimitiveClasses_0.prototype._get_longArrayClass_ = function () {
    return this._longArrayClass;
  };
  PrimitiveClasses_0.prototype._get_floatArrayClass_ = function () {
    return this._floatArrayClass;
  };
  PrimitiveClasses_0.prototype._get_doubleArrayClass_ = function () {
    return this._doubleArrayClass;
  };
  PrimitiveClasses_0.prototype.functionClass = function (arity) {
    var tmp0_elvis_lhs = functionClasses[arity];
    var tmp;
    if (tmp0_elvis_lhs == null) {
      var tmp0_unsafeCast_0_3 = Function;
      var tmp_0 = tmp0_unsafeCast_0_3;
      var tmp_1 = '' + 'Function' + arity;
      var result_2 = new PrimitiveKClassImpl(tmp_0, tmp_1, _no_name_provided_$factory_38(arity));
      var tmp1_asDynamic_0_5 = functionClasses;
      tmp1_asDynamic_0_5[arity] = result_2;
      tmp = result_2;
    } else {
      tmp = tmp0_elvis_lhs;
    }
    return tmp;
  };
  PrimitiveClasses_0.$metadata$ = {
    simpleName: 'PrimitiveClasses',
    kind: 'object',
    interfaces: []
  };
  Object.defineProperty(PrimitiveClasses_0.prototype, 'anyClass', {
    configurable: true,
    get: PrimitiveClasses_0.prototype._get_anyClass_
  });
  Object.defineProperty(PrimitiveClasses_0.prototype, 'numberClass', {
    configurable: true,
    get: PrimitiveClasses_0.prototype._get_numberClass_
  });
  Object.defineProperty(PrimitiveClasses_0.prototype, 'nothingClass', {
    configurable: true,
    get: PrimitiveClasses_0.prototype._get_nothingClass_
  });
  Object.defineProperty(PrimitiveClasses_0.prototype, 'booleanClass', {
    configurable: true,
    get: PrimitiveClasses_0.prototype._get_booleanClass_
  });
  Object.defineProperty(PrimitiveClasses_0.prototype, 'byteClass', {
    configurable: true,
    get: PrimitiveClasses_0.prototype._get_byteClass_
  });
  Object.defineProperty(PrimitiveClasses_0.prototype, 'shortClass', {
    configurable: true,
    get: PrimitiveClasses_0.prototype._get_shortClass_
  });
  Object.defineProperty(PrimitiveClasses_0.prototype, 'intClass', {
    configurable: true,
    get: PrimitiveClasses_0.prototype._get_intClass_
  });
  Object.defineProperty(PrimitiveClasses_0.prototype, 'floatClass', {
    configurable: true,
    get: PrimitiveClasses_0.prototype._get_floatClass_
  });
  Object.defineProperty(PrimitiveClasses_0.prototype, 'doubleClass', {
    configurable: true,
    get: PrimitiveClasses_0.prototype._get_doubleClass_
  });
  Object.defineProperty(PrimitiveClasses_0.prototype, 'arrayClass', {
    configurable: true,
    get: PrimitiveClasses_0.prototype._get_arrayClass_
  });
  Object.defineProperty(PrimitiveClasses_0.prototype, 'stringClass', {
    configurable: true,
    get: PrimitiveClasses_0.prototype._get_stringClass_
  });
  Object.defineProperty(PrimitiveClasses_0.prototype, 'throwableClass', {
    configurable: true,
    get: PrimitiveClasses_0.prototype._get_throwableClass_
  });
  Object.defineProperty(PrimitiveClasses_0.prototype, 'booleanArrayClass', {
    configurable: true,
    get: PrimitiveClasses_0.prototype._get_booleanArrayClass_
  });
  Object.defineProperty(PrimitiveClasses_0.prototype, 'charArrayClass', {
    configurable: true,
    get: PrimitiveClasses_0.prototype._get_charArrayClass_
  });
  Object.defineProperty(PrimitiveClasses_0.prototype, 'byteArrayClass', {
    configurable: true,
    get: PrimitiveClasses_0.prototype._get_byteArrayClass_
  });
  Object.defineProperty(PrimitiveClasses_0.prototype, 'shortArrayClass', {
    configurable: true,
    get: PrimitiveClasses_0.prototype._get_shortArrayClass_
  });
  Object.defineProperty(PrimitiveClasses_0.prototype, 'intArrayClass', {
    configurable: true,
    get: PrimitiveClasses_0.prototype._get_intArrayClass_
  });
  Object.defineProperty(PrimitiveClasses_0.prototype, 'longArrayClass', {
    configurable: true,
    get: PrimitiveClasses_0.prototype._get_longArrayClass_
  });
  Object.defineProperty(PrimitiveClasses_0.prototype, 'floatArrayClass', {
    configurable: true,
    get: PrimitiveClasses_0.prototype._get_floatArrayClass_
  });
  Object.defineProperty(PrimitiveClasses_0.prototype, 'doubleArrayClass', {
    configurable: true,
    get: PrimitiveClasses_0.prototype._get_doubleArrayClass_
  });
  var PrimitiveClasses_instance;
  function PrimitiveClasses_getInstance() {
    if (PrimitiveClasses_instance == null)
      new PrimitiveClasses_0();
    return PrimitiveClasses_instance;
  }
  function _no_name_provided_$factory_19() {
    var i = new _no_name_provided__33();
    return function (p1) {
      return i.invoke_79(p1);
    };
  }
  function _no_name_provided_$factory_20() {
    var i = new _no_name_provided__34();
    return function (p1) {
      return i.invoke_79(p1);
    };
  }
  function _no_name_provided_$factory_21() {
    var i = new _no_name_provided__35();
    return function (p1) {
      return i.invoke_79(p1);
    };
  }
  function _no_name_provided_$factory_22() {
    var i = new _no_name_provided__36();
    return function (p1) {
      return i.invoke_79(p1);
    };
  }
  function _no_name_provided_$factory_23() {
    var i = new _no_name_provided__37();
    return function (p1) {
      return i.invoke_79(p1);
    };
  }
  function _no_name_provided_$factory_24() {
    var i = new _no_name_provided__38();
    return function (p1) {
      return i.invoke_79(p1);
    };
  }
  function _no_name_provided_$factory_25() {
    var i = new _no_name_provided__39();
    return function (p1) {
      return i.invoke_79(p1);
    };
  }
  function _no_name_provided_$factory_26() {
    var i = new _no_name_provided__40();
    return function (p1) {
      return i.invoke_79(p1);
    };
  }
  function _no_name_provided_$factory_27() {
    var i = new _no_name_provided__41();
    return function (p1) {
      return i.invoke_79(p1);
    };
  }
  function _no_name_provided_$factory_28() {
    var i = new _no_name_provided__42();
    return function (p1) {
      return i.invoke_79(p1);
    };
  }
  function _no_name_provided_$factory_29() {
    var i = new _no_name_provided__43();
    return function (p1) {
      return i.invoke_79(p1);
    };
  }
  function _no_name_provided_$factory_30() {
    var i = new _no_name_provided__44();
    return function (p1) {
      return i.invoke_79(p1);
    };
  }
  function _no_name_provided_$factory_31() {
    var i = new _no_name_provided__45();
    return function (p1) {
      return i.invoke_79(p1);
    };
  }
  function _no_name_provided_$factory_32() {
    var i = new _no_name_provided__46();
    return function (p1) {
      return i.invoke_79(p1);
    };
  }
  function _no_name_provided_$factory_33() {
    var i = new _no_name_provided__47();
    return function (p1) {
      return i.invoke_79(p1);
    };
  }
  function _no_name_provided_$factory_34() {
    var i = new _no_name_provided__48();
    return function (p1) {
      return i.invoke_79(p1);
    };
  }
  function _no_name_provided_$factory_35() {
    var i = new _no_name_provided__49();
    return function (p1) {
      return i.invoke_79(p1);
    };
  }
  function _no_name_provided_$factory_36() {
    var i = new _no_name_provided__50();
    return function (p1) {
      return i.invoke_79(p1);
    };
  }
  function _no_name_provided_$factory_37() {
    var i = new _no_name_provided__51();
    return function (p1) {
      return i.invoke_79(p1);
    };
  }
  function _no_name_provided_$factory_38($arity) {
    var i = new _no_name_provided__52($arity);
    return function (p1) {
      return i.invoke_79(p1);
    };
  }
  function functionClasses$init$() {
    var tmp0_arrayOfNulls_0 = 0;
    return fillArrayVal(Array(tmp0_arrayOfNulls_0), null);
  }
  function getKClass_0(jClass) {
    var tmp;
    if (Array.isArray(jClass)) {
      tmp = getKClassM_0(jClass);
    } else {
      tmp = getKClass1_0(jClass);
    }
    return tmp;
  }
  function getKClassM_0(jClasses) {
    var tmp0_subject = jClasses.length;
    var tmp;
    switch (tmp0_subject) {
      case 1:
        tmp = getKClass1_0(jClasses[0]);
        break;
      case 0:
        var tmp0_unsafeCast_0 = NothingKClassImpl_getInstance();
        tmp = tmp0_unsafeCast_0;
        break;
      default:var tmp1_unsafeCast_0 = new ErrorKClass();
        tmp = tmp1_unsafeCast_0;
        break;
    }
    return tmp;
  }
  function getKClass1_0(jClass) {
    if (jClass === String) {
      var tmp0_unsafeCast_0 = PrimitiveClasses_getInstance()._stringClass;
      return tmp0_unsafeCast_0;
    }var metadata = jClass.$metadata$;
    var tmp;
    if (metadata != null) {
      var tmp_0;
      if (metadata.$kClass$ == null) {
        var kClass = new SimpleKClassImpl(jClass);
        metadata.$kClass$ = kClass;
        tmp_0 = kClass;
      } else {
        tmp_0 = metadata.$kClass$;
      }
      tmp = tmp_0;
    } else {
      tmp = new SimpleKClassImpl(jClass);
    }
    return tmp;
  }
  function getKClassFromExpression_0(e) {
    var tmp0_subject = typeof e;
    var tmp;
    switch (tmp0_subject) {
      case 'string':
        tmp = PrimitiveClasses_getInstance()._stringClass;
        break;
      case 'number':
        var tmp_0;
        var tmp0_asDynamic_0 = jsBitwiseOr(e, 0);
        if (tmp0_asDynamic_0 === e) {
          tmp_0 = PrimitiveClasses_getInstance()._intClass;
        } else {
          {
            tmp_0 = PrimitiveClasses_getInstance()._doubleClass;
          }
        }

        tmp = tmp_0;
        break;
      case 'boolean':
        tmp = PrimitiveClasses_getInstance()._booleanClass;
        break;
      case 'function':
        var tmp_1 = PrimitiveClasses_getInstance();
        tmp = tmp_1.functionClass(e.length);
        break;
      default:var tmp_2;
        if (isBooleanArray(e)) {
          tmp_2 = PrimitiveClasses_getInstance()._booleanArrayClass;
        } else {
          if (isCharArray(e)) {
            tmp_2 = PrimitiveClasses_getInstance()._charArrayClass;
          } else {
            if (isByteArray(e)) {
              tmp_2 = PrimitiveClasses_getInstance()._byteArrayClass;
            } else {
              if (isShortArray(e)) {
                tmp_2 = PrimitiveClasses_getInstance()._shortArrayClass;
              } else {
                if (isIntArray(e)) {
                  tmp_2 = PrimitiveClasses_getInstance()._intArrayClass;
                } else {
                  if (isLongArray(e)) {
                    tmp_2 = PrimitiveClasses_getInstance()._longArrayClass;
                  } else {
                    if (isFloatArray(e)) {
                      tmp_2 = PrimitiveClasses_getInstance()._floatArrayClass;
                    } else {
                      if (isDoubleArray(e)) {
                        tmp_2 = PrimitiveClasses_getInstance()._doubleArrayClass;
                      } else {
                        if (isInterface(e, KClass)) {
                          tmp_2 = getKClass_0(KClass);
                        } else {
                          if (isArray(e)) {
                            tmp_2 = PrimitiveClasses_getInstance()._arrayClass;
                          } else {
                            {
                              var constructor = Object.getPrototypeOf(e).constructor;
                              var tmp_3;
                              if (constructor === Object) {
                                tmp_3 = PrimitiveClasses_getInstance()._anyClass;
                              } else if (constructor === Error) {
                                tmp_3 = PrimitiveClasses_getInstance()._throwableClass;
                              } else {
                                var jsClass_0 = constructor;
                                tmp_3 = getKClass1_0(jsClass_0);
                              }
                              tmp_2 = tmp_3;
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }

        tmp = tmp_2;
        break;
    }
    var tmp1_unsafeCast_0 = tmp;
    return tmp1_unsafeCast_0;
  }
  function Appendable() {
  }
  Appendable.$metadata$ = {
    simpleName: 'Appendable',
    kind: 'interface',
    interfaces: []
  };
  function CharacterCodingException(message) {
    Exception_init_$Init$_0(message, this);
    captureStack(this, CharacterCodingException);
  }
  CharacterCodingException.$metadata$ = {
    simpleName: 'CharacterCodingException',
    kind: 'class',
    interfaces: []
  };
  function StringBuilder_init_$Init$(capacity, $this) {
    StringBuilder_init_$Init$_1($this);
    return $this;
  }
  function StringBuilder_init_$Create$(capacity) {
    return StringBuilder_init_$Init$(capacity, Object.create(StringBuilder.prototype));
  }
  function StringBuilder_init_$Init$_0(content, $this) {
    StringBuilder.call($this, toString_2(content));
    return $this;
  }
  function StringBuilder_init_$Create$_0(content) {
    return StringBuilder_init_$Init$_0(content, Object.create(StringBuilder.prototype));
  }
  function StringBuilder_init_$Init$_1($this) {
    StringBuilder.call($this, '');
    return $this;
  }
  function StringBuilder_init_$Create$_1() {
    return StringBuilder_init_$Init$_1(Object.create(StringBuilder.prototype));
  }
  function StringBuilder(content) {
    this._string = !(content === undefined) ? content : '';
  }
  StringBuilder.prototype._get_length__33 = function () {
    var tmp0_asDynamic_0 = this._string;
    return tmp0_asDynamic_0.length;
  };
  StringBuilder.prototype.get_72 = function (index) {
    var tmp0_getOrElse_0 = this._string;
    var tmp;
    if (index >= 0 ? index <= _get_lastIndex__3(tmp0_getOrElse_0) : false) {
      tmp = charSequenceGet(tmp0_getOrElse_0, index);
    } else {
      throw IndexOutOfBoundsException_init_$Create$('' + 'index: ' + index + ', length: ' + this._get_length__33() + '}');
    }
    return tmp;
  };
  StringBuilder.prototype.subSequence_1 = function (startIndex, endIndex) {
    var tmp0_substring_0 = this._string;
    return tmp0_substring_0.substring(startIndex, endIndex);
  };
  StringBuilder.prototype.append_2 = function (value_0) {
    var tmp0_this = this;
    tmp0_this._string = tmp0_this._string + value_0;
    return this;
  };
  StringBuilder.prototype.append_3 = function (value_0) {
    var tmp0_this = this;
    tmp0_this._string = tmp0_this._string + toString_1(value_0);
    return this;
  };
  StringBuilder.prototype.reverse = function () {
    var reversed_1 = '';
    var index = this._string.length - 1 | 0;
    while (index >= 0) {
      var tmp = this._string;
      var tmp0 = index;
      index = tmp0 - 1 | 0;
      var low = charSequenceGet(tmp, tmp0);
      if (isLowSurrogate(low) ? index >= 0 : false) {
        var tmp_0 = this._string;
        var tmp1 = index;
        index = tmp1 - 1 | 0;
        var high = charSequenceGet(tmp_0, tmp1);
        if (isHighSurrogate(high)) {
          reversed_1 = reversed_1 + high + low;
        } else {
          reversed_1 = reversed_1 + low + high;
        }
      } else {
        reversed_1 = reversed_1 + low;
      }
    }
    this._string = reversed_1;
    return this;
  };
  StringBuilder.prototype.append_4 = function (value_0) {
    var tmp0_this = this;
    tmp0_this._string = tmp0_this._string + toString_1(value_0);
    return this;
  };
  StringBuilder.prototype.append_5 = function (value_0) {
    var tmp0_this = this;
    var tmp = tmp0_this;
    var tmp_0 = tmp0_this._string;
    var tmp1_elvis_lhs = value_0;
    tmp._string = tmp_0 + (tmp1_elvis_lhs == null ? 'null' : tmp1_elvis_lhs);
    return this;
  };
  StringBuilder.prototype.toString = function () {
    return this._string;
  };
  StringBuilder.$metadata$ = {
    simpleName: 'StringBuilder',
    kind: 'class',
    interfaces: [Appendable, CharSequence]
  };
  function isWhitespace(_this_) {
    return isWhitespaceImpl(_this_);
  }
  function isLowSurrogate(_this_) {
    Companion_getInstance_8();
    var containsLower = new Char(56320);
    var tmp;
    Companion_getInstance_8();
    if (_this_ <= new Char(57343)) {
      tmp = containsLower <= _this_;
    } else {
      {
        tmp = false;
      }
    }
    return tmp;
  }
  function isHighSurrogate(_this_) {
    Companion_getInstance_8();
    var containsLower = new Char(55296);
    var tmp;
    Companion_getInstance_8();
    if (_this_ <= new Char(56319)) {
      tmp = containsLower <= _this_;
    } else {
      {
        tmp = false;
      }
    }
    return tmp;
  }
  function uppercaseChar(_this_) {
    var tmp0_asDynamic_0 = _this_.toString();
    var tmp1_unsafeCast_0 = tmp0_asDynamic_0.toUpperCase();
    var uppercase = tmp1_unsafeCast_0;
    return uppercase.length > 1 ? _this_ : charSequenceGet(uppercase, 0);
  }
  function checkRadix(radix) {
    if (!(2 <= radix ? radix <= 36 : false)) {
      throw IllegalArgumentException_init_$Create$_0('' + 'radix ' + radix + ' was not in valid range 2..36');
    }return radix;
  }
  function toLong(_this_, radix) {
    var tmp0_elvis_lhs = toLongOrNull(_this_, radix);
    var tmp;
    if (tmp0_elvis_lhs == null) {
      numberFormatError(_this_);
    } else {
      tmp = tmp0_elvis_lhs;
    }
    return tmp;
  }
  function digitOf(char, radix) {
    var tmp0_let_0 = (char.compareTo_9(new Char(48)) >= 0 ? char.compareTo_9(new Char(57)) <= 0 : false) ? char.minus(new Char(48)) : (char.compareTo_9(new Char(65)) >= 0 ? char.compareTo_9(new Char(90)) <= 0 : false) ? char.minus(new Char(65)) + 10 | 0 : (char.compareTo_9(new Char(97)) >= 0 ? char.compareTo_9(new Char(122)) <= 0 : false) ? char.minus(new Char(97)) + 10 | 0 : -1;
    return tmp0_let_0 >= radix ? -1 : tmp0_let_0;
  }
  function decodeToString(_this_) {
    return decodeUtf8(_this_, 0, _this_.length, false);
  }
  function encodeToByteArray(_this_) {
    return encodeUtf8(_this_, 0, _this_.length, false);
  }
  function decodeToString_0(_this_, startIndex, endIndex, throwOnInvalidSequence) {
    Companion_getInstance().checkBoundsIndexes(startIndex, endIndex, _this_.length);
    return decodeUtf8(_this_, startIndex, endIndex, throwOnInvalidSequence);
  }
  function decodeToString$default(_this_, startIndex, endIndex, throwOnInvalidSequence, $mask0, $handler) {
    if (!(($mask0 & 1) === 0))
      startIndex = 0;
    if (!(($mask0 & 2) === 0))
      endIndex = _this_.length;
    if (!(($mask0 & 4) === 0))
      throwOnInvalidSequence = false;
    return decodeToString_0(_this_, startIndex, endIndex, throwOnInvalidSequence);
  }
  function startsWith(_this_, prefix, ignoreCase) {
    if (!ignoreCase) {
      var tmp0_nativeStartsWith_0 = 0;
      return _this_.startsWith(prefix, tmp0_nativeStartsWith_0);
    } else
      return regionMatches(_this_, 0, prefix, 0, prefix.length, ignoreCase);
  }
  function startsWith$default(_this_, prefix, ignoreCase, $mask0, $handler) {
    if (!(($mask0 & 2) === 0))
      ignoreCase = false;
    return startsWith(_this_, prefix, ignoreCase);
  }
  function isBlank(_this_) {
    var tmp;
    if (charSequenceLength(_this_) === 0) {
      tmp = true;
    } else {
      var tmp_0;
      if (typeof _this_ === 'string') {
        tmp_0 = _this_;
      } else {
        {
          tmp_0 = toString_2(_this_);
        }
      }
      tmp = matches(tmp_0, '^[\\s\\xA0]+$');
    }
    return tmp;
  }
  function regionMatches(_this_, thisOffset, other, otherOffset, length, ignoreCase) {
    return regionMatchesImpl(_this_, thisOffset, other, otherOffset, length, ignoreCase);
  }
  function matches(_this_, regex) {
    var result = _this_.match(regex);
    return !(result == null) ? !(result.length === 0) : false;
  }
  var REPLACEMENT_BYTE_SEQUENCE;
  function decodeUtf8(bytes, startIndex, endIndex, throwOnMalformed) {
    var tmp0_require_0 = (startIndex >= 0 ? endIndex <= bytes.length : false) ? startIndex <= endIndex : false;
    if (!tmp0_require_0) {
      var message_1_1 = 'Failed requirement.';
      throw IllegalArgumentException_init_$Create$_0(toString_2(message_1_1));
    }var byteIndex = startIndex;
    var stringBuilder = StringBuilder_init_$Create$_1();
    while (byteIndex < endIndex) {
      var tmp0 = byteIndex;
      byteIndex = tmp0 + 1 | 0;
      var byte = bytes[tmp0];
      if (byte >= 0) {
        stringBuilder.append_2(numberToChar(byte));
        Unit_getInstance();
      } else if (byte >> 5 === -2) {
        var code = codePointFrom2(bytes, byte, byteIndex, endIndex, throwOnMalformed);
        if (code <= 0) {
          stringBuilder.append_2(new Char(65533));
          Unit_getInstance();
          byteIndex = byteIndex + (-code | 0) | 0;
        } else {
          stringBuilder.append_2(numberToChar(code));
          Unit_getInstance();
          byteIndex = byteIndex + 1 | 0;
        }
      } else if (byte >> 4 === -2) {
        var code_0 = codePointFrom3(bytes, byte, byteIndex, endIndex, throwOnMalformed);
        if (code_0 <= 0) {
          stringBuilder.append_2(new Char(65533));
          Unit_getInstance();
          byteIndex = byteIndex + (-code_0 | 0) | 0;
        } else {
          stringBuilder.append_2(numberToChar(code_0));
          Unit_getInstance();
          byteIndex = byteIndex + 2 | 0;
        }
      } else if (byte >> 3 === -2) {
        var code_1 = codePointFrom4(bytes, byte, byteIndex, endIndex, throwOnMalformed);
        if (code_1 <= 0) {
          stringBuilder.append_2(new Char(65533));
          Unit_getInstance();
          byteIndex = byteIndex + (-code_1 | 0) | 0;
        } else {
          var high = (code_1 - 65536 | 0) >> 10 | 55296;
          var low = code_1 & 1023 | 56320;
          stringBuilder.append_2(numberToChar(high));
          Unit_getInstance();
          stringBuilder.append_2(numberToChar(low));
          Unit_getInstance();
          byteIndex = byteIndex + 3 | 0;
        }
      } else {
        malformed(0, byteIndex, throwOnMalformed);
        Unit_getInstance();
        stringBuilder.append_2(new Char(65533));
        Unit_getInstance();
      }
    }
    return stringBuilder.toString();
  }
  function encodeUtf8(string, startIndex, endIndex, throwOnMalformed) {
    var tmp0_require_0 = (startIndex >= 0 ? endIndex <= string.length : false) ? startIndex <= endIndex : false;
    if (!tmp0_require_0) {
      var message_1_1 = 'Failed requirement.';
      throw IllegalArgumentException_init_$Create$_0(toString_2(message_1_1));
    }var bytes = new Int8Array(imul(endIndex - startIndex | 0, 3));
    var byteIndex = 0;
    var charIndex = startIndex;
    while (charIndex < endIndex) {
      var tmp0 = charIndex;
      charIndex = tmp0 + 1 | 0;
      var tmp1__get_code__0 = charSequenceGet(string, tmp0);
      var code = tmp1__get_code__0.toInt_5();
      if (code < 128) {
        var tmp1 = byteIndex;
        byteIndex = tmp1 + 1 | 0;
        bytes[tmp1] = toByte(code);
      } else if (code < 2048) {
        var tmp2 = byteIndex;
        byteIndex = tmp2 + 1 | 0;
        bytes[tmp2] = toByte(code >> 6 | 192);
        var tmp3 = byteIndex;
        byteIndex = tmp3 + 1 | 0;
        bytes[tmp3] = toByte(code & 63 | 128);
      } else if (code < 55296 ? true : code >= 57344) {
        var tmp4 = byteIndex;
        byteIndex = tmp4 + 1 | 0;
        bytes[tmp4] = toByte(code >> 12 | 224);
        var tmp5 = byteIndex;
        byteIndex = tmp5 + 1 | 0;
        bytes[tmp5] = toByte(code >> 6 & 63 | 128);
        var tmp6 = byteIndex;
        byteIndex = tmp6 + 1 | 0;
        bytes[tmp6] = toByte(code & 63 | 128);
      } else {
        var codePoint = codePointFromSurrogate(string, code, charIndex, endIndex, throwOnMalformed);
        if (codePoint <= 0) {
          var tmp7 = byteIndex;
          byteIndex = tmp7 + 1 | 0;
          bytes[tmp7] = REPLACEMENT_BYTE_SEQUENCE[0];
          var tmp8 = byteIndex;
          byteIndex = tmp8 + 1 | 0;
          bytes[tmp8] = REPLACEMENT_BYTE_SEQUENCE[1];
          var tmp9 = byteIndex;
          byteIndex = tmp9 + 1 | 0;
          bytes[tmp9] = REPLACEMENT_BYTE_SEQUENCE[2];
        } else {
          var tmp10 = byteIndex;
          byteIndex = tmp10 + 1 | 0;
          bytes[tmp10] = toByte(codePoint >> 18 | 240);
          var tmp11 = byteIndex;
          byteIndex = tmp11 + 1 | 0;
          bytes[tmp11] = toByte(codePoint >> 12 & 63 | 128);
          var tmp12 = byteIndex;
          byteIndex = tmp12 + 1 | 0;
          bytes[tmp12] = toByte(codePoint >> 6 & 63 | 128);
          var tmp13 = byteIndex;
          byteIndex = tmp13 + 1 | 0;
          bytes[tmp13] = toByte(codePoint & 63 | 128);
          var tmp14 = charIndex;
          charIndex = tmp14 + 1 | 0;
          Unit_getInstance();
        }
      }
    }
    return bytes.length === byteIndex ? bytes : copyOf_0(bytes, byteIndex);
  }
  function codePointFrom2(bytes, byte1, index, endIndex, throwOnMalformed) {
    if ((byte1 & 30) === 0 ? true : index >= endIndex) {
      return malformed(0, index, throwOnMalformed);
    }var byte2 = bytes[index];
    if (!((byte2 & 192) === 128)) {
      return malformed(0, index, throwOnMalformed);
    }return byte1 << 6 ^ byte2 ^ 3968;
  }
  function codePointFrom3(bytes, byte1, index, endIndex, throwOnMalformed) {
    if (index >= endIndex) {
      return malformed(0, index, throwOnMalformed);
    }var byte2 = bytes[index];
    if ((byte1 & 15) === 0) {
      if (!((byte2 & 224) === 160)) {
        return malformed(0, index, throwOnMalformed);
      }} else if ((byte1 & 15) === 13) {
      if (!((byte2 & 224) === 128)) {
        return malformed(0, index, throwOnMalformed);
      }} else if (!((byte2 & 192) === 128)) {
      return malformed(0, index, throwOnMalformed);
    }if ((index + 1 | 0) === endIndex) {
      return malformed(1, index, throwOnMalformed);
    }var byte3 = bytes[index + 1 | 0];
    if (!((byte3 & 192) === 128)) {
      return malformed(1, index, throwOnMalformed);
    }return byte1 << 12 ^ byte2 << 6 ^ byte3 ^ -123008;
  }
  function codePointFrom4(bytes, byte1, index, endIndex, throwOnMalformed) {
    if (index >= endIndex) {
      malformed(0, index, throwOnMalformed);
      Unit_getInstance();
    }var byte2 = bytes[index];
    if ((byte1 & 15) === 0) {
      if ((byte2 & 240) <= 128) {
        return malformed(0, index, throwOnMalformed);
      }} else if ((byte1 & 15) === 4) {
      if (!((byte2 & 240) === 128)) {
        return malformed(0, index, throwOnMalformed);
      }} else if ((byte1 & 15) > 4) {
      return malformed(0, index, throwOnMalformed);
    } else if (!((byte2 & 192) === 128)) {
      return malformed(0, index, throwOnMalformed);
    }if ((index + 1 | 0) === endIndex) {
      return malformed(1, index, throwOnMalformed);
    }var byte3 = bytes[index + 1 | 0];
    if (!((byte3 & 192) === 128)) {
      return malformed(1, index, throwOnMalformed);
    }if ((index + 2 | 0) === endIndex) {
      return malformed(2, index, throwOnMalformed);
    }var byte4 = bytes[index + 2 | 0];
    if (!((byte4 & 192) === 128)) {
      return malformed(2, index, throwOnMalformed);
    }return byte1 << 18 ^ byte2 << 12 ^ byte3 << 6 ^ byte4 ^ 3678080;
  }
  function malformed(size_0, index, throwOnMalformed) {
    if (throwOnMalformed)
      throw new CharacterCodingException('' + 'Malformed sequence starting at ' + (index - 1 | 0));
    return -size_0 | 0;
  }
  function codePointFromSurrogate(string, high, index, endIndex, throwOnMalformed) {
    if (!(55296 <= high ? high <= 56319 : false) ? true : index >= endIndex) {
      return malformed(0, index, throwOnMalformed);
    }var tmp0__get_code__0 = charSequenceGet(string, index);
    var low = tmp0__get_code__0.toInt_5();
    if (!(56320 <= low ? low <= 57343 : false)) {
      return malformed(0, index, throwOnMalformed);
    }return 65536 + ((high & 1023) << 10) | 0 | low & 1023;
  }
  function REPLACEMENT_BYTE_SEQUENCE$init$() {
    var tmp0_byteArrayOf_0 = new Int8Array([-17, -65, -67]);
    return tmp0_byteArrayOf_0;
  }
  function addSuppressed(_this_, exception) {
    if (!(_this_ === exception)) {
      var tmp0_unsafeCast_0 = _this_._suppressed;
      var suppressed = tmp0_unsafeCast_0;
      if (suppressed == null) {
        _this_._suppressed = mutableListOf([exception]);
      } else {
        suppressed.add_39(exception);
        Unit_getInstance();
      }
    }}
  var DurationUnit_NANOSECONDS_instance;
  var DurationUnit_MICROSECONDS_instance;
  var DurationUnit_MILLISECONDS_instance;
  var DurationUnit_SECONDS_instance;
  var DurationUnit_MINUTES_instance;
  var DurationUnit_HOURS_instance;
  var DurationUnit_DAYS_instance;
  var DurationUnit_entriesInitialized;
  function DurationUnit_initEntries() {
    if (DurationUnit_entriesInitialized)
      return Unit_getInstance();
    DurationUnit_entriesInitialized = true;
    DurationUnit_NANOSECONDS_instance = new DurationUnit('NANOSECONDS', 0, 1.0);
    DurationUnit_MICROSECONDS_instance = new DurationUnit('MICROSECONDS', 1, 1000.0);
    DurationUnit_MILLISECONDS_instance = new DurationUnit('MILLISECONDS', 2, 1000000.0);
    DurationUnit_SECONDS_instance = new DurationUnit('SECONDS', 3, 1.0E9);
    DurationUnit_MINUTES_instance = new DurationUnit('MINUTES', 4, 6.0E10);
    DurationUnit_HOURS_instance = new DurationUnit('HOURS', 5, 3.6E12);
    DurationUnit_DAYS_instance = new DurationUnit('DAYS', 6, 8.64E13);
  }
  function DurationUnit(name, ordinal, scale) {
    Enum.call(this, name, ordinal);
    this._scale = scale;
  }
  DurationUnit.$metadata$ = {
    simpleName: 'DurationUnit',
    kind: 'class',
    interfaces: []
  };
  function convertDurationUnit(value_0, sourceUnit, targetUnit) {
    var sourceCompareTarget = compareTo(sourceUnit._scale, targetUnit._scale);
    return sourceCompareTarget > 0 ? value_0 * (sourceUnit._scale / targetUnit._scale) : sourceCompareTarget < 0 ? value_0 / (targetUnit._scale / sourceUnit._scale) : value_0;
  }
  function DurationUnit_NANOSECONDS_getInstance() {
    DurationUnit_initEntries();
    return DurationUnit_NANOSECONDS_instance;
  }
  function DurationUnit_MILLISECONDS_getInstance() {
    DurationUnit_initEntries();
    return DurationUnit_MILLISECONDS_instance;
  }
  function Companion_9() {
    Companion_instance_8 = this;
    this._MIN_VALUE_5 = new Char(0);
    this._MAX_VALUE_5 = new Char(65535);
    this._MIN_HIGH_SURROGATE = new Char(55296);
    this._MAX_HIGH_SURROGATE = new Char(56319);
    this._MIN_LOW_SURROGATE = new Char(56320);
    this._MAX_LOW_SURROGATE = new Char(57343);
    this._MIN_SURROGATE = new Char(55296);
    this._MAX_SURROGATE = new Char(57343);
    this._SIZE_BYTES_5 = 2;
    this._SIZE_BITS_5 = 16;
  }
  Companion_9.$metadata$ = {
    simpleName: 'Companion',
    kind: 'object',
    interfaces: []
  };
  var Companion_instance_8;
  function Companion_getInstance_8() {
    if (Companion_instance_8 == null)
      new Companion_9();
    return Companion_instance_8;
  }
  function Char(code) {
    Companion_getInstance_8();
    var tmp = this;
    tmp._value = _UShort___get_data__impl_(code) & 65535;
  }
  Char.prototype.compareTo_9 = function (other) {
    return this._value - other._value | 0;
  };
  Char.prototype.compareTo_34 = function (other) {
    return this.compareTo_9(other instanceof Char ? other : THROW_CCE());
  };
  Char.prototype.minus = function (other) {
    return this._value - other._value | 0;
  };
  Char.prototype.minus_39 = function (other) {
    return numberToChar(this._value - other | 0);
  };
  Char.prototype.toInt_5 = function () {
    return this._value;
  };
  Char.prototype.equals = function (other) {
    if (other === this)
      return true;
    if (!(other instanceof Char))
      return false;
    else {
    }
    return this._value === other._value;
  };
  Char.prototype.hashCode = function () {
    return this._value;
  };
  Char.prototype.toString = function () {
    var tmp0_unsafeCast_0 = String.fromCharCode(this._value);
    return tmp0_unsafeCast_0;
  };
  Char.$metadata$ = {
    simpleName: 'Char',
    kind: 'class',
    interfaces: [Comparable]
  };
  function Iterable() {
  }
  Iterable.$metadata$ = {
    simpleName: 'Iterable',
    kind: 'interface',
    interfaces: []
  };
  function List() {
  }
  List.$metadata$ = {
    simpleName: 'List',
    kind: 'interface',
    interfaces: [Collection]
  };
  function Collection() {
  }
  Collection.$metadata$ = {
    simpleName: 'Collection',
    kind: 'interface',
    interfaces: [Iterable]
  };
  function MutableList() {
  }
  MutableList.$metadata$ = {
    simpleName: 'MutableList',
    kind: 'interface',
    interfaces: [List, MutableCollection]
  };
  function Entry() {
  }
  Entry.$metadata$ = {
    simpleName: 'Entry',
    kind: 'interface',
    interfaces: []
  };
  function Map_0() {
  }
  Map_0.$metadata$ = {
    simpleName: 'Map',
    kind: 'interface',
    interfaces: []
  };
  function MutableSet() {
  }
  MutableSet.$metadata$ = {
    simpleName: 'MutableSet',
    kind: 'interface',
    interfaces: [Set, MutableCollection]
  };
  function Set() {
  }
  Set.$metadata$ = {
    simpleName: 'Set',
    kind: 'interface',
    interfaces: [Collection]
  };
  function MutableEntry() {
  }
  MutableEntry.$metadata$ = {
    simpleName: 'MutableEntry',
    kind: 'interface',
    interfaces: [Entry]
  };
  function MutableMap() {
  }
  MutableMap.$metadata$ = {
    simpleName: 'MutableMap',
    kind: 'interface',
    interfaces: [Map_0]
  };
  function MutableCollection() {
  }
  MutableCollection.$metadata$ = {
    simpleName: 'MutableCollection',
    kind: 'interface',
    interfaces: [Collection, MutableIterable]
  };
  function MutableIterable() {
  }
  MutableIterable.$metadata$ = {
    simpleName: 'MutableIterable',
    kind: 'interface',
    interfaces: [Iterable]
  };
  function Companion_10() {
    Companion_instance_9 = this;
  }
  Companion_10.$metadata$ = {
    simpleName: 'Companion',
    kind: 'object',
    interfaces: []
  };
  var Companion_instance_9;
  function Companion_getInstance_9() {
    if (Companion_instance_9 == null)
      new Companion_10();
    return Companion_instance_9;
  }
  function Enum(name, ordinal) {
    Companion_getInstance_9();
    this._name = name;
    this._ordinal = ordinal;
  }
  Enum.prototype._get_name__18 = function () {
    return this._name;
  };
  Enum.prototype.compareTo_11 = function (other) {
    return compareTo(this._ordinal, other._ordinal);
  };
  Enum.prototype.compareTo_34 = function (other) {
    return this.compareTo_11(other instanceof Enum ? other : THROW_CCE());
  };
  Enum.prototype.equals = function (other) {
    return this === other;
  };
  Enum.prototype.hashCode = function () {
    return identityHashCode(this);
  };
  Enum.prototype.toString = function () {
    return this._name;
  };
  Enum.$metadata$ = {
    simpleName: 'Enum',
    kind: 'class',
    interfaces: [Comparable]
  };
  function toString_1(_this_) {
    var tmp0_safe_receiver = _this_;
    var tmp1_elvis_lhs = tmp0_safe_receiver == null ? null : toString_2(tmp0_safe_receiver);
    return tmp1_elvis_lhs == null ? 'null' : tmp1_elvis_lhs;
  }
  function fillArrayVal(array, initValue) {
    var inductionVariable = 0;
    var last_2 = array.length - 1 | 0;
    if (inductionVariable <= last_2)
      do {
        var i = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        array[i] = initValue;
      }
       while (!(i === last_2));
    return array;
  }
  function arrayIterator(array) {
    return new _no_name_provided__53(array);
  }
  function intArrayIterator(array) {
    return new _no_name_provided__54(array);
  }
  function longArray(size_0) {
    var tmp0_withType_0 = 'LongArray';
    var tmp1_withType_0 = fillArrayVal(Array(size_0), new Long(0, 0));
    tmp1_withType_0.$type$ = tmp0_withType_0;
    var tmp2_unsafeCast_0 = tmp1_withType_0;
    return tmp2_unsafeCast_0;
  }
  function charArrayOf(arr) {
    var tmp0_withType_0 = 'CharArray';
    var tmp1_withType_0 = arr.slice();
    tmp1_withType_0.$type$ = tmp0_withType_0;
    var tmp2_unsafeCast_0 = tmp1_withType_0;
    return tmp2_unsafeCast_0;
  }
  function longArrayOf(arr) {
    var tmp0_withType_0 = 'LongArray';
    var tmp1_withType_0 = arr.slice();
    tmp1_withType_0.$type$ = tmp0_withType_0;
    var tmp2_unsafeCast_0 = tmp1_withType_0;
    return tmp2_unsafeCast_0;
  }
  function _no_name_provided__53($array) {
    this._$array = $array;
    this._index_5 = 0;
  }
  _no_name_provided__53.prototype.hasNext_41 = function () {
    return !(this._index_5 === this._$array.length);
  };
  _no_name_provided__53.prototype.next_46 = function () {
    var tmp;
    if (!(this._index_5 === this._$array.length)) {
      var tmp0_this = this;
      var tmp1 = tmp0_this._index_5;
      tmp0_this._index_5 = tmp1 + 1 | 0;
      tmp = this._$array[tmp1];
    } else {
      throw NoSuchElementException_init_$Create$_0('' + this._index_5);
    }
    return tmp;
  };
  _no_name_provided__53.$metadata$ = {
    kind: 'class',
    interfaces: [Iterator_2]
  };
  function _no_name_provided__54($array) {
    this._$array_0 = $array;
    IntIterator.call(this);
    this._index_6 = 0;
  }
  _no_name_provided__54.prototype.hasNext_41 = function () {
    return !(this._index_6 === this._$array_0.length);
  };
  _no_name_provided__54.prototype.nextInt_1 = function () {
    var tmp;
    if (!(this._index_6 === this._$array_0.length)) {
      var tmp0_this = this;
      var tmp1 = tmp0_this._index_6;
      tmp0_this._index_6 = tmp1 + 1 | 0;
      tmp = this._$array_0[tmp1];
    } else {
      throw NoSuchElementException_init_$Create$_0('' + this._index_6);
    }
    return tmp;
  };
  _no_name_provided__54.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  var buf;
  var bufFloat64;
  var bufInt32;
  var lowIndex;
  var highIndex;
  function getNumberHashCode(obj) {
    var tmp0_unsafeCast_0 = jsBitwiseOr(obj, 0);
    if (tmp0_unsafeCast_0 === obj) {
      return numberToInt(obj);
    } else {
    }
    bufFloat64[0] = obj;
    return imul(bufInt32[highIndex], 31) + bufInt32[lowIndex] | 0;
  }
  function bufFloat64$init$() {
    var tmp0_unsafeCast_0 = new Float64Array(buf);
    return tmp0_unsafeCast_0;
  }
  function bufInt32$init$() {
    var tmp0_unsafeCast_0 = new Int32Array(buf);
    return tmp0_unsafeCast_0;
  }
  function lowIndex$init$() {
    bufFloat64[0] = -1.0;
    return !(bufInt32[0] === 0) ? 1 : 0;
  }
  function charSequenceGet(a, index) {
    var tmp;
    if (isString(a)) {
      var tmp0_unsafeCast_0 = a.charCodeAt(index);
      var tmp1_Char_0 = tmp0_unsafeCast_0;
      var tmp_0;
      Companion_getInstance_8();
      var tmp0__get_code__0_1 = new Char(0);
      if (tmp1_Char_0 < tmp0__get_code__0_1.toInt_5()) {
        tmp_0 = true;
      } else {
        {
          Companion_getInstance_8();
          var tmp1__get_code__0_2 = new Char(65535);
          tmp_0 = tmp1_Char_0 > tmp1__get_code__0_2.toInt_5();
        }
      }
      if (tmp_0) {
        throw IllegalArgumentException_init_$Create$_0('' + 'Invalid Char code: ' + tmp1_Char_0);
      } else {
      }
      tmp = new Char(_UShort___init__impl_(toShort(tmp1_Char_0)));
    } else {
      tmp = a.get_72(index);
    }
    return tmp;
  }
  function isString(a) {
    return typeof a === 'string';
  }
  function charSequenceLength(a) {
    var tmp;
    if (isString(a)) {
      var tmp0_unsafeCast_0 = a.length;
      tmp = tmp0_unsafeCast_0;
    } else {
      tmp = a._get_length__33();
    }
    return tmp;
  }
  function charSequenceSubSequence(a, startIndex, endIndex) {
    var tmp;
    if (isString(a)) {
      var tmp0_unsafeCast_0 = a.substring(startIndex, endIndex);
      tmp = tmp0_unsafeCast_0;
    } else {
      tmp = a.subSequence_1(startIndex, endIndex);
    }
    return tmp;
  }
  function contentEqualsInternal(_this_, other) {
    var a = _this_;
    var b = other;
    if (a === b)
      return true;
    if (((a == null ? true : b == null) ? true : !isArrayish(b)) ? true : a.length != b.length)
      return false;
    var inductionVariable = 0;
    var last_2 = a.length;
    if (inductionVariable < last_2)
      do {
        var i = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        if (!equals_0(a[i], b[i])) {
          return false;
        }}
       while (inductionVariable < last_2);
    return true;
  }
  function arrayToString(array) {
    return joinToString$default_0(array, ', ', '[', ']', 0, null, _no_name_provided_$factory_39(), 24, null);
  }
  function _no_name_provided__55() {
  }
  _no_name_provided__55.prototype.invoke_79 = function (it) {
    return toString_2(it);
  };
  _no_name_provided__55.prototype.invoke_1115 = function (p1) {
    return this.invoke_79((p1 == null ? true : isObject(p1)) ? p1 : THROW_CCE());
  };
  _no_name_provided__55.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided_$factory_39() {
    var i = new _no_name_provided__55();
    return function (p1) {
      return i.invoke_79(p1);
    };
  }
  function compareTo(a, b) {
    var tmp0_subject = typeof a;
    var tmp;
    switch (tmp0_subject) {
      case 'number':
        var tmp_0;
        if (typeof b === 'number') {
          tmp_0 = doubleCompareTo(a, b);
        } else {
          if (b instanceof Long) {
            tmp_0 = doubleCompareTo(a, b.toDouble_4());
          } else {
            {
              tmp_0 = primitiveCompareTo(a, b);
            }
          }
        }

        tmp = tmp_0;
        break;
      case 'string':
      case 'boolean':
        tmp = primitiveCompareTo(a, b);
        break;
      default:tmp = compareToDoNotIntrinsicify(a, b);
        break;
    }
    return tmp;
  }
  function doubleCompareTo(a, b) {
    var tmp;
    if (a < b) {
      tmp = -1;
    } else if (a > b) {
      tmp = 1;
    } else if (a === b) {
      var tmp_0;
      if (a !== 0) {
        tmp_0 = 0;
      } else {
        var tmp0_asDynamic_0 = 1;
        var ia = tmp0_asDynamic_0 / a;
        var tmp_1;
        var tmp1_asDynamic_0 = 1;
        if (ia === tmp1_asDynamic_0 / b) {
          tmp_1 = 0;
        } else {
          if (ia < 0) {
            tmp_1 = -1;
          } else {
            {
              tmp_1 = 1;
            }
          }
        }
        tmp_0 = tmp_1;
      }
      tmp = tmp_0;
    } else if (a !== a) {
      tmp = b !== b ? 0 : 1;
    } else {
      tmp = -1;
    }
    return tmp;
  }
  function primitiveCompareTo(a, b) {
    return a < b ? -1 : a > b ? 1 : 0;
  }
  function compareToDoNotIntrinsicify(a, b) {
    return a.compareTo_34(b);
  }
  function identityHashCode(obj) {
    return getObjectHashCode(obj);
  }
  function getObjectHashCode(obj) {
    if (!jsIn('kotlinHashCodeValue$', obj)) {
      var hash = jsBitwiseOr(Math.random() * 4.294967296E9, 0);
      var descriptor = new Object();
      descriptor.value = hash;
      descriptor.enumerable = false;
      Object.defineProperty(obj, 'kotlinHashCodeValue$', descriptor);
    }var tmp0_unsafeCast_0 = obj['kotlinHashCodeValue$'];
    return tmp0_unsafeCast_0;
  }
  function equals_0(obj1, obj2) {
    if (obj1 == null) {
      return obj2 == null;
    }if (obj2 == null) {
      return false;
    }if (typeof obj1 === 'object' ? typeof obj1.equals === 'function' : false) {
      return obj1.equals(obj2);
    }if (obj1 !== obj1) {
      return obj2 !== obj2;
    }if (typeof obj1 === 'number' ? typeof obj2 === 'number' : false) {
      var tmp;
      if (obj1 === obj2) {
        var tmp_0;
        if (obj1 !== 0) {
          tmp_0 = true;
        } else {
          var tmp0_asDynamic_0 = 1;
          var tmp_1 = tmp0_asDynamic_0 / obj1;
          var tmp1_asDynamic_0 = 1;
          tmp_0 = tmp_1 === tmp1_asDynamic_0 / obj2;
        }
        tmp = tmp_0;
      } else {
        tmp = false;
      }
      return tmp;
    }return obj1 === obj2;
  }
  function hashCode(obj) {
    if (obj == null)
      return 0;
    var tmp0_subject = typeof obj;
    var tmp;
    switch (tmp0_subject) {
      case 'object':
        tmp = 'function' === typeof obj.hashCode ? obj.hashCode() : getObjectHashCode(obj);
        break;
      case 'function':
        tmp = getObjectHashCode(obj);
        break;
      case 'number':
        tmp = getNumberHashCode(obj);
        break;
      case 'boolean':
        var tmp_0;
        if (obj) {
          tmp_0 = 1;
        } else {
          {
            tmp_0 = 0;
          }
        }

        tmp = tmp_0;
        break;
      default:tmp = getStringHashCode(hashCode$outlinedJsCode$(obj));
        break;
    }
    return tmp;
  }
  function toString_2(o) {
    var tmp;
    if (o == null) {
      tmp = 'null';
    } else if (isArrayish(o)) {
      tmp = '[...]';
    } else {
      var tmp0_unsafeCast_0 = o.toString();
      tmp = tmp0_unsafeCast_0;
    }
    return tmp;
  }
  function getStringHashCode(str) {
    var hash = 0;
    var length = str.length;
    var inductionVariable = 0;
    var last_2 = length - 1 | 0;
    if (inductionVariable <= last_2)
      do {
        var i = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        var code = str.charCodeAt(i);
        hash = imul(hash, 31) + code | 0;
      }
       while (!(i === last_2));
    return hash;
  }
  function anyToString(o) {
    return Object.prototype.toString.call(o);
  }
  function boxIntrinsic(x) {
    var tmp0_error_0 = 'Should be lowered';
    throw IllegalStateException_init_$Create$_0(toString_2(tmp0_error_0));
  }
  function unboxIntrinsic(x) {
    var tmp0_error_0 = 'Should be lowered';
    throw IllegalStateException_init_$Create$_0(toString_2(tmp0_error_0));
  }
  function captureStack(instance, constructorFunction) {
    if (Error.captureStackTrace != null) {
      Error.captureStackTrace(instance, constructorFunction);
    } else {
      instance.stack = (new Error()).stack;
    }
  }
  function extendThrowable(this_, message, cause) {
    Error.call(this_);
    setPropertiesToThrowableInstance(this_, message, cause);
  }
  function setPropertiesToThrowableInstance(this_, message, cause) {
    if (!hasOwnPrototypeProperty(this_, 'message')) {
      var tmp;
      if (message == null) {
        var tmp_0;
        if (!(message === null)) {
          var tmp0_safe_receiver = cause;
          var tmp1_elvis_lhs = tmp0_safe_receiver == null ? null : tmp0_safe_receiver.toString();
          tmp_0 = tmp1_elvis_lhs == null ? undefined : tmp1_elvis_lhs;
        } else {
          tmp_0 = undefined;
        }
        tmp = tmp_0;
      } else {
        tmp = message;
      }
      this_.message = tmp;
    }if (!hasOwnPrototypeProperty(this_, 'cause')) {
      this_.cause = cause;
    }this_.name = Object.getPrototypeOf(this_).constructor.name;
  }
  function hasOwnPrototypeProperty(o, name) {
    var tmp0_unsafeCast_0 = Object.getPrototypeOf(o).hasOwnProperty(name);
    return tmp0_unsafeCast_0;
  }
  function hashCode$outlinedJsCode$(obj) {
    return String(obj);
  }
  function getContinuation() {
    throw Exception_init_$Create$_0('Implemented as intrinsic');
  }
  function returnIfSuspended(argument, $cont) {
    throw Exception_init_$Create$_0('Implemented as intrinsic');
  }
  function ensureNotNull(v) {
    var tmp;
    if (v == null) {
      THROW_NPE();
    } else {
      tmp = v;
    }
    return tmp;
  }
  function THROW_NPE() {
    throw NullPointerException_init_$Create$();
  }
  function noWhenBranchMatchedException() {
    throw NoWhenBranchMatchedException_init_$Create$();
  }
  function THROW_CCE() {
    throw ClassCastException_init_$Create$();
  }
  function throwUninitializedPropertyAccessException(name) {
    throw UninitializedPropertyAccessException_init_$Create$('' + 'lateinit property ' + name + ' has not been initialized');
  }
  function THROW_ISE() {
    throw IllegalStateException_init_$Create$();
  }
  function lazy(initializer) {
    return new UnsafeLazyImpl(initializer);
  }
  function fillFrom(src_0, dst) {
    var srcLen = src_0.length;
    var dstLen = dst.length;
    var index = 0;
    var arr = dst;
    while (index < srcLen ? index < dstLen : false) {
      var tmp = index;
      var tmp0 = index;
      index = tmp0 + 1 | 0;
      arr[tmp] = src_0[tmp0];
    }
    return dst;
  }
  function arrayCopyResize(source, newSize, defaultValue) {
    var tmp0_unsafeCast_0 = source.slice(0, newSize);
    var result = tmp0_unsafeCast_0;
    if (source.$type$ !== undefined) {
      result.$type$ = source.$type$;
    }var index = source.length;
    if (newSize > index) {
      result.length = newSize;
      while (index < newSize) {
        var tmp0 = index;
        index = tmp0 + 1 | 0;
        result[tmp0] = defaultValue;
      }
    }return result;
  }
  function arrayPlusCollection(array, collection) {
    var tmp0_unsafeCast_0 = array.slice();
    var result = tmp0_unsafeCast_0;
    result.length = result.length + collection._get_size__52() | 0;
    if (array.$type$ !== undefined) {
      result.$type$ = array.$type$;
    }var index = array.length;
    var tmp0_iterator = collection.iterator_66();
    while (tmp0_iterator.hasNext_41()) {
      var element = tmp0_iterator.next_46();
      var tmp1 = index;
      index = tmp1 + 1 | 0;
      result[tmp1] = element;
    }
    return result;
  }
  function Companion_11() {
    Companion_instance_10 = this;
    this._MIN_VALUE_6 = new Long(0, -2147483648);
    this._MAX_VALUE_6 = new Long(-1, 2147483647);
    this._SIZE_BYTES_6 = 8;
    this._SIZE_BITS_6 = 64;
  }
  Companion_11.$metadata$ = {
    simpleName: 'Companion',
    kind: 'object',
    interfaces: []
  };
  var Companion_instance_10;
  function Companion_getInstance_10() {
    if (Companion_instance_10 == null)
      new Companion_11();
    return Companion_instance_10;
  }
  function Long(low, high) {
    Companion_getInstance_10();
    Number_0.call(this);
    this._low = low;
    this._high = high;
  }
  Long.prototype.compareTo_75 = function (other) {
    return compare(this, other);
  };
  Long.prototype.compareTo_34 = function (other) {
    return this.compareTo_75(other instanceof Long ? other : THROW_CCE());
  };
  Long.prototype.plus_71 = function (other) {
    return add(this, other);
  };
  Long.prototype.minus_40 = function (other) {
    return subtract_0(this, other);
  };
  Long.prototype.times_40 = function (other) {
    return multiply(this, other);
  };
  Long.prototype.div_34 = function (other) {
    return divide(this, other);
  };
  Long.prototype.rem_33 = function (other) {
    return modulo(this, other);
  };
  Long.prototype.inc_5 = function () {
    return this.plus_71(new Long(1, 0));
  };
  Long.prototype.dec_5 = function () {
    return this.minus_40(new Long(1, 0));
  };
  Long.prototype.unaryMinus_5 = function () {
    return this.inv_0().plus_71(new Long(1, 0));
  };
  Long.prototype.shl_4 = function (bitCount_1) {
    return shiftLeft(this, bitCount_1);
  };
  Long.prototype.shr_4 = function (bitCount_1) {
    return shiftRight(this, bitCount_1);
  };
  Long.prototype.ushr_0 = function (bitCount_1) {
    return shiftRightUnsigned(this, bitCount_1);
  };
  Long.prototype.and = function (other) {
    return new Long(this._low & other._low, this._high & other._high);
  };
  Long.prototype.or = function (other) {
    return new Long(this._low | other._low, this._high | other._high);
  };
  Long.prototype.xor = function (other) {
    return new Long(this._low ^ other._low, this._high ^ other._high);
  };
  Long.prototype.inv_0 = function () {
    return new Long(~this._low, ~this._high);
  };
  Long.prototype.toByte_4 = function () {
    return toByte(this._low);
  };
  Long.prototype.toInt_5 = function () {
    return this._low;
  };
  Long.prototype.toDouble_4 = function () {
    return toNumber(this);
  };
  Long.prototype.valueOf = function () {
    return this.toDouble_4();
  };
  Long.prototype.equals = function (other) {
    var tmp;
    if (other instanceof Long) {
      tmp = equalsLong(this, other);
    } else {
      {
        tmp = false;
      }
    }
    return tmp;
  };
  Long.prototype.hashCode = function () {
    return hashCode_0(this);
  };
  Long.prototype.toString = function () {
    return toStringImpl(this, 10);
  };
  Long.$metadata$ = {
    simpleName: 'Long',
    kind: 'class',
    interfaces: [Comparable]
  };
  var ZERO;
  var ONE;
  var NEG_ONE;
  var MAX_VALUE;
  var MIN_VALUE;
  var TWO_PWR_24_;
  function compare(_this_, other) {
    if (equalsLong(_this_, other)) {
      return 0;
    }var thisNeg = isNegative(_this_);
    var otherNeg = isNegative(other);
    return (thisNeg ? !otherNeg : false) ? -1 : (!thisNeg ? otherNeg : false) ? 1 : isNegative(subtract_0(_this_, other)) ? -1 : 1;
  }
  function add(_this_, other) {
    var a48 = _this_._high >>> 16;
    var a32 = _this_._high & 65535;
    var a16 = _this_._low >>> 16;
    var a00 = _this_._low & 65535;
    var b48 = other._high >>> 16;
    var b32 = other._high & 65535;
    var b16 = other._low >>> 16;
    var b00 = other._low & 65535;
    var c48 = 0;
    var c32 = 0;
    var c16 = 0;
    var c00 = 0;
    c00 = c00 + (a00 + b00 | 0) | 0;
    c16 = c16 + (c00 >>> 16) | 0;
    c00 = c00 & 65535;
    c16 = c16 + (a16 + b16 | 0) | 0;
    c32 = c32 + (c16 >>> 16) | 0;
    c16 = c16 & 65535;
    c32 = c32 + (a32 + b32 | 0) | 0;
    c48 = c48 + (c32 >>> 16) | 0;
    c32 = c32 & 65535;
    c48 = c48 + (a48 + b48 | 0) | 0;
    c48 = c48 & 65535;
    return new Long(c16 << 16 | c00, c48 << 16 | c32);
  }
  function subtract_0(_this_, other) {
    return add(_this_, other.unaryMinus_5());
  }
  function multiply(_this_, other) {
    if (isZero(_this_)) {
      return ZERO;
    } else if (isZero(other)) {
      return ZERO;
    }if (equalsLong(_this_, MIN_VALUE)) {
      return isOdd(other) ? MIN_VALUE : ZERO;
    } else if (equalsLong(other, MIN_VALUE)) {
      return isOdd(_this_) ? MIN_VALUE : ZERO;
    }if (isNegative(_this_)) {
      var tmp;
      if (isNegative(other)) {
        tmp = multiply(negate(_this_), negate(other));
      } else {
        tmp = negate(multiply(negate(_this_), other));
      }
      return tmp;
    } else if (isNegative(other)) {
      return negate(multiply(_this_, negate(other)));
    }if (lessThan(_this_, TWO_PWR_24_) ? lessThan(other, TWO_PWR_24_) : false) {
      return fromNumber(toNumber(_this_) * toNumber(other));
    }var a48 = _this_._high >>> 16;
    var a32 = _this_._high & 65535;
    var a16 = _this_._low >>> 16;
    var a00 = _this_._low & 65535;
    var b48 = other._high >>> 16;
    var b32 = other._high & 65535;
    var b16 = other._low >>> 16;
    var b00 = other._low & 65535;
    var c48 = 0;
    var c32 = 0;
    var c16 = 0;
    var c00 = 0;
    c00 = c00 + imul(a00, b00) | 0;
    c16 = c16 + (c00 >>> 16) | 0;
    c00 = c00 & 65535;
    c16 = c16 + imul(a16, b00) | 0;
    c32 = c32 + (c16 >>> 16) | 0;
    c16 = c16 & 65535;
    c16 = c16 + imul(a00, b16) | 0;
    c32 = c32 + (c16 >>> 16) | 0;
    c16 = c16 & 65535;
    c32 = c32 + imul(a32, b00) | 0;
    c48 = c48 + (c32 >>> 16) | 0;
    c32 = c32 & 65535;
    c32 = c32 + imul(a16, b16) | 0;
    c48 = c48 + (c32 >>> 16) | 0;
    c32 = c32 & 65535;
    c32 = c32 + imul(a00, b32) | 0;
    c48 = c48 + (c32 >>> 16) | 0;
    c32 = c32 & 65535;
    c48 = c48 + (((imul(a48, b00) + imul(a32, b16) | 0) + imul(a16, b32) | 0) + imul(a00, b48) | 0) | 0;
    c48 = c48 & 65535;
    return new Long(c16 << 16 | c00, c48 << 16 | c32);
  }
  function divide(_this_, other) {
    if (isZero(other)) {
      throw Exception_init_$Create$_0('division by zero');
    } else if (isZero(_this_)) {
      return ZERO;
    }if (equalsLong(_this_, MIN_VALUE)) {
      if (equalsLong(other, ONE) ? true : equalsLong(other, NEG_ONE)) {
        return MIN_VALUE;
      } else if (equalsLong(other, MIN_VALUE)) {
        return ONE;
      } else {
        var halfThis = shiftRight(_this_, 1);
        var approx = shiftLeft(halfThis.div_34(other), 1);
        if (equalsLong(approx, ZERO)) {
          return isNegative(other) ? ONE : NEG_ONE;
        } else {
          var rem = subtract_0(_this_, multiply(other, approx));
          return add(approx, rem.div_34(other));
        }
      }
    } else if (equalsLong(other, MIN_VALUE)) {
      return ZERO;
    }if (isNegative(_this_)) {
      var tmp;
      if (isNegative(other)) {
        tmp = negate(_this_).div_34(negate(other));
      } else {
        tmp = negate(negate(_this_).div_34(other));
      }
      return tmp;
    } else if (isNegative(other)) {
      return negate(_this_.div_34(negate(other)));
    }var res = ZERO;
    var rem_0 = _this_;
    while (greaterThanOrEqual(rem_0, other)) {
      var approxDouble = toNumber(rem_0) / toNumber(other);
      var approx2 = function () {
        var $externalVarargReceiverTmp = Math;
        return $externalVarargReceiverTmp.max.apply($externalVarargReceiverTmp, [].concat([].slice.call(new Float64Array([1.0, Math.floor(approxDouble)]))));
      }.call(this);
      var log2 = Math.ceil(Math.log(approx2) / Math.LN2);
      var delta_0 = log2 <= 48.0 ? 1.0 : Math.pow(2.0, log2 - 48);
      var approxRes = fromNumber(approx2);
      var approxRem = multiply(approxRes, other);
      while (isNegative(approxRem) ? true : greaterThan(approxRem, rem_0)) {
        approx2 = approx2 - delta_0;
        approxRes = fromNumber(approx2);
        approxRem = multiply(approxRes, other);
      }
      if (isZero(approxRes)) {
        approxRes = ONE;
      }res = add(res, approxRes);
      rem_0 = subtract_0(rem_0, approxRem);
    }
    return res;
  }
  function modulo(_this_, other) {
    return subtract_0(_this_, multiply(_this_.div_34(other), other));
  }
  function shiftLeft(_this_, numBits) {
    var numBits_0 = numBits & 63;
    if (numBits_0 === 0) {
      return _this_;
    } else {
      if (numBits_0 < 32) {
        return new Long(_this_._low << numBits_0, _this_._high << numBits_0 | _this_._low >>> (32 - numBits_0 | 0));
      } else {
        return new Long(0, _this_._low << (numBits_0 - 32 | 0));
      }
    }
  }
  function shiftRight(_this_, numBits) {
    var numBits_0 = numBits & 63;
    if (numBits_0 === 0) {
      return _this_;
    } else {
      if (numBits_0 < 32) {
        return new Long(_this_._low >>> numBits_0 | _this_._high << (32 - numBits_0 | 0), _this_._high >> numBits_0);
      } else {
        return new Long(_this_._high >> (numBits_0 - 32 | 0), _this_._high >= 0 ? 0 : -1);
      }
    }
  }
  function shiftRightUnsigned(_this_, numBits) {
    var numBits_0 = numBits & 63;
    if (numBits_0 === 0) {
      return _this_;
    } else {
      if (numBits_0 < 32) {
        return new Long(_this_._low >>> numBits_0 | _this_._high << (32 - numBits_0 | 0), _this_._high >>> numBits_0);
      } else {
        var tmp;
        if (numBits_0 === 32) {
          tmp = new Long(_this_._high, 0);
        } else {
          tmp = new Long(_this_._high >>> (numBits_0 - 32 | 0), 0);
        }
        return tmp;
      }
    }
  }
  function toNumber(_this_) {
    return _this_._high * 4.294967296E9 + getLowBitsUnsigned(_this_);
  }
  function equalsLong(_this_, other) {
    return _this_._high === other._high ? _this_._low === other._low : false;
  }
  function hashCode_0(l) {
    return l._low ^ l._high;
  }
  function toStringImpl(_this_, radix) {
    if (radix < 2 ? true : 36 < radix) {
      throw Exception_init_$Create$_0('' + 'radix out of range: ' + radix);
    }if (isZero(_this_)) {
      return '0';
    }if (isNegative(_this_)) {
      if (equalsLong(_this_, MIN_VALUE)) {
        var radixLong = fromInt(radix);
        var div_0 = _this_.div_34(radixLong);
        var rem = subtract_0(multiply(div_0, radixLong), _this_).toInt_5();
        var tmp = toStringImpl(div_0, radix);
        var tmp0_unsafeCast_0 = rem.toString(radix);
        return tmp + tmp0_unsafeCast_0;
      } else {
        return '' + '-' + toStringImpl(negate(_this_), radix);
      }
    }var radixToPower = fromNumber(Math.pow(radix, 6.0));
    var rem_0 = _this_;
    var result = '';
    while (true) {
      var remDiv = rem_0.div_34(radixToPower);
      var intval = subtract_0(rem_0, multiply(remDiv, radixToPower)).toInt_5();
      var tmp1_unsafeCast_0 = intval.toString(radix);
      var digits = tmp1_unsafeCast_0;
      rem_0 = remDiv;
      if (isZero(rem_0)) {
        return digits + result;
      } else {
        while (digits.length < 6) {
          digits = '0' + digits;
        }
        result = digits + result;
      }
    }
  }
  function fromInt(value_0) {
    return new Long(value_0, value_0 < 0 ? -1 : 0);
  }
  function isNegative(_this_) {
    return _this_._high < 0;
  }
  function isZero(_this_) {
    return _this_._high === 0 ? _this_._low === 0 : false;
  }
  function isOdd(_this_) {
    return (_this_._low & 1) === 1;
  }
  function negate(_this_) {
    return _this_.unaryMinus_5();
  }
  function lessThan(_this_, other) {
    return compare(_this_, other) < 0;
  }
  function fromNumber(value_0) {
    if (isNaN_0(value_0)) {
      return ZERO;
    } else if (value_0 <= -9.223372036854776E18) {
      return MIN_VALUE;
    } else if (value_0 + 1 >= 9.223372036854776E18) {
      return MAX_VALUE;
    } else if (value_0 < 0.0) {
      return negate(fromNumber(-value_0));
    } else {
      var twoPwr32 = 4.294967296E9;
      return new Long(jsBitwiseOr(value_0 % twoPwr32, 0), jsBitwiseOr(value_0 / twoPwr32, 0));
    }
  }
  function greaterThan(_this_, other) {
    return compare(_this_, other) > 0;
  }
  function greaterThanOrEqual(_this_, other) {
    return compare(_this_, other) >= 0;
  }
  function getLowBitsUnsigned(_this_) {
    return _this_._low >= 0 ? _this_._low : 4.294967296E9 + _this_._low;
  }
  function imul(a_local, b_local) {
    var lhs = jsBitwiseAnd(a_local, 4.29490176E9) * jsBitwiseAnd(b_local, 65535);
    var rhs = jsBitwiseAnd(a_local, 65535) * b_local;
    return jsBitwiseOr(lhs + rhs, 0);
  }
  function primitiveArrayConcat(args) {
    var size_local = 0;
    var inductionVariable = 0;
    var last_2 = args.length - 1 | 0;
    if (inductionVariable <= last_2)
      do {
        var i = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        var tmp = size_local;
        var tmp0_unsafeCast_0 = args[i];
        size_local = tmp + tmp0_unsafeCast_0.length | 0;
      }
       while (!(i === last_2));
    var a = args[0];
    var tmp1_unsafeCast_0 = primitiveArrayConcat$outlinedJsCode$(a, size_local);
    var result = tmp1_unsafeCast_0;
    if (a.$type$ != null) {
      var tmp2_withType_0 = a.$type$;
      result.$type$ = tmp2_withType_0;
    } else {
    }
    size_local = 0;
    var inductionVariable_0 = 0;
    var last_3 = args.length - 1 | 0;
    if (inductionVariable_0 <= last_3)
      do {
        var i_0 = inductionVariable_0;
        inductionVariable_0 = inductionVariable_0 + 1 | 0;
        var tmp3_unsafeCast_0 = args[i_0];
        var arr = tmp3_unsafeCast_0;
        var inductionVariable_1 = 0;
        var last_4 = arr.length - 1 | 0;
        if (inductionVariable_1 <= last_4)
          do {
            var j = inductionVariable_1;
            inductionVariable_1 = inductionVariable_1 + 1 | 0;
            var tmp3 = size_local;
            size_local = tmp3 + 1 | 0;
            result[tmp3] = arr[j];
          }
           while (!(j === last_4));
      }
       while (!(i_0 === last_3));
    return result;
  }
  function primitiveArrayConcat$outlinedJsCode$(a, size_local) {
    return new a.constructor(size_local);
  }
  function numberToByte(a) {
    return toByte(numberToInt(a));
  }
  function toByte(a) {
    var tmp0_unsafeCast_0 = toByte$outlinedJsCode$(a);
    return tmp0_unsafeCast_0;
  }
  function numberToInt(a) {
    var tmp;
    if (a instanceof Long) {
      tmp = a.toInt_5();
    } else {
      {
        tmp = doubleToInt(a);
      }
    }
    return tmp;
  }
  function doubleToInt(a) {
    return a > 2.147483647E9 ? 2147483647 : a < -2.147483648E9 ? -2147483648 : jsBitwiseOr(a, 0);
  }
  function numberToDouble(a) {
    var tmp0_unsafeCast_0 = numberToDouble$outlinedJsCode$_0(a);
    return tmp0_unsafeCast_0;
  }
  function toShort(a) {
    var tmp0_unsafeCast_0 = toShort$outlinedJsCode$_1(a);
    return tmp0_unsafeCast_0;
  }
  function numberToLong(a) {
    var tmp;
    if (a instanceof Long) {
      tmp = a;
    } else {
      {
        tmp = fromNumber(a);
      }
    }
    return tmp;
  }
  function numberToChar(a) {
    var tmp0_Char_0 = numberToInt(a) & 65535;
    var tmp;
    Companion_getInstance_8();
    var tmp0__get_code__0_1 = new Char(0);
    if (tmp0_Char_0 < tmp0__get_code__0_1.toInt_5()) {
      tmp = true;
    } else {
      {
        Companion_getInstance_8();
        var tmp1__get_code__0_2 = new Char(65535);
        tmp = tmp0_Char_0 > tmp1__get_code__0_2.toInt_5();
      }
    }
    if (tmp) {
      throw IllegalArgumentException_init_$Create$_0('' + 'Invalid Char code: ' + tmp0_Char_0);
    } else {
    }
    return new Char(_UShort___init__impl_(toShort(tmp0_Char_0)));
  }
  function toLong_0(a) {
    return fromInt(a);
  }
  function toByte$outlinedJsCode$(a) {
    return a << 24 >> 24;
  }
  function numberToDouble$outlinedJsCode$_0(a) {
    return +a;
  }
  function toShort$outlinedJsCode$_1(a) {
    return a << 16 >> 16;
  }
  function numberRangeToNumber(start_0, endInclusive) {
    return new IntRange(start_0, endInclusive);
  }
  var propertyRefClassMetadataCache;
  function getPropertyCallableRef(name, paramCount, type, getter, setter) {
    getter.get = getter;
    getter.set = setter;
    getter.callableName = name;
    var tmp0_unsafeCast_0 = getPropertyRefClass(getter, getKPropMetadata(paramCount, setter, type));
    return tmp0_unsafeCast_0;
  }
  function getPropertyRefClass(obj, metadata) {
    obj.$metadata$ = metadata;
    obj.constructor = obj;
    return obj;
  }
  function getKPropMetadata(paramCount, setter, type) {
    var mdata = propertyRefClassMetadataCache[paramCount][setter == null ? 0 : 1];
    if (mdata.interfaces.length == 0) {
      mdata.interfaces.push(type);
    }return mdata;
  }
  function getLocalDelegateReference(name, type, mutable, lambda) {
    return getPropertyCallableRef(name, 0, type, lambda, mutable ? lambda : null);
  }
  function propertyRefClassMetadataCache$init$() {
    var tmp = {kind: 'class', interfaces: []};
    var tmp0_arrayOf_0 = [tmp, {kind: 'class', interfaces: []}];
    var tmp_0 = tmp0_arrayOf_0;
    var tmp_1 = {kind: 'class', interfaces: []};
    var tmp1_arrayOf_0 = [tmp_1, {kind: 'class', interfaces: []}];
    var tmp_2 = tmp1_arrayOf_0;
    var tmp_3 = {kind: 'class', interfaces: []};
    var tmp2_arrayOf_0 = [tmp_3, {kind: 'class', interfaces: []}];
    var tmp3_arrayOf_0 = [tmp_0, tmp_2, tmp2_arrayOf_0];
    return tmp3_arrayOf_0;
  }
  function isArrayish(o) {
    var tmp;
    if (isJsArray(o)) {
      tmp = true;
    } else {
      var tmp0_unsafeCast_0 = ArrayBuffer.isView(o);
      tmp = tmp0_unsafeCast_0;
    }
    return tmp;
  }
  function isJsArray(obj) {
    var tmp0_unsafeCast_0 = Array.isArray(obj);
    return tmp0_unsafeCast_0;
  }
  function isInterface(obj, iface) {
    var tmp0_elvis_lhs = obj.constructor;
    var tmp;
    if (tmp0_elvis_lhs == null) {
      return false;
    } else {
      tmp = tmp0_elvis_lhs;
    }
    var ctor = tmp;
    return isInterfaceImpl(ctor, iface);
  }
  function isInterfaceImpl(ctor, iface) {
    if (ctor === iface)
      return true;
    var metadata = ctor.$metadata$;
    if (!(metadata == null)) {
      var interfaces = metadata.interfaces;
      var indexedObject = interfaces;
      var inductionVariable = 0;
      var last_2 = indexedObject.length;
      while (inductionVariable < last_2) {
        var i = indexedObject[inductionVariable];
        inductionVariable = inductionVariable + 1 | 0;
        if (isInterfaceImpl(i, iface)) {
          return true;
        }}
    }var superPrototype = !(ctor.prototype == null) ? Object.getPrototypeOf(ctor.prototype) : null;
    var superConstructor = superPrototype != null ? superPrototype.constructor : null;
    return !(superConstructor == null) ? isInterfaceImpl(superConstructor, iface) : false;
  }
  function isArray(obj) {
    var tmp;
    if (isJsArray(obj)) {
      tmp = !obj.$type$;
    } else {
      tmp = false;
    }
    return tmp;
  }
  function isObject(obj) {
    var objTypeOf = typeof obj;
    var tmp0_subject = objTypeOf;
    switch (tmp0_subject) {
      case 'string':
        return true;
      case 'number':
        return true;
      case 'boolean':
        return true;
      case 'function':
        return true;
      default:return jsInstanceOf(obj, Object);
    }
  }
  function isNumber(a) {
    var tmp;
    if (typeof a === 'number') {
      tmp = true;
    } else {
      tmp = a instanceof Long;
    }
    return tmp;
  }
  function isComparable(value_0) {
    var type = typeof value_0;
    return ((type === 'string' ? true : type === 'boolean') ? true : isNumber(value_0)) ? true : isInterface(value_0, _get_js_(getKClass_0(Comparable)));
  }
  function isCharSequence(value_0) {
    return typeof value_0 === 'string' ? true : isInterface(value_0, _get_js_(getKClass_0(CharSequence)));
  }
  function isBooleanArray(a) {
    return isJsArray(a) ? a.$type$ === 'BooleanArray' : false;
  }
  function isByteArray(a) {
    return jsInstanceOf(a, Int8Array);
  }
  function isShortArray(a) {
    return jsInstanceOf(a, Int16Array);
  }
  function isCharArray(a) {
    return isJsArray(a) ? a.$type$ === 'CharArray' : false;
  }
  function isIntArray(a) {
    return jsInstanceOf(a, Int32Array);
  }
  function isFloatArray(a) {
    return jsInstanceOf(a, Float32Array);
  }
  function isLongArray(a) {
    return isJsArray(a) ? a.$type$ === 'LongArray' : false;
  }
  function isDoubleArray(a) {
    return jsInstanceOf(a, Float64Array);
  }
  function jsIsType(obj, jsClass_0) {
    if (jsClass_0 === Object) {
      return isObject(obj);
    }if ((obj == null ? true : jsClass_0 == null) ? true : !(typeof obj === 'object') ? !(typeof obj === 'function') : false) {
      return false;
    }if (typeof jsClass_0 === 'function' ? jsInstanceOf(obj, jsClass_0) : false) {
      return true;
    }var proto = jsGetPrototypeOf(jsClass_0);
    var tmp0_safe_receiver = proto;
    var constructor = tmp0_safe_receiver == null ? null : tmp0_safe_receiver.constructor;
    if (constructor != null ? jsIn('$metadata$', constructor) : false) {
      var metadata = constructor.$metadata$;
      if (metadata.kind === 'object') {
        return obj === jsClass_0;
      }}var klassMetadata = jsClass_0.$metadata$;
    if (klassMetadata == null) {
      return jsInstanceOf(obj, jsClass_0);
    }if (klassMetadata.kind === 'interface' ? obj.constructor != null : false) {
      return isInterfaceImpl(obj.constructor, jsClass_0);
    }return false;
  }
  function jsGetPrototypeOf(jsClass_0) {
    return Object.getPrototypeOf(jsClass_0);
  }
  function copyOf(_this_, newSize) {
    var tmp0_require_0 = newSize >= 0;
    if (!tmp0_require_0) {
      var message_1 = '' + 'Invalid new array size: ' + newSize + '.';
      throw IllegalArgumentException_init_$Create$_0(toString_2(message_1));
    }return fillFrom(_this_, new Int32Array(newSize));
  }
  function copyOf_0(_this_, newSize) {
    var tmp0_require_0 = newSize >= 0;
    if (!tmp0_require_0) {
      var message_1 = '' + 'Invalid new array size: ' + newSize + '.';
      throw IllegalArgumentException_init_$Create$_0(toString_2(message_1));
    }return fillFrom(_this_, new Int8Array(newSize));
  }
  function plus_2(_this_, elements) {
    return arrayPlusCollection(_this_, elements);
  }
  function fill(_this_, element, fromIndex, toIndex) {
    Companion_getInstance().checkRangeIndexes(fromIndex, toIndex, _this_.length);
    _this_.fill(element, fromIndex, toIndex);
  }
  function copyOf_1(_this_, newSize) {
    var tmp0_require_0 = newSize >= 0;
    if (!tmp0_require_0) {
      var message_1 = '' + 'Invalid new array size: ' + newSize + '.';
      throw IllegalArgumentException_init_$Create$_0(toString_2(message_1));
    }return arrayCopyResize(_this_, newSize, null);
  }
  function fill_0(_this_, element, fromIndex, toIndex) {
    Companion_getInstance().checkRangeIndexes(fromIndex, toIndex, _this_.length);
    _this_.fill(element, fromIndex, toIndex);
  }
  function fill$default(_this_, element, fromIndex, toIndex, $mask0, $handler) {
    if (!(($mask0 & 2) === 0))
      fromIndex = 0;
    if (!(($mask0 & 4) === 0))
      toIndex = _this_.length;
    return fill_0(_this_, element, fromIndex, toIndex);
  }
  function copyOfRange(_this_, fromIndex, toIndex) {
    Companion_getInstance().checkRangeIndexes(fromIndex, toIndex, _this_.length);
    var tmp0_withType_0 = 'LongArray';
    var tmp1_withType_0 = _this_.slice(fromIndex, toIndex);
    tmp1_withType_0.$type$ = tmp0_withType_0;
    return tmp1_withType_0;
  }
  function contentEquals_1(_this_, other) {
    return contentEqualsInternal(_this_, other);
  }
  function copyOf_2(_this_) {
    var tmp0_withType_0 = 'LongArray';
    var tmp1_withType_0 = _this_.slice();
    tmp1_withType_0.$type$ = tmp0_withType_0;
    return tmp1_withType_0;
  }
  function copyOfRange_0(_this_, fromIndex, toIndex) {
    Companion_getInstance().checkRangeIndexes(fromIndex, toIndex, _this_.length);
    return _this_.slice(fromIndex, toIndex);
  }
  function contentEquals_2(_this_, other) {
    return contentEqualsInternal(_this_, other);
  }
  function copyOfRange_1(_this_, fromIndex, toIndex) {
    Companion_getInstance().checkRangeIndexes(fromIndex, toIndex, _this_.length);
    return _this_.slice(fromIndex, toIndex);
  }
  function asList(_this_) {
    return new ArrayList(_this_);
  }
  function copyOfRange_2(_this_, fromIndex, toIndex) {
    Companion_getInstance().checkRangeIndexes(fromIndex, toIndex, _this_.length);
    return _this_.slice(fromIndex, toIndex);
  }
  function toTypedArray(_this_) {
    return [].slice.call(_this_);
  }
  function reverse(_this_) {
    var midPoint = (_this_._get_size__52() / 2 | 0) - 1 | 0;
    if (midPoint < 0)
      return Unit_getInstance();
    var reverseIndex = _get_lastIndex__2(_this_);
    var inductionVariable = 0;
    if (inductionVariable <= midPoint)
      do {
        var index = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        var tmp = _this_.get_72(index);
        _this_.set_3(index, _this_.get_72(reverseIndex));
        Unit_getInstance();
        _this_.set_3(reverseIndex, tmp);
        Unit_getInstance();
        var tmp1 = reverseIndex;
        reverseIndex = tmp1 - 1 | 0;
        Unit_getInstance();
      }
       while (!(index === midPoint));
  }
  function asList_0(_this_) {
    return new _no_name_provided__56(_this_);
  }
  function asList_1(_this_) {
    return new _no_name_provided__57(_this_);
  }
  function _no_name_provided__56($this_asList) {
    this._$this_asList = $this_asList;
    AbstractList.call(this);
  }
  _no_name_provided__56.prototype._get_size__52 = function () {
    return _ULongArray___get_size__impl_(this._$this_asList);
  };
  _no_name_provided__56.prototype.isEmpty_44 = function () {
    return ULongArray__isEmpty_impl(this._$this_asList);
  };
  _no_name_provided__56.prototype.contains_40 = function (element) {
    return ULongArray__contains_impl(this._$this_asList, element);
  };
  _no_name_provided__56.prototype.contains_51 = function (element) {
    if (!(element instanceof ULong))
      return false;
    else {
    }
    return this.contains_40(element instanceof ULong ? element._data_1 : THROW_CCE());
  };
  _no_name_provided__56.prototype.get_27 = function (index) {
    Companion_getInstance().checkElementIndex(index, this._get_size__52());
    return ULongArray__get_impl(this._$this_asList, index);
  };
  _no_name_provided__56.prototype.get_72 = function (index) {
    return new ULong(this.get_27(index));
  };
  _no_name_provided__56.$metadata$ = {
    kind: 'class',
    interfaces: [RandomAccess]
  };
  function _no_name_provided__57($this_asList) {
    this._$this_asList_0 = $this_asList;
    AbstractList.call(this);
  }
  _no_name_provided__57.prototype._get_size__52 = function () {
    return _UByteArray___get_size__impl_(this._$this_asList_0);
  };
  _no_name_provided__57.prototype.isEmpty_44 = function () {
    return UByteArray__isEmpty_impl(this._$this_asList_0);
  };
  _no_name_provided__57.prototype.contains_42 = function (element) {
    return UByteArray__contains_impl(this._$this_asList_0, element);
  };
  _no_name_provided__57.prototype.contains_51 = function (element) {
    if (!(element instanceof UByte))
      return false;
    else {
    }
    return this.contains_42(element instanceof UByte ? element._data : THROW_CCE());
  };
  _no_name_provided__57.prototype.get_29 = function (index) {
    Companion_getInstance().checkElementIndex(index, this._get_size__52());
    return UByteArray__get_impl(this._$this_asList_0, index);
  };
  _no_name_provided__57.prototype.get_72 = function (index) {
    return new UByte(this.get_29(index));
  };
  _no_name_provided__57.$metadata$ = {
    kind: 'class',
    interfaces: [RandomAccess]
  };
  function isWhitespaceImpl(_this_) {
    var ch_0 = _this_.toInt_5();
    return (((9 <= ch_0 ? ch_0 <= 13 : false) ? true : 28 <= ch_0 ? ch_0 <= 32 : false) ? true : ch_0 === 160) ? true : ch_0 > 4096 ? (((((ch_0 === 5760 ? true : 8192 <= ch_0 ? ch_0 <= 8202 : false) ? true : ch_0 === 8232) ? true : ch_0 === 8233) ? true : ch_0 === 8239) ? true : ch_0 === 8287) ? true : ch_0 === 12288 : false;
  }
  function releaseIntercepted($this) {
    var intercepted_0 = $this._intercepted_;
    if (!(intercepted_0 == null) ? !(intercepted_0 === $this) : false) {
      ensureNotNull($this._get_context__31().get_62(Key_getInstance())).releaseInterceptedContinuation_10(intercepted_0);
    }$this._intercepted_ = CompletedContinuation_getInstance();
  }
  function CoroutineImpl_0(resultContinuation) {
    this._resultContinuation = resultContinuation;
    this._state_2 = 0;
    this._exceptionState = 0;
    this._result_0 = null;
    this._exception_0 = null;
    this._finallyPath = null;
    var tmp = this;
    var tmp0_safe_receiver = this._resultContinuation;
    tmp.__context = tmp0_safe_receiver == null ? null : tmp0_safe_receiver._get_context__31();
    this._intercepted_ = null;
  }
  CoroutineImpl_0.prototype._get_exception_ = function () {
    return this._exception_0;
  };
  CoroutineImpl_0.prototype._get_context__31 = function () {
    return ensureNotNull(this.__context);
  };
  CoroutineImpl_0.prototype.intercepted_16 = function () {
    var tmp2_elvis_lhs = this._intercepted_;
    var tmp;
    if (tmp2_elvis_lhs == null) {
      var tmp0_safe_receiver = this._get_context__31().get_62(Key_getInstance());
      var tmp1_elvis_lhs = tmp0_safe_receiver == null ? null : tmp0_safe_receiver.interceptContinuation_10(this);
      var tmp0_also_0 = tmp1_elvis_lhs == null ? this : tmp1_elvis_lhs;
      this._intercepted_ = tmp0_also_0;
      tmp = tmp0_also_0;
    } else {
      tmp = tmp2_elvis_lhs;
    }
    return tmp;
  };
  CoroutineImpl_0.prototype.resumeWith_34 = function (result) {
    var current_0 = this;
    var tmp;
    if (_Result___get_isFailure__impl_(result)) {
      tmp = null;
    } else {
      var tmp_0 = _Result___get_value__impl_(result);
      tmp = (tmp_0 == null ? true : isObject(tmp_0)) ? tmp_0 : THROW_CCE();
    }
    var currentResult = tmp;
    var currentException = Result__exceptionOrNull_impl(result);
    while (true) {
      var tmp0_with_0 = current_0;
      if (currentException == null) {
        tmp0_with_0._result_0 = currentResult;
      } else {
        tmp0_with_0._state_2 = tmp0_with_0._exceptionState;
        tmp0_with_0._exception_0 = currentException;
      }
      try {
        var outcome_2 = tmp0_with_0.doResume_17();
        if (outcome_2 === _get_COROUTINE_SUSPENDED_())
          return Unit_getInstance();
        currentResult = outcome_2;
        currentException = null;
      } catch ($p) {
        currentResult = null;
        currentException = $p;
      }
      releaseIntercepted(tmp0_with_0);
      var completion_4 = ensureNotNull(tmp0_with_0._resultContinuation);
      if (completion_4 instanceof CoroutineImpl_0) {
        current_0 = completion_4;
      } else {
        {
          if (!(currentException == null)) {
            var tmp0_resumeWithException_0_5 = ensureNotNull(currentException);
            var tmp0_failure_0_1_6 = Companion_getInstance_2();
            completion_4.resumeWith_21(_Result___init__impl_(createFailure(tmp0_resumeWithException_0_5)));
          } else {
            var tmp1_resume_0_7 = currentResult;
            var tmp0_success_0_1_8 = Companion_getInstance_2();
            completion_4.resumeWith_21(_Result___init__impl_(tmp1_resume_0_7));
          }
          return Unit_getInstance();
        }
      }
    }
  };
  CoroutineImpl_0.prototype.resumeWith_21 = function (result) {
    return this.resumeWith_34(result);
  };
  CoroutineImpl_0.$metadata$ = {
    simpleName: 'CoroutineImpl',
    kind: 'class',
    interfaces: [Continuation]
  };
  function CompletedContinuation() {
    CompletedContinuation_instance = this;
  }
  CompletedContinuation.prototype._get_context__31 = function () {
    var tmp0_error_0 = 'This continuation is already complete';
    throw IllegalStateException_init_$Create$_0(toString_2(tmp0_error_0));
  };
  CompletedContinuation.prototype.resumeWith_34 = function (result) {
    var tmp0_error_0 = 'This continuation is already complete';
    throw IllegalStateException_init_$Create$_0(toString_2(tmp0_error_0));
  };
  CompletedContinuation.prototype.resumeWith_21 = function (result) {
    return this.resumeWith_34(result);
  };
  CompletedContinuation.prototype.toString = function () {
    return 'This continuation is already complete';
  };
  CompletedContinuation.$metadata$ = {
    simpleName: 'CompletedContinuation',
    kind: 'object',
    interfaces: [Continuation]
  };
  var CompletedContinuation_instance;
  function CompletedContinuation_getInstance() {
    if (CompletedContinuation_instance == null)
      new CompletedContinuation();
    return CompletedContinuation_instance;
  }
  function intercepted(_this_) {
    var tmp0_safe_receiver = _this_ instanceof CoroutineImpl_0 ? _this_ : null;
    var tmp1_elvis_lhs = tmp0_safe_receiver == null ? null : tmp0_safe_receiver.intercepted_16();
    return tmp1_elvis_lhs == null ? _this_ : tmp1_elvis_lhs;
  }
  function createCoroutineUnintercepted(_this_, receiver, completion) {
    return new _no_name_provided__1_3(_this_, receiver, completion);
  }
  function _no_name_provided__1_3($this_createCoroutineUnintercepted, $receiver, $completion) {
    this._$this_createCoroutineUnintercepted = $this_createCoroutineUnintercepted;
    this._$receiver = $receiver;
    this._$completion = $completion;
    CoroutineImpl_0.call(this, isInterface($completion, Continuation) ? $completion : THROW_CCE());
  }
  _no_name_provided__1_3.prototype.doResume_2 = function () {
    if (this._get_exception_() != null)
      throw this._get_exception_();
    return this._$this_createCoroutineUnintercepted(this._$receiver, this._$completion);
  };
  _no_name_provided__1_3.prototype.doResume_17 = function () {
    return this.doResume_2();
  };
  _no_name_provided__1_3.$metadata$ = {
    simpleName: '<no name provided>_1',
    kind: 'class',
    interfaces: []
  };
  function Exception_init_$Init$($this) {
    extendThrowable($this, void 1, void 1);
    Exception.call($this);
    return $this;
  }
  function Exception_init_$Create$() {
    var tmp = Exception_init_$Init$(Object.create(Exception.prototype));
    captureStack(tmp, Exception_init_$Create$);
    return tmp;
  }
  function Exception_init_$Init$_0(message, $this) {
    extendThrowable($this, message, void 1);
    Exception.call($this);
    return $this;
  }
  function Exception_init_$Create$_0(message) {
    var tmp = Exception_init_$Init$_0(message, Object.create(Exception.prototype));
    captureStack(tmp, Exception_init_$Create$_0);
    return tmp;
  }
  function Exception_init_$Init$_1(message, cause, $this) {
    extendThrowable($this, message, cause);
    Exception.call($this);
    return $this;
  }
  function Exception_init_$Create$_1(message, cause) {
    var tmp = Exception_init_$Init$_1(message, cause, Object.create(Exception.prototype));
    captureStack(tmp, Exception_init_$Create$_1);
    return tmp;
  }
  function Exception() {
    captureStack(this, Exception);
  }
  Exception.$metadata$ = {
    simpleName: 'Exception',
    kind: 'class',
    interfaces: []
  };
  function Error_init_$Init$(message, $this) {
    extendThrowable($this, message, void 1);
    Error_0.call($this);
    return $this;
  }
  function Error_init_$Init$_0(message, cause, $this) {
    extendThrowable($this, message, cause);
    Error_0.call($this);
    return $this;
  }
  function Error_0() {
    captureStack(this, Error_0);
  }
  Error_0.$metadata$ = {
    simpleName: 'Error',
    kind: 'class',
    interfaces: []
  };
  function IllegalArgumentException_init_$Init$($this) {
    RuntimeException_init_$Init$($this);
    IllegalArgumentException.call($this);
    return $this;
  }
  function IllegalArgumentException_init_$Create$() {
    var tmp = IllegalArgumentException_init_$Init$(Object.create(IllegalArgumentException.prototype));
    captureStack(tmp, IllegalArgumentException_init_$Create$);
    return tmp;
  }
  function IllegalArgumentException_init_$Init$_0(message, $this) {
    RuntimeException_init_$Init$_0(message, $this);
    IllegalArgumentException.call($this);
    return $this;
  }
  function IllegalArgumentException_init_$Create$_0(message) {
    var tmp = IllegalArgumentException_init_$Init$_0(message, Object.create(IllegalArgumentException.prototype));
    captureStack(tmp, IllegalArgumentException_init_$Create$_0);
    return tmp;
  }
  function IllegalArgumentException() {
    captureStack(this, IllegalArgumentException);
  }
  IllegalArgumentException.$metadata$ = {
    simpleName: 'IllegalArgumentException',
    kind: 'class',
    interfaces: []
  };
  function RuntimeException_init_$Init$($this) {
    Exception_init_$Init$($this);
    RuntimeException.call($this);
    return $this;
  }
  function RuntimeException_init_$Init$_0(message, $this) {
    Exception_init_$Init$_0(message, $this);
    RuntimeException.call($this);
    return $this;
  }
  function RuntimeException_init_$Create$(message) {
    var tmp = RuntimeException_init_$Init$_0(message, Object.create(RuntimeException.prototype));
    captureStack(tmp, RuntimeException_init_$Create$);
    return tmp;
  }
  function RuntimeException_init_$Init$_1(message, cause, $this) {
    Exception_init_$Init$_1(message, cause, $this);
    RuntimeException.call($this);
    return $this;
  }
  function RuntimeException_init_$Create$_0(message, cause) {
    var tmp = RuntimeException_init_$Init$_1(message, cause, Object.create(RuntimeException.prototype));
    captureStack(tmp, RuntimeException_init_$Create$_0);
    return tmp;
  }
  function RuntimeException() {
    captureStack(this, RuntimeException);
  }
  RuntimeException.$metadata$ = {
    simpleName: 'RuntimeException',
    kind: 'class',
    interfaces: []
  };
  function NoSuchElementException_init_$Init$($this) {
    RuntimeException_init_$Init$($this);
    NoSuchElementException.call($this);
    return $this;
  }
  function NoSuchElementException_init_$Create$() {
    var tmp = NoSuchElementException_init_$Init$(Object.create(NoSuchElementException.prototype));
    captureStack(tmp, NoSuchElementException_init_$Create$);
    return tmp;
  }
  function NoSuchElementException_init_$Init$_0(message, $this) {
    RuntimeException_init_$Init$_0(message, $this);
    NoSuchElementException.call($this);
    return $this;
  }
  function NoSuchElementException_init_$Create$_0(message) {
    var tmp = NoSuchElementException_init_$Init$_0(message, Object.create(NoSuchElementException.prototype));
    captureStack(tmp, NoSuchElementException_init_$Create$_0);
    return tmp;
  }
  function NoSuchElementException() {
    captureStack(this, NoSuchElementException);
  }
  NoSuchElementException.$metadata$ = {
    simpleName: 'NoSuchElementException',
    kind: 'class',
    interfaces: []
  };
  function IllegalStateException_init_$Init$($this) {
    RuntimeException_init_$Init$($this);
    IllegalStateException.call($this);
    return $this;
  }
  function IllegalStateException_init_$Create$() {
    var tmp = IllegalStateException_init_$Init$(Object.create(IllegalStateException.prototype));
    captureStack(tmp, IllegalStateException_init_$Create$);
    return tmp;
  }
  function IllegalStateException_init_$Init$_0(message, $this) {
    RuntimeException_init_$Init$_0(message, $this);
    IllegalStateException.call($this);
    return $this;
  }
  function IllegalStateException_init_$Create$_0(message) {
    var tmp = IllegalStateException_init_$Init$_0(message, Object.create(IllegalStateException.prototype));
    captureStack(tmp, IllegalStateException_init_$Create$_0);
    return tmp;
  }
  function IllegalStateException_init_$Init$_1(message, cause, $this) {
    RuntimeException_init_$Init$_1(message, cause, $this);
    IllegalStateException.call($this);
    return $this;
  }
  function IllegalStateException_init_$Create$_1(message, cause) {
    var tmp = IllegalStateException_init_$Init$_1(message, cause, Object.create(IllegalStateException.prototype));
    captureStack(tmp, IllegalStateException_init_$Create$_1);
    return tmp;
  }
  function IllegalStateException() {
    captureStack(this, IllegalStateException);
  }
  IllegalStateException.$metadata$ = {
    simpleName: 'IllegalStateException',
    kind: 'class',
    interfaces: []
  };
  function IndexOutOfBoundsException_init_$Init$(message, $this) {
    RuntimeException_init_$Init$_0(message, $this);
    IndexOutOfBoundsException.call($this);
    return $this;
  }
  function IndexOutOfBoundsException_init_$Create$(message) {
    var tmp = IndexOutOfBoundsException_init_$Init$(message, Object.create(IndexOutOfBoundsException.prototype));
    captureStack(tmp, IndexOutOfBoundsException_init_$Create$);
    return tmp;
  }
  function IndexOutOfBoundsException() {
    captureStack(this, IndexOutOfBoundsException);
  }
  IndexOutOfBoundsException.$metadata$ = {
    simpleName: 'IndexOutOfBoundsException',
    kind: 'class',
    interfaces: []
  };
  function NumberFormatException_init_$Init$(message, $this) {
    IllegalArgumentException_init_$Init$_0(message, $this);
    NumberFormatException.call($this);
    return $this;
  }
  function NumberFormatException_init_$Create$(message) {
    var tmp = NumberFormatException_init_$Init$(message, Object.create(NumberFormatException.prototype));
    captureStack(tmp, NumberFormatException_init_$Create$);
    return tmp;
  }
  function NumberFormatException() {
    captureStack(this, NumberFormatException);
  }
  NumberFormatException.$metadata$ = {
    simpleName: 'NumberFormatException',
    kind: 'class',
    interfaces: []
  };
  function ArithmeticException_init_$Init$(message, $this) {
    RuntimeException_init_$Init$_0(message, $this);
    ArithmeticException.call($this);
    return $this;
  }
  function ArithmeticException_init_$Create$(message) {
    var tmp = ArithmeticException_init_$Init$(message, Object.create(ArithmeticException.prototype));
    captureStack(tmp, ArithmeticException_init_$Create$);
    return tmp;
  }
  function ArithmeticException() {
    captureStack(this, ArithmeticException);
  }
  ArithmeticException.$metadata$ = {
    simpleName: 'ArithmeticException',
    kind: 'class',
    interfaces: []
  };
  function UnsupportedOperationException_init_$Init$($this) {
    RuntimeException_init_$Init$($this);
    UnsupportedOperationException.call($this);
    return $this;
  }
  function UnsupportedOperationException_init_$Create$() {
    var tmp = UnsupportedOperationException_init_$Init$(Object.create(UnsupportedOperationException.prototype));
    captureStack(tmp, UnsupportedOperationException_init_$Create$);
    return tmp;
  }
  function UnsupportedOperationException_init_$Init$_0(message, $this) {
    RuntimeException_init_$Init$_0(message, $this);
    UnsupportedOperationException.call($this);
    return $this;
  }
  function UnsupportedOperationException_init_$Create$_0(message) {
    var tmp = UnsupportedOperationException_init_$Init$_0(message, Object.create(UnsupportedOperationException.prototype));
    captureStack(tmp, UnsupportedOperationException_init_$Create$_0);
    return tmp;
  }
  function UnsupportedOperationException() {
    captureStack(this, UnsupportedOperationException);
  }
  UnsupportedOperationException.$metadata$ = {
    simpleName: 'UnsupportedOperationException',
    kind: 'class',
    interfaces: []
  };
  function ConcurrentModificationException_init_$Init$($this) {
    RuntimeException_init_$Init$($this);
    ConcurrentModificationException.call($this);
    return $this;
  }
  function ConcurrentModificationException_init_$Create$() {
    var tmp = ConcurrentModificationException_init_$Init$(Object.create(ConcurrentModificationException.prototype));
    captureStack(tmp, ConcurrentModificationException_init_$Create$);
    return tmp;
  }
  function ConcurrentModificationException_init_$Init$_0(message, $this) {
    RuntimeException_init_$Init$_0(message, $this);
    ConcurrentModificationException.call($this);
    return $this;
  }
  function ConcurrentModificationException_init_$Create$_0(message) {
    var tmp = ConcurrentModificationException_init_$Init$_0(message, Object.create(ConcurrentModificationException.prototype));
    captureStack(tmp, ConcurrentModificationException_init_$Create$_0);
    return tmp;
  }
  function ConcurrentModificationException() {
    captureStack(this, ConcurrentModificationException);
  }
  ConcurrentModificationException.$metadata$ = {
    simpleName: 'ConcurrentModificationException',
    kind: 'class',
    interfaces: []
  };
  function AssertionError_init_$Init$(message, $this) {
    Error_init_$Init$(message, $this);
    AssertionError.call($this);
    return $this;
  }
  function AssertionError_init_$Create$(message) {
    var tmp = AssertionError_init_$Init$(message, Object.create(AssertionError.prototype));
    captureStack(tmp, AssertionError_init_$Create$);
    return tmp;
  }
  function AssertionError() {
    captureStack(this, AssertionError);
  }
  AssertionError.$metadata$ = {
    simpleName: 'AssertionError',
    kind: 'class',
    interfaces: []
  };
  function NullPointerException_init_$Init$($this) {
    RuntimeException_init_$Init$($this);
    NullPointerException.call($this);
    return $this;
  }
  function NullPointerException_init_$Create$() {
    var tmp = NullPointerException_init_$Init$(Object.create(NullPointerException.prototype));
    captureStack(tmp, NullPointerException_init_$Create$);
    return tmp;
  }
  function NullPointerException() {
    captureStack(this, NullPointerException);
  }
  NullPointerException.$metadata$ = {
    simpleName: 'NullPointerException',
    kind: 'class',
    interfaces: []
  };
  function NoWhenBranchMatchedException_init_$Init$($this) {
    RuntimeException_init_$Init$($this);
    NoWhenBranchMatchedException.call($this);
    return $this;
  }
  function NoWhenBranchMatchedException_init_$Create$() {
    var tmp = NoWhenBranchMatchedException_init_$Init$(Object.create(NoWhenBranchMatchedException.prototype));
    captureStack(tmp, NoWhenBranchMatchedException_init_$Create$);
    return tmp;
  }
  function NoWhenBranchMatchedException() {
    captureStack(this, NoWhenBranchMatchedException);
  }
  NoWhenBranchMatchedException.$metadata$ = {
    simpleName: 'NoWhenBranchMatchedException',
    kind: 'class',
    interfaces: []
  };
  function ClassCastException_init_$Init$($this) {
    RuntimeException_init_$Init$($this);
    ClassCastException.call($this);
    return $this;
  }
  function ClassCastException_init_$Create$() {
    var tmp = ClassCastException_init_$Init$(Object.create(ClassCastException.prototype));
    captureStack(tmp, ClassCastException_init_$Create$);
    return tmp;
  }
  function ClassCastException() {
    captureStack(this, ClassCastException);
  }
  ClassCastException.$metadata$ = {
    simpleName: 'ClassCastException',
    kind: 'class',
    interfaces: []
  };
  function UninitializedPropertyAccessException_init_$Init$(message, $this) {
    RuntimeException_init_$Init$_0(message, $this);
    UninitializedPropertyAccessException.call($this);
    return $this;
  }
  function UninitializedPropertyAccessException_init_$Create$(message) {
    var tmp = UninitializedPropertyAccessException_init_$Init$(message, Object.create(UninitializedPropertyAccessException.prototype));
    captureStack(tmp, UninitializedPropertyAccessException_init_$Create$);
    return tmp;
  }
  function UninitializedPropertyAccessException() {
    captureStack(this, UninitializedPropertyAccessException);
  }
  UninitializedPropertyAccessException.$metadata$ = {
    simpleName: 'UninitializedPropertyAccessException',
    kind: 'class',
    interfaces: []
  };
  function jsIn(lhs_hack, rhs_hack) {
    var tmp0_unsafeCast_0 = jsIn$outlinedJsCode$(lhs_hack, rhs_hack);
    return tmp0_unsafeCast_0;
  }
  function jsBitwiseOr(lhs_hack, rhs_hack) {
    var tmp0_unsafeCast_0 = jsBitwiseOr$outlinedJsCode$_0(lhs_hack, rhs_hack);
    return tmp0_unsafeCast_0;
  }
  function jsTypeOf(value_hack) {
    var tmp0_unsafeCast_0 = jsTypeOf$outlinedJsCode$_1(value_hack);
    return tmp0_unsafeCast_0;
  }
  function jsDeleteProperty(obj_hack, property_hack) {
    jsDeleteProperty$outlinedJsCode$_2(obj_hack, property_hack);
  }
  function jsInstanceOf(obj_hack, jsClass_hack) {
    var tmp0_unsafeCast_0 = jsInstanceOf$outlinedJsCode$_3(obj_hack, jsClass_hack);
    return tmp0_unsafeCast_0;
  }
  function jsBitwiseAnd(lhs_hack, rhs_hack) {
    var tmp0_unsafeCast_0 = jsBitwiseAnd$outlinedJsCode$_4(lhs_hack, rhs_hack);
    return tmp0_unsafeCast_0;
  }
  function jsIn$outlinedJsCode$(lhs_hack, rhs_hack) {
    return lhs_hack in rhs_hack;
  }
  function jsBitwiseOr$outlinedJsCode$_0(lhs_hack, rhs_hack) {
    return lhs_hack | rhs_hack;
  }
  function jsTypeOf$outlinedJsCode$_1(value_hack) {
    return typeof value_hack;
  }
  function jsDeleteProperty$outlinedJsCode$_2(obj_hack, property_hack) {
    return delete obj_hack[property_hack];
  }
  function jsInstanceOf$outlinedJsCode$_3(obj_hack, jsClass_hack) {
    return obj_hack instanceof jsClass_hack;
  }
  function jsBitwiseAnd$outlinedJsCode$_4(lhs_hack, rhs_hack) {
    return lhs_hack & rhs_hack;
  }
  function toString_3(_this_, radix) {
    return toStringImpl(_this_, checkRadix(radix));
  }
  function Creator() {
  }
  Creator.prototype.tryFromFloat$default_2 = function (float, exactRequired, $mask0, $handler) {
    if (!(($mask0 & 2) === 0))
      exactRequired = false;
    return $handler == null ? this.tryFromFloat_2(float, exactRequired) : $handler(float, exactRequired);
  };
  Creator.prototype.tryFromDouble$default_2 = function (double, exactRequired, $mask0, $handler) {
    if (!(($mask0 & 2) === 0))
      exactRequired = false;
    return $handler == null ? this.tryFromDouble_2(double, exactRequired) : $handler(double, exactRequired);
  };
  Creator.$metadata$ = {
    simpleName: 'Creator',
    kind: 'interface',
    interfaces: []
  };
  function Util() {
  }
  Util.$metadata$ = {
    simpleName: 'Util',
    kind: 'interface',
    interfaces: []
  };
  function BigNumber() {
  }
  BigNumber.prototype._get_isNegative__0 = function () {
    return this.signum_2() < 0;
  };
  BigNumber.$metadata$ = {
    simpleName: 'BigNumber',
    kind: 'interface',
    interfaces: []
  };
  function CommonBigNumberOperations() {
  }
  CommonBigNumberOperations.prototype.plus_4 = function (other) {
    return this.getInstance_2().add_34(other);
  };
  CommonBigNumberOperations.prototype.minus_2 = function (other) {
    return this.getInstance_2().subtract_9(other);
  };
  CommonBigNumberOperations.prototype.times_0 = function (other) {
    return this.getInstance_2().multiply_10(other);
  };
  CommonBigNumberOperations.prototype.div_1 = function (other) {
    return this.getInstance_2().divide_9(other);
  };
  CommonBigNumberOperations.prototype.rem_1 = function (other) {
    return this.getInstance_2().remainder_5(other);
  };
  CommonBigNumberOperations.prototype.plus_70 = function (int) {
    return this.getInstance_2().add_34(this.getCreator_2().fromInt_5(int));
  };
  CommonBigNumberOperations.prototype.times_39 = function (int) {
    return this.getInstance_2().multiply_10(this.getCreator_2().fromInt_5(int));
  };
  CommonBigNumberOperations.prototype.minus_39 = function (int) {
    return this.getInstance_2().subtract_9(this.getCreator_2().fromInt_5(int));
  };
  CommonBigNumberOperations.prototype.div_33 = function (int) {
    return this.getInstance_2().divide_9(this.getCreator_2().fromInt_5(int));
  };
  CommonBigNumberOperations.prototype.rem_32 = function (int) {
    return this.getInstance_2().remainder_5(this.getCreator_2().fromInt_5(int));
  };
  CommonBigNumberOperations.$metadata$ = {
    simpleName: 'CommonBigNumberOperations',
    kind: 'interface',
    interfaces: []
  };
  function NarrowingOperations() {
  }
  NarrowingOperations.$metadata$ = {
    simpleName: 'NarrowingOperations',
    kind: 'interface',
    interfaces: []
  };
  function ByteArraySerializable() {
  }
  ByteArraySerializable.$metadata$ = {
    simpleName: 'ByteArraySerializable',
    kind: 'interface',
    interfaces: []
  };
  function BitwiseCapable() {
  }
  BitwiseCapable.$metadata$ = {
    simpleName: 'BitwiseCapable',
    kind: 'interface',
    interfaces: []
  };
  function ByteArrayDeserializable() {
  }
  ByteArrayDeserializable.$metadata$ = {
    simpleName: 'ByteArrayDeserializable',
    kind: 'interface',
    interfaces: []
  };
  var SignificantDecider_FIVE_instance;
  var SignificantDecider_LESS_THAN_FIVE_instance;
  var SignificantDecider_MORE_THAN_FIVE_instance;
  var SignificantDecider_entriesInitialized;
  function SignificantDecider_initEntries() {
    if (SignificantDecider_entriesInitialized)
      return Unit_getInstance();
    SignificantDecider_entriesInitialized = true;
    SignificantDecider_FIVE_instance = new SignificantDecider('FIVE', 0);
    SignificantDecider_LESS_THAN_FIVE_instance = new SignificantDecider('LESS_THAN_FIVE', 1);
    SignificantDecider_MORE_THAN_FIVE_instance = new SignificantDecider('MORE_THAN_FIVE', 2);
  }
  function roundOrDont($this, significand, exponent, decimalMode) {
    var tmp;
    if (decimalMode._isPrecisionUnlimited) {
      tmp = BigDecimal_init_$Create$(significand, exponent, null, 4, null);
    } else {
      tmp = roundSignificand($this, significand, exponent, decimalMode);
    }
    return tmp;
  }
  function SignificantDecider(name, ordinal) {
    Enum.call(this, name, ordinal);
  }
  SignificantDecider.$metadata$ = {
    simpleName: 'SignificantDecider',
    kind: 'class',
    interfaces: []
  };
  function determineDecider($this, discarded) {
    var tmp = Companion_getInstance_13()._TEN_0;
    var tmp0_minus_0 = discarded.numberOfDecimalDigits();
    var tmp1_minus_0 = 1;
    var scale = tmp.pow_0(tmp0_minus_0.minus_40(toLong_0(tmp1_minus_0)));
    var divrem = discarded.divrem(scale);
    var significant = divrem._quotient.abs_0().intValue(true);
    var rest_0 = divrem._remainder.abs_0();
    var tmp_0;
    if (significant === 5) {
      var tmp_1;
      if (rest_0.equals(Companion_getInstance_13()._ZERO_0)) {
        tmp_1 = SignificantDecider_FIVE_getInstance();
      } else {
        tmp_1 = SignificantDecider_MORE_THAN_FIVE_getInstance();
      }
      tmp_0 = tmp_1;
    } else if (significant > 5) {
      tmp_0 = SignificantDecider_MORE_THAN_FIVE_getInstance();
    } else if (significant < 5) {
      tmp_0 = SignificantDecider_LESS_THAN_FIVE_getInstance();
    } else {
      throw RuntimeException_init_$Create$("Couldn't determine decider");
    }
    return tmp_0;
  }
  function roundDiscarded($this, significand, discarded, decimalMode) {
    var toDiscard = significand.numberOfDecimalDigits().minus_40(decimalMode._decimalPrecision);
    var tmp;
    if (toDiscard.compareTo_75(new Long(0, 0)) > 0) {
      var additionallyDiscarded = significand.divrem(Companion_getInstance_13()._TEN_0.pow_0(toDiscard));
      tmp = new Pair(additionallyDiscarded._quotient, additionallyDiscarded._remainder);
    } else {
      tmp = new Pair(significand, discarded);
    }
    var tmp0_container = tmp;
    var result = tmp0_container.component1_4();
    var remainder = tmp0_container.component2_4();
    var tmp_0;
    if (significand.equals(Companion_getInstance_13()._ZERO_0)) {
      tmp_0 = discarded._sign;
    } else {
      tmp_0 = significand._sign;
    }
    var sign = tmp_0;
    if (remainder.isZero_0()) {
      return result;
    }var decider = determineDecider($this, remainder);
    var tmp1_subject = decimalMode._roundingMode_0;
    if (tmp1_subject.equals(RoundingMode_AWAY_FROM_ZERO_getInstance())) {
      if (sign.equals(Sign_POSITIVE_getInstance())) {
        var tmp2 = result;
        result = tmp2.inc_5();
        Unit_getInstance();
      } else {
        var tmp3 = result;
        result = tmp3.dec_5();
        Unit_getInstance();
      }
    } else if (tmp1_subject.equals(RoundingMode_TOWARDS_ZERO_getInstance())) {
      Unit_getInstance();
    } else if (tmp1_subject.equals(RoundingMode_CEILING_getInstance())) {
      if (sign.equals(Sign_POSITIVE_getInstance())) {
        var tmp4 = result;
        result = tmp4.inc_5();
        Unit_getInstance();
      } else {
        Unit_getInstance();
      }
    } else if (tmp1_subject.equals(RoundingMode_FLOOR_getInstance())) {
      if (sign.equals(Sign_POSITIVE_getInstance())) {
        Unit_getInstance();
      } else {
        var tmp5 = result;
        result = tmp5.dec_5();
        Unit_getInstance();
      }
    } else if (tmp1_subject.equals(RoundingMode_ROUND_HALF_AWAY_FROM_ZERO_getInstance())) {
      var tmp6_subject = sign;
      if (tmp6_subject.equals(Sign_POSITIVE_getInstance())) {
        if (!decider.equals(SignificantDecider_LESS_THAN_FIVE_getInstance())) {
          var tmp7 = result;
          result = tmp7.inc_5();
          Unit_getInstance();
        }} else if (tmp6_subject.equals(Sign_NEGATIVE_getInstance())) {
        if (!decider.equals(SignificantDecider_LESS_THAN_FIVE_getInstance())) {
          var tmp8 = result;
          result = tmp8.dec_5();
          Unit_getInstance();
        }} else if (tmp6_subject.equals(Sign_ZERO_getInstance())) {
      }} else if (tmp1_subject.equals(RoundingMode_ROUND_HALF_TOWARDS_ZERO_getInstance())) {
      var tmp9_subject = sign;
      if (tmp9_subject.equals(Sign_POSITIVE_getInstance())) {
        if (decider.equals(SignificantDecider_MORE_THAN_FIVE_getInstance())) {
          var tmp10 = result;
          result = tmp10.inc_5();
          Unit_getInstance();
        }} else if (tmp9_subject.equals(Sign_NEGATIVE_getInstance())) {
        if (decider.equals(SignificantDecider_MORE_THAN_FIVE_getInstance())) {
          var tmp11 = result;
          result = tmp11.dec_5();
          Unit_getInstance();
        }} else if (tmp9_subject.equals(Sign_ZERO_getInstance())) {
      }} else if (tmp1_subject.equals(RoundingMode_ROUND_HALF_CEILING_getInstance())) {
      var tmp12_subject = sign;
      if (tmp12_subject.equals(Sign_POSITIVE_getInstance())) {
        if (!decider.equals(SignificantDecider_LESS_THAN_FIVE_getInstance())) {
          var tmp13 = result;
          result = tmp13.inc_5();
          Unit_getInstance();
        }} else if (tmp12_subject.equals(Sign_NEGATIVE_getInstance())) {
        if (decider.equals(SignificantDecider_MORE_THAN_FIVE_getInstance())) {
          var tmp14 = result;
          result = tmp14.dec_5();
          Unit_getInstance();
        }} else if (tmp12_subject.equals(Sign_ZERO_getInstance())) {
      }} else if (tmp1_subject.equals(RoundingMode_ROUND_HALF_FLOOR_getInstance())) {
      var tmp15_subject = sign;
      if (tmp15_subject.equals(Sign_POSITIVE_getInstance())) {
        if (decider.equals(SignificantDecider_MORE_THAN_FIVE_getInstance())) {
          var tmp16 = result;
          result = tmp16.inc_5();
          Unit_getInstance();
        }} else if (tmp15_subject.equals(Sign_NEGATIVE_getInstance())) {
        if (!decider.equals(SignificantDecider_LESS_THAN_FIVE_getInstance())) {
          var tmp17 = result;
          result = tmp17.dec_5();
          Unit_getInstance();
        }} else if (tmp15_subject.equals(Sign_ZERO_getInstance())) {
      }} else if (tmp1_subject.equals(RoundingMode_ROUND_HALF_TO_EVEN_getInstance())) {
      if (decider.equals(SignificantDecider_FIVE_getInstance())) {
        if (significand.rem_32(2).equals(Companion_getInstance_13()._ONE_0)) {
          var tmp18_subject = sign;
          if (tmp18_subject.equals(Sign_POSITIVE_getInstance())) {
            if (!decider.equals(SignificantDecider_LESS_THAN_FIVE_getInstance())) {
              var tmp19 = result;
              result = tmp19.inc_5();
              Unit_getInstance();
            }} else if (tmp18_subject.equals(Sign_NEGATIVE_getInstance())) {
            if (!decider.equals(SignificantDecider_LESS_THAN_FIVE_getInstance())) {
              var tmp20 = result;
              result = tmp20.dec_5();
              Unit_getInstance();
            }} else if (tmp18_subject.equals(Sign_ZERO_getInstance())) {
          }} else {
          var tmp21_subject = sign;
          if (tmp21_subject.equals(Sign_POSITIVE_getInstance())) {
            if (decider.equals(SignificantDecider_MORE_THAN_FIVE_getInstance())) {
              var tmp22 = result;
              result = tmp22.inc_5();
              Unit_getInstance();
            }} else if (tmp21_subject.equals(Sign_NEGATIVE_getInstance())) {
            if (!decider.equals(SignificantDecider_LESS_THAN_FIVE_getInstance())) {
              var tmp23 = result;
              result = tmp23.dec_5();
              Unit_getInstance();
            }} else if (tmp21_subject.equals(Sign_ZERO_getInstance())) {
          }}
      } else if (decider.equals(SignificantDecider_MORE_THAN_FIVE_getInstance())) {
        if (sign.equals(Sign_POSITIVE_getInstance())) {
          var tmp24 = result;
          result = tmp24.inc_5();
          Unit_getInstance();
        }if (sign.equals(Sign_NEGATIVE_getInstance())) {
          var tmp25 = result;
          result = tmp25.dec_5();
          Unit_getInstance();
        }}} else if (tmp1_subject.equals(RoundingMode_ROUND_HALF_TO_ODD_getInstance())) {
      if (decider.equals(SignificantDecider_FIVE_getInstance())) {
        if (significand.rem_32(2).equals(Companion_getInstance_13()._ZERO_0)) {
          var tmp26_subject = sign;
          if (tmp26_subject.equals(Sign_POSITIVE_getInstance())) {
            if (!decider.equals(SignificantDecider_LESS_THAN_FIVE_getInstance())) {
              var tmp27 = result;
              result = tmp27.inc_5();
              Unit_getInstance();
            }} else if (tmp26_subject.equals(Sign_NEGATIVE_getInstance())) {
            if (!decider.equals(SignificantDecider_LESS_THAN_FIVE_getInstance())) {
              var tmp28 = result;
              result = tmp28.dec_5();
              Unit_getInstance();
            }} else if (tmp26_subject.equals(Sign_ZERO_getInstance())) {
          }} else {
          var tmp29_subject = sign;
          if (tmp29_subject.equals(Sign_POSITIVE_getInstance())) {
            if (decider.equals(SignificantDecider_MORE_THAN_FIVE_getInstance())) {
              var tmp30 = result;
              result = tmp30.inc_5();
              Unit_getInstance();
            }} else if (tmp29_subject.equals(Sign_NEGATIVE_getInstance())) {
            if (!decider.equals(SignificantDecider_LESS_THAN_FIVE_getInstance())) {
              var tmp31 = result;
              result = tmp31.dec_5();
              Unit_getInstance();
            }} else if (tmp29_subject.equals(Sign_ZERO_getInstance())) {
          }}
      } else if (decider.equals(SignificantDecider_MORE_THAN_FIVE_getInstance())) {
        if (sign.equals(Sign_POSITIVE_getInstance())) {
          var tmp32 = result;
          result = tmp32.inc_5();
          Unit_getInstance();
        }if (sign.equals(Sign_NEGATIVE_getInstance())) {
          var tmp33 = result;
          result = tmp33.dec_5();
          Unit_getInstance();
        }}} else if (tmp1_subject.equals(RoundingMode_NONE_getInstance())) {
      throw ArithmeticException_init_$Create$('Non-terminating result of division operation. Specify decimalPrecision');
    }return result;
  }
  function roundSignificand($this, significand, exponent, decimalMode) {
    if (significand.equals(Companion_getInstance_13()._ZERO_0)) {
      return new BigDecimal(Companion_getInstance_13()._ZERO_0, exponent, decimalMode);
    }var significandDigits = significand.numberOfDecimalDigits();
    var tmp;
    if (decimalMode._usingScale_0) {
      tmp = decimalMode._decimalPrecision.plus_71(decimalMode._scale_1);
    } else {
      tmp = decimalMode._decimalPrecision;
    }
    var desiredPrecision = tmp;
    var tmp_0;
    if (desiredPrecision.compareTo_75(significandDigits) > 0) {
      var extendedSignificand = significand.times_0(Companion_getInstance_13()._TEN_0.pow_0(desiredPrecision.minus_40(significandDigits)));
      tmp_0 = new BigDecimal(extendedSignificand, exponent, decimalMode);
    } else if (desiredPrecision.compareTo_75(significandDigits) < 0) {
      var divRem = significand.divrem(Companion_getInstance_13()._TEN_0.pow_0(significandDigits.minus_40(desiredPrecision)));
      var resolvedRemainder = divRem._remainder;
      if (divRem._remainder.equals(Companion_getInstance_13()._ZERO_0)) {
        return new BigDecimal(divRem._quotient, exponent, decimalMode);
      }var tmp_1;
      if (significand.numberOfDecimalDigits().equals(divRem._quotient.numberOfDecimalDigits().plus_71(divRem._remainder.numberOfDecimalDigits()))) {
        var newSignificand = roundDiscarded($this, divRem._quotient, resolvedRemainder, decimalMode);
        var exponentModifier = newSignificand.numberOfDecimalDigits().minus_40(divRem._quotient.numberOfDecimalDigits());
        tmp_1 = new BigDecimal(newSignificand, exponent.plus_71(exponentModifier), decimalMode);
      } else {
        tmp_1 = $this.handleZeroRounding(divRem._quotient, exponent, decimalMode);
      }
      tmp_0 = tmp_1;
    } else {
      tmp_0 = new BigDecimal(significand, exponent, decimalMode);
    }
    return tmp_0;
  }
  function applyScale($this, significand, exponent, decimalMode) {
    if (!decimalMode._usingScale_0) {
      return new BigDecimal(significand, exponent, decimalMode);
    }var tmp;
    if (exponent.compareTo_75(new Long(0, 0)) >= 0) {
      var tmp0_plus_0 = exponent.plus_71(decimalMode._scale_1);
      var tmp1_plus_0 = 1;
      var tmp_0 = tmp0_plus_0.plus_71(toLong_0(tmp1_plus_0));
      tmp = DecimalMode_init_$Create$(tmp_0, decimalMode._roundingMode_0, new Long(0, 0), 4, null);
    } else if (exponent.compareTo_75(new Long(0, 0)) < 0) {
      var tmp2_plus_0 = decimalMode._scale_1;
      var tmp3_plus_0 = 1;
      var tmp_1 = tmp2_plus_0.plus_71(toLong_0(tmp3_plus_0));
      tmp = DecimalMode_init_$Create$(tmp_1, decimalMode._roundingMode_0, new Long(0, 0), 4, null);
    } else {
      throw RuntimeException_init_$Create$('Unexpected state');
    }
    var workMode = tmp;
    var tmp_2;
    if (exponent.compareTo_75(new Long(0, 0)) >= 0) {
      tmp_2 = roundSignificand($this, significand, exponent, workMode);
    } else {
      var temp = BigDecimal_init_$Create$(significand, exponent, null, 4, null).plus_70(significand.signum_2());
      tmp_2 = roundSignificand($this, temp._significand, temp._exponent, workMode).minus_39(significand.signum_2());
    }
    return tmp_2;
  }
  function resolveDecimalMode($this, firstDecimalMode, secondDecimalMode, suppliedDecimalMode) {
    var tmp;
    if (!(suppliedDecimalMode == null)) {
      tmp = suppliedDecimalMode;
    } else {
      if (firstDecimalMode == null ? secondDecimalMode == null : false) {
        var tmp_0 = new Long(0, 0);
        return DecimalMode_init_$Create$(tmp_0, null, new Long(0, 0), 7, null);
      }if (firstDecimalMode == null ? !(secondDecimalMode == null) : false) {
        return secondDecimalMode;
      }if (secondDecimalMode == null ? !(firstDecimalMode == null) : false) {
        return firstDecimalMode;
      }if (!ensureNotNull(firstDecimalMode)._roundingMode_0.equals(ensureNotNull(secondDecimalMode)._roundingMode_0)) {
        throw ArithmeticException_init_$Create$('' + 'Different rounding modes! This: ' + firstDecimalMode._roundingMode_0 + ' Other: ' + secondDecimalMode._roundingMode_0);
      }var tmp_1;
      if (firstDecimalMode._decimalPrecision.compareTo_75(secondDecimalMode._decimalPrecision) >= 0) {
        tmp_1 = firstDecimalMode;
      } else {
        tmp_1 = secondDecimalMode;
      }
      var unifiedDecimalMode = tmp_1;
      tmp = unifiedDecimalMode;
    }
    return tmp;
  }
  function SignificantDecider_FIVE_getInstance() {
    SignificantDecider_initEntries();
    return SignificantDecider_FIVE_instance;
  }
  function SignificantDecider_LESS_THAN_FIVE_getInstance() {
    SignificantDecider_initEntries();
    return SignificantDecider_LESS_THAN_FIVE_instance;
  }
  function SignificantDecider_MORE_THAN_FIVE_getInstance() {
    SignificantDecider_initEntries();
    return SignificantDecider_MORE_THAN_FIVE_instance;
  }
  var ScaleOps_Max_instance;
  var ScaleOps_Min_instance;
  var ScaleOps_Add_instance;
  var ScaleOps_entriesInitialized;
  function ScaleOps_initEntries() {
    if (ScaleOps_entriesInitialized)
      return Unit_getInstance();
    ScaleOps_entriesInitialized = true;
    ScaleOps_Max_instance = new ScaleOps('Max', 0);
    ScaleOps_Min_instance = new ScaleOps('Min', 1);
    ScaleOps_Add_instance = new ScaleOps('Add', 2);
  }
  function BigDecimal_init_$Init$(_significand, _exponent, _decimalMode, $mask0, $marker, $this) {
    if (!(($mask0 & 2) === 0))
      _exponent = new Long(0, 0);
    if (!(($mask0 & 4) === 0))
      _decimalMode = null;
    BigDecimal.call($this, _significand, _exponent, _decimalMode);
    return $this;
  }
  function BigDecimal_init_$Create$(_significand, _exponent, _decimalMode, $mask0, $marker) {
    return BigDecimal_init_$Init$(_significand, _exponent, _decimalMode, $mask0, $marker, Object.create(BigDecimal.prototype));
  }
  function Companion_12() {
    Companion_instance_11 = this;
    var tmp = this;
    var tmp_0 = Companion_getInstance_13()._ZERO_0;
    var tmp_1 = new Long(0, 0);
    tmp._ZERO = BigDecimal_init_$Create$(tmp_0, tmp_1, null, 6, null);
    var tmp_2 = this;
    var tmp_3 = Companion_getInstance_13()._ONE_0;
    var tmp_4 = new Long(0, 0);
    tmp_2._ONE = BigDecimal_init_$Create$(tmp_3, tmp_4, null, 6, null);
    var tmp_5 = this;
    var tmp_6 = Companion_getInstance_13()._TWO_0;
    var tmp_7 = new Long(0, 0);
    tmp_5._TWO = BigDecimal_init_$Create$(tmp_6, tmp_7, null, 6, null);
    var tmp_8 = this;
    var tmp_9 = Companion_getInstance_13()._TEN_0;
    var tmp_10 = new Long(1, 0);
    tmp_8._TEN = BigDecimal_init_$Create$(tmp_9, tmp_10, null, 4, null);
    this._useToStringExpanded = false;
    var tmp_11 = this;
    var tmp0_doubleArrayOf_0 = new Float64Array([1.0, 10.0, 100.0, 1000.0, 10000.0, 100000.0, 1000000.0, 1.0E7, 1.0E8, 1.0E9, 1.0E10, 1.0E11, 1.0E12, 1.0E13, 1.0E14, 1.0E15, 1.0E16, 1.0E17, 1.0E18, 1.0E19, 1.0E20, 1.0E21, 1.0E22]);
    tmp_11._double10pow = tmp0_doubleArrayOf_0;
    var tmp_12 = this;
    DoubleCompanionObject_getInstance();
    tmp_12._maximumDouble = this.fromDouble$default(1.7976931348623157E308, null, 2, null);
    var tmp_13 = this;
    DoubleCompanionObject_getInstance();
    tmp_13._leastSignificantDouble = this.fromDouble$default(4.9E-324, null, 2, null);
    var tmp_14 = this;
    var tmp0_floatArrayOf_0 = new Float32Array([1.0, 10.0, 100.0, 1000.0, 10000.0, 100000.0, 1000000.0, 1.0E7, 1.0E8, 1.0E9, 1.0E10]);
    tmp_14._float10pow = tmp0_floatArrayOf_0;
    var tmp_15 = this;
    FloatCompanionObject_getInstance();
    tmp_15._maximumFloat = this.fromFloat$default(3.4028235E38, null, 2, null);
    var tmp_16 = this;
    FloatCompanionObject_getInstance();
    tmp_16._leastSignificantFloat = this.fromFloat$default(1.4E-45, null, 2, null);
  }
  Companion_12.prototype._get_ZERO__4 = function () {
    return this._ZERO;
  };
  Companion_12.prototype.handleZeroRounding = function (significand, exponent, decimalMode) {
    var tmp;
    if (significand._sign.equals(Sign_POSITIVE_getInstance())) {
      var tmp0_subject = decimalMode._roundingMode_0;
      var tmp_0;
      if (tmp0_subject.equals(RoundingMode_CEILING_getInstance()) ? true : tmp0_subject.equals(RoundingMode_AWAY_FROM_ZERO_getInstance())) {
        var increasedSignificand = significand.inc_5();
        var exponentModifier = increasedSignificand.numberOfDecimalDigits().minus_40(significand.numberOfDecimalDigits());
        tmp_0 = new BigDecimal(increasedSignificand, exponent.plus_71(exponentModifier), decimalMode);
      } else {
        tmp_0 = new BigDecimal(significand, exponent, decimalMode);
      }
      tmp = tmp_0;
    } else if (significand._sign.equals(Sign_NEGATIVE_getInstance())) {
      var tmp1_subject = decimalMode._roundingMode_0;
      var tmp_1;
      if (tmp1_subject.equals(RoundingMode_FLOOR_getInstance()) ? true : tmp1_subject.equals(RoundingMode_AWAY_FROM_ZERO_getInstance())) {
        var increasedSignificand_0 = significand.dec_5();
        var exponentModifier_0 = increasedSignificand_0.numberOfDecimalDigits().minus_40(significand.numberOfDecimalDigits());
        tmp_1 = new BigDecimal(increasedSignificand_0, exponent.plus_71(exponentModifier_0), decimalMode);
      } else {
        tmp_1 = new BigDecimal(significand, exponent, decimalMode);
      }
      tmp = tmp_1;
    } else {
      tmp = new BigDecimal(significand, exponent, decimalMode);
    }
    return tmp;
  };
  Companion_12.prototype.fromBigInteger_0 = function (bigInteger, decimalMode) {
    var tmp0_minus_0 = bigInteger.numberOfDecimalDigits();
    var tmp1_minus_0 = 1;
    return (new BigDecimal(bigInteger, tmp0_minus_0.minus_40(toLong_0(tmp1_minus_0)), decimalMode)).roundSignificand(decimalMode);
  };
  Companion_12.prototype.fromLong_0 = function (long, decimalMode) {
    var bigint = Companion_getInstance_13().fromLong_5(long);
    var tmp0_minus_0 = bigint.numberOfDecimalDigits();
    var tmp1_minus_0 = 1;
    return (new BigDecimal(bigint, tmp0_minus_0.minus_40(toLong_0(tmp1_minus_0)), decimalMode)).roundSignificand(decimalMode);
  };
  Companion_12.prototype.fromInt_0 = function (int, decimalMode) {
    var bigint = Companion_getInstance_13().fromInt_5(int);
    var tmp0_minus_0 = bigint.numberOfDecimalDigits();
    var tmp1_minus_0 = 1;
    return (new BigDecimal(bigint, tmp0_minus_0.minus_40(toLong_0(tmp1_minus_0)), decimalMode)).roundSignificand(decimalMode);
  };
  Companion_12.prototype.fromShort_0 = function (short, decimalMode) {
    var bigint = Companion_getInstance_13().fromShort_5(short);
    var tmp0_minus_0 = bigint.numberOfDecimalDigits();
    var tmp1_minus_0 = 1;
    return (new BigDecimal(bigint, tmp0_minus_0.minus_40(toLong_0(tmp1_minus_0)), decimalMode)).roundSignificand(decimalMode);
  };
  Companion_12.prototype.fromByte_0 = function (byte, decimalMode) {
    var bigint = Companion_getInstance_13().fromByte_5(byte);
    var tmp0_minus_0 = bigint.numberOfDecimalDigits();
    var tmp1_minus_0 = 1;
    return (new BigDecimal(bigint, tmp0_minus_0.minus_40(toLong_0(tmp1_minus_0)), decimalMode)).roundSignificand(decimalMode);
  };
  Companion_12.prototype.fromFloat = function (float, decimalMode) {
    var floatString = float.toString();
    var tmp;
    var tmp_0;
    var tmp_1 = new Char(46);
    if (contains$default(floatString, tmp_1, false, 2, null)) {
      tmp_0 = !contains_5(floatString, new Char(69), true);
    } else {
      {
        tmp_0 = false;
      }
    }
    if (tmp_0) {
      var tmp$ret$0;
      l$ret$1: do {
        var inductionVariable = _get_lastIndex__3(floatString);
        if (0 <= inductionVariable)
          do {
            var index_2 = inductionVariable;
            inductionVariable = inductionVariable + -1 | 0;
            var tmp0__anonymous__5 = charSequenceGet(floatString, index_2);
            if (!tmp0__anonymous__5.equals(new Char(48))) {
              var tmp0_substring_0_3 = 0;
              var tmp1_substring_0_4 = index_2 + 1 | 0;
              tmp$ret$0 = floatString.substring(tmp0_substring_0_3, tmp1_substring_0_4);
              break l$ret$1;
            } else {
            }
          }
           while (0 <= inductionVariable);
        tmp$ret$0 = '';
      }
       while (false);
      tmp = this.parseStringWithMode(tmp$ret$0, decimalMode).roundSignificand(decimalMode);
    } else {
      {
        tmp = this.parseStringWithMode(floatString, decimalMode).roundSignificand(decimalMode);
      }
    }
    return tmp;
  };
  Companion_12.prototype.fromFloat$default = function (float, decimalMode, $mask0, $handler) {
    if (!(($mask0 & 2) === 0))
      decimalMode = null;
    return this.fromFloat(float, decimalMode);
  };
  Companion_12.prototype.fromDouble = function (double, decimalMode) {
    var doubleString = double.toString();
    var tmp;
    var tmp_0;
    var tmp_1 = new Char(46);
    if (contains$default(doubleString, tmp_1, false, 2, null)) {
      tmp_0 = !contains_5(doubleString, new Char(69), true);
    } else {
      {
        tmp_0 = false;
      }
    }
    if (tmp_0) {
      var tmp$ret$0;
      l$ret$1: do {
        var inductionVariable = _get_lastIndex__3(doubleString);
        if (0 <= inductionVariable)
          do {
            var index_2 = inductionVariable;
            inductionVariable = inductionVariable + -1 | 0;
            var tmp0__anonymous__5 = charSequenceGet(doubleString, index_2);
            if (!tmp0__anonymous__5.equals(new Char(48))) {
              var tmp0_substring_0_3 = 0;
              var tmp1_substring_0_4 = index_2 + 1 | 0;
              tmp$ret$0 = doubleString.substring(tmp0_substring_0_3, tmp1_substring_0_4);
              break l$ret$1;
            } else {
            }
          }
           while (0 <= inductionVariable);
        tmp$ret$0 = '';
      }
       while (false);
      tmp = this.parseStringWithMode(tmp$ret$0, decimalMode).roundSignificand(decimalMode);
    } else {
      {
        tmp = this.parseStringWithMode(doubleString, decimalMode).roundSignificand(decimalMode).roundSignificand(decimalMode);
      }
    }
    return tmp;
  };
  Companion_12.prototype.fromDouble$default = function (double, decimalMode, $mask0, $handler) {
    if (!(($mask0 & 2) === 0))
      decimalMode = null;
    return this.fromDouble(double, decimalMode);
  };
  Companion_12.prototype.fromBigInteger_3 = function (bigInteger) {
    return this.fromBigInteger_0(bigInteger, null);
  };
  Companion_12.prototype.fromLong_5 = function (long) {
    return this.fromLong_0(long, null);
  };
  Companion_12.prototype.fromInt_5 = function (int) {
    return this.fromInt_0(int, null);
  };
  Companion_12.prototype.fromShort_5 = function (short) {
    return this.fromShort_0(short, null);
  };
  Companion_12.prototype.fromByte_5 = function (byte) {
    return this.fromByte_0(byte, null);
  };
  Companion_12.prototype.tryFromFloat_2 = function (float, exactRequired) {
    return this.fromFloat(float, null);
  };
  Companion_12.prototype.tryFromDouble_2 = function (double, exactRequired) {
    return this.fromDouble(double, null);
  };
  Companion_12.prototype.parseString = function (string) {
    return this.parseStringWithMode$default(string, null, 2, null);
  };
  Companion_12.prototype.parseStringWithMode = function (floatingPointString, decimalMode) {
    if (charSequenceLength(floatingPointString) === 0) {
      return this._ZERO;
    } else {
    }
    if (contains_5(floatingPointString, new Char(69), true)) {
      var tmp;
      var tmp_0 = new Char(46);
      if (!contains$default(floatingPointString, tmp_0, false, 2, null)) {
        var tmp_1 = charArrayOf([new Char(69), new Char(101)]);
        var splitAroundE = split$default(floatingPointString, tmp_1, false, 0, 6, null);
        tmp = listOf([splitAroundE.get_72(0), '0E' + splitAroundE.get_72(1)]);
      } else {
        {
          var tmp_2 = charArrayOf([new Char(46)]);
          tmp = split$default(floatingPointString, tmp_2, false, 0, 6, null);
        }
      }
      var split_1 = tmp;
      var tmp0_subject = split_1._get_size__52();
      if (tmp0_subject === 2) {
        var signPresent = charSequenceGet(floatingPointString, 0).equals(new Char(45)) ? true : charSequenceGet(floatingPointString, 0).equals(new Char(43));
        var tmp_3;
        if (signPresent) {
          tmp_3 = 1;
        } else {
          tmp_3 = 0;
        }
        var leftStart = tmp_3;
        var tmp_4;
        if (signPresent) {
          var tmp_5;
          if (charSequenceGet(floatingPointString, 0).equals(new Char(45))) {
            tmp_5 = Sign_NEGATIVE_getInstance();
          } else {
            tmp_5 = Sign_POSITIVE_getInstance();
          }
          tmp_4 = tmp_5;
        } else {
          tmp_4 = Sign_POSITIVE_getInstance();
        }
        var sign = tmp_4;
        var tmp0_substring_0 = split_1.get_72(0);
        var left = tmp0_substring_0.substring(leftStart);
        var tmp_6 = split_1.get_72(1);
        var tmp_7 = charArrayOf([new Char(69), new Char(101)]);
        var rightSplit = split$default(tmp_6, tmp_7, false, 0, 6, null);
        var right = rightSplit.get_72(0);
        var exponentSplit = rightSplit.get_72(1);
        var exponentSignPresent = charSequenceGet(exponentSplit, 0).equals(new Char(45)) ? true : charSequenceGet(exponentSplit, 0).equals(new Char(43));
        var tmp_8;
        if (charSequenceGet(exponentSplit, 0).equals(new Char(45))) {
          tmp_8 = Sign_NEGATIVE_getInstance();
        } else {
          tmp_8 = Sign_POSITIVE_getInstance();
        }
        var exponentSign = tmp_8;
        var tmp_9;
        if (exponentSignPresent) {
          tmp_9 = 1;
        } else {
          tmp_9 = 0;
        }
        var skipSignIfPresent = tmp_9;
        var exponentString = exponentSplit.substring(skipSignIfPresent);
        var tmp_10;
        if (exponentSign.equals(Sign_POSITIVE_getInstance())) {
          tmp_10 = toLong(exponentString, 10);
        } else {
          var tmp1_times_0 = toLong(exponentString, 10);
          var tmp2_times_0 = -1;
          tmp_10 = tmp1_times_0.times_40(toLong_0(tmp2_times_0));
        }
        var exponent = tmp_10;
        var tmp$ret$0;
        l$ret$1: do {
          var inductionVariable = 0;
          var last_2 = charSequenceLength(left) - 1 | 0;
          if (inductionVariable <= last_2)
            do {
              var index_2 = inductionVariable;
              inductionVariable = inductionVariable + 1 | 0;
              var tmp3__anonymous__3 = charSequenceGet(left, index_2);
              if (!tmp3__anonymous__3.equals(new Char(48))) {
                tmp$ret$0 = index_2;
                break l$ret$1;
              } else {
              }
            }
             while (inductionVariable <= last_2);
          tmp$ret$0 = -1;
        }
         while (false);
        var leftFirstNonZero = tmp$ret$0;
        if (leftFirstNonZero === -1) {
          leftFirstNonZero = 0;
        }var tmp$ret$2;
        l$ret$3: do {
          var inductionVariable_0 = charSequenceLength(right) - 1 | 0;
          if (0 <= inductionVariable_0)
            do {
              var index_2_0 = inductionVariable_0;
              inductionVariable_0 = inductionVariable_0 + -1 | 0;
              var tmp4__anonymous__3 = charSequenceGet(right, index_2_0);
              if (!tmp4__anonymous__3.equals(new Char(48))) {
                tmp$ret$2 = index_2_0;
                break l$ret$3;
              } else {
              }
            }
             while (0 <= inductionVariable_0);
          tmp$ret$2 = -1;
        }
         while (false);
        var rightLastNonZero = tmp$ret$2;
        if (rightLastNonZero === -1) {
          rightLastNonZero = right.length - 1 | 0;
        }var tmp5_substring_0 = leftFirstNonZero;
        var tmp6_substring_0 = left.length;
        var leftTruncated = left.substring(tmp5_substring_0, tmp6_substring_0);
        var tmp7_substring_0 = 0;
        var tmp8_substring_0 = rightLastNonZero + 1 | 0;
        var rightTruncated = right.substring(tmp7_substring_0, tmp8_substring_0);
        var significand = Companion_getInstance_13().parseString_0(leftTruncated + rightTruncated, 10);
        if (significand.equals(Companion_getInstance_13()._ZERO_0)) {
          sign = Sign_ZERO_getInstance();
        }if (sign.equals(Sign_NEGATIVE_getInstance())) {
          significand = significand.negate();
        }var tmp_11;
        if (!(leftTruncated === '0')) {
          var tmp9_plus_0 = leftTruncated.length;
          var tmp10_minus_0 = exponent.plus_71(toLong_0(tmp9_plus_0));
          var tmp11_minus_0 = 1;
          tmp_11 = tmp10_minus_0.minus_40(toLong_0(tmp11_minus_0));
        } else {
          var tmp12_minus_0 = exponent.minus_40(numberToLong(rightTruncated.length).minus_40(significand.numberOfDecimalDigits()));
          var tmp13_minus_0 = 1;
          tmp_11 = tmp12_minus_0.minus_40(toLong_0(tmp13_minus_0));
        }
        var exponentModifiedByFloatingPointPosition = tmp_11;
        return new BigDecimal(significand, exponentModifiedByFloatingPointPosition, decimalMode);
      } else
        throw ArithmeticException_init_$Create$('' + 'Invalid (or unsupported) floating point number format: ' + floatingPointString);
    } else {
      var tmp_12 = new Char(46);
      if (contains$default(floatingPointString, tmp_12, false, 2, null)) {
        var tmp_13 = charArrayOf([new Char(46)]);
        var split_2 = split$default(floatingPointString, tmp_13, false, 0, 6, null);
        var tmp1_subject = split_2._get_size__52();
        if (tmp1_subject === 2) {
          var signPresent_0 = charSequenceGet(floatingPointString, 0).equals(new Char(45)) ? true : charSequenceGet(floatingPointString, 0).equals(new Char(43));
          var tmp_14;
          if (signPresent_0) {
            tmp_14 = 1;
          } else {
            tmp_14 = 0;
          }
          var leftStart_0 = tmp_14;
          var tmp_15;
          if (signPresent_0) {
            var tmp_16;
            if (charSequenceGet(floatingPointString, 0).equals(new Char(45))) {
              tmp_16 = Sign_NEGATIVE_getInstance();
            } else {
              tmp_16 = Sign_POSITIVE_getInstance();
            }
            tmp_15 = tmp_16;
          } else {
            tmp_15 = Sign_POSITIVE_getInstance();
          }
          var sign_0 = tmp_15;
          var tmp14_substring_0 = split_2.get_72(0);
          var left_0 = tmp14_substring_0.substring(leftStart_0);
          var right_0 = split_2.get_72(1);
          var tmp$ret$4;
          l$ret$5: do {
            var inductionVariable_1 = 0;
            var last_3 = charSequenceLength(left_0) - 1 | 0;
            if (inductionVariable_1 <= last_3)
              do {
                var index_2_1 = inductionVariable_1;
                inductionVariable_1 = inductionVariable_1 + 1 | 0;
                var tmp15__anonymous__3 = charSequenceGet(left_0, index_2_1);
                if (!tmp15__anonymous__3.equals(new Char(48))) {
                  tmp$ret$4 = index_2_1;
                  break l$ret$5;
                } else {
                }
              }
               while (inductionVariable_1 <= last_3);
            tmp$ret$4 = -1;
          }
           while (false);
          var leftFirstNonZero_0 = tmp$ret$4;
          if (leftFirstNonZero_0 === -1) {
            leftFirstNonZero_0 = 0;
          }var tmp$ret$6;
          l$ret$7: do {
            var inductionVariable_2 = charSequenceLength(right_0) - 1 | 0;
            if (0 <= inductionVariable_2)
              do {
                var index_2_2 = inductionVariable_2;
                inductionVariable_2 = inductionVariable_2 + -1 | 0;
                var tmp16__anonymous__3 = charSequenceGet(right_0, index_2_2);
                if (!tmp16__anonymous__3.equals(new Char(48))) {
                  tmp$ret$6 = index_2_2;
                  break l$ret$7;
                } else {
                }
              }
               while (0 <= inductionVariable_2);
            tmp$ret$6 = -1;
          }
           while (false);
          var rightLastNonZero_0 = tmp$ret$6;
          if (rightLastNonZero_0 === -1) {
            rightLastNonZero_0 = right_0.length - 1 | 0;
          }var tmp17_substring_0 = leftFirstNonZero_0;
          var tmp18_substring_0 = left_0.length;
          var leftTruncated_0 = left_0.substring(tmp17_substring_0, tmp18_substring_0);
          var tmp19_substring_0 = 0;
          var tmp20_substring_0 = rightLastNonZero_0 + 1 | 0;
          var rightTruncated_0 = right_0.substring(tmp19_substring_0, tmp20_substring_0);
          var significand_0 = Companion_getInstance_13().parseString_0(leftTruncated_0 + rightTruncated_0, 10);
          var tmp_17;
          var tmp_18;
          if (charSequenceLength(leftTruncated_0) > 0) {
            tmp_18 = !charSequenceGet(leftTruncated_0, 0).equals(new Char(48));
          } else {
            {
              tmp_18 = false;
            }
          }
          if (tmp_18) {
            tmp_17 = leftTruncated_0.length - 1 | 0;
          } else {
            {
              var tmp$ret$8;
              l$ret$9: do {
                var inductionVariable_3 = 0;
                var last_4 = charSequenceLength(rightTruncated_0) - 1 | 0;
                if (inductionVariable_3 <= last_4)
                  do {
                    var index_2_3 = inductionVariable_3;
                    inductionVariable_3 = inductionVariable_3 + 1 | 0;
                    var tmp21__anonymous__3 = charSequenceGet(rightTruncated_0, index_2_3);
                    if (!tmp21__anonymous__3.equals(new Char(48))) {
                      tmp$ret$8 = index_2_3;
                      break l$ret$9;
                    } else {
                    }
                  }
                   while (inductionVariable_3 <= last_4);
                tmp$ret$8 = -1;
              }
               while (false);
              tmp_17 = imul(tmp$ret$8 + 1 | 0, -1);
            }
          }
          var exponent_0 = tmp_17;
          if (significand_0.equals(Companion_getInstance_13()._ZERO_0)) {
            sign_0 = Sign_ZERO_getInstance();
          }if (sign_0.equals(Sign_NEGATIVE_getInstance())) {
            significand_0 = significand_0.negate();
          }return new BigDecimal(significand_0, toLong_0(exponent_0), decimalMode);
        } else
          throw ArithmeticException_init_$Create$('' + 'Invalid (or unsupported) floating point number format: ' + floatingPointString);
      } else {
        {
          var significand_1 = Companion_getInstance_13().parseString_0(floatingPointString, 10);
          var tmp22_minus_0 = significand_1.numberOfDecimalDigits();
          var tmp23_minus_0 = 1;
          return new BigDecimal(significand_1, tmp22_minus_0.minus_40(toLong_0(tmp23_minus_0)), decimalMode);
        }
      }
    }
  };
  Companion_12.prototype.parseStringWithMode$default = function (floatingPointString, decimalMode, $mask0, $handler) {
    if (!(($mask0 & 2) === 0))
      decimalMode = null;
    return this.parseStringWithMode(floatingPointString, decimalMode);
  };
  Companion_12.$metadata$ = {
    simpleName: 'Companion',
    kind: 'object',
    interfaces: [Creator]
  };
  var Companion_instance_11;
  function Companion_getInstance_11() {
    if (Companion_instance_11 == null)
      new Companion_12();
    return Companion_instance_11;
  }
  function removeTrailingZeroes($this, bigDecimal) {
    if (bigDecimal.equals(Companion_getInstance_11()._ZERO))
      return $this;
    var significand = bigDecimal._significand;
    var divisionResult = new QuotientAndRemainder(bigDecimal._significand, Companion_getInstance_13()._ZERO_0);
    do {
      divisionResult = divisionResult._quotient.divrem(Companion_getInstance_13()._TEN_0);
      if (divisionResult._remainder.equals(Companion_getInstance_13()._ZERO_0)) {
        significand = divisionResult._quotient;
      }}
     while (divisionResult._remainder.equals(Companion_getInstance_13()._ZERO_0));
    var tmp = significand;
    return BigDecimal_init_$Create$(tmp, bigDecimal._exponent, null, 4, null);
  }
  function ScaleOps(name, ordinal) {
    Enum.call(this, name, ordinal);
  }
  ScaleOps.$metadata$ = {
    simpleName: 'ScaleOps',
    kind: 'class',
    interfaces: []
  };
  function computeMode($this, other, op) {
    var tmp;
    if ((($this._decimalMode == null ? true : $this._decimalMode._isPrecisionUnlimited) ? true : other._decimalMode == null) ? true : other._decimalMode._isPrecisionUnlimited) {
      tmp = Companion_getInstance_12()._DEFAULT;
    } else {
      var tmp0_max_0 = $this._decimalMode._decimalPrecision;
      var tmp1_max_0 = other._decimalMode._decimalPrecision;
      var tmp_0 = tmp0_max_0.compareTo_75(tmp1_max_0) >= 0 ? tmp0_max_0 : tmp1_max_0;
      var tmp_1;
      if ($this._decimalMode._usingScale_0 ? other._decimalMode._usingScale_0 : false) {
        var tmp0_subject = op;
        var tmp_2;
        if (tmp0_subject.equals(ScaleOps_Max_getInstance())) {
          var tmp2_max_0 = $this._decimalMode._scale_1;
          var tmp3_max_0 = other._decimalMode._scale_1;
          tmp_2 = tmp2_max_0.compareTo_75(tmp3_max_0) >= 0 ? tmp2_max_0 : tmp3_max_0;
        } else if (tmp0_subject.equals(ScaleOps_Min_getInstance())) {
          var tmp4_min_0 = $this._decimalMode._scale_1;
          var tmp5_min_0 = other._decimalMode._scale_1;
          tmp_2 = tmp4_min_0.compareTo_75(tmp5_min_0) <= 0 ? tmp4_min_0 : tmp5_min_0;
        } else if (tmp0_subject.equals(ScaleOps_Add_getInstance())) {
          tmp_2 = $this._decimalMode._scale_1.plus_71(other._decimalMode._scale_1);
        } else {
          noWhenBranchMatchedException();
        }
        tmp_1 = tmp_2;
      } else {
        tmp_1 = new Long(-1, -1);
      }
      tmp = new DecimalMode(tmp_0, $this._decimalMode._roundingMode_0, tmp_1);
    }
    return tmp;
  }
  function getRidOfRadix($this, bigDecimal) {
    var precision = bigDecimal._significand.numberOfDecimalDigits();
    var tmp0_plus_0 = bigDecimal._exponent.minus_40(precision);
    var tmp1_plus_0 = 1;
    var newExponent = tmp0_plus_0.plus_71(toLong_0(tmp1_plus_0));
    return BigDecimal_init_$Create$(bigDecimal._significand, newExponent, null, 4, null);
  }
  function bringSignificandToSameExponent($this, first_4, second) {
    var firstPrepared = getRidOfRadix($this, first_4);
    var secondPrepared = getRidOfRadix($this, second);
    var firstPreparedExponent = firstPrepared._exponent;
    var secondPreparedExponent = secondPrepared._exponent;
    var tmp;
    if (first_4._exponent.compareTo_75(second._exponent) > 0) {
      var moveFirstBy = firstPreparedExponent.minus_40(secondPreparedExponent);
      var tmp_0;
      if (moveFirstBy.compareTo_75(new Long(0, 0)) >= 0) {
        var movedFirst = firstPrepared._significand.times_0(toBigInteger(10).pow_0(moveFirstBy));
        return new Triple(movedFirst, second._significand, secondPreparedExponent);
      } else {
        var tmp_1 = toBigInteger(10);
        var tmp0_times_0 = -1;
        var movedSecond = secondPrepared._significand.times_0(tmp_1.pow_0(moveFirstBy.times_40(toLong_0(tmp0_times_0))));
        tmp_0 = new Triple(first_4._significand, movedSecond, firstPreparedExponent);
      }
      tmp = tmp_0;
    } else if (first_4._exponent.compareTo_75(second._exponent) < 0) {
      var moveSecondBy = secondPreparedExponent.minus_40(firstPreparedExponent);
      var tmp_2;
      if (moveSecondBy.compareTo_75(new Long(0, 0)) >= 0) {
        var movedSecond_0 = secondPrepared._significand.times_0(toBigInteger(10).pow_0(moveSecondBy));
        tmp_2 = new Triple(first_4._significand, movedSecond_0, firstPreparedExponent);
      } else {
        var tmp_3 = toBigInteger(10);
        var tmp1_times_0 = -1;
        var movedFirst_0 = firstPrepared._significand.times_0(tmp_3.pow_0(moveSecondBy.times_40(toLong_0(tmp1_times_0))));
        tmp_2 = new Triple(movedFirst_0, second._significand, firstPreparedExponent);
      }
      return tmp_2;
    } else if (first_4._exponent.equals(second._exponent)) {
      var delta_0 = firstPreparedExponent.minus_40(secondPreparedExponent);
      var tmp_4;
      if (delta_0.compareTo_75(new Long(0, 0)) > 0) {
        var movedFirst_1 = first_4._significand.times_0(toBigInteger(10).pow_0(delta_0));
        tmp_4 = new Triple(movedFirst_1, second._significand, firstPreparedExponent);
      } else {
        if (delta_0.compareTo_75(new Long(0, 0)) < 0) {
          var tmp_5 = toBigInteger(10);
          var tmp2_times_0 = -1;
          var movedSecond_1 = second._significand.times_0(tmp_5.pow_0(delta_0.times_40(toLong_0(tmp2_times_0))));
          tmp_4 = new Triple(first_4._significand, movedSecond_1, firstPreparedExponent);
        } else {
          var tmp3_compareTo_0 = 0;
          if (delta_0.compareTo_75(toLong_0(tmp3_compareTo_0)) === 0) {
            tmp_4 = new Triple(first_4._significand, second._significand, firstPreparedExponent);
          } else {
            {
              throw RuntimeException_init_$Create$('' + 'Invalid delta: ' + delta_0);
            }
          }
        }
      }
      return tmp_4;
    } else {
      throw RuntimeException_init_$Create$('' + 'Invalid comparison state BigInteger: ' + first_4._exponent + ', ' + second._exponent);
    }
    return tmp;
  }
  function javascriptNumberComparison($this, number) {
    var float = numberToDouble(number);
    var tmp;
    if (float % 1 === 0.0) {
      tmp = $this.compare_0(Companion_getInstance_11().fromLong_5(numberToLong(number)));
    } else {
      var tmp_0 = numberToDouble(number);
      tmp = $this.compare_0(toBigDecimal$default(tmp_0, null, null, 3, null));
    }
    return tmp;
  }
  function placeADotInStringExpanded($this, input, position_0) {
    var prefix = substring(input, until(0, input.length - position_0 | 0));
    var tmp$ret$0;
    l$ret$1: do {
      var tmp0_dropLastWhile_0 = substring(input, until(input.length - position_0 | 0, input.length));
      var inductionVariable = _get_lastIndex__3(tmp0_dropLastWhile_0);
      if (0 <= inductionVariable)
        do {
          var index_2 = inductionVariable;
          inductionVariable = inductionVariable + -1 | 0;
          var tmp1__anonymous__5 = charSequenceGet(tmp0_dropLastWhile_0, index_2);
          if (!tmp1__anonymous__5.equals(new Char(48))) {
            var tmp0_substring_0_3 = 0;
            var tmp1_substring_0_4 = index_2 + 1 | 0;
            tmp$ret$0 = tmp0_dropLastWhile_0.substring(tmp0_substring_0_3, tmp1_substring_0_4);
            break l$ret$1;
          } else {
          }
        }
         while (0 <= inductionVariable);
      tmp$ret$0 = '';
    }
     while (false);
    var suffix = tmp$ret$0;
    var tmp;
    if (charSequenceLength(suffix) > 0) {
      tmp = '' + prefix + '.' + suffix;
    } else {
      {
        tmp = prefix;
      }
    }
    return tmp;
  }
  function placeADotInString($this, input, position_0) {
    var prefix = substring(input, until(0, input.length - position_0 | 0));
    var suffix = substring(input, until(input.length - position_0 | 0, input.length));
    var prepared = '' + prefix + '.' + suffix;
    var tmp$ret$0;
    l$ret$1: do {
      var inductionVariable = _get_lastIndex__3(prepared);
      if (0 <= inductionVariable)
        do {
          var index_2 = inductionVariable;
          inductionVariable = inductionVariable + -1 | 0;
          var tmp0__anonymous__5 = charSequenceGet(prepared, index_2);
          if (!tmp0__anonymous__5.equals(new Char(48))) {
            var tmp0_substring_0_3 = 0;
            var tmp1_substring_0_4 = index_2 + 1 | 0;
            tmp$ret$0 = prepared.substring(tmp0_substring_0_3, tmp1_substring_0_4);
            break l$ret$1;
          } else {
          }
        }
         while (0 <= inductionVariable);
      tmp$ret$0 = '';
    }
     while (false);
    return tmp$ret$0;
  }
  function ScaleOps_Max_getInstance() {
    ScaleOps_initEntries();
    return ScaleOps_Max_instance;
  }
  function ScaleOps_Min_getInstance() {
    ScaleOps_initEntries();
    return ScaleOps_Min_instance;
  }
  function ScaleOps_Add_getInstance() {
    ScaleOps_initEntries();
    return ScaleOps_Add_instance;
  }
  function BigDecimal(_significand, _exponent, _decimalMode) {
    Companion_getInstance_11();
    if (!(_decimalMode == null) ? _decimalMode._usingScale_0 : false) {
      var wrk = applyScale(Companion_getInstance_11(), _significand, _exponent, _decimalMode);
      if (!wrk.isZero_0()) {
        this._significand = wrk._significand;
        this._exponent = wrk._exponent;
        var newPrecision = this._significand.numberOfDecimalDigits();
        this._precision = newPrecision;
        var tmp = this;
        tmp._decimalMode = _decimalMode.copy$default_0(newPrecision, null, new Long(0, 0), 6, null);
      } else {
        this._significand = wrk._significand;
        this._exponent = wrk._exponent.times_40(_decimalMode._decimalPrecision.plus_71(_decimalMode._scale_1));
        this._precision = _decimalMode._decimalPrecision.plus_71(_decimalMode._scale_1);
        var tmp_0 = this;
        tmp_0._decimalMode = _decimalMode.copy$default_0(this._precision, null, new Long(0, 0), 6, null);
      }
    } else {
      this._significand = _significand;
      this._precision = _significand.numberOfDecimalDigits();
      this._exponent = _exponent;
      this._decimalMode = _decimalMode;
    }
    var tmp_1 = this;
    var tmp0_safe_receiver = this._decimalMode;
    var tmp1_elvis_lhs = tmp0_safe_receiver == null ? null : tmp0_safe_receiver._decimalPrecision;
    tmp_1._precisionLimit = tmp1_elvis_lhs == null ? new Long(0, 0) : tmp1_elvis_lhs;
    var tmp_2 = this;
    var tmp0_safe_receiver_0 = this._decimalMode;
    var tmp1_elvis_lhs_0 = tmp0_safe_receiver_0 == null ? null : tmp0_safe_receiver_0._roundingMode_0;
    tmp_2._roundingMode = tmp1_elvis_lhs_0 == null ? RoundingMode_NONE_getInstance() : tmp1_elvis_lhs_0;
    var tmp_3 = this;
    var tmp0_safe_receiver_1 = this._decimalMode;
    var tmp1_elvis_lhs_1 = tmp0_safe_receiver_1 == null ? null : tmp0_safe_receiver_1._scale_1;
    tmp_3._scale_0 = tmp1_elvis_lhs_1 == null ? new Long(-1, -1) : tmp1_elvis_lhs_1;
    this._usingScale = this._scale_0.compareTo_75(new Long(0, 0)) >= 0;
  }
  BigDecimal.prototype.getCreator_2 = function () {
    return Companion_getInstance_11();
  };
  BigDecimal.prototype.getInstance_2 = function () {
    return this;
  };
  BigDecimal.prototype.add_25 = function (other) {
    return this.add_27(other, computeMode(this, other, ScaleOps_Max_getInstance()));
  };
  BigDecimal.prototype.add_34 = function (other) {
    return this.add_25(other instanceof BigDecimal ? other : THROW_CCE());
  };
  BigDecimal.prototype.add_27 = function (other, decimalMode) {
    var resolvedDecimalMode = resolveDecimalMode(Companion_getInstance_11(), this._decimalMode, other._decimalMode, decimalMode);
    if (this.equals(Companion_getInstance_11()._ZERO)) {
      return roundOrDont(Companion_getInstance_11(), other._significand, other._exponent, resolvedDecimalMode);
    }if (other.equals(Companion_getInstance_11()._ZERO)) {
      return roundOrDont(Companion_getInstance_11(), this._significand, this._exponent, resolvedDecimalMode);
    }var tmp0_container = bringSignificandToSameExponent(this, this, other);
    var first_4 = tmp0_container.component1_4();
    var second = tmp0_container.component2_4();
    var firstNumOfDigits = first_4.numberOfDecimalDigits();
    var secondNumOfDigits = second.numberOfDecimalDigits();
    var newSignificand = first_4.plus_4(second);
    var newSignificandNumOfDigit = newSignificand.numberOfDecimalDigits();
    var tmp;
    if (firstNumOfDigits.compareTo_75(secondNumOfDigits) > 0) {
      tmp = firstNumOfDigits;
    } else {
      tmp = secondNumOfDigits;
    }
    var largerOperand = tmp;
    var carryDetected = newSignificandNumOfDigit.minus_40(largerOperand);
    var tmp0_max_0 = this._exponent;
    var tmp1_max_0 = other._exponent;
    var newExponent = (tmp0_max_0.compareTo_75(tmp1_max_0) >= 0 ? tmp0_max_0 : tmp1_max_0).plus_71(carryDetected);
    var tmp_0;
    if (resolvedDecimalMode._usingScale_0) {
      var tmp_1 = Companion_getInstance_11();
      tmp_0 = roundOrDont(tmp_1, newSignificand, newExponent, resolvedDecimalMode.copy$default_0(newSignificandNumOfDigit, null, new Long(0, 0), 6, null));
    } else {
      tmp_0 = roundOrDont(Companion_getInstance_11(), newSignificand, newExponent, resolvedDecimalMode);
    }
    return tmp_0;
  };
  BigDecimal.prototype.subtract_0 = function (other) {
    return this.subtract_2(other, computeMode(this, other, ScaleOps_Max_getInstance()));
  };
  BigDecimal.prototype.subtract_9 = function (other) {
    return this.subtract_0(other instanceof BigDecimal ? other : THROW_CCE());
  };
  BigDecimal.prototype.subtract_2 = function (other, decimalMode) {
    var resolvedDecimalMode = resolveDecimalMode(Companion_getInstance_11(), this._decimalMode, other._decimalMode, decimalMode);
    if (this.equals(Companion_getInstance_11()._ZERO)) {
      return roundOrDont(Companion_getInstance_11(), other._significand.negate(), other._exponent, resolvedDecimalMode);
    }if (other.equals(Companion_getInstance_11()._ZERO)) {
      return roundOrDont(Companion_getInstance_11(), this._significand, this._exponent, resolvedDecimalMode);
    }var tmp0_container = bringSignificandToSameExponent(this, this, other);
    var first_4 = tmp0_container.component1_4();
    var second = tmp0_container.component2_4();
    var firstNumOfDigits = first_4.numberOfDecimalDigits();
    var secondNumOfDigits = second.numberOfDecimalDigits();
    var newSignificand = first_4.minus_2(second);
    var newSignificandNumOfDigit = newSignificand.numberOfDecimalDigits();
    var tmp;
    if (firstNumOfDigits.compareTo_75(secondNumOfDigits) > 0) {
      tmp = firstNumOfDigits;
    } else {
      tmp = secondNumOfDigits;
    }
    var largerOperand = tmp;
    var borrowDetected = newSignificandNumOfDigit.minus_40(largerOperand);
    var tmp0_max_0 = this._exponent;
    var tmp1_max_0 = other._exponent;
    var newExponent = (tmp0_max_0.compareTo_75(tmp1_max_0) >= 0 ? tmp0_max_0 : tmp1_max_0).plus_71(borrowDetected);
    if (this._usingScale) {
      var tmp_0 = Companion_getInstance_11();
      return roundOrDont(tmp_0, newSignificand, newExponent, resolvedDecimalMode.copy$default_0(newSignificandNumOfDigit, null, new Long(0, 0), 6, null));
    } else {
      return roundOrDont(Companion_getInstance_11(), newSignificand, newExponent, resolvedDecimalMode);
    }
  };
  BigDecimal.prototype.multiply_1 = function (other) {
    return this.multiply_3(other, computeMode(this, other, ScaleOps_Max_getInstance()));
  };
  BigDecimal.prototype.multiply_10 = function (other) {
    return this.multiply_1(other instanceof BigDecimal ? other : THROW_CCE());
  };
  BigDecimal.prototype.multiply_3 = function (other, decimalMode) {
    var resolvedDecimalMode = resolveDecimalMode(Companion_getInstance_11(), this._decimalMode, other._decimalMode, decimalMode);
    var firstNumOfDigits = this._significand.numberOfDecimalDigits();
    var secondNumOfDigits = other._significand.numberOfDecimalDigits();
    var newSignificand = this._significand.times_0(other._significand);
    var newSignificandNumOfDigit = newSignificand.numberOfDecimalDigits();
    var moveExponent = newSignificandNumOfDigit.minus_40(firstNumOfDigits.plus_71(secondNumOfDigits));
    var tmp0_plus_0 = this._exponent.plus_71(other._exponent).plus_71(moveExponent);
    var tmp1_plus_0 = 1;
    var newExponent = tmp0_plus_0.plus_71(toLong_0(tmp1_plus_0));
    var tmp;
    if (resolvedDecimalMode._usingScale_0) {
      var tmp_0 = Companion_getInstance_11();
      tmp = roundOrDont(tmp_0, newSignificand, newExponent, resolvedDecimalMode.copy$default_0(newSignificandNumOfDigit, null, new Long(0, 0), 6, null));
    } else {
      tmp = roundOrDont(Companion_getInstance_11(), newSignificand, newExponent, resolvedDecimalMode);
    }
    return tmp;
  };
  BigDecimal.prototype.divide_0 = function (other) {
    return this.divide_2(other, computeMode(this, other, ScaleOps_Max_getInstance()));
  };
  BigDecimal.prototype.divide_9 = function (other) {
    return this.divide_0(other instanceof BigDecimal ? other : THROW_CCE());
  };
  BigDecimal.prototype.divide_2 = function (other, decimalMode) {
    var resolvedDecimalMode = resolveDecimalMode(Companion_getInstance_11(), this._decimalMode, other._decimalMode, decimalMode);
    if (resolvedDecimalMode._isPrecisionUnlimited) {
      var newExponent = this._exponent.minus_40(other._exponent);
      var tmp0_times_0 = other._precision;
      var tmp1_times_0 = 2;
      var tmp2_plus_0 = tmp0_times_0.times_40(toLong_0(tmp1_times_0));
      var tmp3_plus_0 = 6;
      var power = tmp2_plus_0.plus_71(toLong_0(tmp3_plus_0));
      var thisPrepared = this._significand.times_0(Companion_getInstance_13()._TEN_0.pow_0(power));
      var divRem = thisPrepared.divrem(other._significand);
      var result = divRem._quotient;
      var tmp4_minus_0 = other._precision;
      var tmp5_minus_0 = 1;
      var expectedDiff = tmp4_minus_0.minus_40(toLong_0(tmp5_minus_0));
      var exponentModifier = expectedDiff.plus_71(result.numberOfDecimalDigits().minus_40(thisPrepared.numberOfDecimalDigits()));
      if (!divRem._remainder.equals(Companion_getInstance_13()._ZERO_0)) {
        throw ArithmeticException_init_$Create$('Non-terminating result of division operation (i.e. 1/3 = 0.3333... library needs to know when to stop and how to round up at that point). Specify decimalPrecision inside your decimal mode.');
      }return new BigDecimal(result, newExponent.plus_71(exponentModifier), resolvedDecimalMode);
    } else {
      var tmp6_minus_0 = this._exponent.minus_40(other._exponent);
      var tmp7_minus_0 = 1;
      var newExponent_0 = tmp6_minus_0.minus_40(toLong_0(tmp7_minus_0));
      var desiredPrecision = resolvedDecimalMode._decimalPrecision;
      var power_0 = desiredPrecision.minus_40(this._precision).plus_71(other._precision);
      var tmp;
      if (power_0.compareTo_75(new Long(0, 0)) > 0) {
        tmp = this._significand.times_0(toBigInteger(10).pow_0(power_0));
      } else if (power_0.compareTo_75(new Long(0, 0)) < 0) {
        var tmp_0 = toBigInteger(10);
        tmp = this._significand.div_1(tmp_0.pow_0(abs(power_0)));
      } else {
        tmp = this._significand;
      }
      var thisPrepared_0 = tmp;
      var divRem_0 = thisPrepared_0.divrem(other._significand);
      var result_0 = divRem_0._quotient;
      if (result_0.equals(Companion_getInstance_13()._ZERO_0)) {
        var tmp0 = newExponent_0;
        newExponent_0 = tmp0.dec_5();
        Unit_getInstance();
      }var exponentModifier_0 = result_0.numberOfDecimalDigits().minus_40(resolvedDecimalMode._decimalPrecision);
      var tmp_1;
      if (this._usingScale) {
        var tmp_2 = roundDiscarded(Companion_getInstance_11(), result_0, divRem_0._remainder, resolvedDecimalMode);
        var tmp_3 = newExponent_0.plus_71(exponentModifier_0);
        var tmp_4 = result_0.numberOfDecimalDigits();
        tmp_1 = new BigDecimal(tmp_2, tmp_3, resolvedDecimalMode.copy$default_0(tmp_4, null, new Long(0, 0), 6, null));
      } else {
        tmp_1 = new BigDecimal(roundDiscarded(Companion_getInstance_11(), result_0, divRem_0._remainder, resolvedDecimalMode), newExponent_0.plus_71(exponentModifier_0), resolvedDecimalMode);
      }
      return tmp_1;
    }
  };
  BigDecimal.prototype.remainder_0 = function (other) {
    return this.divideAndRemainder(other)._second;
  };
  BigDecimal.prototype.remainder_5 = function (other) {
    return this.remainder_0(other instanceof BigDecimal ? other : THROW_CCE());
  };
  BigDecimal.prototype.divideAndRemainder = function (other) {
    var tmp0_elvis_lhs = this._decimalMode;
    var tmp;
    if (tmp0_elvis_lhs == null) {
      var tmp0_plus_0 = this._exponent;
      var tmp1_plus_0 = 1;
      var tmp_0 = tmp0_plus_0.plus_71(toLong_0(tmp1_plus_0));
      var tmp_1 = RoundingMode_FLOOR_getInstance();
      tmp = DecimalMode_init_$Create$(tmp_0, tmp_1, new Long(0, 0), 4, null);
    } else {
      tmp = tmp0_elvis_lhs;
    }
    var resolvedRoundingMode = tmp;
    var quotient = this.divide_2(other, resolvedRoundingMode);
    var quotientInfinitePrecision = quotient.copy$default(null, new Long(0, 0), Companion_getInstance_12()._DEFAULT, 3, null);
    var remainder = this.minus_4(quotientInfinitePrecision.times_2(other));
    return new Pair(quotient, remainder);
  };
  BigDecimal.prototype.isZero_0 = function () {
    return this._significand.isZero_0();
  };
  BigDecimal.prototype.copy = function (significand, exponent, decimalMode) {
    return new BigDecimal(significand, exponent, decimalMode);
  };
  BigDecimal.prototype.copy$default = function (significand, exponent, decimalMode, $mask0, $handler) {
    if (!(($mask0 & 1) === 0))
      significand = this._significand;
    if (!(($mask0 & 2) === 0))
      exponent = this._exponent;
    if (!(($mask0 & 4) === 0))
      decimalMode = this._decimalMode;
    return this.copy(significand, exponent, decimalMode);
  };
  BigDecimal.prototype.moveDecimalPoint = function (places) {
    if (places.equals(new Long(0, 0))) {
      return this;
    }var tmp = this._exponent.plus_71(places);
    return this.copy$default(null, tmp, null, 5, null);
  };
  BigDecimal.prototype.floor_0 = function () {
    var tmp0_plus_0 = this._exponent;
    var tmp1_plus_0 = 1;
    var tmp = tmp0_plus_0.plus_71(toLong_0(tmp1_plus_0));
    var tmp_0 = RoundingMode_FLOOR_getInstance();
    return this.roundSignificand(DecimalMode_init_$Create$(tmp, tmp_0, new Long(0, 0), 4, null));
  };
  BigDecimal.prototype.toBigInteger = function () {
    if (this._exponent.compareTo_75(new Long(0, 0)) < 0) {
      return Companion_getInstance_13()._ZERO_0;
    }var precisionExponentDiff = this._exponent.minus_40(this._precision);
    var tmp;
    if (precisionExponentDiff.compareTo_75(new Long(0, 0)) > 0) {
      var tmp_0 = toBigInteger(10);
      var tmp0_plus_0 = 1;
      tmp = this._significand.times_0(tmp_0.pow_0(precisionExponentDiff.plus_71(toLong_0(tmp0_plus_0))));
    } else if (precisionExponentDiff.compareTo_75(new Long(0, 0)) < 0) {
      var tmp_1 = toBigInteger(10);
      var tmp1_minus_0 = abs(precisionExponentDiff);
      var tmp2_minus_0 = 1;
      tmp = this._significand.div_1(tmp_1.pow_0(tmp1_minus_0.minus_40(toLong_0(tmp2_minus_0))));
    } else {
      tmp = this._significand;
    }
    return tmp;
  };
  BigDecimal.prototype.minus_4 = function (other) {
    return this.subtract_2(other, computeMode(this, other, ScaleOps_Max_getInstance()));
  };
  BigDecimal.prototype.times_2 = function (other) {
    return this.multiply_3(other, computeMode(this, other, ScaleOps_Max_getInstance()));
  };
  BigDecimal.prototype.signum_2 = function () {
    return this._significand.signum_2();
  };
  BigDecimal.prototype.roundSignificand = function (decimalMode) {
    if (decimalMode == null) {
      return this;
    }return roundSignificand(Companion_getInstance_11(), this._significand, this._exponent, decimalMode);
  };
  BigDecimal.prototype.compare_0 = function (other) {
    var tmp;
    if (this._exponent.equals(other._exponent) ? this._precision.equals(other._precision) : false) {
      tmp = this._significand.compare_1(other._significand);
    } else {
      var tmp0_container = bringSignificandToSameExponent(this, this, other);
      var preparedFirst = tmp0_container.component1_4();
      var preparedSecond = tmp0_container.component2_4();
      tmp = preparedFirst.compare_1(preparedSecond);
    }
    return tmp;
  };
  BigDecimal.prototype.compareTo_26 = function (other) {
    if (isNumber(other)) {
      if (ComparisonWorkaround_getInstance().isSpecialHandlingForFloatNeeded(other)) {
        return javascriptNumberComparison(this, other);
      }} else {
    }
    var tmp0_subject = other;
    var tmp;
    if (tmp0_subject instanceof BigDecimal) {
      tmp = this.compare_0(other);
    } else {
      if (tmp0_subject instanceof Long) {
        tmp = this.compare_0(Companion_getInstance_11().fromLong_5(other));
      } else {
        if (typeof tmp0_subject === 'number') {
          tmp = this.compare_0(Companion_getInstance_11().fromInt_5(other));
        } else {
          if (typeof tmp0_subject === 'number') {
            tmp = this.compare_0(Companion_getInstance_11().fromShort_5(other));
          } else {
            if (typeof tmp0_subject === 'number') {
              tmp = this.compare_0(Companion_getInstance_11().fromByte_5(other));
            } else {
              if (typeof tmp0_subject === 'number') {
                var tmp_0 = Companion_getInstance_11();
                var tmp_1 = other;
                tmp = this.compare_0(tmp_0.fromDouble$default(tmp_1, null, 2, null));
              } else {
                if (typeof tmp0_subject === 'number') {
                  var tmp_2 = Companion_getInstance_11();
                  var tmp_3 = other;
                  tmp = this.compare_0(tmp_2.fromFloat$default(tmp_3, null, 2, null));
                } else {
                  {
                    throw RuntimeException_init_$Create$('' + 'Invalid comparison type for BigDecimal: ' + getKClassFromExpression_0(other)._get_simpleName__4());
                  }
                }
              }
            }
          }
        }
      }
    }
    return tmp;
  };
  BigDecimal.prototype.compareTo_34 = function (other) {
    return this.compareTo_26(isObject(other) ? other : THROW_CCE());
  };
  BigDecimal.prototype.equals = function (other) {
    var tmp0_subject = other;
    var tmp;
    if (tmp0_subject instanceof BigDecimal) {
      tmp = this.compare_0(other);
    } else {
      if (tmp0_subject instanceof Long) {
        tmp = this.compare_0(Companion_getInstance_11().fromLong_5(other));
      } else {
        if (!(tmp0_subject == null) ? typeof tmp0_subject === 'number' : false) {
          tmp = this.compare_0(Companion_getInstance_11().fromInt_5(other));
        } else {
          if (!(tmp0_subject == null) ? typeof tmp0_subject === 'number' : false) {
            tmp = this.compare_0(Companion_getInstance_11().fromShort_5(other));
          } else {
            if (!(tmp0_subject == null) ? typeof tmp0_subject === 'number' : false) {
              tmp = this.compare_0(Companion_getInstance_11().fromByte_5(other));
            } else {
              if (!(tmp0_subject == null) ? typeof tmp0_subject === 'number' : false) {
                var tmp_0 = Companion_getInstance_11();
                var tmp_1 = other;
                tmp = this.compare_0(tmp_0.fromDouble$default(tmp_1, null, 2, null));
              } else {
                if (!(tmp0_subject == null) ? typeof tmp0_subject === 'number' : false) {
                  var tmp_2 = Companion_getInstance_11();
                  var tmp_3 = other;
                  tmp = this.compare_0(tmp_2.fromFloat$default(tmp_3, null, 2, null));
                } else {
                  {
                    tmp = -1;
                  }
                }
              }
            }
          }
        }
      }
    }
    var comparison = tmp;
    return comparison === 0;
  };
  BigDecimal.prototype.hashCode = function () {
    if (this.equals(Companion_getInstance_11()._ZERO)) {
      return 0;
    }return removeTrailingZeroes(this, this)._significand.hashCode() + this._exponent.hashCode() | 0;
  };
  BigDecimal.prototype.toString = function () {
    if (Companion_getInstance_11()._useToStringExpanded) {
      return this.toStringExpanded();
    }var significandString = this._significand.toString_1(10);
    var tmp;
    if (this._significand.compareTo_26(0) < 0) {
      tmp = 2;
    } else {
      tmp = 1;
    }
    var modifier = tmp;
    var tmp_0;
    var tmp$ret$0;
    l$ret$1: do {
      var tmp0_dropLastWhile_0 = this._significand.toString();
      var inductionVariable = _get_lastIndex__3(tmp0_dropLastWhile_0);
      if (0 <= inductionVariable)
        do {
          var index_2 = inductionVariable;
          inductionVariable = inductionVariable + -1 | 0;
          var tmp1__anonymous__5 = charSequenceGet(tmp0_dropLastWhile_0, index_2);
          if (!tmp1__anonymous__5.equals(new Char(48))) {
            var tmp0_substring_0_3 = 0;
            var tmp1_substring_0_4 = index_2 + 1 | 0;
            tmp$ret$0 = tmp0_dropLastWhile_0.substring(tmp0_substring_0_3, tmp1_substring_0_4);
            break l$ret$1;
          } else {
          }
        }
         while (0 <= inductionVariable);
      tmp$ret$0 = '';
    }
     while (false);
    if (tmp$ret$0.length <= 1) {
      tmp_0 = '0';
    } else {
      {
        tmp_0 = '';
      }
    }
    var expand = tmp_0;
    var tmp_1;
    if (this._exponent.compareTo_75(new Long(0, 0)) > 0) {
      tmp_1 = '' + placeADotInString(this, significandString, significandString.length - modifier | 0) + expand + 'E+' + this._exponent;
    } else if (this._exponent.compareTo_75(new Long(0, 0)) < 0) {
      tmp_1 = '' + placeADotInString(this, significandString, significandString.length - modifier | 0) + expand + 'E' + this._exponent;
    } else if (this._exponent.equals(new Long(0, 0))) {
      tmp_1 = '' + placeADotInString(this, significandString, significandString.length - modifier | 0) + expand;
    } else {
      throw RuntimeException_init_$Create$('Invalid state, please report a bug (Integer compareTo invalid)');
    }
    return tmp_1;
  };
  BigDecimal.prototype.toStringExpanded = function () {
    if (this.equals(Companion_getInstance_11()._ZERO)) {
      return '0';
    }var digits = this._significand.numberOfDecimalDigits();
    if (this._exponent.compareTo_75(toLong_0(IntCompanionObject_getInstance()._MAX_VALUE_2)) > 0) {
      throw RuntimeException_init_$Create$('Invalid toStringExpanded request (exponent > Int.MAX_VALUE)');
    }var significandString = this._significand.toStringWithoutSign(10);
    var tmp;
    if (this._significand._sign.equals(Sign_NEGATIVE_getInstance())) {
      tmp = '-';
    } else {
      tmp = '';
    }
    var sign = tmp;
    var tmp_0;
    if (this._exponent.compareTo_75(new Long(0, 0)) > 0) {
      var tmp0_plus_0 = this._exponent.minus_40(digits);
      var tmp1_plus_0 = 1;
      var diffBigInt = tmp0_plus_0.plus_71(toLong_0(tmp1_plus_0));
      var tmp_1;
      if (diffBigInt.compareTo_75(new Long(0, 0)) > 0) {
        var expandZeros = this.times_3(diffBigInt, new Char(48));
        tmp_1 = significandString + expandZeros;
      } else {
        tmp_1 = placeADotInStringExpanded(this, significandString, (significandString.length - this._exponent.toInt_5() | 0) - 1 | 0);
      }
      tmp_0 = tmp_1;
    } else if (this._exponent.compareTo_75(new Long(0, 0)) < 0) {
      var tmp2__get_absoluteValue__0 = this._exponent.toInt_5();
      var diffInt = abs_0(tmp2__get_absoluteValue__0);
      var tmp_2;
      if (diffInt > 0) {
        var tmp3__get_absoluteValue__0 = this._exponent;
        var expandZeros_0 = this.times_3(abs(tmp3__get_absoluteValue__0), new Char(48));
        tmp_2 = placeADotInStringExpanded(this, expandZeros_0 + significandString, (diffInt + significandString.length | 0) - 1 | 0);
      } else {
        tmp_2 = placeADotInStringExpanded(this, significandString, significandString.length - 1 | 0);
      }
      tmp_0 = tmp_2;
    } else if (this._exponent.equals(new Long(0, 0))) {
      if (digits.equals(new Long(1, 0))) {
        return sign + significandString;
      }tmp_0 = placeADotInStringExpanded(this, significandString, significandString.length - 1 | 0);
    } else {
      throw RuntimeException_init_$Create$('Invalid state, please report a bug (Integer compareTo invalid)');
    }
    var adjusted = tmp_0;
    return sign + adjusted;
  };
  BigDecimal.prototype.times_3 = function (_this__0, char) {
    if (_this__0.compareTo_75(new Long(0, 0)) < 0) {
      throw RuntimeException_init_$Create$('Char cannot be multiplied with negative number');
    }var counter_0 = _this__0;
    var stringBuilder = StringBuilder_init_$Create$_1();
    while (counter_0.compareTo_75(new Long(0, 0)) > 0) {
      stringBuilder.append_2(char);
      Unit_getInstance();
      var tmp0 = counter_0;
      counter_0 = tmp0.dec_5();
      Unit_getInstance();
    }
    return stringBuilder.toString();
  };
  BigDecimal.$metadata$ = {
    simpleName: 'BigDecimal',
    kind: 'class',
    interfaces: [BigNumber, CommonBigNumberOperations, NarrowingOperations, Comparable]
  };
  function toBigDecimal(_this_, exponentModifier, decimalMode) {
    var tmp = Companion_getInstance_11().fromFloat(_this_, decimalMode);
    var tmp0_elvis_lhs = exponentModifier;
    return tmp.moveDecimalPoint(tmp0_elvis_lhs == null ? new Long(0, 0) : tmp0_elvis_lhs);
  }
  function toBigDecimal$default(_this_, exponentModifier, decimalMode, $mask0, $handler) {
    if (!(($mask0 & 1) === 0))
      exponentModifier = null;
    if (!(($mask0 & 2) === 0))
      decimalMode = null;
    return toBigDecimal(_this_, exponentModifier, decimalMode);
  }
  function DecimalMode_init_$Init$(decimalPrecision, roundingMode, scale, $mask0, $marker, $this) {
    if (!(($mask0 & 1) === 0))
      decimalPrecision = new Long(0, 0);
    if (!(($mask0 & 2) === 0))
      roundingMode = RoundingMode_NONE_getInstance();
    if (!(($mask0 & 4) === 0))
      scale = new Long(-1, -1);
    DecimalMode.call($this, decimalPrecision, roundingMode, scale);
    return $this;
  }
  function DecimalMode_init_$Create$(decimalPrecision, roundingMode, scale, $mask0, $marker) {
    return DecimalMode_init_$Init$(decimalPrecision, roundingMode, scale, $mask0, $marker, Object.create(DecimalMode.prototype));
  }
  function Companion_13() {
    Companion_instance_12 = this;
    var tmp = this;
    var tmp_0 = new Long(0, 0);
    tmp._DEFAULT = DecimalMode_init_$Create$(tmp_0, null, new Long(0, 0), 7, null);
    this._US_CURRENCY = new DecimalMode(new Long(30, 0), RoundingMode_ROUND_HALF_AWAY_FROM_ZERO_getInstance(), new Long(2, 0));
  }
  Companion_13.$metadata$ = {
    simpleName: 'Companion',
    kind: 'object',
    interfaces: []
  };
  var Companion_instance_12;
  function Companion_getInstance_12() {
    if (Companion_instance_12 == null)
      new Companion_13();
    return Companion_instance_12;
  }
  function DecimalMode(decimalPrecision, roundingMode, scale) {
    Companion_getInstance_12();
    this._decimalPrecision = decimalPrecision;
    this._roundingMode_0 = roundingMode;
    this._scale_1 = scale;
    this._isPrecisionUnlimited = this._decimalPrecision.equals(new Long(0, 0));
    this._usingScale_0 = this._scale_1.compareTo_75(new Long(0, 0)) >= 0;
    if ((!this._usingScale_0 ? this._decimalPrecision.equals(new Long(0, 0)) : false) ? !this._roundingMode_0.equals(RoundingMode_NONE_getInstance()) : false) {
      throw ArithmeticException_init_$Create$('Rounding mode with 0 digits precision.');
    }if (this._scale_1.compareTo_75(new Long(-1, -1)) < 0) {
      throw ArithmeticException_init_$Create$('Negative Scale is unsupported.');
    }if (this._usingScale_0 ? this._roundingMode_0.equals(RoundingMode_NONE_getInstance()) : false) {
      throw ArithmeticException_init_$Create$('' + 'Scale of ' + this._scale_1 + ' digits to the right of the decimal requires a RoundingMode that is not NONE.');
    }}
  DecimalMode.prototype.copy_0 = function (decimalPrecision, roundingMode, scale) {
    return new DecimalMode(decimalPrecision, roundingMode, scale);
  };
  DecimalMode.prototype.copy$default_0 = function (decimalPrecision, roundingMode, scale, $mask0, $handler) {
    if (!(($mask0 & 1) === 0))
      decimalPrecision = this._decimalPrecision;
    if (!(($mask0 & 2) === 0))
      roundingMode = this._roundingMode_0;
    if (!(($mask0 & 4) === 0))
      scale = this._scale_1;
    return this.copy_0(decimalPrecision, roundingMode, scale);
  };
  DecimalMode.prototype.toString = function () {
    return '' + 'DecimalMode(decimalPrecision=' + this._decimalPrecision + ', roundingMode=' + this._roundingMode_0 + ', scale=' + this._scale_1 + ')';
  };
  DecimalMode.prototype.hashCode = function () {
    var result = this._decimalPrecision.hashCode();
    result = imul(result, 31) + this._roundingMode_0.hashCode() | 0;
    result = imul(result, 31) + this._scale_1.hashCode() | 0;
    return result;
  };
  DecimalMode.prototype.equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof DecimalMode))
      return false;
    else {
    }
    var tmp0_other_with_cast = other instanceof DecimalMode ? other : THROW_CCE();
    if (!this._decimalPrecision.equals(tmp0_other_with_cast._decimalPrecision))
      return false;
    if (!this._roundingMode_0.equals(tmp0_other_with_cast._roundingMode_0))
      return false;
    if (!this._scale_1.equals(tmp0_other_with_cast._scale_1))
      return false;
    return true;
  };
  DecimalMode.$metadata$ = {
    simpleName: 'DecimalMode',
    kind: 'class',
    interfaces: []
  };
  var RoundingMode_FLOOR_instance;
  var RoundingMode_CEILING_instance;
  var RoundingMode_AWAY_FROM_ZERO_instance;
  var RoundingMode_TOWARDS_ZERO_instance;
  var RoundingMode_NONE_instance;
  var RoundingMode_ROUND_HALF_AWAY_FROM_ZERO_instance;
  var RoundingMode_ROUND_HALF_TOWARDS_ZERO_instance;
  var RoundingMode_ROUND_HALF_CEILING_instance;
  var RoundingMode_ROUND_HALF_FLOOR_instance;
  var RoundingMode_ROUND_HALF_TO_EVEN_instance;
  var RoundingMode_ROUND_HALF_TO_ODD_instance;
  var RoundingMode_entriesInitialized;
  function RoundingMode_initEntries() {
    if (RoundingMode_entriesInitialized)
      return Unit_getInstance();
    RoundingMode_entriesInitialized = true;
    RoundingMode_FLOOR_instance = new RoundingMode('FLOOR', 0);
    RoundingMode_CEILING_instance = new RoundingMode('CEILING', 1);
    RoundingMode_AWAY_FROM_ZERO_instance = new RoundingMode('AWAY_FROM_ZERO', 2);
    RoundingMode_TOWARDS_ZERO_instance = new RoundingMode('TOWARDS_ZERO', 3);
    RoundingMode_NONE_instance = new RoundingMode('NONE', 4);
    RoundingMode_ROUND_HALF_AWAY_FROM_ZERO_instance = new RoundingMode('ROUND_HALF_AWAY_FROM_ZERO', 5);
    RoundingMode_ROUND_HALF_TOWARDS_ZERO_instance = new RoundingMode('ROUND_HALF_TOWARDS_ZERO', 6);
    RoundingMode_ROUND_HALF_CEILING_instance = new RoundingMode('ROUND_HALF_CEILING', 7);
    RoundingMode_ROUND_HALF_FLOOR_instance = new RoundingMode('ROUND_HALF_FLOOR', 8);
    RoundingMode_ROUND_HALF_TO_EVEN_instance = new RoundingMode('ROUND_HALF_TO_EVEN', 9);
    RoundingMode_ROUND_HALF_TO_ODD_instance = new RoundingMode('ROUND_HALF_TO_ODD', 10);
  }
  function RoundingMode(name, ordinal) {
    Enum.call(this, name, ordinal);
  }
  RoundingMode.$metadata$ = {
    simpleName: 'RoundingMode',
    kind: 'class',
    interfaces: []
  };
  function RoundingMode_FLOOR_getInstance() {
    RoundingMode_initEntries();
    return RoundingMode_FLOOR_instance;
  }
  function RoundingMode_CEILING_getInstance() {
    RoundingMode_initEntries();
    return RoundingMode_CEILING_instance;
  }
  function RoundingMode_AWAY_FROM_ZERO_getInstance() {
    RoundingMode_initEntries();
    return RoundingMode_AWAY_FROM_ZERO_instance;
  }
  function RoundingMode_TOWARDS_ZERO_getInstance() {
    RoundingMode_initEntries();
    return RoundingMode_TOWARDS_ZERO_instance;
  }
  function RoundingMode_NONE_getInstance() {
    RoundingMode_initEntries();
    return RoundingMode_NONE_instance;
  }
  function RoundingMode_ROUND_HALF_AWAY_FROM_ZERO_getInstance() {
    RoundingMode_initEntries();
    return RoundingMode_ROUND_HALF_AWAY_FROM_ZERO_instance;
  }
  function RoundingMode_ROUND_HALF_TOWARDS_ZERO_getInstance() {
    RoundingMode_initEntries();
    return RoundingMode_ROUND_HALF_TOWARDS_ZERO_instance;
  }
  function RoundingMode_ROUND_HALF_CEILING_getInstance() {
    RoundingMode_initEntries();
    return RoundingMode_ROUND_HALF_CEILING_instance;
  }
  function RoundingMode_ROUND_HALF_FLOOR_getInstance() {
    RoundingMode_initEntries();
    return RoundingMode_ROUND_HALF_FLOOR_instance;
  }
  function RoundingMode_ROUND_HALF_TO_EVEN_getInstance() {
    RoundingMode_initEntries();
    return RoundingMode_ROUND_HALF_TO_EVEN_instance;
  }
  function RoundingMode_ROUND_HALF_TO_ODD_getInstance() {
    RoundingMode_initEntries();
    return RoundingMode_ROUND_HALF_TO_ODD_instance;
  }
  function BigInteger_init_$Init$(long, $this) {
    var tmp = Companion_getInstance_13()._arithmetic.fromLong_4(long);
    var tmp0_determinSignFromNumber_0 = Companion_getInstance_13();
    var tmp0_subject_1 = getKClass_0(Long);
    var tmp_0;
    if (tmp0_subject_1.equals(getKClass_0(Long))) {
      if (long instanceof Long)
        long;
      else
        THROW_CCE();
      Unit_getInstance();
      tmp_0 = long.compareTo_75(new Long(0, 0)) < 0 ? Sign_NEGATIVE_getInstance() : long.compareTo_75(new Long(0, 0)) > 0 ? Sign_POSITIVE_getInstance() : Sign_ZERO_getInstance();
    } else if (tmp0_subject_1.equals(PrimitiveClasses_getInstance()._get_intClass_())) {
      if (typeof long === 'number')
        long;
      else
        THROW_CCE();
      Unit_getInstance();
      tmp_0 = long < 0 ? Sign_NEGATIVE_getInstance() : long > 0 ? Sign_POSITIVE_getInstance() : Sign_ZERO_getInstance();
    } else if (tmp0_subject_1.equals(PrimitiveClasses_getInstance()._get_shortClass_())) {
      if (typeof long === 'number')
        long;
      else
        THROW_CCE();
      Unit_getInstance();
      tmp_0 = long < 0 ? Sign_NEGATIVE_getInstance() : long > 0 ? Sign_POSITIVE_getInstance() : Sign_ZERO_getInstance();
    } else if (tmp0_subject_1.equals(PrimitiveClasses_getInstance()._get_byteClass_())) {
      if (typeof long === 'number')
        long;
      else
        THROW_CCE();
      Unit_getInstance();
      tmp_0 = long < 0 ? Sign_NEGATIVE_getInstance() : long > 0 ? Sign_POSITIVE_getInstance() : Sign_ZERO_getInstance();
    } else {
      throw RuntimeException_init_$Create$('' + 'Unsupported type ' + getKClass_0(Long));
    }
    BigInteger.call($this, tmp, tmp_0);
    return $this;
  }
  function BigInteger_init_$Create$(long) {
    return BigInteger_init_$Init$(long, Object.create(BigInteger.prototype));
  }
  function BigInteger_init_$Init$_0(int, $this) {
    var tmp = Companion_getInstance_13()._arithmetic.fromInt_4(int);
    var tmp0_determinSignFromNumber_0 = Companion_getInstance_13();
    var tmp0_subject_1 = PrimitiveClasses_getInstance()._get_intClass_();
    var tmp_0;
    if (tmp0_subject_1.equals(getKClass_0(Long))) {
      if (int instanceof Long)
        int;
      else
        THROW_CCE();
      Unit_getInstance();
      tmp_0 = int.compareTo_75(new Long(0, 0)) < 0 ? Sign_NEGATIVE_getInstance() : int.compareTo_75(new Long(0, 0)) > 0 ? Sign_POSITIVE_getInstance() : Sign_ZERO_getInstance();
    } else if (tmp0_subject_1.equals(PrimitiveClasses_getInstance()._get_intClass_())) {
      if (typeof int === 'number')
        int;
      else
        THROW_CCE();
      Unit_getInstance();
      tmp_0 = int < 0 ? Sign_NEGATIVE_getInstance() : int > 0 ? Sign_POSITIVE_getInstance() : Sign_ZERO_getInstance();
    } else if (tmp0_subject_1.equals(PrimitiveClasses_getInstance()._get_shortClass_())) {
      if (typeof int === 'number')
        int;
      else
        THROW_CCE();
      Unit_getInstance();
      tmp_0 = int < 0 ? Sign_NEGATIVE_getInstance() : int > 0 ? Sign_POSITIVE_getInstance() : Sign_ZERO_getInstance();
    } else if (tmp0_subject_1.equals(PrimitiveClasses_getInstance()._get_byteClass_())) {
      if (typeof int === 'number')
        int;
      else
        THROW_CCE();
      Unit_getInstance();
      tmp_0 = int < 0 ? Sign_NEGATIVE_getInstance() : int > 0 ? Sign_POSITIVE_getInstance() : Sign_ZERO_getInstance();
    } else {
      throw RuntimeException_init_$Create$('' + 'Unsupported type ' + PrimitiveClasses_getInstance()._get_intClass_());
    }
    BigInteger.call($this, tmp, tmp_0);
    return $this;
  }
  function BigInteger_init_$Create$_0(int) {
    return BigInteger_init_$Init$_0(int, Object.create(BigInteger.prototype));
  }
  function BigInteger_init_$Init$_1(short, $this) {
    var tmp = Companion_getInstance_13()._arithmetic.fromShort_4(short);
    var tmp0_determinSignFromNumber_0 = Companion_getInstance_13();
    var tmp0_subject_1 = PrimitiveClasses_getInstance()._get_shortClass_();
    var tmp_0;
    if (tmp0_subject_1.equals(getKClass_0(Long))) {
      if (short instanceof Long)
        short;
      else
        THROW_CCE();
      Unit_getInstance();
      tmp_0 = short.compareTo_75(new Long(0, 0)) < 0 ? Sign_NEGATIVE_getInstance() : short.compareTo_75(new Long(0, 0)) > 0 ? Sign_POSITIVE_getInstance() : Sign_ZERO_getInstance();
    } else if (tmp0_subject_1.equals(PrimitiveClasses_getInstance()._get_intClass_())) {
      if (typeof short === 'number')
        short;
      else
        THROW_CCE();
      Unit_getInstance();
      tmp_0 = short < 0 ? Sign_NEGATIVE_getInstance() : short > 0 ? Sign_POSITIVE_getInstance() : Sign_ZERO_getInstance();
    } else if (tmp0_subject_1.equals(PrimitiveClasses_getInstance()._get_shortClass_())) {
      if (typeof short === 'number')
        short;
      else
        THROW_CCE();
      Unit_getInstance();
      tmp_0 = short < 0 ? Sign_NEGATIVE_getInstance() : short > 0 ? Sign_POSITIVE_getInstance() : Sign_ZERO_getInstance();
    } else if (tmp0_subject_1.equals(PrimitiveClasses_getInstance()._get_byteClass_())) {
      if (typeof short === 'number')
        short;
      else
        THROW_CCE();
      Unit_getInstance();
      tmp_0 = short < 0 ? Sign_NEGATIVE_getInstance() : short > 0 ? Sign_POSITIVE_getInstance() : Sign_ZERO_getInstance();
    } else {
      throw RuntimeException_init_$Create$('' + 'Unsupported type ' + PrimitiveClasses_getInstance()._get_shortClass_());
    }
    BigInteger.call($this, tmp, tmp_0);
    return $this;
  }
  function BigInteger_init_$Create$_1(short) {
    return BigInteger_init_$Init$_1(short, Object.create(BigInteger.prototype));
  }
  function BigInteger_init_$Init$_2(byte, $this) {
    var tmp = Companion_getInstance_13()._arithmetic.fromByte_4(byte);
    var tmp0_determinSignFromNumber_0 = Companion_getInstance_13();
    var tmp0_subject_1 = PrimitiveClasses_getInstance()._get_byteClass_();
    var tmp_0;
    if (tmp0_subject_1.equals(getKClass_0(Long))) {
      if (byte instanceof Long)
        byte;
      else
        THROW_CCE();
      Unit_getInstance();
      tmp_0 = byte.compareTo_75(new Long(0, 0)) < 0 ? Sign_NEGATIVE_getInstance() : byte.compareTo_75(new Long(0, 0)) > 0 ? Sign_POSITIVE_getInstance() : Sign_ZERO_getInstance();
    } else if (tmp0_subject_1.equals(PrimitiveClasses_getInstance()._get_intClass_())) {
      if (typeof byte === 'number')
        byte;
      else
        THROW_CCE();
      Unit_getInstance();
      tmp_0 = byte < 0 ? Sign_NEGATIVE_getInstance() : byte > 0 ? Sign_POSITIVE_getInstance() : Sign_ZERO_getInstance();
    } else if (tmp0_subject_1.equals(PrimitiveClasses_getInstance()._get_shortClass_())) {
      if (typeof byte === 'number')
        byte;
      else
        THROW_CCE();
      Unit_getInstance();
      tmp_0 = byte < 0 ? Sign_NEGATIVE_getInstance() : byte > 0 ? Sign_POSITIVE_getInstance() : Sign_ZERO_getInstance();
    } else if (tmp0_subject_1.equals(PrimitiveClasses_getInstance()._get_byteClass_())) {
      if (typeof byte === 'number')
        byte;
      else
        THROW_CCE();
      Unit_getInstance();
      tmp_0 = byte < 0 ? Sign_NEGATIVE_getInstance() : byte > 0 ? Sign_POSITIVE_getInstance() : Sign_ZERO_getInstance();
    } else {
      throw RuntimeException_init_$Create$('' + 'Unsupported type ' + PrimitiveClasses_getInstance()._get_byteClass_());
    }
    BigInteger.call($this, tmp, tmp_0);
    return $this;
  }
  function BigInteger_init_$Create$_2(byte) {
    return BigInteger_init_$Init$_2(byte, Object.create(BigInteger.prototype));
  }
  function Companion_14() {
    Companion_instance_13 = this;
    this._arithmetic = chosenArithmetic;
    this._ZERO_0 = new BigInteger(this._arithmetic._get_ZERO__3(), Sign_ZERO_getInstance());
    this._ONE_0 = new BigInteger(this._arithmetic._get_ONE__0(), Sign_POSITIVE_getInstance());
    this._TWO_0 = new BigInteger(this._arithmetic._get_TWO__0(), Sign_POSITIVE_getInstance());
    this._TEN_0 = new BigInteger(this._arithmetic._get_TEN__0(), Sign_POSITIVE_getInstance());
    var tmp = this;
    var tmp0_log10_0 = 2.0;
    tmp._LOG_10_OF_2 = Math.log10(tmp0_log10_0);
  }
  Companion_14.prototype._get_ZERO__4 = function () {
    return this._ZERO_0;
  };
  Companion_14.prototype.parseString_0 = function (string, base) {
    if (base < 2 ? true : base > 36) {
      throw NumberFormatException_init_$Create$('' + 'Unsupported base: ' + base + '. Supported base range is from 2 to 36');
    }var tmp = new Char(46);
    var decimal = contains$default(string, tmp, false, 2, null);
    if (decimal) {
      var bigDecimal = Companion_getInstance_11().parseString(string);
      var isActuallyDecimal = bigDecimal.minus_4(bigDecimal.floor_0()).compareTo_26(0) > 0;
      if (isActuallyDecimal) {
        throw NumberFormatException_init_$Create$('Supplied string is decimal, which cannot be converted to BigInteger without precision loss.');
      }return bigDecimal.toBigInteger();
    }var signed = charSequenceGet(string, 0).equals(new Char(45)) ? true : charSequenceGet(string, 0).equals(new Char(43));
    var tmp_0;
    if (signed) {
      if (string.length === 1) {
        throw NumberFormatException_init_$Create$('' + 'Invalid big integer: ' + string);
      }var tmp_1;
      if (charSequenceGet(string, 0).equals(new Char(45))) {
        tmp_1 = Sign_NEGATIVE_getInstance();
      } else {
        tmp_1 = Sign_POSITIVE_getInstance();
      }
      var isNegative_0 = tmp_1;
      if (string.length === 2 ? charSequenceGet(string, 1).equals(new Char(48)) : false) {
        return this._ZERO_0;
      }var tmp0_substring_0 = 1;
      var tmp1_substring_0 = string.length;
      tmp_0 = new BigInteger(this._arithmetic.parseForBase_0(string.substring(tmp0_substring_0, tmp1_substring_0), base), isNegative_0);
    } else {
      if (string.length === 1 ? charSequenceGet(string, 0).equals(new Char(48)) : false) {
        return this._ZERO_0;
      }tmp_0 = new BigInteger(this._arithmetic.parseForBase_0(string, base), Sign_POSITIVE_getInstance());
    }
    return tmp_0;
  };
  Companion_14.prototype.fromBigInteger_3 = function (bigInteger) {
    return bigInteger;
  };
  Companion_14.prototype.fromULong = function (uLong) {
    return new BigInteger(this._arithmetic.fromULong_1(uLong), Sign_POSITIVE_getInstance());
  };
  Companion_14.prototype.fromUInt = function (uInt) {
    return new BigInteger(this._arithmetic.fromUInt_1(uInt), Sign_POSITIVE_getInstance());
  };
  Companion_14.prototype.fromUShort = function (uShort) {
    return new BigInteger(this._arithmetic.fromUShort_1(uShort), Sign_POSITIVE_getInstance());
  };
  Companion_14.prototype.fromUByte = function (uByte) {
    return new BigInteger(this._arithmetic.fromUByte_1(uByte), Sign_POSITIVE_getInstance());
  };
  Companion_14.prototype.fromLong_5 = function (long) {
    return BigInteger_init_$Create$(long);
  };
  Companion_14.prototype.fromInt_5 = function (int) {
    return BigInteger_init_$Create$_0(int);
  };
  Companion_14.prototype.fromShort_5 = function (short) {
    return BigInteger_init_$Create$_1(short);
  };
  Companion_14.prototype.fromByte_5 = function (byte) {
    return BigInteger_init_$Create$_2(byte);
  };
  Companion_14.prototype.tryFromFloat_2 = function (float, exactRequired) {
    var floatDecimalPart = float - Math.floor(float);
    var tmp = Companion_getInstance_11();
    var bigDecimal = tmp.fromFloat(Math.floor(float), null);
    if (exactRequired) {
      if (floatDecimalPart > 0) {
        throw ArithmeticException_init_$Create$('Cant create BigInteger without precision loss, and exact  value was required');
      }}return bigDecimal.toBigInteger();
  };
  Companion_14.prototype.tryFromDouble_2 = function (double, exactRequired) {
    var doubleDecimalPart = double - Math.floor(double);
    var tmp = Companion_getInstance_11();
    var bigDecimal = tmp.fromDouble(Math.floor(double), null);
    if (exactRequired) {
      if (doubleDecimalPart > 0.0) {
        throw ArithmeticException_init_$Create$('Cant create BigInteger without precision loss, and exact  value was required');
      }}return bigDecimal.toBigInteger();
  };
  Companion_14.prototype.fromUByteArray = function (source, sign) {
    var result = this._arithmetic.fromUByteArray_1(source);
    return new BigInteger(result, sign);
  };
  Companion_14.prototype.fromByteArray = function (source, sign) {
    var result = this._arithmetic.fromByteArray_1(source);
    return new BigInteger(result, sign);
  };
  Companion_14.$metadata$ = {
    simpleName: 'Companion',
    kind: 'object',
    interfaces: [Creator, Util, ByteArrayDeserializable]
  };
  var Companion_instance_13;
  function Companion_getInstance_13() {
    if (Companion_instance_13 == null)
      new Companion_14();
    return Companion_instance_13;
  }
  function isResultZero($this, resultMagnitude) {
    return Companion_getInstance_13()._arithmetic.compare_4(resultMagnitude, Companion_getInstance_13()._arithmetic._get_ZERO__3()) === 0;
  }
  function javascriptNumberComparison_0($this, number) {
    var float = numberToDouble(number);
    var tmp;
    if (float % 1 === 0.0) {
      tmp = $this.compare_1(Companion_getInstance_13().fromLong_5(numberToLong(number)));
    } else {
      var tmp_0 = numberToDouble(number);
      tmp = $this.compareFloatAndBigInt(tmp_0, _no_name_provided_$factory_42($this));
    }
    return tmp;
  }
  function QuotientAndRemainder(quotient, remainder) {
    this._quotient = quotient;
    this._remainder = remainder;
  }
  QuotientAndRemainder.prototype.component1_4 = function () {
    return this._quotient;
  };
  QuotientAndRemainder.prototype.component2_4 = function () {
    return this._remainder;
  };
  QuotientAndRemainder.prototype.toString = function () {
    return '' + 'QuotientAndRemainder(quotient=' + this._quotient + ', remainder=' + this._remainder + ')';
  };
  QuotientAndRemainder.prototype.hashCode = function () {
    var result = this._quotient.hashCode();
    result = imul(result, 31) + this._remainder.hashCode() | 0;
    return result;
  };
  QuotientAndRemainder.prototype.equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof QuotientAndRemainder))
      return false;
    else {
    }
    var tmp0_other_with_cast = other instanceof QuotientAndRemainder ? other : THROW_CCE();
    if (!this._quotient.equals(tmp0_other_with_cast._quotient))
      return false;
    if (!this._remainder.equals(tmp0_other_with_cast._remainder))
      return false;
    return true;
  };
  QuotientAndRemainder.$metadata$ = {
    simpleName: 'QuotientAndRemainder',
    kind: 'class',
    interfaces: []
  };
  function BigIntegerRange(start_0, endInclusive) {
    this._start = start_0;
    this._endInclusive = endInclusive;
  }
  BigIntegerRange.prototype.iterator_66 = function () {
    return new BigIntegerIterator(this._start, this._endInclusive);
  };
  BigIntegerRange.$metadata$ = {
    simpleName: 'BigIntegerRange',
    kind: 'class',
    interfaces: [ClosedRange, Iterable]
  };
  function BigIntegerIterator(start_0, endInclusive) {
    this._endInclusive_0 = endInclusive;
    this._current = start_0;
  }
  BigIntegerIterator.prototype.hasNext_41 = function () {
    return this._current.compareTo_26(this._endInclusive_0) <= 0;
  };
  BigIntegerIterator.prototype.next_46 = function () {
    var tmp0_this = this;
    var tmp1 = tmp0_this._current;
    tmp0_this._current = tmp1.inc_5();
    return tmp1;
  };
  BigIntegerIterator.$metadata$ = {
    simpleName: 'BigIntegerIterator',
    kind: 'class',
    interfaces: [Iterator_2]
  };
  function _no_name_provided__58(this$0) {
    this._this$0_11 = this$0;
  }
  _no_name_provided__58.prototype.invoke_85 = function (it) {
    return this._this$0_11.compare_1(it);
  };
  _no_name_provided__58.prototype.invoke_1115 = function (p1) {
    return this.invoke_85(p1 instanceof BigInteger ? p1 : THROW_CCE());
  };
  _no_name_provided__58.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__59(this$0) {
    this._this$0_12 = this$0;
  }
  _no_name_provided__59.prototype.invoke_85 = function (it) {
    return this._this$0_12.compare_1(it);
  };
  _no_name_provided__59.prototype.invoke_1115 = function (p1) {
    return this.invoke_85(p1 instanceof BigInteger ? p1 : THROW_CCE());
  };
  _no_name_provided__59.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__60(this$0) {
    this._this$0_13 = this$0;
  }
  _no_name_provided__60.prototype.invoke_85 = function (it) {
    return this._this$0_13.compare_1(it);
  };
  _no_name_provided__60.prototype.invoke_1115 = function (p1) {
    return this.invoke_85(p1 instanceof BigInteger ? p1 : THROW_CCE());
  };
  _no_name_provided__60.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function BigInteger(wordArray, requestedSign) {
    Companion_getInstance_13();
    if (requestedSign.equals(Sign_ZERO_getInstance())) {
      var tmp0_require_0 = isResultZero(this, wordArray);
      if (!tmp0_require_0) {
        var message_1 = 'sign should be Sign.ZERO iff magnitude has a value of 0';
        throw IllegalArgumentException_init_$Create$_0(toString_2(message_1));
      }}this._magnitude = BigInteger63Arithmetic_getInstance().removeLeadingZeros_0(wordArray);
    var tmp = this;
    var tmp_0;
    if (isResultZero(this, this._magnitude)) {
      tmp_0 = Sign_ZERO_getInstance();
    } else {
      tmp_0 = requestedSign;
    }
    tmp._sign = tmp_0;
    this._numberOfWords = _ULongArray___get_size__impl_(this._magnitude);
    this._stringRepresentation = null;
  }
  BigInteger.prototype.getCreator_2 = function () {
    return Companion_getInstance_13();
  };
  BigInteger.prototype.getInstance_2 = function () {
    return this;
  };
  BigInteger.prototype.add_28 = function (other) {
    var comparison = Companion_getInstance_13()._arithmetic.compare_4(this._magnitude, other._magnitude);
    var tmp;
    if (other._sign.equals(this._sign)) {
      return new BigInteger(Companion_getInstance_13()._arithmetic.add_32(this._magnitude, other._magnitude), this._sign);
    } else {
      var tmp_0;
      if (comparison > 0) {
        tmp_0 = new BigInteger(Companion_getInstance_13()._arithmetic.subtract_7(this._magnitude, other._magnitude), this._sign);
      } else if (comparison < 0) {
        tmp_0 = new BigInteger(Companion_getInstance_13()._arithmetic.subtract_7(other._magnitude, this._magnitude), other._sign);
      } else {
        tmp_0 = Companion_getInstance_13()._ZERO_0;
      }
      tmp = tmp_0;
    }
    return tmp;
  };
  BigInteger.prototype.add_34 = function (other) {
    return this.add_28(other instanceof BigInteger ? other : THROW_CCE());
  };
  BigInteger.prototype.subtract_3 = function (other) {
    var comparison = Companion_getInstance_13()._arithmetic.compare_4(this._magnitude, other._magnitude);
    if (this.equals(Companion_getInstance_13()._ZERO_0)) {
      return other.negate();
    }if (other.equals(Companion_getInstance_13()._ZERO_0)) {
      return this;
    }var tmp;
    if (other._sign.equals(this._sign)) {
      var tmp_0;
      if (comparison > 0) {
        tmp_0 = new BigInteger(Companion_getInstance_13()._arithmetic.subtract_7(this._magnitude, other._magnitude), this._sign);
      } else if (comparison < 0) {
        tmp_0 = new BigInteger(Companion_getInstance_13()._arithmetic.subtract_7(other._magnitude, this._magnitude), this._sign.not_0());
      } else {
        tmp_0 = Companion_getInstance_13()._ZERO_0;
      }
      tmp = tmp_0;
    } else {
      return new BigInteger(Companion_getInstance_13()._arithmetic.add_32(this._magnitude, other._magnitude), this._sign);
    }
    return tmp;
  };
  BigInteger.prototype.subtract_9 = function (other) {
    return this.subtract_3(other instanceof BigInteger ? other : THROW_CCE());
  };
  BigInteger.prototype.multiply_4 = function (other) {
    if (this.isZero_0() ? true : other.isZero_0()) {
      return Companion_getInstance_13()._ZERO_0;
    }if (other.equals(Companion_getInstance_13()._ONE_0)) {
      return this;
    }var tmp;
    if (!this._sign.equals(other._sign)) {
      tmp = Sign_NEGATIVE_getInstance();
    } else {
      tmp = Sign_POSITIVE_getInstance();
    }
    var sign = tmp;
    var tmp_0;
    if (sign.equals(Sign_POSITIVE_getInstance())) {
      tmp_0 = new BigInteger(Companion_getInstance_13()._arithmetic.multiply_8(this._magnitude, other._magnitude), sign);
    } else {
      tmp_0 = new BigInteger(Companion_getInstance_13()._arithmetic.multiply_8(this._magnitude, other._magnitude), sign);
    }
    return tmp_0;
  };
  BigInteger.prototype.multiply_10 = function (other) {
    return this.multiply_4(other instanceof BigInteger ? other : THROW_CCE());
  };
  BigInteger.prototype.divide_3 = function (other) {
    if (other.isZero_0()) {
      throw ArithmeticException_init_$Create$('' + 'Division by zero! ' + this + ' / ' + other);
    }var result = Companion_getInstance_13()._arithmetic.divide_7(this._magnitude, other._magnitude)._first._storage_1;
    var tmp;
    if (equals_0(new ULongArray(result), new ULongArray(Companion_getInstance_13()._arithmetic._get_ZERO__3()))) {
      tmp = Companion_getInstance_13()._ZERO_0;
    } else {
      var tmp_0;
      if (!this._sign.equals(other._sign)) {
        tmp_0 = Sign_NEGATIVE_getInstance();
      } else {
        tmp_0 = Sign_POSITIVE_getInstance();
      }
      var sign = tmp_0;
      tmp = new BigInteger(result, sign);
    }
    return tmp;
  };
  BigInteger.prototype.divide_9 = function (other) {
    return this.divide_3(other instanceof BigInteger ? other : THROW_CCE());
  };
  BigInteger.prototype.remainder_2 = function (other) {
    if (other.isZero_0()) {
      throw ArithmeticException_init_$Create$('' + 'Division by zero! ' + this + ' / ' + other);
    }var tmp;
    if (!this._sign.equals(other._sign)) {
      tmp = Sign_NEGATIVE_getInstance();
    } else {
      tmp = Sign_POSITIVE_getInstance();
    }
    var sign = tmp;
    var result = Companion_getInstance_13()._arithmetic.divide_7(this._magnitude, other._magnitude)._second._storage_1;
    if (equals_0(new ULongArray(result), new ULongArray(Companion_getInstance_13()._arithmetic._get_ZERO__3()))) {
      sign = Sign_ZERO_getInstance();
    }return new BigInteger(result, sign);
  };
  BigInteger.prototype.remainder_5 = function (other) {
    return this.remainder_2(other instanceof BigInteger ? other : THROW_CCE());
  };
  BigInteger.prototype.divideAndRemainder_0 = function (other) {
    if (other.isZero_0()) {
      throw ArithmeticException_init_$Create$('' + 'Division by zero! ' + this + ' / ' + other);
    }var tmp;
    if (!this._sign.equals(other._sign)) {
      tmp = Sign_NEGATIVE_getInstance();
    } else {
      tmp = Sign_POSITIVE_getInstance();
    }
    var sign = tmp;
    var result = Companion_getInstance_13()._arithmetic.divide_7(this._magnitude, other._magnitude);
    var tmp_0;
    if (equals_0(result._first, new ULongArray(Companion_getInstance_13()._arithmetic._get_ZERO__3()))) {
      tmp_0 = Companion_getInstance_13()._ZERO_0;
    } else {
      tmp_0 = new BigInteger(result._first._storage_1, sign);
    }
    var quotient = tmp_0;
    var tmp_1;
    if (equals_0(result._second, new ULongArray(Companion_getInstance_13()._arithmetic._get_ZERO__3()))) {
      tmp_1 = Companion_getInstance_13()._ZERO_0;
    } else {
      tmp_1 = new BigInteger(result._second._storage_1, this._sign);
    }
    var remainder = tmp_1;
    return new Pair(quotient, remainder);
  };
  BigInteger.prototype.gcd = function (other) {
    return new BigInteger(Companion_getInstance_13()._arithmetic.gcd_1(this._magnitude, other._magnitude), Sign_POSITIVE_getInstance());
  };
  BigInteger.prototype.modInverse = function (modulo_0) {
    if (!this.gcd(modulo_0).equals(Companion_getInstance_13()._ONE_0)) {
      throw ArithmeticException_init_$Create$('BigInteger is not invertible. This and modulus are not relatively prime (coprime)');
    }var u = Companion_getInstance_13()._ONE_0;
    var w = Companion_getInstance_13()._ZERO_0;
    var b = this;
    var c = modulo_0;
    while (!c.equals(Companion_getInstance_13()._ZERO_0)) {
      var tmp0_container = b.divrem(c);
      var q = tmp0_container.component1_4();
      var r = tmp0_container.component2_4();
      b = c;
      c = r;
      var tmpU = u;
      u = w;
      w = tmpU.minus_2(q.times_0(w));
    }
    return u;
  };
  BigInteger.prototype.mod = function (modulo_0) {
    var result = this.rem_1(modulo_0);
    var tmp;
    if (result.compareTo_26(0) < 0) {
      tmp = result.plus_4(modulo_0);
    } else {
      tmp = result;
    }
    return tmp;
  };
  BigInteger.prototype.compare_1 = function (other) {
    if (this.isZero_0() ? other.isZero_0() : false)
      return 0;
    if (other.isZero_0() ? this._sign.equals(Sign_POSITIVE_getInstance()) : false)
      return 1;
    if (other.isZero_0() ? this._sign.equals(Sign_NEGATIVE_getInstance()) : false)
      return -1;
    if (this.isZero_0() ? other._sign.equals(Sign_POSITIVE_getInstance()) : false)
      return -1;
    if (this.isZero_0() ? other._sign.equals(Sign_NEGATIVE_getInstance()) : false)
      return 1;
    if (!this._sign.equals(other._sign))
      return this._sign.equals(Sign_POSITIVE_getInstance()) ? 1 : -1;
    var result = Companion_getInstance_13()._arithmetic.compare_4(this._magnitude, other._magnitude);
    var tmp;
    if (this._sign.equals(Sign_NEGATIVE_getInstance()) ? other._sign.equals(Sign_NEGATIVE_getInstance()) : false) {
      tmp = imul(result, -1);
    } else {
      tmp = result;
    }
    return tmp;
  };
  BigInteger.prototype.isZero_0 = function () {
    return this._sign.equals(Sign_ZERO_getInstance()) ? true : chosenArithmetic.compare_4(this._magnitude, chosenArithmetic._get_ZERO__3()) === 0;
  };
  BigInteger.prototype.negate = function () {
    return new BigInteger(this._magnitude, this._sign.not_0());
  };
  BigInteger.prototype.abs_0 = function () {
    return new BigInteger(this._magnitude, Sign_POSITIVE_getInstance());
  };
  BigInteger.prototype.pow_0 = function (exponent) {
    if (exponent.compareTo_75(new Long(0, 0)) < 0) {
      throw ArithmeticException_init_$Create$('Negative exponent not supported with BigInteger');
    }var tmp0_subject = this;
    var tmp;
    if (tmp0_subject.equals(Companion_getInstance_13()._ZERO_0)) {
      tmp = Companion_getInstance_13()._ZERO_0;
    } else if (tmp0_subject.equals(Companion_getInstance_13()._ONE_0)) {
      tmp = Companion_getInstance_13()._ONE_0;
    } else {
      var tmp_0;
      if (this._sign.equals(Sign_NEGATIVE_getInstance())) {
        var tmp_1;
        var tmp0_rem_0 = 2;
        if (exponent.rem_33(toLong_0(tmp0_rem_0)).equals(new Long(0, 0))) {
          tmp_1 = Sign_POSITIVE_getInstance();
        } else {
          {
            tmp_1 = Sign_NEGATIVE_getInstance();
          }
        }
        tmp_0 = tmp_1;
      } else {
        tmp_0 = Sign_POSITIVE_getInstance();
      }
      var sign = tmp_0;
      tmp = new BigInteger(Companion_getInstance_13()._arithmetic.pow_3(this._magnitude, exponent), sign);
    }
    return tmp;
  };
  BigInteger.prototype.pow_1 = function (exponent) {
    return this.pow_0(toLong_0(exponent));
  };
  BigInteger.prototype.signum_2 = function () {
    var tmp0_subject = this._sign;
    var tmp;
    if (tmp0_subject.equals(Sign_POSITIVE_getInstance())) {
      tmp = 1;
    } else if (tmp0_subject.equals(Sign_NEGATIVE_getInstance())) {
      tmp = -1;
    } else if (tmp0_subject.equals(Sign_ZERO_getInstance())) {
      tmp = 0;
    } else {
      noWhenBranchMatchedException();
    }
    return tmp;
  };
  BigInteger.prototype.numberOfDecimalDigits = function () {
    if (this.isZero_0()) {
      return new Long(1, 0);
    }var bitLenght = Companion_getInstance_13()._arithmetic.bitLength_2(this._magnitude);
    var tmp0_ceil_0 = (bitLenght - 1 | 0) * Companion_getInstance_13()._LOG_10_OF_2;
    var minDigit = Math.ceil(tmp0_ceil_0);
    var tmp = this.div_1(toBigInteger(10).pow_1(numberToInt(minDigit)));
    var counter_0 = new Long(0, 0);
    while (!(tmp.compareTo_26(0) === 0)) {
      tmp = tmp.div_33(10);
      var tmp0 = counter_0;
      counter_0 = tmp0.inc_5();
      Unit_getInstance();
    }
    var tmp1_plus_0 = counter_0;
    var tmp2_plus_0 = numberToInt(minDigit);
    return tmp1_plus_0.plus_71(toLong_0(tmp2_plus_0));
  };
  BigInteger.prototype.shl_4 = function (places) {
    return new BigInteger(Companion_getInstance_13()._arithmetic.shiftLeft_1(this._magnitude, places), this._sign);
  };
  BigInteger.prototype.shr_4 = function (places) {
    var result = new BigInteger(Companion_getInstance_13()._arithmetic.shiftRight_1(this._magnitude, places), this._sign);
    if (equals_0(new ULongArray(result._magnitude), new ULongArray(Companion_getInstance_13()._arithmetic._get_ZERO__3()))) {
      return Companion_getInstance_13()._ZERO_0;
    }return result;
  };
  BigInteger.prototype.unaryMinus_5 = function () {
    return this.negate();
  };
  BigInteger.prototype.dec_5 = function () {
    return this.minus_2(Companion_getInstance_13()._ONE_0);
  };
  BigInteger.prototype.inc_5 = function () {
    return this.plus_4(Companion_getInstance_13()._ONE_0);
  };
  BigInteger.prototype.divrem = function (other) {
    var result = this.divideAndRemainder_0(other);
    return new QuotientAndRemainder(result._first, result._second);
  };
  BigInteger.prototype.and_0 = function (other) {
    return new BigInteger(Companion_getInstance_13()._arithmetic.and_3(this._magnitude, other._magnitude), this._sign);
  };
  BigInteger.prototype.or_0 = function (other) {
    return new BigInteger(Companion_getInstance_13()._arithmetic.or_2(this._magnitude, other._magnitude), this._sign);
  };
  BigInteger.prototype.xor_0 = function (other) {
    var resultMagnitude = Companion_getInstance_13()._arithmetic.xor_2(this._magnitude, other._magnitude);
    var resultSign = !!(this._get_isNegative__0() ^ other._get_isNegative__0()) ? Sign_NEGATIVE_getInstance() : isResultZero(this, resultMagnitude) ? Sign_ZERO_getInstance() : Sign_POSITIVE_getInstance();
    return new BigInteger(resultMagnitude, resultSign);
  };
  BigInteger.prototype.compareTo_26 = function (other) {
    if (isNumber(other)) {
      if (ComparisonWorkaround_getInstance().isSpecialHandlingForFloatNeeded(other)) {
        return javascriptNumberComparison_0(this, other);
      }} else {
    }
    var tmp0_subject = other;
    var tmp;
    if (tmp0_subject instanceof BigInteger) {
      tmp = this.compare_1(other);
    } else {
      if (tmp0_subject instanceof Long) {
        tmp = this.compare_1(Companion_getInstance_13().fromLong_5(other));
      } else {
        if (typeof tmp0_subject === 'number') {
          tmp = this.compare_1(Companion_getInstance_13().fromInt_5(other));
        } else {
          if (typeof tmp0_subject === 'number') {
            tmp = this.compare_1(Companion_getInstance_13().fromShort_5(other));
          } else {
            if (typeof tmp0_subject === 'number') {
              tmp = this.compare_1(Companion_getInstance_13().fromByte_5(other));
            } else {
              if (tmp0_subject instanceof ULong) {
                tmp = this.compare_1(Companion_getInstance_13().fromULong(other._data_1));
              } else {
                if (tmp0_subject instanceof UInt) {
                  tmp = this.compare_1(Companion_getInstance_13().fromUInt(other._data_0));
                } else {
                  if (tmp0_subject instanceof UShort) {
                    tmp = this.compare_1(Companion_getInstance_13().fromUShort(other._data_2));
                  } else {
                    if (tmp0_subject instanceof UByte) {
                      tmp = this.compare_1(Companion_getInstance_13().fromUByte(other._data));
                    } else {
                      if (typeof tmp0_subject === 'number') {
                        var tmp_0 = other;
                        tmp = this.compareFloatAndBigInt(tmp_0, _no_name_provided_$factory_40(this));
                      } else {
                        if (typeof tmp0_subject === 'number') {
                          var tmp_1 = other;
                          tmp = this.compareDoubleAndBigInt(tmp_1, _no_name_provided_$factory_41(this));
                        } else {
                          {
                            throw RuntimeException_init_$Create$('' + 'Invalid comparison type for BigInteger: ' + getKClassFromExpression_0(other));
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    return tmp;
  };
  BigInteger.prototype.compareTo_34 = function (other) {
    return this.compareTo_26(isObject(other) ? other : THROW_CCE());
  };
  BigInteger.prototype.compareFloatAndBigInt = function (float, comparisonBlock) {
    var withoutDecimalPart = Math.floor(float);
    var hasDecimalPart = !(float % 1 === 0.0);
    var tmp;
    if (hasDecimalPart) {
      var tmp_0 = Companion_getInstance_13();
      var tmp_1 = withoutDecimalPart + 1;
      var comparisonResult = comparisonBlock(tmp_0.tryFromFloat$default_2(tmp_1, false, 2, null));
      var tmp_2;
      if (comparisonResult === 0) {
        tmp_2 = 1;
      } else {
        tmp_2 = comparisonResult;
      }
      tmp = tmp_2;
    } else {
      var tmp_3 = Companion_getInstance_13();
      tmp = comparisonBlock(tmp_3.tryFromFloat$default_2(withoutDecimalPart, false, 2, null));
    }
    return tmp;
  };
  BigInteger.prototype.compareDoubleAndBigInt = function (double, comparisonBlock) {
    var withoutDecimalPart = Math.floor(double);
    var hasDecimalPart = !(double % 1 === 0.0);
    var tmp;
    if (hasDecimalPart) {
      var tmp_0 = Companion_getInstance_13();
      var tmp_1 = withoutDecimalPart + 1;
      var comparisonResult = comparisonBlock(tmp_0.tryFromDouble$default_2(tmp_1, false, 2, null));
      var tmp_2;
      if (comparisonResult === 0) {
        tmp_2 = 1;
      } else {
        tmp_2 = comparisonResult;
      }
      tmp = tmp_2;
    } else {
      var tmp_3 = Companion_getInstance_13();
      tmp = comparisonBlock(tmp_3.tryFromDouble$default_2(withoutDecimalPart, false, 2, null));
    }
    return tmp;
  };
  BigInteger.prototype.equals = function (other) {
    var tmp0_subject = other;
    var tmp;
    if (tmp0_subject instanceof BigInteger) {
      tmp = this.compare_1(other);
    } else {
      if (tmp0_subject instanceof Long) {
        tmp = this.compare_1(Companion_getInstance_13().fromLong_5(other));
      } else {
        if (!(tmp0_subject == null) ? typeof tmp0_subject === 'number' : false) {
          tmp = this.compare_1(Companion_getInstance_13().fromInt_5(other));
        } else {
          if (!(tmp0_subject == null) ? typeof tmp0_subject === 'number' : false) {
            tmp = this.compare_1(Companion_getInstance_13().fromShort_5(other));
          } else {
            if (!(tmp0_subject == null) ? typeof tmp0_subject === 'number' : false) {
              tmp = this.compare_1(Companion_getInstance_13().fromByte_5(other));
            } else {
              if (tmp0_subject instanceof ULong) {
                tmp = this.compare_1(Companion_getInstance_13().fromULong(other._data_1));
              } else {
                if (tmp0_subject instanceof UInt) {
                  tmp = this.compare_1(Companion_getInstance_13().fromUInt(other._data_0));
                } else {
                  if (tmp0_subject instanceof UShort) {
                    tmp = this.compare_1(Companion_getInstance_13().fromUShort(other._data_2));
                  } else {
                    if (tmp0_subject instanceof UByte) {
                      tmp = this.compare_1(Companion_getInstance_13().fromUByte(other._data));
                    } else {
                      {
                        tmp = -1;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    var comparison = tmp;
    return comparison === 0;
  };
  BigInteger.prototype.hashCode = function () {
    var tmp0_fold_0 = this._magnitude;
    var tmp1_fold_0 = 0;
    var accumulator_1 = tmp1_fold_0;
    var tmp0_iterator_2 = ULongArray__iterator_impl(tmp0_fold_0);
    while (tmp0_iterator_2.hasNext_41()) {
      var element_3 = tmp0_iterator_2.next_46()._data_1;
      var tmp2__anonymous__4 = accumulator_1;
      accumulator_1 = tmp2__anonymous__4 + ULong__hashCode_impl(element_3) | 0;
    }
    return accumulator_1 + this._sign.hashCode() | 0;
  };
  BigInteger.prototype.toString = function () {
    return this.toString_1(10);
  };
  BigInteger.prototype.toString_1 = function (base) {
    var tmp;
    if (this._sign.equals(Sign_NEGATIVE_getInstance())) {
      tmp = '-';
    } else {
      tmp = '';
    }
    var sign = tmp;
    return sign + this.toStringWithoutSign(base);
  };
  BigInteger.prototype.toStringWithoutSign = function (base) {
    return Companion_getInstance_13()._arithmetic.toString_3(this._magnitude, base);
  };
  BigInteger.prototype.toModularBigInteger = function (modulo_0) {
    var creator = Companion_getInstance_14().creatorForModulo(modulo_0);
    return creator.fromBigInteger_3(this);
  };
  BigInteger.prototype.intValue = function (exactRequired) {
    if (exactRequired ? this.compareTo_26(IntCompanionObject_getInstance()._MAX_VALUE_2) > 0 ? true : this.compareTo_26(IntCompanionObject_getInstance()._MIN_VALUE_2) < 0 : false) {
      throw ArithmeticException_init_$Create$('Cannot convert to int and provide exact value');
    }var tmp0_toInt_0 = ULongArray__get_impl(this._magnitude, 0);
    return imul(_ULong___get_data__impl_(tmp0_toInt_0).toInt_5(), this.signum_2());
  };
  BigInteger.prototype.toUByteArray_10 = function () {
    return Companion_getInstance_13()._arithmetic.toUByteArray_1(this._magnitude);
  };
  BigInteger.prototype.toByteArray_10 = function () {
    return Companion_getInstance_13()._arithmetic.toByteArray_1(this._magnitude);
  };
  BigInteger.prototype.rangeTo = function (other) {
    return new BigIntegerRange(this, other);
  };
  BigInteger.$metadata$ = {
    simpleName: 'BigInteger',
    kind: 'class',
    interfaces: [BigNumber, CommonBigNumberOperations, NarrowingOperations, BitwiseCapable, Comparable, ByteArraySerializable]
  };
  function _no_name_provided_$factory_40(this$0) {
    var i = new _no_name_provided__58(this$0);
    return function (p1) {
      return i.invoke_85(p1);
    };
  }
  function _no_name_provided_$factory_41(this$0) {
    var i = new _no_name_provided__59(this$0);
    return function (p1) {
      return i.invoke_85(p1);
    };
  }
  function _no_name_provided_$factory_42(this$0) {
    var i = new _no_name_provided__60(this$0);
    return function (p1) {
      return i.invoke_85(p1);
    };
  }
  function BigInteger32ArithmeticInterface() {
  }
  BigInteger32ArithmeticInterface.$metadata$ = {
    simpleName: 'BigInteger32ArithmeticInterface',
    kind: 'interface',
    interfaces: []
  };
  function BigIntegerArithmetic() {
  }
  BigIntegerArithmetic.$metadata$ = {
    simpleName: 'BigIntegerArithmetic',
    kind: 'interface',
    interfaces: []
  };
  var Sign_POSITIVE_instance;
  var Sign_NEGATIVE_instance;
  var Sign_ZERO_instance;
  var Sign_entriesInitialized;
  function Sign_initEntries() {
    if (Sign_entriesInitialized)
      return Unit_getInstance();
    Sign_entriesInitialized = true;
    Sign_POSITIVE_instance = new Sign('POSITIVE', 0);
    Sign_NEGATIVE_instance = new Sign('NEGATIVE', 1);
    Sign_ZERO_instance = new Sign('ZERO', 2);
  }
  function Sign(name, ordinal) {
    Enum.call(this, name, ordinal);
  }
  Sign.prototype.not_0 = function () {
    var tmp0_subject = this;
    var tmp;
    if (tmp0_subject.equals(Sign_POSITIVE_getInstance())) {
      tmp = Sign_NEGATIVE_getInstance();
    } else if (tmp0_subject.equals(Sign_NEGATIVE_getInstance())) {
      tmp = Sign_POSITIVE_getInstance();
    } else if (tmp0_subject.equals(Sign_ZERO_getInstance())) {
      tmp = Sign_ZERO_getInstance();
    } else {
      noWhenBranchMatchedException();
    }
    return tmp;
  };
  Sign.$metadata$ = {
    simpleName: 'Sign',
    kind: 'class',
    interfaces: []
  };
  function Sign_POSITIVE_getInstance() {
    Sign_initEntries();
    return Sign_POSITIVE_instance;
  }
  function Sign_NEGATIVE_getInstance() {
    Sign_initEntries();
    return Sign_NEGATIVE_instance;
  }
  function Sign_ZERO_getInstance() {
    Sign_initEntries();
    return Sign_ZERO_instance;
  }
  function toBigInteger(_this_) {
    return Companion_getInstance_13().fromInt_5(_this_);
  }
  var chosenArithmetic;
  function Sextuple(a, b, c, d, e, f) {
    this._a = a;
    this._b = b;
    this._c = c;
    this._d = d;
    this._e = e;
    this._f = f;
  }
  Sextuple.prototype.component1_4 = function () {
    return this._a;
  };
  Sextuple.prototype.component2_4 = function () {
    return this._b;
  };
  Sextuple.prototype.component3_1 = function () {
    return this._c;
  };
  Sextuple.prototype.component4_0 = function () {
    return this._d;
  };
  Sextuple.prototype.component5 = function () {
    return this._e;
  };
  Sextuple.prototype.component6 = function () {
    return this._f;
  };
  Sextuple.prototype.toString = function () {
    return '' + 'Sextuple(a=' + this._a + ', b=' + this._b + ', c=' + this._c + ', d=' + this._d + ', e=' + this._e + ', f=' + this._f + ')';
  };
  Sextuple.prototype.hashCode = function () {
    var result = this._a == null ? 0 : hashCode(this._a);
    result = imul(result, 31) + (this._b == null ? 0 : hashCode(this._b)) | 0;
    result = imul(result, 31) + (this._c == null ? 0 : hashCode(this._c)) | 0;
    result = imul(result, 31) + (this._d == null ? 0 : hashCode(this._d)) | 0;
    result = imul(result, 31) + (this._e == null ? 0 : hashCode(this._e)) | 0;
    result = imul(result, 31) + (this._f == null ? 0 : hashCode(this._f)) | 0;
    return result;
  };
  Sextuple.prototype.equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof Sextuple))
      return false;
    else {
    }
    var tmp0_other_with_cast = other instanceof Sextuple ? other : THROW_CCE();
    if (!equals_0(this._a, tmp0_other_with_cast._a))
      return false;
    if (!equals_0(this._b, tmp0_other_with_cast._b))
      return false;
    if (!equals_0(this._c, tmp0_other_with_cast._c))
      return false;
    if (!equals_0(this._d, tmp0_other_with_cast._d))
      return false;
    if (!equals_0(this._e, tmp0_other_with_cast._e))
      return false;
    if (!equals_0(this._f, tmp0_other_with_cast._f))
      return false;
    return true;
  };
  Sextuple.$metadata$ = {
    simpleName: 'Sextuple',
    kind: 'class',
    interfaces: []
  };
  function Quadruple(a, b, c, d) {
    this._a_0 = a;
    this._b_0 = b;
    this._c_0 = c;
    this._d_0 = d;
  }
  Quadruple.prototype.component1_4 = function () {
    return this._a_0;
  };
  Quadruple.prototype.component2_4 = function () {
    return this._b_0;
  };
  Quadruple.prototype.component3_1 = function () {
    return this._c_0;
  };
  Quadruple.prototype.component4_0 = function () {
    return this._d_0;
  };
  Quadruple.prototype.toString = function () {
    return '' + 'Quadruple(a=' + this._a_0 + ', b=' + this._b_0 + ', c=' + this._c_0 + ', d=' + this._d_0 + ')';
  };
  Quadruple.prototype.hashCode = function () {
    var result = this._a_0 == null ? 0 : hashCode(this._a_0);
    result = imul(result, 31) + (this._b_0 == null ? 0 : hashCode(this._b_0)) | 0;
    result = imul(result, 31) + (this._c_0 == null ? 0 : hashCode(this._c_0)) | 0;
    result = imul(result, 31) + (this._d_0 == null ? 0 : hashCode(this._d_0)) | 0;
    return result;
  };
  Quadruple.prototype.equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof Quadruple))
      return false;
    else {
    }
    var tmp0_other_with_cast = other instanceof Quadruple ? other : THROW_CCE();
    if (!equals_0(this._a_0, tmp0_other_with_cast._a_0))
      return false;
    if (!equals_0(this._b_0, tmp0_other_with_cast._b_0))
      return false;
    if (!equals_0(this._c_0, tmp0_other_with_cast._c_0))
      return false;
    if (!equals_0(this._d_0, tmp0_other_with_cast._d_0))
      return false;
    return true;
  };
  Quadruple.$metadata$ = {
    simpleName: 'Quadruple',
    kind: 'class',
    interfaces: []
  };
  function SignedUIntArray(unsignedValue, sign) {
    this._unsignedValue = unsignedValue;
    this._sign_0 = sign;
  }
  SignedUIntArray.prototype.toString = function () {
    return '' + 'SignedUIntArray(unsignedValue=' + new UIntArray(this._unsignedValue) + ', sign=' + this._sign_0 + ')';
  };
  SignedUIntArray.prototype.hashCode = function () {
    var result = UIntArray__hashCode_impl(this._unsignedValue);
    result = imul(result, 31) + (this._sign_0 | 0) | 0;
    return result;
  };
  SignedUIntArray.prototype.equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof SignedUIntArray))
      return false;
    else {
    }
    var tmp0_other_with_cast = other instanceof SignedUIntArray ? other : THROW_CCE();
    if (!equals_0(new UIntArray(this._unsignedValue), new UIntArray(tmp0_other_with_cast._unsignedValue)))
      return false;
    if (!(this._sign_0 === tmp0_other_with_cast._sign_0))
      return false;
    return true;
  };
  SignedUIntArray.$metadata$ = {
    simpleName: 'SignedUIntArray',
    kind: 'class',
    interfaces: []
  };
  function BigInteger32Arithmetic() {
    BigInteger32Arithmetic_instance = this;
    var tmp = this;
    var tmp0_intArrayOf_0 = new Int32Array([]);
    tmp.__emitIntArray = tmp0_intArrayOf_0;
    this._baseMask = _ULong___init__impl_(new Long(-1, 0));
    this._baseMaskInt = _UInt___init__impl_(-1);
    this._overflowMask = _ULong___init__impl_(new Long(0, 1));
    this._lowerMask = _ULong___init__impl_(new Long(65535, 0));
    this._base = _UInt___init__impl_(-1);
    this._basePowerOfTwo = 32;
    this._wordSizeInBits = 32;
    this._ZERO_1 = _UIntArray___init__impl__0(0);
    var tmp_0 = this;
    var tmp0_UIntArray_0 = 1;
    var tmp_1 = 0;
    var tmp_2 = tmp0_UIntArray_0;
    var tmp_3 = new Int32Array(tmp_2);
    while (tmp_1 < tmp_2) {
      var tmp_4 = tmp_1;
      var tmp0_toInt_0_5 = _UInt___init__impl_(1);
      tmp_3[tmp_4] = _UInt___get_data__impl_(tmp0_toInt_0_5);
      tmp_1 = tmp_1 + 1 | 0;
    }
    tmp_0._ONE_1 = _UIntArray___init__impl_(tmp_3);
    var tmp_5 = this;
    var tmp0_UIntArray_0_0 = 1;
    var tmp_1_0 = 0;
    var tmp_2_0 = tmp0_UIntArray_0_0;
    var tmp_3_0 = new Int32Array(tmp_2_0);
    while (tmp_1_0 < tmp_2_0) {
      var tmp_4_0 = tmp_1_0;
      var tmp0_toInt_0_5_0 = _UInt___init__impl_(2);
      tmp_3_0[tmp_4_0] = _UInt___get_data__impl_(tmp0_toInt_0_5_0);
      tmp_1_0 = tmp_1_0 + 1 | 0;
    }
    tmp_5._TWO_1 = _UIntArray___init__impl_(tmp_3_0);
    var tmp_6 = this;
    var tmp0_UIntArray_0_1 = 1;
    var tmp_1_1 = 0;
    var tmp_2_1 = tmp0_UIntArray_0_1;
    var tmp_3_1 = new Int32Array(tmp_2_1);
    while (tmp_1_1 < tmp_2_1) {
      var tmp_4_1 = tmp_1_1;
      var tmp0_toInt_0_5_1 = _UInt___init__impl_(10);
      tmp_3_1[tmp_4_1] = _UInt___get_data__impl_(tmp0_toInt_0_5_1);
      tmp_1_1 = tmp_1_1 + 1 | 0;
    }
    tmp_6._TEN_1 = _UIntArray___init__impl_(tmp_3_1);
    this._karatsubaThreshold = 60;
    this._toomCookThreshold = 15000;
    this._SIGNED_POSITIVE_TWO = new SignedUIntArray(this._TWO_1, true);
  }
  BigInteger32Arithmetic.prototype.numberOfLeadingZerosInAWord = function (value_0) {
    var x = value_0;
    var y;
    var n = this._basePowerOfTwo;
    var tmp0_shr_0 = x;
    var tmp1_shr_0 = 16;
    y = _UInt___init__impl_(_UInt___get_data__impl_(tmp0_shr_0) >>> tmp1_shr_0);
    if (!equals_0(new UInt(y), new UInt(_UInt___init__impl_(0)))) {
      n = n - 16 | 0;
      x = y;
    }var tmp2_shr_0 = x;
    var tmp3_shr_0 = 8;
    y = _UInt___init__impl_(_UInt___get_data__impl_(tmp2_shr_0) >>> tmp3_shr_0);
    if (!equals_0(new UInt(y), new UInt(_UInt___init__impl_(0)))) {
      n = n - 8 | 0;
      x = y;
    }var tmp4_shr_0 = x;
    var tmp5_shr_0 = 4;
    y = _UInt___init__impl_(_UInt___get_data__impl_(tmp4_shr_0) >>> tmp5_shr_0);
    if (!equals_0(new UInt(y), new UInt(_UInt___init__impl_(0)))) {
      n = n - 4 | 0;
      x = y;
    }var tmp6_shr_0 = x;
    var tmp7_shr_0 = 2;
    y = _UInt___init__impl_(_UInt___get_data__impl_(tmp6_shr_0) >>> tmp7_shr_0);
    if (!equals_0(new UInt(y), new UInt(_UInt___init__impl_(0)))) {
      n = n - 2 | 0;
      x = y;
    }var tmp8_shr_0 = x;
    var tmp9_shr_0 = 1;
    y = _UInt___init__impl_(_UInt___get_data__impl_(tmp8_shr_0) >>> tmp9_shr_0);
    if (!equals_0(new UInt(y), new UInt(_UInt___init__impl_(0)))) {
      return n - 2 | 0;
    }var tmp = n;
    var tmp10_toInt_0 = x;
    return tmp - _UInt___get_data__impl_(tmp10_toInt_0) | 0;
  };
  BigInteger32Arithmetic.prototype.bitLength_0 = function (value_0) {
    if (UIntArray__isEmpty_impl(value_0)) {
      return 0;
    }var mostSignificant = UIntArray__get_impl(value_0, _UIntArray___get_size__impl_(value_0) - 1 | 0);
    return this.bitLength_1(mostSignificant) + imul(_UIntArray___get_size__impl_(value_0) - 1 | 0, this._basePowerOfTwo) | 0;
  };
  BigInteger32Arithmetic.prototype.bitLength_1 = function (value_0) {
    return this._basePowerOfTwo - this.numberOfLeadingZerosInAWord(value_0) | 0;
  };
  BigInteger32Arithmetic.prototype.removeLeadingZeros = function (bigInteger) {
    var tmp$ret$0;
    l$ret$1: do {
      var tmp0_indexOfLast_0_1 = _UIntArray___get_storage__impl_(bigInteger);
      var inductionVariable = tmp0_indexOfLast_0_1.length - 1 | 0;
      if (0 <= inductionVariable)
        do {
          var index_2_3 = inductionVariable;
          inductionVariable = inductionVariable + -1 | 0;
          var tmp1__anonymous__3_4 = tmp0_indexOfLast_0_1[index_2_3];
          var tmp0__anonymous__5 = _UInt___init__impl_(tmp1__anonymous__3_4);
          if (!equals_0(new UInt(tmp0__anonymous__5), new UInt(_UInt___init__impl_(0)))) {
            tmp$ret$0 = index_2_3;
            break l$ret$1;
          } else {
          }
        }
         while (0 <= inductionVariable);
      tmp$ret$0 = -1;
    }
     while (false);
    var firstEmpty = tmp$ret$0 + 1 | 0;
    if (firstEmpty === -1 ? true : firstEmpty === 0) {
      return this._ZERO_1;
    }var tmp1_copyOfRange_0 = 0;
    return _UIntArray___init__impl_(copyOfRange_0(_UIntArray___get_storage__impl_(bigInteger), tmp1_copyOfRange_0, firstEmpty));
  };
  BigInteger32Arithmetic.prototype.countLeadingZeroWords = function (bigInteger) {
    var lastNonEmptyIndex = _UIntArray___get_size__impl_(bigInteger) - 1 | 0;
    if (lastNonEmptyIndex <= 0) {
      return 0;
    }var element = UIntArray__get_impl(bigInteger, lastNonEmptyIndex);
    while (equals_0(new UInt(element), new UInt(_UInt___init__impl_(0))) ? lastNonEmptyIndex > 0 : false) {
      lastNonEmptyIndex = lastNonEmptyIndex - 1 | 0;
      element = UIntArray__get_impl(bigInteger, lastNonEmptyIndex);
    }
    if (equals_0(new UInt(UIntArray__get_impl(bigInteger, lastNonEmptyIndex)), new UInt(_UInt___init__impl_(0)))) {
      lastNonEmptyIndex = lastNonEmptyIndex - 1 | 0;
    }return (_UIntArray___get_size__impl_(bigInteger) - lastNonEmptyIndex | 0) - 1 | 0;
  };
  BigInteger32Arithmetic.prototype.shiftLeft_0 = function (operand, places) {
    if (UIntArray__isEmpty_impl(operand) ? true : places === 0) {
      return operand;
    }var originalSize = _UIntArray___get_size__impl_(operand);
    var leadingZeros = this.numberOfLeadingZerosInAWord(UIntArray__get_impl(operand, _UIntArray___get_size__impl_(operand) - 1 | 0));
    var shiftWords = places / this._basePowerOfTwo | 0;
    var shiftBits = places % this._basePowerOfTwo;
    var tmp;
    if (shiftBits > leadingZeros) {
      tmp = shiftWords + 1 | 0;
    } else {
      tmp = shiftWords;
    }
    var wordsNeeded = tmp;
    if (shiftBits === 0) {
      var tmp0_UIntArray_0 = _UIntArray___get_size__impl_(operand) + wordsNeeded | 0;
      var tmp_1 = 0;
      var tmp_2 = tmp0_UIntArray_0;
      var tmp_3 = new Int32Array(tmp_2);
      while (tmp_1 < tmp_2) {
        var tmp_4 = tmp_1;
        var tmp0_subject_7 = tmp_4;
        var tmp0_toInt_0_5 = (0 <= tmp0_subject_7 ? tmp0_subject_7 < shiftWords : false) ? _UInt___init__impl_(0) : UIntArray__get_impl(operand, tmp_4 - shiftWords | 0);
        tmp_3[tmp_4] = _UInt___get_data__impl_(tmp0_toInt_0_5);
        tmp_1 = tmp_1 + 1 | 0;
      }
      return _UIntArray___init__impl_(tmp_3);
    }var tmp1_UIntArray_0 = _UIntArray___get_size__impl_(operand) + wordsNeeded | 0;
    var tmp_1_0 = 0;
    var tmp_2_0 = tmp1_UIntArray_0;
    var tmp_3_0 = new Int32Array(tmp_2_0);
    while (tmp_1_0 < tmp_2_0) {
      var tmp_4_0 = tmp_1_0;
      var tmp0_subject_7_0 = tmp_4_0;
      var tmp_0;
      if (0 <= tmp0_subject_7_0 ? tmp0_subject_7_0 < shiftWords : false) {
        tmp_0 = _UInt___init__impl_(0);
      } else {
        if (tmp0_subject_7_0 === shiftWords) {
          var tmp0_shl_0_8 = UIntArray__get_impl(operand, tmp_4_0 - shiftWords | 0);
          tmp_0 = _UInt___init__impl_(_UInt___get_data__impl_(tmp0_shl_0_8) << shiftBits);
        } else {
          var containsLower = shiftWords + 1 | 0;
          if (tmp0_subject_7_0 < (originalSize + shiftWords | 0) ? containsLower <= tmp0_subject_7_0 : false) {
            var tmp1_shl_0_10 = UIntArray__get_impl(operand, tmp_4_0 - shiftWords | 0);
            var tmp4_or_0_9 = _UInt___init__impl_(_UInt___get_data__impl_(tmp1_shl_0_10) << shiftBits);
            var tmp2_shr_0_12 = UIntArray__get_impl(operand, (tmp_4_0 - shiftWords | 0) - 1 | 0);
            var tmp3_shr_0_13 = BigInteger32Arithmetic_getInstance()._basePowerOfTwo - shiftBits | 0;
            var tmp5_or_0_11 = _UInt___init__impl_(_UInt___get_data__impl_(tmp2_shr_0_12) >>> tmp3_shr_0_13);
            tmp_0 = _UInt___init__impl_(_UInt___get_data__impl_(tmp4_or_0_9) | _UInt___get_data__impl_(tmp5_or_0_11));
          } else {
            if (tmp0_subject_7_0 === ((originalSize + wordsNeeded | 0) - 1 | 0)) {
              var tmp6_shr_0_14 = UIntArray__get_impl(operand, tmp_4_0 - wordsNeeded | 0);
              var tmp7_shr_0_15 = BigInteger32Arithmetic_getInstance()._basePowerOfTwo - shiftBits | 0;
              tmp_0 = _UInt___init__impl_(_UInt___get_data__impl_(tmp6_shr_0_14) >>> tmp7_shr_0_15);
            } else {
              {
                throw RuntimeException_init_$Create$('' + 'Invalid case ' + tmp_4_0);
              }
            }
          }
        }
      }
      var tmp0_toInt_0_5_0 = tmp_0;
      tmp_3_0[tmp_4_0] = _UInt___get_data__impl_(tmp0_toInt_0_5_0);
      tmp_1_0 = tmp_1_0 + 1 | 0;
    }
    return _UIntArray___init__impl_(tmp_3_0);
  };
  BigInteger32Arithmetic.prototype.shiftRight_0 = function (operand, places) {
    if (UIntArray__isEmpty_impl(operand) ? true : places === 0) {
      return operand;
    }var shiftBits = places % this._basePowerOfTwo;
    var wordsToDiscard = places / this._basePowerOfTwo | 0;
    if (wordsToDiscard >= _UIntArray___get_size__impl_(operand)) {
      return this._ZERO_1;
    }if (shiftBits === 0) {
      var tmp0_copyOfRange_0 = _UIntArray___get_size__impl_(operand);
      return _UIntArray___init__impl_(copyOfRange_0(_UIntArray___get_storage__impl_(operand), wordsToDiscard, tmp0_copyOfRange_0));
    }if (_UIntArray___get_size__impl_(operand) > 1 ? (_UIntArray___get_size__impl_(operand) - wordsToDiscard | 0) === 1 : false) {
      var tmp1_shr_0 = UIntArray__get_impl(operand, _UIntArray___get_size__impl_(operand) - 1 | 0);
      var tmp2_uintArrayOf_0 = _UIntArray___init__impl_(new Int32Array([_UInt___init__impl_(_UInt___get_data__impl_(tmp1_shr_0) >>> shiftBits)]));
      return tmp2_uintArrayOf_0;
    }var tmp3_UIntArray_0 = _UIntArray___get_size__impl_(operand) - wordsToDiscard | 0;
    var tmp_1 = 0;
    var tmp_2 = tmp3_UIntArray_0;
    var tmp_3 = new Int32Array(tmp_2);
    while (tmp_1 < tmp_2) {
      var tmp_4 = tmp_1;
      var tmp0_subject_7 = tmp_4;
      var tmp;
      if (0 <= tmp0_subject_7 ? tmp0_subject_7 < ((_UIntArray___get_size__impl_(operand) - 1 | 0) - wordsToDiscard | 0) : false) {
        var tmp0_shr_0_9 = UIntArray__get_impl(operand, tmp_4 + wordsToDiscard | 0);
        var tmp3_or_0_8 = _UInt___init__impl_(_UInt___get_data__impl_(tmp0_shr_0_9) >>> shiftBits);
        var tmp1_shl_0_11 = UIntArray__get_impl(operand, (tmp_4 + wordsToDiscard | 0) + 1 | 0);
        var tmp2_shl_0_12 = BigInteger32Arithmetic_getInstance()._basePowerOfTwo - shiftBits | 0;
        var tmp4_or_0_10 = _UInt___init__impl_(_UInt___get_data__impl_(tmp1_shl_0_11) << tmp2_shl_0_12);
        tmp = _UInt___init__impl_(_UInt___get_data__impl_(tmp3_or_0_8) | _UInt___get_data__impl_(tmp4_or_0_10));
      } else if (tmp0_subject_7 === ((_UIntArray___get_size__impl_(operand) - 1 | 0) - wordsToDiscard | 0)) {
        var tmp5_shr_0_13 = UIntArray__get_impl(operand, tmp_4 + wordsToDiscard | 0);
        tmp = _UInt___init__impl_(_UInt___get_data__impl_(tmp5_shr_0_13) >>> shiftBits);
      } else {
        throw RuntimeException_init_$Create$('' + 'Invalid case ' + tmp_4);
      }
      var tmp0_toInt_0_5 = tmp;
      tmp_3[tmp_4] = _UInt___get_data__impl_(tmp0_toInt_0_5);
      tmp_1 = tmp_1 + 1 | 0;
    }
    var result = _UIntArray___init__impl_(tmp_3);
    return this.removeLeadingZeros(result);
  };
  BigInteger32Arithmetic.prototype.normalize_0 = function (dividend, divisor) {
    var divisorSize = _UIntArray___get_size__impl_(divisor);
    var normalizationShift = this.numberOfLeadingZerosInAWord(UIntArray__get_impl(divisor, divisorSize - 1 | 0));
    var divisorNormalized = this.shl_1(divisor, normalizationShift);
    var dividendNormalized = this.shl_1(dividend, normalizationShift);
    return new Triple(new UIntArray(dividendNormalized), new UIntArray(divisorNormalized), normalizationShift);
  };
  BigInteger32Arithmetic.prototype.denormalize = function (remainderNormalized, normalizationShift) {
    var remainder = this.shr_1(remainderNormalized, normalizationShift);
    return remainder;
  };
  BigInteger32Arithmetic.prototype.compare_3 = function (first_4, second) {
    var firstStart = _UIntArray___get_size__impl_(first_4) - this.countLeadingZeroWords(first_4) | 0;
    var secondStart = _UIntArray___get_size__impl_(second) - this.countLeadingZeroWords(second) | 0;
    if (firstStart > secondStart) {
      return 1;
    }if (secondStart > firstStart) {
      return -1;
    }var counter_0 = firstStart - 1 | 0;
    var firstIsLarger = false;
    var bothAreEqual = true;
    $l$break_0: while (counter_0 >= 0) {
      var tmp0_compareTo_0 = UIntArray__get_impl(first_4, counter_0);
      var tmp1_compareTo_0 = UIntArray__get_impl(second, counter_0);
      if (uintCompare(_UInt___get_data__impl_(tmp0_compareTo_0), _UInt___get_data__impl_(tmp1_compareTo_0)) > 0) {
        firstIsLarger = true;
        bothAreEqual = false;
        break $l$break_0;
      } else {
      }
      var tmp2_compareTo_0 = UIntArray__get_impl(first_4, counter_0);
      var tmp3_compareTo_0 = UIntArray__get_impl(second, counter_0);
      if (uintCompare(_UInt___get_data__impl_(tmp2_compareTo_0), _UInt___get_data__impl_(tmp3_compareTo_0)) < 0) {
        firstIsLarger = false;
        bothAreEqual = false;
        break $l$break_0;
      } else {
      }
      var tmp0 = counter_0;
      counter_0 = tmp0 - 1 | 0;
      Unit_getInstance();
    }
    if (bothAreEqual) {
      return 0;
    }if (firstIsLarger) {
      return 1;
    } else {
      return -1;
    }
  };
  BigInteger32Arithmetic.prototype.add_31 = function (first_4, second) {
    if (_UIntArray___get_size__impl_(first_4) === 1 ? equals_0(new UInt(UIntArray__get_impl(first_4, 0)), new UInt(_UInt___init__impl_(0))) : false)
      return second;
    if (_UIntArray___get_size__impl_(second) === 1 ? equals_0(new UInt(UIntArray__get_impl(second, 0)), new UInt(_UInt___init__impl_(0))) : false)
      return first_4;
    var tmp;
    if (_UIntArray___get_size__impl_(first_4) > _UIntArray___get_size__impl_(second)) {
      tmp = new Quadruple(_UIntArray___get_size__impl_(first_4), _UIntArray___get_size__impl_(second), new UIntArray(first_4), new UIntArray(second));
    } else {
      tmp = new Quadruple(_UIntArray___get_size__impl_(second), _UIntArray___get_size__impl_(first_4), new UIntArray(second), new UIntArray(first_4));
    }
    var tmp0_container = tmp;
    var maxLength = tmp0_container.component1_4();
    var minLength = tmp0_container.component2_4();
    var largerData = tmp0_container.component3_1()._storage_0;
    var smallerData = tmp0_container.component4_0()._storage_0;
    var tmp0_UIntArray_0 = maxLength + 1 | 0;
    var tmp_1 = 0;
    var tmp_2 = tmp0_UIntArray_0;
    var tmp_3 = new Int32Array(tmp_2);
    while (tmp_1 < tmp_2) {
      var tmp_4 = tmp_1;
      var tmp0_toInt_0_5 = _UInt___init__impl_(0);
      tmp_3[tmp_4] = _UInt___get_data__impl_(tmp0_toInt_0_5);
      tmp_1 = tmp_1 + 1 | 0;
    }
    var result = _UIntArray___init__impl_(tmp_3);
    var i = 0;
    var sum = _ULong___init__impl_(new Long(0, 0));
    while (i < minLength) {
      var tmp1_plus_0 = sum;
      var tmp2_plus_0 = UIntArray__get_impl(largerData, i);
      var tmp0_plus_0_1 = _ULong___init__impl_(toLong_0(_UInt___get_data__impl_(tmp2_plus_0)).and(new Long(-1, 0)));
      var tmp3_plus_0 = _ULong___init__impl_(_ULong___get_data__impl_(tmp1_plus_0).plus_71(_ULong___get_data__impl_(tmp0_plus_0_1)));
      var tmp4_plus_0 = UIntArray__get_impl(smallerData, i);
      var tmp0_plus_0_1_0 = _ULong___init__impl_(toLong_0(_UInt___get_data__impl_(tmp4_plus_0)).and(new Long(-1, 0)));
      sum = _ULong___init__impl_(_ULong___get_data__impl_(tmp3_plus_0).plus_71(_ULong___get_data__impl_(tmp0_plus_0_1_0)));
      var tmp_0 = i;
      var tmp5_and_0 = sum;
      var tmp6_and_0 = this._baseMask;
      var tmp7_toUInt_0 = _ULong___init__impl_(_ULong___get_data__impl_(tmp5_and_0).and(_ULong___get_data__impl_(tmp6_and_0)));
      var tmp0_toUInt_0_1 = _ULong___get_data__impl_(tmp7_toUInt_0);
      UIntArray__set_impl(result, tmp_0, _UInt___init__impl_(tmp0_toUInt_0_1.toInt_5()));
      var tmp8_shr_0 = sum;
      var tmp9_shr_0 = this._basePowerOfTwo;
      sum = _ULong___init__impl_(_ULong___get_data__impl_(tmp8_shr_0).ushr_0(tmp9_shr_0));
      var tmp1 = i;
      i = tmp1 + 1 | 0;
      Unit_getInstance();
    }
    while (true) {
      if (equals_0(new ULong(sum), new ULong(_ULong___init__impl_(new Long(0, 0))))) {
        while (i < maxLength) {
          UIntArray__set_impl(result, i, UIntArray__get_impl(largerData, i));
          var tmp2 = i;
          i = tmp2 + 1 | 0;
          Unit_getInstance();
        }
        var tmp_5;
        if (equals_0(new UInt(UIntArray__get_impl(result, _UIntArray___get_size__impl_(result) - 1 | 0)), new UInt(_UInt___init__impl_(0)))) {
          var tmp10_copyOfRange_0 = 0;
          var tmp11_copyOfRange_0 = _UIntArray___get_size__impl_(result) - 1 | 0;
          tmp_5 = _UIntArray___init__impl_(copyOfRange_0(_UIntArray___get_storage__impl_(result), tmp10_copyOfRange_0, tmp11_copyOfRange_0));
        } else {
          tmp_5 = result;
        }
        return tmp_5;
      }if (i === maxLength) {
        var tmp12_toUInt_0 = sum;
        var tmp0_toUInt_0_1_0 = _ULong___get_data__impl_(tmp12_toUInt_0);
        UIntArray__set_impl(result, maxLength, _UInt___init__impl_(tmp0_toUInt_0_1_0.toInt_5()));
        return result;
      }var tmp13_plus_0 = sum;
      var tmp14_plus_0 = UIntArray__get_impl(largerData, i);
      var tmp0_plus_0_1_1 = _ULong___init__impl_(toLong_0(_UInt___get_data__impl_(tmp14_plus_0)).and(new Long(-1, 0)));
      sum = _ULong___init__impl_(_ULong___get_data__impl_(tmp13_plus_0).plus_71(_ULong___get_data__impl_(tmp0_plus_0_1_1)));
      var tmp_6 = i;
      var tmp15_and_0 = sum;
      var tmp16_and_0 = this._baseMask;
      var tmp17_toUInt_0 = _ULong___init__impl_(_ULong___get_data__impl_(tmp15_and_0).and(_ULong___get_data__impl_(tmp16_and_0)));
      var tmp0_toUInt_0_1_1 = _ULong___get_data__impl_(tmp17_toUInt_0);
      UIntArray__set_impl(result, tmp_6, _UInt___init__impl_(tmp0_toUInt_0_1_1.toInt_5()));
      var tmp18_shr_0 = sum;
      var tmp19_shr_0 = this._basePowerOfTwo;
      sum = _ULong___init__impl_(_ULong___get_data__impl_(tmp18_shr_0).ushr_0(tmp19_shr_0));
      var tmp3 = i;
      i = tmp3 + 1 | 0;
      Unit_getInstance();
    }
  };
  BigInteger32Arithmetic.prototype.subtract_6 = function (first_4, second) {
    var firstWithoutLeadingZeroes = this.removeLeadingZeros(first_4);
    var secondWithoutLeadingZeroes = this.removeLeadingZeros(second);
    var firstIsLarger = this.compare_3(firstWithoutLeadingZeroes, secondWithoutLeadingZeroes) === 1;
    var tmp;
    if (firstIsLarger) {
      tmp = new Quadruple(_UIntArray___get_size__impl_(firstWithoutLeadingZeroes), _UIntArray___get_size__impl_(secondWithoutLeadingZeroes), new UIntArray(firstWithoutLeadingZeroes), new UIntArray(secondWithoutLeadingZeroes));
    } else {
      tmp = new Quadruple(_UIntArray___get_size__impl_(secondWithoutLeadingZeroes), _UIntArray___get_size__impl_(firstWithoutLeadingZeroes), new UIntArray(secondWithoutLeadingZeroes), new UIntArray(firstWithoutLeadingZeroes));
    }
    var tmp0_container = tmp;
    var largerLength = tmp0_container.component1_4();
    var smallerLength = tmp0_container.component2_4();
    var largerData = tmp0_container.component3_1()._storage_0;
    var smallerData = tmp0_container.component4_0()._storage_0;
    var tmp0_UIntArray_0 = largerLength + 1 | 0;
    var tmp_1 = 0;
    var tmp_2 = tmp0_UIntArray_0;
    var tmp_3 = new Int32Array(tmp_2);
    while (tmp_1 < tmp_2) {
      var tmp_4 = tmp_1;
      var tmp0_toInt_0_5 = _UInt___init__impl_(0);
      tmp_3[tmp_4] = _UInt___get_data__impl_(tmp0_toInt_0_5);
      tmp_1 = tmp_1 + 1 | 0;
    }
    var result = _UIntArray___init__impl_(tmp_3);
    var i = 0;
    var diff = _ULong___init__impl_(new Long(0, 0));
    while (i < smallerLength) {
      if (i >= _UIntArray___get_size__impl_(largerData)) {
        println('Breakpoint');
      }if (i >= _UIntArray___get_size__impl_(smallerData)) {
        println('Breakpoint');
      }var tmp1_toULong_0 = UIntArray__get_impl(largerData, i);
      var tmp2_minus_0 = _ULong___init__impl_(toLong_0(_UInt___get_data__impl_(tmp1_toULong_0)).and(new Long(-1, 0)));
      var tmp3_minus_0 = UIntArray__get_impl(smallerData, i);
      var tmp0_minus_0_1 = _ULong___init__impl_(toLong_0(_UInt___get_data__impl_(tmp3_minus_0)).and(new Long(-1, 0)));
      var tmp4_minus_0 = _ULong___init__impl_(_ULong___get_data__impl_(tmp2_minus_0).minus_40(_ULong___get_data__impl_(tmp0_minus_0_1)));
      var tmp5_minus_0 = diff;
      diff = _ULong___init__impl_(_ULong___get_data__impl_(tmp4_minus_0).minus_40(_ULong___get_data__impl_(tmp5_minus_0)));
      var tmp_0 = i;
      var tmp6_toUInt_0 = diff;
      var tmp0_toUInt_0_1 = _ULong___get_data__impl_(tmp6_toUInt_0);
      UIntArray__set_impl(result, tmp_0, _UInt___init__impl_(tmp0_toUInt_0_1.toInt_5()));
      var tmp7_and_0 = diff;
      var tmp8_and_0 = this._overflowMask;
      var tmp9_shr_0 = _ULong___init__impl_(_ULong___get_data__impl_(tmp7_and_0).and(_ULong___get_data__impl_(tmp8_and_0)));
      var tmp10_shr_0 = this._wordSizeInBits;
      diff = _ULong___init__impl_(_ULong___get_data__impl_(tmp9_shr_0).ushr_0(tmp10_shr_0));
      var tmp1 = i;
      i = tmp1 + 1 | 0;
      Unit_getInstance();
    }
    while (!equals_0(new ULong(diff), new ULong(_ULong___init__impl_(new Long(0, 0))))) {
      var tmp11_minus_0 = UIntArray__get_impl(largerData, i);
      var tmp12_minus_0 = diff;
      var tmp0_minus_0_1_0 = _ULong___init__impl_(toLong_0(_UInt___get_data__impl_(tmp11_minus_0)).and(new Long(-1, 0)));
      diff = _ULong___init__impl_(_ULong___get_data__impl_(tmp0_minus_0_1_0).minus_40(_ULong___get_data__impl_(tmp12_minus_0)));
      var tmp_5 = i;
      var tmp13_toUInt_0 = diff;
      var tmp0_toUInt_0_1_0 = _ULong___get_data__impl_(tmp13_toUInt_0);
      var tmp14_and_0 = _UInt___init__impl_(tmp0_toUInt_0_1_0.toInt_5());
      var tmp15_and_0 = this._baseMaskInt;
      UIntArray__set_impl(result, tmp_5, _UInt___init__impl_(_UInt___get_data__impl_(tmp14_and_0) & _UInt___get_data__impl_(tmp15_and_0)));
      var tmp16_and_0 = diff;
      var tmp17_and_0 = this._overflowMask;
      var tmp18_shr_0 = _ULong___init__impl_(_ULong___get_data__impl_(tmp16_and_0).and(_ULong___get_data__impl_(tmp17_and_0)));
      var tmp19_shr_0 = this._wordSizeInBits;
      diff = _ULong___init__impl_(_ULong___get_data__impl_(tmp18_shr_0).ushr_0(tmp19_shr_0));
      var tmp2 = i;
      i = tmp2 + 1 | 0;
      Unit_getInstance();
    }
    while (i < largerLength) {
      UIntArray__set_impl(result, i, UIntArray__get_impl(largerData, i));
      var tmp3 = i;
      i = tmp3 + 1 | 0;
      Unit_getInstance();
    }
    var tmp0_filterTo_0_1 = ArrayList_init_$Create$();
    var tmp0_iterator_1_2 = UIntArray__iterator_impl(result);
    while (tmp0_iterator_1_2.hasNext_41()) {
      var element_2_3 = tmp0_iterator_1_2.next_46()._data_0;
      if (equals_0(new UInt(element_2_3), new UInt(_UInt___init__impl_(0)))) {
        tmp0_filterTo_0_1.add_39(new UInt(element_2_3));
        Unit_getInstance();
      } else {
      }
    }
    if (tmp0_filterTo_0_1.isEmpty_44()) {
      return this._ZERO_1;
    } else {
    }
    var tmp$ret$0;
    l$ret$1: do {
      var tmp0_indexOfLast_0_1 = _UIntArray___get_storage__impl_(result);
      var inductionVariable = tmp0_indexOfLast_0_1.length - 1 | 0;
      if (0 <= inductionVariable)
        do {
          var index_2_3 = inductionVariable;
          inductionVariable = inductionVariable + -1 | 0;
          var tmp1__anonymous__3_4 = tmp0_indexOfLast_0_1[index_2_3];
          var tmp20__anonymous__5 = _UInt___init__impl_(tmp1__anonymous__3_4);
          if (!equals_0(new UInt(tmp20__anonymous__5), new UInt(_UInt___init__impl_(0)))) {
            tmp$ret$0 = index_2_3;
            break l$ret$1;
          } else {
          }
        }
         while (0 <= inductionVariable);
      tmp$ret$0 = -1;
    }
     while (false);
    var firstEmpty = tmp$ret$0 + 1 | 0;
    var tmp21_copyOfRange_0 = 0;
    return _UIntArray___init__impl_(copyOfRange_0(_UIntArray___get_storage__impl_(result), tmp21_copyOfRange_0, firstEmpty));
  };
  BigInteger32Arithmetic.prototype.multiply_7 = function (first_4, second) {
    var result = _UIntArray___init__impl__0(_UIntArray___get_size__impl_(first_4) + 1 | 0);
    var product;
    var sum;
    var inductionVariable = 0;
    var last_2 = _UIntArray___get_size__impl_(first_4);
    if (inductionVariable < last_2)
      do {
        var i = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        var tmp0_toULong_0 = UIntArray__get_impl(first_4, i);
        var tmp1_times_0 = _ULong___init__impl_(toLong_0(_UInt___get_data__impl_(tmp0_toULong_0)).and(new Long(-1, 0)));
        var tmp0_times_0_1 = _ULong___init__impl_(toLong_0(_UInt___get_data__impl_(second)).and(new Long(-1, 0)));
        product = _ULong___init__impl_(_ULong___get_data__impl_(tmp1_times_0).times_40(_ULong___get_data__impl_(tmp0_times_0_1)));
        var tmp2_toULong_0 = UIntArray__get_impl(result, i);
        var tmp6_plus_0 = _ULong___init__impl_(toLong_0(_UInt___get_data__impl_(tmp2_toULong_0)).and(new Long(-1, 0)));
        var tmp3_and_0 = product;
        var tmp4_and_0 = this._baseMask;
        var tmp5_toUInt_0 = _ULong___init__impl_(_ULong___get_data__impl_(tmp3_and_0).and(_ULong___get_data__impl_(tmp4_and_0)));
        var tmp0_toUInt_0_1 = _ULong___get_data__impl_(tmp5_toUInt_0);
        var tmp7_plus_0 = _UInt___init__impl_(tmp0_toUInt_0_1.toInt_5());
        var tmp0_plus_0_1 = _ULong___init__impl_(toLong_0(_UInt___get_data__impl_(tmp7_plus_0)).and(new Long(-1, 0)));
        sum = _ULong___init__impl_(_ULong___get_data__impl_(tmp6_plus_0).plus_71(_ULong___get_data__impl_(tmp0_plus_0_1)));
        var tmp8_and_0 = sum;
        var tmp9_and_0 = this._baseMask;
        var tmp10_toUInt_0 = _ULong___init__impl_(_ULong___get_data__impl_(tmp8_and_0).and(_ULong___get_data__impl_(tmp9_and_0)));
        var tmp0_toUInt_0_1_0 = _ULong___get_data__impl_(tmp10_toUInt_0);
        UIntArray__set_impl(result, i, _UInt___init__impl_(tmp0_toUInt_0_1_0.toInt_5()));
        var tmp11_shr_0 = sum;
        var tmp12_shr_0 = this._basePowerOfTwo;
        sum = _ULong___init__impl_(_ULong___get_data__impl_(tmp11_shr_0).ushr_0(tmp12_shr_0));
        var tmp = i + 1 | 0;
        var tmp13_shr_0 = product;
        var tmp14_shr_0 = this._basePowerOfTwo;
        var tmp15_toUInt_0 = _ULong___init__impl_(_ULong___get_data__impl_(tmp13_shr_0).ushr_0(tmp14_shr_0));
        var tmp0_toUInt_0_1_1 = _ULong___get_data__impl_(tmp15_toUInt_0);
        var tmp17_plus_0 = _UInt___init__impl_(tmp0_toUInt_0_1_1.toInt_5());
        var tmp16_toUInt_0 = sum;
        var tmp0_toUInt_0_1_2 = _ULong___get_data__impl_(tmp16_toUInt_0);
        var tmp18_plus_0 = _UInt___init__impl_(tmp0_toUInt_0_1_2.toInt_5());
        UIntArray__set_impl(result, tmp, _UInt___init__impl_(_UInt___get_data__impl_(tmp17_plus_0) + _UInt___get_data__impl_(tmp18_plus_0) | 0));
      }
       while (inductionVariable < last_2);
    return this.removeLeadingZeros(result);
  };
  BigInteger32Arithmetic.prototype.divide_6 = function (first_4, second) {
    return this.basicDivide(first_4, second);
  };
  BigInteger32Arithmetic.prototype.basicDivide = function (unnormalizedDividend, unnormalizedDivisor) {
    if (this.compareTo_23(unnormalizedDivisor, unnormalizedDividend) > 0) {
      return new Pair(new UIntArray(this._ZERO_1), new UIntArray(unnormalizedDividend));
    }if (_UIntArray___get_size__impl_(unnormalizedDivisor) === 1 ? _UIntArray___get_size__impl_(unnormalizedDividend) === 1 : false) {
      var tmp0_div_0 = UIntArray__get_impl(unnormalizedDividend, 0);
      var tmp1_div_0 = UIntArray__get_impl(unnormalizedDivisor, 0);
      var tmp2_uintArrayOf_0 = _UIntArray___init__impl_(new Int32Array([uintDivide(tmp0_div_0, tmp1_div_0)]));
      var tmp = new UIntArray(this.removeLeadingZeros(tmp2_uintArrayOf_0));
      var tmp3_rem_0 = UIntArray__get_impl(unnormalizedDividend, 0);
      var tmp4_rem_0 = UIntArray__get_impl(unnormalizedDivisor, 0);
      var tmp5_uintArrayOf_0 = _UIntArray___init__impl_(new Int32Array([uintRemainder(tmp3_rem_0, tmp4_rem_0)]));
      return new Pair(tmp, new UIntArray(this.removeLeadingZeros(tmp5_uintArrayOf_0)));
    }var bitPrecision = this.bitLength_0(unnormalizedDividend) - this.bitLength_0(unnormalizedDivisor) | 0;
    if (bitPrecision === 0) {
      var tmp6_uintArrayOf_0 = _UIntArray___init__impl_(new Int32Array([_UInt___init__impl_(1)]));
      return new Pair(new UIntArray(tmp6_uintArrayOf_0), new UIntArray(this.minus_8(unnormalizedDividend, unnormalizedDivisor)));
    }var tmp0_container = this.normalize_0(unnormalizedDividend, unnormalizedDivisor);
    var dividend = tmp0_container.component1_4()._storage_0;
    var divisor = tmp0_container.component2_4()._storage_0;
    var normalizationShift = tmp0_container.component3_1();
    var dividendSize = _UIntArray___get_size__impl_(dividend);
    var divisorSize = _UIntArray___get_size__impl_(divisor);
    var wordPrecision = dividendSize - divisorSize | 0;
    var qjhat;
    var reconstructedQuotient;
    var quotient = _UIntArray___init__impl__0(wordPrecision);
    var divisorTimesBaseToPowerOfM = this.shl_1(divisor, imul(wordPrecision, this._basePowerOfTwo));
    if (this.compareTo_23(dividend, divisorTimesBaseToPowerOfM) >= 0) {
      quotient = _UIntArray___init__impl__0(wordPrecision + 1 | 0);
      UIntArray__set_impl(quotient, wordPrecision, _UInt___init__impl_(1));
      dividend = this.minus_8(dividend, divisorTimesBaseToPowerOfM);
    }var inductionVariable = wordPrecision - 1 | 0;
    if (0 <= inductionVariable)
      do {
        var j = inductionVariable;
        inductionVariable = inductionVariable + -1 | 0;
        var tmp_0;
        if ((divisorSize + j | 0) < _UIntArray___get_size__impl_(dividend)) {
          var tmp7_toULong_0 = UIntArray__get_impl(dividend, divisorSize + j | 0);
          var tmp8_shl_0 = _ULong___init__impl_(toLong_0(_UInt___get_data__impl_(tmp7_toULong_0)).and(new Long(-1, 0)));
          var tmp9_shl_0 = this._basePowerOfTwo;
          var tmp10_plus_0 = _ULong___init__impl_(_ULong___get_data__impl_(tmp8_shl_0).shl_4(tmp9_shl_0));
          var tmp11_plus_0 = UIntArray__get_impl(dividend, (divisorSize + j | 0) - 1 | 0);
          var tmp0_plus_0_1 = _ULong___init__impl_(toLong_0(_UInt___get_data__impl_(tmp11_plus_0)).and(new Long(-1, 0)));
          var tmp12_div_0 = _ULong___init__impl_(_ULong___get_data__impl_(tmp10_plus_0).plus_71(_ULong___get_data__impl_(tmp0_plus_0_1)));
          var tmp13_div_0 = UIntArray__get_impl(divisor, divisorSize - 1 | 0);
          var tmp0_div_0_1 = _ULong___init__impl_(toLong_0(_UInt___get_data__impl_(tmp13_div_0)).and(new Long(-1, 0)));
          tmp_0 = ulongDivide(tmp12_div_0, tmp0_div_0_1);
        } else {
          var tmp_1;
          if ((divisorSize + j | 0) === _UIntArray___get_size__impl_(dividend)) {
            var tmp14_div_0 = UIntArray__get_impl(dividend, (divisorSize + j | 0) - 1 | 0);
            var tmp15_div_0 = UIntArray__get_impl(divisor, divisorSize - 1 | 0);
            var tmp16_toULong_0 = uintDivide(tmp14_div_0, tmp15_div_0);
            tmp_1 = _ULong___init__impl_(toLong_0(_UInt___get_data__impl_(tmp16_toULong_0)).and(new Long(-1, 0)));
          } else {
            tmp_1 = _ULong___init__impl_(new Long(0, 0));
          }
          tmp_0 = tmp_1;
        }
        qjhat = tmp_0;
        var tmp_2 = quotient;
        var tmp_3;
        var tmp19_compareTo_0 = qjhat;
        var tmp17_minus_0 = this._base;
        var tmp18_minus_0 = _ULong___init__impl_(new Long(1, 0));
        var tmp0_minus_0_1 = _ULong___init__impl_(toLong_0(_UInt___get_data__impl_(tmp17_minus_0)).and(new Long(-1, 0)));
        var tmp20_compareTo_0 = _ULong___init__impl_(_ULong___get_data__impl_(tmp0_minus_0_1).minus_40(_ULong___get_data__impl_(tmp18_minus_0)));
        if (ulongCompare(_ULong___get_data__impl_(tmp19_compareTo_0), _ULong___get_data__impl_(tmp20_compareTo_0)) < 0) {
          var tmp21_toUInt_0 = qjhat;
          var tmp0_toUInt_0_1 = _ULong___get_data__impl_(tmp21_toUInt_0);
          tmp_3 = _UInt___init__impl_(tmp0_toUInt_0_1.toInt_5());
        } else {
          {
            var tmp22_minus_0 = this._base;
            var tmp23_minus_0 = _UInt___init__impl_(1);
            tmp_3 = _UInt___init__impl_(_UInt___get_data__impl_(tmp22_minus_0) - _UInt___get_data__impl_(tmp23_minus_0) | 0);
          }
        }
        UIntArray__set_impl(tmp_2, j, tmp_3);
        reconstructedQuotient = this.shl_1(this.times_6(divisor, UIntArray__get_impl(quotient, j)), imul(j, this._basePowerOfTwo));
        while (this.compareTo_23(reconstructedQuotient, dividend) > 0) {
          var tmp_4 = quotient;
          var tmp24_minus_0 = UIntArray__get_impl(quotient, j);
          var tmp25_minus_0 = _UInt___init__impl_(1);
          UIntArray__set_impl(tmp_4, j, _UInt___init__impl_(_UInt___get_data__impl_(tmp24_minus_0) - _UInt___get_data__impl_(tmp25_minus_0) | 0));
          reconstructedQuotient = this.shl_1(this.times_6(divisor, UIntArray__get_impl(quotient, j)), imul(j, this._basePowerOfTwo));
        }
        dividend = this.minus_8(dividend, reconstructedQuotient);
      }
       while (0 <= inductionVariable);
    while (this.compareTo_23(dividend, divisor) >= 0) {
      quotient = this.plus_9(quotient, _UInt___init__impl_(1));
      dividend = this.minus_8(dividend, divisor);
    }
    var denormRemainder = this.denormalize(dividend, normalizationShift);
    return new Pair(new UIntArray(this.removeLeadingZeros(quotient)), new UIntArray(denormRemainder));
  };
  BigInteger32Arithmetic.prototype.shl_1 = function (_this__0, places) {
    return this.shiftLeft_0(_this__0, places);
  };
  BigInteger32Arithmetic.prototype.shr_1 = function (_this__0, places) {
    return this.shiftRight_0(_this__0, places);
  };
  BigInteger32Arithmetic.prototype.minus_8 = function (_this__0, other) {
    return this.subtract_6(_this__0, other);
  };
  BigInteger32Arithmetic.prototype.plus_9 = function (_this__0, other) {
    var tmp0_uintArrayOf_0 = _UIntArray___init__impl_(new Int32Array([other]));
    return this.add_31(_this__0, tmp0_uintArrayOf_0);
  };
  BigInteger32Arithmetic.prototype.times_6 = function (_this__0, other) {
    return this.multiply_7(_this__0, other);
  };
  BigInteger32Arithmetic.prototype.compareTo_23 = function (_this__0, other) {
    return this.compare_3(_this__0, other);
  };
  BigInteger32Arithmetic.$metadata$ = {
    simpleName: 'BigInteger32Arithmetic',
    kind: 'object',
    interfaces: [BigInteger32ArithmeticInterface]
  };
  var BigInteger32Arithmetic_instance;
  function BigInteger32Arithmetic_getInstance() {
    if (BigInteger32Arithmetic_instance == null)
      new BigInteger32Arithmetic();
    return BigInteger32Arithmetic_instance;
  }
  function multiplyWithCorrectedSize($this, first_4, second, firstCorrectedSize, secondCorrectedSize) {
    if (isZero_0(first_4, $this) ? true : isZero_0(second, $this)) {
      return $this._ZERO_2;
    }if ((firstCorrectedSize >= 120 ? true : secondCorrectedSize >= 120) ? firstCorrectedSize <= 15000 ? true : secondCorrectedSize < 15000 : false) {
      return karatsubaMultiplyWithCorrectedSizes($this, first_4, second, firstCorrectedSize, secondCorrectedSize);
    }if (firstCorrectedSize >= 15000 ? secondCorrectedSize >= 15000 : false) {
      return $this.toomCook3Multiply(first_4, second);
    }return basecaseMultiplyWithCorrectedSize($this, first_4, second, firstCorrectedSize, secondCorrectedSize);
  }
  function basecaseMultiplyWithCorrectedSize($this, first_4, second, firstCorrectedSizeStart, secondCorrectedSizeStart) {
    var resultArray = $this._ZERO_2;
    var index_1 = 0;
    var tmp0_iterator_2 = ULongArray__iterator_impl(second);
    while (tmp0_iterator_2.hasNext_41()) {
      var item_3 = tmp0_iterator_2.next_46()._data_1;
      var tmp1_4 = index_1;
      index_1 = tmp1_4 + 1 | 0;
      var tmp0__anonymous__5 = tmp1_4;
      if (tmp0__anonymous__5 > secondCorrectedSizeStart) {
        Unit_getInstance();
      } else {
        resultArray = BigInteger63Arithmetic_getInstance().plus_11(resultArray, BigInteger63Arithmetic_getInstance().shl_3(BigInteger63Arithmetic_getInstance().baseMultiply(first_4, item_3), imul(tmp0__anonymous__5, BigInteger63Arithmetic_getInstance()._basePowerOfTwo_0)));
      }
    }
    return resultArray;
  }
  function karatsubaMultiplyWithCorrectedSizes($this, firstUnsigned, secondUnsigned, firstCorrectedSize, secondCorrectedSize) {
    var first_4 = new SignedULongArray(firstUnsigned, true);
    var second = new SignedULongArray(secondUnsigned, true);
    var halfLength = (function () {
      var $externalVarargReceiverTmp = Math;
      return $externalVarargReceiverTmp.max.apply($externalVarargReceiverTmp, [].concat([].slice.call(new Int32Array([firstCorrectedSize, secondCorrectedSize]))));
    }.call(this) + 1 | 0) / 2 | 0;
    var mask = $this.minus_11($this.shl_3($this._ONE_2, imul(halfLength, $this._wordSizeInBits_0)), _ULong___init__impl_(new Long(1, 0)));
    var firstLower = $this.and_2(first_4, mask);
    var firstHigher = $this.shr_2(first_4, imul(halfLength, $this._wordSizeInBits_0));
    var secondLower = $this.and_2(second, mask);
    var secondHigher = $this.shr_2(second, imul(halfLength, $this._wordSizeInBits_0));
    var higherProduct = $this.times_7(firstHigher, secondHigher);
    var lowerProduct = $this.times_7(firstLower, secondLower);
    var middleProduct = $this.times_7($this.plus_10(firstHigher, firstLower), $this.plus_10(secondHigher, secondLower));
    var result = $this.plus_10($this.plus_10($this.shl_2(higherProduct, imul(imul(2, $this._wordSizeInBits_0), halfLength)), $this.shl_2($this.minus_9($this.minus_9(middleProduct, higherProduct), lowerProduct), imul($this._wordSizeInBits_0, halfLength))), lowerProduct);
    return result._unsignedValue_0;
  }
  function isZero_0(_this_, $this) {
    if (equals_0(new ULongArray(_this_), new ULongArray($this._ZERO_2)))
      return true;
    if (_ULongArray___get_size__impl_(_this_) === 1 ? equals_0(new ULong(ULongArray__get_impl(_this_, 0)), new ULong(_ULong___init__impl_(new Long(0, 0)))) : false)
      return true;
    if ((_ULongArray___get_size__impl_(_this_) - $this.countLeadingZeroWords_0(_this_) | 0) === 0)
      return true;
    return false;
  }
  function euclideanGcd($this, first_4, second) {
    var u = first_4;
    var v = second;
    while (!isZero_0(v, $this)) {
      var tmpU = u;
      u = v;
      v = $this.rem_5(tmpU, v);
    }
    return u;
  }
  function binaryGcd($this, first_4, second) {
    var $this_0 = $this;
    var first_5 = first_4;
    var second_0 = second;
    while (true) {
      var $this_1 = $this_0;
      var first_6 = first_5;
      var second_1 = second_0;
      if (contentEquals(first_6, second_1)) {
        return first_6;
      }if (isZero_0(first_6, $this_1)) {
        return second_1;
      }if (isZero_0(second_1, $this_1)) {
        return first_6;
      }if (isZero_0($this_1.and_3(first_6, $this_1._ONE_2), $this_1)) {
        if (isZero_0($this_1.and_3(second_1, $this_1._ONE_2), $this_1)) {
          return $this_1.shl_3(binaryGcd($this_1, $this_1.shr_3(first_6, 1), $this_1.shr_3(second_1, 1)), 1);
        } else {
          $this_0 = $this_1;
          first_5 = $this_1.shr_3(first_6, 1);
          second_0 = second_1;
          continue;
        }
      }if (isZero_0($this_1.and_3(second_1, $this_1._ONE_2), $this_1)) {
        $this_0 = $this_1;
        first_5 = first_6;
        second_0 = $this_1.shr_3(second_1, 1);
        continue;
      }var tmp;
      if ($this_1.compare_4(first_6, second_1) === 1) {
        $this_0 = $this_1;
        first_5 = $this_1.shr_3($this_1.subtract_7(first_6, second_1), 1);
        second_0 = second_1;
        continue;
      } else {
        $this_0 = $this_1;
        first_5 = $this_1.shr_3($this_1.subtract_7(second_1, first_6), 1);
        second_0 = first_6;
        continue;
      }
      return tmp;
    }
  }
  function SignedULongArray(unsignedValue, sign) {
    this._unsignedValue_0 = unsignedValue;
    this._sign_1 = sign;
  }
  SignedULongArray.prototype.copy_1 = function (unsignedValue, sign) {
    return new SignedULongArray(unsignedValue, sign);
  };
  SignedULongArray.prototype.copy$default_1 = function (unsignedValue, sign, $mask0, $handler) {
    if (!(($mask0 & 1) === 0))
      unsignedValue = this._unsignedValue_0;
    if (!(($mask0 & 2) === 0))
      sign = this._sign_1;
    return this.copy_1(unsignedValue, sign);
  };
  SignedULongArray.prototype.toString = function () {
    return '' + 'SignedULongArray(unsignedValue=' + new ULongArray(this._unsignedValue_0) + ', sign=' + this._sign_1 + ')';
  };
  SignedULongArray.prototype.hashCode = function () {
    var result = ULongArray__hashCode_impl(this._unsignedValue_0);
    result = imul(result, 31) + (this._sign_1 | 0) | 0;
    return result;
  };
  SignedULongArray.prototype.equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof SignedULongArray))
      return false;
    else {
    }
    var tmp0_other_with_cast = other instanceof SignedULongArray ? other : THROW_CCE();
    if (!equals_0(new ULongArray(this._unsignedValue_0), new ULongArray(tmp0_other_with_cast._unsignedValue_0)))
      return false;
    if (!(this._sign_1 === tmp0_other_with_cast._sign_1))
      return false;
    return true;
  };
  SignedULongArray.$metadata$ = {
    simpleName: 'SignedULongArray',
    kind: 'class',
    interfaces: []
  };
  function signedAdd($this, first_4, second) {
    var tmp;
    if (!!(first_4._sign_1 ^ second._sign_1)) {
      var tmp_0;
      if ($this.compareTo_24(first_4._unsignedValue_0, second._unsignedValue_0) > 0) {
        tmp_0 = new SignedULongArray($this.minus_10(first_4._unsignedValue_0, second._unsignedValue_0), first_4._sign_1);
      } else {
        tmp_0 = new SignedULongArray($this.minus_10(second._unsignedValue_0, first_4._unsignedValue_0), second._sign_1);
      }
      tmp = tmp_0;
    } else {
      tmp = new SignedULongArray($this.plus_11(first_4._unsignedValue_0, second._unsignedValue_0), first_4._sign_1);
    }
    return tmp;
  }
  function signedSubtract($this, first_4, second) {
    return signedAdd($this, first_4, second.copy$default_1(null, !second._sign_1, 1, null));
  }
  function signedMultiply($this, first_4, second) {
    return new SignedULongArray($this.times_8(first_4._unsignedValue_0, second._unsignedValue_0), !!!(first_4._sign_1 ^ second._sign_1));
  }
  function signedDivide($this, first_4, second) {
    return new SignedULongArray($this.div_6(first_4._unsignedValue_0, second._unsignedValue_0), !!!(first_4._sign_1 ^ second._sign_1));
  }
  function BigInteger63Arithmetic() {
    BigInteger63Arithmetic_instance = this;
    var tmp = this;
    var tmp0_longArrayOf_0 = longArrayOf([]);
    tmp.__emitLongArray = tmp0_longArrayOf_0;
    var tmp_0 = this;
    var tmp0_ulongArrayOf_0 = _ULongArray___init__impl_(longArrayOf([_ULong___init__impl_(new Long(0, 0))]));
    tmp_0._ZERO_2 = tmp0_ulongArrayOf_0;
    var tmp_1 = this;
    var tmp0_ulongArrayOf_0_0 = _ULongArray___init__impl_(longArrayOf([_ULong___init__impl_(new Long(1, 0))]));
    tmp_1._ONE_2 = tmp0_ulongArrayOf_0_0;
    var tmp_2 = this;
    var tmp0_ulongArrayOf_0_1 = _ULongArray___init__impl_(longArrayOf([_ULong___init__impl_(new Long(2, 0))]));
    tmp_2._TWO_2 = tmp0_ulongArrayOf_0_1;
    var tmp_3 = this;
    var tmp0_ulongArrayOf_0_2 = _ULongArray___init__impl_(longArrayOf([_ULong___init__impl_(new Long(10, 0))]));
    tmp_3._TEN_2 = tmp0_ulongArrayOf_0_2;
    var tmp_4 = this;
    var tmp0_ulongArrayOf_0_3 = _ULongArray___init__impl_(longArrayOf([_ULong___init__impl_(new Long(-1431655765, 715827882))]));
    tmp_4._reciprocalOf3In2ToThePowerOf63 = tmp0_ulongArrayOf_0_3;
    this._basePowerOfTwo_0 = 63;
    this._wordSizeInBits_0 = 63;
    this._baseMask_0 = _ULong___init__impl_(new Long(-1, 2147483647));
    var tmp_5 = this;
    var tmp0_ulongArrayOf_0_4 = _ULongArray___init__impl_(longArrayOf([_ULong___init__impl_(new Long(-1, 2147483647))]));
    tmp_5._baseMaskArray = tmp0_ulongArrayOf_0_4;
    this._lowMask = _ULong___init__impl_(new Long(-1, 0));
    this._highMask = _ULong___init__impl_(new Long(0, 2147483647));
    this._overflowMask_0 = _ULong___init__impl_(new Long(0, -2147483648));
    this._karatsubaThreshold_0 = 120;
    this._toomCookThreshold_0 = 15000;
    this._debugOperandSize = true;
    this._SIGNED_POSITIVE_TWO_0 = new SignedULongArray(BigInteger63Arithmetic_getInstance()._TWO_2, true);
    var tmp_6 = this;
    var tmp0_ulongArrayOf_0_5 = _ULongArray___init__impl_(longArrayOf([_ULong___init__impl_(new Long(1, 0))]));
    var tmp_7 = new ULongArray(tmp0_ulongArrayOf_0_5);
    var tmp1_ulongArrayOf_0 = _ULongArray___init__impl_(longArrayOf([_ULong___init__impl_(new Long(10, 0))]));
    var tmp_8 = new ULongArray(tmp1_ulongArrayOf_0);
    var tmp2_ulongArrayOf_0 = _ULongArray___init__impl_(longArrayOf([_ULong___init__impl_(new Long(100, 0))]));
    var tmp_9 = new ULongArray(tmp2_ulongArrayOf_0);
    var tmp3_ulongArrayOf_0 = _ULongArray___init__impl_(longArrayOf([_ULong___init__impl_(new Long(1000, 0))]));
    var tmp_10 = new ULongArray(tmp3_ulongArrayOf_0);
    var tmp4_ulongArrayOf_0 = _ULongArray___init__impl_(longArrayOf([_ULong___init__impl_(new Long(10000, 0))]));
    var tmp_11 = new ULongArray(tmp4_ulongArrayOf_0);
    var tmp5_ulongArrayOf_0 = _ULongArray___init__impl_(longArrayOf([_ULong___init__impl_(new Long(100000, 0))]));
    var tmp_12 = new ULongArray(tmp5_ulongArrayOf_0);
    var tmp6_ulongArrayOf_0 = _ULongArray___init__impl_(longArrayOf([_ULong___init__impl_(new Long(1000000, 0))]));
    var tmp_13 = new ULongArray(tmp6_ulongArrayOf_0);
    var tmp7_ulongArrayOf_0 = _ULongArray___init__impl_(longArrayOf([_ULong___init__impl_(new Long(10000000, 0))]));
    var tmp_14 = new ULongArray(tmp7_ulongArrayOf_0);
    var tmp8_ulongArrayOf_0 = _ULongArray___init__impl_(longArrayOf([_ULong___init__impl_(new Long(100000000, 0))]));
    var tmp_15 = new ULongArray(tmp8_ulongArrayOf_0);
    var tmp9_ulongArrayOf_0 = _ULongArray___init__impl_(longArrayOf([_ULong___init__impl_(new Long(1000000000, 0))]));
    var tmp_16 = new ULongArray(tmp9_ulongArrayOf_0);
    var tmp10_ulongArrayOf_0 = _ULongArray___init__impl_(longArrayOf([_ULong___init__impl_(new Long(1410065408, 2))]));
    var tmp_17 = new ULongArray(tmp10_ulongArrayOf_0);
    var tmp11_ulongArrayOf_0 = _ULongArray___init__impl_(longArrayOf([_ULong___init__impl_(new Long(1215752192, 23))]));
    var tmp_18 = new ULongArray(tmp11_ulongArrayOf_0);
    var tmp12_ulongArrayOf_0 = _ULongArray___init__impl_(longArrayOf([_ULong___init__impl_(new Long(-727379968, 232))]));
    var tmp_19 = new ULongArray(tmp12_ulongArrayOf_0);
    var tmp13_ulongArrayOf_0 = _ULongArray___init__impl_(longArrayOf([_ULong___init__impl_(new Long(1316134912, 2328))]));
    var tmp_20 = new ULongArray(tmp13_ulongArrayOf_0);
    var tmp14_ulongArrayOf_0 = _ULongArray___init__impl_(longArrayOf([_ULong___init__impl_(new Long(276447232, 23283))]));
    var tmp_21 = new ULongArray(tmp14_ulongArrayOf_0);
    var tmp15_ulongArrayOf_0 = _ULongArray___init__impl_(longArrayOf([_ULong___init__impl_(new Long(-1530494976, 232830))]));
    var tmp_22 = new ULongArray(tmp15_ulongArrayOf_0);
    var tmp16_ulongArrayOf_0 = _ULongArray___init__impl_(longArrayOf([_ULong___init__impl_(new Long(1874919424, 2328306))]));
    var tmp_23 = new ULongArray(tmp16_ulongArrayOf_0);
    var tmp17_ulongArrayOf_0 = _ULongArray___init__impl_(longArrayOf([_ULong___init__impl_(new Long(1569325056, 23283064))]));
    var tmp_24 = new ULongArray(tmp17_ulongArrayOf_0);
    var tmp18_ulongArrayOf_0 = _ULongArray___init__impl_(longArrayOf([_ULong___init__impl_(new Long(-1486618624, 232830643))]));
    var tmp_25 = new ULongArray(tmp18_ulongArrayOf_0);
    var tmp19_ulongArrayOf_0 = _ULongArray___init__impl_(longArrayOf([_ULong___init__impl_(new Long(-1981284352, 180822788)), _ULong___init__impl_(new Long(1, 0))]));
    var tmp_26 = new ULongArray(tmp19_ulongArrayOf_0);
    var tmp20_ulongArrayOf_0 = _ULongArray___init__impl_(longArrayOf([_ULong___init__impl_(new Long(1661992960, 1808227885)), _ULong___init__impl_(new Long(10, 0))]));
    var tmp_27 = new ULongArray(tmp20_ulongArrayOf_0);
    var tmp21_ulongArrayOf_0 = _ULongArray___init__impl_(longArrayOf([_ULong___init__impl_(new Long(-559939584, 902409669)), _ULong___init__impl_(new Long(108, 0))]));
    var tmp_28 = new ULongArray(tmp21_ulongArrayOf_0);
    var tmp22_ulongArrayOf_0 = _ULongArray___init__impl_(longArrayOf([_ULong___init__impl_(new Long(-1304428544, 434162106)), _ULong___init__impl_(new Long(1084, 0))]));
    var tmp_29 = new ULongArray(tmp22_ulongArrayOf_0);
    var tmp23_ulongArrayOf_0 = _ULongArray___init__impl_(longArrayOf([_ULong___init__impl_(new Long(-159383552, 46653770)), _ULong___init__impl_(new Long(10842, 0))]));
    var tmp_30 = new ULongArray(tmp23_ulongArrayOf_0);
    var tmp24_ulongArrayOf_0 = _ULongArray___init__impl_(longArrayOf([_ULong___init__impl_(new Long(-1593835520, 466537709)), _ULong___init__impl_(new Long(108420, 0))]));
    var tmp_31 = new ULongArray(tmp24_ulongArrayOf_0);
    var tmp25_ulongArrayOf_0 = _ULongArray___init__impl_(longArrayOf([_ULong___init__impl_(new Long(1241513984, 370409800)), _ULong___init__impl_(new Long(1084202, 0))]));
    var tmp_32 = new ULongArray(tmp25_ulongArrayOf_0);
    var tmp26_ulongArrayOf_0 = _ULongArray___init__impl_(longArrayOf([_ULong___init__impl_(new Long(-469762048, 1556614354)), _ULong___init__impl_(new Long(10842021, 0))]));
    var tmp_33 = new ULongArray(tmp26_ulongArrayOf_0);
    var tmp27_ulongArrayOf_0 = _ULongArray___init__impl_(longArrayOf([_ULong___init__impl_(new Long(-402653184, 533758012)), _ULong___init__impl_(new Long(108420217, 0))]));
    var tmp_34 = new ULongArray(tmp27_ulongArrayOf_0);
    var tmp28_ulongArrayOf_0 = _ULongArray___init__impl_(longArrayOf([_ULong___init__impl_(new Long(268435456, 1042612833)), _ULong___init__impl_(new Long(1084202172, 0))]));
    var tmp_35 = new ULongArray(tmp28_ulongArrayOf_0);
    var tmp29_ulongArrayOf_0 = _ULongArray___init__impl_(longArrayOf([_ULong___init__impl_(new Long(-1610612736, 1836193738)), _ULong___init__impl_(new Long(-2042880164, 2))]));
    var tmp_36 = new ULongArray(tmp29_ulongArrayOf_0);
    var tmp30_ulongArrayOf_0 = _ULongArray___init__impl_(longArrayOf([_ULong___init__impl_(new Long(1073741824, 1182068202)), _ULong___init__impl_(new Long(1046034848, 25))]));
    var tmp_37 = new ULongArray(tmp30_ulongArrayOf_0);
    var tmp31_ulongArrayOf_0 = _ULongArray___init__impl_(longArrayOf([_ULong___init__impl_(new Long(-2147483648, 1083263782)), _ULong___init__impl_(new Long(1870413893, 252))]));
    var tmp_38 = new ULongArray(tmp31_ulongArrayOf_0);
    var tmp32_ulongArrayOf_0 = _ULongArray___init__impl_(longArrayOf([_ULong___init__impl_(new Long(0, 95219585)), _ULong___init__impl_(new Long(1524269751, 2524))]));
    var tmp_39 = new ULongArray(tmp32_ulongArrayOf_0);
    var tmp33_ulongArrayOf_0 = _ULongArray___init__impl_(longArrayOf([_ULong___init__impl_(new Long(0, 952195850)), _ULong___init__impl_(new Long(-1937171674, 25243))]));
    var tmp_40 = new ULongArray(tmp33_ulongArrayOf_0);
    var tmp34_ulongArrayOf_0 = _ULongArray___init__impl_(longArrayOf([_ULong___init__impl_(new Long(0, 932023908)), _ULong___init__impl_(new Long(2103119744, 252435))]));
    var tmp_41 = new ULongArray(tmp34_ulongArrayOf_0);
    var tmp35_ulongArrayOf_0 = _ULongArray___init__impl_(longArrayOf([_ULong___init__impl_(new Long(0, 730304488)), _ULong___init__impl_(new Long(-443639036, 2524354))]));
    var tmp_42 = new ULongArray(tmp35_ulongArrayOf_0);
    var tmp36_ulongArrayOf_0 = _ULongArray___init__impl_(longArrayOf([_ULong___init__impl_(new Long(0, 860593936)), _ULong___init__impl_(new Long(-141423061, 25243548))]));
    var tmp_43 = new ULongArray(tmp36_ulongArrayOf_0);
    var tmp37_ulongArrayOf_0 = _ULongArray___init__impl_(longArrayOf([_ULong___init__impl_(new Long(0, 16004768)), _ULong___init__impl_(new Long(-1414230606, 252435489))]));
    var tmp_44 = new ULongArray(tmp37_ulongArrayOf_0);
    var tmp38_ulongArrayOf_0 = _ULongArray___init__impl_(longArrayOf([_ULong___init__impl_(new Long(0, 160047680)), _ULong___init__impl_(new Long(-1257404172, 376871248)), _ULong___init__impl_(new Long(1, 0))]));
    var tmp_45 = new ULongArray(tmp38_ulongArrayOf_0);
    var tmp39_ulongArrayOf_0 = _ULongArray___init__impl_(longArrayOf([_ULong___init__impl_(new Long(0, 1600476800)), _ULong___init__impl_(new Long(310860168, 1621228839)), _ULong___init__impl_(new Long(11, 0))]));
    var tmp_46 = new ULongArray(tmp39_ulongArrayOf_0);
    var tmp40_ulongArrayOf_0 = _ULongArray___init__impl_(longArrayOf([_ULong___init__impl_(new Long(0, 972382464)), _ULong___init__impl_(new Long(-1186365609, 1179902854)), _ULong___init__impl_(new Long(117, 0))]));
    var tmp_47 = new ULongArray(tmp40_ulongArrayOf_0);
    var tmp41_ulongArrayOf_0 = _ULongArray___init__impl_(longArrayOf([_ULong___init__impl_(new Long(0, 1133890048)), _ULong___init__impl_(new Long(1021245802, 1061610307)), _ULong___init__impl_(new Long(1175, 0))]));
    var tmp_48 = new ULongArray(tmp41_ulongArrayOf_0);
    var tmp42_ulongArrayOf_0 = _ULongArray___init__impl_(longArrayOf([_ULong___init__impl_(new Long(0, 601482240)), _ULong___init__impl_(new Long(1622523433, 2026168480)), _ULong___init__impl_(new Long(11754, 0))]));
    var tmp_49 = new ULongArray(tmp42_ulongArrayOf_0);
    var tmp43_ulongArrayOf_0 = _ULongArray___init__impl_(longArrayOf([_ULong___init__impl_(new Long(0, 1719855104)), _ULong___init__impl_(new Long(-954634852, 934331971)), _ULong___init__impl_(new Long(117549, 0))]));
    var tmp_50 = new ULongArray(tmp43_ulongArrayOf_0);
    var tmp44_ulongArrayOf_0 = _ULongArray___init__impl_(longArrayOf([_ULong___init__impl_(new Long(0, 18681856)), _ULong___init__impl_(new Long(-956413920, 753385125)), _ULong___init__impl_(new Long(1175494, 0))]));
    var tmp_51 = new ULongArray(tmp44_ulongArrayOf_0);
    var tmp45_ulongArrayOf_0 = _ULongArray___init__impl_(longArrayOf([_ULong___init__impl_(new Long(0, 186818560)), _ULong___init__impl_(new Long(-974204608, 1091400313)), _ULong___init__impl_(new Long(11754943, 0))]));
    var tmp_52 = new ULongArray(tmp45_ulongArrayOf_0);
    var tmp46_ulongArrayOf_0 = _ULongArray___init__impl_(longArrayOf([_ULong___init__impl_(new Long(0, 1868185600)), _ULong___init__impl_(new Long(-1152111488, 176584897)), _ULong___init__impl_(new Long(117549435, 0))]));
    var tmp_53 = new ULongArray(tmp46_ulongArrayOf_0);
    var tmp47_ulongArrayOf_0 = _ULongArray___init__impl_(longArrayOf([_ULong___init__impl_(new Long(0, 1501986816)), _ULong___init__impl_(new Long(1363787016, 1765848977)), _ULong___init__impl_(new Long(1175494350, 0))]));
    var tmp_54 = new ULongArray(tmp47_ulongArrayOf_0);
    var tmp48_ulongArrayOf_0 = _ULongArray___init__impl_(longArrayOf([_ULong___init__impl_(new Long(0, 2134966272)), _ULong___init__impl_(new Long(752968278, 478620589)), _ULong___init__impl_(new Long(-1129958380, 2))]));
    var tmp_55 = new ULongArray(tmp48_ulongArrayOf_0);
    var tmp49_ulongArrayOf_0 = _ULongArray___init__impl_(longArrayOf([_ULong___init__impl_(new Long(0, 2022309888)), _ULong___init__impl_(new Long(-1060251803, 491238595)), _ULong___init__impl_(new Long(1585318090, 27))]));
    var tmp_56 = new ULongArray(tmp49_ulongArrayOf_0);
    var tmp50_ulongArrayOf_0 = _ULongArray___init__impl_(longArrayOf([_ULong___init__impl_(new Long(0, 895746048)), _ULong___init__impl_(new Long(-2012583429, 617418661)), _ULong___init__impl_(new Long(-1326688282, 273))]));
    var tmp_57 = new ULongArray(tmp50_ulongArrayOf_0);
    var tmp51_ulongArrayOf_0 = _ULongArray___init__impl_(longArrayOf([_ULong___init__impl_(new Long(0, 367525888)), _ULong___init__impl_(new Long(1349002194, 1879219319)), _ULong___init__impl_(new Long(-381980930, 2736))]));
    var tmp_58 = new ULongArray(tmp51_ulongArrayOf_0);
    var tmp52_ulongArrayOf_0 = _ULongArray___init__impl_(longArrayOf([_ULong___init__impl_(new Long(0, 1527775232)), _ULong___init__impl_(new Long(605120053, 1612324009)), _ULong___init__impl_(new Long(475158004, 27369))]));
    var tmp_59 = new ULongArray(tmp52_ulongArrayOf_0);
    var tmp53_ulongArrayOf_0 = _ULongArray___init__impl_(longArrayOf([_ULong___init__impl_(new Long(0, 245366784)), _ULong___init__impl_(new Long(1756233241, 1090854555)), _ULong___init__impl_(new Long(456612751, 273691))]));
    var tmp_60 = new ULongArray(tmp53_ulongArrayOf_0);
    var tmp54_ulongArrayOf_0 = _ULongArray___init__impl_(longArrayOf([_ULong___init__impl_(new Long(0, 306184192)), _ULong___init__impl_(new Long(382463227, 171127314)), _ULong___init__impl_(new Long(271160219, 2736911))]));
    var tmp_61 = new ULongArray(tmp54_ulongArrayOf_0);
    var tmp55_ulongArrayOf_0 = _ULongArray___init__impl_(longArrayOf([_ULong___init__impl_(new Long(0, 914358272)), _ULong___init__impl_(new Long(-470335025, 1711273140)), _ULong___init__impl_(new Long(-1583365106, 27369110))]));
    var tmp_62 = new ULongArray(tmp55_ulongArrayOf_0);
    var tmp56_ulongArrayOf_0 = _ULongArray___init__impl_(longArrayOf([_ULong___init__impl_(new Long(0, 553648128)), _ULong___init__impl_(new Long(-408382950, 2080345872)), _ULong___init__impl_(new Long(1346218131, 273691106))]));
    var tmp_63 = new ULongArray(tmp56_ulongArrayOf_0);
    var tmp57_ulongArrayOf_0 = _ULongArray___init__impl_(longArrayOf([_ULong___init__impl_(new Long(0, 1241513984)), _ULong___init__impl_(new Long(211137798, 1476105897)), _ULong___init__impl_(new Long(577279431, 589427415)), _ULong___init__impl_(new Long(1, 0))]));
    var tmp_64 = new ULongArray(tmp57_ulongArrayOf_0);
    var tmp58_ulongArrayOf_0 = _ULongArray___init__impl_(longArrayOf([_ULong___init__impl_(new Long(0, 1677721600)), _ULong___init__impl_(new Long(2111377985, 1876157082)), _ULong___init__impl_(new Long(1477827020, 1599306855)), _ULong___init__impl_(new Long(12, 0))]));
    var tmp_65 = new ULongArray(tmp58_ulongArrayOf_0);
    var tmp59_ulongArrayOf_0 = _ULongArray___init__impl_(longArrayOf([_ULong___init__impl_(new Long(0, 1744830464)), _ULong___init__impl_(new Long(-361056623, 1581701640)), _ULong___init__impl_(new Long(1893368320, 960683017)), _ULong___init__impl_(new Long(127, 0))]));
    var tmp_66 = new ULongArray(tmp59_ulongArrayOf_0);
    var tmp60_ulongArrayOf_0 = _ULongArray___init__impl_(longArrayOf([_ULong___init__impl_(new Long(0, 268435456)), _ULong___init__impl_(new Long(684401074, 784630873)), _ULong___init__impl_(new Long(1753814023, 1016895582)), _ULong___init__impl_(new Long(1274, 0))]));
    var tmp_67 = new ULongArray(tmp60_ulongArrayOf_0);
    var tmp61_ulongArrayOf_0 = _ULongArray___init__impl_(longArrayOf([_ULong___init__impl_(new Long(0, 536870912)), _ULong___init__impl_(new Long(-1745923851, 1403857787)), _ULong___init__impl_(new Long(358271049, 1579021232)), _ULong___init__impl_(new Long(12744, 0))]));
    var tmp_68 = new ULongArray(tmp61_ulongArrayOf_0);
    var tmp62_ulongArrayOf_0 = _ULongArray___init__impl_(longArrayOf([_ULong___init__impl_(new Long(0, 1073741824)), _ULong___init__impl_(new Long(-279369324, 1153675987)), _ULong___init__impl_(new Long(-712256800, 757826784)), _ULong___init__impl_(new Long(127447, 0))]));
    var tmp_69 = new ULongArray(tmp62_ulongArrayOf_0);
    var tmp63_ulongArrayOf_0 = _ULongArray___init__impl_(longArrayOf([_ULong___init__impl_(new Long(0, 0)), _ULong___init__impl_(new Long(1501274061, 799341639)), _ULong___init__impl_(new Long(1467366597, 1135816904)), _ULong___init__impl_(new Long(1274473, 0))]));
    var tmp_70 = new ULongArray(tmp63_ulongArrayOf_0);
    var tmp64_ulongArrayOf_0 = _ULongArray___init__impl_(longArrayOf([_ULong___init__impl_(new Long(0, 0)), _ULong___init__impl_(new Long(2127838722, 1550965449)), _ULong___init__impl_(new Long(1788764085, 620750803)), _ULong___init__impl_(new Long(12744735, 0))]));
    var tmp_71 = new ULongArray(tmp64_ulongArrayOf_0);
    var tmp65_ulongArrayOf_0 = _ULongArray___init__impl_(longArrayOf([_ULong___init__impl_(new Long(0, 0)), _ULong___init__impl_(new Long(-196449260, 477268958)), _ULong___init__impl_(new Long(707771673, 1912540738)), _ULong___init__impl_(new Long(127447352, 0))]));
    var tmp_72 = new ULongArray(tmp65_ulongArrayOf_0);
    var tmp66_ulongArrayOf_0 = _ULongArray___init__impl_(longArrayOf([_ULong___init__impl_(new Long(0, 0)), _ULong___init__impl_(new Long(-1964492600, 477722293)), _ULong___init__impl_(new Long(-1512217860, 1945538197)), _ULong___init__impl_(new Long(1274473528, 0))]));
    var tmp_73 = new ULongArray(tmp66_ulongArrayOf_0);
    var tmp67_ulongArrayOf_0 = _ULongArray___init__impl_(longArrayOf([_ULong___init__impl_(new Long(0, 0)), _ULong___init__impl_(new Long(1829910480, 482255639)), _ULong___init__impl_(new Long(2057690586, 128029144)), _ULong___init__impl_(new Long(-140166599, 2))]));
    var tmp_74 = new ULongArray(tmp67_ulongArrayOf_0);
    var tmp68_ulongArrayOf_0 = _ULongArray___init__impl_(longArrayOf([_ULong___init__impl_(new Long(0, 0)), _ULong___init__impl_(new Long(1119235616, 527589098)), _ULong___init__impl_(new Long(-897930618, 1280291444)), _ULong___init__impl_(new Long(-1401665990, 29))]));
    var tmp_75 = new ULongArray(tmp68_ulongArrayOf_0);
    var tmp69_ulongArrayOf_0 = _ULongArray___init__impl_(longArrayOf([_ULong___init__impl_(new Long(0, 0)), _ULong___init__impl_(new Long(-1692545728, 980923686)), _ULong___init__impl_(new Long(-389371586, 2065496207)), _ULong___init__impl_(new Long(-1131758007, 296))]));
    var tmp_76 = new ULongArray(tmp69_ulongArrayOf_0);
    var tmp70_ulongArrayOf_0 = _ULongArray___init__impl_(longArrayOf([_ULong___init__impl_(new Long(0, 0)), _ULong___init__impl_(new Long(254411904, 1219302274)), _ULong___init__impl_(new Long(401251440, 1327609247)), _ULong___init__impl_(new Long(1567321827, 2967))]));
    var tmp_77 = new ULongArray(tmp70_ulongArrayOf_0);
    var tmp71_ulongArrayOf_0 = _ULongArray___init__impl_(longArrayOf([_ULong___init__impl_(new Long(0, 0)), _ULong___init__impl_(new Long(-1750848256, 1455604500)), _ULong___init__impl_(new Long(-282452891, 391190582)), _ULong___init__impl_(new Long(-1506650908, 29673))]));
    var tmp_78 = new ULongArray(tmp71_ulongArrayOf_0);
    var tmp72_ulongArrayOf_0 = _ULongArray___init__impl_(longArrayOf([_ULong___init__impl_(new Long(0, 0)), _ULong___init__impl_(new Long(-328613376, 1671143117)), _ULong___init__impl_(new Long(1470438392, 1764422181)), _ULong___init__impl_(new Long(2113360105, 296736))]));
    var tmp_79 = new ULongArray(tmp72_ulongArrayOf_0);
    var tmp73_ulongArrayOf_0 = _ULongArray___init__impl_(longArrayOf([_ULong___init__impl_(new Long(0, 0)), _ULong___init__impl_(new Long(1008833536, 1679045643)), _ULong___init__impl_(new Long(1819482039, 464352629)), _ULong___init__impl_(new Long(-341235422, 2967364))]));
    var tmp_80 = new ULongArray(tmp73_ulongArrayOf_0);
    var tmp74_ulongArrayOf_0 = _ULongArray___init__impl_(longArrayOf([_ULong___init__impl_(new Long(0, 0)), _ULong___init__impl_(new Long(1498400768, 1758070896)), _ULong___init__impl_(new Long(1014951213, 348558998)), _ULong___init__impl_(new Long(882613078, 29673649))]));
    var tmp_81 = new ULongArray(tmp74_ulongArrayOf_0);
    var tmp75_ulongArrayOf_0 = _ULongArray___init__impl_(longArrayOf([_ULong___init__impl_(new Long(0, 0)), _ULong___init__impl_(new Long(2099105792, 400839779)), _ULong___init__impl_(new Long(1559577546, 1338106334)), _ULong___init__impl_(new Long(236196189, 296736492))]));
    var tmp_82 = new ULongArray(tmp75_ulongArrayOf_0);
    var tmp76_ulongArrayOf_0 = _ULongArray___init__impl_(longArrayOf([_ULong___init__impl_(new Long(0, 0)), _ULong___init__impl_(new Long(-483778560, 1860914146)), _ULong___init__impl_(new Long(-1584093723, 496161455)), _ULong___init__impl_(new Long(-1933005400, 819881272)), _ULong___init__impl_(new Long(1, 0))]));
    var tmp_83 = new ULongArray(tmp76_ulongArrayOf_0);
    var tmp77_ulongArrayOf_0 = _ULongArray___init__impl_(longArrayOf([_ULong___init__impl_(new Long(0, 0)), _ULong___init__impl_(new Long(-542818304, 1429272284)), _ULong___init__impl_(new Long(1338931962, 666647260)), _ULong___init__impl_(new Long(2144782482, 1756361781)), _ULong___init__impl_(new Long(13, 0))]));
    var tmp_84 = new ULongArray(tmp77_ulongArrayOf_0);
    var tmp78_ulongArrayOf_0 = _ULongArray___init__impl_(longArrayOf([_ULong___init__impl_(new Long(0, 0)), _ULong___init__impl_(new Long(-1133215744, 1407820960)), _ULong___init__impl_(new Long(504417738, 224021659)), _ULong___init__impl_(new Long(-27011657, 383748630)), _ULong___init__impl_(new Long(138, 0))]));
    var tmp_85 = new ULongArray(tmp78_ulongArrayOf_0);
    var tmp79_ulongArrayOf_0 = _ULongArray___init__impl_(longArrayOf([_ULong___init__impl_(new Long(0, 0)), _ULong___init__impl_(new Long(1552744448, 1193307719)), _ULong___init__impl_(new Long(749210090, 92732943)), _ULong___init__impl_(new Long(-270116569, 1690002661)), _ULong___init__impl_(new Long(1381, 0))]));
    var tmp_86 = new ULongArray(tmp79_ulongArrayOf_0);
    var tmp80_ulongArrayOf_0 = _ULongArray___init__impl_(longArrayOf([_ULong___init__impl_(new Long(0, 0)), _ULong___init__impl_(new Long(-1652424704, 1195658953)), _ULong___init__impl_(new Long(-1097833687, 927329431)), _ULong___init__impl_(new Long(1593801606, 1867641083)), _ULong___init__impl_(new Long(13817, 0))]));
    var tmp_87 = new ULongArray(tmp80_ulongArrayOf_0);
    var tmp81_ulongArrayOf_0 = _ULongArray___init__impl_(longArrayOf([_ULong___init__impl_(new Long(0, 0)), _ULong___init__impl_(new Long(655622144, 1219171296)), _ULong___init__impl_(new Long(1906565023, 683359725)), _ULong___init__impl_(new Long(-1241853120, 1496541649)), _ULong___init__impl_(new Long(138178, 0))]));
    var tmp_88 = new ULongArray(tmp81_ulongArrayOf_0);
    var tmp82_ulongArrayOf_0 = _ULongArray___init__impl_(longArrayOf([_ULong___init__impl_(new Long(0, 0)), _ULong___init__impl_(new Long(-2033713152, 1454294721)), _ULong___init__impl_(new Long(1885781051, 391146310)), _ULong___init__impl_(new Long(466370691, 2080514609)), _ULong___init__impl_(new Long(1381786, 0))]));
    var tmp_89 = new ULongArray(tmp82_ulongArrayOf_0);
    var tmp83_ulongArrayOf_0 = _ULongArray___init__impl_(longArrayOf([_ULong___init__impl_(new Long(0, 0)), _ULong___init__impl_(new Long(1137704960, 1658045327)), _ULong___init__impl_(new Long(1677941332, 1763979456)), _ULong___init__impl_(new Long(368739615, 1477793259)), _ULong___init__impl_(new Long(13817869, 0))]));
    var tmp_90 = new ULongArray(tmp83_ulongArrayOf_0);
    var tmp84_ulongArrayOf_0 = _ULongArray___init__impl_(longArrayOf([_ULong___init__impl_(new Long(0, 0)), _ULong___init__impl_(new Long(-1507852288, 1548067736)), _ULong___init__impl_(new Long(-400455857, 459925379)), _ULong___init__impl_(new Long(-607571138, 1893030702)), _ULong___init__impl_(new Long(138178696, 0))]));
    var tmp_91 = new ULongArray(tmp84_ulongArrayOf_0);
    var tmp85_ulongArrayOf_0 = _ULongArray___init__impl_(longArrayOf([_ULong___init__impl_(new Long(0, 0)), _ULong___init__impl_(new Long(2101346304, 448291830)), _ULong___init__impl_(new Long(290408733, 304286503)), _ULong___init__impl_(new Long(-1780744082, 1750437844)), _ULong___init__impl_(new Long(1381786968, 0))]));
    var tmp_92 = new ULongArray(tmp85_ulongArrayOf_0);
    var tmp86_ulongArrayOf_0 = _ULongArray___init__impl_(longArrayOf([_ULong___init__impl_(new Long(0, 0)), _ULong___init__impl_(new Long(-461373440, 187951008)), _ULong___init__impl_(new Long(-1390879964, 895381382)), _ULong___init__impl_(new Long(-627571635, 324509261)), _ULong___init__impl_(new Long(932967800, 3))]));
    var tmp_93 = new ULongArray(tmp86_ulongArrayOf_0);
    var tmp87_ulongArrayOf_0 = _ULongArray___init__impl_(longArrayOf([_ULong___init__impl_(new Long(0, 0)), _ULong___init__impl_(new Long(-318767104, 1879510088)), _ULong___init__impl_(new Long(-1023897752, 363879234)), _ULong___init__impl_(new Long(-1980749050, 1097608970)), _ULong___init__impl_(new Long(739743409, 32))]));
    var tmp_94 = new ULongArray(tmp87_ulongArrayOf_0);
    var tmp88_ulongArrayOf_0 = _ULongArray___init__impl_(longArrayOf([_ULong___init__impl_(new Long(0, 0)), _ULong___init__impl_(new Long(1107296256, 1615231705)), _ULong___init__impl_(new Long(-1649042920, 1491308699)), _ULong___init__impl_(new Long(1667345981, 238671465)), _ULong___init__impl_(new Long(-1192500497, 321))]));
    var tmp_95 = new ULongArray(tmp88_ulongArrayOf_0);
    var tmp89_ulongArrayOf_0 = _ULongArray___init__impl_(longArrayOf([_ULong___init__impl_(new Long(0, 0)), _ULong___init__impl_(new Long(-1811939328, 1119931516)), _ULong___init__impl_(new Long(689439991, 2028185108)), _ULong___init__impl_(new Long(-506409368, 239231005)), _ULong___init__impl_(new Long(959896919, 3217))]));
    var tmp_96 = new ULongArray(tmp89_ulongArrayOf_0);
    var tmp90_ulongArrayOf_0 = _ULongArray___init__impl_(longArrayOf([_ULong___init__impl_(new Long(0, 0)), _ULong___init__impl_(new Long(-939524096, 461896925)), _ULong___init__impl_(new Long(-1695534677, 954498249)), _ULong___init__impl_(new Long(-769126375, 244826410)), _ULong___init__impl_(new Long(1009034599, 32172))]));
    var tmp_97 = new ULongArray(tmp90_ulongArrayOf_0);
    var tmp91_ulongArrayOf_0 = _ULongArray___init__impl_(longArrayOf([_ULong___init__impl_(new Long(0, 0)), _ULong___init__impl_(new Long(-805306368, 324001961)), _ULong___init__impl_(new Long(224522416, 955047904)), _ULong___init__impl_(new Long(898670846, 300780460)), _ULong___init__impl_(new Long(1500411399, 321722))]));
    var tmp_98 = new ULongArray(tmp91_ulongArrayOf_0);
    var tmp92_ulongArrayOf_0 = _ULongArray___init__impl_(longArrayOf([_ULong___init__impl_(new Long(0, 0)), _ULong___init__impl_(new Long(536870912, 1092535970)), _ULong___init__impl_(new Long(-2049743135, 960544448)), _ULong___init__impl_(new Long(396773872, 860320954)), _ULong___init__impl_(new Long(2119212103, 3217223))]));
    var tmp_99 = new ULongArray(tmp92_ulongArrayOf_0);
    var tmp93_ulongArrayOf_0 = _ULongArray___init__impl_(longArrayOf([_ULong___init__impl_(new Long(0, 0)), _ULong___init__impl_(new Long(1073741824, 187941461)), _ULong___init__impl_(new Long(977405135, 1015509893)), _ULong___init__impl_(new Long(-327228572, 13274948)), _ULong___init__impl_(new Long(-282715446, 32172234))]));
    var tmp_100 = new ULongArray(tmp93_ulongArrayOf_0);
    var tmp94_ulongArrayOf_0 = _ULongArray___init__impl_(longArrayOf([_ULong___init__impl_(new Long(0, 0)), _ULong___init__impl_(new Long(-2147483648, 1879414612)), _ULong___init__impl_(new Long(1184116758, 1565164340)), _ULong___init__impl_(new Long(1022681580, 132749489)), _ULong___init__impl_(new Long(1467812836, 321722349))]));
    var tmp_101 = new ULongArray(tmp94_ulongArrayOf_0);
    var tmp95_ulongArrayOf_0 = _ULongArray___init__impl_(longArrayOf([_ULong___init__impl_(new Long(0, 0)), _ULong___init__impl_(new Long(0, 1614276941)), _ULong___init__impl_(new Long(-1043734300, 619257866)), _ULong___init__impl_(new Long(1636881215, 1327494892)), _ULong___init__impl_(new Long(1793226472, 1069739845)), _ULong___init__impl_(new Long(1, 0))]));
    var tmp_102 = new ULongArray(tmp95_ulongArrayOf_0);
    var tmp96_ulongArrayOf_0 = _ULongArray___init__impl_(longArrayOf([_ULong___init__impl_(new Long(0, 0)), _ULong___init__impl_(new Long(0, 1110383874)), _ULong___init__impl_(new Long(-1847408401, 1897611371)), _ULong___init__impl_(new Long(-811057032, 390047035)), _ULong___init__impl_(new Long(752395542, 2107463862)), _ULong___init__impl_(new Long(14, 0))]));
    var tmp_103 = new ULongArray(tmp96_ulongArrayOf_0);
    var tmp97_ulongArrayOf_0 = _ULongArray___init__impl_(longArrayOf([_ULong___init__impl_(new Long(0, 0)), _ULong___init__impl_(new Long(0, 366420500)), _ULong___init__impl_(new Long(-1294214821, 1796244531)), _ULong___init__impl_(new Long(479364280, 1752986710)), _ULong___init__impl_(new Long(-1065979171, 1747285789)), _ULong___init__impl_(new Long(149, 0))]));
    var tmp_104 = new ULongArray(tmp97_ulongArrayOf_0);
    var tmp98_ulongArrayOf_0 = _ULongArray___init__impl_(longArrayOf([_ULong___init__impl_(new Long(0, 0)), _ULong___init__impl_(new Long(0, 1516721352)), _ULong___init__impl_(new Long(-57246321, 782576132)), _ULong___init__impl_(new Long(498675512, 349997917)), _ULong___init__impl_(new Long(-2069857110, 292988713)), _ULong___init__impl_(new Long(1498, 0))]));
    var tmp_105 = new ULongArray(tmp98_ulongArrayOf_0);
    var tmp99_ulongArrayOf_0 = _ULongArray___init__impl_(longArrayOf([_ULong___init__impl_(new Long(0, 0)), _ULong___init__impl_(new Long(0, 134827984)), _ULong___init__impl_(new Long(-572463203, 1383310385)), _ULong___init__impl_(new Long(691787827, 1352495523)), _ULong___init__impl_(new Long(776265381, 782403487)), _ULong___init__impl_(new Long(14981, 0))]));
    var tmp_106 = new ULongArray(tmp99_ulongArrayOf_0);
    var tmp100_ulongArrayOf_0 = _ULongArray___init__impl_(longArrayOf([_ULong___init__impl_(new Long(0, 0)), _ULong___init__impl_(new Long(0, 1348279840)), _ULong___init__impl_(new Long(-1429664734, 948201970)), _ULong___init__impl_(new Long(-1672056316, 640053343)), _ULong___init__impl_(new Long(-827280776, 1381583927)), _ULong___init__impl_(new Long(149813, 0))]));
    var tmp101_arrayOf_0 = [tmp_7, tmp_8, tmp_9, tmp_10, tmp_11, tmp_12, tmp_13, tmp_14, tmp_15, tmp_16, tmp_17, tmp_18, tmp_19, tmp_20, tmp_21, tmp_22, tmp_23, tmp_24, tmp_25, tmp_26, tmp_27, tmp_28, tmp_29, tmp_30, tmp_31, tmp_32, tmp_33, tmp_34, tmp_35, tmp_36, tmp_37, tmp_38, tmp_39, tmp_40, tmp_41, tmp_42, tmp_43, tmp_44, tmp_45, tmp_46, tmp_47, tmp_48, tmp_49, tmp_50, tmp_51, tmp_52, tmp_53, tmp_54, tmp_55, tmp_56, tmp_57, tmp_58, tmp_59, tmp_60, tmp_61, tmp_62, tmp_63, tmp_64, tmp_65, tmp_66, tmp_67, tmp_68, tmp_69, tmp_70, tmp_71, tmp_72, tmp_73, tmp_74, tmp_75, tmp_76, tmp_77, tmp_78, tmp_79, tmp_80, tmp_81, tmp_82, tmp_83, tmp_84, tmp_85, tmp_86, tmp_87, tmp_88, tmp_89, tmp_90, tmp_91, tmp_92, tmp_93, tmp_94, tmp_95, tmp_96, tmp_97, tmp_98, tmp_99, tmp_100, tmp_101, tmp_102, tmp_103, tmp_104, tmp_105, tmp_106, new ULongArray(tmp100_ulongArrayOf_0)];
    tmp_6._powersOf10 = tmp101_arrayOf_0;
  }
  BigInteger63Arithmetic.prototype._get_ZERO__3 = function () {
    return this._ZERO_2;
  };
  BigInteger63Arithmetic.prototype._get_ONE__0 = function () {
    return this._ONE_2;
  };
  BigInteger63Arithmetic.prototype._get_TWO__0 = function () {
    return this._TWO_2;
  };
  BigInteger63Arithmetic.prototype._get_TEN__0 = function () {
    return this._TEN_2;
  };
  BigInteger63Arithmetic.prototype.numberOfLeadingZerosInAWord_0 = function (value_0) {
    var x = value_0;
    var y;
    var n = 63;
    var tmp0_shr_0 = x;
    var tmp1_shr_0 = 32;
    y = _ULong___init__impl_(_ULong___get_data__impl_(tmp0_shr_0).ushr_0(tmp1_shr_0));
    if (!equals_0(new ULong(y), new ULong(_ULong___init__impl_(new Long(0, 0))))) {
      n = n - 32 | 0;
      x = y;
    }var tmp2_shr_0 = x;
    var tmp3_shr_0 = 16;
    y = _ULong___init__impl_(_ULong___get_data__impl_(tmp2_shr_0).ushr_0(tmp3_shr_0));
    if (!equals_0(new ULong(y), new ULong(_ULong___init__impl_(new Long(0, 0))))) {
      n = n - 16 | 0;
      x = y;
    }var tmp4_shr_0 = x;
    var tmp5_shr_0 = 8;
    y = _ULong___init__impl_(_ULong___get_data__impl_(tmp4_shr_0).ushr_0(tmp5_shr_0));
    if (!equals_0(new ULong(y), new ULong(_ULong___init__impl_(new Long(0, 0))))) {
      n = n - 8 | 0;
      x = y;
    }var tmp6_shr_0 = x;
    var tmp7_shr_0 = 4;
    y = _ULong___init__impl_(_ULong___get_data__impl_(tmp6_shr_0).ushr_0(tmp7_shr_0));
    if (!equals_0(new ULong(y), new ULong(_ULong___init__impl_(new Long(0, 0))))) {
      n = n - 4 | 0;
      x = y;
    }var tmp8_shr_0 = x;
    var tmp9_shr_0 = 2;
    y = _ULong___init__impl_(_ULong___get_data__impl_(tmp8_shr_0).ushr_0(tmp9_shr_0));
    if (!equals_0(new ULong(y), new ULong(_ULong___init__impl_(new Long(0, 0))))) {
      n = n - 2 | 0;
      x = y;
    }var tmp10_shr_0 = x;
    var tmp11_shr_0 = 1;
    y = _ULong___init__impl_(_ULong___get_data__impl_(tmp10_shr_0).ushr_0(tmp11_shr_0));
    if (!equals_0(new ULong(y), new ULong(_ULong___init__impl_(new Long(0, 0))))) {
      return n - 2 | 0;
    }var tmp = n;
    var tmp12_toInt_0 = x;
    return tmp - _ULong___get_data__impl_(tmp12_toInt_0).toInt_5() | 0;
  };
  BigInteger63Arithmetic.prototype.numberOfLeadingZeroesInA64BitWord = function (value_0) {
    var x = value_0;
    var y;
    var n = 64;
    var tmp0_shr_0 = x;
    var tmp1_shr_0 = 32;
    y = _ULong___init__impl_(_ULong___get_data__impl_(tmp0_shr_0).ushr_0(tmp1_shr_0));
    if (!equals_0(new ULong(y), new ULong(_ULong___init__impl_(new Long(0, 0))))) {
      n = n - 32 | 0;
      x = y;
    }var tmp2_shr_0 = x;
    var tmp3_shr_0 = 16;
    y = _ULong___init__impl_(_ULong___get_data__impl_(tmp2_shr_0).ushr_0(tmp3_shr_0));
    if (!equals_0(new ULong(y), new ULong(_ULong___init__impl_(new Long(0, 0))))) {
      n = n - 16 | 0;
      x = y;
    }var tmp4_shr_0 = x;
    var tmp5_shr_0 = 8;
    y = _ULong___init__impl_(_ULong___get_data__impl_(tmp4_shr_0).ushr_0(tmp5_shr_0));
    if (!equals_0(new ULong(y), new ULong(_ULong___init__impl_(new Long(0, 0))))) {
      n = n - 8 | 0;
      x = y;
    }var tmp6_shr_0 = x;
    var tmp7_shr_0 = 4;
    y = _ULong___init__impl_(_ULong___get_data__impl_(tmp6_shr_0).ushr_0(tmp7_shr_0));
    if (!equals_0(new ULong(y), new ULong(_ULong___init__impl_(new Long(0, 0))))) {
      n = n - 4 | 0;
      x = y;
    }var tmp8_shr_0 = x;
    var tmp9_shr_0 = 2;
    y = _ULong___init__impl_(_ULong___get_data__impl_(tmp8_shr_0).ushr_0(tmp9_shr_0));
    if (!equals_0(new ULong(y), new ULong(_ULong___init__impl_(new Long(0, 0))))) {
      n = n - 2 | 0;
      x = y;
    }var tmp10_shr_0 = x;
    var tmp11_shr_0 = 1;
    y = _ULong___init__impl_(_ULong___get_data__impl_(tmp10_shr_0).ushr_0(tmp11_shr_0));
    if (!equals_0(new ULong(y), new ULong(_ULong___init__impl_(new Long(0, 0))))) {
      return n - 2 | 0;
    }var tmp = n;
    var tmp12_toInt_0 = x;
    return tmp - _ULong___get_data__impl_(tmp12_toInt_0).toInt_5() | 0;
  };
  BigInteger63Arithmetic.prototype.bitLength_2 = function (value_0) {
    if (isZero_0(value_0, this)) {
      return 0;
    }var start_0 = (_ULongArray___get_size__impl_(value_0) - this.countLeadingZeroWords_0(value_0) | 0) - 1 | 0;
    var mostSignificant = ULongArray__get_impl(value_0, start_0);
    return this.bitLength_3(mostSignificant) + imul(start_0, 63) | 0;
  };
  BigInteger63Arithmetic.prototype.bitLengthFor64BitArray = function (value_0) {
    if (isZero_0(value_0, this)) {
      return 0;
    }var mostSignificant = ULongArray__get_impl(value_0, _ULongArray___get_size__impl_(value_0) - 1 | 0);
    return this.bitLengthFor64BitWord(mostSignificant) + imul(_ULongArray___get_size__impl_(value_0) - 1 | 0, 64) | 0;
  };
  BigInteger63Arithmetic.prototype.bitLength_3 = function (value_0) {
    return 63 - this.numberOfLeadingZerosInAWord_0(value_0) | 0;
  };
  BigInteger63Arithmetic.prototype.bitLengthFor64BitWord = function (value_0) {
    return 64 - this.numberOfLeadingZeroesInA64BitWord(value_0) | 0;
  };
  BigInteger63Arithmetic.prototype.removeLeadingZeros_0 = function (bigInteger) {
    var correctedSize = _ULongArray___get_size__impl_(bigInteger) - this.countLeadingZeroWords_0(bigInteger) | 0;
    if (correctedSize === 0) {
      return this._ZERO_2;
    }if (_ULongArray___get_size__impl_(bigInteger) === correctedSize) {
      return bigInteger;
    }if ((_ULongArray___get_size__impl_(bigInteger) - correctedSize | 0) > 1000) {
      println('' + 'RLZ original array : ' + _ULongArray___get_size__impl_(bigInteger) + ' contains: ' + ((_ULongArray___get_size__impl_(bigInteger) - correctedSize | 0) - 1 | 0) + ' zeros');
    }var tmp0_copyOfRange_0 = 0;
    return _ULongArray___init__impl_(copyOfRange(_ULongArray___get_storage__impl_(bigInteger), tmp0_copyOfRange_0, correctedSize));
  };
  BigInteger63Arithmetic.prototype.countLeadingZeroWords_0 = function (bigInteger) {
    var lastNonEmptyIndex = _ULongArray___get_size__impl_(bigInteger) - 1 | 0;
    if (lastNonEmptyIndex <= 0) {
      return 0;
    }var element = ULongArray__get_impl(bigInteger, lastNonEmptyIndex);
    while (equals_0(new ULong(element), new ULong(_ULong___init__impl_(new Long(0, 0)))) ? lastNonEmptyIndex > 0 : false) {
      lastNonEmptyIndex = lastNonEmptyIndex - 1 | 0;
      element = ULongArray__get_impl(bigInteger, lastNonEmptyIndex);
    }
    if (equals_0(new ULong(ULongArray__get_impl(bigInteger, lastNonEmptyIndex)), new ULong(_ULong___init__impl_(new Long(0, 0))))) {
      lastNonEmptyIndex = lastNonEmptyIndex - 1 | 0;
    }return (_ULongArray___get_size__impl_(bigInteger) - lastNonEmptyIndex | 0) - 1 | 0;
  };
  BigInteger63Arithmetic.prototype.shiftLeft_1 = function (operand, places) {
    if (isZero_0(operand, this)) {
      return operand;
    }if (places === 0) {
      return operand;
    }if (ULongArray__isEmpty_impl(operand)) {
      return this._ZERO_2;
    }var leadingZeroWords = this.countLeadingZeroWords_0(operand);
    if (_ULongArray___get_size__impl_(operand) === leadingZeroWords) {
      return this._ZERO_2;
    }var originalSize = _ULongArray___get_size__impl_(operand) - leadingZeroWords | 0;
    var leadingZeros = this.numberOfLeadingZerosInAWord_0(ULongArray__get_impl(operand, originalSize - 1 | 0));
    var shiftWords = places / this._basePowerOfTwo_0 | 0;
    var shiftBits = places % this._basePowerOfTwo_0;
    var tmp;
    if (shiftBits > leadingZeros) {
      tmp = shiftWords + 1 | 0;
    } else {
      tmp = shiftWords;
    }
    var wordsNeeded = tmp;
    if (shiftBits === 0) {
      var tmp0_ULongArray_0 = originalSize + wordsNeeded | 0;
      var tmp_1 = 0;
      var tmp_2 = tmp0_ULongArray_0;
      var tmp_3 = longArray(tmp_2);
      while (tmp_1 < tmp_2) {
        var tmp_4 = tmp_1;
        var tmp0_subject_7 = tmp_4;
        var tmp0_toLong_0_5 = (0 <= tmp0_subject_7 ? tmp0_subject_7 < shiftWords : false) ? _ULong___init__impl_(new Long(0, 0)) : ULongArray__get_impl(operand, tmp_4 - shiftWords | 0);
        tmp_3[tmp_4] = _ULong___get_data__impl_(tmp0_toLong_0_5);
        tmp_1 = tmp_1 + 1 | 0;
      }
      return _ULongArray___init__impl_(tmp_3);
    }var tmp1_ULongArray_0 = originalSize + wordsNeeded | 0;
    var tmp_1_0 = 0;
    var tmp_2_0 = tmp1_ULongArray_0;
    var tmp_3_0 = longArray(tmp_2_0);
    while (tmp_1_0 < tmp_2_0) {
      var tmp_4_0 = tmp_1_0;
      var tmp0_subject_7_0 = tmp_4_0;
      var tmp_0;
      if (0 <= tmp0_subject_7_0 ? tmp0_subject_7_0 < shiftWords : false) {
        tmp_0 = _ULong___init__impl_(new Long(0, 0));
      } else {
        if (tmp0_subject_7_0 === shiftWords) {
          var tmp0_shl_0_9 = ULongArray__get_impl(operand, tmp_4_0 - shiftWords | 0);
          var tmp1_and_0_8 = _ULong___init__impl_(_ULong___get_data__impl_(tmp0_shl_0_9).shl_4(shiftBits));
          var tmp2_and_0_10 = BigInteger63Arithmetic_getInstance()._baseMask_0;
          tmp_0 = _ULong___init__impl_(_ULong___get_data__impl_(tmp1_and_0_8).and(_ULong___get_data__impl_(tmp2_and_0_10)));
        } else {
          var containsLower = shiftWords + 1 | 0;
          if (tmp0_subject_7_0 < (originalSize + shiftWords | 0) ? containsLower <= tmp0_subject_7_0 : false) {
            var tmp3_shl_0_13 = ULongArray__get_impl(operand, tmp_4_0 - shiftWords | 0);
            var tmp4_and_0_12 = _ULong___init__impl_(_ULong___get_data__impl_(tmp3_shl_0_13).shl_4(shiftBits));
            var tmp5_and_0_14 = BigInteger63Arithmetic_getInstance()._baseMask_0;
            var tmp8_or_0_11 = _ULong___init__impl_(_ULong___get_data__impl_(tmp4_and_0_12).and(_ULong___get_data__impl_(tmp5_and_0_14)));
            var tmp6_shr_0_16 = ULongArray__get_impl(operand, (tmp_4_0 - shiftWords | 0) - 1 | 0);
            var tmp7_shr_0_17 = BigInteger63Arithmetic_getInstance()._basePowerOfTwo_0 - shiftBits | 0;
            var tmp9_or_0_15 = _ULong___init__impl_(_ULong___get_data__impl_(tmp6_shr_0_16).ushr_0(tmp7_shr_0_17));
            tmp_0 = _ULong___init__impl_(_ULong___get_data__impl_(tmp8_or_0_11).or(_ULong___get_data__impl_(tmp9_or_0_15)));
          } else {
            if (tmp0_subject_7_0 === ((originalSize + wordsNeeded | 0) - 1 | 0)) {
              var tmp10_shr_0_18 = ULongArray__get_impl(operand, tmp_4_0 - wordsNeeded | 0);
              var tmp11_shr_0_19 = BigInteger63Arithmetic_getInstance()._basePowerOfTwo_0 - shiftBits | 0;
              tmp_0 = _ULong___init__impl_(_ULong___get_data__impl_(tmp10_shr_0_18).ushr_0(tmp11_shr_0_19));
            } else {
              {
                throw RuntimeException_init_$Create$('' + 'Invalid case ' + tmp_4_0);
              }
            }
          }
        }
      }
      var tmp0_toLong_0_5_0 = tmp_0;
      tmp_3_0[tmp_4_0] = _ULong___get_data__impl_(tmp0_toLong_0_5_0);
      tmp_1_0 = tmp_1_0 + 1 | 0;
    }
    return _ULongArray___init__impl_(tmp_3_0);
  };
  BigInteger63Arithmetic.prototype.shiftRight_1 = function (operand, places) {
    if (ULongArray__isEmpty_impl(operand) ? true : places === 0) {
      return operand;
    }var leadingZeroWords = this.countLeadingZeroWords_0(operand);
    var realOperandSize = _ULongArray___get_size__impl_(operand) - leadingZeroWords | 0;
    var shiftBits = places % this._basePowerOfTwo_0;
    var wordsToDiscard = places / this._basePowerOfTwo_0 | 0;
    if (wordsToDiscard >= realOperandSize) {
      return this._ZERO_2;
    }if (shiftBits === 0) {
      var tmp0_copyOfRange_0 = realOperandSize - wordsToDiscard | 0;
      _ULongArray___init__impl_(copyOfRange(_ULongArray___get_storage__impl_(operand), tmp0_copyOfRange_0, realOperandSize));
      Unit_getInstance();
    }if (realOperandSize > 1 ? (realOperandSize - wordsToDiscard | 0) === 1 : false) {
      var tmp1_shr_0 = ULongArray__get_impl(operand, realOperandSize - 1 | 0);
      var tmp2_ulongArrayOf_0 = _ULongArray___init__impl_(longArrayOf([_ULong___init__impl_(_ULong___get_data__impl_(tmp1_shr_0).ushr_0(shiftBits))]));
      return tmp2_ulongArrayOf_0;
    }var newLength = realOperandSize - wordsToDiscard | 0;
    if (newLength === 0) {
      return this._ZERO_2;
    }var tmp3_ULongArray_0 = realOperandSize - wordsToDiscard | 0;
    var tmp_1 = 0;
    var tmp_2 = tmp3_ULongArray_0;
    var tmp_3 = longArray(tmp_2);
    while (tmp_1 < tmp_2) {
      var tmp_4 = tmp_1;
      var tmp0_subject_7 = tmp_4;
      var tmp;
      if (0 <= tmp0_subject_7 ? tmp0_subject_7 < ((realOperandSize - 1 | 0) - wordsToDiscard | 0) : false) {
        var tmp0_shr_0_9 = ULongArray__get_impl(operand, tmp_4 + wordsToDiscard | 0);
        var tmp5_or_0_8 = _ULong___init__impl_(_ULong___get_data__impl_(tmp0_shr_0_9).ushr_0(shiftBits));
        var tmp1_shl_0_12 = ULongArray__get_impl(operand, (tmp_4 + wordsToDiscard | 0) + 1 | 0);
        var tmp2_shl_0_13 = BigInteger63Arithmetic_getInstance()._basePowerOfTwo_0 - shiftBits | 0;
        var tmp3_and_0_11 = _ULong___init__impl_(_ULong___get_data__impl_(tmp1_shl_0_12).shl_4(tmp2_shl_0_13));
        var tmp4_and_0_14 = BigInteger63Arithmetic_getInstance()._baseMask_0;
        var tmp6_or_0_10 = _ULong___init__impl_(_ULong___get_data__impl_(tmp3_and_0_11).and(_ULong___get_data__impl_(tmp4_and_0_14)));
        tmp = _ULong___init__impl_(_ULong___get_data__impl_(tmp5_or_0_8).or(_ULong___get_data__impl_(tmp6_or_0_10)));
      } else if (tmp0_subject_7 === ((realOperandSize - 1 | 0) - wordsToDiscard | 0)) {
        var tmp7_shr_0_15 = ULongArray__get_impl(operand, tmp_4 + wordsToDiscard | 0);
        tmp = _ULong___init__impl_(_ULong___get_data__impl_(tmp7_shr_0_15).ushr_0(shiftBits));
      } else {
        throw RuntimeException_init_$Create$('' + 'Invalid case ' + tmp_4);
      }
      var tmp0_toLong_0_5 = tmp;
      tmp_3[tmp_4] = _ULong___get_data__impl_(tmp0_toLong_0_5);
      tmp_1 = tmp_1 + 1 | 0;
    }
    var result = _ULongArray___init__impl_(tmp_3);
    return result;
  };
  BigInteger63Arithmetic.prototype.compareWithStartIndexes = function (first_4, second, firstStart, secondStart) {
    if (firstStart > secondStart) {
      return 1;
    }if (secondStart > firstStart) {
      return -1;
    }var counter_0 = firstStart - 1 | 0;
    var firstIsLarger = false;
    var bothAreEqual = true;
    $l$break_0: while (counter_0 >= 0) {
      var tmp0_compareTo_0 = ULongArray__get_impl(first_4, counter_0);
      var tmp1_compareTo_0 = ULongArray__get_impl(second, counter_0);
      if (ulongCompare(_ULong___get_data__impl_(tmp0_compareTo_0), _ULong___get_data__impl_(tmp1_compareTo_0)) > 0) {
        firstIsLarger = true;
        bothAreEqual = false;
        break $l$break_0;
      } else {
      }
      var tmp2_compareTo_0 = ULongArray__get_impl(first_4, counter_0);
      var tmp3_compareTo_0 = ULongArray__get_impl(second, counter_0);
      if (ulongCompare(_ULong___get_data__impl_(tmp2_compareTo_0), _ULong___get_data__impl_(tmp3_compareTo_0)) < 0) {
        firstIsLarger = false;
        bothAreEqual = false;
        break $l$break_0;
      } else {
      }
      var tmp0 = counter_0;
      counter_0 = tmp0 - 1 | 0;
      Unit_getInstance();
    }
    if (bothAreEqual) {
      return 0;
    }if (firstIsLarger) {
      return 1;
    } else {
      return -1;
    }
  };
  BigInteger63Arithmetic.prototype.compare_4 = function (first_4, second) {
    var firstStart = _ULongArray___get_size__impl_(first_4) - this.countLeadingZeroWords_0(first_4) | 0;
    var secondStart = _ULongArray___get_size__impl_(second) - this.countLeadingZeroWords_0(second) | 0;
    return this.compareWithStartIndexes(first_4, second, firstStart, secondStart);
  };
  BigInteger63Arithmetic.prototype.baseAddIntoArray = function (resultArray, resultArrayStart, first_4, second) {
    if (isZero_0(first_4, this)) {
      var tmp0_copyInto_0 = 0;
      var tmp1_copyInto_0 = _ULongArray___get_size__impl_(first_4);
      var tmp0_copyInto_0_1 = _ULongArray___get_storage__impl_(first_4);
      var tmp1_copyInto_0_2 = _ULongArray___get_storage__impl_(resultArray);
      var tmp = tmp0_copyInto_0_1;
      arrayCopy_0(tmp, tmp1_copyInto_0_2, resultArrayStart, tmp0_copyInto_0, tmp1_copyInto_0);
      Unit_getInstance();
      Unit_getInstance();
      return Unit_getInstance();
    }if (isZero_0(second, this)) {
      var tmp2_copyInto_0 = 0;
      var tmp3_copyInto_0 = _ULongArray___get_size__impl_(second);
      var tmp0_copyInto_0_1_0 = _ULongArray___get_storage__impl_(second);
      var tmp1_copyInto_0_2_0 = _ULongArray___get_storage__impl_(resultArray);
      var tmp_0 = tmp0_copyInto_0_1_0;
      arrayCopy_0(tmp_0, tmp1_copyInto_0_2_0, resultArrayStart, tmp2_copyInto_0, tmp3_copyInto_0);
      Unit_getInstance();
      Unit_getInstance();
      return Unit_getInstance();
    }var firstStart = _ULongArray___get_size__impl_(first_4) - this.countLeadingZeroWords_0(first_4) | 0;
    var secondStart = _ULongArray___get_size__impl_(second) - this.countLeadingZeroWords_0(second) | 0;
    var tmp_1;
    if (firstStart > secondStart) {
      tmp_1 = new Sextuple(_ULongArray___get_size__impl_(first_4), _ULongArray___get_size__impl_(second), new ULongArray(first_4), new ULongArray(second), firstStart, secondStart);
    } else {
      tmp_1 = new Sextuple(_ULongArray___get_size__impl_(second), _ULongArray___get_size__impl_(first_4), new ULongArray(second), new ULongArray(first_4), secondStart, firstStart);
    }
    var tmp0_container = tmp_1;
    var largerLength = tmp0_container.component1_4();
    var smallerLength = tmp0_container.component2_4();
    var largerData = tmp0_container.component3_1()._storage_1;
    var smallerData = tmp0_container.component4_0()._storage_1;
    var largerStart = tmp0_container.component5();
    var smallerStart = tmp0_container.component6();
    var i = 0;
    var sum = _ULong___init__impl_(new Long(0, 0));
    while (i < smallerStart) {
      var tmp4_plus_0 = sum;
      var tmp5_plus_0 = ULongArray__get_impl(largerData, i);
      var tmp6_plus_0 = _ULong___init__impl_(_ULong___get_data__impl_(tmp4_plus_0).plus_71(_ULong___get_data__impl_(tmp5_plus_0)));
      var tmp7_plus_0 = ULongArray__get_impl(smallerData, i);
      sum = _ULong___init__impl_(_ULong___get_data__impl_(tmp6_plus_0).plus_71(_ULong___get_data__impl_(tmp7_plus_0)));
      var tmp_2 = i + resultArrayStart | 0;
      var tmp8_and_0 = sum;
      var tmp9_and_0 = this._baseMask_0;
      ULongArray__set_impl(resultArray, tmp_2, _ULong___init__impl_(_ULong___get_data__impl_(tmp8_and_0).and(_ULong___get_data__impl_(tmp9_and_0))));
      var tmp10_shr_0 = sum;
      var tmp11_shr_0 = 63;
      sum = _ULong___init__impl_(_ULong___get_data__impl_(tmp10_shr_0).ushr_0(tmp11_shr_0));
      var tmp1 = i;
      i = tmp1 + 1 | 0;
      Unit_getInstance();
    }
    while (true) {
      if (equals_0(new ULong(sum), new ULong(_ULong___init__impl_(new Long(0, 0))))) {
        while (i < largerStart) {
          ULongArray__set_impl(resultArray, i + resultArrayStart | 0, ULongArray__get_impl(largerData, i));
          var tmp2 = i;
          i = tmp2 + 1 | 0;
          Unit_getInstance();
        }
        return Unit_getInstance();
      }if (i === largerLength) {
        ULongArray__set_impl(resultArray, largerLength + resultArrayStart | 0, sum);
        return Unit_getInstance();
      }var tmp12_plus_0 = sum;
      var tmp13_plus_0 = ULongArray__get_impl(largerData, i);
      sum = _ULong___init__impl_(_ULong___get_data__impl_(tmp12_plus_0).plus_71(_ULong___get_data__impl_(tmp13_plus_0)));
      var tmp_3 = i;
      var tmp14_and_0 = sum;
      var tmp15_and_0 = this._baseMask_0;
      ULongArray__set_impl(resultArray, tmp_3, _ULong___init__impl_(_ULong___get_data__impl_(tmp14_and_0).and(_ULong___get_data__impl_(tmp15_and_0))));
      var tmp16_shr_0 = sum;
      var tmp17_shr_0 = 63;
      sum = _ULong___init__impl_(_ULong___get_data__impl_(tmp16_shr_0).ushr_0(tmp17_shr_0));
      var tmp3 = i;
      i = tmp3 + 1 | 0;
      Unit_getInstance();
    }
  };
  BigInteger63Arithmetic.prototype.add_32 = function (first_4, second) {
    if (isZero_0(first_4, this))
      return second;
    if (isZero_0(second, this))
      return first_4;
    var firstStart = _ULongArray___get_size__impl_(first_4) - this.countLeadingZeroWords_0(first_4) | 0;
    var secondStart = _ULongArray___get_size__impl_(second) - this.countLeadingZeroWords_0(second) | 0;
    var tmp;
    if (firstStart > secondStart) {
      tmp = new Sextuple(_ULongArray___get_size__impl_(first_4), _ULongArray___get_size__impl_(second), new ULongArray(first_4), new ULongArray(second), firstStart, secondStart);
    } else {
      tmp = new Sextuple(_ULongArray___get_size__impl_(second), _ULongArray___get_size__impl_(first_4), new ULongArray(second), new ULongArray(first_4), secondStart, firstStart);
    }
    var tmp0_container = tmp;
    var largerLength = tmp0_container.component1_4();
    var smallerLength = tmp0_container.component2_4();
    var largerData = tmp0_container.component3_1()._storage_1;
    var smallerData = tmp0_container.component4_0()._storage_1;
    var largerStart = tmp0_container.component5();
    var smallerStart = tmp0_container.component6();
    var firstMostSignificant_1 = ULongArray__get_impl(largerData, largerStart - 1 | 0);
    var secondMostSignificant_2 = ULongArray__get_impl(smallerData, smallerStart - 1 | 0);
    var tmp_0;
    var tmp0_and_0 = firstMostSignificant_1;
    var tmp1_and_0 = _ULong___init__impl_(new Long(0, 1610612736));
    if (!equals_0(new ULong(_ULong___init__impl_(_ULong___get_data__impl_(tmp0_and_0).and(_ULong___get_data__impl_(tmp1_and_0)))), new ULong(_ULong___init__impl_(new Long(0, 0))))) {
      tmp_0 = true;
    } else {
      {
        var tmp2_and_0 = secondMostSignificant_2;
        var tmp3_and_0 = _ULong___init__impl_(new Long(0, 1610612736));
        tmp_0 = !equals_0(new ULong(_ULong___init__impl_(_ULong___get_data__impl_(tmp2_and_0).and(_ULong___get_data__impl_(tmp3_and_0)))), new ULong(_ULong___init__impl_(new Long(0, 0))));
      }
    }
    var possibleOverflow_3 = tmp_0;
    var possibleOverflow = possibleOverflow_3;
    var tmp_1;
    if (possibleOverflow) {
      var tmp4_ULongArray_0 = largerLength + 1 | 0;
      var tmp_1_0 = 0;
      var tmp_2 = tmp4_ULongArray_0;
      var tmp_3 = longArray(tmp_2);
      while (tmp_1_0 < tmp_2) {
        var tmp_4 = tmp_1_0;
        var tmp0_toLong_0_5 = _ULong___init__impl_(new Long(0, 0));
        tmp_3[tmp_4] = _ULong___get_data__impl_(tmp0_toLong_0_5);
        tmp_1_0 = tmp_1_0 + 1 | 0;
      }
      tmp_1 = _ULongArray___init__impl_(tmp_3);
    } else {
      var tmp_1_1 = 0;
      var tmp_2_0 = largerLength;
      var tmp_3_0 = longArray(tmp_2_0);
      while (tmp_1_1 < tmp_2_0) {
        var tmp_4_0 = tmp_1_1;
        var tmp0_toLong_0_5_0 = _ULong___init__impl_(new Long(0, 0));
        tmp_3_0[tmp_4_0] = _ULong___get_data__impl_(tmp0_toLong_0_5_0);
        tmp_1_1 = tmp_1_1 + 1 | 0;
      }
      tmp_1 = _ULongArray___init__impl_(tmp_3_0);
    }
    var result = tmp_1;
    this.baseAddIntoArray(result, 0, first_4, second);
    var tmp_5;
    if (possibleOverflow) {
      tmp_5 = this.removeLeadingZeros_0(result);
    } else {
      tmp_5 = result;
    }
    return tmp_5;
  };
  BigInteger63Arithmetic.prototype.subtractWithStartIndexes = function (first_4, second, firstStart, secondStart) {
    var comparison = this.compareWithStartIndexes(first_4, second, firstStart, secondStart);
    var firstSize = firstStart + 1 | 0;
    var secondSize = secondStart + 1 | 0;
    var firstIsLarger = comparison === 1;
    if (comparison === 0)
      return this._ZERO_2;
    if (secondSize === 1 ? equals_0(new ULong(ULongArray__get_impl(second, 0)), new ULong(_ULong___init__impl_(new Long(0, 0)))) : false) {
      return first_4;
    }if (!firstIsLarger) {
      throw RuntimeException_init_$Create$('subtract result less than zero');
    }var tmp;
    if (firstIsLarger) {
      tmp = new Quadruple(new ULongArray(first_4), new ULongArray(second), firstStart, secondStart);
    } else {
      tmp = new Quadruple(new ULongArray(second), new ULongArray(first_4), secondStart, firstStart);
    }
    var tmp0_container = tmp;
    var largerData = tmp0_container.component1_4()._storage_1;
    var smallerData = tmp0_container.component2_4()._storage_1;
    var largerStart = tmp0_container.component3_1();
    var smallerStart = tmp0_container.component4_0();
    var tmp_1 = 0;
    var tmp_2 = largerStart;
    var tmp_3 = longArray(tmp_2);
    while (tmp_1 < tmp_2) {
      var tmp_4 = tmp_1;
      var tmp0_toLong_0_5 = _ULong___init__impl_(new Long(0, 0));
      tmp_3[tmp_4] = _ULong___get_data__impl_(tmp0_toLong_0_5);
      tmp_1 = tmp_1 + 1 | 0;
    }
    var result = _ULongArray___init__impl_(tmp_3);
    var i = 0;
    var diff = _ULong___init__impl_(new Long(0, 0));
    while (i < smallerStart) {
      var tmp0_minus_0 = ULongArray__get_impl(largerData, i);
      var tmp1_minus_0 = ULongArray__get_impl(smallerData, i);
      var tmp2_minus_0 = _ULong___init__impl_(_ULong___get_data__impl_(tmp0_minus_0).minus_40(_ULong___get_data__impl_(tmp1_minus_0)));
      var tmp3_minus_0 = diff;
      diff = _ULong___init__impl_(_ULong___get_data__impl_(tmp2_minus_0).minus_40(_ULong___get_data__impl_(tmp3_minus_0)));
      var tmp_0 = i;
      var tmp4_and_0 = diff;
      var tmp5_and_0 = this._baseMask_0;
      ULongArray__set_impl(result, tmp_0, _ULong___init__impl_(_ULong___get_data__impl_(tmp4_and_0).and(_ULong___get_data__impl_(tmp5_and_0))));
      var tmp6_shr_0 = diff;
      var tmp7_shr_0 = 63;
      diff = _ULong___init__impl_(_ULong___get_data__impl_(tmp6_shr_0).ushr_0(tmp7_shr_0));
      var tmp1 = i;
      i = tmp1 + 1 | 0;
      Unit_getInstance();
    }
    while (!equals_0(new ULong(diff), new ULong(_ULong___init__impl_(new Long(0, 0))))) {
      var tmp8_minus_0 = ULongArray__get_impl(largerData, i);
      var tmp9_minus_0 = diff;
      diff = _ULong___init__impl_(_ULong___get_data__impl_(tmp8_minus_0).minus_40(_ULong___get_data__impl_(tmp9_minus_0)));
      var tmp_5 = i;
      var tmp10_and_0 = diff;
      var tmp11_and_0 = this._baseMask_0;
      ULongArray__set_impl(result, tmp_5, _ULong___init__impl_(_ULong___get_data__impl_(tmp10_and_0).and(_ULong___get_data__impl_(tmp11_and_0))));
      var tmp12_shr_0 = diff;
      var tmp13_shr_0 = 63;
      diff = _ULong___init__impl_(_ULong___get_data__impl_(tmp12_shr_0).ushr_0(tmp13_shr_0));
      var tmp2 = i;
      i = tmp2 + 1 | 0;
      Unit_getInstance();
    }
    while (i < largerStart) {
      ULongArray__set_impl(result, i, ULongArray__get_impl(largerData, i));
      var tmp3 = i;
      i = tmp3 + 1 | 0;
      Unit_getInstance();
    }
    if (this.countLeadingZeroWords_0(result) === (_ULongArray___get_size__impl_(result) - 1 | 0) ? equals_0(new ULong(ULongArray__get_impl(result, 0)), new ULong(_ULong___init__impl_(new Long(0, 0)))) : false) {
      return this._ZERO_2;
    }return this.removeLeadingZeros_0(result);
  };
  BigInteger63Arithmetic.prototype.subtract_7 = function (first_4, second) {
    var firstStart = _ULongArray___get_size__impl_(first_4) - this.countLeadingZeroWords_0(first_4) | 0;
    var secondStart = _ULongArray___get_size__impl_(second) - this.countLeadingZeroWords_0(second) | 0;
    return this.subtractWithStartIndexes(first_4, second, firstStart, secondStart);
  };
  BigInteger63Arithmetic.prototype.multiply_8 = function (first_4, second) {
    var firstCorrectedSize = _ULongArray___get_size__impl_(first_4) - this.countLeadingZeroWords_0(first_4) | 0;
    var secondCorrectedSize = _ULongArray___get_size__impl_(second) - this.countLeadingZeroWords_0(second) | 0;
    return multiplyWithCorrectedSize(this, first_4, second, firstCorrectedSize, secondCorrectedSize);
  };
  BigInteger63Arithmetic.prototype.extendULongArray = function (original, numberOfWords, value_0) {
    var tmp0_ULongArray_0 = _ULongArray___get_size__impl_(original) + numberOfWords | 0;
    var tmp_1 = 0;
    var tmp_2 = tmp0_ULongArray_0;
    var tmp_3 = longArray(tmp_2);
    while (tmp_1 < tmp_2) {
      var tmp_4 = tmp_1;
      var tmp0_toLong_0_5 = tmp_4 < _ULongArray___get_size__impl_(original) ? ULongArray__get_impl(original, tmp_4) : value_0;
      tmp_3[tmp_4] = _ULong___get_data__impl_(tmp0_toLong_0_5);
      tmp_1 = tmp_1 + 1 | 0;
    }
    return _ULongArray___init__impl_(tmp_3);
  };
  BigInteger63Arithmetic.prototype.toomCook3Multiply = function (firstUnchecked, secondUnchecked) {
    var tmp;
    if (!(_ULongArray___get_size__impl_(firstUnchecked) % 3 === 0)) {
      var tmp_0 = new ULongArray(firstUnchecked);
      var tmp0_ULongArray_0 = imul((_ULongArray___get_size__impl_(firstUnchecked) + 2 | 0) / 3 | 0, 3) - _ULongArray___get_size__impl_(firstUnchecked) | 0;
      var tmp_1 = 0;
      var tmp_2 = tmp0_ULongArray_0;
      var tmp_3 = longArray(tmp_2);
      while (tmp_1 < tmp_2) {
        var tmp_4 = tmp_1;
        var tmp0_toLong_0_5 = _ULong___init__impl_(new Long(0, 0));
        tmp_3[tmp_4] = _ULong___get_data__impl_(tmp0_toLong_0_5);
        tmp_1 = tmp_1 + 1 | 0;
      }
      var tmp1_asIterable_0 = _ULongArray___init__impl_(tmp_3);
      tmp = plus(tmp_0, new ULongArray(tmp1_asIterable_0));
    } else {
      tmp = new ULongArray(firstUnchecked);
    }
    var first_4 = toULongArray(tmp);
    var tmp_5;
    if (!(_ULongArray___get_size__impl_(secondUnchecked) % 3 === 0)) {
      var tmp_6 = new ULongArray(secondUnchecked);
      var tmp2_ULongArray_0 = imul((_ULongArray___get_size__impl_(secondUnchecked) + 2 | 0) / 3 | 0, 3) - _ULongArray___get_size__impl_(secondUnchecked) | 0;
      var tmp_1_0 = 0;
      var tmp_2_0 = tmp2_ULongArray_0;
      var tmp_3_0 = longArray(tmp_2_0);
      while (tmp_1_0 < tmp_2_0) {
        var tmp_4_0 = tmp_1_0;
        var tmp0_toLong_0_5_0 = _ULong___init__impl_(new Long(0, 0));
        tmp_3_0[tmp_4_0] = _ULong___get_data__impl_(tmp0_toLong_0_5_0);
        tmp_1_0 = tmp_1_0 + 1 | 0;
      }
      var tmp3_asIterable_0 = _ULongArray___init__impl_(tmp_3_0);
      tmp_5 = plus(tmp_6, new ULongArray(tmp3_asIterable_0));
    } else {
      tmp_5 = new ULongArray(secondUnchecked);
    }
    var second = toULongArray(tmp_5);
    var firstLength = _ULongArray___get_size__impl_(first_4);
    var secondLength = _ULongArray___get_size__impl_(second);
    var tmp_7;
    if (firstLength > secondLength) {
      var prepared = this.extendULongArray(second, firstLength - secondLength | 0, _ULong___init__impl_(new Long(0, 0)));
      tmp_7 = new Pair(new ULongArray(first_4), new ULongArray(prepared));
    } else if (firstLength < secondLength) {
      var prepared_0 = this.extendULongArray(first_4, secondLength - firstLength | 0, _ULong___init__impl_(new Long(0, 0)));
      tmp_7 = new Pair(new ULongArray(prepared_0), new ULongArray(second));
    } else {
      tmp_7 = new Pair(new ULongArray(first_4), new ULongArray(second));
    }
    var tmp0_container = tmp_7;
    var firstPrepared = tmp0_container.component1_4()._storage_1;
    var secondPrepared = tmp0_container.component2_4()._storage_1;
    var tmp4_max_0 = _ULongArray___get_size__impl_(first_4);
    var tmp5_max_0 = _ULongArray___get_size__impl_(second);
    var longestLength = function () {
      var $externalVarargReceiverTmp = Math;
      return $externalVarargReceiverTmp.max.apply($externalVarargReceiverTmp, [].concat([].slice.call(new Int32Array([tmp4_max_0, tmp5_max_0]))));
    }.call(this);
    var extendedDigit = (longestLength + 2 | 0) / 3 | 0;
    var m0 = new SignedULongArray(toULongArray(slice_1(firstPrepared, until(0, extendedDigit))), true);
    var m1 = new SignedULongArray(toULongArray(slice_1(firstPrepared, until(extendedDigit, imul(extendedDigit, 2)))), true);
    var m2 = new SignedULongArray(toULongArray(slice_1(firstPrepared, until(imul(extendedDigit, 2), imul(extendedDigit, 3)))), true);
    var n0 = new SignedULongArray(toULongArray(slice_1(secondPrepared, until(0, extendedDigit))), true);
    var n1 = new SignedULongArray(toULongArray(slice_1(secondPrepared, until(extendedDigit, imul(extendedDigit, 2)))), true);
    var n2 = new SignedULongArray(toULongArray(slice_1(secondPrepared, until(imul(extendedDigit, 2), imul(extendedDigit, 3)))), true);
    var p0 = this.plus_10(m0, m2);
    var pe0 = m0;
    var pe1 = this.plus_10(p0, m1);
    var pem1 = this.minus_9(p0, m1);
    var doublePemM2 = this.times_7(this.plus_10(pem1, m2), this._SIGNED_POSITIVE_TWO_0);
    var pem2 = this.minus_9(doublePemM2, m0);
    var pinf = m2;
    var q0 = this.plus_10(n0, n2);
    var qe0 = n0;
    var qe1 = this.plus_10(q0, n1);
    var qem1 = this.minus_9(q0, n1);
    var doubleQemN2 = this.times_7(this.plus_10(qem1, n2), this._SIGNED_POSITIVE_TWO_0);
    var qem2 = this.minus_9(doubleQemN2, n0);
    var qinf = n2;
    var re0 = this.times_7(pe0, qe0);
    var re1 = this.times_7(pe1, qe1);
    var rem1 = this.times_7(pem1, qem1);
    var rem2 = this.times_7(pem2, qem2);
    var rinf = this.times_7(pinf, qinf);
    var r0 = re0;
    var r4 = rinf;
    var rem2re1diff = this.minus_9(rem2, re1);
    var tmp6_ulongArrayOf_0 = _ULongArray___init__impl_(longArrayOf([_ULong___init__impl_(new Long(3, 0))]));
    var r3 = this.div_5(rem2re1diff, new SignedULongArray(tmp6_ulongArrayOf_0, true));
    var r1 = this.shr_2(this.minus_9(re1, rem1), 1);
    var r2 = this.minus_9(rem1, r0);
    r3 = this.plus_10(this.shr_2(this.minus_9(r2, r3), 1), this.times_7(this._SIGNED_POSITIVE_TWO_0, rinf));
    r2 = this.minus_9(this.plus_10(r2, r1), r4);
    r1 = this.minus_9(r1, r3);
    var bShiftAmount = imul(extendedDigit, 63);
    var rb0 = r0;
    var rb1 = this.shl_2(r1, bShiftAmount);
    var rb2 = this.shl_2(r2, imul(bShiftAmount, 2));
    var rb3 = this.shl_2(r3, imul(bShiftAmount, 3));
    var rb4 = this.shl_2(r4, imul(bShiftAmount, 4));
    var rb = this.plus_10(this.plus_10(this.plus_10(this.plus_10(rb0, rb1), rb2), rb3), rb4);
    return rb._unsignedValue_0;
  };
  BigInteger63Arithmetic.prototype.baseMultiply = function (first_4, second) {
    var firstCorrectedSize = _ULongArray___get_size__impl_(first_4) - this.countLeadingZeroWords_0(first_4) | 0;
    return this.baseMultiplyWithCorrectedSize(first_4, second, firstCorrectedSize);
  };
  BigInteger63Arithmetic.prototype.baseMultiplyWithCorrectedSize = function (first_4, second, firstCorrectedSize) {
    var tmp0_and_0 = this._lowMask;
    var secondLow = _ULong___init__impl_(_ULong___get_data__impl_(second).and(_ULong___get_data__impl_(tmp0_and_0)));
    var tmp1_shr_0 = 32;
    var secondHigh = _ULong___init__impl_(_ULong___get_data__impl_(second).ushr_0(tmp1_shr_0));
    var requiredBits = this.bitLength_2(first_4) + this.bitLength_3(second) | 0;
    var tmp;
    if (!(requiredBits % 63 === 0)) {
      tmp = (requiredBits / 63 | 0) + 1 | 0;
    } else {
      tmp = requiredBits / 63 | 0;
    }
    var requiredWords = tmp;
    var result = _ULongArray___init__impl__0(requiredWords);
    var carryIntoNextRound = _ULong___init__impl_(new Long(0, 0));
    var i = 0;
    var j = 0;
    while (i < firstCorrectedSize) {
      var tmp2_and_0 = ULongArray__get_impl(first_4, i);
      var tmp3_and_0 = this._lowMask;
      var firstLow = _ULong___init__impl_(_ULong___get_data__impl_(tmp2_and_0).and(_ULong___get_data__impl_(tmp3_and_0)));
      var tmp4_shr_0 = ULongArray__get_impl(first_4, i);
      var tmp5_shr_0 = 32;
      var firstHigh = _ULong___init__impl_(_ULong___get_data__impl_(tmp4_shr_0).ushr_0(tmp5_shr_0));
      var tmp0 = i;
      i = tmp0 + 1 | 0;
      Unit_getInstance();
      var lowerProduct = _ULong___init__impl_(_ULong___get_data__impl_(firstLow).times_40(_ULong___get_data__impl_(secondLow)));
      var tmp6_shr_0 = 63;
      var lowerCarry = _ULong___init__impl_(_ULong___get_data__impl_(lowerProduct).ushr_0(tmp6_shr_0));
      var tmp8_plus_0 = carryIntoNextRound;
      var tmp7_and_0 = this._baseMask_0;
      var tmp9_plus_0 = _ULong___init__impl_(_ULong___get_data__impl_(lowerProduct).and(_ULong___get_data__impl_(tmp7_and_0)));
      var lowResult = _ULong___init__impl_(_ULong___get_data__impl_(tmp8_plus_0).plus_71(_ULong___get_data__impl_(tmp9_plus_0)));
      var tmp12_plus_0 = lowerCarry;
      var tmp10_shr_0 = lowResult;
      var tmp11_shr_0 = 63;
      var tmp13_plus_0 = _ULong___init__impl_(_ULong___get_data__impl_(tmp10_shr_0).ushr_0(tmp11_shr_0));
      lowerCarry = _ULong___init__impl_(_ULong___get_data__impl_(tmp12_plus_0).plus_71(_ULong___get_data__impl_(tmp13_plus_0)));
      var tmp14_and_0 = lowResult;
      var tmp15_and_0 = this._baseMask_0;
      lowResult = _ULong___init__impl_(_ULong___get_data__impl_(tmp14_and_0).and(_ULong___get_data__impl_(tmp15_and_0)));
      var tmp16_plus_0 = _ULong___init__impl_(_ULong___get_data__impl_(firstLow).times_40(_ULong___get_data__impl_(secondHigh)));
      var tmp17_plus_0 = _ULong___init__impl_(_ULong___get_data__impl_(secondLow).times_40(_ULong___get_data__impl_(firstHigh)));
      var middleProduct = _ULong___init__impl_(_ULong___get_data__impl_(tmp16_plus_0).plus_71(_ULong___get_data__impl_(tmp17_plus_0)));
      var middleCarry = lowerCarry;
      var tmp19_plus_0 = middleCarry;
      var tmp18_shr_0 = 31;
      var tmp20_plus_0 = _ULong___init__impl_(_ULong___get_data__impl_(middleProduct).ushr_0(tmp18_shr_0));
      middleCarry = _ULong___init__impl_(_ULong___get_data__impl_(tmp19_plus_0).plus_71(_ULong___get_data__impl_(tmp20_plus_0)));
      var tmp24_plus_0 = lowResult;
      var tmp21_shl_0 = 32;
      var tmp22_and_0 = _ULong___init__impl_(_ULong___get_data__impl_(middleProduct).shl_4(tmp21_shl_0));
      var tmp23_and_0 = this._baseMask_0;
      var tmp25_plus_0 = _ULong___init__impl_(_ULong___get_data__impl_(tmp22_and_0).and(_ULong___get_data__impl_(tmp23_and_0)));
      lowResult = _ULong___init__impl_(_ULong___get_data__impl_(tmp24_plus_0).plus_71(_ULong___get_data__impl_(tmp25_plus_0)));
      var tmp28_plus_0 = middleCarry;
      var tmp26_shr_0 = lowResult;
      var tmp27_shr_0 = 63;
      var tmp29_plus_0 = _ULong___init__impl_(_ULong___get_data__impl_(tmp26_shr_0).ushr_0(tmp27_shr_0));
      middleCarry = _ULong___init__impl_(_ULong___get_data__impl_(tmp28_plus_0).plus_71(_ULong___get_data__impl_(tmp29_plus_0)));
      var tmp_0 = j;
      var tmp30_and_0 = lowResult;
      var tmp31_and_0 = this._baseMask_0;
      ULongArray__set_impl(result, tmp_0, _ULong___init__impl_(_ULong___get_data__impl_(tmp30_and_0).and(_ULong___get_data__impl_(tmp31_and_0))));
      var highResult = middleCarry;
      var tmp32_shl_0 = _ULong___init__impl_(_ULong___get_data__impl_(firstHigh).times_40(_ULong___get_data__impl_(secondHigh)));
      var tmp33_shl_0 = 1;
      var higherProduct = _ULong___init__impl_(_ULong___get_data__impl_(tmp32_shl_0).shl_4(tmp33_shl_0));
      var tmp34_plus_0 = highResult;
      highResult = _ULong___init__impl_(_ULong___get_data__impl_(tmp34_plus_0).plus_71(_ULong___get_data__impl_(higherProduct)));
      carryIntoNextRound = highResult;
      var tmp1 = j;
      j = tmp1 + 1 | 0;
      Unit_getInstance();
    }
    if (!equals_0(new ULong(carryIntoNextRound), new ULong(_ULong___init__impl_(new Long(0, 0))))) {
      ULongArray__set_impl(result, j, carryIntoNextRound);
    }return result;
  };
  BigInteger63Arithmetic.prototype.pow_3 = function (base, exponent) {
    if (exponent.equals(new Long(0, 0))) {
      return this._ONE_2;
    }if (exponent.equals(new Long(1, 0))) {
      return base;
    }if ((_ULongArray___get_size__impl_(base) === 1 ? equals_0(new ULong(ULongArray__get_impl(base, 0)), new ULong(_ULong___init__impl_(new Long(10, 0)))) : false) ? exponent.compareTo_75(toLong_0(this._powersOf10.length)) < 0 : false) {
      return this._powersOf10[exponent.toInt_5()]._storage_1;
    }var firstCorrectedSize = _ULongArray___get_size__impl_(base) - this.countLeadingZeroWords_0(base) | 0;
    var helperVar = this._ONE_2;
    var exponentVar = exponent;
    var baseVar = base;
    while (exponentVar.compareTo_75(new Long(1, 0)) > 0) {
      var tmp0_rem_0 = exponentVar;
      var tmp1_rem_0 = 2;
      if (tmp0_rem_0.rem_33(toLong_0(tmp1_rem_0)).equals(new Long(0, 0))) {
        baseVar = this.times_8(baseVar, baseVar);
        var tmp2_div_0 = exponentVar;
        var tmp3_div_0 = 2;
        exponentVar = tmp2_div_0.div_34(toLong_0(tmp3_div_0));
      } else {
        {
          helperVar = this.times_8(baseVar, helperVar);
          baseVar = this.times_8(baseVar, baseVar);
          var tmp4_minus_0 = exponentVar;
          var tmp5_minus_0 = 1;
          var tmp6_div_0 = tmp4_minus_0.minus_40(toLong_0(tmp5_minus_0));
          var tmp7_div_0 = 2;
          exponentVar = tmp6_div_0.div_34(toLong_0(tmp7_div_0));
        }
      }
    }
    return this.times_8(helperVar, baseVar);
  };
  BigInteger63Arithmetic.prototype.normalize_1 = function (dividend, divisor) {
    var divisorSize = _ULongArray___get_size__impl_(divisor);
    var normalizationShift = this.numberOfLeadingZerosInAWord_0(ULongArray__get_impl(divisor, divisorSize - 1 | 0));
    var divisorNormalized = this.shl_3(divisor, normalizationShift);
    var dividendNormalized = this.shl_3(dividend, normalizationShift);
    return new Triple(new ULongArray(dividendNormalized), new ULongArray(divisorNormalized), normalizationShift);
  };
  BigInteger63Arithmetic.prototype.denormalize_0 = function (remainderNormalized, normalizationShift) {
    var remainder = this.shr_3(remainderNormalized, normalizationShift);
    return remainder;
  };
  BigInteger63Arithmetic.prototype.baseDivide = function (unnormalizedDividend, unnormalizedDivisor) {
    if (this.compareTo_24(unnormalizedDivisor, unnormalizedDividend) > 0) {
      return new Pair(new ULongArray(this._ZERO_2), new ULongArray(unnormalizedDividend));
    }if (_ULongArray___get_size__impl_(unnormalizedDivisor) === 1 ? _ULongArray___get_size__impl_(unnormalizedDividend) === 1 : false) {
      var tmp0_div_0 = ULongArray__get_impl(unnormalizedDividend, 0);
      var tmp1_div_0 = ULongArray__get_impl(unnormalizedDivisor, 0);
      var tmp2_ulongArrayOf_0 = _ULongArray___init__impl_(longArrayOf([ulongDivide(tmp0_div_0, tmp1_div_0)]));
      var tmp = new ULongArray(this.removeLeadingZeros_0(tmp2_ulongArrayOf_0));
      var tmp3_rem_0 = ULongArray__get_impl(unnormalizedDividend, 0);
      var tmp4_rem_0 = ULongArray__get_impl(unnormalizedDivisor, 0);
      var tmp5_ulongArrayOf_0 = _ULongArray___init__impl_(longArrayOf([ulongRemainder(tmp3_rem_0, tmp4_rem_0)]));
      return new Pair(tmp, new ULongArray(this.removeLeadingZeros_0(tmp5_ulongArrayOf_0)));
    }var bitPrecision = this.bitLength_2(unnormalizedDividend) - this.bitLength_2(unnormalizedDivisor) | 0;
    if (bitPrecision === 0) {
      return new Pair(new ULongArray(this._ONE_2), new ULongArray(this.minus_10(unnormalizedDividend, unnormalizedDivisor)));
    }var tmp0_container = this.normalize_1(unnormalizedDividend, unnormalizedDivisor);
    var dividend = tmp0_container.component1_4()._storage_1;
    var divisor = tmp0_container.component2_4()._storage_1;
    var normalizationShift = tmp0_container.component3_1();
    var dividendSize = _ULongArray___get_size__impl_(dividend);
    var divisorSize = _ULongArray___get_size__impl_(divisor);
    var divisorCorrectedSize = _ULongArray___get_size__impl_(divisor) - this.countLeadingZeroWords_0(divisor) | 0;
    var wordPrecision = dividendSize - divisorSize | 0;
    var qjhat;
    var reconstructedQuotient;
    var quotient = _ULongArray___init__impl__0(wordPrecision);
    var divisorTimesBaseToPowerOfM = this.shl_3(divisor, imul(wordPrecision, this._basePowerOfTwo_0));
    if (this.compareTo_24(dividend, divisorTimesBaseToPowerOfM) >= 0) {
      quotient = _ULongArray___init__impl__0(wordPrecision + 1 | 0);
      ULongArray__set_impl(quotient, wordPrecision, _ULong___init__impl_(new Long(1, 0)));
      dividend = this.minus_10(dividend, divisorTimesBaseToPowerOfM);
    }var inductionVariable = wordPrecision - 1 | 0;
    if (0 <= inductionVariable)
      do {
        var j = inductionVariable;
        inductionVariable = inductionVariable + -1 | 0;
        var tmp_0;
        if ((divisorSize + j | 0) < _ULongArray___get_size__impl_(dividend)) {
          var tmp6_ulongArrayOf_0 = _ULongArray___init__impl_(longArrayOf([ULongArray__get_impl(dividend, divisorSize + j | 0)]));
          tmp_0 = this.plus_12(this.shl_3(tmp6_ulongArrayOf_0, this._basePowerOfTwo_0), ULongArray__get_impl(dividend, (divisorSize + j | 0) - 1 | 0));
        } else {
          var tmp_1;
          if ((divisorSize + j | 0) === _ULongArray___get_size__impl_(dividend)) {
            var tmp7_ulongArrayOf_0 = _ULongArray___init__impl_(longArrayOf([ULongArray__get_impl(dividend, (divisorSize + j | 0) - 1 | 0)]));
            tmp_1 = tmp7_ulongArrayOf_0;
          } else {
            tmp_1 = this._ZERO_2;
          }
          tmp_0 = tmp_1;
        }
        var twoDigit = tmp_0;
        var tmp_2 = BigInteger32Arithmetic_getInstance();
        var tmp_3 = this.to32Bit(twoDigit);
        var tmp8_ulongArrayOf_0 = _ULongArray___init__impl_(longArrayOf([ULongArray__get_impl(divisor, divisorSize - 1 | 0)]));
        var convertedResult = tmp_2.divide_6(tmp_3, this.to32Bit(tmp8_ulongArrayOf_0));
        qjhat = this.from32Bit(convertedResult._first._storage_0);
        var tmp_4 = quotient;
        var tmp_5;
        var tmp_6 = qjhat;
        var tmp9_minus_0 = this._baseMask_0;
        var tmp10_minus_0 = _ULong___init__impl_(new Long(1, 0));
        if (this.compareTo_25(tmp_6, _ULong___init__impl_(_ULong___get_data__impl_(tmp9_minus_0).minus_40(_ULong___get_data__impl_(tmp10_minus_0)))) < 0) {
          tmp_5 = ULongArray__get_impl(qjhat, 0);
        } else {
          {
            tmp_5 = this._baseMask_0;
          }
        }
        ULongArray__set_impl(tmp_4, j, tmp_5);
        reconstructedQuotient = this.shl_3(this.baseMultiplyWithCorrectedSize(divisor, ULongArray__get_impl(quotient, j), divisorCorrectedSize), imul(j, this._basePowerOfTwo_0));
        while (this.compareTo_24(reconstructedQuotient, dividend) > 0) {
          var tmp_7 = quotient;
          var tmp11_minus_0 = ULongArray__get_impl(quotient, j);
          var tmp12_minus_0 = _UInt___init__impl_(1);
          var tmp0_minus_0_1 = _ULong___init__impl_(toLong_0(_UInt___get_data__impl_(tmp12_minus_0)).and(new Long(-1, 0)));
          ULongArray__set_impl(tmp_7, j, _ULong___init__impl_(_ULong___get_data__impl_(tmp11_minus_0).minus_40(_ULong___get_data__impl_(tmp0_minus_0_1))));
          reconstructedQuotient = this.shl_3(this.baseMultiplyWithCorrectedSize(divisor, ULongArray__get_impl(quotient, j), divisorCorrectedSize), imul(j, this._basePowerOfTwo_0));
        }
        dividend = this.minus_10(dividend, reconstructedQuotient);
      }
       while (0 <= inductionVariable);
    while (this.compareTo_24(dividend, divisor) >= 0) {
      quotient = this.plus_12(quotient, _ULong___init__impl_(new Long(1, 0)));
      dividend = this.minus_10(dividend, divisor);
    }
    var denormRemainder = this.denormalize_0(dividend, normalizationShift);
    return new Pair(new ULongArray(this.removeLeadingZeros_0(quotient)), new ULongArray(denormRemainder));
  };
  BigInteger63Arithmetic.prototype.convertTo64BitRepresentation = function (operand) {
    if (isZero_0(operand, this))
      return this._ZERO_2;
    var length = this.bitLength_2(operand);
    var tmp;
    if (length % 64 === 0) {
      tmp = length / 64 | 0;
    } else {
      tmp = (length / 64 | 0) + 1 | 0;
    }
    var requiredLength = tmp;
    var wordStep;
    var shiftAmount;
    var result = _ULongArray___init__impl__0(requiredLength);
    var inductionVariable = 0;
    if (inductionVariable < requiredLength)
      do {
        var i = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        wordStep = i / 63 | 0;
        shiftAmount = i % 63;
        if (((i + wordStep | 0) + 1 | 0) < _ULongArray___get_size__impl_(operand)) {
          var tmp0_shr_0 = ULongArray__get_impl(operand, i + wordStep | 0);
          var tmp1_shr_0 = shiftAmount;
          var tmp4_or_0 = _ULong___init__impl_(_ULong___get_data__impl_(tmp0_shr_0).ushr_0(tmp1_shr_0));
          var tmp2_shl_0 = ULongArray__get_impl(operand, (i + wordStep | 0) + 1 | 0);
          var tmp3_shl_0 = 63 - shiftAmount | 0;
          var tmp5_or_0 = _ULong___init__impl_(_ULong___get_data__impl_(tmp2_shl_0).shl_4(tmp3_shl_0));
          ULongArray__set_impl(result, i, _ULong___init__impl_(_ULong___get_data__impl_(tmp4_or_0).or(_ULong___get_data__impl_(tmp5_or_0))));
        } else {
          var tmp6_shr_0 = ULongArray__get_impl(operand, i + wordStep | 0);
          var tmp7_shr_0 = shiftAmount;
          ULongArray__set_impl(result, i, _ULong___init__impl_(_ULong___get_data__impl_(tmp6_shr_0).ushr_0(tmp7_shr_0)));
        }
      }
       while (inductionVariable < requiredLength);
    return this.removeLeadingZeros_0(result);
  };
  BigInteger63Arithmetic.prototype.convertTo32BitRepresentation = function (operand) {
    var power64Representation = this.convertTo64BitRepresentation(operand);
    var result = _UIntArray___init__impl__0(imul(_ULongArray___get_size__impl_(power64Representation), 2));
    var inductionVariable = 0;
    var last_2 = _ULongArray___get_size__impl_(power64Representation);
    if (inductionVariable < last_2)
      do {
        var i = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        var tmp = imul(2, i);
        var tmp1_and_0 = ULongArray__get_impl(power64Representation, i);
        var tmp0_toULong_0 = BigInteger32Arithmetic_getInstance()._base;
        var tmp2_and_0 = _ULong___init__impl_(toLong_0(_UInt___get_data__impl_(tmp0_toULong_0)).and(new Long(-1, 0)));
        var tmp3_toUInt_0 = _ULong___init__impl_(_ULong___get_data__impl_(tmp1_and_0).and(_ULong___get_data__impl_(tmp2_and_0)));
        var tmp0_toUInt_0_1 = _ULong___get_data__impl_(tmp3_toUInt_0);
        UIntArray__set_impl(result, tmp, _UInt___init__impl_(tmp0_toUInt_0_1.toInt_5()));
        var tmp_0 = imul(2, i) + 1 | 0;
        var tmp4_shr_0 = ULongArray__get_impl(power64Representation, i);
        var tmp5_shr_0 = 32;
        var tmp6_toUInt_0 = _ULong___init__impl_(_ULong___get_data__impl_(tmp4_shr_0).ushr_0(tmp5_shr_0));
        var tmp0_toUInt_0_1_0 = _ULong___get_data__impl_(tmp6_toUInt_0);
        UIntArray__set_impl(result, tmp_0, _UInt___init__impl_(tmp0_toUInt_0_1_0.toInt_5()));
      }
       while (inductionVariable < last_2);
    return BigInteger32Arithmetic_getInstance().removeLeadingZeros(result);
  };
  BigInteger63Arithmetic.prototype.convertFrom32BitRepresentation = function (operand) {
    if (_UIntArray___get_size__impl_(operand) === 0) {
      return this._ZERO_2;
    }if (_UIntArray___get_size__impl_(operand) === 1) {
      var tmp0_toULong_0 = UIntArray__get_impl(operand, 0);
      var tmp1_ulongArrayOf_0 = _ULongArray___init__impl_(longArrayOf([_ULong___init__impl_(toLong_0(_UInt___get_data__impl_(tmp0_toULong_0)).and(new Long(-1, 0)))]));
      return tmp1_ulongArrayOf_0;
    }var length = BigInteger32Arithmetic_getInstance().bitLength_0(operand);
    var tmp;
    if (length % 63 === 0) {
      tmp = length / 63 | 0;
    } else {
      tmp = (length / 63 | 0) + 1 | 0;
    }
    var requiredLength = tmp;
    var result = _ULongArray___init__impl__0(requiredLength);
    var skipWordCount;
    var inductionVariable = 0;
    if (inductionVariable < requiredLength)
      do {
        var i = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        skipWordCount = i / 32 | 0;
        var shiftAmount = i % 32;
        var position_0 = imul(i, 2) - skipWordCount | 0;
        if (requiredLength === 2) {
          var tmp2_toULong_0 = UIntArray__get_impl(operand, 0);
          var tmp8_or_0 = _ULong___init__impl_(toLong_0(_UInt___get_data__impl_(tmp2_toULong_0)).and(new Long(-1, 0)));
          var tmp3_toULong_0 = UIntArray__get_impl(operand, 1);
          var tmp4_shl_0 = _ULong___init__impl_(toLong_0(_UInt___get_data__impl_(tmp3_toULong_0)).and(new Long(-1, 0)));
          var tmp5_shl_0 = 32;
          var tmp6_and_0 = _ULong___init__impl_(_ULong___get_data__impl_(tmp4_shl_0).shl_4(tmp5_shl_0));
          var tmp7_and_0 = this._highMask;
          var tmp9_or_0 = _ULong___init__impl_(_ULong___get_data__impl_(tmp6_and_0).and(_ULong___get_data__impl_(tmp7_and_0)));
          ULongArray__set_impl(result, 0, _ULong___init__impl_(_ULong___get_data__impl_(tmp8_or_0).or(_ULong___get_data__impl_(tmp9_or_0))));
          if (_UIntArray___get_size__impl_(operand) === 4) {
            var tmp10_toULong_0 = UIntArray__get_impl(operand, 1);
            var tmp11_shr_0 = _ULong___init__impl_(toLong_0(_UInt___get_data__impl_(tmp10_toULong_0)).and(new Long(-1, 0)));
            var tmp12_shr_0 = 31;
            var tmp16_or_0 = _ULong___init__impl_(_ULong___get_data__impl_(tmp11_shr_0).ushr_0(tmp12_shr_0));
            var tmp13_toULong_0 = UIntArray__get_impl(operand, 2);
            var tmp14_shl_0 = _ULong___init__impl_(toLong_0(_UInt___get_data__impl_(tmp13_toULong_0)).and(new Long(-1, 0)));
            var tmp15_shl_0 = 1;
            var tmp17_or_0 = _ULong___init__impl_(_ULong___get_data__impl_(tmp14_shl_0).shl_4(tmp15_shl_0));
            var tmp21_or_0 = _ULong___init__impl_(_ULong___get_data__impl_(tmp16_or_0).or(_ULong___get_data__impl_(tmp17_or_0)));
            var tmp18_toULong_0 = UIntArray__get_impl(operand, 3);
            var tmp19_shl_0 = _ULong___init__impl_(toLong_0(_UInt___get_data__impl_(tmp18_toULong_0)).and(new Long(-1, 0)));
            var tmp20_shl_0 = 33;
            var tmp22_or_0 = _ULong___init__impl_(_ULong___get_data__impl_(tmp19_shl_0).shl_4(tmp20_shl_0));
            ULongArray__set_impl(result, 1, _ULong___init__impl_(_ULong___get_data__impl_(tmp21_or_0).or(_ULong___get_data__impl_(tmp22_or_0))));
          } else {
            var tmp23_toULong_0 = UIntArray__get_impl(operand, 1);
            var tmp24_shr_0 = _ULong___init__impl_(toLong_0(_UInt___get_data__impl_(tmp23_toULong_0)).and(new Long(-1, 0)));
            var tmp25_shr_0 = 31;
            var tmp29_or_0 = _ULong___init__impl_(_ULong___get_data__impl_(tmp24_shr_0).ushr_0(tmp25_shr_0));
            var tmp26_toULong_0 = UIntArray__get_impl(operand, 2);
            var tmp27_shl_0 = _ULong___init__impl_(toLong_0(_UInt___get_data__impl_(tmp26_toULong_0)).and(new Long(-1, 0)));
            var tmp28_shl_0 = 1;
            var tmp30_or_0 = _ULong___init__impl_(_ULong___get_data__impl_(tmp27_shl_0).shl_4(tmp28_shl_0));
            ULongArray__set_impl(result, 1, _ULong___init__impl_(_ULong___get_data__impl_(tmp29_or_0).or(_ULong___get_data__impl_(tmp30_or_0))));
          }
        } else {
          var tmp1_subject = i;
          if (tmp1_subject === 0) {
            var tmp31_toULong_0 = UIntArray__get_impl(operand, 0);
            var tmp37_or_0 = _ULong___init__impl_(toLong_0(_UInt___get_data__impl_(tmp31_toULong_0)).and(new Long(-1, 0)));
            var tmp32_toULong_0 = UIntArray__get_impl(operand, 1);
            var tmp33_shl_0 = _ULong___init__impl_(toLong_0(_UInt___get_data__impl_(tmp32_toULong_0)).and(new Long(-1, 0)));
            var tmp34_shl_0 = 32;
            var tmp35_and_0 = _ULong___init__impl_(_ULong___get_data__impl_(tmp33_shl_0).shl_4(tmp34_shl_0));
            var tmp36_and_0 = this._highMask;
            var tmp38_or_0 = _ULong___init__impl_(_ULong___get_data__impl_(tmp35_and_0).and(_ULong___get_data__impl_(tmp36_and_0)));
            ULongArray__set_impl(result, i, _ULong___init__impl_(_ULong___get_data__impl_(tmp37_or_0).or(_ULong___get_data__impl_(tmp38_or_0))));
          } else if (1 <= tmp1_subject ? tmp1_subject < (requiredLength - 1 | 0) : false) {
            var tmp39_toULong_0 = UIntArray__get_impl(operand, position_0 - 1 | 0);
            var tmp40_shr_0 = _ULong___init__impl_(toLong_0(_UInt___get_data__impl_(tmp39_toULong_0)).and(new Long(-1, 0)));
            var tmp41_shr_0 = 32 - shiftAmount | 0;
            var tmp44_or_0 = _ULong___init__impl_(_ULong___get_data__impl_(tmp40_shr_0).ushr_0(tmp41_shr_0));
            var tmp42_toULong_0 = UIntArray__get_impl(operand, position_0);
            var tmp43_shl_0 = _ULong___init__impl_(toLong_0(_UInt___get_data__impl_(tmp42_toULong_0)).and(new Long(-1, 0)));
            var tmp45_or_0 = _ULong___init__impl_(_ULong___get_data__impl_(tmp43_shl_0).shl_4(shiftAmount));
            var tmp51_or_0 = _ULong___init__impl_(_ULong___get_data__impl_(tmp44_or_0).or(_ULong___get_data__impl_(tmp45_or_0)));
            var tmp46_toULong_0 = UIntArray__get_impl(operand, position_0 + 1 | 0);
            var tmp47_shl_0 = _ULong___init__impl_(toLong_0(_UInt___get_data__impl_(tmp46_toULong_0)).and(new Long(-1, 0)));
            var tmp48_shl_0 = 32 + shiftAmount | 0;
            var tmp49_and_0 = _ULong___init__impl_(_ULong___get_data__impl_(tmp47_shl_0).shl_4(tmp48_shl_0));
            var tmp50_and_0 = this._highMask;
            var tmp52_or_0 = _ULong___init__impl_(_ULong___get_data__impl_(tmp49_and_0).and(_ULong___get_data__impl_(tmp50_and_0)));
            ULongArray__set_impl(result, i, _ULong___init__impl_(_ULong___get_data__impl_(tmp51_or_0).or(_ULong___get_data__impl_(tmp52_or_0))));
          } else if (tmp1_subject === (requiredLength - 1 | 0)) {
            if (position_0 < _UIntArray___get_size__impl_(operand)) {
              var tmp53_toULong_0 = UIntArray__get_impl(operand, position_0 - 1 | 0);
              var tmp54_shr_0 = _ULong___init__impl_(toLong_0(_UInt___get_data__impl_(tmp53_toULong_0)).and(new Long(-1, 0)));
              var tmp55_shr_0 = 32 - shiftAmount | 0;
              var tmp58_or_0 = _ULong___init__impl_(_ULong___get_data__impl_(tmp54_shr_0).ushr_0(tmp55_shr_0));
              var tmp56_toULong_0 = UIntArray__get_impl(operand, position_0);
              var tmp57_shl_0 = _ULong___init__impl_(toLong_0(_UInt___get_data__impl_(tmp56_toULong_0)).and(new Long(-1, 0)));
              var tmp59_or_0 = _ULong___init__impl_(_ULong___get_data__impl_(tmp57_shl_0).shl_4(shiftAmount));
              ULongArray__set_impl(result, i, _ULong___init__impl_(_ULong___get_data__impl_(tmp58_or_0).or(_ULong___get_data__impl_(tmp59_or_0))));
            } else {
              var tmp60_toULong_0 = UIntArray__get_impl(operand, position_0 - 1 | 0);
              var tmp61_shr_0 = _ULong___init__impl_(toLong_0(_UInt___get_data__impl_(tmp60_toULong_0)).and(new Long(-1, 0)));
              var tmp62_shr_0 = 32 - shiftAmount | 0;
              ULongArray__set_impl(result, i, _ULong___init__impl_(_ULong___get_data__impl_(tmp61_shr_0).ushr_0(tmp62_shr_0)));
            }
          }}
      }
       while (inductionVariable < requiredLength);
    return result;
  };
  BigInteger63Arithmetic.prototype.convertFrom64BitRepresentation = function (operand) {
    if (_ULongArray___get_size__impl_(operand) === 0) {
      return this._ZERO_2;
    }if (_ULongArray___get_size__impl_(operand) === 1) {
      var result = _ULongArray___init__impl__0(2);
      var tmp0_and_0 = ULongArray__get_impl(operand, 0);
      var tmp1_and_0 = this._baseMask_0;
      ULongArray__set_impl(result, 0, _ULong___init__impl_(_ULong___get_data__impl_(tmp0_and_0).and(_ULong___get_data__impl_(tmp1_and_0))));
      var tmp2_shr_0 = ULongArray__get_impl(operand, 0);
      var tmp3_shr_0 = 63;
      ULongArray__set_impl(result, 1, _ULong___init__impl_(_ULong___get_data__impl_(tmp2_shr_0).ushr_0(tmp3_shr_0)));
      return result;
    }var length = this.bitLengthFor64BitArray(operand);
    var tmp;
    if (length % 63 === 0) {
      tmp = length / 63 | 0;
    } else {
      tmp = (length / 63 | 0) + 1 | 0;
    }
    var requiredLength = tmp;
    if (requiredLength === 2) {
      var result_0 = _ULongArray___init__impl__0(2);
      var tmp4_and_0 = ULongArray__get_impl(operand, 0);
      var tmp5_and_0 = this._baseMask_0;
      ULongArray__set_impl(result_0, 0, _ULong___init__impl_(_ULong___get_data__impl_(tmp4_and_0).and(_ULong___get_data__impl_(tmp5_and_0))));
      var tmp6_shl_0 = ULongArray__get_impl(operand, 1);
      var tmp7_shl_0 = 1;
      var tmp10_or_0 = _ULong___init__impl_(_ULong___get_data__impl_(tmp6_shl_0).shl_4(tmp7_shl_0));
      var tmp8_shr_0 = ULongArray__get_impl(operand, 0);
      var tmp9_shr_0 = 63;
      var tmp11_or_0 = _ULong___init__impl_(_ULong___get_data__impl_(tmp8_shr_0).ushr_0(tmp9_shr_0));
      ULongArray__set_impl(result_0, 1, _ULong___init__impl_(_ULong___get_data__impl_(tmp10_or_0).or(_ULong___get_data__impl_(tmp11_or_0))));
      return result_0;
    }var result_1 = _ULongArray___init__impl__0(requiredLength);
    var skipWordCount;
    var inductionVariable = 0;
    if (inductionVariable < requiredLength)
      do {
        var i = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        skipWordCount = i / 64 | 0;
        var shiftAmount = i % 64;
        var position_0 = i - skipWordCount | 0;
        var tmp1_subject = i;
        if (tmp1_subject === 0) {
          var tmp12_and_0 = ULongArray__get_impl(operand, 0);
          var tmp13_and_0 = this._baseMask_0;
          ULongArray__set_impl(result_1, i, _ULong___init__impl_(_ULong___get_data__impl_(tmp12_and_0).and(_ULong___get_data__impl_(tmp13_and_0))));
        } else if (1 <= tmp1_subject ? tmp1_subject < (requiredLength - 1 | 0) : false) {
          var tmp_0;
          if (shiftAmount > 0) {
            var tmp14_shr_0 = ULongArray__get_impl(operand, position_0 - 1 | 0);
            var tmp15_shr_0 = 64 - shiftAmount | 0;
            var tmp17_or_0 = _ULong___init__impl_(_ULong___get_data__impl_(tmp14_shr_0).ushr_0(tmp15_shr_0));
            var tmp16_shl_0 = ULongArray__get_impl(operand, position_0);
            var tmp18_or_0 = _ULong___init__impl_(_ULong___get_data__impl_(tmp16_shl_0).shl_4(shiftAmount));
            var tmp19_and_0 = _ULong___init__impl_(_ULong___get_data__impl_(tmp17_or_0).or(_ULong___get_data__impl_(tmp18_or_0)));
            var tmp20_and_0 = this._baseMask_0;
            tmp_0 = _ULong___init__impl_(_ULong___get_data__impl_(tmp19_and_0).and(_ULong___get_data__impl_(tmp20_and_0)));
          } else {
            var tmp21_shl_0 = ULongArray__get_impl(operand, position_0);
            var tmp22_and_0 = _ULong___init__impl_(_ULong___get_data__impl_(tmp21_shl_0).shl_4(shiftAmount));
            var tmp23_and_0 = this._baseMask_0;
            tmp_0 = _ULong___init__impl_(_ULong___get_data__impl_(tmp22_and_0).and(_ULong___get_data__impl_(tmp23_and_0)));
          }
          ULongArray__set_impl(result_1, i, tmp_0);
        } else if (tmp1_subject === (requiredLength - 1 | 0)) {
          if (position_0 < _ULongArray___get_size__impl_(operand)) {
            var tmp_1;
            if (shiftAmount > 0) {
              var tmp24_shr_0 = ULongArray__get_impl(operand, position_0 - 1 | 0);
              var tmp25_shr_0 = 64 - shiftAmount | 0;
              var tmp27_or_0 = _ULong___init__impl_(_ULong___get_data__impl_(tmp24_shr_0).ushr_0(tmp25_shr_0));
              var tmp26_shl_0 = ULongArray__get_impl(operand, position_0);
              var tmp28_or_0 = _ULong___init__impl_(_ULong___get_data__impl_(tmp26_shl_0).shl_4(shiftAmount));
              var tmp29_and_0 = _ULong___init__impl_(_ULong___get_data__impl_(tmp27_or_0).or(_ULong___get_data__impl_(tmp28_or_0)));
              var tmp30_and_0 = this._baseMask_0;
              tmp_1 = _ULong___init__impl_(_ULong___get_data__impl_(tmp29_and_0).and(_ULong___get_data__impl_(tmp30_and_0)));
            } else {
              var tmp31_shl_0 = ULongArray__get_impl(operand, position_0);
              var tmp32_and_0 = _ULong___init__impl_(_ULong___get_data__impl_(tmp31_shl_0).shl_4(shiftAmount));
              var tmp33_and_0 = this._baseMask_0;
              tmp_1 = _ULong___init__impl_(_ULong___get_data__impl_(tmp32_and_0).and(_ULong___get_data__impl_(tmp33_and_0)));
            }
            ULongArray__set_impl(result_1, i, tmp_1);
          } else {
            var tmp_2;
            if (shiftAmount > 0) {
              var tmp34_shr_0 = ULongArray__get_impl(operand, position_0 - 1 | 0);
              var tmp35_shr_0 = 64 - shiftAmount | 0;
              tmp_2 = _ULong___init__impl_(_ULong___get_data__impl_(tmp34_shr_0).ushr_0(tmp35_shr_0));
            } else {
              tmp_2 = _ULong___init__impl_(new Long(0, 0));
            }
            ULongArray__set_impl(result_1, i, tmp_2);
          }
        }}
       while (inductionVariable < requiredLength);
    return result_1;
  };
  BigInteger63Arithmetic.prototype.divide_7 = function (first_4, second) {
    return this.baseDivide(first_4, second);
  };
  BigInteger63Arithmetic.prototype.gcd_1 = function (first_4, second) {
    var tmp;
    if (_ULongArray___get_size__impl_(first_4) > 150 ? true : _ULongArray___get_size__impl_(second) > 150) {
      tmp = euclideanGcd(this, first_4, second);
    } else {
      tmp = binaryGcd(this, first_4, second);
    }
    return tmp;
  };
  BigInteger63Arithmetic.prototype.plus_10 = function (_this__0, other) {
    return signedAdd(this, _this__0, other);
  };
  BigInteger63Arithmetic.prototype.minus_9 = function (_this__0, other) {
    return signedSubtract(this, _this__0, other);
  };
  BigInteger63Arithmetic.prototype.times_7 = function (_this__0, other) {
    return signedMultiply(this, _this__0, other);
  };
  BigInteger63Arithmetic.prototype.div_5 = function (_this__0, other) {
    return signedDivide(this, _this__0, other);
  };
  BigInteger63Arithmetic.prototype.shr_2 = function (_this__0, places) {
    return new SignedULongArray(this.shr_3(_this__0._unsignedValue_0, places), _this__0._sign_1);
  };
  BigInteger63Arithmetic.prototype.shl_2 = function (_this__0, places) {
    return new SignedULongArray(this.shl_3(_this__0._unsignedValue_0, places), _this__0._sign_1);
  };
  BigInteger63Arithmetic.prototype.and_2 = function (_this__0, operand) {
    return new SignedULongArray(this.and_3(_this__0._unsignedValue_0, operand), _this__0._sign_1);
  };
  BigInteger63Arithmetic.prototype.parseForBase_0 = function (number, base) {
    var parsed = this._ZERO_2;
    var tmp0_forEach_0 = number.toLowerCase();
    var indexedObject = tmp0_forEach_0;
    var inductionVariable = 0;
    var last_2 = indexedObject.length;
    while (inductionVariable < last_2) {
      var element_2 = charSequenceGet(indexedObject, inductionVariable);
      inductionVariable = inductionVariable + 1 | 0;
      var tmp = BigInteger63Arithmetic_getInstance();
      var tmp_0 = BigInteger63Arithmetic_getInstance();
      var tmp_1 = parsed;
      var tmp_2 = tmp_0.times_9(tmp_1, _ULong___init__impl_(toLong_0(base)));
      var tmp0_toULong_0_4 = toDigit(element_2, base);
      parsed = tmp.plus_12(tmp_2, _ULong___init__impl_(toLong_0(tmp0_toULong_0_4)));
    }
    return this.removeLeadingZeros_0(parsed);
  };
  BigInteger63Arithmetic.prototype.toString_3 = function (operand, base) {
    var copy_1 = _ULongArray___init__impl_(copyOf_2(_ULongArray___get_storage__impl_(operand)));
    var tmp0_ulongArrayOf_0 = _ULongArray___init__impl_(longArrayOf([_ULong___init__impl_(toLong_0(base))]));
    var baseArray = tmp0_ulongArrayOf_0;
    var stringBuilder = StringBuilder_init_$Create$_1();
    while (!equals_0(new ULongArray(copy_1), new ULongArray(this._ZERO_2))) {
      var divremResult = this.divrem_0(copy_1, baseArray);
      if (ULongArray__isEmpty_impl(divremResult._second._storage_1)) {
        stringBuilder.append_4(0);
        Unit_getInstance();
      } else {
        stringBuilder.append_5(toString_0(ULongArray__get_impl(divremResult._second._storage_1, 0), base));
        Unit_getInstance();
      }
      copy_1 = divremResult._first._storage_1;
    }
    var tmp1_reversed_0 = stringBuilder.toString();
    return toString_2(reversed_0(isCharSequence(tmp1_reversed_0) ? tmp1_reversed_0 : THROW_CCE()));
  };
  BigInteger63Arithmetic.prototype.and_3 = function (operand, mask) {
    var tmp;
    if (_ULongArray___get_size__impl_(operand) > _ULongArray___get_size__impl_(mask)) {
      tmp = new Pair(new ULongArray(operand), new ULongArray(mask));
    } else {
      tmp = new Pair(new ULongArray(mask), new ULongArray(operand));
    }
    var tmp0_container = tmp;
    var bigger = tmp0_container.component1_4()._storage_1;
    var smaller = tmp0_container.component2_4()._storage_1;
    var tmp0_ULongArray_0 = _ULongArray___get_size__impl_(smaller);
    var tmp_1 = 0;
    var tmp_2 = tmp0_ULongArray_0;
    var tmp_3 = longArray(tmp_2);
    while (tmp_1 < tmp_2) {
      var tmp_4 = tmp_1;
      var tmp0_and_0_7 = ULongArray__get_impl(operand, tmp_4);
      var tmp1_and_0_8 = ULongArray__get_impl(mask, tmp_4);
      var tmp0_toLong_0_5 = _ULong___init__impl_(_ULong___get_data__impl_(tmp0_and_0_7).and(_ULong___get_data__impl_(tmp1_and_0_8)));
      tmp_3[tmp_4] = _ULong___get_data__impl_(tmp0_toLong_0_5);
      tmp_1 = tmp_1 + 1 | 0;
    }
    return _ULongArray___init__impl_(tmp_3);
  };
  BigInteger63Arithmetic.prototype.or_2 = function (operand, mask) {
    var tmp0_ULongArray_0 = _ULongArray___get_size__impl_(operand);
    var tmp_1 = 0;
    var tmp_2 = tmp0_ULongArray_0;
    var tmp_3 = longArray(tmp_2);
    while (tmp_1 < tmp_2) {
      var tmp_4 = tmp_1;
      var tmp;
      if (tmp_4 < _ULongArray___get_size__impl_(mask)) {
        var tmp0_or_0_7 = ULongArray__get_impl(operand, tmp_4);
        var tmp1_or_0_8 = ULongArray__get_impl(mask, tmp_4);
        tmp = _ULong___init__impl_(_ULong___get_data__impl_(tmp0_or_0_7).or(_ULong___get_data__impl_(tmp1_or_0_8)));
      } else {
        tmp = ULongArray__get_impl(operand, tmp_4);
      }
      var tmp0_toLong_0_5 = tmp;
      tmp_3[tmp_4] = _ULong___get_data__impl_(tmp0_toLong_0_5);
      tmp_1 = tmp_1 + 1 | 0;
    }
    return this.removeLeadingZeros_0(_ULongArray___init__impl_(tmp_3));
  };
  BigInteger63Arithmetic.prototype.xor_2 = function (operand, mask) {
    if (_ULongArray___get_size__impl_(operand) < _ULongArray___get_size__impl_(mask))
      return this.xor_2(mask, operand);
    var tmp0_ULongArray_0 = _ULongArray___get_size__impl_(operand);
    var tmp_1 = 0;
    var tmp_2 = tmp0_ULongArray_0;
    var tmp_3 = longArray(tmp_2);
    while (tmp_1 < tmp_2) {
      var tmp_4 = tmp_1;
      var tmp;
      if (tmp_4 < _ULongArray___get_size__impl_(mask)) {
        var tmp0_xor_0_7 = ULongArray__get_impl(operand, tmp_4);
        var tmp1_xor_0_8 = ULongArray__get_impl(mask, tmp_4);
        tmp = _ULong___init__impl_(_ULong___get_data__impl_(tmp0_xor_0_7).xor(_ULong___get_data__impl_(tmp1_xor_0_8)));
      } else {
        var tmp2_xor_0_9 = ULongArray__get_impl(operand, tmp_4);
        var tmp3_xor_0_10 = _ULong___init__impl_(new Long(0, 0));
        tmp = _ULong___init__impl_(_ULong___get_data__impl_(tmp2_xor_0_9).xor(_ULong___get_data__impl_(tmp3_xor_0_10)));
      }
      var tmp0_toLong_0_5 = tmp;
      tmp_3[tmp_4] = _ULong___get_data__impl_(tmp0_toLong_0_5);
      tmp_1 = tmp_1 + 1 | 0;
    }
    return this.removeLeadingZeros_0(_ULongArray___init__impl_(tmp_3));
  };
  BigInteger63Arithmetic.prototype.shl_3 = function (_this__0, places) {
    return this.shiftLeft_1(_this__0, places);
  };
  BigInteger63Arithmetic.prototype.shr_3 = function (_this__0, places) {
    return this.shiftRight_1(_this__0, places);
  };
  BigInteger63Arithmetic.prototype.plus_11 = function (_this__0, other) {
    return this.add_32(_this__0, other);
  };
  BigInteger63Arithmetic.prototype.minus_10 = function (_this__0, other) {
    return this.subtract_7(_this__0, other);
  };
  BigInteger63Arithmetic.prototype.times_8 = function (_this__0, other) {
    return this.multiply_8(_this__0, other);
  };
  BigInteger63Arithmetic.prototype.plus_12 = function (_this__0, other) {
    var tmp0_ulongArrayOf_0 = _ULongArray___init__impl_(longArrayOf([other]));
    return this.add_32(_this__0, tmp0_ulongArrayOf_0);
  };
  BigInteger63Arithmetic.prototype.minus_11 = function (_this__0, other) {
    var tmp0_ulongArrayOf_0 = _ULongArray___init__impl_(longArrayOf([other]));
    return this.subtract_7(_this__0, tmp0_ulongArrayOf_0);
  };
  BigInteger63Arithmetic.prototype.times_9 = function (_this__0, other) {
    return this.baseMultiply(_this__0, other);
  };
  BigInteger63Arithmetic.prototype.div_6 = function (_this__0, other) {
    return this.divide_7(_this__0, other)._first._storage_1;
  };
  BigInteger63Arithmetic.prototype.rem_5 = function (_this__0, other) {
    return this.divide_7(_this__0, other)._second._storage_1;
  };
  BigInteger63Arithmetic.prototype.divrem_0 = function (_this__0, other) {
    return this.divide_7(_this__0, other);
  };
  BigInteger63Arithmetic.prototype.compareTo_24 = function (_this__0, other) {
    return this.compare_4(_this__0, other);
  };
  BigInteger63Arithmetic.prototype.compareTo_25 = function (_this__0, other) {
    var tmp0_ulongArrayOf_0 = _ULongArray___init__impl_(longArrayOf([other]));
    return this.compare_4(_this__0, tmp0_ulongArrayOf_0);
  };
  BigInteger63Arithmetic.prototype.to32Bit = function (_this__0) {
    return this.convertTo32BitRepresentation(_this__0);
  };
  BigInteger63Arithmetic.prototype.from32Bit = function (_this__0) {
    return this.convertFrom32BitRepresentation(_this__0);
  };
  BigInteger63Arithmetic.prototype.fromULong_1 = function (uLong) {
    var tmp;
    var tmp0_and_0 = this._overflowMask_0;
    if (!equals_0(new ULong(_ULong___init__impl_(_ULong___get_data__impl_(uLong).and(_ULong___get_data__impl_(tmp0_and_0)))), new ULong(_ULong___init__impl_(new Long(0, 0))))) {
      var tmp1_and_0 = this._baseMask_0;
      var tmp2_ulongArrayOf_0 = _ULongArray___init__impl_(longArrayOf([_ULong___init__impl_(_ULong___get_data__impl_(uLong).and(_ULong___get_data__impl_(tmp1_and_0))), _ULong___init__impl_(new Long(1, 0))]));
      tmp = tmp2_ulongArrayOf_0;
    } else {
      {
        var tmp3_ulongArrayOf_0 = _ULongArray___init__impl_(longArrayOf([uLong]));
        tmp = tmp3_ulongArrayOf_0;
      }
    }
    return tmp;
  };
  BigInteger63Arithmetic.prototype.fromUInt_1 = function (uInt) {
    var tmp0_ulongArrayOf_0 = _ULongArray___init__impl_(longArrayOf([_ULong___init__impl_(toLong_0(_UInt___get_data__impl_(uInt)).and(new Long(-1, 0)))]));
    return tmp0_ulongArrayOf_0;
  };
  BigInteger63Arithmetic.prototype.fromUShort_1 = function (uShort) {
    var tmp0_ulongArrayOf_0 = _ULongArray___init__impl_(longArrayOf([_ULong___init__impl_(toLong_0(_UShort___get_data__impl_(uShort)).and(new Long(65535, 0)))]));
    return tmp0_ulongArrayOf_0;
  };
  BigInteger63Arithmetic.prototype.fromUByte_1 = function (uByte) {
    var tmp0_ulongArrayOf_0 = _ULongArray___init__impl_(longArrayOf([_ULong___init__impl_(toLong_0(_UByte___get_data__impl_(uByte)).and(new Long(255, 0)))]));
    return tmp0_ulongArrayOf_0;
  };
  BigInteger63Arithmetic.prototype.fromLong_4 = function (long) {
    Companion_getInstance_10();
    if (long.equals(new Long(0, -2147483648))) {
      var tmp0_ulongArrayOf_0 = _ULongArray___init__impl_(longArrayOf([_ULong___init__impl_(new Long(0, 0)), _ULong___init__impl_(new Long(1, 0))]));
      return tmp0_ulongArrayOf_0;
    } else {
    }
    var tmp1_toULong_0 = abs(long);
    var tmp2_and_0 = _ULong___init__impl_(tmp1_toULong_0);
    var tmp3_and_0 = this._baseMask_0;
    var tmp4_ulongArrayOf_0 = _ULongArray___init__impl_(longArrayOf([_ULong___init__impl_(_ULong___get_data__impl_(tmp2_and_0).and(_ULong___get_data__impl_(tmp3_and_0)))]));
    return tmp4_ulongArrayOf_0;
  };
  BigInteger63Arithmetic.prototype.fromInt_4 = function (int) {
    var tmp0__get_absoluteValue__0 = toLong_0(int);
    var tmp1_toULong_0 = abs(tmp0__get_absoluteValue__0);
    var tmp2_ulongArrayOf_0 = _ULongArray___init__impl_(longArrayOf([_ULong___init__impl_(tmp1_toULong_0)]));
    return tmp2_ulongArrayOf_0;
  };
  BigInteger63Arithmetic.prototype.fromShort_4 = function (short) {
    var tmp0__get_absoluteValue__0 = short;
    var tmp1_toULong_0 = abs_0(tmp0__get_absoluteValue__0);
    var tmp2_ulongArrayOf_0 = _ULongArray___init__impl_(longArrayOf([_ULong___init__impl_(toLong_0(tmp1_toULong_0))]));
    return tmp2_ulongArrayOf_0;
  };
  BigInteger63Arithmetic.prototype.fromByte_4 = function (byte) {
    var tmp0__get_absoluteValue__0 = byte;
    var tmp1_toULong_0 = abs_0(tmp0__get_absoluteValue__0);
    var tmp2_ulongArrayOf_0 = _ULongArray___init__impl_(longArrayOf([_ULong___init__impl_(toLong_0(tmp1_toULong_0))]));
    return tmp2_ulongArrayOf_0;
  };
  BigInteger63Arithmetic.prototype.fromUByteArray_1 = function (source) {
    var padLength = 8 - _UByteArray___get_size__impl_(source) % 8 | 0;
    var tmp0_plus_0 = _UByteArray___init__impl__0(padLength);
    var tmp0_plus_0_1 = _UByteArray___get_storage__impl_(tmp0_plus_0);
    var tmp1_plus_0_2 = _UByteArray___get_storage__impl_(source);
    var paddedSource = _UByteArray___init__impl_(primitiveArrayConcat([tmp0_plus_0_1, tmp1_plus_0_2]));
    var trimmedSource = toUByteArray(flatten(reversed(chunked(new UByteArray(paddedSource), 8))));
    var ulongsCount = _UByteArray___get_size__impl_(trimmedSource) / 8 | 0;
    var ulongRest = _UByteArray___get_size__impl_(trimmedSource) % 8;
    var ulongArray = _ULongArray___init__impl__0(ulongsCount + 1 | 0);
    var inductionVariable = 0;
    if (inductionVariable < ulongsCount)
      do {
        var i = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        var inductionVariable_0 = 0;
        if (inductionVariable_0 < 8)
          do {
            var j = inductionVariable_0;
            inductionVariable_0 = inductionVariable_0 + 1 | 0;
            var tmp4_or_0 = ULongArray__get_impl(ulongArray, i);
            var tmp1_toULong_0 = UByteArray__get_impl(trimmedSource, imul(i, 8) + j | 0);
            var tmp2_shl_0 = _ULong___init__impl_(toLong_0(_UByte___get_data__impl_(tmp1_toULong_0)).and(new Long(255, 0)));
            var tmp3_shl_0 = 56 - imul(j, 8) | 0;
            var tmp5_or_0 = _ULong___init__impl_(_ULong___get_data__impl_(tmp2_shl_0).shl_4(tmp3_shl_0));
            ULongArray__set_impl(ulongArray, i, _ULong___init__impl_(_ULong___get_data__impl_(tmp4_or_0).or(_ULong___get_data__impl_(tmp5_or_0))));
          }
           while (inductionVariable_0 < 8);
      }
       while (inductionVariable < ulongsCount);
    var inductionVariable_1 = 0;
    if (inductionVariable_1 < ulongRest)
      do {
        var i_0 = inductionVariable_1;
        inductionVariable_1 = inductionVariable_1 + 1 | 0;
        var tmp = _ULongArray___get_size__impl_(ulongArray) - 1 | 0;
        var tmp9_or_0 = ULongArray__get_impl(ulongArray, _ULongArray___get_size__impl_(ulongArray) - 1 | 0);
        var tmp6_toULong_0 = UByteArray__get_impl(trimmedSource, imul(ulongsCount, 8) + i_0 | 0);
        var tmp7_shl_0 = _ULong___init__impl_(toLong_0(_UByte___get_data__impl_(tmp6_toULong_0)).and(new Long(255, 0)));
        var tmp8_shl_0 = imul(ulongRest - 1 | 0, 8) - imul(i_0, 8) | 0;
        var tmp10_or_0 = _ULong___init__impl_(_ULong___get_data__impl_(tmp7_shl_0).shl_4(tmp8_shl_0));
        ULongArray__set_impl(ulongArray, tmp, _ULong___init__impl_(_ULong___get_data__impl_(tmp9_or_0).or(_ULong___get_data__impl_(tmp10_or_0))));
      }
       while (inductionVariable_1 < ulongRest);
    var tmp$ret$0;
    l$ret$1: do {
      var inductionVariable_2 = _get_lastIndex_(_ULongArray___get_storage__impl_(ulongArray));
      if (0 <= inductionVariable_2)
        do {
          var index_2 = inductionVariable_2;
          inductionVariable_2 = inductionVariable_2 + -1 | 0;
          var tmp11__anonymous__3 = ULongArray__get_impl(ulongArray, index_2);
          if (!equals_0(new ULong(tmp11__anonymous__3), new ULong(_ULong___init__impl_(new Long(0, 0))))) {
            tmp$ret$0 = take_2(ulongArray, index_2 + 1 | 0);
            break l$ret$1;
          } else {
          }
        }
         while (0 <= inductionVariable_2);
      tmp$ret$0 = emptyList();
    }
     while (false);
    var result = this.convertFrom64BitRepresentation(toULongArray(tmp$ret$0));
    return result;
  };
  BigInteger63Arithmetic.prototype.fromByteArray_1 = function (source) {
    return this.fromUByteArray_1(_UByteArray___init__impl_(source));
  };
  BigInteger63Arithmetic.prototype.toUByteArray_1 = function (operand) {
    var tmp0_reversedArray_0 = this.convertTo64BitRepresentation(operand);
    var as64Bit = _ULongArray___init__impl_(reversedArray(_ULongArray___get_storage__impl_(tmp0_reversedArray_0)));
    var result = _UByteArray___init__impl__0(imul(_ULongArray___get_size__impl_(as64Bit), 8));
    var inductionVariable = 0;
    var last_2 = _ULongArray___get_size__impl_(as64Bit);
    if (inductionVariable < last_2)
      do {
        var i = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        var tmp1_copyInto_0 = toBigEndianUByteArray(ULongArray__get_impl(as64Bit, i));
        var tmp2_copyInto_0 = imul(i, 8);
        var tmp3_copyInto_0 = 0;
        var tmp4_copyInto_0 = 8;
        var tmp0_copyInto_0_1 = _UByteArray___get_storage__impl_(tmp1_copyInto_0);
        var tmp1_copyInto_0_2 = _UByteArray___get_storage__impl_(result);
        var tmp = tmp0_copyInto_0_1;
        arrayCopy_0(tmp, tmp1_copyInto_0_2, tmp2_copyInto_0, tmp3_copyInto_0, tmp4_copyInto_0);
        Unit_getInstance();
        Unit_getInstance();
      }
       while (inductionVariable < last_2);
    var yielding_1 = false;
    var list_2 = ArrayList_init_$Create$();
    var tmp0_iterator_3 = UByteArray__iterator_impl(result);
    while (tmp0_iterator_3.hasNext_41()) {
      var item_4 = tmp0_iterator_3.next_46()._data;
      if (yielding_1) {
        list_2.add_39(new UByte(item_4));
        Unit_getInstance();
      } else {
        if (!equals_0(new UInt(_UInt___init__impl_(_UByte___get_data__impl_(item_4) & 255)), new UInt(_UInt___init__impl_(0)))) {
          list_2.add_39(new UByte(item_4));
          Unit_getInstance();
          yielding_1 = true;
        } else {
        }
      }
    }
    return toUByteArray(list_2);
  };
  BigInteger63Arithmetic.prototype.toByteArray_1 = function (operand) {
    var tmp0_asByteArray_0 = this.toUByteArray_1(operand);
    return _UByteArray___get_storage__impl_(tmp0_asByteArray_0);
  };
  BigInteger63Arithmetic.$metadata$ = {
    simpleName: 'BigInteger63Arithmetic',
    kind: 'object',
    interfaces: [BigIntegerArithmetic]
  };
  var BigInteger63Arithmetic_instance;
  function BigInteger63Arithmetic_getInstance() {
    if (BigInteger63Arithmetic_instance == null)
      new BigInteger63Arithmetic();
    return BigInteger63Arithmetic_instance;
  }
  function toBigEndianUByteArray(_this_) {
    var tmp0_UByteArray_0 = 8;
    var tmp_1 = 0;
    var tmp_2 = tmp0_UByteArray_0;
    var tmp_3 = new Int8Array(tmp_2);
    while (tmp_1 < tmp_2) {
      var tmp_4 = tmp_1;
      var tmp0_shr_0_9 = 56 - imul(tmp_4, 8) | 0;
      var tmp1_and_0_8 = _ULong___init__impl_(_ULong___get_data__impl_(_this_).ushr_0(tmp0_shr_0_9));
      var tmp2_and_0_10 = _ULong___init__impl_(new Long(255, 0));
      var tmp3_toUByte_0_7 = _ULong___init__impl_(_ULong___get_data__impl_(tmp1_and_0_8).and(_ULong___get_data__impl_(tmp2_and_0_10)));
      var tmp0_toUByte_0_1_11 = _ULong___get_data__impl_(tmp3_toUByte_0_7);
      var tmp0_toByte_0_5 = _UByte___init__impl_(tmp0_toUByte_0_1_11.toByte_4());
      tmp_3[tmp_4] = _UByte___get_data__impl_(tmp0_toByte_0_5);
      tmp_1 = tmp_1 + 1 | 0;
    }
    return _UByteArray___init__impl_(tmp_3);
  }
  function fromTwosComplementByteArray(_this_, source) {
    if (source.length === 0) {
      return _this_._ZERO_0;
    }if (source[0] < 0) {
      var inverted = invert(_UByteArray___init__impl_(source));
      var incremented = increment(inverted);
      var converted = Companion_getInstance_13().fromUByteArray(incremented, Sign_NEGATIVE_getInstance());
      return converted;
    } else {
      return _this_.fromByteArray(source, Sign_POSITIVE_getInstance());
    }
  }
  function toTwosComplementByteArray(_this_) {
    if (ULongArray__isEmpty_impl(_this_._magnitude)) {
      var tmp0_byteArrayOf_0 = new Int8Array([0]);
      return tmp0_byteArrayOf_0;
    }var tmp;
    if (_this_._sign.equals(Sign_NEGATIVE_getInstance())) {
      if (_ULongArray___get_size__impl_(_this_._magnitude) === 1 ? equals_0(new ULong(ULongArray__get_impl(_this_._magnitude, 0)), new ULong(_ULong___init__impl_(new Long(1, 0)))) : false) {
        var tmp1_byteArrayOf_0 = new Int8Array([-1]);
        return tmp1_byteArrayOf_0;
      }var nonTwosComplementArray = _this_.toByteArray_10();
      var inverted = invert(_UByteArray___init__impl_(nonTwosComplementArray));
      var incremented = increment(inverted);
      var result = _UByteArray___get_storage__impl_(incremented);
      var tmp$ret$0;
      l$ret$1: do {
        var inductionVariable = 0;
        var last_2 = result.length - 1 | 0;
        if (inductionVariable <= last_2)
          do {
            var index_2 = inductionVariable;
            inductionVariable = inductionVariable + 1 | 0;
            var tmp2__anonymous__3 = result[index_2];
            if (!(tmp2__anonymous__3 === -1)) {
              tmp$ret$0 = index_2;
              break l$ret$1;
            } else {
            }
          }
           while (inductionVariable <= last_2);
        tmp$ret$0 = -1;
      }
       while (false);
      var firstNonZero = tmp$ret$0;
      var tmp_0;
      if (firstNonZero === -1) {
        tmp_0 = 0;
      } else {
        tmp_0 = firstNonZero;
      }
      var firstNonZeroChecked = tmp_0;
      var firstNonZeroValue = result[firstNonZeroChecked];
      var tmp3_and_0 = _UInt___init__impl_(firstNonZeroValue);
      var tmp4_and_0 = _UInt___init__impl_(255);
      var tmp5_shr_0 = _UInt___init__impl_(_UInt___get_data__impl_(tmp3_and_0) & _UInt___get_data__impl_(tmp4_and_0));
      var tmp6_shr_0 = 7;
      var needsAdditionalByte = !equals_0(new UInt(_UInt___init__impl_(_UInt___get_data__impl_(tmp5_shr_0) >>> tmp6_shr_0)), new UInt(_UInt___init__impl_(1)));
      var tmp_1;
      if (needsAdditionalByte) {
        var tmp7_byteArrayOf_0 = new Int8Array([-1]);
        var tmp8_plus_0 = tmp7_byteArrayOf_0;
        var tmp9_plus_0 = sliceArray(result, until(firstNonZeroChecked, result.length));
        tmp_1 = primitiveArrayConcat([tmp8_plus_0, tmp9_plus_0]);
      } else {
        tmp_1 = sliceArray(result, until(firstNonZeroChecked, result.length));
      }
      var trimmed = tmp_1;
      tmp = trimmed;
    } else {
      if (contentEquals(_this_._magnitude, BigInteger63Arithmetic_getInstance()._ZERO_2)) {
        var tmp10_byteArrayOf_0 = new Int8Array([0]);
        return tmp10_byteArrayOf_0;
      }var result_0 = _this_.toByteArray_10();
      var tmp$ret$2;
      l$ret$3: do {
        var inductionVariable_0 = 0;
        var last_3 = result_0.length - 1 | 0;
        if (inductionVariable_0 <= last_3)
          do {
            var index_2_0 = inductionVariable_0;
            inductionVariable_0 = inductionVariable_0 + 1 | 0;
            var tmp11__anonymous__3 = result_0[index_2_0];
            if (!equals_0(new UInt(_UInt___init__impl_(tmp11__anonymous__3)), new UInt(_UInt___init__impl_(0)))) {
              tmp$ret$2 = index_2_0;
              break l$ret$3;
            } else {
            }
          }
           while (inductionVariable_0 <= last_3);
        tmp$ret$2 = -1;
      }
       while (false);
      var firstNonZero_0 = tmp$ret$2;
      var tmp_2;
      if (firstNonZero_0 === -1) {
        tmp_2 = 0;
      } else {
        tmp_2 = firstNonZero_0;
      }
      var firstNonZeroChecked_0 = tmp_2;
      var firstNonZeroValue_0 = result_0[firstNonZeroChecked_0];
      var tmp12_and_0 = _UInt___init__impl_(firstNonZeroValue_0);
      var tmp13_and_0 = _UInt___init__impl_(255);
      var tmp14_shr_0 = _UInt___init__impl_(_UInt___get_data__impl_(tmp12_and_0) & _UInt___get_data__impl_(tmp13_and_0));
      var tmp15_shr_0 = 7;
      var needsAdditionalByte_0 = !equals_0(new UInt(_UInt___init__impl_(_UInt___get_data__impl_(tmp14_shr_0) >>> tmp15_shr_0)), new UInt(_UInt___init__impl_(0)));
      var tmp_3;
      if (needsAdditionalByte_0) {
        var tmp16_byteArrayOf_0 = new Int8Array([0]);
        var tmp17_plus_0 = tmp16_byteArrayOf_0;
        var tmp18_plus_0 = sliceArray(result_0, until(firstNonZeroChecked_0, result_0.length));
        tmp_3 = primitiveArrayConcat([tmp17_plus_0, tmp18_plus_0]);
      } else {
        tmp_3 = sliceArray(result_0, until(firstNonZeroChecked_0, result_0.length));
      }
      var trimmed_0 = tmp_3;
      tmp = trimmed_0;
    }
    return tmp;
  }
  function invert(array) {
    var tmp0_mapTo_0_1 = ArrayList_init_$Create$_0(_UByteArray___get_size__impl_(array));
    var tmp0_iterator_1_2 = UByteArray__iterator_impl(array);
    while (tmp0_iterator_1_2.hasNext_41()) {
      var item_2_3 = tmp0_iterator_1_2.next_46()._data;
      var tmp0_inv_0_1_5 = _UByte___get_data__impl_(item_2_3);
      tmp0_mapTo_0_1.add_39(new UByte(_UByte___init__impl_(toByte(~tmp0_inv_0_1_5))));
      Unit_getInstance();
    }
    return toUByteArray(tmp0_mapTo_0_1);
  }
  function increment(byteString) {
    var tmp$ret$0;
    l$ret$1: do {
      var tmp0_indexOfLast_0_1 = _UByteArray___get_storage__impl_(byteString);
      var inductionVariable = tmp0_indexOfLast_0_1.length - 1 | 0;
      if (0 <= inductionVariable)
        do {
          var index_2_3 = inductionVariable;
          inductionVariable = inductionVariable + -1 | 0;
          var tmp1__anonymous__3_4 = tmp0_indexOfLast_0_1[index_2_3];
          var tmp0__anonymous__5 = _UByte___init__impl_(tmp1__anonymous__3_4);
          var tmp0_compareTo_0_6 = _UInt___init__impl_(255);
          var tmp0_compareTo_0_1_7 = _UInt___init__impl_(_UByte___get_data__impl_(tmp0__anonymous__5) & 255);
          if (uintCompare(_UInt___get_data__impl_(tmp0_compareTo_0_1_7), _UInt___get_data__impl_(tmp0_compareTo_0_6)) < 0) {
            tmp$ret$0 = index_2_3;
            break l$ret$1;
          } else {
          }
        }
         while (0 <= inductionVariable);
      tmp$ret$0 = -1;
    }
     while (false);
    var firstLessThan255 = tmp$ret$0;
    var tmp;
    if (!(firstLessThan255 === -1)) {
      var tmp0_copyOf_0_1 = _UByteArray___get_storage__impl_(byteString);
      var copy_1 = _UByteArray___init__impl_(tmp0_copyOf_0_1.slice());
      var inductionVariable_0 = _UByteArray___get_size__impl_(byteString) - 1 | 0;
      var last_2 = firstLessThan255 + 1 | 0;
      if (last_2 <= inductionVariable_0)
        do {
          var i = inductionVariable_0;
          inductionVariable_0 = inductionVariable_0 + -1 | 0;
          UByteArray__set_impl(copy_1, i, _UByte___init__impl_(0));
        }
         while (!(i === last_2));
      var tmp1_array = copy_1;
      var tmp2_index0 = firstLessThan255;
      var tmp3 = UByteArray__get_impl(tmp1_array, tmp2_index0);
      UByteArray__set_impl(tmp1_array, tmp2_index0, _UByte___init__impl_(numberToByte(_UByte___get_data__impl_(tmp3) + 1)));
      Unit_getInstance();
      tmp = copy_1;
    } else {
      var tmp1_ubyteArrayOf_0 = _UByteArray___init__impl_(new Int8Array([_UByte___init__impl_(1)]));
      var tmp2_plus_0 = tmp1_ubyteArrayOf_0;
      var tmp0_plus_0_1 = _UByteArray___get_storage__impl_(tmp2_plus_0);
      var tmp1_plus_0_2 = _UByteArray___get_storage__impl_(byteString);
      tmp = _UByteArray___init__impl_(primitiveArrayConcat([tmp0_plus_0_1, tmp1_plus_0_2]));
    }
    return tmp;
  }
  function toDigit(_this_, base) {
    var tmp0_subject = _this_;
    var tmp;
    if (new Char(48) <= tmp0_subject ? tmp0_subject <= new Char(57) : false) {
      tmp = _this_.minus_39(48).toInt_5();
    } else if (new Char(97) <= tmp0_subject ? tmp0_subject <= new Char(122) : false) {
      tmp = _this_.minus(new Char(97)) + 10 | 0;
    } else if (new Char(65) <= tmp0_subject ? tmp0_subject <= new Char(90) : false) {
      tmp = _this_.minus(new Char(65)) + 10 | 0;
    } else if (new Char(65313) <= tmp0_subject ? tmp0_subject <= new Char(65338) : false) {
      tmp = _this_.minus(new Char(65313)) - 10 | 0;
    } else if (new Char(65345) <= tmp0_subject ? tmp0_subject <= new Char(65370) : false) {
      tmp = _this_.minus(new Char(65345)) - 10 | 0;
    } else if (tmp0_subject.equals(new Char(46))) {
      throw NumberFormatException_init_$Create$('' + 'Invalid digit for radix ' + _this_ + ' (Possibly a decimal value, which is not supported by BigInteger parser');
    } else {
      throw NumberFormatException_init_$Create$('' + 'Invalid digit for radix ' + _this_);
    }
    var digit = tmp;
    if (digit < 0 ? true : digit >= base) {
      throw NumberFormatException_init_$Create$('' + _this_ + ' is not a valid digit for number system with base ' + base);
    }return digit;
  }
  function prep(_this_, $this) {
    var result = _this_.rem_1($this._$modulo);
    var tmp0_subject = result._sign;
    var tmp;
    if (tmp0_subject.equals(Sign_POSITIVE_getInstance())) {
      tmp = result;
    } else if (tmp0_subject.equals(Sign_NEGATIVE_getInstance())) {
      tmp = result.plus_4($this._$modulo);
    } else if (tmp0_subject.equals(Sign_ZERO_getInstance())) {
      tmp = Companion_getInstance_13()._ZERO_0;
    } else {
      noWhenBranchMatchedException();
    }
    return tmp;
  }
  function _no_name_provided__61($modulo) {
    this._$modulo = $modulo;
    this._ZERO_3 = new ModularBigInteger(Companion_getInstance_13()._ZERO_0, this._$modulo, this);
    this._ONE_3 = new ModularBigInteger(Companion_getInstance_13()._ONE_0, this._$modulo, this);
    this._TWO_3 = new ModularBigInteger(Companion_getInstance_13()._TWO_0, this._$modulo, this);
    this._TEN_3 = new ModularBigInteger(Companion_getInstance_13()._TEN_0, this._$modulo, this);
  }
  _no_name_provided__61.prototype._get_ZERO__4 = function () {
    return this._ZERO_3;
  };
  _no_name_provided__61.prototype.fromBigInteger_3 = function (bigInteger) {
    return new ModularBigInteger(prep(bigInteger, this), this._$modulo, this);
  };
  _no_name_provided__61.prototype.fromLong_5 = function (long) {
    return new ModularBigInteger(prep(Companion_getInstance_13().fromLong_5(long), this), this._$modulo, this);
  };
  _no_name_provided__61.prototype.fromInt_5 = function (int) {
    return new ModularBigInteger(prep(Companion_getInstance_13().fromInt_5(int), this), this._$modulo, this);
  };
  _no_name_provided__61.prototype.fromShort_5 = function (short) {
    return new ModularBigInteger(prep(Companion_getInstance_13().fromShort_5(short), this), this._$modulo, this);
  };
  _no_name_provided__61.prototype.fromByte_5 = function (byte) {
    return new ModularBigInteger(prep(Companion_getInstance_13().fromByte_5(byte), this), this._$modulo, this);
  };
  _no_name_provided__61.prototype.tryFromFloat_2 = function (float, exactRequired) {
    return new ModularBigInteger(prep(Companion_getInstance_13().tryFromFloat_2(float, exactRequired), this), this._$modulo, this);
  };
  _no_name_provided__61.prototype.tryFromDouble_2 = function (double, exactRequired) {
    return new ModularBigInteger(prep(Companion_getInstance_13().tryFromDouble_2(double, exactRequired), this), this._$modulo, this);
  };
  _no_name_provided__61.$metadata$ = {
    kind: 'class',
    interfaces: [Creator]
  };
  function Companion_15() {
    Companion_instance_14 = this;
  }
  Companion_15.prototype.creatorForModulo = function (modulo_0) {
    return new _no_name_provided__61(modulo_0);
  };
  Companion_15.$metadata$ = {
    simpleName: 'Companion',
    kind: 'object',
    interfaces: []
  };
  var Companion_instance_14;
  function Companion_getInstance_14() {
    if (Companion_instance_14 == null)
      new Companion_15();
    return Companion_instance_14;
  }
  function assertSameModulo($this, other) {
    if (!$this._modulus.equals(other._modulus)) {
      throw RuntimeException_init_$Create$('' + 'Different moduli! This ' + $this._modulus + '\n Other ' + other._modulus);
    }}
  function checkIfDivisible($this, other) {
    if (!other._residue.gcd($this._modulus).equals(Companion_getInstance_13()._ONE_0)) {
      throw ArithmeticException_init_$Create$('BigInteger is not invertible. Operand and modulus are not relatively prime (coprime)');
    }}
  function ModularBigInteger(signedResidue, modulus, creator) {
    Companion_getInstance_14();
    this._modulus = modulus;
    this._creator = creator;
    var tmp = this;
    var tmp0_subject = signedResidue._sign;
    var tmp_0;
    if (tmp0_subject.equals(Sign_POSITIVE_getInstance())) {
      tmp_0 = signedResidue;
    } else if (tmp0_subject.equals(Sign_NEGATIVE_getInstance())) {
      tmp_0 = signedResidue.plus_4(this._modulus);
    } else if (tmp0_subject.equals(Sign_ZERO_getInstance())) {
      tmp_0 = Companion_getInstance_13()._ZERO_0;
    } else {
      noWhenBranchMatchedException();
    }
    tmp._residue = tmp_0;
    if (this._modulus._sign.equals(Sign_NEGATIVE_getInstance())) {
      throw ArithmeticException_init_$Create$('Modulus must be a positive number');
    }}
  ModularBigInteger.prototype.getCreator_2 = function () {
    return this._creator;
  };
  ModularBigInteger.prototype.getInstance_2 = function () {
    return this;
  };
  ModularBigInteger.prototype.add_33 = function (other) {
    assertSameModulo(this, other);
    return new ModularBigInteger(this._residue.plus_4(other._residue).rem_1(this._modulus), this._modulus, this._creator);
  };
  ModularBigInteger.prototype.add_34 = function (other) {
    return this.add_33(other instanceof ModularBigInteger ? other : THROW_CCE());
  };
  ModularBigInteger.prototype.subtract_8 = function (other) {
    assertSameModulo(this, other);
    return new ModularBigInteger(this._residue.minus_2(other._residue).rem_1(this._modulus), this._modulus, this._creator);
  };
  ModularBigInteger.prototype.subtract_9 = function (other) {
    return this.subtract_8(other instanceof ModularBigInteger ? other : THROW_CCE());
  };
  ModularBigInteger.prototype.multiply_9 = function (other) {
    assertSameModulo(this, other);
    return new ModularBigInteger(this._residue.times_0(other._residue).rem_1(this._modulus), this._modulus, this._creator);
  };
  ModularBigInteger.prototype.multiply_10 = function (other) {
    return this.multiply_9(other instanceof ModularBigInteger ? other : THROW_CCE());
  };
  ModularBigInteger.prototype.divide_8 = function (other) {
    assertSameModulo(this, other);
    var modInverse = other._residue.modInverse(this._modulus);
    var result = modInverse.times_0(this._residue).rem_1(this._modulus);
    return new ModularBigInteger(result, this._modulus, this._creator);
  };
  ModularBigInteger.prototype.divide_9 = function (other) {
    return this.divide_8(other instanceof ModularBigInteger ? other : THROW_CCE());
  };
  ModularBigInteger.prototype.remainder_4 = function (other) {
    assertSameModulo(this, other);
    checkIfDivisible(this, other);
    var remainder = this._residue.rem_1(other._residue);
    var result = remainder.rem_1(this._modulus);
    return new ModularBigInteger(result, this._modulus, this._creator);
  };
  ModularBigInteger.prototype.remainder_5 = function (other) {
    return this.remainder_4(other instanceof ModularBigInteger ? other : THROW_CCE());
  };
  ModularBigInteger.prototype.inverse_0 = function () {
    var inverse = this._residue.modInverse(this._modulus);
    return new ModularBigInteger(inverse, this._modulus, this._creator);
  };
  ModularBigInteger.prototype.compare_5 = function (other) {
    assertSameModulo(this, other);
    return this._residue.compareTo_26(other._residue);
  };
  ModularBigInteger.prototype.pow_4 = function (exponent) {
    var e = exponent;
    var tmp;
    if (this._modulus.equals(Companion_getInstance_13()._ONE_0)) {
      tmp = this._creator._get_ZERO__4();
    } else {
      var residue = Companion_getInstance_13()._ONE_0;
      var base = this._residue;
      while (e.compareTo_26(0) > 0) {
        if (e.rem_32(2).equals(Companion_getInstance_13()._ONE_0)) {
          residue = residue.times_0(base).rem_1(this._modulus);
        }e = e.shr_4(1);
        base = base.pow_1(2).rem_1(this._modulus);
      }
      tmp = new ModularBigInteger(residue, this._modulus, this._creator);
    }
    return tmp;
  };
  ModularBigInteger.prototype.signum_2 = function () {
    return this._residue.signum_2();
  };
  ModularBigInteger.prototype.compareTo_26 = function (other) {
    var tmp0_subject = other;
    var tmp;
    if (tmp0_subject instanceof ModularBigInteger) {
      tmp = this.compare_5(other);
    } else {
      if (tmp0_subject instanceof BigInteger) {
        tmp = this._residue.compare_1(other);
      } else {
        if (tmp0_subject instanceof Long) {
          tmp = this.compare_5(this._creator.fromLong_5(other));
        } else {
          if (typeof tmp0_subject === 'number') {
            tmp = this.compare_5(this._creator.fromInt_5(other));
          } else {
            if (typeof tmp0_subject === 'number') {
              tmp = this.compare_5(this._creator.fromShort_5(other));
            } else {
              if (typeof tmp0_subject === 'number') {
                tmp = this.compare_5(this._creator.fromByte_5(other));
              } else {
                {
                  throw RuntimeException_init_$Create$('' + 'Invalid comparison type for BigInteger: ' + other);
                }
              }
            }
          }
        }
      }
    }
    return tmp;
  };
  ModularBigInteger.prototype.equals = function (other) {
    var tmp;
    if (other == null) {
      tmp = false;
    } else {
      tmp = this.compareTo_26(other) === 0;
    }
    return tmp;
  };
  ModularBigInteger.prototype.toString = function () {
    return this._residue.toString();
  };
  ModularBigInteger.prototype.toUByteArray_10 = function () {
    return this._residue.toUByteArray_10();
  };
  ModularBigInteger.prototype.toByteArray_10 = function () {
    return this._residue.toByteArray_10();
  };
  ModularBigInteger.$metadata$ = {
    simpleName: 'ModularBigInteger',
    kind: 'class',
    interfaces: [BigNumber, CommonBigNumberOperations, NarrowingOperations, ByteArraySerializable]
  };
  function ComparisonWorkaround() {
    ComparisonWorkaround_instance = this;
  }
  ComparisonWorkaround.prototype.isSpecialHandlingForFloatNeeded = function (number) {
    return true;
  };
  ComparisonWorkaround.$metadata$ = {
    simpleName: 'ComparisonWorkaround',
    kind: 'object',
    interfaces: []
  };
  var ComparisonWorkaround_instance;
  function ComparisonWorkaround_getInstance() {
    if (ComparisonWorkaround_instance == null)
      new ComparisonWorkaround();
    return ComparisonWorkaround_instance;
  }
  function None() {
    None_instance = this;
    TraceBase.call(this);
  }
  None.$metadata$ = {
    simpleName: 'None',
    kind: 'object',
    interfaces: []
  };
  var None_instance;
  function None_getInstance() {
    if (None_instance == null)
      new None();
    return None_instance;
  }
  function TraceBase() {
  }
  TraceBase.prototype.atomicfu$Trace$append$1 = function (event) {
  };
  TraceBase.prototype.atomicfu$Trace$append$2 = function (event1, event2) {
  };
  TraceBase.prototype.atomicfu$Trace$append$3 = function (event1, event2, event3) {
  };
  TraceBase.prototype.atomicfu$Trace$append$4 = function (event1, event2, event3, event4) {
  };
  TraceBase.$metadata$ = {
    simpleName: 'TraceBase',
    kind: 'class',
    interfaces: []
  };
  function AtomicRef(value_0) {
    this._value_0 = value_0;
  }
  AtomicRef.prototype._set_value__6 = function (_set___) {
    this._value_0 = _set___;
  };
  AtomicRef.prototype._get_value__33 = function () {
    return this._value_0;
  };
  AtomicRef.prototype.atomicfu$compareAndSet = function (expect, update) {
    if (!(this._value_0 === expect))
      return false;
    this._value_0 = update;
    return true;
  };
  AtomicRef.prototype.atomicfu$getAndSet = function (value_0) {
    var oldValue = this._value_0;
    this._value_0 = value_0;
    return oldValue;
  };
  AtomicRef.prototype.toString = function () {
    return toString_1(this._value_0);
  };
  AtomicRef.$metadata$ = {
    simpleName: 'AtomicRef',
    kind: 'class',
    interfaces: []
  };
  Object.defineProperty(AtomicRef.prototype, 'kotlinx$atomicfu$value', {
    configurable: true,
    get: AtomicRef.prototype._get_value__33,
    set: AtomicRef.prototype._set_value__6
  });
  function atomic(initial) {
    return atomic_3(initial, None_getInstance());
  }
  function AtomicBoolean(value_0) {
    this._value_1 = value_0;
  }
  AtomicBoolean.prototype._set_value__0 = function (_set___) {
    this._value_1 = _set___;
  };
  AtomicBoolean.prototype._get_value__33 = function () {
    return this._value_1;
  };
  AtomicBoolean.prototype.atomicfu$compareAndSet = function (expect, update) {
    if (!(this._value_1 === expect))
      return false;
    this._value_1 = update;
    return true;
  };
  AtomicBoolean.prototype.atomicfu$getAndSet = function (value_0) {
    var oldValue = this._value_1;
    this._value_1 = value_0;
    return oldValue;
  };
  AtomicBoolean.prototype.toString = function () {
    return this._value_1.toString();
  };
  AtomicBoolean.$metadata$ = {
    simpleName: 'AtomicBoolean',
    kind: 'class',
    interfaces: []
  };
  Object.defineProperty(AtomicBoolean.prototype, 'kotlinx$atomicfu$value', {
    configurable: true,
    get: AtomicBoolean.prototype._get_value__33,
    set: AtomicBoolean.prototype._set_value__0
  });
  function atomic_0(initial) {
    return atomic_5(initial, None_getInstance());
  }
  function AtomicInt(value_0) {
    this._value_2 = value_0;
  }
  AtomicInt.prototype._set_value__9 = function (_set___) {
    this._value_2 = _set___;
  };
  AtomicInt.prototype._get_value__33 = function () {
    return this._value_2;
  };
  AtomicInt.prototype.atomicfu$compareAndSet = function (expect, update) {
    if (!(this._value_2 === expect))
      return false;
    this._value_2 = update;
    return true;
  };
  AtomicInt.prototype.atomicfu$getAndSet = function (value_0) {
    var oldValue = this._value_2;
    this._value_2 = value_0;
    return oldValue;
  };
  AtomicInt.prototype.atomicfu$getAndIncrement = function () {
    var tmp0_this = this;
    var tmp1 = tmp0_this._value_2;
    tmp0_this._value_2 = tmp1 + 1 | 0;
    return tmp1;
  };
  AtomicInt.prototype.atomicfu$getAndDecrement = function () {
    var tmp0_this = this;
    var tmp1 = tmp0_this._value_2;
    tmp0_this._value_2 = tmp1 - 1 | 0;
    return tmp1;
  };
  AtomicInt.prototype.atomicfu$getAndAdd = function (delta_0) {
    var oldValue = this._value_2;
    var tmp0_this = this;
    tmp0_this._value_2 = tmp0_this._value_2 + delta_0 | 0;
    return oldValue;
  };
  AtomicInt.prototype.atomicfu$addAndGet = function (delta_0) {
    var tmp0_this = this;
    tmp0_this._value_2 = tmp0_this._value_2 + delta_0 | 0;
    return this._value_2;
  };
  AtomicInt.prototype.atomicfu$incrementAndGet = function () {
    var tmp0_this = this;
    tmp0_this._value_2 = tmp0_this._value_2 + 1 | 0;
    return tmp0_this._value_2;
  };
  AtomicInt.prototype.atomicfu$decrementAndGet = function () {
    var tmp0_this = this;
    tmp0_this._value_2 = tmp0_this._value_2 - 1 | 0;
    return tmp0_this._value_2;
  };
  AtomicInt.prototype.toString = function () {
    return this._value_2.toString();
  };
  AtomicInt.$metadata$ = {
    simpleName: 'AtomicInt',
    kind: 'class',
    interfaces: []
  };
  Object.defineProperty(AtomicInt.prototype, 'kotlinx$atomicfu$value', {
    configurable: true,
    get: AtomicInt.prototype._get_value__33,
    set: AtomicInt.prototype._set_value__9
  });
  function atomic_1(initial) {
    return atomic_7(initial, None_getInstance());
  }
  function AtomicLong(value_0) {
    this._value_3 = value_0;
  }
  AtomicLong.prototype._set_value__2 = function (_set___) {
    this._value_3 = _set___;
  };
  AtomicLong.prototype._get_value__33 = function () {
    return this._value_3;
  };
  AtomicLong.prototype.atomicfu$compareAndSet = function (expect, update) {
    if (!this._value_3.equals(expect))
      return false;
    this._value_3 = update;
    return true;
  };
  AtomicLong.prototype.atomicfu$getAndSet = function (value_0) {
    var oldValue = this._value_3;
    this._value_3 = value_0;
    return oldValue;
  };
  AtomicLong.prototype.atomicfu$getAndIncrement$long = function () {
    var tmp0_this = this;
    var tmp1 = tmp0_this._value_3;
    tmp0_this._value_3 = tmp1.inc_5();
    return tmp1;
  };
  AtomicLong.prototype.atomicfu$getAndDecrement$long = function () {
    var tmp0_this = this;
    var tmp1 = tmp0_this._value_3;
    tmp0_this._value_3 = tmp1.dec_5();
    return tmp1;
  };
  AtomicLong.prototype.atomicfu$getAndAdd$long = function (delta_0) {
    var oldValue = this._value_3;
    var tmp0_this = this;
    tmp0_this._value_3 = tmp0_this._value_3.plus_71(delta_0);
    return oldValue;
  };
  AtomicLong.prototype.atomicfu$addAndGet$long = function (delta_0) {
    var tmp0_this = this;
    tmp0_this._value_3 = tmp0_this._value_3.plus_71(delta_0);
    return this._value_3;
  };
  AtomicLong.prototype.atomicfu$incrementAndGet$long = function () {
    var tmp0_this = this;
    tmp0_this._value_3 = tmp0_this._value_3.inc_5();
    return tmp0_this._value_3;
  };
  AtomicLong.prototype.atomicfu$decrementAndGet$long = function () {
    var tmp0_this = this;
    tmp0_this._value_3 = tmp0_this._value_3.dec_5();
    return tmp0_this._value_3;
  };
  AtomicLong.prototype.toString = function () {
    return this._value_3.toString();
  };
  AtomicLong.$metadata$ = {
    simpleName: 'AtomicLong',
    kind: 'class',
    interfaces: []
  };
  Object.defineProperty(AtomicLong.prototype, 'kotlinx$atomicfu$value', {
    configurable: true,
    get: AtomicLong.prototype._get_value__33,
    set: AtomicLong.prototype._set_value__2
  });
  function atomic_2(initial, trace) {
    return atomic_3(initial, trace === void 1 ? None_getInstance() : trace);
  }
  function atomic_3(initial, trace) {
    return new AtomicRef(initial);
  }
  function atomic_4(initial, trace) {
    return atomic_5(initial, trace === void 1 ? None_getInstance() : trace);
  }
  function atomic_5(initial, trace) {
    return new AtomicBoolean(initial);
  }
  function atomic_6(initial, trace) {
    return atomic_7(initial, trace === void 1 ? None_getInstance() : trace);
  }
  function atomic_7(initial, trace) {
    return new AtomicInt(initial);
  }
  function atomic_8(initial, trace) {
    return atomic_9(initial, trace === void 1 ? None_getInstance() : trace);
  }
  function atomic_9(initial, trace) {
    return new AtomicLong(initial);
  }
  function AbstractCoroutine(parentContext, active) {
    JobSupport.call(this, active);
    this._parentContext = parentContext;
    this._context = this._parentContext.plus_43(this);
  }
  AbstractCoroutine.prototype._get_context__31 = function () {
    return this._context;
  };
  AbstractCoroutine.prototype._get_coroutineContext__8 = function () {
    return this._context;
  };
  AbstractCoroutine.prototype._get_isActive__23 = function () {
    return JobSupport.prototype._get_isActive__23.call(this);
  };
  AbstractCoroutine.prototype.initParentJob_6 = function () {
    this.initParentJobInternal_9(this._parentContext.get_62(Key_getInstance_2()));
  };
  AbstractCoroutine.prototype.onStart_6 = function () {
  };
  AbstractCoroutine.prototype.onStartInternal_9 = function () {
    this.onStart_6();
  };
  AbstractCoroutine.prototype.onCompleted_6 = function (value_0) {
  };
  AbstractCoroutine.prototype.onCancelled_6 = function (cause, handled) {
  };
  AbstractCoroutine.prototype.cancellationExceptionMessage_9 = function () {
    return '' + _get_classSimpleName_(this) + ' was cancelled';
  };
  AbstractCoroutine.prototype.onCompletionInternal_9 = function (state) {
    if (state instanceof CompletedExceptionally)
      this.onCancelled_6(state._cause, state._get_handled__0());
    else {
      {
        this.onCompleted_6((state == null ? true : isObject(state)) ? state : THROW_CCE());
      }
    }
  };
  AbstractCoroutine.prototype.resumeWith_21 = function (result) {
    var state = this.makeCompletingOnce_7(toState$default(result, null, 1, null));
    if (state === COMPLETING_WAITING_CHILDREN)
      return Unit_getInstance();
    this.afterResume_6(state);
  };
  AbstractCoroutine.prototype.afterResume_6 = function (state) {
    return this.afterCompletion_9(state);
  };
  AbstractCoroutine.prototype.handleOnCompletionException_9 = function (exception) {
    handleCoroutineException(this._context, exception);
  };
  AbstractCoroutine.prototype.nameString_11 = function () {
    var tmp0_elvis_lhs = _get_coroutineName_(this._context);
    var tmp;
    if (tmp0_elvis_lhs == null) {
      return JobSupport.prototype.nameString_11.call(this);
    } else {
      tmp = tmp0_elvis_lhs;
    }
    var coroutineName = tmp;
    return '' + '"' + coroutineName + '":' + JobSupport.prototype.nameString_11.call(this);
  };
  AbstractCoroutine.prototype.start_9 = function (start_0, receiver, block) {
    this.initParentJob_6();
    start_0.invoke_91(block, receiver, this);
  };
  AbstractCoroutine.$metadata$ = {
    simpleName: 'AbstractCoroutine',
    kind: 'class',
    interfaces: [Job, Continuation, CoroutineScope]
  };
  function launch(_this_, context, start_0, block) {
    var newContext = newCoroutineContext(_this_, context);
    var coroutine = start_0._get_isLazy_() ? new LazyStandaloneCoroutine(newContext, block) : new StandaloneCoroutine(newContext, true);
    coroutine.start_9(start_0, coroutine, block);
    return coroutine;
  }
  function launch$default(_this_, context, start_0, block, $mask0, $handler) {
    if (!(($mask0 & 1) === 0))
      context = EmptyCoroutineContext_getInstance();
    if (!(($mask0 & 2) === 0))
      start_0 = CoroutineStart_DEFAULT_getInstance();
    return launch(_this_, context, start_0, block);
  }
  function withContext(context, block, $cont) {
    var tmp$ret$0;
    l$ret$1: do {
      var tmp0__anonymous__1 = $cont;
      var oldContext_2 = tmp0__anonymous__1._get_context__31();
      var newContext_3 = oldContext_2.plus_43(context);
      checkCompletion(newContext_3);
      if (newContext_3 === oldContext_2) {
        var coroutine_4 = new ScopeCoroutine(newContext_3, tmp0__anonymous__1);
        tmp$ret$0 = startUndispatchedOrReturn(coroutine_4, coroutine_4, block);
        break l$ret$1;
      }if (equals_0(newContext_3.get_62(Key_getInstance()), oldContext_2.get_62(Key_getInstance()))) {
        var coroutine_5 = new UndispatchedCoroutine(newContext_3, tmp0__anonymous__1);
        var tmp0_withCoroutineContext_0_6 = null;
        tmp$ret$0 = startUndispatchedOrReturn(coroutine_5, coroutine_5, block);
        break l$ret$1;
      }var coroutine_7 = new DispatchedCoroutine(newContext_3, tmp0__anonymous__1);
      coroutine_7.initParentJob_6();
      startCoroutineCancellable$default(block, coroutine_7, coroutine_7, null, 4, null);
      tmp$ret$0 = coroutine_7.getResult_1();
    }
     while (false);
    return tmp$ret$0;
  }
  function async(_this_, context, start_0, block) {
    var newContext = newCoroutineContext(_this_, context);
    var coroutine = start_0._get_isLazy_() ? new LazyDeferredCoroutine(newContext, block) : new DeferredCoroutine(newContext, true);
    coroutine.start_9(start_0, coroutine, block);
    return coroutine;
  }
  function async$default(_this_, context, start_0, block, $mask0, $handler) {
    if (!(($mask0 & 1) === 0))
      context = EmptyCoroutineContext_getInstance();
    if (!(($mask0 & 2) === 0))
      start_0 = CoroutineStart_DEFAULT_getInstance();
    return async(_this_, context, start_0, block);
  }
  function StandaloneCoroutine(parentContext, active) {
    AbstractCoroutine.call(this, parentContext, active);
  }
  StandaloneCoroutine.prototype.handleJobException_9 = function (exception) {
    handleCoroutineException(this._get_context__31(), exception);
    return true;
  };
  StandaloneCoroutine.$metadata$ = {
    simpleName: 'StandaloneCoroutine',
    kind: 'class',
    interfaces: []
  };
  function LazyStandaloneCoroutine(parentContext, block) {
    StandaloneCoroutine.call(this, parentContext, false);
    this._continuation = createCoroutineUnintercepted(block, this, this);
  }
  LazyStandaloneCoroutine.prototype.onStart_6 = function () {
    startCoroutineCancellable_0(this._continuation, this);
  };
  LazyStandaloneCoroutine.$metadata$ = {
    simpleName: 'LazyStandaloneCoroutine',
    kind: 'class',
    interfaces: []
  };
  function trySuspend($this) {
    var tmp0_loop_0 = $this.__decision;
    while (true) {
      var tmp1__anonymous__1 = tmp0_loop_0._value_2;
      var tmp0_subject_2 = tmp1__anonymous__1;
      switch (tmp0_subject_2) {
        case 0:
          if ($this.__decision.atomicfu$compareAndSet(0, 1))
            return true;
          break;
        case 2:
          return false;
        default:var tmp0_error_0_3 = 'Already suspended';
          throw IllegalStateException_init_$Create$_0(toString_2(tmp0_error_0_3));
      }
    }
    Unit_getInstance();
  }
  function tryResume($this) {
    var tmp0_loop_0 = $this.__decision;
    while (true) {
      var tmp1__anonymous__1 = tmp0_loop_0._value_2;
      var tmp0_subject_2 = tmp1__anonymous__1;
      switch (tmp0_subject_2) {
        case 0:
          if ($this.__decision.atomicfu$compareAndSet(0, 2))
            return true;
          break;
        case 1:
          return false;
        default:var tmp0_error_0_3 = 'Already resumed';
          throw IllegalStateException_init_$Create$_0(toString_2(tmp0_error_0_3));
      }
    }
    Unit_getInstance();
  }
  function DispatchedCoroutine(context, uCont) {
    ScopeCoroutine.call(this, context, uCont);
    this.__decision = atomic_1(0);
  }
  DispatchedCoroutine.prototype.afterCompletion_9 = function (state) {
    this.afterResume_6(state);
  };
  DispatchedCoroutine.prototype.afterResume_6 = function (state) {
    if (tryResume(this))
      return Unit_getInstance();
    var tmp = intercepted(this._get_uCont__1());
    var tmp_0 = recoverResult(state, this._get_uCont__1());
    resumeCancellableWith$default(tmp, tmp_0, null, 2, null);
  };
  DispatchedCoroutine.prototype.getResult_1 = function () {
    if (trySuspend(this))
      return _get_COROUTINE_SUSPENDED_();
    var state = unboxState(this._get_state__15());
    if (state instanceof CompletedExceptionally)
      throw state._cause;
    else {
    }
    return (state == null ? true : isObject(state)) ? state : THROW_CCE();
  };
  DispatchedCoroutine.$metadata$ = {
    simpleName: 'DispatchedCoroutine',
    kind: 'class',
    interfaces: []
  };
  function $awaitCOROUTINE$0(_this_, resultContinuation) {
    CoroutineImpl_0.call(this, resultContinuation);
    this.__this_ = _this_;
  }
  $awaitCOROUTINE$0.prototype.doResume_17 = function () {
    var suspendResult = this._result_0;
    $sm: do
      try {
        var tmp = this._state_2;
        switch (tmp) {
          case 0:
            this._exceptionState = 2;
            this._state_2 = 1;
            suspendResult = this.__this_.awaitInternal_1(this);
            if (suspendResult === _get_COROUTINE_SUSPENDED_()) {
              return suspendResult;
            }
            continue $sm;
          case 1:
            var tmp_0 = suspendResult;
            return (tmp_0 == null ? true : isObject(tmp_0)) ? tmp_0 : THROW_CCE();
          case 2:
            throw this._exception_0;
        }
      } catch ($p) {
        if (this._exceptionState === 2) {
          throw $p;
        } else {
          this._state_2 = this._exceptionState;
          this._exception_0 = $p;
        }
      }
     while (true);
  };
  $awaitCOROUTINE$0.$metadata$ = {
    simpleName: '$awaitCOROUTINE$0',
    kind: 'class',
    interfaces: []
  };
  function DeferredCoroutine(parentContext, active) {
    AbstractCoroutine.call(this, parentContext, active);
  }
  DeferredCoroutine.prototype.await_1 = function ($cont) {
    var tmp = new $awaitCOROUTINE$0(this, $cont);
    tmp._result_0 = Unit_getInstance();
    tmp._exception_0 = null;
    return tmp.doResume_17();
  };
  DeferredCoroutine.$metadata$ = {
    simpleName: 'DeferredCoroutine',
    kind: 'class',
    interfaces: [Deferred, SelectClause1]
  };
  function LazyDeferredCoroutine(parentContext, block) {
    DeferredCoroutine.call(this, parentContext, false);
    this._continuation_0 = createCoroutineUnintercepted(block, this, this);
  }
  LazyDeferredCoroutine.prototype.onStart_6 = function () {
    startCoroutineCancellable_0(this._continuation_0, this);
  };
  LazyDeferredCoroutine.$metadata$ = {
    simpleName: 'LazyDeferredCoroutine',
    kind: 'class',
    interfaces: []
  };
  function CancellableContinuation() {
  }
  CancellableContinuation.prototype.cancel$default_8 = function (cause, $mask0, $handler) {
    if (!(($mask0 & 1) === 0))
      cause = null;
    return $handler == null ? this.cancel_8(cause) : $handler(cause);
  };
  CancellableContinuation.$metadata$ = {
    simpleName: 'CancellableContinuation',
    kind: 'interface',
    interfaces: [Continuation]
  };
  function disposeOnCancellation(_this_, handle) {
    var tmp0__get_asHandler__0 = new DisposeOnCancel(handle);
    return _this_.invokeOnCancellation_1(tmp0__get_asHandler__0);
  }
  function DisposeOnCancel(handle) {
    CancelHandler.call(this);
    this._handle = handle;
  }
  DisposeOnCancel.prototype.invoke_341 = function (cause) {
    return this._handle.dispose_15();
  };
  DisposeOnCancel.prototype.invoke = function (cause) {
    return this.invoke_341(cause);
  };
  DisposeOnCancel.prototype.toString = function () {
    return '' + 'DisposeOnCancel[' + this._handle + ']';
  };
  DisposeOnCancel.$metadata$ = {
    simpleName: 'DisposeOnCancel',
    kind: 'class',
    interfaces: []
  };
  function _set_parentHandle_($this, value_0) {
    $this.__parentHandle._value_0 = value_0;
  }
  function _get_parentHandle_($this) {
    return $this.__parentHandle._value_0;
  }
  function _get_stateDebugRepresentation_($this) {
    var tmp0_subject = $this._get_state__15();
    var tmp;
    if (!(tmp0_subject == null) ? isInterface(tmp0_subject, NotCompleted) : false) {
      tmp = 'Active';
    } else {
      if (tmp0_subject instanceof CancelledContinuation) {
        tmp = 'Cancelled';
      } else {
        {
          tmp = 'Completed';
        }
      }
    }
    return tmp;
  }
  function isReusable($this) {
    var tmp;
    var tmp_0 = $this._delegate_0;
    if (tmp_0 instanceof DispatchedContinuation) {
      tmp = $this._delegate_0.isReusable($this);
    } else {
      {
        tmp = false;
      }
    }
    return tmp;
  }
  function setupCancellation($this) {
    if (checkCompleted($this))
      return Unit_getInstance();
    if (!(_get_parentHandle_($this) === null))
      return Unit_getInstance();
    var tmp0_elvis_lhs = $this._delegate_0._get_context__31().get_62(Key_getInstance_2());
    var tmp;
    if (tmp0_elvis_lhs == null) {
      return Unit_getInstance();
    } else {
      tmp = tmp0_elvis_lhs;
    }
    var parent_0 = tmp;
    var tmp0__get_asHandler__0 = new ChildContinuation($this);
    var handle = parent_0.invokeOnCompletion$default_10(true, false, tmp0__get_asHandler__0, 2, null);
    _set_parentHandle_($this, handle);
    if ($this._get_isCompleted__11() ? !isReusable($this) : false) {
      handle.dispose_15();
      _set_parentHandle_($this, NonDisposableHandle_getInstance());
    }}
  function checkCompleted($this) {
    var completed = $this._get_isCompleted__11();
    if (!_get_isReusableMode_($this._get_resumeMode__1()))
      return completed;
    var tmp = $this._delegate_0;
    var tmp0_elvis_lhs = tmp instanceof DispatchedContinuation ? tmp : null;
    var tmp_0;
    if (tmp0_elvis_lhs == null) {
      return completed;
    } else {
      tmp_0 = tmp0_elvis_lhs;
    }
    var dispatched = tmp_0;
    var tmp1_elvis_lhs = dispatched.checkPostponedCancellation($this);
    var tmp_1;
    if (tmp1_elvis_lhs == null) {
      return completed;
    } else {
      tmp_1 = tmp1_elvis_lhs;
    }
    var cause = tmp_1;
    if (!completed) {
      $this.cancel_8(cause);
      Unit_getInstance();
    }return true;
  }
  function cancelLater($this, cause) {
    if (!_get_isReusableMode_($this._get_resumeMode__1()))
      return false;
    var tmp = $this._delegate_0;
    var tmp0_elvis_lhs = tmp instanceof DispatchedContinuation ? tmp : null;
    var tmp_0;
    if (tmp0_elvis_lhs == null) {
      return false;
    } else {
      tmp_0 = tmp0_elvis_lhs;
    }
    var dispatched = tmp_0;
    return dispatched.postponeCancellation(cause);
  }
  function callCancelHandler($this, handler, cause) {
    var tmp;
    try {
      tmp = invokeIt(handler, cause);
    } catch ($p) {
      var tmp_0;
      if ($p instanceof Error) {
        tmp_0 = handleCoroutineException($this._get_context__31(), new CompletionHandlerException('' + 'Exception in invokeOnCancellation handler for ' + $this, $p));
      } else {
        {
          throw $p;
        }
      }
      tmp = tmp_0;
    }
    return tmp;
  }
  function trySuspend_0($this) {
    var tmp0_loop_0 = $this.__decision_0;
    while (true) {
      var tmp1__anonymous__1 = tmp0_loop_0._value_2;
      var tmp0_subject_2 = tmp1__anonymous__1;
      switch (tmp0_subject_2) {
        case 0:
          if ($this.__decision_0.atomicfu$compareAndSet(0, 1))
            return true;
          break;
        case 2:
          return false;
        default:var tmp0_error_0_3 = 'Already suspended';
          throw IllegalStateException_init_$Create$_0(toString_2(tmp0_error_0_3));
      }
    }
    Unit_getInstance();
  }
  function tryResume_0($this) {
    var tmp0_loop_0 = $this.__decision_0;
    while (true) {
      var tmp1__anonymous__1 = tmp0_loop_0._value_2;
      var tmp0_subject_2 = tmp1__anonymous__1;
      switch (tmp0_subject_2) {
        case 0:
          if ($this.__decision_0.atomicfu$compareAndSet(0, 2))
            return true;
          break;
        case 1:
          return false;
        default:var tmp0_error_0_3 = 'Already resumed';
          throw IllegalStateException_init_$Create$_0(toString_2(tmp0_error_0_3));
      }
    }
    Unit_getInstance();
  }
  function multipleHandlersError($this, handler, state) {
    var tmp0_error_0 = '' + "It's prohibited to register multiple handlers, tried to register " + handler + ', already has ' + state;
    throw IllegalStateException_init_$Create$_0(toString_2(tmp0_error_0));
  }
  function makeCancelHandler($this, handler) {
    var tmp;
    if (handler instanceof CancelHandler) {
      tmp = handler;
    } else {
      {
        tmp = new InvokeOnCancel(handler);
      }
    }
    return tmp;
  }
  function dispatchResume($this, mode) {
    if (tryResume_0($this))
      return Unit_getInstance();
    dispatch($this, mode);
  }
  function resumedState($this, state, proposedUpdate, resumeMode, onCancellation, idempotent) {
    var tmp;
    if (proposedUpdate instanceof CompletedExceptionally) {
      tmp = proposedUpdate;
    } else {
      if (!_get_isCancellableMode_(resumeMode) ? idempotent == null : false) {
        tmp = proposedUpdate;
      } else {
        var tmp_0;
        var tmp_1;
        if (!(onCancellation == null)) {
          tmp_1 = true;
        } else {
          var tmp_2;
          if (state instanceof CancelHandler) {
            tmp_2 = !(state instanceof BeforeResumeCancelHandler);
          } else {
            {
              tmp_2 = false;
            }
          }
          tmp_1 = tmp_2;
        }
        if (tmp_1) {
          tmp_0 = true;
        } else {
          {
            tmp_0 = !(idempotent == null);
          }
        }
        if (tmp_0) {
          var tmp_3 = state instanceof CancelHandler ? state : null;
          tmp = CompletedContinuation_init_$Create$(proposedUpdate, tmp_3, onCancellation, idempotent, null, 16, null);
        } else {
          {
            tmp = proposedUpdate;
          }
        }
      }
    }
    return tmp;
  }
  function resumeImpl($this, proposedUpdate, resumeMode, onCancellation) {
    var tmp0_loop_0 = $this.__state;
    while (true) {
      var tmp$ret$0;
      l$ret$1: do {
        var tmp1__anonymous__1 = tmp0_loop_0._value_0;
        var tmp0_subject_2 = tmp1__anonymous__1;
        if (!(tmp0_subject_2 == null) ? isInterface(tmp0_subject_2, NotCompleted) : false) {
          var update_3 = resumedState($this, tmp1__anonymous__1, proposedUpdate, resumeMode, onCancellation, null);
          if (!$this.__state.atomicfu$compareAndSet(tmp1__anonymous__1, update_3)) {
            tmp$ret$0 = Unit_getInstance();
            break l$ret$1;
          }detachChildIfNonResuable($this);
          dispatchResume($this, resumeMode);
          return Unit_getInstance();
        } else {
          if (tmp0_subject_2 instanceof CancelledContinuation) {
            if (tmp1__anonymous__1.makeResumed()) {
              var tmp1_safe_receiver_4 = onCancellation;
              if (tmp1_safe_receiver_4 == null)
                null;
              else {
                $this.callOnCancellation_0(tmp1_safe_receiver_4, tmp1__anonymous__1._get_cause__25());
                Unit_getInstance();
              }
              Unit_getInstance();
              return Unit_getInstance();
            }} else {
          }
        }
        alreadyResumedError($this, proposedUpdate);
      }
       while (false);
    }
    Unit_getInstance();
  }
  function resumeImpl$default($this, proposedUpdate, resumeMode, onCancellation, $mask0, $handler) {
    if (!(($mask0 & 8) === 0))
      onCancellation = null;
    return resumeImpl($this, proposedUpdate, resumeMode, onCancellation);
  }
  function alreadyResumedError($this, proposedUpdate) {
    var tmp0_error_0 = '' + 'Already resumed, but proposed with update ' + proposedUpdate;
    throw IllegalStateException_init_$Create$_0(toString_2(tmp0_error_0));
  }
  function detachChildIfNonResuable($this) {
    if (!isReusable($this))
      $this.detachChild_0();
  }
  function CancellableContinuationImpl(delegate, resumeMode) {
    DispatchedTask.call(this, resumeMode);
    this._delegate_0 = delegate;
    this._context_0 = this._delegate_0._get_context__31();
    this.__decision_0 = atomic_1(0);
    this.__state = atomic(Active_getInstance());
    this.__parentHandle = atomic(null);
  }
  CancellableContinuationImpl.prototype._get_delegate__2 = function () {
    return this._delegate_0;
  };
  CancellableContinuationImpl.prototype._get_context__31 = function () {
    return this._context_0;
  };
  CancellableContinuationImpl.prototype._get_state__15 = function () {
    return this.__state._value_0;
  };
  CancellableContinuationImpl.prototype._get_isCompleted__11 = function () {
    var tmp = this._get_state__15();
    return !(!(tmp == null) ? isInterface(tmp, NotCompleted) : false);
  };
  CancellableContinuationImpl.prototype.initCancellability_0 = function () {
    setupCancellation(this);
  };
  CancellableContinuationImpl.prototype.takeState_2 = function () {
    return this._get_state__15();
  };
  CancellableContinuationImpl.prototype.cancelCompletedResult_2 = function (takenState, cause) {
    var tmp0_loop_0 = this.__state;
    while (true) {
      var tmp1__anonymous__1 = tmp0_loop_0._value_0;
      var tmp0_subject_2 = tmp1__anonymous__1;
      if (!(tmp0_subject_2 == null) ? isInterface(tmp0_subject_2, NotCompleted) : false) {
        var tmp0_error_0_3 = 'Not completed';
        throw IllegalStateException_init_$Create$_0(toString_2(tmp0_error_0_3));
      } else {
        if (tmp0_subject_2 instanceof CompletedExceptionally)
          return Unit_getInstance();
        else {
          if (tmp0_subject_2 instanceof CompletedContinuation_0) {
            var tmp1_check_0_4 = !tmp1__anonymous__1._get_cancelled_();
            if (!tmp1_check_0_4) {
              var message_1_5 = 'Must be called at most once';
              throw IllegalStateException_init_$Create$_0(toString_2(message_1_5));
            }var tmp = tmp1__anonymous__1;
            var update_6 = tmp.copy$default_2(null, null, null, null, cause, 15, null);
            if (this.__state.atomicfu$compareAndSet(tmp1__anonymous__1, update_6)) {
              tmp1__anonymous__1.invokeHandlers(this, cause);
              return Unit_getInstance();
            }} else {
            {
              if (this.__state.atomicfu$compareAndSet(tmp1__anonymous__1, CompletedContinuation_init_$Create$(tmp1__anonymous__1, null, null, null, cause, 14, null))) {
                return Unit_getInstance();
              } else {
              }
            }
          }
        }
      }
    }
    Unit_getInstance();
  };
  CancellableContinuationImpl.prototype.cancel_8 = function (cause) {
    var tmp0_loop_0 = this.__state;
    while (true) {
      var tmp$ret$0;
      l$ret$1: do {
        var tmp1__anonymous__1 = tmp0_loop_0._value_0;
        if (!(!(tmp1__anonymous__1 == null) ? isInterface(tmp1__anonymous__1, NotCompleted) : false))
          return false;
        else {
        }
        var update_2 = new CancelledContinuation(this, cause, tmp1__anonymous__1 instanceof CancelHandler);
        if (!this.__state.atomicfu$compareAndSet(tmp1__anonymous__1, update_2)) {
          tmp$ret$0 = Unit_getInstance();
          break l$ret$1;
        }var tmp0_safe_receiver_3 = tmp1__anonymous__1 instanceof CancelHandler ? tmp1__anonymous__1 : null;
        if (tmp0_safe_receiver_3 == null)
          null;
        else {
          this.callCancelHandler_0(tmp0_safe_receiver_3, cause);
          Unit_getInstance();
        }
        Unit_getInstance();
        detachChildIfNonResuable(this);
        dispatchResume(this, this._get_resumeMode__1());
        return true;
      }
       while (false);
    }
    Unit_getInstance();
  };
  CancellableContinuationImpl.prototype.parentCancelled_7 = function (cause) {
    if (cancelLater(this, cause))
      return Unit_getInstance();
    this.cancel_8(cause);
    Unit_getInstance();
    detachChildIfNonResuable(this);
  };
  CancellableContinuationImpl.prototype.callCancelHandler_0 = function (handler, cause) {
    var tmp;
    try {
      tmp = handler.invoke(cause);
    } catch ($p) {
      var tmp_0;
      if ($p instanceof Error) {
        tmp_0 = handleCoroutineException(this._get_context__31(), new CompletionHandlerException('' + 'Exception in invokeOnCancellation handler for ' + this, $p));
      } else {
        {
          throw $p;
        }
      }
      tmp = tmp_0;
    }
    return tmp;
  };
  CancellableContinuationImpl.prototype.callOnCancellation_0 = function (onCancellation, cause) {
    try {
      onCancellation(cause);
    } catch ($p) {
      if ($p instanceof Error) {
        handleCoroutineException(this._get_context__31(), new CompletionHandlerException('' + 'Exception in resume onCancellation handler for ' + this, $p));
      } else {
        {
          throw $p;
        }
      }
    }
  };
  CancellableContinuationImpl.prototype.getContinuationCancellationCause_0 = function (parent_0) {
    return parent_0.getCancellationException_10();
  };
  CancellableContinuationImpl.prototype.getResult_1 = function () {
    setupCancellation(this);
    if (trySuspend_0(this))
      return _get_COROUTINE_SUSPENDED_();
    var state = this._get_state__15();
    if (state instanceof CompletedExceptionally)
      throw recoverStackTrace(state._cause, this);
    else {
    }
    if (_get_isCancellableMode_(this._get_resumeMode__1())) {
      var job = this._get_context__31().get_62(Key_getInstance_2());
      if (!(job == null) ? !job._get_isActive__23() : false) {
        var cause = job.getCancellationException_10();
        this.cancelCompletedResult_2(state, cause);
        throw recoverStackTrace(cause, this);
      }}return this.getSuccessfulResult_2(state);
  };
  CancellableContinuationImpl.prototype.resumeWith_21 = function (result) {
    var tmp = toState(result, this);
    var tmp_0 = this._get_resumeMode__1();
    return resumeImpl$default(this, tmp, tmp_0, null, 8, null);
  };
  CancellableContinuationImpl.prototype.invokeOnCancellation_1 = function (handler) {
    var cancelHandler = makeCancelHandler(this, handler);
    var tmp0_loop_0 = this.__state;
    while (true) {
      var tmp1__anonymous__1 = tmp0_loop_0._value_0;
      var tmp0_subject_2 = tmp1__anonymous__1;
      if (tmp0_subject_2 instanceof Active) {
        if (this.__state.atomicfu$compareAndSet(tmp1__anonymous__1, cancelHandler))
          return Unit_getInstance();
      } else {
        if (tmp0_subject_2 instanceof CancelHandler)
          multipleHandlersError(this, handler, tmp1__anonymous__1);
        else {
          if (tmp0_subject_2 instanceof CompletedExceptionally) {
            if (!tmp1__anonymous__1.makeHandled_0())
              multipleHandlersError(this, handler, tmp1__anonymous__1);
            if (tmp1__anonymous__1 instanceof CancelledContinuation) {
              var tmp1_safe_receiver_3 = tmp1__anonymous__1 instanceof CompletedExceptionally ? tmp1__anonymous__1 : null;
              callCancelHandler(this, handler, tmp1_safe_receiver_3 == null ? null : tmp1_safe_receiver_3._cause);
            } else {
            }
            return Unit_getInstance();
          } else {
            if (tmp0_subject_2 instanceof CompletedContinuation_0) {
              if (!(tmp1__anonymous__1._cancelHandler == null))
                multipleHandlersError(this, handler, tmp1__anonymous__1);
              if (cancelHandler instanceof BeforeResumeCancelHandler)
                return Unit_getInstance();
              else {
              }
              if (tmp1__anonymous__1._get_cancelled_()) {
                callCancelHandler(this, handler, tmp1__anonymous__1._cancelCause);
                return Unit_getInstance();
              }var tmp = tmp1__anonymous__1;
              var update_4 = tmp.copy$default_2(null, cancelHandler, null, null, null, 29, null);
              if (this.__state.atomicfu$compareAndSet(tmp1__anonymous__1, update_4))
                return Unit_getInstance();
            } else {
              {
                if (cancelHandler instanceof BeforeResumeCancelHandler)
                  return Unit_getInstance();
                else {
                }
                var update_5 = CompletedContinuation_init_$Create$(tmp1__anonymous__1, cancelHandler, null, null, null, 28, null);
                if (this.__state.atomicfu$compareAndSet(tmp1__anonymous__1, update_5))
                  return Unit_getInstance();
              }
            }
          }
        }
      }
    }
    Unit_getInstance();
  };
  CancellableContinuationImpl.prototype.detachChild_0 = function () {
    var handle = _get_parentHandle_(this);
    var tmp0_safe_receiver = handle;
    if (tmp0_safe_receiver == null)
      null;
    else {
      tmp0_safe_receiver.dispose_15();
      Unit_getInstance();
    }
    Unit_getInstance();
    _set_parentHandle_(this, NonDisposableHandle_getInstance());
  };
  CancellableContinuationImpl.prototype.getSuccessfulResult_2 = function (state) {
    var tmp0_subject = state;
    var tmp;
    if (tmp0_subject instanceof CompletedContinuation_0) {
      var tmp_0 = state._result_1;
      tmp = (tmp_0 == null ? true : isObject(tmp_0)) ? tmp_0 : THROW_CCE();
    } else {
      {
        tmp = (state == null ? true : isObject(state)) ? state : THROW_CCE();
      }
    }
    return tmp;
  };
  CancellableContinuationImpl.prototype.getExceptionalResult_2 = function (state) {
    var tmp0_safe_receiver = DispatchedTask.prototype.getExceptionalResult_2.call(this, state);
    var tmp;
    if (tmp0_safe_receiver == null) {
      tmp = null;
    } else {
      tmp = recoverStackTrace(tmp0_safe_receiver, this._delegate_0);
    }
    return tmp;
  };
  CancellableContinuationImpl.prototype.toString = function () {
    return '' + this.nameString_11() + '(' + toDebugString(this._delegate_0) + '){' + _get_stateDebugRepresentation_(this) + '}@' + _get_hexAddress_(this);
  };
  CancellableContinuationImpl.prototype.nameString_11 = function () {
    return 'CancellableContinuation';
  };
  CancellableContinuationImpl.$metadata$ = {
    simpleName: 'CancellableContinuationImpl',
    kind: 'class',
    interfaces: [CancellableContinuation, CoroutineStackFrame]
  };
  function CancelHandler() {
    CancelHandlerBase.call(this);
  }
  CancelHandler.$metadata$ = {
    simpleName: 'CancelHandler',
    kind: 'class',
    interfaces: [NotCompleted]
  };
  function Active() {
    Active_instance = this;
  }
  Active.prototype.toString = function () {
    return 'Active';
  };
  Active.$metadata$ = {
    simpleName: 'Active',
    kind: 'object',
    interfaces: [NotCompleted]
  };
  var Active_instance;
  function Active_getInstance() {
    if (Active_instance == null)
      new Active();
    return Active_instance;
  }
  function NotCompleted() {
  }
  NotCompleted.$metadata$ = {
    simpleName: 'NotCompleted',
    kind: 'interface',
    interfaces: []
  };
  function CompletedContinuation_init_$Init$(result, cancelHandler, onCancellation, idempotentResume, cancelCause, $mask0, $marker, $this) {
    if (!(($mask0 & 2) === 0))
      cancelHandler = null;
    if (!(($mask0 & 4) === 0))
      onCancellation = null;
    if (!(($mask0 & 8) === 0))
      idempotentResume = null;
    if (!(($mask0 & 16) === 0))
      cancelCause = null;
    CompletedContinuation_0.call($this, result, cancelHandler, onCancellation, idempotentResume, cancelCause);
    return $this;
  }
  function CompletedContinuation_init_$Create$(result, cancelHandler, onCancellation, idempotentResume, cancelCause, $mask0, $marker) {
    return CompletedContinuation_init_$Init$(result, cancelHandler, onCancellation, idempotentResume, cancelCause, $mask0, $marker, Object.create(CompletedContinuation_0.prototype));
  }
  function CompletedContinuation_0(result, cancelHandler, onCancellation, idempotentResume, cancelCause) {
    this._result_1 = result;
    this._cancelHandler = cancelHandler;
    this._onCancellation = onCancellation;
    this._idempotentResume = idempotentResume;
    this._cancelCause = cancelCause;
  }
  CompletedContinuation_0.prototype._get_cancelled_ = function () {
    return !(this._cancelCause == null);
  };
  CompletedContinuation_0.prototype.invokeHandlers = function (cont, cause) {
    var tmp0_safe_receiver = this._cancelHandler;
    if (tmp0_safe_receiver == null)
      null;
    else {
      cont.callCancelHandler_0(tmp0_safe_receiver, cause);
      Unit_getInstance();
    }
    Unit_getInstance();
    var tmp1_safe_receiver = this._onCancellation;
    if (tmp1_safe_receiver == null)
      null;
    else {
      cont.callOnCancellation_0(tmp1_safe_receiver, cause);
      Unit_getInstance();
    }
    Unit_getInstance();
  };
  CompletedContinuation_0.prototype.copy_2 = function (result, cancelHandler, onCancellation, idempotentResume, cancelCause) {
    return new CompletedContinuation_0(result, cancelHandler, onCancellation, idempotentResume, cancelCause);
  };
  CompletedContinuation_0.prototype.copy$default_2 = function (result, cancelHandler, onCancellation, idempotentResume, cancelCause, $mask0, $handler) {
    if (!(($mask0 & 1) === 0))
      result = this._result_1;
    if (!(($mask0 & 2) === 0))
      cancelHandler = this._cancelHandler;
    if (!(($mask0 & 4) === 0))
      onCancellation = this._onCancellation;
    if (!(($mask0 & 8) === 0))
      idempotentResume = this._idempotentResume;
    if (!(($mask0 & 16) === 0))
      cancelCause = this._cancelCause;
    return this.copy_2(result, cancelHandler, onCancellation, idempotentResume, cancelCause);
  };
  CompletedContinuation_0.prototype.toString = function () {
    return '' + 'CompletedContinuation(result=' + this._result_1 + ', cancelHandler=' + this._cancelHandler + ', onCancellation=' + this._onCancellation + ', idempotentResume=' + this._idempotentResume + ', cancelCause=' + this._cancelCause + ')';
  };
  CompletedContinuation_0.prototype.hashCode = function () {
    var result = this._result_1 == null ? 0 : hashCode(this._result_1);
    result = imul(result, 31) + (this._cancelHandler == null ? 0 : hashCode(this._cancelHandler)) | 0;
    result = imul(result, 31) + (this._onCancellation == null ? 0 : hashCode(this._onCancellation)) | 0;
    result = imul(result, 31) + (this._idempotentResume == null ? 0 : hashCode(this._idempotentResume)) | 0;
    result = imul(result, 31) + (this._cancelCause == null ? 0 : hashCode(this._cancelCause)) | 0;
    return result;
  };
  CompletedContinuation_0.prototype.equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof CompletedContinuation_0))
      return false;
    else {
    }
    var tmp0_other_with_cast = other instanceof CompletedContinuation_0 ? other : THROW_CCE();
    if (!equals_0(this._result_1, tmp0_other_with_cast._result_1))
      return false;
    if (!equals_0(this._cancelHandler, tmp0_other_with_cast._cancelHandler))
      return false;
    if (!equals_0(this._onCancellation, tmp0_other_with_cast._onCancellation))
      return false;
    if (!equals_0(this._idempotentResume, tmp0_other_with_cast._idempotentResume))
      return false;
    if (!equals_0(this._cancelCause, tmp0_other_with_cast._cancelCause))
      return false;
    return true;
  };
  CompletedContinuation_0.$metadata$ = {
    simpleName: 'CompletedContinuation',
    kind: 'class',
    interfaces: []
  };
  function BeforeResumeCancelHandler() {
  }
  BeforeResumeCancelHandler.$metadata$ = {
    simpleName: 'BeforeResumeCancelHandler',
    kind: 'class',
    interfaces: []
  };
  function InvokeOnCancel(handler) {
    CancelHandler.call(this);
    this._handler = handler;
  }
  InvokeOnCancel.prototype.invoke_341 = function (cause) {
    this._handler(cause);
  };
  InvokeOnCancel.prototype.invoke = function (cause) {
    return this.invoke_341(cause);
  };
  InvokeOnCancel.prototype.toString = function () {
    return '' + 'InvokeOnCancel[' + _get_classSimpleName_(this._handler) + '@' + _get_hexAddress_(this) + ']';
  };
  InvokeOnCancel.$metadata$ = {
    simpleName: 'InvokeOnCancel',
    kind: 'class',
    interfaces: []
  };
  function CompletableJob() {
  }
  CompletableJob.$metadata$ = {
    simpleName: 'CompletableJob',
    kind: 'interface',
    interfaces: [Job]
  };
  function CompletedExceptionally_init_$Init$(cause, handled, $mask0, $marker, $this) {
    if (!(($mask0 & 2) === 0))
      handled = false;
    CompletedExceptionally.call($this, cause, handled);
    return $this;
  }
  function CompletedExceptionally_init_$Create$(cause, handled, $mask0, $marker) {
    return CompletedExceptionally_init_$Init$(cause, handled, $mask0, $marker, Object.create(CompletedExceptionally.prototype));
  }
  function CompletedExceptionally(cause, handled) {
    this._cause = cause;
    this.__handled = atomic_0(handled);
  }
  CompletedExceptionally.prototype._get_cause__25 = function () {
    return this._cause;
  };
  CompletedExceptionally.prototype._get_handled__0 = function () {
    return this.__handled._value_1;
  };
  CompletedExceptionally.prototype.makeHandled_0 = function () {
    return this.__handled.atomicfu$compareAndSet(false, true);
  };
  CompletedExceptionally.prototype.toString = function () {
    return '' + _get_classSimpleName_(this) + '[' + this._cause + ']';
  };
  CompletedExceptionally.$metadata$ = {
    simpleName: 'CompletedExceptionally',
    kind: 'class',
    interfaces: []
  };
  function CancelledContinuation(continuation, cause, handled) {
    var tmp0_elvis_lhs = cause;
    CompletedExceptionally.call(this, tmp0_elvis_lhs == null ? CancellationException_init_$Create$('' + 'Continuation ' + continuation + ' was cancelled normally') : tmp0_elvis_lhs, handled);
    this.__resumed = atomic_0(false);
  }
  CancelledContinuation.prototype.makeResumed = function () {
    return this.__resumed.atomicfu$compareAndSet(false, true);
  };
  CancelledContinuation.$metadata$ = {
    simpleName: 'CancelledContinuation',
    kind: 'class',
    interfaces: []
  };
  function toState(_this_, caller) {
    var exception_1 = Result__exceptionOrNull_impl(_this_);
    var tmp;
    if (exception_1 == null) {
      var tmp_0 = _Result___get_value__impl_(_this_);
      var tmp0__anonymous__2 = (tmp_0 == null ? true : isObject(tmp_0)) ? tmp_0 : THROW_CCE();
      tmp = tmp0__anonymous__2;
    } else {
      var tmp_1 = recoverStackTrace(exception_1, caller);
      tmp = CompletedExceptionally_init_$Create$(tmp_1, false, 2, null);
    }
    return tmp;
  }
  function toState_0(_this_, onCancellation) {
    var exception_1 = Result__exceptionOrNull_impl(_this_);
    var tmp;
    if (exception_1 == null) {
      var tmp_0 = _Result___get_value__impl_(_this_);
      var tmp0__anonymous__2 = (tmp_0 == null ? true : isObject(tmp_0)) ? tmp_0 : THROW_CCE();
      tmp = !(onCancellation == null) ? new CompletedWithCancellation(tmp0__anonymous__2, onCancellation) : tmp0__anonymous__2;
    } else {
      tmp = CompletedExceptionally_init_$Create$(exception_1, false, 2, null);
    }
    return tmp;
  }
  function toState$default(_this_, onCancellation, $mask0, $handler) {
    if (!(($mask0 & 1) === 0))
      onCancellation = null;
    return toState_0(_this_, onCancellation);
  }
  function CompletedWithCancellation(result, onCancellation) {
    this._result_2 = result;
    this._onCancellation_0 = onCancellation;
  }
  CompletedWithCancellation.prototype.toString = function () {
    return '' + 'CompletedWithCancellation(result=' + this._result_2 + ', onCancellation=' + this._onCancellation_0 + ')';
  };
  CompletedWithCancellation.prototype.hashCode = function () {
    var result = this._result_2 == null ? 0 : hashCode(this._result_2);
    result = imul(result, 31) + hashCode(this._onCancellation_0) | 0;
    return result;
  };
  CompletedWithCancellation.prototype.equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof CompletedWithCancellation))
      return false;
    else {
    }
    var tmp0_other_with_cast = other instanceof CompletedWithCancellation ? other : THROW_CCE();
    if (!equals_0(this._result_2, tmp0_other_with_cast._result_2))
      return false;
    if (!equals_0(this._onCancellation_0, tmp0_other_with_cast._onCancellation_0))
      return false;
    return true;
  };
  CompletedWithCancellation.$metadata$ = {
    simpleName: 'CompletedWithCancellation',
    kind: 'class',
    interfaces: []
  };
  function recoverResult(state, uCont) {
    var tmp;
    if (state instanceof CompletedExceptionally) {
      var tmp0_failure_0 = Companion_getInstance_2();
      var tmp1_failure_0 = recoverStackTrace(state._cause, uCont);
      tmp = _Result___init__impl_(createFailure(tmp1_failure_0));
    } else {
      {
        var tmp2_success_0 = Companion_getInstance_2();
        var tmp3_success_0 = (state == null ? true : isObject(state)) ? state : THROW_CCE();
        tmp = _Result___init__impl_(tmp3_success_0);
      }
    }
    return tmp;
  }
  function _no_name_provided__62() {
  }
  _no_name_provided__62.prototype.invoke_89 = function (it) {
    return it instanceof CoroutineDispatcher ? it : null;
  };
  _no_name_provided__62.prototype.invoke_1115 = function (p1) {
    return this.invoke_89((!(p1 == null) ? isInterface(p1, Element_0) : false) ? p1 : THROW_CCE());
  };
  _no_name_provided__62.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function Key_1() {
    Key_instance_0 = this;
    var tmp = Key_getInstance();
    AbstractCoroutineContextKey.call(this, tmp, _no_name_provided_$factory_43());
  }
  Key_1.$metadata$ = {
    simpleName: 'Key',
    kind: 'object',
    interfaces: []
  };
  var Key_instance_0;
  function Key_getInstance_0() {
    if (Key_instance_0 == null)
      new Key_1();
    return Key_instance_0;
  }
  function CoroutineDispatcher() {
    Key_getInstance_0();
    AbstractCoroutineContextElement.call(this, Key_getInstance());
  }
  CoroutineDispatcher.prototype.isDispatchNeeded_9 = function (context) {
    return true;
  };
  CoroutineDispatcher.prototype.interceptContinuation_10 = function (continuation) {
    return new DispatchedContinuation(this, continuation);
  };
  CoroutineDispatcher.prototype.releaseInterceptedContinuation_10 = function (continuation) {
    var tmp0_safe_receiver = (continuation instanceof DispatchedContinuation ? continuation : THROW_CCE())._get_reusableCancellableContinuation_();
    if (tmp0_safe_receiver == null)
      null;
    else {
      tmp0_safe_receiver.detachChild_0();
      Unit_getInstance();
    }
    Unit_getInstance();
  };
  CoroutineDispatcher.prototype.toString = function () {
    return '' + _get_classSimpleName_(this) + '@' + _get_hexAddress_(this);
  };
  CoroutineDispatcher.$metadata$ = {
    simpleName: 'CoroutineDispatcher',
    kind: 'class',
    interfaces: [ContinuationInterceptor]
  };
  function _no_name_provided_$factory_43() {
    var i = new _no_name_provided__62();
    return function (p1) {
      return i.invoke_89(p1);
    };
  }
  function Key_2() {
    Key_instance_1 = this;
  }
  Key_2.$metadata$ = {
    simpleName: 'Key',
    kind: 'object',
    interfaces: [Key_0]
  };
  var Key_instance_1;
  function Key_getInstance_1() {
    if (Key_instance_1 == null)
      new Key_2();
    return Key_instance_1;
  }
  function handleCoroutineException(context, exception) {
    try {
      var tmp0_safe_receiver = context.get_62(Key_getInstance_1());
      if (tmp0_safe_receiver == null)
        null;
      else {
        tmp0_safe_receiver.handleException__error(context, exception);
        return Unit_getInstance();
      }
      Unit_getInstance();
    } catch ($p) {
      if ($p instanceof Error) {
        handleCoroutineExceptionImpl(context, handlerException(exception, $p));
        return Unit_getInstance();
      } else {
        {
          throw $p;
        }
      }
    }
    handleCoroutineExceptionImpl(context, exception);
  }
  function handlerException(originalException, thrownException) {
    if (originalException === thrownException)
      return originalException;
    var tmp0_apply_0 = RuntimeException_init_$Create$_0('Exception while trying to handle coroutine exception', thrownException);
    return tmp0_apply_0;
  }
  function CoroutineScope() {
  }
  CoroutineScope.$metadata$ = {
    simpleName: 'CoroutineScope',
    kind: 'interface',
    interfaces: []
  };
  function MainScope() {
    return new ContextScope(SupervisorJob$default(null, 1, null).plus_43(Dispatchers_getInstance()._Main));
  }
  function CoroutineScope_0(context) {
    var tmp;
    if (!(context.get_62(Key_getInstance_2()) == null)) {
      tmp = context;
    } else {
      tmp = context.plus_43(Job$default(null, 1, null));
    }
    return new ContextScope(tmp);
  }
  function coroutineScope(block, $cont) {
    var tmp0__anonymous__1 = $cont;
    var coroutine_2 = new ScopeCoroutine(tmp0__anonymous__1._get_context__31(), tmp0__anonymous__1);
    return startUndispatchedOrReturn(coroutine_2, coroutine_2, block);
  }
  var CoroutineStart_DEFAULT_instance;
  var CoroutineStart_LAZY_instance;
  var CoroutineStart_ATOMIC_instance;
  var CoroutineStart_UNDISPATCHED_instance;
  var CoroutineStart_entriesInitialized;
  function CoroutineStart_initEntries() {
    if (CoroutineStart_entriesInitialized)
      return Unit_getInstance();
    CoroutineStart_entriesInitialized = true;
    CoroutineStart_DEFAULT_instance = new CoroutineStart('DEFAULT', 0);
    CoroutineStart_LAZY_instance = new CoroutineStart('LAZY', 1);
    CoroutineStart_ATOMIC_instance = new CoroutineStart('ATOMIC', 2);
    CoroutineStart_UNDISPATCHED_instance = new CoroutineStart('UNDISPATCHED', 3);
  }
  function CoroutineStart(name, ordinal) {
    Enum.call(this, name, ordinal);
  }
  CoroutineStart.prototype.invoke_91 = function (block, receiver, completion) {
    var tmp0_subject = this;
    var tmp;
    if (tmp0_subject.equals(CoroutineStart_DEFAULT_getInstance())) {
      tmp = startCoroutineCancellable$default(block, receiver, completion, null, 4, null);
    } else if (tmp0_subject.equals(CoroutineStart_ATOMIC_getInstance())) {
      tmp = startCoroutine(block, receiver, completion);
    } else if (tmp0_subject.equals(CoroutineStart_UNDISPATCHED_getInstance())) {
      tmp = startCoroutineUndispatched(block, receiver, completion);
    } else if (tmp0_subject.equals(CoroutineStart_LAZY_getInstance())) {
      tmp = Unit_getInstance();
    } else {
      noWhenBranchMatchedException();
    }
    return tmp;
  };
  CoroutineStart.prototype._get_isLazy_ = function () {
    return this === CoroutineStart_LAZY_getInstance();
  };
  CoroutineStart.$metadata$ = {
    simpleName: 'CoroutineStart',
    kind: 'class',
    interfaces: []
  };
  function CoroutineStart_DEFAULT_getInstance() {
    CoroutineStart_initEntries();
    return CoroutineStart_DEFAULT_instance;
  }
  function CoroutineStart_LAZY_getInstance() {
    CoroutineStart_initEntries();
    return CoroutineStart_LAZY_instance;
  }
  function CoroutineStart_ATOMIC_getInstance() {
    CoroutineStart_initEntries();
    return CoroutineStart_ATOMIC_instance;
  }
  function CoroutineStart_UNDISPATCHED_getInstance() {
    CoroutineStart_initEntries();
    return CoroutineStart_UNDISPATCHED_instance;
  }
  function CopyableThrowable() {
  }
  CopyableThrowable.$metadata$ = {
    simpleName: 'CopyableThrowable',
    kind: 'interface',
    interfaces: []
  };
  function Deferred() {
  }
  Deferred.$metadata$ = {
    simpleName: 'Deferred',
    kind: 'interface',
    interfaces: [Job]
  };
  function Delay() {
  }
  Delay.$metadata$ = {
    simpleName: 'Delay',
    kind: 'interface',
    interfaces: []
  };
  function delta($this, unconfined) {
    return unconfined ? new Long(0, 1) : new Long(1, 0);
  }
  function EventLoop() {
    CoroutineDispatcher.call(this);
    this._useCount = new Long(0, 0);
    this._shared = false;
    this._unconfinedQueue = null;
  }
  EventLoop.prototype.processUnconfinedEvent_0 = function () {
    var tmp0_elvis_lhs = this._unconfinedQueue;
    var tmp;
    if (tmp0_elvis_lhs == null) {
      return false;
    } else {
      tmp = tmp0_elvis_lhs;
    }
    var queue = tmp;
    var tmp1_elvis_lhs = queue.removeFirstOrNull_2();
    var tmp_0;
    if (tmp1_elvis_lhs == null) {
      return false;
    } else {
      tmp_0 = tmp1_elvis_lhs;
    }
    var task = tmp_0;
    task.run_4();
    return true;
  };
  EventLoop.prototype.dispatchUnconfined_0 = function (task) {
    var tmp0_elvis_lhs = this._unconfinedQueue;
    var tmp;
    if (tmp0_elvis_lhs == null) {
      var tmp0_also_0 = new ArrayQueue();
      this._unconfinedQueue = tmp0_also_0;
      tmp = tmp0_also_0;
    } else {
      tmp = tmp0_elvis_lhs;
    }
    var queue = tmp;
    queue.addLast_8(task);
  };
  EventLoop.prototype._get_isUnconfinedLoopActive__0 = function () {
    return this._useCount.compareTo_75(delta(this, true)) >= 0;
  };
  EventLoop.prototype._get_isUnconfinedQueueEmpty__0 = function () {
    var tmp0_safe_receiver = this._unconfinedQueue;
    var tmp1_elvis_lhs = tmp0_safe_receiver == null ? null : tmp0_safe_receiver._get_isEmpty__3();
    return tmp1_elvis_lhs == null ? true : tmp1_elvis_lhs;
  };
  EventLoop.prototype.incrementUseCount_0 = function (unconfined) {
    var tmp0_this = this;
    tmp0_this._useCount = tmp0_this._useCount.plus_71(delta(this, unconfined));
    if (!unconfined)
      this._shared = true;
  };
  EventLoop.prototype.decrementUseCount_0 = function (unconfined) {
    var tmp0_this = this;
    tmp0_this._useCount = tmp0_this._useCount.minus_40(delta(this, unconfined));
    if (this._useCount.compareTo_75(new Long(0, 0)) > 0)
      return Unit_getInstance();
    if (this._shared) {
      this.shutdown_0();
    }};
  EventLoop.prototype.shutdown_0 = function () {
  };
  EventLoop.$metadata$ = {
    simpleName: 'EventLoop',
    kind: 'class',
    interfaces: []
  };
  function ThreadLocalEventLoop() {
    ThreadLocalEventLoop_instance = this;
    this._ref = new CommonThreadLocal();
  }
  ThreadLocalEventLoop.prototype._get_eventLoop_ = function () {
    var tmp0_elvis_lhs = this._ref.get_60();
    var tmp;
    if (tmp0_elvis_lhs == null) {
      var tmp0_also_0 = createEventLoop();
      ThreadLocalEventLoop_getInstance()._ref.set_4(tmp0_also_0);
      tmp = tmp0_also_0;
    } else {
      tmp = tmp0_elvis_lhs;
    }
    return tmp;
  };
  ThreadLocalEventLoop.$metadata$ = {
    simpleName: 'ThreadLocalEventLoop',
    kind: 'object',
    interfaces: []
  };
  var ThreadLocalEventLoop_instance;
  function ThreadLocalEventLoop_getInstance() {
    if (ThreadLocalEventLoop_instance == null)
      new ThreadLocalEventLoop();
    return ThreadLocalEventLoop_instance;
  }
  function CompletionHandlerException(message, cause) {
    RuntimeException_init_$Init$_1(message, cause, this);
    captureStack(this, CompletionHandlerException);
  }
  CompletionHandlerException.$metadata$ = {
    simpleName: 'CompletionHandlerException',
    kind: 'class',
    interfaces: []
  };
  function CoroutinesInternalError(message, cause) {
    Error_init_$Init$_0(message, cause, this);
    captureStack(this, CoroutinesInternalError);
  }
  CoroutinesInternalError.$metadata$ = {
    simpleName: 'CoroutinesInternalError',
    kind: 'class',
    interfaces: []
  };
  function DisposableHandle() {
  }
  DisposableHandle.$metadata$ = {
    simpleName: 'DisposableHandle',
    kind: 'interface',
    interfaces: []
  };
  function Key_3() {
    Key_instance_2 = this;
    Key_getInstance_1();
    Unit_getInstance();
  }
  Key_3.$metadata$ = {
    simpleName: 'Key',
    kind: 'object',
    interfaces: [Key_0]
  };
  var Key_instance_2;
  function Key_getInstance_2() {
    if (Key_instance_2 == null)
      new Key_3();
    return Key_instance_2;
  }
  function Job() {
  }
  Job.prototype.cancel$default_13 = function (cause, $mask0, $handler) {
    if (!(($mask0 & 1) === 0))
      cause = null;
    return $handler == null ? this.cancel_13(cause) : $handler(cause);
  };
  Job.prototype.invokeOnCompletion$default_10 = function (onCancelling, invokeImmediately, handler, $mask0, $handler) {
    if (!(($mask0 & 1) === 0))
      onCancelling = false;
    if (!(($mask0 & 2) === 0))
      invokeImmediately = true;
    return $handler == null ? this.invokeOnCompletion_23(onCancelling, invokeImmediately, handler) : $handler(onCancelling, invokeImmediately, handler);
  };
  Job.$metadata$ = {
    simpleName: 'Job',
    kind: 'interface',
    interfaces: [Element_0]
  };
  function ChildJob() {
  }
  ChildJob.$metadata$ = {
    simpleName: 'ChildJob',
    kind: 'interface',
    interfaces: [Job]
  };
  function ParentJob() {
  }
  ParentJob.$metadata$ = {
    simpleName: 'ParentJob',
    kind: 'interface',
    interfaces: [Job]
  };
  function ChildHandle() {
  }
  ChildHandle.$metadata$ = {
    simpleName: 'ChildHandle',
    kind: 'interface',
    interfaces: [DisposableHandle]
  };
  function NonDisposableHandle() {
    NonDisposableHandle_instance = this;
  }
  NonDisposableHandle.prototype.dispose_15 = function () {
  };
  NonDisposableHandle.prototype.childCancelled_12 = function (cause) {
    return false;
  };
  NonDisposableHandle.prototype.toString = function () {
    return 'NonDisposableHandle';
  };
  NonDisposableHandle.$metadata$ = {
    simpleName: 'NonDisposableHandle',
    kind: 'object',
    interfaces: [DisposableHandle, ChildHandle]
  };
  var NonDisposableHandle_instance;
  function NonDisposableHandle_getInstance() {
    if (NonDisposableHandle_instance == null)
      new NonDisposableHandle();
    return NonDisposableHandle_instance;
  }
  function Job_0(parent_0) {
    return new JobImpl(parent_0);
  }
  function Job$default(parent_0, $mask0, $handler) {
    if (!(($mask0 & 1) === 0))
      parent_0 = null;
    return Job_0(parent_0);
  }
  function _get_job_(_this_) {
    var tmp0_elvis_lhs = _this_.get_62(Key_getInstance_2());
    var tmp;
    if (tmp0_elvis_lhs == null) {
      var tmp0_error_0 = '' + "Current context doesn't contain Job in it: " + _this_;
      throw IllegalStateException_init_$Create$_0(toString_2(tmp0_error_0));
    } else {
      tmp = tmp0_elvis_lhs;
    }
    return tmp;
  }
  var COMPLETING_ALREADY;
  var COMPLETING_WAITING_CHILDREN;
  var COMPLETING_RETRY;
  var TOO_LATE_TO_CANCEL;
  var SEALED;
  var EMPTY_NEW;
  var EMPTY_ACTIVE;
  function Empty(isActive) {
    this._isActive = isActive;
  }
  Empty.prototype._get_isActive__23 = function () {
    return this._isActive;
  };
  Empty.prototype._get_list__12 = function () {
    return null;
  };
  Empty.prototype.toString = function () {
    return '' + 'Empty{' + (this._isActive ? 'Active' : 'New') + '}';
  };
  Empty.$metadata$ = {
    simpleName: 'Empty',
    kind: 'class',
    interfaces: [Incomplete]
  };
  function Incomplete() {
  }
  Incomplete.$metadata$ = {
    simpleName: 'Incomplete',
    kind: 'interface',
    interfaces: []
  };
  function NodeList_0() {
    LinkedListHead.call(this);
  }
  NodeList_0.prototype._get_isActive__23 = function () {
    return true;
  };
  NodeList_0.prototype._get_list__12 = function () {
    return this;
  };
  NodeList_0.prototype.getString = function (state) {
    var tmp0_apply_0_1 = StringBuilder_init_$Create$_1();
    tmp0_apply_0_1.append_5('List{');
    Unit_getInstance();
    tmp0_apply_0_1.append_5(state);
    Unit_getInstance();
    tmp0_apply_0_1.append_5('}[');
    Unit_getInstance();
    var first_3_0 = true;
    var cur_1_4 = this._get__next__1();
    while (!equals_0(cur_1_4, this)) {
      if (cur_1_4 instanceof JobNode) {
        var tmp0__anonymous__2_5 = cur_1_4;
        if (first_3_0)
          first_3_0 = false;
        else {
          tmp0_apply_0_1.append_5(', ');
          Unit_getInstance();
        }
        tmp0_apply_0_1.append_4(tmp0__anonymous__2_5);
        Unit_getInstance();
      } else {
      }
      cur_1_4 = cur_1_4.__next;
    }
    tmp0_apply_0_1.append_5(']');
    Unit_getInstance();
    return tmp0_apply_0_1.toString();
  };
  NodeList_0.prototype.toString = function () {
    return DEBUG ? this.getString('Active') : anyToString(this);
  };
  NodeList_0.$metadata$ = {
    simpleName: 'NodeList',
    kind: 'class',
    interfaces: [Incomplete]
  };
  function JobNode() {
    CompletionHandlerBase.call(this);
  }
  JobNode.prototype._get_job__6 = function () {
    var tmp = this._job;
    if (!(tmp == null))
      return tmp;
    else {
      throwUninitializedPropertyAccessException('job');
    }
  };
  JobNode.prototype._get_isActive__23 = function () {
    return true;
  };
  JobNode.prototype._get_list__12 = function () {
    return null;
  };
  JobNode.prototype.dispose_15 = function () {
    return this._get_job__6().removeNode_9(this);
  };
  JobNode.prototype.toString = function () {
    return '' + _get_classSimpleName_(this) + '@' + _get_hexAddress_(this) + '[job@' + _get_hexAddress_(this._get_job__6()) + ']';
  };
  JobNode.$metadata$ = {
    simpleName: 'JobNode',
    kind: 'class',
    interfaces: [DisposableHandle, Incomplete]
  };
  function _set_exceptionsHolder_($this, value_0) {
    $this.__exceptionsHolder._value_0 = value_0;
  }
  function _get_exceptionsHolder_($this) {
    return $this.__exceptionsHolder._value_0;
  }
  function allocateList($this) {
    return ArrayList_init_$Create$_0(4);
  }
  function finalizeFinishingState($this, state, proposedUpdate) {
    var tmp0_safe_receiver = proposedUpdate instanceof CompletedExceptionally ? proposedUpdate : null;
    var proposedException = tmp0_safe_receiver == null ? null : tmp0_safe_receiver._cause;
    var wasCancelling = false;
    wasCancelling = state._get_isCancelling_();
    var exceptions_2 = state.sealLocked(proposedException);
    var finalCause_3 = getFinalRootCause($this, state, exceptions_2);
    if (!(finalCause_3 == null))
      addSuppressedExceptions($this, finalCause_3, exceptions_2);
    var finalException = finalCause_3;
    var tmp;
    if (finalException == null) {
      tmp = proposedUpdate;
    } else if (finalException === proposedException) {
      tmp = proposedUpdate;
    } else {
      tmp = CompletedExceptionally_init_$Create$(finalException, false, 2, null);
    }
    var finalState = tmp;
    if (!(finalException == null)) {
      var handled = cancelParent($this, finalException) ? true : $this.handleJobException_9(finalException);
      if (handled) {
        (finalState instanceof CompletedExceptionally ? finalState : THROW_CCE()).makeHandled_0();
        Unit_getInstance();
      }}if (!wasCancelling)
      $this.onCancelling_9(finalException);
    $this.onCompletionInternal_9(finalState);
    var casSuccess = $this.__state_0.atomicfu$compareAndSet(state, boxIncomplete(finalState));
    completeStateFinalization($this, state, finalState);
    return finalState;
  }
  function getFinalRootCause($this, state, exceptions) {
    if (exceptions.isEmpty_44()) {
      if (state._get_isCancelling_()) {
        var tmp0_defaultCancellationException_0 = null;
        var tmp1_defaultCancellationException_0 = null;
        var tmp0_elvis_lhs_1 = tmp0_defaultCancellationException_0;
        return new JobCancellationException(tmp0_elvis_lhs_1 == null ? $this.cancellationExceptionMessage_9() : tmp0_elvis_lhs_1, tmp1_defaultCancellationException_0, $this);
      }return null;
    }var tmp$ret$0;
    l$ret$1: do {
      var tmp0_iterator_1 = exceptions.iterator_66();
      while (tmp0_iterator_1.hasNext_41()) {
        var element_2 = tmp0_iterator_1.next_46();
        if (!(element_2 instanceof CancellationException)) {
          tmp$ret$0 = element_2;
          break l$ret$1;
        } else {
        }
      }
      tmp$ret$0 = null;
    }
     while (false);
    var firstNonCancellation = tmp$ret$0;
    if (!(firstNonCancellation == null))
      return firstNonCancellation;
    var first_4 = exceptions.get_72(0);
    if (first_4 instanceof TimeoutCancellationException) {
      var tmp$ret$2;
      l$ret$3: do {
        var tmp0_iterator_1_0 = exceptions.iterator_66();
        while (tmp0_iterator_1_0.hasNext_41()) {
          var element_2_0 = tmp0_iterator_1_0.next_46();
          var tmp;
          if (!(element_2_0 === first_4)) {
            tmp = element_2_0 instanceof TimeoutCancellationException;
          } else {
            tmp = false;
          }
          if (tmp) {
            tmp$ret$2 = element_2_0;
            break l$ret$3;
          } else {
          }
        }
        tmp$ret$2 = null;
      }
       while (false);
      var detailedTimeoutException = tmp$ret$2;
      if (!(detailedTimeoutException == null))
        return detailedTimeoutException;
    } else {
    }
    return first_4;
  }
  function addSuppressedExceptions($this, rootCause, exceptions) {
    if (exceptions._get_size__52() <= 1)
      return Unit_getInstance();
    var seenExceptions = identitySet(exceptions._get_size__52());
    var unwrappedCause = unwrap(rootCause);
    var tmp0_iterator = exceptions.iterator_66();
    while (tmp0_iterator.hasNext_41()) {
      var exception = tmp0_iterator.next_46();
      var unwrapped = unwrap(exception);
      var tmp;
      var tmp_0;
      if (!(unwrapped === rootCause) ? !(unwrapped === unwrappedCause) : false) {
        tmp_0 = !(unwrapped instanceof CancellationException);
      } else {
        tmp_0 = false;
      }
      if (tmp_0) {
        tmp = seenExceptions.add_39(unwrapped);
      } else {
        {
          tmp = false;
        }
      }
      if (tmp) {
      } else {
      }
    }
  }
  function tryFinalizeSimpleState($this, state, update) {
    if (!$this.__state_0.atomicfu$compareAndSet(state, boxIncomplete(update)))
      return false;
    $this.onCancelling_9(null);
    $this.onCompletionInternal_9(update);
    completeStateFinalization($this, state, update);
    return true;
  }
  function completeStateFinalization($this, state, update) {
    var tmp0_safe_receiver = $this._get_parentHandle__9();
    if (tmp0_safe_receiver == null)
      null;
    else {
      tmp0_safe_receiver.dispose_15();
      $this._set_parentHandle__9(NonDisposableHandle_getInstance());
      Unit_getInstance();
    }
    Unit_getInstance();
    var tmp1_safe_receiver = update instanceof CompletedExceptionally ? update : null;
    var cause = tmp1_safe_receiver == null ? null : tmp1_safe_receiver._cause;
    if (state instanceof JobNode) {
      try {
        state.invoke(cause);
      } catch ($p) {
        if ($p instanceof Error) {
          $this.handleOnCompletionException_9(new CompletionHandlerException('' + 'Exception in completion handler ' + state + ' for ' + $this, $p));
        } else {
          {
            throw $p;
          }
        }
      }
    } else {
      {
        var tmp2_safe_receiver = state._get_list__12();
        if (tmp2_safe_receiver == null)
          null;
        else {
          notifyCompletion(tmp2_safe_receiver, $this, cause);
          Unit_getInstance();
        }
        Unit_getInstance();
      }
    }
  }
  function notifyCancelling($this, list, cause) {
    $this.onCancelling_9(cause);
    var exception_1 = null;
    var cur_1 = list._get__next__1();
    while (!equals_0(cur_1, list)) {
      if (cur_1 instanceof JobCancellingNode) {
        var tmp0__anonymous__2_2 = cur_1;
        try {
          tmp0__anonymous__2_2.invoke(cause);
        } catch ($p) {
          if ($p instanceof Error) {
            var tmp0_safe_receiver_5_5 = exception_1;
            var tmp;
            if (tmp0_safe_receiver_5_5 == null) {
              tmp = null;
            } else {
              tmp = tmp0_safe_receiver_5_5;
            }
            var tmp1_elvis_lhs_4_4 = tmp;
            if (tmp1_elvis_lhs_4_4 == null) {
              exception_1 = new CompletionHandlerException('' + 'Exception in completion handler ' + tmp0__anonymous__2_2 + ' for ' + $this, $p);
              Unit_getInstance();
            } else
              tmp1_elvis_lhs_4_4;
            Unit_getInstance();
          } else {
            {
              throw $p;
            }
          }
        }
      } else {
      }
      cur_1 = cur_1.__next;
    }
    var tmp0_safe_receiver_8 = exception_1;
    if (tmp0_safe_receiver_8 == null)
      null;
    else {
      $this.handleOnCompletionException_9(tmp0_safe_receiver_8);
      Unit_getInstance();
    }
    Unit_getInstance();
    cancelParent($this, cause);
    Unit_getInstance();
  }
  function cancelParent($this, cause) {
    if ($this._get_isScopedCoroutine__9())
      return true;
    var isCancellation = cause instanceof CancellationException;
    var parent_0 = $this._get_parentHandle__9();
    if (parent_0 === null ? true : parent_0 === NonDisposableHandle_getInstance()) {
      return isCancellation;
    }return parent_0.childCancelled_12(cause) ? true : isCancellation;
  }
  function notifyCompletion(_this_, $this, cause) {
    var exception_1 = null;
    var cur_1 = _this_._get__next__1();
    while (!equals_0(cur_1, _this_)) {
      if (cur_1 instanceof JobNode) {
        var tmp0__anonymous__2_2 = cur_1;
        try {
          tmp0__anonymous__2_2.invoke(cause);
        } catch ($p) {
          if ($p instanceof Error) {
            var tmp0_safe_receiver_5_5 = exception_1;
            var tmp;
            if (tmp0_safe_receiver_5_5 == null) {
              tmp = null;
            } else {
              tmp = tmp0_safe_receiver_5_5;
            }
            var tmp1_elvis_lhs_4_4 = tmp;
            if (tmp1_elvis_lhs_4_4 == null) {
              exception_1 = new CompletionHandlerException('' + 'Exception in completion handler ' + tmp0__anonymous__2_2 + ' for ' + $this, $p);
              Unit_getInstance();
            } else
              tmp1_elvis_lhs_4_4;
            Unit_getInstance();
          } else {
            {
              throw $p;
            }
          }
        }
      } else {
      }
      cur_1 = cur_1.__next;
    }
    var tmp0_safe_receiver_8 = exception_1;
    if (tmp0_safe_receiver_8 == null)
      null;
    else {
      $this.handleOnCompletionException_9(tmp0_safe_receiver_8);
      Unit_getInstance();
    }
    return Unit_getInstance();
  }
  function startInternal($this, state) {
    var tmp0_subject = state;
    if (tmp0_subject instanceof Empty) {
      if (state._isActive)
        return 0;
      if (!$this.__state_0.atomicfu$compareAndSet(state, EMPTY_ACTIVE))
        return -1;
      $this.onStartInternal_9();
      return 1;
    } else {
      if (tmp0_subject instanceof InactiveNodeList) {
        if (!$this.__state_0.atomicfu$compareAndSet(state, state._list_2))
          return -1;
        $this.onStartInternal_9();
        return 1;
      } else {
        return 0;
      }
    }
  }
  function makeNode($this, handler, onCancelling) {
    var tmp;
    if (onCancelling) {
      var tmp0_elvis_lhs = handler instanceof JobCancellingNode ? handler : null;
      tmp = tmp0_elvis_lhs == null ? new InvokeOnCancelling(handler) : tmp0_elvis_lhs;
    } else {
      var tmp1_safe_receiver = handler instanceof JobNode ? handler : null;
      var tmp_0;
      if (tmp1_safe_receiver == null) {
        tmp_0 = null;
      } else {
        tmp_0 = tmp1_safe_receiver;
      }
      var tmp2_elvis_lhs = tmp_0;
      tmp = tmp2_elvis_lhs == null ? new InvokeOnCompletion(handler) : tmp2_elvis_lhs;
    }
    var node_0 = tmp;
    node_0._job = $this;
    return node_0;
  }
  function addLastAtomic($this, expect, list, node_0) {
    var tmp$ret$0;
    l$ret$1: do {
      if (!($this._get_state__15() === expect)) {
        tmp$ret$0 = false;
        break l$ret$1;
      } else {
      }
      list.addLast_14(node_0);
      tmp$ret$0 = true;
    }
     while (false);
    return tmp$ret$0;
  }
  function promoteEmptyToNodeList($this, state) {
    var list = new NodeList_0();
    var update = state._isActive ? list : new InactiveNodeList(list);
    $this.__state_0.atomicfu$compareAndSet(state, update);
    Unit_getInstance();
  }
  function promoteSingleToNodeList($this, state) {
    state.addOneIfEmpty_7(new NodeList_0());
    Unit_getInstance();
    var list = state.__next;
    $this.__state_0.atomicfu$compareAndSet(state, list);
    Unit_getInstance();
  }
  function cancelMakeCompleting($this, cause) {
    while (true) {
      var tmp0__anonymous__1 = $this._get_state__15();
      var tmp;
      if (!(!(tmp0__anonymous__1 == null) ? isInterface(tmp0__anonymous__1, Incomplete) : false)) {
        tmp = true;
      } else {
        {
          var tmp_0;
          if (tmp0__anonymous__1 instanceof Finishing) {
            tmp_0 = tmp0__anonymous__1._get_isCompleting_();
          } else {
            {
              tmp_0 = false;
            }
          }
          tmp = tmp_0;
        }
      }
      if (tmp) {
        return COMPLETING_ALREADY;
      } else {
      }
      var tmp_1 = createCauseException($this, cause);
      var proposedUpdate_2 = CompletedExceptionally_init_$Create$(tmp_1, false, 2, null);
      var finalState_3 = tryMakeCompleting($this, tmp0__anonymous__1, proposedUpdate_2);
      if (!(finalState_3 === COMPLETING_RETRY))
        return finalState_3;
    }
    Unit_getInstance();
  }
  function createCauseException($this, cause) {
    var tmp0_subject = cause;
    var tmp;
    if (tmp0_subject == null ? true : tmp0_subject instanceof Error) {
      var tmp1_elvis_lhs = cause;
      var tmp_0;
      if (tmp1_elvis_lhs == null) {
        var tmp0_defaultCancellationException_0 = null;
        var tmp1_defaultCancellationException_0 = null;
        var tmp0_elvis_lhs_1 = tmp0_defaultCancellationException_0;
        tmp_0 = new JobCancellationException(tmp0_elvis_lhs_1 == null ? $this.cancellationExceptionMessage_9() : tmp0_elvis_lhs_1, tmp1_defaultCancellationException_0, $this);
      } else {
        tmp_0 = tmp1_elvis_lhs;
      }
      tmp = tmp_0;
    } else {
      {
        tmp = ((!(cause == null) ? isInterface(cause, ParentJob) : false) ? cause : THROW_CCE()).getChildJobCancellationCause_10();
      }
    }
    return tmp;
  }
  function makeCancelling($this, cause) {
    var causeExceptionCache = null;
    while (true) {
      var tmp$ret$0;
      l$ret$1: do {
        var tmp0__anonymous__1 = $this._get_state__15();
        var tmp0_subject_2 = tmp0__anonymous__1;
        if (tmp0_subject_2 instanceof Finishing) {
          if (tmp0__anonymous__1._get_isSealed_())
            return TOO_LATE_TO_CANCEL;
          var wasCancelling_2_4 = tmp0__anonymous__1._get_isCancelling_();
          if (!(cause == null) ? true : !wasCancelling_2_4) {
            var tmp0_elvis_lhs_4_6 = causeExceptionCache;
            var tmp;
            if (tmp0_elvis_lhs_4_6 == null) {
              var tmp0_also_0_5_7 = createCauseException($this, cause);
              causeExceptionCache = tmp0_also_0_5_7;
              tmp = tmp0_also_0_5_7;
            } else {
              tmp = tmp0_elvis_lhs_4_6;
            }
            var causeException_3_5 = tmp;
            tmp0__anonymous__1.addExceptionLocked(causeException_3_5);
          }var tmp1_takeIf_0_6_8 = tmp0__anonymous__1._get_rootCause_();
          var tmp_0;
          if (!wasCancelling_2_4) {
            tmp_0 = tmp1_takeIf_0_6_8;
          } else {
            {
              tmp_0 = null;
            }
          }
          var notifyRootCause_3 = tmp_0;
          var tmp1_safe_receiver_9 = notifyRootCause_3;
          if (tmp1_safe_receiver_9 == null)
            null;
          else {
            notifyCancelling($this, tmp0__anonymous__1._list_1, tmp1_safe_receiver_9);
            Unit_getInstance();
          }
          Unit_getInstance();
          return COMPLETING_ALREADY;
        } else {
          if (!(tmp0_subject_2 == null) ? isInterface(tmp0_subject_2, Incomplete) : false) {
            var tmp2_elvis_lhs_11 = causeExceptionCache;
            var tmp_1;
            if (tmp2_elvis_lhs_11 == null) {
              var tmp0_also_0_12 = createCauseException($this, cause);
              causeExceptionCache = tmp0_also_0_12;
              tmp_1 = tmp0_also_0_12;
            } else {
              tmp_1 = tmp2_elvis_lhs_11;
            }
            var causeException_10 = tmp_1;
            if (tmp0__anonymous__1._get_isActive__23()) {
              if (tryMakeCancelling($this, tmp0__anonymous__1, causeException_10))
                return COMPLETING_ALREADY;
            } else {
              var finalState_13 = tryMakeCompleting($this, tmp0__anonymous__1, CompletedExceptionally_init_$Create$(causeException_10, false, 2, null));
              if (finalState_13 === COMPLETING_ALREADY) {
                var tmp1_error_0_14 = '' + 'Cannot happen in ' + tmp0__anonymous__1;
                throw IllegalStateException_init_$Create$_0(toString_2(tmp1_error_0_14));
              } else if (finalState_13 === COMPLETING_RETRY) {
                tmp$ret$0 = Unit_getInstance();
                break l$ret$1;
              } else
                return finalState_13;
            }
          } else {
            return TOO_LATE_TO_CANCEL;
          }
        }
      }
       while (false);
    }
    Unit_getInstance();
  }
  function getOrPromoteCancellingList($this, state) {
    var tmp1_elvis_lhs = state._get_list__12();
    var tmp;
    if (tmp1_elvis_lhs == null) {
      var tmp0_subject = state;
      var tmp_0;
      if (tmp0_subject instanceof Empty) {
        tmp_0 = new NodeList_0();
      } else {
        if (tmp0_subject instanceof JobNode) {
          promoteSingleToNodeList($this, state);
          tmp_0 = null;
        } else {
          {
            var tmp0_error_0 = '' + 'State should have list: ' + state;
            throw IllegalStateException_init_$Create$_0(toString_2(tmp0_error_0));
          }
        }
      }
      tmp = tmp_0;
    } else {
      tmp = tmp1_elvis_lhs;
    }
    return tmp;
  }
  function tryMakeCancelling($this, state, rootCause) {
    var tmp0_elvis_lhs = getOrPromoteCancellingList($this, state);
    var tmp;
    if (tmp0_elvis_lhs == null) {
      return false;
    } else {
      tmp = tmp0_elvis_lhs;
    }
    var list = tmp;
    var cancelling = new Finishing(list, false, rootCause);
    if (!$this.__state_0.atomicfu$compareAndSet(state, cancelling))
      return false;
    notifyCancelling($this, list, rootCause);
    return true;
  }
  function tryMakeCompleting($this, state, proposedUpdate) {
    if (!(!(state == null) ? isInterface(state, Incomplete) : false))
      return COMPLETING_ALREADY;
    else {
    }
    var tmp;
    var tmp_0;
    var tmp_1;
    if (state instanceof Empty) {
      tmp_1 = true;
    } else {
      {
        tmp_1 = state instanceof JobNode;
      }
    }
    if (tmp_1) {
      tmp_0 = !(state instanceof ChildHandleNode);
    } else {
      {
        tmp_0 = false;
      }
    }
    if (tmp_0) {
      tmp = !(proposedUpdate instanceof CompletedExceptionally);
    } else {
      {
        tmp = false;
      }
    }
    if (tmp) {
      if (tryFinalizeSimpleState($this, state, proposedUpdate)) {
        return proposedUpdate;
      }return COMPLETING_RETRY;
    } else {
    }
    return tryMakeCompletingSlowPath($this, state, proposedUpdate);
  }
  function tryMakeCompletingSlowPath($this, state, proposedUpdate) {
    var tmp0_elvis_lhs = getOrPromoteCancellingList($this, state);
    var tmp;
    if (tmp0_elvis_lhs == null) {
      return COMPLETING_RETRY;
    } else {
      tmp = tmp0_elvis_lhs;
    }
    var list = tmp;
    var tmp1_elvis_lhs = state instanceof Finishing ? state : null;
    var finishing = tmp1_elvis_lhs == null ? new Finishing(list, false, null) : tmp1_elvis_lhs;
    var notifyRootCause = null;
    if (finishing._get_isCompleting_())
      return COMPLETING_ALREADY;
    finishing._set_isCompleting_(true);
    if (!(finishing === state)) {
      if (!$this.__state_0.atomicfu$compareAndSet(state, finishing))
        return COMPLETING_RETRY;
    }var wasCancelling_2 = finishing._get_isCancelling_();
    var tmp0_safe_receiver_3 = proposedUpdate instanceof CompletedExceptionally ? proposedUpdate : null;
    if (tmp0_safe_receiver_3 == null)
      null;
    else {
      finishing.addExceptionLocked(tmp0_safe_receiver_3._cause);
      Unit_getInstance();
    }
    Unit_getInstance();
    var tmp0_takeIf_0_4 = finishing._get_rootCause_();
    var tmp_0;
    if (!wasCancelling_2) {
      tmp_0 = tmp0_takeIf_0_4;
    } else {
      {
        tmp_0 = null;
      }
    }
    notifyRootCause = tmp_0;
    var tmp2_safe_receiver = notifyRootCause;
    if (tmp2_safe_receiver == null)
      null;
    else {
      notifyCancelling($this, list, tmp2_safe_receiver);
      Unit_getInstance();
    }
    Unit_getInstance();
    var child = firstChild($this, state);
    if (!(child == null) ? tryWaitForChild($this, finishing, child, proposedUpdate) : false)
      return COMPLETING_WAITING_CHILDREN;
    return finalizeFinishingState($this, finishing, proposedUpdate);
  }
  function _get_exceptionOrNull_(_this_, $this) {
    var tmp0_safe_receiver = _this_ instanceof CompletedExceptionally ? _this_ : null;
    return tmp0_safe_receiver == null ? null : tmp0_safe_receiver._cause;
  }
  function firstChild($this, state) {
    var tmp1_elvis_lhs = state instanceof ChildHandleNode ? state : null;
    var tmp;
    if (tmp1_elvis_lhs == null) {
      var tmp0_safe_receiver = state._get_list__12();
      tmp = tmp0_safe_receiver == null ? null : nextChild(tmp0_safe_receiver, $this);
    } else {
      tmp = tmp1_elvis_lhs;
    }
    return tmp;
  }
  function tryWaitForChild($this, state, child, proposedUpdate) {
    var $this_0 = $this;
    var state_0 = state;
    var child_0 = child;
    var proposedUpdate_0 = proposedUpdate;
    while (true) {
      var $this_1 = $this_0;
      var state_1 = state_0;
      var child_1 = child_0;
      var proposedUpdate_1 = proposedUpdate_0;
      var tmp0__get_asHandler__0 = new ChildCompletion($this_1, state_1, child_1, proposedUpdate_1);
      var handle = child_1._childJob.invokeOnCompletion$default_10(false, false, tmp0__get_asHandler__0, 1, null);
      if (!(handle === NonDisposableHandle_getInstance()))
        return true;
      var tmp0_elvis_lhs = nextChild(child_1, $this_1);
      var tmp;
      if (tmp0_elvis_lhs == null) {
        return false;
      } else {
        tmp = tmp0_elvis_lhs;
      }
      var nextChild_0 = tmp;
      $this_0 = $this_1;
      state_0 = state_1;
      child_0 = nextChild_0;
      proposedUpdate_0 = proposedUpdate_1;
      continue;
    }
  }
  function continueCompleting($this, state, lastChild, proposedUpdate) {
    var waitChild = nextChild(lastChild, $this);
    if (!(waitChild == null) ? tryWaitForChild($this, state, waitChild, proposedUpdate) : false)
      return Unit_getInstance();
    var finalState = finalizeFinishingState($this, state, proposedUpdate);
    $this.afterCompletion_9(finalState);
  }
  function nextChild(_this_, $this) {
    var cur = _this_;
    $l$break: while (true) {
      var tmp0__get_isRemoved__0 = cur;
      if (!tmp0__get_isRemoved__0.__removed) {
        break $l$break;
      }var tmp1__get_prevNode__0 = cur;
      cur = tmp1__get_prevNode__0.__prev;
    }
    while (true) {
      var tmp2__get_nextNode__0 = cur;
      cur = tmp2__get_nextNode__0.__next;
      var tmp3__get_isRemoved__0 = cur;
      if (tmp3__get_isRemoved__0.__removed)
        continue;
      else {
      }
      if (cur instanceof ChildHandleNode)
        return cur;
      else {
      }
      if (cur instanceof NodeList_0)
        return null;
      else {
      }
    }
  }
  function stateString($this, state) {
    var tmp0_subject = state;
    var tmp;
    if (tmp0_subject instanceof Finishing) {
      tmp = state._get_isCancelling_() ? 'Cancelling' : state._get_isCompleting_() ? 'Completing' : 'Active';
    } else {
      if (!(tmp0_subject == null) ? isInterface(tmp0_subject, Incomplete) : false) {
        tmp = state._get_isActive__23() ? 'Active' : 'New';
      } else {
        if (tmp0_subject instanceof CompletedExceptionally) {
          tmp = 'Cancelled';
        } else {
          {
            tmp = 'Completed';
          }
        }
      }
    }
    return tmp;
  }
  function Finishing(list, isCompleting, rootCause) {
    this._list_1 = list;
    this.__isCompleting = atomic_0(isCompleting);
    this.__rootCause = atomic(rootCause);
    this.__exceptionsHolder = atomic(null);
  }
  Finishing.prototype._get_list__12 = function () {
    return this._list_1;
  };
  Finishing.prototype._set_isCompleting_ = function (value_0) {
    this.__isCompleting._value_1 = value_0;
  };
  Finishing.prototype._get_isCompleting_ = function () {
    return this.__isCompleting._value_1;
  };
  Finishing.prototype._set_rootCause_ = function (value_0) {
    this.__rootCause._value_0 = value_0;
  };
  Finishing.prototype._get_rootCause_ = function () {
    return this.__rootCause._value_0;
  };
  Finishing.prototype._get_isSealed_ = function () {
    return _get_exceptionsHolder_(this) === SEALED;
  };
  Finishing.prototype._get_isCancelling_ = function () {
    return !(this._get_rootCause_() == null);
  };
  Finishing.prototype._get_isActive__23 = function () {
    return this._get_rootCause_() == null;
  };
  Finishing.prototype.sealLocked = function (proposedException) {
    var eh = _get_exceptionsHolder_(this);
    var tmp;
    if (eh == null) {
      tmp = allocateList(this);
    } else {
      if (eh instanceof Error) {
        var tmp0_also_0 = allocateList(this);
        tmp0_also_0.add_39(eh);
        Unit_getInstance();
        tmp = tmp0_also_0;
      } else {
        if (eh instanceof ArrayList) {
          tmp = eh instanceof ArrayList ? eh : THROW_CCE();
        } else {
          {
            var tmp1_error_0 = '' + 'State is ' + eh;
            throw IllegalStateException_init_$Create$_0(toString_2(tmp1_error_0));
          }
        }
      }
    }
    var list = tmp;
    var rootCause = this._get_rootCause_();
    var tmp0_safe_receiver = rootCause;
    if (tmp0_safe_receiver == null)
      null;
    else {
      list.add_21(0, tmp0_safe_receiver);
      Unit_getInstance();
    }
    Unit_getInstance();
    if (!(proposedException == null) ? !equals_0(proposedException, rootCause) : false) {
      list.add_39(proposedException);
      Unit_getInstance();
    }_set_exceptionsHolder_(this, SEALED);
    return list;
  };
  Finishing.prototype.addExceptionLocked = function (exception) {
    var rootCause = this._get_rootCause_();
    if (rootCause == null) {
      this._set_rootCause_(exception);
      return Unit_getInstance();
    }if (exception === rootCause)
      return Unit_getInstance();
    var eh = _get_exceptionsHolder_(this);
    if (eh == null)
      _set_exceptionsHolder_(this, exception);
    else {
      if (eh instanceof Error) {
        if (exception === eh)
          return Unit_getInstance();
        var tmp0_apply_0 = allocateList(this);
        tmp0_apply_0.add_39(eh);
        Unit_getInstance();
        tmp0_apply_0.add_39(exception);
        Unit_getInstance();
        _set_exceptionsHolder_(this, tmp0_apply_0);
      } else {
        if (eh instanceof ArrayList) {
          (eh instanceof ArrayList ? eh : THROW_CCE()).add_39(exception);
          Unit_getInstance();
        } else {
          {
            var tmp1_error_0 = '' + 'State is ' + eh;
            throw IllegalStateException_init_$Create$_0(toString_2(tmp1_error_0));
          }
        }
      }
    }
  };
  Finishing.prototype.toString = function () {
    return '' + 'Finishing[cancelling=' + this._get_isCancelling_() + ', completing=' + this._get_isCompleting_() + ', rootCause=' + this._get_rootCause_() + ', exceptions=' + _get_exceptionsHolder_(this) + ', list=' + this._list_1 + ']';
  };
  Finishing.$metadata$ = {
    simpleName: 'Finishing',
    kind: 'class',
    interfaces: [Incomplete]
  };
  function ChildCompletion(parent_0, state, child, proposedUpdate) {
    JobNode.call(this);
    this._parent = parent_0;
    this._state_3 = state;
    this._child = child;
    this._proposedUpdate = proposedUpdate;
  }
  ChildCompletion.prototype.invoke_341 = function (cause) {
    continueCompleting(this._parent, this._state_3, this._child, this._proposedUpdate);
  };
  ChildCompletion.prototype.invoke = function (cause) {
    return this.invoke_341(cause);
  };
  ChildCompletion.$metadata$ = {
    simpleName: 'ChildCompletion',
    kind: 'class',
    interfaces: []
  };
  function AwaitContinuation(delegate, job) {
    CancellableContinuationImpl.call(this, delegate, 1);
    this._job_0 = job;
  }
  AwaitContinuation.prototype.getContinuationCancellationCause_0 = function (parent_0) {
    var state = this._job_0._get_state__15();
    if (state instanceof Finishing) {
      var tmp0_safe_receiver = state._get_rootCause_();
      if (tmp0_safe_receiver == null)
        null;
      else {
        return tmp0_safe_receiver;
      }
      Unit_getInstance();
    } else {
    }
    if (state instanceof CompletedExceptionally)
      return state._cause;
    else {
    }
    return parent_0.getCancellationException_10();
  };
  AwaitContinuation.prototype.nameString_11 = function () {
    return 'AwaitContinuation';
  };
  AwaitContinuation.$metadata$ = {
    simpleName: 'AwaitContinuation',
    kind: 'class',
    interfaces: []
  };
  function awaitSuspend($this, $cont) {
    var tmp0__anonymous__1 = $cont;
    var cont_2 = new AwaitContinuation(intercepted(tmp0__anonymous__1), $this);
    var tmp0__get_asHandler__0_3 = new ResumeAwaitOnCompletion(cont_2);
    disposeOnCancellation(cont_2, $this.invokeOnCompletion_22(tmp0__get_asHandler__0_3));
    return cont_2.getResult_1();
  }
  function _no_name_provided__63(this$0, resultContinuation) {
    this._this$0_14 = this$0;
    CoroutineImpl_0.call(this, resultContinuation);
  }
  _no_name_provided__63.prototype.invoke_93 = function ($this$sequence, $cont) {
    var tmp = this.create_0($this$sequence, $cont);
    tmp._result_0 = Unit_getInstance();
    tmp._exception_0 = null;
    return tmp.doResume_17();
  };
  _no_name_provided__63.prototype.doResume_17 = function () {
    var suspendResult = this._result_0;
    $sm: do
      try {
        var tmp = this._state_2;
        switch (tmp) {
          case 0:
            this._exceptionState = 8;
            this._state0 = this._this$0_14._get_state__15();
            var tmp_0 = this._state0;
            if (tmp_0 instanceof ChildHandleNode) {
              this._state_2 = 6;
              suspendResult = this._$this$sequence.yield_0(this._state0._childJob, this);
              if (suspendResult === _get_COROUTINE_SUSPENDED_()) {
                return suspendResult;
              }continue $sm;
            } else {
              {
                var tmp_1 = this._state0;
                if (!(tmp_1 == null) ? isInterface(tmp_1, Incomplete) : false) {
                  this._tmp0_safe_receiver1 = this._state0._get_list__12();
                  if (this._tmp0_safe_receiver1 == null) {
                    this._WHEN_RESULT2 = null;
                    this._state_2 = 5;
                    continue $sm;
                  } else {
                    this._cur_1_23 = this._tmp0_safe_receiver1._get__next__1();
                    this._state_2 = 1;
                    continue $sm;
                  }
                } else {
                  {
                    this._state_2 = 7;
                    continue $sm;
                  }
                }
              }
            }

            break;
          case 1:
            if (!!equals_0(this._cur_1_23, this._tmp0_safe_receiver1)) {
              this._state_2 = 4;
              continue $sm;
            }
            var tmp_2 = this._cur_1_23;
            if (tmp_2 instanceof ChildHandleNode) {
              var tmp_3 = this;
              tmp_3._tmp0__anonymous__2_34 = this._cur_1_23;
              this._state_2 = 2;
              suspendResult = this._$this$sequence.yield_0(this._tmp0__anonymous__2_34._childJob, this);
              if (suspendResult === _get_COROUTINE_SUSPENDED_()) {
                return suspendResult;
              }continue $sm;
            } else {
              {
                this._state_2 = 3;
                continue $sm;
              }
            }

            break;
          case 2:
            suspendResult;
            this._state_2 = 3;
            continue $sm;
          case 3:
            this._cur_1_23 = this._cur_1_23.__next;
            this._state_2 = 1;
            continue $sm;
          case 4:
            this._WHEN_RESULT2 = Unit_getInstance();
            this._state_2 = 5;
            continue $sm;
          case 5:
            Unit_getInstance();
            this._state_2 = 7;
            continue $sm;
          case 6:
            suspendResult;
            this._state_2 = 7;
            continue $sm;
          case 7:
            return Unit_getInstance();
          case 8:
            throw this._exception_0;
        }
      } catch ($p) {
        if (this._exceptionState === 8) {
          throw $p;
        } else {
          this._state_2 = this._exceptionState;
          this._exception_0 = $p;
        }
      }
     while (true);
  };
  _no_name_provided__63.prototype.create_0 = function ($this$sequence, completion) {
    var i = new _no_name_provided__63(this._this$0_14, completion);
    i._$this$sequence = $this$sequence;
    return i;
  };
  _no_name_provided__63.$metadata$ = {
    kind: 'class',
    interfaces: [],
    suspendArity: 1
  };
  function JobSupport(active) {
    this.__state_0 = atomic(active ? EMPTY_ACTIVE : EMPTY_NEW);
    this.__parentHandle_0 = atomic(null);
  }
  JobSupport.prototype._get_key__35 = function () {
    return Key_getInstance_2();
  };
  JobSupport.prototype._set_parentHandle__9 = function (value_0) {
    this.__parentHandle_0._value_0 = value_0;
  };
  JobSupport.prototype._get_parentHandle__9 = function () {
    return this.__parentHandle_0._value_0;
  };
  JobSupport.prototype.initParentJobInternal_9 = function (parent_0) {
    if (parent_0 == null) {
      this._set_parentHandle__9(NonDisposableHandle_getInstance());
      return Unit_getInstance();
    }parent_0.start_16();
    Unit_getInstance();
    var handle = parent_0.attachChild_10(this);
    this._set_parentHandle__9(handle);
    if (this._get_isCompleted__11()) {
      handle.dispose_15();
      this._set_parentHandle__9(NonDisposableHandle_getInstance());
    }};
  JobSupport.prototype._get_state__15 = function () {
    var tmp0_loop_0 = this.__state_0;
    while (true) {
      var tmp1__anonymous__1 = tmp0_loop_0._value_0;
      if (!(tmp1__anonymous__1 instanceof OpDescriptor))
        return tmp1__anonymous__1;
      else {
      }
      tmp1__anonymous__1.perform(this);
      Unit_getInstance();
    }
    Unit_getInstance();
  };
  JobSupport.prototype._get_isActive__23 = function () {
    var state = this._get_state__15();
    var tmp;
    if (!(state == null) ? isInterface(state, Incomplete) : false) {
      tmp = state._get_isActive__23();
    } else {
      {
        tmp = false;
      }
    }
    return tmp;
  };
  JobSupport.prototype._get_isCompleted__11 = function () {
    var tmp = this._get_state__15();
    return !(!(tmp == null) ? isInterface(tmp, Incomplete) : false);
  };
  JobSupport.prototype.start_16 = function () {
    while (true) {
      var tmp0__anonymous__1 = this._get_state__15();
      var tmp0_subject_2 = startInternal(this, tmp0__anonymous__1);
      if (tmp0_subject_2 === 0)
        return false;
      else if (tmp0_subject_2 === 1)
        return true;
    }
    Unit_getInstance();
  };
  JobSupport.prototype.onStartInternal_9 = function () {
  };
  JobSupport.prototype.getCancellationException_10 = function () {
    var state = this._get_state__15();
    var tmp;
    if (state instanceof Finishing) {
      var tmp0_safe_receiver = state._get_rootCause_();
      var tmp1_elvis_lhs = tmp0_safe_receiver == null ? null : this.toCancellationException_9(tmp0_safe_receiver, '' + _get_classSimpleName_(this) + ' is cancelling');
      var tmp_0;
      if (tmp1_elvis_lhs == null) {
        var tmp0_error_0 = '' + 'Job is still new or active: ' + this;
        throw IllegalStateException_init_$Create$_0(toString_2(tmp0_error_0));
      } else {
        tmp_0 = tmp1_elvis_lhs;
      }
      tmp = tmp_0;
    } else {
      if (!(state == null) ? isInterface(state, Incomplete) : false) {
        var tmp1_error_0 = '' + 'Job is still new or active: ' + this;
        throw IllegalStateException_init_$Create$_0(toString_2(tmp1_error_0));
      } else {
        if (state instanceof CompletedExceptionally) {
          var tmp_1 = state._cause;
          tmp = this.toCancellationException$default_9(tmp_1, null, 1, null);
        } else {
          {
            tmp = new JobCancellationException('' + _get_classSimpleName_(this) + ' has completed normally', null, this);
          }
        }
      }
    }
    return tmp;
  };
  JobSupport.prototype.toCancellationException_9 = function (_this__0, message) {
    var tmp0_elvis_lhs = _this__0 instanceof CancellationException ? _this__0 : null;
    var tmp;
    if (tmp0_elvis_lhs == null) {
      var tmp0_elvis_lhs_1 = message;
      tmp = new JobCancellationException(tmp0_elvis_lhs_1 == null ? this.cancellationExceptionMessage_9() : tmp0_elvis_lhs_1, _this__0, this);
    } else {
      tmp = tmp0_elvis_lhs;
    }
    return tmp;
  };
  JobSupport.prototype.toCancellationException$default_9 = function (_this__0, message, $mask0, $handler) {
    if (!(($mask0 & 1) === 0))
      message = null;
    return this.toCancellationException_9(_this__0, message);
  };
  JobSupport.prototype.invokeOnCompletion_22 = function (handler) {
    return this.invokeOnCompletion_23(false, true, handler);
  };
  JobSupport.prototype.invokeOnCompletion_23 = function (onCancelling, invokeImmediately, handler) {
    var node_0 = makeNode(this, handler, onCancelling);
    while (true) {
      var tmp$ret$0;
      l$ret$1: do {
        var tmp0__anonymous__1 = this._get_state__15();
        var tmp0_subject_2 = tmp0__anonymous__1;
        if (tmp0_subject_2 instanceof Empty) {
          if (tmp0__anonymous__1._isActive) {
            if (this.__state_0.atomicfu$compareAndSet(tmp0__anonymous__1, node_0))
              return node_0;
          } else
            promoteEmptyToNodeList(this, tmp0__anonymous__1);
        } else {
          if (!(tmp0_subject_2 == null) ? isInterface(tmp0_subject_2, Incomplete) : false) {
            var list_3 = tmp0__anonymous__1._get_list__12();
            if (list_3 == null) {
              promoteSingleToNodeList(this, tmp0__anonymous__1 instanceof JobNode ? tmp0__anonymous__1 : THROW_CCE());
            } else {
              var rootCause_4 = null;
              var handle_5 = NonDisposableHandle_getInstance();
              var tmp;
              if (onCancelling) {
                tmp = tmp0__anonymous__1 instanceof Finishing;
              } else {
                tmp = false;
              }
              if (tmp) {
                rootCause_4 = tmp0__anonymous__1._get_rootCause_();
                var tmp_0;
                if (rootCause_4 == null) {
                  tmp_0 = true;
                } else {
                  var tmp_1;
                  if (handler instanceof ChildHandleNode) {
                    tmp_1 = !tmp0__anonymous__1._get_isCompleting_();
                  } else {
                    {
                      tmp_1 = false;
                    }
                  }
                  tmp_0 = tmp_1;
                }
                if (tmp_0) {
                  if (!addLastAtomic(this, tmp0__anonymous__1, list_3, node_0)) {
                    tmp$ret$0 = Unit_getInstance();
                    break l$ret$1;
                  }if (rootCause_4 == null)
                    return node_0;
                  handle_5 = node_0;
                } else {
                }
              } else {
              }
              if (!(rootCause_4 == null)) {
                if (invokeImmediately)
                  invokeIt(handler, rootCause_4);
                return handle_5;
              } else {
                if (addLastAtomic(this, tmp0__anonymous__1, list_3, node_0))
                  return node_0;
              }
            }
          } else {
            {
              if (invokeImmediately) {
                var tmp1_safe_receiver_6 = tmp0__anonymous__1 instanceof CompletedExceptionally ? tmp0__anonymous__1 : null;
                invokeIt(handler, tmp1_safe_receiver_6 == null ? null : tmp1_safe_receiver_6._cause);
              }return NonDisposableHandle_getInstance();
            }
          }
        }
      }
       while (false);
    }
    Unit_getInstance();
  };
  JobSupport.prototype.removeNode_9 = function (node_0) {
    while (true) {
      var tmp0__anonymous__1 = this._get_state__15();
      var tmp0_subject_2 = tmp0__anonymous__1;
      if (tmp0_subject_2 instanceof JobNode) {
        if (!(tmp0__anonymous__1 === node_0))
          return Unit_getInstance();
        if (this.__state_0.atomicfu$compareAndSet(tmp0__anonymous__1, EMPTY_ACTIVE))
          return Unit_getInstance();
      } else {
        if (!(tmp0_subject_2 == null) ? isInterface(tmp0_subject_2, Incomplete) : false) {
          if (!(tmp0__anonymous__1._get_list__12() == null)) {
            node_0.remove_38();
            Unit_getInstance();
          }return Unit_getInstance();
        } else {
          return Unit_getInstance();
        }
      }
    }
    Unit_getInstance();
  };
  JobSupport.prototype._get_onCancelComplete__9 = function () {
    return false;
  };
  JobSupport.prototype.cancel_13 = function (cause) {
    var tmp0_elvis_lhs = cause;
    var tmp;
    if (tmp0_elvis_lhs == null) {
      var tmp0_defaultCancellationException_0 = null;
      var tmp1_defaultCancellationException_0 = null;
      var tmp0_elvis_lhs_1 = tmp0_defaultCancellationException_0;
      tmp = new JobCancellationException(tmp0_elvis_lhs_1 == null ? this.cancellationExceptionMessage_9() : tmp0_elvis_lhs_1, tmp1_defaultCancellationException_0, this);
    } else {
      tmp = tmp0_elvis_lhs;
    }
    this.cancelInternal_9(tmp);
  };
  JobSupport.prototype.cancellationExceptionMessage_9 = function () {
    return 'Job was cancelled';
  };
  JobSupport.prototype.cancelInternal_9 = function (cause) {
    this.cancelImpl_9(cause);
    Unit_getInstance();
  };
  JobSupport.prototype.parentCancelled_12 = function (parentJob) {
    this.cancelImpl_9(parentJob);
    Unit_getInstance();
  };
  JobSupport.prototype.childCancelled_12 = function (cause) {
    if (cause instanceof CancellationException)
      return true;
    else {
    }
    return this.cancelImpl_9(cause) ? this._get_handlesException__9() : false;
  };
  JobSupport.prototype.cancelImpl_9 = function (cause) {
    var finalState = COMPLETING_ALREADY;
    if (this._get_onCancelComplete__9()) {
      finalState = cancelMakeCompleting(this, cause);
      if (finalState === COMPLETING_WAITING_CHILDREN)
        return true;
    }if (finalState === COMPLETING_ALREADY) {
      finalState = makeCancelling(this, cause);
    }var tmp;
    if (finalState === COMPLETING_ALREADY) {
      tmp = true;
    } else if (finalState === COMPLETING_WAITING_CHILDREN) {
      tmp = true;
    } else if (finalState === TOO_LATE_TO_CANCEL) {
      tmp = false;
    } else {
      this.afterCompletion_9(finalState);
      tmp = true;
    }
    return tmp;
  };
  JobSupport.prototype.getChildJobCancellationCause_10 = function () {
    var state = this._get_state__15();
    var tmp0_subject = state;
    var tmp;
    if (tmp0_subject instanceof Finishing) {
      tmp = state._get_rootCause_();
    } else {
      if (tmp0_subject instanceof CompletedExceptionally) {
        tmp = state._cause;
      } else {
        if (!(tmp0_subject == null) ? isInterface(tmp0_subject, Incomplete) : false) {
          var tmp0_error_0 = '' + 'Cannot be cancelling child in this state: ' + state;
          throw IllegalStateException_init_$Create$_0(toString_2(tmp0_error_0));
        } else {
          {
            tmp = null;
          }
        }
      }
    }
    var rootCause = tmp;
    var tmp1_elvis_lhs = rootCause instanceof CancellationException ? rootCause : null;
    return tmp1_elvis_lhs == null ? new JobCancellationException('' + 'Parent job is ' + stateString(this, state), rootCause, this) : tmp1_elvis_lhs;
  };
  JobSupport.prototype.makeCompletingOnce_7 = function (proposedUpdate) {
    while (true) {
      var tmp$ret$0;
      l$ret$1: do {
        var tmp0__anonymous__1 = this._get_state__15();
        var finalState_2 = tryMakeCompleting(this, tmp0__anonymous__1, proposedUpdate);
        if (finalState_2 === COMPLETING_ALREADY)
          throw IllegalStateException_init_$Create$_1('' + 'Job ' + this + ' is already complete or completing, ' + ('' + 'but is being completed with ' + proposedUpdate), _get_exceptionOrNull_(proposedUpdate, this));
        else if (finalState_2 === COMPLETING_RETRY) {
          tmp$ret$0 = Unit_getInstance();
          break l$ret$1;
        } else
          return finalState_2;
      }
       while (false);
    }
    Unit_getInstance();
  };
  JobSupport.prototype._get_children__28 = function () {
    return sequence(_no_name_provided_$factory_44(this, null));
  };
  JobSupport.prototype.attachChild_10 = function (child) {
    var tmp0__get_asHandler__0 = new ChildHandleNode(child);
    var tmp = this.invokeOnCompletion$default_10(true, false, tmp0__get_asHandler__0, 2, null);
    return isInterface(tmp, ChildHandle) ? tmp : THROW_CCE();
  };
  JobSupport.prototype.handleOnCompletionException_9 = function (exception) {
    throw exception;
  };
  JobSupport.prototype.onCancelling_9 = function (cause) {
  };
  JobSupport.prototype._get_isScopedCoroutine__9 = function () {
    return false;
  };
  JobSupport.prototype._get_handlesException__9 = function () {
    return true;
  };
  JobSupport.prototype.handleJobException_9 = function (exception) {
    return false;
  };
  JobSupport.prototype.onCompletionInternal_9 = function (state) {
  };
  JobSupport.prototype.afterCompletion_9 = function (state) {
  };
  JobSupport.prototype.toString = function () {
    return '' + this.toDebugString_9() + '@' + _get_hexAddress_(this);
  };
  JobSupport.prototype.toDebugString_9 = function () {
    return '' + this.nameString_11() + '{' + stateString(this, this._get_state__15()) + '}';
  };
  JobSupport.prototype.nameString_11 = function () {
    return _get_classSimpleName_(this);
  };
  JobSupport.prototype.awaitInternal_1 = function ($cont) {
    $l$break: while (true) {
      var state = this._get_state__15();
      if (!(!(state == null) ? isInterface(state, Incomplete) : false)) {
        if (state instanceof CompletedExceptionally) {
          var tmp0_recoverAndThrow_0 = state._cause;
          throw tmp0_recoverAndThrow_0;
        } else {
        }
        return unboxState(state);
      } else {
      }
      if (startInternal(this, state) >= 0)
        break $l$break;
    }
    return awaitSuspend(this, $cont);
  };
  JobSupport.$metadata$ = {
    simpleName: 'JobSupport',
    kind: 'class',
    interfaces: [Job, ChildJob, ParentJob, SelectClause0]
  };
  function boxIncomplete(_this_) {
    var tmp;
    if (!(_this_ == null) ? isInterface(_this_, Incomplete) : false) {
      tmp = new IncompleteStateBox(_this_);
    } else {
      {
        tmp = _this_;
      }
    }
    return tmp;
  }
  function JobCancellingNode() {
    JobNode.call(this);
  }
  JobCancellingNode.$metadata$ = {
    simpleName: 'JobCancellingNode',
    kind: 'class',
    interfaces: []
  };
  function InactiveNodeList(list) {
    this._list_2 = list;
  }
  InactiveNodeList.prototype._get_list__12 = function () {
    return this._list_2;
  };
  InactiveNodeList.prototype._get_isActive__23 = function () {
    return false;
  };
  InactiveNodeList.prototype.toString = function () {
    return DEBUG ? this._list_2.getString('New') : anyToString(this);
  };
  InactiveNodeList.$metadata$ = {
    simpleName: 'InactiveNodeList',
    kind: 'class',
    interfaces: [Incomplete]
  };
  function ChildHandleNode(childJob) {
    JobCancellingNode.call(this);
    this._childJob = childJob;
  }
  ChildHandleNode.prototype.invoke_341 = function (cause) {
    return this._childJob.parentCancelled_12(this._get_job__6());
  };
  ChildHandleNode.prototype.invoke = function (cause) {
    return this.invoke_341(cause);
  };
  ChildHandleNode.prototype.childCancelled_12 = function (cause) {
    return this._get_job__6().childCancelled_12(cause);
  };
  ChildHandleNode.$metadata$ = {
    simpleName: 'ChildHandleNode',
    kind: 'class',
    interfaces: [ChildHandle]
  };
  function InvokeOnCancelling(handler) {
    JobCancellingNode.call(this);
    this._handler_0 = handler;
    this.__invoked = atomic_1(0);
  }
  InvokeOnCancelling.prototype.invoke_341 = function (cause) {
    if (this.__invoked.atomicfu$compareAndSet(0, 1))
      this._handler_0(cause);
  };
  InvokeOnCancelling.prototype.invoke = function (cause) {
    return this.invoke_341(cause);
  };
  InvokeOnCancelling.$metadata$ = {
    simpleName: 'InvokeOnCancelling',
    kind: 'class',
    interfaces: []
  };
  function InvokeOnCompletion(handler) {
    JobNode.call(this);
    this._handler_1 = handler;
  }
  InvokeOnCompletion.prototype.invoke_341 = function (cause) {
    return this._handler_1(cause);
  };
  InvokeOnCompletion.prototype.invoke = function (cause) {
    return this.invoke_341(cause);
  };
  InvokeOnCompletion.$metadata$ = {
    simpleName: 'InvokeOnCompletion',
    kind: 'class',
    interfaces: []
  };
  function unboxState(_this_) {
    var tmp0_safe_receiver = _this_ instanceof IncompleteStateBox ? _this_ : null;
    var tmp1_elvis_lhs = tmp0_safe_receiver == null ? null : tmp0_safe_receiver._state_4;
    return tmp1_elvis_lhs == null ? _this_ : tmp1_elvis_lhs;
  }
  function ResumeAwaitOnCompletion(continuation) {
    JobNode.call(this);
    this._continuation_1 = continuation;
  }
  ResumeAwaitOnCompletion.prototype.invoke_341 = function (cause) {
    var state = this._get_job__6()._get_state__15();
    if (state instanceof CompletedExceptionally) {
      var tmp0_resumeWithException_0 = this._continuation_1;
      var tmp1_resumeWithException_0 = state._cause;
      var tmp0_failure_0_1 = Companion_getInstance_2();
      tmp0_resumeWithException_0.resumeWith_21(_Result___init__impl_(createFailure(tmp1_resumeWithException_0)));
    } else {
      {
        var tmp2_resume_0 = this._continuation_1;
        var tmp = unboxState(state);
        var tmp3_resume_0 = (tmp == null ? true : isObject(tmp)) ? tmp : THROW_CCE();
        var tmp0_success_0_1 = Companion_getInstance_2();
        tmp2_resume_0.resumeWith_21(_Result___init__impl_(tmp3_resume_0));
      }
    }
  };
  ResumeAwaitOnCompletion.prototype.invoke = function (cause) {
    return this.invoke_341(cause);
  };
  ResumeAwaitOnCompletion.$metadata$ = {
    simpleName: 'ResumeAwaitOnCompletion',
    kind: 'class',
    interfaces: []
  };
  function IncompleteStateBox(state) {
    this._state_4 = state;
  }
  IncompleteStateBox.$metadata$ = {
    simpleName: 'IncompleteStateBox',
    kind: 'class',
    interfaces: []
  };
  function ChildContinuation(child) {
    JobCancellingNode.call(this);
    this._child_0 = child;
  }
  ChildContinuation.prototype.invoke_341 = function (cause) {
    this._child_0.parentCancelled_7(this._child_0.getContinuationCancellationCause_0(this._get_job__6()));
  };
  ChildContinuation.prototype.invoke = function (cause) {
    return this.invoke_341(cause);
  };
  ChildContinuation.$metadata$ = {
    simpleName: 'ChildContinuation',
    kind: 'class',
    interfaces: []
  };
  function handlesException($this) {
    var tmp = $this._get_parentHandle__9();
    var tmp0_safe_receiver = tmp instanceof ChildHandleNode ? tmp : null;
    var tmp1_elvis_lhs = tmp0_safe_receiver == null ? null : tmp0_safe_receiver._get_job__6();
    var tmp_0;
    if (tmp1_elvis_lhs == null) {
      return false;
    } else {
      tmp_0 = tmp1_elvis_lhs;
    }
    var parentJob = tmp_0;
    while (true) {
      if (parentJob._get_handlesException__9())
        return true;
      var tmp_1 = parentJob._get_parentHandle__9();
      var tmp2_safe_receiver = tmp_1 instanceof ChildHandleNode ? tmp_1 : null;
      var tmp3_elvis_lhs = tmp2_safe_receiver == null ? null : tmp2_safe_receiver._get_job__6();
      var tmp_2;
      if (tmp3_elvis_lhs == null) {
        return false;
      } else {
        tmp_2 = tmp3_elvis_lhs;
      }
      parentJob = tmp_2;
    }
  }
  function JobImpl(parent_0) {
    JobSupport.call(this, true);
    this.initParentJobInternal_9(parent_0);
    this._handlesException = handlesException(this);
  }
  JobImpl.prototype._get_onCancelComplete__9 = function () {
    return true;
  };
  JobImpl.prototype._get_handlesException__9 = function () {
    return this._handlesException;
  };
  JobImpl.$metadata$ = {
    simpleName: 'JobImpl',
    kind: 'class',
    interfaces: [CompletableJob]
  };
  function _no_name_provided_$factory_44(this$0, resultContinuation) {
    var i = new _no_name_provided__63(this$0, resultContinuation);
    var l = function (p1, $cont) {
      return i.invoke_93(p1, $cont);
    };
    l.$arity = 1;
    return l;
  }
  function MainCoroutineDispatcher() {
    CoroutineDispatcher.call(this);
  }
  MainCoroutineDispatcher.prototype.toString = function () {
    var tmp0_elvis_lhs = this.toStringInternalImpl_0();
    return tmp0_elvis_lhs == null ? '' + _get_classSimpleName_(this) + '@' + _get_hexAddress_(this) : tmp0_elvis_lhs;
  };
  MainCoroutineDispatcher.prototype.toStringInternalImpl_0 = function () {
    var main_0 = Dispatchers_getInstance()._Main;
    if (this === main_0)
      return 'Dispatchers.Main';
    var tmp;
    try {
      tmp = main_0._get_immediate__0();
    } catch ($p) {
      var tmp_0;
      if ($p instanceof UnsupportedOperationException) {
        tmp_0 = null;
      } else {
        {
          throw $p;
        }
      }
      tmp = tmp_0;
    }
    var immediate = tmp;
    if (this === immediate)
      return 'Dispatchers.Main.immediate';
    return null;
  };
  MainCoroutineDispatcher.$metadata$ = {
    simpleName: 'MainCoroutineDispatcher',
    kind: 'class',
    interfaces: []
  };
  function SupervisorJob(parent_0) {
    return new SupervisorJobImpl(parent_0);
  }
  function SupervisorJob$default(parent_0, $mask0, $handler) {
    if (!(($mask0 & 1) === 0))
      parent_0 = null;
    return SupervisorJob(parent_0);
  }
  function SupervisorJobImpl(parent_0) {
    JobImpl.call(this, parent_0);
  }
  SupervisorJobImpl.prototype.childCancelled_12 = function (cause) {
    return false;
  };
  SupervisorJobImpl.$metadata$ = {
    simpleName: 'SupervisorJobImpl',
    kind: 'class',
    interfaces: []
  };
  function TimeoutCancellationException() {
  }
  TimeoutCancellationException.$metadata$ = {
    simpleName: 'TimeoutCancellationException',
    kind: 'class',
    interfaces: [CopyableThrowable]
  };
  function Unconfined() {
    Unconfined_instance = this;
    CoroutineDispatcher.call(this);
  }
  Unconfined.prototype.isDispatchNeeded_9 = function (context) {
    return false;
  };
  Unconfined.prototype.dispatch_9 = function (context, block) {
    var yieldContext = context.get_62(Key_getInstance_3());
    if (!(yieldContext == null)) {
      yieldContext.dispatcherWasUnconfined__error = true;
      return Unit_getInstance();
    }throw UnsupportedOperationException_init_$Create$_0('Dispatchers.Unconfined.dispatch function can only be used by the yield function. If you wrap Unconfined dispatcher in your code, make sure you properly delegate isDispatchNeeded and dispatch calls.');
  };
  Unconfined.prototype.toString = function () {
    return 'Dispatchers.Unconfined';
  };
  Unconfined.$metadata$ = {
    simpleName: 'Unconfined',
    kind: 'object',
    interfaces: []
  };
  var Unconfined_instance;
  function Unconfined_getInstance() {
    if (Unconfined_instance == null)
      new Unconfined();
    return Unconfined_instance;
  }
  function Key_4() {
    Key_instance_3 = this;
  }
  Key_4.$metadata$ = {
    simpleName: 'Key',
    kind: 'object',
    interfaces: [Key_0]
  };
  var Key_instance_3;
  function Key_getInstance_3() {
    if (Key_instance_3 == null)
      new Key_4();
    return Key_instance_3;
  }
  function checkCompletion(_this_) {
    var job = _this_.get_62(Key_getInstance_2());
    if (!(job == null) ? !job._get_isActive__23() : false)
      throw job.getCancellationException_10();
  }
  function Flow() {
  }
  Flow.$metadata$ = {
    simpleName: 'Flow',
    kind: 'interface',
    interfaces: []
  };
  function FlowCollector() {
  }
  FlowCollector.$metadata$ = {
    simpleName: 'FlowCollector',
    kind: 'interface',
    interfaces: []
  };
  function MutableSharedFlow() {
  }
  MutableSharedFlow.$metadata$ = {
    simpleName: 'MutableSharedFlow',
    kind: 'interface',
    interfaces: [SharedFlow, FlowCollector]
  };
  function SharedFlow() {
  }
  SharedFlow.$metadata$ = {
    simpleName: 'SharedFlow',
    kind: 'interface',
    interfaces: [Flow]
  };
  var NONE;
  var PENDING;
  function MutableStateFlow() {
  }
  MutableStateFlow.$metadata$ = {
    simpleName: 'MutableStateFlow',
    kind: 'interface',
    interfaces: [StateFlow, MutableSharedFlow]
  };
  function MutableStateFlow_0(value_0) {
    var tmp0_elvis_lhs = value_0;
    return new StateFlowImpl(tmp0_elvis_lhs == null ? NULL : tmp0_elvis_lhs);
  }
  function StateFlow() {
  }
  StateFlow.$metadata$ = {
    simpleName: 'StateFlow',
    kind: 'interface',
    interfaces: [SharedFlow]
  };
  function updateState($this, expectedState, newState) {
    var curSequence = 0;
    var curSlots = $this._get_slots__0();
    var oldState_2 = $this.__state_1._value_0;
    if (!(expectedState == null) ? !equals_0(oldState_2, expectedState) : false)
      return false;
    if (equals_0(oldState_2, newState))
      return true;
    $this.__state_1._value_0 = newState;
    curSequence = $this._sequence_2;
    if ((curSequence & 1) === 0) {
      var tmp0_3 = curSequence;
      curSequence = tmp0_3 + 1 | 0;
      Unit_getInstance();
      $this._sequence_2 = curSequence;
    } else {
      $this._sequence_2 = curSequence + 2 | 0;
      return true;
    }
    curSlots = $this._get_slots__0();
    while (true) {
      var tmp0_safe_receiver = curSlots;
      if (tmp0_safe_receiver == null)
        null;
      else {
        var tmp0_iterator_1 = arrayIterator(tmp0_safe_receiver);
        while (tmp0_iterator_1.hasNext_41()) {
          var element_2 = tmp0_iterator_1.next_46();
          var tmp0_safe_receiver_4 = element_2;
          if (tmp0_safe_receiver_4 == null)
            null;
          else {
            tmp0_safe_receiver_4.makePending__error();
            Unit_getInstance();
          }
          Unit_getInstance();
        }
        Unit_getInstance();
      }
      Unit_getInstance();
      if ($this._sequence_2 === curSequence) {
        $this._sequence_2 = curSequence + 1 | 0;
        return true;
      }curSequence = $this._sequence_2;
      curSlots = $this._get_slots__0();
    }
  }
  function StateFlowImpl(initialState) {
    AbstractSharedFlow.call(this);
    this.__state_1 = atomic(initialState);
    this._sequence_2 = 0;
  }
  StateFlowImpl.prototype._set_value__6 = function (value_0) {
    var tmp0_elvis_lhs = value_0;
    updateState(this, null, tmp0_elvis_lhs == null ? NULL : tmp0_elvis_lhs);
    Unit_getInstance();
  };
  StateFlowImpl.prototype._get_value__33 = function () {
    var tmp0_unbox_0 = NULL;
    var tmp1_unbox_0 = this.__state_1._value_0;
    var tmp;
    if (tmp1_unbox_0 === tmp0_unbox_0) {
      tmp = (null == null ? true : isObject(null)) ? null : THROW_CCE();
    } else {
      tmp = isObject(tmp1_unbox_0) ? tmp1_unbox_0 : THROW_CCE();
    }
    return tmp;
  };
  StateFlowImpl.prototype.compareAndSet_0 = function (expect, update) {
    var tmp0_elvis_lhs = expect;
    var tmp = tmp0_elvis_lhs == null ? NULL : tmp0_elvis_lhs;
    var tmp1_elvis_lhs = update;
    return updateState(this, tmp, tmp1_elvis_lhs == null ? NULL : tmp1_elvis_lhs);
  };
  StateFlowImpl.$metadata$ = {
    simpleName: 'StateFlowImpl',
    kind: 'class',
    interfaces: [MutableStateFlow, CancellableFlow, FusibleFlow]
  };
  function AbstractSharedFlow() {
    this._slots = null;
    this._nCollectors = 0;
    this._nextIndex = 0;
    this.__subscriptionCount = null;
  }
  AbstractSharedFlow.prototype._get_slots__0 = function () {
    return this._slots;
  };
  AbstractSharedFlow.$metadata$ = {
    simpleName: 'AbstractSharedFlow',
    kind: 'class',
    interfaces: []
  };
  function FusibleFlow() {
  }
  FusibleFlow.$metadata$ = {
    simpleName: 'FusibleFlow',
    kind: 'interface',
    interfaces: [Flow]
  };
  var NULL;
  function CancellableFlow() {
  }
  CancellableFlow.$metadata$ = {
    simpleName: 'CancellableFlow',
    kind: 'interface',
    interfaces: [Flow]
  };
  function ensureCapacity($this) {
    var currentSize = $this._elements.length;
    var newCapacity = currentSize << 1;
    var newElements = fillArrayVal(Array(newCapacity), null);
    var tmp0_copyInto_0 = $this._elements;
    var tmp1_copyInto_0 = $this._head_0;
    var tmp2_copyInto_0 = 0;
    var tmp3_copyInto_0 = tmp0_copyInto_0.length;
    arrayCopy_0(tmp0_copyInto_0, newElements, tmp2_copyInto_0, tmp1_copyInto_0, tmp3_copyInto_0);
    Unit_getInstance();
    var tmp4_copyInto_0 = $this._elements;
    var tmp5_copyInto_0 = $this._elements.length - $this._head_0 | 0;
    var tmp6_copyInto_0 = $this._head_0;
    var tmp7_copyInto_0 = 0;
    arrayCopy_0(tmp4_copyInto_0, newElements, tmp5_copyInto_0, tmp7_copyInto_0, tmp6_copyInto_0);
    Unit_getInstance();
    $this._elements = newElements;
    $this._head_0 = 0;
    $this._tail = currentSize;
  }
  function ArrayQueue() {
    var tmp = this;
    var tmp0_arrayOfNulls_0 = 16;
    tmp._elements = fillArrayVal(Array(tmp0_arrayOfNulls_0), null);
    this._head_0 = 0;
    this._tail = 0;
  }
  ArrayQueue.prototype._get_isEmpty__3 = function () {
    return this._head_0 === this._tail;
  };
  ArrayQueue.prototype.addLast_8 = function (element) {
    this._elements[this._tail] = element;
    this._tail = (this._tail + 1 | 0) & (this._elements.length - 1 | 0);
    if (this._tail === this._head_0)
      ensureCapacity(this);
  };
  ArrayQueue.prototype.removeFirstOrNull_2 = function () {
    if (this._head_0 === this._tail)
      return null;
    var element = this._elements[this._head_0];
    this._elements[this._head_0] = null;
    this._head_0 = (this._head_0 + 1 | 0) & (this._elements.length - 1 | 0);
    return isObject(element) ? element : THROW_CCE();
  };
  ArrayQueue.$metadata$ = {
    simpleName: 'ArrayQueue',
    kind: 'class',
    interfaces: []
  };
  function OpDescriptor() {
  }
  OpDescriptor.$metadata$ = {
    simpleName: 'OpDescriptor',
    kind: 'class',
    interfaces: []
  };
  var UNDEFINED;
  var REUSABLE_CLAIMED;
  function resumeCancellableWith(_this_, result, onCancellation) {
    var tmp0_subject = _this_;
    var tmp;
    if (tmp0_subject instanceof DispatchedContinuation) {
      var tmp2_resumeCancellableWith_0 = _this_;
      var state_1 = toState_0(result, onCancellation);
      var tmp_0;
      if (tmp2_resumeCancellableWith_0._dispatcher.isDispatchNeeded_9(tmp2_resumeCancellableWith_0._get_context__31())) {
        tmp2_resumeCancellableWith_0.__state_2 = state_1;
        tmp2_resumeCancellableWith_0._set_resumeMode__0(1);
        tmp_0 = tmp2_resumeCancellableWith_0._dispatcher.dispatch_9(tmp2_resumeCancellableWith_0._get_context__31(), tmp2_resumeCancellableWith_0);
      } else {
        var tmp$ret$0;
        l$ret$1: do {
          var tmp0_executeUnconfined_0 = 1;
          var tmp1_executeUnconfined_0 = false;
          var eventLoop_2 = ThreadLocalEventLoop_getInstance()._get_eventLoop_();
          if (tmp1_executeUnconfined_0 ? eventLoop_2._get_isUnconfinedQueueEmpty__0() : false) {
            tmp$ret$0 = false;
            break l$ret$1;
          }var tmp_1;
          if (eventLoop_2._get_isUnconfinedLoopActive__0()) {
            tmp2_resumeCancellableWith_0.__state_2 = state_1;
            tmp2_resumeCancellableWith_0._set_resumeMode__0(tmp0_executeUnconfined_0);
            eventLoop_2.dispatchUnconfined_0(tmp2_resumeCancellableWith_0);
            tmp_1 = true;
          } else {
            eventLoop_2.incrementUseCount_0(true);
            try {
              var tmp$ret$2;
              l$ret$3: do {
                var job_1_4 = tmp2_resumeCancellableWith_0._get_context__31().get_62(Key_getInstance_2());
                if (!(job_1_4 == null) ? !job_1_4._get_isActive__23() : false) {
                  var cause_2_5 = job_1_4.getCancellationException_10();
                  tmp2_resumeCancellableWith_0.cancelCompletedResult_2(state_1, cause_2_5);
                  var tmp0_failure_0_1_6 = Companion_getInstance_2();
                  tmp2_resumeCancellableWith_0.resumeWith_21(_Result___init__impl_(createFailure(cause_2_5)));
                  tmp$ret$2 = true;
                  break l$ret$3;
                }tmp$ret$2 = false;
              }
               while (false);
              if (!tmp$ret$2) {
                var tmp0_withContinuationContext_0_7 = tmp2_resumeCancellableWith_0._continuation_2;
                var tmp1_withContinuationContext_0_8 = tmp2_resumeCancellableWith_0._countOrElement;
                tmp2_resumeCancellableWith_0._continuation_2.resumeWith_21(result);
              } else {
              }
              $l$break: while (true) {
                if (!eventLoop_2.processUnconfinedEvent_0())
                  break $l$break;
              }
            } catch ($p) {
              if ($p instanceof Error) {
                tmp2_resumeCancellableWith_0.handleFatalException_2($p, null);
              } else {
                {
                  throw $p;
                }
              }
            }
            finally {
              eventLoop_2.decrementUseCount_0(true);
            }
            tmp_1 = false;
          }
          tmp$ret$0 = tmp_1;
        }
         while (false);
        tmp_0 = Unit_getInstance();
      }
      tmp = tmp_0;
    } else {
      {
        tmp = _this_.resumeWith_21(result);
      }
    }
    return tmp;
  }
  function resumeCancellableWith$default(_this_, result, onCancellation, $mask0, $handler) {
    if (!(($mask0 & 2) === 0))
      onCancellation = null;
    return resumeCancellableWith(_this_, result, onCancellation);
  }
  function DispatchedContinuation(dispatcher, continuation) {
    DispatchedTask.call(this, -1);
    this._dispatcher = dispatcher;
    this._continuation_2 = continuation;
    this.__state_2 = UNDEFINED;
    this._countOrElement = threadContextElements(this._get_context__31());
    this.__reusableCancellableContinuation = atomic(null);
  }
  DispatchedContinuation.prototype._get_context__31 = function () {
    return this._continuation_2._get_context__31();
  };
  DispatchedContinuation.prototype._get_reusableCancellableContinuation_ = function () {
    var tmp = this.__reusableCancellableContinuation._value_0;
    return tmp instanceof CancellableContinuationImpl ? tmp : null;
  };
  DispatchedContinuation.prototype.isReusable = function (requester) {
    var tmp0_elvis_lhs = this.__reusableCancellableContinuation._value_0;
    var tmp;
    if (tmp0_elvis_lhs == null) {
      return false;
    } else {
      tmp = tmp0_elvis_lhs;
    }
    var value_0 = tmp;
    if (value_0 instanceof CancellableContinuationImpl)
      return value_0 === requester;
    else {
    }
    return true;
  };
  DispatchedContinuation.prototype.checkPostponedCancellation = function (continuation) {
    var tmp0_loop_0 = this.__reusableCancellableContinuation;
    while (true) {
      var tmp1__anonymous__1 = tmp0_loop_0._value_0;
      if (tmp1__anonymous__1 === REUSABLE_CLAIMED) {
        if (this.__reusableCancellableContinuation.atomicfu$compareAndSet(REUSABLE_CLAIMED, continuation))
          return null;
      } else {
        if (tmp1__anonymous__1 === null)
          return null;
        else {
          if (tmp1__anonymous__1 instanceof Error) {
            var tmp0_require_0_2 = this.__reusableCancellableContinuation.atomicfu$compareAndSet(tmp1__anonymous__1, null);
            if (!tmp0_require_0_2) {
              var message_1_1_3 = 'Failed requirement.';
              throw IllegalArgumentException_init_$Create$_0(toString_2(message_1_1_3));
            }return tmp1__anonymous__1;
          } else {
            {
              var tmp1_error_0_4 = '' + 'Inconsistent state ' + tmp1__anonymous__1;
              throw IllegalStateException_init_$Create$_0(toString_2(tmp1_error_0_4));
            }
          }
        }
      }
    }
    Unit_getInstance();
  };
  DispatchedContinuation.prototype.postponeCancellation = function (cause) {
    var tmp0_loop_0 = this.__reusableCancellableContinuation;
    while (true) {
      var tmp1__anonymous__1 = tmp0_loop_0._value_0;
      var tmp0_subject_2 = tmp1__anonymous__1;
      if (equals_0(tmp0_subject_2, REUSABLE_CLAIMED)) {
        if (this.__reusableCancellableContinuation.atomicfu$compareAndSet(REUSABLE_CLAIMED, cause))
          return true;
      } else {
        if (tmp0_subject_2 instanceof Error)
          return true;
        else {
          {
            if (this.__reusableCancellableContinuation.atomicfu$compareAndSet(tmp1__anonymous__1, null))
              return false;
          }
        }
      }
    }
    Unit_getInstance();
  };
  DispatchedContinuation.prototype.takeState_2 = function () {
    var state = this.__state_2;
    this.__state_2 = UNDEFINED;
    return state;
  };
  DispatchedContinuation.prototype._get_delegate__2 = function () {
    return this;
  };
  DispatchedContinuation.prototype.resumeWith_21 = function (result) {
    var context = this._continuation_2._get_context__31();
    var state = toState$default(result, null, 1, null);
    if (this._dispatcher.isDispatchNeeded_9(context)) {
      this.__state_2 = state;
      this._set_resumeMode__0(0);
      this._dispatcher.dispatch_9(context, this);
    } else {
      var tmp$ret$0;
      l$ret$1: do {
        var tmp0_executeUnconfined_0 = 0;
        var tmp1_executeUnconfined_0 = false;
        var eventLoop_2 = ThreadLocalEventLoop_getInstance()._get_eventLoop_();
        if (tmp1_executeUnconfined_0 ? eventLoop_2._get_isUnconfinedQueueEmpty__0() : false) {
          tmp$ret$0 = false;
          break l$ret$1;
        }var tmp;
        if (eventLoop_2._get_isUnconfinedLoopActive__0()) {
          this.__state_2 = state;
          this._set_resumeMode__0(tmp0_executeUnconfined_0);
          eventLoop_2.dispatchUnconfined_0(this);
          tmp = true;
        } else {
          eventLoop_2.incrementUseCount_0(true);
          try {
            var tmp0_withCoroutineContext_0_4 = this._get_context__31();
            var tmp1_withCoroutineContext_0_5 = this._countOrElement;
            this._continuation_2.resumeWith_21(result);
            $l$break: while (true) {
              if (!eventLoop_2.processUnconfinedEvent_0())
                break $l$break;
            }
          } catch ($p) {
            if ($p instanceof Error) {
              this.handleFatalException_2($p, null);
            } else {
              {
                throw $p;
              }
            }
          }
          finally {
            eventLoop_2.decrementUseCount_0(true);
          }
          tmp = false;
        }
        tmp$ret$0 = tmp;
      }
       while (false);
      Unit_getInstance();
    }
  };
  DispatchedContinuation.prototype.cancelCompletedResult_2 = function (takenState, cause) {
    if (takenState instanceof CompletedWithCancellation) {
      takenState._onCancellation_0(cause);
    } else {
    }
  };
  DispatchedContinuation.prototype.toString = function () {
    return '' + 'DispatchedContinuation[' + this._dispatcher + ', ' + toDebugString(this._continuation_2) + ']';
  };
  DispatchedContinuation.$metadata$ = {
    simpleName: 'DispatchedContinuation',
    kind: 'class',
    interfaces: [CoroutineStackFrame, Continuation]
  };
  function DispatchedTask(resumeMode) {
    SchedulerTask.call(this);
    this._resumeMode = resumeMode;
  }
  DispatchedTask.prototype._set_resumeMode__0 = function (_set___) {
    this._resumeMode = _set___;
  };
  DispatchedTask.prototype._get_resumeMode__1 = function () {
    return this._resumeMode;
  };
  DispatchedTask.prototype.cancelCompletedResult_2 = function (takenState, cause) {
  };
  DispatchedTask.prototype.getSuccessfulResult_2 = function (state) {
    return (state == null ? true : isObject(state)) ? state : THROW_CCE();
  };
  DispatchedTask.prototype.getExceptionalResult_2 = function (state) {
    var tmp0_safe_receiver = state instanceof CompletedExceptionally ? state : null;
    return tmp0_safe_receiver == null ? null : tmp0_safe_receiver._cause;
  };
  DispatchedTask.prototype.run_4 = function () {
    var taskContext = _get_taskContext_(this);
    var fatalException = null;
    try {
      var tmp = this._get_delegate__2();
      var delegate = tmp instanceof DispatchedContinuation ? tmp : THROW_CCE();
      var continuation = delegate._continuation_2;
      var tmp0_withContinuationContext_0 = delegate._countOrElement;
      var context_2 = continuation._get_context__31();
      var state_3 = this.takeState_2();
      var exception_4 = this.getExceptionalResult_2(state_3);
      var job_5 = (exception_4 == null ? _get_isCancellableMode_(this._resumeMode) : false) ? context_2.get_62(Key_getInstance_2()) : null;
      if (!(job_5 == null) ? !job_5._get_isActive__23() : false) {
        var cause_6 = job_5.getCancellationException_10();
        this.cancelCompletedResult_2(state_3, cause_6);
        var tmp0_failure_0_7 = Companion_getInstance_2();
        var tmp1_failure_0_8 = recoverStackTrace(cause_6, continuation);
        continuation.resumeWith_21(_Result___init__impl_(createFailure(tmp1_failure_0_8)));
      } else {
        if (!(exception_4 == null)) {
          var tmp0_failure_0_1_9 = Companion_getInstance_2();
          continuation.resumeWith_21(_Result___init__impl_(createFailure(exception_4)));
        } else {
          var tmp2_resume_0_10 = this.getSuccessfulResult_2(state_3);
          var tmp0_success_0_1_11 = Companion_getInstance_2();
          continuation.resumeWith_21(_Result___init__impl_(tmp2_resume_0_10));
        }
      }
    } catch ($p) {
      if ($p instanceof Error) {
        fatalException = $p;
      } else {
        {
          throw $p;
        }
      }
    }
    finally {
      var tmp_0;
      try {
        var tmp0_success_0_1 = Companion_getInstance_2();
        var tmp1_success_0_2 = Unit_getInstance();
        tmp_0 = _Result___init__impl_(Unit_getInstance());
      } catch ($p_0) {
        var tmp_1;
        if ($p_0 instanceof Error) {
          var tmp2_failure_0_4 = Companion_getInstance_2();
          tmp_1 = _Result___init__impl_(createFailure($p_0));
        } else {
          {
            throw $p_0;
          }
        }
        tmp_0 = tmp_1;
      }
      var result = tmp_0;
      this.handleFatalException_2(fatalException, Result__exceptionOrNull_impl(result));
    }
  };
  DispatchedTask.prototype.handleFatalException_2 = function (exception, finallyException) {
    if (exception === null ? finallyException === null : false)
      return Unit_getInstance();
    if (!(exception === null) ? !(finallyException === null) : false) {
    }var tmp0_elvis_lhs = exception;
    var cause = tmp0_elvis_lhs == null ? finallyException : tmp0_elvis_lhs;
    var reason = new CoroutinesInternalError('' + 'Fatal exception in coroutines machinery for ' + this + '. ' + "Please read KDoc to 'handleFatalException' method and report this incident to maintainers", ensureNotNull(cause));
    handleCoroutineException(this._get_delegate__2()._get_context__31(), reason);
  };
  DispatchedTask.$metadata$ = {
    simpleName: 'DispatchedTask',
    kind: 'class',
    interfaces: []
  };
  function _get_isReusableMode_(_this_) {
    return _this_ === 2;
  }
  function _get_isCancellableMode_(_this_) {
    return _this_ === 1 ? true : _this_ === 2;
  }
  function dispatch(_this_, mode) {
    var delegate = _this_._get_delegate__2();
    var undispatched = mode === 4;
    var tmp;
    var tmp_0;
    if (!undispatched) {
      tmp_0 = delegate instanceof DispatchedContinuation;
    } else {
      tmp_0 = false;
    }
    if (tmp_0) {
      tmp = _get_isCancellableMode_(mode) === _get_isCancellableMode_(_this_._resumeMode);
    } else {
      {
        tmp = false;
      }
    }
    if (tmp) {
      var dispatcher = delegate._dispatcher;
      var context = delegate._get_context__31();
      if (dispatcher.isDispatchNeeded_9(context)) {
        dispatcher.dispatch_9(context, _this_);
      } else {
        resumeUnconfined(_this_);
      }
    } else {
      {
        resume(_this_, delegate, undispatched);
      }
    }
  }
  function resumeUnconfined(_this_) {
    var eventLoop = ThreadLocalEventLoop_getInstance()._get_eventLoop_();
    if (eventLoop._get_isUnconfinedLoopActive__0()) {
      eventLoop.dispatchUnconfined_0(_this_);
    } else {
      eventLoop.incrementUseCount_0(true);
      try {
        resume(_this_, _this_._get_delegate__2(), true);
        $l$break: while (true) {
          if (!eventLoop.processUnconfinedEvent_0())
            break $l$break;
        }
      } catch ($p) {
        if ($p instanceof Error) {
          _this_.handleFatalException_2($p, null);
        } else {
          {
            throw $p;
          }
        }
      }
      finally {
        eventLoop.decrementUseCount_0(true);
      }
    }
  }
  function resume(_this_, delegate, undispatched) {
    var state = _this_.takeState_2();
    var exception = _this_.getExceptionalResult_2(state);
    var tmp;
    if (!(exception == null)) {
      var tmp0_failure_0 = Companion_getInstance_2();
      tmp = _Result___init__impl_(createFailure(exception));
    } else {
      var tmp1_success_0 = Companion_getInstance_2();
      var tmp2_success_0 = _this_.getSuccessfulResult_2(state);
      tmp = _Result___init__impl_(tmp2_success_0);
    }
    var result = tmp;
    if (undispatched) {
      var tmp3_resumeUndispatchedWith_0 = delegate instanceof DispatchedContinuation ? delegate : THROW_CCE();
      var tmp0_withContinuationContext_0_1 = tmp3_resumeUndispatchedWith_0._continuation_2;
      var tmp1_withContinuationContext_0_2 = tmp3_resumeUndispatchedWith_0._countOrElement;
      tmp3_resumeUndispatchedWith_0._continuation_2.resumeWith_21(result);
    } else
      delegate.resumeWith_21(result);
  }
  function ContextScope(context) {
    this._coroutineContext = context;
  }
  ContextScope.prototype._get_coroutineContext__8 = function () {
    return this._coroutineContext;
  };
  ContextScope.prototype.toString = function () {
    return '' + 'CoroutineScope(coroutineContext=' + this._coroutineContext + ')';
  };
  ContextScope.$metadata$ = {
    simpleName: 'ContextScope',
    kind: 'class',
    interfaces: [CoroutineScope]
  };
  function ScopeCoroutine(context, uCont) {
    AbstractCoroutine.call(this, context, true);
    this._uCont = uCont;
  }
  ScopeCoroutine.prototype._get_uCont__1 = function () {
    return this._uCont;
  };
  ScopeCoroutine.prototype._get_isScopedCoroutine__9 = function () {
    return true;
  };
  ScopeCoroutine.prototype.afterCompletion_9 = function (state) {
    var tmp = intercepted(this._uCont);
    var tmp_0 = recoverResult(state, this._uCont);
    resumeCancellableWith$default(tmp, tmp_0, null, 2, null);
  };
  ScopeCoroutine.prototype.afterResume_6 = function (state) {
    this._uCont.resumeWith_21(recoverResult(state, this._uCont));
  };
  ScopeCoroutine.$metadata$ = {
    simpleName: 'ScopeCoroutine',
    kind: 'class',
    interfaces: [CoroutineStackFrame]
  };
  function Symbol(symbol) {
    this._symbol = symbol;
  }
  Symbol.prototype.toString = function () {
    return '' + '<' + this._symbol + '>';
  };
  Symbol.$metadata$ = {
    simpleName: 'Symbol',
    kind: 'class',
    interfaces: []
  };
  function startCoroutineCancellable(_this_, receiver, completion, onCancellation) {
    var tmp;
    try {
      var tmp_0 = intercepted(createCoroutineUnintercepted(_this_, receiver, completion));
      var tmp0_success_0_3 = Companion_getInstance_2();
      var tmp1_success_0_4 = Unit_getInstance();
      tmp = resumeCancellableWith(tmp_0, _Result___init__impl_(Unit_getInstance()), onCancellation);
    } catch ($p) {
      var tmp_1;
      if ($p instanceof Error) {
        var tmp0_failure_0 = Companion_getInstance_2();
        tmp_1 = completion.resumeWith_21(_Result___init__impl_(createFailure($p)));
      } else {
        {
          throw $p;
        }
      }
      tmp = tmp_1;
    }
    return tmp;
  }
  function startCoroutineCancellable$default(_this_, receiver, completion, onCancellation, $mask0, $handler) {
    if (!(($mask0 & 4) === 0))
      onCancellation = null;
    return startCoroutineCancellable(_this_, receiver, completion, onCancellation);
  }
  function startCoroutineCancellable_0(_this_, fatalCompletion) {
    var tmp;
    try {
      var tmp_0 = intercepted(_this_);
      var tmp0_success_0_4 = Companion_getInstance_2();
      var tmp1_success_0_5 = Unit_getInstance();
      var tmp_1 = _Result___init__impl_(Unit_getInstance());
      tmp = resumeCancellableWith$default(tmp_0, tmp_1, null, 2, null);
    } catch ($p) {
      var tmp_2;
      if ($p instanceof Error) {
        var tmp0_failure_0_2 = Companion_getInstance_2();
        tmp_2 = fatalCompletion.resumeWith_21(_Result___init__impl_(createFailure($p)));
      } else {
        {
          throw $p;
        }
      }
      tmp = tmp_2;
    }
    return tmp;
  }
  function startUndispatchedOrReturn(_this_, receiver, block) {
    _this_.initParentJob_6();
    var tmp$ret$0;
    l$ret$1: do {
      var tmp;
      try {
        tmp = block(receiver, _this_);
      } catch ($p) {
        var tmp_0;
        if ($p instanceof Error) {
          var tmp_1 = $p;
          tmp_0 = CompletedExceptionally_init_$Create$(tmp_1, false, 2, null);
        } else {
          {
            throw $p;
          }
        }
        tmp = tmp_0;
      }
      var result_1 = tmp;
      if (result_1 === _get_COROUTINE_SUSPENDED_()) {
        tmp$ret$0 = _get_COROUTINE_SUSPENDED_();
        break l$ret$1;
      }var state_3 = _this_.makeCompletingOnce_7(result_1);
      if (state_3 === COMPLETING_WAITING_CHILDREN) {
        tmp$ret$0 = _get_COROUTINE_SUSPENDED_();
        break l$ret$1;
      }var tmp_2;
      if (state_3 instanceof CompletedExceptionally) {
        var tmp_3;
        var tmp0__anonymous__5 = state_3._cause;
        {
          throw recoverStackTrace(state_3._cause, _this_._uCont);
        }
        tmp_2 = tmp_3;
      } else {
        {
          tmp_2 = unboxState(state_3);
        }
      }
      tmp$ret$0 = tmp_2;
    }
     while (false);
    return tmp$ret$0;
  }
  function startCoroutineUndispatched(_this_, receiver, completion) {
    var tmp$ret$0;
    l$ret$1: do {
      var actualCompletion_1 = completion;
      var tmp;
      try {
        var tmp0_withCoroutineContext_0_8 = completion._get_context__31();
        var tmp1_withCoroutineContext_0_9 = null;
        tmp = _this_(receiver, actualCompletion_1);
      } catch ($p) {
        var tmp_0;
        if ($p instanceof Error) {
          var tmp0_failure_0_1_4 = Companion_getInstance_2();
          actualCompletion_1.resumeWith_21(_Result___init__impl_(createFailure($p)));
          tmp$ret$0 = Unit_getInstance();
          break l$ret$1;
        } else {
          {
            throw $p;
          }
        }
        tmp = tmp_0;
      }
      var value_2 = tmp;
      if (!(value_2 === _get_COROUTINE_SUSPENDED_())) {
        var tmp0_resume_0_5 = (value_2 == null ? true : isObject(value_2)) ? value_2 : THROW_CCE();
        var tmp0_success_0_1_6 = Companion_getInstance_2();
        actualCompletion_1.resumeWith_21(_Result___init__impl_(tmp0_resume_0_5));
      }}
     while (false);
  }
  function SelectClause0() {
  }
  SelectClause0.$metadata$ = {
    simpleName: 'SelectClause0',
    kind: 'interface',
    interfaces: []
  };
  function SelectClause1() {
  }
  SelectClause1.$metadata$ = {
    simpleName: 'SelectClause1',
    kind: 'interface',
    interfaces: []
  };
  function CompletionHandlerBase() {
    LinkedListNode.call(this);
  }
  CompletionHandlerBase.$metadata$ = {
    simpleName: 'CompletionHandlerBase',
    kind: 'class',
    interfaces: []
  };
  function invokeIt(_this_, cause) {
    var tmp0_subject = typeof _this_;
    if (tmp0_subject === 'function')
      _this_(cause);
    else {
      _this_.invoke(cause);
    }
  }
  function CancelHandlerBase() {
  }
  CancelHandlerBase.$metadata$ = {
    simpleName: 'CancelHandlerBase',
    kind: 'class',
    interfaces: []
  };
  function toDebugString(_this_) {
    return toString_2(_this_);
  }
  function newCoroutineContext(_this_, context) {
    var combined = _this_._get_coroutineContext__8().plus_43(context);
    return (!(combined === Dispatchers_getInstance()._Default) ? combined.get_62(Key_getInstance()) == null : false) ? combined.plus_43(Dispatchers_getInstance()._Default) : combined;
  }
  function UndispatchedCoroutine(context, uCont) {
    ScopeCoroutine.call(this, context, uCont);
  }
  UndispatchedCoroutine.prototype.afterResume_6 = function (state) {
    return this._get_uCont__1().resumeWith_21(recoverResult(state, this._get_uCont__1()));
  };
  UndispatchedCoroutine.$metadata$ = {
    simpleName: 'UndispatchedCoroutine',
    kind: 'class',
    interfaces: []
  };
  function createDefaultDispatcher() {
    var tmp;
    if ((!(typeof navigator === 'undefined') ? navigator != null : false) ? navigator.product == 'ReactNative' : false) {
      tmp = NodeDispatcher_getInstance();
    } else {
      if (isJsdom()) {
        tmp = NodeDispatcher_getInstance();
      } else {
        var tmp_0;
        var tmp_1;
        if (!(typeof window === 'undefined')) {
          var tmp0_asDynamic_0 = window;
          tmp_1 = tmp0_asDynamic_0 != null;
        } else {
          tmp_1 = false;
        }
        if (tmp_1) {
          var tmp1_asDynamic_0 = window;
          tmp_0 = !(typeof tmp1_asDynamic_0.addEventListener === 'undefined');
        } else {
          {
            tmp_0 = false;
          }
        }
        if (tmp_0) {
          tmp = asCoroutineDispatcher(window);
        } else {
          if (typeof process === 'undefined') {
            tmp = SetTimeoutDispatcher_getInstance();
          } else {
            {
              tmp = NodeDispatcher_getInstance();
            }
          }
        }
      }
    }
    return tmp;
  }
  function isJsdom() {
    return ((((!(typeof navigator === 'undefined') ? navigator != null : false) ? navigator.userAgent != null : false) ? !(typeof navigator.userAgent === 'undefined') : false) ? !(typeof navigator.userAgent.match === 'undefined') : false) ? navigator.userAgent.match('\\bjsdom\\b') : false;
  }
  function _get_coroutineName_(_this_) {
    return null;
  }
  function handleCoroutineExceptionImpl(context, exception) {
    (function () {
      var $externalVarargReceiverTmp = console;
      return $externalVarargReceiverTmp.error.apply($externalVarargReceiverTmp, [].concat([exception]));
    }.call(this));
  }
  var counter;
  var DEBUG;
  function _get_classSimpleName_(_this_) {
    var tmp0_elvis_lhs = getKClassFromExpression_0(_this_)._get_simpleName__4();
    return tmp0_elvis_lhs == null ? 'Unknown' : tmp0_elvis_lhs;
  }
  function _get_hexAddress_(_this_) {
    var result = _this_.__debug_counter;
    if (!(typeof result === 'number')) {
      counter = counter + 1 | 0;
      result = counter;
      _this_.__debug_counter = result;
    }return ((!(result == null) ? typeof result === 'number' : false) ? result : THROW_CCE()).toString();
  }
  function Dispatchers() {
    Dispatchers_instance = this;
    this._Default = createDefaultDispatcher();
    this._Main = new JsMainDispatcher(this._Default, false);
    this._Unconfined = Unconfined_getInstance();
  }
  Dispatchers.$metadata$ = {
    simpleName: 'Dispatchers',
    kind: 'object',
    interfaces: []
  };
  var Dispatchers_instance;
  function Dispatchers_getInstance() {
    if (Dispatchers_instance == null)
      new Dispatchers();
    return Dispatchers_instance;
  }
  function JsMainDispatcher(delegate, invokeImmediately) {
    MainCoroutineDispatcher.call(this);
    this._delegate_1 = delegate;
    this._invokeImmediately = invokeImmediately;
    this._immediate = this._invokeImmediately ? this : new JsMainDispatcher(this._delegate_1, true);
  }
  JsMainDispatcher.prototype._get_immediate__0 = function () {
    return this._immediate;
  };
  JsMainDispatcher.prototype.isDispatchNeeded_9 = function (context) {
    return !this._invokeImmediately;
  };
  JsMainDispatcher.prototype.dispatch_9 = function (context, block) {
    return this._delegate_1.dispatch_9(context, block);
  };
  JsMainDispatcher.prototype.toString = function () {
    var tmp0_elvis_lhs = this.toStringInternalImpl_0();
    return tmp0_elvis_lhs == null ? this._delegate_1.toString() : tmp0_elvis_lhs;
  };
  JsMainDispatcher.$metadata$ = {
    simpleName: 'JsMainDispatcher',
    kind: 'class',
    interfaces: []
  };
  function createEventLoop() {
    return new UnconfinedEventLoop();
  }
  function UnconfinedEventLoop() {
    EventLoop.call(this);
  }
  UnconfinedEventLoop.prototype.dispatch_9 = function (context, block) {
    unsupported();
  };
  UnconfinedEventLoop.$metadata$ = {
    simpleName: 'UnconfinedEventLoop',
    kind: 'class',
    interfaces: []
  };
  function unsupported() {
    throw UnsupportedOperationException_init_$Create$_0('runBlocking event loop is not supported');
  }
  function JobCancellationException(message, cause, job) {
    CancellationException.call(this, message, cause);
    this._job_1 = job;
    captureStack(this, JobCancellationException);
  }
  JobCancellationException.prototype.toString = function () {
    return '' + CancellationException.prototype.toString.call(this) + '; job=' + this._job_1;
  };
  JobCancellationException.prototype.equals = function (other) {
    var tmp;
    if (other === this) {
      tmp = true;
    } else {
      var tmp_0;
      var tmp_1;
      var tmp_2;
      if (other instanceof JobCancellationException) {
        tmp_2 = other.message == this.message;
      } else {
        {
          tmp_2 = false;
        }
      }
      if (tmp_2) {
        tmp_1 = equals_0(other._job_1, this._job_1);
      } else {
        {
          tmp_1 = false;
        }
      }
      if (tmp_1) {
        tmp_0 = equals_0(other.cause, this.cause);
      } else {
        {
          tmp_0 = false;
        }
      }
      tmp = tmp_0;
    }
    return tmp;
  };
  JobCancellationException.prototype.hashCode = function () {
    var tmp = imul(imul(getStringHashCode(ensureNotNull(this.message)), 31) + hashCode(this._job_1) | 0, 31);
    var tmp0_safe_receiver = this.cause;
    var tmp1_elvis_lhs = tmp0_safe_receiver == null ? null : hashCode(tmp0_safe_receiver);
    return tmp + (tmp1_elvis_lhs == null ? 0 : tmp1_elvis_lhs) | 0;
  };
  JobCancellationException.$metadata$ = {
    simpleName: 'JobCancellationException',
    kind: 'class',
    interfaces: []
  };
  function CancellationException_init_$Init$(message, $this) {
    CancellationException.call($this, message, null);
    return $this;
  }
  function CancellationException_init_$Create$(message) {
    var tmp = CancellationException_init_$Init$(message, Object.create(CancellationException.prototype));
    captureStack(tmp, CancellationException_init_$Create$);
    return tmp;
  }
  function CancellationException(message, cause) {
    IllegalStateException_init_$Init$_1(message, cause, this);
    captureStack(this, CancellationException);
  }
  CancellationException.$metadata$ = {
    simpleName: 'CancellationException',
    kind: 'class',
    interfaces: []
  };
  function NodeDispatcher() {
    NodeDispatcher_instance = this;
    SetTimeoutBasedDispatcher.call(this);
  }
  NodeDispatcher.prototype.scheduleQueueProcessing_1 = function () {
    process.nextTick(this._get_messageQueue__1()._processQueue);
  };
  NodeDispatcher.$metadata$ = {
    simpleName: 'NodeDispatcher',
    kind: 'object',
    interfaces: []
  };
  var NodeDispatcher_instance;
  function NodeDispatcher_getInstance() {
    if (NodeDispatcher_instance == null)
      new NodeDispatcher();
    return NodeDispatcher_instance;
  }
  function SetTimeoutDispatcher() {
    SetTimeoutDispatcher_instance = this;
    SetTimeoutBasedDispatcher.call(this);
  }
  SetTimeoutDispatcher.prototype.scheduleQueueProcessing_1 = function () {
    setTimeout(this._get_messageQueue__1()._processQueue, 0);
    Unit_getInstance();
  };
  SetTimeoutDispatcher.$metadata$ = {
    simpleName: 'SetTimeoutDispatcher',
    kind: 'object',
    interfaces: []
  };
  var SetTimeoutDispatcher_instance;
  function SetTimeoutDispatcher_getInstance() {
    if (SetTimeoutDispatcher_instance == null)
      new SetTimeoutDispatcher();
    return SetTimeoutDispatcher_instance;
  }
  function _no_name_provided__64(this$0) {
    this._this$0_15 = this$0;
  }
  _no_name_provided__64.prototype.invoke_422 = function () {
    this._this$0_15.process_1();
  };
  _no_name_provided__64.prototype.invoke_523 = function () {
    this.invoke_422();
    return Unit_getInstance();
  };
  _no_name_provided__64.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function ScheduledMessageQueue($outer) {
    this._$this_6 = $outer;
    MessageQueue.call(this);
    var tmp = this;
    tmp._processQueue = _no_name_provided_$factory_45(this);
  }
  ScheduledMessageQueue.prototype.schedule_1 = function () {
    this._$this_6.scheduleQueueProcessing_1();
  };
  ScheduledMessageQueue.prototype.reschedule_1 = function () {
    setTimeout(this._processQueue, 0);
    Unit_getInstance();
  };
  ScheduledMessageQueue.$metadata$ = {
    simpleName: 'ScheduledMessageQueue',
    kind: 'class',
    interfaces: []
  };
  function SetTimeoutBasedDispatcher() {
    CoroutineDispatcher.call(this);
    this._messageQueue = new ScheduledMessageQueue(this);
  }
  SetTimeoutBasedDispatcher.prototype._get_messageQueue__1 = function () {
    return this._messageQueue;
  };
  SetTimeoutBasedDispatcher.prototype.dispatch_9 = function (context, block) {
    this._messageQueue.enqueue_1(block);
  };
  SetTimeoutBasedDispatcher.$metadata$ = {
    simpleName: 'SetTimeoutBasedDispatcher',
    kind: 'class',
    interfaces: [Delay]
  };
  function MessageQueue() {
    ArrayQueue.call(this);
    this._yieldEvery = 16;
    this._scheduled = false;
  }
  MessageQueue.prototype.enqueue_1 = function (element) {
    this.addLast_8(element);
    if (!this._scheduled) {
      this._scheduled = true;
      this.schedule_1();
    }};
  MessageQueue.prototype.process_1 = function () {
    try {
      var tmp0_repeat_0 = this._yieldEvery;
      var inductionVariable = 0;
      if (inductionVariable < tmp0_repeat_0)
        do {
          var index_2 = inductionVariable;
          inductionVariable = inductionVariable + 1 | 0;
          var tmp0_elvis_lhs_5 = this.removeFirstOrNull_2();
          var tmp;
          if (tmp0_elvis_lhs_5 == null) {
            return Unit_getInstance();
          } else {
            tmp = tmp0_elvis_lhs_5;
          }
          var element_4 = tmp;
          element_4.run_4();
        }
         while (inductionVariable < tmp0_repeat_0);
    }finally {
      if (this._get_isEmpty__3()) {
        this._scheduled = false;
      } else {
        this.reschedule_1();
      }
    }
  };
  MessageQueue.$metadata$ = {
    simpleName: 'MessageQueue',
    kind: 'class',
    interfaces: []
  };
  function WindowDispatcher(window_0) {
    CoroutineDispatcher.call(this);
    this._window = window_0;
    this._queue = new WindowMessageQueue(this._window);
  }
  WindowDispatcher.prototype.dispatch_9 = function (context, block) {
    return this._queue.enqueue_1(block);
  };
  WindowDispatcher.$metadata$ = {
    simpleName: 'WindowDispatcher',
    kind: 'class',
    interfaces: [Delay]
  };
  function _no_name_provided__65(this$0) {
    this._this$0_16 = this$0;
  }
  _no_name_provided__65.prototype.invoke_101 = function (event) {
    if (event.source == this._this$0_16._window_0 ? event.data == this._this$0_16._messageName : false) {
      event.stopPropagation();
      this._this$0_16.process_1();
    }};
  _no_name_provided__65.prototype.invoke_1115 = function (p1) {
    this.invoke_101((p1 == null ? true : p1) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__65.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__66(this$0) {
    this._this$0_17 = this$0;
  }
  _no_name_provided__66.prototype.invoke_383 = function (it) {
    this._this$0_17.process_1();
  };
  _no_name_provided__66.prototype.invoke_1115 = function (p1) {
    this.invoke_383(p1 instanceof Unit ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__66.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function WindowMessageQueue(window_0) {
    MessageQueue.call(this);
    this._window_0 = window_0;
    this._messageName = 'dispatchCoroutine';
    this._window_0.addEventListener('message', _no_name_provided_$factory_46(this), true);
  }
  WindowMessageQueue.prototype.schedule_1 = function () {
    var tmp = Promise.resolve(Unit_getInstance());
    tmp.then(_no_name_provided_$factory_47(this));
    Unit_getInstance();
  };
  WindowMessageQueue.prototype.reschedule_1 = function () {
    this._window_0.postMessage(this._messageName, '*');
  };
  WindowMessageQueue.$metadata$ = {
    simpleName: 'WindowMessageQueue',
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided_$factory_45(this$0) {
    var i = new _no_name_provided__64(this$0);
    return function () {
      i.invoke_422();
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_46(this$0) {
    var i = new _no_name_provided__65(this$0);
    return function (p1) {
      i.invoke_101(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_47(this$0) {
    var i = new _no_name_provided__66(this$0);
    return function (p1) {
      i.invoke_383(p1);
      return Unit_getInstance();
    };
  }
  function Runnable() {
  }
  Runnable.$metadata$ = {
    simpleName: 'Runnable',
    kind: 'interface',
    interfaces: []
  };
  function SchedulerTask() {
  }
  SchedulerTask.$metadata$ = {
    simpleName: 'SchedulerTask',
    kind: 'class',
    interfaces: [Runnable]
  };
  function _get_taskContext_(_this_) {
    return Unit_getInstance();
  }
  function asCoroutineDispatcher(_this_) {
    var tmp0_elvis_lhs = _this_.coroutineDispatcher;
    var tmp;
    if (tmp0_elvis_lhs == null) {
      var tmp0_also_0 = new WindowDispatcher(_this_);
      _this_.coroutineDispatcher = tmp0_also_0;
      tmp = tmp0_also_0;
    } else {
      tmp = tmp0_elvis_lhs;
    }
    return tmp;
  }
  function identitySet(expectedSize) {
    return HashSet_init_$Create$_0(expectedSize);
  }
  function LinkedListHead() {
    LinkedListNode.call(this);
  }
  LinkedListHead.$metadata$ = {
    simpleName: 'LinkedListHead',
    kind: 'class',
    interfaces: []
  };
  function LinkedListNode() {
    this.__next = this;
    this.__prev = this;
    this.__removed = false;
  }
  LinkedListNode.prototype._get__next__1 = function () {
    return this.__next;
  };
  LinkedListNode.prototype.addLast_14 = function (node_0) {
    var prev = this.__prev;
    node_0.__next = this;
    node_0.__prev = prev;
    prev.__next = node_0;
    this.__prev = node_0;
  };
  LinkedListNode.prototype.remove_38 = function () {
    if (this.__removed)
      return false;
    var prev = this.__prev;
    var next = this.__next;
    prev.__next = next;
    next.__prev = prev;
    this.__removed = true;
    return true;
  };
  LinkedListNode.prototype.addOneIfEmpty_7 = function (node_0) {
    if (!(this.__next === this))
      return false;
    this.addLast_14(node_0);
    return true;
  };
  LinkedListNode.$metadata$ = {
    simpleName: 'LinkedListNode',
    kind: 'class',
    interfaces: []
  };
  function unwrap(exception) {
    return exception;
  }
  function CoroutineStackFrame() {
  }
  CoroutineStackFrame.$metadata$ = {
    simpleName: 'CoroutineStackFrame',
    kind: 'interface',
    interfaces: []
  };
  function recoverStackTrace(exception, continuation) {
    return exception;
  }
  function threadContextElements(context) {
    return 0;
  }
  function CommonThreadLocal() {
    this._value_4 = null;
  }
  CommonThreadLocal.prototype.get_60 = function () {
    var tmp = this._value_4;
    return (tmp == null ? true : isObject(tmp)) ? tmp : THROW_CCE();
  };
  CommonThreadLocal.prototype.set_4 = function (value_0) {
    this._value_4 = value_0;
  };
  CommonThreadLocal.$metadata$ = {
    simpleName: 'CommonThreadLocal',
    kind: 'class',
    interfaces: []
  };
  function ch($this, x, y, z) {
    return x & y | ~x & z;
  }
  function maj($this, x, y, z) {
    return x & y | x & z | y & z;
  }
  function bigSig0($this, x) {
    return rotateRight_0(x, 2) ^ rotateRight_0(x, 13) ^ rotateRight_0(x, 22);
  }
  function bigSig1($this, x) {
    return rotateRight_0(x, 6) ^ rotateRight_0(x, 11) ^ rotateRight_0(x, 25);
  }
  function smallSig0($this, x) {
    return rotateRight_0(x, 7) ^ rotateRight_0(x, 18) ^ x >>> 3;
  }
  function smallSig1($this, x) {
    return rotateRight_0(x, 17) ^ rotateRight_0(x, 19) ^ x >>> 10;
  }
  function Sha256() {
    Sha256_instance = this;
    var tmp = this;
    var tmp0_intArrayOf_0 = new Int32Array([1116352408, 1899447441, -1245643825, -373957723, 961987163, 1508970993, -1841331548, -1424204075, -670586216, 310598401, 607225278, 1426881987, 1925078388, -2132889090, -1680079193, -1046744716, -459576895, -272742522, 264347078, 604807628, 770255983, 1249150122, 1555081692, 1996064986, -1740746414, -1473132947, -1341970488, -1084653625, -958395405, -710438585, 113926993, 338241895, 666307205, 773529912, 1294757372, 1396182291, 1695183700, 1986661051, -2117940946, -1838011259, -1564481375, -1474664885, -1035236496, -949202525, -778901479, -694614492, -200395387, 275423344, 430227734, 506948616, 659060556, 883997877, 958139571, 1322822218, 1537002063, 1747873779, 1955562222, 2024104815, -2067236844, -1933114872, -1866530822, -1538233109, -1090935817, -965641998]);
    tmp._K = tmp0_intArrayOf_0;
    var tmp_0 = this;
    var tmp0_intArrayOf_0_0 = new Int32Array([1779033703, -1150833019, 1013904242, -1521486534, 1359893119, -1694144372, 528734635, 1541459225]);
    tmp_0._H0 = tmp0_intArrayOf_0_0;
    this._W = new Int32Array(64);
    this._H = new Int32Array(8);
    this._TEMP = new Int32Array(8);
  }
  Sha256.prototype.digest = function (message) {
    copy_0(this._H0, 0, this._H, 0, this._H0.length);
    var words = toIntArray_0(this.padMessage(message));
    var i = 0;
    var n = words.length / 16 | 0;
    while (i < n) {
      copy_0(words, imul(i, 16), this._W, 0, 16);
      var inductionVariable = 16;
      var last_2 = this._W.length;
      if (inductionVariable < last_2)
        do {
          var t = inductionVariable;
          inductionVariable = inductionVariable + 1 | 0;
          this._W[t] = ((smallSig1(this, this._W[t - 2 | 0]) + this._W[t - 7 | 0] | 0) + smallSig0(this, this._W[t - 15 | 0]) | 0) + this._W[t - 16 | 0] | 0;
        }
         while (inductionVariable < last_2);
      copy_0(this._H, 0, this._TEMP, 0, this._H.length);
      var inductionVariable_0 = 0;
      var last_3 = this._W.length - 1 | 0;
      if (inductionVariable_0 <= last_3)
        do {
          var t_0 = inductionVariable_0;
          inductionVariable_0 = inductionVariable_0 + 1 | 0;
          var t1 = (((this._TEMP[7] + bigSig1(this, this._TEMP[4]) | 0) + ch(this, this._TEMP[4], this._TEMP[5], this._TEMP[6]) | 0) + this._K[t_0] | 0) + this._W[t_0] | 0;
          var t2 = bigSig0(this, this._TEMP[0]) + maj(this, this._TEMP[0], this._TEMP[1], this._TEMP[2]) | 0;
          copy_0(this._TEMP, 0, this._TEMP, 1, this._TEMP.length - 1 | 0);
          var tmp2_array = this._TEMP;
          var tmp3_index0 = 4;
          tmp2_array[tmp3_index0] = tmp2_array[tmp3_index0] + t1 | 0;
          this._TEMP[0] = t1 + t2 | 0;
        }
         while (inductionVariable_0 <= last_3);
      var inductionVariable_1 = 0;
      var last_4 = this._H.length - 1 | 0;
      if (inductionVariable_1 <= last_4)
        do {
          var t_1 = inductionVariable_1;
          inductionVariable_1 = inductionVariable_1 + 1 | 0;
          var tmp5_array = this._H;
          var tmp6_index0 = t_1;
          tmp5_array[tmp6_index0] = tmp5_array[tmp6_index0] + this._TEMP[t_1] | 0;
        }
         while (inductionVariable_1 <= last_4);
      i = i + 1 | 0;
      Unit_getInstance();
    }
    return toByteArray_0(this._H);
  };
  Sha256.prototype.padMessage = function (message) {
    var blockBits = 512;
    var blockBytes = blockBits / 8 | 0;
    var newMessageLength = (message.length + 1 | 0) + 8 | 0;
    var padBytes = blockBytes - newMessageLength % blockBytes | 0;
    newMessageLength = newMessageLength + padBytes | 0;
    var paddedMessage = new Int8Array(newMessageLength);
    copy(message, 0, paddedMessage, 0, message.length);
    paddedMessage[message.length] = -128;
    var lenPos = (message.length + 1 | 0) + padBytes | 0;
    putLong(paddedMessage, lenPos, numberToLong(message.length).times_40(new Long(8, 0)));
    return paddedMessage;
  };
  Sha256.$metadata$ = {
    simpleName: 'Sha256',
    kind: 'object',
    interfaces: []
  };
  var Sha256_instance;
  function Sha256_getInstance() {
    if (Sha256_instance == null)
      new Sha256();
    return Sha256_instance;
  }
  function toInt(_this_) {
    if (_this_.length < 4)
      throw NumberFormatException_init_$Create$('The array size is less than 4');
    return (((toUInt(_this_[0]) << 24) + (toUInt(_this_[1]) << 16) | 0) + (toUInt(_this_[2]) << 8) | 0) + (toUInt(_this_[3]) << 0) | 0;
  }
  function toIntArray_0(_this_) {
    if (!(_this_.length % 4 === 0)) {
      throw IllegalArgumentException_init_$Create$_0('Byte array length must be a multiple of 4');
    }var array = new Int32Array(_this_.length / 4 | 0);
    var inductionVariable = 0;
    var last_2 = array.length - 1 | 0;
    if (inductionVariable <= last_2)
      do {
        var i = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        var tmp0_arrayOf_0 = [_this_[imul(i, 4)], _this_[imul(i, 4) + 1 | 0], _this_[imul(i, 4) + 2 | 0], _this_[imul(i, 4) + 3 | 0]];
        var integer = tmp0_arrayOf_0;
        array[i] = toInt(integer);
      }
       while (inductionVariable <= last_2);
    return array;
  }
  function copy(_this_, srcPos, dest, destPos, length) {
    var tmp0_copyInto_0 = srcPos + length | 0;
    var tmp = _this_;
    arrayCopy_0(tmp, dest, destPos, srcPos, tmp0_copyInto_0);
    Unit_getInstance();
  }
  function putLong(_this_, offset, value_0) {
    var inductionVariable = 7;
    if (0 <= inductionVariable)
      do {
        var i = inductionVariable;
        inductionVariable = inductionVariable + -1 | 0;
        var tmp0_toUByte_0 = value_0.ushr_0(imul(i, 8));
        var temp = _UByte___init__impl_(tmp0_toUByte_0.toByte_4());
        var tmp = (offset + 7 | 0) - i | 0;
        _this_[tmp] = _UByte___get_data__impl_(temp);
      }
       while (0 <= inductionVariable);
  }
  function toUInt(_this_) {
    return _this_ < 0 ? (255 + _this_ | 0) + 1 | 0 : _this_;
  }
  function copy_0(_this_, srcPos, dest, destPos, length) {
    var tmp0_copyInto_0 = srcPos + length | 0;
    var tmp = _this_;
    arrayCopy_0(tmp, dest, destPos, srcPos, tmp0_copyInto_0);
    Unit_getInstance();
  }
  function toByteArray_0(_this_) {
    var array = new Int8Array(imul(_this_.length, 4));
    var inductionVariable = 0;
    var last_2 = _this_.length - 1 | 0;
    if (inductionVariable <= last_2)
      do {
        var i = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        var bytes = toBytes(_this_[i]);
        array[imul(i, 4)] = bytes[0];
        array[imul(i, 4) + 1 | 0] = bytes[1];
        array[imul(i, 4) + 2 | 0] = bytes[2];
        array[imul(i, 4) + 3 | 0] = bytes[3];
      }
       while (inductionVariable <= last_2);
    return array;
  }
  function rotateRight_0(_this_, distance) {
    return _this_ >>> distance | _this_ << (-distance | 0);
  }
  function toBytes(_this_) {
    var result = new Int8Array(4);
    result[0] = toByte(_this_ >> 24);
    result[1] = toByte(_this_ >> 16);
    result[2] = toByte(_this_ >> 8);
    result[3] = toByte(_this_);
    return toTypedArray(result);
  }
  function Bls12381() {
    Bls12381_instance = this;
    this._x = Companion_getInstance_13().parseString_0('D201000000010000', 16).times_39(-1);
    this._q = Companion_getInstance_13().parseString_0('1A0111EA397FE69A4B1BA7B6434BACD764774B84F38512BF6730D2A0F6B0F6241EABFFFEB153FFFFB9FEFFFFFFFFAAAB', 16);
    this._a_1 = _Fq___init__impl__0(this._q, 0);
    this._b_1 = _Fq___init__impl__0(this._q, 4);
    this._aTwist = Fq2_init_$Create$_0(this._q, 0, 0);
    this._bTwist = Fq2_init_$Create$_0(this._q, 4, 4);
    this._gx = _Fq___init__impl__1(this._q, Companion_getInstance_13().parseString_0('17F1D3A73197D7942695638C4FA9AC0FC3688C4F9774B905A14E3A3F171BAC586C55E83FF97A1AEFFB3AF00ADB22C6BB', 16));
    this._gy = _Fq___init__impl__1(this._q, Companion_getInstance_13().parseString_0('08B3F481E3AAA0F1A09E30ED741D8AE4FCF5E095D5D00AF600DB18CB2C04B3EDD03CC744A2888AE40CAA232946C5E7E1', 16));
    this._g2x = Fq2_init_$Create$_1(this._q, Companion_getInstance_13().parseString_0('352701069587466618187139116011060144890029952792775240219908644239793785735715026873347600343865175952761926303160', 10), Companion_getInstance_13().parseString_0('3059144344244213709971259814753781636986470325476647558659373206291635324768958432433509563104347017837885763365758', 10));
    this._g2y = Fq2_init_$Create$_1(this._q, Companion_getInstance_13().parseString_0('1985150602287291935568054521177171638300868978215655730859378665066344726373823718423869104263333984641494340347905', 10), Companion_getInstance_13().parseString_0('927553665492332455747201965776037880757740193453592970025027978793976877002675564980949289727957565575433344219582', 10));
    this._n = Companion_getInstance_13().parseString_0('73EDA753299D7D483339D80809A1D80553BDA402FFFE5BFEFFFFFFFF00000001', 16);
    this._h = Companion_getInstance_13().parseString_0('396C8C005555E1568C00AAAB0000AAAB', 16);
    this._h_eff = Companion_getInstance_13().parseString_0('BC69F08F2EE75B3584C6A0EA91B352888E2A8E9145AD7689986FF031508FFE1329C2F178731DB956D82BF015D1212B02EC0EC69D7477C1AE954CBC06689F6A359894C0ADEBBF6B4E8020005AAA95551', 16);
    this._k = BigInteger_init_$Create$_0(12);
    this._sqrtn3 = Companion_getInstance_13().parseString_0('1586958781458431025242759403266842894121773480562120986020912974854563298150952611241517463240701', 10);
    this._sqrt_n3m1o2 = Companion_getInstance_13().parseString_0('793479390729215512621379701633421447060886740281060493010456487427281649075476305620758731620350', 10);
  }
  Bls12381.$metadata$ = {
    simpleName: 'Bls12381',
    kind: 'object',
    interfaces: []
  };
  var Bls12381_instance;
  function Bls12381_getInstance() {
    if (Bls12381_instance == null)
      new Bls12381();
    return Bls12381_instance;
  }
  function Field() {
  }
  Field.$metadata$ = {
    simpleName: 'Field',
    kind: 'interface',
    interfaces: [Comparable]
  };
  function FieldCompanion() {
  }
  FieldCompanion.$metadata$ = {
    simpleName: 'FieldCompanion',
    kind: 'interface',
    interfaces: []
  };
  function div(_this_, other) {
    return _this_.divImp_4(other);
  }
  function pow(_this_, other) {
    return _this_.powImp_11(other);
  }
  function times(_this_, other) {
    return _this_.timesImp_4(other);
  }
  function plus_3(_this_, other) {
    return _this_.plusImp_4(other);
  }
  function unaryMinus(_this_) {
    return _this_.unaryMinusImp_5();
  }
  function minus_0(_this_, other) {
    return _this_.minusImp_4(other);
  }
  function pow_0(_this_, other) {
    return Fq__powImp_impl_1(_this_, other);
  }
  function not(_this_) {
    return Fq__invertImp_impl(_this_);
  }
  function plus_4(_this_, other) {
    var tmp = Fq__plusImp_impl(_this_, new Fq(other));
    return tmp instanceof Fq ? tmp._value_5 : THROW_CCE();
  }
  function times_0(_this_, other) {
    var tmp = Fq__timesImp_impl(_this_, new Fq(other));
    return tmp instanceof Fq ? tmp._value_5 : THROW_CCE();
  }
  function unaryMinus_0(_this_) {
    return Fq__unaryMinusImp_impl(_this_);
  }
  function minus_1(_this_, other) {
    var tmp = Fq__minusImp_impl(_this_, new Fq(other));
    return tmp instanceof Fq ? tmp._value_5 : THROW_CCE();
  }
  function modSqrt(_this_) {
    return Fq__modSqrtImp_impl(_this_);
  }
  function pow_1(_this_, other) {
    return Fq__powImp_impl(_this_, other);
  }
  function minus_2(_this_, other) {
    var tmp = _this_.minusImp_4(other);
    return tmp instanceof Fq2 ? tmp : THROW_CCE();
  }
  function times_1(_this_, other) {
    var tmp = _this_.timesImp_4(other);
    return tmp instanceof Fq2 ? tmp : THROW_CCE();
  }
  function plus_5(_this_, other) {
    var tmp = _this_.plusImp_4(other);
    return tmp instanceof Fq2 ? tmp : THROW_CCE();
  }
  function minus_3(_this_, other) {
    var tmp = _this_.minusImp_4(other);
    return tmp instanceof Fq6 ? tmp : THROW_CCE();
  }
  function times_2(_this_, other) {
    var tmp = _this_.timesImp_4(other);
    return tmp instanceof Fq6 ? tmp : THROW_CCE();
  }
  function unaryMinus_1(_this_) {
    var tmp = _this_.unaryMinusImp_5();
    return tmp instanceof Fq6 ? tmp : THROW_CCE();
  }
  function pow_2(_this_, other) {
    return _this_.powImp_12(other);
  }
  function unaryMinus_2(_this_) {
    var tmp = _this_.unaryMinusImp_5();
    return tmp instanceof Fq2 ? tmp : THROW_CCE();
  }
  function modSqrt_0(_this_) {
    return _this_.modSqrtImp_5();
  }
  function getCompanionFromClass(c) {
    if (c.equals(getKClass_0(Fq))) {
      return Companion_getInstance_15();
    } else if (c.equals(getKClass_0(Fq2))) {
      return Companion_getInstance_16();
    } else if (c.equals(getKClass_0(Fq6))) {
      return Companion_getInstance_17();
    } else if (c.equals(getKClass_0(Fq12))) {
      return Companion_getInstance_18();
    } else {
      throw Exception_init_$Create$();
    }
  }
  function _Fq___init__impl_(value_0) {
    return value_0;
  }
  function _Fq___get_value__impl_(this_0) {
    return this_0;
  }
  function _Fq___get_Q__impl_(this_0) {
    return _Fq___get_value__impl_(this_0)._modulus;
  }
  function _Fq___init__impl__0(Q, value_0) {
    var tmp = _Fq___init__impl_(BigInteger_init_$Create$_0(value_0).toModularBigInteger(Q));
    return tmp;
  }
  function _Fq___init__impl__1(Q, value_0) {
    var tmp = _Fq___init__impl_(value_0.toModularBigInteger(Q));
    return tmp;
  }
  function _Fq___get_extension__impl_(this_0) {
    return 1;
  }
  function Fq__plusImp_impl(this_0, other) {
    var tmp;
    if (other instanceof Fq) {
      tmp = new Fq(_Fq___init__impl_(_Fq___get_value__impl_(this_0).plus_4(_Fq___get_value__impl_(other._value_5))));
    } else {
      if (other instanceof FieldExt) {
        tmp = other.plusImp_4(new Fq(this_0));
      } else {
        {
          throw IllegalStateException_init_$Create$_0('UNSUPPORTED FBASE');
        }
      }
    }
    return tmp;
  }
  function Fq__compareTo_impl(this_0, other) {
    if (!(other instanceof Fq))
      throw IllegalArgumentException_init_$Create$();
    else {
    }
    return _Fq___get_value__impl_(this_0).compareTo_26(_Fq___get_value__impl_(other._value_5));
  }
  function Fq__compareTo_impl_0(this_0, other) {
    var tmp = this_0._value_5;
    return Fq__compareTo_impl(tmp, (!(other == null) ? isInterface(other, Field) : false) ? other : THROW_CCE());
  }
  function Fq__unaryMinusImp_impl(this_0) {
    return _Fq___init__impl__1(_Fq___get_Q__impl_(this_0), _Fq___get_value__impl_(this_0)._residue.unaryMinus_5());
  }
  function Fq__unaryMinusImp_impl_0(this_0) {
    return new Fq(Fq__unaryMinusImp_impl(this_0._value_5));
  }
  function Fq__minusImp_impl(this_0, other) {
    var tmp;
    if (other instanceof Fq) {
      tmp = new Fq(_Fq___init__impl_(_Fq___get_value__impl_(this_0).minus_2(_Fq___get_value__impl_(other._value_5))));
    } else {
      if (other instanceof FieldExt) {
        tmp = Fq__plusImp_impl(this_0, other.unaryMinusImp_5());
      } else {
        {
          throw new NotImplementedError('' + 'Not valid arg ' + other);
        }
      }
    }
    return tmp;
  }
  function Fq__timesImp_impl(this_0, other) {
    if (other instanceof Fq) {
      return new Fq(_Fq___init__impl_(_Fq___get_value__impl_(this_0).times_0(_Fq___get_value__impl_(other._value_5))));
    } else {
      if (other instanceof FieldExt) {
        return other.timesImp_4(new Fq(this_0));
      } else {
      }
    }
    throw new NotImplementedError('' + 'Not supported for ' + getKClassFromExpression_0(other));
  }
  function Fq__toString_impl(this_0) {
    var s = toHexString_0(_Fq___get_value__impl_(this_0).toByteArray_10());
    var tmp;
    if (s.length > 10) {
      tmp = '' + 'Fq(' + take_0(s, 5) + '...' + takeLast_0(s, 5) + ')';
    } else {
      tmp = '' + 'Fq(' + s + ')';
    }
    return tmp;
  }
  function Fq__powImp_impl(this_0, n) {
    return pow_0(this_0, BigInteger_init_$Create$_0(n));
  }
  function Fq__powImp_impl_0(this_0, n) {
    return new Fq(Fq__powImp_impl(this_0._value_5, n));
  }
  function Fq__powImp_impl_1(this_0, n) {
    if (n.equals(Companion_getInstance_13()._ZERO_0)) {
      return _Fq___init__impl__0(_Fq___get_Q__impl_(this_0), 1);
    } else if (n.equals(Companion_getInstance_13()._ONE_0)) {
      return this_0;
    } else if (n.mod(Companion_getInstance_13()._TWO_0).equals(Companion_getInstance_13()._ZERO_0)) {
      return pow_0(_Fq___init__impl_(_Fq___get_value__impl_(this_0).times_0(_Fq___get_value__impl_(this_0))), n.div_33(2));
    } else {
      var tmp = Fq__timesImp_impl(pow_0(_Fq___init__impl_(_Fq___get_value__impl_(this_0).times_0(_Fq___get_value__impl_(this_0))), n.div_33(2)), new Fq(this_0));
      return tmp instanceof Fq ? tmp._value_5 : THROW_CCE();
    }
  }
  function Fq__powImp_impl_2(this_0, n) {
    return new Fq(Fq__powImp_impl_1(this_0._value_5, n));
  }
  function Fq__invertImp_impl(this_0) {
    return _Fq___init__impl_(_Fq___get_value__impl_(this_0).inverse_0());
  }
  function Fq__invertImp_impl_0(this_0) {
    return new Fq(Fq__invertImp_impl(this_0._value_5));
  }
  function Fq__divImp_impl(this_0, other) {
    if (other instanceof Fq) {
      return Fq__timesImp_impl(this_0, new Fq(not(other._value_5)));
    } else {
      if (other instanceof FieldExt) {
        return other.divImp_4(new Fq(this_0));
      } else {
      }
    }
    throw new NotImplementedError('' + 'Not supported for ' + getKClassFromExpression_0(other));
  }
  function Fq__modSqrtImp_impl(this_0) {
    if (_Fq___get_value__impl_(this_0)._residue.equals(Companion_getInstance_13()._ZERO_0))
      return _Fq___init__impl__1(_Fq___get_Q__impl_(this_0), Companion_getInstance_13()._ZERO_0);
    if (!_Fq___get_value__impl_(this_0).pow_4(_Fq___get_Q__impl_(this_0).minus_2(Companion_getInstance_13()._ONE_0).div_1(Companion_getInstance_13()._TWO_0))._residue.equals(Companion_getInstance_13()._ONE_0))
      throw Exception_init_$Create$_0('No sqrt');
    if (_Fq___get_Q__impl_(this_0).mod(BigInteger_init_$Create$_0(4)).equals(BigInteger_init_$Create$_0(3)))
      return _Fq___init__impl_(_Fq___get_value__impl_(this_0).pow_4(_Fq___get_Q__impl_(this_0).plus_4(Companion_getInstance_13()._ONE_0).div_33(4)));
    if (_Fq___get_Q__impl_(this_0).mod(BigInteger_init_$Create$_0(8)).equals(BigInteger_init_$Create$_0(5)))
      return _Fq___init__impl_(_Fq___get_value__impl_(this_0).pow_4(_Fq___get_Q__impl_(this_0).plus_4(BigInteger_init_$Create$_0(3)).div_33(8)));
    var S = Companion_getInstance_13()._ZERO_0;
    var q = _Fq___get_Q__impl_(this_0).minus_39(1);
    while (q.mod(BigInteger_init_$Create$_0(2)).equals(BigInteger_init_$Create$_0(0))) {
      q = q.div_33(2);
      S = S.plus_4(Companion_getInstance_13()._ONE_0);
    }
    var z = Companion_getInstance_13()._ZERO_0;
    var tmp0_iterator = Companion_getInstance_13()._ZERO_0.rangeTo(_Fq___get_Q__impl_(this_0).minus_39(1)).iterator_66();
    $l$break: while (tmp0_iterator.hasNext_41()) {
      var i = tmp0_iterator.next_46();
      var euler = powMod(i, _Fq___get_Q__impl_(this_0).minus_39(1).div_33(2), _Fq___get_Q__impl_(this_0));
      if (euler.equals(Companion_getInstance_13()._ONE_0.negate().mod(_Fq___get_Q__impl_(this_0)))) {
        z = i;
        break $l$break;
      }}
    var M = S;
    var c = powMod(z, q, _Fq___get_Q__impl_(this_0));
    var t = _Fq___get_value__impl_(this_0).pow_4(q);
    var R = _Fq___get_value__impl_(this_0).pow_4(q.plus_70(1).div_33(2));
    while (true) {
      if (t._residue.equals(Companion_getInstance_13()._ZERO_0))
        return _Fq___init__impl__0(_Fq___get_Q__impl_(this_0), 0);
      if (t._residue.equals(Companion_getInstance_13()._ONE_0))
        return _Fq___init__impl_(R);
      var i_0 = Companion_getInstance_13()._ZERO_0;
      var f = t;
      while (!f._residue.equals(Companion_getInstance_13()._ONE_0)) {
        f = f.pow_4(Companion_getInstance_13()._TWO_0);
        i_0 = i_0.plus_70(1);
      }
      var b = powMod(c, powMod(BigInteger_init_$Create$_0(2), M.minus_2(i_0).minus_39(1), _Fq___get_Q__impl_(this_0)), _Fq___get_Q__impl_(this_0));
      M = i_0;
      c = powMod(b, Companion_getInstance_13()._TWO_0, _Fq___get_Q__impl_(this_0));
      t = t._residue.times_0(c).toModularBigInteger(_Fq___get_Q__impl_(this_0));
      R = R._residue.times_0(b).toModularBigInteger(_Fq___get_Q__impl_(this_0));
    }
  }
  function Fq__modSqrtImp_impl_0(this_0) {
    return new Fq(Fq__modSqrtImp_impl(this_0._value_5));
  }
  function _Fq___get_flatSequence__impl_(this_0) {
    return _Fq___get_sequence__impl_(this_0);
  }
  function _Fq___get_sequence__impl_(this_0) {
    return sequenceOf([new Fq(this_0)]);
  }
  function Fq__toByteArray_impl(this_0) {
    return fixSize(_Fq___get_value__impl_(this_0).toByteArray_10(), 48);
  }
  function Fq__toUByteArray_impl(this_0) {
    return fixSize_0(_Fq___get_value__impl_(this_0).toUByteArray_10(), 48);
  }
  function Companion_16() {
    Companion_instance_15 = this;
  }
  Companion_16.prototype.zero_0 = function (Q) {
    return _Fq___init__impl__0(Q, 0);
  };
  Companion_16.prototype.zero_4 = function (Q) {
    return new Fq(this.zero_0(Q));
  };
  Companion_16.prototype.one_0 = function (Q) {
    return _Fq___init__impl__0(Q, 1);
  };
  Companion_16.prototype.one_4 = function (Q) {
    return new Fq(this.one_0(Q));
  };
  Companion_16.prototype._get_base__3 = function () {
    return null;
  };
  Companion_16.prototype.fromFq_0 = function (Q, fq) {
    return fq;
  };
  Companion_16.prototype.fromFq_4 = function (Q, fq) {
    return new Fq(this.fromFq_0(Q, fq));
  };
  Companion_16.prototype.fromBytes = function (buffer, q) {
    return _Fq___init__impl__1(q, Companion_getInstance_13().fromByteArray(buffer, Sign_POSITIVE_getInstance()));
  };
  Companion_16.prototype.isInstance_9 = function (any) {
    return getKClass_0(Fq).isInstance_4(any);
  };
  Companion_16.$metadata$ = {
    simpleName: 'Companion',
    kind: 'object',
    interfaces: [FieldCompanion]
  };
  var Companion_instance_15;
  function Companion_getInstance_15() {
    if (Companion_instance_15 == null)
      new Companion_16();
    return Companion_instance_15;
  }
  function Fq__hashCode_impl(this_0) {
    return hashCode(this_0);
  }
  function Fq__equals_impl(this_0, other) {
    if (!(other instanceof Fq))
      return false;
    else {
    }
    var tmp0_other_with_cast = other instanceof Fq ? other._value_5 : THROW_CCE();
    if (!this_0.equals(tmp0_other_with_cast))
      return false;
    return true;
  }
  function Fq(value_0) {
    Companion_getInstance_15();
    this._value_5 = value_0;
  }
  Fq.prototype._get_extension__4 = function () {
    return _Fq___get_extension__impl_(this._value_5);
  };
  Fq.prototype.plusImp_4 = function (other) {
    return Fq__plusImp_impl(this._value_5, other);
  };
  Fq.prototype.compareTo_33 = function (other) {
    return Fq__compareTo_impl(this._value_5, other);
  };
  Fq.prototype.compareTo_34 = function (other) {
    return Fq__compareTo_impl_0(this, other);
  };
  Fq.prototype.unaryMinusImp_0 = function () {
    return Fq__unaryMinusImp_impl(this._value_5);
  };
  Fq.prototype.unaryMinusImp_5 = function () {
    return Fq__unaryMinusImp_impl_0(this);
  };
  Fq.prototype.minusImp_4 = function (other) {
    return Fq__minusImp_impl(this._value_5, other);
  };
  Fq.prototype.timesImp_4 = function (other) {
    return Fq__timesImp_impl(this._value_5, other);
  };
  Fq.prototype.toString = function () {
    return Fq__toString_impl(this._value_5);
  };
  Fq.prototype.powImp_1 = function (n) {
    return Fq__powImp_impl(this._value_5, n);
  };
  Fq.prototype.powImp_11 = function (n) {
    return Fq__powImp_impl_0(this, n);
  };
  Fq.prototype.powImp_3 = function (n) {
    return Fq__powImp_impl_1(this._value_5, n);
  };
  Fq.prototype.powImp_12 = function (n) {
    return Fq__powImp_impl_2(this, n);
  };
  Fq.prototype.invertImp_0 = function () {
    return Fq__invertImp_impl(this._value_5);
  };
  Fq.prototype.invertImp_5 = function () {
    return Fq__invertImp_impl_0(this);
  };
  Fq.prototype.divImp_4 = function (other) {
    return Fq__divImp_impl(this._value_5, other);
  };
  Fq.prototype.modSqrtImp_0 = function () {
    return Fq__modSqrtImp_impl(this._value_5);
  };
  Fq.prototype.modSqrtImp_5 = function () {
    return Fq__modSqrtImp_impl_0(this);
  };
  Fq.prototype._get_flatSequence__4 = function () {
    return _Fq___get_flatSequence__impl_(this._value_5);
  };
  Fq.prototype._get_sequence__4 = function () {
    return _Fq___get_sequence__impl_(this._value_5);
  };
  Fq.prototype.toByteArray_10 = function () {
    return Fq__toByteArray_impl(this._value_5);
  };
  Fq.prototype.toUByteArray_10 = function () {
    return Fq__toUByteArray_impl(this._value_5);
  };
  Fq.prototype.hashCode = function () {
    return Fq__hashCode_impl(this._value_5);
  };
  Fq.prototype.equals = function (other) {
    return Fq__equals_impl(this._value_5, other);
  };
  Fq.$metadata$ = {
    simpleName: 'Fq',
    kind: 'class',
    interfaces: [Field]
  };
  function Fq2_init_$Init$(Q, a, b, $this) {
    Fq2.call($this, Q, new Pair(new Fq(a), new Fq(b)));
    return $this;
  }
  function Fq2_init_$Create$(Q, a, b) {
    return Fq2_init_$Init$(Q, a, b, Object.create(Fq2.prototype));
  }
  function Fq2_init_$Init$_0(Q, a, b, $this) {
    Fq2.call($this, Q, new Pair(new Fq(_Fq___init__impl__0(Q, a)), new Fq(_Fq___init__impl__0(Q, b))));
    return $this;
  }
  function Fq2_init_$Create$_0(Q, a, b) {
    return Fq2_init_$Init$_0(Q, a, b, Object.create(Fq2.prototype));
  }
  function Fq2_init_$Init$_1(Q, a, b, $this) {
    Fq2.call($this, Q, new Pair(new Fq(_Fq___init__impl__1(Q, a)), new Fq(_Fq___init__impl__1(Q, b))));
    return $this;
  }
  function Fq2_init_$Create$_1(Q, a, b) {
    return Fq2_init_$Init$_1(Q, a, b, Object.create(Fq2.prototype));
  }
  function Companion_17() {
    Companion_instance_16 = this;
  }
  Companion_17.prototype.zero_4 = function (Q) {
    return this.fromFq_4(Q, _Fq___init__impl__0(Q, 0));
  };
  Companion_17.prototype.one_4 = function (Q) {
    return this.fromFq_4(Q, _Fq___init__impl__0(Q, 1));
  };
  Companion_17.prototype.fromFq_4 = function (Q, fq) {
    var tmp = this._get_base__3().fromFq_4(Q, fq);
    var y = tmp instanceof Fq ? tmp._value_5 : THROW_CCE();
    var tmp_0 = this._get_base__3().zero_4(Q);
    var z = tmp_0 instanceof Fq ? tmp_0._value_5 : THROW_CCE();
    return new Fq2(Q, new Pair(new Fq(y), new Fq(z)));
  };
  Companion_17.prototype._get_base__3 = function () {
    return Companion_getInstance_15();
  };
  Companion_17.prototype.isInstance_9 = function (any) {
    return getKClass_0(Fq2).isInstance_4(any);
  };
  Companion_17.prototype.fromBytes_0 = function (buffer, q) {
    if (!(buffer.length === 96)) {
      throw Exception_init_$Create$_0('Invalid size');
    }return new Fq2(q, new Pair(new Fq(Companion_getInstance_15().fromBytes(toByteArray(drop(buffer, 48)), q)), new Fq(Companion_getInstance_15().fromBytes(toByteArray(take(buffer, 48)), q))));
  };
  Companion_17.$metadata$ = {
    simpleName: 'Companion',
    kind: 'object',
    interfaces: [FieldCompanion]
  };
  var Companion_instance_16;
  function Companion_getInstance_16() {
    if (Companion_instance_16 == null)
      new Companion_17();
    return Companion_instance_16;
  }
  function Fq2(Q, pair) {
    Companion_getInstance_16();
    FieldExt.call(this, Q);
    this._pair = pair;
  }
  Fq2.prototype._get_root_ = function () {
    return _Fq___init__impl__0(this._get_Q__2(), -1);
  };
  Fq2.prototype._get_root__5 = function () {
    return new Fq(this._get_root_());
  };
  Fq2.prototype.companion_2 = function () {
    return Companion_getInstance_16();
  };
  Fq2.prototype._get_embedding__2 = function () {
    return 2;
  };
  Fq2.prototype._get_a_ = function () {
    return this._pair._first._value_5;
  };
  Fq2.prototype._get_b_ = function () {
    return this._pair._second._value_5;
  };
  Fq2.prototype.fromArgs_2 = function (Q, args) {
    var tmp = args.get_72(0);
    var tmp_0 = tmp instanceof Fq ? tmp._value_5 : THROW_CCE();
    var tmp_1 = args.get_72(1);
    return Fq2_init_$Create$(Q, tmp_0, tmp_1 instanceof Fq ? tmp_1._value_5 : THROW_CCE());
  };
  Fq2.prototype._get_extension__4 = function () {
    return 2;
  };
  Fq2.prototype._get_sequence__4 = function () {
    return asSequence_0(toList_2(this._pair));
  };
  Fq2.prototype.invertImp_5 = function () {
    var factor = Fq__invertImp_impl(plus_4(times_0(this._get_a_(), this._get_a_()), times_0(this._get_b_(), this._get_b_())));
    return Fq2_init_$Create$(this._get_Q__2(), times_0(this._get_a_(), factor), times_0(unaryMinus_0(this._get_b_()), factor));
  };
  Fq2.prototype.mulByNonresidue_0 = function () {
    return Fq2_init_$Create$(this._get_Q__2(), minus_1(this._get_a_(), this._get_b_()), plus_4(this._get_a_(), this._get_b_()));
  };
  Fq2.prototype.modSqrtImp_5 = function () {
    if (equals_0(this._pair._second, new Fq(Companion_getInstance_15().zero_0(this._get_Q__2()))))
      return this.fromArgs_2(this._get_Q__2(), listOf([new Fq(modSqrt(this._pair._first._value_5)), new Fq(Companion_getInstance_15().zero_0(this._get_Q__2()))]));
    var alpha = plus_4(pow_1(this._pair._first._value_5, 2), pow_1(this._pair._second._value_5, 2));
    var gamma = pow_0(alpha, this._get_Q__2().minus_39(1).div_33(2));
    if (equals_0(new Fq(gamma), new Fq(_Fq___init__impl__0(this._get_Q__2(), -1))))
      throw Exception_init_$Create$_0('NO SQRT EXIST');
    alpha = modSqrt(alpha);
    var delta_0 = times_0(plus_4(this._pair._first._value_5, alpha), Fq__invertImp_impl(_Fq___init__impl__0(this._get_Q__2(), 2)));
    gamma = pow_0(delta_0, this._get_Q__2().minus_39(1).div_33(2));
    if (equals_0(new Fq(gamma), new Fq(_Fq___init__impl__0(this._get_Q__2(), -1)))) {
      delta_0 = times_0(minus_1(this._pair._first._value_5, alpha), Fq__invertImp_impl(_Fq___init__impl__0(this._get_Q__2(), 2)));
    }var x0 = modSqrt(delta_0);
    var x1 = times_0(this._pair._second._value_5, Fq__invertImp_impl(times_0(_Fq___init__impl__0(this._get_Q__2(), 2), x0)));
    return Fq2_init_$Create$(this._get_Q__2(), x0, x1);
  };
  Fq2.$metadata$ = {
    simpleName: 'Fq2',
    kind: 'class',
    interfaces: []
  };
  function Fq6_init_$Init$(Q, a, b, c, $this) {
    Fq6.call($this, Q, new Triple(a, b, c));
    return $this;
  }
  function Fq6_init_$Create$(Q, a, b, c) {
    return Fq6_init_$Init$(Q, a, b, c, Object.create(Fq6.prototype));
  }
  function Companion_18() {
    Companion_instance_17 = this;
  }
  Companion_18.prototype.zero_4 = function (Q) {
    return this.fromFq_4(Q, _Fq___init__impl__0(Q, 0));
  };
  Companion_18.prototype.one_4 = function (Q) {
    return this.fromFq_4(Q, _Fq___init__impl__0(Q, 1));
  };
  Companion_18.prototype.fromFq_4 = function (Q, fq) {
    var tmp = this._get_base__3().fromFq_4(Q, fq);
    var y = tmp instanceof Fq2 ? tmp : THROW_CCE();
    var tmp_0 = this._get_base__3().zero_4(Q);
    var z = tmp_0 instanceof Fq2 ? tmp_0 : THROW_CCE();
    return new Fq6(Q, new Triple(y, z, z));
  };
  Companion_18.prototype._get_base__3 = function () {
    return Companion_getInstance_16();
  };
  Companion_18.prototype.isInstance_9 = function (any) {
    return getKClass_0(Fq6).isInstance_4(any);
  };
  Companion_18.$metadata$ = {
    simpleName: 'Companion',
    kind: 'object',
    interfaces: [FieldCompanion]
  };
  var Companion_instance_17;
  function Companion_getInstance_17() {
    if (Companion_instance_17 == null)
      new Companion_18();
    return Companion_instance_17;
  }
  function Fq6(Q, triple) {
    Companion_getInstance_17();
    FieldExt.call(this, Q);
    this._Q = Q;
    this._triple = triple;
  }
  Fq6.prototype._get_Q__2 = function () {
    return this._Q;
  };
  Fq6.prototype._get_a__3 = function () {
    return this._triple._first_0;
  };
  Fq6.prototype._get_b__3 = function () {
    return this._triple._second_0;
  };
  Fq6.prototype._get_c__1 = function () {
    return this._triple._third;
  };
  Fq6.prototype._get_root__5 = function () {
    return Fq2_init_$Create$(this._Q, Companion_getInstance_15().one_0(this._Q), Companion_getInstance_15().one_0(this._Q));
  };
  Fq6.prototype._get_extension__4 = function () {
    return 6;
  };
  Fq6.prototype._get_embedding__2 = function () {
    return 3;
  };
  Fq6.prototype._get_sequence__4 = function () {
    return asSequence_0(toList_3(this._triple));
  };
  Fq6.prototype.fromArgs_2 = function (Q, args) {
    var tmp = args.get_72(0);
    var tmp_0 = tmp instanceof Fq2 ? tmp : THROW_CCE();
    var tmp_1 = args.get_72(1);
    var tmp_2 = tmp_1 instanceof Fq2 ? tmp_1 : THROW_CCE();
    var tmp_3 = args.get_72(2);
    return Fq6_init_$Create$(Q, tmp_0, tmp_2, tmp_3 instanceof Fq2 ? tmp_3 : THROW_CCE());
  };
  Fq6.prototype.companion_2 = function () {
    return Companion_getInstance_17();
  };
  Fq6.prototype.invertImp_5 = function () {
    var g0 = minus_2(times_1(this._get_a__3(), this._get_a__3()), times_1(this._get_b__3(), this._get_c__1().mulByNonresidue_0()));
    var g1 = minus_2(times_1(this._get_c__1(), this._get_c__1()).mulByNonresidue_0(), times_1(this._get_a__3(), this._get_b__3()));
    var g2 = minus_2(times_1(this._get_b__3(), this._get_b__3()), times_1(this._get_a__3(), this._get_c__1()));
    var factor = plus_5(times_1(g0, this._get_a__3()), plus_5(times_1(g1, this._get_c__1()), times_1(g2, this._get_b__3())).mulByNonresidue_0()).invertImp_5();
    return Fq6_init_$Create$(this._Q, times_1(g0, factor), times_1(g1, factor), times_1(g2, factor));
  };
  Fq6.prototype.modSqrtImp_5 = function () {
    var tmp0_TODO_0 = 'Not yet implemented For Fq6';
    throw new NotImplementedError('' + 'An operation is not implemented: ' + tmp0_TODO_0);
  };
  Fq6.prototype.mulByNonresidue_0 = function () {
    return Fq6_init_$Create$(this._Q, times_1(this._get_c__1(), this._get_root__5()), this._get_a__3(), this._get_b__3());
  };
  Fq6.$metadata$ = {
    simpleName: 'Fq6',
    kind: 'class',
    interfaces: []
  };
  function Fq12_init_$Init$(Q, a, b, $this) {
    Fq12.call($this, Q, new Pair(a, b));
    return $this;
  }
  function Fq12_init_$Create$(Q, a, b) {
    return Fq12_init_$Init$(Q, a, b, Object.create(Fq12.prototype));
  }
  function Companion_19() {
    Companion_instance_18 = this;
  }
  Companion_19.prototype.zero_4 = function (Q) {
    return this.fromFq_4(Q, _Fq___init__impl__0(Q, 0));
  };
  Companion_19.prototype.one_4 = function (Q) {
    return this.fromFq_4(Q, _Fq___init__impl__0(Q, 1));
  };
  Companion_19.prototype._get_base__3 = function () {
    return Companion_getInstance_17();
  };
  Companion_19.prototype.fromFq_4 = function (Q, fq) {
    var tmp = this._get_base__3().fromFq_4(Q, fq);
    var y = tmp instanceof Fq6 ? tmp : THROW_CCE();
    var tmp_0 = this._get_base__3().zero_4(Q);
    var z = tmp_0 instanceof Fq6 ? tmp_0 : THROW_CCE();
    return new Fq12(Q, new Pair(y, z));
  };
  Companion_19.prototype.isInstance_9 = function (any) {
    return getKClass_0(Fq12).isInstance_4(any);
  };
  Companion_19.$metadata$ = {
    simpleName: 'Companion',
    kind: 'object',
    interfaces: [FieldCompanion]
  };
  var Companion_instance_18;
  function Companion_getInstance_18() {
    if (Companion_instance_18 == null)
      new Companion_19();
    return Companion_instance_18;
  }
  function Fq12(Q, pair) {
    Companion_getInstance_18();
    FieldExt.call(this, Q);
    this._Q_0 = Q;
    this._pair_0 = pair;
  }
  Fq12.prototype._get_Q__2 = function () {
    return this._Q_0;
  };
  Fq12.prototype._get_extension__4 = function () {
    return 12;
  };
  Fq12.prototype._get_embedding__2 = function () {
    return 2;
  };
  Fq12.prototype._get_root__5 = function () {
    return Fq6_init_$Create$(this._Q_0, Companion_getInstance_16().zero_4(this._Q_0), Companion_getInstance_16().one_4(this._Q_0), Companion_getInstance_16().zero_4(this._Q_0));
  };
  Fq12.prototype._get_sequence__4 = function () {
    return asSequence_0(toList_2(this._pair_0));
  };
  Fq12.prototype.companion_2 = function () {
    return Companion_getInstance_18();
  };
  Fq12.prototype.invertImp_5 = function () {
    var factor = minus_3(times_2(this._get_a__3(), this._get_a__3()), times_2(this._get_b__3(), this._get_b__3()).mulByNonresidue_0()).invertImp_5();
    var tmp = times_2(this._get_a__3(), factor);
    var tmp_0 = unaryMinus_1(this._get_b__3()).timesImp_4(factor);
    return Fq12_init_$Create$(this._Q_0, tmp, tmp_0 instanceof Fq6 ? tmp_0 : THROW_CCE());
  };
  Fq12.prototype._get_a__3 = function () {
    return this._pair_0._first;
  };
  Fq12.prototype._get_b__3 = function () {
    return this._pair_0._second;
  };
  Fq12.prototype.modSqrtImp_5 = function () {
    var tmp0_TODO_0 = 'Not yet implemented For Fq12';
    throw new NotImplementedError('' + 'An operation is not implemented: ' + tmp0_TODO_0);
  };
  Fq12.prototype.fromArgs_2 = function (Q, args) {
    var tmp = args.get_72(0);
    var tmp_0 = tmp instanceof Fq6 ? tmp : THROW_CCE();
    var tmp_1 = args.get_72(1);
    return new Fq12(Q, new Pair(tmp_0, tmp_1 instanceof Fq6 ? tmp_1 : THROW_CCE()));
  };
  Fq12.$metadata$ = {
    simpleName: 'Fq12',
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__67() {
  }
  _no_name_provided__67.prototype.invoke_105 = function (it) {
    return it._first.plusImp_4(it._second);
  };
  _no_name_provided__67.prototype.invoke_1115 = function (p1) {
    return this.invoke_105(p1 instanceof Pair ? p1 : THROW_CCE());
  };
  _no_name_provided__67.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__68() {
  }
  _no_name_provided__68.prototype.invoke_111 = function (it) {
    return it.unaryMinusImp_5();
  };
  _no_name_provided__68.prototype.invoke_1115 = function (p1) {
    return this.invoke_111((!(p1 == null) ? isInterface(p1, Field) : false) ? p1 : THROW_CCE());
  };
  _no_name_provided__68.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__69() {
  }
  _no_name_provided__69.prototype.invoke_109 = function (it) {
    return it instanceof Fq ? it._value_5 : THROW_CCE();
  };
  _no_name_provided__69.prototype.invoke_1115 = function (p1) {
    return new Fq(this.invoke_109((!(p1 == null) ? isInterface(p1, Field) : false) ? p1 : THROW_CCE()));
  };
  _no_name_provided__69.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__70() {
  }
  _no_name_provided__70.prototype.invoke_111 = function (it) {
    return it._get_flatSequence__4();
  };
  _no_name_provided__70.prototype.invoke_1115 = function (p1) {
    return this.invoke_111((!(p1 == null) ? isInterface(p1, Field) : false) ? p1 : THROW_CCE());
  };
  _no_name_provided__70.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function FieldExt(Q) {
    this._Q_1 = Q;
  }
  FieldExt.prototype._get_Q__2 = function () {
    return this._Q_1;
  };
  FieldExt.prototype.toString = function () {
    var tmp = this._get_extension__4();
    var tmp_0 = this._get_sequence__4();
    return '' + 'Fq(' + tmp + '(' + joinToString$default_2(tmp_0, ',', null, null, 0, null, null, 62, null) + ')';
  };
  FieldExt.prototype.plusImp_4 = function (other) {
    if (other._get_extension__4() > this._get_extension__4()) {
      return other.plusImp_4(this);
    }var tmp;
    if (this.companion_2().isInstance_9(other)) {
      tmp = zip_0(this._get_sequence__4(), other._get_sequence__4());
    } else {
      var new_0 = toMutableList_3(this.companion_2().zero_4(this._get_Q__2())._get_sequence__4());
      new_0.set_3(0, new_0.get_72(0).plusImp_4(other));
      Unit_getInstance();
      tmp = zip_0(this._get_sequence__4(), asSequence_0(toList_0(new_0)));
    }
    var zip_1 = tmp;
    var tmp_0 = this._get_Q__2();
    return this.fromArgs_2(tmp_0, toList_1(map(zip_1, _no_name_provided_$factory_48())));
  };
  FieldExt.prototype.unaryMinusImp_5 = function () {
    var tmp = this._get_Q__2();
    var tmp_0 = this._get_sequence__4();
    return this.fromArgs_2(tmp, toList_1(map(tmp_0, _no_name_provided_$factory_49())));
  };
  FieldExt.prototype.minusImp_4 = function (other) {
    return this.plusImp_4(unaryMinus(other));
  };
  FieldExt.prototype.timesImp_4 = function (other) {
    if (this._get_extension__4() < other._get_extension__4()) {
      return other.timesImp_4(this);
    }var tmp0_MutableList_0 = count(this._get_sequence__4());
    var list_1 = ArrayList_init_$Create$_0(tmp0_MutableList_0);
    var inductionVariable = 0;
    if (inductionVariable < tmp0_MutableList_0)
      do {
        var index_3_3 = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        list_1.add_39(ensureNotNull(this.companion_2()._get_base__3()).zero_4(this._get_Q__2()));
        Unit_getInstance();
      }
       while (inductionVariable < tmp0_MutableList_0);
    var buffer = list_1;
    var tmp1_forEachIndexed_0 = this._get_sequence__4();
    var index_1 = 0;
    var tmp0_iterator_2 = tmp1_forEachIndexed_0.iterator_66();
    while (tmp0_iterator_2.hasNext_41()) {
      var item_3 = tmp0_iterator_2.next_46();
      var tmp1_4 = index_1;
      index_1 = tmp1_4 + 1 | 0;
      var tmp2__anonymous__5 = checkIndexOverflow(tmp1_4);
      if (this._get_extension__4() === other._get_extension__4()) {
        var tmp0_forEachIndexed_0_6 = other._get_sequence__4();
        var index_1_7 = 0;
        var tmp0_iterator_2_8 = tmp0_forEachIndexed_0_6.iterator_66();
        while (tmp0_iterator_2_8.hasNext_41()) {
          var item_3_9 = tmp0_iterator_2_8.next_46();
          var tmp1_4_11 = index_1_7;
          index_1_7 = tmp1_4_11 + 1 | 0;
          var tmp1__anonymous__5_10 = checkIndexOverflow(tmp1_4_11);
          if ((tmp2__anonymous__5 + tmp1__anonymous__5_10 | 0) >= this._get_embedding__2()) {
            buffer.set_3((tmp2__anonymous__5 + tmp1__anonymous__5_10 | 0) % this._get_embedding__2(), buffer.get_72((tmp2__anonymous__5 + tmp1__anonymous__5_10 | 0) % this._get_embedding__2()).plusImp_4(item_3.timesImp_4(item_3_9).timesImp_4(this._get_root__5())));
            Unit_getInstance();
          } else {
            buffer.set_3((tmp2__anonymous__5 + tmp1__anonymous__5_10 | 0) % this._get_embedding__2(), buffer.get_72((tmp2__anonymous__5 + tmp1__anonymous__5_10 | 0) % this._get_embedding__2()).plusImp_4(item_3.timesImp_4(item_3_9)));
            Unit_getInstance();
          }
        }
      } else {
        buffer.set_3(tmp2__anonymous__5, item_3.timesImp_4(other));
        Unit_getInstance();
      }
    }
    return this.fromArgs_2(this._get_Q__2(), buffer);
  };
  FieldExt.prototype.compareTo_33 = function (other) {
    var a = this.toByteArray_10();
    var b = other.toByteArray_10();
    return Companion_getInstance_13().fromByteArray(a, Sign_POSITIVE_getInstance()).compareTo_26(Companion_getInstance_13().fromByteArray(b, Sign_POSITIVE_getInstance()));
  };
  FieldExt.prototype.compareTo_34 = function (other) {
    return this.compareTo_33((!(other == null) ? isInterface(other, Field) : false) ? other : THROW_CCE());
  };
  FieldExt.prototype.equals = function (other) {
    if (other == null) {
      return false;
    } else {
      if (this.companion_2().isInstance_9(other)) {
        var bf = (other instanceof FieldExt ? other : THROW_CCE()).flatten_4();
        var zip_1 = zip(this.flatten_4(), bf);
        var tmp$ret$0;
        l$ret$1: do {
          var tmp0_iterator_1 = zip_1.iterator_66();
          while (tmp0_iterator_1.hasNext_41()) {
            var element_2 = tmp0_iterator_1.next_46();
            if (!_Fq___get_value__impl_(element_2._first._value_5).equals(_Fq___get_value__impl_(element_2._second._value_5))) {
              tmp$ret$0 = element_2;
              break l$ret$1;
            } else {
            }
          }
          tmp$ret$0 = null;
        }
         while (false);
        var equiv = tmp$ret$0;
        return equiv == null ? other._get_Q__2().equals(this._get_Q__2()) : false;
      } else {
        var tmp;
        if (other instanceof FieldExt) {
          tmp = this._get_extension__4() <= other._get_extension__4();
        } else {
          {
            tmp = false;
          }
        }
        if (tmp) {
          return equals_0(other, this);
        } else {
          var tmp_0;
          var tmp_1;
          if (other instanceof FieldExt) {
            tmp_1 = true;
          } else {
            {
              tmp_1 = other instanceof BigInteger;
            }
          }
          if (tmp_1) {
            tmp_0 = true;
          } else {
            {
              tmp_0 = !(other == null) ? typeof other === 'number' : false;
            }
          }
          if (tmp_0) {
            var tmp0_find_0 = drop_0(this._get_sequence__4(), 1);
            var tmp$ret$2;
            l$ret$3: do {
              var tmp0_iterator_1_1 = tmp0_find_0.iterator_66();
              while (tmp0_iterator_1_1.hasNext_41()) {
                var element_2_2 = tmp0_iterator_1_1.next_46();
                if (!equals_0(element_2_2, ensureNotNull(this.companion_2()._get_base__3()).zero_4(this._get_Q__2()))) {
                  tmp$ret$2 = element_2_2;
                  break l$ret$3;
                } else {
                }
              }
              tmp$ret$2 = null;
            }
             while (false);
            var check = tmp$ret$2 == null;
            if (!check)
              return false;
            return equals_0(first_1(this._get_sequence__4()), other);
          } else {
            {
              throw NotImplementedError_init_$Create$(null, 1, null);
            }
          }
        }
      }
    }
  };
  FieldExt.prototype._get_flatSequence__4 = function () {
    var seq = this._get_sequence__4();
    var newBases = seq;
    var tmp;
    var tmp_0 = first_1(newBases);
    if (tmp_0 instanceof Fq) {
      tmp = map(newBases, _no_name_provided_$factory_50());
    } else {
      {
        tmp = flatten_1(map(newBases, _no_name_provided_$factory_51()));
      }
    }
    return tmp;
  };
  FieldExt.prototype.flatten_4 = function () {
    return toList_1(this._get_flatSequence__4());
  };
  FieldExt.prototype.powImp_11 = function (n) {
    return pow_2(this, BigInteger_init_$Create$_0(n));
  };
  FieldExt.prototype.divImp_4 = function (other) {
    return this.timesImp_4(other.invertImp_5());
  };
  FieldExt.prototype.powImp_12 = function (n) {
    var ans = this.companion_2().one_4(this._get_Q__2());
    var base = this;
    var e = n;
    while (e.compareTo_26(0) > 0) {
      if (e.and_0(Companion_getInstance_13()._ONE_0).equals(Companion_getInstance_13()._ONE_0))
        ans = ans.timesImp_4(base);
      base = base.timesImp_4(base);
      e = e.shr_4(1);
    }
    return ans;
  };
  FieldExt.prototype.toByteArray_10 = function () {
    var bytes = ArrayList_init_$Create$();
    var tmp0_forEach_0 = reversed(toList_1(this._get_sequence__4()));
    var tmp0_iterator_1 = tmp0_forEach_0.iterator_66();
    while (tmp0_iterator_1.hasNext_41()) {
      var element_2 = tmp0_iterator_1.next_46();
      bytes.addAll_14(toList(element_2.toByteArray_10()));
      Unit_getInstance();
    }
    return toByteArray(bytes);
  };
  FieldExt.prototype.toUByteArray_10 = function () {
    var bytes = ArrayList_init_$Create$();
    var tmp0_forEach_0 = reversed(toList_1(this._get_sequence__4()));
    var tmp0_iterator_1 = tmp0_forEach_0.iterator_66();
    while (tmp0_iterator_1.hasNext_41()) {
      var element_2 = tmp0_iterator_1.next_46();
      bytes.addAll_14(toList_0(new UByteArray(element_2.toUByteArray_10())));
      Unit_getInstance();
    }
    return toUByteArray(bytes);
  };
  FieldExt.prototype.hashCode = function () {
    var result = this._get_Q__2().hashCode();
    var tmp0_forEach_0 = this.flatten_4();
    var tmp0_iterator_1 = tmp0_forEach_0.iterator_66();
    while (tmp0_iterator_1.hasNext_41()) {
      var element_2 = tmp0_iterator_1.next_46()._value_5;
      result = imul(result, 31) + hashCode(_Fq___get_value__impl_(element_2)) | 0;
    }
    return result;
  };
  FieldExt.$metadata$ = {
    simpleName: 'FieldExt',
    kind: 'class',
    interfaces: [Field]
  };
  function _no_name_provided_$factory_48() {
    var i = new _no_name_provided__67();
    return function (p1) {
      return i.invoke_105(p1);
    };
  }
  function _no_name_provided_$factory_49() {
    var i = new _no_name_provided__68();
    return function (p1) {
      return i.invoke_111(p1);
    };
  }
  function _no_name_provided_$factory_50() {
    var i = new _no_name_provided__69();
    return function (p1) {
      return new Fq(i.invoke_109(p1));
    };
  }
  function _no_name_provided_$factory_51() {
    var i = new _no_name_provided__70();
    return function (p1) {
      return i.invoke_111(p1);
    };
  }
  function _PrivateKey___init__impl_(value_0) {
    if (_PrivateKey___get_value__impl_(value_0).compareTo_26(_get_defaultEc_()._n_0) >= 0) {
      throw IllegalArgumentException_init_$Create$_0('' + _PrivateKey___get_value__impl_(value_0) + '\nis more than group order\n' + _get_defaultEc_()._n_0);
    }return value_0;
  }
  function _PrivateKey___get_value__impl_(this_0) {
    return this_0;
  }
  function PrivateKey__getG1_impl(this_0) {
    return G1Generator$default(null, 1, null).times_12(_PrivateKey___get_value__impl_(this_0));
  }
  function Companion_20() {
    Companion_instance_19 = this;
    this._PRIVATE_KEY_SIZE = 32;
  }
  Companion_20.prototype.fromByteArray_2 = function (bytes) {
    return _PrivateKey___init__impl_(Companion_getInstance_13().fromByteArray(bytes, Sign_POSITIVE_getInstance()).mod(_get_defaultEc_()._n_0));
  };
  Companion_20.$metadata$ = {
    simpleName: 'Companion',
    kind: 'object',
    interfaces: []
  };
  var Companion_instance_19;
  function Companion_getInstance_19() {
    if (Companion_instance_19 == null)
      new Companion_20();
    return Companion_instance_19;
  }
  function G1Element(x, y, z, infinity, ec) {
    JacobianPoint.call(this, x, y, z, infinity, ec);
  }
  G1Element.prototype.plus_36 = function (other) {
    return JacobianPoint.prototype.plus_38.call(this, other).asG1_0();
  };
  G1Element.prototype.times_12 = function (other) {
    return JacobianPoint.prototype.times_12.call(this, other).asG1_0();
  };
  G1Element.$metadata$ = {
    simpleName: 'G1Element',
    kind: 'class',
    interfaces: []
  };
  function JacobianPoint(x, y, z, infinity, ec) {
    this._x_0 = x;
    this._y = y;
    this._z = z;
    this._infinity = infinity;
    this._ec = ec;
    this._FE = getCompanionFromClass(getKClassFromExpression_0(this._x_0));
  }
  JacobianPoint.prototype.asG1_0 = function () {
    return new G1Element(this._x_0, this._y, this._z, this._infinity, this._ec);
  };
  JacobianPoint.prototype.toAffine_0 = function () {
    if (this._infinity) {
      return new AffinePoint(new Fq(Companion_getInstance_15().zero_0(this._ec._q_0)), new Fq(Companion_getInstance_15().zero_0(this._ec._q_0)), this._infinity, this._ec);
    }var newX = div(this._x_0, pow(this._z, 2));
    var newY = div(this._y, pow(this._z, 3));
    return new AffinePoint(newX, newY, this._infinity, this._ec);
  };
  JacobianPoint.prototype.toByteArray_10 = function () {
    var tmp0_toByteArray_0 = pointToBytes(this, this._ec, this._FE);
    var tmp0_copyOf_0_1 = _UByteArray___get_storage__impl_(tmp0_toByteArray_0);
    return tmp0_copyOf_0_1.slice();
  };
  JacobianPoint.prototype.toUByteArray_10 = function () {
    return pointToBytes(this, this._ec, this._FE);
  };
  JacobianPoint.prototype.equals = function (other) {
    if (!(other instanceof JacobianPoint))
      return false;
    else {
    }
    return this.toAffine_0().equals(other.toAffine_0());
  };
  JacobianPoint.prototype.plus_38 = function (other) {
    return addPointsJacobian(this, other, this._ec, this._FE);
  };
  JacobianPoint.prototype.times_12 = function (other) {
    return scalarMultiJacobian$default(other, this, this._ec, null, 8, null);
  };
  JacobianPoint.prototype.toString = function () {
    return '' + 'JacobianPoint(x=' + this._x_0 + ', y=' + this._y + ', z=' + this._z + ', i=' + this._infinity + ')';
  };
  JacobianPoint.prototype.hashCode = function () {
    var result = hashCode(this._x_0);
    result = imul(31, result) + hashCode(this._y) | 0;
    result = imul(31, result) + hashCode(this._z) | 0;
    result = imul(31, result) + (this._infinity | 0) | 0;
    return result;
  };
  JacobianPoint.$metadata$ = {
    simpleName: 'JacobianPoint',
    kind: 'class',
    interfaces: []
  };
  function EC(q, a, b, gx, gy, g2x, g2y, n, h, x, k, sqrt_n3, sqrt_n3m1o2) {
    this._q_0 = q;
    this._a_2 = a;
    this._b_2 = b;
    this._gx_0 = gx;
    this._gy_0 = gy;
    this._g2x_0 = g2x;
    this._g2y_0 = g2y;
    this._n_0 = n;
    this._h_0 = h;
    this._x_1 = x;
    this._k_0 = k;
    this._sqrt_n3 = sqrt_n3;
    this._sqrt_n3m1o2_0 = sqrt_n3m1o2;
  }
  EC.prototype.toString = function () {
    return '' + 'EC(q=' + this._q_0 + ', a=' + this._a_2 + ', b=' + this._b_2 + ', gx=' + new Fq(this._gx_0) + ', gy=' + new Fq(this._gy_0) + ', g2x=' + this._g2x_0 + ', g2y=' + this._g2y_0 + ', n=' + this._n_0 + ', h=' + this._h_0 + ', x=' + this._x_1 + ', k=' + this._k_0 + ', sqrt_n3=' + this._sqrt_n3 + ', sqrt_n3m1o2=' + this._sqrt_n3m1o2_0 + ')';
  };
  EC.prototype.hashCode = function () {
    var result = this._q_0.hashCode();
    result = imul(result, 31) + hashCode(this._a_2) | 0;
    result = imul(result, 31) + hashCode(this._b_2) | 0;
    result = imul(result, 31) + Fq__hashCode_impl(this._gx_0) | 0;
    result = imul(result, 31) + Fq__hashCode_impl(this._gy_0) | 0;
    result = imul(result, 31) + this._g2x_0.hashCode() | 0;
    result = imul(result, 31) + this._g2y_0.hashCode() | 0;
    result = imul(result, 31) + this._n_0.hashCode() | 0;
    result = imul(result, 31) + this._h_0.hashCode() | 0;
    result = imul(result, 31) + this._x_1.hashCode() | 0;
    result = imul(result, 31) + this._k_0.hashCode() | 0;
    result = imul(result, 31) + this._sqrt_n3.hashCode() | 0;
    result = imul(result, 31) + this._sqrt_n3m1o2_0.hashCode() | 0;
    return result;
  };
  EC.prototype.equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof EC))
      return false;
    else {
    }
    var tmp0_other_with_cast = other instanceof EC ? other : THROW_CCE();
    if (!this._q_0.equals(tmp0_other_with_cast._q_0))
      return false;
    if (!equals_0(this._a_2, tmp0_other_with_cast._a_2))
      return false;
    if (!equals_0(this._b_2, tmp0_other_with_cast._b_2))
      return false;
    if (!equals_0(new Fq(this._gx_0), new Fq(tmp0_other_with_cast._gx_0)))
      return false;
    if (!equals_0(new Fq(this._gy_0), new Fq(tmp0_other_with_cast._gy_0)))
      return false;
    if (!this._g2x_0.equals(tmp0_other_with_cast._g2x_0))
      return false;
    if (!this._g2y_0.equals(tmp0_other_with_cast._g2y_0))
      return false;
    if (!this._n_0.equals(tmp0_other_with_cast._n_0))
      return false;
    if (!this._h_0.equals(tmp0_other_with_cast._h_0))
      return false;
    if (!this._x_1.equals(tmp0_other_with_cast._x_1))
      return false;
    if (!this._k_0.equals(tmp0_other_with_cast._k_0))
      return false;
    if (!this._sqrt_n3.equals(tmp0_other_with_cast._sqrt_n3))
      return false;
    if (!this._sqrt_n3m1o2_0.equals(tmp0_other_with_cast._sqrt_n3m1o2_0))
      return false;
    return true;
  };
  EC.$metadata$ = {
    simpleName: 'EC',
    kind: 'class',
    interfaces: []
  };
  function AffinePoint(x, y, infinity, ec) {
    this._x_2 = x;
    this._y_0 = y;
    this._infinity_0 = infinity;
    this._ec_0 = ec;
    this._FE_0 = getCompanionFromClass(getKClassFromExpression_0(this._x_2));
    if (!getKClassFromExpression_0(this._x_2).equals(getKClassFromExpression_0(this._y_0))) {
      throw IllegalArgumentException_init_$Create$();
    }}
  AffinePoint.prototype.equals = function (other) {
    if (!(other instanceof AffinePoint))
      return false;
    else {
    }
    return (equals_0(this._x_2, other._x_2) ? equals_0(this._y_0, other._y_0) : false) ? this._infinity_0 === other._infinity_0 : false;
  };
  AffinePoint.prototype._get_isOnCurve_ = function () {
    if (this._infinity_0)
      return true;
    var left = times(this._y_0, this._y_0);
    var right = plus_3(plus_3(times(times(this._x_2, this._x_2), this._x_2), times(this._ec_0._a_2, this._x_2)), this._ec_0._b_2);
    return equals_0(left, right);
  };
  AffinePoint.prototype.toJacobian = function () {
    return new JacobianPoint(this._x_2, this._y_0, this._FE_0.one_4(this._ec_0._q_0), this._infinity_0, this._ec_0);
  };
  AffinePoint.prototype.hashCode = function () {
    var result = hashCode(this._x_2);
    result = imul(31, result) + hashCode(this._y_0) | 0;
    result = imul(31, result) + (this._infinity_0 | 0) | 0;
    return result;
  };
  AffinePoint.prototype.toString = function () {
    return '' + 'AffinePoint(x=' + this._x_2 + ', y=' + this._y_0 + ', infinity=' + this._infinity_0 + ', ec=' + this._ec_0 + ')';
  };
  AffinePoint.$metadata$ = {
    simpleName: 'AffinePoint',
    kind: 'class',
    interfaces: []
  };
  function pointToBytes(pointJ, ec, FE) {
    var point = pointJ.toAffine_0();
    var output_0 = point._x_2.toUByteArray_10();
    if (point._infinity_0) {
      var tmp0_ubyteArrayOf_0 = _UByteArray___init__impl_(new Int8Array([_UByte___init__impl_(64)]));
      var tmp2_plus_0 = tmp0_ubyteArrayOf_0;
      var tmp1_UByteArray_0 = _UByteArray___get_size__impl_(output_0) - 1 | 0;
      var tmp_1 = 0;
      var tmp_2 = tmp1_UByteArray_0;
      var tmp_3 = new Int8Array(tmp_2);
      while (tmp_1 < tmp_2) {
        var tmp_4 = tmp_1;
        var tmp0_toByte_0_5 = _UByte___init__impl_(0);
        tmp_3[tmp_4] = _UByte___get_data__impl_(tmp0_toByte_0_5);
        tmp_1 = tmp_1 + 1 | 0;
      }
      var tmp3_plus_0 = _UByteArray___init__impl_(tmp_3);
      var tmp0_plus_0_1 = _UByteArray___get_storage__impl_(tmp2_plus_0);
      var tmp1_plus_0_2 = _UByteArray___get_storage__impl_(tmp3_plus_0);
      return _UByteArray___init__impl_(primitiveArrayConcat([tmp0_plus_0_1, tmp1_plus_0_2]));
    }var tmp;
    if (equals_0(FE, Companion_getInstance_15())) {
      var tmp_0 = point._y_0;
      tmp = signFq(tmp_0 instanceof Fq ? tmp_0._value_5 : THROW_CCE(), ec);
    } else {
      var tmp_5 = point._y_0;
      tmp = signFq2(tmp_5 instanceof Fq2 ? tmp_5 : THROW_CCE(), ec);
    }
    var sign = tmp;
    if (sign) {
      var tmp5_or_0 = UByteArray__get_impl(output_0, 0);
      var tmp4_toUByte_0 = _UInt___init__impl_(160);
      var tmp0_toUByte_0_1 = _UInt___get_data__impl_(tmp4_toUByte_0);
      var tmp6_or_0 = _UByte___init__impl_(toByte(tmp0_toUByte_0_1));
      var tmp0_or_0_1 = _UByte___get_data__impl_(tmp5_or_0);
      var tmp1_or_0_2 = _UByte___get_data__impl_(tmp6_or_0);
      UByteArray__set_impl(output_0, 0, _UByte___init__impl_(toByte(tmp0_or_0_1 | tmp1_or_0_2)));
    } else {
      var tmp8_or_0 = UByteArray__get_impl(output_0, 0);
      var tmp7_toUByte_0 = _UInt___init__impl_(128);
      var tmp0_toUByte_0_1_0 = _UInt___get_data__impl_(tmp7_toUByte_0);
      var tmp9_or_0 = _UByte___init__impl_(toByte(tmp0_toUByte_0_1_0));
      var tmp0_or_0_1_0 = _UByte___get_data__impl_(tmp8_or_0);
      var tmp1_or_0_2_0 = _UByte___get_data__impl_(tmp9_or_0);
      UByteArray__set_impl(output_0, 0, _UByte___init__impl_(toByte(tmp0_or_0_1_0 | tmp1_or_0_2_0)));
    }
    return output_0;
  }
  function addPointsJacobian(p1, p2, ec, FE) {
    if (p1._infinity)
      return p2;
    if (p2._infinity)
      return p1;
    var U1 = times(p1._x_0, pow(p2._z, 2));
    var U2 = times(p2._x_0, pow(p1._z, 2));
    var S1 = times(p1._y, pow(p2._z, 3));
    var S2 = times(p2._y, pow(p1._z, 3));
    if (equals_0(U1, U2)) {
      if (!equals_0(S1, S2))
        return new JacobianPoint(FE.one_4(ec._q_0), FE.one_4(ec._q_0), FE.zero_4(ec._q_0), true, ec);
      else
        return doublePointJacobian(p1, ec, FE);
    }var H = minus_0(U2, U1);
    var R = minus_0(S2, S1);
    var Hsq = times(H, H);
    var Hcu = times(H, Hsq);
    var X3 = minus_0(minus_0(times(R, R), Hcu), times(times(new Fq(_Fq___init__impl__0(ec._q_0, 2)), U1), Hsq));
    var Y3 = minus_0(times(R, minus_0(times(U1, Hsq), X3)), times(S1, Hcu));
    var Z3 = times(times(H, p1._z), p2._z);
    return new JacobianPoint(X3, Y3, Z3, false, ec);
  }
  function scalarMultiJacobian(c, p1, ec, FE) {
    if (p1._infinity ? true : c.mod(ec._q_0).equals(Companion_getInstance_13()._ZERO_0))
      return new JacobianPoint(FE.one_4(ec._q_0), FE.one_4(ec._q_0), FE.zero_4(ec._q_0), true, ec);
    var result = new JacobianPoint(FE.one_4(ec._q_0), FE.one_4(ec._q_0), FE.zero_4(ec._q_0), true, ec);
    var addend = p1;
    var x = c;
    while (x.compareTo_26(Companion_getInstance_13()._ZERO_0) > 0) {
      if (x.and_0(Companion_getInstance_13()._ONE_0).compareTo_26(Companion_getInstance_13()._ZERO_0) > 0) {
        result = result.plus_38(addend);
      }addend = addend.plus_38(addend);
      x = x.shr_4(1);
    }
    return result;
  }
  function scalarMultiJacobian$default(c, p1, ec, FE, $mask0, $handler) {
    if (!(($mask0 & 4) === 0))
      ec = _get_defaultEc_();
    if (!(($mask0 & 8) === 0))
      FE = Companion_getInstance_15();
    return scalarMultiJacobian(c, p1, ec, FE);
  }
  function signFq(element, ec) {
    return Fq__compareTo_impl(element, new Fq(_Fq___init__impl__1(ec._q_0, ec._q_0.minus_39(1).div_33(2)))) > 0;
  }
  function signFq$default(element, ec, $mask0, $handler) {
    if (!(($mask0 & 2) === 0))
      ec = _get_defaultEc_();
    return signFq(element, ec);
  }
  function signFq2(element, ec) {
    if (equals_0(new Fq(element._get_b_()), new Fq(_Fq___init__impl__0(ec._q_0, 0)))) {
      var tmp = element._get_a_();
      return signFq$default(tmp, null, 2, null);
    }return Fq__compareTo_impl(element._get_b_(), new Fq(_Fq___init__impl__1(ec._q_0, ec._q_0.minus_39(1).div_33(2)))) > 0;
  }
  function _get_defaultEc_() {
    return new EC(Bls12381_getInstance()._q, new Fq(Bls12381_getInstance()._a_1), new Fq(Bls12381_getInstance()._b_1), Bls12381_getInstance()._gx, Bls12381_getInstance()._gy, Bls12381_getInstance()._g2x, Bls12381_getInstance()._g2y, Bls12381_getInstance()._n, Bls12381_getInstance()._h, Bls12381_getInstance()._x, Bls12381_getInstance()._k, Bls12381_getInstance()._sqrtn3, Bls12381_getInstance()._sqrt_n3m1o2);
  }
  function doublePointJacobian(p1, ec, FE) {
    var X = p1._x_0;
    var Y = p1._y;
    var Z = p1._z;
    if (equals_0(Y, FE.zero_4(ec._q_0)) ? true : p1._infinity) {
      return new JacobianPoint(FE.one_4(ec._q_0), FE.one_4(ec._q_0), FE.zero_4(ec._q_0), true, ec);
    }var S = times(times(times(new Fq(_Fq___init__impl__0(ec._q_0, 4)), X), Y), Y);
    var zSq = times(Z, Z);
    var z4th = times(zSq, zSq);
    var ySq = times(Y, Y);
    var y4th = times(ySq, ySq);
    var fieldM = times(times(new Fq(_Fq___init__impl__0(ec._q_0, 3)), X), X);
    fieldM = plus_3(fieldM, times(ec._a_2, z4th));
    var Xp = minus_0(times(fieldM, fieldM), times(new Fq(_Fq___init__impl__0(ec._q_0, 2)), S));
    var Yp = minus_0(times(fieldM, minus_0(S, Xp)), times(new Fq(_Fq___init__impl__0(ec._q_0, 8)), y4th));
    var Zp = times(times(new Fq(_Fq___init__impl__0(ec._q_0, 2)), Y), Z);
    return new JacobianPoint(Xp, Yp, Zp, false, ec);
  }
  function G1Infinity(ec, FE) {
    return new G1Element(FE.one_4(ec._q_0), FE.one_4(ec._q_0), FE.zero_4(ec._q_0), true, ec);
  }
  function G1Infinity$default(ec, FE, $mask0, $handler) {
    if (!(($mask0 & 1) === 0))
      ec = _get_defaultEc_();
    if (!(($mask0 & 2) === 0))
      FE = Companion_getInstance_15();
    return G1Infinity(ec, FE);
  }
  function G1FromBytes(bytes, ec, FE) {
    return bytesTopoint(_UByteArray___init__impl_(bytes.slice()), ec, FE).asG1_0();
  }
  function G1FromBytes$default(bytes, ec, FE, $mask0, $handler) {
    if (!(($mask0 & 2) === 0))
      ec = _get_defaultEc_();
    if (!(($mask0 & 4) === 0))
      FE = Companion_getInstance_15();
    return G1FromBytes(bytes, ec, FE);
  }
  function G1Generator(ec) {
    return (new AffinePoint(new Fq(ec._gx_0), new Fq(ec._gy_0), false, ec)).toJacobian().asG1_0();
  }
  function G1Generator$default(ec, $mask0, $handler) {
    if (!(($mask0 & 1) === 0))
      ec = _get_defaultEc_();
    return G1Generator(ec);
  }
  function bytesTopoint(buffer, ec, FE) {
    if (equals_0(FE, Companion_getInstance_15())) {
      if (!(_UByteArray___get_size__impl_(buffer) === 48))
        throw Exception_init_$Create$_0('' + 'G1 Element must be 48 bytes but ' + toHexString(buffer));
    } else if (equals_0(FE, Companion_getInstance_16())) {
      if (!(_UByteArray___get_size__impl_(buffer) === 96))
        throw Exception_init_$Create$_0('G2 Element must be 96 bytes');
    } else {
      throw Exception_init_$Create$_0('Not Fq or Fq2 (Not a G1 OR G2 Element!)');
    }
    var tmp1_and_0 = UByteArray__get_impl(buffer, 0);
    var tmp0_toUByte_0 = 224;
    var tmp2_and_0 = _UByte___init__impl_(toByte(tmp0_toUByte_0));
    var tmp0_and_0_1 = _UByte___get_data__impl_(tmp1_and_0);
    var tmp1_and_0_2 = _UByte___get_data__impl_(tmp2_and_0);
    var mByte = _UByte___init__impl_(toByte(tmp0_and_0_1 & tmp1_and_0_2));
    var tmp = listOf([32, 96, 224]);
    if (tmp.contains_51(_UByte___get_data__impl_(mByte) & 255)) {
      throw Exception_init_$Create$_0('Invalid first three bits');
    } else {
    }
    var tmp3_toUByte_0 = 128;
    var tmp4_and_0 = _UByte___init__impl_(toByte(tmp3_toUByte_0));
    var tmp0_and_0_1_0 = _UByte___get_data__impl_(mByte);
    var tmp1_and_0_2_0 = _UByte___get_data__impl_(tmp4_and_0);
    var cbit = _UByte___init__impl_(toByte(tmp0_and_0_1_0 & tmp1_and_0_2_0));
    var tmp5_toUByte_0 = 64;
    var tmp6_and_0 = _UByte___init__impl_(toByte(tmp5_toUByte_0));
    var tmp0_and_0_1_1 = _UByte___get_data__impl_(mByte);
    var tmp1_and_0_2_1 = _UByte___get_data__impl_(tmp6_and_0);
    var iBit = _UByte___init__impl_(toByte(tmp0_and_0_1_1 & tmp1_and_0_2_1));
    var tmp7_toUByte_0 = 32;
    var tmp8_and_0 = _UByte___init__impl_(toByte(tmp7_toUByte_0));
    var tmp0_and_0_1_2 = _UByte___get_data__impl_(mByte);
    var tmp1_and_0_2_2 = _UByte___get_data__impl_(tmp8_and_0);
    var tmp9_compareTo_0 = _UByte___init__impl_(toByte(tmp0_and_0_1_2 & tmp1_and_0_2_2));
    var tmp10_compareTo_0 = _UInt___init__impl_(0);
    var tmp0_compareTo_0_1 = _UInt___init__impl_(_UByte___get_data__impl_(tmp9_compareTo_0) & 255);
    var sBit = uintCompare(_UInt___get_data__impl_(tmp0_compareTo_0_1), _UInt___get_data__impl_(tmp10_compareTo_0)) > 0;
    var tmp_0 = new UByte(cbit);
    var tmp11_toUByte_0 = _UInt___init__impl_(0);
    var tmp0_toUByte_0_1 = _UInt___get_data__impl_(tmp11_toUByte_0);
    if (equals_0(tmp_0, new UByte(_UByte___init__impl_(toByte(tmp0_toUByte_0_1))))) {
      throw Exception_init_$Create$_0('First bit must be 1 (only compressed points');
    } else {
    }
    var tmp13_and_0 = UByteArray__get_impl(buffer, 0);
    var tmp12_toUByte_0 = _UInt___init__impl_(31);
    var tmp0_toUByte_0_1_0 = _UInt___get_data__impl_(tmp12_toUByte_0);
    var tmp14_and_0 = _UByte___init__impl_(toByte(tmp0_toUByte_0_1_0));
    var tmp0_and_0_1_3 = _UByte___get_data__impl_(tmp13_and_0);
    var tmp1_and_0_2_3 = _UByte___get_data__impl_(tmp14_and_0);
    var tmp15_ubyteArrayOf_0 = _UByteArray___init__impl_(new Int8Array([_UByte___init__impl_(toByte(tmp0_and_0_1_3 & tmp1_and_0_2_3))]));
    var newBuffer = plus_1(tmp15_ubyteArrayOf_0, drop_2(buffer, 1));
    var tmp16_compareTo_0 = _UInt___init__impl_(0);
    var tmp0_compareTo_0_1_0 = _UInt___init__impl_(_UByte___get_data__impl_(iBit) & 255);
    if (uintCompare(_UInt___get_data__impl_(tmp0_compareTo_0_1_0), _UInt___get_data__impl_(tmp16_compareTo_0)) > 0) {
      var tmp$ret$0;
      l$ret$1: do {
        var tmp0_iterator_1_1 = UByteArray__iterator_impl(buffer);
        while (tmp0_iterator_1_1.hasNext_41()) {
          var element_2_2 = tmp0_iterator_1_1.next_46()._data;
          var tmp_1 = new UByte(element_2_2);
          var tmp0_toUByte_0_4 = 0;
          if (!equals_0(tmp_1, new UByte(_UByte___init__impl_(toByte(tmp0_toUByte_0_4))))) {
            tmp$ret$0 = element_2_2;
            break l$ret$1;
          } else {
          }
        }
        tmp$ret$0 = null;
      }
       while (false);
      var check = tmp$ret$0;
      var tmp_2 = check;
      if (!((tmp_2 == null ? null : new UByte(tmp_2)) == null))
        throw IllegalArgumentException_init_$Create$_0('Point at infinity set but data not all zeros');
      else {
        return (new AffinePoint(FE.zero_4(ec._q_0), FE.zero_4(ec._q_0), true, ec)).toJacobian();
      }
    } else {
    }
    var x = fromBytesFor(newBuffer, ec._q_0, FE);
    var yValue = yForX(x, ec, FE);
    var tmp_3;
    if (equals_0(FE, Companion_getInstance_15())) {
      var tmp_4;
      if (signFq(yValue instanceof Fq ? yValue._value_5 : THROW_CCE(), ec) === sBit) {
        tmp_4 = yValue._value_5;
      } else {
        {
          tmp_4 = unaryMinus_0(yValue._value_5);
        }
      }
      tmp_3 = new Fq(tmp_4);
    } else {
      var tmp_5;
      if (signFq2(yValue instanceof Fq2 ? yValue : THROW_CCE(), ec) === sBit) {
        tmp_5 = yValue;
      } else {
        {
          tmp_5 = unaryMinus_2(yValue);
        }
      }
      tmp_3 = tmp_5;
    }
    var y = tmp_3;
    return (new AffinePoint(x, y, false, ec)).toJacobian();
  }
  function fromBytesFor(bytes, q, FE) {
    var tmp;
    if (equals_0(FE, Companion_getInstance_15())) {
      var tmp_0 = Companion_getInstance_15();
      var tmp0_copyOf_0_1 = _UByteArray___get_storage__impl_(bytes);
      tmp = new Fq(tmp_0.fromBytes(tmp0_copyOf_0_1.slice(), q));
    } else if (equals_0(FE, Companion_getInstance_16())) {
      var tmp_1 = Companion_getInstance_16();
      var tmp0_copyOf_0_1_0 = _UByteArray___get_storage__impl_(bytes);
      tmp = tmp_1.fromBytes_0(tmp0_copyOf_0_1_0.slice(), q);
    } else {
      throw Exception_init_$Create$_0('Not Fq or Fq2 (Not a G1 OR G2 Element!)');
    }
    return tmp;
  }
  function yForX(forX, ec, FE) {
    var x = forX;
    if (!FE.isInstance_9(x)) {
      var tmp0_TODO_0 = 'Not implemented yet';
      throw new NotImplementedError('' + 'An operation is not implemented: ' + tmp0_TODO_0);
    }var u = plus_3(plus_3(times(times(x, x), x), times(ec._a_2, x)), ec._b_2);
    var y = modSqrt_0(u);
    if (equals_0(y, FE.zero_4(ec._q_0)) ? true : !(new AffinePoint(x, y, false, ec))._get_isOnCurve_()) {
      throw Exception_init_$Create$_0('No y for point x');
    }return y;
  }
  function Clvmc() {
    Clvmc_instance = this;
    this._defaultEvaluator = S2Run_getInstance().buildRunProgForSearchPath(listOf(['puzzles', 'runtime']));
  }
  Clvmc.prototype.compileFromText = function (program) {
    var assembled = assemble(program);
    var tmp = Companion_getInstance_22();
    var tmp_0 = Companion_getInstance_22();
    var inputSexp = tmp.to_1(new Pair(assembled, tmp_0.to_8(emptyList())));
    var tmp_1 = S2Bindings_getInstance()._run;
    var runProgram_0 = this._defaultEvaluator.evaluate$default_1(tmp_1, inputSexp, null, 4, null);
    return runProgram_0._second;
  };
  Clvmc.$metadata$ = {
    simpleName: 'Clvmc',
    kind: 'object',
    interfaces: []
  };
  var Clvmc_instance;
  function Clvmc_getInstance() {
    if (Clvmc_instance == null)
      new Clvmc();
    return Clvmc_instance;
  }
  var CURRY_OBJ_CODE_S;
  var CURRY_OBJ_CODE;
  var UNCURRY_PATTERN_FUNCTION;
  var UNCURRY_PATTERN_CORE;
  function _get__defaultMacroLookup_($this) {
    var tmp0_getValue_0 = $this.__defaultMacroLookup$delegate;
    var tmp1_getValue_0 = _defaultMacroLookup$factory();
    return tmp0_getValue_0._get_value__33();
  }
  function _no_name_provided__71(this$0) {
    this._this$0_18 = this$0;
  }
  _no_name_provided__71.prototype.invoke_523 = function () {
    var run = assemble('(a (com 2 3) 1)');
    var tmp0_fold_0 = this._this$0_18._defaultSrc;
    var tmp = Companion_getInstance_22();
    var tmp1_fold_0 = tmp.to_8(emptyList());
    var accumulator_1 = tmp1_fold_0;
    var tmp0_iterator_2 = tmp0_fold_0.iterator_66();
    while (tmp0_iterator_2.hasNext_41()) {
      var element_3 = tmp0_iterator_2.next_46();
      var tmp2__anonymous__4 = accumulator_1;
      var sexp_5 = assemble(element_3);
      var env_6 = Companion_getInstance_22().to_1(new Pair(sexp_5, tmp2__anonymous__4));
      var evalResult_7 = this._this$0_18._eval.evaluate_1(run, env_6, null);
      accumulator_1 = evalResult_7._second.cons_3(tmp2__anonymous__4);
    }
    return accumulator_1;
  };
  _no_name_provided__71.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function MacroDefaults(eval_0) {
    this._eval = eval_0;
    this._defaultSrc = listOf([trimIndent('\n        ; we have to compile this externally, since it uses itself\n        ;(defmacro defmacro (name params body)\n        ;    (qq (list (unquote name) (mod (unquote params) (unquote body))))\n        ;)\n        (q . ("defmacro"\n           (c (q . "list")\n              (c (f 1)\n                 (c (c (q . "mod")\n                       (c (f (r 1))\n                          (c (f (r (r 1)))\n                             (q . ()))))\n                    (q . ()))))))\n        '), trimIndent('\n        ;(defmacro list ARGS\n        ;    ((c (mod args\n        ;        (defun compile-list\n        ;               (args)\n        ;               (if args\n        ;                   (qq (c (unquote (f args))\n        ;                         (unquote (compile-list (r args)))))\n        ;                   ()))\n        ;            (compile-list args)\n        ;        )\n        ;        ARGS\n        ;    ))\n        ;)\n        (q "list"\n            (a (q #a (q #a 2 (c 2 (c 3 (q))))\n                     (c (q #a (i 5\n                                 (q #c (q . 4)\n                                       (c 9 (c (a 2 (c 2 (c 13 (q))))\n                                               (q)))\n                                 )\n                                 (q 1))\n                               1)\n                        1))\n                1))\n        '), trimIndent('\n        (defmacro function (BODY)\n        (qq (opt (com (q . (unquote BODY))\n                 (qq (unquote (macros)))\n                 (qq (unquote (symbols)))))))"""\n        '), trimIndent('\n        (defmacro if (A B C)\n        (qq (a\n            (i (unquote A)\n               (function (unquote B))\n               (function (unquote C)))\n            @)))"""\n        ')]);
    var tmp = this;
    tmp.__defaultMacroLookup$delegate = lazy(_no_name_provided_$factory_52(this));
  }
  MacroDefaults.prototype.defaultMacroLookup = function () {
    return _get__defaultMacroLookup_(this);
  };
  MacroDefaults.$metadata$ = {
    simpleName: 'MacroDefaults',
    kind: 'class',
    interfaces: []
  };
  function _defaultMacroLookup$factory() {
    return getPropertyCallableRef('_defaultMacroLookup', 1, KProperty1, function (receiver) {
      return _get__defaultMacroLookup_(receiver);
    }, null);
  }
  function _no_name_provided_$factory_52(this$0) {
    var i = new _no_name_provided__71(this$0);
    return function () {
      return i.invoke_523();
    };
  }
  function _no_name_provided__72() {
  }
  _no_name_provided__72.prototype.invoke_257 = function (it) {
    throw IllegalStateException_init_$Create$_0('This should not be used like this');
  };
  _no_name_provided__72.prototype.invoke_1115 = function (p1) {
    return this.invoke_257(p1 instanceof SExp ? p1 : THROW_CCE());
  };
  _no_name_provided__72.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__73() {
  }
  _no_name_provided__73.prototype.invoke_257 = function (it) {
    throw IllegalStateException_init_$Create$_0('This should not be used like this');
  };
  _no_name_provided__73.prototype.invoke_1115 = function (p1) {
    return this.invoke_257(p1 instanceof SExp ? p1 : THROW_CCE());
  };
  _no_name_provided__73.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function MiscOps() {
    MiscOps_instance = this;
    this._APPLY_KW = Operators_getInstance()._KEYWORD_TO_ATOM.get_56('a');
    this._CONS_KW = Operators_getInstance()._KEYWORD_TO_ATOM.get_56('c');
    var tmp = this;
    tmp._opCom = buildOpByName('com', _no_name_provided_$factory_53());
    var tmp_0 = this;
    tmp_0._opOpt = buildOpByName('opt', _no_name_provided_$factory_54());
    this._list_3 = listOf([this._opCom, this._opOpt]);
  }
  MiscOps.$metadata$ = {
    simpleName: 'MiscOps',
    kind: 'object',
    interfaces: []
  };
  var MiscOps_instance;
  function MiscOps_getInstance() {
    if (MiscOps_instance == null)
      new MiscOps();
    return MiscOps_instance;
  }
  function _no_name_provided_$factory_53() {
    var i = new _no_name_provided__72();
    return function (p1) {
      return i.invoke_257(p1);
    };
  }
  function _no_name_provided_$factory_54() {
    var i = new _no_name_provided__73();
    return function (p1) {
      return i.invoke_257(p1);
    };
  }
  function buildTree($this, items) {
    var size_0 = items._get_size__52();
    if (size_0 === 0)
      return Companion_getInstance_22().___null__;
    if (size_0 === 1)
      return Companion_getInstance_22().to_7(first_0(items));
    var half = size_0 / 2 | 0;
    var left = buildTree($this, slice(items, until(0, half)));
    var right = buildTree($this, slice(items, until(half, size_0)));
    return Companion_getInstance_22().to_1(new Pair(left, right));
  }
  function buildTreeProgram($this, items) {
    var size_0 = items._get_size__52();
    if (size_0 === 0)
      return Companion_getInstance_22().___null__;
    if (size_0 === 1)
      return items.get_72(0);
    var half = size_0 / 2 | 0;
    var left = buildTreeProgram($this, slice(items, until(0, half)));
    var right = buildTreeProgram($this, slice(items, until(half, size_0)));
    return Companion_getInstance_22().to_8(listOf([$this._consKw, left, right]));
  }
  function buildUsedConstantsNames($this, functions, constants, macros) {
    var tmp0_mapTo_0_1 = ArrayList_init_$Create$_0(collectionSizeOrDefault(macros, 10));
    var tmp0_iterator_1_2 = macros.iterator_66();
    while (tmp0_iterator_1_2.hasNext_41()) {
      var item_2_3 = tmp0_iterator_1_2.next_46();
      tmp0_mapTo_0_1.add_39(new Pair(first_1(rest(item_2_3)).asString(), item_2_3));
      Unit_getInstance();
    }
    var macrosAsDict = toMap(tmp0_mapTo_0_1);
    var possibleSymbols = toSet(functions._get_keys__1());
    possibleSymbols = union(possibleSymbols, constants._get_keys__1());
    var newNames = setOf('');
    var usedNames = setOf('');
    $l$break: while (true) {
      var tmp0_isNotEmpty_0 = newNames;
      if (!!tmp0_isNotEmpty_0.isEmpty_44()) {
        break $l$break;
      }var priorNewNames = newNames;
      newNames = HashSet_init_$Create$();
      var tmp0_iterator_1 = priorNewNames.iterator_66();
      while (tmp0_iterator_1.hasNext_41()) {
        var element_2 = tmp0_iterator_1.next_46();
        if ((isInterface(functions, Map_0) ? functions : THROW_CCE()).containsKey_7(element_2)) {
          var tmp0_map_0_5 = asFlatList(ensureNotNull(functions.get_56(element_2)));
          var tmp0_mapTo_0_1_6 = ArrayList_init_$Create$_0(collectionSizeOrDefault(tmp0_map_0_5, 10));
          var tmp0_iterator_1_2_7 = tmp0_map_0_5.iterator_66();
          while (tmp0_iterator_1_2_7.hasNext_41()) {
            var item_2_3_8 = tmp0_iterator_1_2_7.next_46()._storage;
            var tmp0_copyOf_0_1_5_9 = _UByteArray___get_storage__impl_(item_2_3_8);
            tmp0_mapTo_0_1_6.add_39(decodeToString(tmp0_copyOf_0_1_5_9.slice()));
            Unit_getInstance();
          }
          var flat_4 = tmp0_mapTo_0_1_6;
          newNames = union(newNames, flat_4);
        } else {
        }
        if ((isInterface(macrosAsDict, Map_0) ? macrosAsDict : THROW_CCE()).containsKey_7(element_2)) {
          var tmp1_map_0_11 = asFlatList(ensureNotNull(macrosAsDict.get_56(element_2)));
          var tmp0_mapTo_0_1_12 = ArrayList_init_$Create$_0(collectionSizeOrDefault(tmp1_map_0_11, 10));
          var tmp0_iterator_1_2_13 = tmp1_map_0_11.iterator_66();
          while (tmp0_iterator_1_2_13.hasNext_41()) {
            var item_2_3_14 = tmp0_iterator_1_2_13.next_46()._storage;
            var tmp0_copyOf_0_1_5_15 = _UByteArray___get_storage__impl_(item_2_3_14);
            tmp0_mapTo_0_1_12.add_39(decodeToString(tmp0_copyOf_0_1_5_15.slice()));
            Unit_getInstance();
          }
          var flat_10 = tmp0_mapTo_0_1_12;
          newNames = union(newNames, flat_10);
        } else {
        }
      }
      newNames = toHashSet(subtract(newNames, usedNames));
      usedNames = union(usedNames, newNames);
    }
    usedNames = toHashSet(intersect(usedNames, possibleSymbols));
    return minus(usedNames, '');
  }
  function parseInclude($this, name, namespace, functions, constants, macros, runProgram_0) {
    var prog = assemble('(_read (_full_path_for_name 1))');
    var result = runProgram_0.evaluate_1(prog, name, null);
    var tmp0_forEach_0 = result._second;
    var tmp0_iterator_1 = tmp0_forEach_0.iterator_66();
    while (tmp0_iterator_1.hasNext_41()) {
      var element_2 = tmp0_iterator_1.next_46();
      parseModSexp(Mod_getInstance(), element_2, namespace, functions, constants, macros, runProgram_0);
    }
  }
  function unquoteArgs($this, code, args) {
    if (listp(code)) {
      var c1 = first_1(code);
      var c2 = rest(code);
      return unquoteArgs($this, c1, args).cons_3(unquoteArgs($this, c2, args));
    }if (contains_3(args, code)) {
      return Companion_getInstance_22().to_8(listOf([encodeToByteArray('unquote'), code]));
    }return code;
  }
  function defunInlineToMacro($this, declerationSexp) {
    var d2 = rest(declerationSexp);
    var d3 = rest(d2);
    var r = mutableListOf([Companion_getInstance_22().to_3(encodeToByteArray('defmacro')), first_1(d2), first_1(d3)]);
    var code = first_1(rest(d3));
    var args = asFlatList(Companion_getInstance_22().to_0(first_1(d3)));
    var unquotedCode = unquoteArgs($this, code, Companion_getInstance_22().to_8(args));
    r.add_39(Companion_getInstance_22().to_3(encodeToByteArray('qq')));
    Unit_getInstance();
    r.add_39(unquotedCode);
    Unit_getInstance();
    return Companion_getInstance_22().to_8(r);
  }
  function parseModSexp($this, declerationSexp, namespace, functions, constants, macros, runProgram_0) {
    var tmp = first_1(declerationSexp)._get_atom__4();
    var op = ensureNotNull(tmp == null ? null : new UByteArray(tmp))._storage;
    var name = first_1(rest(declerationSexp));
    var tmp0_toUByteArray_0 = encodeToByteArray('include');
    if (contentEquals_0(op, _UByteArray___init__impl_(tmp0_toUByteArray_0.slice()))) {
      parseInclude($this, name, namespace, functions, constants, macros, runProgram_0);
      return Unit_getInstance();
    } else {
    }
    var nameStr = name.asString();
    if (namespace.contains_51(nameStr)) {
      throw new EvalError('' + 'Symbol ' + nameStr + ' already defined');
    }namespace.add_39(nameStr);
    Unit_getInstance();
    var tmp1_toUByteArray_0 = encodeToByteArray('defmacro');
    if (contentEquals_0(op, _UByteArray___init__impl_(tmp1_toUByteArray_0.slice()))) {
      macros.add_39(declerationSexp);
      Unit_getInstance();
      return Unit_getInstance();
    } else {
      var tmp2_toUByteArray_0 = encodeToByteArray('defun');
      if (contentEquals_0(op, _UByteArray___init__impl_(tmp2_toUByteArray_0.slice()))) {
        var tmp3_set_0 = rest(rest(declerationSexp));
        functions.put_6(nameStr, tmp3_set_0);
        Unit_getInstance();
        return Unit_getInstance();
      } else {
        var tmp4_toUByteArray_0 = encodeToByteArray('defun-inline');
        if (contentEquals_0(op, _UByteArray___init__impl_(tmp4_toUByteArray_0.slice()))) {
          macros.add_39(defunInlineToMacro($this, declerationSexp));
          Unit_getInstance();
          return Unit_getInstance();
        } else {
          var tmp5_toUByteArray_0 = encodeToByteArray('defconstant');
          if (contentEquals_0(op, _UByteArray___init__impl_(tmp5_toUByteArray_0.slice()))) {
            var tmp6_set_0 = Companion_getInstance_22().to_1(S2Compile_getInstance().quote(first_1(rest(rest(declerationSexp)))));
            constants.put_6(nameStr, tmp6_set_0);
            Unit_getInstance();
            return Unit_getInstance();
          } else {
            {
              throw new EvalError('Expected fun, defun, or defconstant');
            }
          }
        }
      }
    }
  }
  function Stage1Result(functions, constants, macros) {
    this._functions = functions;
    this._constants = constants;
    this._macros = macros;
  }
  Stage1Result.prototype.toString = function () {
    return '' + 'Stage1Result(functions=' + this._functions + ', constants=' + this._constants + ', macros=' + this._macros + ')';
  };
  Stage1Result.prototype.hashCode = function () {
    var result = this._functions.hashCode();
    result = imul(result, 31) + this._constants.hashCode() | 0;
    result = imul(result, 31) + this._macros.hashCode() | 0;
    return result;
  };
  Stage1Result.prototype.equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof Stage1Result))
      return false;
    else {
    }
    var tmp0_other_with_cast = other instanceof Stage1Result ? other : THROW_CCE();
    if (!this._functions.equals(tmp0_other_with_cast._functions))
      return false;
    if (!this._constants.equals(tmp0_other_with_cast._constants))
      return false;
    if (!this._macros.equals(tmp0_other_with_cast._macros))
      return false;
    return true;
  };
  Stage1Result.$metadata$ = {
    simpleName: 'Stage1Result',
    kind: 'class',
    interfaces: []
  };
  function buildMacroLookupProgram($this, macroLookup, macros, runProgram_0) {
    var macroLookupProgram = Companion_getInstance_22().to_1(S2Compile_getInstance().quote(macroLookup));
    var tmp0_iterator_1 = macros.iterator_66();
    while (tmp0_iterator_1.hasNext_41()) {
      var element_2 = tmp0_iterator_1.next_46();
      macroLookupProgram = S2Compile_getInstance().eval_0(Companion_getInstance_22().to_8(listOf(['opt', listOf(['com', S2Compile_getInstance().quote_1(listOf([Mod_getInstance()._consKw, element_2, macroLookupProgram])), macroLookupProgram])])), Companion_getInstance_20()._TOP._get_shortPath_());
      macroLookupProgram = S2Optimize_getInstance().optimizeSexp(macroLookupProgram, runProgram_0);
    }
    return macroLookupProgram;
  }
  function symbolTableForTree($this, tree, rootNode) {
    if (nullp(tree)) {
      return emptyList();
    }if (!listp(tree))
      return listOf_0(listOf([tree, Companion_getInstance_22().to_8(new UByteArray(rootNode._get_shortPath_()))]));
    var left = symbolTableForTree($this, first_1(tree), rootNode.plus_39(Companion_getInstance_20()._LEFT));
    var right = symbolTableForTree($this, rest(tree), rootNode.plus_39(Companion_getInstance_20()._RIGHT));
    return plus(left, right);
  }
  function compileFunctions($this, functions, macroLookupProgram, constantsSymbolTable, argsRootNode) {
    var compiledFunctions = HashMap_init_$Create$();
    var tmp0_iterator_1 = functions._get_entries__5().iterator_66();
    while (tmp0_iterator_1.hasNext_41()) {
      var element_2 = tmp0_iterator_1.next_46();
      var localSymbolTable_4 = symbolTableForTree(Mod_getInstance(), first_1(element_2._get_value__33()), argsRootNode);
      var allSymbols_5 = plus(localSymbolTable_4, constantsSymbolTable);
      var tmp0_set_0_6 = element_2._get_key__35();
      var tmp1_set_0_7 = Companion_getInstance_22().to_8(listOf(['opt', listOf(['com', S2Compile_getInstance().quote(first_1(rest(element_2._get_value__33()))), macroLookupProgram, S2Compile_getInstance().quote_1(allSymbols_5)])]));
      compiledFunctions.put_6(tmp0_set_0_6, tmp1_set_0_7);
      Unit_getInstance();
    }
    return compiledFunctions;
  }
  function _no_name_provided__74() {
  }
  _no_name_provided__74.prototype.invoke_124 = function (args, macroLookup, symbolTable, runProgram_0, level) {
    var s1result = Mod_getInstance().compileModStage1(args, runProgram_0);
    var macroLookupProgram = buildMacroLookupProgram(Mod_getInstance(), macroLookup, s1result._macros, runProgram_0);
    var allConstantsNames = buildUsedConstantsNames(Mod_getInstance(), s1result._functions, s1result._constants, s1result._macros);
    var hasConstantTree = !allConstantsNames.isEmpty_44();
    var constantsTree = Companion_getInstance_22().to_0(buildTree(Mod_getInstance(), toList_0(allConstantsNames)));
    var constantsRootNode = Companion_getInstance_20()._LEFT;
    var tmp;
    if (hasConstantTree) {
      tmp = Companion_getInstance_20()._RIGHT;
    } else {
      tmp = Companion_getInstance_20()._TOP;
    }
    var argsRootNode = tmp;
    var constantsSymbolTable = symbolTableForTree(Mod_getInstance(), constantsTree, constantsRootNode);
    var compiledFunctions = compileFunctions(Mod_getInstance(), s1result._functions, macroLookupProgram, constantsSymbolTable, argsRootNode);
    var tmp0_elvis_lhs = compiledFunctions.get_56('');
    var tmp_0;
    if (tmp0_elvis_lhs == null) {
      throw new EvalError('No main?');
    } else {
      tmp_0 = tmp0_elvis_lhs;
    }
    var mainPathSrc = dissasemble(tmp_0);
    var tmp_1;
    if (hasConstantTree) {
      var tmp0_filterTo_0_1 = LinkedHashMap_init_$Create$();
      var tmp0_iterator_1_2 = compiledFunctions._get_entries__5().iterator_66();
      while (tmp0_iterator_1_2.hasNext_41()) {
        var element_2_3 = tmp0_iterator_1_2.next_46();
        if (allConstantsNames.contains_51(element_2_3._get_key__35())) {
          tmp0_filterTo_0_1.put_6(element_2_3._get_key__35(), element_2_3._get_value__33());
          Unit_getInstance();
        } else {
        }
      }
      var allConstantsLookup = toMutableMap(tmp0_filterTo_0_1);
      allConstantsLookup.putAll_2(s1result._constants);
      var tmp0_mapTo_0_1 = ArrayList_init_$Create$_0(collectionSizeOrDefault(allConstantsNames, 10));
      var tmp0_iterator_1_2_0 = allConstantsNames.iterator_66();
      while (tmp0_iterator_1_2_0.hasNext_41()) {
        var item_2_3 = tmp0_iterator_1_2_0.next_46();
        tmp0_mapTo_0_1.add_39(ensureNotNull(allConstantsLookup.get_56(item_2_3)));
        Unit_getInstance();
      }
      var allConstantsLists = tmp0_mapTo_0_1;
      var allConstnatsTreeProgram = Companion_getInstance_22().to_0(buildTreeProgram(Mod_getInstance(), allConstantsLists));
      var allConstantsTreeSource = dissasemble(allConstnatsTreeProgram);
      tmp_1 = '' + '(c ' + allConstantsTreeSource + ' 1)';
    } else {
      tmp_1 = '1';
    }
    var argTreeSrc = tmp_1;
    var mainCode = '' + '(opt (q . (a ' + mainPathSrc + ' ' + argTreeSrc + ')))';
    var assm = assemble(mainCode);
    return assm;
  };
  _no_name_provided__74.prototype.invoke_125 = function (p1, p2, p3, p4, p5) {
    var tmp = p1 instanceof SExp ? p1 : THROW_CCE();
    var tmp_0 = p2 instanceof SExp ? p2 : THROW_CCE();
    var tmp_1 = p3 instanceof SExp ? p3 : THROW_CCE();
    var tmp_2 = p4 instanceof Evaluator ? p4 : THROW_CCE();
    return this.invoke_124(tmp, tmp_0, tmp_1, tmp_2, (!(p5 == null) ? typeof p5 === 'number' : false) ? p5 : THROW_CCE());
  };
  _no_name_provided__74.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function Mod() {
    Mod_instance = this;
    this._consKw = ensureNotNull(Operators_getInstance()._KEYWORD_TO_ATOM.get_56('c'));
    this._mainName = '';
    var tmp = this;
    tmp._compileMod = new CompileBinding('mod', _no_name_provided_$factory_55());
  }
  Mod.prototype.compileModStage1 = function (iargs, runProgram_0) {
    var functions = HashMap_init_$Create$();
    var constants = HashMap_init_$Create$();
    var macros = ArrayList_init_$Create$();
    var mainLocalArguments = first_1(iargs);
    var args = iargs;
    var namespace = HashSet_init_$Create$();
    $l$break: while (true) {
      args = rest(args);
      if (nullp(rest(args)))
        break $l$break;
      parseModSexp(this, first_1(args), namespace, functions, constants, macros, runProgram_0);
    }
    var uncompiledMain = first_1(args);
    var tmp0_set_0 = this._mainName;
    var tmp1_set_0 = Companion_getInstance_22().to_8(listOf([mainLocalArguments, uncompiledMain]));
    functions.put_6(tmp0_set_0, tmp1_set_0);
    Unit_getInstance();
    return new Stage1Result(functions, constants, macros);
  };
  Mod.$metadata$ = {
    simpleName: 'Mod',
    kind: 'object',
    interfaces: []
  };
  var Mod_instance;
  function Mod_getInstance() {
    if (Mod_instance == null)
      new Mod();
    return Mod_instance;
  }
  function _no_name_provided_$factory_55() {
    var i = new _no_name_provided__74();
    return function (p1, p2, p3, p4, p5) {
      return i.invoke_124(p1, p2, p3, p4, p5);
    };
  }
  function S2Bindings() {
    S2Bindings_instance = this;
    this._brun = assemble('(a 2 3)');
    this._run = assemble('(a (opt (com 2)) 3)');
  }
  S2Bindings.$metadata$ = {
    simpleName: 'S2Bindings',
    kind: 'object',
    interfaces: []
  };
  var S2Bindings_instance;
  function S2Bindings_getInstance() {
    if (S2Bindings_instance == null)
      new S2Bindings();
    return S2Bindings_instance;
  }
  function invoke$com($macroLookup, $symbolTable, $runProgramF, sExp) {
    return S2Compile_getInstance().doComProg(sExp, $macroLookup, $symbolTable, $runProgramF);
  }
  function lowerQuote($this, prog, macroLookup, symbolTable, runProgram_0) {
    var tmp;
    if (nullp(prog)) {
      tmp = prog;
    } else if (listp(prog)) {
      var tmp_0;
      var tmp0_safe_receiver = first_1(prog)._get_atom__4();
      var tmp_1;
      var tmp_2 = tmp0_safe_receiver;
      if ((tmp_2 == null ? null : new UByteArray(tmp_2)) == null) {
        tmp_1 = null;
      } else {
        {
          var tmp0_toUByteArray_0 = encodeToByteArray('quote');
          tmp_1 = contentEquals_0(tmp0_safe_receiver, _UByteArray___init__impl_(tmp0_toUByteArray_0.slice()));
        }
      }
      if (tmp_1 === true) {
        if (!nullp(rest(rest(prog)))) {
          throw new EvalError('' + 'Compilation error compining ' + dissasemble(prog) + '. Quote takes 1 argument.');
        }var tmp_3 = Companion_getInstance_22();
        var tmp_4 = first_1(rest(prog));
        tmp_0 = tmp_3.to_1($this.quote(lowerQuote$default($this, tmp_4, null, null, null, 28, null)));
      } else {
        {
          var tmp_5 = Companion_getInstance_22();
          var tmp_6 = first_1(prog);
          var tmp_7 = lowerQuote$default($this, tmp_6, null, null, null, 28, null);
          var tmp_8 = rest(prog);
          tmp_0 = tmp_5.to_1(new Pair(tmp_7, lowerQuote$default($this, tmp_8, null, null, null, 28, null)));
        }
      }
      tmp = tmp_0;
    } else {
      tmp = prog;
    }
    return tmp;
  }
  function lowerQuote$default($this, prog, macroLookup, symbolTable, runProgram_0, $mask0, $handler) {
    if (!(($mask0 & 4) === 0))
      macroLookup = null;
    if (!(($mask0 & 8) === 0))
      symbolTable = null;
    if (!(($mask0 & 16) === 0))
      runProgram_0 = null;
    return lowerQuote($this, prog, macroLookup, symbolTable, runProgram_0);
  }
  function _no_name_provided__75() {
  }
  _no_name_provided__75.prototype.invoke_124 = function (args, macroLookup, symbolTable, runProgramF, level) {
    var sexp = first_1(args);
    if (!listp(sexp) ? true : nullp(sexp)) {
      return Companion_getInstance_22().to_1(S2Compile_getInstance().quote(sexp));
    } else if (listp(sexp) ? !listp(first_1(sexp)) : false) {
      var tmp = first_1(sexp)._get_atom__4();
      var op = ensureNotNull(tmp == null ? null : new UByteArray(tmp))._storage;
      var tmp0_toUByteArray_0 = encodeToByteArray('qq');
      if (contentEquals_0(op, _UByteArray___init__impl_(tmp0_toUByteArray_0.slice()))) {
        var subExp = S2Compile_getInstance()._get_compileqq_().call(rest(sexp), macroLookup, symbolTable, runProgramF, level + 1 | 0);
        var arg = Companion_getInstance_22().to_8(listOf([S2Compile_getInstance()._CONS_ATOM, new UByteArray(op), listOf([S2Compile_getInstance()._CONS_ATOM, subExp, S2Compile_getInstance().quote_0(0)])]));
        return invoke$com(macroLookup, symbolTable, runProgramF, arg);
      } else {
        var tmp1_toUByteArray_0 = encodeToByteArray('unquote');
        if (contentEquals_0(op, _UByteArray___init__impl_(tmp1_toUByteArray_0.slice()))) {
          if (level === 1) {
            return invoke$com(macroLookup, symbolTable, runProgramF, first_1(rest(sexp)));
          }var subExp_0 = S2Compile_getInstance()._get_compileqq_().call(rest(sexp), macroLookup, symbolTable, runProgramF, level - 1 | 0);
          return invoke$com(macroLookup, symbolTable, runProgramF, Companion_getInstance_22().to_8(listOf([S2Compile_getInstance()._CONS_ATOM, new UByteArray(op), listOf([S2Compile_getInstance()._CONS_ATOM, subExp_0, S2Compile_getInstance().quote_0(0)])])));
        } else {
        }
      }
    }var A = invoke$com(macroLookup, symbolTable, runProgramF, Companion_getInstance_22().to_8(listOf(['qq', first_1(sexp)])));
    var B = invoke$com(macroLookup, symbolTable, runProgramF, Companion_getInstance_22().to_8(listOf(['qq', rest(sexp)])));
    return Companion_getInstance_22().to_8(listOf([S2Compile_getInstance()._CONS_ATOM, A, B]));
  };
  _no_name_provided__75.prototype.invoke_125 = function (p1, p2, p3, p4, p5) {
    var tmp = p1 instanceof SExp ? p1 : THROW_CCE();
    var tmp_0 = p2 instanceof SExp ? p2 : THROW_CCE();
    var tmp_1 = p3 instanceof SExp ? p3 : THROW_CCE();
    var tmp_2 = p4 instanceof Evaluator ? p4 : THROW_CCE();
    return this.invoke_124(tmp, tmp_0, tmp_1, tmp_2, (!(p5 == null) ? typeof p5 === 'number' : false) ? p5 : THROW_CCE());
  };
  _no_name_provided__75.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__76() {
  }
  _no_name_provided__76.prototype.invoke_124 = function (_anonymous_parameter_0_, macroLookup, _anonymous_parameter_2_, _anonymous_parameter_3_, _anonymous_parameter_4_) {
    return Companion_getInstance_22().to_1(S2Compile_getInstance().quote(macroLookup));
  };
  _no_name_provided__76.prototype.invoke_125 = function (p1, p2, p3, p4, p5) {
    var tmp = p1 instanceof SExp ? p1 : THROW_CCE();
    var tmp_0 = p2 instanceof SExp ? p2 : THROW_CCE();
    var tmp_1 = p3 instanceof SExp ? p3 : THROW_CCE();
    var tmp_2 = p4 instanceof Evaluator ? p4 : THROW_CCE();
    return this.invoke_124(tmp, tmp_0, tmp_1, tmp_2, (!(p5 == null) ? typeof p5 === 'number' : false) ? p5 : THROW_CCE());
  };
  _no_name_provided__76.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__77() {
  }
  _no_name_provided__77.prototype.invoke_124 = function (_anonymous_parameter_0_, _anonymous_parameter_1_, symbolTable, _anonymous_parameter_3_, _anonymous_parameter_4_) {
    return Companion_getInstance_22().to_1(S2Compile_getInstance().quote(symbolTable));
  };
  _no_name_provided__77.prototype.invoke_125 = function (p1, p2, p3, p4, p5) {
    var tmp = p1 instanceof SExp ? p1 : THROW_CCE();
    var tmp_0 = p2 instanceof SExp ? p2 : THROW_CCE();
    var tmp_1 = p3 instanceof SExp ? p3 : THROW_CCE();
    var tmp_2 = p4 instanceof Evaluator ? p4 : THROW_CCE();
    return this.invoke_124(tmp, tmp_0, tmp_1, tmp_2, (!(p5 == null) ? typeof p5 === 'number' : false) ? p5 : THROW_CCE());
  };
  _no_name_provided__77.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__78($macroLookup, $symbolTable, $runProgram) {
    this._$macroLookup = $macroLookup;
    this._$symbolTable = $symbolTable;
    this._$runProgram = $runProgram;
  }
  _no_name_provided__78.prototype.invoke_257 = function (it) {
    return S2Compile_getInstance().doComProg(it, this._$macroLookup, this._$symbolTable, this._$runProgram);
  };
  _no_name_provided__78.prototype.invoke_1115 = function (p1) {
    return this.invoke_257(p1 instanceof SExp ? p1 : THROW_CCE());
  };
  _no_name_provided__78.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function S2Compile() {
    S2Compile_instance = this;
    this._QUOTE_ATOM = ensureNotNull(Operators_getInstance()._KEYWORD_TO_ATOM.get_56('q'));
    this._APPLY_ATOM = ensureNotNull(Operators_getInstance()._KEYWORD_TO_ATOM.get_56('a'));
    this._CONS_ATOM = ensureNotNull(Operators_getInstance()._KEYWORD_TO_ATOM.get_56('c'));
    var tmp = this;
    var tmp_0 = Operators_getInstance()._KEYWORD_TO_ATOM._get_values__6();
    var tmp0_map_0 = MiscOps_getInstance()._list_3;
    var tmp0_mapTo_0_1 = ArrayList_init_$Create$_0(collectionSizeOrDefault(tmp0_map_0, 10));
    var tmp0_iterator_1_2 = tmp0_map_0.iterator_66();
    while (tmp0_iterator_1_2.hasNext_41()) {
      var item_2_3 = tmp0_iterator_1_2.next_46();
      tmp0_mapTo_0_1.add_39(item_2_3._get_opCode__0());
      Unit_getInstance();
    }
    tmp._PASSTHRU_OPERATOR = filterNotNull_0(plus(tmp_0, tmp0_mapTo_0_1));
    var tmp_1 = this;
    tmp_1._compileqq = new CompileBinding('qq', _no_name_provided_$factory_56());
    var tmp_2 = this;
    tmp_2._compileMacros = new CompileBinding('macros', _no_name_provided_$factory_57());
    var tmp_3 = this;
    tmp_3._compileSymbols = new CompileBinding('symbols', _no_name_provided_$factory_58());
    var tmp_4 = this;
    var tmp_5 = new Pair(ensureNotNull(buildOpByName$default('qq', null, 2, null)._get_opCode__0()), this._get_compileqq_());
    var tmp_6 = new Pair(ensureNotNull(buildOpByName$default('macros', null, 2, null)._get_opCode__0()), this._compileMacros);
    var tmp_7 = new Pair(ensureNotNull(buildOpByName$default('symbols', null, 2, null)._get_opCode__0()), this._compileSymbols);
    var tmp_8 = new Pair(ensureNotNull(buildOpByName$default('lambda', null, 2, null)._get_opCode__0()), Mod_getInstance()._compileMod);
    tmp_4._compileBindings = mapOf([tmp_5, tmp_6, tmp_7, tmp_8, new Pair(ensureNotNull(buildOpByName$default('mod', null, 2, null)._get_opCode__0()), Mod_getInstance()._compileMod)]);
  }
  S2Compile.prototype._get_compileqq_ = function () {
    var tmp = this._compileqq;
    if (!(tmp == null))
      return tmp;
    else {
      throwUninitializedPropertyAccessException('compileqq');
    }
  };
  S2Compile.prototype.quote = function (other) {
    return new Pair(this._QUOTE_ATOM, other);
  };
  S2Compile.prototype.quote_0 = function (other) {
    return new Pair(this._QUOTE_ATOM, other);
  };
  S2Compile.prototype.quote_1 = function (other) {
    return new Pair(this._QUOTE_ATOM, other);
  };
  S2Compile.prototype.eval = function (prog, args) {
    return Companion_getInstance_22().to_8(listOf([this._APPLY_ATOM, prog, args]));
  };
  S2Compile.prototype.eval_0 = function (prog, args) {
    return Companion_getInstance_22().to_8(listOf([this._APPLY_ATOM, prog, new UByteArray(args)]));
  };
  S2Compile.prototype.brun = function (prog, args) {
    return this.eval(Companion_getInstance_22().to_1(this.quote(prog)), this.quote(args));
  };
  S2Compile.prototype.doComProg = function (progC, macroLookup, symbolTable, runProgram_0) {
    var prog = lowerQuote(this, progC, macroLookup, symbolTable, runProgram_0);
    if (nullp(prog) ? true : !listp(prog)) {
      var tmp = prog._get_atom__4();
      var atom = ensureNotNull(tmp == null ? null : new UByteArray(tmp))._storage;
      var tmp0_toUByteArray_0 = encodeToByteArray('@');
      if (contentEquals_0(atom, _UByteArray___init__impl_(tmp0_toUByteArray_0.slice())))
        return Companion_getInstance_22().to_8(new UByteArray(Companion_getInstance_20()._TOP._get_shortPath_()));
      else {
      }
      var tmp0_iterator_1 = symbolTable.iterator_66();
      while (tmp0_iterator_1.hasNext_41()) {
        var element_2 = tmp0_iterator_1.next_46();
        var symbol_4 = first_1(element_2);
        var value_5 = first_1(rest(element_2));
        if (symbol_4.equals(prog)) {
          return Companion_getInstance_22().to_0(value_5);
        }}
      return Companion_getInstance_22().to_1(this.quote(prog));
    }var operator = first_1(prog);
    if (listp(operator)) {
      var innnerExp = this.eval_0(Companion_getInstance_22().to_8(listOf(['com', this.quote(operator), this.quote(macroLookup), this.quote(symbolTable)])), Companion_getInstance_20()._TOP._get_shortPath_());
      return Companion_getInstance_22().to_8(listOf_0(innnerExp));
    }var tmp_0 = operator._get_atom__4();
    var asAtom = ensureNotNull(tmp_0 == null ? null : new UByteArray(tmp_0))._storage;
    var tmp0_iterator_1_0 = macroLookup.iterator_66();
    while (tmp0_iterator_1_0.hasNext_41()) {
      var element_2_0 = tmp0_iterator_1_0.next_46();
      var tmp_1 = first_1(element_2_0)._get_atom__4();
      var macroName_4 = ensureNotNull(tmp_1 == null ? null : new UByteArray(tmp_1))._storage;
      if (contentEquals_0(asAtom, macroName_4)) {
        var macroCode_5 = first_1(rest(element_2_0));
        var postProg_6 = S2Compile_getInstance().brun(macroCode_5, rest(prog));
        var e_7 = S2Compile_getInstance().eval_0(Companion_getInstance_22().to_8(listOf(['com', postProg_6, S2Compile_getInstance().quote(macroLookup), S2Compile_getInstance().quote(symbolTable)])), Companion_getInstance_20()._TOP._get_shortPath_());
        return e_7;
      }}
    var tmp0_safe_receiver = this._compileBindings.get_56(Companion_getInstance_13().fromUByteArray(asAtom, Sign_POSITIVE_getInstance()));
    if (tmp0_safe_receiver == null)
      null;
    else {
      var postProg_2 = tmp0_safe_receiver.call(rest(prog), macroLookup, symbolTable, runProgram_0, 1);
      var eval_3 = S2Compile_getInstance().eval_0(Companion_getInstance_22().to_1(S2Compile_getInstance().quote(postProg_2)), Companion_getInstance_20()._TOP._get_shortPath_());
      return eval_3;
    }
    Unit_getInstance();
    if (operator.equals(Companion_getInstance_22().to_6(this._QUOTE_ATOM))) {
      return prog;
    }var tmp_2 = rest(prog);
    var compiledArgs = toList_1(map(tmp_2, _no_name_provided_$factory_59(macroLookup, symbolTable, runProgram_0)));
    var r = Companion_getInstance_22().to_8(plus(listOf_0(operator), compiledArgs));
    var tmp_3;
    if (this._PASSTHRU_OPERATOR.contains_51(operator.asBig())) {
      tmp_3 = true;
    } else {
      var tmp_4 = operator.asString();
      tmp_3 = startsWith$default(tmp_4, '_', false, 2, null);
    }
    if (tmp_3) {
      return r;
    } else {
    }
    var tmp1_forEach_0 = asFlatListOfLists(symbolTable);
    var tmp0_iterator_1_1 = tmp1_forEach_0.iterator_66();
    while (tmp0_iterator_1_1.hasNext_41()) {
      var element_2_1 = tmp0_iterator_1_1.next_46();
      var symbol_4_0 = element_2_1.get_72(0)._storage;
      var value_5_0 = element_2_1.get_72(1)._storage;
      var tmp0_toUByteArray_0_6 = encodeToByteArray('*');
      if (contentEquals_0(symbol_4_0, _UByteArray___init__impl_(tmp0_toUByteArray_0_6.slice()))) {
        return r;
      } else {
      }
      if (contentEquals_0(symbol_4_0, asAtom)) {
        var newArgs_7 = S2Compile_getInstance().eval_0(Companion_getInstance_22().to_8(listOf(['opt', listOf(['com', S2Compile_getInstance().quote_1(plus(listOf_0('list'), toList_1(rest(prog)))), S2Compile_getInstance().quote(macroLookup), S2Compile_getInstance().quote(symbolTable)])])), Companion_getInstance_20()._TOP._get_shortPath_());
        return Companion_getInstance_22().to_8(listOf([S2Compile_getInstance()._APPLY_ATOM, new UByteArray(value_5_0), listOf([S2Compile_getInstance()._CONS_ATOM, new UByteArray(Companion_getInstance_20()._LEFT._get_shortPath_()), newArgs_7])]));
      }}
    throw new EvalError('' + 'Compile ' + dissasemble(progC) + ' failed. Unknown operator: ' + operator);
  };
  S2Compile.$metadata$ = {
    simpleName: 'S2Compile',
    kind: 'object',
    interfaces: []
  };
  var S2Compile_instance;
  function S2Compile_getInstance() {
    if (S2Compile_instance == null)
      new S2Compile();
    return S2Compile_instance;
  }
  function _no_name_provided_$factory_56() {
    var i = new _no_name_provided__75();
    return function (p1, p2, p3, p4, p5) {
      return i.invoke_124(p1, p2, p3, p4, p5);
    };
  }
  function _no_name_provided_$factory_57() {
    var i = new _no_name_provided__76();
    return function (p1, p2, p3, p4, p5) {
      return i.invoke_124(p1, p2, p3, p4, p5);
    };
  }
  function _no_name_provided_$factory_58() {
    var i = new _no_name_provided__77();
    return function (p1, p2, p3, p4, p5) {
      return i.invoke_124(p1, p2, p3, p4, p5);
    };
  }
  function _no_name_provided_$factory_59($macroLookup, $symbolTable, $runProgram) {
    var i = new _no_name_provided__78($macroLookup, $symbolTable, $runProgram);
    return function (p1) {
      return i.invoke_257(p1);
    };
  }
  function _no_name_provided__79($eval) {
    this._$eval = $eval;
  }
  _no_name_provided__79.prototype.invoke_257 = function (it) {
    return S2Optimize_getInstance().optimizeSexp(it, this._$eval);
  };
  _no_name_provided__79.prototype.invoke_1115 = function (p1) {
    return this.invoke_257(p1 instanceof SExp ? p1 : THROW_CCE());
  };
  _no_name_provided__79.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function Optimizer(name, lambda) {
    this._name_0 = name;
    this._lambda = lambda;
  }
  Optimizer.prototype.optimize = function (r, eval_0) {
    return this._lambda(r, eval_0);
  };
  Optimizer.$metadata$ = {
    simpleName: 'Optimizer',
    kind: 'class',
    interfaces: []
  };
  function nonNil($this, sExp) {
    var tmp;
    if (listp(sExp)) {
      tmp = true;
    } else {
      var tmp_0 = sExp._get_atom__4();
      tmp = _UByteArray___get_size__impl_(ensureNotNull(tmp_0 == null ? null : new UByteArray(tmp_0))._storage) > 0;
    }
    return tmp;
  }
  function seemsConstant($this, sExp) {
    if (!listp(sExp))
      return !nonNil($this, sExp);
    var operator = first_1(sExp);
    if (!listp(operator)) {
      var atom = operator.asBig();
      if (atom.equals($this._QUOTE_ATOM_0))
        return true;
      if (atom.equals($this._RAISE_KW))
        return false;
    } else if (!seemsConstant($this, operator)) {
      return false;
    }var tmp$ret$0;
    l$ret$1: do {
      var tmp0_all_0 = rest(sExp);
      var tmp0_iterator_1 = tmp0_all_0.iterator_66();
      while (tmp0_iterator_1.hasNext_41()) {
        var element_2 = tmp0_iterator_1.next_46();
        if (!seemsConstant(S2Optimize_getInstance(), element_2)) {
          tmp$ret$0 = false;
          break l$ret$1;
        } else {
        }
      }
      tmp$ret$0 = true;
    }
     while (false);
    return tmp$ret$0;
  }
  function isArgsCall($this, r) {
    return !listp(r) ? r.asBig().equals(Companion_getInstance_13()._ONE_0) : false;
  }
  function _no_name_provided__80() {
  }
  _no_name_provided__80.prototype.invoke_142 = function (r, eval_0) {
    var tmp = S2Optimize_getInstance()._CONS_OPTIMIZER_PATTERN_FIRST;
    var t1 = match$default(tmp, r, null, 4, null);
    if (!(t1 == null)) {
      return ensureNotNull(t1.get_56('first'));
    }var tmp_0 = S2Optimize_getInstance()._CONS_OPTIMIZER_PATTERN_REST;
    var t2 = match$default(tmp_0, r, null, 4, null);
    if (!(t2 == null)) {
      return ensureNotNull(t2.get_56('rest'));
    }return r;
  };
  _no_name_provided__80.prototype.invoke_915 = function (p1, p2) {
    var tmp = p1 instanceof SExp ? p1 : THROW_CCE();
    return this.invoke_142(tmp, p2 instanceof Evaluator ? p2 : THROW_CCE());
  };
  _no_name_provided__80.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__81() {
  }
  _no_name_provided__81.prototype.invoke_142 = function (r, eval_0) {
    if (seemsConstant(S2Optimize_getInstance(), r) ? nonNil(S2Optimize_getInstance(), r) : false) {
      var e = eval_0.evaluate_1(r, Companion_getInstance_22().___null__, null);
      return Companion_getInstance_22().to_1(S2Compile_getInstance().quote(e._second));
    } else {
      return r;
    }
  };
  _no_name_provided__81.prototype.invoke_915 = function (p1, p2) {
    var tmp = p1 instanceof SExp ? p1 : THROW_CCE();
    return this.invoke_142(tmp, p2 instanceof Evaluator ? p2 : THROW_CCE());
  };
  _no_name_provided__81.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__82() {
  }
  _no_name_provided__82.prototype.invoke_142 = function (r, eval_0) {
    var tmp = S2Optimize_getInstance()._CONS_Q_A_OPTIMIZER_PATTERN;
    var t1 = match$default(tmp, r, null, 4, null);
    return (!(t1 == null) ? isArgsCall(S2Optimize_getInstance(), ensureNotNull(t1.get_56('args'))) : false) ? ensureNotNull(t1.get_56('sexp')) : r;
  };
  _no_name_provided__82.prototype.invoke_915 = function (p1, p2) {
    var tmp = p1 instanceof SExp ? p1 : THROW_CCE();
    return this.invoke_142(tmp, p2 instanceof Evaluator ? p2 : THROW_CCE());
  };
  _no_name_provided__82.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__83() {
  }
  _no_name_provided__83.prototype.invoke_142 = function (r, eval_0) {
    if (!listp(r))
      return r;
    var operator = first_1(r);
    if (!listp(operator)) {
      var op = operator.asBig();
      if (op.equals(S2Optimize_getInstance()._QUOTE_ATOM_0)) {
        return r;
      }}var tmp = Companion_getInstance_22();
    return tmp.to_8(toList_1(map(r, _no_name_provided_$factory_67(eval_0))));
  };
  _no_name_provided__83.prototype.invoke_915 = function (p1, p2) {
    var tmp = p1 instanceof SExp ? p1 : THROW_CCE();
    return this.invoke_142(tmp, p2 instanceof Evaluator ? p2 : THROW_CCE());
  };
  _no_name_provided__83.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__84() {
  }
  _no_name_provided__84.prototype.invoke_142 = function (r, eval_0) {
    var tmp = S2Optimize_getInstance()._FIRST_ATOM_PATTERN;
    var t1 = match$default(tmp, r, null, 4, null);
    if (!(t1 == null) ? nonNil(S2Optimize_getInstance(), ensureNotNull(t1.get_56('atom'))) : false) {
      var node_0 = new NodePath(ensureNotNull(t1.get_56('atom')).asBig().intValue(true));
      node_0 = node_0.plus_39(Companion_getInstance_20()._LEFT);
      return Companion_getInstance_22().to_8(new UByteArray(node_0._get_shortPath_()));
    }var tmp_0 = S2Optimize_getInstance()._REST_ATOM_PATTERN;
    var t2 = match$default(tmp_0, r, null, 4, null);
    if (!(t2 == null) ? nonNil(S2Optimize_getInstance(), ensureNotNull(t2.get_56('atom'))) : false) {
      var node_1 = new NodePath(ensureNotNull(t2.get_56('atom')).asBig().intValue(true));
      node_1 = node_1.plus_39(Companion_getInstance_20()._RIGHT);
      return Companion_getInstance_22().to_8(new UByteArray(node_1._get_shortPath_()));
    }return r;
  };
  _no_name_provided__84.prototype.invoke_915 = function (p1, p2) {
    var tmp = p1 instanceof SExp ? p1 : THROW_CCE();
    return this.invoke_142(tmp, p2 instanceof Evaluator ? p2 : THROW_CCE());
  };
  _no_name_provided__84.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__85() {
  }
  _no_name_provided__85.prototype.invoke_142 = function (r, eval_0) {
    var tmp = S2Optimize_getInstance()._QUOTE_PATTERN_1;
    var t1 = match$default(tmp, r, null, 4, null);
    var tmp_0;
    if (!(t1 == null)) {
      tmp_0 = Companion_getInstance_22().to_4(0);
    } else {
      tmp_0 = r;
    }
    return tmp_0;
  };
  _no_name_provided__85.prototype.invoke_915 = function (p1, p2) {
    var tmp = p1 instanceof SExp ? p1 : THROW_CCE();
    return this.invoke_142(tmp, p2 instanceof Evaluator ? p2 : THROW_CCE());
  };
  _no_name_provided__85.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__86() {
  }
  _no_name_provided__86.prototype.invoke_142 = function (r, eval_0) {
    var tmp = S2Optimize_getInstance()._APPLY_NULL_PATTERN_1;
    var t1 = match$default(tmp, r, null, 4, null);
    return !(t1 == null) ? Companion_getInstance_22().___null__ : r;
  };
  _no_name_provided__86.prototype.invoke_915 = function (p1, p2) {
    var tmp = p1 instanceof SExp ? p1 : THROW_CCE();
    return this.invoke_142(tmp, p2 instanceof Evaluator ? p2 : THROW_CCE());
  };
  _no_name_provided__86.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function S2Optimize() {
    S2Optimize_instance = this;
    this._CONS_OPTIMIZER_PATTERN_FIRST = assemble('(f (c (: . first) (: . rest)))');
    this._CONS_OPTIMIZER_PATTERN_REST = assemble('(r (c (: . first) (: . rest)))');
    this._APPLY_KW_0 = ensureNotNull(Operators_getInstance()._KEYWORD_TO_ATOM.get_56('a'));
    this._FIRST_KW = ensureNotNull(Operators_getInstance()._KEYWORD_TO_ATOM.get_56('f'));
    this._REST_KW = ensureNotNull(Operators_getInstance()._KEYWORD_TO_ATOM.get_56('r'));
    this._CONS_KW_0 = ensureNotNull(Operators_getInstance()._KEYWORD_TO_ATOM.get_56('c'));
    this._RAISE_KW = ensureNotNull(Operators_getInstance()._KEYWORD_TO_ATOM.get_56('x'));
    this._QUOTE_ATOM_0 = ensureNotNull(Operators_getInstance()._KEYWORD_TO_ATOM.get_56('q'));
    this._APPLY_ATOM_0 = ensureNotNull(Operators_getInstance()._KEYWORD_TO_ATOM.get_56('a'));
    this._debugOptimization = false;
    var tmp = this;
    tmp._consOptimizer = new Optimizer('cons', _no_name_provided_$factory_60());
    var tmp_0 = this;
    tmp_0._constantOptimizer = new Optimizer('constant', _no_name_provided_$factory_61());
    this._CONS_Q_A_OPTIMIZER_PATTERN = assemble('(a (q . (: . sexp)) (: . args))');
    var tmp_1 = this;
    tmp_1._consQAOptimzier = new Optimizer('cons_q_a', _no_name_provided_$factory_62());
    var tmp_2 = this;
    tmp_2._childrenOptimizer = new Optimizer('children_optimizer', _no_name_provided_$factory_63());
    this._FIRST_ATOM_PATTERN = assemble('(f ($ . atom))');
    this._REST_ATOM_PATTERN = assemble('(r ($ . atom))');
    var tmp_3 = this;
    tmp_3._pathOptimizer = new Optimizer('path', _no_name_provided_$factory_64());
    this._QUOTE_PATTERN_1 = assemble('(q . 0)');
    var tmp_4 = this;
    tmp_4._quoteNullOptimizer = new Optimizer('quoteNull', _no_name_provided_$factory_65());
    this._APPLY_NULL_PATTERN_1 = assemble('(a 0 . (: . rest))');
    var tmp_5 = this;
    tmp_5._nullOptimizer = new Optimizer('null', _no_name_provided_$factory_66());
    this._OPTIMIZERS = listOf([this._consOptimizer, this._constantOptimizer, this._consQAOptimzier, this._childrenOptimizer, this._pathOptimizer, this._quoteNullOptimizer, this._nullOptimizer]);
  }
  S2Optimize.prototype.optimizeSexp = function (r, eval_0) {
    if (nullp(r) ? true : !listp(r)) {
      return r;
    }var optimizedR = r;
    $l$break: while (listp(optimizedR)) {
      var initial = optimizedR;
      var tmp$ret$0;
      l$ret$1: do {
        var tmp0_firstOrNull_0 = this._OPTIMIZERS;
        var tmp0_iterator_1 = tmp0_firstOrNull_0.iterator_66();
        while (tmp0_iterator_1.hasNext_41()) {
          var element_2 = tmp0_iterator_1.next_46();
          optimizedR = element_2.optimize(optimizedR, eval_0);
          if (!optimizedR.equals(initial)) {
            tmp$ret$0 = element_2;
            break l$ret$1;
          } else {
          }
        }
        tmp$ret$0 = null;
      }
       while (false);
      var tmp0_elvis_lhs = tmp$ret$0;
      var tmp;
      if (tmp0_elvis_lhs == null) {
        break $l$break;
      } else {
        tmp = tmp0_elvis_lhs;
      }
      var optimizer = tmp;
      if (this._debugOptimization) {
        println('' + 'OPT-' + optimizer._name_0 + ': ' + initial + ' -> ' + optimizedR);
      }}
    return optimizedR;
  };
  S2Optimize.$metadata$ = {
    simpleName: 'S2Optimize',
    kind: 'object',
    interfaces: []
  };
  var S2Optimize_instance;
  function S2Optimize_getInstance() {
    if (S2Optimize_instance == null)
      new S2Optimize();
    return S2Optimize_instance;
  }
  function _no_name_provided_$factory_60() {
    var i = new _no_name_provided__80();
    return function (p1, p2) {
      return i.invoke_142(p1, p2);
    };
  }
  function _no_name_provided_$factory_61() {
    var i = new _no_name_provided__81();
    return function (p1, p2) {
      return i.invoke_142(p1, p2);
    };
  }
  function _no_name_provided_$factory_62() {
    var i = new _no_name_provided__82();
    return function (p1, p2) {
      return i.invoke_142(p1, p2);
    };
  }
  function _no_name_provided_$factory_63() {
    var i = new _no_name_provided__83();
    return function (p1, p2) {
      return i.invoke_142(p1, p2);
    };
  }
  function _no_name_provided_$factory_64() {
    var i = new _no_name_provided__84();
    return function (p1, p2) {
      return i.invoke_142(p1, p2);
    };
  }
  function _no_name_provided_$factory_65() {
    var i = new _no_name_provided__85();
    return function (p1, p2) {
      return i.invoke_142(p1, p2);
    };
  }
  function _no_name_provided_$factory_66() {
    var i = new _no_name_provided__86();
    return function (p1, p2) {
      return i.invoke_142(p1, p2);
    };
  }
  function _no_name_provided_$factory_67($eval) {
    var i = new _no_name_provided__79($eval);
    return function (p1) {
      return i.invoke_257(p1);
    };
  }
  function _no_name_provided__87($newLookup) {
    this._$newLookup = $newLookup;
    Evaluator.call(this);
  }
  _no_name_provided__87.prototype.evaluate_1 = function (program, args, maxCost) {
    var tmp = new OperatorMap(this._$newLookup);
    return runProgram$default(program, args, tmp, maxCost, null, 16, null);
  };
  _no_name_provided__87.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__88($macroDefaults, $runProg0) {
    this._$macroDefaults = $macroDefaults;
    this._$runProg0 = $runProg0;
  }
  _no_name_provided__88.prototype.invoke_257 = function (sexp) {
    var prog = first_3(sexp);
    var symTable = Companion_getInstance_22().___null__;
    var tmp;
    if (!nullp(rest(sexp))) {
      if (!nullp(rest(rest(sexp))))
        symTable = first_3(rest(rest(sexp)));
      tmp = first_3(rest(sexp));
    } else {
      tmp = this._$macroDefaults.defaultMacroLookup();
    }
    var macroLookup = tmp;
    return new Pair(Companion_getInstance_13()._ONE_0, S2Compile_getInstance().doComProg(prog, macroLookup, symTable, this._$runProg0));
  };
  _no_name_provided__88.prototype.invoke_1115 = function (p1) {
    return this.invoke_257(p1 instanceof SExp ? p1 : THROW_CCE());
  };
  _no_name_provided__88.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__89($runProg0) {
    this._$runProg0_0 = $runProg0;
  }
  _no_name_provided__89.prototype.invoke_257 = function (sexp) {
    return new Pair(Companion_getInstance_13()._ONE_0, S2Optimize_getInstance().optimizeSexp(first_3(sexp), this._$runProg0_0));
  };
  _no_name_provided__89.prototype.invoke_1115 = function (p1) {
    return this.invoke_257(p1 instanceof SExp ? p1 : THROW_CCE());
  };
  _no_name_provided__89.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__90($paths) {
    this._$paths = $paths;
  }
  _no_name_provided__90.prototype.invoke_257 = function (sexp) {
    var filename = first_3(sexp).asString();
    var tmp$ret$0;
    l$ret$1: do {
      var tmp0_iterator_1_1 = this._$paths.iterator_66();
      while (tmp0_iterator_1_1.hasNext_41()) {
        var element_2_2 = tmp0_iterator_1_1.next_46();
        var fPath_4 = '' + element_2_2 + '/' + filename;
        if (isFile(fPath_4)) {
          tmp$ret$0 = element_2_2;
          break l$ret$1;
        } else {
        }
      }
      tmp$ret$0 = null;
    }
     while (false);
    var tmp0_elvis_lhs = tmp$ret$0;
    var tmp;
    if (tmp0_elvis_lhs == null) {
      throw new EvalError('' + 'Cannot find or open ' + filename);
    } else {
      tmp = tmp0_elvis_lhs;
    }
    var test = tmp;
    return new Pair(Companion_getInstance_13()._ONE_0, Companion_getInstance_22().to_7('' + test + '/' + filename));
  };
  _no_name_provided__90.prototype.invoke_1115 = function (p1) {
    return this.invoke_257(p1 instanceof SExp ? p1 : THROW_CCE());
  };
  _no_name_provided__90.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__91() {
  }
  _no_name_provided__91.prototype.invoke_257 = function (sexp) {
    var f = first_3(sexp).asString();
    var bytes = readBytesOfResource(f);
    var asm = assemble(decodeToString(bytes));
    return new Pair(Companion_getInstance_13()._ONE_0, asm);
  };
  _no_name_provided__91.prototype.invoke_1115 = function (p1) {
    return this.invoke_257(p1 instanceof SExp ? p1 : THROW_CCE());
  };
  _no_name_provided__91.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__92() {
  }
  _no_name_provided__92.prototype.invoke_257 = function (sexp) {
    var tmp0_TODO_0 = 'Write not yet implemented for KtCLVM';
    throw new NotImplementedError('' + 'An operation is not implemented: ' + tmp0_TODO_0);
  };
  _no_name_provided__92.prototype.invoke_1115 = function (p1) {
    return this.invoke_257(p1 instanceof SExp ? p1 : THROW_CCE());
  };
  _no_name_provided__92.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__93($newLookup) {
    this._$newLookup_0 = $newLookup;
    Evaluator.call(this);
  }
  _no_name_provided__93.prototype.evaluate_1 = function (program, args, maxCost) {
    var tmp = new OperatorMap(this._$newLookup_0);
    return runProgram$default(program, args, tmp, maxCost, null, 16, null);
  };
  _no_name_provided__93.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function S2Run() {
    S2Run_instance = this;
  }
  S2Run.prototype.buildRunProgForSearchPath = function (paths) {
    var newLookup = Companion_getInstance_23().from(Operators_getInstance()._OPERATOR_LOOKUP);
    var runProg0 = new _no_name_provided__87(newLookup);
    var macroDefaults = new MacroDefaults(runProg0);
    var tmp = buildOpByName('com', _no_name_provided_$factory_68(macroDefaults, runProg0));
    var tmp_0 = buildOpByName('opt', _no_name_provided_$factory_69(runProg0));
    var tmp_1 = buildOpByName('_full_path_for_name', _no_name_provided_$factory_70(paths));
    var tmp_2 = buildOpByName('_read', _no_name_provided_$factory_71());
    var tmp0_map_0 = listOf([tmp, tmp_0, tmp_1, tmp_2, buildOpByName('_write', _no_name_provided_$factory_72())]);
    var tmp0_mapTo_0_1 = ArrayList_init_$Create$_0(collectionSizeOrDefault(tmp0_map_0, 10));
    var tmp0_iterator_1_2 = tmp0_map_0.iterator_66();
    while (tmp0_iterator_1_2.hasNext_41()) {
      var item_2_3 = tmp0_iterator_1_2.next_46();
      tmp0_mapTo_0_1.add_39(new Pair(ensureNotNull(item_2_3._get_opCode__0()), item_2_3));
      Unit_getInstance();
    }
    var bindings = tmp0_mapTo_0_1;
    putAll_0(_OperatorMap___get_map__impl_(newLookup), bindings);
    return new _no_name_provided__93(newLookup);
  };
  S2Run.$metadata$ = {
    simpleName: 'S2Run',
    kind: 'object',
    interfaces: []
  };
  var S2Run_instance;
  function S2Run_getInstance() {
    if (S2Run_instance == null)
      new S2Run();
    return S2Run_instance;
  }
  function _no_name_provided_$factory_68($macroDefaults, $runProg0) {
    var i = new _no_name_provided__88($macroDefaults, $runProg0);
    return function (p1) {
      return i.invoke_257(p1);
    };
  }
  function _no_name_provided_$factory_69($runProg0) {
    var i = new _no_name_provided__89($runProg0);
    return function (p1) {
      return i.invoke_257(p1);
    };
  }
  function _no_name_provided_$factory_70($paths) {
    var i = new _no_name_provided__90($paths);
    return function (p1) {
      return i.invoke_257(p1);
    };
  }
  function _no_name_provided_$factory_71() {
    var i = new _no_name_provided__91();
    return function (p1) {
      return i.invoke_257(p1);
    };
  }
  function _no_name_provided_$factory_72() {
    var i = new _no_name_provided__92();
    return function (p1) {
      return i.invoke_257(p1);
    };
  }
  function Evaluator() {
  }
  Evaluator.prototype.evaluate$default_1 = function (program, args, maxCost, $mask0, $handler) {
    if (!(($mask0 & 4) === 0))
      maxCost = null;
    return $handler == null ? this.evaluate_1(program, args, maxCost) : $handler(program, args, maxCost);
  };
  Evaluator.$metadata$ = {
    simpleName: 'Evaluator',
    kind: 'class',
    interfaces: []
  };
  function CompileBinding(name, lambda) {
    this._name_1 = name;
    this._lambda_0 = lambda;
  }
  CompileBinding.prototype.call = function (args, macroLookup, symbolTable, evaluator, level) {
    return this._lambda_0(args, macroLookup, symbolTable, evaluator, level);
  };
  CompileBinding.prototype.toString = function () {
    return '' + 'CompileBinding(' + this._name_1 + ')';
  };
  CompileBinding.$metadata$ = {
    simpleName: 'CompileBinding',
    kind: 'class',
    interfaces: []
  };
  function composePath($this, path0i, path1i) {
    var mask = 1;
    var path0 = path0i;
    var path1 = path1i;
    var tempPath = path0;
    while (tempPath > 1) {
      path1 = path1 << 1;
      mask = mask << 1;
      tempPath = tempPath >> 1;
    }
    mask = mask - 1 | 0;
    return path1 | path0 & mask;
  }
  function Companion_21() {
    Companion_instance_20 = this;
    this._TOP = new NodePath(1);
    this._LEFT = this._TOP._get_first__1();
    this._RIGHT = this._TOP._get_rest__0();
  }
  Companion_21.$metadata$ = {
    simpleName: 'Companion',
    kind: 'object',
    interfaces: []
  };
  var Companion_instance_20;
  function Companion_getInstance_20() {
    if (Companion_instance_20 == null)
      new Companion_21();
    return Companion_instance_20;
  }
  function NodePath(startIndex) {
    Companion_getInstance_20();
    this._ARGS_KW = ensureNotNull(Operators_getInstance()._KEYWORD_TO_ATOM.get_56('a'));
    this._FIRST_KW_0 = ensureNotNull(Operators_getInstance()._KEYWORD_TO_ATOM.get_56('f'));
    this._REST_KW_0 = ensureNotNull(Operators_getInstance()._KEYWORD_TO_ATOM.get_56('f'));
    if (startIndex < 0) {
      var byteCount = (bitCount_0(startIndex) + 7 | 0) >> 3;
      var blob = toBytes_2(startIndex, byteCount);
      var tmp = this;
      var tmp0_ubyteArrayOf_0 = _UByteArray___init__impl_(new Int8Array([_UByte___init__impl_(0)]));
      var tmp1_plus_0 = tmp0_ubyteArrayOf_0;
      var tmp0_plus_0_1 = _UByteArray___get_storage__impl_(tmp1_plus_0);
      var tmp1_plus_0_2 = _UByteArray___get_storage__impl_(blob);
      var tmp2_toInt_0 = readUInt(_UByteArray___init__impl_(primitiveArrayConcat([tmp0_plus_0_1, tmp1_plus_0_2])));
      tmp._index_7 = _UInt___get_data__impl_(tmp2_toInt_0);
    } else {
      this._index_7 = startIndex;
    }
  }
  NodePath.prototype._get_first__1 = function () {
    return new NodePath(imul(this._index_7, 2));
  };
  NodePath.prototype._get_rest__0 = function () {
    return new NodePath(imul(this._index_7, 2) + 1 | 0);
  };
  NodePath.prototype._get_shortPath_ = function () {
    var byteCount = bytesRequired_0(this._index_7);
    return toBytes_2(this._index_7, byteCount);
  };
  NodePath.prototype.plus_39 = function (other) {
    return new NodePath(composePath(this, this._index_7, other._index_7));
  };
  NodePath.prototype.toString = function () {
    return '' + 'NodePath: ' + this._index_7;
  };
  NodePath.$metadata$ = {
    simpleName: 'NodePath',
    kind: 'class',
    interfaces: []
  };
  function OpStackCallable(name, l) {
    this._name_2 = name;
    this._l = l;
  }
  OpStackCallable.prototype.call_0 = function (opStack, valStack) {
    return this._l(opStack, valStack);
  };
  OpStackCallable.prototype.toString = function () {
    return '' + 'OpStackCallable(' + this._name_2 + ')';
  };
  OpStackCallable.$metadata$ = {
    simpleName: 'OpStackCallable',
    kind: 'class',
    interfaces: []
  };
  function assemble(string) {
    var irSymbols = readIr$default(string, null, 2, null);
    return assembleFromIr(irSymbols);
  }
  function assembleFromIr(irSExp) {
    var keyword = Utils_getInstance().irAsSymbol(irSExp);
    if (!(keyword == null)) {
      if (first_2(keyword).equals(new Char(35))) {
        keyword = drop_1(keyword, 1);
      }var atom = Operators_getInstance()._KEYWORD_TO_ATOM.get_56(keyword);
      if (!(atom == null)) {
        return Companion_getInstance_22().to_6(atom);
      } else {
        return Utils_getInstance().irVal(irSExp);
      }
    }if (!Utils_getInstance().irListp(irSExp)) {
      return Utils_getInstance().irVal(irSExp);
    }if (Utils_getInstance().irNullp(irSExp)) {
      return Companion_getInstance_22().___null__;
    }var first_4 = Utils_getInstance().irFirst(irSExp);
    var sexp1 = assembleFromIr(first_4);
    var sexp2 = assembleFromIr(Utils_getInstance().irRest(irSExp));
    return sexp1.cons_3(sexp2);
  }
  function dissasemble(sExp) {
    var symbols = dissasembleToIr$default(sExp, null, 2, null);
    return writeIr(symbols);
  }
  function dissasembleToIr(sExp, iAllowKeybaord) {
    var allowKeyword = iAllowKeybaord;
    if (Utils_getInstance().isIr(sExp) ? allowKeyword == null ? true : allowKeyword : false) {
      var tmp = Utils_getInstance();
      var tmp_0 = Companion_getInstance_22().to_1(Utils_getInstance().irSymbol('ir'));
      return tmp.irCons$default(tmp_0, sExp, null, 4, null);
    }if (listp(sExp)) {
      if (listp(first_1(sExp)) ? true : allowKeyword == null)
        allowKeyword = true;
      var v0 = dissasembleToIr(first_1(sExp), allowKeyword);
      var v1 = dissasembleToIr(rest(sExp), false);
      var tmp_1 = Utils_getInstance();
      return tmp_1.irCons$default(v0, v1, null, 4, null);
    }var tmp_2 = sExp._get_atom__4();
    var atom = ensureNotNull(tmp_2 == null ? null : new UByteArray(tmp_2))._storage;
    if (!(allowKeyword == null) ? allowKeyword : false) {
      var atomBig = Companion_getInstance_13().fromUByteArray(atom, Sign_POSITIVE_getInstance());
      var v = Operators_getInstance()._KEYWORD_FROM_ATOM.get_56(atomBig);
      if (!(v == null) ? !(v === '.') : false)
        return Companion_getInstance_22().to_1(Utils_getInstance().irSymbol(v));
    }if (nullp(sExp)) {
      return Utils_getInstance().irNull();
    }return Companion_getInstance_22().to_1(new Pair(typeForAtom(atom), new UByteArray(atom)));
  }
  function dissasembleToIr$default(sExp, iAllowKeybaord, $mask0, $handler) {
    if (!(($mask0 & 2) === 0))
      iAllowKeybaord = null;
    return dissasembleToIr(sExp, iAllowKeybaord);
  }
  function AtomOrPair() {
  }
  AtomOrPair.$metadata$ = {
    simpleName: 'AtomOrPair',
    kind: 'interface',
    interfaces: []
  };
  function ClvmObject_init_$Init$(atom, $this) {
    ClvmObject.call($this, atom, null, ClvmObjectType_ATOM_getInstance());
    return $this;
  }
  function ClvmObject_init_$Create$(atom) {
    return ClvmObject_init_$Init$(atom, Object.create(ClvmObject.prototype));
  }
  function ClvmObject_init_$Init$_0(pair, $this) {
    ClvmObject.call($this, null, pair, ClvmObjectType_PAIR_getInstance());
    return $this;
  }
  function ClvmObject_init_$Create$_0(pair) {
    return ClvmObject_init_$Init$_0(pair, Object.create(ClvmObject.prototype));
  }
  function Companion_22() {
    Companion_instance_21 = this;
  }
  Companion_22.prototype.cast = function (any) {
    if (any instanceof Pair) {
      var tmp;
      var tmp_0 = any._first;
      if (!(tmp_0 == null) ? isInterface(tmp_0, AtomOrPair) : false) {
        var tmp_1 = any._second;
        tmp = !(tmp_1 == null) ? isInterface(tmp_1, AtomOrPair) : false;
      } else {
        {
          tmp = false;
        }
      }
      if (tmp) {
        return ClvmObject_init_$Create$_0(any instanceof Pair ? any : THROW_CCE());
      } else {
      }
    } else {
      if (any instanceof UByteArray) {
        return ClvmObject_init_$Create$(any._storage);
      } else {
        if (isInterface(any, AtomOrPair)) {
          return any;
        } else {
        }
      }
    }
    throw IllegalStateException_init_$Create$_0('' + 'Cannot cast: ' + getKClassFromExpression_0(any)._get_simpleName__4());
  };
  Companion_22.$metadata$ = {
    simpleName: 'Companion',
    kind: 'object',
    interfaces: []
  };
  var Companion_instance_21;
  function Companion_getInstance_21() {
    if (Companion_instance_21 == null)
      new Companion_22();
    return Companion_instance_21;
  }
  function ClvmObject(_atom, _pair, type) {
    Companion_getInstance_21();
    this.__atom = _atom;
    this.__pair = _pair;
    this._type = type;
  }
  ClvmObject.prototype._get_atom__4 = function () {
    var tmp;
    if (this._type.equals(ClvmObjectType_ATOM_getInstance())) {
      var tmp_0 = this.__atom;
      tmp = ensureNotNull(tmp_0 == null ? null : new UByteArray(tmp_0))._storage;
    } else {
      tmp = null;
    }
    return tmp;
  };
  ClvmObject.prototype._get_pair__3 = function () {
    return this._type.equals(ClvmObjectType_PAIR_getInstance()) ? ensureNotNull(this.__pair) : null;
  };
  ClvmObject.prototype._get_atomOrPair__3 = function () {
    var tmp;
    if (this._type.equals(ClvmObjectType_ATOM_getInstance())) {
      var tmp_0 = this.__atom;
      tmp = ensureNotNull(tmp_0 == null ? null : new UByteArray(tmp_0));
    } else {
      tmp = ensureNotNull(this._get_pair__3());
    }
    return tmp;
  };
  ClvmObject.prototype.cons_3 = function (right) {
    return ClvmObject_init_$Create$_0(new Pair(this, right));
  };
  ClvmObject.prototype.toString = function () {
    var tmp = this.__atom;
    return '' + 'ClvmObject(_atom=' + (tmp == null ? null : new UByteArray(tmp)) + ', _pair=' + this.__pair + ', type=' + this._type + ')';
  };
  ClvmObject.prototype.hashCode = function () {
    var tmp;
    var tmp_0 = this.__atom;
    if ((tmp_0 == null ? null : new UByteArray(tmp_0)) == null) {
      tmp = 0;
    } else {
      {
        tmp = UByteArray__hashCode_impl(this.__atom);
      }
    }
    var result = tmp;
    result = imul(result, 31) + (this.__pair == null ? 0 : this.__pair.hashCode()) | 0;
    result = imul(result, 31) + this._type.hashCode() | 0;
    return result;
  };
  ClvmObject.prototype.equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof ClvmObject))
      return false;
    else {
    }
    var tmp0_other_with_cast = other instanceof ClvmObject ? other : THROW_CCE();
    var tmp = this.__atom;
    var tmp_0 = tmp == null ? null : new UByteArray(tmp);
    var tmp_1 = tmp0_other_with_cast.__atom;
    if (!equals_0(tmp_0, tmp_1 == null ? null : new UByteArray(tmp_1)))
      return false;
    else {
    }
    if (!equals_0(this.__pair, tmp0_other_with_cast.__pair))
      return false;
    if (!this._type.equals(tmp0_other_with_cast._type))
      return false;
    return true;
  };
  ClvmObject.$metadata$ = {
    simpleName: 'ClvmObject',
    kind: 'class',
    interfaces: [AtomOrPair]
  };
  var ClvmObjectType_ATOM_instance;
  var ClvmObjectType_PAIR_instance;
  var ClvmObjectType_entriesInitialized;
  function ClvmObjectType_initEntries() {
    if (ClvmObjectType_entriesInitialized)
      return Unit_getInstance();
    ClvmObjectType_entriesInitialized = true;
    ClvmObjectType_ATOM_instance = new ClvmObjectType('ATOM', 0);
    ClvmObjectType_PAIR_instance = new ClvmObjectType('PAIR', 1);
  }
  function ClvmObjectType(name, ordinal) {
    Enum.call(this, name, ordinal);
  }
  ClvmObjectType.$metadata$ = {
    simpleName: 'ClvmObjectType',
    kind: 'class',
    interfaces: []
  };
  function ClvmObjectType_ATOM_getInstance() {
    ClvmObjectType_initEntries();
    return ClvmObjectType_ATOM_instance;
  }
  function ClvmObjectType_PAIR_getInstance() {
    ClvmObjectType_initEntries();
    return ClvmObjectType_PAIR_instance;
  }
  function SExp_init_$Init$(atom, $this) {
    SExp.call($this, ClvmObject_init_$Create$(atom));
    return $this;
  }
  function SExp_init_$Create$(atom) {
    return SExp_init_$Init$(atom, Object.create(SExp.prototype));
  }
  function Companion_23() {
    Companion_instance_22 = this;
    this.___null__ = SExp_init_$Create$(_UByteArray___init__impl__0(0));
    var tmp = this;
    var tmp0_UByteArray_0 = 1;
    var tmp_1 = 0;
    var tmp_2 = tmp0_UByteArray_0;
    var tmp_3 = new Int8Array(tmp_2);
    while (tmp_1 < tmp_2) {
      var tmp_4 = tmp_1;
      var tmp0_toUByte_0_7 = _UInt___init__impl_(1);
      var tmp0_toUByte_0_1_8 = _UInt___get_data__impl_(tmp0_toUByte_0_7);
      var tmp0_toByte_0_5 = _UByte___init__impl_(toByte(tmp0_toUByte_0_1_8));
      tmp_3[tmp_4] = _UByte___get_data__impl_(tmp0_toByte_0_5);
      tmp_1 = tmp_1 + 1 | 0;
    }
    tmp.___true__ = SExp_init_$Create$(_UByteArray___init__impl_(tmp_3));
    this.___false__ = this.___null__;
  }
  Companion_23.prototype.to_0 = function (that) {
    return toImp(this, that);
  };
  Companion_23.prototype.to_1 = function (that) {
    return toImp(this, that);
  };
  Companion_23.prototype.to_2 = function (that) {
    return toImp(this, that);
  };
  Companion_23.prototype.to_3 = function (that) {
    return toImp(this, that);
  };
  Companion_23.prototype.to_4 = function (that) {
    return toImp(this, that);
  };
  Companion_23.prototype.to_5 = function (that) {
    return toImp(this, that);
  };
  Companion_23.prototype.to_6 = function (that) {
    return toImp(this, that);
  };
  Companion_23.prototype.to_7 = function (that) {
    return toImp(this, that);
  };
  Companion_23.prototype.to_8 = function (that) {
    return toImp(this, that);
  };
  Companion_23.$metadata$ = {
    simpleName: 'Companion',
    kind: 'object',
    interfaces: []
  };
  var Companion_instance_22;
  function Companion_getInstance_22() {
    if (Companion_instance_22 == null)
      new Companion_23();
    return Companion_instance_22;
  }
  function _no_name_provided__94(this$0, resultContinuation) {
    this._this$0_19 = this$0;
    CoroutineImpl_0.call(this, resultContinuation);
  }
  _no_name_provided__94.prototype.invoke_154 = function ($this$sequence, $cont) {
    var tmp = this.create_1($this$sequence, $cont);
    tmp._result_0 = Unit_getInstance();
    tmp._exception_0 = null;
    return tmp.doResume_17();
  };
  _no_name_provided__94.prototype.doResume_17 = function () {
    var suspendResult = this._result_0;
    $sm: do
      try {
        var tmp = this._state_2;
        switch (tmp) {
          case 0:
            this._exceptionState = 4;
            this._v0 = this._this$0_19;
            this._state_2 = 1;
            continue $sm;
          case 1:
            if (!!nullp(this._v0)) {
              this._state_2 = 3;
              continue $sm;
            }
            this._state_2 = 2;
            suspendResult = this._$this$sequence_0.yield_0(first_3(this._v0), this);
            if (suspendResult === _get_COROUTINE_SUSPENDED_()) {
              return suspendResult;
            }
            continue $sm;
          case 2:
            suspendResult;
            this._v0 = rest(this._v0);
            this._state_2 = 1;
            continue $sm;
          case 3:
            return Unit_getInstance();
          case 4:
            throw this._exception_0;
        }
      } catch ($p) {
        if (this._exceptionState === 4) {
          throw $p;
        } else {
          this._state_2 = this._exceptionState;
          this._exception_0 = $p;
        }
      }
     while (true);
  };
  _no_name_provided__94.prototype.create_1 = function ($this$sequence, completion) {
    var i = new _no_name_provided__94(this._this$0_19, completion);
    i._$this$sequence_0 = $this$sequence;
    return i;
  };
  _no_name_provided__94.$metadata$ = {
    kind: 'class',
    interfaces: [],
    suspendArity: 1
  };
  function SExp(_object) {
    Companion_getInstance_22();
    this.__object = _object;
    var tmp = this;
    tmp._sequence_3 = sequence(_no_name_provided_$factory_73(this, null));
  }
  SExp.prototype._get_atom__4 = function () {
    return this.__object._get_atom__4();
  };
  SExp.prototype._get_atomOrPair__3 = function () {
    return this.__object._get_atomOrPair__3();
  };
  SExp.prototype._get_pair__3 = function () {
    return this.__object._get_pair__3();
  };
  SExp.prototype.cons_3 = function (right) {
    return new SExp(this.__object.cons_3(right));
  };
  SExp.prototype._get_asByteArraySequence_ = function () {
    return new SexpByteSequence(this);
  };
  SExp.prototype._get_hex_ = function () {
    var bytes = toUByteArray(toList_1(flatten_0(this._get_asByteArraySequence_())));
    return toHexString(bytes);
  };
  SExp.prototype._get_treeHash_ = function () {
    return sha256TreeHash$default(this, null, 2, null);
  };
  SExp.prototype.asString = function () {
    var tmp = this._get_atom__4();
    var tmp0_toByteArray_0 = ensureNotNull(tmp == null ? null : new UByteArray(tmp))._storage;
    var tmp0_copyOf_0_1 = _UByteArray___get_storage__impl_(tmp0_toByteArray_0);
    return decodeToString(tmp0_copyOf_0_1.slice());
  };
  SExp.prototype.iterator_66 = function () {
    return this._sequence_3.iterator_66();
  };
  SExp.prototype.equals = function (other) {
    var tmp;
    try {
      tmp = toImp(Companion_getInstance_22(), other);
    } catch ($p) {
      var tmp_0;
      if ($p instanceof Exception) {
        return false;
      } else {
        {
          throw $p;
        }
      }
      tmp = tmp_0;
    }
    var test = tmp;
    var compareStack = ArrayList_init_$Create$();
    push(compareStack, [new Pair(this, test)]);
    while (compareStack._get_size__52() > 0) {
      var pair = pop(compareStack);
      var p1 = pair._first._get_pair__3();
      if (!(p1 == null)) {
        var p2 = pair._second._get_pair__3();
        if (!(p2 == null)) {
          push(compareStack, [new Pair(p1._first, p2._first)]);
          push(compareStack, [new Pair(p1._second, p2._second)]);
        } else {
          return false;
        }
      } else {
        var tmp_1;
        if (!(pair._second._get_pair__3() == null)) {
          tmp_1 = true;
        } else {
          var tmp_2 = pair._first._get_atom__4();
          var tmp_3 = ensureNotNull(tmp_2 == null ? null : new UByteArray(tmp_2))._storage;
          var tmp_4 = pair._second._get_atom__4();
          tmp_1 = !contentEquals_0(tmp_3, ensureNotNull(tmp_4 == null ? null : new UByteArray(tmp_4))._storage);
        }
        if (tmp_1) {
          return false;
        } else {
        }
      }
    }
    return true;
  };
  SExp.prototype.asBig = function () {
    var tmp = Companion_getInstance_13();
    var tmp_0 = this._get_atom__4();
    var tmp0_toByteArray_0 = ensureNotNull(tmp_0 == null ? null : new UByteArray(tmp_0))._storage;
    var tmp0_copyOf_0_1 = _UByteArray___get_storage__impl_(tmp0_toByteArray_0);
    return fromTwosComplementByteArray(tmp, tmp0_copyOf_0_1.slice());
  };
  SExp.prototype.toString = function () {
    return '' + 'SExp(' + this._get_hex_() + ')';
  };
  SExp.prototype.hashCode = function () {
    return UByteArray__hashCode_impl(this._get_treeHash_());
  };
  SExp.$metadata$ = {
    simpleName: 'SExp',
    kind: 'class',
    interfaces: [Sequence, AtomOrPair]
  };
  function toImp(_this_, from) {
    if (from instanceof SExp)
      return from;
    else {
    }
    var v = from;
    var stack = ArrayList_init_$Create$_1(listOf_0(v));
    var ops = ArrayList_init_$Create$();
    push(ops, [new Pair(0, null)]);
    while (ops._get_size__52() > 0) {
      var p = pop(ops);
      var op = p._first;
      var tmp0_subject = op;
      switch (tmp0_subject) {
        case 0:
          v = pop(stack);
          if (v instanceof Pair) {
            var left = v._first;
            var right = v._second;
            var target_0 = stack._get_size__52();
            push(stack, [new Pair(left, right)]);
            if (!(!(right == null) ? isInterface(right, AtomOrPair) : false)) {
              push(stack, [right]);
              push(ops, [new Pair(2, target_0)]);
              push(ops, [new Pair(0, null)]);
            } else {
            }
            if (!(!(left == null) ? isInterface(left, AtomOrPair) : false)) {
              push(stack, [left]);
              push(ops, [new Pair(1, target_0)]);
              push(ops, [new Pair(0, null)]);
            } else {
            }
          } else {
            if (!(v == null) ? isInterface(v, AtomOrPair) : false) {
              push(stack, [v._get_atomOrPair__3()]);
            } else {
              if (v instanceof UByteArray) {
                push(stack, [v]);
              } else {
                if (!(v == null) ? isByteArray(v) : false) {
                  var tmp0_toUByteArray_0 = v;
                  push(stack, [new UByteArray(_UByteArray___init__impl_(tmp0_toUByteArray_0.slice()))]);
                } else {
                  if (v instanceof G1Element) {
                    push(stack, [new UByteArray(v.toUByteArray_10())]);
                  } else {
                    if (!(v == null) ? typeof v === 'number' : false) {
                      push(stack, [new UByteArray(toTrimmed2sCompUbyteARray(BigInteger_init_$Create$_0(v)))]);
                    } else {
                      if (v instanceof UInt) {
                        var tmp = v._data_0;
                        push(stack, [new UByteArray(trimSigned(toUByteArray(new UByteArray(toBytes$default(tmp, 0, 1, null)))))]);
                      } else {
                        if (v instanceof Long) {
                          push(stack, [new UByteArray(toTrimmed2sCompUbyteARray(BigInteger_init_$Create$(v)))]);
                        } else {
                          if (v instanceof ULong) {
                            var tmp_0 = v._data_1;
                            push(stack, [new UByteArray(trimSigned(toUByteArray(new UByteArray(toBytes$default_0(tmp_0, 0, 1, null)))))]);
                          } else {
                            if (!(v == null) ? typeof v === 'string' : false) {
                              var tmp1_toUByteArray_0 = encodeToByteArray(v);
                              push(stack, [new UByteArray(_UByteArray___init__impl_(tmp1_toUByteArray_0.slice()))]);
                            } else {
                              if (v == null) {
                                push(stack, [_this_.___null__]);
                              } else {
                                if (v instanceof BigInteger) {
                                  push(stack, [new UByteArray(toTrimmed2sCompUbyteARray(v))]);
                                } else {
                                  if (!(v == null) ? isInterface(v, Collection) : false) {
                                    var target_1 = stack._get_size__52();
                                    push(stack, [_this_.___null__]);
                                    var tmp2_forEach_0 = (!(v == null) ? isInterface(v, Iterable) : false) ? v : THROW_CCE();
                                    var tmp0_iterator_1 = tmp2_forEach_0.iterator_66();
                                    while (tmp0_iterator_1.hasNext_41()) {
                                      var element_2 = tmp0_iterator_1.next_46();
                                      push(stack, [element_2]);
                                      push(ops, [new Pair(3, target_1)]);
                                      if (!(!(element_2 == null) ? isInterface(element_2, AtomOrPair) : false)) {
                                        push(ops, [new Pair(0, null)]);
                                      } else {
                                      }
                                    }
                                  } else {
                                    {
                                      throw IllegalArgumentException_init_$Create$_0('' + 'SEXP does not support ' + v + ' (' + getKClassFromExpression_0(v) + ')');
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }

          break;
        case 1:
          var target_2 = ensureNotNull(p._second);
          var tmp_1 = Companion_getInstance_21().cast(ensureNotNull(pop(stack)));
          var tmp_2 = stack.get_72(target_2);
          stack.set_3(target_2, new Pair(tmp_1, (tmp_2 instanceof Pair ? tmp_2 : THROW_CCE())._second));
          Unit_getInstance();
          break;
        case 2:
          var target_3 = ensureNotNull(p._second);
          var tmp_3 = stack.get_72(target_3);
          stack.set_3(target_3, new Pair((tmp_3 instanceof Pair ? tmp_3 : THROW_CCE())._first, Companion_getInstance_21().cast(ensureNotNull(pop(stack)))));
          Unit_getInstance();
          break;
        case 3:
          var target_4 = ensureNotNull(p._second);
          var tmp_4 = stack.get_72(target_4);
          stack.set_3(target_4, new Pair(Companion_getInstance_21().cast(ensureNotNull(pop(stack))), Companion_getInstance_21().cast(ensureNotNull(tmp_4))));
          Unit_getInstance();
          break;
      }
    }
    var result = stack.get_72(0);
    if (result == null ? true : !(stack._get_size__52() === 1)) {
      if (result == null) {
        throw IllegalStateException_init_$Create$_0('internal error: Null');
      } else {
        throw IllegalStateException_init_$Create$_0('' + 'internal error: ' + getKClassFromExpression_0(result)._get_simpleName__4());
      }
    }return new SExp(Companion_getInstance_21().cast(result));
  }
  function _no_name_provided_$factory_73(this$0, resultContinuation) {
    var i = new _no_name_provided__94(this$0, resultContinuation);
    var l = function (p1, $cont) {
      return i.invoke_154(p1, $cont);
    };
    l.$arity = 1;
    return l;
  }
  function first_3(_this_) {
    var tmp = Companion_getInstance_22();
    var tmp0_safe_receiver = _this_._get_pair__3();
    var tmp1_elvis_lhs = tmp0_safe_receiver == null ? null : tmp0_safe_receiver._first;
    var tmp_0;
    if (tmp1_elvis_lhs == null) {
      throw new EvalError('First of non cons');
    } else {
      tmp_0 = tmp1_elvis_lhs;
    }
    return tmp.to_2(tmp_0);
  }
  function rest(_this_) {
    var tmp = Companion_getInstance_22();
    var tmp0_safe_receiver = _this_._get_pair__3();
    var tmp1_elvis_lhs = tmp0_safe_receiver == null ? null : tmp0_safe_receiver._second;
    var tmp_0;
    if (tmp1_elvis_lhs == null) {
      throw new EvalError('First of non cons');
    } else {
      tmp_0 = tmp1_elvis_lhs;
    }
    return tmp.to_2(tmp_0);
  }
  function nullp(_this_) {
    var tmp0_safe_receiver = _this_._get_atom__4();
    var tmp;
    var tmp_0 = tmp0_safe_receiver;
    if ((tmp_0 == null ? null : new UByteArray(tmp_0)) == null) {
      tmp = null;
    } else {
      {
        tmp = UByteArray__isEmpty_impl(tmp0_safe_receiver);
      }
    }
    var tmp1_elvis_lhs = tmp;
    return tmp1_elvis_lhs == null ? false : tmp1_elvis_lhs;
  }
  function listp(_this_) {
    return !(_this_._get_pair__3() == null);
  }
  function listLen(_this_) {
    var v = _this_;
    var size_0 = 0;
    while (!(v._get_pair__3() == null)) {
      size_0 = size_0 + 1 | 0;
      v = ensureNotNull(v._get_pair__3())._second;
    }
    return size_0;
  }
  function asFlatListOfLists(_this_) {
    var x = map(_this_, _no_name_provided_$factory_74());
    return toList_1(x);
  }
  function asFlatList(_this_) {
    var tmp;
    if (listp(_this_)) {
      tmp = plus(asFlatList(first_3(_this_)), asFlatList(rest(_this_)));
    } else {
      var tmp_0 = _this_._get_atom__4();
      tmp = listOf_0(tmp_0 == null ? null : new UByteArray(tmp_0));
    }
    var x = tmp;
    var tmp0_mapTo_0_1 = ArrayList_init_$Create$_0(collectionSizeOrDefault(x, 10));
    var tmp0_iterator_1_2 = x.iterator_66();
    while (tmp0_iterator_1_2.hasNext_41()) {
      var tmp_1 = tmp0_iterator_1_2.next_46();
      var item_2_3 = tmp_1 == null ? null : tmp_1._storage;
      var tmp0_elvis_lhs_5 = item_2_3;
      var tmp_2;
      var tmp_3 = tmp0_elvis_lhs_5;
      if ((tmp_3 == null ? null : new UByteArray(tmp_3)) == null) {
        throw IllegalStateException_init_$Create$_0('Null atom is impossible');
      } else {
        {
          tmp_2 = tmp0_elvis_lhs_5;
        }
      }
      tmp0_mapTo_0_1.add_39(new UByteArray(tmp_2));
      Unit_getInstance();
    }
    return tmp0_mapTo_0_1;
  }
  function _no_name_provided__95() {
  }
  _no_name_provided__95.prototype.invoke_257 = function (it) {
    return asFlatList(it);
  };
  _no_name_provided__95.prototype.invoke_1115 = function (p1) {
    return this.invoke_257(p1 instanceof SExp ? p1 : THROW_CCE());
  };
  _no_name_provided__95.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided_$factory_74() {
    var i = new _no_name_provided__95();
    return function (p1) {
      return i.invoke_257(p1);
    };
  }
  function _no_name_provided__96(this$0) {
    this._this$0_20 = this$0;
    this._todoStack = ArrayList_init_$Create$();
    push(this._todoStack, [this._this$0_20._sexp]);
  }
  _no_name_provided__96.prototype.hasNext_41 = function () {
    var tmp0_isNotEmpty_0 = this._todoStack;
    return !tmp0_isNotEmpty_0.isEmpty_44();
  };
  _no_name_provided__96.prototype.next_33 = function () {
    if (!this.hasNext_41())
      throw IllegalStateException_init_$Create$();
    var sexp = pop(this._todoStack);
    var pair = sexp._get_pair__3();
    if (!(pair == null)) {
      push(this._todoStack, [pair._second]);
      push(this._todoStack, [pair._first]);
      return SExpSerailization_getInstance()._CONS_BOX_MARKER;
    } else {
      var tmp = SExpSerailization_getInstance();
      var tmp_0 = sexp._get_atom__4();
      return tmp.atomToSerializedBytes(ensureNotNull(tmp_0 == null ? null : new UByteArray(tmp_0))._storage);
    }
  };
  _no_name_provided__96.prototype.next_46 = function () {
    return new UByteArray(this.next_33());
  };
  _no_name_provided__96.$metadata$ = {
    kind: 'class',
    interfaces: [Iterator_2]
  };
  function SexpByteSequence(sexp) {
    this._sexp = sexp;
  }
  SexpByteSequence.prototype.iterator_66 = function () {
    return new _no_name_provided__96(this);
  };
  SexpByteSequence.$metadata$ = {
    simpleName: 'SexpByteSequence',
    kind: 'class',
    interfaces: [Sequence]
  };
  function SExpSerailization() {
    SExpSerailization_instance = this;
    this._MAX_SINGLE_BYTE = _UInt___init__impl_(127);
    var tmp = this;
    var tmp0_UByteArray_0 = 1;
    var tmp_1 = 0;
    var tmp_2 = tmp0_UByteArray_0;
    var tmp_3 = new Int8Array(tmp_2);
    while (tmp_1 < tmp_2) {
      var tmp_4 = tmp_1;
      var tmp0_toByte_0_5 = _UByte___init__impl_(-1);
      tmp_3[tmp_4] = _UByte___get_data__impl_(tmp0_toByte_0_5);
      tmp_1 = tmp_1 + 1 | 0;
    }
    tmp._CONS_BOX_MARKER = _UByteArray___init__impl_(tmp_3);
  }
  SExpSerailization.prototype.atomToSerializedBytes = function (atom) {
    if (UByteArray__isEmpty_impl(atom)) {
      var tmp0_UByteArray_0 = 1;
      var tmp_1 = 0;
      var tmp_2 = tmp0_UByteArray_0;
      var tmp_3 = new Int8Array(tmp_2);
      while (tmp_1 < tmp_2) {
        var tmp_4 = tmp_1;
        var tmp0_toUByte_0_7 = 128;
        var tmp0_toByte_0_5 = _UByte___init__impl_(toByte(tmp0_toUByte_0_7));
        tmp_3[tmp_4] = _UByte___get_data__impl_(tmp0_toByte_0_5);
        tmp_1 = tmp_1 + 1 | 0;
      }
      return _UByteArray___init__impl_(tmp_3);
    }if (_UByteArray___get_size__impl_(atom) === 1) {
      var tmp1_compareTo_0 = UByteArray__get_impl(atom, 0);
      var tmp2_compareTo_0 = this._MAX_SINGLE_BYTE;
      var tmp0_compareTo_0_1 = _UInt___init__impl_(_UByte___get_data__impl_(tmp1_compareTo_0) & 255);
      if (uintCompare(_UInt___get_data__impl_(tmp0_compareTo_0_1), _UInt___get_data__impl_(tmp2_compareTo_0)) < 0) {
        return atom;
      } else {
      }
    }var tmp3_toUInt_0 = _UByteArray___get_size__impl_(atom);
    var size_0 = _UInt___init__impl_(tmp3_toUInt_0);
    var tmp;
    var tmp4_compareTo_0 = _UInt___init__impl_(64);
    if (uintCompare(_UInt___get_data__impl_(size_0), _UInt___get_data__impl_(tmp4_compareTo_0)) < 0) {
      var tmp5_or_0 = _UInt___init__impl_(128);
      var tmp6_toUByte_0 = _UInt___init__impl_(_UInt___get_data__impl_(tmp5_or_0) | _UInt___get_data__impl_(size_0));
      var tmp0_toUByte_0_1 = _UInt___get_data__impl_(tmp6_toUByte_0);
      var tmp7_ubyteArrayOf_0 = _UByteArray___init__impl_(new Int8Array([_UByte___init__impl_(toByte(tmp0_toUByte_0_1))]));
      tmp = tmp7_ubyteArrayOf_0;
    } else {
      var tmp8_compareTo_0 = _UInt___init__impl_(8192);
      if (uintCompare(_UInt___get_data__impl_(size_0), _UInt___get_data__impl_(tmp8_compareTo_0)) < 0) {
        var tmp10_or_0 = _UInt___init__impl_(192);
        var tmp9_shr_0 = 8;
        var tmp11_or_0 = _UInt___init__impl_(_UInt___get_data__impl_(size_0) >>> tmp9_shr_0);
        var tmp12_toUByte_0 = _UInt___init__impl_(_UInt___get_data__impl_(tmp10_or_0) | _UInt___get_data__impl_(tmp11_or_0));
        var tmp0_toUByte_0_1_0 = _UInt___get_data__impl_(tmp12_toUByte_0);
        var tmp_0 = _UByte___init__impl_(toByte(tmp0_toUByte_0_1_0));
        var tmp13_shr_0 = 8;
        var tmp14_and_0 = _UInt___init__impl_(_UInt___get_data__impl_(size_0) >>> tmp13_shr_0);
        var tmp15_and_0 = _UInt___init__impl_(255);
        var tmp16_toUByte_0 = _UInt___init__impl_(_UInt___get_data__impl_(tmp14_and_0) & _UInt___get_data__impl_(tmp15_and_0));
        var tmp0_toUByte_0_1_1 = _UInt___get_data__impl_(tmp16_toUByte_0);
        var tmp17_ubyteArrayOf_0 = _UByteArray___init__impl_(new Int8Array([tmp_0, _UByte___init__impl_(toByte(tmp0_toUByte_0_1_1))]));
        tmp = tmp17_ubyteArrayOf_0;
      } else {
        var tmp18_compareTo_0 = _UInt___init__impl_(1048576);
        if (uintCompare(_UInt___get_data__impl_(size_0), _UInt___get_data__impl_(tmp18_compareTo_0)) < 0) {
          var tmp20_or_0 = _UInt___init__impl_(224);
          var tmp19_shr_0 = 16;
          var tmp21_or_0 = _UInt___init__impl_(_UInt___get_data__impl_(size_0) >>> tmp19_shr_0);
          var tmp22_toUByte_0 = _UInt___init__impl_(_UInt___get_data__impl_(tmp20_or_0) | _UInt___get_data__impl_(tmp21_or_0));
          var tmp0_toUByte_0_1_2 = _UInt___get_data__impl_(tmp22_toUByte_0);
          var tmp_5 = _UByte___init__impl_(toByte(tmp0_toUByte_0_1_2));
          var tmp23_shr_0 = 8;
          var tmp24_and_0 = _UInt___init__impl_(_UInt___get_data__impl_(size_0) >>> tmp23_shr_0);
          var tmp25_and_0 = _UInt___init__impl_(255);
          var tmp26_toUByte_0 = _UInt___init__impl_(_UInt___get_data__impl_(tmp24_and_0) & _UInt___get_data__impl_(tmp25_and_0));
          var tmp0_toUByte_0_1_3 = _UInt___get_data__impl_(tmp26_toUByte_0);
          var tmp_6 = _UByte___init__impl_(toByte(tmp0_toUByte_0_1_3));
          var tmp27_shr_0 = 0;
          var tmp28_and_0 = _UInt___init__impl_(_UInt___get_data__impl_(size_0) >>> tmp27_shr_0);
          var tmp29_and_0 = _UInt___init__impl_(255);
          var tmp30_toUByte_0 = _UInt___init__impl_(_UInt___get_data__impl_(tmp28_and_0) & _UInt___get_data__impl_(tmp29_and_0));
          var tmp0_toUByte_0_1_4 = _UInt___get_data__impl_(tmp30_toUByte_0);
          var tmp31_ubyteArrayOf_0 = _UByteArray___init__impl_(new Int8Array([tmp_5, tmp_6, _UByte___init__impl_(toByte(tmp0_toUByte_0_1_4))]));
          tmp = tmp31_ubyteArrayOf_0;
        } else {
          var tmp32_compareTo_0 = _UInt___init__impl_(134217728);
          if (uintCompare(_UInt___get_data__impl_(size_0), _UInt___get_data__impl_(tmp32_compareTo_0)) < 0) {
            var tmp34_or_0 = _UInt___init__impl_(240);
            var tmp33_shr_0 = 24;
            var tmp35_or_0 = _UInt___init__impl_(_UInt___get_data__impl_(size_0) >>> tmp33_shr_0);
            var tmp36_toUByte_0 = _UInt___init__impl_(_UInt___get_data__impl_(tmp34_or_0) | _UInt___get_data__impl_(tmp35_or_0));
            var tmp0_toUByte_0_1_5 = _UInt___get_data__impl_(tmp36_toUByte_0);
            var tmp_7 = _UByte___init__impl_(toByte(tmp0_toUByte_0_1_5));
            var tmp37_shr_0 = 16;
            var tmp38_and_0 = _UInt___init__impl_(_UInt___get_data__impl_(size_0) >>> tmp37_shr_0);
            var tmp39_and_0 = _UInt___init__impl_(255);
            var tmp40_toUByte_0 = _UInt___init__impl_(_UInt___get_data__impl_(tmp38_and_0) & _UInt___get_data__impl_(tmp39_and_0));
            var tmp0_toUByte_0_1_6 = _UInt___get_data__impl_(tmp40_toUByte_0);
            var tmp_8 = _UByte___init__impl_(toByte(tmp0_toUByte_0_1_6));
            var tmp41_shr_0 = 8;
            var tmp42_and_0 = _UInt___init__impl_(_UInt___get_data__impl_(size_0) >>> tmp41_shr_0);
            var tmp43_and_0 = _UInt___init__impl_(255);
            var tmp44_toUByte_0 = _UInt___init__impl_(_UInt___get_data__impl_(tmp42_and_0) & _UInt___get_data__impl_(tmp43_and_0));
            var tmp0_toUByte_0_1_7 = _UInt___get_data__impl_(tmp44_toUByte_0);
            var tmp_9 = _UByte___init__impl_(toByte(tmp0_toUByte_0_1_7));
            var tmp45_shr_0 = 0;
            var tmp46_and_0 = _UInt___init__impl_(_UInt___get_data__impl_(size_0) >>> tmp45_shr_0);
            var tmp47_and_0 = _UInt___init__impl_(255);
            var tmp48_toUByte_0 = _UInt___init__impl_(_UInt___get_data__impl_(tmp46_and_0) & _UInt___get_data__impl_(tmp47_and_0));
            var tmp0_toUByte_0_1_8 = _UInt___get_data__impl_(tmp48_toUByte_0);
            var tmp49_ubyteArrayOf_0 = _UByteArray___init__impl_(new Int8Array([tmp_7, tmp_8, tmp_9, _UByte___init__impl_(toByte(tmp0_toUByte_0_1_8))]));
            tmp = tmp49_ubyteArrayOf_0;
          } else {
            {
              throw IllegalArgumentException_init_$Create$_0('Sexp too long' + new UInt(size_0));
            }
          }
        }
      }
    }
    var sizeBlob = tmp;
    return toUByteArray(flatten(listOf([new UByteArray(sizeBlob), new UByteArray(atom)])));
  };
  SExpSerailization.$metadata$ = {
    simpleName: 'SExpSerailization',
    kind: 'object',
    interfaces: []
  };
  var SExpSerailization_instance;
  function SExpSerailization_getInstance() {
    if (SExpSerailization_instance == null)
      new SExpSerailization();
    return SExpSerailization_instance;
  }
  function sha256TreeHash(sexpToHash, precalculated) {
    var valStack = ArrayList_init_$Create$();
    push(valStack, [sexpToHash]);
    var opStack = ArrayList_init_$Create$();
    push(opStack, [(new Functions(precalculated))._get_handleSexp_()]);
    $l$break: while (true) {
      if (!!opStack.isEmpty_44()) {
        break $l$break;
      }var op = pop(opStack);
      op.call_0(opStack, valStack);
      Unit_getInstance();
    }
    var tmp = valStack.get_72(0)._get_atom__4();
    return ensureNotNull(tmp == null ? null : new UByteArray(tmp))._storage;
  }
  function sha256TreeHash$default(sexpToHash, precalculated, $mask0, $handler) {
    if (!(($mask0 & 2) === 0)) {
      precalculated = emptySet();
    }return sha256TreeHash(sexpToHash, precalculated);
  }
  function _no_name_provided__97(this$0) {
    this._this$0_21 = this$0;
  }
  _no_name_provided__97.prototype.invoke_269 = function (opStack, valStack) {
    var sexp = pop(valStack);
    if (!(sexp._get_pair__3() == null)) {
      var pair = ensureNotNull(sexp._get_pair__3());
      var p0 = Companion_getInstance_22().to_2(pair._first);
      var p1 = Companion_getInstance_22().to_2(pair._second);
      push(valStack, [p0]);
      push(valStack, [p1]);
      push(opStack, [this._this$0_21._get_handlePair_()]);
      push(opStack, [this._this$0_21._get_handleSexp_()]);
      push(opStack, [this._this$0_21._get_roll_()]);
      push(opStack, [this._this$0_21._get_handleSexp_()]);
    } else {
      var tmp = sexp._get_atom__4();
      var atom = ensureNotNull(tmp == null ? null : new UByteArray(tmp))._storage;
      var tmp_0;
      if (this._this$0_21._precalculated.contains_51(new UByteArray(atom))) {
        var tmp_1 = sexp._get_atom__4();
        tmp_0 = ensureNotNull(tmp_1 == null ? null : new UByteArray(tmp_1))._storage;
      } else {
        var tmp_2 = new Sha256_0();
        var tmp0_plus_0 = this._this$0_21._ONE_4;
        var tmp_3 = sexp._get_atom__4();
        var tmp1_plus_0 = ensureNotNull(tmp_3 == null ? null : new UByteArray(tmp_3))._storage;
        var tmp0_plus_0_1 = _UByteArray___get_storage__impl_(tmp0_plus_0);
        var tmp1_plus_0_2 = _UByteArray___get_storage__impl_(tmp1_plus_0);
        tmp_0 = tmp_2.digest_0(_UByteArray___init__impl_(primitiveArrayConcat([tmp0_plus_0_1, tmp1_plus_0_2])));
      }
      var r = tmp_0;
      push(valStack, [SExp_init_$Create$(r)]);
    }
    return Companion_getInstance_13()._ZERO_0;
  };
  _no_name_provided__97.prototype.invoke_915 = function (p1, p2) {
    var tmp = p1 instanceof ArrayList ? p1 : THROW_CCE();
    return this.invoke_269(tmp, p2 instanceof ArrayList ? p2 : THROW_CCE());
  };
  _no_name_provided__97.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__98(this$0) {
    this._this$0_22 = this$0;
  }
  _no_name_provided__98.prototype.invoke_269 = function (opStack, valStack) {
    var p0 = pop(valStack);
    var p1 = pop(valStack);
    var tmp = new Sha256_0();
    var tmp0_plus_0 = this._this$0_22._TWO_4;
    var tmp_0 = p0._get_atom__4();
    var tmp1_plus_0 = ensureNotNull(tmp_0 == null ? null : new UByteArray(tmp_0))._storage;
    var tmp0_plus_0_1 = _UByteArray___get_storage__impl_(tmp0_plus_0);
    var tmp1_plus_0_2 = _UByteArray___get_storage__impl_(tmp1_plus_0);
    var tmp2_plus_0 = _UByteArray___init__impl_(primitiveArrayConcat([tmp0_plus_0_1, tmp1_plus_0_2]));
    var tmp_1 = p1._get_atom__4();
    var tmp3_plus_0 = ensureNotNull(tmp_1 == null ? null : new UByteArray(tmp_1))._storage;
    var tmp0_plus_0_1_0 = _UByteArray___get_storage__impl_(tmp2_plus_0);
    var tmp1_plus_0_2_0 = _UByteArray___get_storage__impl_(tmp3_plus_0);
    push(valStack, [SExp_init_$Create$(tmp.digest_0(_UByteArray___init__impl_(primitiveArrayConcat([tmp0_plus_0_1_0, tmp1_plus_0_2_0]))))]);
    return Companion_getInstance_13()._ZERO_0;
  };
  _no_name_provided__98.prototype.invoke_915 = function (p1, p2) {
    var tmp = p1 instanceof ArrayList ? p1 : THROW_CCE();
    return this.invoke_269(tmp, p2 instanceof ArrayList ? p2 : THROW_CCE());
  };
  _no_name_provided__98.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__99() {
  }
  _no_name_provided__99.prototype.invoke_269 = function (opStack, valStack) {
    var p0 = pop(valStack);
    var p1 = pop(valStack);
    push(valStack, [p0]);
    push(valStack, [p1]);
    return Companion_getInstance_13()._ZERO_0;
  };
  _no_name_provided__99.prototype.invoke_915 = function (p1, p2) {
    var tmp = p1 instanceof ArrayList ? p1 : THROW_CCE();
    return this.invoke_269(tmp, p2 instanceof ArrayList ? p2 : THROW_CCE());
  };
  _no_name_provided__99.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function Functions(precalculated) {
    this._precalculated = precalculated;
    var tmp = this;
    var tmp0_UByteArray_0 = 1;
    var tmp_1 = 0;
    var tmp_2 = tmp0_UByteArray_0;
    var tmp_3 = new Int8Array(tmp_2);
    while (tmp_1 < tmp_2) {
      var tmp_4 = tmp_1;
      var tmp0_toUByte_0_7 = 1;
      var tmp0_toByte_0_5 = _UByte___init__impl_(toByte(tmp0_toUByte_0_7));
      tmp_3[tmp_4] = _UByte___get_data__impl_(tmp0_toByte_0_5);
      tmp_1 = tmp_1 + 1 | 0;
    }
    tmp._ONE_4 = _UByteArray___init__impl_(tmp_3);
    var tmp_0 = this;
    var tmp0_UByteArray_0_0 = 1;
    var tmp_1_0 = 0;
    var tmp_2_0 = tmp0_UByteArray_0_0;
    var tmp_3_0 = new Int8Array(tmp_2_0);
    while (tmp_1_0 < tmp_2_0) {
      var tmp_4_0 = tmp_1_0;
      var tmp0_toUByte_0_7_0 = 2;
      var tmp0_toByte_0_5_0 = _UByte___init__impl_(toByte(tmp0_toUByte_0_7_0));
      tmp_3_0[tmp_4_0] = _UByte___get_data__impl_(tmp0_toByte_0_5_0);
      tmp_1_0 = tmp_1_0 + 1 | 0;
    }
    tmp_0._TWO_4 = _UByteArray___init__impl_(tmp_3_0);
    var tmp_5 = this;
    tmp_5._handleSexp = new OpStackCallable('handleSexp', _no_name_provided_$factory_75(this));
    var tmp_6 = this;
    tmp_6._handlePair = new OpStackCallable('HandlePair', _no_name_provided_$factory_76(this));
    var tmp_7 = this;
    tmp_7._roll = new OpStackCallable('roll', _no_name_provided_$factory_77());
  }
  Functions.prototype._get_handleSexp_ = function () {
    var tmp = this._handleSexp;
    if (!(tmp == null))
      return tmp;
    else {
      throwUninitializedPropertyAccessException('handleSexp');
    }
  };
  Functions.prototype._get_roll_ = function () {
    var tmp = this._roll;
    if (!(tmp == null))
      return tmp;
    else {
      throwUninitializedPropertyAccessException('roll');
    }
  };
  Functions.prototype._get_handlePair_ = function () {
    var tmp = this._handlePair;
    if (!(tmp == null))
      return tmp;
    else {
      throwUninitializedPropertyAccessException('handlePair');
    }
  };
  Functions.$metadata$ = {
    simpleName: 'Functions',
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided_$factory_75(this$0) {
    var i = new _no_name_provided__97(this$0);
    return function (p1, p2) {
      return i.invoke_269(p1, p2);
    };
  }
  function _no_name_provided_$factory_76(this$0) {
    var i = new _no_name_provided__98(this$0);
    return function (p1, p2) {
      return i.invoke_269(p1, p2);
    };
  }
  function _no_name_provided_$factory_77() {
    var i = new _no_name_provided__99();
    return function (p1, p2) {
      return i.invoke_269(p1, p2);
    };
  }
  function writeIr(irSexp) {
    var sb = StringBuilder_init_$Create$_1();
    writeIrToBuilder(irSexp, sb);
    return sb.toString();
  }
  function writeIrToBuilder(irSexp, sb) {
    var tmp0_forEach_0 = irFormatSequence(irSexp);
    var tmp0_iterator_1 = tmp0_forEach_0.iterator_66();
    while (tmp0_iterator_1.hasNext_41()) {
      var element_2 = tmp0_iterator_1.next_46();
      sb.append_5(element_2);
      Unit_getInstance();
    }
  }
  function irFormatSequence(irSexp) {
    return sequence(_no_name_provided_$factory_78(irSexp, null));
  }
  function sexpFormatSequence(irSexp) {
    return sequence(_no_name_provided_$factory_79(irSexp, null));
  }
  function _no_name_provided__100($irSexp, resultContinuation) {
    this._$irSexp = $irSexp;
    CoroutineImpl_0.call(this, resultContinuation);
  }
  _no_name_provided__100.prototype.invoke_164 = function ($this$sequence, $cont) {
    var tmp = this.create_3($this$sequence, $cont);
    tmp._result_0 = Unit_getInstance();
    tmp._exception_0 = null;
    return tmp.doResume_17();
  };
  _no_name_provided__100.prototype.doResume_17 = function () {
    var suspendResult = this._result_0;
    $sm: do
      try {
        var tmp = this._state_2;
        switch (tmp) {
          case 0:
            this._exceptionState = 18;
            if (Utils_getInstance().irListp(this._$irSexp)) {
              this._state_2 = 17;
              suspendResult = this._$this$sequence_1.yieldAll_2(sexpFormatSequence(this._$irSexp), this);
              if (suspendResult === _get_COROUTINE_SUSPENDED_()) {
                return suspendResult;
              }continue $sm;
            } else {
              this._state_2 = 1;
              continue $sm;
            }

            break;
          case 1:
            var tmp_0 = this;
            var tmp0_elvis_lhs = toTypeAtom(Utils_getInstance().irType(this._$irSexp));
            var tmp_1;
            if (tmp0_elvis_lhs == null) {
              throw Exception_init_$Create$_0('' + 'Could not determine IR Type: ' + this._$irSexp);
            } else {
              tmp_1 = tmp0_elvis_lhs;
            }

            tmp_0._type0 = tmp_1;
            if (this._type0.equals(TypeAtom_CODE_getInstance())) {
              this._str1 = toHexString(toUByteArray(toList_1(flatten_0(new SexpByteSequence(this._$irSexp)))));
              this._state_2 = 16;
              suspendResult = this._$this$sequence_1.yield_0('' + 'CODE[' + this._str1 + ']', this);
              if (suspendResult === _get_COROUTINE_SUSPENDED_()) {
                return suspendResult;
              }continue $sm;
            } else {
              this._state_2 = 2;
              continue $sm;
            }

            break;
          case 2:
            if (this._type0.equals(TypeAtom_NULL_getInstance())) {
              this._state_2 = 15;
              suspendResult = this._$this$sequence_1.yield_0('()', this);
              if (suspendResult === _get_COROUTINE_SUSPENDED_()) {
                return suspendResult;
              }continue $sm;
            } else {
              this._state_2 = 3;
              continue $sm;
            }

            break;
          case 3:
            this._atom2 = Utils_getInstance().irAsAtom(this._$irSexp);
            this._tmp1_subject3 = this._type0;
            if (this._tmp1_subject3.equals(TypeAtom_INT_getInstance())) {
              this._state_2 = 13;
              var tmp_2 = Companion_getInstance_13();
              var tmp_3 = this;
              tmp_3._tmp0_copyOf_0_14 = _UByteArray___get_storage__impl_(this._atom2);
              suspendResult = this._$this$sequence_1.yield_0(fromTwosComplementByteArray(tmp_2, this._tmp0_copyOf_0_14.slice()).toString_1(10), this);
              if (suspendResult === _get_COROUTINE_SUSPENDED_()) {
                return suspendResult;
              }continue $sm;
            } else {
              if (this._tmp1_subject3.equals(TypeAtom_NODE_getInstance())) {
                this._state_2 = 12;
                var tmp_4 = Companion_getInstance_13();
                var tmp_5 = this;
                tmp_5._tmp0_copyOf_0_15 = _UByteArray___get_storage__impl_(this._atom2);
                suspendResult = this._$this$sequence_1.yield_0('' + 'NODE[' + fromTwosComplementByteArray(tmp_4, this._tmp0_copyOf_0_15.slice()).toString_1(10) + ']', this);
                if (suspendResult === _get_COROUTINE_SUSPENDED_()) {
                  return suspendResult;
                }continue $sm;
              } else {
                if (this._tmp1_subject3.equals(TypeAtom_HEX_getInstance())) {
                  this._state_2 = 11;
                  suspendResult = this._$this$sequence_1.yield_0(toHexStringWithPrefix(this._atom2, '0x'), this);
                  if (suspendResult === _get_COROUTINE_SUSPENDED_()) {
                    return suspendResult;
                  }continue $sm;
                } else {
                  if (this._tmp1_subject3.equals(TypeAtom_QUOTES_getInstance())) {
                    this._state_2 = 10;
                    var tmp_6 = this;
                    tmp_6._tmp0_copyOf_0_16 = _UByteArray___get_storage__impl_(this._atom2);
                    suspendResult = this._$this$sequence_1.yield_0('' + '"' + decodeToString(this._tmp0_copyOf_0_16.slice()) + '"', this);
                    if (suspendResult === _get_COROUTINE_SUSPENDED_()) {
                      return suspendResult;
                    }continue $sm;
                  } else {
                    if (this._tmp1_subject3.equals(TypeAtom_DOUBLE_QUOTE_getInstance())) {
                      this._state_2 = 9;
                      var tmp_7 = this;
                      tmp_7._tmp0_copyOf_0_17 = _UByteArray___get_storage__impl_(this._atom2);
                      suspendResult = this._$this$sequence_1.yield_0('' + '"' + decodeToString(this._tmp0_copyOf_0_17.slice()) + '"', this);
                      if (suspendResult === _get_COROUTINE_SUSPENDED_()) {
                        return suspendResult;
                      }continue $sm;
                    } else {
                      if (this._tmp1_subject3.equals(TypeAtom_SINGLE_QUOTE_getInstance())) {
                        this._state_2 = 8;
                        var tmp_8 = this;
                        tmp_8._tmp0_copyOf_0_18 = _UByteArray___get_storage__impl_(this._atom2);
                        suspendResult = this._$this$sequence_1.yield_0('' + "'" + decodeToString(this._tmp0_copyOf_0_18.slice()) + "'", this);
                        if (suspendResult === _get_COROUTINE_SUSPENDED_()) {
                          return suspendResult;
                        }continue $sm;
                      } else {
                        if (listOf([TypeAtom_OPERATOR_getInstance(), TypeAtom_SYMBOL_getInstance()]).contains_51(this._tmp1_subject3)) {
                          this._exceptionState = 5;
                          this._state_2 = 4;
                          var tmp_9 = this;
                          tmp_9._tmp0_copyOf_0_19 = _UByteArray___get_storage__impl_(this._atom2);
                          suspendResult = this._$this$sequence_1.yield_0(decodeToString(this._tmp0_copyOf_0_19.slice()), this);
                          if (suspendResult === _get_COROUTINE_SUSPENDED_()) {
                            return suspendResult;
                          }continue $sm;
                        } else {
                          throw Exception_init_$Create$_0('' + 'Unknown IR Format: ' + this._type0 + ' ' + this._$irSexp);
                        }
                      }
                    }
                  }
                }
              }
            }

            break;
          case 4:
            suspendResult;
            this._exceptionState = 18;
            this._state_2 = 7;
            continue $sm;
          case 5:
            this._exceptionState = 18;
            var tmp_10 = this._exception_0;
            if (tmp_10 instanceof Exception) {
              this._e10 = this._exception_0;
              this._state_2 = 6;
              suspendResult = this._$this$sequence_1.yield_0('' + '(indecipherable symbol: ' + toHexString(this._atom2) + ')', this);
              if (suspendResult === _get_COROUTINE_SUSPENDED_()) {
                return suspendResult;
              }continue $sm;
            } else {
              {
                throw this._exception_0;
              }
            }

            break;
          case 6:
            suspendResult;
            this._state_2 = 7;
            continue $sm;
          case 7:
            this._exceptionState = 18;
            this._state_2 = 14;
            continue $sm;
          case 8:
            suspendResult;
            this._state_2 = 14;
            continue $sm;
          case 9:
            suspendResult;
            this._state_2 = 14;
            continue $sm;
          case 10:
            suspendResult;
            this._state_2 = 14;
            continue $sm;
          case 11:
            suspendResult;
            this._state_2 = 14;
            continue $sm;
          case 12:
            suspendResult;
            this._state_2 = 14;
            continue $sm;
          case 13:
            suspendResult;
            this._state_2 = 14;
            continue $sm;
          case 14:
            return Unit_getInstance();
          case 15:
            suspendResult;
            return Unit_getInstance();
          case 16:
            suspendResult;
            return Unit_getInstance();
          case 17:
            suspendResult;
            return Unit_getInstance();
          case 18:
            throw this._exception_0;
        }
      } catch ($p) {
        if (this._exceptionState === 18) {
          throw $p;
        } else {
          this._state_2 = this._exceptionState;
          this._exception_0 = $p;
        }
      }
     while (true);
  };
  _no_name_provided__100.prototype.create_3 = function ($this$sequence, completion) {
    var i = new _no_name_provided__100(this._$irSexp, completion);
    i._$this$sequence_1 = $this$sequence;
    return i;
  };
  _no_name_provided__100.$metadata$ = {
    kind: 'class',
    interfaces: [],
    suspendArity: 1
  };
  function _no_name_provided__101($irSexp, resultContinuation) {
    this._$irSexp_0 = $irSexp;
    CoroutineImpl_0.call(this, resultContinuation);
  }
  _no_name_provided__101.prototype.invoke_164 = function ($this$sequence, $cont) {
    var tmp = this.create_3($this$sequence, $cont);
    tmp._result_0 = Unit_getInstance();
    tmp._exception_0 = null;
    return tmp.doResume_17();
  };
  _no_name_provided__101.prototype.doResume_17 = function () {
    var suspendResult = this._result_0;
    $sm: do
      try {
        var tmp = this._state_2;
        switch (tmp) {
          case 0:
            this._exceptionState = 11;
            this._state_2 = 1;
            suspendResult = this._$this$sequence_2.yield_0('(', this);
            if (suspendResult === _get_COROUTINE_SUSPENDED_()) {
              return suspendResult;
            }
            continue $sm;
          case 1:
            suspendResult;
            this._isFirst0 = true;
            this._x1 = this._$irSexp_0;
            this._state_2 = 2;
            continue $sm;
          case 2:
            if (!!Utils_getInstance().irNullp(this._x1)) {
              this._state_2 = 9;
              continue $sm;
            }
            if (!Utils_getInstance().irListp(this._x1)) {
              this._state_2 = 7;
              suspendResult = this._$this$sequence_2.yield_0(' . ', this);
              if (suspendResult === _get_COROUTINE_SUSPENDED_()) {
                return suspendResult;
              }continue $sm;
            } else {
              this._state_2 = 3;
              continue $sm;
            }

            break;
          case 3:
            if (!this._isFirst0) {
              this._state_2 = 4;
              suspendResult = this._$this$sequence_2.yield_0(' ', this);
              if (suspendResult === _get_COROUTINE_SUSPENDED_()) {
                return suspendResult;
              }continue $sm;
            } else {
              this._state_2 = 5;
              continue $sm;
            }

            break;
          case 4:
            suspendResult;
            this._state_2 = 5;
            continue $sm;
          case 5:
            this._state_2 = 6;
            suspendResult = this._$this$sequence_2.yieldAll_2(irFormatSequence(Utils_getInstance().irFirst(this._x1)), this);
            if (suspendResult === _get_COROUTINE_SUSPENDED_()) {
              return suspendResult;
            }
            continue $sm;
          case 6:
            suspendResult;
            this._x1 = Utils_getInstance().irRest(this._x1);
            this._isFirst0 = false;
            this._state_2 = 2;
            continue $sm;
          case 7:
            suspendResult;
            this._state_2 = 8;
            suspendResult = this._$this$sequence_2.yieldAll_2(irFormatSequence(this._x1), this);
            if (suspendResult === _get_COROUTINE_SUSPENDED_()) {
              return suspendResult;
            }
            continue $sm;
          case 8:
            suspendResult;
            this._state_2 = 9;
            continue $sm;
          case 9:
            this._state_2 = 10;
            suspendResult = this._$this$sequence_2.yield_0(')', this);
            if (suspendResult === _get_COROUTINE_SUSPENDED_()) {
              return suspendResult;
            }
            continue $sm;
          case 10:
            suspendResult;
            return Unit_getInstance();
          case 11:
            throw this._exception_0;
        }
      } catch ($p) {
        if (this._exceptionState === 11) {
          throw $p;
        } else {
          this._state_2 = this._exceptionState;
          this._exception_0 = $p;
        }
      }
     while (true);
  };
  _no_name_provided__101.prototype.create_3 = function ($this$sequence, completion) {
    var i = new _no_name_provided__101(this._$irSexp_0, completion);
    i._$this$sequence_2 = $this$sequence;
    return i;
  };
  _no_name_provided__101.$metadata$ = {
    kind: 'class',
    interfaces: [],
    suspendArity: 1
  };
  function _no_name_provided_$factory_78($irSexp, resultContinuation) {
    var i = new _no_name_provided__100($irSexp, resultContinuation);
    var l = function (p1, $cont) {
      return i.invoke_164(p1, $cont);
    };
    l.$arity = 1;
    return l;
  }
  function _no_name_provided_$factory_79($irSexp, resultContinuation) {
    var i = new _no_name_provided__101($irSexp, resultContinuation);
    var l = function (p1, $cont) {
      return i.invoke_164(p1, $cont);
    };
    l.$arity = 1;
    return l;
  }
  function readIr(s, toSexpFun) {
    var stream = (new TokenReader(s, toSexpFun)).iterator_66();
    var tmp0_iterator_1 = stream;
    while (tmp0_iterator_1.hasNext_41()) {
      var element_2 = tmp0_iterator_1.next_46();
      var tokenize_4 = tokenizeSexp(element_2._first, element_2._second, stream);
      return toSexpFun(tokenize_4);
    }
    throw Exception_init_$Create$_0('unexpected end of stream');
  }
  function readIr$default(s, toSexpFun, $mask0, $handler) {
    if (!(($mask0 & 2) === 0)) {
      toSexpFun = _no_name_provided_$factory_80();
    }return readIr(s, toSexpFun);
  }
  function TokenReader(s, toSexpFun) {
    this._s = s;
    this._toSexpFun = toSexpFun;
  }
  TokenReader.prototype.iterator_66 = function () {
    return new TokenIterator(this._s, this._toSexpFun);
  };
  TokenReader.$metadata$ = {
    simpleName: 'TokenReader',
    kind: 'class',
    interfaces: [Sequence]
  };
  function tokenizeSexp(token, offset, stream) {
    if (token === '(') {
      var next = nextConsToken(stream);
      return tokenizeCons(next._first, next._second, stream);
    }var tmp = _no_name_provided_$factory_81();
    var tmp_0 = _no_name_provided_$factory_82();
    var tmp_1 = _no_name_provided_$factory_83();
    var tmp0_iterator = listOf([tmp, tmp_0, tmp_1, _no_name_provided_$factory_84()]).iterator_66();
    while (tmp0_iterator.hasNext_41()) {
      var f = tmp0_iterator.next_46();
      var r = f(token, offset);
      if (!(r == null)) {
        return r;
      }}
    throw IllegalStateException_init_$Create$_0('No symbol fall through');
  }
  function consumeWhitespace($this) {
    $l$break: while (true) {
      while ($this._offset < $this._s_0.length ? isWhitespace(charSequenceGet($this._s_0, $this._offset)) : false) {
        var tmp0_this = $this;
        tmp0_this._offset = tmp0_this._offset + 1 | 0;
      }
      if ($this._offset >= $this._s_0.length ? true : !charSequenceGet($this._s_0, $this._offset).equals(new Char(59))) {
        break $l$break;
      }while ($this._offset < $this._s_0.length ? !charSequenceGet($this._s_0, $this._offset).equals(new Char(10)) ? !charSequenceGet($this._s_0, $this._offset).equals(new Char(13)) : false : false) {
        var tmp1_this = $this;
        tmp1_this._offset = tmp1_this._offset + 1 | 0;
      }
    }
  }
  function consumeUntilWhitespace($this) {
    var start_0 = $this._offset;
    while (($this._offset < $this._s_0.length ? !isWhitespace(charSequenceGet($this._s_0, $this._offset)) : false) ? !charSequenceGet($this._s_0, $this._offset).equals(new Char(41)) : false) {
      var tmp0_this = $this;
      tmp0_this._offset = tmp0_this._offset + 1 | 0;
    }
    return to(slice_0($this._s_0, until(start_0, $this._offset)), $this._offset);
  }
  function TokenIterator(s, toSexpFun) {
    this._s_0 = s;
    this._toSexpFun_0 = toSexpFun;
    this._offset = 0;
  }
  TokenIterator.prototype.hasNext_41 = function () {
    return this._offset < this._s_0.length;
  };
  TokenIterator.prototype.next_46 = function () {
    consumeWhitespace(this);
    if (this._offset >= this._s_0.length) {
      throw IllegalStateException_init_$Create$();
    }var c = charSequenceGet(this._s_0, this._offset);
    if (contains$default('(.)', c, false, 2, null)) {
      var tmp0_this = this;
      tmp0_this._offset = tmp0_this._offset + 1 | 0;
      return to(c.toString(), this._offset - 1 | 0);
    } else {
    }
    if (contains$default('"\'', c, false, 2, null)) {
      var start_0 = this._offset;
      var initialC = charSequenceGet(this._s_0, start_0);
      var tmp1_this = this;
      tmp1_this._offset = tmp1_this._offset + 1 | 0;
      while (this._offset < this._s_0.length ? !charSequenceGet(this._s_0, this._offset).equals(initialC) : false) {
        var tmp2_this = this;
        tmp2_this._offset = tmp2_this._offset + 1 | 0;
      }
      if (this._offset < this._s_0.length) {
        var tmp3_this = this;
        tmp3_this._offset = tmp3_this._offset + 1 | 0;
        return to(substring(this._s_0, until(start_0, this._offset)), start_0);
      } else {
        throw Exception_init_$Create$_0('' + 'Unterminated string starting at ' + start_0);
      }
    } else {
    }
    var start_1 = this._offset;
    var result = consumeUntilWhitespace(this);
    this._offset = result._second;
    return new Pair(result._first, start_1);
  };
  TokenIterator.$metadata$ = {
    simpleName: 'TokenIterator',
    kind: 'class',
    interfaces: [Iterator_2]
  };
  function nextConsToken(stream) {
    try {
      return stream.next_46();
    } catch ($p) {
      if ($p instanceof Exception) {
        throw Exception_init_$Create$_0('Missing )');
      } else {
        {
          throw $p;
        }
      }
    }
  }
  function tokenizeCons(token, offset, stream) {
    if (token === ')') {
      return Utils_getInstance().irNew(TypeAtom_NULL_getInstance()._value_6, 0, offset);
    }var initialoffset = offset;
    var firstSexp = tokenizeSexp(token, offset, stream);
    var tokenOffset = nextConsToken(stream);
    var restSexp;
    if (tokenOffset._first === '.') {
      var dotOffset = tokenOffset._second;
      tokenOffset = nextConsToken(stream);
      restSexp = tokenizeSexp(tokenOffset._first, tokenOffset._second, stream);
      tokenOffset = nextConsToken(stream);
      if (!(tokenOffset._first === ')')) {
        throw Exception_init_$Create$_0('' + 'illegal dot expression ' + dotOffset);
      }} else {
      restSexp = tokenizeCons(tokenOffset._first, tokenOffset._second, stream);
    }
    return Utils_getInstance().irCons(firstSexp, restSexp, initialoffset);
  }
  function tokenizeInt(token, offset) {
    var tmp;
    try {
      tmp = Utils_getInstance().irNew(TypeAtom_INT_getInstance()._value_6, Companion_getInstance_13().parseString_0(token, 10), offset);
    } catch ($p) {
      var tmp_0;
      if ($p instanceof Exception) {
        tmp_0 = null;
      } else {
        {
          throw $p;
        }
      }
      tmp = tmp_0;
    }
    return tmp;
  }
  function tokenizeHex(s, offset) {
    var token;
    var tmp0_uppercase_0 = take_0(s, 2);
    if (tmp0_uppercase_0.toUpperCase() === '0X') {
      try {
        token = drop_1(s, 2);
        if (token.length % 2 === 1) {
          token = '0' + token;
        }return Utils_getInstance().irNew(TypeAtom_HEX_getInstance()._value_6, new UByteArray(HexString__toUByteArray_impl(asHexString(token))), offset);
      } catch ($p) {
        if ($p instanceof Exception) {
          throw Exception_init_$Create$_1('' + 'Invalid hex format: ' + offset, $p);
        } else {
          {
            throw $p;
          }
        }
      }
    } else {
    }
    return null;
  }
  function tokenizeQuotes(token, offset) {
    if (token.length < 2)
      return null;
    var c = first_2(token);
    if (!contains$default('\'"', c, false, 2, null))
      return null;
    else {
    }
    if (!last_1(token).equals(c)) {
      throw Exception_init_$Create$_0('' + 'Underminated string starting at ' + offset + '.');
    }var qType = c.equals(new Char(39)) ? TypeAtom_SINGLE_QUOTE_getInstance() : TypeAtom_DOUBLE_QUOTE_getInstance();
    var tmp = Companion_getInstance_22();
    var tmp_0 = new Pair(qType, offset);
    var tmp0_toUByteArray_0 = encodeToByteArray(dropLast(drop_1(token, 1), 1));
    return tmp.to_1(new Pair(tmp_0, new UByteArray(_UByteArray___init__impl_(tmp0_toUByteArray_0.slice()))));
  }
  function tokenizeSymbol(token, offset) {
    var tmp = Companion_getInstance_22();
    var tmp_0 = new Pair(TypeAtom_SYMBOL_getInstance(), offset);
    var tmp0_toUByteArray_0 = encodeToByteArray(token);
    return tmp.to_1(new Pair(tmp_0, new UByteArray(_UByteArray___init__impl_(tmp0_toUByteArray_0.slice()))));
  }
  function _no_name_provided__102() {
  }
  _no_name_provided__102.prototype.invoke_259 = function (it) {
    return toImp(Companion_getInstance_22(), it);
  };
  _no_name_provided__102.prototype.invoke_1115 = function (p1) {
    return this.invoke_259(isObject(p1) ? p1 : THROW_CCE());
  };
  _no_name_provided__102.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__103() {
  }
  _no_name_provided__103.prototype.invoke_173 = function (p0, p1) {
    return tokenizeInt(p0, p1);
  };
  _no_name_provided__103.prototype.invoke_915 = function (p1, p2) {
    var tmp = (!(p1 == null) ? typeof p1 === 'string' : false) ? p1 : THROW_CCE();
    return this.invoke_173(tmp, (!(p2 == null) ? typeof p2 === 'number' : false) ? p2 : THROW_CCE());
  };
  _no_name_provided__103.prototype._get_name__18 = function () {
    return 'tokenizeInt';
  };
  _no_name_provided__103.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__104() {
  }
  _no_name_provided__104.prototype.invoke_173 = function (p0, p1) {
    return tokenizeHex(p0, p1);
  };
  _no_name_provided__104.prototype.invoke_915 = function (p1, p2) {
    var tmp = (!(p1 == null) ? typeof p1 === 'string' : false) ? p1 : THROW_CCE();
    return this.invoke_173(tmp, (!(p2 == null) ? typeof p2 === 'number' : false) ? p2 : THROW_CCE());
  };
  _no_name_provided__104.prototype._get_name__18 = function () {
    return 'tokenizeHex';
  };
  _no_name_provided__104.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__105() {
  }
  _no_name_provided__105.prototype.invoke_173 = function (p0, p1) {
    return tokenizeQuotes(p0, p1);
  };
  _no_name_provided__105.prototype.invoke_915 = function (p1, p2) {
    var tmp = (!(p1 == null) ? typeof p1 === 'string' : false) ? p1 : THROW_CCE();
    return this.invoke_173(tmp, (!(p2 == null) ? typeof p2 === 'number' : false) ? p2 : THROW_CCE());
  };
  _no_name_provided__105.prototype._get_name__18 = function () {
    return 'tokenizeQuotes';
  };
  _no_name_provided__105.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__106() {
  }
  _no_name_provided__106.prototype.invoke_173 = function (p0, p1) {
    return tokenizeSymbol(p0, p1);
  };
  _no_name_provided__106.prototype.invoke_915 = function (p1, p2) {
    var tmp = (!(p1 == null) ? typeof p1 === 'string' : false) ? p1 : THROW_CCE();
    return this.invoke_173(tmp, (!(p2 == null) ? typeof p2 === 'number' : false) ? p2 : THROW_CCE());
  };
  _no_name_provided__106.prototype._get_name__18 = function () {
    return 'tokenizeSymbol';
  };
  _no_name_provided__106.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided_$factory_80() {
    var i = new _no_name_provided__102();
    return function (p1) {
      return i.invoke_259(p1);
    };
  }
  function _no_name_provided_$factory_81() {
    var i = new _no_name_provided__103();
    var l = function (p1, p2) {
      return i.invoke_173(p1, p2);
    };
    l.callableName = i._get_name__18();
    return l;
  }
  function _no_name_provided_$factory_82() {
    var i = new _no_name_provided__104();
    var l = function (p1, p2) {
      return i.invoke_173(p1, p2);
    };
    l.callableName = i._get_name__18();
    return l;
  }
  function _no_name_provided_$factory_83() {
    var i = new _no_name_provided__105();
    var l = function (p1, p2) {
      return i.invoke_173(p1, p2);
    };
    l.callableName = i._get_name__18();
    return l;
  }
  function _no_name_provided_$factory_84() {
    var i = new _no_name_provided__106();
    var l = function (p1, p2) {
      return i.invoke_173(p1, p2);
    };
    l.callableName = i._get_name__18();
    return l;
  }
  function TypeAtomValue(string) {
    this._string_0 = string;
    this._num = Companion_getInstance_13().fromByteArray(encodeToByteArray(this._string_0), Sign_POSITIVE_getInstance());
  }
  TypeAtomValue.prototype._get_atom__2 = function () {
    return this._num.toUByteArray_10();
  };
  TypeAtomValue.prototype._get_atom__4 = function () {
    return this._get_atom__2();
  };
  TypeAtomValue.prototype._get_atomOrPair__3 = function () {
    return new UByteArray(this._get_atom__2());
  };
  TypeAtomValue.prototype._get_pair__3 = function () {
    return null;
  };
  TypeAtomValue.prototype.cons_3 = function (right) {
    return this;
  };
  TypeAtomValue.prototype.toString = function () {
    return '' + 'SExp IR Type: ' + this._string_0;
  };
  TypeAtomValue.$metadata$ = {
    simpleName: 'TypeAtomValue',
    kind: 'class',
    interfaces: [AtomOrPair]
  };
  var TypeAtom_CONS_instance;
  var TypeAtom_NULL_instance;
  var TypeAtom_INT_instance;
  var TypeAtom_HEX_instance;
  var TypeAtom_QUOTES_instance;
  var TypeAtom_DOUBLE_QUOTE_instance;
  var TypeAtom_SINGLE_QUOTE_instance;
  var TypeAtom_SYMBOL_instance;
  var TypeAtom_OPERATOR_instance;
  var TypeAtom_CODE_instance;
  var TypeAtom_NODE_instance;
  function values() {
    return [TypeAtom_CONS_getInstance(), TypeAtom_NULL_getInstance(), TypeAtom_INT_getInstance(), TypeAtom_HEX_getInstance(), TypeAtom_QUOTES_getInstance(), TypeAtom_DOUBLE_QUOTE_getInstance(), TypeAtom_SINGLE_QUOTE_getInstance(), TypeAtom_SYMBOL_getInstance(), TypeAtom_OPERATOR_getInstance(), TypeAtom_CODE_getInstance(), TypeAtom_NODE_getInstance()];
  }
  var TypeAtom_entriesInitialized;
  function TypeAtom_initEntries() {
    if (TypeAtom_entriesInitialized)
      return Unit_getInstance();
    TypeAtom_entriesInitialized = true;
    TypeAtom_CONS_instance = new TypeAtom('CONS', 0, new TypeAtomValue('CONS'));
    TypeAtom_NULL_instance = new TypeAtom('NULL', 1, new TypeAtomValue('NULL'));
    TypeAtom_INT_instance = new TypeAtom('INT', 2, new TypeAtomValue('INT'));
    TypeAtom_HEX_instance = new TypeAtom('HEX', 3, new TypeAtomValue('HEX'));
    TypeAtom_QUOTES_instance = new TypeAtom('QUOTES', 4, new TypeAtomValue('QT'));
    TypeAtom_DOUBLE_QUOTE_instance = new TypeAtom('DOUBLE_QUOTE', 5, new TypeAtomValue('DQT'));
    TypeAtom_SINGLE_QUOTE_instance = new TypeAtom('SINGLE_QUOTE', 6, new TypeAtomValue('SQT'));
    TypeAtom_SYMBOL_instance = new TypeAtom('SYMBOL', 7, new TypeAtomValue('SYM'));
    TypeAtom_OPERATOR_instance = new TypeAtom('OPERATOR', 8, new TypeAtomValue('OP'));
    TypeAtom_CODE_instance = new TypeAtom('CODE', 9, new TypeAtomValue('CODE'));
    TypeAtom_NODE_instance = new TypeAtom('NODE', 10, new TypeAtomValue('NODE'));
  }
  function TypeAtom(name, ordinal, value_0) {
    Enum.call(this, name, ordinal);
    this._value_6 = value_0;
  }
  TypeAtom.prototype.cons_3 = function (right) {
    return this._value_6.cons_3(right);
  };
  TypeAtom.prototype._get_atom__4 = function () {
    return this._value_6._get_atom__2();
  };
  TypeAtom.prototype._get_atomOrPair__3 = function () {
    return this._value_6._get_atomOrPair__3();
  };
  TypeAtom.prototype._get_pair__3 = function () {
    return this._value_6._get_pair__3();
  };
  TypeAtom.$metadata$ = {
    simpleName: 'TypeAtom',
    kind: 'class',
    interfaces: [AtomOrPair]
  };
  function toTypeAtom(from) {
    var tmp0_find_0 = values();
    var tmp$ret$0;
    l$ret$1: do {
      var indexedObject = tmp0_find_0;
      var inductionVariable = 0;
      var last_2 = indexedObject.length;
      while (inductionVariable < last_2) {
        var element_2_2 = indexedObject[inductionVariable];
        inductionVariable = inductionVariable + 1 | 0;
        if (element_2_2._value_6._num.equals(from)) {
          tmp$ret$0 = element_2_2;
          break l$ret$1;
        } else {
        }
      }
      tmp$ret$0 = null;
    }
     while (false);
    return tmp$ret$0;
  }
  function typeForAtom(from) {
    if (_UByteArray___get_size__impl_(from) > 2) {
      var tmp;
      try {
        var tmp0_copyOf_0_1 = _UByteArray___get_storage__impl_(from);
        var tmp_0 = tmp0_copyOf_0_1.slice();
        decodeToString$default(tmp_0, 0, 0, true, 3, null);
        Unit_getInstance();
        tmp = TypeAtom_QUOTES_getInstance();
      } catch ($p) {
        var tmp_1;
        if ($p instanceof Exception) {
          tmp_1 = TypeAtom_HEX_getInstance();
        } else {
          {
            throw $p;
          }
        }
        tmp = tmp_1;
      }
      return tmp;
    }var tmp_2 = Companion_getInstance_13();
    var tmp0_copyOf_0_1_0 = _UByteArray___get_storage__impl_(from);
    if (contentEquals_0(toTrimmed2sCompUbyteARray(fromTwosComplementByteArray(tmp_2, tmp0_copyOf_0_1_0.slice())), from))
      return TypeAtom_INT_getInstance();
    else {
    }
    return TypeAtom_HEX_getInstance();
  }
  function TypeAtom_CONS_getInstance() {
    TypeAtom_initEntries();
    return TypeAtom_CONS_instance;
  }
  function TypeAtom_NULL_getInstance() {
    TypeAtom_initEntries();
    return TypeAtom_NULL_instance;
  }
  function TypeAtom_INT_getInstance() {
    TypeAtom_initEntries();
    return TypeAtom_INT_instance;
  }
  function TypeAtom_HEX_getInstance() {
    TypeAtom_initEntries();
    return TypeAtom_HEX_instance;
  }
  function TypeAtom_QUOTES_getInstance() {
    TypeAtom_initEntries();
    return TypeAtom_QUOTES_instance;
  }
  function TypeAtom_DOUBLE_QUOTE_getInstance() {
    TypeAtom_initEntries();
    return TypeAtom_DOUBLE_QUOTE_instance;
  }
  function TypeAtom_SINGLE_QUOTE_getInstance() {
    TypeAtom_initEntries();
    return TypeAtom_SINGLE_QUOTE_instance;
  }
  function TypeAtom_SYMBOL_getInstance() {
    TypeAtom_initEntries();
    return TypeAtom_SYMBOL_instance;
  }
  function TypeAtom_OPERATOR_getInstance() {
    TypeAtom_initEntries();
    return TypeAtom_OPERATOR_instance;
  }
  function TypeAtom_CODE_getInstance() {
    TypeAtom_initEntries();
    return TypeAtom_CODE_instance;
  }
  function TypeAtom_NODE_getInstance() {
    TypeAtom_initEntries();
    return TypeAtom_NODE_instance;
  }
  function Utils() {
    Utils_instance = this;
  }
  Utils.prototype.irNew = function (type, value_0, offset) {
    var tmp;
    if (!(offset == null)) {
      tmp = Companion_getInstance_22().to_1(to(type, offset));
    } else {
      tmp = Companion_getInstance_22().to_2(type);
    }
    var x = tmp;
    return Companion_getInstance_22().to_1(to(x, value_0));
  };
  Utils.prototype.irNew$default = function (type, value_0, offset, $mask0, $handler) {
    if (!(($mask0 & 4) === 0))
      offset = null;
    return this.irNew(type, value_0, offset);
  };
  Utils.prototype.irCons = function (first_4, rest_0, offset) {
    var tmp = TypeAtom_CONS_getInstance()._value_6;
    return this.irNew(tmp, this.irNew$default(first_4, rest_0, null, 4, null), offset);
  };
  Utils.prototype.irCons$default = function (first_4, rest_0, offset, $mask0, $handler) {
    if (!(($mask0 & 4) === 0))
      offset = null;
    return this.irCons(first_4, rest_0, offset);
  };
  Utils.prototype.irNull = function () {
    var tmp = TypeAtom_NULL_getInstance()._value_6;
    return this.irNew$default(tmp, 0, null, 4, null);
  };
  Utils.prototype.irType = function (irSexp) {
    var type = first_1(irSexp);
    if (listp(type)) {
      type = first_1(type);
    }var tmp = Companion_getInstance_13();
    var tmp_0 = type._get_atom__4();
    return tmp.fromUByteArray(ensureNotNull(tmp_0 == null ? null : new UByteArray(tmp_0))._storage, Sign_POSITIVE_getInstance());
  };
  Utils.prototype.irVal = function (irSexp) {
    return rest(irSexp);
  };
  Utils.prototype.irNullp = function (irSexp) {
    return this.irType(irSexp).equals(TypeAtom_NULL_getInstance()._value_6._num);
  };
  Utils.prototype.irListp = function (irSexp) {
    return this.irType(irSexp).equals(TypeAtom_CONS_getInstance()._value_6._num);
  };
  Utils.prototype.irAsSexp = function (irSexp) {
    if (this.irNullp(irSexp)) {
      return Companion_getInstance_22().___null__;
    }if (this.irType(irSexp).equals(TypeAtom_CONS_getInstance()._value_6._num))
      return this.irAsSexp(this.irFirst(irSexp)).cons_3(this.irAsSexp(this.irRest(irSexp)));
    return rest(irSexp);
  };
  Utils.prototype.irAsAtom = function (irSexp) {
    var tmp = rest(irSexp)._get_atom__4();
    return ensureNotNull(tmp == null ? null : new UByteArray(tmp))._storage;
  };
  Utils.prototype.irFirst = function (irSexp) {
    return first_1(rest(irSexp));
  };
  Utils.prototype.irRest = function (irSexp) {
    return rest(rest(irSexp));
  };
  Utils.prototype.irSymbol = function (symbol) {
    var tmp = TypeAtom_SYMBOL_getInstance();
    var tmp0_toUByteArray_0 = encodeToByteArray(symbol);
    return new Pair(tmp, new UByteArray(_UByteArray___init__impl_(tmp0_toUByteArray_0.slice())));
  };
  Utils.prototype.irAsSymbol = function (irSexp) {
    var tmp;
    if (listp(irSexp) ? this.irType(irSexp).equals(TypeAtom_SYMBOL_getInstance()._value_6._num) : false) {
      var tmp_0 = this.irAsSexp(irSexp)._get_atom__4();
      var tmp0_toByteArray_0 = ensureNotNull(tmp_0 == null ? null : new UByteArray(tmp_0))._storage;
      var tmp0_copyOf_0_1 = _UByteArray___get_storage__impl_(tmp0_toByteArray_0);
      tmp = decodeToString(tmp0_copyOf_0_1.slice());
    } else {
      tmp = null;
    }
    return tmp;
  };
  Utils.prototype.isIr = function (sexp) {
    if (nullp(sexp) ? true : !listp(sexp)) {
      return false;
    }if (listp(first_1(sexp))) {
      return false;
    }var f = first_1(sexp)._get_atom__4();
    var tmp;
    var tmp_0 = f;
    if ((tmp_0 == null ? null : new UByteArray(tmp_0)) == null) {
      tmp = true;
    } else {
      {
        tmp = _UByteArray___get_size__impl_(f) > 1;
      }
    }
    if (tmp) {
      return false;
    } else {
    }
    var tmp_1 = Companion_getInstance_13();
    var tmp0_copyOf_0_1 = _UByteArray___get_storage__impl_(f);
    var theType = fromTwosComplementByteArray(tmp_1, tmp0_copyOf_0_1.slice());
    var tmp0_elvis_lhs = toTypeAtom(theType);
    var tmp_2;
    if (tmp0_elvis_lhs == null) {
      return false;
    } else {
      tmp_2 = tmp0_elvis_lhs;
    }
    var t = tmp_2;
    var r = rest(sexp);
    if (t.equals(TypeAtom_CONS_getInstance())) {
      if (nullp(r))
        return true;
      if (listp(r))
        return this.isIr(first_1(r)) ? this.isIr(rest(r)) : false;
      else
        return false;
    }return !listp(r);
  };
  Utils.$metadata$ = {
    simpleName: 'Utils',
    kind: 'object',
    interfaces: []
  };
  var Utils_instance;
  function Utils_getInstance() {
    if (Utils_instance == null)
      new Utils();
    return Utils_instance;
  }
  var ATOM_MATCH;
  var SEXP_MATCH;
  function match(pattern, sexp, knownBindings) {
    if (!listp(pattern)) {
      if (listp(sexp)) {
        return null;
      }return contentEquals_0(pattern._get_atom__4(), sexp._get_atom__4()) ? knownBindings : null;
    }var left = first_1(pattern);
    var right = rest(pattern);
    if (left.equals(ATOM_MATCH)) {
      if (listp(sexp))
        return null;
      var tmp = sexp._get_atom__4();
      var atom = ensureNotNull(tmp == null ? null : new UByteArray(tmp))._storage;
      if (right.equals(ATOM_MATCH)) {
        var tmp_0 = ATOM_MATCH._get_atom__4();
        if (contentEquals_0(atom, ensureNotNull(tmp_0 == null ? null : new UByteArray(tmp_0))._storage)) {
          return emptyMap();
        } else {
        }
        return null;
      }var tmp_1 = right._get_atom__4();
      return unifyBindings(knownBindings, ensureNotNull(tmp_1 == null ? null : new UByteArray(tmp_1))._storage, sexp);
    }if (left.equals(SEXP_MATCH)) {
      if (right.equals(SEXP_MATCH)) {
        var tmp_2 = sexp._get_atom__4();
        var atom_0 = ensureNotNull(tmp_2 == null ? null : new UByteArray(tmp_2))._storage;
        var tmp_3 = SEXP_MATCH._get_atom__4();
        if (contentEquals_0(atom_0, ensureNotNull(tmp_3 == null ? null : new UByteArray(tmp_3))._storage)) {
          return emptyMap();
        } else {
        }
        return null;
      }var tmp_4 = right._get_atom__4();
      return unifyBindings(knownBindings, ensureNotNull(tmp_4 == null ? null : new UByteArray(tmp_4))._storage, sexp);
    }if (!listp(sexp))
      return null;
    var newBindsings = match(left, first_1(sexp), knownBindings);
    if (newBindsings == null) {
      return newBindsings;
    } else {
      return match(right, rest(sexp), newBindsings);
    }
  }
  function match$default(pattern, sexp, knownBindings, $mask0, $handler) {
    if (!(($mask0 & 4) === 0)) {
      knownBindings = emptyMap();
    }return match(pattern, sexp, knownBindings);
  }
  function unifyBindings(bindings, iNewKey, newValue) {
    var tmp0_copyOf_0_1 = _UByteArray___get_storage__impl_(iNewKey);
    var newKey = decodeToString(tmp0_copyOf_0_1.slice());
    if ((isInterface(bindings, Map_0) ? bindings : THROW_CCE()).containsKey_7(newKey)) {
      if (!equals_0(bindings.get_56(newKey), newValue)) {
        return null;
      }return bindings;
    } else {
    }
    var newBindigs = HashMap_init_$Create$_2(bindings);
    newBindigs.put_6(newKey, newValue);
    Unit_getInstance();
    return newBindigs;
  }
  function ATOM_MATCH$init$() {
    var tmp = Companion_getInstance_22();
    var tmp0_toUByteArray_0 = encodeToByteArray('$');
    return tmp.to_8(new UByteArray(_UByteArray___init__impl_(tmp0_toUByteArray_0.slice())));
  }
  function SEXP_MATCH$init$() {
    var tmp = Companion_getInstance_22();
    var tmp0_toUByteArray_0 = encodeToByteArray(':');
    return tmp.to_8(new UByteArray(_UByteArray___init__impl_(tmp0_toUByteArray_0.slice())));
  }
  function Op() {
  }
  Op.prototype.asAtom_0 = function () {
    return ensureNotNull(this._get_opCode__0()).toUByteArray_10();
  };
  Op.$metadata$ = {
    simpleName: 'Op',
    kind: 'interface',
    interfaces: []
  };
  function _no_name_provided__107() {
  }
  _no_name_provided__107.prototype.invoke_257 = function (args) {
    if (!(listLen(args) === 3)) {
      throw new EvalError('' + 'if op takes 3 arguments: ' + args);
    }var r = rest(args);
    var tmp;
    if (nullp(first_3(args))) {
      Costs_getInstance();
      tmp = new Pair(toBigInteger(33), first_3(rest(r)));
    } else {
      Costs_getInstance();
      tmp = new Pair(toBigInteger(33), first_3(r));
    }
    return tmp;
  };
  _no_name_provided__107.prototype.invoke_1115 = function (p1) {
    return this.invoke_257(p1 instanceof SExp ? p1 : THROW_CCE());
  };
  _no_name_provided__107.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__108() {
  }
  _no_name_provided__108.prototype.invoke_257 = function (args) {
    if (!(listLen(args) === 2)) {
      throw new EvalError('' + 'cons op takes 2 arguments: ' + args);
    }Costs_getInstance();
    return new Pair(toBigInteger(50), first_3(args).cons_3(first_3(rest(args))));
  };
  _no_name_provided__108.prototype.invoke_1115 = function (p1) {
    return this.invoke_257(p1 instanceof SExp ? p1 : THROW_CCE());
  };
  _no_name_provided__108.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__109() {
  }
  _no_name_provided__109.prototype.invoke_257 = function (args) {
    if (!(listLen(args) === 1)) {
      throw new EvalError('' + 'first op takes 1 arguments: ' + args);
    }Costs_getInstance();
    return new Pair(toBigInteger(30), first_3(first_3(args)));
  };
  _no_name_provided__109.prototype.invoke_1115 = function (p1) {
    return this.invoke_257(p1 instanceof SExp ? p1 : THROW_CCE());
  };
  _no_name_provided__109.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__110() {
  }
  _no_name_provided__110.prototype.invoke_257 = function (args) {
    if (!(listLen(args) === 1)) {
      throw new EvalError('' + 'rest op takes 1 arguments: ' + args);
    }Costs_getInstance();
    return new Pair(toBigInteger(30), rest(first_3(args)));
  };
  _no_name_provided__110.prototype.invoke_1115 = function (p1) {
    return this.invoke_257(p1 instanceof SExp ? p1 : THROW_CCE());
  };
  _no_name_provided__110.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__111() {
  }
  _no_name_provided__111.prototype.invoke_257 = function (args) {
    if (!(listLen(args) === 1)) {
      throw new EvalError('' + 'l op takes 1 arguments: ' + args);
    }Costs_getInstance();
    return new Pair(toBigInteger(19), listp(first_3(args)) ? Companion_getInstance_22().___true__ : Companion_getInstance_22().___false__);
  };
  _no_name_provided__111.prototype.invoke_1115 = function (p1) {
    return this.invoke_257(p1 instanceof SExp ? p1 : THROW_CCE());
  };
  _no_name_provided__111.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__112() {
  }
  _no_name_provided__112.prototype.invoke_257 = function (args) {
    throw new EvalError('' + 'CLVM Raise: ' + args._get_hex_());
  };
  _no_name_provided__112.prototype.invoke_1115 = function (p1) {
    return this.invoke_257(p1 instanceof SExp ? p1 : THROW_CCE());
  };
  _no_name_provided__112.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__113() {
  }
  _no_name_provided__113.prototype.invoke_257 = function (args) {
    if (!(listLen(args) === 2)) {
      throw new EvalError('' + '= op takes 2 arguments: ' + args);
    }var a0 = first_3(args);
    var a1 = first_3(rest(args));
    if (!(a0._get_pair__3() == null) ? true : !(a1._get_pair__3() == null)) {
      throw new EvalError('= does not work on lisp lists');
    }var tmp = a0._get_atom__4();
    var b0 = ensureNotNull(tmp == null ? null : new UByteArray(tmp))._storage;
    var tmp_0 = a1._get_atom__4();
    var b1 = ensureNotNull(tmp_0 == null ? null : new UByteArray(tmp_0))._storage;
    Costs_getInstance();
    var tmp_1 = _UByteArray___get_size__impl_(b0) + _UByteArray___get_size__impl_(b1) | 0;
    Costs_getInstance();
    var cost = 117 + imul(tmp_1, 1) | 0;
    return new Pair(toBigInteger(cost), contentEquals_0(b0, b1) ? Companion_getInstance_22().___true__ : Companion_getInstance_22().___false__);
  };
  _no_name_provided__113.prototype.invoke_1115 = function (p1) {
    return this.invoke_257(p1 instanceof SExp ? p1 : THROW_CCE());
  };
  _no_name_provided__113.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function CoreOps() {
    CoreOps_instance = this;
    var tmp = this;
    tmp._opIf = buildOp(3, 'i', 'if', _no_name_provided_$factory_85());
    var tmp_0 = this;
    tmp_0._opCons = buildOp(4, 'c', 'cons', _no_name_provided_$factory_86());
    var tmp_1 = this;
    tmp_1._opFirst = buildOp(5, 'f', 'first', _no_name_provided_$factory_87());
    var tmp_2 = this;
    tmp_2._opRest = buildOp(6, 'r', 'rest', _no_name_provided_$factory_88());
    var tmp_3 = this;
    tmp_3._opListP = buildOp(7, 'l', 'listp', _no_name_provided_$factory_89());
    var tmp_4 = this;
    tmp_4._opRaise = buildOp(8, 'x', 'raise', _no_name_provided_$factory_90());
    var tmp_5 = this;
    tmp_5._opEq = buildOp(9, '=', 'eq', _no_name_provided_$factory_91());
    this._list_4 = listOf([this._opIf, this._opCons, this._opFirst, this._opRest, this._opListP, this._opRaise, this._opEq]);
  }
  CoreOps.$metadata$ = {
    simpleName: 'CoreOps',
    kind: 'object',
    interfaces: []
  };
  var CoreOps_instance;
  function CoreOps_getInstance() {
    if (CoreOps_instance == null)
      new CoreOps();
    return CoreOps_instance;
  }
  function OperatorLookupCallable() {
  }
  OperatorLookupCallable.$metadata$ = {
    simpleName: 'OperatorLookupCallable',
    kind: 'interface',
    interfaces: []
  };
  function buildOp(opCode, rewriteSymbol, name, op) {
    return buildOp_0(BigInteger_init_$Create$_0(opCode), rewriteSymbol, name, op);
  }
  function buildOp_0(opCode, rewriteSymbol, opName, op) {
    return new _no_name_provided__114(opName, rewriteSymbol, opCode, op);
  }
  function buildOp_1(opCode, name, op) {
    return buildOp_0(BigInteger_init_$Create$_0(opCode), name, name, op);
  }
  function buildOpByName(name, op) {
    return buildOp_0(Companion_getInstance_13().fromByteArray(encodeToByteArray(name), Sign_POSITIVE_getInstance()), name, name, op);
  }
  function buildOpByName$default(name, op, $mask0, $handler) {
    if (!(($mask0 & 2) === 0)) {
      op = _no_name_provided_$factory_92();
    }return buildOpByName(name, op);
  }
  function _no_name_provided__114($opName, $rewriteSymbol, $opCode, $op) {
    this._$opName = $opName;
    this._$rewriteSymbol = $rewriteSymbol;
    this._$opCode = $opCode;
    this._$op = $op;
    this._opName = this._$opName;
    this._rewriteSymbol = this._$rewriteSymbol;
    this._opCode = this._$opCode;
  }
  _no_name_provided__114.prototype._get_rewriteSymbol__0 = function () {
    return this._rewriteSymbol;
  };
  _no_name_provided__114.prototype.call_3 = function (args) {
    return this._$op(args);
  };
  _no_name_provided__114.prototype._get_opCode__0 = function () {
    return this._opCode;
  };
  _no_name_provided__114.$metadata$ = {
    kind: 'class',
    interfaces: [Op]
  };
  function _no_name_provided__115() {
  }
  _no_name_provided__115.prototype.invoke_257 = function (it) {
    return new Pair(Companion_getInstance_13()._ZERO_0, it);
  };
  _no_name_provided__115.prototype.invoke_1115 = function (p1) {
    return this.invoke_257(p1 instanceof SExp ? p1 : THROW_CCE());
  };
  _no_name_provided__115.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided_$factory_85() {
    var i = new _no_name_provided__107();
    return function (p1) {
      return i.invoke_257(p1);
    };
  }
  function _no_name_provided_$factory_86() {
    var i = new _no_name_provided__108();
    return function (p1) {
      return i.invoke_257(p1);
    };
  }
  function _no_name_provided_$factory_87() {
    var i = new _no_name_provided__109();
    return function (p1) {
      return i.invoke_257(p1);
    };
  }
  function _no_name_provided_$factory_88() {
    var i = new _no_name_provided__110();
    return function (p1) {
      return i.invoke_257(p1);
    };
  }
  function _no_name_provided_$factory_89() {
    var i = new _no_name_provided__111();
    return function (p1) {
      return i.invoke_257(p1);
    };
  }
  function _no_name_provided_$factory_90() {
    var i = new _no_name_provided__112();
    return function (p1) {
      return i.invoke_257(p1);
    };
  }
  function _no_name_provided_$factory_91() {
    var i = new _no_name_provided__113();
    return function (p1) {
      return i.invoke_257(p1);
    };
  }
  function _no_name_provided_$factory_92() {
    var i = new _no_name_provided__115();
    return function (p1) {
      return i.invoke_257(p1);
    };
  }
  function Costs() {
    Costs_instance = this;
    this._IF_COST = 33;
    this._CONS_COST = 50;
    this._FIRST_COST = 30;
    this._REST_COST = 30;
    this._LISTP_COST = 19;
    this._MALLOC_COST_PER_BYTE = 10;
    this._ARITH_BASE_COST = 99;
    this._ARITH_COST_PER_BYTE = 3;
    this._ARITH_COST_PER_ARG = 320;
    this._LOG_BASE_COST = 100;
    this._LOG_COST_PER_BYTE = 3;
    this._LOG_COST_PER_ARG = 264;
    this._GRS_BASE_COST = 117;
    this._GRS_COST_PER_BYTE = 1;
    this._EQ_BASE_COST = 117;
    this._EQ_COST_PER_BYTE = 1;
    this._GR_BASE_COST = 498;
    this._GR_COST_PER_BYTE = 2;
    this._DIVMOD_BASE_COST = 1116;
    this._DIVMOD_COST_PER_BYTE = 6;
    this._DIV_BASE_COST = 988;
    this._DIV_COST_PER_BYTE = 4;
    this._SHA256_BASE_COST = 87;
    this._SHA256_COST_PER_ARG = 134;
    this._SHA256_COST_PER_BYTE = 2;
    this._POINT_ADD_BASE_COST = 101094;
    this._POINT_ADD_COST_PER_ARG = 1343980;
    this._PUBKEY_BASE_COST = 1325730;
    this._PUBKEY_COST_PER_BYTE = 38;
    this._MUL_BASE_COST = 92;
    this._MUL_COST_PER_OP = 885;
    this._MUL_LINEAR_COST_PER_BYTE = 6;
    this._MUL_SQUARE_COST_PER_BYTE_DIVIDER = 128;
    this._STRLEN_BASE_COST = 173;
    this._STRLEN_COST_PER_BYTE = 1;
    this._PATH_LOOKUP_BASE_COST = 40;
    this._PATH_LOOKUP_COST_PER_LEG = 4;
    this._PATH_LOOKUP_COST_PER_ZERO_BYTE = 4;
    this._CONCAT_BASE_COST = 142;
    this._CONCAT_COST_PER_ARG = 135;
    this._CONCAT_COST_PER_BYTE = 3;
    this._BOOL_BASE_COST = 200;
    this._BOOL_COST_PER_ARG = 300;
    this._ASHIFT_BASE_COST = 596;
    this._ASHIFT_COST_PER_BYTE = 3;
    this._LSHIFT_BASE_COST = 277;
    this._LSHIFT_COST_PER_BYTE = 3;
    this._LOGNOT_BASE_COST = 331;
    this._LOGNOT_COST_PER_BYTE = 3;
    this._APPLY_COST = 90;
    this._QUOTE_COST = 20;
  }
  Costs.$metadata$ = {
    simpleName: 'Costs',
    kind: 'object',
    interfaces: []
  };
  var Costs_instance;
  function Costs_getInstance() {
    if (Costs_instance == null)
      new Costs();
    return Costs_instance;
  }
  function _no_name_provided__116() {
  }
  _no_name_provided__116.prototype.invoke_195 = function (a, b) {
    return a.and_0(b);
  };
  _no_name_provided__116.prototype.invoke_915 = function (p1, p2) {
    var tmp = p1 instanceof BigInteger ? p1 : THROW_CCE();
    return this.invoke_195(tmp, p2 instanceof BigInteger ? p2 : THROW_CCE());
  };
  _no_name_provided__116.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__117() {
  }
  _no_name_provided__117.prototype.invoke_195 = function (a, b) {
    return a.or_0(b);
  };
  _no_name_provided__117.prototype.invoke_915 = function (p1, p2) {
    var tmp = p1 instanceof BigInteger ? p1 : THROW_CCE();
    return this.invoke_195(tmp, p2 instanceof BigInteger ? p2 : THROW_CCE());
  };
  _no_name_provided__117.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__118() {
  }
  _no_name_provided__118.prototype.invoke_195 = function (a, b) {
    return a.xor_0(b);
  };
  _no_name_provided__118.prototype.invoke_915 = function (p1, p2) {
    var tmp = p1 instanceof BigInteger ? p1 : THROW_CCE();
    return this.invoke_195(tmp, p2 instanceof BigInteger ? p2 : THROW_CCE());
  };
  _no_name_provided__118.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__119() {
  }
  _no_name_provided__119.prototype.invoke_257 = function (args) {
    Costs_getInstance();
    var cost = toBigInteger(87);
    var argLen = 0;
    var tmp0_ubyteArrayOf_0 = _UByteArray___init__impl_(new Int8Array([]));
    var tmp1_fold_0 = tmp0_ubyteArrayOf_0;
    var accumulator_1 = tmp1_fold_0;
    var tmp0_iterator_2 = args.iterator_66();
    while (tmp0_iterator_2.hasNext_41()) {
      var element_3 = tmp0_iterator_2.next_46();
      var tmp2__anonymous__4 = accumulator_1;
      var tmp = element_3._get_atom__4();
      if ((tmp == null ? null : new UByteArray(tmp)) == null) {
        throw new EvalError('Sha256 on list');
      } else {
      }
      var tmp_0 = argLen;
      var tmp_1 = element_3._get_atom__4();
      argLen = tmp_0 + _UByteArray___get_size__impl_(ensureNotNull(tmp_1 == null ? null : new UByteArray(tmp_1))._storage) | 0;
      var tmp_2 = cost;
      Costs_getInstance();
      cost = tmp_2.plus_70(134);
      var tmp_3 = element_3._get_atom__4();
      var tmp0_plus_0_5 = ensureNotNull(tmp_3 == null ? null : new UByteArray(tmp_3))._storage;
      var tmp0_plus_0_1_6 = _UByteArray___get_storage__impl_(tmp2__anonymous__4);
      var tmp1_plus_0_2_7 = _UByteArray___get_storage__impl_(tmp0_plus_0_5);
      accumulator_1 = _UByteArray___init__impl_(primitiveArrayConcat([tmp0_plus_0_1_6, tmp1_plus_0_2_7]));
    }
    var bytes = accumulator_1;
    var hash = (new Sha256_0()).digest_0(bytes);
    var tmp_4 = cost;
    var tmp_5 = argLen;
    Costs_getInstance();
    cost = tmp_4.plus_70(imul(tmp_5, 2));
    return MoreOps_getInstance().mallocCost(cost, Companion_getInstance_22().to_8(new UByteArray(hash)));
  };
  _no_name_provided__119.prototype.invoke_1115 = function (p1) {
    return this.invoke_257(p1 instanceof SExp ? p1 : THROW_CCE());
  };
  _no_name_provided__119.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__120($opName) {
    this._$opName_0 = $opName;
  }
  _no_name_provided__120.prototype.invoke_257 = function (it) {
    if (!(it._get_pair__3() == null))
      throw new EvalError('' + this._$opName_0 + ' needs atom args');
    var tmp = it._get_atom__4();
    if (_UByteArray___get_size__impl_(ensureNotNull(tmp == null ? null : new UByteArray(tmp))._storage) > 4) {
      throw new EvalError('' + this._$opName_0 + ' too big for int32');
    } else {
    }
    var tmp_0 = it._get_atom__4();
    var tmp_1 = new UInt(readUInt(ensureNotNull(tmp_0 == null ? null : new UByteArray(tmp_0))._storage));
    var tmp_2 = it._get_atom__4();
    return new Pair(tmp_1, _UByteArray___get_size__impl_(ensureNotNull(tmp_2 == null ? null : new UByteArray(tmp_2))._storage));
  };
  _no_name_provided__120.prototype.invoke_1115 = function (p1) {
    return this.invoke_257(p1 instanceof SExp ? p1 : THROW_CCE());
  };
  _no_name_provided__120.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__121($opName) {
    this._$opName_1 = $opName;
  }
  _no_name_provided__121.prototype.invoke_257 = function (it) {
    if (!(it._get_pair__3() == null))
      throw new EvalError('' + this._$opName_1 + ' needs int');
    var tmp = Companion_getInstance_13();
    var tmp_0 = it._get_atom__4();
    var tmp0_toByteArray_0 = ensureNotNull(tmp_0 == null ? null : new UByteArray(tmp_0))._storage;
    var tmp0_copyOf_0_1 = _UByteArray___get_storage__impl_(tmp0_toByteArray_0);
    var tmp_1 = fromTwosComplementByteArray(tmp, tmp0_copyOf_0_1.slice());
    var tmp_2 = it._get_atom__4();
    return new Pair(tmp_1, _UByteArray___get_size__impl_(ensureNotNull(tmp_2 == null ? null : new UByteArray(tmp_2))._storage));
  };
  _no_name_provided__121.prototype.invoke_1115 = function (p1) {
    return this.invoke_257(p1 instanceof SExp ? p1 : THROW_CCE());
  };
  _no_name_provided__121.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__122() {
  }
  _no_name_provided__122.prototype.invoke_203 = function (it) {
    var tmp0_toInt_0 = it._first._data_0;
    return new Pair(_UInt___get_data__impl_(tmp0_toInt_0), it._second);
  };
  _no_name_provided__122.prototype.invoke_1115 = function (p1) {
    return this.invoke_203(p1 instanceof Pair ? p1 : THROW_CCE());
  };
  _no_name_provided__122.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__123($opName, $args) {
    this._$opName_2 = $opName;
    this._$args = $args;
  }
  _no_name_provided__123.prototype.invoke_257 = function (it) {
    if (!(it._get_pair__3() == null))
      throw new EvalError('' + this._$opName_2 + ' not expecting pair ' + this._$args);
    var tmp = it._get_atom__4();
    var bytes = ensureNotNull(tmp == null ? null : new UByteArray(tmp))._storage;
    return Companion_getInstance_22().___false__.equals(new UByteArray(bytes)) ? Companion_getInstance_22().___false__ : Companion_getInstance_22().___true__;
  };
  _no_name_provided__123.prototype.invoke_1115 = function (p1) {
    return this.invoke_257(p1 instanceof SExp ? p1 : THROW_CCE());
  };
  _no_name_provided__123.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__124() {
  }
  _no_name_provided__124.prototype.invoke_257 = function (args) {
    var total = BigInteger_init_$Create$(new Long(0, 0));
    Costs_getInstance();
    var cost = toBigInteger(99);
    var argSize = 0;
    var tmp0_forEach_0 = MoreOps_getInstance().argsAsBig('+', args);
    var tmp0_iterator_1 = tmp0_forEach_0.iterator_66();
    while (tmp0_iterator_1.hasNext_41()) {
      var element_2 = tmp0_iterator_1.next_46();
      total = total.plus_4(element_2._first);
      argSize = argSize + element_2._second | 0;
      var tmp = cost;
      Costs_getInstance();
      cost = tmp.plus_70(320);
    }
    var tmp_0 = cost;
    var tmp_1 = argSize;
    Costs_getInstance();
    cost = tmp_0.plus_70(imul(tmp_1, 3));
    return MoreOps_getInstance().mallocCost(cost, Companion_getInstance_22().to_6(total));
  };
  _no_name_provided__124.prototype.invoke_1115 = function (p1) {
    return this.invoke_257(p1 instanceof SExp ? p1 : THROW_CCE());
  };
  _no_name_provided__124.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__125() {
  }
  _no_name_provided__125.prototype.invoke_257 = function (args) {
    var total = BigInteger_init_$Create$(new Long(0, 0));
    Costs_getInstance();
    var cost = toBigInteger(99);
    var argSize = 0;
    var sign = 1;
    var tmp0_forEach_0 = MoreOps_getInstance().argsAsBig('-', args);
    var tmp0_iterator_1 = tmp0_forEach_0.iterator_66();
    while (tmp0_iterator_1.hasNext_41()) {
      var element_2 = tmp0_iterator_1.next_46();
      total = total.plus_4(element_2._first.times_39(sign));
      sign = -1;
      argSize = argSize + element_2._second | 0;
      var tmp = cost;
      Costs_getInstance();
      cost = tmp.plus_70(320);
    }
    var tmp_0 = cost;
    var tmp_1 = argSize;
    Costs_getInstance();
    cost = tmp_0.plus_70(imul(tmp_1, 3));
    return MoreOps_getInstance().mallocCost(cost, Companion_getInstance_22().to_6(total));
  };
  _no_name_provided__125.prototype.invoke_1115 = function (p1) {
    return this.invoke_257(p1 instanceof SExp ? p1 : THROW_CCE());
  };
  _no_name_provided__125.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__126() {
  }
  _no_name_provided__126.prototype.invoke_257 = function (args) {
    Costs_getInstance();
    var cost = toBigInteger(92);
    var operands = MoreOps_getInstance().argsAsBig('*', args);
    if (count(operands) === 0) {
      MoreOps_getInstance().mallocCost(cost, Companion_getInstance_22().to_4(1));
      Unit_getInstance();
    }var v = first_1(operands);
    var num = v._first;
    var vs = v._second;
    var tmp0_forEach_0 = drop_0(operands, 1);
    var tmp0_iterator_1 = tmp0_forEach_0.iterator_66();
    while (tmp0_iterator_1.hasNext_41()) {
      var element_2 = tmp0_iterator_1.next_46();
      var tmp = cost;
      Costs_getInstance();
      cost = tmp.plus_70(885);
      var tmp_0 = cost;
      var tmp_1 = element_2._second + vs | 0;
      Costs_getInstance();
      cost = tmp_0.plus_70(imul(tmp_1, 6));
      var tmp_2 = cost;
      var tmp_3 = imul(element_2._second, vs);
      Costs_getInstance();
      cost = tmp_2.plus_70(tmp_3 / 128 | 0);
      num = num.times_0(element_2._first);
      vs = bytesRequired(num);
    }
    return MoreOps_getInstance().mallocCost(cost, Companion_getInstance_22().to_6(num));
  };
  _no_name_provided__126.prototype.invoke_1115 = function (p1) {
    return this.invoke_257(p1 instanceof SExp ? p1 : THROW_CCE());
  };
  _no_name_provided__126.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__127() {
  }
  _no_name_provided__127.prototype.invoke_257 = function (args) {
    Costs_getInstance();
    var cost = toBigInteger(1116);
    var i = toList_1(MoreOps_getInstance().argsAsBigList('divmod', args, 2));
    if (i.get_72(1)._first.equals(Companion_getInstance_13()._ZERO_0)) {
      throw new EvalError('' + 'Divmod by 0 ' + args);
    }var tmp = cost;
    var tmp_0 = i.get_72(0)._second + i.get_72(1)._second | 0;
    Costs_getInstance();
    cost = tmp.plus_70(imul(tmp_0, 6));
    var q = i.get_72(0)._first.div_1(i.get_72(1)._first);
    var r = i.get_72(0)._first.rem_1(i.get_72(1)._first);
    var q1 = Companion_getInstance_22().to_6(q);
    var r1 = Companion_getInstance_22().to_6(r);
    var tmp_1 = cost;
    var tmp_2 = q1._get_atom__4();
    var tmp_3 = _UByteArray___get_size__impl_(ensureNotNull(tmp_2 == null ? null : new UByteArray(tmp_2))._storage);
    var tmp_4 = r1._get_atom__4();
    var tmp_5 = _UByteArray___get_size__impl_(ensureNotNull(tmp_4 == null ? null : new UByteArray(tmp_4))._storage);
    Costs_getInstance();
    cost = tmp_1.plus_70(tmp_3 + imul(tmp_5, 10) | 0);
    return to(cost, Companion_getInstance_22().to_1(new Pair(q, r)));
  };
  _no_name_provided__127.prototype.invoke_1115 = function (p1) {
    return this.invoke_257(p1 instanceof SExp ? p1 : THROW_CCE());
  };
  _no_name_provided__127.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__128() {
  }
  _no_name_provided__128.prototype.invoke_257 = function (args) {
    Costs_getInstance();
    var cost = toBigInteger(988);
    var i = toList_1(MoreOps_getInstance().argsAsBigList('/', args, 2));
    if (i.get_72(1)._first.equals(Companion_getInstance_13()._ZERO_0)) {
      throw new EvalError('' + 'div by 0 ' + args);
    }var tmp = cost;
    var tmp_0 = i.get_72(0)._second + i.get_72(1)._second | 0;
    Costs_getInstance();
    cost = tmp.plus_70(imul(tmp_0, 4));
    var q = i.get_72(0)._first.div_1(i.get_72(1)._first);
    return MoreOps_getInstance().mallocCost(cost, Companion_getInstance_22().to_6(q));
  };
  _no_name_provided__128.prototype.invoke_1115 = function (p1) {
    return this.invoke_257(p1 instanceof SExp ? p1 : THROW_CCE());
  };
  _no_name_provided__128.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__129() {
  }
  _no_name_provided__129.prototype.invoke_257 = function (args) {
    var i = toList_1(MoreOps_getInstance().argsAsBigList('>', args, 2));
    var i0 = i.get_72(0)._first;
    var l0 = i.get_72(0)._second;
    var i1 = i.get_72(1)._first;
    var l1 = i.get_72(1)._second;
    Costs_getInstance();
    var cost = toBigInteger(498);
    var tmp = cost;
    var tmp_0 = l0 + l1 | 0;
    Costs_getInstance();
    cost = tmp.plus_70(imul(tmp_0, 2));
    return to(cost, i0.compareTo_26(i1) > 0 ? Companion_getInstance_22().___true__ : Companion_getInstance_22().___false__);
  };
  _no_name_provided__129.prototype.invoke_1115 = function (p1) {
    return this.invoke_257(p1 instanceof SExp ? p1 : THROW_CCE());
  };
  _no_name_provided__129.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__130() {
  }
  _no_name_provided__130.prototype.invoke_257 = function (args) {
    var argList = toList_1(args);
    if (!(argList._get_size__52() === 2)) {
      throw new EvalError('>s takes exactly 2 arugments');
    }var a0 = argList.get_72(0);
    var a1 = argList.get_72(1);
    if (!(a0._get_pair__3() == null) ? true : !(a1._get_pair__3() == null)) {
      throw new EvalError('>s on list');
    }var tmp = a0._get_atom__4();
    var b0 = ensureNotNull(tmp == null ? null : new UByteArray(tmp))._storage;
    var tmp_0 = a1._get_atom__4();
    var b1 = ensureNotNull(tmp_0 == null ? null : new UByteArray(tmp_0))._storage;
    Costs_getInstance();
    var cost = toBigInteger(117);
    var tmp_1 = cost;
    var tmp_2 = _UByteArray___get_size__impl_(b0) + _UByteArray___get_size__impl_(b1) | 0;
    Costs_getInstance();
    cost = tmp_1.plus_70(imul(tmp_2, 1));
    return to(cost, Companion_getInstance_13().fromUByteArray(b0, Sign_POSITIVE_getInstance()).compareTo_26(Companion_getInstance_13().fromUByteArray(b1, Sign_POSITIVE_getInstance())) > 0 ? Companion_getInstance_22().___true__ : Companion_getInstance_22().___false__);
  };
  _no_name_provided__130.prototype.invoke_1115 = function (p1) {
    return this.invoke_257(p1 instanceof SExp ? p1 : THROW_CCE());
  };
  _no_name_provided__130.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__131() {
  }
  _no_name_provided__131.prototype.invoke_257 = function (args) {
    var i = toList_1(MoreOps_getInstance().argsAsBigList('pubkey_for_exp', args, 1));
    var i0 = i.get_72(0)._first;
    var l0 = i.get_72(0)._second;
    i0 = i0.mod(Companion_getInstance_13().parseString_0('73EDA753299D7D483339D80809A1D80553BDA402FFFE5BFEFFFFFFFF00000001', 16));
    var exponent = Companion_getInstance_19().fromByteArray_2(i0.toByteArray_10());
    var tmp;
    try {
      var r = Companion_getInstance_22().to_3(PrivateKey__getG1_impl(exponent).toByteArray_10());
      Costs_getInstance();
      var tmp_0 = toBigInteger(1325730);
      Costs_getInstance();
      var cost = tmp_0.plus_70(imul(38, l0));
      tmp = MoreOps_getInstance().mallocCost(cost, r);
    } catch ($p) {
      var tmp_1;
      if ($p instanceof Exception) {
        throw new EvalError('op_pubkey_for_exp');
      } else {
        {
          throw $p;
        }
      }
      tmp = tmp_1;
    }
    return tmp;
  };
  _no_name_provided__131.prototype.invoke_1115 = function (p1) {
    return this.invoke_257(p1 instanceof SExp ? p1 : THROW_CCE());
  };
  _no_name_provided__131.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__132() {
  }
  _no_name_provided__132.prototype.invoke_257 = function (args) {
    Costs_getInstance();
    var cost = toBigInteger(101094);
    var tmp0_fold_0 = G1Infinity$default(null, null, 3, null);
    var accumulator_1 = tmp0_fold_0;
    var tmp0_iterator_2 = args.iterator_66();
    while (tmp0_iterator_2.hasNext_41()) {
      var element_3 = tmp0_iterator_2.next_46();
      var tmp1__anonymous__4 = accumulator_1;
      if (!(element_3._get_pair__3() == null)) {
        throw new EvalError('Cannot add lisp list here');
      }var tmp = cost;
      Costs_getInstance();
      cost = tmp.plus_70(1343980);
      var tmp_0 = element_3._get_atom__4();
      var tmp0_toByteArray_0_5 = ensureNotNull(tmp_0 == null ? null : new UByteArray(tmp_0))._storage;
      var tmp0_copyOf_0_1_6 = _UByteArray___get_storage__impl_(tmp0_toByteArray_0_5);
      var tmp_1 = tmp0_copyOf_0_1_6.slice();
      accumulator_1 = tmp1__anonymous__4.plus_36(G1FromBytes$default(tmp_1, null, null, 6, null));
    }
    var result = accumulator_1;
    return MoreOps_getInstance().mallocCost(cost, Companion_getInstance_22().to_5(result));
  };
  _no_name_provided__132.prototype.invoke_1115 = function (p1) {
    return this.invoke_257(p1 instanceof SExp ? p1 : THROW_CCE());
  };
  _no_name_provided__132.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__133() {
  }
  _no_name_provided__133.prototype.invoke_257 = function (args) {
    if (!(count(args) === 1)) {
      throw new EvalError('' + 'String Len takes one argument. Got ' + args);
    }var a0 = first_1(args);
    if (!(a0._get_pair__3() == null)) {
      throw new EvalError('' + 'String Len does not work on a list ' + args);
    }var tmp = a0._get_atom__4();
    var size_0 = _UByteArray___get_size__impl_(ensureNotNull(tmp == null ? null : new UByteArray(tmp))._storage);
    Costs_getInstance();
    var tmp_0 = toBigInteger(173);
    Costs_getInstance();
    var cost = tmp_0.plus_4(toBigInteger(1).times_39(size_0));
    return MoreOps_getInstance().mallocCost(cost, Companion_getInstance_22().to_4(size_0));
  };
  _no_name_provided__133.prototype.invoke_1115 = function (p1) {
    return this.invoke_257(p1 instanceof SExp ? p1 : THROW_CCE());
  };
  _no_name_provided__133.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__134() {
  }
  _no_name_provided__134.prototype.invoke_257 = function (args) {
    var argCounts = count(args);
    if (!(argCounts === 2) ? !(argCounts === 3) : false) {
      throw new EvalError('' + 'Substr takes 2 or 3 arguments not ' + argCounts);
    }var a0 = first_1(args);
    if (!(a0._get_pair__3() == null)) {
      throw new EvalError('Substr on list');
    }var tmp = a0._get_atom__4();
    var stringAtom = ensureNotNull(tmp == null ? null : new UByteArray(tmp))._storage;
    var tmp_0;
    if (argCounts === 2) {
      tmp_0 = to(first_1(MoreOps_getInstance().argsAsInt32('substr', rest(args)))._first, _UByteArray___get_size__impl_(stringAtom));
    } else {
      var ints = toList_1(MoreOps_getInstance().argsAsInt32('substr', rest(args)));
      tmp_0 = to(ints.get_72(0)._first, ints.get_72(1)._first);
    }
    var res = tmp_0;
    if (((res._second > _UByteArray___get_size__impl_(stringAtom) ? true : res._second < res._first) ? true : res._second < 0) ? true : res._first < 0) {
      throw new EvalError('' + 'Invalid substr arguments: ' + args);
    }var substr = toUByteArray(slice_2(stringAtom, until(res._first, res._second)));
    return to(Companion_getInstance_13()._ONE_0, Companion_getInstance_22().to_8(new UByteArray(substr)));
  };
  _no_name_provided__134.prototype.invoke_1115 = function (p1) {
    return this.invoke_257(p1 instanceof SExp ? p1 : THROW_CCE());
  };
  _no_name_provided__134.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__135() {
  }
  _no_name_provided__135.prototype.invoke_257 = function (args) {
    Costs_getInstance();
    var cost = toBigInteger(142);
    var s = _UByteArray___init__impl__0(0);
    var tmp0_iterator_1 = args.iterator_66();
    while (tmp0_iterator_1.hasNext_41()) {
      var element_2 = tmp0_iterator_1.next_46();
      if (!(element_2._get_pair__3() == null))
        throw new EvalError('' + 'concat on list: ' + args);
      var tmp0_plus_0_4 = s;
      var tmp = element_2._get_atom__4();
      var tmp1_plus_0_5 = ensureNotNull(tmp == null ? null : new UByteArray(tmp))._storage;
      var tmp0_plus_0_1_6 = _UByteArray___get_storage__impl_(tmp0_plus_0_4);
      var tmp1_plus_0_2_7 = _UByteArray___get_storage__impl_(tmp1_plus_0_5);
      s = _UByteArray___init__impl_(primitiveArrayConcat([tmp0_plus_0_1_6, tmp1_plus_0_2_7]));
      var tmp_0 = cost;
      Costs_getInstance();
      cost = tmp_0.plus_70(135);
    }
    var tmp_1 = cost;
    var tmp_2 = _UByteArray___get_size__impl_(s);
    Costs_getInstance();
    cost = tmp_1.plus_70(imul(tmp_2, 3));
    return MoreOps_getInstance().mallocCost(cost, Companion_getInstance_22().to_8(new UByteArray(s)));
  };
  _no_name_provided__135.prototype.invoke_1115 = function (p1) {
    return this.invoke_257(p1 instanceof SExp ? p1 : THROW_CCE());
  };
  _no_name_provided__135.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__136() {
  }
  _no_name_provided__136.prototype.invoke_257 = function (args) {
    var argList = toList_1(MoreOps_getInstance().argsAsBigList('ash', args, 2));
    var i0 = argList.get_72(0)._first;
    var l0 = argList.get_72(0)._second;
    var i1 = argList.get_72(1)._first;
    var l1 = argList.get_72(1)._second;
    if (l1 > 4)
      throw new EvalError('' + 'Ash requires int32 args with no leadings 0s ' + argList);
    if (i1.compareTo_26(65535) > 0)
      throw new EvalError('' + 'Shifts too large ' + Companion_getInstance_22().to_6(i1));
    var shift = i1.intValue(true);
    var r = i1.compareTo_26(0) >= 0 ? i0.shl_4(shift) : i0.shr_4(-shift | 0);
    Costs_getInstance();
    var tmp = toBigInteger(596);
    Costs_getInstance();
    var cost = tmp.plus_4(toBigInteger(3).times_39(bytesRequired_0(l0)));
    return MoreOps_getInstance().mallocCost(cost, Companion_getInstance_22().to_6(r));
  };
  _no_name_provided__136.prototype.invoke_1115 = function (p1) {
    return this.invoke_257(p1 instanceof SExp ? p1 : THROW_CCE());
  };
  _no_name_provided__136.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__137() {
  }
  _no_name_provided__137.prototype.invoke_257 = function (args) {
    var argList = toList_1(MoreOps_getInstance().argsAsBigList('lsh', args, 2));
    var l0 = argList.get_72(0)._second;
    var i1 = argList.get_72(1)._first;
    var l1 = argList.get_72(1)._second;
    if (l1 > 4)
      throw new EvalError('' + 'Ash requires int32 args with no leadings 0s ' + argList);
    if (i1.compareTo_26(65535) > 0)
      throw new EvalError('' + 'Shifts too large ' + Companion_getInstance_22().to_6(i1));
    var tmp = Companion_getInstance_13();
    var tmp_0 = first_1(args)._get_atom__4();
    var i0 = tmp.fromUByteArray(ensureNotNull(tmp_0 == null ? null : new UByteArray(tmp_0))._storage, Sign_POSITIVE_getInstance()).intValue(true);
    var shift = i1.intValue(true);
    var r = i1.compareTo_26(0) >= 0 ? i0 << shift : i0 >> (-shift | 0);
    Costs_getInstance();
    var tmp_1 = toBigInteger(277);
    Costs_getInstance();
    var cost = tmp_1.plus_4(toBigInteger(3).times_39(bytesRequired_0(l0)));
    return MoreOps_getInstance().mallocCost(cost, Companion_getInstance_22().to_4(r));
  };
  _no_name_provided__137.prototype.invoke_1115 = function (p1) {
    return this.invoke_257(p1 instanceof SExp ? p1 : THROW_CCE());
  };
  _no_name_provided__137.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__138() {
  }
  _no_name_provided__138.prototype.invoke_235 = function (it) {
    return it._first;
  };
  _no_name_provided__138.prototype.invoke_1115 = function (p1) {
    return this.invoke_235(p1 instanceof Pair ? p1 : THROW_CCE());
  };
  _no_name_provided__138.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__139() {
  }
  _no_name_provided__139.prototype.invoke_257 = function (args) {
    var binop = _no_name_provided_$factory_121();
    return MoreOps_getInstance().binopReduce('logand', args, binop);
  };
  _no_name_provided__139.prototype.invoke_1115 = function (p1) {
    return this.invoke_257(p1 instanceof SExp ? p1 : THROW_CCE());
  };
  _no_name_provided__139.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__140() {
  }
  _no_name_provided__140.prototype.invoke_257 = function (args) {
    var binop = _no_name_provided_$factory_122();
    return MoreOps_getInstance().binopReduce('logior', args, binop);
  };
  _no_name_provided__140.prototype.invoke_1115 = function (p1) {
    return this.invoke_257(p1 instanceof SExp ? p1 : THROW_CCE());
  };
  _no_name_provided__140.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__141() {
  }
  _no_name_provided__141.prototype.invoke_257 = function (args) {
    var binop = _no_name_provided_$factory_123();
    return MoreOps_getInstance().binopReduce('logxor', args, binop);
  };
  _no_name_provided__141.prototype.invoke_1115 = function (p1) {
    return this.invoke_257(p1 instanceof SExp ? p1 : THROW_CCE());
  };
  _no_name_provided__141.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__142() {
  }
  _no_name_provided__142.prototype.invoke_257 = function (args) {
    if (!(count(args) === 1)) {
      throw new EvalError('' + 'Invalid # lognot args. Expected 1. Found ' + count(args));
    }var tmp = first_1(args)._get_atom__4();
    var bytes = ensureNotNull(tmp == null ? null : new UByteArray(tmp))._storage;
    Costs_getInstance();
    var tmp_0 = toBigInteger(331);
    var tmp_1 = _UByteArray___get_size__impl_(bytes);
    Costs_getInstance();
    var cost = tmp_0.plus_70(imul(tmp_1, 3));
    var tmp_2;
    if (UByteArray__isEmpty_impl(bytes)) {
      tmp_2 = MoreOps_getInstance().mallocCost(cost, Companion_getInstance_22().to_6(BigInteger_init_$Create$_0(-1)));
    } else {
      var tmp0_mapTo_0_1 = ArrayList_init_$Create$_0(_UByteArray___get_size__impl_(bytes));
      var tmp0_iterator_1_2 = UByteArray__iterator_impl(bytes);
      while (tmp0_iterator_1_2.hasNext_41()) {
        var item_2_3 = tmp0_iterator_1_2.next_46()._data;
        var tmp0_inv_0_1_5 = _UByte___get_data__impl_(item_2_3);
        tmp0_mapTo_0_1.add_39(new UByte(_UByte___init__impl_(toByte(~tmp0_inv_0_1_5))));
        Unit_getInstance();
      }
      var map_0 = toUByteArray(tmp0_mapTo_0_1);
      tmp_2 = MoreOps_getInstance().mallocCost(cost, Companion_getInstance_22().to_8(new UByteArray(map_0)));
    }
    return tmp_2;
  };
  _no_name_provided__142.prototype.invoke_1115 = function (p1) {
    return this.invoke_257(p1 instanceof SExp ? p1 : THROW_CCE());
  };
  _no_name_provided__142.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__143() {
  }
  _no_name_provided__143.prototype.invoke_257 = function (args) {
    var arg = first_1(MoreOps_getInstance().argsAsBoolList('not', args, 1));
    var tmp;
    if (first_1(arg).equals(Companion_getInstance_22().___null__)) {
      tmp = Companion_getInstance_22().___true__;
    } else {
      tmp = Companion_getInstance_22().___false__;
    }
    var r = tmp;
    Costs_getInstance();
    return to(toBigInteger(200), Companion_getInstance_22().to_0(r));
  };
  _no_name_provided__143.prototype.invoke_1115 = function (p1) {
    return this.invoke_257(p1 instanceof SExp ? p1 : THROW_CCE());
  };
  _no_name_provided__143.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__144() {
  }
  _no_name_provided__144.prototype.invoke_257 = function (args) {
    var items = MoreOps_getInstance().argsAsBools('any', args);
    Costs_getInstance();
    var tmp = toBigInteger(200);
    Costs_getInstance();
    var cost = tmp.plus_70(imul(300, count(items)));
    var r = Companion_getInstance_22().___false__;
    var tmp$ret$0;
    l$ret$1: do {
      var tmp0_iterator_1_1 = items.iterator_66();
      while (tmp0_iterator_1_1.hasNext_41()) {
        var element_2_2 = tmp0_iterator_1_1.next_46();
        if (!element_2_2.equals(Companion_getInstance_22().___null__)) {
          tmp$ret$0 = element_2_2;
          break l$ret$1;
        } else {
        }
      }
      tmp$ret$0 = null;
    }
     while (false);
    var tmp0_safe_receiver = tmp$ret$0;
    if (tmp0_safe_receiver == null)
      null;
    else {
      r = Companion_getInstance_22().___true__;
      Unit_getInstance();
    }
    Unit_getInstance();
    return to(cost, Companion_getInstance_22().to_0(r));
  };
  _no_name_provided__144.prototype.invoke_1115 = function (p1) {
    return this.invoke_257(p1 instanceof SExp ? p1 : THROW_CCE());
  };
  _no_name_provided__144.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__145() {
  }
  _no_name_provided__145.prototype.invoke_257 = function (args) {
    var items = MoreOps_getInstance().argsAsBools('all', args);
    Costs_getInstance();
    var tmp = toBigInteger(200);
    Costs_getInstance();
    var cost = tmp.plus_70(imul(300, count(items)));
    var r = Companion_getInstance_22().___true__;
    var tmp$ret$0;
    l$ret$1: do {
      var tmp0_iterator_1_1 = items.iterator_66();
      while (tmp0_iterator_1_1.hasNext_41()) {
        var element_2_2 = tmp0_iterator_1_1.next_46();
        if (element_2_2.equals(Companion_getInstance_22().___null__)) {
          tmp$ret$0 = element_2_2;
          break l$ret$1;
        } else {
        }
      }
      tmp$ret$0 = null;
    }
     while (false);
    var tmp0_safe_receiver = tmp$ret$0;
    if (tmp0_safe_receiver == null)
      null;
    else {
      r = Companion_getInstance_22().___false__;
      Unit_getInstance();
    }
    Unit_getInstance();
    return to(cost, Companion_getInstance_22().to_0(r));
  };
  _no_name_provided__145.prototype.invoke_1115 = function (p1) {
    return this.invoke_257(p1 instanceof SExp ? p1 : THROW_CCE());
  };
  _no_name_provided__145.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__146() {
  }
  _no_name_provided__146.prototype.invoke_257 = function (args) {
    if (count(args) < 1)
      throw new EvalError('' + 'Soft fork requires at least 1 arg. ' + args);
    var a = first_1(args);
    if (!(a._get_pair__3() == null))
      throw new EvalError('soft fork requires bigint args');
    var tmp = Companion_getInstance_13();
    var tmp_0 = a._get_atom__4();
    var cost = tmp.fromUByteArray(ensureNotNull(tmp_0 == null ? null : new UByteArray(tmp_0))._storage, Sign_POSITIVE_getInstance());
    if (cost.compareTo_26(1) < 0)
      throw new EvalError('' + 'cost must be > 0: ' + args);
    return to(cost, Companion_getInstance_22().___false__);
  };
  _no_name_provided__146.prototype.invoke_1115 = function (p1) {
    return this.invoke_257(p1 instanceof SExp ? p1 : THROW_CCE());
  };
  _no_name_provided__146.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function MoreOps() {
    MoreOps_instance = this;
    var tmp = this;
    tmp._opSha256 = buildOp_1(11, 'sha256', _no_name_provided_$factory_93());
    var tmp_0 = this;
    tmp_0._opAdd = buildOp(16, '+', 'add', _no_name_provided_$factory_94());
    var tmp_1 = this;
    tmp_1._opSubtract = buildOp(17, '-', 'subtract', _no_name_provided_$factory_95());
    var tmp_2 = this;
    tmp_2._opMultiply = buildOp(18, '*', 'multiply', _no_name_provided_$factory_96());
    var tmp_3 = this;
    tmp_3._opDivMod = buildOp_1(20, 'divmod', _no_name_provided_$factory_97());
    var tmp_4 = this;
    tmp_4._opDiv = buildOp(19, '/', 'div', _no_name_provided_$factory_98());
    var tmp_5 = this;
    tmp_5._opGr = buildOp(21, '>', 'gr', _no_name_provided_$factory_99());
    var tmp_6 = this;
    tmp_6._opGrBytes = buildOp(10, '>s', 'gr_bytes', _no_name_provided_$factory_100());
    var tmp_7 = this;
    tmp_7._opPubkeyForExp = buildOp_1(30, 'pubkey_for_exp', _no_name_provided_$factory_101());
    var tmp_8 = this;
    tmp_8._opPointAdd = buildOp_1(29, 'point_add', _no_name_provided_$factory_102());
    var tmp_9 = this;
    tmp_9._opStrLen = buildOp_1(13, 'strlen', _no_name_provided_$factory_103());
    var tmp_10 = this;
    tmp_10._opSubStr = buildOp_1(12, 'substr', _no_name_provided_$factory_104());
    var tmp_11 = this;
    tmp_11._opConcat = buildOp_1(14, 'concat', _no_name_provided_$factory_105());
    var tmp_12 = this;
    tmp_12._opAsh = buildOp_1(22, 'ash', _no_name_provided_$factory_106());
    var tmp_13 = this;
    tmp_13._opLsh = buildOp_1(23, 'lsh', _no_name_provided_$factory_107());
    var tmp_14 = this;
    tmp_14._opLogand = buildOp_1(24, 'logand', _no_name_provided_$factory_108());
    var tmp_15 = this;
    tmp_15._opLogior = buildOp_1(25, 'logior', _no_name_provided_$factory_109());
    var tmp_16 = this;
    tmp_16._opLogxor = buildOp_1(26, 'logxor', _no_name_provided_$factory_110());
    var tmp_17 = this;
    tmp_17._opLogNot = buildOp_1(27, 'lognot', _no_name_provided_$factory_111());
    var tmp_18 = this;
    tmp_18._opNot = buildOp_1(32, 'not', _no_name_provided_$factory_112());
    var tmp_19 = this;
    tmp_19._opAny = buildOp_1(33, 'any', _no_name_provided_$factory_113());
    var tmp_20 = this;
    tmp_20._opAll = buildOp_1(34, 'all', _no_name_provided_$factory_114());
    var tmp_21 = this;
    tmp_21._opSoftFork = buildOp_1(36, 'softfork', _no_name_provided_$factory_115());
    this._list_5 = listOf([this._opSha256, this._opAdd, this._opSubtract, this._opMultiply, this._opDiv, this._opDivMod, this._opGr, this._opAsh, this._opLsh, this._opLogand, this._opConcat, this._opLogior, this._opLogxor, this._opLogNot, this._opPointAdd, this._opPubkeyForExp, this._opNot, this._opAny, this._opAll, this._opSoftFork, this._opSubStr, this._opStrLen, this._opGrBytes]);
  }
  MoreOps.prototype.mallocCost = function (cost, atom) {
    var tmp = atom._get_atom__4();
    var tmp_0 = _UByteArray___get_size__impl_(ensureNotNull(tmp == null ? null : new UByteArray(tmp))._storage);
    Costs_getInstance();
    return new Pair(cost.plus_70(imul(tmp_0, 10)), atom);
  };
  MoreOps.prototype.argsAsUInt32 = function (opName, args) {
    return map(args, _no_name_provided_$factory_116(opName));
  };
  MoreOps.prototype.argsAsBig = function (opName, args) {
    return map(args, _no_name_provided_$factory_117(opName));
  };
  MoreOps.prototype.argsAsInt32 = function (opName, args) {
    var tmp = this.argsAsUInt32(opName, args);
    return map(tmp, _no_name_provided_$factory_118());
  };
  MoreOps.prototype.argsAsBigList = function (opName, args, count_0) {
    var list = this.argsAsBig(opName, args);
    if (!(count(list) === count_0)) {
      throw new EvalError('' + opName + ' takes exactly ' + count_0 + ' arguments.');
    }return list;
  };
  MoreOps.prototype.argsAsBools = function (opName, args) {
    return map(args, _no_name_provided_$factory_119(opName, args));
  };
  MoreOps.prototype.argsAsBoolList = function (opName, args, count_0) {
    var list = this.argsAsBools(opName, args);
    if (!(count(list) === count_0)) {
      throw new EvalError('' + opName + ' takes exactly ' + count_0 + ' arguments.');
    }return list;
  };
  MoreOps.prototype.binopReduce = function (opName, args, reduce) {
    var big = this.argsAsBig(opName, args);
    Costs_getInstance();
    var tmp = toBigInteger(100);
    var sum_1 = 0;
    var tmp0_iterator_2 = big.iterator_66();
    while (tmp0_iterator_2.hasNext_41()) {
      var element_3 = tmp0_iterator_2.next_46();
      var tmp_0 = sum_1;
      Costs_getInstance();
      sum_1 = tmp_0 + 264 | 0;
    }
    var tmp_1 = tmp.plus_70(sum_1);
    var sum_1_0 = 0;
    var tmp0_iterator_2_0 = big.iterator_66();
    while (tmp0_iterator_2_0.hasNext_41()) {
      var element_3_0 = tmp0_iterator_2_0.next_46();
      var tmp_2 = sum_1_0;
      sum_1_0 = tmp_2 + element_3_0._second | 0;
    }
    var tmp_3 = sum_1_0;
    Costs_getInstance();
    var cost = tmp_1.plus_70(imul(tmp_3, 3));
    var tmp0_reduce_0 = map(big, _no_name_provided_$factory_120());
    var iterator_1 = tmp0_reduce_0.iterator_66();
    if (!iterator_1.hasNext_41())
      throw UnsupportedOperationException_init_$Create$_0("Empty sequence can't be reduced.");
    var accumulator_2 = iterator_1.next_46();
    while (iterator_1.hasNext_41()) {
      var tmp1__anonymous__3 = accumulator_2;
      var tmp2__anonymous__3 = iterator_1.next_46();
      accumulator_2 = reduce(tmp1__anonymous__3, tmp2__anonymous__3);
    }
    var reduction = accumulator_2;
    return this.mallocCost(cost, Companion_getInstance_22().to_6(reduction));
  };
  MoreOps.$metadata$ = {
    simpleName: 'MoreOps',
    kind: 'object',
    interfaces: []
  };
  var MoreOps_instance;
  function MoreOps_getInstance() {
    if (MoreOps_instance == null)
      new MoreOps();
    return MoreOps_instance;
  }
  function _no_name_provided_$factory_93() {
    var i = new _no_name_provided__119();
    return function (p1) {
      return i.invoke_257(p1);
    };
  }
  function _no_name_provided_$factory_94() {
    var i = new _no_name_provided__124();
    return function (p1) {
      return i.invoke_257(p1);
    };
  }
  function _no_name_provided_$factory_95() {
    var i = new _no_name_provided__125();
    return function (p1) {
      return i.invoke_257(p1);
    };
  }
  function _no_name_provided_$factory_96() {
    var i = new _no_name_provided__126();
    return function (p1) {
      return i.invoke_257(p1);
    };
  }
  function _no_name_provided_$factory_97() {
    var i = new _no_name_provided__127();
    return function (p1) {
      return i.invoke_257(p1);
    };
  }
  function _no_name_provided_$factory_98() {
    var i = new _no_name_provided__128();
    return function (p1) {
      return i.invoke_257(p1);
    };
  }
  function _no_name_provided_$factory_99() {
    var i = new _no_name_provided__129();
    return function (p1) {
      return i.invoke_257(p1);
    };
  }
  function _no_name_provided_$factory_100() {
    var i = new _no_name_provided__130();
    return function (p1) {
      return i.invoke_257(p1);
    };
  }
  function _no_name_provided_$factory_101() {
    var i = new _no_name_provided__131();
    return function (p1) {
      return i.invoke_257(p1);
    };
  }
  function _no_name_provided_$factory_102() {
    var i = new _no_name_provided__132();
    return function (p1) {
      return i.invoke_257(p1);
    };
  }
  function _no_name_provided_$factory_103() {
    var i = new _no_name_provided__133();
    return function (p1) {
      return i.invoke_257(p1);
    };
  }
  function _no_name_provided_$factory_104() {
    var i = new _no_name_provided__134();
    return function (p1) {
      return i.invoke_257(p1);
    };
  }
  function _no_name_provided_$factory_105() {
    var i = new _no_name_provided__135();
    return function (p1) {
      return i.invoke_257(p1);
    };
  }
  function _no_name_provided_$factory_106() {
    var i = new _no_name_provided__136();
    return function (p1) {
      return i.invoke_257(p1);
    };
  }
  function _no_name_provided_$factory_107() {
    var i = new _no_name_provided__137();
    return function (p1) {
      return i.invoke_257(p1);
    };
  }
  function _no_name_provided_$factory_108() {
    var i = new _no_name_provided__139();
    return function (p1) {
      return i.invoke_257(p1);
    };
  }
  function _no_name_provided_$factory_109() {
    var i = new _no_name_provided__140();
    return function (p1) {
      return i.invoke_257(p1);
    };
  }
  function _no_name_provided_$factory_110() {
    var i = new _no_name_provided__141();
    return function (p1) {
      return i.invoke_257(p1);
    };
  }
  function _no_name_provided_$factory_111() {
    var i = new _no_name_provided__142();
    return function (p1) {
      return i.invoke_257(p1);
    };
  }
  function _no_name_provided_$factory_112() {
    var i = new _no_name_provided__143();
    return function (p1) {
      return i.invoke_257(p1);
    };
  }
  function _no_name_provided_$factory_113() {
    var i = new _no_name_provided__144();
    return function (p1) {
      return i.invoke_257(p1);
    };
  }
  function _no_name_provided_$factory_114() {
    var i = new _no_name_provided__145();
    return function (p1) {
      return i.invoke_257(p1);
    };
  }
  function _no_name_provided_$factory_115() {
    var i = new _no_name_provided__146();
    return function (p1) {
      return i.invoke_257(p1);
    };
  }
  function _no_name_provided_$factory_116($opName) {
    var i = new _no_name_provided__120($opName);
    return function (p1) {
      return i.invoke_257(p1);
    };
  }
  function _no_name_provided_$factory_117($opName) {
    var i = new _no_name_provided__121($opName);
    return function (p1) {
      return i.invoke_257(p1);
    };
  }
  function _no_name_provided_$factory_118() {
    var i = new _no_name_provided__122();
    return function (p1) {
      return i.invoke_203(p1);
    };
  }
  function _no_name_provided_$factory_119($opName, $args) {
    var i = new _no_name_provided__123($opName, $args);
    return function (p1) {
      return i.invoke_257(p1);
    };
  }
  function _no_name_provided_$factory_120() {
    var i = new _no_name_provided__138();
    return function (p1) {
      return i.invoke_235(p1);
    };
  }
  function _no_name_provided_$factory_121() {
    var i = new _no_name_provided__116();
    return function (p1, p2) {
      return i.invoke_195(p1, p2);
    };
  }
  function _no_name_provided_$factory_122() {
    var i = new _no_name_provided__117();
    return function (p1, p2) {
      return i.invoke_195(p1, p2);
    };
  }
  function _no_name_provided_$factory_123() {
    var i = new _no_name_provided__118();
    return function (p1, p2) {
      return i.invoke_195(p1, p2);
    };
  }
  function _OperatorMap___init__impl_(map_0) {
    return map_0;
  }
  function _OperatorMap___get_map__impl_(this_0) {
    return this_0;
  }
  function _OperatorMap___get_quoteAtom__impl_(this_0) {
    return ensureNotNull(_OperatorMap___get_map__impl_(this_0).get_56(toBigInteger(1))).asAtom_0();
  }
  function _OperatorMap___get_applyAtom__impl_(this_0) {
    return ensureNotNull(_OperatorMap___get_map__impl_(this_0).get_56(toBigInteger(2))).asAtom_0();
  }
  function OperatorMap__call_impl(this_0, op, args) {
    var opNum = Companion_getInstance_13().fromUByteArray(op, Sign_POSITIVE_getInstance());
    var tmp0_elvis_lhs = _OperatorMap___get_map__impl_(this_0).get_56(opNum);
    var tmp;
    if (tmp0_elvis_lhs == null) {
      return Operators_getInstance().defaultUnknownOp(op, args);
    } else {
      tmp = tmp0_elvis_lhs;
    }
    var f = tmp;
    return f.call_3(args);
  }
  function Companion_24() {
    Companion_instance_23 = this;
  }
  Companion_24.prototype.from = function (other) {
    return _OperatorMap___init__impl_(HashMap_init_$Create$_2(_OperatorMap___get_map__impl_(other)));
  };
  Companion_24.$metadata$ = {
    simpleName: 'Companion',
    kind: 'object',
    interfaces: []
  };
  var Companion_instance_23;
  function Companion_getInstance_23() {
    if (Companion_instance_23 == null)
      new Companion_24();
    return Companion_instance_23;
  }
  function OperatorMap__toString_impl(this_0) {
    return '' + 'OperatorMap(map=' + this_0 + ')';
  }
  function OperatorMap__hashCode_impl(this_0) {
    return this_0.hashCode();
  }
  function OperatorMap__equals_impl(this_0, other) {
    if (!(other instanceof OperatorMap))
      return false;
    else {
    }
    var tmp0_other_with_cast = other instanceof OperatorMap ? other._map_1 : THROW_CCE();
    if (!this_0.equals(tmp0_other_with_cast))
      return false;
    return true;
  }
  function OperatorMap(map_0) {
    Companion_getInstance_23();
    this._map_1 = map_0;
  }
  OperatorMap.prototype._get_quoteAtom__0 = function () {
    return _OperatorMap___get_quoteAtom__impl_(this._map_1);
  };
  OperatorMap.prototype._get_applyAtom__0 = function () {
    return _OperatorMap___get_applyAtom__impl_(this._map_1);
  };
  OperatorMap.prototype.call_4 = function (op, args) {
    return OperatorMap__call_impl(this._map_1, op, args);
  };
  OperatorMap.prototype.toString = function () {
    return OperatorMap__toString_impl(this._map_1);
  };
  OperatorMap.prototype.hashCode = function () {
    return OperatorMap__hashCode_impl(this._map_1);
  };
  OperatorMap.prototype.equals = function (other) {
    return OperatorMap__equals_impl(this._map_1, other);
  };
  OperatorMap.$metadata$ = {
    simpleName: 'OperatorMap',
    kind: 'class',
    interfaces: [OperatorLookupCallable]
  };
  function _no_name_provided__147($opName) {
    this._$opName_3 = $opName;
  }
  _no_name_provided__147.prototype.invoke_257 = function (it) {
    if (!(it._get_pair__3() == null))
      throw Exception_init_$Create$_0('' + this._$opName_3 + ' requires int args');
    var tmp = it._get_atom__4();
    return _UByteArray___get_size__impl_(ensureNotNull(tmp == null ? null : new UByteArray(tmp))._storage);
  };
  _no_name_provided__147.prototype.invoke_1115 = function (p1) {
    return this.invoke_257(p1 instanceof SExp ? p1 : THROW_CCE());
  };
  _no_name_provided__147.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function Operators() {
    Operators_instance = this;
    this._keyOps = plus(plus(CoreOps_getInstance()._list_4, MoreOps_getInstance()._list_5), ShallowOps_getInstance()._list_6);
    var tmp = this;
    var tmp0_map_0 = this._keyOps;
    var tmp0_mapTo_0_1 = ArrayList_init_$Create$_0(collectionSizeOrDefault(tmp0_map_0, 10));
    var tmp0_iterator_1_2 = tmp0_map_0.iterator_66();
    while (tmp0_iterator_1_2.hasNext_41()) {
      var item_2_3 = tmp0_iterator_1_2.next_46();
      tmp0_mapTo_0_1.add_39(new Pair(ensureNotNull(item_2_3._get_opCode__0()), item_2_3));
      Unit_getInstance();
    }
    tmp._keyOpMap = toMap(tmp0_mapTo_0_1);
    var tmp_0 = this;
    var tmp0_map_0_0 = this._keyOps;
    var tmp0_mapTo_0_1_0 = ArrayList_init_$Create$_0(collectionSizeOrDefault(tmp0_map_0_0, 10));
    var tmp0_iterator_1_2_0 = tmp0_map_0_0.iterator_66();
    while (tmp0_iterator_1_2_0.hasNext_41()) {
      var item_2_3_0 = tmp0_iterator_1_2_0.next_46();
      tmp0_mapTo_0_1_0.add_39(new Pair(ensureNotNull(item_2_3_0._get_opCode__0()), item_2_3_0._get_rewriteSymbol__0()));
      Unit_getInstance();
    }
    tmp_0._KEYWORD_FROM_ATOM = toMap(tmp0_mapTo_0_1_0);
    var tmp_1 = this;
    var tmp0_map_0_1 = this._keyOps;
    var tmp0_mapTo_0_1_1 = ArrayList_init_$Create$_0(collectionSizeOrDefault(tmp0_map_0_1, 10));
    var tmp0_iterator_1_2_1 = tmp0_map_0_1.iterator_66();
    while (tmp0_iterator_1_2_1.hasNext_41()) {
      var item_2_3_1 = tmp0_iterator_1_2_1.next_46();
      tmp0_mapTo_0_1_1.add_39(new Pair(item_2_3_1._get_rewriteSymbol__0(), ensureNotNull(item_2_3_1._get_opCode__0())));
      Unit_getInstance();
    }
    tmp_1._KEYWORD_TO_ATOM = toMap(tmp0_mapTo_0_1_1);
    this._OPERATOR_LOOKUP = _OperatorMap___init__impl_(HashMap_init_$Create$_2(this._keyOpMap));
  }
  Operators.prototype.argsLen = function (opName, args) {
    return map(args, _no_name_provided_$factory_124(opName));
  };
  Operators.prototype.defaultUnknownOp = function (op, args) {
    var tmp;
    if (UByteArray__isEmpty_impl(op)) {
      tmp = true;
    } else {
      var tmp_0;
      var tmp_1 = new UByte(UByteArray__get_impl(op, 0));
      var tmp0_toUByte_0 = _UInt___init__impl_(255);
      var tmp0_toUByte_0_1 = _UInt___get_data__impl_(tmp0_toUByte_0);
      if (equals_0(tmp_1, new UByte(_UByte___init__impl_(toByte(tmp0_toUByte_0_1))))) {
        var tmp_2 = new UByte(UByteArray__get_impl(op, 1));
        var tmp1_toUByte_0 = _UInt___init__impl_(255);
        var tmp0_toUByte_0_1_0 = _UInt___get_data__impl_(tmp1_toUByte_0);
        tmp_0 = equals_0(tmp_2, new UByte(_UByte___init__impl_(toByte(tmp0_toUByte_0_1_0))));
      } else {
        {
          tmp_0 = false;
        }
      }
      tmp = tmp_0;
    }
    if (tmp) {
      throw new EvalError('' + 'Reserved Operator: ' + to(args, new UByteArray(op)));
    } else {
    }
    var tmp0_toUByte_0_1_1 = last(_UByteArray___get_storage__impl_(op));
    var tmp2_and_0 = _UByte___init__impl_(tmp0_toUByte_0_1_1);
    var tmp3_and_0 = _UByte___init__impl_(-64);
    var tmp0_and_0_1 = _UByte___get_data__impl_(tmp2_and_0);
    var tmp1_and_0_2 = _UByte___get_data__impl_(tmp3_and_0);
    var tmp4_toUInt_0 = _UByte___init__impl_(toByte(tmp0_and_0_1 & tmp1_and_0_2));
    var tmp5_shr_0 = _UInt___init__impl_(_UByte___get_data__impl_(tmp4_toUInt_0) & 255);
    var tmp6_shr_0 = 6;
    var costFunction = _UInt___init__impl_(_UInt___get_data__impl_(tmp5_shr_0) >>> tmp6_shr_0);
    if (_UByteArray___get_size__impl_(op) > 5)
      throw new EvalError('' + 'Invalid operator: ' + Companion_getInstance_22().to_8(new UByteArray(op)));
    var costMultiplier = Companion_getInstance_13().fromUByteArray(toUByteArray(dropLast_0(op, 1)), Sign_POSITIVE_getInstance()).plus_70(1);
    var tmp0_subject = costFunction;
    var tmp_3;
    if (equals_0(new UInt(tmp0_subject), new UInt(_UInt___init__impl_(0)))) {
      tmp_3 = 1;
    } else if (equals_0(new UInt(tmp0_subject), new UInt(_UInt___init__impl_(1)))) {
      Costs_getInstance();
      var cost = 99;
      var argSize = 0;
      var tmp7_forEach_0 = this.argsLen('unknown op', args);
      var tmp0_iterator_1 = tmp7_forEach_0.iterator_66();
      while (tmp0_iterator_1.hasNext_41()) {
        var element_2 = tmp0_iterator_1.next_46();
        argSize = argSize + element_2 | 0;
        var tmp_4 = cost;
        Costs_getInstance();
        cost = tmp_4 + 320 | 0;
      }
      var tmp_5 = cost;
      var tmp_6 = argSize;
      Costs_getInstance();
      tmp_3 = tmp_5 + imul(tmp_6, 3) | 0;
    } else if (equals_0(new UInt(tmp0_subject), new UInt(_UInt___init__impl_(2)))) {
      Costs_getInstance();
      var cost_0 = 92;
      var operands = this.argsLen('unknown op', args);
      var iterator_1 = operands.iterator_66();
      if (!iterator_1.hasNext_41())
        throw UnsupportedOperationException_init_$Create$_0("Empty sequence can't be reduced.");
      var accumulator_2 = iterator_1.next_46();
      while (iterator_1.hasNext_41()) {
        var tmp8__anonymous__3 = accumulator_2;
        var tmp9__anonymous__3 = iterator_1.next_46();
        var tmp_7 = cost_0;
        Costs_getInstance();
        cost_0 = tmp_7 + 885 | 0;
        var tmp_8 = cost_0;
        var tmp_9 = tmp8__anonymous__3 + tmp9__anonymous__3 | 0;
        Costs_getInstance();
        cost_0 = tmp_8 + imul(tmp_9, 6) | 0;
        var tmp_10 = cost_0;
        var tmp_11 = imul(tmp8__anonymous__3, tmp9__anonymous__3);
        Costs_getInstance();
        cost_0 = tmp_10 + (tmp_11 / 128 | 0) | 0;
        accumulator_2 = tmp8__anonymous__3 + tmp9__anonymous__3 | 0;
      }
      tmp_3 = accumulator_2;
    } else if (equals_0(new UInt(tmp0_subject), new UInt(_UInt___init__impl_(3)))) {
      Costs_getInstance();
      var cost_1 = 142;
      var tmp10_fold_0 = 0;
      var accumulator_1 = tmp10_fold_0;
      var tmp0_iterator_2 = args.iterator_66();
      while (tmp0_iterator_2.hasNext_41()) {
        var element_3 = tmp0_iterator_2.next_46();
        var tmp11__anonymous__4 = accumulator_1;
        if (!(element_3._get_pair__3() == null))
          throw new EvalError('' + 'unknwon op on list. ' + element_3);
        var tmp_12 = cost_1;
        Costs_getInstance();
        cost_1 = tmp_12 + 135 | 0;
        var tmp_13 = element_3._get_atom__4();
        accumulator_1 = tmp11__anonymous__4 + _UByteArray___get_size__impl_(ensureNotNull(tmp_13 == null ? null : new UByteArray(tmp_13))._storage) | 0;
      }
      var length = accumulator_1;
      var tmp_14 = cost_1;
      Costs_getInstance();
      tmp_3 = tmp_14 + imul(length, 3) | 0;
    } else {
      throw new EvalError('Illegal state for cost function');
    }
    var cost_2 = toBigInteger(tmp_3).times_0(costMultiplier);
    if (cost_2.compareTo_26(fastPow(Companion_getInstance_13()._TWO_0, 32)) >= 0)
      throw new EvalError('' + 'invalid oeprator ' + Companion_getInstance_22().to_8(new UByteArray(op)));
    return to(cost_2, Companion_getInstance_22().___null__);
  };
  Operators.$metadata$ = {
    simpleName: 'Operators',
    kind: 'object',
    interfaces: []
  };
  var Operators_instance;
  function Operators_getInstance() {
    if (Operators_instance == null)
      new Operators();
    return Operators_instance;
  }
  function EvalError(msg) {
    Exception_init_$Init$_0(msg, this);
    captureStack(this, EvalError);
  }
  EvalError.$metadata$ = {
    simpleName: 'EvalError',
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided_$factory_124($opName) {
    var i = new _no_name_provided__147($opName);
    return function (p1) {
      return i.invoke_257(p1);
    };
  }
  function _no_name_provided__148() {
  }
  _no_name_provided__148.prototype.invoke_257 = function (args) {
    throw IllegalStateException_init_$Create$_0('Quote should not be handled this way');
  };
  _no_name_provided__148.prototype.invoke_1115 = function (p1) {
    return this.invoke_257(p1 instanceof SExp ? p1 : THROW_CCE());
  };
  _no_name_provided__148.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__149() {
  }
  _no_name_provided__149.prototype.invoke_257 = function (args) {
    throw IllegalStateException_init_$Create$_0('Apply should not be handled this way');
  };
  _no_name_provided__149.prototype.invoke_1115 = function (p1) {
    return this.invoke_257(p1 instanceof SExp ? p1 : THROW_CCE());
  };
  _no_name_provided__149.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function ShallowOps() {
    ShallowOps_instance = this;
    var tmp = this;
    tmp._opQuote = buildOp(1, 'q', 'q', _no_name_provided_$factory_125());
    var tmp_0 = this;
    tmp_0._opApply = buildOp(2, 'a', 'a', _no_name_provided_$factory_126());
    this._list_6 = listOf([this._opQuote, this._opApply]);
  }
  ShallowOps.$metadata$ = {
    simpleName: 'ShallowOps',
    kind: 'object',
    interfaces: []
  };
  var ShallowOps_instance;
  function ShallowOps_getInstance() {
    if (ShallowOps_instance == null)
      new ShallowOps();
    return ShallowOps_instance;
  }
  function _no_name_provided_$factory_125() {
    var i = new _no_name_provided__148();
    return function (p1) {
      return i.invoke_257(p1);
    };
  }
  function _no_name_provided_$factory_126() {
    var i = new _no_name_provided__149();
    return function (p1) {
      return i.invoke_257(p1);
    };
  }
  function runProgram(program, progArgs, operatorLookup, maxCost, preEvalF) {
    var tmp;
    if (!(preEvalF == null)) {
      tmp = toPreEvalOp(preEvalF, _no_name_provided_$factory_127());
    } else {
      tmp = null;
    }
    var preEvalOp = tmp;
    var swapOp = new OpStackCallable('swap', _no_name_provided_$factory_128());
    var consOp = new OpStackCallable('cons', _no_name_provided_$factory_129());
    var applyOp = {_v: null};
    var evalOp = {_v: null};
    evalOp._v = new OpStackCallable('Eval', _no_name_provided_$factory_130(preEvalOp, applyOp, operatorLookup, consOp, evalOp, swapOp));
    applyOp._v = new OpStackCallable('Apply', _no_name_provided_$factory_131(operatorLookup, evalOp));
    var opStack = ArrayList_init_$Create$();
    var tmp_0;
    if (evalOp._v == null) {
      throwUninitializedPropertyAccessException('evalOp');
    } else {
      tmp_0 = evalOp._v;
    }
    push(opStack, [tmp_0]);
    var valStack = ArrayList_init_$Create$();
    push(valStack, [program.cons_3(progArgs)]);
    var cost = Companion_getInstance_13()._ZERO_0;
    $l$break: while (true) {
      if (!!opStack.isEmpty_44()) {
        break $l$break;
      }var f = pop(opStack);
      cost = cost.plus_4(f.call_0(opStack, valStack));
      if (!(maxCost == null) ? cost.compareTo_26(maxCost) > 0 : false) {
        throw new EvalError('' + 'Max cost of ' + maxCost + ' exceeded.');
      }}
    return to(cost, last_0(valStack));
  }
  function runProgram$default(program, progArgs, operatorLookup, maxCost, preEvalF, $mask0, $handler) {
    if (!(($mask0 & 4) === 0))
      operatorLookup = new OperatorMap(Operators_getInstance()._OPERATOR_LOOKUP);
    if (!(($mask0 & 8) === 0))
      maxCost = null;
    if (!(($mask0 & 16) === 0))
      preEvalF = null;
    return runProgram(program, progArgs, operatorLookup, maxCost, preEvalF);
  }
  function toPreEvalOp(preEvalF, toSexpFun) {
    return _no_name_provided_$factory_132(toSexpFun, preEvalF);
  }
  function msbMask(uByte) {
    var x = _UByte___get_data__impl_(uByte) & 255;
    x = x | x >> 1;
    x = x | x >> 2;
    x = x | x >> 4;
    var tmp0_toUByte_0 = (x + 1 | 0) >> 1;
    return _UByte___init__impl_(toByte(tmp0_toUByte_0));
  }
  function runProgram$traversePath(sexp, iEnv) {
    Costs_getInstance();
    var cost = toBigInteger(40);
    var tmp = cost;
    Costs_getInstance();
    cost = tmp.plus_70(4);
    if (nullp(sexp))
      return to(cost, Companion_getInstance_22().___null__);
    var tmp_0 = sexp._get_atom__4();
    var b = ensureNotNull(tmp_0 == null ? null : new UByteArray(tmp_0))._storage;
    var endByteCursor = 0;
    $l$break: while (true) {
      var tmp_1;
      if (endByteCursor < _UByteArray___get_size__impl_(b)) {
        var tmp0_toUInt_0 = UByteArray__get_impl(b, endByteCursor);
        tmp_1 = equals_0(new UInt(_UInt___init__impl_(_UByte___get_data__impl_(tmp0_toUInt_0) & 255)), new UInt(_UInt___init__impl_(0)));
      } else {
        tmp_1 = false;
      }
      if (!tmp_1) {
        break $l$break;
      }endByteCursor = endByteCursor + 1 | 0;
    }
    var tmp_2 = cost;
    var tmp_3 = endByteCursor;
    Costs_getInstance();
    cost = tmp_2.plus_70(imul(tmp_3, 4));
    if (endByteCursor === _UByteArray___get_size__impl_(b)) {
      return to(cost, Companion_getInstance_22().___null__);
    }var endBitmask = msbMask(UByteArray__get_impl(b, endByteCursor));
    var byteCursor = _UByteArray___get_size__impl_(b) - 1 | 0;
    var bitMask = _UInt___init__impl_(1);
    var env = iEnv;
    $l$break_0: while (true) {
      var tmp_4;
      if (byteCursor < endByteCursor) {
        tmp_4 = true;
      } else {
        var tmp1_compareTo_0 = bitMask;
        var tmp0_compareTo_0_1 = _UInt___init__impl_(_UByte___get_data__impl_(endBitmask) & 255);
        tmp_4 = uintCompare(_UInt___get_data__impl_(tmp1_compareTo_0), _UInt___get_data__impl_(tmp0_compareTo_0_1)) < 0;
      }
      if (!tmp_4) {
        break $l$break_0;
      }if (env._get_pair__3() == null) {
        throw new EvalError('' + 'Path into atom! ' + env);
      }var tmp3_and_0 = UByteArray__get_impl(b, byteCursor);
      var tmp2_toUByte_0 = bitMask;
      var tmp0_toUByte_0_1 = _UInt___get_data__impl_(tmp2_toUByte_0);
      var tmp4_and_0 = _UByte___init__impl_(toByte(tmp0_toUByte_0_1));
      var tmp0_and_0_1 = _UByte___get_data__impl_(tmp3_and_0);
      var tmp1_and_0_2 = _UByte___get_data__impl_(tmp4_and_0);
      var tmp5_compareTo_0 = _UByte___init__impl_(toByte(tmp0_and_0_1 & tmp1_and_0_2));
      var tmp6_compareTo_0 = _UInt___init__impl_(0);
      var tmp0_compareTo_0_1_0 = _UInt___init__impl_(_UByte___get_data__impl_(tmp5_compareTo_0) & 255);
      if (uintCompare(_UInt___get_data__impl_(tmp0_compareTo_0_1_0), _UInt___get_data__impl_(tmp6_compareTo_0)) > 0) {
        env = rest(env);
      } else {
        {
          env = first_1(env);
        }
      }
      var tmp_5 = cost;
      Costs_getInstance();
      cost = tmp_5.plus_70(4);
      var tmp7_shl_0 = bitMask;
      var tmp8_shl_0 = 1;
      bitMask = _UInt___init__impl_(_UInt___get_data__impl_(tmp7_shl_0) << tmp8_shl_0);
      if (equals_0(new UInt(bitMask), new UInt(_UInt___init__impl_(256)))) {
        byteCursor = byteCursor - 1 | 0;
        bitMask = _UInt___init__impl_(1);
      }}
    return to(cost, env);
  }
  function _no_name_provided__150() {
  }
  _no_name_provided__150.prototype.invoke_259 = function (it) {
    return toImp(Companion_getInstance_22(), it);
  };
  _no_name_provided__150.prototype.invoke_1115 = function (p1) {
    return this.invoke_259(isObject(p1) ? p1 : THROW_CCE());
  };
  _no_name_provided__150.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__151() {
  }
  _no_name_provided__151.prototype.invoke_269 = function (opStack, valStack) {
    var v2 = pop(valStack);
    var v1 = pop(valStack);
    push(valStack, [v2]);
    push(valStack, [v1]);
    return Companion_getInstance_13()._ZERO_0;
  };
  _no_name_provided__151.prototype.invoke_915 = function (p1, p2) {
    var tmp = p1 instanceof ArrayList ? p1 : THROW_CCE();
    return this.invoke_269(tmp, p2 instanceof ArrayList ? p2 : THROW_CCE());
  };
  _no_name_provided__151.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__152() {
  }
  _no_name_provided__152.prototype.invoke_269 = function (opStack, valStack) {
    var v1 = pop(valStack);
    var v2 = pop(valStack);
    push(valStack, [v1.cons_3(v2)]);
    return Companion_getInstance_13()._ZERO_0;
  };
  _no_name_provided__152.prototype.invoke_915 = function (p1, p2) {
    var tmp = p1 instanceof ArrayList ? p1 : THROW_CCE();
    return this.invoke_269(tmp, p2 instanceof ArrayList ? p2 : THROW_CCE());
  };
  _no_name_provided__152.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__153($preEvalOp, $applyOp, $operatorLookup, $consOp, $evalOp, $swapOp) {
    this._$preEvalOp = $preEvalOp;
    this._$applyOp = $applyOp;
    this._$operatorLookup = $operatorLookup;
    this._$consOp = $consOp;
    this._$evalOp = $evalOp;
    this._$swapOp = $swapOp;
  }
  _no_name_provided__153.prototype.invoke_269 = function (opStack, valStack) {
    if (!(this._$preEvalOp == null))
      this._$preEvalOp(opStack, valStack);
    var pair = pop(valStack);
    var sexp = first_1(pair);
    var args = rest(pair);
    if (sexp._get_pair__3() == null) {
      var t = runProgram$traversePath(sexp, args);
      push(valStack, [t._second]);
      return t._first;
    }var operator = first_1(sexp);
    if (!(operator._get_pair__3() == null)) {
      var opPair = ensureNotNull(operator._get_pair__3());
      if (!(opPair._first._get_pair__3() == null) ? true : !equals_0(opPair._second, Companion_getInstance_22().___null__)) {
        throw Exception_init_$Create$_0('' + 'in ((X)...) syntax X must be lone atom: ' + sexp);
      }var newOpList = rest(sexp);
      push(valStack, [Companion_getInstance_22().to_2(opPair._first)]);
      push(valStack, [newOpList]);
      var tmp;
      if (this._$applyOp._v == null) {
        throwUninitializedPropertyAccessException('applyOp');
      } else {
        tmp = this._$applyOp._v;
      }
      push(opStack, [tmp]);
      Costs_getInstance();
      return toBigInteger(90);
    }var tmp_0 = operator._get_atom__4();
    var op = ensureNotNull(tmp_0 == null ? null : new UByteArray(tmp_0))._storage;
    var operandList = rest(sexp);
    if (contentEquals_0(op, this._$operatorLookup._get_quoteAtom__0())) {
      push(valStack, [operandList]);
      Costs_getInstance();
      return toBigInteger(20);
    }var tmp_1;
    if (this._$applyOp._v == null) {
      throwUninitializedPropertyAccessException('applyOp');
    } else {
      tmp_1 = this._$applyOp._v;
    }
    push(opStack, [tmp_1]);
    push(valStack, [operator]);
    while (!nullp(operandList)) {
      var z = first_1(operandList);
      push(valStack, [z.cons_3(args)]);
      push(opStack, [this._$consOp]);
      var tmp_2;
      if (this._$evalOp._v == null) {
        throwUninitializedPropertyAccessException('evalOp');
      } else {
        tmp_2 = this._$evalOp._v;
      }
      push(opStack, [tmp_2]);
      push(opStack, [this._$swapOp]);
      operandList = rest(operandList);
    }
    push(valStack, [Companion_getInstance_22().___null__]);
    return Companion_getInstance_13()._ONE_0;
  };
  _no_name_provided__153.prototype.invoke_915 = function (p1, p2) {
    var tmp = p1 instanceof ArrayList ? p1 : THROW_CCE();
    return this.invoke_269(tmp, p2 instanceof ArrayList ? p2 : THROW_CCE());
  };
  _no_name_provided__153.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__154($operatorLookup, $evalOp) {
    this._$operatorLookup_0 = $operatorLookup;
    this._$evalOp_0 = $evalOp;
  }
  _no_name_provided__154.prototype.invoke_269 = function (opStack, valStack) {
    var operandList = pop(valStack);
    var operator = pop(valStack);
    if (!(operator._get_pair__3() == null))
      throw Exception_init_$Create$_0('Internal error');
    var tmp = operator._get_atom__4();
    var op = ensureNotNull(tmp == null ? null : new UByteArray(tmp))._storage;
    if (contentEquals_0(op, this._$operatorLookup_0._get_applyAtom__0())) {
      if (!(count(operandList) === 2)) {
        throw Exception_init_$Create$_0('Apply requires exactly 2 arguments');
      }var newProgram = first_1(operandList);
      var newArgs = first_1(rest(operandList));
      push(valStack, [newProgram.cons_3(newArgs)]);
      var tmp_0;
      if (this._$evalOp_0._v == null) {
        throwUninitializedPropertyAccessException('evalOp');
      } else {
        tmp_0 = this._$evalOp_0._v;
      }
      push(opStack, [tmp_0]);
      Costs_getInstance();
      return toBigInteger(90);
    }var r = this._$operatorLookup_0.call_4(op, operandList);
    push(valStack, [r._second]);
    return r._first;
  };
  _no_name_provided__154.prototype.invoke_915 = function (p1, p2) {
    var tmp = p1 instanceof ArrayList ? p1 : THROW_CCE();
    return this.invoke_269(tmp, p2 instanceof ArrayList ? p2 : THROW_CCE());
  };
  _no_name_provided__154.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__155($tmp0_safe_receiver, $toSexpFun, $valueStack) {
    this._$tmp0_safe_receiver = $tmp0_safe_receiver;
    this._$toSexpFun = $toSexpFun;
    this._$valueStack = $valueStack;
  }
  _no_name_provided__155.prototype.invoke_269 = function (_anonymous_parameter_0_, _anonymous_parameter_1_) {
    this._$tmp0_safe_receiver.call__error(this._$toSexpFun(last_0(this._$valueStack)));
    return Companion_getInstance_13()._ZERO_0;
  };
  _no_name_provided__155.prototype.invoke_915 = function (p1, p2) {
    var tmp = p1 instanceof ArrayList ? p1 : THROW_CCE();
    return this.invoke_269(tmp, p2 instanceof ArrayList ? p2 : THROW_CCE());
  };
  _no_name_provided__155.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__156($toSexpFun, $preEvalF) {
    this._$toSexpFun_0 = $toSexpFun;
    this._$preEvalF = $preEvalF;
  }
  _no_name_provided__156.prototype.invoke_271 = function (opStack, valueStack) {
    var v = this._$toSexpFun_0(last_0(valueStack));
    var tmp0_safe_receiver = this._$preEvalF.call__error(first_1(v), rest(v));
    if (tmp0_safe_receiver == null)
      null;
    else {
      opStack.add_39(new OpStackCallable('Pre Eval', _no_name_provided_$factory_133(tmp0_safe_receiver, this._$toSexpFun_0, valueStack)));
      Unit_getInstance();
    }
    Unit_getInstance();
  };
  _no_name_provided__156.prototype.invoke_915 = function (p1, p2) {
    var tmp = p1 instanceof ArrayList ? p1 : THROW_CCE();
    this.invoke_271(tmp, p2 instanceof ArrayList ? p2 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__156.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided_$factory_127() {
    var i = new _no_name_provided__150();
    return function (p1) {
      return i.invoke_259(p1);
    };
  }
  function _no_name_provided_$factory_128() {
    var i = new _no_name_provided__151();
    return function (p1, p2) {
      return i.invoke_269(p1, p2);
    };
  }
  function _no_name_provided_$factory_129() {
    var i = new _no_name_provided__152();
    return function (p1, p2) {
      return i.invoke_269(p1, p2);
    };
  }
  function _no_name_provided_$factory_130($preEvalOp, $applyOp, $operatorLookup, $consOp, $evalOp, $swapOp) {
    var i = new _no_name_provided__153($preEvalOp, $applyOp, $operatorLookup, $consOp, $evalOp, $swapOp);
    return function (p1, p2) {
      return i.invoke_269(p1, p2);
    };
  }
  function _no_name_provided_$factory_131($operatorLookup, $evalOp) {
    var i = new _no_name_provided__154($operatorLookup, $evalOp);
    return function (p1, p2) {
      return i.invoke_269(p1, p2);
    };
  }
  function _no_name_provided_$factory_132($toSexpFun, $preEvalF) {
    var i = new _no_name_provided__156($toSexpFun, $preEvalF);
    return function (p1, p2) {
      i.invoke_271(p1, p2);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_133($tmp0_safe_receiver, $toSexpFun, $valueStack) {
    var i = new _no_name_provided__155($tmp0_safe_receiver, $toSexpFun, $valueStack);
    return function (p1, p2) {
      return i.invoke_269(p1, p2);
    };
  }
  function _Program___init__impl_(sexp) {
    return sexp;
  }
  function _get_sexp_($this) {
    return $this;
  }
  function Program__run_impl(this_0, args) {
    return Program__runWithCost_impl(this_0, Companion_getInstance_24()._infiniteCost, args)._second;
  }
  function Program__runWithCost_impl(this_0, cost, args) {
    var aorp = runProgram(_get_sexp_(this_0), args, new OperatorMap(Operators_getInstance()._OPERATOR_LOOKUP), cost, null);
    return new Pair(aorp._first, Companion_getInstance_22().to_0(aorp._second));
  }
  function Companion_25() {
    Companion_instance_24 = this;
    this._infiniteCost = Companion_getInstance_13().parseString_0('7FFFFFFFFFFFFFFF', 16);
  }
  Companion_25.$metadata$ = {
    simpleName: 'Companion',
    kind: 'object',
    interfaces: []
  };
  var Companion_instance_24;
  function Companion_getInstance_24() {
    if (Companion_instance_24 == null)
      new Companion_25();
    return Companion_instance_24;
  }
  function Program__toString_impl(this_0) {
    return '' + 'Program(sexp=' + this_0 + ')';
  }
  function Program__hashCode_impl(this_0) {
    return this_0.hashCode();
  }
  function Program__equals_impl(this_0, other) {
    if (!(other instanceof Program))
      return false;
    else {
    }
    var tmp0_other_with_cast = other instanceof Program ? other._sexp_0 : THROW_CCE();
    if (!this_0.equals(tmp0_other_with_cast))
      return false;
    return true;
  }
  function Program(sexp) {
    Companion_getInstance_24();
    this._sexp_0 = sexp;
  }
  Program.prototype.toString = function () {
    return Program__toString_impl(this._sexp_0);
  };
  Program.prototype.hashCode = function () {
    return Program__hashCode_impl(this._sexp_0);
  };
  Program.prototype.equals = function (other) {
    return Program__equals_impl(this._sexp_0, other);
  };
  Program.$metadata$ = {
    simpleName: 'Program',
    kind: 'class',
    interfaces: []
  };
  function HashFunction() {
  }
  HashFunction.$metadata$ = {
    simpleName: 'HashFunction',
    kind: 'interface',
    interfaces: []
  };
  function Sha256_0() {
  }
  Sha256_0.prototype.digest_0 = function (data) {
    var tmp = Sha256_getInstance();
    var tmp0_copyOf_0_1 = _UByteArray___get_storage__impl_(data);
    var tmp0_toUByteArray_0 = tmp.digest(tmp0_copyOf_0_1.slice());
    return _UByteArray___init__impl_(tmp0_toUByteArray_0.slice());
  };
  Sha256_0.$metadata$ = {
    simpleName: 'Sha256',
    kind: 'class',
    interfaces: [HashFunction]
  };
  function push(_this_, items) {
    addAll(_this_, items);
    Unit_getInstance();
  }
  function pop(_this_) {
    return _this_.removeAt_2(_get_lastIndex__2(_this_));
  }
  function fastPow(_this_, n) {
    return exponentationBySquaring(Companion_getInstance_13()._ONE_0, _this_, BigInteger_init_$Create$_0(n));
  }
  function exponentationBySquaring(y, x, n) {
    var y_0 = y;
    var x_0 = x;
    var n_0 = n;
    while (true) {
      var y_1 = y_0;
      var x_1 = x_0;
      var n_1 = n_0;
      var tmp;
      if (n_1.compareTo_26(Companion_getInstance_13()._ZERO_0) < 0) {
        throw UnsupportedOperationException_init_$Create$_0('neg exponent');
      } else if (n_1.equals(Companion_getInstance_13()._ZERO_0)) {
        tmp = y_1;
      } else if (n_1.equals(Companion_getInstance_13()._ONE_0)) {
        tmp = x_1.times_0(y_1);
      } else if (n_1.mod(Companion_getInstance_13()._TWO_0).equals(Companion_getInstance_13()._ZERO_0)) {
        y_0 = y_1;
        x_0 = x_1.times_0(x_1);
        n_0 = n_1.div_33(2);
        continue;
      } else {
        y_0 = x_1.times_0(y_1);
        x_0 = x_1.times_0(x_1);
        n_0 = n_1.minus_39(1).div_33(2);
        continue;
      }
      return tmp;
    }
  }
  function powMod(_this_, n, m) {
    if (m.equals(Companion_getInstance_13()._ONE_0))
      return Companion_getInstance_13()._ZERO_0;
    var r = Companion_getInstance_13()._ONE_0;
    var b = _this_.mod(m);
    var e = n;
    while (e.compareTo_26(0) > 0) {
      if (e.mod(Companion_getInstance_13()._TWO_0).equals(Companion_getInstance_13()._ONE_0)) {
        r = r.times_0(b).mod(m);
      }e = e.shr_4(1);
      b = b.times_0(b).mod(m);
    }
    return r;
  }
  function fixSize(_this_, size_0) {
    var str = toHexString_0(_this_);
    return HexString__toByteArray_impl(asHexString(takeLast_0(padStart(str, imul(size_0, 2), new Char(48)), imul(size_0, 2))));
  }
  function fixSize_0(_this_, size_0) {
    var str = toHexString(_this_);
    return HexString__toUByteArray_impl(asHexString(takeLast_0(padStart(str, imul(size_0, 2), new Char(48)), imul(size_0, 2))));
  }
  function toTrimmed2sCompUbyteARray(_this_) {
    if (_this_.equals(Companion_getInstance_13()._ZERO_0)) {
      var tmp0_ubyteArrayOf_0 = _UByteArray___init__impl_(new Int8Array([]));
      return tmp0_ubyteArrayOf_0;
    }var tmp1_toUByteArray_0 = toTwosComplementByteArray(_this_);
    var array = _UByteArray___init__impl_(tmp1_toUByteArray_0.slice());
    $l$break: while (true) {
      var tmp;
      if (_UByteArray___get_size__impl_(array) > 1) {
        var tmp2_first_0 = array;
        var tmp0_toUByte_0_1 = first(_UByteArray___get_storage__impl_(tmp2_first_0));
        var tmp_0 = new UByte(_UByte___init__impl_(tmp0_toUByte_0_1));
        var tmp_1;
        var tmp4_and_0 = UByteArray__get_impl(array, 1);
        var tmp3_toUByte_0 = _UInt___init__impl_(128);
        var tmp0_toUByte_0_1_0 = _UInt___get_data__impl_(tmp3_toUByte_0);
        var tmp5_and_0 = _UByte___init__impl_(toByte(tmp0_toUByte_0_1_0));
        var tmp0_and_0_1 = _UByte___get_data__impl_(tmp4_and_0);
        var tmp1_and_0_2 = _UByte___get_data__impl_(tmp5_and_0);
        var tmp6_compareTo_0 = _UByte___init__impl_(toByte(tmp0_and_0_1 & tmp1_and_0_2));
        var tmp7_compareTo_0 = _UInt___init__impl_(0);
        var tmp0_compareTo_0_1 = _UInt___init__impl_(_UByte___get_data__impl_(tmp6_compareTo_0) & 255);
        if (uintCompare(_UInt___get_data__impl_(tmp0_compareTo_0_1), _UInt___get_data__impl_(tmp7_compareTo_0)) > 0) {
          var tmp8_toUByte_0 = _UInt___init__impl_(255);
          var tmp0_toUByte_0_1_1 = _UInt___get_data__impl_(tmp8_toUByte_0);
          tmp_1 = _UByte___init__impl_(toByte(tmp0_toUByte_0_1_1));
        } else {
          {
            var tmp9_toUByte_0 = _UInt___init__impl_(0);
            var tmp0_toUByte_0_1_2 = _UInt___get_data__impl_(tmp9_toUByte_0);
            tmp_1 = _UByte___init__impl_(toByte(tmp0_toUByte_0_1_2));
          }
        }
        tmp = equals_0(tmp_0, new UByte(tmp_1));
      } else {
        tmp = false;
      }
      if (!tmp) {
        break $l$break;
      }array = toUByteArray(drop_2(array, 1));
    }
    return array;
  }
  function trimSigned(_this_) {
    var array = _this_;
    $l$break: while (true) {
      var tmp;
      if (_UByteArray___get_size__impl_(array) > 1) {
        var tmp0_first_0 = array;
        var tmp0_toUByte_0_1 = first(_UByteArray___get_storage__impl_(tmp0_first_0));
        var tmp_0 = new UByte(_UByte___init__impl_(tmp0_toUByte_0_1));
        var tmp_1;
        var tmp2_and_0 = UByteArray__get_impl(array, 1);
        var tmp1_toUByte_0 = _UInt___init__impl_(128);
        var tmp0_toUByte_0_1_0 = _UInt___get_data__impl_(tmp1_toUByte_0);
        var tmp3_and_0 = _UByte___init__impl_(toByte(tmp0_toUByte_0_1_0));
        var tmp0_and_0_1 = _UByte___get_data__impl_(tmp2_and_0);
        var tmp1_and_0_2 = _UByte___get_data__impl_(tmp3_and_0);
        var tmp4_compareTo_0 = _UByte___init__impl_(toByte(tmp0_and_0_1 & tmp1_and_0_2));
        var tmp5_compareTo_0 = _UInt___init__impl_(0);
        var tmp0_compareTo_0_1 = _UInt___init__impl_(_UByte___get_data__impl_(tmp4_compareTo_0) & 255);
        if (uintCompare(_UInt___get_data__impl_(tmp0_compareTo_0_1), _UInt___get_data__impl_(tmp5_compareTo_0)) > 0) {
          var tmp6_toUByte_0 = _UInt___init__impl_(255);
          var tmp0_toUByte_0_1_1 = _UInt___get_data__impl_(tmp6_toUByte_0);
          tmp_1 = _UByte___init__impl_(toByte(tmp0_toUByte_0_1_1));
        } else {
          {
            var tmp7_toUByte_0 = _UInt___init__impl_(0);
            var tmp0_toUByte_0_1_2 = _UInt___get_data__impl_(tmp7_toUByte_0);
            tmp_1 = _UByte___init__impl_(toByte(tmp0_toUByte_0_1_2));
          }
        }
        tmp = equals_0(tmp_0, new UByte(tmp_1));
      } else {
        tmp = false;
      }
      if (!tmp) {
        break $l$break;
      }array = toUByteArray(drop_2(array, 1));
    }
    return array;
  }
  function toBytes_0(_this_, count_0) {
    if (count_0 > 4)
      throw IllegalArgumentException_init_$Create$();
    var result = _UByteArray___init__impl__0(count_0);
    var inductionVariable = 0;
    if (inductionVariable < count_0)
      do {
        var i = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        var tmp0_shr_0 = imul((count_0 - i | 0) - 1 | 0, 8);
        var tmp1_toUByte_0 = _UInt___init__impl_(_UInt___get_data__impl_(_this_) >>> tmp0_shr_0);
        var tmp0_toUByte_0_1 = _UInt___get_data__impl_(tmp1_toUByte_0);
        UByteArray__set_impl(result, i, _UByte___init__impl_(toByte(tmp0_toUByte_0_1)));
      }
       while (inductionVariable < count_0);
    return result;
  }
  function toBytes$default(_this_, count_0, $mask0, $handler) {
    if (!(($mask0 & 1) === 0))
      count_0 = 4;
    return toBytes_0(_this_, count_0);
  }
  function toBytes_1(_this_, count_0) {
    if (count_0 > 8)
      throw IllegalArgumentException_init_$Create$();
    var result = _UByteArray___init__impl__0(count_0);
    var inductionVariable = 0;
    if (inductionVariable < count_0)
      do {
        var i = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        var tmp0_shr_0 = imul((count_0 - i | 0) - 1 | 0, 8);
        var tmp1_toUByte_0 = _ULong___init__impl_(_ULong___get_data__impl_(_this_).ushr_0(tmp0_shr_0));
        var tmp0_toUByte_0_1 = _ULong___get_data__impl_(tmp1_toUByte_0);
        UByteArray__set_impl(result, i, _UByte___init__impl_(tmp0_toUByte_0_1.toByte_4()));
      }
       while (inductionVariable < count_0);
    return result;
  }
  function toBytes$default_0(_this_, count_0, $mask0, $handler) {
    if (!(($mask0 & 1) === 0))
      count_0 = 8;
    return toBytes_1(_this_, count_0);
  }
  function bytesRequired(_this_) {
    return (bitCount(_this_) + 7 | 0) >> 3;
  }
  function bytesRequired_0(_this_) {
    return (bitCount_0(_this_) + 7 | 0) >> 3;
  }
  function bitCount(_this_) {
    var i = highestOneBit(_this_);
    var c = 0;
    while (i > 0) {
      var tmp0 = c;
      c = tmp0 + 1 | 0;
      Unit_getInstance();
      i = i >> 1;
    }
    return c;
  }
  function bitCount_0(_this_) {
    var i = takeHighestOneBit(_this_);
    var c = 0;
    while (i > 0) {
      var tmp0 = c;
      c = tmp0 + 1 | 0;
      Unit_getInstance();
      i = i >> 1;
    }
    return c;
  }
  function highestOneBit(_this_) {
    var c = 0;
    var i = _this_;
    while (i.compareTo_26(Companion_getInstance_13()._ZERO_0) > 0) {
      i = i.shr_4(1);
      var tmp0 = c;
      c = tmp0 + 1 | 0;
      Unit_getInstance();
    }
    return c;
  }
  function toBytes_2(_this_, count_0) {
    return toBytes_0(_UInt___init__impl_(_this_), count_0);
  }
  function readUInt(_this_) {
    var result = _UInt___init__impl_(0);
    var multiplier = _UInt___init__impl_(1);
    var end_0 = _UByteArray___get_size__impl_(_this_) <= 4 ? 0 : _UByteArray___get_size__impl_(_this_) - 4 | 0;
    var inductionVariable = _UByteArray___get_size__impl_(_this_) - 1 | 0;
    if (end_0 <= inductionVariable)
      do {
        var i = inductionVariable;
        inductionVariable = inductionVariable + -1 | 0;
        var tmp2_plus_0 = result;
        var tmp0_times_0 = multiplier;
        var tmp1_times_0 = UByteArray__get_impl(_this_, i);
        var tmp0_times_0_1 = _UInt___init__impl_(_UByte___get_data__impl_(tmp1_times_0) & 255);
        var tmp3_plus_0 = _UInt___init__impl_(imul(_UInt___get_data__impl_(tmp0_times_0), _UInt___get_data__impl_(tmp0_times_0_1)));
        result = _UInt___init__impl_(_UInt___get_data__impl_(tmp2_plus_0) + _UInt___get_data__impl_(tmp3_plus_0) | 0);
        var tmp4_times_0 = multiplier;
        var tmp5_times_0 = _UInt___init__impl_(256);
        multiplier = _UInt___init__impl_(imul(_UInt___get_data__impl_(tmp4_times_0), _UInt___get_data__impl_(tmp5_times_0)));
      }
       while (!(i === end_0));
    return result;
  }
  function toHexString(_this_) {
    return toHexStringWithPrefix(_this_, '');
  }
  function toHexStringWithPrefix(_this_, prefix) {
    return encode(_this_, prefix);
  }
  function toHexString_0(_this_) {
    return toHexStringWithPrefix_0(_this_, '');
  }
  function toHexStringWithPrefix_0(_this_, prefix) {
    return encode_2(_this_, prefix);
  }
  function asHexString(_this_) {
    return _HexString___init__impl_(_this_);
  }
  function encode(value_0, prefix) {
    var tmp = new UByteArray(value_0);
    return prefix + joinToString$default_1(tmp, '', null, null, 0, null, _no_name_provided_$factory_134(), 30, null);
  }
  function encode_0(value_0) {
    return encode_1(_UByte___get_data__impl_(value_0));
  }
  function encode_1(value_0) {
    return charSequenceGet('0123456789abcdef', value_0 >> 4 & 15).toString() + charSequenceGet('0123456789abcdef', value_0 & 15).toString();
  }
  function decode(value_0) {
    if (!(value_0.length % 2 === 0)) {
      throw IllegalArgumentException_init_$Create$_0('hex-string must have an even number of digits (nibbles)');
    }var tmp;
    if (startsWith$default(value_0, '0x', false, 2, null)) {
      var tmp0_substring_0 = 2;
      tmp = value_0.substring(tmp0_substring_0);
    } else {
      {
        tmp = value_0;
      }
    }
    var cleanInput = tmp;
    var tmp1_apply_0 = new Int8Array(cleanInput.length / 2 | 0);
    var i_2 = 0;
    while (i_2 < cleanInput.length) {
      tmp1_apply_0[i_2 / 2 | 0] = toByte((hexToBin(charSequenceGet(cleanInput, i_2)) << 4) + hexToBin(charSequenceGet(cleanInput, i_2 + 1 | 0)) | 0);
      i_2 = i_2 + 2 | 0;
    }
    return tmp1_apply_0;
  }
  function decodeUnsigned(value_0) {
    if (!(value_0.length % 2 === 0)) {
      throw IllegalArgumentException_init_$Create$_0('hex-string must have an even number of digits (nibbles)');
    }var tmp;
    if (startsWith$default(value_0, '0x', false, 2, null)) {
      var tmp0_substring_0 = 2;
      tmp = value_0.substring(tmp0_substring_0);
    } else {
      {
        tmp = value_0;
      }
    }
    var cleanInput = tmp;
    var tmp1_apply_0 = _UByteArray___init__impl__0(cleanInput.length / 2 | 0);
    var i_2 = 0;
    while (i_2 < cleanInput.length) {
      var tmp_0 = i_2 / 2 | 0;
      var tmp0_toUByte_0_3 = (hexToBin(charSequenceGet(cleanInput, i_2)) << 4) + hexToBin(charSequenceGet(cleanInput, i_2 + 1 | 0)) | 0;
      UByteArray__set_impl(tmp1_apply_0, tmp_0, _UByte___init__impl_(toByte(tmp0_toUByte_0_3)));
      i_2 = i_2 + 2 | 0;
    }
    return tmp1_apply_0;
  }
  function hexToBin(ch_0) {
    var tmp0_subject = ch_0;
    var tmp;
    if (new Char(48) <= tmp0_subject ? tmp0_subject <= new Char(57) : false) {
      tmp = ch_0.minus(new Char(48));
    } else if (new Char(65) <= tmp0_subject ? tmp0_subject <= new Char(70) : false) {
      tmp = ch_0.minus(new Char(65)) + 10 | 0;
    } else if (new Char(97) <= tmp0_subject ? tmp0_subject <= new Char(102) : false) {
      tmp = ch_0.minus(new Char(97)) + 10 | 0;
    } else {
      throw IllegalArgumentException_init_$Create$_0('' + "'" + ch_0 + "' is not a valid hex character");
    }
    return tmp;
  }
  function encode_2(value_0, prefix) {
    return prefix + joinToString$default(value_0, '', null, null, 0, null, _no_name_provided_$factory_135(), 30, null);
  }
  function _no_name_provided__157() {
  }
  _no_name_provided__157.prototype.invoke_273 = function (it) {
    return encode_0(it);
  };
  _no_name_provided__157.prototype.invoke_1115 = function (p1) {
    return this.invoke_273(p1 instanceof UByte ? p1._data : THROW_CCE());
  };
  _no_name_provided__157.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__158() {
  }
  _no_name_provided__158.prototype.invoke_275 = function (it) {
    return encode_1(it);
  };
  _no_name_provided__158.prototype.invoke_1115 = function (p1) {
    return this.invoke_275((!(p1 == null) ? typeof p1 === 'number' : false) ? p1 : THROW_CCE());
  };
  _no_name_provided__158.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided_$factory_134() {
    var i = new _no_name_provided__157();
    return function (p1) {
      return i.invoke_273(p1._data);
    };
  }
  function _no_name_provided_$factory_135() {
    var i = new _no_name_provided__158();
    return function (p1) {
      return i.invoke_275(p1);
    };
  }
  function _HexString___init__impl_(string) {
    return string;
  }
  function _HexString___get_string__impl_(this_0) {
    return this_0;
  }
  function HexString__toByteArray_impl(this_0) {
    return decode(_HexString___get_string__impl_(this_0));
  }
  function HexString__toUByteArray_impl(this_0) {
    return decodeUnsigned(_HexString___get_string__impl_(this_0));
  }
  var testScope;
  var testCoroutineContext;
  function isFile(path) {
    var tmp;
    try {
      readBytesOfResource(path);
      Unit_getInstance();
      tmp = true;
    } catch ($p) {
      var tmp_0;
      if ($p instanceof Exception) {
        (function () {
          var $externalVarargReceiverTmp = console;
          return $externalVarargReceiverTmp.log.apply($externalVarargReceiverTmp, [].concat([$p]));
        }.call(this));
        tmp_0 = false;
      } else {
        {
          throw $p;
        }
      }
      tmp = tmp_0;
    }
    return tmp;
  }
  function readBytesOfResource(path) {
    var x = new XMLHttpRequest();
    var url = '' + '/' + path;
    x.open('GET', '' + '/' + path, false);
    x.send();
    if (!(x.status === 200)) {
      throw Exception_init_$Create$_0('' + 'Cannot get ' + url + ', ' + x.status + ': ' + x.statusText);
    }return encodeToByteArray(x.responseText);
  }
  function Applier() {
  }
  Applier.prototype.onBeginChanges_1 = function () {
  };
  Applier.prototype.onEndChanges_1 = function () {
  };
  Applier.$metadata$ = {
    simpleName: 'Applier',
    kind: 'interface',
    interfaces: []
  };
  function AbstractApplier(root) {
    this._root = root;
    var tmp = this;
    tmp._stack = ArrayList_init_$Create$();
    this._current_0 = this._root;
    this._$stable = 8;
  }
  AbstractApplier.prototype._set_current_ = function (_set___) {
    this._current_0 = _set___;
  };
  AbstractApplier.prototype._get_current__2 = function () {
    return this._current_0;
  };
  AbstractApplier.prototype.down_0 = function (node_0) {
    this._stack.add_39(this._get_current__2());
    Unit_getInstance();
    this._set_current_(node_0);
  };
  AbstractApplier.prototype.down_1 = function (node_0) {
    return this.down_0((node_0 == null ? true : isObject(node_0)) ? node_0 : THROW_CCE());
  };
  AbstractApplier.prototype.up_1 = function () {
    var tmp0_isNotEmpty_0 = this._stack;
    var tmp1_check_0 = !tmp0_isNotEmpty_0.isEmpty_44();
    if (!tmp1_check_0) {
      var message_2_1 = 'Check failed.';
      throw IllegalStateException_init_$Create$_0(toString_2(message_2_1));
    }this._set_current_(this._stack.removeAt_2(this._stack._get_size__52() - 1 | 0));
  };
  AbstractApplier.$metadata$ = {
    simpleName: 'AbstractApplier',
    kind: 'class',
    interfaces: [Applier]
  };
  function FrameAwaiter(onFrame, continuation) {
    this._onFrame = onFrame;
    this._continuation_3 = continuation;
  }
  FrameAwaiter.prototype.resume = function (timeNanos) {
    var tmp;
    try {
      var tmp0_success_0_1 = Companion_getInstance_2();
      var tmp1_success_0_2 = this._onFrame(timeNanos);
      tmp = _Result___init__impl_(tmp1_success_0_2);
    } catch ($p) {
      var tmp_0;
      if ($p instanceof Error) {
        var tmp2_failure_0_4 = Companion_getInstance_2();
        tmp_0 = _Result___init__impl_(createFailure($p));
      } else {
        {
          throw $p;
        }
      }
      tmp = tmp_0;
    }
    this._continuation_3.resumeWith_21(tmp);
  };
  FrameAwaiter.$metadata$ = {
    simpleName: 'FrameAwaiter',
    kind: 'class',
    interfaces: []
  };
  function fail($this, cause) {
    var tmp0_synchronized_0 = $this._lock;
    if (!($this._failureCause == null))
      return Unit_getInstance();
    $this._failureCause = cause;
    var tmp0_fastForEach_0_2 = $this._awaiters;
    var inductionVariable = 0;
    var last_2 = tmp0_fastForEach_0_2._get_size__52() - 1 | 0;
    if (inductionVariable <= last_2)
      do {
        var index_3_4 = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        var item_4_5 = tmp0_fastForEach_0_2.get_72(index_3_4);
        var tmp0_resumeWithException_0_6_6 = item_4_5._continuation_3;
        var tmp0_failure_0_1_7_7 = Companion_getInstance_2();
        tmp0_resumeWithException_0_6_6.resumeWith_21(_Result___init__impl_(createFailure(cause)));
      }
       while (inductionVariable <= last_2);
    $this._awaiters.clear_9();
  }
  function _no_name_provided__159(this$0, $awaiter_4) {
    this._this$0_23 = this$0;
    this._$awaiter_4 = $awaiter_4;
  }
  _no_name_provided__159.prototype.invoke_341 = function (it) {
    var tmp0_synchronized_0_12 = this._this$0_23._lock;
    var tmp = this._this$0_23._awaiters;
    var tmp_0;
    if (this._$awaiter_4._v == null) {
      throwUninitializedPropertyAccessException('awaiter');
    } else {
      tmp_0 = this._$awaiter_4._v;
    }
    tmp.remove_46(tmp_0);
    Unit_getInstance();
  };
  _no_name_provided__159.prototype.invoke_1115 = function (p1) {
    this.invoke_341((p1 == null ? true : p1 instanceof Error) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__159.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function BroadcastFrameClock(onNewAwaiters) {
    this._onNewAwaiters = onNewAwaiters;
    this._lock = new Object();
    this._failureCause = null;
    var tmp = this;
    tmp._awaiters = ArrayList_init_$Create$();
    var tmp_0 = this;
    tmp_0._spareList = ArrayList_init_$Create$();
    this._$stable_0 = 8;
  }
  BroadcastFrameClock.prototype._get_hasAwaiters_ = function () {
    var tmp0_synchronized_0 = this._lock;
    var tmp0_isNotEmpty_0_2 = this._awaiters;
    return !tmp0_isNotEmpty_0_2.isEmpty_44();
  };
  BroadcastFrameClock.prototype.sendFrame = function (timeNanos) {
    var tmp0_synchronized_0 = this._lock;
    var toResume_2 = this._awaiters;
    this._awaiters = this._spareList;
    this._spareList = toResume_2;
    var inductionVariable = 0;
    var last_2 = toResume_2._get_size__52();
    if (inductionVariable < last_2)
      do {
        var i_4 = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        toResume_2.get_72(i_4).resume(timeNanos);
      }
       while (inductionVariable < last_2);
    toResume_2.clear_9();
  };
  BroadcastFrameClock.prototype.withFrameNanos_1 = function (onFrame, $cont) {
    var tmp0__anonymous__1_1 = $cont;
    var cancellable_2_2 = new CancellableContinuationImpl(intercepted(tmp0__anonymous__1_1), 1);
    cancellable_2_2.initCancellability_0();
    var tmp$ret$0;
    l$ret$1: do {
      var awaiter_4 = {_v: null};
      var tmp0_synchronized_0_6 = this._lock;
      var cause_2_7 = this._failureCause;
      if (!(cause_2_7 == null)) {
        var tmp0_failure_0_1_3_8 = Companion_getInstance_2();
        cancellable_2_2.resumeWith_21(_Result___init__impl_(createFailure(cause_2_7)));
        tmp$ret$0 = Unit_getInstance();
        break l$ret$1;
      }awaiter_4._v = new FrameAwaiter(onFrame, cancellable_2_2);
      var tmp0_isNotEmpty_0_5_10 = this._awaiters;
      var hadAwaiters_4_9 = !tmp0_isNotEmpty_0_5_10.isEmpty_44();
      var tmp = this._awaiters;
      var tmp_0;
      if (awaiter_4._v == null) {
        throwUninitializedPropertyAccessException('awaiter');
      } else {
        tmp_0 = awaiter_4._v;
      }
      tmp.add_39(tmp_0);
      Unit_getInstance();
      var hasNewAwaiters_5 = !hadAwaiters_4_9;
      cancellable_2_2.invokeOnCancellation_1(_no_name_provided_$factory_136(this, awaiter_4));
      if (hasNewAwaiters_5 ? !(this._onNewAwaiters == null) : false) {
        try {
          this._onNewAwaiters();
        } catch ($p) {
          if ($p instanceof Error) {
            fail(this, $p);
          } else {
            {
              throw $p;
            }
          }
        }
      }}
     while (false);
    return cancellable_2_2.getResult_1();
  };
  BroadcastFrameClock.$metadata$ = {
    simpleName: 'BroadcastFrameClock',
    kind: 'class',
    interfaces: [MonotonicFrameClock]
  };
  function _no_name_provided_$factory_136(this$0, $awaiter_4) {
    var i = new _no_name_provided__159(this$0, $awaiter_4);
    return function (p1) {
      i.invoke_341(p1);
      return Unit_getInstance();
    };
  }
  function invalidApplier() {
    var tmp0_error_0 = 'Invalid applier';
    throw IllegalStateException_init_$Create$_0(toString_2(tmp0_error_0));
  }
  var removeCurrentGroupInstance;
  var endGroupInstance;
  var startRootGroup;
  var invocation;
  var provider;
  var compositionLocalMap;
  var providerValues;
  var providerMaps;
  var reference;
  function RememberManager() {
  }
  RememberManager.$metadata$ = {
    simpleName: 'RememberManager',
    kind: 'interface',
    interfaces: []
  };
  function removeCurrentGroup(_this_, rememberManager) {
    var tmp0_iterator_0 = _this_.groupSlots();
    var tmp0_iterator = tmp0_iterator_0;
    while (tmp0_iterator.hasNext_41()) {
      var slot = tmp0_iterator.next_46();
      var tmp1_subject = slot;
      if (!(tmp1_subject == null) ? isInterface(tmp1_subject, RememberObserver) : false) {
        rememberManager.forgetting_0(slot);
      } else {
        if (tmp1_subject instanceof RecomposeScopeImpl) {
          var composition = slot._composition_0;
          if (!(composition == null)) {
            composition._pendingInvalidScopes_0 = true;
            slot._composition_0 = null;
          }} else {
        }
      }
    }
    _this_.removeGroup();
    Unit_getInstance();
  }
  function startRoot($this) {
    $this._reader = $this._slotTable.openReader();
    startGroup($this, 100);
    $this._parentContext_0.startComposing_0();
    $this._parentProvider = $this._parentContext_0.getCompositionLocalScope_0();
    $this._providersInvalidStack.push(asInt($this._providersInvalid));
    $this._providersInvalid = $this.changed_0($this._parentProvider);
    $this._collectParameterInformation = $this._parentContext_0._get_collectingParameterInformation__0();
    var tmp0_safe_receiver = resolveCompositionLocal($this, LocalInspectionTables, $this._parentProvider);
    if (tmp0_safe_receiver == null)
      null;
    else {
      tmp0_safe_receiver.add_39($this._slotTable);
      Unit_getInstance();
      $this._parentContext_0.recordInspectionTable_0(tmp0_safe_receiver);
      Unit_getInstance();
    }
    Unit_getInstance();
    startGroup($this, $this._parentContext_0._get_compoundHashKey__0());
  }
  function endRoot($this) {
    $this.endGroup_1();
    $this._parentContext_0.doneComposing_0();
    $this.endGroup_1();
    recordEndRoot($this);
    finalizeCompose($this);
    $this._reader.close_7();
  }
  function abortRoot($this) {
    cleanUpCompose($this);
    $this._pendingStack.clear_9();
    $this._nodeIndexStack.clear_9();
    $this._groupNodeCountStack.clear_9();
    $this._entersStack.clear_9();
    $this._providersInvalidStack.clear_9();
    $this._reader.close_7();
    $this._compoundKeyHash = 0;
    $this._childrenComposing = 0;
    $this._nodeExpected = false;
    $this._isComposing = false;
  }
  function startGroup($this, key_0) {
    return start($this, key_0, null, false, null);
  }
  function startGroup_0($this, key_0, dataKey) {
    return start($this, key_0, dataKey, false, null);
  }
  function skipGroup($this) {
    var tmp0_this = $this;
    tmp0_this._groupNodeCount = tmp0_this._groupNodeCount + $this._reader.skipGroup_0() | 0;
  }
  function resolveCompositionLocal($this, key_0, scope) {
    var tmp;
    if (contains_6(scope, key_0)) {
      tmp = getValueOf(scope, key_0);
    } else {
      tmp = key_0._defaultValueHolder._get_value__33();
    }
    return tmp;
  }
  function ensureWriter($this) {
    if ($this._writer._closed) {
      $this._writer = $this._insertTable.openWriter();
      $this._writer.skipToGroupEnd_2();
      $this._hasProvider = false;
    }}
  function startReaderGroup($this, isNode_0, data) {
    if (isNode_0) {
      $this._reader.startNode_2();
    } else {
      if (!(data == null) ? !($this._reader._get_groupAux_() === data) : false) {
        recordSlotTableOperation$default($this, false, _no_name_provided_$factory_146(data), 2, null);
      }$this._reader.startGroup_1();
    }
  }
  function start($this, key_0, objectKey_0, isNode_0, data) {
    validateNodeNotExpected($this);
    updateCompoundKeyWhenWeEnterGroup($this, key_0, objectKey_0, data);
    if ($this._inserting) {
      $this._reader.beginEmpty();
      var startIndex = $this._writer._currentGroup;
      if (isNode_0)
        $this._writer.startNode_1(Companion_getInstance_25()._Empty);
      else if (!(data == null)) {
        var tmp = $this._writer;
        var tmp0_elvis_lhs = objectKey_0;
        tmp.startData(key_0, tmp0_elvis_lhs == null ? Companion_getInstance_25()._Empty : tmp0_elvis_lhs, data);
      } else {
        var tmp_0 = $this._writer;
        var tmp1_elvis_lhs = objectKey_0;
        tmp_0.startGroup_0(key_0, tmp1_elvis_lhs == null ? Companion_getInstance_25()._Empty : tmp1_elvis_lhs);
      }
      var tmp2_safe_receiver = $this._pending;
      if (tmp2_safe_receiver == null)
        null;
      else {
        var insertKeyInfo_2 = new KeyInfo(key_0, -1, insertedGroupVirtualIndex($this, startIndex), -1, 0);
        tmp2_safe_receiver.registerInsert(insertKeyInfo_2, $this._nodeIndex - tmp2_safe_receiver._startIndex_1 | 0);
        tmp2_safe_receiver.recordUsed_1(insertKeyInfo_2);
      }
      Unit_getInstance();
      enterGroup($this, isNode_0, null);
      return Unit_getInstance();
    }if ($this._pending == null) {
      var slotKey = $this._reader._get_groupKey_();
      if (slotKey === key_0 ? equals_0(objectKey_0, $this._reader._get_groupObjectKey_()) : false) {
        startReaderGroup($this, isNode_0, data);
      } else {
        $this._pending = new Pending($this._reader.extractKeys(), $this._nodeIndex);
      }
    }var pending = $this._pending;
    var newPending = null;
    if (!(pending == null)) {
      var keyInfo = pending.getNext(key_0, objectKey_0);
      if (!(keyInfo == null)) {
        pending.recordUsed_1(keyInfo);
        Unit_getInstance();
        var location = keyInfo._location_1;
        $this._nodeIndex = pending.nodePositionOf(keyInfo) + pending._startIndex_1 | 0;
        var relativePosition = pending.slotPositionOf(keyInfo);
        var currentRelativePosition = relativePosition - pending._groupIndex | 0;
        pending.registerMoveSlot(relativePosition, pending._groupIndex);
        recordReaderMoving($this, location);
        $this._reader.reposition(location);
        if (currentRelativePosition > 0) {
          recordSlotEditingOperation($this, _no_name_provided_$factory_147(currentRelativePosition));
        }startReaderGroup($this, isNode_0, data);
      } else {
        $this._reader.beginEmpty();
        $this._inserting = true;
        ensureWriter($this);
        $this._writer.beginInsert();
        var startIndex_0 = $this._writer._currentGroup;
        if (isNode_0)
          $this._writer.startNode_1(Companion_getInstance_25()._Empty);
        else if (!(data == null)) {
          var tmp_1 = $this._writer;
          var tmp3_elvis_lhs = objectKey_0;
          tmp_1.startData(key_0, tmp3_elvis_lhs == null ? Companion_getInstance_25()._Empty : tmp3_elvis_lhs, data);
        } else {
          var tmp_2 = $this._writer;
          var tmp4_elvis_lhs = objectKey_0;
          tmp_2.startGroup_0(key_0, tmp4_elvis_lhs == null ? Companion_getInstance_25()._Empty : tmp4_elvis_lhs);
        }
        $this._insertAnchor = $this._writer.anchor_0(startIndex_0);
        var insertKeyInfo = new KeyInfo(key_0, -1, insertedGroupVirtualIndex($this, startIndex_0), -1, 0);
        pending.registerInsert(insertKeyInfo, $this._nodeIndex - pending._startIndex_1 | 0);
        pending.recordUsed_1(insertKeyInfo);
        Unit_getInstance();
        newPending = new Pending(ArrayList_init_$Create$(), isNode_0 ? 0 : $this._nodeIndex);
      }
    }enterGroup($this, isNode_0, newPending);
  }
  function enterGroup($this, isNode_0, newPending) {
    $this._pendingStack.push_0($this._pending);
    Unit_getInstance();
    $this._pending = newPending;
    $this._nodeIndexStack.push($this._nodeIndex);
    if (isNode_0)
      $this._nodeIndex = 0;
    $this._groupNodeCountStack.push($this._groupNodeCount);
    $this._groupNodeCount = 0;
  }
  function exitGroup($this, expectedNodeCount, inserting) {
    var previousPending = $this._pendingStack.pop_0();
    if (!(previousPending == null) ? !inserting : false) {
      var tmp0_this = previousPending;
      var tmp1 = tmp0_this._groupIndex;
      tmp0_this._groupIndex = tmp1 + 1 | 0;
      Unit_getInstance();
    }$this._pending = previousPending;
    $this._nodeIndex = $this._nodeIndexStack.pop_0() + expectedNodeCount | 0;
    $this._groupNodeCount = $this._groupNodeCountStack.pop_0() + expectedNodeCount | 0;
  }
  function end($this, isNode_0) {
    if ($this._inserting) {
      var parent_0 = $this._writer._parent_1;
      updateCompoundKeyWhenWeExitGroup($this, $this._writer.groupKey_1(parent_0), $this._writer.groupObjectKey_1(parent_0), $this._writer.groupAux_1(parent_0));
    } else {
      var parent_1 = $this._reader._parent_2;
      updateCompoundKeyWhenWeExitGroup($this, $this._reader.groupKey_1(parent_1), $this._reader.groupObjectKey_1(parent_1), $this._reader.groupAux_1(parent_1));
    }
    var expectedNodeCount = $this._groupNodeCount;
    var pending = $this._pending;
    if (!(pending == null) ? pending._keyInfos._get_size__52() > 0 : false) {
      var previous = pending._keyInfos;
      var current_0 = pending._get_used_();
      var usedKeys = fastToSet(current_0);
      var placedKeys = LinkedHashSet_init_$Create$();
      var currentIndex = 0;
      var currentEnd = current_0._get_size__52();
      var previousIndex = 0;
      var previousEnd = previous._get_size__52();
      var nodeOffset = 0;
      while (previousIndex < previousEnd) {
        var previousInfo = previous.get_72(previousIndex);
        if (!usedKeys.contains_51(previousInfo)) {
          var deleteOffset = pending.nodePositionOf(previousInfo);
          recordRemoveNode($this, deleteOffset + pending._startIndex_1 | 0, previousInfo._nodes);
          pending.updateNodeCount(previousInfo._location_1, 0);
          Unit_getInstance();
          recordReaderMoving($this, previousInfo._location_1);
          $this._reader.reposition(previousInfo._location_1);
          recordDelete($this);
          $this._reader.skipGroup_0();
          Unit_getInstance();
          removeRange($this._invalidations, previousInfo._location_1, previousInfo._location_1 + $this._reader.groupSize_1(previousInfo._location_1) | 0);
          var tmp0 = previousIndex;
          previousIndex = tmp0 + 1 | 0;
          Unit_getInstance();
          continue;
        }if (placedKeys.contains_51(previousInfo)) {
          var tmp1 = previousIndex;
          previousIndex = tmp1 + 1 | 0;
          Unit_getInstance();
          continue;
        }if (currentIndex < currentEnd) {
          var currentInfo = current_0.get_72(currentIndex);
          if (!(currentInfo === previousInfo)) {
            var nodePosition = pending.nodePositionOf(currentInfo);
            placedKeys.add_39(currentInfo);
            Unit_getInstance();
            if (!(nodePosition === nodeOffset)) {
              var updatedCount = pending.updatedNodeCountOf(currentInfo);
              recordMoveNode($this, nodePosition + pending._startIndex_1 | 0, nodeOffset + pending._startIndex_1 | 0, updatedCount);
              pending.registerMoveNode(nodePosition, nodeOffset, updatedCount);
            }} else {
            var tmp2 = previousIndex;
            previousIndex = tmp2 + 1 | 0;
            Unit_getInstance();
          }
          var tmp3 = currentIndex;
          currentIndex = tmp3 + 1 | 0;
          Unit_getInstance();
          nodeOffset = nodeOffset + pending.updatedNodeCountOf(currentInfo) | 0;
        }}
      realizeMovement($this);
      if (previous._get_size__52() > 0) {
        recordReaderMoving($this, $this._reader._get_groupEnd_());
        $this._reader.skipToGroupEnd_2();
      }}var removeIndex = $this._nodeIndex;
    while (!$this._reader._get_isGroupEnd_()) {
      var startSlot = $this._reader._currentGroup_0;
      recordDelete($this);
      var nodesToRemove = $this._reader.skipGroup_0();
      recordRemoveNode($this, removeIndex, nodesToRemove);
      removeRange($this._invalidations, startSlot, $this._reader._currentGroup_0);
    }
    var inserting = $this._inserting;
    if (inserting) {
      if (isNode_0) {
        registerInsertUpFixup($this);
        expectedNodeCount = 1;
      }$this._reader.endEmpty();
      var parentGroup = $this._writer._parent_1;
      $this._writer.endGroup_0();
      Unit_getInstance();
      if (!$this._reader._get_inEmpty_()) {
        var virtualIndex = insertedGroupVirtualIndex($this, parentGroup);
        $this._writer.endInsert();
        $this._writer.close_7();
        recordInsert($this, $this._insertAnchor);
        $this._inserting = false;
        if (!$this._slotTable._get_isEmpty__3()) {
          updateNodeCount($this, virtualIndex, 0);
          updateNodeCountOverrides($this, virtualIndex, expectedNodeCount);
        }}} else {
      if (isNode_0)
        recordUp($this);
      recordEndGroup($this);
      var parentGroup_0 = $this._reader._parent_2;
      var parentNodeCount = updatedNodeCount($this, parentGroup_0);
      if (!(expectedNodeCount === parentNodeCount)) {
        updateNodeCountOverrides($this, parentGroup_0, expectedNodeCount);
      }if (isNode_0) {
        expectedNodeCount = 1;
      }$this._reader.endGroup_1();
      realizeMovement($this);
    }
    exitGroup($this, expectedNodeCount, inserting);
  }
  function recomposeToGroupEnd($this) {
    var wasComposing = $this._isComposing;
    $this._isComposing = true;
    var recomposed = false;
    var parent_0 = $this._reader._parent_2;
    var end_0 = parent_0 + $this._reader.groupSize_1(parent_0) | 0;
    var recomposeIndex = $this._nodeIndex;
    var recomposeCompoundKey = $this._compoundKeyHash;
    var oldGroupNodeCount = $this._groupNodeCount;
    var oldGroup = parent_0;
    var firstInRange_0 = firstInRange($this._invalidations, $this._reader._currentGroup_0, end_0);
    while (!(firstInRange_0 == null)) {
      var location = firstInRange_0._location;
      removeLocation($this._invalidations, location);
      Unit_getInstance();
      recomposed = true;
      $this._reader.reposition(location);
      var newGroup = $this._reader._currentGroup_0;
      recordUpsAndDowns($this, oldGroup, newGroup, parent_0);
      oldGroup = newGroup;
      $this._nodeIndex = nodeIndexOf($this, location, newGroup, parent_0, recomposeIndex);
      $this._compoundKeyHash = compoundKeyOf($this, $this._reader.parent_1(newGroup), parent_0, recomposeCompoundKey);
      firstInRange_0._scope.compose($this);
      $this._reader.restoreParent(parent_0);
      firstInRange_0 = firstInRange($this._invalidations, $this._reader._currentGroup_0, end_0);
    }
    if (recomposed) {
      recordUpsAndDowns($this, oldGroup, parent_0, parent_0);
      $this._reader.skipToGroupEnd_2();
      var parentGroupNodes = updatedNodeCount($this, parent_0);
      $this._nodeIndex = recomposeIndex + parentGroupNodes | 0;
      $this._groupNodeCount = oldGroupNodeCount + parentGroupNodes | 0;
    } else {
      skipReaderToGroupEnd($this);
    }
    $this._compoundKeyHash = recomposeCompoundKey;
    $this._isComposing = wasComposing;
  }
  function insertedGroupVirtualIndex($this, index) {
    return -2 - index | 0;
  }
  function updateNodeCountOverrides($this, group, newCount) {
    var currentCount = updatedNodeCount($this, group);
    if (!(currentCount === newCount)) {
      var delta_0 = newCount - currentCount | 0;
      var current_0 = group;
      var minPending = $this._pendingStack._get_size__52() - 1 | 0;
      $l$break_0: while (!(current_0 === -1)) {
        var newCurrentNodes = updatedNodeCount($this, current_0) + delta_0 | 0;
        updateNodeCount($this, current_0, newCurrentNodes);
        var inductionVariable = minPending;
        if (0 <= inductionVariable)
          $l$break: do {
            var pendingIndex = inductionVariable;
            inductionVariable = inductionVariable + -1 | 0;
            var pending = $this._pendingStack.peek_1(pendingIndex);
            if (!(pending == null) ? pending.updateNodeCount(current_0, newCurrentNodes) : false) {
              minPending = pendingIndex - 1 | 0;
              break $l$break;
            }}
           while (0 <= inductionVariable);
        if (current_0 < 0) {
          current_0 = $this._reader._parent_2;
        } else {
          if ($this._reader.isNode_0(current_0))
            break $l$break_0;
          current_0 = $this._reader.parent_1(current_0);
        }
      }
    }}
  function nodeIndexOf($this, groupLocation, group, recomposeGroup, recomposeIndex) {
    var anchorGroup = $this._reader.parent_1(group);
    $l$break: while (!(anchorGroup === recomposeGroup)) {
      if ($this._reader.isNode_0(anchorGroup))
        break $l$break;
      anchorGroup = $this._reader.parent_1(anchorGroup);
    }
    var index = $this._reader.isNode_0(anchorGroup) ? 0 : recomposeIndex;
    if (anchorGroup === group)
      return index;
    var current_0 = anchorGroup;
    var nodeIndexLimit = index + (updatedNodeCount($this, anchorGroup) - $this._reader.nodeCount(group) | 0) | 0;
    loop: while (index < nodeIndexLimit) {
      if (current_0 === groupLocation)
        break loop;
      var tmp0 = current_0;
      current_0 = tmp0 + 1 | 0;
      Unit_getInstance();
      while (current_0 < groupLocation) {
        var end_0 = current_0 + $this._reader.groupSize_1(current_0) | 0;
        if (groupLocation < end_0)
          continue loop;
        index = index + updatedNodeCount($this, current_0) | 0;
        current_0 = end_0;
      }
      break loop;
    }
    return index;
  }
  function updatedNodeCount($this, group) {
    if (group < 0) {
      var tmp0_safe_receiver = $this._nodeCountVirtualOverrides;
      var tmp;
      if (tmp0_safe_receiver == null) {
        tmp = null;
      } else {
        tmp = tmp0_safe_receiver.get_56(group);
      }
      var tmp1_elvis_lhs = tmp;
      return tmp1_elvis_lhs == null ? 0 : tmp1_elvis_lhs;
    }var nodeCounts = $this._nodeCountOverrides;
    if (!(nodeCounts == null)) {
      var override = nodeCounts[group];
      if (override >= 0)
        return override;
    }return $this._reader.nodeCount(group);
  }
  function updateNodeCount($this, group, count_0) {
    if (!(updatedNodeCount($this, group) === count_0)) {
      if (group < 0) {
        var tmp0_elvis_lhs = $this._nodeCountVirtualOverrides;
        var tmp;
        if (tmp0_elvis_lhs == null) {
          var newCounts_2 = HashMap_init_$Create$();
          $this._nodeCountVirtualOverrides = newCounts_2;
          tmp = newCounts_2;
        } else {
          tmp = tmp0_elvis_lhs;
        }
        var virtualCounts = tmp;
        virtualCounts.put_6(group, count_0);
        Unit_getInstance();
      } else {
        var tmp1_elvis_lhs = $this._nodeCountOverrides;
        var tmp_0;
        if (tmp1_elvis_lhs == null) {
          var newCounts_2_0 = new Int32Array($this._reader._get_size__52());
          fill$default(newCounts_2_0, -1, 0, 0, 6, null);
          $this._nodeCountOverrides = newCounts_2_0;
          tmp_0 = newCounts_2_0;
        } else {
          tmp_0 = tmp1_elvis_lhs;
        }
        var nodeCounts = tmp_0;
        nodeCounts[group] = count_0;
      }
    }}
  function clearUpdatedNodeCounts($this) {
    $this._nodeCountOverrides = null;
    $this._nodeCountVirtualOverrides = null;
  }
  function recordUpsAndDowns($this, oldGroup, newGroup, commonRoot) {
    var reader = $this._reader;
    var nearestCommonRoot = nearestCommonRootOf(reader, oldGroup, newGroup, commonRoot);
    var current_0 = oldGroup;
    while (current_0 > 0 ? !(current_0 === nearestCommonRoot) : false) {
      if (reader.isNode_0(current_0))
        recordUp($this);
      current_0 = reader.parent_1(current_0);
    }
    doRecordDownsFor($this, newGroup, nearestCommonRoot);
  }
  function doRecordDownsFor($this, group, nearestCommonRoot) {
    if (group > 0 ? !(group === nearestCommonRoot) : false) {
      doRecordDownsFor($this, $this._reader.parent_1(group), nearestCommonRoot);
      if ($this._reader.isNode_0(group))
        recordDown($this, nodeAt($this._reader, $this, group));
    }}
  function compoundKeyOf($this, group, recomposeGroup, recomposeKey) {
    var tmp;
    if (group === recomposeGroup) {
      tmp = recomposeKey;
    } else {
      var tmp0_rol_0 = compoundKeyOf($this, $this._reader.parent_1(group), recomposeGroup, recomposeKey);
      var tmp1_rol_0 = 3;
      tmp = rotateLeft(tmp0_rol_0, tmp1_rol_0) ^ groupCompoundKeyPart($this._reader, $this, group);
    }
    return tmp;
  }
  function groupCompoundKeyPart(_this_, $this, group) {
    var tmp;
    if (_this_.hasObjectKey(group)) {
      var tmp0_safe_receiver = _this_.groupObjectKey_1(group);
      var tmp1_elvis_lhs = tmp0_safe_receiver == null ? null : hashCode(tmp0_safe_receiver);
      tmp = tmp1_elvis_lhs == null ? 0 : tmp1_elvis_lhs;
    } else {
      var tmp0_let_0 = _this_.groupKey_1(group);
      var tmp_0;
      if (tmp0_let_0 === 207) {
        var tmp0_safe_receiver_3 = _this_.groupAux_1(group);
        var tmp_1;
        if (tmp0_safe_receiver_3 == null) {
          tmp_1 = null;
        } else {
          tmp_1 = equals_0(tmp0_safe_receiver_3, Companion_getInstance_25()._Empty) ? tmp0_let_0 : hashCode(tmp0_safe_receiver_3);
        }
        var tmp1_elvis_lhs_2 = tmp_1;
        tmp_0 = tmp1_elvis_lhs_2 == null ? tmp0_let_0 : tmp1_elvis_lhs_2;
      } else {
        tmp_0 = tmp0_let_0;
      }
      tmp = tmp_0;
    }
    return tmp;
  }
  function skipReaderToGroupEnd($this) {
    $this._groupNodeCount = $this._reader._get_parentNodes_();
    $this._reader.skipToGroupEnd_2();
  }
  function addRecomposeScope($this) {
    if ($this._inserting) {
      var tmp = $this._composition;
      var scope = new RecomposeScopeImpl(tmp instanceof CompositionImpl ? tmp : THROW_CCE());
      $this._invalidateStack.push_0(scope);
      Unit_getInstance();
      $this.updateValue(scope);
      scope.start_17($this._snapshot._get_id__26());
    } else {
      var invalidation = removeLocation($this._invalidations, $this._reader._parent_2);
      var tmp_0 = $this._reader.next_46();
      var scope_0 = tmp_0 instanceof RecomposeScopeImpl ? tmp_0 : THROW_CCE();
      scope_0._requiresRecompose = !(invalidation == null);
      $this._invalidateStack.push_0(scope_0);
      Unit_getInstance();
      scope_0.start_17($this._snapshot._get_id__26());
    }
  }
  function _get_node_(_this_, $this) {
    return _this_.node_2(_this_._parent_2);
  }
  function nodeAt(_this_, $this, index) {
    return _this_.node_2(index);
  }
  function validateNodeExpected($this) {
    var tmp0_check_0 = $this._nodeExpected;
    if (!tmp0_check_0) {
      var message_1 = 'A call to createNode(), emitNode() or useNode() expected was not expected';
      throw IllegalStateException_init_$Create$_0(toString_2(message_1));
    }$this._nodeExpected = false;
  }
  function validateNodeNotExpected($this) {
    var tmp0_check_0 = !$this._nodeExpected;
    if (!tmp0_check_0) {
      var message_1 = 'A call to createNode(), emitNode() or useNode() expected';
      throw IllegalStateException_init_$Create$_0(toString_2(message_1));
    }}
  function record($this, change) {
    $this._changes.add_39(change);
    Unit_getInstance();
  }
  function recordApplierOperation($this, change) {
    realizeUps($this);
    realizeDowns_0($this);
    record($this, change);
  }
  function recordSlotEditingOperation($this, change) {
    realizeOperationLocation$default($this, false, 2, null);
    recordSlotEditing($this);
    record($this, change);
  }
  function recordSlotTableOperation($this, forParent, change) {
    realizeOperationLocation($this, forParent);
    record($this, change);
  }
  function recordSlotTableOperation$default($this, forParent, change, $mask0, $handler) {
    if (!(($mask0 & 2) === 0))
      forParent = false;
    return recordSlotTableOperation($this, forParent, change);
  }
  function realizeUps($this) {
    var count_0 = $this._pendingUps;
    if (count_0 > 0) {
      $this._pendingUps = 0;
      record($this, _no_name_provided_$factory_149(count_0));
    }}
  function realizeDowns($this, nodes) {
    record($this, _no_name_provided_$factory_150(nodes));
  }
  function realizeDowns_0($this) {
    if ($this._downNodes.isNotEmpty_0()) {
      realizeDowns($this, $this._downNodes.toArray_3());
      $this._downNodes.clear_9();
    }}
  function recordDown($this, node_0) {
    $this._downNodes.push_0(node_0);
    Unit_getInstance();
  }
  function recordUp($this) {
    if ($this._downNodes.isNotEmpty_0()) {
      $this._downNodes.pop_0();
      Unit_getInstance();
    } else {
      var tmp0_this = $this;
      var tmp1 = tmp0_this._pendingUps;
      tmp0_this._pendingUps = tmp1 + 1 | 0;
      Unit_getInstance();
    }
  }
  function realizeOperationLocation($this, forParent) {
    var location = forParent ? $this._reader._parent_2 : $this._reader._currentGroup_0;
    var distance = location - $this._writersReaderDelta | 0;
    var tmp0_require_0 = distance >= 0;
    if (!tmp0_require_0) {
      var message_1 = 'Tried to seek backward';
      throw IllegalArgumentException_init_$Create$_0(toString_2(message_1));
    }if (distance > 0) {
      record($this, _no_name_provided_$factory_151(distance));
      $this._writersReaderDelta = location;
    }}
  function realizeOperationLocation$default($this, forParent, $mask0, $handler) {
    if (!(($mask0 & 2) === 0))
      forParent = false;
    return realizeOperationLocation($this, forParent);
  }
  function recordInsert($this, anchor) {
    if ($this._insertFixups.isEmpty_44()) {
      var insertTable = $this._insertTable;
      recordSlotEditingOperation($this, _no_name_provided_$factory_152(insertTable, anchor));
    } else {
      var fixups = toMutableList_1($this._insertFixups);
      $this._insertFixups.clear_9();
      realizeUps($this);
      realizeDowns_0($this);
      var insertTable_0 = $this._insertTable;
      recordSlotEditingOperation($this, _no_name_provided_$factory_153(insertTable_0, fixups, anchor));
    }
  }
  function recordFixup($this, change) {
    $this._insertFixups.add_39(change);
    Unit_getInstance();
  }
  function recordInsertUpFixup($this, change) {
    $this._insertUpFixups.push_0(change);
    Unit_getInstance();
  }
  function registerInsertUpFixup($this) {
    $this._insertFixups.add_39($this._insertUpFixups.pop_0());
    Unit_getInstance();
  }
  function recordDelete($this) {
    recordSlotEditingOperation($this, removeCurrentGroupInstance);
    var tmp0_this = $this;
    tmp0_this._writersReaderDelta = tmp0_this._writersReaderDelta + $this._reader._get_groupSize_() | 0;
  }
  function recordReaderMoving($this, location) {
    var distance = $this._reader._currentGroup_0 - $this._writersReaderDelta | 0;
    $this._writersReaderDelta = location - distance | 0;
  }
  function recordSlotEditing($this) {
    if (!$this._slotTable._get_isEmpty__3()) {
      var reader = $this._reader;
      var location = reader._parent_2;
      if (!($this._startedGroups.peekOr(-1) === location)) {
        if (!$this._startedGroup) {
          recordSlotTableOperation$default($this, false, startRootGroup, 2, null);
          $this._startedGroup = true;
        }var anchor = reader.anchor_0(location);
        $this._startedGroups.push(location);
        recordSlotTableOperation$default($this, false, _no_name_provided_$factory_154(anchor), 2, null);
      }}}
  function recordEndGroup($this) {
    var location = $this._reader._parent_2;
    var currentStartedGroup = $this._startedGroups.peekOr(-1);
    var tmp0_check_0 = currentStartedGroup <= location;
    if (!tmp0_check_0) {
      var message_1 = 'Missed recording an endGroup';
      throw IllegalStateException_init_$Create$_0(toString_2(message_1));
    }if ($this._startedGroups.peekOr(-1) === location) {
      $this._startedGroups.pop_0();
      Unit_getInstance();
      recordSlotTableOperation$default($this, false, endGroupInstance, 2, null);
    }}
  function recordEndRoot($this) {
    if ($this._startedGroup) {
      recordSlotTableOperation$default($this, false, endGroupInstance, 2, null);
      $this._startedGroup = false;
    }}
  function finalizeCompose($this) {
    realizeUps($this);
    var tmp0_check_0 = $this._pendingStack.isEmpty_44();
    if (!tmp0_check_0) {
      var message_1 = 'Start/end imbalance';
      throw IllegalStateException_init_$Create$_0(toString_2(message_1));
    }var tmp1_check_0 = $this._startedGroups.isEmpty_44();
    if (!tmp1_check_0) {
      var message_1_0 = 'Missed recording an endGroup()';
      throw IllegalStateException_init_$Create$_0(toString_2(message_1_0));
    }cleanUpCompose($this);
  }
  function cleanUpCompose($this) {
    $this._pending = null;
    $this._nodeIndex = 0;
    $this._groupNodeCount = 0;
    $this._writersReaderDelta = 0;
    $this._compoundKeyHash = 0;
    $this._nodeExpected = false;
    $this._startedGroup = false;
    $this._startedGroups.clear_9();
    $this._invalidateStack.clear_9();
    clearUpdatedNodeCounts($this);
  }
  function recordRemoveNode($this, nodeIndex_1, count_0) {
    if (count_0 > 0) {
      var tmp0_check_0 = nodeIndex_1 >= 0;
      if (!tmp0_check_0) {
        var message_1 = '' + 'Invalid remove index ' + nodeIndex_1;
        throw IllegalStateException_init_$Create$_0(toString_2(message_1));
      }if ($this._previousRemove === nodeIndex_1) {
        var tmp0_this = $this;
        tmp0_this._previousCount = tmp0_this._previousCount + count_0 | 0;
      } else {
        realizeMovement($this);
        $this._previousRemove = nodeIndex_1;
        $this._previousCount = count_0;
      }
    }}
  function recordMoveNode($this, from, to_0, count_0) {
    if (count_0 > 0) {
      if (($this._previousCount > 0 ? $this._previousMoveFrom === (from - $this._previousCount | 0) : false) ? $this._previousMoveTo === (to_0 - $this._previousCount | 0) : false) {
        var tmp0_this = $this;
        tmp0_this._previousCount = tmp0_this._previousCount + count_0 | 0;
      } else {
        realizeMovement($this);
        $this._previousMoveFrom = from;
        $this._previousMoveTo = to_0;
        $this._previousCount = count_0;
      }
    }}
  function realizeMovement($this) {
    var count_0 = $this._previousCount;
    $this._previousCount = 0;
    if (count_0 > 0) {
      if ($this._previousRemove >= 0) {
        var removeIndex = $this._previousRemove;
        $this._previousRemove = -1;
        recordApplierOperation($this, _no_name_provided_$factory_155(removeIndex, count_0));
      } else {
        var from = $this._previousMoveFrom;
        $this._previousMoveFrom = -1;
        var to_0 = $this._previousMoveTo;
        $this._previousMoveTo = -1;
        recordApplierOperation($this, _no_name_provided_$factory_156(from, to_0, count_0));
      }
    }}
  function updateCompoundKeyWhenWeEnterGroup($this, groupKey, dataKey, data) {
    if (dataKey == null)
      if ((!(data == null) ? groupKey === 207 : false) ? !equals_0(data, Companion_getInstance_25()._Empty) : false)
        updateCompoundKeyWhenWeEnterGroupKeyHash($this, hashCode(data));
      else
        updateCompoundKeyWhenWeEnterGroupKeyHash($this, groupKey);
    else
      updateCompoundKeyWhenWeEnterGroupKeyHash($this, hashCode(dataKey));
  }
  function updateCompoundKeyWhenWeEnterGroupKeyHash($this, keyHash) {
    var tmp = $this;
    var tmp0_rol_0 = $this._compoundKeyHash;
    var tmp1_rol_0 = 3;
    tmp._compoundKeyHash = rotateLeft(tmp0_rol_0, tmp1_rol_0) ^ keyHash;
  }
  function updateCompoundKeyWhenWeExitGroup($this, groupKey, dataKey, data) {
    if (dataKey == null)
      if ((!(data == null) ? groupKey === 207 : false) ? !equals_0(data, Companion_getInstance_25()._Empty) : false)
        updateCompoundKeyWhenWeExitGroupKeyHash($this, hashCode(data));
      else
        updateCompoundKeyWhenWeExitGroupKeyHash($this, groupKey);
    else
      updateCompoundKeyWhenWeExitGroupKeyHash($this, hashCode(dataKey));
  }
  function updateCompoundKeyWhenWeExitGroupKeyHash($this, groupKey) {
    var tmp = $this;
    var tmp0_ror_0 = $this._compoundKeyHash ^ groupKey;
    var tmp1_ror_0 = 3;
    tmp._compoundKeyHash = rotateRight(tmp0_ror_0, tmp1_ror_0);
  }
  function doCompose$composable($this, invalidationsRequested, content) {
    var tmp0_check_0 = !$this._isComposing;
    if (!tmp0_check_0) {
      var message_1 = 'Reentrant composition is not supported';
      throw IllegalStateException_init_$Create$_0(toString_2(message_1));
    }var tmp$ret$0;
    l$ret$1: do {
      var tmp1_trace_0 = 'Compose:recompose';
      var token_1 = Trace_getInstance().beginSection(tmp1_trace_0);
      try {
        $this._snapshot = currentSnapshot();
        var tmp0_iterator_1_3 = invalidationsRequested.iterator_66();
        while (tmp0_iterator_1_3.hasNext_41()) {
          var element_2_4 = tmp0_iterator_1_3.next_46();
          var tmp0_safe_receiver_6_7 = element_2_4._anchor;
          var tmp1_elvis_lhs_5_6 = tmp0_safe_receiver_6_7 == null ? null : tmp0_safe_receiver_6_7._location_0;
          var tmp;
          if (tmp1_elvis_lhs_5_6 == null) {
            return Unit_getInstance();
          } else {
            tmp = tmp1_elvis_lhs_5_6;
          }
          var location_4_5 = tmp;
          $this._invalidations.add_39(new Invalidation(element_2_4, location_4_5));
          Unit_getInstance();
        }
        var tmp0_sortBy_0_8 = $this._invalidations;
        if (tmp0_sortBy_0_8._get_size__52() > 1) {
          var tmp_0 = _no_name_provided_$factory_157();
          sortWith(tmp0_sortBy_0_8, new sam$kotlin_Comparator$0(tmp_0));
        }$this._nodeIndex = 0;
        var complete_10 = false;
        $this._isComposing = true;
        var tmp_1;
        try {
          startRoot($this);
          if (!(content == null)) {
            startGroup_0($this, 200, invocation);
            invokeComposable$composable($this, content);
            $this.endGroup_1();
          } else {
            $this.skipCurrentGroup_0();
          }
          endRoot($this);
          complete_10 = true;
          tmp_1 = Unit_getInstance();
        }finally {
          $this._isComposing = false;
          $this._invalidations.clear_9();
          $this._providerUpdates.clear_9();
          if (!complete_10)
            abortRoot($this);
        }
        tmp$ret$0 = tmp_1;
        break l$ret$1;
      }finally {
        Trace_getInstance().endSection(token_1);
      }
    }
     while (false);
  }
  function sam$kotlin_Comparator$0(function_0) {
    this._function = function_0;
  }
  sam$kotlin_Comparator$0.prototype.compare_6 = function (a, b) {
    return this._function(a, b);
  };
  sam$kotlin_Comparator$0.prototype.compare = function (a, b) {
    return this.compare_6(a, b);
  };
  sam$kotlin_Comparator$0.$metadata$ = {
    simpleName: 'sam$kotlin_Comparator$0',
    kind: 'class',
    interfaces: [Comparator]
  };
  function _no_name_provided__160($factory, $groupAnchor, $insertIndex) {
    this._$factory = $factory;
    this._$groupAnchor = $groupAnchor;
    this._$insertIndex = $insertIndex;
  }
  _no_name_provided__160.prototype.invoke_320 = function (applier, slots, _anonymous_parameter_2_) {
    var node_0 = this._$factory();
    slots.updateNode_0(this._$groupAnchor, node_0);
    var nodeApplier = isInterface(applier, Applier) ? applier : THROW_CCE();
    nodeApplier.insertTopDown_2(this._$insertIndex, node_0);
    applier.down_1(node_0);
  };
  _no_name_provided__160.prototype.invoke_917 = function (p1, p2, p3) {
    var tmp = (!(p1 == null) ? isInterface(p1, Applier) : false) ? p1 : THROW_CCE();
    var tmp_0 = p2 instanceof SlotWriter ? p2 : THROW_CCE();
    this.invoke_320(tmp, tmp_0, (!(p3 == null) ? isInterface(p3, RememberManager) : false) ? p3 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__160.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__161($groupAnchor, $insertIndex) {
    this._$groupAnchor_0 = $groupAnchor;
    this._$insertIndex_0 = $insertIndex;
  }
  _no_name_provided__161.prototype.invoke_320 = function (applier, slots, _anonymous_parameter_2_) {
    var nodeToInsert = slots.node_1(this._$groupAnchor_0);
    applier.up_1();
    var nodeApplier = isInterface(applier, Applier) ? applier : THROW_CCE();
    nodeApplier.insertBottomUp_2(this._$insertIndex_0, nodeToInsert);
  };
  _no_name_provided__161.prototype.invoke_917 = function (p1, p2, p3) {
    var tmp = (!(p1 == null) ? isInterface(p1, Applier) : false) ? p1 : THROW_CCE();
    var tmp_0 = p2 instanceof SlotWriter ? p2 : THROW_CCE();
    this.invoke_320(tmp, tmp_0, (!(p3 == null) ? isInterface(p3, RememberManager) : false) ? p3 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__161.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__162($block, $value) {
    this._$block_0 = $block;
    this._$value = $value;
  }
  _no_name_provided__162.prototype.invoke_320 = function (applier, _anonymous_parameter_1_, _anonymous_parameter_2_) {
    var tmp = applier._get_current__2();
    this._$block_0((tmp == null ? true : isObject(tmp)) ? tmp : THROW_CCE(), this._$value);
  };
  _no_name_provided__162.prototype.invoke_917 = function (p1, p2, p3) {
    var tmp = (!(p1 == null) ? isInterface(p1, Applier) : false) ? p1 : THROW_CCE();
    var tmp_0 = p2 instanceof SlotWriter ? p2 : THROW_CCE();
    this.invoke_320(tmp, tmp_0, (!(p3 == null) ? isInterface(p3, RememberManager) : false) ? p3 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__162.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__163($value) {
    this._$value_0 = $value;
  }
  _no_name_provided__163.prototype.invoke_320 = function (_anonymous_parameter_0_, _anonymous_parameter_1_, rememberManager) {
    rememberManager.remembering_0(this._$value_0);
  };
  _no_name_provided__163.prototype.invoke_917 = function (p1, p2, p3) {
    var tmp = (!(p1 == null) ? isInterface(p1, Applier) : false) ? p1 : THROW_CCE();
    var tmp_0 = p2 instanceof SlotWriter ? p2 : THROW_CCE();
    this.invoke_320(tmp, tmp_0, (!(p3 == null) ? isInterface(p3, RememberManager) : false) ? p3 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__163.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__164($value, this$0, $groupSlotIndex) {
    this._$value_1 = $value;
    this._this$0_24 = this$0;
    this._$groupSlotIndex = $groupSlotIndex;
  }
  _no_name_provided__164.prototype.invoke_320 = function (_anonymous_parameter_0_, slots, rememberManager) {
    var tmp = this._$value_1;
    if (!(tmp == null) ? isInterface(tmp, RememberObserver) : false) {
      this._this$0_24._abandonSet.add_39(this._$value_1);
      Unit_getInstance();
      rememberManager.remembering_0(this._$value_1);
    } else {
    }
    var previous = slots.set_6(this._$groupSlotIndex, this._$value_1);
    if (!(previous == null) ? isInterface(previous, RememberObserver) : false)
      rememberManager.forgetting_0(previous);
    else {
      if (previous instanceof RecomposeScopeImpl) {
        var composition = previous._composition_0;
        if (!(composition == null)) {
          previous._composition_0 = null;
          composition._pendingInvalidScopes_0 = true;
        }} else {
      }
    }
  };
  _no_name_provided__164.prototype.invoke_917 = function (p1, p2, p3) {
    var tmp = (!(p1 == null) ? isInterface(p1, Applier) : false) ? p1 : THROW_CCE();
    var tmp_0 = p2 instanceof SlotWriter ? p2 : THROW_CCE();
    this.invoke_320(tmp, tmp_0, (!(p3 == null) ? isInterface(p3, RememberManager) : false) ? p3 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__164.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__165($effect) {
    this._$effect = $effect;
  }
  _no_name_provided__165.prototype.invoke_320 = function (_anonymous_parameter_0_, _anonymous_parameter_1_, rememberManager) {
    rememberManager.sideEffect_0(this._$effect);
  };
  _no_name_provided__165.prototype.invoke_917 = function (p1, p2, p3) {
    var tmp = (!(p1 == null) ? isInterface(p1, Applier) : false) ? p1 : THROW_CCE();
    var tmp_0 = p2 instanceof SlotWriter ? p2 : THROW_CCE();
    this.invoke_320(tmp, tmp_0, (!(p3 == null) ? isInterface(p3, RememberManager) : false) ? p3 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__165.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__166($data) {
    this._$data = $data;
  }
  _no_name_provided__166.prototype.invoke_320 = function (_anonymous_parameter_0_, slots, _anonymous_parameter_2_) {
    slots.updateAux(this._$data);
  };
  _no_name_provided__166.prototype.invoke_917 = function (p1, p2, p3) {
    var tmp = (!(p1 == null) ? isInterface(p1, Applier) : false) ? p1 : THROW_CCE();
    var tmp_0 = p2 instanceof SlotWriter ? p2 : THROW_CCE();
    this.invoke_320(tmp, tmp_0, (!(p3 == null) ? isInterface(p3, RememberManager) : false) ? p3 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__166.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__167($currentRelativePosition) {
    this._$currentRelativePosition = $currentRelativePosition;
  }
  _no_name_provided__167.prototype.invoke_320 = function (_anonymous_parameter_0_, slots, _anonymous_parameter_2_) {
    slots.moveGroup(this._$currentRelativePosition);
  };
  _no_name_provided__167.prototype.invoke_917 = function (p1, p2, p3) {
    var tmp = (!(p1 == null) ? isInterface(p1, Applier) : false) ? p1 : THROW_CCE();
    var tmp_0 = p2 instanceof SlotWriter ? p2 : THROW_CCE();
    this.invoke_320(tmp, tmp_0, (!(p3 == null) ? isInterface(p3, RememberManager) : false) ? p3 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__167.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__168($tmp2_safe_receiver, this$0) {
    this._$tmp2_safe_receiver = $tmp2_safe_receiver;
    this._this$0_25 = this$0;
  }
  _no_name_provided__168.prototype.invoke_320 = function (_anonymous_parameter_0_, _anonymous_parameter_1_, _anonymous_parameter_2_) {
    this._$tmp2_safe_receiver(this._this$0_25._composition);
  };
  _no_name_provided__168.prototype.invoke_917 = function (p1, p2, p3) {
    var tmp = (!(p1 == null) ? isInterface(p1, Applier) : false) ? p1 : THROW_CCE();
    var tmp_0 = p2 instanceof SlotWriter ? p2 : THROW_CCE();
    this.invoke_320(tmp, tmp_0, (!(p3 == null) ? isInterface(p3, RememberManager) : false) ? p3 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__168.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__169($count) {
    this._$count = $count;
  }
  _no_name_provided__169.prototype.invoke_320 = function (applier, _anonymous_parameter_1_, _anonymous_parameter_2_) {
    var inductionVariable = 0;
    var last_2 = this._$count;
    if (inductionVariable < last_2)
      do {
        var index_2 = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        applier.up_1();
      }
       while (inductionVariable < last_2);
  };
  _no_name_provided__169.prototype.invoke_917 = function (p1, p2, p3) {
    var tmp = (!(p1 == null) ? isInterface(p1, Applier) : false) ? p1 : THROW_CCE();
    var tmp_0 = p2 instanceof SlotWriter ? p2 : THROW_CCE();
    this.invoke_320(tmp, tmp_0, (!(p3 == null) ? isInterface(p3, RememberManager) : false) ? p3 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__169.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__170($nodes) {
    this._$nodes = $nodes;
  }
  _no_name_provided__170.prototype.invoke_320 = function (applier, _anonymous_parameter_1_, _anonymous_parameter_2_) {
    var inductionVariable = 0;
    var last_2 = this._$nodes.length - 1 | 0;
    if (inductionVariable <= last_2)
      do {
        var index = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        var nodeApplier = isInterface(applier, Applier) ? applier : THROW_CCE();
        nodeApplier.down_1(this._$nodes[index]);
      }
       while (inductionVariable <= last_2);
  };
  _no_name_provided__170.prototype.invoke_917 = function (p1, p2, p3) {
    var tmp = (!(p1 == null) ? isInterface(p1, Applier) : false) ? p1 : THROW_CCE();
    var tmp_0 = p2 instanceof SlotWriter ? p2 : THROW_CCE();
    this.invoke_320(tmp, tmp_0, (!(p3 == null) ? isInterface(p3, RememberManager) : false) ? p3 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__170.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__171($distance) {
    this._$distance = $distance;
  }
  _no_name_provided__171.prototype.invoke_320 = function (_anonymous_parameter_0_, slots, _anonymous_parameter_2_) {
    slots.advanceBy(this._$distance);
  };
  _no_name_provided__171.prototype.invoke_917 = function (p1, p2, p3) {
    var tmp = (!(p1 == null) ? isInterface(p1, Applier) : false) ? p1 : THROW_CCE();
    var tmp_0 = p2 instanceof SlotWriter ? p2 : THROW_CCE();
    this.invoke_320(tmp, tmp_0, (!(p3 == null) ? isInterface(p3, RememberManager) : false) ? p3 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__171.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__172($insertTable, $anchor) {
    this._$insertTable = $insertTable;
    this._$anchor = $anchor;
  }
  _no_name_provided__172.prototype.invoke_320 = function (_anonymous_parameter_0_, slots, _anonymous_parameter_2_) {
    slots.beginInsert();
    slots.moveFrom(this._$insertTable, this._$anchor.toIndexFor(this._$insertTable));
    Unit_getInstance();
    slots.endInsert();
  };
  _no_name_provided__172.prototype.invoke_917 = function (p1, p2, p3) {
    var tmp = (!(p1 == null) ? isInterface(p1, Applier) : false) ? p1 : THROW_CCE();
    var tmp_0 = p2 instanceof SlotWriter ? p2 : THROW_CCE();
    this.invoke_320(tmp, tmp_0, (!(p3 == null) ? isInterface(p3, RememberManager) : false) ? p3 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__172.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__173($insertTable, $fixups, $anchor) {
    this._$insertTable_0 = $insertTable;
    this._$fixups = $fixups;
    this._$anchor_0 = $anchor;
  }
  _no_name_provided__173.prototype.invoke_320 = function (applier, slots, rememberManager) {
    var tmp0_let_0 = this._$insertTable_0.openWriter();
    try {
      var inductionVariable = 0;
      var last_2 = this._$fixups._get_size__52() - 1 | 0;
      if (inductionVariable <= last_2)
        do {
          var index_3_4 = inductionVariable;
          inductionVariable = inductionVariable + 1 | 0;
          var item_4_5 = this._$fixups.get_72(index_3_4);
          item_4_5(applier, tmp0_let_0, rememberManager);
        }
         while (inductionVariable <= last_2);
    }finally {
      tmp0_let_0.close_7();
    }
    slots.beginInsert();
    slots.moveFrom(this._$insertTable_0, this._$anchor_0.toIndexFor(this._$insertTable_0));
    Unit_getInstance();
    slots.endInsert();
  };
  _no_name_provided__173.prototype.invoke_917 = function (p1, p2, p3) {
    var tmp = (!(p1 == null) ? isInterface(p1, Applier) : false) ? p1 : THROW_CCE();
    var tmp_0 = p2 instanceof SlotWriter ? p2 : THROW_CCE();
    this.invoke_320(tmp, tmp_0, (!(p3 == null) ? isInterface(p3, RememberManager) : false) ? p3 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__173.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__174($anchor) {
    this._$anchor_1 = $anchor;
  }
  _no_name_provided__174.prototype.invoke_320 = function (_anonymous_parameter_0_, slots, _anonymous_parameter_2_) {
    slots.ensureStarted_0(this._$anchor_1);
  };
  _no_name_provided__174.prototype.invoke_917 = function (p1, p2, p3) {
    var tmp = (!(p1 == null) ? isInterface(p1, Applier) : false) ? p1 : THROW_CCE();
    var tmp_0 = p2 instanceof SlotWriter ? p2 : THROW_CCE();
    this.invoke_320(tmp, tmp_0, (!(p3 == null) ? isInterface(p3, RememberManager) : false) ? p3 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__174.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__175($removeIndex, $count) {
    this._$removeIndex = $removeIndex;
    this._$count_0 = $count;
  }
  _no_name_provided__175.prototype.invoke_320 = function (applier, _anonymous_parameter_1_, _anonymous_parameter_2_) {
    applier.remove_49(this._$removeIndex, this._$count_0);
  };
  _no_name_provided__175.prototype.invoke_917 = function (p1, p2, p3) {
    var tmp = (!(p1 == null) ? isInterface(p1, Applier) : false) ? p1 : THROW_CCE();
    var tmp_0 = p2 instanceof SlotWriter ? p2 : THROW_CCE();
    this.invoke_320(tmp, tmp_0, (!(p3 == null) ? isInterface(p3, RememberManager) : false) ? p3 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__175.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__176($from, $to, $count) {
    this._$from = $from;
    this._$to = $to;
    this._$count_1 = $count;
  }
  _no_name_provided__176.prototype.invoke_320 = function (applier, _anonymous_parameter_1_, _anonymous_parameter_2_) {
    applier.move_3(this._$from, this._$to, this._$count_1);
  };
  _no_name_provided__176.prototype.invoke_917 = function (p1, p2, p3) {
    var tmp = (!(p1 == null) ? isInterface(p1, Applier) : false) ? p1 : THROW_CCE();
    var tmp_0 = p2 instanceof SlotWriter ? p2 : THROW_CCE();
    this.invoke_320(tmp, tmp_0, (!(p3 == null) ? isInterface(p3, RememberManager) : false) ? p3 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__176.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__177() {
  }
  _no_name_provided__177.prototype.invoke_313 = function (a, b) {
    var tmp = a._location;
    return compareValues(tmp, b._location);
  };
  _no_name_provided__177.prototype.invoke_915 = function (p1, p2) {
    var tmp = p1 instanceof Invalidation ? p1 : THROW_CCE();
    return this.invoke_313(tmp, p2 instanceof Invalidation ? p2 : THROW_CCE());
  };
  _no_name_provided__177.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function ComposerImpl(applier, parentContext, slotTable, abandonSet, changes, composition) {
    this._applier = applier;
    this._parentContext_0 = parentContext;
    this._slotTable = slotTable;
    this._abandonSet = abandonSet;
    this._changes = changes;
    this._composition = composition;
    this._pendingStack = new Stack();
    this._pending = null;
    this._nodeIndex = 0;
    this._nodeIndexStack = new IntStack();
    this._groupNodeCount = 0;
    this._groupNodeCountStack = new IntStack();
    this._nodeCountOverrides = null;
    this._nodeCountVirtualOverrides = null;
    this._collectParameterInformation = false;
    this._nodeExpected = false;
    var tmp = this;
    tmp._invalidations = ArrayList_init_$Create$();
    this._pendingInvalidScopes = false;
    this._entersStack = new IntStack();
    this._parentProvider = persistentHashMapOf();
    this._providerUpdates = HashMap_init_$Create$();
    this._providersInvalid = false;
    this._providersInvalidStack = new IntStack();
    this._reusing = false;
    this._reusingGroup = -1;
    this._childrenComposing = 0;
    this._snapshot = currentSnapshot();
    this._invalidateStack = new Stack();
    this._isComposing = false;
    this._isDisposed = false;
    var tmp_0 = this;
    var tmp0_also_0 = this._slotTable.openReader();
    tmp0_also_0.close_7();
    tmp_0._reader = tmp0_also_0;
    this._insertTable = new SlotTable();
    var tmp_1 = this;
    var tmp0_also_0_0 = this._insertTable.openWriter();
    tmp0_also_0_0.close_7();
    tmp_1._writer = tmp0_also_0_0;
    this._hasProvider = false;
    var tmp_2 = this;
    var tmp1_read_0 = this._insertTable;
    var tmp0_let_0 = tmp1_read_0.openReader();
    var tmp_3;
    try {
      tmp_3 = tmp0_let_0.anchor_0(0);
    }finally {
      tmp0_let_0.close_7();
    }
    tmp_2._insertAnchor = tmp_3;
    var tmp_4 = this;
    tmp_4._insertFixups = ArrayList_init_$Create$();
    this._inserting = false;
    this._compoundKeyHash = 0;
    this._pendingUps = 0;
    this._downNodes = new Stack();
    this._writersReaderDelta = 0;
    this._startedGroup = false;
    this._startedGroups = new IntStack();
    this._insertUpFixups = new Stack();
    this._previousRemove = -1;
    this._previousMoveFrom = -1;
    this._previousMoveTo = -1;
    this._previousCount = 0;
  }
  ComposerImpl.prototype._get_applier__0 = function () {
    return this._applier;
  };
  ComposerImpl.prototype._get_areChildrenComposing_ = function () {
    return this._childrenComposing > 0;
  };
  ComposerImpl.prototype.startReplaceableGroup_0 = function (key_0) {
    return start(this, key_0, null, false, null);
  };
  ComposerImpl.prototype.endReplaceableGroup_0 = function () {
    return this.endGroup_1();
  };
  ComposerImpl.prototype.startDefaults_0 = function () {
    return start(this, 0, null, false, null);
  };
  ComposerImpl.prototype.endDefaults_0 = function () {
    this.endGroup_1();
    var scope = this._get_currentRecomposeScope_();
    if (!(scope == null) ? scope._used : false) {
      scope._defaultsInScope = true;
    }};
  ComposerImpl.prototype._get_defaultsInvalid__0 = function () {
    var tmp;
    if (this._providersInvalid) {
      tmp = true;
    } else {
      var tmp0_safe_receiver = this._get_currentRecomposeScope_();
      tmp = (tmp0_safe_receiver == null ? null : tmp0_safe_receiver._defaultsInvalid) === true;
    }
    return tmp;
  };
  ComposerImpl.prototype._get_inserting__0 = function () {
    return this._inserting;
  };
  ComposerImpl.prototype._get_skipping__0 = function () {
    var tmp;
    if ((!this._inserting ? !this._reusing : false) ? !this._providersInvalid : false) {
      var tmp0_safe_receiver = this._get_currentRecomposeScope_();
      tmp = (tmp0_safe_receiver == null ? null : tmp0_safe_receiver._requiresRecompose) === false;
    } else {
      tmp = false;
    }
    return tmp;
  };
  ComposerImpl.prototype.endGroup_1 = function () {
    return end(this, false);
  };
  ComposerImpl.prototype.startNode_2 = function () {
    var key_0 = this._inserting ? 125 : this._reusing ? this._reader._get_groupKey_() === 125 ? 126 : 125 : this._reader._get_groupKey_() === 126 ? 126 : 125;
    start(this, key_0, null, true, null);
    this._nodeExpected = true;
  };
  ComposerImpl.prototype.createNode_0 = function (factory) {
    validateNodeExpected(this);
    var tmp0_check_0 = this._inserting;
    if (!tmp0_check_0) {
      var message_1 = 'createNode() can only be called when inserting';
      throw IllegalStateException_init_$Create$_0(toString_2(message_1));
    }var insertIndex = this._nodeIndexStack.peek_0();
    var groupAnchor = this._writer.anchor_0(this._writer._parent_1);
    var tmp0_this = this;
    var tmp1 = tmp0_this._groupNodeCount;
    tmp0_this._groupNodeCount = tmp1 + 1 | 0;
    Unit_getInstance();
    recordFixup(this, _no_name_provided_$factory_140(factory, groupAnchor, insertIndex));
    recordInsertUpFixup(this, _no_name_provided_$factory_141(groupAnchor, insertIndex));
  };
  ComposerImpl.prototype.useNode_0 = function () {
    validateNodeExpected(this);
    var tmp0_check_0 = !this._inserting;
    if (!tmp0_check_0) {
      var message_1 = 'useNode() called while inserting';
      throw IllegalStateException_init_$Create$_0(toString_2(message_1));
    }recordDown(this, _get_node_(this._reader, this));
  };
  ComposerImpl.prototype.endNode_0 = function () {
    return end(this, true);
  };
  ComposerImpl.prototype.apply_0 = function (value_0, block) {
    var operation = _no_name_provided_$factory_142(block, value_0);
    if (this._inserting)
      recordFixup(this, operation);
    else
      recordApplierOperation(this, operation);
  };
  ComposerImpl.prototype.nextSlot = function () {
    var tmp;
    if (this._inserting) {
      validateNodeNotExpected(this);
      tmp = Companion_getInstance_25()._Empty;
    } else {
      var tmp0_let_0 = this._reader.next_46();
      tmp = this._reusing ? Companion_getInstance_25()._Empty : tmp0_let_0;
    }
    return tmp;
  };
  ComposerImpl.prototype.changed_0 = function (value_0) {
    var tmp;
    if (!equals_0(this.nextSlot(), value_0)) {
      this.updateValue(value_0);
      tmp = true;
    } else {
      tmp = false;
    }
    return tmp;
  };
  ComposerImpl.prototype.updateValue = function (value_0) {
    if (this._inserting) {
      this._writer.update_0(value_0);
      Unit_getInstance();
      if (!(value_0 == null) ? isInterface(value_0, RememberObserver) : false) {
        record(this, _no_name_provided_$factory_143(value_0));
      } else {
      }
    } else {
      var groupSlotIndex = this._reader._get_groupSlotIndex_() - 1 | 0;
      recordSlotTableOperation(this, true, _no_name_provided_$factory_144(value_0, this, groupSlotIndex));
    }
  };
  ComposerImpl.prototype.recordSideEffect_0 = function (effect) {
    record(this, _no_name_provided_$factory_145(effect));
  };
  ComposerImpl.prototype._get_currentRecomposeScope_ = function () {
    var tmp0_let_0 = this._invalidateStack;
    return (this._childrenComposing === 0 ? tmp0_let_0.isNotEmpty_0() : false) ? tmp0_let_0.peek_0() : null;
  };
  ComposerImpl.prototype.tryImminentInvalidation = function (scope) {
    var tmp0_elvis_lhs = scope._anchor;
    var tmp;
    if (tmp0_elvis_lhs == null) {
      return false;
    } else {
      tmp = tmp0_elvis_lhs;
    }
    var anchor = tmp;
    var location = anchor.toIndexFor(this._slotTable);
    if (this._isComposing ? location >= this._reader._currentGroup_0 : false) {
      insertIfMissing(this._invalidations, location, scope);
      return true;
    }return false;
  };
  ComposerImpl.prototype.skipCurrentGroup_0 = function () {
    if (this._invalidations.isEmpty_44()) {
      skipGroup(this);
    } else {
      var reader = this._reader;
      var key_0 = reader._get_groupKey_();
      var dataKey = reader._get_groupObjectKey_();
      var aux_0 = reader._get_groupAux_();
      updateCompoundKeyWhenWeEnterGroup(this, key_0, dataKey, aux_0);
      startReaderGroup(this, reader._get_isNode_(), null);
      recomposeToGroupEnd(this);
      reader.endGroup_1();
      updateCompoundKeyWhenWeExitGroup(this, key_0, dataKey, aux_0);
    }
  };
  ComposerImpl.prototype.skipToGroupEnd_2 = function () {
    var tmp0_check_0 = this._groupNodeCount === 0;
    if (!tmp0_check_0) {
      var message_1 = 'No nodes can be emitted before calling skipAndEndGroup';
      throw IllegalStateException_init_$Create$_0(toString_2(message_1));
    }var tmp0_safe_receiver = this._get_currentRecomposeScope_();
    if (tmp0_safe_receiver == null)
      null;
    else {
      tmp0_safe_receiver.scopeSkipped();
      Unit_getInstance();
    }
    Unit_getInstance();
    if (this._invalidations.isEmpty_44()) {
      skipReaderToGroupEnd(this);
    } else {
      recomposeToGroupEnd(this);
    }
  };
  ComposerImpl.prototype.startRestartGroup_0 = function (key_0) {
    start(this, key_0, null, false, null);
    addRecomposeScope(this);
    return this;
  };
  ComposerImpl.prototype.endRestartGroup_0 = function () {
    var scope = this._invalidateStack.isNotEmpty_0() ? this._invalidateStack.pop_0() : null;
    var tmp0_safe_receiver = scope;
    if (tmp0_safe_receiver == null) {
      Unit_getInstance();
    } else
      tmp0_safe_receiver._requiresRecompose = false;
    var tmp1_safe_receiver = scope;
    var tmp2_safe_receiver = tmp1_safe_receiver == null ? null : tmp1_safe_receiver.end_0(this._snapshot._get_id__26());
    if (tmp2_safe_receiver == null)
      null;
    else {
      record(this, _no_name_provided_$factory_148(tmp2_safe_receiver, this));
      Unit_getInstance();
    }
    Unit_getInstance();
    var tmp;
    if ((!(scope == null) ? !scope._skipped : false) ? scope._used ? true : this._collectParameterInformation : false) {
      if (scope._anchor == null) {
        var tmp_0 = scope;
        var tmp_1;
        if (this._inserting) {
          tmp_1 = this._writer.anchor_0(this._writer._parent_1);
        } else {
          tmp_1 = this._reader.anchor_0(this._reader._parent_2);
        }
        tmp_0._anchor = tmp_1;
      }scope._defaultsInvalid = false;
      tmp = scope;
    } else {
      tmp = null;
    }
    var result = tmp;
    end(this, false);
    return result;
  };
  ComposerImpl.prototype.sourceInformation_0 = function (sourceInformation_0) {
    if (this._inserting) {
      this._writer.insertAux(sourceInformation_0);
    }};
  ComposerImpl.prototype.prepareCompose_1 = function (block) {
    var tmp0_check_0 = !this._isComposing;
    if (!tmp0_check_0) {
      var message_1 = 'Preparing a composition while composing is not supported';
      throw IllegalStateException_init_$Create$_0(toString_2(message_1));
    }this._isComposing = true;
    try {
      block();
    }finally {
      this._isComposing = false;
    }
  };
  ComposerImpl.prototype.recompose = function (invalidationsRequested) {
    var tmp0_check_0 = this._changes.isEmpty_44();
    if (!tmp0_check_0) {
      var message_1 = 'Expected applyChanges() to have been called';
      throw IllegalStateException_init_$Create$_0(toString_2(message_1));
    }if (invalidationsRequested.isNotEmpty_0()) {
      doCompose$composable(this, invalidationsRequested, null);
      var tmp1_isNotEmpty_0 = this._changes;
      return !tmp1_isNotEmpty_0.isEmpty_44();
    }return false;
  };
  ComposerImpl.prototype._get_recomposeScope__0 = function () {
    return this._get_currentRecomposeScope_();
  };
  ComposerImpl.prototype.rememberedValue_0 = function () {
    return this.nextSlot();
  };
  ComposerImpl.prototype.updateRememberedValue_0 = function (value_0) {
    return this.updateValue(value_0);
  };
  ComposerImpl.prototype.recordUsed_0 = function (scope) {
    var tmp0_safe_receiver = scope instanceof RecomposeScopeImpl ? scope : null;
    if (tmp0_safe_receiver == null) {
      Unit_getInstance();
    } else
      tmp0_safe_receiver._used = true;
  };
  ComposerImpl.prototype.composeContent$composable = function (invalidationsRequested, content) {
    var tmp0_check_0 = this._changes.isEmpty_44();
    if (!tmp0_check_0) {
      var message_1 = 'Expected applyChanges() to have been called';
      throw IllegalStateException_init_$Create$_0(toString_2(message_1));
    }doCompose$composable(this, invalidationsRequested, content);
  };
  ComposerImpl.$metadata$ = {
    simpleName: 'ComposerImpl',
    kind: 'class',
    interfaces: [Composer]
  };
  function _no_name_provided__178() {
  }
  _no_name_provided__178.prototype.toString = function () {
    return 'Empty';
  };
  _no_name_provided__178.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function Companion_26() {
    Companion_instance_25 = this;
    var tmp = this;
    tmp._Empty = new _no_name_provided__178();
  }
  Companion_26.$metadata$ = {
    simpleName: 'Companion',
    kind: 'object',
    interfaces: []
  };
  var Companion_instance_25;
  function Companion_getInstance_25() {
    if (Companion_instance_25 == null)
      new Companion_26();
    return Companion_instance_25;
  }
  function Composer() {
  }
  Composer.$metadata$ = {
    simpleName: 'Composer',
    kind: 'interface',
    interfaces: []
  };
  var InvalidationResult_IGNORED_instance;
  var InvalidationResult_SCHEDULED_instance;
  var InvalidationResult_DEFERRED_instance;
  var InvalidationResult_IMMINENT_instance;
  var InvalidationResult_entriesInitialized;
  function InvalidationResult_initEntries() {
    if (InvalidationResult_entriesInitialized)
      return Unit_getInstance();
    InvalidationResult_entriesInitialized = true;
    InvalidationResult_IGNORED_instance = new InvalidationResult('IGNORED', 0);
    InvalidationResult_SCHEDULED_instance = new InvalidationResult('SCHEDULED', 1);
    InvalidationResult_DEFERRED_instance = new InvalidationResult('DEFERRED', 2);
    InvalidationResult_IMMINENT_instance = new InvalidationResult('IMMINENT', 3);
  }
  function InvalidationResult(name, ordinal) {
    Enum.call(this, name, ordinal);
  }
  InvalidationResult.$metadata$ = {
    simpleName: 'InvalidationResult',
    kind: 'class',
    interfaces: []
  };
  function sourceInformation(composer, sourceInformation_0) {
    composer.sourceInformation_0(sourceInformation_0);
  }
  function ScopeUpdateScope() {
  }
  ScopeUpdateScope.$metadata$ = {
    simpleName: 'ScopeUpdateScope',
    kind: 'interface',
    interfaces: []
  };
  function _no_name_provided__179(this$0) {
    this._this$0_26 = this$0;
  }
  _no_name_provided__179.prototype.invoke_523 = function () {
    var tmp0_also_0 = multiMap();
    var inductionVariable = 0;
    var last_2 = this._this$0_26._keyInfos._get_size__52();
    if (inductionVariable < last_2)
      do {
        var index_3 = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        var keyInfo_4 = this._this$0_26._keyInfos.get_72(index_3);
        put(tmp0_also_0, _get_joinedKey_(keyInfo_4), keyInfo_4);
        Unit_getInstance();
      }
       while (inductionVariable < last_2);
    return tmp0_also_0;
  };
  _no_name_provided__179.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function Pending(keyInfos, startIndex) {
    this._keyInfos = keyInfos;
    this._startIndex_1 = startIndex;
    this._groupIndex = 0;
    var tmp0_require_0 = this._startIndex_1 >= 0;
    if (!tmp0_require_0) {
      var message_1 = 'Invalid start index';
      throw IllegalArgumentException_init_$Create$_0(toString_2(message_1));
    }var tmp = this;
    tmp._usedKeys = ArrayList_init_$Create$();
    var tmp_0 = this;
    var runningNodeIndex_2 = 0;
    var result_3 = HashMap_init_$Create$();
    var inductionVariable = 0;
    var last_2 = this._keyInfos._get_size__52();
    if (inductionVariable < last_2)
      do {
        var index_5 = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        var keyInfo_6 = this._keyInfos.get_72(index_5);
        var tmp0_set_0_7 = keyInfo_6._location_1;
        var tmp1_set_0_8 = new GroupInfo(index_5, runningNodeIndex_2, keyInfo_6._nodes);
        result_3.put_6(tmp0_set_0_7, tmp1_set_0_8);
        Unit_getInstance();
        runningNodeIndex_2 = runningNodeIndex_2 + keyInfo_6._nodes | 0;
      }
       while (inductionVariable < last_2);
    tmp_0._groupInfos = result_3;
    var tmp_1 = this;
    tmp_1._keyMap$delegate = lazy(_no_name_provided_$factory_158(this));
  }
  Pending.prototype._get_keyMap_ = function () {
    var tmp0_getValue_0 = this._keyMap$delegate;
    var tmp1_getValue_0 = keyMap$factory();
    return tmp0_getValue_0._get_value__33();
  };
  Pending.prototype.getNext = function (key_0, dataKey) {
    var joinedKey = !(dataKey == null) ? new JoinedKey(key_0, dataKey) : key_0;
    return pop_0(this._get_keyMap_(), joinedKey);
  };
  Pending.prototype.recordUsed_1 = function (keyInfo) {
    return this._usedKeys.add_39(keyInfo);
  };
  Pending.prototype._get_used_ = function () {
    return this._usedKeys;
  };
  Pending.prototype.registerMoveSlot = function (from, to_0) {
    if (from > to_0) {
      var tmp0_forEach_0 = this._groupInfos._get_values__6();
      var tmp0_iterator_1 = tmp0_forEach_0.iterator_66();
      while (tmp0_iterator_1.hasNext_41()) {
        var element_2 = tmp0_iterator_1.next_46();
        var position_4 = element_2._slotIndex;
        if (position_4 === from)
          element_2._slotIndex = to_0;
        else if (to_0 <= position_4 ? position_4 < from : false)
          element_2._slotIndex = position_4 + 1 | 0;
      }
    } else if (to_0 > from) {
      var tmp1_forEach_0 = this._groupInfos._get_values__6();
      var tmp0_iterator_1_0 = tmp1_forEach_0.iterator_66();
      while (tmp0_iterator_1_0.hasNext_41()) {
        var element_2_0 = tmp0_iterator_1_0.next_46();
        var position_4_0 = element_2_0._slotIndex;
        if (position_4_0 === from)
          element_2_0._slotIndex = to_0;
        else if ((from + 1 | 0) <= position_4_0 ? position_4_0 < to_0 : false)
          element_2_0._slotIndex = position_4_0 - 1 | 0;
      }
    }};
  Pending.prototype.registerMoveNode = function (from, to_0, count_0) {
    if (from > to_0) {
      var tmp0_forEach_0 = this._groupInfos._get_values__6();
      var tmp0_iterator_1 = tmp0_forEach_0.iterator_66();
      while (tmp0_iterator_1.hasNext_41()) {
        var element_2 = tmp0_iterator_1.next_46();
        var position_4 = element_2._nodeIndex_0;
        if (from <= position_4 ? position_4 < (from + count_0 | 0) : false)
          element_2._nodeIndex_0 = to_0 + (position_4 - from | 0) | 0;
        else if (to_0 <= position_4 ? position_4 < from : false)
          element_2._nodeIndex_0 = position_4 + count_0 | 0;
      }
    } else if (to_0 > from) {
      var tmp1_forEach_0 = this._groupInfos._get_values__6();
      var tmp0_iterator_1_0 = tmp1_forEach_0.iterator_66();
      while (tmp0_iterator_1_0.hasNext_41()) {
        var element_2_0 = tmp0_iterator_1_0.next_46();
        var position_4_0 = element_2_0._nodeIndex_0;
        if (from <= position_4_0 ? position_4_0 < (from + count_0 | 0) : false)
          element_2_0._nodeIndex_0 = to_0 + (position_4_0 - from | 0) | 0;
        else if ((from + 1 | 0) <= position_4_0 ? position_4_0 < to_0 : false)
          element_2_0._nodeIndex_0 = position_4_0 - count_0 | 0;
      }
    }};
  Pending.prototype.registerInsert = function (keyInfo, insertIndex) {
    var tmp0_set_0 = this._groupInfos;
    var tmp1_set_0 = keyInfo._location_1;
    var tmp2_set_0 = new GroupInfo(-1, insertIndex, 0);
    tmp0_set_0.put_6(tmp1_set_0, tmp2_set_0);
    Unit_getInstance();
  };
  Pending.prototype.updateNodeCount = function (group, newCount) {
    var groupInfo_0 = this._groupInfos.get_56(group);
    if (!(groupInfo_0 == null)) {
      var index = groupInfo_0._nodeIndex_0;
      var difference = newCount - groupInfo_0._nodeCount | 0;
      groupInfo_0._nodeCount = newCount;
      if (!(difference === 0)) {
        var tmp0_forEach_0 = this._groupInfos._get_values__6();
        var tmp0_iterator_1 = tmp0_forEach_0.iterator_66();
        while (tmp0_iterator_1.hasNext_41()) {
          var element_2 = tmp0_iterator_1.next_46();
          if (element_2._nodeIndex_0 >= index ? !equals_0(element_2, groupInfo_0) : false) {
            var tmp0_this_4 = element_2;
            tmp0_this_4._nodeIndex_0 = tmp0_this_4._nodeIndex_0 + difference | 0;
          }}
      }return true;
    }return false;
  };
  Pending.prototype.slotPositionOf = function (keyInfo) {
    var tmp0_safe_receiver = this._groupInfos.get_56(keyInfo._location_1);
    var tmp1_elvis_lhs = tmp0_safe_receiver == null ? null : tmp0_safe_receiver._slotIndex;
    return tmp1_elvis_lhs == null ? -1 : tmp1_elvis_lhs;
  };
  Pending.prototype.nodePositionOf = function (keyInfo) {
    var tmp0_safe_receiver = this._groupInfos.get_56(keyInfo._location_1);
    var tmp1_elvis_lhs = tmp0_safe_receiver == null ? null : tmp0_safe_receiver._nodeIndex_0;
    return tmp1_elvis_lhs == null ? -1 : tmp1_elvis_lhs;
  };
  Pending.prototype.updatedNodeCountOf = function (keyInfo) {
    var tmp0_safe_receiver = this._groupInfos.get_56(keyInfo._location_1);
    var tmp1_elvis_lhs = tmp0_safe_receiver == null ? null : tmp0_safe_receiver._nodeCount;
    return tmp1_elvis_lhs == null ? keyInfo._nodes : tmp1_elvis_lhs;
  };
  Pending.$metadata$ = {
    simpleName: 'Pending',
    kind: 'class',
    interfaces: []
  };
  function Invalidation(scope, location) {
    this._scope = scope;
    this._location = location;
  }
  Invalidation.$metadata$ = {
    simpleName: 'Invalidation',
    kind: 'class',
    interfaces: []
  };
  function asInt(_this_) {
    return _this_ ? 1 : 0;
  }
  function contains_6(_this_, key_0) {
    return _this_.containsKey_7(key_0 instanceof CompositionLocal ? key_0 : THROW_CCE());
  }
  function getValueOf(_this_, key_0) {
    var tmp0_safe_receiver = _this_.get_56(key_0 instanceof CompositionLocal ? key_0 : THROW_CCE());
    var tmp = tmp0_safe_receiver == null ? null : tmp0_safe_receiver._get_value__33();
    return (tmp == null ? true : isObject(tmp)) ? tmp : THROW_CCE();
  }
  function removeRange(_this_, start_0, end_0) {
    var tmp0_let_0 = findLocation(_this_, start_0);
    var index = tmp0_let_0 < 0 ? -(tmp0_let_0 + 1 | 0) | 0 : tmp0_let_0;
    $l$break: while (index < _this_._get_size__52()) {
      var validation = _this_.get_72(index);
      if (validation._location < end_0) {
        _this_.removeAt_2(index);
        Unit_getInstance();
      } else
        break $l$break;
    }
  }
  function firstInRange(_this_, start_0, end_0) {
    var tmp0_let_0 = findLocation(_this_, start_0);
    var index = tmp0_let_0 < 0 ? -(tmp0_let_0 + 1 | 0) | 0 : tmp0_let_0;
    if (index < _this_._get_size__52()) {
      var firstInvalidation = _this_.get_72(index);
      if (firstInvalidation._location < end_0)
        return firstInvalidation;
    }return null;
  }
  function removeLocation(_this_, location) {
    var index = findLocation(_this_, location);
    return index >= 0 ? _this_.removeAt_2(index) : null;
  }
  function nearestCommonRootOf(_this_, a, b, common) {
    if (a === b)
      return a;
    if (a === common ? true : b === common)
      return common;
    if (_this_.parent_1(a) === b)
      return b;
    if (_this_.parent_1(b) === a)
      return a;
    if (_this_.parent_1(a) === _this_.parent_1(b))
      return _this_.parent_1(a);
    var currentA = a;
    var currentB = b;
    var aDistance = distanceFrom(_this_, a, common);
    var bDistance = distanceFrom(_this_, b, common);
    var tmp0_repeat_0 = aDistance - bDistance | 0;
    var inductionVariable = 0;
    if (inductionVariable < tmp0_repeat_0)
      do {
        var index_2 = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        currentA = _this_.parent_1(currentA);
      }
       while (inductionVariable < tmp0_repeat_0);
    var tmp1_repeat_0 = bDistance - aDistance | 0;
    var inductionVariable_0 = 0;
    if (inductionVariable_0 < tmp1_repeat_0)
      do {
        var index_2_0 = inductionVariable_0;
        inductionVariable_0 = inductionVariable_0 + 1 | 0;
        currentB = _this_.parent_1(currentB);
      }
       while (inductionVariable_0 < tmp1_repeat_0);
    while (!(currentA === currentB)) {
      currentA = _this_.parent_1(currentA);
      currentB = _this_.parent_1(currentB);
    }
    return currentA;
  }
  function insertIfMissing(_this_, location, scope) {
    var index = findLocation(_this_, location);
    if (index < 0) {
      _this_.add_21(-(index + 1 | 0) | 0, new Invalidation(scope, location));
    }}
  function invokeComposable$composable(composer, composable) {
    var realFn = typeof composable === 'function' ? composable : THROW_CCE();
    realFn(composer, 1);
  }
  function GroupInfo(slotIndex_0, nodeIndex_1, nodeCount_0) {
    this._slotIndex = slotIndex_0;
    this._nodeIndex_0 = nodeIndex_1;
    this._nodeCount = nodeCount_0;
  }
  GroupInfo.$metadata$ = {
    simpleName: 'GroupInfo',
    kind: 'class',
    interfaces: []
  };
  function put(_this_, key_0, value_0) {
    var value_1 = _this_.get_56(key_0);
    var tmp;
    if (value_1 == null) {
      var answer_2 = LinkedHashSet_init_$Create$();
      _this_.put_6(key_0, answer_2);
      Unit_getInstance();
      tmp = answer_2;
    } else {
      tmp = value_1;
    }
    return tmp.add_39(value_0);
  }
  function _get_joinedKey_(_this_) {
    return !(_this_._objectKey == null) ? new JoinedKey(_this_._key_2, _this_._objectKey) : _this_._key_2;
  }
  function multiMap() {
    return HashMap_init_$Create$();
  }
  function pop_0(_this_, key_0) {
    var tmp0_safe_receiver = _this_.get_56(key_0);
    var tmp1_safe_receiver = tmp0_safe_receiver == null ? null : firstOrNull_0(tmp0_safe_receiver);
    var tmp;
    if (tmp1_safe_receiver == null) {
      tmp = null;
    } else {
      remove_0(_this_, key_0, tmp1_safe_receiver);
      Unit_getInstance();
      tmp = tmp1_safe_receiver;
    }
    return tmp;
  }
  function findLocation(_this_, location) {
    var low = 0;
    var high = _this_._get_size__52() - 1 | 0;
    while (low <= high) {
      var mid = (low + high | 0) >>> 1;
      var midVal = _this_.get_72(mid);
      var cmp = compareTo(midVal._location, location);
      if (cmp < 0)
        low = mid + 1 | 0;
      else if (cmp > 0)
        high = mid - 1 | 0;
      else
        return mid;
    }
    return -(low + 1 | 0) | 0;
  }
  function distanceFrom(_this_, index, root) {
    var count_0 = 0;
    var current_0 = index;
    while (current_0 > 0 ? !(current_0 === root) : false) {
      current_0 = _this_.parent_1(current_0);
      var tmp0 = count_0;
      count_0 = tmp0 + 1 | 0;
      Unit_getInstance();
    }
    return count_0;
  }
  function remove_0(_this_, key_0, value_0) {
    var tmp0_safe_receiver = _this_.get_56(key_0);
    var tmp;
    if (tmp0_safe_receiver == null) {
      tmp = null;
    } else {
      tmp0_safe_receiver.remove_46(value_0);
      Unit_getInstance();
      if (tmp0_safe_receiver.isEmpty_44()) {
        _this_.remove_44(key_0);
        Unit_getInstance();
      }tmp = Unit_getInstance();
    }
    return tmp;
  }
  function _Updater___init__impl_(composer) {
    return composer;
  }
  function _Updater___get_composer__impl_(this_0) {
    return this_0;
  }
  function Updater__set_impl(this_0, value_0, block) {
    var tmp0_with_0 = _Updater___get_composer__impl_(this_0);
    var tmp;
    if (tmp0_with_0._get_inserting__0() ? true : !equals_0(tmp0_with_0.rememberedValue_0(), value_0)) {
      tmp0_with_0.updateRememberedValue_0(value_0);
      tmp = _Updater___get_composer__impl_(this_0).apply_0(value_0, block);
    }return tmp;
  }
  function _SkippableUpdater___init__impl_(composer) {
    return composer;
  }
  function _SkippableUpdater___get_composer__impl_(this_0) {
    return this_0;
  }
  function SkippableUpdater__toString_impl(this_0) {
    return '' + 'SkippableUpdater(composer=' + this_0 + ')';
  }
  function SkippableUpdater__hashCode_impl(this_0) {
    return hashCode(this_0);
  }
  function SkippableUpdater__equals_impl(this_0, other) {
    if (!(other instanceof SkippableUpdater))
      return false;
    else {
    }
    var tmp0_other_with_cast = other instanceof SkippableUpdater ? other._composer : THROW_CCE();
    if (!equals_0(this_0, tmp0_other_with_cast))
      return false;
    return true;
  }
  function SkippableUpdater(composer) {
    this._composer = composer;
  }
  SkippableUpdater.prototype.toString = function () {
    return SkippableUpdater__toString_impl(this._composer);
  };
  SkippableUpdater.prototype.hashCode = function () {
    return SkippableUpdater__hashCode_impl(this._composer);
  };
  SkippableUpdater.prototype.equals = function (other) {
    return SkippableUpdater__equals_impl(this._composer, other);
  };
  SkippableUpdater.$metadata$ = {
    simpleName: 'SkippableUpdater',
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__180() {
  }
  _no_name_provided__180.prototype.invoke_320 = function (_anonymous_parameter_0_, slots, rememberManager) {
    removeCurrentGroup(slots, rememberManager);
  };
  _no_name_provided__180.prototype.invoke_917 = function (p1, p2, p3) {
    var tmp = (!(p1 == null) ? isInterface(p1, Applier) : false) ? p1 : THROW_CCE();
    var tmp_0 = p2 instanceof SlotWriter ? p2 : THROW_CCE();
    this.invoke_320(tmp, tmp_0, (!(p3 == null) ? isInterface(p3, RememberManager) : false) ? p3 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__180.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__181() {
  }
  _no_name_provided__181.prototype.invoke_320 = function (_anonymous_parameter_0_, slots, _anonymous_parameter_2_) {
    slots.endGroup_0();
    Unit_getInstance();
  };
  _no_name_provided__181.prototype.invoke_917 = function (p1, p2, p3) {
    var tmp = (!(p1 == null) ? isInterface(p1, Applier) : false) ? p1 : THROW_CCE();
    var tmp_0 = p2 instanceof SlotWriter ? p2 : THROW_CCE();
    this.invoke_320(tmp, tmp_0, (!(p3 == null) ? isInterface(p3, RememberManager) : false) ? p3 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__181.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__182() {
  }
  _no_name_provided__182.prototype.invoke_320 = function (_anonymous_parameter_0_, slots, _anonymous_parameter_2_) {
    slots.ensureStarted(0);
  };
  _no_name_provided__182.prototype.invoke_917 = function (p1, p2, p3) {
    var tmp = (!(p1 == null) ? isInterface(p1, Applier) : false) ? p1 : THROW_CCE();
    var tmp_0 = p2 instanceof SlotWriter ? p2 : THROW_CCE();
    this.invoke_320(tmp, tmp_0, (!(p3 == null) ? isInterface(p3, RememberManager) : false) ? p3 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__182.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function InvalidationResult_IGNORED_getInstance() {
    InvalidationResult_initEntries();
    return InvalidationResult_IGNORED_instance;
  }
  function InvalidationResult_SCHEDULED_getInstance() {
    InvalidationResult_initEntries();
    return InvalidationResult_SCHEDULED_instance;
  }
  function InvalidationResult_DEFERRED_getInstance() {
    InvalidationResult_initEntries();
    return InvalidationResult_DEFERRED_instance;
  }
  function InvalidationResult_IMMINENT_getInstance() {
    InvalidationResult_initEntries();
    return InvalidationResult_IMMINENT_instance;
  }
  function keyMap$factory() {
    return getPropertyCallableRef('keyMap', 1, KProperty1, function (receiver) {
      return receiver._get_keyMap_();
    }, null);
  }
  function _no_name_provided_$factory_137() {
    var i = new _no_name_provided__180();
    return function (p1, p2, p3) {
      i.invoke_320(p1, p2, p3);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_138() {
    var i = new _no_name_provided__181();
    return function (p1, p2, p3) {
      i.invoke_320(p1, p2, p3);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_139() {
    var i = new _no_name_provided__182();
    return function (p1, p2, p3) {
      i.invoke_320(p1, p2, p3);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_140($factory, $groupAnchor, $insertIndex) {
    var i = new _no_name_provided__160($factory, $groupAnchor, $insertIndex);
    return function (p1, p2, p3) {
      i.invoke_320(p1, p2, p3);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_141($groupAnchor, $insertIndex) {
    var i = new _no_name_provided__161($groupAnchor, $insertIndex);
    return function (p1, p2, p3) {
      i.invoke_320(p1, p2, p3);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_142($block, $value) {
    var i = new _no_name_provided__162($block, $value);
    return function (p1, p2, p3) {
      i.invoke_320(p1, p2, p3);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_143($value) {
    var i = new _no_name_provided__163($value);
    return function (p1, p2, p3) {
      i.invoke_320(p1, p2, p3);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_144($value, this$0, $groupSlotIndex) {
    var i = new _no_name_provided__164($value, this$0, $groupSlotIndex);
    return function (p1, p2, p3) {
      i.invoke_320(p1, p2, p3);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_145($effect) {
    var i = new _no_name_provided__165($effect);
    return function (p1, p2, p3) {
      i.invoke_320(p1, p2, p3);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_146($data) {
    var i = new _no_name_provided__166($data);
    return function (p1, p2, p3) {
      i.invoke_320(p1, p2, p3);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_147($currentRelativePosition) {
    var i = new _no_name_provided__167($currentRelativePosition);
    return function (p1, p2, p3) {
      i.invoke_320(p1, p2, p3);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_148($tmp2_safe_receiver, this$0) {
    var i = new _no_name_provided__168($tmp2_safe_receiver, this$0);
    return function (p1, p2, p3) {
      i.invoke_320(p1, p2, p3);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_149($count) {
    var i = new _no_name_provided__169($count);
    return function (p1, p2, p3) {
      i.invoke_320(p1, p2, p3);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_150($nodes) {
    var i = new _no_name_provided__170($nodes);
    return function (p1, p2, p3) {
      i.invoke_320(p1, p2, p3);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_151($distance) {
    var i = new _no_name_provided__171($distance);
    return function (p1, p2, p3) {
      i.invoke_320(p1, p2, p3);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_152($insertTable, $anchor) {
    var i = new _no_name_provided__172($insertTable, $anchor);
    return function (p1, p2, p3) {
      i.invoke_320(p1, p2, p3);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_153($insertTable, $fixups, $anchor) {
    var i = new _no_name_provided__173($insertTable, $fixups, $anchor);
    return function (p1, p2, p3) {
      i.invoke_320(p1, p2, p3);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_154($anchor) {
    var i = new _no_name_provided__174($anchor);
    return function (p1, p2, p3) {
      i.invoke_320(p1, p2, p3);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_155($removeIndex, $count) {
    var i = new _no_name_provided__175($removeIndex, $count);
    return function (p1, p2, p3) {
      i.invoke_320(p1, p2, p3);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_156($from, $to, $count) {
    var i = new _no_name_provided__176($from, $to, $count);
    return function (p1, p2, p3) {
      i.invoke_320(p1, p2, p3);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_157() {
    var i = new _no_name_provided__177();
    return function (p1, p2) {
      return i.invoke_313(p1, p2);
    };
  }
  function _no_name_provided_$factory_158(this$0) {
    var i = new _no_name_provided__179(this$0);
    return function () {
      return i.invoke_523();
    };
  }
  function removeCurrentGroupInstance$init$() {
    return _no_name_provided_$factory_137();
  }
  function endGroupInstance$init$() {
    return _no_name_provided_$factory_138();
  }
  function startRootGroup$init$() {
    return _no_name_provided_$factory_139();
  }
  var PendingApplyNoModifications;
  function CompositionImpl_init_$Init$(parent_0, applier, recomposeContext, $mask0, $marker, $this) {
    if (!(($mask0 & 4) === 0))
      recomposeContext = null;
    CompositionImpl.call($this, parent_0, applier, recomposeContext);
    return $this;
  }
  function CompositionImpl_init_$Create$(parent_0, applier, recomposeContext, $mask0, $marker) {
    return CompositionImpl_init_$Init$(parent_0, applier, recomposeContext, $mask0, $marker, Object.create(CompositionImpl.prototype));
  }
  function _get_areChildrenComposing_($this) {
    return $this._composer_0._get_areChildrenComposing_();
  }
  function drainPendingModificationsForCompositionLocked($this) {
    var toRecord = $this._pendingModifications.getAndSet(PendingApplyNoModifications);
    if (toRecord == null) {
    } else {
      if (equals_0(toRecord, PendingApplyNoModifications)) {
        var tmp0_error_0 = 'pending composition has not been applied';
        throw IllegalStateException_init_$Create$_0(toString_2(tmp0_error_0));
      } else {
        if (!(toRecord == null) ? isInterface(toRecord, Set) : false) {
          addPendingInvalidationsLocked($this, (!(toRecord == null) ? isInterface(toRecord, Set) : false) ? toRecord : THROW_CCE());
        } else {
          if (!(toRecord == null) ? isArray(toRecord) : false) {
            var indexedObject = (!(toRecord == null) ? isArray(toRecord) : false) ? toRecord : THROW_CCE();
            var inductionVariable = 0;
            var last_2 = indexedObject.length;
            while (inductionVariable < last_2) {
              var changed = indexedObject[inductionVariable];
              inductionVariable = inductionVariable + 1 | 0;
              addPendingInvalidationsLocked($this, changed);
            }
          } else {
            {
              var tmp1_error_0 = '' + 'corrupt pendingModifications drain: ' + $this._pendingModifications;
              throw IllegalStateException_init_$Create$_0(toString_2(tmp1_error_0));
            }
          }
        }
      }
    }
  }
  function drainPendingModificationsLocked($this) {
    var toRecord = $this._pendingModifications.getAndSet(null);
    if (equals_0(toRecord, PendingApplyNoModifications)) {
    } else {
      if (!(toRecord == null) ? isInterface(toRecord, Set) : false) {
        addPendingInvalidationsLocked($this, (!(toRecord == null) ? isInterface(toRecord, Set) : false) ? toRecord : THROW_CCE());
      } else {
        if (!(toRecord == null) ? isArray(toRecord) : false) {
          var indexedObject = (!(toRecord == null) ? isArray(toRecord) : false) ? toRecord : THROW_CCE();
          var inductionVariable = 0;
          var last_2 = indexedObject.length;
          while (inductionVariable < last_2) {
            var changed = indexedObject[inductionVariable];
            inductionVariable = inductionVariable + 1 | 0;
            addPendingInvalidationsLocked($this, changed);
          }
        } else {
          if (toRecord == null) {
            var tmp0_error_0 = 'calling recordModificationsOf and applyChanges concurrently is not supported';
            throw IllegalStateException_init_$Create$_0(toString_2(tmp0_error_0));
          } else {
            {
              var tmp1_error_0 = '' + 'corrupt pendingModifications drain: ' + $this._pendingModifications;
              throw IllegalStateException_init_$Create$_0(toString_2(tmp1_error_0));
            }
          }
        }
      }
    }
  }
  function addPendingInvalidationsLocked($this, values_0) {
    var invalidated = null;
    var tmp0_iterator = values_0.iterator_66();
    while (tmp0_iterator.hasNext_41()) {
      var value_0 = tmp0_iterator.next_46();
      if (value_0 instanceof RecomposeScopeImpl) {
        value_0.invalidateForResult();
        Unit_getInstance();
      } else {
        {
          var tmp1_forEachScopeOf_0 = $this._observations;
          var index_1 = find_1(tmp1_forEachScopeOf_0, value_0);
          if (index_1 >= 0) {
            var tmp0_forEach_0 = scopeSetAt(tmp1_forEachScopeOf_0, index_1);
            var tmp0_iterator_1 = tmp0_forEach_0.iterator_66();
            while (tmp0_iterator_1.hasNext_41()) {
              var element_2 = tmp0_iterator_1.next_46();
              if (!$this._observationsProcessed.remove_42(value_0, element_2) ? !element_2.invalidateForResult().equals(InvalidationResult_IGNORED_getInstance()) : false) {
                var tmp0_elvis_lhs_3 = invalidated;
                var tmp;
                if (tmp0_elvis_lhs_3 == null) {
                  var tmp0_also_0_4 = HashSet_init_$Create$();
                  invalidated = tmp0_also_0_4;
                  tmp = tmp0_also_0_4;
                } else {
                  tmp = tmp0_elvis_lhs_3;
                }
                tmp.add_39(element_2);
                Unit_getInstance();
              }}
          }}
      }
    }
    var tmp1_safe_receiver = invalidated;
    if (tmp1_safe_receiver == null)
      null;
    else {
      var tmp0_removeValueIf_0_2 = $this._observations;
      var destinationIndex_1_3 = 0;
      var inductionVariable = 0;
      var last_2 = tmp0_removeValueIf_0_2._size_3;
      if (inductionVariable < last_2)
        do {
          var i_3_5 = inductionVariable;
          inductionVariable = inductionVariable + 1 | 0;
          var valueIndex_4_6 = tmp0_removeValueIf_0_2._valueOrder[i_3_5];
          var set_5_7 = ensureNotNull(tmp0_removeValueIf_0_2._scopeSets[valueIndex_4_6]);
          var destinationIndex_1_8 = 0;
          var inductionVariable_0 = 0;
          var last_3 = set_5_7._size_2;
          if (inductionVariable_0 < last_3)
            do {
              var i_3_10 = inductionVariable_0;
              inductionVariable_0 = inductionVariable_0 + 1 | 0;
              var tmp_0 = set_5_7._values_1[i_3_10];
              var item_4_11 = isObject(tmp_0) ? tmp_0 : THROW_CCE();
              if (!tmp1_safe_receiver.contains_51(item_4_11)) {
                if (!(destinationIndex_1_8 === i_3_10)) {
                  set_5_7._values_1[destinationIndex_1_8] = item_4_11;
                }var tmp1_5_12 = destinationIndex_1_8;
                destinationIndex_1_8 = tmp1_5_12 + 1 | 0;
                Unit_getInstance();
              } else {
              }
            }
             while (inductionVariable_0 < last_3);
          var inductionVariable_1 = destinationIndex_1_8;
          var last_4 = set_5_7._size_2;
          if (inductionVariable_1 < last_4)
            do {
              var i_7_14 = inductionVariable_1;
              inductionVariable_1 = inductionVariable_1 + 1 | 0;
              set_5_7._values_1[i_7_14] = null;
            }
             while (inductionVariable_1 < last_4);
          set_5_7._size_2 = destinationIndex_1_8;
          if (set_5_7._size_2 > 0) {
            if (!(destinationIndex_1_3 === i_3_5)) {
              var destinationKeyOrder_6_15 = tmp0_removeValueIf_0_2._valueOrder[destinationIndex_1_3];
              tmp0_removeValueIf_0_2._valueOrder[destinationIndex_1_3] = valueIndex_4_6;
              tmp0_removeValueIf_0_2._valueOrder[i_3_5] = destinationKeyOrder_6_15;
            }var tmp1_7_16 = destinationIndex_1_3;
            destinationIndex_1_3 = tmp1_7_16 + 1 | 0;
            Unit_getInstance();
          }}
         while (inductionVariable < last_2);
      var inductionVariable_2 = destinationIndex_1_3;
      var last_5 = tmp0_removeValueIf_0_2._size_3;
      if (inductionVariable_2 < last_5)
        do {
          var i_9_18 = inductionVariable_2;
          inductionVariable_2 = inductionVariable_2 + 1 | 0;
          tmp0_removeValueIf_0_2._values_2[tmp0_removeValueIf_0_2._valueOrder[i_9_18]] = null;
        }
         while (inductionVariable_2 < last_5);
      tmp0_removeValueIf_0_2._size_3 = destinationIndex_1_3;
      Unit_getInstance();
    }
    Unit_getInstance();
  }
  function takeInvalidations($this) {
    var tmp0_also_0 = $this._invalidations_0;
    $this._invalidations_0 = new IdentityArraySet();
    return tmp0_also_0;
  }
  function RememberEventDispatcher(abandoning) {
    this._abandoning = abandoning;
    var tmp = this;
    tmp._remembering = ArrayList_init_$Create$();
    var tmp_0 = this;
    tmp_0._forgetting = ArrayList_init_$Create$();
    var tmp_1 = this;
    tmp_1._sideEffects = ArrayList_init_$Create$();
  }
  RememberEventDispatcher.prototype.remembering_0 = function (instance) {
    var tmp0_let_0 = this._forgetting.lastIndexOf_2(instance);
    if (tmp0_let_0 >= 0) {
      this._forgetting.removeAt_2(tmp0_let_0);
      Unit_getInstance();
      this._abandoning.remove_46(instance);
    } else {
      this._remembering.add_39(instance);
    }
    Unit_getInstance();
  };
  RememberEventDispatcher.prototype.forgetting_0 = function (instance) {
    var tmp0_let_0 = this._remembering.lastIndexOf_2(instance);
    if (tmp0_let_0 >= 0) {
      this._remembering.removeAt_2(tmp0_let_0);
      Unit_getInstance();
      this._abandoning.remove_46(instance);
    } else {
      this._forgetting.add_39(instance);
    }
    Unit_getInstance();
  };
  RememberEventDispatcher.prototype.sideEffect_0 = function (effect) {
    var tmp0_this = this;
    var tmp0_plusAssign_0 = tmp0_this._sideEffects;
    tmp0_plusAssign_0.add_39(effect);
    Unit_getInstance();
  };
  RememberEventDispatcher.prototype.dispatchRememberObservers = function () {
    var tmp0_isNotEmpty_0 = this._forgetting;
    if (!tmp0_isNotEmpty_0.isEmpty_44()) {
      var inductionVariable = this._forgetting._get_size__52() - 1 | 0;
      if (0 <= inductionVariable)
        do {
          var i = inductionVariable;
          inductionVariable = inductionVariable + -1 | 0;
          var instance = this._forgetting.get_72(i);
          if (!this._abandoning.contains_51(instance)) {
            instance.onForgotten_1();
          }}
         while (0 <= inductionVariable);
    } else {
    }
    var tmp1_isNotEmpty_0 = this._remembering;
    if (!tmp1_isNotEmpty_0.isEmpty_44()) {
      var tmp2_fastForEach_0 = this._remembering;
      var inductionVariable_0 = 0;
      var last_2 = tmp2_fastForEach_0._get_size__52() - 1 | 0;
      if (inductionVariable_0 <= last_2)
        do {
          var index_3 = inductionVariable_0;
          inductionVariable_0 = inductionVariable_0 + 1 | 0;
          var item_4 = tmp2_fastForEach_0.get_72(index_3);
          this._abandoning.remove_46(item_4);
          Unit_getInstance();
          item_4.onRemembered_1();
        }
         while (inductionVariable_0 <= last_2);
    } else {
    }
  };
  RememberEventDispatcher.prototype.dispatchSideEffects = function () {
    var tmp0_isNotEmpty_0 = this._sideEffects;
    if (!tmp0_isNotEmpty_0.isEmpty_44()) {
      var tmp1_fastForEach_0 = this._sideEffects;
      var inductionVariable = 0;
      var last_2 = tmp1_fastForEach_0._get_size__52() - 1 | 0;
      if (inductionVariable <= last_2)
        do {
          var index_3 = inductionVariable;
          inductionVariable = inductionVariable + 1 | 0;
          var item_4 = tmp1_fastForEach_0.get_72(index_3);
          item_4();
        }
         while (inductionVariable <= last_2);
      this._sideEffects.clear_9();
    } else {
    }
  };
  RememberEventDispatcher.prototype.dispatchAbandons = function () {
    var tmp0_isNotEmpty_0 = this._abandoning;
    if (!tmp0_isNotEmpty_0.isEmpty_44()) {
      var iterator_0 = this._abandoning.iterator_66();
      while (iterator_0.hasNext_41()) {
        var instance = iterator_0.next_46();
        iterator_0.remove_22();
        instance.onAbandoned_1();
      }
    } else {
    }
  };
  RememberEventDispatcher.$metadata$ = {
    simpleName: 'RememberEventDispatcher',
    kind: 'class',
    interfaces: [RememberManager]
  };
  function CompositionImpl(parent_0, applier, recomposeContext) {
    this._parent_0 = parent_0;
    this._applier_0 = applier;
    this._pendingModifications = new AtomicReference(null);
    this._lock_0 = new Object();
    this._abandonSet_0 = HashSet_init_$Create$();
    this._slotTable_0 = new SlotTable();
    this._observations = new IdentityScopeMap();
    var tmp = this;
    tmp._changes_0 = ArrayList_init_$Create$();
    this._observationsProcessed = new IdentityScopeMap();
    this._invalidations_0 = new IdentityArraySet();
    this._pendingInvalidScopes_0 = false;
    var tmp_0 = this;
    var tmp0_also_0 = new ComposerImpl(this._applier_0, this._parent_0, this._slotTable_0, this._abandonSet_0, this._changes_0, this);
    this._parent_0.registerComposer_0(tmp0_also_0);
    tmp_0._composer_0 = tmp0_also_0;
    this.__recomposeContext = recomposeContext;
    var tmp_1 = this;
    var tmp_2 = this._parent_0;
    tmp_1._isRoot = tmp_2 instanceof Recomposer;
    this._disposed = false;
    this._composable = ComposableSingletons$CompositionKt_getInstance()._lambda_1;
  }
  CompositionImpl.prototype._get_isComposing__4 = function () {
    return this._composer_0._isComposing;
  };
  CompositionImpl.prototype._get_isDisposed__0 = function () {
    return this._disposed;
  };
  CompositionImpl.prototype.recordModificationsOf_0 = function (values_0) {
    $l$break: while (true) {
      var old = this._pendingModifications.get_60();
      var tmp0_subject = old;
      var tmp;
      if (tmp0_subject == null ? true : equals_0(tmp0_subject, PendingApplyNoModifications)) {
        tmp = values_0;
      } else {
        if (!(tmp0_subject == null) ? isInterface(tmp0_subject, Set) : false) {
          var tmp0_arrayOf_0 = [old, values_0];
          tmp = tmp0_arrayOf_0;
        } else {
          if (!(tmp0_subject == null) ? isArray(tmp0_subject) : false) {
            tmp = plus_2((!(old == null) ? isArray(old) : false) ? old : THROW_CCE(), values_0);
          } else {
            {
              var tmp1_error_0 = '' + 'corrupt pendingModifications: ' + this._pendingModifications;
              throw IllegalStateException_init_$Create$_0(toString_2(tmp1_error_0));
            }
          }
        }
      }
      var new_0 = tmp;
      if (this._pendingModifications.compareAndSet_1(old, new_0)) {
        if (old == null) {
          var tmp2_synchronized_0 = this._lock_0;
          drainPendingModificationsLocked(this);
        }break $l$break;
      }}
  };
  CompositionImpl.prototype.observesAnyOf_0 = function (values_0) {
    var tmp0_iterator = values_0.iterator_66();
    while (tmp0_iterator.hasNext_41()) {
      var value_0 = tmp0_iterator.next_46();
      if (this._observations.contains_46(value_0))
        return true;
    }
    return false;
  };
  CompositionImpl.prototype.prepareCompose_1 = function (block) {
    return this._composer_0.prepareCompose_1(block);
  };
  CompositionImpl.prototype.recordReadOf_0 = function (value_0) {
    if (!_get_areChildrenComposing_(this)) {
      var tmp0_safe_receiver = this._composer_0._get_currentRecomposeScope_();
      if (tmp0_safe_receiver == null)
        null;
      else {
        tmp0_safe_receiver._used = true;
        this._observations.add_37(value_0, tmp0_safe_receiver);
        Unit_getInstance();
        tmp0_safe_receiver.recordRead(value_0);
        Unit_getInstance();
      }
      Unit_getInstance();
    }};
  CompositionImpl.prototype.recordWriteOf_0 = function (value_0) {
    var tmp0_synchronized_0 = this._lock_0;
    var tmp1_forEachScopeOf_0_2 = this._observations;
    var index_1_3 = find_1(tmp1_forEachScopeOf_0_2, value_0);
    var tmp;
    if (index_1_3 >= 0) {
      var tmp0_forEach_0_4 = scopeSetAt(tmp1_forEachScopeOf_0_2, index_1_3);
      var tmp0_iterator_1_5 = tmp0_forEach_0_4.iterator_66();
      while (tmp0_iterator_1_5.hasNext_41()) {
        var element_2_6 = tmp0_iterator_1_5.next_46();
        if (element_2_6.invalidateForResult().equals(InvalidationResult_IMMINENT_getInstance())) {
          this._observationsProcessed.add_37(value_0, element_2_6);
          Unit_getInstance();
        }}
      tmp = Unit_getInstance();
    }return tmp;
  };
  CompositionImpl.prototype.recompose_1 = function () {
    var tmp0_synchronized_0 = this._lock_0;
    drainPendingModificationsForCompositionLocked(this);
    var tmp0_also_0_2 = this._composer_0.recompose(takeInvalidations(this));
    if (!tmp0_also_0_2)
      drainPendingModificationsLocked(this);
    return tmp0_also_0_2;
  };
  CompositionImpl.prototype.applyChanges_0 = function () {
    var tmp0_synchronized_0 = this._lock_0;
    var manager_2 = new RememberEventDispatcher(this._abandonSet_0);
    try {
      this._applier_0.onBeginChanges_1();
      var tmp1_write_0_3 = this._slotTable_0;
      var tmp0_let_0_4 = tmp1_write_0_3.openWriter();
      try {
        var applier_3_5 = this._applier_0;
        var tmp0_fastForEach_0_4_6 = this._changes_0;
        var inductionVariable = 0;
        var last_2 = tmp0_fastForEach_0_4_6._get_size__52() - 1 | 0;
        if (inductionVariable <= last_2)
          do {
            var index_3_6_8 = inductionVariable;
            inductionVariable = inductionVariable + 1 | 0;
            var item_4_7_9 = tmp0_fastForEach_0_4_6.get_72(index_3_6_8);
            item_4_7_9(applier_3_5, tmp0_let_0_4, manager_2);
          }
           while (inductionVariable <= last_2);
        this._changes_0.clear_9();
      }finally {
        tmp0_let_0_4.close_7();
      }
      this._applier_0.onEndChanges_1();
      manager_2.dispatchRememberObservers();
      manager_2.dispatchSideEffects();
      if (this._pendingInvalidScopes_0) {
        this._pendingInvalidScopes_0 = false;
        var tmp2_removeValueIf_0_10 = this._observations;
        var destinationIndex_1_11 = 0;
        var inductionVariable_0 = 0;
        var last_3 = tmp2_removeValueIf_0_10._size_3;
        if (inductionVariable_0 < last_3)
          do {
            var i_3_13 = inductionVariable_0;
            inductionVariable_0 = inductionVariable_0 + 1 | 0;
            var valueIndex_4_14 = tmp2_removeValueIf_0_10._valueOrder[i_3_13];
            var set_5_15 = ensureNotNull(tmp2_removeValueIf_0_10._scopeSets[valueIndex_4_14]);
            var destinationIndex_1_16 = 0;
            var inductionVariable_1 = 0;
            var last_4 = set_5_15._size_2;
            if (inductionVariable_1 < last_4)
              do {
                var i_3_18 = inductionVariable_1;
                inductionVariable_1 = inductionVariable_1 + 1 | 0;
                var tmp = set_5_15._values_1[i_3_18];
                var item_4_19 = isObject(tmp) ? tmp : THROW_CCE();
                if (!!item_4_19._get_valid__1()) {
                  if (!(destinationIndex_1_16 === i_3_18)) {
                    set_5_15._values_1[destinationIndex_1_16] = item_4_19;
                  }var tmp1_5_20 = destinationIndex_1_16;
                  destinationIndex_1_16 = tmp1_5_20 + 1 | 0;
                  Unit_getInstance();
                } else {
                }
              }
               while (inductionVariable_1 < last_4);
            var inductionVariable_2 = destinationIndex_1_16;
            var last_5 = set_5_15._size_2;
            if (inductionVariable_2 < last_5)
              do {
                var i_7_22 = inductionVariable_2;
                inductionVariable_2 = inductionVariable_2 + 1 | 0;
                set_5_15._values_1[i_7_22] = null;
              }
               while (inductionVariable_2 < last_5);
            set_5_15._size_2 = destinationIndex_1_16;
            if (set_5_15._size_2 > 0) {
              if (!(destinationIndex_1_11 === i_3_13)) {
                var destinationKeyOrder_6_23 = tmp2_removeValueIf_0_10._valueOrder[destinationIndex_1_11];
                tmp2_removeValueIf_0_10._valueOrder[destinationIndex_1_11] = valueIndex_4_14;
                tmp2_removeValueIf_0_10._valueOrder[i_3_13] = destinationKeyOrder_6_23;
              }var tmp1_7_24 = destinationIndex_1_11;
              destinationIndex_1_11 = tmp1_7_24 + 1 | 0;
              Unit_getInstance();
            }}
           while (inductionVariable_0 < last_3);
        var inductionVariable_3 = destinationIndex_1_11;
        var last_6 = tmp2_removeValueIf_0_10._size_3;
        if (inductionVariable_3 < last_6)
          do {
            var i_9_26 = inductionVariable_3;
            inductionVariable_3 = inductionVariable_3 + 1 | 0;
            tmp2_removeValueIf_0_10._values_2[tmp2_removeValueIf_0_10._valueOrder[i_9_26]] = null;
          }
           while (inductionVariable_3 < last_6);
        tmp2_removeValueIf_0_10._size_3 = destinationIndex_1_11;
      }}finally {
      manager_2.dispatchAbandons();
    }
    drainPendingModificationsLocked(this);
  };
  CompositionImpl.prototype.invalidateAll_0 = function () {
    var tmp0_synchronized_0 = this._lock_0;
    var tmp0_forEach_0_2 = this._slotTable_0._slots_1;
    var indexedObject = tmp0_forEach_0_2;
    var inductionVariable = 0;
    var last_2 = indexedObject.length;
    while (inductionVariable < last_2) {
      var element_2_4 = indexedObject[inductionVariable];
      inductionVariable = inductionVariable + 1 | 0;
      var tmp0_safe_receiver_4_5 = element_2_4 instanceof RecomposeScopeImpl ? element_2_4 : null;
      if (tmp0_safe_receiver_4_5 == null)
        null;
      else {
        tmp0_safe_receiver_4_5.invalidate_2();
        Unit_getInstance();
      }
      Unit_getInstance();
    }
  };
  CompositionImpl.prototype.invalidate = function (scope) {
    if (scope._defaultsInScope) {
      scope._defaultsInvalid = true;
    }var anchor = scope._anchor;
    if ((anchor == null ? true : !this._slotTable_0.ownsAnchor(anchor)) ? true : !anchor._get_valid__1())
      return InvalidationResult_IGNORED_getInstance();
    var location = anchor.toIndexFor(this._slotTable_0);
    if (location < 0)
      return InvalidationResult_IGNORED_getInstance();
    if (this._get_isComposing__4() ? this._composer_0.tryImminentInvalidation(scope) : false) {
      return InvalidationResult_IMMINENT_getInstance();
    }this._invalidations_0.add_36(scope);
    Unit_getInstance();
    this._parent_0.invalidate_3(this);
    return this._get_isComposing__4() ? InvalidationResult_DEFERRED_getInstance() : InvalidationResult_SCHEDULED_getInstance();
  };
  CompositionImpl.prototype.removeObservation = function (instance, scope) {
    this._observations.remove_42(instance, scope);
    Unit_getInstance();
  };
  CompositionImpl.prototype.$set_composable$$composable = function (set__$) {
    this._composable = set__$;
  };
  CompositionImpl.prototype.setContent$composable_0 = function (content) {
    var tmp0_check_0 = !this._disposed;
    if (!tmp0_check_0) {
      var message_1 = 'The composition is disposed';
      throw IllegalStateException_init_$Create$_0(toString_2(message_1));
    }this.$set_composable$$composable(content);
    this._parent_0.composeInitial$composable_0(this, this._composable);
  };
  CompositionImpl.prototype.composeContent$composable_1 = function (content) {
    var tmp0_synchronized_0 = this._lock_0;
    drainPendingModificationsForCompositionLocked(this);
    this._composer_0.composeContent$composable(takeInvalidations(this), content);
  };
  CompositionImpl.$metadata$ = {
    simpleName: 'CompositionImpl',
    kind: 'class',
    interfaces: [ControlledComposition]
  };
  function ControlledComposition() {
  }
  ControlledComposition.$metadata$ = {
    simpleName: 'ControlledComposition',
    kind: 'interface',
    interfaces: [Composition]
  };
  function _no_name_provided__183($boundThis) {
    this._$boundThis = $boundThis;
  }
  _no_name_provided__183.prototype.invoke_912 = function (p0, p1) {
    return this._$boundThis.invoke_912(p0, p1);
  };
  _no_name_provided__183.prototype.invoke_915 = function (p1, p2) {
    var tmp = (!(p1 == null) ? isInterface(p1, Composer) : false) ? p1 : THROW_CCE();
    return this.invoke_912(tmp, (!(p2 == null) ? typeof p2 === 'number' : false) ? p2 : THROW_CCE());
  };
  _no_name_provided__183.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__184() {
  }
  _no_name_provided__184.prototype.invoke_914 = function ($composer, $changed) {
    var $composer_0 = $composer;
    sourceInformation($composer_0, 'C:Composition.kt#9igjgp');
    if (!(($changed & 11 ^ 2) === 0) ? true : !$composer_0._get_skipping__0()) {
      Unit_getInstance();
    } else
      $composer_0.skipToGroupEnd_2();
  };
  _no_name_provided__184.prototype.invoke_915 = function (p1, p2) {
    var tmp = (p1 == null ? true : isInterface(p1, Composer)) ? p1 : THROW_CCE();
    this.invoke_914(tmp, (!(p2 == null) ? typeof p2 === 'number' : false) ? p2 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__184.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__185($boundThis) {
    this._$boundThis_0 = $boundThis;
  }
  _no_name_provided__185.prototype.invoke_912 = function (p0, p1) {
    return this._$boundThis_0.invoke_912(p0, p1);
  };
  _no_name_provided__185.prototype.invoke_915 = function (p1, p2) {
    var tmp = (!(p1 == null) ? isInterface(p1, Composer) : false) ? p1 : THROW_CCE();
    return this.invoke_912(tmp, (!(p2 == null) ? typeof p2 === 'number' : false) ? p2 : THROW_CCE());
  };
  _no_name_provided__185.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__186() {
  }
  _no_name_provided__186.prototype.invoke_914 = function ($composer, $changed) {
    var $composer_0 = $composer;
    sourceInformation($composer_0, 'C:Composition.kt#9igjgp');
    if (!(($changed & 11 ^ 2) === 0) ? true : !$composer_0._get_skipping__0()) {
      Unit_getInstance();
    } else
      $composer_0.skipToGroupEnd_2();
  };
  _no_name_provided__186.prototype.invoke_915 = function (p1, p2) {
    var tmp = (p1 == null ? true : isInterface(p1, Composer)) ? p1 : THROW_CCE();
    this.invoke_914(tmp, (!(p2 == null) ? typeof p2 === 'number' : false) ? p2 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__186.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function ComposableSingletons$CompositionKt() {
    ComposableSingletons$CompositionKt_instance = this;
    var tmp = this;
    tmp._lambda_1 = _no_name_provided_$factory_160(composableLambdaInstance(-985543561, false, _no_name_provided_$factory_159()));
    var tmp_0 = this;
    tmp_0._lambda_2 = _no_name_provided_$factory_162(composableLambdaInstance(-985549210, false, _no_name_provided_$factory_161()));
  }
  ComposableSingletons$CompositionKt.$metadata$ = {
    simpleName: 'ComposableSingletons$CompositionKt',
    kind: 'object',
    interfaces: []
  };
  var ComposableSingletons$CompositionKt_instance;
  function ComposableSingletons$CompositionKt_getInstance() {
    if (ComposableSingletons$CompositionKt_instance == null)
      new ComposableSingletons$CompositionKt();
    return ComposableSingletons$CompositionKt_instance;
  }
  function Composition() {
  }
  Composition.$metadata$ = {
    simpleName: 'Composition',
    kind: 'interface',
    interfaces: []
  };
  function ControlledComposition_0(applier, parent_0) {
    return CompositionImpl_init_$Create$(parent_0, applier, null, 4, null);
  }
  function _no_name_provided_$factory_159() {
    var i = new _no_name_provided__184();
    return function (p1, p2) {
      i.invoke_914(p1, p2);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_160($boundThis) {
    var i = new _no_name_provided__183($boundThis);
    return function (p1, p2) {
      return i.invoke_912(p1, p2);
    };
  }
  function _no_name_provided_$factory_161() {
    var i = new _no_name_provided__186();
    return function (p1, p2) {
      i.invoke_914(p1, p2);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_162($boundThis) {
    var i = new _no_name_provided__185($boundThis);
    return function (p1, p2) {
      return i.invoke_912(p1, p2);
    };
  }
  var EmptyCompositionLocalMap;
  function CompositionContext() {
    this._$stable_1 = 0;
  }
  CompositionContext.prototype.recordInspectionTable_0 = function (table) {
  };
  CompositionContext.prototype.registerComposer_0 = function (composer) {
  };
  CompositionContext.prototype.getCompositionLocalScope_0 = function () {
    return EmptyCompositionLocalMap;
  };
  CompositionContext.prototype.startComposing_0 = function () {
  };
  CompositionContext.prototype.doneComposing_0 = function () {
  };
  CompositionContext.$metadata$ = {
    simpleName: 'CompositionContext',
    kind: 'class',
    interfaces: []
  };
  function CompositionLocal(defaultFactory) {
    this._defaultValueHolder = new LazyValueHolder(defaultFactory);
  }
  CompositionLocal.$metadata$ = {
    simpleName: 'CompositionLocal',
    kind: 'class',
    interfaces: []
  };
  function ProvidableCompositionLocal(defaultFactory) {
    CompositionLocal.call(this, defaultFactory);
  }
  ProvidableCompositionLocal.$metadata$ = {
    simpleName: 'ProvidableCompositionLocal',
    kind: 'class',
    interfaces: []
  };
  function staticCompositionLocalOf(defaultFactory) {
    return new StaticProvidableCompositionLocal(defaultFactory);
  }
  function StaticProvidableCompositionLocal(defaultFactory) {
    ProvidableCompositionLocal.call(this, defaultFactory);
  }
  StaticProvidableCompositionLocal.$metadata$ = {
    simpleName: 'StaticProvidableCompositionLocal',
    kind: 'class',
    interfaces: []
  };
  var InternalDisposableEffectScope;
  function DisposableEffectScope() {
    this._$stable_2 = 0;
  }
  DisposableEffectScope.$metadata$ = {
    simpleName: 'DisposableEffectScope',
    kind: 'class',
    interfaces: []
  };
  function DisposableEffectResult() {
  }
  DisposableEffectResult.$metadata$ = {
    simpleName: 'DisposableEffectResult',
    kind: 'interface',
    interfaces: []
  };
  function DisposableEffect$composable(key1, effect, $composer, $changed) {
    var $composer_0 = $composer;
    $composer_0.startReplaceableGroup_0(1867059813);
    sourceInformation($composer_0, 'C(DisposableEffect$composable)P(1)154@6171L47:Effects.kt#9igjgp');
    var tmp0_remember$composable_0 = $composer_0;
    var tmp1_remember$composable_0 = 14 & $changed;
    var $composer_1 = tmp0_remember$composable_0;
    $composer_1.startReplaceableGroup_0(884179877);
    sourceInformation($composer_1, 'C(remember$composable)P(1):Composables.kt#9igjgp');
    var tmp1_cache_0_3 = $composer_1;
    var tmp2_cache_0_4 = $composer_1.changed_0(key1);
    var tmp0_let_0_5 = tmp1_cache_0_3.rememberedValue_0();
    var tmp;
    if (tmp2_cache_0_4 ? true : tmp0_let_0_5 === Companion_getInstance_25()._Empty) {
      var tmp$ret$0;
      l$ret$1: do {
        var tmp0_return_8 = new DisposableEffectImpl(effect);
        tmp$ret$0 = tmp0_return_8;
        break l$ret$1;
      }
       while (false);
      var value_2_2_6 = tmp$ret$0;
      tmp1_cache_0_3.updateRememberedValue_0(value_2_2_6);
      tmp = value_2_2_6;
    } else {
      tmp = tmp0_let_0_5;
    }
    var tmp_0 = tmp;
    var tmp0_2 = (tmp_0 == null ? true : isObject(tmp_0)) ? tmp_0 : THROW_CCE();
    $composer_1.endReplaceableGroup_0();
    Unit_getInstance();
    $composer_0.endReplaceableGroup_0();
  }
  function SideEffect$composable(effect, $composer, $changed) {
    var $composer_0 = $composer;
    $composer_0.startReplaceableGroup_0(2104625033);
    sourceInformation($composer_0, 'C(SideEffect$composable):Effects.kt#9igjgp');
    $composer_0.recordSideEffect_0(effect);
    $composer_0.endReplaceableGroup_0();
  }
  function DisposableEffectImpl(effect) {
    this._effect = effect;
    this._onDispose = null;
  }
  DisposableEffectImpl.prototype.onRemembered_1 = function () {
    this._onDispose = this._effect(InternalDisposableEffectScope);
  };
  DisposableEffectImpl.prototype.onForgotten_1 = function () {
    var tmp0_safe_receiver = this._onDispose;
    if (tmp0_safe_receiver == null)
      null;
    else {
      tmp0_safe_receiver.dispose_15();
      Unit_getInstance();
    }
    Unit_getInstance();
    this._onDispose = null;
  };
  DisposableEffectImpl.prototype.onAbandoned_1 = function () {
  };
  DisposableEffectImpl.$metadata$ = {
    simpleName: 'DisposableEffectImpl',
    kind: 'class',
    interfaces: [RememberObserver]
  };
  function JoinedKey(left, right) {
    this._left_1 = left;
    this._right = right;
  }
  JoinedKey.prototype.toString = function () {
    return '' + 'JoinedKey(left=' + this._left_1 + ', right=' + this._right + ')';
  };
  JoinedKey.prototype.hashCode = function () {
    var result = this._left_1 == null ? 0 : hashCode(this._left_1);
    result = imul(result, 31) + (this._right == null ? 0 : hashCode(this._right)) | 0;
    return result;
  };
  JoinedKey.prototype.equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof JoinedKey))
      return false;
    else {
    }
    var tmp0_other_with_cast = other instanceof JoinedKey ? other : THROW_CCE();
    if (!equals_0(this._left_1, tmp0_other_with_cast._left_1))
      return false;
    if (!equals_0(this._right, tmp0_other_with_cast._right))
      return false;
    return true;
  };
  JoinedKey.$metadata$ = {
    simpleName: 'JoinedKey',
    kind: 'class',
    interfaces: []
  };
  function Key_5() {
    Key_instance_4 = this;
  }
  Key_5.$metadata$ = {
    simpleName: 'Key',
    kind: 'object',
    interfaces: [Key_0]
  };
  var Key_instance_4;
  function Key_getInstance_4() {
    if (Key_instance_4 == null)
      new Key_5();
    return Key_instance_4;
  }
  function MonotonicFrameClock() {
  }
  MonotonicFrameClock.prototype._get_key__35 = function () {
    return Key_getInstance_4();
  };
  MonotonicFrameClock.$metadata$ = {
    simpleName: 'MonotonicFrameClock',
    kind: 'interface',
    interfaces: [Element_0]
  };
  function _get_monotonicFrameClock_(_this_) {
    var tmp0_elvis_lhs = _this_.get_62(Key_getInstance_4());
    var tmp;
    if (tmp0_elvis_lhs == null) {
      var tmp0_error_0 = 'A MonotonicFrameClock is not available in this CoroutineContext. Callers should supply an appropriate MonotonicFrameClock using withContext.';
      throw IllegalStateException_init_$Create$_0(toString_2(tmp0_error_0));
    } else {
      tmp = tmp0_elvis_lhs;
    }
    return tmp;
  }
  function OpaqueKey(key_0) {
    this._key_1 = key_0;
  }
  OpaqueKey.prototype.toString = function () {
    return '' + 'OpaqueKey(key=' + this._key_1 + ')';
  };
  OpaqueKey.prototype.hashCode = function () {
    return getStringHashCode(this._key_1);
  };
  OpaqueKey.prototype.equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof OpaqueKey))
      return false;
    else {
    }
    var tmp0_other_with_cast = other instanceof OpaqueKey ? other : THROW_CCE();
    if (!(this._key_1 === tmp0_other_with_cast._key_1))
      return false;
    return true;
  };
  OpaqueKey.$metadata$ = {
    simpleName: 'OpaqueKey',
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__187(this$0, $token, $tmp0_safe_receiver) {
    this._this$0_27 = this$0;
    this._$token = $token;
    this._$tmp0_safe_receiver_0 = $tmp0_safe_receiver;
  }
  _no_name_provided__187.prototype.invoke_330 = function (composition) {
    var tmp;
    if (this._this$0_27._currentToken === this._$token ? equals_0(this._$tmp0_safe_receiver_0, this._this$0_27._trackedInstances) : false) {
      tmp = composition instanceof CompositionImpl;
    } else {
      tmp = false;
    }
    if (tmp) {
      var destinationIndex_1_7 = 0;
      var inductionVariable = 0;
      var last_2 = this._$tmp0_safe_receiver_0._size_1;
      if (inductionVariable < last_2)
        do {
          var i_3_9 = inductionVariable;
          inductionVariable = inductionVariable + 1 | 0;
          var tmp_0 = this._$tmp0_safe_receiver_0._keys_0[i_3_9];
          var key_4_10 = isObject(tmp_0) ? tmp_0 : THROW_CCE();
          var value_5_11 = this._$tmp0_safe_receiver_0._values_0[i_3_9];
          var tmp0_also_0_10_12 = !(value_5_11 === this._$token);
          if (tmp0_also_0_10_12)
            composition.removeObservation(key_4_10, this._this$0_27);
          if (!tmp0_also_0_10_12) {
            if (!(destinationIndex_1_7 === i_3_9)) {
              this._$tmp0_safe_receiver_0._keys_0[destinationIndex_1_7] = key_4_10;
              this._$tmp0_safe_receiver_0._values_0[destinationIndex_1_7] = value_5_11;
            }var tmp1_6_13 = destinationIndex_1_7;
            destinationIndex_1_7 = tmp1_6_13 + 1 | 0;
            Unit_getInstance();
          } else {
          }
        }
         while (inductionVariable < last_2);
      var inductionVariable_0 = destinationIndex_1_7;
      var last_3 = this._$tmp0_safe_receiver_0._size_1;
      if (inductionVariable_0 < last_3)
        do {
          var i_8_15 = inductionVariable_0;
          inductionVariable_0 = inductionVariable_0 + 1 | 0;
          this._$tmp0_safe_receiver_0._keys_0[i_8_15] = null;
        }
         while (inductionVariable_0 < last_3);
      this._$tmp0_safe_receiver_0._size_1 = destinationIndex_1_7;
      if (this._$tmp0_safe_receiver_0._size_1 === 0)
        this._this$0_27._trackedInstances = null;
    } else {
    }
  };
  _no_name_provided__187.prototype.invoke_1115 = function (p1) {
    this.invoke_330((!(p1 == null) ? isInterface(p1, Composition) : false) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__187.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function RecomposeScopeImpl(composition) {
    this._composition_0 = composition;
    this._anchor = null;
    this._used = false;
    this._defaultsInScope = false;
    this._defaultsInvalid = false;
    this._requiresRecompose = false;
    this._block = null;
    this._currentToken = 0;
    this._trackedInstances = null;
    this._skipped = false;
  }
  RecomposeScopeImpl.prototype._get_valid__1 = function () {
    var tmp;
    if (!(this._composition_0 == null)) {
      var tmp0_safe_receiver = this._anchor;
      var tmp1_elvis_lhs = tmp0_safe_receiver == null ? null : tmp0_safe_receiver._get_valid__1();
      tmp = tmp1_elvis_lhs == null ? false : tmp1_elvis_lhs;
    } else {
      tmp = false;
    }
    return tmp;
  };
  RecomposeScopeImpl.prototype.compose = function (composer) {
    var tmp0_safe_receiver = this._block;
    var tmp1_elvis_lhs = tmp0_safe_receiver == null ? null : tmp0_safe_receiver(composer, 1);
    if (tmp1_elvis_lhs == null) {
      var tmp0_error_0 = 'Invalid restart scope';
      throw IllegalStateException_init_$Create$_0(toString_2(tmp0_error_0));
    } else {
      Unit_getInstance();
    }
  };
  RecomposeScopeImpl.prototype.invalidateForResult = function () {
    var tmp0_safe_receiver = this._composition_0;
    var tmp1_elvis_lhs = tmp0_safe_receiver == null ? null : tmp0_safe_receiver.invalidate(this);
    return tmp1_elvis_lhs == null ? InvalidationResult_IGNORED_getInstance() : tmp1_elvis_lhs;
  };
  RecomposeScopeImpl.prototype.invalidate_2 = function () {
    var tmp0_safe_receiver = this._composition_0;
    if (tmp0_safe_receiver == null)
      null;
    else
      tmp0_safe_receiver.invalidate(this);
    Unit_getInstance();
  };
  RecomposeScopeImpl.prototype.updateScope_0 = function (block) {
    this._block = block;
  };
  RecomposeScopeImpl.prototype.start_17 = function (token) {
    this._currentToken = token;
    this._skipped = false;
  };
  RecomposeScopeImpl.prototype.scopeSkipped = function () {
    this._skipped = true;
  };
  RecomposeScopeImpl.prototype.recordRead = function (instance) {
    var tmp0_elvis_lhs = this._trackedInstances;
    var tmp;
    if (tmp0_elvis_lhs == null) {
      var tmp0_also_0 = new IdentityArrayIntMap();
      this._trackedInstances = tmp0_also_0;
      tmp = tmp0_also_0;
    } else {
      tmp = tmp0_elvis_lhs;
    }
    tmp.add_35(instance, this._currentToken);
  };
  RecomposeScopeImpl.prototype.end_0 = function (token) {
    var tmp0_safe_receiver = this._trackedInstances;
    var tmp;
    if (tmp0_safe_receiver == null) {
      tmp = null;
    } else {
      var tmp_0;
      var tmp_1;
      if (!this._skipped) {
        var tmp$ret$0;
        l$ret$1: do {
          var inductionVariable = 0;
          var last_2 = tmp0_safe_receiver._size_1;
          if (inductionVariable < last_2)
            do {
              var i_2_3 = inductionVariable;
              inductionVariable = inductionVariable + 1 | 0;
              var tmp_2 = tmp0_safe_receiver._keys_0[i_2_3];
              var tmp0__anonymous__3_4 = isObject(tmp_2) ? tmp_2 : THROW_CCE();
              var tmp1__anonymous__3_5 = tmp0_safe_receiver._values_0[i_2_3];
              if (!(tmp1__anonymous__3_5 === token)) {
                tmp$ret$0 = true;
                break l$ret$1;
              } else {
              }
            }
             while (inductionVariable < last_2);
          tmp$ret$0 = false;
        }
         while (false);
        tmp_1 = tmp$ret$0;
      } else {
        tmp_1 = false;
      }
      if (tmp_1) {
        tmp_0 = _no_name_provided_$factory_163(this, token, tmp0_safe_receiver);
      } else {
        {
          tmp_0 = null;
        }
      }
      tmp = tmp_0;
    }
    return tmp;
  };
  RecomposeScopeImpl.$metadata$ = {
    simpleName: 'RecomposeScopeImpl',
    kind: 'class',
    interfaces: [ScopeUpdateScope, RecomposeScope]
  };
  function RecomposeScope() {
  }
  RecomposeScope.$metadata$ = {
    simpleName: 'RecomposeScope',
    kind: 'interface',
    interfaces: []
  };
  function _no_name_provided_$factory_163(this$0, $token, $tmp0_safe_receiver) {
    var i = new _no_name_provided__187(this$0, $token, $tmp0_safe_receiver);
    return function (p1) {
      i.invoke_330(p1);
      return Unit_getInstance();
    };
  }
  var ProduceAnotherFrame;
  var FramePending;
  var State_ShutDown_instance;
  var State_ShuttingDown_instance;
  var State_Inactive_instance;
  var State_InactivePendingWork_instance;
  var State_Idle_instance;
  var State_PendingWork_instance;
  var State_entriesInitialized_0;
  function State_initEntries_0() {
    if (State_entriesInitialized_0)
      return Unit_getInstance();
    State_entriesInitialized_0 = true;
    State_ShutDown_instance = new State_0('ShutDown', 0);
    State_ShuttingDown_instance = new State_0('ShuttingDown', 1);
    State_Inactive_instance = new State_0('Inactive', 2);
    State_InactivePendingWork_instance = new State_0('InactivePendingWork', 3);
    State_Idle_instance = new State_0('Idle', 4);
    State_PendingWork_instance = new State_0('PendingWork', 5);
  }
  function addRunning($this, info) {
    $l$break: while (true) {
      var old = $this.__runningRecomposers._get_value__33();
      var new_0 = old.add_39(info);
      if (old === new_0 ? true : $this.__runningRecomposers.compareAndSet_0(old, new_0))
        break $l$break;
    }
  }
  function removeRunning($this, info) {
    $l$break: while (true) {
      var old = $this.__runningRecomposers._get_value__33();
      var new_0 = old.remove_46(info);
      if (old === new_0 ? true : $this.__runningRecomposers.compareAndSet_0(old, new_0))
        break $l$break;
    }
  }
  function _no_name_provided__188(this$0, $throwable) {
    this._this$0_28 = this$0;
    this._$throwable = $throwable;
  }
  _no_name_provided__188.prototype.invoke_341 = function (runnerJobCause) {
    var tmp0_synchronized_0_4_8 = this._this$0_28._stateLock;
    var tmp = this._this$0_28;
    var tmp0_safe_receiver_2_5_9 = this._$throwable;
    var tmp_0;
    if (tmp0_safe_receiver_2_5_9 == null) {
      tmp_0 = null;
    } else {
      var tmp0_safe_receiver_3_4_7_11 = runnerJobCause;
      var tmp_1;
      if (tmp0_safe_receiver_3_4_7_11 == null) {
        tmp_1 = null;
      } else {
        var tmp_2;
        if (!(tmp0_safe_receiver_3_4_7_11 instanceof CancellationException)) {
          tmp_2 = tmp0_safe_receiver_3_4_7_11;
        } else {
          {
            tmp_2 = null;
          }
        }
        tmp_1 = tmp_2;
      }
      var tmp1_safe_receiver_2_3_6_10 = tmp_1;
      if (tmp1_safe_receiver_2_3_6_10 == null)
        null;
      else {
        addSuppressed(tmp0_safe_receiver_2_5_9, tmp1_safe_receiver_2_3_6_10);
        Unit_getInstance();
      }
      Unit_getInstance();
      tmp_0 = tmp0_safe_receiver_2_5_9;
    }
    tmp._closeCause = tmp_0;
    this._this$0_28.__state_3._set_value__6(State_ShutDown_getInstance());
  };
  _no_name_provided__188.prototype.invoke_1115 = function (p1) {
    this.invoke_341((p1 == null ? true : p1 instanceof Error) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__188.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__189(this$0, $toRecompose, $toApply) {
    this._this$0_29 = this$0;
    this._$toRecompose = $toRecompose;
    this._$toApply = $toApply;
  }
  _no_name_provided__189.prototype.invoke_334 = function (frameTime) {
    if (this._this$0_29._broadcastFrameClock._get_hasAwaiters_()) {
      var tmp$ret$0;
      l$ret$1: do {
        var tmp0_trace_0 = 'Recomposer:animation';
        var token_1 = Trace_getInstance().beginSection(tmp0_trace_0);
        try {
          this._this$0_29._broadcastFrameClock.sendFrame(frameTime);
          tmp$ret$0 = Companion_getInstance_30().sendApplyNotifications();
          break l$ret$1;
        }finally {
          Trace_getInstance().endSection(token_1);
        }
      }
       while (false);
    }var tmp$ret$2;
    l$ret$3: do {
      var tmp1_trace_0 = 'Recomposer:recompose';
      var token_1_0 = Trace_getInstance().beginSection(tmp1_trace_0);
      try {
        var tmp0_synchronized_0_3 = this._this$0_29._stateLock;
        recordComposerModificationsLocked(this._this$0_29);
        var tmp0_fastForEach_0_2_4 = this._this$0_29._compositionInvalidations;
        var inductionVariable = 0;
        var last_2 = tmp0_fastForEach_0_2_4._get_size__52() - 1 | 0;
        if (inductionVariable <= last_2)
          do {
            var index_3_4_6 = inductionVariable;
            inductionVariable = inductionVariable + 1 | 0;
            var item_4_5_7 = tmp0_fastForEach_0_2_4.get_72(index_3_4_6);
            this._$toRecompose.add_39(item_4_5_7);
            Unit_getInstance();
          }
           while (inductionVariable <= last_2);
        this._this$0_29._compositionInvalidations.clear_9();
        var modifiedValues_8 = new IdentityArraySet();
        var alreadyComposed_9 = new IdentityArraySet();
        $l$break: while (true) {
          if (!!this._$toRecompose.isEmpty_44()) {
            break $l$break;
          }try {
            var inductionVariable_0 = 0;
            var last_3 = this._$toRecompose._get_size__52() - 1 | 0;
            if (inductionVariable_0 <= last_3)
              do {
                var index_3_11 = inductionVariable_0;
                inductionVariable_0 = inductionVariable_0 + 1 | 0;
                var item_4_12 = this._$toRecompose.get_72(index_3_11);
                alreadyComposed_9.add_36(item_4_12);
                Unit_getInstance();
                var tmp0_safe_receiver_6_13 = performRecompose(this._this$0_29, item_4_12, modifiedValues_8);
                if (tmp0_safe_receiver_6_13 == null)
                  null;
                else {
                  this._$toApply.add_39(tmp0_safe_receiver_6_13);
                  Unit_getInstance();
                }
                Unit_getInstance();
              }
               while (inductionVariable_0 <= last_3);
          }finally {
            this._$toRecompose.clear_9();
          }
          if (modifiedValues_8.isNotEmpty_0()) {
            var tmp1_synchronized_0_14 = this._this$0_29._stateLock;
            var tmp0_fastForEach_0_2_15 = this._this$0_29._knownCompositions;
            var inductionVariable_1 = 0;
            var last_4 = tmp0_fastForEach_0_2_15._get_size__52() - 1 | 0;
            if (inductionVariable_1 <= last_4)
              do {
                var index_3_4_17 = inductionVariable_1;
                inductionVariable_1 = inductionVariable_1 + 1 | 0;
                var item_4_5_18 = tmp0_fastForEach_0_2_15.get_72(index_3_4_17);
                if (!alreadyComposed_9.contains_44(item_4_5_18) ? item_4_5_18.observesAnyOf_0(modifiedValues_8) : false) {
                  this._$toRecompose.add_39(item_4_5_18);
                  Unit_getInstance();
                }}
               while (inductionVariable_1 <= last_4);
          }}
        if (!this._$toApply.isEmpty_44()) {
          var tmp0_this_19 = this._this$0_29;
          var tmp1_20 = tmp0_this_19._changeCount;
          tmp0_this_19._changeCount = tmp1_20.inc_5();
          Unit_getInstance();
          try {
            var inductionVariable_2 = 0;
            var last_5 = this._$toApply._get_size__52() - 1 | 0;
            if (inductionVariable_2 <= last_5)
              do {
                var index_3_22 = inductionVariable_2;
                inductionVariable_2 = inductionVariable_2 + 1 | 0;
                var item_4_23 = this._$toApply.get_72(index_3_22);
                item_4_23.applyChanges_0();
              }
               while (inductionVariable_2 <= last_5);
          }finally {
            this._$toApply.clear_9();
          }
        } else {
        }
        var tmp2_synchronized_0_24 = this._this$0_29._stateLock;
        tmp$ret$2 = deriveStateLocked(this._this$0_29);
        break l$ret$3;
      }finally {
        Trace_getInstance().endSection(token_1_0);
      }
    }
     while (false);
    return tmp$ret$2;
  };
  _no_name_provided__189.prototype.invoke_1115 = function (p1) {
    return this.invoke_334(p1 instanceof Long ? p1 : THROW_CCE());
  };
  _no_name_provided__189.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__190(this$0) {
    this._this$0_30 = this$0;
  }
  _no_name_provided__190.prototype.invoke_336 = function (changed, _anonymous_parameter_1_) {
    var tmp0_synchronized_0 = this._this$0_30._stateLock;
    var tmp;
    if (this._this$0_30.__state_3._get_value__33().compareTo_11(State_Idle_getInstance()) >= 0) {
      var tmp0_this_2 = this._this$0_30;
      var tmp0_plusAssign_0_3 = tmp0_this_2._snapshotInvalidations;
      tmp0_plusAssign_0_3.add_39(changed);
      Unit_getInstance();
      tmp = deriveStateLocked(this._this$0_30);
    } else {
      tmp = null;
    }
    var tmp0_safe_receiver = tmp;
    if (tmp0_safe_receiver == null)
      null;
    else {
      var tmp1_resume_0 = Unit_getInstance();
      var tmp0_success_0_1 = Companion_getInstance_2();
      tmp0_safe_receiver.resumeWith_21(_Result___init__impl_(Unit_getInstance()));
      Unit_getInstance();
    }
    Unit_getInstance();
  };
  _no_name_provided__190.prototype.invoke_915 = function (p1, p2) {
    var tmp = (!(p1 == null) ? isInterface(p1, Set) : false) ? p1 : THROW_CCE();
    this.invoke_336(tmp, p2 instanceof Snapshot ? p2 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__190.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__191($block, $parentFrameClock, resultContinuation) {
    this._$block_1 = $block;
    this._$parentFrameClock = $parentFrameClock;
    CoroutineImpl_0.call(this, resultContinuation);
  }
  _no_name_provided__191.prototype.invoke_389 = function ($this$coroutineScope, $cont) {
    var tmp = this.create_13($this$coroutineScope, $cont);
    tmp._result_0 = Unit_getInstance();
    tmp._exception_0 = null;
    return tmp.doResume_17();
  };
  _no_name_provided__191.prototype.doResume_17 = function () {
    var suspendResult = this._result_0;
    $sm: do
      try {
        var tmp = this._state_2;
        switch (tmp) {
          case 0:
            this._exceptionState = 2;
            this._state_2 = 1;
            suspendResult = this._$block_1(this._$this$coroutineScope, this._$parentFrameClock, this);
            if (suspendResult === _get_COROUTINE_SUSPENDED_()) {
              return suspendResult;
            }
            continue $sm;
          case 1:
            suspendResult;
            return Unit_getInstance();
          case 2:
            throw this._exception_0;
        }
      } catch ($p) {
        if (this._exceptionState === 2) {
          throw $p;
        } else {
          this._state_2 = this._exceptionState;
          this._exception_0 = $p;
        }
      }
     while (true);
  };
  _no_name_provided__191.prototype.create_13 = function ($this$coroutineScope, completion) {
    var i = new _no_name_provided__191(this._$block_1, this._$parentFrameClock, completion);
    i._$this$coroutineScope = $this$coroutineScope;
    return i;
  };
  _no_name_provided__191.$metadata$ = {
    kind: 'class',
    interfaces: [],
    suspendArity: 1
  };
  function State_0(name, ordinal) {
    Enum.call(this, name, ordinal);
  }
  State_0.$metadata$ = {
    simpleName: 'State',
    kind: 'class',
    interfaces: []
  };
  function deriveStateLocked($this) {
    if ($this.__state_3._get_value__33().compareTo_11(State_ShuttingDown_getInstance()) <= 0) {
      $this._knownCompositions.clear_9();
      $this._snapshotInvalidations.clear_9();
      $this._compositionInvalidations.clear_9();
      $this._compositionsAwaitingApply.clear_9();
      var tmp0_safe_receiver = $this._workContinuation;
      if (tmp0_safe_receiver == null)
        null;
      else {
        tmp0_safe_receiver.cancel$default_8(null, 1, null);
      }
      Unit_getInstance();
      $this._workContinuation = null;
      return null;
    }var tmp;
    if ($this._runnerJob == null) {
      $this._snapshotInvalidations.clear_9();
      $this._compositionInvalidations.clear_9();
      tmp = $this._broadcastFrameClock._get_hasAwaiters_() ? State_InactivePendingWork_getInstance() : State_Inactive_getInstance();
    } else {
      var tmp_0;
      var tmp_1;
      var tmp_2;
      var tmp_3;
      var tmp0_isNotEmpty_0 = $this._compositionInvalidations;
      if (!tmp0_isNotEmpty_0.isEmpty_44()) {
        tmp_3 = true;
      } else {
        {
          var tmp1_isNotEmpty_0 = $this._snapshotInvalidations;
          tmp_3 = !tmp1_isNotEmpty_0.isEmpty_44();
        }
      }
      if (tmp_3) {
        tmp_2 = true;
      } else {
        {
          var tmp2_isNotEmpty_0 = $this._compositionsAwaitingApply;
          tmp_2 = !tmp2_isNotEmpty_0.isEmpty_44();
        }
      }
      if (tmp_2) {
        tmp_1 = true;
      } else {
        {
          tmp_1 = $this._concurrentCompositionsOutstanding > 0;
        }
      }
      if (tmp_1) {
        tmp_0 = true;
      } else {
        {
          tmp_0 = $this._broadcastFrameClock._get_hasAwaiters_();
        }
      }
      if (tmp_0) {
        tmp = State_PendingWork_getInstance();
      } else {
        {
          tmp = State_Idle_getInstance();
        }
      }
    }
    var newState = tmp;
    $this.__state_3._set_value__6(newState);
    var tmp_4;
    if (newState.equals(State_PendingWork_getInstance())) {
      var tmp3_also_0 = $this._workContinuation;
      $this._workContinuation = null;
      tmp_4 = tmp3_also_0;
    } else {
      tmp_4 = null;
    }
    return tmp_4;
  }
  function _get_shouldKeepRecomposing_($this) {
    var tmp;
    var tmp0_synchronized_0 = $this._stateLock;
    if (!$this._isClosed) {
      tmp = true;
    } else {
      {
        var tmp$ret$0;
        l$ret$1: do {
          var tmp1_any_0 = $this._effectJob._get_children__28();
          var tmp0_iterator_1 = tmp1_any_0.iterator_66();
          while (tmp0_iterator_1.hasNext_41()) {
            var element_2 = tmp0_iterator_1.next_46();
            if (element_2._get_isActive__23()) {
              tmp$ret$0 = true;
              break l$ret$1;
            } else {
            }
          }
          tmp$ret$0 = false;
        }
         while (false);
        tmp = tmp$ret$0;
      }
    }
    return tmp;
  }
  function RecomposerInfoImpl($outer) {
    this._$this_7 = $outer;
  }
  RecomposerInfoImpl.$metadata$ = {
    simpleName: 'RecomposerInfoImpl',
    kind: 'class',
    interfaces: [RecomposerInfo]
  };
  function recordComposerModificationsLocked($this) {
    var tmp0_isNotEmpty_0 = $this._snapshotInvalidations;
    if (!tmp0_isNotEmpty_0.isEmpty_44()) {
      var tmp1_fastForEach_0 = $this._snapshotInvalidations;
      var inductionVariable = 0;
      var last_2 = tmp1_fastForEach_0._get_size__52() - 1 | 0;
      if (inductionVariable <= last_2)
        do {
          var index_3 = inductionVariable;
          inductionVariable = inductionVariable + 1 | 0;
          var item_4 = tmp1_fastForEach_0.get_72(index_3);
          var tmp0_fastForEach_0_6 = $this._knownCompositions;
          var inductionVariable_0 = 0;
          var last_3 = tmp0_fastForEach_0_6._get_size__52() - 1 | 0;
          if (inductionVariable_0 <= last_3)
            do {
              var index_3_8 = inductionVariable_0;
              inductionVariable_0 = inductionVariable_0 + 1 | 0;
              var item_4_9 = tmp0_fastForEach_0_6.get_72(index_3_8);
              item_4_9.recordModificationsOf_0(item_4);
            }
             while (inductionVariable_0 <= last_3);
        }
         while (inductionVariable <= last_2);
      $this._snapshotInvalidations.clear_9();
      if (!(deriveStateLocked($this) == null)) {
        var tmp2_error_0 = 'called outside of runRecomposeAndApplyChanges';
        throw IllegalStateException_init_$Create$_0(toString_2(tmp2_error_0));
      }} else {
    }
  }
  function registerRunnerJob($this, callingJob) {
    var tmp0_synchronized_0 = $this._stateLock;
    var tmp0_safe_receiver_2 = $this._closeCause;
    if (tmp0_safe_receiver_2 == null)
      null;
    else {
      throw tmp0_safe_receiver_2;
    }
    Unit_getInstance();
    if ($this.__state_3._get_value__33().compareTo_11(State_ShuttingDown_getInstance()) <= 0) {
      var tmp0_error_0_3 = 'Recomposer shut down';
      throw IllegalStateException_init_$Create$_0(toString_2(tmp0_error_0_3));
    }if (!($this._runnerJob == null)) {
      var tmp1_error_0_4 = 'Recomposer already running';
      throw IllegalStateException_init_$Create$_0(toString_2(tmp1_error_0_4));
    }$this._runnerJob = callingJob;
    deriveStateLocked($this);
    Unit_getInstance();
  }
  function _get_hasSchedulingWork_($this) {
    var tmp0_synchronized_0 = $this._stateLock;
    var tmp;
    var tmp_0;
    var tmp0_isNotEmpty_0_2 = $this._snapshotInvalidations;
    if (!tmp0_isNotEmpty_0_2.isEmpty_44()) {
      tmp_0 = true;
    } else {
      {
        var tmp1_isNotEmpty_0_3 = $this._compositionInvalidations;
        tmp_0 = !tmp1_isNotEmpty_0_3.isEmpty_44();
      }
    }
    if (tmp_0) {
      tmp = true;
    } else {
      {
        tmp = $this._broadcastFrameClock._get_hasAwaiters_();
      }
    }
    return tmp;
  }
  function awaitWorkAvailable($this, $cont) {
    var tmp = new $awaitWorkAvailableCOROUTINE$1($this, $cont);
    tmp._result_0 = Unit_getInstance();
    tmp._exception_0 = null;
    return tmp.doResume_17();
  }
  function recompositionRunner($this, block, $cont) {
    var parentFrameClock = _get_monotonicFrameClock_($cont._get_context__31());
    return withContext($this._broadcastFrameClock, _no_name_provided_$factory_167($this, block, parentFrameClock, null), $cont);
  }
  function performRecompose($this, composition, modifiedValues) {
    if (composition._get_isComposing__4() ? true : composition._get_isDisposed__0())
      return null;
    var tmp;
    var tmp$ret$2;
    l$ret$3: do {
      var snapshot_1 = Companion_getInstance_30().takeMutableSnapshot(readObserverOf($this, composition), writeObserverOf($this, composition, modifiedValues));
      try {
        var tmp$ret$0;
        l$ret$1: do {
          var previous_1 = snapshot_1.makeCurrent_3();
          try {
            var tmp0_safe_receiver_3 = modifiedValues;
            if ((tmp0_safe_receiver_3 == null ? null : tmp0_safe_receiver_3.isNotEmpty_0()) === true) {
              composition.prepareCompose_1(_no_name_provided_$factory_168(modifiedValues, composition));
            } else {
            }
            tmp$ret$0 = composition.recompose_1();
            break l$ret$1;
          }finally {
            snapshot_1.restoreCurrent_3(previous_1);
          }
        }
         while (false);
        tmp$ret$2 = tmp$ret$0;
        break l$ret$3;
      }finally {
        applyAndCheck($this, snapshot_1);
      }
    }
     while (false);
    if (tmp$ret$2) {
      tmp = composition;
    } else {
      {
        tmp = null;
      }
    }
    return tmp;
  }
  function readObserverOf($this, composition) {
    return _no_name_provided_$factory_169(composition);
  }
  function writeObserverOf($this, composition, modifiedValues) {
    return _no_name_provided_$factory_170(composition, modifiedValues);
  }
  function applyAndCheck($this, snapshot) {
    var applyResult = snapshot.apply_4();
    if (applyResult instanceof Failure_0) {
      var tmp0_error_0 = 'Unsupported concurrent change during composition. A state object was modified by composition as well as being modified outside composition.';
      throw IllegalStateException_init_$Create$_0(toString_2(tmp0_error_0));
    } else {
    }
  }
  function _get_hasFrameWorkLocked_($this) {
    var tmp;
    var tmp0_isNotEmpty_0 = $this._compositionInvalidations;
    if (!tmp0_isNotEmpty_0.isEmpty_44()) {
      tmp = true;
    } else {
      {
        tmp = $this._broadcastFrameClock._get_hasAwaiters_();
      }
    }
    return tmp;
  }
  function Companion_27() {
    Companion_instance_26 = this;
    this.__runningRecomposers = MutableStateFlow_0(persistentSetOf());
  }
  Companion_27.$metadata$ = {
    simpleName: 'Companion',
    kind: 'object',
    interfaces: []
  };
  var Companion_instance_26;
  function Companion_getInstance_26() {
    if (Companion_instance_26 == null)
      new Companion_27();
    return Companion_instance_26;
  }
  function _no_name_provided__192(this$0) {
    this._this$0_31 = this$0;
  }
  _no_name_provided__192.prototype.invoke_422 = function () {
    var tmp0_synchronized_0 = this._this$0_31._stateLock;
    var tmp0_also_0_2 = deriveStateLocked(this._this$0_31);
    if (this._this$0_31.__state_3._get_value__33().compareTo_11(State_ShuttingDown_getInstance()) <= 0)
      throw new CancellationException('Recomposer shutdown; frame clock awaiter will never resume', this._this$0_31._closeCause);
    var tmp0_safe_receiver = tmp0_also_0_2;
    if (tmp0_safe_receiver == null)
      null;
    else {
      var tmp1_resume_0 = Unit_getInstance();
      var tmp0_success_0_1 = Companion_getInstance_2();
      tmp0_safe_receiver.resumeWith_21(_Result___init__impl_(Unit_getInstance()));
      Unit_getInstance();
    }
    Unit_getInstance();
  };
  _no_name_provided__192.prototype.invoke_523 = function () {
    this.invoke_422();
    return Unit_getInstance();
  };
  _no_name_provided__192.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__193(this$0) {
    this._this$0_32 = this$0;
  }
  _no_name_provided__193.prototype.invoke_341 = function (throwable) {
    var cancellation_3 = new CancellationException('Recomposer effect job completed', throwable);
    var continuationToResume_4 = null;
    var tmp0_synchronized_0_5 = this._this$0_32._stateLock;
    var runnerJob_2_6 = this._this$0_32._runnerJob;
    if (!(runnerJob_2_6 == null)) {
      this._this$0_32.__state_3._set_value__6(State_ShuttingDown_getInstance());
      if (!this._this$0_32._isClosed) {
        runnerJob_2_6.cancel_13(cancellation_3);
      } else if (!(this._this$0_32._workContinuation == null)) {
        continuationToResume_4 = this._this$0_32._workContinuation;
      }this._this$0_32._workContinuation = null;
      runnerJob_2_6.invokeOnCompletion_22(_no_name_provided_$factory_171(this._this$0_32, throwable));
    } else {
      this._this$0_32._closeCause = cancellation_3;
      this._this$0_32.__state_3._set_value__6(State_ShutDown_getInstance());
      Unit_getInstance();
    }
    Unit_getInstance();
    var tmp0_safe_receiver_12 = continuationToResume_4;
    if (tmp0_safe_receiver_12 == null)
      null;
    else {
      var tmp1_resume_0_13 = Unit_getInstance();
      var tmp0_success_0_1_14 = Companion_getInstance_2();
      tmp0_safe_receiver_12.resumeWith_21(_Result___init__impl_(Unit_getInstance()));
      Unit_getInstance();
    }
    Unit_getInstance();
  };
  _no_name_provided__193.prototype.invoke_1115 = function (p1) {
    this.invoke_341((p1 == null ? true : p1 instanceof Error) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__193.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__194(this$0, resultContinuation) {
    this._this$0_33 = this$0;
    CoroutineImpl_0.call(this, resultContinuation);
  }
  _no_name_provided__194.prototype.invoke_343 = function ($this$recompositionRunner, parentFrameClock, $cont) {
    var tmp = this.create_5($this$recompositionRunner, parentFrameClock, $cont);
    tmp._result_0 = Unit_getInstance();
    tmp._exception_0 = null;
    return tmp.doResume_17();
  };
  _no_name_provided__194.prototype.invoke_915 = function (p1, p2, $cont) {
    var tmp = (!(p1 == null) ? isInterface(p1, CoroutineScope) : false) ? p1 : THROW_CCE();
    this.invoke_343(tmp, (!(p2 == null) ? isInterface(p2, MonotonicFrameClock) : false) ? p2 : THROW_CCE(), $cont);
    return Unit_getInstance();
  };
  _no_name_provided__194.prototype.doResume_17 = function () {
    var suspendResult = this._result_0;
    $sm: do
      try {
        var tmp = this._state_2;
        switch (tmp) {
          case 0:
            this._exceptionState = 6;
            var tmp_0 = this;
            tmp_0._toRecompose0 = ArrayList_init_$Create$();
            var tmp_1 = this;
            tmp_1._toApply1 = ArrayList_init_$Create$();
            this._state_2 = 1;
            continue $sm;
          case 1:
            if (!_get_shouldKeepRecomposing_(this._this$0_33)) {
              this._state_2 = 5;
              continue $sm;
            }
            this._state_2 = 2;
            suspendResult = awaitWorkAvailable(this._this$0_33, this);
            if (suspendResult === _get_COROUTINE_SUSPENDED_()) {
              return suspendResult;
            }
            continue $sm;
          case 2:
            suspendResult;
            var tmp_2 = this;
            tmp_2._tmp0_synchronized_02 = this._this$0_33._stateLock;
            var tmp_3;
            if (!_get_hasFrameWorkLocked_(this._this$0_33)) {
              recordComposerModificationsLocked(this._this$0_33);
              tmp_3 = !_get_hasFrameWorkLocked_(this._this$0_33);
            } else {
              tmp_3 = false;
            }

            if (tmp_3) {
              this._state_2 = 1;
              continue $sm;
            } else {
              {
                this._state_2 = 3;
                continue $sm;
              }
            }

            break;
          case 3:
            this._state_2 = 4;
            suspendResult = this._parentFrameClock.withFrameNanos_1(_no_name_provided_$factory_172(this._this$0_33, this._toRecompose0, this._toApply1), this);
            if (suspendResult === _get_COROUTINE_SUSPENDED_()) {
              return suspendResult;
            }
            continue $sm;
          case 4:
            suspendResult;
            Unit_getInstance();
            this._state_2 = 1;
            continue $sm;
          case 5:
            return Unit_getInstance();
          case 6:
            throw this._exception_0;
        }
      } catch ($p) {
        if (this._exceptionState === 6) {
          throw $p;
        } else {
          this._state_2 = this._exceptionState;
          this._exception_0 = $p;
        }
      }
     while (true);
  };
  _no_name_provided__194.prototype.create_5 = function ($this$recompositionRunner, parentFrameClock, completion) {
    var i = new _no_name_provided__194(this._this$0_33, completion);
    i._$this$recompositionRunner = $this$recompositionRunner;
    i._parentFrameClock = parentFrameClock;
    return i;
  };
  _no_name_provided__194.$metadata$ = {
    kind: 'class',
    interfaces: [],
    suspendArity: 2
  };
  function _no_name_provided__195(this$0, $block, $parentFrameClock, resultContinuation) {
    this._this$0_34 = this$0;
    this._$block_2 = $block;
    this._$parentFrameClock_0 = $parentFrameClock;
    CoroutineImpl_0.call(this, resultContinuation);
  }
  _no_name_provided__195.prototype.invoke_389 = function ($this$withContext, $cont) {
    var tmp = this.create_13($this$withContext, $cont);
    tmp._result_0 = Unit_getInstance();
    tmp._exception_0 = null;
    return tmp.doResume_17();
  };
  _no_name_provided__195.prototype.doResume_17 = function () {
    var suspendResult = this._result_0;
    $sm: do
      try {
        var tmp = this._state_2;
        switch (tmp) {
          case 0:
            this._exceptionState = 5;
            this._callingJob0 = _get_job_(this._$this$withContext._get_coroutineContext__8());
            registerRunnerJob(this._this$0_34, this._callingJob0);
            var tmp_0 = this;
            var tmp_1 = Companion_getInstance_30();
            tmp_0._unregisterApplyObserver1 = tmp_1.registerApplyObserver(_no_name_provided_$factory_173(this._this$0_34));
            addRunning(Companion_getInstance_26(), this._this$0_34._recomposerInfo);
            this._state_2 = 1;
            continue $sm;
          case 1:
            this._exceptionState = 4;
            var tmp_2 = this;
            tmp_2._tmp0_synchronized_03 = this._this$0_34._stateLock;
            var tmp_3 = this;
            tmp_3._tmp0_fastForEach_0_24 = this._this$0_34._knownCompositions;
            var inductionVariable = 0;
            var last_2 = this._tmp0_fastForEach_0_24._get_size__52() - 1 | 0;
            if (inductionVariable <= last_2)
              do {
                var index_3_4 = inductionVariable;
                inductionVariable = inductionVariable + 1 | 0;
                var item_4_5 = this._tmp0_fastForEach_0_24.get_72(index_3_4);
                item_4_5.invalidateAll_0();
              }
               while (inductionVariable <= last_2);
            this._state_2 = 2;
            suspendResult = coroutineScope(_no_name_provided_$factory_174(this._$block_2, this._$parentFrameClock_0, null), this);
            if (suspendResult === _get_COROUTINE_SUSPENDED_()) {
              return suspendResult;
            }
            continue $sm;
          case 2:
            this._tmp$ret$02 = suspendResult;
            this._exceptionState = 5;
            this._state_2 = 3;
            continue $sm;
          case 3:
            this._unregisterApplyObserver1.dispose_15();
            var tmp1_synchronized_0 = this._this$0_34._stateLock;
            if (this._this$0_34._runnerJob === this._callingJob0) {
              this._this$0_34._runnerJob = null;
            }
            deriveStateLocked(this._this$0_34);
            Unit_getInstance();
            removeRunning(Companion_getInstance_26(), this._this$0_34._recomposerInfo);
            return Unit_getInstance();
          case 4:
            this._exceptionState = 5;
            var t = this._exception_0;
            this._unregisterApplyObserver1.dispose_15();
            var tmp1_synchronized_0_0 = this._this$0_34._stateLock;
            if (this._this$0_34._runnerJob === this._callingJob0) {
              this._this$0_34._runnerJob = null;
            }
            deriveStateLocked(this._this$0_34);
            Unit_getInstance();
            removeRunning(Companion_getInstance_26(), this._this$0_34._recomposerInfo);
            throw t;
          case 5:
            throw this._exception_0;
        }
      } catch ($p) {
        if (this._exceptionState === 5) {
          throw $p;
        } else {
          this._state_2 = this._exceptionState;
          this._exception_0 = $p;
        }
      }
     while (true);
  };
  _no_name_provided__195.prototype.create_13 = function ($this$withContext, completion) {
    var i = new _no_name_provided__195(this._this$0_34, this._$block_2, this._$parentFrameClock_0, completion);
    i._$this$withContext = $this$withContext;
    return i;
  };
  _no_name_provided__195.$metadata$ = {
    kind: 'class',
    interfaces: [],
    suspendArity: 1
  };
  function _no_name_provided__196($modifiedValues, $composition) {
    this._$modifiedValues = $modifiedValues;
    this._$composition = $composition;
  }
  _no_name_provided__196.prototype.invoke_422 = function () {
    var tmp0_iterator_1_5 = this._$modifiedValues.iterator_66();
    while (tmp0_iterator_1_5.hasNext_41()) {
      var element_2_6 = tmp0_iterator_1_5.next_46();
      this._$composition.recordWriteOf_0(element_2_6);
    }
  };
  _no_name_provided__196.prototype.invoke_523 = function () {
    this.invoke_422();
    return Unit_getInstance();
  };
  _no_name_provided__196.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__197($composition) {
    this._$composition_0 = $composition;
  }
  _no_name_provided__197.prototype.invoke_918 = function (value_0) {
    this._$composition_0.recordReadOf_0(value_0);
  };
  _no_name_provided__197.prototype.invoke_1115 = function (p1) {
    this.invoke_918(isObject(p1) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__197.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__198($composition, $modifiedValues) {
    this._$composition_1 = $composition;
    this._$modifiedValues_0 = $modifiedValues;
  }
  _no_name_provided__198.prototype.invoke_918 = function (value_0) {
    this._$composition_1.recordWriteOf_0(value_0);
    var tmp0_safe_receiver = this._$modifiedValues_0;
    if (tmp0_safe_receiver == null)
      null;
    else
      tmp0_safe_receiver.add_36(value_0);
    Unit_getInstance();
  };
  _no_name_provided__198.prototype.invoke_1115 = function (p1) {
    this.invoke_918(isObject(p1) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__198.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function State_ShutDown_getInstance() {
    State_initEntries_0();
    return State_ShutDown_instance;
  }
  function State_ShuttingDown_getInstance() {
    State_initEntries_0();
    return State_ShuttingDown_instance;
  }
  function State_Inactive_getInstance() {
    State_initEntries_0();
    return State_Inactive_instance;
  }
  function State_InactivePendingWork_getInstance() {
    State_initEntries_0();
    return State_InactivePendingWork_instance;
  }
  function State_Idle_getInstance() {
    State_initEntries_0();
    return State_Idle_instance;
  }
  function State_PendingWork_getInstance() {
    State_initEntries_0();
    return State_PendingWork_instance;
  }
  function $awaitWorkAvailableCOROUTINE$1(_this_, resultContinuation) {
    CoroutineImpl_0.call(this, resultContinuation);
    this.__this__0 = _this_;
  }
  $awaitWorkAvailableCOROUTINE$1.prototype.doResume_17 = function () {
    var suspendResult = this._result_0;
    $sm: do
      try {
        var tmp = this._state_2;
        switch (tmp) {
          case 0:
            this._exceptionState = 3;
            if (!_get_hasSchedulingWork_(this.__this__0)) {
              this._state_2 = 1;
              var tmp_0 = this;
              tmp_0._tmp0__anonymous__1_10 = this;
              this._cancellable_2_21 = new CancellableContinuationImpl(intercepted(this._tmp0__anonymous__1_10), 1);
              this._cancellable_2_21.initCancellability_0();
              var tmp_1 = this;
              tmp_1._tmp0_synchronized_0_42 = this.__this__0._stateLock;
              if (_get_hasSchedulingWork_(this.__this__0)) {
                var tmp0_resume_0_2_5 = Unit_getInstance();
                var tmp0_success_0_1_3_6 = Companion_getInstance_2();
                this._cancellable_2_21.resumeWith_21(_Result___init__impl_(Unit_getInstance()));
              } else {
                this.__this__0._workContinuation = this._cancellable_2_21;
              }
              suspendResult = this._cancellable_2_21.getResult_1();
              if (suspendResult === _get_COROUTINE_SUSPENDED_()) {
                return suspendResult;
              }continue $sm;
            } else {
              this._state_2 = 2;
              continue $sm;
            }

            break;
          case 1:
            suspendResult;
            this._state_2 = 2;
            continue $sm;
          case 2:
            return Unit_getInstance();
          case 3:
            throw this._exception_0;
        }
      } catch ($p) {
        if (this._exceptionState === 3) {
          throw $p;
        } else {
          this._state_2 = this._exceptionState;
          this._exception_0 = $p;
        }
      }
     while (true);
  };
  $awaitWorkAvailableCOROUTINE$1.$metadata$ = {
    simpleName: '$awaitWorkAvailableCOROUTINE$1',
    kind: 'class',
    interfaces: []
  };
  function Recomposer(effectCoroutineContext) {
    Companion_getInstance_26();
    CompositionContext.call(this);
    this._changeCount = new Long(0, 0);
    var tmp = this;
    tmp._broadcastFrameClock = new BroadcastFrameClock(_no_name_provided_$factory_164(this));
    var tmp_0 = this;
    var tmp0_apply_0 = Job_0(effectCoroutineContext.get_62(Key_getInstance_2()));
    tmp0_apply_0.invokeOnCompletion_22(_no_name_provided_$factory_165(this));
    Unit_getInstance();
    tmp_0._effectJob = tmp0_apply_0;
    this._effectCoroutineContext = effectCoroutineContext.plus_43(this._broadcastFrameClock).plus_43(this._effectJob);
    this._stateLock = new Object();
    this._runnerJob = null;
    this._closeCause = null;
    var tmp_1 = this;
    tmp_1._knownCompositions = ArrayList_init_$Create$();
    var tmp_2 = this;
    tmp_2._snapshotInvalidations = ArrayList_init_$Create$();
    var tmp_3 = this;
    tmp_3._compositionInvalidations = ArrayList_init_$Create$();
    var tmp_4 = this;
    tmp_4._compositionsAwaitingApply = ArrayList_init_$Create$();
    this._workContinuation = null;
    this._concurrentCompositionsOutstanding = 0;
    this._isClosed = false;
    this.__state_3 = MutableStateFlow_0(State_Inactive_getInstance());
    this._recomposerInfo = new RecomposerInfoImpl(this);
    this._$stable_3 = 8;
  }
  Recomposer.prototype.runRecomposeAndApplyChanges = function ($cont) {
    return recompositionRunner(this, _no_name_provided_$factory_166(this, null), $cont);
  };
  Recomposer.prototype._get_compoundHashKey__0 = function () {
    return 1000;
  };
  Recomposer.prototype._get_collectingParameterInformation__0 = function () {
    return false;
  };
  Recomposer.prototype.recordInspectionTable_0 = function (table) {
  };
  Recomposer.prototype.invalidate_3 = function (composition) {
    var tmp0_synchronized_0 = this._stateLock;
    var tmp;
    if (!this._compositionInvalidations.contains_51(composition)) {
      var tmp0_this_2 = this;
      var tmp0_plusAssign_0_3 = tmp0_this_2._compositionInvalidations;
      tmp0_plusAssign_0_3.add_39(composition);
      Unit_getInstance();
      tmp = deriveStateLocked(this);
    } else {
      tmp = null;
    }
    var tmp0_safe_receiver = tmp;
    if (tmp0_safe_receiver == null)
      null;
    else {
      var tmp1_resume_0 = Unit_getInstance();
      var tmp0_success_0_1 = Companion_getInstance_2();
      tmp0_safe_receiver.resumeWith_21(_Result___init__impl_(Unit_getInstance()));
      Unit_getInstance();
    }
    Unit_getInstance();
  };
  Recomposer.prototype.composeInitial$composable_0 = function (composition, content) {
    var composerWasComposing = composition._get_isComposing__4();
    var tmp$ret$2;
    l$ret$3: do {
      var tmp0_composing_0 = null;
      var snapshot_1 = Companion_getInstance_30().takeMutableSnapshot(readObserverOf(this, composition), writeObserverOf(this, composition, tmp0_composing_0));
      try {
        var tmp$ret$0;
        l$ret$1: do {
          var previous_1_2 = snapshot_1.makeCurrent_3();
          try {
            tmp$ret$0 = composition.composeContent$composable_1(content);
            break l$ret$1;
          }finally {
            snapshot_1.restoreCurrent_3(previous_1_2);
          }
        }
         while (false);
        tmp$ret$2 = tmp$ret$0;
        break l$ret$3;
      }finally {
        applyAndCheck(this, snapshot_1);
      }
    }
     while (false);
    if (!composerWasComposing) {
      Companion_getInstance_30().notifyObjectsInitialized_4();
    }composition.applyChanges_0();
    var tmp1_synchronized_0 = this._stateLock;
    if (this.__state_3._get_value__33().compareTo_11(State_ShuttingDown_getInstance()) > 0) {
      if (!this._knownCompositions.contains_51(composition)) {
        var tmp0_this_2 = this;
        var tmp0_plusAssign_0_3 = tmp0_this_2._knownCompositions;
        tmp0_plusAssign_0_3.add_39(composition);
        Unit_getInstance();
      }}if (!composerWasComposing) {
      Companion_getInstance_30().notifyObjectsInitialized_4();
    }};
  Recomposer.$metadata$ = {
    simpleName: 'Recomposer',
    kind: 'class',
    interfaces: []
  };
  function RecomposerInfo() {
  }
  RecomposerInfo.$metadata$ = {
    simpleName: 'RecomposerInfo',
    kind: 'interface',
    interfaces: []
  };
  function _no_name_provided_$factory_164(this$0) {
    var i = new _no_name_provided__192(this$0);
    return function () {
      i.invoke_422();
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_165(this$0) {
    var i = new _no_name_provided__193(this$0);
    return function (p1) {
      i.invoke_341(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_166(this$0, resultContinuation) {
    var i = new _no_name_provided__194(this$0, resultContinuation);
    var l = function (p1, p2, $cont) {
      return i.invoke_343(p1, p2, $cont);
    };
    l.$arity = 2;
    return l;
  }
  function _no_name_provided_$factory_167(this$0, $block, $parentFrameClock, resultContinuation) {
    var i = new _no_name_provided__195(this$0, $block, $parentFrameClock, resultContinuation);
    var l = function (p1, $cont) {
      return i.invoke_389(p1, $cont);
    };
    l.$arity = 1;
    return l;
  }
  function _no_name_provided_$factory_168($modifiedValues, $composition) {
    var i = new _no_name_provided__196($modifiedValues, $composition);
    return function () {
      i.invoke_422();
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_169($composition) {
    var i = new _no_name_provided__197($composition);
    return function (p1) {
      i.invoke_918(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_170($composition, $modifiedValues) {
    var i = new _no_name_provided__198($composition, $modifiedValues);
    return function (p1) {
      i.invoke_918(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_171(this$0, $throwable) {
    var i = new _no_name_provided__188(this$0, $throwable);
    return function (p1) {
      i.invoke_341(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_172(this$0, $toRecompose, $toApply) {
    var i = new _no_name_provided__189(this$0, $toRecompose, $toApply);
    return function (p1) {
      return i.invoke_334(p1);
    };
  }
  function _no_name_provided_$factory_173(this$0) {
    var i = new _no_name_provided__190(this$0);
    return function (p1, p2) {
      i.invoke_336(p1, p2);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_174($block, $parentFrameClock, resultContinuation) {
    var i = new _no_name_provided__191($block, $parentFrameClock, resultContinuation);
    var l = function (p1, $cont) {
      return i.invoke_389(p1, $cont);
    };
    l.$arity = 1;
    return l;
  }
  function RememberObserver() {
  }
  RememberObserver.$metadata$ = {
    simpleName: 'RememberObserver',
    kind: 'interface',
    interfaces: []
  };
  function startGroup_1($this, key_0, objectKey_0, isNode_0, aux_0) {
    var inserting = $this._insertCount > 0;
    $this._nodeCountStack.push($this._nodeCount_0);
    var tmp = $this;
    var tmp_0;
    if (inserting) {
      insertGroups($this, 1);
      var current_0 = $this._currentGroup;
      var currentAddress = groupIndexToAddress($this, current_0);
      var hasObjectKey_0 = !(objectKey_0 === Companion_getInstance_25()._Empty);
      var hasAux_0 = !isNode_0 ? !(aux_0 === Companion_getInstance_25()._Empty) : false;
      initGroup($this._groups, currentAddress, key_0, isNode_0, hasObjectKey_0, hasAux_0, $this._parent_1, $this._currentSlot);
      $this._currentSlotEnd = $this._currentSlot;
      var dataSlotsNeeded = ((isNode_0 ? 1 : 0) + (hasObjectKey_0 ? 1 : 0) | 0) + (hasAux_0 ? 1 : 0) | 0;
      if (dataSlotsNeeded > 0) {
        insertSlots($this, dataSlotsNeeded, current_0);
        var slots = $this._slots_0;
        var currentSlot = $this._currentSlot;
        if (isNode_0) {
          var tmp0 = currentSlot;
          currentSlot = tmp0 + 1 | 0;
          slots[tmp0] = aux_0;
        }if (hasObjectKey_0) {
          var tmp1 = currentSlot;
          currentSlot = tmp1 + 1 | 0;
          slots[tmp1] = objectKey_0;
        }if (hasAux_0) {
          var tmp2 = currentSlot;
          currentSlot = tmp2 + 1 | 0;
          slots[tmp2] = aux_0;
        }$this._currentSlot = currentSlot;
      }$this._nodeCount_0 = 0;
      var newCurrent = current_0 + 1 | 0;
      $this._parent_1 = current_0;
      $this._currentGroup = newCurrent;
      tmp_0 = newCurrent;
    } else {
      var previousParent = $this._parent_1;
      $this._startStack.push(previousParent);
      saveCurrentGroupEnd($this);
      var currentGroup = $this._currentGroup;
      var currentGroupAddress = groupIndexToAddress($this, currentGroup);
      if (!equals_0(aux_0, Companion_getInstance_25()._Empty)) {
        if (isNode_0)
          $this.updateNode(aux_0);
        else
          $this.updateAux(aux_0);
      }$this._currentSlot = slotIndex($this._groups, $this, currentGroupAddress);
      $this._currentSlotEnd = dataIndex_0($this._groups, $this, groupIndexToAddress($this, $this._currentGroup + 1 | 0));
      $this._nodeCount_0 = nodeCount($this._groups, currentGroupAddress);
      $this._parent_1 = currentGroup;
      $this._currentGroup = currentGroup + 1 | 0;
      tmp_0 = currentGroup + groupSize($this._groups, currentGroupAddress) | 0;
    }
    tmp._currentGroupEnd = tmp_0;
  }
  function saveCurrentGroupEnd($this) {
    $this._endStack.push((_get_capacity_($this) - $this._groupGapLen | 0) - $this._currentGroupEnd | 0);
  }
  function restoreCurrentGroupEnd($this) {
    var newGroupEnd = (_get_capacity_($this) - $this._groupGapLen | 0) - $this._endStack.pop_0() | 0;
    $this._currentGroupEnd = newGroupEnd;
    return newGroupEnd;
  }
  function fixParentAnchorsFor($this, parent_0, endGroup, firstChild_0) {
    var parentAnchor_0 = parentIndexToAnchor($this, parent_0, $this._groupGapStart);
    var child = firstChild_0;
    while (child < endGroup) {
      updateParentAnchor($this._groups, groupIndexToAddress($this, child), parentAnchor_0);
      var childEnd = child + groupSize($this._groups, groupIndexToAddress($this, child)) | 0;
      fixParentAnchorsFor($this, child, childEnd, child + 1 | 0);
      child = childEnd;
    }
  }
  function moveGroupGapTo($this, index) {
    var gapLen = $this._groupGapLen;
    var gapStart = $this._groupGapStart;
    if (!(gapStart === index)) {
      var tmp0_isNotEmpty_0 = $this._anchors;
      if (!tmp0_isNotEmpty_0.isEmpty_44())
        updateAnchors($this, gapStart, index);
      else {
      }
      if (gapLen > 0) {
        var groups = $this._groups;
        var groupPhysicalAddress = imul(index, 5);
        var groupPhysicalGapLen = imul(gapLen, 5);
        var groupPhysicalGapStart = imul(gapStart, 5);
        if (index < gapStart) {
          var tmp1_copyInto_0 = groupPhysicalAddress + groupPhysicalGapLen | 0;
          var tmp = groups;
          arrayCopy_0(tmp, groups, tmp1_copyInto_0, groupPhysicalAddress, groupPhysicalGapStart);
          Unit_getInstance();
        } else {
          var tmp2_copyInto_0 = groupPhysicalGapStart + groupPhysicalGapLen | 0;
          var tmp3_copyInto_0 = groupPhysicalAddress + groupPhysicalGapLen | 0;
          var tmp_0 = groups;
          arrayCopy_0(tmp_0, groups, groupPhysicalGapStart, tmp2_copyInto_0, tmp3_copyInto_0);
          Unit_getInstance();
        }
      }var groupAddress = index < gapStart ? index + gapLen | 0 : gapStart;
      var capacity = _get_capacity_($this);
      var tmp4_check_0 = groupAddress < capacity;
      if (!tmp4_check_0) {
        var message_2_1 = 'Check failed.';
        throw IllegalStateException_init_$Create$_0(toString_2(message_2_1));
      }while (groupAddress < capacity) {
        var oldAnchor = parentAnchor($this._groups, groupAddress);
        var oldIndex = parentAnchorToIndex($this, oldAnchor);
        var newAnchor = parentIndexToAnchor($this, oldIndex, index);
        if (!(newAnchor === oldAnchor)) {
          updateParentAnchor($this._groups, groupAddress, newAnchor);
        }var tmp0 = groupAddress;
        groupAddress = tmp0 + 1 | 0;
        Unit_getInstance();
        if (groupAddress === index)
          groupAddress = groupAddress + gapLen | 0;
      }
    }$this._groupGapStart = index;
  }
  function moveSlotGapTo($this, index, group) {
    var gapLen = $this._slotsGapLen;
    var gapStart = $this._slotsGapStart;
    var slotsGapOwner = $this._slotsGapOwner;
    if (!(gapStart === index)) {
      var slots = $this._slots_0;
      if (index < gapStart) {
        var tmp0_copyInto_0 = index + gapLen | 0;
        arrayCopy_0(slots, slots, tmp0_copyInto_0, index, gapStart);
        Unit_getInstance();
      } else {
        var tmp1_copyInto_0 = gapStart + gapLen | 0;
        var tmp2_copyInto_0 = index + gapLen | 0;
        arrayCopy_0(slots, slots, gapStart, tmp1_copyInto_0, tmp2_copyInto_0);
        Unit_getInstance();
      }
      fill(slots, null, index, index + gapLen | 0);
    }var tmp3_min_0 = group + 1 | 0;
    var tmp4_min_0 = $this._get_size__52();
    var newSlotsGapOwner = function () {
      var $externalVarargReceiverTmp = Math;
      return $externalVarargReceiverTmp.min.apply($externalVarargReceiverTmp, [].concat([].slice.call(new Int32Array([tmp3_min_0, tmp4_min_0]))));
    }.call(this);
    if (!(slotsGapOwner === newSlotsGapOwner)) {
      var slotsSize = $this._slots_0.length - gapLen | 0;
      if (newSlotsGapOwner < slotsGapOwner) {
        var updateAddress = groupIndexToAddress($this, newSlotsGapOwner);
        var stopUpdateAddress = groupIndexToAddress($this, slotsGapOwner);
        var groupGapStart = $this._groupGapStart;
        while (updateAddress < stopUpdateAddress) {
          var anchor = dataAnchor($this._groups, updateAddress);
          var tmp5_check_0 = anchor >= 0;
          if (!tmp5_check_0) {
            var message_1 = 'Unexpected anchor value, expected a positive anchor';
            throw IllegalStateException_init_$Create$_0(toString_2(message_1));
          }updateDataAnchor($this._groups, updateAddress, -((slotsSize - anchor | 0) + 1 | 0) | 0);
          var tmp0 = updateAddress;
          updateAddress = tmp0 + 1 | 0;
          Unit_getInstance();
          if (updateAddress === groupGapStart)
            updateAddress = updateAddress + $this._groupGapLen | 0;
        }
      } else {
        var updateAddress_0 = groupIndexToAddress($this, slotsGapOwner);
        var stopUpdateAddress_0 = groupIndexToAddress($this, newSlotsGapOwner);
        while (updateAddress_0 < stopUpdateAddress_0) {
          var anchor_0 = dataAnchor($this._groups, updateAddress_0);
          var tmp6_check_0 = anchor_0 < 0;
          if (!tmp6_check_0) {
            var message_1_0 = 'Unexpected anchor value, expected a negative anchor';
            throw IllegalStateException_init_$Create$_0(toString_2(message_1_0));
          }updateDataAnchor($this._groups, updateAddress_0, (slotsSize + anchor_0 | 0) + 1 | 0);
          var tmp1 = updateAddress_0;
          updateAddress_0 = tmp1 + 1 | 0;
          Unit_getInstance();
          if (updateAddress_0 === $this._groupGapStart)
            updateAddress_0 = updateAddress_0 + $this._groupGapLen | 0;
        }
      }
      $this._slotsGapOwner = newSlotsGapOwner;
    }$this._slotsGapStart = index;
  }
  function insertGroups($this, size_0) {
    if (size_0 > 0) {
      var currentGroup = $this._currentGroup;
      moveGroupGapTo($this, currentGroup);
      var gapStart = $this._groupGapStart;
      var gapLen = $this._groupGapLen;
      var oldCapacity = $this._groups.length / 5 | 0;
      var oldSize = oldCapacity - gapLen | 0;
      if (gapLen < size_0) {
        var groups = $this._groups;
        var tmp0_max_0 = imul(oldCapacity, 2);
        var tmp1_max_0 = oldSize + size_0 | 0;
        var tmp2_max_0 = function () {
          var $externalVarargReceiverTmp = Math;
          return $externalVarargReceiverTmp.max.apply($externalVarargReceiverTmp, [].concat([].slice.call(new Int32Array([tmp0_max_0, tmp1_max_0]))));
        }.call(this);
        var tmp3_max_0 = 32;
        var newCapacity = function () {
          var $externalVarargReceiverTmp = Math;
          return $externalVarargReceiverTmp.max.apply($externalVarargReceiverTmp, [].concat([].slice.call(new Int32Array([tmp2_max_0, tmp3_max_0]))));
        }.call(this);
        var newGroups = new Int32Array(imul(newCapacity, 5));
        var newGapLen = newCapacity - oldSize | 0;
        var oldGapEndAddress = gapStart + gapLen | 0;
        var newGapEndAddress = gapStart + newGapLen | 0;
        var tmp4_copyInto_0 = 0;
        var tmp5_copyInto_0 = 0;
        var tmp6_copyInto_0 = imul(gapStart, 5);
        var tmp = groups;
        arrayCopy_0(tmp, newGroups, tmp4_copyInto_0, tmp5_copyInto_0, tmp6_copyInto_0);
        Unit_getInstance();
        var tmp7_copyInto_0 = imul(newGapEndAddress, 5);
        var tmp8_copyInto_0 = imul(oldGapEndAddress, 5);
        var tmp9_copyInto_0 = imul(oldCapacity, 5);
        var tmp_0 = groups;
        arrayCopy_0(tmp_0, newGroups, tmp7_copyInto_0, tmp8_copyInto_0, tmp9_copyInto_0);
        Unit_getInstance();
        $this._groups = newGroups;
        gapLen = newGapLen;
      }var currentEnd = $this._currentGroupEnd;
      if (currentEnd >= gapStart)
        $this._currentGroupEnd = currentEnd + size_0 | 0;
      $this._groupGapStart = gapStart + size_0 | 0;
      $this._groupGapLen = gapLen - size_0 | 0;
      var index = oldSize > 0 ? dataIndex($this, currentGroup + size_0 | 0) : 0;
      var anchor = dataIndexToDataAnchor($this, index, $this._slotsGapOwner < gapStart ? 0 : $this._slotsGapStart, $this._slotsGapLen, $this._slots_0.length);
      var inductionVariable = gapStart;
      var last_2 = gapStart + size_0 | 0;
      if (inductionVariable < last_2)
        do {
          var groupAddress = inductionVariable;
          inductionVariable = inductionVariable + 1 | 0;
          updateDataAnchor($this._groups, groupAddress, anchor);
        }
         while (inductionVariable < last_2);
      var slotsGapOwner = $this._slotsGapOwner;
      if (slotsGapOwner >= gapStart) {
        $this._slotsGapOwner = slotsGapOwner + size_0 | 0;
      }}}
  function insertSlots($this, size_0, group) {
    if (size_0 > 0) {
      moveSlotGapTo($this, $this._currentSlot, group);
      var gapStart = $this._slotsGapStart;
      var gapLen = $this._slotsGapLen;
      if (gapLen < size_0) {
        var slots = $this._slots_0;
        var oldCapacity = slots.length;
        var oldSize = oldCapacity - gapLen | 0;
        var tmp0_max_0 = imul(oldCapacity, 2);
        var tmp1_max_0 = oldSize + size_0 | 0;
        var tmp2_max_0 = function () {
          var $externalVarargReceiverTmp = Math;
          return $externalVarargReceiverTmp.max.apply($externalVarargReceiverTmp, [].concat([].slice.call(new Int32Array([tmp0_max_0, tmp1_max_0]))));
        }.call(this);
        var tmp3_max_0 = 32;
        var newCapacity = function () {
          var $externalVarargReceiverTmp = Math;
          return $externalVarargReceiverTmp.max.apply($externalVarargReceiverTmp, [].concat([].slice.call(new Int32Array([tmp2_max_0, tmp3_max_0]))));
        }.call(this);
        var tmp = 0;
        var tmp_0 = newCapacity;
        var tmp_1 = fillArrayVal(Array(tmp_0), null);
        while (tmp < tmp_0) {
          var tmp_2 = tmp;
          tmp_1[tmp_2] = null;
          tmp = tmp + 1 | 0;
        }
        var newData = tmp_1;
        var newGapLen = newCapacity - oldSize | 0;
        var oldGapEndAddress = gapStart + gapLen | 0;
        var newGapEndAddress = gapStart + newGapLen | 0;
        var tmp4_copyInto_0 = 0;
        var tmp5_copyInto_0 = 0;
        arrayCopy_0(slots, newData, tmp4_copyInto_0, tmp5_copyInto_0, gapStart);
        Unit_getInstance();
        arrayCopy_0(slots, newData, newGapEndAddress, oldGapEndAddress, oldCapacity);
        Unit_getInstance();
        $this._slots_0 = newData;
        gapLen = newGapLen;
      }var currentDataEnd = $this._currentSlotEnd;
      if (currentDataEnd >= gapStart)
        $this._currentSlotEnd = currentDataEnd + size_0 | 0;
      $this._slotsGapStart = gapStart + size_0 | 0;
      $this._slotsGapLen = gapLen - size_0 | 0;
    }}
  function removeGroups($this, start_0, len) {
    var tmp;
    if (len > 0) {
      var anchorsRemoved = false;
      var anchors = $this._anchors;
      moveGroupGapTo($this, start_0);
      if (!anchors.isEmpty_44())
        anchorsRemoved = removeAnchors($this, start_0, len);
      else {
      }
      $this._groupGapStart = start_0;
      var previousGapLen = $this._groupGapLen;
      var newGapLen = previousGapLen + len | 0;
      $this._groupGapLen = newGapLen;
      var slotsGapOwner = $this._slotsGapOwner;
      if (slotsGapOwner > start_0) {
        $this._slotsGapOwner = slotsGapOwner - len | 0;
      }if ($this._currentGroupEnd >= $this._groupGapStart) {
        var tmp0_this = $this;
        tmp0_this._currentGroupEnd = tmp0_this._currentGroupEnd - len | 0;
      }tmp = anchorsRemoved;
    } else {
      tmp = false;
    }
    return tmp;
  }
  function removeSlots($this, start_0, len, group) {
    if (len > 0) {
      var gapLen = $this._slotsGapLen;
      var removeEnd = start_0 + len | 0;
      moveSlotGapTo($this, removeEnd, group);
      $this._slotsGapStart = start_0;
      $this._slotsGapLen = gapLen + len | 0;
      fill($this._slots_0, null, start_0, start_0 + len | 0);
      var currentDataEnd = $this._currentSlotEnd;
      if (currentDataEnd >= start_0)
        $this._currentSlotEnd = currentDataEnd - len | 0;
    }}
  function updateNodeOfGroup($this, index, value_0) {
    var address = groupIndexToAddress($this, index);
    var tmp0_check_0 = address < $this._groups.length ? isNode($this._groups, address) : false;
    if (!tmp0_check_0) {
      var message_1 = '' + 'Updating the node of a group at ' + index + ' that was not created with as a node group';
      throw IllegalStateException_init_$Create$_0(toString_2(message_1));
    }$this._slots_0[dataIndexToDataAddress($this, nodeIndex($this._groups, $this, address))] = value_0;
  }
  function updateAnchors($this, previousGapStart, newGapStart) {
    var gapLen = $this._groupGapLen;
    var size_0 = _get_capacity_($this) - gapLen | 0;
    if (previousGapStart < newGapStart) {
      var index = locationOf($this._anchors, previousGapStart, size_0);
      $l$break_0: while (index < $this._anchors._get_size__52()) {
        var anchor = $this._anchors.get_72(index);
        var location = anchor._location_0;
        if (location < 0) {
          var newLocation = size_0 + location | 0;
          if (newLocation < newGapStart) {
            anchor._location_0 = size_0 + location | 0;
            var tmp0 = index;
            index = tmp0 + 1 | 0;
            Unit_getInstance();
          } else
            break $l$break_0;
        } else
          break $l$break_0;
      }
    } else {
      var index_0 = locationOf($this._anchors, newGapStart, size_0);
      $l$break_1: while (index_0 < $this._anchors._get_size__52()) {
        var anchor_0 = $this._anchors.get_72(index_0);
        var location_0 = anchor_0._location_0;
        if (location_0 >= 0) {
          anchor_0._location_0 = -(size_0 - location_0 | 0) | 0;
          var tmp1 = index_0;
          index_0 = tmp1 + 1 | 0;
          Unit_getInstance();
        } else
          break $l$break_1;
      }
    }
  }
  function removeAnchors($this, gapStart, size_0) {
    var gapLen = $this._groupGapLen;
    var removeEnd = gapStart + size_0 | 0;
    var groupsSize = _get_capacity_($this) - gapLen | 0;
    var tmp0_let_0 = locationOf($this._anchors, gapStart + size_0 | 0, groupsSize);
    var index = tmp0_let_0 >= $this._anchors._get_size__52() ? tmp0_let_0 - 1 | 0 : tmp0_let_0;
    var removeAnchorEnd = 0;
    var removeAnchorStart = index + 1 | 0;
    $l$break: while (index >= 0) {
      var anchor = $this._anchors.get_72(index);
      var location = $this.anchorIndex_0(anchor);
      if (location >= gapStart) {
        if (location < removeEnd) {
          anchor._location_0 = IntCompanionObject_getInstance()._MIN_VALUE_2;
          removeAnchorStart = index;
          if (removeAnchorEnd === 0)
            removeAnchorEnd = index + 1 | 0;
        }var tmp0 = index;
        index = tmp0 - 1 | 0;
        Unit_getInstance();
      } else
        break $l$break;
    }
    var tmp1_also_0 = removeAnchorStart < removeAnchorEnd;
    if (tmp1_also_0)
      $this._anchors.subList_8(removeAnchorStart, removeAnchorEnd).clear_9();
    return tmp1_also_0;
  }
  function moveAnchors($this, originalLocation, newLocation, size_0) {
    var end_0 = originalLocation + size_0 | 0;
    var groupsSize = $this._get_size__52();
    var index = locationOf($this._anchors, originalLocation, groupsSize);
    var removedAnchors = ArrayList_init_$Create$();
    if (index >= 0) {
      $l$break: while (index < $this._anchors._get_size__52()) {
        var anchor = $this._anchors.get_72(index);
        var location = $this.anchorIndex_0(anchor);
        if (location >= originalLocation ? location < end_0 : false) {
          removedAnchors.add_39(anchor);
          Unit_getInstance();
          $this._anchors.removeAt_2(index);
          Unit_getInstance();
        } else
          break $l$break;
      }
    }var moveDelta = newLocation - originalLocation | 0;
    var inductionVariable = 0;
    var last_2 = removedAnchors._get_size__52() - 1 | 0;
    if (inductionVariable <= last_2)
      do {
        var index_3 = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        var item_4 = removedAnchors.get_72(index_3);
        var anchorIndex_6 = $this.anchorIndex_0(item_4);
        var newAnchorIndex_7 = anchorIndex_6 + moveDelta | 0;
        if (newAnchorIndex_7 >= $this._groupGapStart) {
          item_4._location_0 = -(groupsSize - newAnchorIndex_7 | 0) | 0;
        } else {
          item_4._location_0 = newAnchorIndex_7;
        }
        var insertIndex_8 = locationOf($this._anchors, newAnchorIndex_7, groupsSize);
        $this._anchors.add_21(insertIndex_8, item_4);
      }
       while (inductionVariable <= last_2);
  }
  function _get_capacity_($this) {
    return $this._groups.length / 5 | 0;
  }
  function groupIndexToAddress($this, index) {
    return index < $this._groupGapStart ? index : index + $this._groupGapLen | 0;
  }
  function dataIndexToDataAddress($this, dataIndex_1) {
    return dataIndex_1 < $this._slotsGapStart ? dataIndex_1 : dataIndex_1 + $this._slotsGapLen | 0;
  }
  function parent(_this_, $this, index) {
    return parentAnchorToIndex($this, parentAnchor(_this_, groupIndexToAddress($this, index)));
  }
  function dataIndex($this, index) {
    return dataIndex_0($this._groups, $this, groupIndexToAddress($this, index));
  }
  function dataIndex_0(_this_, $this, address) {
    return address >= _get_capacity_($this) ? $this._slots_0.length - $this._slotsGapLen | 0 : dataAnchorToDataIndex($this, dataAnchor(_this_, address), $this._slotsGapLen, $this._slots_0.length);
  }
  function slotIndex(_this_, $this, address) {
    return address >= _get_capacity_($this) ? $this._slots_0.length - $this._slotsGapLen | 0 : dataAnchorToDataIndex($this, slotAnchor(_this_, address), $this._slotsGapLen, $this._slots_0.length);
  }
  function updateDataIndex(_this_, $this, address, dataIndex_1) {
    updateDataAnchor(_this_, address, dataIndexToDataAnchor($this, dataIndex_1, $this._slotsGapStart, $this._slotsGapLen, $this._slots_0.length));
  }
  function nodeIndex(_this_, $this, address) {
    return dataIndex_0(_this_, $this, address);
  }
  function auxIndex(_this_, $this, address) {
    return dataIndex_0(_this_, $this, address) + countOneBits_0(groupInfo(_this_, address) >> 29) | 0;
  }
  function dataIndexToDataAnchor($this, index, gapStart, gapLen, capacity) {
    return index > gapStart ? -(((capacity - gapLen | 0) - index | 0) + 1 | 0) | 0 : index;
  }
  function dataAnchorToDataIndex($this, anchor, gapLen, capacity) {
    return anchor < 0 ? ((capacity - gapLen | 0) + anchor | 0) + 1 | 0 : anchor;
  }
  function parentIndexToAnchor($this, index, gapStart) {
    return index < gapStart ? index : -(($this._get_size__52() - index | 0) - -2 | 0) | 0;
  }
  function parentAnchorToIndex($this, index) {
    return index > -2 ? index : ($this._get_size__52() + index | 0) - -2 | 0;
  }
  function _no_name_provided__199($start, $end, this$0) {
    this._$start = $start;
    this._$end = $end;
    this._this$0_35 = this$0;
    this._current_1 = this._$start;
  }
  _no_name_provided__199.prototype.hasNext_41 = function () {
    return this._current_1 < this._$end;
  };
  _no_name_provided__199.prototype.next_46 = function () {
    var tmp;
    if (this.hasNext_41()) {
      var tmp_0 = this._this$0_35._slots_0;
      var tmp0_this = this;
      var tmp1 = tmp0_this._current_1;
      tmp0_this._current_1 = tmp1 + 1 | 0;
      tmp = tmp_0[dataIndexToDataAddress(this._this$0_35, tmp1)];
    } else {
      tmp = null;
    }
    return tmp;
  };
  _no_name_provided__199.$metadata$ = {
    kind: 'class',
    interfaces: [Iterator_2]
  };
  function SlotWriter(table) {
    this._table = table;
    this._groups = this._table._groups_0;
    this._slots_0 = this._table._slots_1;
    this._anchors = this._table._anchors_0;
    this._groupGapStart = this._table._groupsSize;
    this._groupGapLen = (this._groups.length / 5 | 0) - this._table._groupsSize | 0;
    this._currentGroupEnd = this._table._groupsSize;
    this._currentSlot = 0;
    this._currentSlotEnd = 0;
    this._slotsGapStart = this._table._slotsSize;
    this._slotsGapLen = this._slots_0.length - this._table._slotsSize | 0;
    this._slotsGapOwner = this._table._groupsSize;
    this._insertCount = 0;
    this._nodeCount_0 = 0;
    this._startStack = new IntStack();
    this._endStack = new IntStack();
    this._nodeCountStack = new IntStack();
    this._currentGroup = 0;
    this._parent_1 = -1;
    this._closed = false;
  }
  SlotWriter.prototype.groupKey_1 = function (index) {
    return key(this._groups, groupIndexToAddress(this, index));
  };
  SlotWriter.prototype.groupObjectKey_1 = function (index) {
    var address = groupIndexToAddress(this, index);
    return hasObjectKey(this._groups, address) ? this._slots_0[objectKeyIndex(this._groups, address)] : null;
  };
  SlotWriter.prototype.groupSize_1 = function (index) {
    return groupSize(this._groups, groupIndexToAddress(this, index));
  };
  SlotWriter.prototype.groupAux_1 = function (index) {
    var address = groupIndexToAddress(this, index);
    return hasAux(this._groups, address) ? this._slots_0[auxIndex(this._groups, this, address)] : Companion_getInstance_25()._Empty;
  };
  SlotWriter.prototype.node_2 = function (index) {
    var address = groupIndexToAddress(this, index);
    return isNode(this._groups, address) ? this._slots_0[dataIndexToDataAddress(this, nodeIndex(this._groups, this, address))] : null;
  };
  SlotWriter.prototype.node_1 = function (anchor) {
    return this.node_2(anchor.toIndexFor_0(this));
  };
  SlotWriter.prototype.parent_1 = function (index) {
    return parent(this._groups, this, index);
  };
  SlotWriter.prototype.close_7 = function () {
    this._closed = true;
    moveGroupGapTo(this, this._get_size__52());
    moveSlotGapTo(this, this._slots_0.length - this._slotsGapLen | 0, this._groupGapStart);
    this._table.close_2(this, this._groups, this._groupGapStart, this._slots_0, this._slotsGapStart, this._anchors);
  };
  SlotWriter.prototype.update_0 = function (value_0) {
    var result = this.skip();
    this.set_5(value_0);
    return result;
  };
  SlotWriter.prototype.updateAux = function (value_0) {
    var address = groupIndexToAddress(this, this._currentGroup);
    var tmp0_check_0 = hasAux(this._groups, address);
    if (!tmp0_check_0) {
      var message_1 = 'Updating the data of a group that was not created with a data slot';
      throw IllegalStateException_init_$Create$_0(toString_2(message_1));
    }this._slots_0[dataIndexToDataAddress(this, auxIndex(this._groups, this, address))] = value_0;
  };
  SlotWriter.prototype.insertAux = function (value_0) {
    var tmp0_check_0 = this._insertCount >= 0;
    if (!tmp0_check_0) {
      var message_1 = 'Cannot insert auxiliary data when not inserting';
      throw IllegalStateException_init_$Create$_0(toString_2(message_1));
    }var parent_0 = this._parent_1;
    var parentGroupAddress = groupIndexToAddress(this, parent_0);
    var tmp1_check_0 = !hasAux(this._groups, parentGroupAddress);
    if (!tmp1_check_0) {
      var message_1_0 = 'Group already has auxiliary data';
      throw IllegalStateException_init_$Create$_0(toString_2(message_1_0));
    }insertSlots(this, 1, parent_0);
    var auxIndex_1 = auxIndex(this._groups, this, parentGroupAddress);
    var auxAddress = dataIndexToDataAddress(this, auxIndex_1);
    if (this._currentSlot > auxIndex_1) {
      var slotsToMove = this._currentSlot - auxIndex_1 | 0;
      var tmp2_check_0 = slotsToMove < 3;
      if (!tmp2_check_0) {
        var message_1_1 = 'Moving more than two slot not supported';
        throw IllegalStateException_init_$Create$_0(toString_2(message_1_1));
      }if (slotsToMove > 1) {
        this._slots_0[auxAddress + 2 | 0] = this._slots_0[auxAddress + 1 | 0];
      }this._slots_0[auxAddress + 1 | 0] = this._slots_0[auxAddress];
    }addAux(this._groups, parentGroupAddress);
    this._slots_0[auxAddress] = value_0;
    var tmp0_this = this;
    var tmp1 = tmp0_this._currentSlot;
    tmp0_this._currentSlot = tmp1 + 1 | 0;
    Unit_getInstance();
  };
  SlotWriter.prototype.updateNode = function (value_0) {
    return updateNodeOfGroup(this, this._currentGroup, value_0);
  };
  SlotWriter.prototype.updateNode_0 = function (anchor, value_0) {
    return updateNodeOfGroup(this, anchor.toIndexFor_0(this), value_0);
  };
  SlotWriter.prototype.set_5 = function (value_0) {
    var tmp0_check_0 = this._currentSlot <= this._currentSlotEnd;
    if (!tmp0_check_0) {
      var message_1 = 'Writing to an invalid slot';
      throw IllegalStateException_init_$Create$_0(toString_2(message_1));
    }this._slots_0[dataIndexToDataAddress(this, this._currentSlot - 1 | 0)] = value_0;
  };
  SlotWriter.prototype.set_6 = function (index, value_0) {
    var address = groupIndexToAddress(this, this._currentGroup);
    var slotsStart = slotIndex(this._groups, this, address);
    var slotsEnd = dataIndex_0(this._groups, this, groupIndexToAddress(this, this._currentGroup + 1 | 0));
    var slotsIndex = slotsStart + index | 0;
    var tmp0_check_0 = slotsIndex >= slotsStart ? slotsIndex < slotsEnd : false;
    if (!tmp0_check_0) {
      var message_1 = '' + 'Write to an invalid slot index ' + index + ' for group ' + this._currentGroup;
      throw IllegalStateException_init_$Create$_0(toString_2(message_1));
    }var slotAddress = dataIndexToDataAddress(this, slotsIndex);
    var result = this._slots_0[slotAddress];
    this._slots_0[slotAddress] = value_0;
    return result;
  };
  SlotWriter.prototype.skip = function () {
    if (this._insertCount > 0) {
      insertSlots(this, 1, this._parent_1);
    }var tmp = this._slots_0;
    var tmp0_this = this;
    var tmp1 = tmp0_this._currentSlot;
    tmp0_this._currentSlot = tmp1 + 1 | 0;
    return tmp[dataIndexToDataAddress(this, tmp1)];
  };
  SlotWriter.prototype.advanceBy = function (amount) {
    var tmp0_require_0 = amount >= 0;
    if (!tmp0_require_0) {
      var message_1 = 'Cannot seek backwards';
      throw IllegalArgumentException_init_$Create$_0(toString_2(message_1));
    }var tmp1_check_0 = this._insertCount <= 0;
    if (!tmp1_check_0) {
      var message_1_0 = 'Cannot call seek() while inserting';
      throw IllegalStateException_init_$Create$_0(toString_2(message_1_0));
    }var index = this._currentGroup + amount | 0;
    var tmp2_check_0 = index >= this._parent_1 ? index <= this._currentGroupEnd : false;
    if (!tmp2_check_0) {
      var message_1_1 = '' + 'Cannot seek outside the current group (' + this._parent_1 + '-' + this._currentGroupEnd + ')';
      throw IllegalStateException_init_$Create$_0(toString_2(message_1_1));
    }this._currentGroup = index;
    var newSlot = dataIndex_0(this._groups, this, groupIndexToAddress(this, index));
    this._currentSlot = newSlot;
    this._currentSlotEnd = newSlot;
  };
  SlotWriter.prototype.skipToGroupEnd_2 = function () {
    var newGroup = this._currentGroupEnd;
    this._currentGroup = newGroup;
    this._currentSlot = dataIndex_0(this._groups, this, groupIndexToAddress(this, newGroup));
  };
  SlotWriter.prototype.beginInsert = function () {
    var tmp0_this = this;
    var tmp1 = tmp0_this._insertCount;
    tmp0_this._insertCount = tmp1 + 1 | 0;
    if (tmp1 === 0) {
      saveCurrentGroupEnd(this);
    } else {
    }
  };
  SlotWriter.prototype.endInsert = function () {
    var tmp0_check_0 = this._insertCount > 0;
    if (!tmp0_check_0) {
      var message_1 = 'Unbalanced begin/end insert';
      throw IllegalStateException_init_$Create$_0(toString_2(message_1));
    }var tmp0_this = this;
    tmp0_this._insertCount = tmp0_this._insertCount - 1 | 0;
    if (tmp0_this._insertCount === 0) {
      var tmp1_check_0 = this._nodeCountStack._get_size__52() === this._startStack._get_size__52();
      if (!tmp1_check_0) {
        var message_1_0 = 'startGroup/endGroup mismatch while inserting';
        throw IllegalStateException_init_$Create$_0(toString_2(message_1_0));
      }restoreCurrentGroupEnd(this);
      Unit_getInstance();
    } else {
    }
  };
  SlotWriter.prototype.startGroup_1 = function () {
    var tmp0_require_0 = this._insertCount === 0;
    if (!tmp0_require_0) {
      var message_1 = 'Key must be supplied when inserting';
      throw IllegalArgumentException_init_$Create$_0(toString_2(message_1));
    }startGroup_1(this, 0, Companion_getInstance_25()._Empty, false, Companion_getInstance_25()._Empty);
  };
  SlotWriter.prototype.startGroup_0 = function (key_0, dataKey) {
    return startGroup_1(this, key_0, dataKey, false, Companion_getInstance_25()._Empty);
  };
  SlotWriter.prototype.startNode_1 = function (key_0) {
    return startGroup_1(this, 125, key_0, true, Companion_getInstance_25()._Empty);
  };
  SlotWriter.prototype.startData = function (key_0, objectKey_0, aux_0) {
    return startGroup_1(this, key_0, objectKey_0, false, aux_0);
  };
  SlotWriter.prototype.endGroup_0 = function () {
    var inserting = this._insertCount > 0;
    var currentGroup = this._currentGroup;
    var currentGroupEnd = this._currentGroupEnd;
    var groupIndex = this._parent_1;
    var groupAddress = groupIndexToAddress(this, groupIndex);
    var newNodes = this._nodeCount_0;
    var newGroupSize = currentGroup - groupIndex | 0;
    var isNode_0 = isNode(this._groups, groupAddress);
    if (inserting) {
      updateGroupSize(this._groups, groupAddress, newGroupSize);
      updateNodeCount_0(this._groups, groupAddress, newNodes);
      this._nodeCount_0 = this._nodeCountStack.pop_0() + (isNode_0 ? 1 : newNodes) | 0;
      this._parent_1 = parent(this._groups, this, groupIndex);
    } else {
      var tmp0_require_0 = currentGroup === currentGroupEnd;
      if (!tmp0_require_0) {
        var message_1 = 'Expected to be at the end of a group';
        throw IllegalArgumentException_init_$Create$_0(toString_2(message_1));
      }var oldGroupSize = groupSize(this._groups, groupAddress);
      var oldNodes = nodeCount(this._groups, groupAddress);
      updateGroupSize(this._groups, groupAddress, newGroupSize);
      updateNodeCount_0(this._groups, groupAddress, newNodes);
      var newParent = this._startStack.pop_0();
      restoreCurrentGroupEnd(this);
      Unit_getInstance();
      this._parent_1 = newParent;
      var groupParent = parent(this._groups, this, groupIndex);
      this._nodeCount_0 = this._nodeCountStack.pop_0();
      if (groupParent === newParent) {
        var tmp0_this = this;
        tmp0_this._nodeCount_0 = tmp0_this._nodeCount_0 + (isNode_0 ? 0 : newNodes - oldNodes | 0) | 0;
      } else {
        var groupSizeDelta = newGroupSize - oldGroupSize | 0;
        var nodesDelta = isNode_0 ? 0 : newNodes - oldNodes | 0;
        if (!(groupSizeDelta === 0) ? true : !(nodesDelta === 0)) {
          var current_0 = groupParent;
          while ((!(current_0 === 0) ? !(current_0 === newParent) : false) ? !(nodesDelta === 0) ? true : !(groupSizeDelta === 0) : false) {
            var currentAddress = groupIndexToAddress(this, current_0);
            if (!(groupSizeDelta === 0)) {
              var newSize = groupSize(this._groups, currentAddress) + groupSizeDelta | 0;
              updateGroupSize(this._groups, currentAddress, newSize);
            }if (!(nodesDelta === 0)) {
              updateNodeCount_0(this._groups, currentAddress, nodeCount(this._groups, currentAddress) + nodesDelta | 0);
            }if (isNode(this._groups, currentAddress))
              nodesDelta = 0;
            current_0 = parent(this._groups, this, current_0);
          }
        }var tmp1_this = this;
        tmp1_this._nodeCount_0 = tmp1_this._nodeCount_0 + nodesDelta | 0;
      }
    }
    return newNodes;
  };
  SlotWriter.prototype.ensureStarted = function (index) {
    var tmp0_require_0 = this._insertCount <= 0;
    if (!tmp0_require_0) {
      var message_1 = 'Cannot call ensureStarted() while inserting';
      throw IllegalArgumentException_init_$Create$_0(toString_2(message_1));
    }var parent_0 = this._parent_1;
    if (!(parent_0 === index)) {
      var tmp1_require_0 = index >= parent_0 ? index < this._currentGroupEnd : false;
      if (!tmp1_require_0) {
        var message_1_0 = '' + 'Started group must be a subgroup of the group at ' + parent_0;
        throw IllegalArgumentException_init_$Create$_0(toString_2(message_1_0));
      }var oldCurrent = this._currentGroup;
      var oldCurrentSlot = this._currentSlot;
      var oldCurrentSlotEnd = this._currentSlotEnd;
      this._currentGroup = index;
      this.startGroup_1();
      this._currentGroup = oldCurrent;
      this._currentSlot = oldCurrentSlot;
      this._currentSlotEnd = oldCurrentSlotEnd;
    }};
  SlotWriter.prototype.ensureStarted_0 = function (anchor) {
    return this.ensureStarted(anchor.toIndexFor_0(this));
  };
  SlotWriter.prototype.skipGroup_0 = function () {
    var groupAddress = groupIndexToAddress(this, this._currentGroup);
    var newGroup = this._currentGroup + groupSize(this._groups, groupAddress) | 0;
    this._currentGroup = newGroup;
    this._currentSlot = dataIndex_0(this._groups, this, groupIndexToAddress(this, newGroup));
    return isNode(this._groups, groupAddress) ? 1 : nodeCount(this._groups, groupAddress);
  };
  SlotWriter.prototype.removeGroup = function () {
    var tmp0_require_0 = this._insertCount === 0;
    if (!tmp0_require_0) {
      var message_1 = 'Cannot remove group while inserting';
      throw IllegalArgumentException_init_$Create$_0(toString_2(message_1));
    }var oldGroup = this._currentGroup;
    var oldSlot = this._currentSlot;
    var count_0 = this.skipGroup_0();
    var anchorsRemoved = removeGroups(this, oldGroup, this._currentGroup - oldGroup | 0);
    removeSlots(this, oldSlot, this._currentSlot - oldSlot | 0, oldGroup - 1 | 0);
    this._currentGroup = oldGroup;
    this._currentSlot = oldSlot;
    var tmp0_this = this;
    tmp0_this._nodeCount_0 = tmp0_this._nodeCount_0 - count_0 | 0;
    return anchorsRemoved;
  };
  SlotWriter.prototype.groupSlots = function () {
    var start_0 = dataIndex_0(this._groups, this, groupIndexToAddress(this, this._currentGroup));
    var end_0 = dataIndex_0(this._groups, this, groupIndexToAddress(this, this._currentGroup + this.groupSize_1(this._currentGroup) | 0));
    return new _no_name_provided__199(start_0, end_0, this);
  };
  SlotWriter.prototype.moveGroup = function (offset) {
    var tmp0_require_0 = this._insertCount === 0;
    if (!tmp0_require_0) {
      var message_1 = 'Cannot move a group while inserting';
      throw IllegalArgumentException_init_$Create$_0(toString_2(message_1));
    }var tmp1_require_0 = offset >= 0;
    if (!tmp1_require_0) {
      var message_1_0 = 'Parameter offset is out of bounds';
      throw IllegalArgumentException_init_$Create$_0(toString_2(message_1_0));
    }if (offset === 0)
      return Unit_getInstance();
    var current_0 = this._currentGroup;
    var parent_0 = this._parent_1;
    var parentEnd = this._currentGroupEnd;
    var count_0 = offset;
    var groupToMove = current_0;
    while (count_0 > 0) {
      groupToMove = groupToMove + groupSize(this._groups, groupIndexToAddress(this, groupToMove)) | 0;
      var tmp2_require_0 = groupToMove <= parentEnd;
      if (!tmp2_require_0) {
        var message_1_1 = 'Parameter offset is out of bounds';
        throw IllegalArgumentException_init_$Create$_0(toString_2(message_1_1));
      }var tmp0 = count_0;
      count_0 = tmp0 - 1 | 0;
      Unit_getInstance();
    }
    var moveLen = groupSize(this._groups, groupIndexToAddress(this, groupToMove));
    var currentSlot = this._currentSlot;
    var dataStart = dataIndex_0(this._groups, this, groupIndexToAddress(this, groupToMove));
    var dataEnd = dataIndex_0(this._groups, this, groupIndexToAddress(this, groupToMove + moveLen | 0));
    var moveDataLen = dataEnd - dataStart | 0;
    var tmp3_max_0 = this._currentGroup - 1 | 0;
    var tmp4_max_0 = 0;
    insertSlots(this, moveDataLen, function () {
      var $externalVarargReceiverTmp = Math;
      return $externalVarargReceiverTmp.max.apply($externalVarargReceiverTmp, [].concat([].slice.call(new Int32Array([tmp3_max_0, tmp4_max_0]))));
    }.call(this));
    insertGroups(this, moveLen);
    var groups = this._groups;
    var moveLocationAddress = groupIndexToAddress(this, groupToMove + moveLen | 0);
    var moveLocationOffset = imul(moveLocationAddress, 5);
    var currentAddress = groupIndexToAddress(this, current_0);
    var tmp5_copyInto_0 = imul(currentAddress, 5);
    var tmp6_copyInto_0 = moveLocationOffset + imul(moveLen, 5) | 0;
    var tmp = groups;
    arrayCopy_0(tmp, groups, tmp5_copyInto_0, moveLocationOffset, tmp6_copyInto_0);
    Unit_getInstance();
    if (moveDataLen > 0) {
      var slots = this._slots_0;
      var tmp7_copyInto_0 = dataIndexToDataAddress(this, dataStart + moveDataLen | 0);
      var tmp8_copyInto_0 = dataIndexToDataAddress(this, dataEnd + moveDataLen | 0);
      arrayCopy_0(slots, slots, currentSlot, tmp7_copyInto_0, tmp8_copyInto_0);
      Unit_getInstance();
    }var dataMoveDistance = (dataStart + moveDataLen | 0) - currentSlot | 0;
    var slotsGapStart = this._slotsGapStart;
    var slotsGapLen = this._slotsGapLen;
    var slotsCapacity = this._slots_0.length;
    var slotsGapOwner = this._slotsGapOwner;
    var inductionVariable = current_0;
    var last_2 = current_0 + moveLen | 0;
    if (inductionVariable < last_2)
      do {
        var group = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        var groupAddress = groupIndexToAddress(this, group);
        var oldIndex = dataIndex_0(groups, this, groupAddress);
        var newIndex = oldIndex - dataMoveDistance | 0;
        var newAnchor = dataIndexToDataAnchor(this, newIndex, slotsGapOwner < groupAddress ? 0 : slotsGapStart, slotsGapLen, slotsCapacity);
        updateDataIndex(groups, this, groupAddress, newAnchor);
      }
       while (inductionVariable < last_2);
    moveAnchors(this, groupToMove + moveLen | 0, current_0, moveLen);
    var anchorsRemoved = removeGroups(this, groupToMove + moveLen | 0, moveLen);
    var tmp9_check_0 = !anchorsRemoved;
    if (!tmp9_check_0) {
      var message_1_2 = 'Unexpectedly removed anchors';
      throw IllegalStateException_init_$Create$_0(toString_2(message_1_2));
    }fixParentAnchorsFor(this, parent_0, this._currentGroupEnd, current_0);
    if (moveDataLen > 0) {
      removeSlots(this, dataStart + moveDataLen | 0, moveDataLen, (groupToMove + moveLen | 0) - 1 | 0);
    }};
  SlotWriter.prototype.moveFrom = function (table, index) {
    var tmp0_require_0 = this._insertCount > 0;
    if (!tmp0_require_0) {
      var message_1_1 = 'Failed requirement.';
      throw IllegalArgumentException_init_$Create$_0(toString_2(message_1_1));
    }if ((index === 0 ? this._currentGroup === 0 : false) ? this._table._groupsSize === 0 : false) {
      var myGroups = this._groups;
      var mySlots = this._slots_0;
      var myAnchors = this._anchors;
      var groups = table._groups_0;
      var groupsSize = table._groupsSize;
      var slots = table._slots_1;
      var slotsSize = table._slotsSize;
      this._groups = groups;
      this._slots_0 = slots;
      this._anchors = table._anchors_0;
      this._groupGapStart = groupsSize;
      this._groupGapLen = (groups.length / 5 | 0) - groupsSize | 0;
      this._slotsGapStart = slotsSize;
      this._slotsGapLen = slots.length - slotsSize | 0;
      this._slotsGapOwner = groupsSize;
      table.setTo(myGroups, 0, mySlots, 0, myAnchors);
      return this._anchors;
    }var tmp1_let_0 = table.openWriter();
    var tmp;
    try {
      var groupsToMove_3 = tmp1_let_0.groupSize_1(index);
      var sourceGroupsEnd_4 = index + groupsToMove_3 | 0;
      var sourceSlotsStart_5 = dataIndex(tmp1_let_0, index);
      var sourceSlotsEnd_6 = dataIndex(tmp1_let_0, sourceGroupsEnd_4);
      var slotsToMove_7 = sourceSlotsEnd_6 - sourceSlotsStart_5 | 0;
      insertGroups(this, groupsToMove_3);
      insertSlots(this, slotsToMove_7, this._currentGroup);
      var groups_8 = this._groups;
      var currentGroup_9 = this._currentGroup;
      var tmp0_copyInto_0_10 = tmp1_let_0._groups;
      var tmp1_copyInto_0_11 = imul(currentGroup_9, 5);
      var tmp2_copyInto_0_12 = imul(index, 5);
      var tmp3_copyInto_0_13 = imul(sourceGroupsEnd_4, 5);
      var tmp_0 = tmp0_copyInto_0_10;
      arrayCopy_0(tmp_0, groups_8, tmp1_copyInto_0_11, tmp2_copyInto_0_12, tmp3_copyInto_0_13);
      Unit_getInstance();
      var slots_14 = this._slots_0;
      var currentSlot_15 = this._currentSlot;
      var tmp4_copyInto_0_16 = tmp1_let_0._slots_0;
      arrayCopy_0(tmp4_copyInto_0_16, slots_14, currentSlot_15, sourceSlotsStart_5, sourceSlotsEnd_6);
      Unit_getInstance();
      updateParentAnchor(groups_8, currentGroup_9, this._parent_1);
      var parentDelta_17 = currentGroup_9 - index | 0;
      var moveEnd_18 = currentGroup_9 + groupsToMove_3 | 0;
      var dataIndexDelta_19 = currentSlot_15 - dataIndex_0(groups_8, this, currentGroup_9) | 0;
      var slotsGapOwner_20 = this._slotsGapOwner;
      var slotsGapLen_21 = this._slotsGapLen;
      var slotsCapacity_22 = slots_14.length;
      var inductionVariable = currentGroup_9;
      if (inductionVariable < moveEnd_18)
        do {
          var groupAddress_24 = inductionVariable;
          inductionVariable = inductionVariable + 1 | 0;
          if (!(groupAddress_24 === currentGroup_9)) {
            var previousParent_25 = parentAnchor(groups_8, groupAddress_24);
            updateParentAnchor(groups_8, groupAddress_24, previousParent_25 + parentDelta_17 | 0);
          }var newDataIndex_26 = dataIndex_0(groups_8, this, groupAddress_24) + dataIndexDelta_19 | 0;
          var newDataAnchor_27 = dataIndexToDataAnchor(this, newDataIndex_26, slotsGapOwner_20 < groupAddress_24 ? 0 : this._slotsGapStart, slotsGapLen_21, slotsCapacity_22);
          updateDataAnchor(groups_8, groupAddress_24, newDataAnchor_27);
          if (groupAddress_24 === slotsGapOwner_20) {
            var tmp1_28 = slotsGapOwner_20;
            slotsGapOwner_20 = tmp1_28 + 1 | 0;
            Unit_getInstance();
          }}
         while (inductionVariable < moveEnd_18);
      this._slotsGapOwner = slotsGapOwner_20;
      var startAnchors_29 = locationOf(table._anchors_0, index, table._groupsSize);
      var endAnchors_30 = locationOf(table._anchors_0, sourceGroupsEnd_4, table._groupsSize);
      var tmp_1;
      if (startAnchors_29 < endAnchors_30) {
        var sourceAnchors_32 = table._anchors_0;
        var anchors_33 = ArrayList_init_$Create$_0(endAnchors_30 - startAnchors_29 | 0);
        var anchorDelta_34 = currentGroup_9 - index | 0;
        var inductionVariable_0 = startAnchors_29;
        if (inductionVariable_0 < endAnchors_30)
          do {
            var anchorIndex_36 = inductionVariable_0;
            inductionVariable_0 = inductionVariable_0 + 1 | 0;
            var sourceAnchor_37 = sourceAnchors_32.get_72(anchorIndex_36);
            var tmp3_this_38 = sourceAnchor_37;
            tmp3_this_38._location_0 = tmp3_this_38._location_0 + anchorDelta_34 | 0;
            anchors_33.add_39(sourceAnchor_37);
            Unit_getInstance();
          }
           while (inductionVariable_0 < endAnchors_30);
        var insertLocation_39 = locationOf(this._anchors, this._currentGroup, this._get_size__52());
        this._table._anchors_0.addAll_8(insertLocation_39, anchors_33);
        Unit_getInstance();
        sourceAnchors_32.subList_8(startAnchors_29, endAnchors_30).clear_9();
        tmp_1 = anchors_33;
      } else {
        tmp_1 = emptyList();
      }
      var anchors_31 = tmp_1;
      var parentGroup_40 = tmp1_let_0.parent_1(index);
      if (parentGroup_40 >= 0) {
        tmp1_let_0.startGroup_1();
        tmp1_let_0.advanceBy(parentGroup_40 - tmp1_let_0._currentGroup | 0);
        tmp1_let_0.startGroup_1();
      }tmp1_let_0.advanceBy(index - tmp1_let_0._currentGroup | 0);
      var anchorsRemoved_41 = tmp1_let_0.removeGroup();
      if (parentGroup_40 >= 0) {
        tmp1_let_0.skipToGroupEnd_2();
        tmp1_let_0.endGroup_0();
        Unit_getInstance();
        tmp1_let_0.skipToGroupEnd_2();
        tmp1_let_0.endGroup_0();
        Unit_getInstance();
      }var tmp5_check_0_42 = !anchorsRemoved_41;
      if (!tmp5_check_0_42) {
        var message_1_43 = 'Unexpectedly removed anchors';
        throw IllegalStateException_init_$Create$_0(toString_2(message_1_43));
      }var tmp4_this_44 = this;
      tmp4_this_44._nodeCount_0 = tmp4_this_44._nodeCount_0 + (isNode(groups_8, currentGroup_9) ? 1 : nodeCount(groups_8, currentGroup_9)) | 0;
      this._currentGroup = currentGroup_9 + groupsToMove_3 | 0;
      this._currentSlot = currentSlot_15 + slotsToMove_7 | 0;
      tmp = anchors_31;
    }finally {
      tmp1_let_0.close_7();
    }
    return tmp;
  };
  SlotWriter.prototype.anchor_0 = function (index) {
    var tmp0_getOrAdd_0 = this._anchors;
    var tmp1_getOrAdd_0 = this._get_size__52();
    var location_1 = search(tmp0_getOrAdd_0, index, tmp1_getOrAdd_0);
    var tmp;
    if (location_1 < 0) {
      var anchor_2 = new Anchor(index <= this._groupGapStart ? index : -(this._get_size__52() - index | 0) | 0);
      tmp0_getOrAdd_0.add_21(-(location_1 + 1 | 0) | 0, anchor_2);
      tmp = anchor_2;
    } else {
      tmp = tmp0_getOrAdd_0.get_72(location_1);
    }
    return tmp;
  };
  SlotWriter.prototype.anchorIndex_0 = function (anchor) {
    var tmp0_let_0 = anchor._location_0;
    return tmp0_let_0 < 0 ? this._get_size__52() + tmp0_let_0 | 0 : tmp0_let_0;
  };
  SlotWriter.prototype.toString = function () {
    return '' + 'SlotWriter(current = ' + this._currentGroup + ' end=' + this._currentGroupEnd + ' size = ' + this._get_size__52() + ' ' + ('' + 'gap=' + this._groupGapStart + '-' + (this._groupGapStart + this._groupGapLen | 0) + ')');
  };
  SlotWriter.prototype._get_size__52 = function () {
    return _get_capacity_(this) - this._groupGapLen | 0;
  };
  SlotWriter.$metadata$ = {
    simpleName: 'SlotWriter',
    kind: 'class',
    interfaces: []
  };
  function SlotTable() {
    this._groups_0 = new Int32Array(0);
    this._groupsSize = 0;
    var tmp = this;
    var tmp_0 = 0;
    var tmp_1 = 0;
    var tmp_2 = fillArrayVal(Array(tmp_1), null);
    while (tmp_0 < tmp_1) {
      var tmp_3 = tmp_0;
      tmp_2[tmp_3] = null;
      tmp_0 = tmp_0 + 1 | 0;
    }
    tmp._slots_1 = tmp_2;
    this._slotsSize = 0;
    this._readers = 0;
    this._writer_0 = false;
    this._version = 0;
    var tmp_4 = this;
    tmp_4._anchors_0 = ArrayList_init_$Create$();
  }
  SlotTable.prototype._get_isEmpty__3 = function () {
    return this._groupsSize === 0;
  };
  SlotTable.prototype.openReader = function () {
    if (this._writer_0) {
      var tmp0_error_0 = 'Cannot read while a writer is pending';
      throw IllegalStateException_init_$Create$_0(toString_2(tmp0_error_0));
    }var tmp0_this = this;
    var tmp1 = tmp0_this._readers;
    tmp0_this._readers = tmp1 + 1 | 0;
    Unit_getInstance();
    return new SlotReader(this);
  };
  SlotTable.prototype.openWriter = function () {
    var tmp0_check_0 = !this._writer_0;
    if (!tmp0_check_0) {
      var message_1 = 'Cannot start a writer when another writer is pending';
      throw IllegalStateException_init_$Create$_0(toString_2(message_1));
    }var tmp1_check_0 = this._readers <= 0;
    if (!tmp1_check_0) {
      var message_1_0 = 'Cannot start a writer when a reader is pending';
      throw IllegalStateException_init_$Create$_0(toString_2(message_1_0));
    }this._writer_0 = true;
    var tmp0_this = this;
    var tmp1 = tmp0_this._version;
    tmp0_this._version = tmp1 + 1 | 0;
    Unit_getInstance();
    return new SlotWriter(this);
  };
  SlotTable.prototype.anchorIndex_0 = function (anchor) {
    var tmp0_check_0 = !this._writer_0;
    if (!tmp0_check_0) {
      var message_1 = 'Use active SlotWriter to determine anchor location instead';
      throw IllegalStateException_init_$Create$_0(toString_2(message_1));
    }var tmp1_require_0 = anchor._get_valid__1();
    if (!tmp1_require_0) {
      var message_1_0 = 'Anchor refers to a group that was removed';
      throw IllegalArgumentException_init_$Create$_0(toString_2(message_1_0));
    }return anchor._location_0;
  };
  SlotTable.prototype.ownsAnchor = function (anchor) {
    var tmp;
    if (anchor._get_valid__1()) {
      var tmp0_let_0 = search(this._anchors_0, anchor._location_0, this._groupsSize);
      tmp = tmp0_let_0 >= 0 ? equals_0(this._anchors_0.get_72(tmp0_let_0), anchor) : false;
    } else {
      tmp = false;
    }
    return tmp;
  };
  SlotTable.prototype.close_1 = function (reader) {
    var tmp0_require_0 = reader._table_0 === this ? this._readers > 0 : false;
    if (!tmp0_require_0) {
      var message_1 = 'Unexpected reader close()';
      throw IllegalArgumentException_init_$Create$_0(toString_2(message_1));
    }var tmp0_this = this;
    var tmp1 = tmp0_this._readers;
    tmp0_this._readers = tmp1 - 1 | 0;
    Unit_getInstance();
  };
  SlotTable.prototype.close_2 = function (writer, groups, groupsSize, slots, slotsSize, anchors) {
    var tmp0_require_0 = writer._table === this ? this._writer_0 : false;
    if (!tmp0_require_0) {
      var message_1 = 'Unexpected writer close()';
      throw IllegalArgumentException_init_$Create$_0(toString_2(message_1));
    }this._writer_0 = false;
    this.setTo(groups, groupsSize, slots, slotsSize, anchors);
  };
  SlotTable.prototype.setTo = function (groups, groupsSize, slots, slotsSize, anchors) {
    this._groups_0 = groups;
    this._groupsSize = groupsSize;
    this._slots_1 = slots;
    this._slotsSize = slotsSize;
    this._anchors_0 = anchors;
  };
  SlotTable.prototype.iterator_66 = function () {
    return new GroupIterator(this, 0, this._groupsSize);
  };
  SlotTable.$metadata$ = {
    simpleName: 'SlotTable',
    kind: 'class',
    interfaces: [CompositionData, Iterable]
  };
  function Anchor(loc) {
    this._location_0 = loc;
  }
  Anchor.prototype._get_valid__1 = function () {
    return !(this._location_0 === IntCompanionObject_getInstance()._MIN_VALUE_2);
  };
  Anchor.prototype.toIndexFor = function (slots) {
    return slots.anchorIndex_0(this);
  };
  Anchor.prototype.toIndexFor_0 = function (writer) {
    return writer.anchorIndex_0(this);
  };
  Anchor.$metadata$ = {
    simpleName: 'Anchor',
    kind: 'class',
    interfaces: []
  };
  function isNode(_this_, address) {
    return !((_this_[imul(address, 5) + 1 | 0] & 1073741824) === 0);
  }
  function key(_this_, address) {
    return _this_[imul(address, 5)];
  }
  function hasObjectKey(_this_, address) {
    return !((_this_[imul(address, 5) + 1 | 0] & 536870912) === 0);
  }
  function objectKeyIndex(_this_, address) {
    var tmp0_let_0 = imul(address, 5);
    return _this_[tmp0_let_0 + 4 | 0] + countOneBits_0(_this_[tmp0_let_0 + 1 | 0] >> 30) | 0;
  }
  function groupSize(_this_, address) {
    return _this_[imul(address, 5) + 3 | 0];
  }
  function hasAux(_this_, address) {
    return !((_this_[imul(address, 5) + 1 | 0] & 268435456) === 0);
  }
  function addAux(_this_, address) {
    var arrayIndex = imul(address, 5) + 1 | 0;
    _this_[arrayIndex] = _this_[arrayIndex] | 268435456;
  }
  function initGroup(_this_, address, key_0, isNode_0, hasDataKey, hasData, parentAnchor_0, dataAnchor_0) {
    var nodeBit = isNode_0 ? 1073741824 : 0;
    var dataKeyBit = hasDataKey ? 536870912 : 0;
    var dataBit = hasData ? 268435456 : 0;
    var arrayIndex = imul(address, 5);
    _this_[arrayIndex + 0 | 0] = key_0;
    _this_[arrayIndex + 1 | 0] = nodeBit | dataKeyBit | dataBit;
    _this_[arrayIndex + 2 | 0] = parentAnchor_0;
    _this_[arrayIndex + 3 | 0] = 0;
    _this_[arrayIndex + 4 | 0] = dataAnchor_0;
  }
  function nodeCount(_this_, address) {
    return _this_[imul(address, 5) + 1 | 0] & 134217727;
  }
  function updateGroupSize(_this_, address, value_0) {
    var tmp0_require_0 = value_0 >= 0;
    if (!tmp0_require_0) {
      var message_1_1 = 'Failed requirement.';
      throw IllegalArgumentException_init_$Create$_0(toString_2(message_1_1));
    }_this_[imul(address, 5) + 3 | 0] = value_0;
  }
  function updateNodeCount_0(_this_, address, value_0) {
    var tmp0_require_0 = value_0 >= 0 ? value_0 < 134217727 : false;
    if (!tmp0_require_0) {
      var message_1_1 = 'Failed requirement.';
      throw IllegalArgumentException_init_$Create$_0(toString_2(message_1_1));
    }_this_[imul(address, 5) + 1 | 0] = _this_[imul(address, 5) + 1 | 0] & -134217728 | value_0;
  }
  function updateParentAnchor(_this_, address, value_0) {
    _this_[imul(address, 5) + 2 | 0] = value_0;
  }
  function parentAnchor(_this_, address) {
    return _this_[imul(address, 5) + 2 | 0];
  }
  function updateDataAnchor(_this_, address, anchor) {
    _this_[imul(address, 5) + 4 | 0] = anchor;
  }
  function locationOf(_this_, index, effectiveSize) {
    var tmp0_let_0 = search(_this_, index, effectiveSize);
    return tmp0_let_0 >= 0 ? tmp0_let_0 : -(tmp0_let_0 + 1 | 0) | 0;
  }
  function dataAnchor(_this_, address) {
    return _this_[imul(address, 5) + 4 | 0];
  }
  function slotAnchor(_this_, address) {
    var tmp0_let_0 = imul(address, 5);
    return _this_[tmp0_let_0 + 4 | 0] + countOneBits_0(_this_[tmp0_let_0 + 1 | 0] >> 28) | 0;
  }
  function countOneBits_0(value_0) {
    var tmp0_subject = value_0;
    switch (tmp0_subject) {
      case 0:
        return 0;
      case 1:
        return 1;
      case 2:
        return 1;
      case 3:
        return 2;
      case 4:
        return 1;
      case 5:
        return 2;
      case 6:
        return 2;
      default:return 3;
    }
  }
  function groupInfo(_this_, address) {
    return _this_[imul(address, 5) + 1 | 0];
  }
  function node(_this_, $this, index) {
    var tmp;
    if (isNode(_this_, index)) {
      tmp = $this._slots_2[nodeIndex_0(_this_, index)];
    } else {
      tmp = Companion_getInstance_25()._Empty;
    }
    return tmp;
  }
  function aux(_this_, $this, index) {
    var tmp;
    if (hasAux(_this_, index)) {
      tmp = $this._slots_2[auxIndex_0(_this_, index)];
    } else {
      tmp = Companion_getInstance_25()._Empty;
    }
    return tmp;
  }
  function objectKey(_this_, $this, index) {
    var tmp;
    if (hasObjectKey(_this_, index)) {
      tmp = $this._slots_2[objectKeyIndex(_this_, index)];
    } else {
      tmp = null;
    }
    return tmp;
  }
  function SlotReader(table) {
    this._table_0 = table;
    this._groups_1 = this._table_0._groups_0;
    this._groupsSize_0 = this._table_0._groupsSize;
    this._slots_2 = this._table_0._slots_1;
    this._slotsSize_0 = this._table_0._slotsSize;
    this._currentGroup_0 = 0;
    this._currentEnd = this._groupsSize_0;
    this._parent_2 = -1;
    this._emptyCount = 0;
    this._currentSlot_0 = 0;
    this._currentSlotEnd_0 = 0;
  }
  SlotReader.prototype._get_size__52 = function () {
    return this._groupsSize_0;
  };
  SlotReader.prototype.parent_1 = function (index) {
    return parentAnchor(this._groups_1, index);
  };
  SlotReader.prototype._get_isNode_ = function () {
    return isNode(this._groups_1, this._currentGroup_0);
  };
  SlotReader.prototype.isNode_0 = function (index) {
    return isNode(this._groups_1, index);
  };
  SlotReader.prototype.nodeCount = function (index) {
    return nodeCount(this._groups_1, index);
  };
  SlotReader.prototype.node_2 = function (index) {
    return isNode(this._groups_1, index) ? node(this._groups_1, this, index) : null;
  };
  SlotReader.prototype._get_isGroupEnd_ = function () {
    return this._get_inEmpty_() ? true : this._currentGroup_0 === this._currentEnd;
  };
  SlotReader.prototype._get_inEmpty_ = function () {
    return this._emptyCount > 0;
  };
  SlotReader.prototype._get_groupSize_ = function () {
    return groupSize(this._groups_1, this._currentGroup_0);
  };
  SlotReader.prototype.groupSize_1 = function (index) {
    return groupSize(this._groups_1, index);
  };
  SlotReader.prototype._get_groupEnd_ = function () {
    return this._currentEnd;
  };
  SlotReader.prototype._get_groupKey_ = function () {
    var tmp;
    if (this._currentGroup_0 < this._currentEnd) {
      tmp = key(this._groups_1, this._currentGroup_0);
    } else {
      tmp = 0;
    }
    return tmp;
  };
  SlotReader.prototype.groupKey_1 = function (index) {
    return key(this._groups_1, index);
  };
  SlotReader.prototype._get_groupSlotIndex_ = function () {
    return this._currentSlot_0 - slotAnchor(this._groups_1, this._parent_2) | 0;
  };
  SlotReader.prototype.hasObjectKey = function (index) {
    return hasObjectKey(this._groups_1, index);
  };
  SlotReader.prototype._get_groupObjectKey_ = function () {
    return this._currentGroup_0 < this._currentEnd ? objectKey(this._groups_1, this, this._currentGroup_0) : null;
  };
  SlotReader.prototype.groupObjectKey_1 = function (index) {
    return objectKey(this._groups_1, this, index);
  };
  SlotReader.prototype._get_groupAux_ = function () {
    return this._currentGroup_0 < this._currentEnd ? aux(this._groups_1, this, this._currentGroup_0) : 0;
  };
  SlotReader.prototype.groupAux_1 = function (index) {
    return aux(this._groups_1, this, index);
  };
  SlotReader.prototype._get_parentNodes_ = function () {
    return this._parent_2 >= 0 ? nodeCount(this._groups_1, this._parent_2) : 0;
  };
  SlotReader.prototype.next_46 = function () {
    if (this._emptyCount > 0 ? true : this._currentSlot_0 >= this._currentSlotEnd_0)
      return Companion_getInstance_25()._Empty;
    var tmp0_this = this;
    var tmp1 = tmp0_this._currentSlot_0;
    tmp0_this._currentSlot_0 = tmp1 + 1 | 0;
    return this._slots_2[tmp1];
  };
  SlotReader.prototype.beginEmpty = function () {
    var tmp0_this = this;
    var tmp1 = tmp0_this._emptyCount;
    tmp0_this._emptyCount = tmp1 + 1 | 0;
    Unit_getInstance();
  };
  SlotReader.prototype.endEmpty = function () {
    var tmp0_require_0 = this._emptyCount > 0;
    if (!tmp0_require_0) {
      var message_1 = 'Unbalanced begin/end empty';
      throw IllegalArgumentException_init_$Create$_0(toString_2(message_1));
    }var tmp0_this = this;
    var tmp1 = tmp0_this._emptyCount;
    tmp0_this._emptyCount = tmp1 - 1 | 0;
    Unit_getInstance();
  };
  SlotReader.prototype.close_7 = function () {
    return this._table_0.close_1(this);
  };
  SlotReader.prototype.startGroup_1 = function () {
    if (this._emptyCount <= 0) {
      var tmp0_require_0 = parentAnchor(this._groups_1, this._currentGroup_0) === this._parent_2;
      if (!tmp0_require_0) {
        var message_1 = 'Invalid slot table detected';
        throw IllegalArgumentException_init_$Create$_0(toString_2(message_1));
      }this._parent_2 = this._currentGroup_0;
      this._currentEnd = this._currentGroup_0 + groupSize(this._groups_1, this._currentGroup_0) | 0;
      var tmp0_this = this;
      var tmp1 = tmp0_this._currentGroup_0;
      tmp0_this._currentGroup_0 = tmp1 + 1 | 0;
      var current_0 = tmp1;
      this._currentSlot_0 = slotAnchor(this._groups_1, current_0);
      this._currentSlotEnd_0 = current_0 >= (this._groupsSize_0 - 1 | 0) ? this._slotsSize_0 : dataAnchor(this._groups_1, current_0 + 1 | 0);
    }};
  SlotReader.prototype.startNode_2 = function () {
    if (this._emptyCount <= 0) {
      var tmp0_require_0 = isNode(this._groups_1, this._currentGroup_0);
      if (!tmp0_require_0) {
        var message_1 = 'Expected a node group';
        throw IllegalArgumentException_init_$Create$_0(toString_2(message_1));
      }this.startGroup_1();
    }};
  SlotReader.prototype.skipGroup_0 = function () {
    var tmp0_require_0 = this._emptyCount === 0;
    if (!tmp0_require_0) {
      var message_1 = 'Cannot skip while in an empty region';
      throw IllegalArgumentException_init_$Create$_0(toString_2(message_1));
    }var count_0 = isNode(this._groups_1, this._currentGroup_0) ? 1 : nodeCount(this._groups_1, this._currentGroup_0);
    var tmp0_this = this;
    tmp0_this._currentGroup_0 = tmp0_this._currentGroup_0 + groupSize(this._groups_1, this._currentGroup_0) | 0;
    return count_0;
  };
  SlotReader.prototype.skipToGroupEnd_2 = function () {
    var tmp0_require_0 = this._emptyCount === 0;
    if (!tmp0_require_0) {
      var message_1 = 'Cannot skip the enclosing group while in an empty region';
      throw IllegalArgumentException_init_$Create$_0(toString_2(message_1));
    }this._currentGroup_0 = this._currentEnd;
  };
  SlotReader.prototype.reposition = function (index) {
    var tmp0_require_0 = this._emptyCount === 0;
    if (!tmp0_require_0) {
      var message_1 = 'Cannot reposition while in an empty region';
      throw IllegalArgumentException_init_$Create$_0(toString_2(message_1));
    }this._currentGroup_0 = index;
    var parent_0 = index < this._groupsSize_0 ? parentAnchor(this._groups_1, index) : -1;
    this._parent_2 = parent_0;
    if (parent_0 < 0)
      this._currentEnd = this._groupsSize_0;
    else
      this._currentEnd = parent_0 + groupSize(this._groups_1, parent_0) | 0;
    this._currentSlot_0 = 0;
    this._currentSlotEnd_0 = 0;
  };
  SlotReader.prototype.restoreParent = function (index) {
    var newCurrentEnd = index + groupSize(this._groups_1, index) | 0;
    var current_0 = this._currentGroup_0;
    var tmp0_require_0 = current_0 >= index ? current_0 <= newCurrentEnd : false;
    if (!tmp0_require_0) {
      var message_1 = '' + 'Index ' + index + ' is not a parent of ' + current_0;
      throw IllegalArgumentException_init_$Create$_0(toString_2(message_1));
    }this._parent_2 = index;
    this._currentEnd = newCurrentEnd;
    this._currentSlot_0 = 0;
    this._currentSlotEnd_0 = 0;
  };
  SlotReader.prototype.endGroup_1 = function () {
    if (this._emptyCount === 0) {
      var tmp0_require_0 = this._currentGroup_0 === this._currentEnd;
      if (!tmp0_require_0) {
        var message_1 = 'endGroup() not called at the end of a group';
        throw IllegalArgumentException_init_$Create$_0(toString_2(message_1));
      }var parent_0 = parentAnchor(this._groups_1, this._parent_2);
      this._parent_2 = parent_0;
      this._currentEnd = parent_0 < 0 ? this._groupsSize_0 : parent_0 + groupSize(this._groups_1, parent_0) | 0;
    }};
  SlotReader.prototype.extractKeys = function () {
    var result = ArrayList_init_$Create$();
    if (this._emptyCount > 0)
      return result;
    var index = 0;
    var childIndex = this._currentGroup_0;
    while (childIndex < this._currentEnd) {
      var tmp = key(this._groups_1, childIndex);
      var tmp_0 = objectKey(this._groups_1, this, childIndex);
      var tmp_1 = childIndex;
      var tmp_2 = isNode(this._groups_1, childIndex) ? 1 : nodeCount(this._groups_1, childIndex);
      var tmp0 = index;
      index = tmp0 + 1 | 0;
      result.add_39(new KeyInfo(tmp, tmp_0, tmp_1, tmp_2, tmp0));
      Unit_getInstance();
      childIndex = childIndex + groupSize(this._groups_1, childIndex) | 0;
    }
    return result;
  };
  SlotReader.prototype.anchor_0 = function (index) {
    var tmp0_getOrAdd_0 = this._table_0._anchors_0;
    var tmp1_getOrAdd_0 = this._groupsSize_0;
    var location_1 = search(tmp0_getOrAdd_0, index, tmp1_getOrAdd_0);
    var tmp;
    if (location_1 < 0) {
      var anchor_2 = new Anchor(index);
      tmp0_getOrAdd_0.add_21(-(location_1 + 1 | 0) | 0, anchor_2);
      tmp = anchor_2;
    } else {
      tmp = tmp0_getOrAdd_0.get_72(location_1);
    }
    return tmp;
  };
  SlotReader.$metadata$ = {
    simpleName: 'SlotReader',
    kind: 'class',
    interfaces: []
  };
  function search(_this_, location, effectiveSize) {
    var low = 0;
    var high = _this_._get_size__52() - 1 | 0;
    while (low <= high) {
      var mid = (low + high | 0) >>> 1;
      var tmp0_let_0 = _this_.get_72(mid)._location_0;
      var midVal = tmp0_let_0 < 0 ? effectiveSize + tmp0_let_0 | 0 : tmp0_let_0;
      var cmp = compareTo(midVal, location);
      if (cmp < 0)
        low = mid + 1 | 0;
      else if (cmp > 0)
        high = mid - 1 | 0;
      else
        return mid;
    }
    return -(low + 1 | 0) | 0;
  }
  function nodeIndex_0(_this_, address) {
    return _this_[imul(address, 5) + 4 | 0];
  }
  function auxIndex_0(_this_, address) {
    var tmp0_let_0 = imul(address, 5);
    return tmp0_let_0 >= _this_.length ? _this_.length : _this_[tmp0_let_0 + 4 | 0] + countOneBits_0(_this_[tmp0_let_0 + 1 | 0] >> 29) | 0;
  }
  function validateRead($this) {
    if (!($this._table_1._version === $this._version_0)) {
      throw ConcurrentModificationException_init_$Create$();
    }}
  function _no_name_provided__200(this$0, $group) {
    this._this$0_36 = this$0;
    this._$group = $group;
  }
  _no_name_provided__200.prototype.iterator_66 = function () {
    validateRead(this._this$0_36);
    return new GroupIterator(this._this$0_36._table_1, this._$group + 1 | 0, this._$group + groupSize(this._this$0_36._table_1._groups_0, this._$group) | 0);
  };
  _no_name_provided__200.$metadata$ = {
    kind: 'class',
    interfaces: [CompositionGroup, Iterable]
  };
  function GroupIterator(table, start_0, end_0) {
    this._table_1 = table;
    this._end = end_0;
    this._index_8 = start_0;
    this._version_0 = this._table_1._version;
    if (this._table_1._writer_0)
      throw ConcurrentModificationException_init_$Create$();
  }
  GroupIterator.prototype.hasNext_41 = function () {
    return this._index_8 < this._end;
  };
  GroupIterator.prototype.next_46 = function () {
    validateRead(this);
    var group = this._index_8;
    var tmp0_this = this;
    tmp0_this._index_8 = tmp0_this._index_8 + groupSize(this._table_1._groups_0, group) | 0;
    return new _no_name_provided__200(this, group);
  };
  GroupIterator.$metadata$ = {
    simpleName: 'GroupIterator',
    kind: 'class',
    interfaces: [Iterator_2]
  };
  function KeyInfo(key_0, objectKey_0, location, nodes, index) {
    this._key_2 = key_0;
    this._objectKey = objectKey_0;
    this._location_1 = location;
    this._nodes = nodes;
    this._index_9 = index;
  }
  KeyInfo.$metadata$ = {
    simpleName: 'KeyInfo',
    kind: 'class',
    interfaces: []
  };
  function State_1() {
  }
  State_1.$metadata$ = {
    simpleName: 'State',
    kind: 'interface',
    interfaces: []
  };
  function MutableState() {
  }
  MutableState.$metadata$ = {
    simpleName: 'MutableState',
    kind: 'interface',
    interfaces: [State_1]
  };
  function mutableStateOf(value_0, policy) {
    return createSnapshotMutableState(value_0, policy);
  }
  function mutableStateOf$default(value_0, policy, $mask0, $handler) {
    if (!(($mask0 & 2) === 0))
      policy = structuralEqualityPolicy();
    return mutableStateOf(value_0, policy);
  }
  function SnapshotMutationPolicy() {
  }
  SnapshotMutationPolicy.prototype.merge = function (previous, current_0, applied) {
    return null;
  };
  SnapshotMutationPolicy.$metadata$ = {
    simpleName: 'SnapshotMutationPolicy',
    kind: 'interface',
    interfaces: []
  };
  function structuralEqualityPolicy() {
    var tmp = StructuralEqualityPolicy_getInstance();
    return isInterface(tmp, SnapshotMutationPolicy) ? tmp : THROW_CCE();
  }
  function StructuralEqualityPolicy() {
    StructuralEqualityPolicy_instance = this;
  }
  StructuralEqualityPolicy.prototype.equivalent_0 = function (a, b) {
    return equals_0(a, b);
  };
  StructuralEqualityPolicy.prototype.equivalent_1 = function (a, b) {
    var tmp = (a == null ? true : isObject(a)) ? a : THROW_CCE();
    return this.equivalent_0(tmp, (b == null ? true : isObject(b)) ? b : THROW_CCE());
  };
  StructuralEqualityPolicy.prototype.toString = function () {
    return 'StructuralEqualityPolicy';
  };
  StructuralEqualityPolicy.$metadata$ = {
    simpleName: 'StructuralEqualityPolicy',
    kind: 'object',
    interfaces: [SnapshotMutationPolicy]
  };
  var StructuralEqualityPolicy_instance;
  function StructuralEqualityPolicy_getInstance() {
    if (StructuralEqualityPolicy_instance == null)
      new StructuralEqualityPolicy();
    return StructuralEqualityPolicy_instance;
  }
  function StateStateRecord(myValue) {
    StateRecord.call(this);
    this._value_7 = myValue;
  }
  StateStateRecord.prototype.create_8 = function () {
    return new StateStateRecord(this._value_7);
  };
  StateStateRecord.$metadata$ = {
    simpleName: 'StateStateRecord',
    kind: 'class',
    interfaces: []
  };
  function SnapshotMutableStateImpl(value_0, policy) {
    this._policy = policy;
    this._next_3 = new StateStateRecord(value_0);
  }
  SnapshotMutableStateImpl.prototype._get_policy_ = function () {
    return this._policy;
  };
  SnapshotMutableStateImpl.prototype._set_value__6 = function (value_0) {
    var tmp0_withCurrent_0 = this._next_3;
    var tmp1__anonymous__1 = current(tmp0_withCurrent_0, Companion_getInstance_30()._get_current__2());
    var tmp;
    if (!this._get_policy_().equivalent_1(tmp1__anonymous__1._value_7, value_0)) {
      var tmp2_overwritable_0_2 = this._next_3;
      var snapshot_1_3 = snapshotInitializer;
      var tmp0_synchronized_0_5 = lock;
      snapshot_1_3 = Companion_getInstance_30()._get_current__2();
      var tmp3__anonymous__4_6 = overwritableRecord(tmp2_overwritable_0_2, this, snapshot_1_3, tmp1__anonymous__1);
      tmp3__anonymous__4_6._value_7 = value_0;
      var tmp1_also_0_4 = Unit_getInstance();
      notifyWrite(snapshot_1_3, this);
      tmp = tmp1_also_0_4;
    }return tmp;
  };
  SnapshotMutableStateImpl.prototype._get_value__33 = function () {
    return readable_0(this._next_3, this)._value_7;
  };
  SnapshotMutableStateImpl.prototype._get_firstStateRecord__0 = function () {
    return this._next_3;
  };
  SnapshotMutableStateImpl.prototype.prependStateRecord_0 = function (value_0) {
    var tmp = this;
    tmp._next_3 = value_0 instanceof StateStateRecord ? value_0 : THROW_CCE();
  };
  SnapshotMutableStateImpl.prototype.mergeRecords_0 = function (previous, current_0, applied) {
    var previousRecord = previous instanceof StateStateRecord ? previous : THROW_CCE();
    var currentRecord = current_0 instanceof StateStateRecord ? current_0 : THROW_CCE();
    var appliedRecord = applied instanceof StateStateRecord ? applied : THROW_CCE();
    var tmp;
    if (this._get_policy_().equivalent_1(currentRecord._value_7, appliedRecord._value_7)) {
      tmp = current_0;
    } else {
      var merged = this._get_policy_().merge(previousRecord._value_7, currentRecord._value_7, appliedRecord._value_7);
      var tmp_0;
      if (!(merged == null)) {
        var tmp0_also_0 = appliedRecord.create_8();
        (tmp0_also_0 instanceof StateStateRecord ? tmp0_also_0 : THROW_CCE())._value_7 = merged;
        tmp_0 = tmp0_also_0;
      } else {
        tmp_0 = null;
      }
      tmp = tmp_0;
    }
    return tmp;
  };
  SnapshotMutableStateImpl.$metadata$ = {
    simpleName: 'SnapshotMutableStateImpl',
    kind: 'class',
    interfaces: [StateObject, SnapshotMutableState]
  };
  function IntStack() {
    this._slots_3 = new Int32Array(10);
    this._tos = 0;
  }
  IntStack.prototype._get_size__52 = function () {
    return this._tos;
  };
  IntStack.prototype.push = function (value_0) {
    if (this._tos >= this._slots_3.length) {
      this._slots_3 = copyOf(this._slots_3, imul(this._slots_3.length, 2));
    }var tmp = this._slots_3;
    var tmp0_this = this;
    var tmp1 = tmp0_this._tos;
    tmp0_this._tos = tmp1 + 1 | 0;
    tmp[tmp1] = value_0;
  };
  IntStack.prototype.pop_0 = function () {
    var tmp = this._slots_3;
    var tmp0_this = this;
    tmp0_this._tos = tmp0_this._tos - 1 | 0;
    return tmp[tmp0_this._tos];
  };
  IntStack.prototype.peekOr = function (default_0) {
    return this._tos > 0 ? this.peek_0() : default_0;
  };
  IntStack.prototype.peek_0 = function () {
    return this._slots_3[this._tos - 1 | 0];
  };
  IntStack.prototype.isEmpty_44 = function () {
    return this._tos === 0;
  };
  IntStack.prototype.clear_9 = function () {
    this._tos = 0;
  };
  IntStack.$metadata$ = {
    simpleName: 'IntStack',
    kind: 'class',
    interfaces: []
  };
  function Stack() {
    this._backing = ArrayList_init_$Create$();
  }
  Stack.prototype._get_size__52 = function () {
    return this._backing._get_size__52();
  };
  Stack.prototype.push_0 = function (value_0) {
    return this._backing.add_39(value_0);
  };
  Stack.prototype.pop_0 = function () {
    return this._backing.removeAt_2(this._get_size__52() - 1 | 0);
  };
  Stack.prototype.peek_0 = function () {
    return this._backing.get_72(this._get_size__52() - 1 | 0);
  };
  Stack.prototype.peek_1 = function (index) {
    return this._backing.get_72(index);
  };
  Stack.prototype.isEmpty_44 = function () {
    return this._backing.isEmpty_44();
  };
  Stack.prototype.isNotEmpty_0 = function () {
    return !this.isEmpty_44();
  };
  Stack.prototype.clear_9 = function () {
    return this._backing.clear_9();
  };
  Stack.prototype.toArray_3 = function () {
    var tmp = 0;
    var tmp_0 = this._backing._get_size__52();
    var tmp_1 = fillArrayVal(Array(tmp_0), null);
    while (tmp < tmp_0) {
      var tmp_2 = tmp;
      tmp_1[tmp_2] = this._backing.get_72(tmp_2);
      tmp = tmp + 1 | 0;
    }
    var tmp_3 = tmp_1;
    return isArray(tmp_3) ? tmp_3 : THROW_CCE();
  };
  Stack.$metadata$ = {
    simpleName: 'Stack',
    kind: 'class',
    interfaces: []
  };
  function _get_current_($this) {
    var tmp0_getValue_0 = $this._current$delegate;
    var tmp1_getValue_0 = current$factory();
    return tmp0_getValue_0._get_value__33();
  }
  function LazyValueHolder(valueProducer) {
    this._current$delegate = lazy(valueProducer);
  }
  LazyValueHolder.prototype._get_value__33 = function () {
    return _get_current_(this);
  };
  LazyValueHolder.$metadata$ = {
    simpleName: 'LazyValueHolder',
    kind: 'class',
    interfaces: [State_1]
  };
  function current$factory() {
    return getPropertyCallableRef('current', 1, KProperty1, function (receiver) {
      return _get_current_(receiver);
    }, null);
  }
  function find($this, key_0) {
    var low = 0;
    var high = $this._size_1 - 1 | 0;
    var valueIdentity = identityHashCode_0(key_0);
    while (low <= high) {
      var mid = (low + high | 0) >>> 1;
      var midVal = $this._keys_0[mid];
      var comparison = identityHashCode_0(midVal) - valueIdentity | 0;
      if (comparison < 0)
        low = mid + 1 | 0;
      else if (comparison > 0)
        high = mid - 1 | 0;
      else if (midVal === key_0)
        return mid;
      else
        return findExactIndex($this, mid, key_0, valueIdentity);
    }
    return -(low + 1 | 0) | 0;
  }
  function findExactIndex($this, midIndex, value_0, valueHash) {
    var inductionVariable = midIndex - 1 | 0;
    if (0 <= inductionVariable)
      $l$break: do {
        var i = inductionVariable;
        inductionVariable = inductionVariable + -1 | 0;
        var v = $this._keys_0[i];
        if (v === value_0) {
          return i;
        }if (!(identityHashCode_0(v) === valueHash)) {
          break $l$break;
        }}
       while (0 <= inductionVariable);
    var inductionVariable_0 = midIndex + 1 | 0;
    var last_2 = $this._size_1;
    if (inductionVariable_0 < last_2)
      do {
        var i_0 = inductionVariable_0;
        inductionVariable_0 = inductionVariable_0 + 1 | 0;
        var v_0 = $this._keys_0[i_0];
        if (v_0 === value_0) {
          return i_0;
        }if (!(identityHashCode_0(v_0) === valueHash)) {
          return -(i_0 + 1 | 0) | 0;
        }}
       while (inductionVariable_0 < last_2);
    return -($this._size_1 + 1 | 0) | 0;
  }
  function IdentityArrayIntMap() {
    this._size_1 = 0;
    var tmp = this;
    var tmp0_arrayOfNulls_0 = 4;
    tmp._keys_0 = fillArrayVal(Array(tmp0_arrayOfNulls_0), null);
    this._values_0 = new Int32Array(4);
  }
  IdentityArrayIntMap.prototype.add_35 = function (key_0, value_0) {
    var index;
    if (this._size_1 > 0) {
      index = find(this, key_0);
      if (index >= 0) {
        this._values_0[index] = value_0;
        return Unit_getInstance();
      }} else {
      index = -1;
    }
    var insertIndex = -(index + 1 | 0) | 0;
    if (this._size_1 === this._keys_0.length) {
      var tmp0_arrayOfNulls_0 = imul(this._keys_0.length, 2);
      var newKeys = fillArrayVal(Array(tmp0_arrayOfNulls_0), null);
      var newValues = new Int32Array(imul(this._keys_0.length, 2));
      var tmp1_copyInto_0 = this._keys_0;
      var tmp2_copyInto_0 = insertIndex + 1 | 0;
      var tmp3_copyInto_0 = this._size_1;
      arrayCopy_0(tmp1_copyInto_0, newKeys, tmp2_copyInto_0, insertIndex, tmp3_copyInto_0);
      Unit_getInstance();
      var tmp4_copyInto_0 = this._values_0;
      var tmp5_copyInto_0 = insertIndex + 1 | 0;
      var tmp6_copyInto_0 = this._size_1;
      var tmp = tmp4_copyInto_0;
      arrayCopy_0(tmp, newValues, tmp5_copyInto_0, insertIndex, tmp6_copyInto_0);
      Unit_getInstance();
      var tmp7_copyInto_0 = this._keys_0;
      var tmp8_copyInto_0 = 0;
      var tmp9_copyInto_0 = 0;
      arrayCopy_0(tmp7_copyInto_0, newKeys, tmp8_copyInto_0, tmp9_copyInto_0, insertIndex);
      Unit_getInstance();
      var tmp10_copyInto_0 = this._values_0;
      var tmp11_copyInto_0 = 0;
      var tmp12_copyInto_0 = 0;
      var tmp_0 = tmp10_copyInto_0;
      arrayCopy_0(tmp_0, newValues, tmp11_copyInto_0, tmp12_copyInto_0, insertIndex);
      Unit_getInstance();
      this._keys_0 = newKeys;
      this._values_0 = newValues;
    } else {
      var tmp13_copyInto_0 = this._keys_0;
      var tmp14_copyInto_0 = this._keys_0;
      var tmp15_copyInto_0 = insertIndex + 1 | 0;
      var tmp16_copyInto_0 = this._size_1;
      arrayCopy_0(tmp13_copyInto_0, tmp14_copyInto_0, tmp15_copyInto_0, insertIndex, tmp16_copyInto_0);
      Unit_getInstance();
      var tmp17_copyInto_0 = this._values_0;
      var tmp18_copyInto_0 = this._values_0;
      var tmp19_copyInto_0 = insertIndex + 1 | 0;
      var tmp20_copyInto_0 = this._size_1;
      var tmp_1 = tmp17_copyInto_0;
      arrayCopy_0(tmp_1, tmp18_copyInto_0, tmp19_copyInto_0, insertIndex, tmp20_copyInto_0);
      Unit_getInstance();
    }
    this._keys_0[insertIndex] = key_0;
    this._values_0[insertIndex] = value_0;
    var tmp0_this = this;
    var tmp1 = tmp0_this._size_1;
    tmp0_this._size_1 = tmp1 + 1 | 0;
    Unit_getInstance();
  };
  IdentityArrayIntMap.$metadata$ = {
    simpleName: 'IdentityArrayIntMap',
    kind: 'class',
    interfaces: []
  };
  function find_0($this, value_0) {
    var low = 0;
    var high = $this._size_2 - 1 | 0;
    var valueIdentity = identityHashCode_0(value_0);
    while (low <= high) {
      var mid = (low + high | 0) >>> 1;
      var midVal = $this.get_72(mid);
      var comparison = identityHashCode_0(midVal) - valueIdentity | 0;
      if (comparison < 0)
        low = mid + 1 | 0;
      else if (comparison > 0)
        high = mid - 1 | 0;
      else if (midVal === value_0)
        return mid;
      else
        return findExactIndex_0($this, mid, value_0, valueIdentity);
    }
    return -(low + 1 | 0) | 0;
  }
  function findExactIndex_0($this, midIndex, value_0, valueHash) {
    var inductionVariable = midIndex - 1 | 0;
    if (0 <= inductionVariable)
      $l$break: do {
        var i = inductionVariable;
        inductionVariable = inductionVariable + -1 | 0;
        var v = $this._values_1[i];
        if (v === value_0) {
          return i;
        }if (!(identityHashCode_0(v) === valueHash)) {
          break $l$break;
        }}
       while (0 <= inductionVariable);
    var inductionVariable_0 = midIndex + 1 | 0;
    var last_2 = $this._size_2;
    if (inductionVariable_0 < last_2)
      do {
        var i_0 = inductionVariable_0;
        inductionVariable_0 = inductionVariable_0 + 1 | 0;
        var v_0 = $this._values_1[i_0];
        if (v_0 === value_0) {
          return i_0;
        }if (!(identityHashCode_0(v_0) === valueHash)) {
          return -(i_0 + 1 | 0) | 0;
        }}
       while (inductionVariable_0 < last_2);
    return -($this._size_2 + 1 | 0) | 0;
  }
  function _no_name_provided__201(this$0) {
    this._this$0_37 = this$0;
    this._index_10 = 0;
  }
  _no_name_provided__201.prototype.hasNext_41 = function () {
    return this._index_10 < this._this$0_37._size_2;
  };
  _no_name_provided__201.prototype.next_46 = function () {
    var tmp = this._this$0_37._values_1;
    var tmp0_this = this;
    var tmp1 = tmp0_this._index_10;
    tmp0_this._index_10 = tmp1 + 1 | 0;
    var tmp_0 = tmp[tmp1];
    return isObject(tmp_0) ? tmp_0 : THROW_CCE();
  };
  _no_name_provided__201.$metadata$ = {
    kind: 'class',
    interfaces: [Iterator_2]
  };
  function IdentityArraySet() {
    this._size_2 = 0;
    var tmp = this;
    var tmp0_arrayOfNulls_0 = 16;
    tmp._values_1 = fillArrayVal(Array(tmp0_arrayOfNulls_0), null);
  }
  IdentityArraySet.prototype._get_size__52 = function () {
    return this._size_2;
  };
  IdentityArraySet.prototype.contains_44 = function (element) {
    return find_0(this, element) >= 0;
  };
  IdentityArraySet.prototype.contains_51 = function (element) {
    if (!isObject(element))
      return false;
    else {
    }
    return this.contains_44(isObject(element) ? element : THROW_CCE());
  };
  IdentityArraySet.prototype.get_72 = function (index) {
    var tmp = this._values_1[index];
    return isObject(tmp) ? tmp : THROW_CCE();
  };
  IdentityArraySet.prototype.add_36 = function (value_0) {
    var index;
    if (this._size_2 > 0) {
      index = find_0(this, value_0);
      if (index >= 0) {
        return false;
      }} else {
      index = -1;
    }
    var insertIndex = -(index + 1 | 0) | 0;
    if (this._size_2 === this._values_1.length) {
      var tmp0_arrayOfNulls_0 = imul(this._values_1.length, 2);
      var newSorted = fillArrayVal(Array(tmp0_arrayOfNulls_0), null);
      var tmp1_copyInto_0 = this._values_1;
      var tmp2_copyInto_0 = insertIndex + 1 | 0;
      var tmp3_copyInto_0 = this._size_2;
      arrayCopy_0(tmp1_copyInto_0, newSorted, tmp2_copyInto_0, insertIndex, tmp3_copyInto_0);
      Unit_getInstance();
      var tmp4_copyInto_0 = this._values_1;
      var tmp5_copyInto_0 = 0;
      var tmp6_copyInto_0 = 0;
      arrayCopy_0(tmp4_copyInto_0, newSorted, tmp5_copyInto_0, tmp6_copyInto_0, insertIndex);
      Unit_getInstance();
      this._values_1 = newSorted;
    } else {
      var tmp7_copyInto_0 = this._values_1;
      var tmp8_copyInto_0 = this._values_1;
      var tmp9_copyInto_0 = insertIndex + 1 | 0;
      var tmp10_copyInto_0 = this._size_2;
      arrayCopy_0(tmp7_copyInto_0, tmp8_copyInto_0, tmp9_copyInto_0, insertIndex, tmp10_copyInto_0);
      Unit_getInstance();
    }
    this._values_1[insertIndex] = value_0;
    var tmp0_this = this;
    var tmp1 = tmp0_this._size_2;
    tmp0_this._size_2 = tmp1 + 1 | 0;
    Unit_getInstance();
    return true;
  };
  IdentityArraySet.prototype.isEmpty_44 = function () {
    return this._size_2 === 0;
  };
  IdentityArraySet.prototype.isNotEmpty_0 = function () {
    return this._size_2 > 0;
  };
  IdentityArraySet.prototype.remove_41 = function (value_0) {
    var index = find_0(this, value_0);
    if (index >= 0) {
      if (index < (this._size_2 - 1 | 0)) {
        var tmp0_copyInto_0 = this._values_1;
        var tmp1_copyInto_0 = this._values_1;
        var tmp2_copyInto_0 = index + 1 | 0;
        var tmp3_copyInto_0 = this._size_2;
        arrayCopy_0(tmp0_copyInto_0, tmp1_copyInto_0, index, tmp2_copyInto_0, tmp3_copyInto_0);
        Unit_getInstance();
      }var tmp0_this = this;
      var tmp1 = tmp0_this._size_2;
      tmp0_this._size_2 = tmp1 - 1 | 0;
      Unit_getInstance();
      this._values_1[this._size_2] = null;
      return true;
    }return false;
  };
  IdentityArraySet.prototype.containsAll_32 = function (elements) {
    var tmp$ret$0;
    l$ret$1: do {
      var tmp;
      if (isInterface(elements, Collection)) {
        tmp = elements.isEmpty_44();
      } else {
        {
          tmp = false;
        }
      }
      if (tmp) {
        tmp$ret$0 = true;
        break l$ret$1;
      } else {
      }
      var tmp0_iterator_1 = elements.iterator_66();
      while (tmp0_iterator_1.hasNext_41()) {
        var element_2 = tmp0_iterator_1.next_46();
        if (!this.contains_44(element_2)) {
          tmp$ret$0 = false;
          break l$ret$1;
        } else {
        }
      }
      tmp$ret$0 = true;
    }
     while (false);
    return tmp$ret$0;
  };
  IdentityArraySet.prototype.containsAll_36 = function (elements) {
    return this.containsAll_32(elements);
  };
  IdentityArraySet.prototype.iterator_66 = function () {
    return new _no_name_provided__201(this);
  };
  IdentityArraySet.$metadata$ = {
    simpleName: 'IdentityArraySet',
    kind: 'class',
    interfaces: [Set]
  };
  function scopeSetAt($this, index) {
    return ensureNotNull($this._scopeSets[$this._valueOrder[index]]);
  }
  function getOrCreateIdentitySet($this, value_0) {
    var index;
    if ($this._size_3 > 0) {
      index = find_1($this, value_0);
      if (index >= 0) {
        return scopeSetAt($this, index);
      }} else {
      index = -1;
    }
    var insertIndex = -(index + 1 | 0) | 0;
    if ($this._size_3 < $this._valueOrder.length) {
      var valueIndex = $this._valueOrder[$this._size_3];
      $this._values_2[valueIndex] = value_0;
      var tmp0_elvis_lhs = $this._scopeSets[valueIndex];
      var tmp;
      if (tmp0_elvis_lhs == null) {
        var tmp0_also_0 = new IdentityArraySet();
        $this._scopeSets[valueIndex] = tmp0_also_0;
        tmp = tmp0_also_0;
      } else {
        tmp = tmp0_elvis_lhs;
      }
      var scopeSet = tmp;
      if (insertIndex < $this._size_3) {
        var tmp1_copyInto_0 = $this._valueOrder;
        var tmp2_copyInto_0 = $this._valueOrder;
        var tmp3_copyInto_0 = insertIndex + 1 | 0;
        var tmp4_copyInto_0 = $this._size_3;
        var tmp_0 = tmp1_copyInto_0;
        arrayCopy_0(tmp_0, tmp2_copyInto_0, tmp3_copyInto_0, insertIndex, tmp4_copyInto_0);
        Unit_getInstance();
      }$this._valueOrder[insertIndex] = valueIndex;
      var tmp1_this = $this;
      var tmp2 = tmp1_this._size_3;
      tmp1_this._size_3 = tmp2 + 1 | 0;
      Unit_getInstance();
      return scopeSet;
    }var newSize = imul($this._valueOrder.length, 2);
    var valueIndex_0 = $this._size_3;
    $this._scopeSets = copyOf_1($this._scopeSets, newSize);
    var scopeSet_0 = new IdentityArraySet();
    $this._scopeSets[valueIndex_0] = scopeSet_0;
    $this._values_2 = copyOf_1($this._values_2, newSize);
    $this._values_2[valueIndex_0] = value_0;
    var newKeyOrder = new Int32Array(newSize);
    var inductionVariable = $this._size_3 + 1 | 0;
    if (inductionVariable < newSize)
      do {
        var i = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        newKeyOrder[i] = i;
      }
       while (inductionVariable < newSize);
    if (insertIndex < $this._size_3) {
      var tmp5_copyInto_0 = $this._valueOrder;
      var tmp6_copyInto_0 = insertIndex + 1 | 0;
      var tmp7_copyInto_0 = $this._size_3;
      var tmp_1 = tmp5_copyInto_0;
      arrayCopy_0(tmp_1, newKeyOrder, tmp6_copyInto_0, insertIndex, tmp7_copyInto_0);
      Unit_getInstance();
    }newKeyOrder[insertIndex] = valueIndex_0;
    if (insertIndex > 0) {
      var tmp8_copyInto_0 = $this._valueOrder;
      var tmp9_copyInto_0 = 0;
      var tmp10_copyInto_0 = 0;
      var tmp_2 = tmp8_copyInto_0;
      arrayCopy_0(tmp_2, newKeyOrder, tmp9_copyInto_0, tmp10_copyInto_0, insertIndex);
      Unit_getInstance();
    }$this._valueOrder = newKeyOrder;
    var tmp4_this = $this;
    var tmp5 = tmp4_this._size_3;
    tmp4_this._size_3 = tmp5 + 1 | 0;
    Unit_getInstance();
    return scopeSet_0;
  }
  function find_1($this, value_0) {
    var valueIdentity = identityHashCode_0(value_0);
    var low = 0;
    var high = $this._size_3 - 1 | 0;
    while (low <= high) {
      var mid = (low + high | 0) >>> 1;
      var midValue = ensureNotNull($this._values_2[$this._valueOrder[mid]]);
      var midValHash = identityHashCode_0(midValue);
      var comparison = midValHash - valueIdentity | 0;
      if (comparison < 0)
        low = mid + 1 | 0;
      else if (comparison > 0)
        high = mid - 1 | 0;
      else if (value_0 === midValue)
        return mid;
      else
        return findExactIndex_1($this, mid, value_0, valueIdentity);
    }
    return -(low + 1 | 0) | 0;
  }
  function findExactIndex_1($this, midIndex, value_0, valueHash) {
    var inductionVariable = midIndex - 1 | 0;
    if (0 <= inductionVariable)
      $l$break: do {
        var i = inductionVariable;
        inductionVariable = inductionVariable + -1 | 0;
        var v = ensureNotNull($this._values_2[$this._valueOrder[i]]);
        if (v === value_0) {
          return i;
        }if (!(identityHashCode_0(v) === valueHash)) {
          break $l$break;
        }}
       while (0 <= inductionVariable);
    var inductionVariable_0 = midIndex + 1 | 0;
    var last_2 = $this._size_3;
    if (inductionVariable_0 < last_2)
      do {
        var i_0 = inductionVariable_0;
        inductionVariable_0 = inductionVariable_0 + 1 | 0;
        var v_0 = ensureNotNull($this._values_2[$this._valueOrder[i_0]]);
        if (v_0 === value_0) {
          return i_0;
        }if (!(identityHashCode_0(v_0) === valueHash)) {
          return -(i_0 + 1 | 0) | 0;
        }}
       while (inductionVariable_0 < last_2);
    return -($this._size_3 + 1 | 0) | 0;
  }
  function IdentityScopeMap() {
    var tmp = this;
    var tmp_0 = 0;
    var tmp_1 = 50;
    var tmp_2 = new Int32Array(tmp_1);
    while (tmp_0 < tmp_1) {
      var tmp_3 = tmp_0;
      tmp_2[tmp_3] = tmp_3;
      tmp_0 = tmp_0 + 1 | 0;
    }
    tmp._valueOrder = tmp_2;
    var tmp_4 = this;
    var tmp0_arrayOfNulls_0 = 50;
    tmp_4._values_2 = fillArrayVal(Array(tmp0_arrayOfNulls_0), null);
    var tmp_5 = this;
    var tmp0_arrayOfNulls_0_0 = 50;
    tmp_5._scopeSets = fillArrayVal(Array(tmp0_arrayOfNulls_0_0), null);
    this._size_3 = 0;
  }
  IdentityScopeMap.prototype.add_37 = function (value_0, scope) {
    var valueSet = getOrCreateIdentitySet(this, value_0);
    return valueSet.add_36(scope);
  };
  IdentityScopeMap.prototype.contains_46 = function (element) {
    return find_1(this, element) >= 0;
  };
  IdentityScopeMap.prototype.remove_42 = function (value_0, scope) {
    var index = find_1(this, value_0);
    if (index >= 0) {
      var valueOrderIndex = this._valueOrder[index];
      var tmp0_elvis_lhs = this._scopeSets[valueOrderIndex];
      var tmp;
      if (tmp0_elvis_lhs == null) {
        return false;
      } else {
        tmp = tmp0_elvis_lhs;
      }
      var set = tmp;
      var removed = set.remove_41(scope);
      if (set._size_2 === 0) {
        var startIndex = index + 1 | 0;
        var endIndex = this._size_3;
        if (startIndex < endIndex) {
          var tmp0_copyInto_0 = this._valueOrder;
          var tmp1_copyInto_0 = this._valueOrder;
          var tmp_0 = tmp0_copyInto_0;
          arrayCopy_0(tmp_0, tmp1_copyInto_0, index, startIndex, endIndex);
          Unit_getInstance();
        }this._valueOrder[this._size_3 - 1 | 0] = valueOrderIndex;
        this._values_2[valueOrderIndex] = null;
        var tmp1_this = this;
        var tmp2 = tmp1_this._size_3;
        tmp1_this._size_3 = tmp2 - 1 | 0;
        Unit_getInstance();
      }return removed;
    }return false;
  };
  IdentityScopeMap.$metadata$ = {
    simpleName: 'IdentityScopeMap',
    kind: 'class',
    interfaces: []
  };
  function ImmutableCollection() {
  }
  ImmutableCollection.$metadata$ = {
    simpleName: 'ImmutableCollection',
    kind: 'interface',
    interfaces: [Collection]
  };
  function PersistentCollection() {
  }
  PersistentCollection.$metadata$ = {
    simpleName: 'PersistentCollection',
    kind: 'interface',
    interfaces: [ImmutableCollection]
  };
  function PersistentMap() {
  }
  PersistentMap.$metadata$ = {
    simpleName: 'PersistentMap',
    kind: 'interface',
    interfaces: [ImmutableMap]
  };
  function ImmutableMap() {
  }
  ImmutableMap.$metadata$ = {
    simpleName: 'ImmutableMap',
    kind: 'interface',
    interfaces: [Map_0]
  };
  function PersistentSet() {
  }
  PersistentSet.$metadata$ = {
    simpleName: 'PersistentSet',
    kind: 'interface',
    interfaces: [ImmutableSet, PersistentCollection]
  };
  function ImmutableSet() {
  }
  ImmutableSet.$metadata$ = {
    simpleName: 'ImmutableSet',
    kind: 'interface',
    interfaces: [Set, ImmutableCollection]
  };
  function persistentHashMapOf() {
    return Companion_getInstance_27().emptyOf();
  }
  function persistentSetOf() {
    return Companion_getInstance_29().emptyOf_0();
  }
  function createEntries($this) {
    return new PersistentHashMapEntries($this);
  }
  function Companion_28() {
    Companion_instance_27 = this;
    this._EMPTY_0 = new PersistentHashMap(Companion_getInstance_28()._EMPTY_1, 0);
  }
  Companion_28.prototype.emptyOf = function () {
    var tmp = this._EMPTY_0;
    return tmp instanceof PersistentHashMap ? tmp : THROW_CCE();
  };
  Companion_28.$metadata$ = {
    simpleName: 'Companion',
    kind: 'object',
    interfaces: []
  };
  var Companion_instance_27;
  function Companion_getInstance_27() {
    if (Companion_instance_27 == null)
      new Companion_28();
    return Companion_instance_27;
  }
  function PersistentHashMap(node_0, size_0) {
    Companion_getInstance_27();
    AbstractMap.call(this);
    this._node = node_0;
    this._size_4 = size_0;
  }
  PersistentHashMap.prototype._get_size__52 = function () {
    return this._size_4;
  };
  PersistentHashMap.prototype._get_values__6 = function () {
    return new PersistentHashMapValues(this);
  };
  PersistentHashMap.prototype._get_entries__5 = function () {
    return createEntries(this);
  };
  PersistentHashMap.prototype.containsKey_7 = function (key_0) {
    var tmp0_safe_receiver_2 = key_0;
    var tmp1_elvis_lhs_1 = tmp0_safe_receiver_2 == null ? null : hashCode(tmp0_safe_receiver_2);
    return this._node.containsKey_8(tmp1_elvis_lhs_1 == null ? 0 : tmp1_elvis_lhs_1, key_0, 0);
  };
  PersistentHashMap.prototype.get_56 = function (key_0) {
    var tmp0_safe_receiver_2 = key_0;
    var tmp1_elvis_lhs_1 = tmp0_safe_receiver_2 == null ? null : hashCode(tmp0_safe_receiver_2);
    return this._node.get_57(tmp1_elvis_lhs_1 == null ? 0 : tmp1_elvis_lhs_1, key_0, 0);
  };
  PersistentHashMap.prototype.put_6 = function (key_0, value_0) {
    var tmp0_safe_receiver_2 = key_0;
    var tmp1_elvis_lhs_1 = tmp0_safe_receiver_2 == null ? null : hashCode(tmp0_safe_receiver_2);
    var tmp0_elvis_lhs = this._node.put_7(tmp1_elvis_lhs_1 == null ? 0 : tmp1_elvis_lhs_1, key_0, value_0, 0);
    var tmp;
    if (tmp0_elvis_lhs == null) {
      return this;
    } else {
      tmp = tmp0_elvis_lhs;
    }
    var newNodeResult = tmp;
    return new PersistentHashMap(newNodeResult._node_0, this._size_4 + newNodeResult._sizeDelta | 0);
  };
  PersistentHashMap.prototype.remove_44 = function (key_0) {
    var tmp0_safe_receiver_2 = key_0;
    var tmp1_elvis_lhs_1 = tmp0_safe_receiver_2 == null ? null : hashCode(tmp0_safe_receiver_2);
    var newNode = this._node.remove_45(tmp1_elvis_lhs_1 == null ? 0 : tmp1_elvis_lhs_1, key_0, 0);
    if (this._node === newNode) {
      return this;
    }if (newNode == null) {
      return Companion_getInstance_27().emptyOf();
    }return new PersistentHashMap(newNode, this._size_4 - 1 | 0);
  };
  PersistentHashMap.$metadata$ = {
    simpleName: 'PersistentHashMap',
    kind: 'class',
    interfaces: [PersistentMap]
  };
  function PersistentHashMapValuesIterator(node_0) {
    var tmp = 0;
    var tmp_0 = 8;
    var tmp_1 = fillArrayVal(Array(tmp_0), null);
    while (tmp < tmp_0) {
      var tmp_2 = tmp;
      tmp_1[tmp_2] = new TrieNodeValuesIterator();
      tmp = tmp + 1 | 0;
    }
    PersistentHashMapBaseIterator.call(this, node_0, tmp_1);
  }
  PersistentHashMapValuesIterator.$metadata$ = {
    simpleName: 'PersistentHashMapValuesIterator',
    kind: 'class',
    interfaces: []
  };
  function PersistentHashMapEntriesIterator(node_0) {
    var tmp = 0;
    var tmp_0 = 8;
    var tmp_1 = fillArrayVal(Array(tmp_0), null);
    while (tmp < tmp_0) {
      var tmp_2 = tmp;
      tmp_1[tmp_2] = new TrieNodeEntriesIterator();
      tmp = tmp + 1 | 0;
    }
    PersistentHashMapBaseIterator.call(this, node_0, tmp_1);
  }
  PersistentHashMapEntriesIterator.$metadata$ = {
    simpleName: 'PersistentHashMapEntriesIterator',
    kind: 'class',
    interfaces: []
  };
  function TrieNodeBaseIterator() {
    this._buffer_1 = Companion_getInstance_28()._EMPTY_1._buffer_2;
    this._dataSize = 0;
    this._index_11 = 0;
  }
  TrieNodeBaseIterator.prototype._get_buffer__6 = function () {
    return this._buffer_1;
  };
  TrieNodeBaseIterator.prototype._set_index__3 = function (_set___) {
    this._index_11 = _set___;
  };
  TrieNodeBaseIterator.prototype._get_index__3 = function () {
    return this._index_11;
  };
  TrieNodeBaseIterator.prototype.reset_4 = function (buffer, dataSize, index) {
    this._buffer_1 = buffer;
    this._dataSize = dataSize;
    this._index_11 = index;
  };
  TrieNodeBaseIterator.prototype.reset_5 = function (buffer, dataSize) {
    this.reset_4(buffer, dataSize, 0);
  };
  TrieNodeBaseIterator.prototype.hasNextKey_1 = function () {
    return this._index_11 < this._dataSize;
  };
  TrieNodeBaseIterator.prototype.hasNextNode_1 = function () {
    assert(this._index_11 >= this._dataSize);
    return this._index_11 < this._buffer_1.length;
  };
  TrieNodeBaseIterator.prototype.currentNode_2 = function () {
    assert(this.hasNextNode_1());
    var tmp = this._buffer_1[this._index_11];
    return tmp instanceof TrieNode ? tmp : THROW_CCE();
  };
  TrieNodeBaseIterator.prototype.moveToNextNode_1 = function () {
    assert(this.hasNextNode_1());
    var tmp0_this = this;
    var tmp1 = tmp0_this._index_11;
    tmp0_this._index_11 = tmp1 + 1 | 0;
    Unit_getInstance();
  };
  TrieNodeBaseIterator.prototype.hasNext_41 = function () {
    return this.hasNextKey_1();
  };
  TrieNodeBaseIterator.$metadata$ = {
    simpleName: 'TrieNodeBaseIterator',
    kind: 'class',
    interfaces: [Iterator_2]
  };
  function TrieNodeValuesIterator() {
    TrieNodeBaseIterator.call(this);
  }
  TrieNodeValuesIterator.prototype.next_46 = function () {
    assert(this.hasNextKey_1());
    var tmp0_this = this;
    tmp0_this._set_index__3(tmp0_this._get_index__3() + 2 | 0);
    var tmp = this._get_buffer__6()[this._get_index__3() - 1 | 0];
    return (tmp == null ? true : isObject(tmp)) ? tmp : THROW_CCE();
  };
  TrieNodeValuesIterator.$metadata$ = {
    simpleName: 'TrieNodeValuesIterator',
    kind: 'class',
    interfaces: []
  };
  function moveToNextNodeWithData($this, pathIndex) {
    if ($this._path[pathIndex].hasNextKey_1()) {
      return pathIndex;
    }if ($this._path[pathIndex].hasNextNode_1()) {
      var node_0 = $this._path[pathIndex].currentNode_2();
      if (pathIndex === 6) {
        $this._path[pathIndex + 1 | 0].reset_5(node_0._buffer_2, node_0._buffer_2.length);
      } else {
        $this._path[pathIndex + 1 | 0].reset_5(node_0._buffer_2, imul(2, node_0.entryCount()));
      }
      return moveToNextNodeWithData($this, pathIndex + 1 | 0);
    }return -1;
  }
  function ensureNextEntryIsReady($this) {
    if ($this._path[$this._pathLastIndex].hasNextKey_1()) {
      return Unit_getInstance();
    }var inductionVariable = $this._pathLastIndex;
    if (0 <= inductionVariable)
      do {
        var i = inductionVariable;
        inductionVariable = inductionVariable + -1 | 0;
        var result = moveToNextNodeWithData($this, i);
        if (result === -1 ? $this._path[i].hasNextNode_1() : false) {
          $this._path[i].moveToNextNode_1();
          result = moveToNextNodeWithData($this, i);
        }if (!(result === -1)) {
          $this._pathLastIndex = result;
          return Unit_getInstance();
        }if (i > 0) {
          $this._path[i - 1 | 0].moveToNextNode_1();
        }$this._path[i].reset_5(Companion_getInstance_28()._EMPTY_1._buffer_2, 0);
      }
       while (0 <= inductionVariable);
    $this._hasNext_0 = false;
  }
  function checkHasNext($this) {
    if (!$this.hasNext_41())
      throw NoSuchElementException_init_$Create$();
  }
  function PersistentHashMapBaseIterator(node_0, path) {
    this._path = path;
    this._pathLastIndex = 0;
    this._hasNext_0 = true;
    this._path[0].reset_5(node_0._buffer_2, imul(2, node_0.entryCount()));
    this._pathLastIndex = 0;
    ensureNextEntryIsReady(this);
  }
  PersistentHashMapBaseIterator.prototype.hasNext_41 = function () {
    return this._hasNext_0;
  };
  PersistentHashMapBaseIterator.prototype.next_46 = function () {
    checkHasNext(this);
    var result = this._path[this._pathLastIndex].next_46();
    ensureNextEntryIsReady(this);
    return result;
  };
  PersistentHashMapBaseIterator.$metadata$ = {
    simpleName: 'PersistentHashMapBaseIterator',
    kind: 'class',
    interfaces: [Iterator_2]
  };
  function MapEntry(key_0, value_0) {
    this._key_3 = key_0;
    this._value_8 = value_0;
  }
  MapEntry.prototype._get_key__35 = function () {
    return this._key_3;
  };
  MapEntry.prototype._get_value__33 = function () {
    return this._value_8;
  };
  MapEntry.prototype.hashCode = function () {
    var tmp0_hashCode_0 = this._get_key__35();
    var tmp0_safe_receiver_2 = tmp0_hashCode_0;
    var tmp1_elvis_lhs_1 = tmp0_safe_receiver_2 == null ? null : hashCode(tmp0_safe_receiver_2);
    var tmp = tmp1_elvis_lhs_1 == null ? 0 : tmp1_elvis_lhs_1;
    var tmp1_hashCode_0 = this._get_value__33();
    var tmp0_safe_receiver_2_0 = tmp1_hashCode_0;
    var tmp1_elvis_lhs_1_0 = tmp0_safe_receiver_2_0 == null ? null : hashCode(tmp0_safe_receiver_2_0);
    return tmp ^ (tmp1_elvis_lhs_1_0 == null ? 0 : tmp1_elvis_lhs_1_0);
  };
  MapEntry.prototype.equals = function (other) {
    var tmp0_safe_receiver = (!(other == null) ? isInterface(other, Entry) : false) ? other : null;
    var tmp;
    if (tmp0_safe_receiver == null) {
      tmp = null;
    } else {
      tmp = equals_0(tmp0_safe_receiver._get_key__35(), this._get_key__35()) ? equals_0(tmp0_safe_receiver._get_value__33(), this._get_value__33()) : false;
    }
    var tmp1_elvis_lhs = tmp;
    return tmp1_elvis_lhs == null ? false : tmp1_elvis_lhs;
  };
  MapEntry.prototype.toString = function () {
    return toString_1(this._get_key__35()) + '=' + toString_1(this._get_value__33());
  };
  MapEntry.$metadata$ = {
    simpleName: 'MapEntry',
    kind: 'class',
    interfaces: [Entry]
  };
  function TrieNodeEntriesIterator() {
    TrieNodeBaseIterator.call(this);
  }
  TrieNodeEntriesIterator.prototype.next_46 = function () {
    assert(this.hasNextKey_1());
    var tmp0_this = this;
    tmp0_this._set_index__3(tmp0_this._get_index__3() + 2 | 0);
    var tmp = this._get_buffer__6()[this._get_index__3() - 2 | 0];
    var tmp_0 = (tmp == null ? true : isObject(tmp)) ? tmp : THROW_CCE();
    var tmp_1 = this._get_buffer__6()[this._get_index__3() - 1 | 0];
    return new MapEntry(tmp_0, (tmp_1 == null ? true : isObject(tmp_1)) ? tmp_1 : THROW_CCE());
  };
  TrieNodeEntriesIterator.$metadata$ = {
    simpleName: 'TrieNodeEntriesIterator',
    kind: 'class',
    interfaces: []
  };
  function PersistentHashMapValues(map_0) {
    AbstractCollection.call(this);
    this._map_2 = map_0;
  }
  PersistentHashMapValues.prototype._get_size__52 = function () {
    return this._map_2._size_4;
  };
  PersistentHashMapValues.prototype.contains_47 = function (element) {
    return this._map_2.containsValue_3(element);
  };
  PersistentHashMapValues.prototype.contains_51 = function (element) {
    if (!(element == null ? true : isObject(element)))
      return false;
    else {
    }
    return this.contains_47((element == null ? true : isObject(element)) ? element : THROW_CCE());
  };
  PersistentHashMapValues.prototype.iterator_66 = function () {
    return new PersistentHashMapValuesIterator(this._map_2._node);
  };
  PersistentHashMapValues.$metadata$ = {
    simpleName: 'PersistentHashMapValues',
    kind: 'class',
    interfaces: [ImmutableCollection]
  };
  function PersistentHashMapEntries(map_0) {
    AbstractSet.call(this);
    this._map_3 = map_0;
  }
  PersistentHashMapEntries.prototype._get_size__52 = function () {
    return this._map_3._size_4;
  };
  PersistentHashMapEntries.prototype.contains_49 = function (element) {
    var tmp = isObject(element) ? element : THROW_CCE();
    if (!(!(tmp == null) ? isInterface(tmp, Entry) : false))
      return false;
    else {
    }
    var tmp0_safe_receiver = this._map_3.get_56(element._get_key__35());
    var tmp_0;
    if (tmp0_safe_receiver == null) {
      tmp_0 = null;
    } else {
      tmp_0 = equals_0(tmp0_safe_receiver, element._get_value__33());
    }
    var tmp1_elvis_lhs = tmp_0;
    return tmp1_elvis_lhs == null ? element._get_value__33() == null ? this._map_3.containsKey_7(element._get_key__35()) : false : tmp1_elvis_lhs;
  };
  PersistentHashMapEntries.prototype.contains_51 = function (element) {
    if (!(!(element == null) ? isInterface(element, Entry) : false))
      return false;
    else {
    }
    return this.contains_49((!(element == null) ? isInterface(element, Entry) : false) ? element : THROW_CCE());
  };
  PersistentHashMapEntries.prototype.iterator_66 = function () {
    return new PersistentHashMapEntriesIterator(this._map_3._node);
  };
  PersistentHashMapEntries.$metadata$ = {
    simpleName: 'PersistentHashMapEntries',
    kind: 'class',
    interfaces: [ImmutableSet]
  };
  function TrieNode_init_$Init$(dataMap, nodeMap, buffer, $this) {
    TrieNode.call($this, dataMap, nodeMap, buffer, null);
    return $this;
  }
  function TrieNode_init_$Create$(dataMap, nodeMap, buffer) {
    return TrieNode_init_$Init$(dataMap, nodeMap, buffer, Object.create(TrieNode.prototype));
  }
  function ModificationResult(node_0, sizeDelta) {
    this._node_0 = node_0;
    this._sizeDelta = sizeDelta;
  }
  ModificationResult.$metadata$ = {
    simpleName: 'ModificationResult',
    kind: 'class',
    interfaces: []
  };
  function asInsertResult($this) {
    return new ModificationResult($this, 1);
  }
  function asUpdateResult($this) {
    return new ModificationResult($this, 0);
  }
  function hasNodeAt($this, positionMask) {
    return !(($this._nodeMap & positionMask) === 0);
  }
  function keyAtIndex($this, keyIndex) {
    var tmp = $this._buffer_2[keyIndex];
    return (tmp == null ? true : isObject(tmp)) ? tmp : THROW_CCE();
  }
  function valueAtKeyIndex($this, keyIndex) {
    var tmp = $this._buffer_2[keyIndex + 1 | 0];
    return (tmp == null ? true : isObject(tmp)) ? tmp : THROW_CCE();
  }
  function insertEntryAt($this, positionMask, key_0, value_0) {
    var keyIndex = $this.entryKeyIndex(positionMask);
    var newBuffer = insertEntryAtIndex($this._buffer_2, keyIndex, key_0, value_0);
    return TrieNode_init_$Create$($this._dataMap | positionMask, $this._nodeMap, newBuffer);
  }
  function updateValueAtIndex($this, keyIndex, value_0) {
    var tmp0_copyOf_0 = $this._buffer_2;
    var newBuffer = tmp0_copyOf_0.slice();
    newBuffer[keyIndex + 1 | 0] = value_0;
    return TrieNode_init_$Create$($this._dataMap, $this._nodeMap, newBuffer);
  }
  function updateNodeAtIndex($this, nodeIndex_1, positionMask, newNode) {
    var newNodeBuffer = newNode._buffer_2;
    if (newNodeBuffer.length === 2 ? newNode._nodeMap === 0 : false) {
      if ($this._buffer_2.length === 1) {
        newNode._dataMap = $this._nodeMap;
        return newNode;
      }var keyIndex = $this.entryKeyIndex(positionMask);
      var newBuffer = replaceNodeWithEntry($this._buffer_2, nodeIndex_1, keyIndex, newNodeBuffer[0], newNodeBuffer[1]);
      return TrieNode_init_$Create$($this._dataMap ^ positionMask, $this._nodeMap ^ positionMask, newBuffer);
    }var newBuffer_0 = copyOf_1($this._buffer_2, $this._buffer_2.length);
    newBuffer_0[nodeIndex_1] = newNode;
    return TrieNode_init_$Create$($this._dataMap, $this._nodeMap, newBuffer_0);
  }
  function removeNodeAtIndex($this, nodeIndex_1, positionMask) {
    if ($this._buffer_2.length === 1)
      return null;
    var newBuffer = removeNodeAtIndex_0($this._buffer_2, nodeIndex_1);
    return TrieNode_init_$Create$($this._dataMap, $this._nodeMap ^ positionMask, newBuffer);
  }
  function bufferMoveEntryToNode($this, keyIndex, positionMask, newKeyHash, newKey, newValue, shift, owner) {
    var storedKey = keyAtIndex($this, keyIndex);
    var tmp0_safe_receiver_2 = storedKey;
    var tmp1_elvis_lhs_1 = tmp0_safe_receiver_2 == null ? null : hashCode(tmp0_safe_receiver_2);
    var storedKeyHash = tmp1_elvis_lhs_1 == null ? 0 : tmp1_elvis_lhs_1;
    var storedValue = valueAtKeyIndex($this, keyIndex);
    var newNode = makeNode_0($this, storedKeyHash, storedKey, storedValue, newKeyHash, newKey, newValue, shift + 5 | 0, owner);
    var nodeIndex_1 = $this.nodeIndex(positionMask) + 1 | 0;
    return replaceEntryWithNode($this._buffer_2, keyIndex, nodeIndex_1, newNode);
  }
  function moveEntryToNode($this, keyIndex, positionMask, newKeyHash, newKey, newValue, shift) {
    var newBuffer = bufferMoveEntryToNode($this, keyIndex, positionMask, newKeyHash, newKey, newValue, shift, null);
    return TrieNode_init_$Create$($this._dataMap ^ positionMask, $this._nodeMap | positionMask, newBuffer);
  }
  function makeNode_0($this, keyHash1, key1, value1, keyHash2, key2, value2, shift, owner) {
    if (shift > 30) {
      var tmp0_arrayOf_0 = [key1, value1, key2, value2];
      return new TrieNode(0, 0, tmp0_arrayOf_0, owner);
    }var setBit1 = indexSegment(keyHash1, shift);
    var setBit2 = indexSegment(keyHash2, shift);
    if (!(setBit1 === setBit2)) {
      var tmp;
      if (setBit1 < setBit2) {
        var tmp1_arrayOf_0 = [key1, value1, key2, value2];
        tmp = tmp1_arrayOf_0;
      } else {
        var tmp2_arrayOf_0 = [key2, value2, key1, value1];
        tmp = tmp2_arrayOf_0;
      }
      var nodeBuffer = tmp;
      return new TrieNode(1 << setBit1 | 1 << setBit2, 0, nodeBuffer, owner);
    }var node_0 = makeNode_0($this, keyHash1, key1, value1, keyHash2, key2, value2, shift + 5 | 0, owner);
    var tmp_0 = 1 << setBit1;
    var tmp3_arrayOf_0 = [node_0];
    return new TrieNode(0, tmp_0, tmp3_arrayOf_0, owner);
  }
  function removeEntryAtIndex($this, keyIndex, positionMask) {
    if ($this._buffer_2.length === 2)
      return null;
    var newBuffer = removeEntryAtIndex_0($this._buffer_2, keyIndex);
    return TrieNode_init_$Create$($this._dataMap ^ positionMask, $this._nodeMap, newBuffer);
  }
  function collisionRemoveEntryAtIndex($this, i) {
    if ($this._buffer_2.length === 2)
      return null;
    var newBuffer = removeEntryAtIndex_0($this._buffer_2, i);
    return TrieNode_init_$Create$(0, 0, newBuffer);
  }
  function collisionContainsKey($this, key_0) {
    var progression = step(until(0, $this._buffer_2.length), 2);
    var inductionVariable = progression._first_1;
    var last_2 = progression._last;
    var step_0 = progression._step_0;
    if ((step_0 > 0 ? inductionVariable <= last_2 : false) ? true : step_0 < 0 ? last_2 <= inductionVariable : false)
      do {
        var i = inductionVariable;
        inductionVariable = inductionVariable + step_0 | 0;
        if (equals_0(key_0, $this._buffer_2[i]))
          return true;
      }
       while (!(i === last_2));
    return false;
  }
  function collisionGet($this, key_0) {
    var progression = step(until(0, $this._buffer_2.length), 2);
    var inductionVariable = progression._first_1;
    var last_2 = progression._last;
    var step_0 = progression._step_0;
    if ((step_0 > 0 ? inductionVariable <= last_2 : false) ? true : step_0 < 0 ? last_2 <= inductionVariable : false)
      do {
        var i = inductionVariable;
        inductionVariable = inductionVariable + step_0 | 0;
        if (equals_0(key_0, keyAtIndex($this, i))) {
          return valueAtKeyIndex($this, i);
        }}
       while (!(i === last_2));
    return null;
  }
  function collisionPut($this, key_0, value_0) {
    var progression = step(until(0, $this._buffer_2.length), 2);
    var inductionVariable = progression._first_1;
    var last_2 = progression._last;
    var step_0 = progression._step_0;
    if ((step_0 > 0 ? inductionVariable <= last_2 : false) ? true : step_0 < 0 ? last_2 <= inductionVariable : false)
      do {
        var i = inductionVariable;
        inductionVariable = inductionVariable + step_0 | 0;
        if (equals_0(key_0, keyAtIndex($this, i))) {
          if (value_0 === valueAtKeyIndex($this, i)) {
            return null;
          }var tmp0_copyOf_0 = $this._buffer_2;
          var newBuffer = tmp0_copyOf_0.slice();
          newBuffer[i + 1 | 0] = value_0;
          return asUpdateResult(TrieNode_init_$Create$(0, 0, newBuffer));
        }}
       while (!(i === last_2));
    var newBuffer_0 = insertEntryAtIndex($this._buffer_2, 0, key_0, value_0);
    return asInsertResult(TrieNode_init_$Create$(0, 0, newBuffer_0));
  }
  function collisionRemove($this, key_0) {
    var progression = step(until(0, $this._buffer_2.length), 2);
    var inductionVariable = progression._first_1;
    var last_2 = progression._last;
    var step_0 = progression._step_0;
    if ((step_0 > 0 ? inductionVariable <= last_2 : false) ? true : step_0 < 0 ? last_2 <= inductionVariable : false)
      do {
        var i = inductionVariable;
        inductionVariable = inductionVariable + step_0 | 0;
        if (equals_0(key_0, keyAtIndex($this, i))) {
          return collisionRemoveEntryAtIndex($this, i);
        }}
       while (!(i === last_2));
    return $this;
  }
  function replaceNode($this, targetNode, newNode, nodeIndex_1, positionMask) {
    return newNode == null ? removeNodeAtIndex($this, nodeIndex_1, positionMask) : !(targetNode === newNode) ? updateNodeAtIndex($this, nodeIndex_1, positionMask, newNode) : $this;
  }
  function Companion_29() {
    Companion_instance_28 = this;
    var tmp = this;
    tmp._EMPTY_1 = TrieNode_init_$Create$(0, 0, []);
  }
  Companion_29.$metadata$ = {
    simpleName: 'Companion',
    kind: 'object',
    interfaces: []
  };
  var Companion_instance_28;
  function Companion_getInstance_28() {
    if (Companion_instance_28 == null)
      new Companion_29();
    return Companion_instance_28;
  }
  function TrieNode(dataMap, nodeMap, buffer, ownedBy) {
    Companion_getInstance_28();
    this._dataMap = dataMap;
    this._nodeMap = nodeMap;
    this._ownedBy = ownedBy;
    this._buffer_2 = buffer;
  }
  TrieNode.prototype.entryCount = function () {
    return countOneBits(this._dataMap);
  };
  TrieNode.prototype.hasEntryAt = function (positionMask) {
    return !((this._dataMap & positionMask) === 0);
  };
  TrieNode.prototype.entryKeyIndex = function (positionMask) {
    return imul(2, countOneBits(this._dataMap & (positionMask - 1 | 0)));
  };
  TrieNode.prototype.nodeIndex = function (positionMask) {
    return (this._buffer_2.length - 1 | 0) - countOneBits(this._nodeMap & (positionMask - 1 | 0)) | 0;
  };
  TrieNode.prototype.nodeAtIndex = function (nodeIndex_1) {
    var tmp = this._buffer_2[nodeIndex_1];
    return tmp instanceof TrieNode ? tmp : THROW_CCE();
  };
  TrieNode.prototype.containsKey_8 = function (keyHash, key_0, shift) {
    var keyPositionMask = 1 << indexSegment(keyHash, shift);
    if (this.hasEntryAt(keyPositionMask)) {
      return equals_0(key_0, keyAtIndex(this, this.entryKeyIndex(keyPositionMask)));
    }if (hasNodeAt(this, keyPositionMask)) {
      var targetNode = this.nodeAtIndex(this.nodeIndex(keyPositionMask));
      if (shift === 30) {
        return collisionContainsKey(targetNode, key_0);
      }return targetNode.containsKey_8(keyHash, key_0, shift + 5 | 0);
    }return false;
  };
  TrieNode.prototype.get_57 = function (keyHash, key_0, shift) {
    var keyPositionMask = 1 << indexSegment(keyHash, shift);
    if (this.hasEntryAt(keyPositionMask)) {
      var keyIndex = this.entryKeyIndex(keyPositionMask);
      if (equals_0(key_0, keyAtIndex(this, keyIndex))) {
        return valueAtKeyIndex(this, keyIndex);
      }return null;
    }if (hasNodeAt(this, keyPositionMask)) {
      var targetNode = this.nodeAtIndex(this.nodeIndex(keyPositionMask));
      if (shift === 30) {
        return collisionGet(targetNode, key_0);
      }return targetNode.get_57(keyHash, key_0, shift + 5 | 0);
    }return null;
  };
  TrieNode.prototype.put_7 = function (keyHash, key_0, value_0, shift) {
    var keyPositionMask = 1 << indexSegment(keyHash, shift);
    if (this.hasEntryAt(keyPositionMask)) {
      var keyIndex = this.entryKeyIndex(keyPositionMask);
      if (equals_0(key_0, keyAtIndex(this, keyIndex))) {
        if (valueAtKeyIndex(this, keyIndex) === value_0)
          return null;
        return asUpdateResult(updateValueAtIndex(this, keyIndex, value_0));
      }return asInsertResult(moveEntryToNode(this, keyIndex, keyPositionMask, keyHash, key_0, value_0, shift));
    }if (hasNodeAt(this, keyPositionMask)) {
      var nodeIndex_1 = this.nodeIndex(keyPositionMask);
      var targetNode = this.nodeAtIndex(nodeIndex_1);
      var tmp;
      if (shift === 30) {
        var tmp0_elvis_lhs = collisionPut(targetNode, key_0, value_0);
        var tmp_0;
        if (tmp0_elvis_lhs == null) {
          return null;
        } else {
          tmp_0 = tmp0_elvis_lhs;
        }
        tmp = tmp_0;
      } else {
        var tmp1_elvis_lhs = targetNode.put_7(keyHash, key_0, value_0, shift + 5 | 0);
        var tmp_1;
        if (tmp1_elvis_lhs == null) {
          return null;
        } else {
          tmp_1 = tmp1_elvis_lhs;
        }
        tmp = tmp_1;
      }
      var putResult = tmp;
      var tmp_2 = putResult;
      var tmp0__anonymous__1 = putResult._node_0;
      tmp_2._node_0 = updateNodeAtIndex(this, nodeIndex_1, keyPositionMask, tmp0__anonymous__1);
      return putResult;
    }return asInsertResult(insertEntryAt(this, keyPositionMask, key_0, value_0));
  };
  TrieNode.prototype.remove_45 = function (keyHash, key_0, shift) {
    var keyPositionMask = 1 << indexSegment(keyHash, shift);
    if (this.hasEntryAt(keyPositionMask)) {
      var keyIndex = this.entryKeyIndex(keyPositionMask);
      if (equals_0(key_0, keyAtIndex(this, keyIndex))) {
        return removeEntryAtIndex(this, keyIndex, keyPositionMask);
      }return this;
    }if (hasNodeAt(this, keyPositionMask)) {
      var nodeIndex_1 = this.nodeIndex(keyPositionMask);
      var targetNode = this.nodeAtIndex(nodeIndex_1);
      var tmp;
      if (shift === 30) {
        tmp = collisionRemove(targetNode, key_0);
      } else {
        tmp = targetNode.remove_45(keyHash, key_0, shift + 5 | 0);
      }
      var newNode = tmp;
      return replaceNode(this, targetNode, newNode, nodeIndex_1, keyPositionMask);
    }return this;
  };
  TrieNode.$metadata$ = {
    simpleName: 'TrieNode',
    kind: 'class',
    interfaces: []
  };
  function insertEntryAtIndex(_this_, keyIndex, key_0, value_0) {
    var tmp0_arrayOfNulls_0 = _this_.length + 2 | 0;
    var newBuffer = fillArrayVal(Array(tmp0_arrayOfNulls_0), null);
    var tmp1_copyInto_0 = 0;
    var tmp2_copyInto_0 = 0;
    arrayCopy_0(_this_, newBuffer, tmp1_copyInto_0, tmp2_copyInto_0, keyIndex);
    Unit_getInstance();
    var tmp3_copyInto_0 = keyIndex + 2 | 0;
    var tmp4_copyInto_0 = _this_.length;
    arrayCopy_0(_this_, newBuffer, tmp3_copyInto_0, keyIndex, tmp4_copyInto_0);
    Unit_getInstance();
    newBuffer[keyIndex] = key_0;
    newBuffer[keyIndex + 1 | 0] = value_0;
    return newBuffer;
  }
  function replaceNodeWithEntry(_this_, nodeIndex_1, keyIndex, key_0, value_0) {
    var newBuffer = copyOf_1(_this_, _this_.length + 1 | 0);
    var tmp0_copyInto_0 = nodeIndex_1 + 2 | 0;
    var tmp1_copyInto_0 = nodeIndex_1 + 1 | 0;
    var tmp2_copyInto_0 = _this_.length;
    arrayCopy_0(newBuffer, newBuffer, tmp0_copyInto_0, tmp1_copyInto_0, tmp2_copyInto_0);
    Unit_getInstance();
    var tmp3_copyInto_0 = keyIndex + 2 | 0;
    arrayCopy_0(newBuffer, newBuffer, tmp3_copyInto_0, keyIndex, nodeIndex_1);
    Unit_getInstance();
    newBuffer[keyIndex] = key_0;
    newBuffer[keyIndex + 1 | 0] = value_0;
    return newBuffer;
  }
  function removeNodeAtIndex_0(_this_, nodeIndex_1) {
    var tmp0_arrayOfNulls_0 = _this_.length - 1 | 0;
    var newBuffer = fillArrayVal(Array(tmp0_arrayOfNulls_0), null);
    var tmp1_copyInto_0 = 0;
    var tmp2_copyInto_0 = 0;
    arrayCopy_0(_this_, newBuffer, tmp1_copyInto_0, tmp2_copyInto_0, nodeIndex_1);
    Unit_getInstance();
    var tmp3_copyInto_0 = nodeIndex_1 + 1 | 0;
    var tmp4_copyInto_0 = _this_.length;
    arrayCopy_0(_this_, newBuffer, nodeIndex_1, tmp3_copyInto_0, tmp4_copyInto_0);
    Unit_getInstance();
    return newBuffer;
  }
  function replaceEntryWithNode(_this_, keyIndex, nodeIndex_1, newNode) {
    var newNodeIndex = nodeIndex_1 - 2 | 0;
    var tmp0_arrayOfNulls_0 = (_this_.length - 2 | 0) + 1 | 0;
    var newBuffer = fillArrayVal(Array(tmp0_arrayOfNulls_0), null);
    var tmp1_copyInto_0 = 0;
    var tmp2_copyInto_0 = 0;
    arrayCopy_0(_this_, newBuffer, tmp1_copyInto_0, tmp2_copyInto_0, keyIndex);
    Unit_getInstance();
    var tmp3_copyInto_0 = keyIndex + 2 | 0;
    arrayCopy_0(_this_, newBuffer, keyIndex, tmp3_copyInto_0, nodeIndex_1);
    Unit_getInstance();
    newBuffer[newNodeIndex] = newNode;
    var tmp4_copyInto_0 = newNodeIndex + 1 | 0;
    var tmp5_copyInto_0 = _this_.length;
    arrayCopy_0(_this_, newBuffer, tmp4_copyInto_0, nodeIndex_1, tmp5_copyInto_0);
    Unit_getInstance();
    return newBuffer;
  }
  function indexSegment(index, shift) {
    return index >> shift & 31;
  }
  function removeEntryAtIndex_0(_this_, keyIndex) {
    var tmp0_arrayOfNulls_0 = _this_.length - 2 | 0;
    var newBuffer = fillArrayVal(Array(tmp0_arrayOfNulls_0), null);
    var tmp1_copyInto_0 = 0;
    var tmp2_copyInto_0 = 0;
    arrayCopy_0(_this_, newBuffer, tmp1_copyInto_0, tmp2_copyInto_0, keyIndex);
    Unit_getInstance();
    var tmp3_copyInto_0 = keyIndex + 2 | 0;
    var tmp4_copyInto_0 = _this_.length;
    arrayCopy_0(_this_, newBuffer, keyIndex, tmp3_copyInto_0, tmp4_copyInto_0);
    Unit_getInstance();
    return newBuffer;
  }
  function Companion_30() {
    Companion_instance_29 = this;
    this._EMPTY_2 = new PersistentOrderedSet(EndOfChain_getInstance(), EndOfChain_getInstance(), Companion_getInstance_27().emptyOf());
  }
  Companion_30.prototype.emptyOf_0 = function () {
    return this._EMPTY_2;
  };
  Companion_30.$metadata$ = {
    simpleName: 'Companion',
    kind: 'object',
    interfaces: []
  };
  var Companion_instance_29;
  function Companion_getInstance_29() {
    if (Companion_instance_29 == null)
      new Companion_30();
    return Companion_instance_29;
  }
  function PersistentOrderedSet(firstElement, lastElement, hashMap) {
    Companion_getInstance_29();
    AbstractSet.call(this);
    this._firstElement = firstElement;
    this._lastElement = lastElement;
    this._hashMap = hashMap;
  }
  PersistentOrderedSet.prototype._get_size__52 = function () {
    return this._hashMap._size_4;
  };
  PersistentOrderedSet.prototype.contains_51 = function (element) {
    return this._hashMap.containsKey_7(element);
  };
  PersistentOrderedSet.prototype.add_39 = function (element) {
    if (this._hashMap.containsKey_7(element)) {
      return this;
    }if (this.isEmpty_44()) {
      var newMap = this._hashMap.put_6(element, Links_init_$Create$());
      return new PersistentOrderedSet(element, element, newMap);
    }var tmp = this._lastElement;
    var lastElement = (tmp == null ? true : isObject(tmp)) ? tmp : THROW_CCE();
    var lastLinks = ensureNotNull(this._hashMap.get_56(lastElement));
    var newMap_0 = this._hashMap.put_6(lastElement, lastLinks.withNext(element)).put_6(element, Links_init_$Create$_0(lastElement));
    return new PersistentOrderedSet(this._firstElement, element, newMap_0);
  };
  PersistentOrderedSet.prototype.remove_46 = function (element) {
    var tmp0_elvis_lhs = this._hashMap.get_56(element);
    var tmp;
    if (tmp0_elvis_lhs == null) {
      return this;
    } else {
      tmp = tmp0_elvis_lhs;
    }
    var links = tmp;
    var newMap = this._hashMap.remove_44(element);
    if (links._get_hasPrevious_()) {
      var tmp0_get_0 = newMap;
      var tmp1_get_0 = links._previous;
      var previousLinks = ensureNotNull((isInterface(tmp0_get_0, Map_0) ? tmp0_get_0 : THROW_CCE()).get_56(tmp1_get_0));
      var tmp_0 = newMap;
      var tmp_1 = links._previous;
      newMap = tmp_0.put_6((tmp_1 == null ? true : isObject(tmp_1)) ? tmp_1 : THROW_CCE(), previousLinks.withNext(links._next_4));
    }if (links._get_hasNext_()) {
      var tmp2_get_0 = newMap;
      var tmp3_get_0 = links._next_4;
      var nextLinks = ensureNotNull((isInterface(tmp2_get_0, Map_0) ? tmp2_get_0 : THROW_CCE()).get_56(tmp3_get_0));
      var tmp_2 = newMap;
      var tmp_3 = links._next_4;
      newMap = tmp_2.put_6((tmp_3 == null ? true : isObject(tmp_3)) ? tmp_3 : THROW_CCE(), nextLinks.withPrevious(links._previous));
    }var newFirstElement = !links._get_hasPrevious_() ? links._next_4 : this._firstElement;
    var newLastElement = !links._get_hasNext_() ? links._previous : this._lastElement;
    return new PersistentOrderedSet(newFirstElement, newLastElement, newMap);
  };
  PersistentOrderedSet.prototype.iterator_66 = function () {
    return new PersistentOrderedSetIterator(this._firstElement, this._hashMap);
  };
  PersistentOrderedSet.$metadata$ = {
    simpleName: 'PersistentOrderedSet',
    kind: 'class',
    interfaces: [PersistentSet]
  };
  function Links_init_$Init$($this) {
    Links.call($this, EndOfChain_getInstance(), EndOfChain_getInstance());
    return $this;
  }
  function Links_init_$Create$() {
    return Links_init_$Init$(Object.create(Links.prototype));
  }
  function Links_init_$Init$_0(previous, $this) {
    Links.call($this, previous, EndOfChain_getInstance());
    return $this;
  }
  function Links_init_$Create$_0(previous) {
    return Links_init_$Init$_0(previous, Object.create(Links.prototype));
  }
  function Links(previous, next) {
    this._previous = previous;
    this._next_4 = next;
  }
  Links.prototype.withNext = function (newNext) {
    return new Links(this._previous, newNext);
  };
  Links.prototype.withPrevious = function (newPrevious) {
    return new Links(newPrevious, this._next_4);
  };
  Links.prototype._get_hasNext_ = function () {
    return !(this._next_4 === EndOfChain_getInstance());
  };
  Links.prototype._get_hasPrevious_ = function () {
    return !(this._previous === EndOfChain_getInstance());
  };
  Links.$metadata$ = {
    simpleName: 'Links',
    kind: 'class',
    interfaces: []
  };
  function checkHasNext_0($this) {
    if (!$this.hasNext_41())
      throw NoSuchElementException_init_$Create$();
  }
  function PersistentOrderedSetIterator(nextElement, map_0) {
    this._nextElement = nextElement;
    this._map_4 = map_0;
    this._index_12 = 0;
  }
  PersistentOrderedSetIterator.prototype.hasNext_41 = function () {
    return this._index_12 < this._map_4._get_size__52();
  };
  PersistentOrderedSetIterator.prototype.next_46 = function () {
    checkHasNext_0(this);
    var tmp = this._nextElement;
    var result = (tmp == null ? true : isObject(tmp)) ? tmp : THROW_CCE();
    var tmp0_this = this;
    var tmp1 = tmp0_this._index_12;
    tmp0_this._index_12 = tmp1 + 1 | 0;
    Unit_getInstance();
    var tmp_0 = this;
    var tmp0_getOrElse_0 = this._map_4;
    var tmp0_elvis_lhs_1 = tmp0_getOrElse_0.get_56(result);
    var tmp_1;
    if (tmp0_elvis_lhs_1 == null) {
      throw ConcurrentModificationException_init_$Create$_0('' + 'Hash code of an element (' + result + ') has changed after it was added to the persistent set.');
    } else {
      tmp_1 = tmp0_elvis_lhs_1;
    }
    tmp_0._nextElement = tmp_1._next_4;
    return result;
  };
  PersistentOrderedSetIterator.$metadata$ = {
    simpleName: 'PersistentOrderedSetIterator',
    kind: 'class',
    interfaces: [Iterator_2]
  };
  function EndOfChain() {
    EndOfChain_instance = this;
  }
  EndOfChain.$metadata$ = {
    simpleName: 'EndOfChain',
    kind: 'object',
    interfaces: []
  };
  var EndOfChain_instance;
  function EndOfChain_getInstance() {
    if (EndOfChain_instance == null)
      new EndOfChain();
    return EndOfChain_instance;
  }
  function assert(condition) {
  }
  function composableLambdaInstance(key_0, tracked, block) {
    var tmp0_apply_0 = new ComposableLambdaImpl(key_0, tracked);
    tmp0_apply_0.update_1(block);
    return tmp0_apply_0;
  }
  function replacableWith(_this_, other) {
    var tmp;
    if (_this_ == null) {
      tmp = true;
    } else {
      var tmp_0;
      var tmp_1;
      if (_this_ instanceof RecomposeScopeImpl) {
        tmp_1 = other instanceof RecomposeScopeImpl;
      } else {
        {
          tmp_1 = false;
        }
      }
      if (tmp_1) {
        tmp_0 = (!_this_._get_valid__1() ? true : equals_0(_this_, other)) ? true : equals_0(_this_._anchor, other._anchor);
      } else {
        {
          tmp_0 = false;
        }
      }
      tmp = tmp_0;
    }
    return tmp;
  }
  function differentBits(slot) {
    return bitsForSlot(2, slot);
  }
  function sameBits(slot) {
    return bitsForSlot(1, slot);
  }
  function bitsForSlot(bits, slot) {
    var realSlot = slot % 10;
    return bits << (imul(realSlot, 3) + 1 | 0);
  }
  function composableLambda(composer, key_0, tracked, block) {
    composer.startReplaceableGroup_0(key_0);
    var slot = composer.rememberedValue_0();
    var tmp;
    if (slot === Companion_getInstance_25()._Empty) {
      var value_0 = new ComposableLambdaImpl(key_0, tracked);
      composer.updateRememberedValue_0(value_0);
      tmp = value_0;
    } else {
      tmp = slot instanceof ComposableLambdaImpl ? slot : THROW_CCE();
    }
    var result = tmp;
    result.update_1(block);
    composer.endReplaceableGroup_0();
    return result;
  }
  var liveLiteralCache;
  var isLiveLiteralsEnabled;
  function fastJoinToString(_this_, separator, prefix, postfix, limit, truncated, transform) {
    return fastJoinTo(_this_, StringBuilder_init_$Create$_1(), separator, prefix, postfix, limit, truncated, transform).toString();
  }
  function fastJoinToString$default(_this_, separator, prefix, postfix, limit, truncated, transform, $mask0, $handler) {
    if (!(($mask0 & 1) === 0))
      separator = ', ';
    if (!(($mask0 & 2) === 0))
      prefix = '';
    if (!(($mask0 & 4) === 0))
      postfix = '';
    if (!(($mask0 & 8) === 0))
      limit = -1;
    if (!(($mask0 & 16) === 0))
      truncated = '...';
    if (!(($mask0 & 32) === 0))
      transform = null;
    return fastJoinToString(_this_, separator, prefix, postfix, limit, truncated, transform);
  }
  function fastToSet(_this_) {
    var tmp0_also_0 = HashSet_init_$Create$_0(_this_._get_size__52());
    var inductionVariable = 0;
    var last_2 = _this_._get_size__52() - 1 | 0;
    if (inductionVariable <= last_2)
      do {
        var index_2_3 = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        var item_3_4 = _this_.get_72(index_2_3);
        tmp0_also_0.add_39(item_3_4);
        Unit_getInstance();
      }
       while (inductionVariable <= last_2);
    return tmp0_also_0;
  }
  function fastJoinTo(_this_, buffer, separator, prefix, postfix, limit, truncated, transform) {
    buffer.append_3(prefix);
    Unit_getInstance();
    var count_0 = 0;
    var inductionVariable = 0;
    var last_2 = _this_._get_size__52() - 1 | 0;
    if (inductionVariable <= last_2)
      $l$break: do {
        var index = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        var element = _this_.get_72(index);
        count_0 = count_0 + 1 | 0;
        if (count_0 > 1) {
          buffer.append_3(separator);
          Unit_getInstance();
        } else {
        }
        if (limit < 0 ? true : count_0 <= limit) {
          appendElement_0(buffer, element, transform);
        } else
          break $l$break;
      }
       while (inductionVariable <= last_2);
    if (limit >= 0 ? count_0 > limit : false) {
      buffer.append_3(truncated);
      Unit_getInstance();
    }buffer.append_3(postfix);
    Unit_getInstance();
    return buffer;
  }
  function appendElement_0(_this_, element, transform) {
    if (!(transform == null)) {
      _this_.append_3(transform(element));
      Unit_getInstance();
    } else {
      if (element == null ? true : isCharSequence(element)) {
        _this_.append_3(element);
        Unit_getInstance();
      } else {
        if (element instanceof Char) {
          _this_.append_2(element);
          Unit_getInstance();
        } else {
          {
            _this_.append_3(toString_1(element));
            Unit_getInstance();
          }
        }
      }
    }
  }
  var emptyLambda;
  var threadSnapshot;
  var lock;
  var openSnapshots;
  var nextSnapshotId;
  var applyObservers;
  var globalWriteObservers;
  var currentGlobalSnapshot;
  var snapshotInitializer;
  function _no_name_provided__202($observer) {
    this._$observer = $observer;
  }
  _no_name_provided__202.prototype.dispose_15 = function () {
    var tmp0_synchronized_0 = lock;
    applyObservers.remove_46(this._$observer);
    Unit_getInstance();
  };
  _no_name_provided__202.$metadata$ = {
    kind: 'class',
    interfaces: [ObserverHandle]
  };
  function _no_name_provided__203($observer) {
    this._$observer_0 = $observer;
  }
  _no_name_provided__203.prototype.dispose_15 = function () {
    var tmp0_synchronized_0 = lock;
    globalWriteObservers.remove_46(this._$observer_0);
    Unit_getInstance();
    advanceGlobalSnapshot_0();
  };
  _no_name_provided__203.$metadata$ = {
    kind: 'class',
    interfaces: [ObserverHandle]
  };
  function Companion_31() {
    Companion_instance_30 = this;
  }
  Companion_31.prototype._get_current__2 = function () {
    return currentSnapshot();
  };
  Companion_31.prototype.takeMutableSnapshot = function (readObserver, writeObserver) {
    var tmp = currentSnapshot();
    var tmp0_safe_receiver = tmp instanceof MutableSnapshot ? tmp : null;
    var tmp1_elvis_lhs = tmp0_safe_receiver == null ? null : tmp0_safe_receiver.takeNestedMutableSnapshot_2(readObserver, writeObserver);
    var tmp_0;
    if (tmp1_elvis_lhs == null) {
      var tmp0_error_0 = 'Cannot create a mutable snapshot of an read-only snapshot';
      throw IllegalStateException_init_$Create$_0(toString_2(tmp0_error_0));
    } else {
      tmp_0 = tmp1_elvis_lhs;
    }
    return tmp_0;
  };
  Companion_31.prototype.registerApplyObserver = function (observer) {
    advanceGlobalSnapshot(emptyLambda);
    var tmp0_synchronized_0 = lock;
    applyObservers.add_39(observer);
    Unit_getInstance();
    return new _no_name_provided__202(observer);
  };
  Companion_31.prototype.registerGlobalWriteObserver = function (observer) {
    var tmp0_synchronized_0 = lock;
    globalWriteObservers.add_39(observer);
    Unit_getInstance();
    advanceGlobalSnapshot_0();
    return new _no_name_provided__203(observer);
  };
  Companion_31.prototype.notifyObjectsInitialized_4 = function () {
    return currentSnapshot().notifyObjectsInitialized_4();
  };
  Companion_31.prototype.sendApplyNotifications = function () {
    var tmp0_synchronized_0 = lock;
    var tmp0_safe_receiver_2 = currentGlobalSnapshot.get_60()._get_modified__2();
    var tmp;
    if (tmp0_safe_receiver_2 == null) {
      tmp = null;
    } else {
      tmp = !tmp0_safe_receiver_2.isEmpty_44();
    }
    var changes = tmp === true;
    if (changes)
      advanceGlobalSnapshot_0();
  };
  Companion_31.$metadata$ = {
    simpleName: 'Companion',
    kind: 'object',
    interfaces: []
  };
  var Companion_instance_30;
  function Companion_getInstance_30() {
    if (Companion_instance_30 == null)
      new Companion_31();
    return Companion_instance_30;
  }
  function Snapshot(id, invalid) {
    Companion_getInstance_30();
    this._invalid = invalid;
    this._id = id;
    this._disposed_0 = false;
    this._$stable_4 = 8;
  }
  Snapshot.prototype._set_invalid__3 = function (_set___) {
    this._invalid = _set___;
  };
  Snapshot.prototype._get_invalid__3 = function () {
    return this._invalid;
  };
  Snapshot.prototype._set_id__3 = function (_set___) {
    this._id = _set___;
  };
  Snapshot.prototype._get_id__26 = function () {
    return this._id;
  };
  Snapshot.prototype.makeCurrent_3 = function () {
    var previous = threadSnapshot.get_60();
    threadSnapshot.set_9(this);
    return previous;
  };
  Snapshot.prototype.restoreCurrent_3 = function (snapshot) {
    threadSnapshot.set_9(snapshot);
  };
  Snapshot.prototype._set_disposed__0 = function (_set___) {
    this._disposed_0 = _set___;
  };
  Snapshot.prototype._get_disposed__3 = function () {
    return this._disposed_0;
  };
  Snapshot.prototype.validateNotDisposed_3 = function () {
    var tmp0_require_0 = !this._disposed_0;
    if (!tmp0_require_0) {
      var message_1 = 'Cannot use a disposed snapshot';
      throw IllegalArgumentException_init_$Create$_0(toString_2(message_1));
    }};
  Snapshot.$metadata$ = {
    simpleName: 'Snapshot',
    kind: 'class',
    interfaces: []
  };
  function ObserverHandle() {
  }
  ObserverHandle.$metadata$ = {
    simpleName: 'ObserverHandle',
    kind: 'interface',
    interfaces: []
  };
  function MutableSnapshot(id, invalid, readObserver, writeObserver) {
    Snapshot.call(this, id, invalid);
    this._readObserver = readObserver;
    this._writeObserver = writeObserver;
    this._modified = null;
    this._previousIds = Companion_getInstance_31()._EMPTY_3;
    this._snapshots = 1;
    this._applied = false;
    this._$stable_5 = 8;
  }
  MutableSnapshot.prototype._get_readObserver__3 = function () {
    return this._readObserver;
  };
  MutableSnapshot.prototype._get_writeObserver__3 = function () {
    return this._writeObserver;
  };
  MutableSnapshot.prototype.takeNestedMutableSnapshot_2 = function (readObserver, writeObserver) {
    this.validateNotDisposed_3();
    this.validateNotApplied_2();
    this.recordPrevious_2(this._get_id__26());
    var tmp0_synchronized_0_9 = lock;
    var tmp0_3_11 = nextSnapshotId;
    nextSnapshotId = tmp0_3_11 + 1 | 0;
    var newId_2_10 = tmp0_3_11;
    openSnapshots = openSnapshots.set_7(newId_2_10);
    var invalid_4_12 = this._get_invalid__3();
    this._set_invalid__3(invalid_4_12.set_7(newId_2_10));
    var tmp0_also_0 = new NestedMutableSnapshot(newId_2_10, invalid_4_12, mergedReadObserver(readObserver, this._get_readObserver__3()), mergedWriteObserver(writeObserver, this._get_writeObserver__3()), this);
    var previousId_2_2 = this._get_id__26();
    var tmp0_synchronized_0_3 = lock;
    var tmp0_4_2_4 = nextSnapshotId;
    nextSnapshotId = tmp0_4_2_4 + 1 | 0;
    this._set_id__3(tmp0_4_2_4);
    openSnapshots = openSnapshots.set_7(this._get_id__26());
    var currentInvalid_5_5 = this._get_invalid__3();
    var inductionVariable = previousId_2_2 + 1 | 0;
    var last_2 = this._get_id__26();
    if (inductionVariable < last_2)
      do {
        var invalidId_7_7 = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        currentInvalid_5_5 = currentInvalid_5_5.set_7(invalidId_7_7);
      }
       while (inductionVariable < last_2);
    this._set_invalid__3(currentInvalid_5_5);
    return tmp0_also_0;
  };
  MutableSnapshot.prototype.apply_4 = function () {
    var modified = this._get_modified__2();
    var optimisticMerges_0 = !(modified == null) ? optimisticMerges(currentGlobalSnapshot.get_60(), this, openSnapshots.clear_10(currentGlobalSnapshot.get_60()._get_id__26())) : null;
    var tmp0_synchronized_0 = lock;
    validateOpen(this);
    var tmp;
    if (modified == null ? true : modified._get_size__52() === 0) {
      this.close_7();
      var previousGlobalSnapshot_2 = currentGlobalSnapshot.get_60();
      takeNewGlobalSnapshot(previousGlobalSnapshot_2, emptyLambda);
      var globalModified_3 = previousGlobalSnapshot_2._get_modified__2();
      var tmp_0;
      var tmp_1;
      if (!(globalModified_3 == null)) {
        tmp_1 = !globalModified_3.isEmpty_44();
      } else {
        tmp_1 = false;
      }
      if (tmp_1) {
        tmp_0 = to(toMutableList_1(applyObservers), globalModified_3);
      } else {
        {
          tmp_0 = to(emptyList(), null);
        }
      }
      tmp = tmp_0;
    } else {
      var previousGlobalSnapshot_4 = currentGlobalSnapshot.get_60();
      var result_5 = this.innerApply_2(nextSnapshotId, optimisticMerges_0, openSnapshots.clear_10(previousGlobalSnapshot_4._get_id__26()));
      if (!equals_0(result_5, Success_getInstance()))
        return result_5;
      this.close_7();
      takeNewGlobalSnapshot(previousGlobalSnapshot_4, emptyLambda);
      var globalModified_6 = previousGlobalSnapshot_4._get_modified__2();
      this._set_modified__2(null);
      previousGlobalSnapshot_4._set_modified__2(null);
      tmp = to(toMutableList_1(applyObservers), globalModified_6);
    }
    var tmp0_container = tmp;
    var observers = tmp0_container.component1_4();
    var globalModified = tmp0_container.component2_4();
    this._applied = true;
    var tmp_2;
    if (!(globalModified == null)) {
      tmp_2 = !globalModified.isEmpty_44();
    } else {
      tmp_2 = false;
    }
    if (tmp_2) {
      var inductionVariable = 0;
      var last_2 = observers._get_size__52() - 1 | 0;
      if (inductionVariable <= last_2)
        do {
          var index_2 = inductionVariable;
          inductionVariable = inductionVariable + 1 | 0;
          var item_3 = observers.get_72(index_2);
          item_3(globalModified, this);
        }
         while (inductionVariable <= last_2);
    } else {
    }
    var tmp_3;
    if (!(modified == null)) {
      tmp_3 = !modified.isEmpty_44();
    } else {
      tmp_3 = false;
    }
    if (tmp_3) {
      var inductionVariable_0 = 0;
      var last_3 = observers._get_size__52() - 1 | 0;
      if (inductionVariable_0 <= last_3)
        do {
          var index_2_0 = inductionVariable_0;
          inductionVariable_0 = inductionVariable_0 + 1 | 0;
          var item_3_0 = observers.get_72(index_2_0);
          item_3_0(modified, this);
        }
         while (inductionVariable_0 <= last_3);
    } else {
    }
    return Success_getInstance();
  };
  MutableSnapshot.prototype._get_readOnly__4 = function () {
    return false;
  };
  MutableSnapshot.prototype.nestedActivated_4 = function (snapshot) {
    var tmp0_this = this;
    var tmp1 = tmp0_this._snapshots;
    tmp0_this._snapshots = tmp1 + 1 | 0;
    Unit_getInstance();
  };
  MutableSnapshot.prototype.notifyObjectsInitialized_4 = function () {
    this.advance_2();
  };
  MutableSnapshot.prototype.close_7 = function () {
    var tmp0_synchronized_0 = lock;
    openSnapshots = openSnapshots.clear_10(this._get_id__26()).andNot(this._previousIds);
  };
  MutableSnapshot.prototype.validateNotApplied_2 = function () {
    var tmp0_require_0 = !this._applied;
    if (!tmp0_require_0) {
      var message_1 = 'Unsupported operation on a snapshot that has been applied';
      throw IllegalArgumentException_init_$Create$_0(toString_2(message_1));
    }};
  MutableSnapshot.prototype.innerApply_2 = function (snapshotId, optimisticMerges_0, invalidSnapshots) {
    var mergedRecords = null;
    var start_0 = this._get_invalid__3().set_7(this._get_id__26()).or_3(this._previousIds);
    var modified = ensureNotNull(this._get_modified__2());
    var statesToRemove = null;
    var tmp0_iterator = modified.iterator_66();
    while (tmp0_iterator.hasNext_41()) {
      var state = tmp0_iterator.next_46();
      var first_4 = state._get_firstStateRecord__0();
      var tmp1_elvis_lhs = readable(first_4, snapshotId, invalidSnapshots);
      var tmp;
      if (tmp1_elvis_lhs == null) {
        continue;
      } else {
        tmp = tmp1_elvis_lhs;
      }
      var current_0 = tmp;
      var tmp2_elvis_lhs = readable(first_4, this._get_id__26(), start_0);
      var tmp_0;
      if (tmp2_elvis_lhs == null) {
        continue;
      } else {
        tmp_0 = tmp2_elvis_lhs;
      }
      var previous = tmp_0;
      if (!equals_0(current_0, previous)) {
        var tmp3_elvis_lhs = readable(first_4, this._get_id__26(), this._get_invalid__3());
        var tmp_1;
        if (tmp3_elvis_lhs == null) {
          readError();
        } else {
          tmp_1 = tmp3_elvis_lhs;
        }
        var applied = tmp_1;
        var tmp4_safe_receiver = optimisticMerges_0;
        var tmp5_elvis_lhs = tmp4_safe_receiver == null ? null : tmp4_safe_receiver.get_56(current_0);
        var tmp_2;
        if (tmp5_elvis_lhs == null) {
          tmp_2 = state.mergeRecords_0(previous, current_0, applied);
        } else {
          tmp_2 = tmp5_elvis_lhs;
        }
        var merged = tmp_2;
        var tmp6_subject = merged;
        if (tmp6_subject == null)
          return new Failure_0(this);
        else if (equals_0(tmp6_subject, applied)) {
        } else if (equals_0(tmp6_subject, current_0)) {
          var tmp7_elvis_lhs = mergedRecords;
          var tmp_3;
          if (tmp7_elvis_lhs == null) {
            var tmp0_also_0 = ArrayList_init_$Create$();
            mergedRecords = tmp0_also_0;
            tmp_3 = tmp0_also_0;
          } else {
            tmp_3 = tmp7_elvis_lhs;
          }
          tmp_3.add_39(to(state, current_0.create_8()));
          Unit_getInstance();
          var tmp8_elvis_lhs = statesToRemove;
          var tmp_4;
          if (tmp8_elvis_lhs == null) {
            var tmp1_also_0 = ArrayList_init_$Create$();
            statesToRemove = tmp1_also_0;
            tmp_4 = tmp1_also_0;
          } else {
            tmp_4 = tmp8_elvis_lhs;
          }
          tmp_4.add_39(state);
          Unit_getInstance();
        } else {
          var tmp9_elvis_lhs = mergedRecords;
          var tmp_5;
          if (tmp9_elvis_lhs == null) {
            var tmp2_also_0 = ArrayList_init_$Create$();
            mergedRecords = tmp2_also_0;
            tmp_5 = tmp2_also_0;
          } else {
            tmp_5 = tmp9_elvis_lhs;
          }
          tmp_5.add_39(!equals_0(merged, previous) ? to(state, merged) : to(state, previous.create_8()));
          Unit_getInstance();
        }
      }}
    var tmp10_safe_receiver = mergedRecords;
    if (tmp10_safe_receiver == null)
      null;
    else {
      this.advance_2();
      var inductionVariable = 0;
      var last_2 = tmp10_safe_receiver._get_size__52() - 1 | 0;
      if (inductionVariable <= last_2)
        do {
          var index_2_3 = inductionVariable;
          inductionVariable = inductionVariable + 1 | 0;
          var item_3_4 = tmp10_safe_receiver.get_72(index_2_3);
          var tmp0_container_5_5 = item_3_4;
          var state_6_6 = tmp0_container_5_5.component1_4();
          var stateRecord_7_7 = tmp0_container_5_5.component2_4();
          stateRecord_7_7._snapshotId = this._get_id__26();
          var tmp0_synchronized_0_8_8 = lock;
          stateRecord_7_7._next_5 = state_6_6._get_firstStateRecord__0();
          state_6_6.prependStateRecord_0(stateRecord_7_7);
        }
         while (inductionVariable <= last_2);
      Unit_getInstance();
    }
    Unit_getInstance();
    var tmp11_safe_receiver = statesToRemove;
    if (tmp11_safe_receiver == null)
      null;
    else {
      modified.removeAll_12(tmp11_safe_receiver);
    }
    Unit_getInstance();
    return Success_getInstance();
  };
  MutableSnapshot.prototype.advance_2 = function () {
    this.recordPrevious_2(this._get_id__26());
    var tmp0_also_0_1 = Unit_getInstance();
    var previousId_2_2 = this._get_id__26();
    var tmp0_synchronized_0_3_3 = lock;
    var tmp0_2_4_4 = nextSnapshotId;
    nextSnapshotId = tmp0_2_4_4 + 1 | 0;
    this._set_id__3(tmp0_2_4_4);
    openSnapshots = openSnapshots.set_7(this._get_id__26());
    var currentInvalid_5_5 = this._get_invalid__3();
    var inductionVariable = previousId_2_2 + 1 | 0;
    var last_2 = this._get_id__26();
    if (inductionVariable < last_2)
      do {
        var invalidId_7_7 = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        currentInvalid_5_5 = currentInvalid_5_5.set_7(invalidId_7_7);
      }
       while (inductionVariable < last_2);
    this._set_invalid__3(currentInvalid_5_5);
    return tmp0_also_0_1;
  };
  MutableSnapshot.prototype.recordPrevious_2 = function (id) {
    var tmp0_synchronized_0 = lock;
    this._previousIds = this._previousIds.set_7(id);
  };
  MutableSnapshot.prototype.recordPreviousList_2 = function (snapshots) {
    var tmp0_synchronized_0 = lock;
    this._previousIds = this._previousIds.or_3(snapshots);
  };
  MutableSnapshot.prototype.recordModified_3 = function (state) {
    var tmp0_elvis_lhs = this._get_modified__2();
    var tmp;
    if (tmp0_elvis_lhs == null) {
      var tmp0_also_0 = HashSet_init_$Create$();
      this._set_modified__2(tmp0_also_0);
      tmp = tmp0_also_0;
    } else {
      tmp = tmp0_elvis_lhs;
    }
    tmp.add_39(state);
    Unit_getInstance();
  };
  MutableSnapshot.prototype._set_modified__2 = function (_set___) {
    this._modified = _set___;
  };
  MutableSnapshot.prototype._get_modified__2 = function () {
    return this._modified;
  };
  MutableSnapshot.prototype._get_previousIds__0 = function () {
    return this._previousIds;
  };
  MutableSnapshot.prototype._set_applied__0 = function (_set___) {
    this._applied = _set___;
  };
  MutableSnapshot.$metadata$ = {
    simpleName: 'MutableSnapshot',
    kind: 'class',
    interfaces: []
  };
  function Success() {
    Success_instance = this;
    SnapshotApplyResult.call(this);
    this._$stable_6 = 0;
  }
  Success.$metadata$ = {
    simpleName: 'Success',
    kind: 'object',
    interfaces: []
  };
  var Success_instance;
  function Success_getInstance() {
    if (Success_instance == null)
      new Success();
    return Success_instance;
  }
  function Failure_0(snapshot) {
    SnapshotApplyResult.call(this);
    this._snapshot_0 = snapshot;
    this._$stable_7 = 8;
  }
  Failure_0.$metadata$ = {
    simpleName: 'Failure',
    kind: 'class',
    interfaces: []
  };
  function SnapshotApplyResult() {
    this._$stable_8 = 0;
  }
  SnapshotApplyResult.$metadata$ = {
    simpleName: 'SnapshotApplyResult',
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__204($tmp0_safe_receiver_2) {
    this._$tmp0_safe_receiver_2 = $tmp0_safe_receiver_2;
  }
  _no_name_provided__204.prototype.invoke_918 = function (state) {
    var inductionVariable = 0;
    var last_2 = this._$tmp0_safe_receiver_2._get_size__52() - 1 | 0;
    if (inductionVariable <= last_2)
      do {
        var index_2_5_7 = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        var item_3_6_8 = this._$tmp0_safe_receiver_2.get_72(index_2_5_7);
        item_3_6_8(state);
      }
       while (inductionVariable <= last_2);
  };
  _no_name_provided__204.prototype.invoke_1115 = function (p1) {
    this.invoke_918(isObject(p1) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__204.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__205($readObserver, $writeObserver) {
    this._$readObserver = $readObserver;
    this._$writeObserver = $writeObserver;
  }
  _no_name_provided__205.prototype.invoke_364 = function (invalid) {
    var tmp0_synchronized_0 = lock;
    var tmp0_2 = nextSnapshotId;
    nextSnapshotId = tmp0_2 + 1 | 0;
    return new MutableSnapshot(tmp0_2, invalid, this._$readObserver, this._$writeObserver);
  };
  _no_name_provided__205.prototype.invoke_1115 = function (p1) {
    return this.invoke_364(p1 instanceof SnapshotIdSet ? p1 : THROW_CCE());
  };
  _no_name_provided__205.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function GlobalSnapshot(id, invalid) {
    var tmp0_synchronized_0 = lock;
    var tmp;
    var tmp0_isNotEmpty_0_3 = globalWriteObservers;
    if (!tmp0_isNotEmpty_0_3.isEmpty_44()) {
      tmp = toMutableList_1(globalWriteObservers);
    } else {
      {
        tmp = null;
      }
    }
    var tmp0_safe_receiver_2 = tmp;
    var tmp_0;
    if (tmp0_safe_receiver_2 == null) {
      tmp_0 = null;
    } else {
      var tmp0_elvis_lhs_2_4 = firstOrNull(tmp0_safe_receiver_2);
      var tmp_1;
      if (tmp0_elvis_lhs_2_4 == null) {
        tmp_1 = _no_name_provided_$factory_176(tmp0_safe_receiver_2);
      } else {
        tmp_1 = tmp0_elvis_lhs_2_4;
      }
      tmp_0 = tmp_1;
    }
    MutableSnapshot.call(this, id, invalid, null, tmp_0);
  }
  GlobalSnapshot.prototype.takeNestedMutableSnapshot_2 = function (readObserver, writeObserver) {
    return takeNewSnapshot(_no_name_provided_$factory_177(readObserver, writeObserver));
  };
  GlobalSnapshot.prototype.notifyObjectsInitialized_4 = function () {
    advanceGlobalSnapshot_0();
  };
  GlobalSnapshot.prototype.nestedActivated_2 = function (snapshot) {
    unsupported_0();
  };
  GlobalSnapshot.prototype.nestedActivated_4 = function (snapshot) {
    return this.nestedActivated_2(snapshot);
  };
  GlobalSnapshot.prototype.apply_4 = function () {
    var tmp0_error_0 = 'Cannot apply the global snapshot directly. Call Snapshot.advanceGlobalSnapshot';
    throw IllegalStateException_init_$Create$_0(toString_2(tmp0_error_0));
  };
  GlobalSnapshot.$metadata$ = {
    simpleName: 'GlobalSnapshot',
    kind: 'class',
    interfaces: []
  };
  function StateObject() {
  }
  StateObject.prototype.mergeRecords_0 = function (previous, current_0, applied) {
    return null;
  };
  StateObject.$metadata$ = {
    simpleName: 'StateObject',
    kind: 'interface',
    interfaces: []
  };
  function currentSnapshot() {
    var tmp0_elvis_lhs = threadSnapshot.get_60();
    return tmp0_elvis_lhs == null ? currentGlobalSnapshot.get_60() : tmp0_elvis_lhs;
  }
  function _get_currentSnapshot_($this) {
    var tmp0_elvis_lhs = $this._previousSnapshot;
    return tmp0_elvis_lhs == null ? currentGlobalSnapshot.get_60() : tmp0_elvis_lhs;
  }
  function TransparentObserverMutableSnapshot(previousSnapshot, specifiedReadObserver, specifiedWriteObserver) {
    var tmp = Companion_getInstance_31()._EMPTY_3;
    var tmp0_safe_receiver = previousSnapshot;
    var tmp1_elvis_lhs = tmp0_safe_receiver == null ? null : tmp0_safe_receiver._get_readObserver__3();
    var tmp_0 = mergedReadObserver(specifiedReadObserver, tmp1_elvis_lhs == null ? currentGlobalSnapshot.get_60()._get_readObserver__3() : tmp1_elvis_lhs);
    var tmp2_safe_receiver = previousSnapshot;
    var tmp3_elvis_lhs = tmp2_safe_receiver == null ? null : tmp2_safe_receiver._get_writeObserver__3();
    MutableSnapshot.call(this, 0, tmp, tmp_0, mergedWriteObserver(specifiedWriteObserver, tmp3_elvis_lhs == null ? currentGlobalSnapshot.get_60()._get_writeObserver__3() : tmp3_elvis_lhs));
    this._previousSnapshot = previousSnapshot;
    this._specifiedReadObserver = specifiedReadObserver;
    this._specifiedWriteObserver = specifiedWriteObserver;
  }
  TransparentObserverMutableSnapshot.prototype.dispose_15 = function () {
    this._set_disposed__0(true);
  };
  TransparentObserverMutableSnapshot.prototype._set_id__3 = function (value_0) {
    unsupported_0();
  };
  TransparentObserverMutableSnapshot.prototype._get_id__26 = function () {
    return _get_currentSnapshot_(this)._get_id__26();
  };
  TransparentObserverMutableSnapshot.prototype._set_invalid__3 = function (value_0) {
    unsupported_0();
  };
  TransparentObserverMutableSnapshot.prototype._get_invalid__3 = function () {
    return _get_currentSnapshot_(this)._get_invalid__3();
  };
  TransparentObserverMutableSnapshot.prototype._set_modified__2 = function (value_0) {
    unsupported_0();
  };
  TransparentObserverMutableSnapshot.prototype._get_modified__2 = function () {
    return _get_currentSnapshot_(this)._get_modified__2();
  };
  TransparentObserverMutableSnapshot.prototype._get_readOnly__4 = function () {
    return _get_currentSnapshot_(this)._get_readOnly__4();
  };
  TransparentObserverMutableSnapshot.prototype.apply_4 = function () {
    return _get_currentSnapshot_(this).apply_4();
  };
  TransparentObserverMutableSnapshot.prototype.recordModified_3 = function (state) {
    return _get_currentSnapshot_(this).recordModified_3(state);
  };
  TransparentObserverMutableSnapshot.prototype.takeNestedMutableSnapshot_2 = function (readObserver, writeObserver) {
    return _get_currentSnapshot_(this).takeNestedMutableSnapshot_2(mergedReadObserver(readObserver, this._get_readObserver__3()), mergedWriteObserver(writeObserver, this._get_writeObserver__3()));
  };
  TransparentObserverMutableSnapshot.prototype.notifyObjectsInitialized_4 = function () {
    return _get_currentSnapshot_(this).notifyObjectsInitialized_4();
  };
  TransparentObserverMutableSnapshot.prototype.nestedActivated_2 = function (snapshot) {
    unsupported_0();
  };
  TransparentObserverMutableSnapshot.prototype.nestedActivated_4 = function (snapshot) {
    return this.nestedActivated_2(snapshot);
  };
  TransparentObserverMutableSnapshot.$metadata$ = {
    simpleName: 'TransparentObserverMutableSnapshot',
    kind: 'class',
    interfaces: []
  };
  function advanceGlobalSnapshot(block) {
    var previousGlobalSnapshot = currentGlobalSnapshot.get_60();
    var tmp0_synchronized_0_1 = lock;
    var result = takeNewGlobalSnapshot(previousGlobalSnapshot, block);
    var modified = previousGlobalSnapshot._get_modified__2();
    if (!(modified == null)) {
      var tmp0_synchronized_0_1_0 = lock;
      var observers = toMutableList_1(applyObservers);
      var inductionVariable = 0;
      var last_2 = observers._get_size__52() - 1 | 0;
      if (inductionVariable <= last_2)
        do {
          var index_2 = inductionVariable;
          inductionVariable = inductionVariable + 1 | 0;
          var item_3 = observers.get_72(index_2);
          item_3(modified, previousGlobalSnapshot);
        }
         while (inductionVariable <= last_2);
    }var tmp = threadSnapshot.get_60();
    var tmp0_safe_receiver = tmp instanceof TransparentObserverMutableSnapshot ? tmp : null;
    if (tmp0_safe_receiver == null)
      null;
    else {
      threadSnapshot.set_9(new TransparentObserverMutableSnapshot(currentGlobalSnapshot.get_60(), tmp0_safe_receiver._specifiedReadObserver, tmp0_safe_receiver._specifiedWriteObserver));
      tmp0_safe_receiver.dispose_15();
      Unit_getInstance();
    }
    Unit_getInstance();
    return result;
  }
  function advanceGlobalSnapshot_0() {
    return advanceGlobalSnapshot(_no_name_provided_$factory_178());
  }
  function NestedMutableSnapshot(id, invalid, readObserver, writeObserver, parent_0) {
    MutableSnapshot.call(this, id, invalid, readObserver, writeObserver);
    this._parent_3 = parent_0;
    this._parent_3.nestedActivated_4(this);
  }
  NestedMutableSnapshot.prototype.apply_4 = function () {
    if (this._parent_3._applied ? true : this._parent_3._get_disposed__3())
      return new Failure_0(this);
    var modified = this._get_modified__2();
    var id = this._get_id__26();
    var optimisticMerges_0 = !(modified == null) ? optimisticMerges(this._parent_3, this, this._parent_3._get_invalid__3()) : null;
    var tmp0_synchronized_0_1 = lock;
    validateOpen(this);
    if (modified == null ? true : modified._get_size__52() === 0) {
      this.close_7();
    } else {
      var result_3 = this.innerApply_2(this._parent_3._get_id__26(), optimisticMerges_0, this._parent_3._get_invalid__3());
      if (!equals_0(result_3, Success_getInstance()))
        return result_3;
      var tmp0_elvis_lhs_4 = this._parent_3._get_modified__2();
      var tmp;
      if (tmp0_elvis_lhs_4 == null) {
        var tmp0_also_0_5 = HashSet_init_$Create$();
        this._parent_3._set_modified__2(tmp0_also_0_5);
        tmp = tmp0_also_0_5;
      } else {
        tmp = tmp0_elvis_lhs_4;
      }
      tmp.addAll_14(modified);
      Unit_getInstance();
    }
    if (this._parent_3._get_id__26() < id) {
      this._parent_3.advance_2();
    }this._parent_3._set_invalid__3(this._parent_3._get_invalid__3().clear_10(id).andNot(this._get_previousIds__0()));
    this._parent_3.recordPrevious_2(id);
    this._parent_3.recordPreviousList_2(this._get_previousIds__0());
    this._set_applied__0(true);
    return Success_getInstance();
  };
  NestedMutableSnapshot.$metadata$ = {
    simpleName: 'NestedMutableSnapshot',
    kind: 'class',
    interfaces: []
  };
  function mergedReadObserver(readObserver, parentObserver) {
    var tmp;
    if ((!(readObserver == null) ? !(parentObserver == null) : false) ? !equals_0(readObserver, parentObserver) : false) {
      tmp = _no_name_provided_$factory_179(readObserver, parentObserver);
    } else {
      var tmp0_elvis_lhs = readObserver;
      tmp = tmp0_elvis_lhs == null ? parentObserver : tmp0_elvis_lhs;
    }
    return tmp;
  }
  function mergedWriteObserver(writeObserver, parentObserver) {
    var tmp;
    if ((!(writeObserver == null) ? !(parentObserver == null) : false) ? !equals_0(writeObserver, parentObserver) : false) {
      tmp = _no_name_provided_$factory_180(writeObserver, parentObserver);
    } else {
      var tmp0_elvis_lhs = writeObserver;
      tmp = tmp0_elvis_lhs == null ? parentObserver : tmp0_elvis_lhs;
    }
    return tmp;
  }
  function StateRecord() {
    this._snapshotId = currentSnapshot()._get_id__26();
    this._next_5 = null;
    this._$stable_9 = 8;
  }
  StateRecord.$metadata$ = {
    simpleName: 'StateRecord',
    kind: 'class',
    interfaces: []
  };
  function optimisticMerges(currentSnapshot_0, applyingSnapshot, invalidSnapshots) {
    var modified = applyingSnapshot._get_modified__2();
    var id = currentSnapshot_0._get_id__26();
    if (modified == null)
      return null;
    var start_0 = applyingSnapshot._get_invalid__3().set_7(applyingSnapshot._get_id__26()).or_3(applyingSnapshot._previousIds);
    var result = null;
    var tmp0_iterator = modified.iterator_66();
    while (tmp0_iterator.hasNext_41()) {
      var state = tmp0_iterator.next_46();
      var first_4 = state._get_firstStateRecord__0();
      var tmp1_elvis_lhs = readable(first_4, id, invalidSnapshots);
      var tmp;
      if (tmp1_elvis_lhs == null) {
        continue;
      } else {
        tmp = tmp1_elvis_lhs;
      }
      var current_0 = tmp;
      var tmp2_elvis_lhs = readable(first_4, id, start_0);
      var tmp_0;
      if (tmp2_elvis_lhs == null) {
        continue;
      } else {
        tmp_0 = tmp2_elvis_lhs;
      }
      var previous = tmp_0;
      if (!equals_0(current_0, previous)) {
        var tmp3_elvis_lhs = readable(first_4, applyingSnapshot._get_id__26(), applyingSnapshot._get_invalid__3());
        var tmp_1;
        if (tmp3_elvis_lhs == null) {
          readError();
        } else {
          tmp_1 = tmp3_elvis_lhs;
        }
        var applied = tmp_1;
        var merged = state.mergeRecords_0(previous, current_0, applied);
        if (!(merged == null)) {
          var tmp4_elvis_lhs = result;
          var tmp_2;
          if (tmp4_elvis_lhs == null) {
            var tmp0_also_0 = HashMap_init_$Create$();
            result = tmp0_also_0;
            tmp_2 = tmp0_also_0;
          } else {
            tmp_2 = tmp4_elvis_lhs;
          }
          var tmp1_set_0 = tmp_2;
          tmp1_set_0.put_6(current_0, merged);
          Unit_getInstance();
        } else {
          return null;
        }
      }}
    return result;
  }
  function validateOpen(snapshot) {
    if (!openSnapshots.get_72(snapshot._get_id__26())) {
      var tmp0_error_0 = 'Snapshot is not open';
      throw IllegalStateException_init_$Create$_0(toString_2(tmp0_error_0));
    }}
  function takeNewGlobalSnapshot(previousGlobalSnapshot, block) {
    var result = block(openSnapshots.clear_10(previousGlobalSnapshot._get_id__26()));
    var tmp0_synchronized_0_1 = lock;
    var tmp0_4 = nextSnapshotId;
    nextSnapshotId = tmp0_4 + 1 | 0;
    var globalId_3 = tmp0_4;
    openSnapshots = openSnapshots.clear_10(previousGlobalSnapshot._get_id__26());
    currentGlobalSnapshot.set_8(new GlobalSnapshot(globalId_3, openSnapshots));
    openSnapshots = openSnapshots.set_7(globalId_3);
    return result;
  }
  function readable(r, id, invalid) {
    var current_0 = r;
    var candidate = null;
    while (!(current_0 == null)) {
      if (valid(current_0, id, invalid)) {
        candidate = candidate == null ? current_0 : candidate._snapshotId < current_0._snapshotId ? current_0 : candidate;
      }current_0 = current_0._next_5;
    }
    if (!(candidate == null)) {
      return candidate instanceof StateRecord ? candidate : THROW_CCE();
    }return null;
  }
  function readError() {
    var tmp0_error_0 = 'Reading a state that was created after the snapshot was taken or in a snapshot that has not yet been applied';
    throw IllegalStateException_init_$Create$_0(toString_2(tmp0_error_0));
  }
  function takeNewSnapshot(block) {
    return advanceGlobalSnapshot(_no_name_provided_$factory_181(block));
  }
  function valid(data, snapshot, invalid) {
    return valid_0(snapshot, data._snapshotId, invalid);
  }
  function valid_0(currentSnapshot_0, candidateSnapshot, invalid) {
    return (!(candidateSnapshot === 0) ? candidateSnapshot <= currentSnapshot_0 : false) ? !invalid.get_72(candidateSnapshot) : false;
  }
  function readable_0(_this_, state) {
    return readable_1(_this_, state, currentSnapshot());
  }
  function readable_1(_this_, state, snapshot) {
    var tmp0_safe_receiver = snapshot._get_readObserver__3();
    if (tmp0_safe_receiver == null)
      null;
    else
      tmp0_safe_receiver(state);
    Unit_getInstance();
    var tmp1_elvis_lhs = readable(_this_, snapshot._get_id__26(), snapshot._get_invalid__3());
    var tmp;
    if (tmp1_elvis_lhs == null) {
      readError();
    } else {
      tmp = tmp1_elvis_lhs;
    }
    return tmp;
  }
  function current(r, snapshot) {
    var tmp0_elvis_lhs = readable(r, snapshot._get_id__26(), snapshot._get_invalid__3());
    var tmp;
    if (tmp0_elvis_lhs == null) {
      readError();
    } else {
      tmp = tmp0_elvis_lhs;
    }
    return tmp;
  }
  function notifyWrite(snapshot, state) {
    var tmp0_safe_receiver = snapshot._get_writeObserver__3();
    if (tmp0_safe_receiver == null)
      null;
    else
      tmp0_safe_receiver(state);
    Unit_getInstance();
  }
  function overwritableRecord(_this_, state, snapshot, candidate) {
    if (snapshot._get_readOnly__4()) {
      snapshot.recordModified_3(state);
    }var id = snapshot._get_id__26();
    if (candidate._snapshotId === id)
      return candidate;
    var newData = newOverwritableRecord(_this_, state, snapshot);
    newData._snapshotId = id;
    snapshot.recordModified_3(state);
    return newData;
  }
  function newOverwritableRecord(_this_, state, snapshot) {
    var tmp = used(state, snapshot._get_id__26(), openSnapshots);
    var tmp0_safe_receiver = (tmp == null ? true : tmp instanceof StateRecord) ? tmp : THROW_CCE();
    var tmp_0;
    if (tmp0_safe_receiver == null) {
      tmp_0 = null;
    } else {
      tmp0_safe_receiver._snapshotId = IntCompanionObject_getInstance()._MAX_VALUE_2;
      tmp_0 = tmp0_safe_receiver;
    }
    var tmp1_elvis_lhs = tmp_0;
    var tmp_1;
    if (tmp1_elvis_lhs == null) {
      var tmp0_apply_0 = _this_.create_8();
      tmp0_apply_0._snapshotId = IntCompanionObject_getInstance()._MAX_VALUE_2;
      tmp0_apply_0._next_5 = state._get_firstStateRecord__0();
      state.prependStateRecord_0(tmp0_apply_0 instanceof StateRecord ? tmp0_apply_0 : THROW_CCE());
      var tmp_2 = tmp0_apply_0;
      tmp_1 = tmp_2 instanceof StateRecord ? tmp_2 : THROW_CCE();
    } else {
      tmp_1 = tmp1_elvis_lhs;
    }
    return tmp_1;
  }
  function used(state, id, invalid) {
    var current_0 = state._get_firstStateRecord__0();
    var validRecord = null;
    var lowestOpen = invalid.lowest(id);
    while (!(current_0 == null)) {
      var currentId = current_0._snapshotId;
      if (currentId === 0) {
        return current_0;
      }if (valid(current_0, lowestOpen, invalid)) {
        if (validRecord == null) {
          validRecord = current_0;
        } else {
          return current_0._snapshotId < validRecord._snapshotId ? current_0 : validRecord;
        }
      }current_0 = current_0._next_5;
    }
    return null;
  }
  function _no_name_provided__206() {
  }
  _no_name_provided__206.prototype.invoke_358 = function (it) {
    return Unit_getInstance();
  };
  _no_name_provided__206.prototype.invoke_1115 = function (p1) {
    this.invoke_358(p1 instanceof SnapshotIdSet ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__206.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__207() {
  }
  _no_name_provided__207.prototype.invoke_358 = function (it) {
    return Unit_getInstance();
  };
  _no_name_provided__207.prototype.invoke_1115 = function (p1) {
    this.invoke_358(p1 instanceof SnapshotIdSet ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__207.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__208($readObserver, $parentObserver) {
    this._$readObserver_0 = $readObserver;
    this._$parentObserver = $parentObserver;
  }
  _no_name_provided__208.prototype.invoke_918 = function (state) {
    this._$readObserver_0(state);
    this._$parentObserver(state);
  };
  _no_name_provided__208.prototype.invoke_1115 = function (p1) {
    this.invoke_918(isObject(p1) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__208.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__209($writeObserver, $parentObserver) {
    this._$writeObserver_0 = $writeObserver;
    this._$parentObserver_0 = $parentObserver;
  }
  _no_name_provided__209.prototype.invoke_918 = function (state) {
    this._$writeObserver_0(state);
    this._$parentObserver_0(state);
  };
  _no_name_provided__209.prototype.invoke_1115 = function (p1) {
    this.invoke_918(isObject(p1) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__209.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__210($block) {
    this._$block_3 = $block;
  }
  _no_name_provided__210.prototype.invoke_364 = function (invalid) {
    var result = this._$block_3(invalid);
    var tmp0_synchronized_0_1 = lock;
    openSnapshots = openSnapshots.set_7(result._get_id__26());
    return result;
  };
  _no_name_provided__210.prototype.invoke_1115 = function (p1) {
    return this.invoke_364(p1 instanceof SnapshotIdSet ? p1 : THROW_CCE());
  };
  _no_name_provided__210.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided_$factory_175() {
    var i = new _no_name_provided__206();
    return function (p1) {
      i.invoke_358(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_176($tmp0_safe_receiver_2) {
    var i = new _no_name_provided__204($tmp0_safe_receiver_2);
    return function (p1) {
      i.invoke_918(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_177($readObserver, $writeObserver) {
    var i = new _no_name_provided__205($readObserver, $writeObserver);
    return function (p1) {
      return i.invoke_364(p1);
    };
  }
  function _no_name_provided_$factory_178() {
    var i = new _no_name_provided__207();
    return function (p1) {
      i.invoke_358(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_179($readObserver, $parentObserver) {
    var i = new _no_name_provided__208($readObserver, $parentObserver);
    return function (p1) {
      i.invoke_918(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_180($writeObserver, $parentObserver) {
    var i = new _no_name_provided__209($writeObserver, $parentObserver);
    return function (p1) {
      i.invoke_918(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_181($block) {
    var i = new _no_name_provided__210($block);
    return function (p1) {
      return i.invoke_364(p1);
    };
  }
  function emptyLambda$init$() {
    return _no_name_provided_$factory_175();
  }
  function applyObservers$init$() {
    return ArrayList_init_$Create$();
  }
  function globalWriteObservers$init$() {
    return ArrayList_init_$Create$();
  }
  function currentGlobalSnapshot$init$() {
    var tmp0 = nextSnapshotId;
    nextSnapshotId = tmp0 + 1 | 0;
    var tmp0_also_0 = new GlobalSnapshot(tmp0, Companion_getInstance_31()._EMPTY_3);
    openSnapshots = openSnapshots.set_7(tmp0_also_0._get_id__26());
    return new AtomicReference(tmp0_also_0);
  }
  function Companion_32() {
    Companion_instance_31 = this;
    this._EMPTY_3 = new SnapshotIdSet(new Long(0, 0), new Long(0, 0), 0, null);
  }
  Companion_32.$metadata$ = {
    simpleName: 'Companion',
    kind: 'object',
    interfaces: []
  };
  var Companion_instance_31;
  function Companion_getInstance_31() {
    if (Companion_instance_31 == null)
      new Companion_32();
    return Companion_instance_31;
  }
  function _no_name_provided__211(this$0, resultContinuation) {
    this._this$0_38 = this$0;
    CoroutineImpl_0.call(this, resultContinuation);
  }
  _no_name_provided__211.prototype.invoke_366 = function ($this$sequence, $cont) {
    var tmp = this.create_9($this$sequence, $cont);
    tmp._result_0 = Unit_getInstance();
    tmp._exception_0 = null;
    return tmp.doResume_17();
  };
  _no_name_provided__211.prototype.doResume_17 = function () {
    var suspendResult = this._result_0;
    $sm: do
      try {
        var tmp = this._state_2;
        switch (tmp) {
          case 0:
            this._exceptionState = 15;
            this._belowBound0 = this._this$0_38._belowBound;
            if (!(this._belowBound0 == null)) {
              this._tmp0_iterator1 = intArrayIterator(this._belowBound0);
              this._state_2 = 1;
              continue $sm;
            } else {
              this._state_2 = 4;
              continue $sm;
            }

            break;
          case 1:
            if (!this._tmp0_iterator1.hasNext_41()) {
              this._state_2 = 3;
              continue $sm;
            }
            this._element2 = this._tmp0_iterator1.next_46();
            this._state_2 = 2;
            suspendResult = this._$this$sequence_3.yield_0(this._element2, this);
            if (suspendResult === _get_COROUTINE_SUSPENDED_()) {
              return suspendResult;
            }
            continue $sm;
          case 2:
            suspendResult;
            this._state_2 = 1;
            continue $sm;
          case 3:
            this._state_2 = 4;
            continue $sm;
          case 4:
            if (!this._this$0_38._lowerSet.equals(new Long(0, 0))) {
              var tmp_0 = this;
              Companion_getInstance_10();
              tmp_0._tmp1_iterator3 = until(0, 64).iterator_66();
              this._state_2 = 5;
              continue $sm;
            } else {
              this._state_2 = 9;
              continue $sm;
            }

            break;
          case 5:
            if (!this._tmp1_iterator3.hasNext_41()) {
              this._state_2 = 8;
              continue $sm;
            }
            this._index4 = this._tmp1_iterator3.next_46();
            if (!this._this$0_38._lowerSet.and((new Long(1, 0)).shl_4(this._index4)).equals(new Long(0, 0))) {
              this._state_2 = 6;
              suspendResult = this._$this$sequence_3.yield_0(this._index4 + this._this$0_38._lowerBound | 0, this);
              if (suspendResult === _get_COROUTINE_SUSPENDED_()) {
                return suspendResult;
              }continue $sm;
            } else {
              this._state_2 = 7;
              continue $sm;
            }

            break;
          case 6:
            suspendResult;
            this._state_2 = 7;
            continue $sm;
          case 7:
            this._state_2 = 5;
            continue $sm;
          case 8:
            this._state_2 = 9;
            continue $sm;
          case 9:
            if (!this._this$0_38._upperSet.equals(new Long(0, 0))) {
              var tmp_1 = this;
              Companion_getInstance_10();
              tmp_1._tmp2_iterator5 = until(0, 64).iterator_66();
              this._state_2 = 10;
              continue $sm;
            } else {
              this._state_2 = 14;
              continue $sm;
            }

            break;
          case 10:
            if (!this._tmp2_iterator5.hasNext_41()) {
              this._state_2 = 13;
              continue $sm;
            }
            this._index6 = this._tmp2_iterator5.next_46();
            if (!this._this$0_38._upperSet.and((new Long(1, 0)).shl_4(this._index6)).equals(new Long(0, 0))) {
              this._state_2 = 11;
              Companion_getInstance_10();
              suspendResult = this._$this$sequence_3.yield_0((this._index6 + 64 | 0) + this._this$0_38._lowerBound | 0, this);
              if (suspendResult === _get_COROUTINE_SUSPENDED_()) {
                return suspendResult;
              }continue $sm;
            } else {
              this._state_2 = 12;
              continue $sm;
            }

            break;
          case 11:
            suspendResult;
            this._state_2 = 12;
            continue $sm;
          case 12:
            this._state_2 = 10;
            continue $sm;
          case 13:
            this._state_2 = 14;
            continue $sm;
          case 14:
            return Unit_getInstance();
          case 15:
            throw this._exception_0;
        }
      } catch ($p) {
        if (this._exceptionState === 15) {
          throw $p;
        } else {
          this._state_2 = this._exceptionState;
          this._exception_0 = $p;
        }
      }
     while (true);
  };
  _no_name_provided__211.prototype.create_9 = function ($this$sequence, completion) {
    var i = new _no_name_provided__211(this._this$0_38, completion);
    i._$this$sequence_3 = $this$sequence;
    return i;
  };
  _no_name_provided__211.$metadata$ = {
    kind: 'class',
    interfaces: [],
    suspendArity: 1
  };
  function SnapshotIdSet(upperSet, lowerSet, lowerBound, belowBound) {
    Companion_getInstance_31();
    this._upperSet = upperSet;
    this._lowerSet = lowerSet;
    this._lowerBound = lowerBound;
    this._belowBound = belowBound;
  }
  SnapshotIdSet.prototype.get_72 = function (bit) {
    var offset = bit - this._lowerBound | 0;
    var tmp;
    if (offset >= 0) {
      Companion_getInstance_10();
      tmp = offset < 64;
    } else {
      tmp = false;
    }
    if (tmp) {
      return !(new Long(1, 0)).shl_4(offset).and(this._lowerSet).equals(new Long(0, 0));
    } else {
      var tmp_0;
      Companion_getInstance_10();
      if (offset >= 64) {
        Companion_getInstance_10();
        tmp_0 = offset < imul(64, 2);
      } else {
        {
          tmp_0 = false;
        }
      }
      if (tmp_0) {
        var tmp_1 = new Long(1, 0);
        Companion_getInstance_10();
        return !tmp_1.shl_4(offset - 64 | 0).and(this._upperSet).equals(new Long(0, 0));
      } else {
        if (offset > 0) {
          return false;
        } else {
          {
            var tmp0_safe_receiver = this._belowBound;
            var tmp_2;
            if (tmp0_safe_receiver == null) {
              tmp_2 = null;
            } else {
              tmp_2 = binarySearch(tmp0_safe_receiver, bit) >= 0;
            }
            var tmp1_elvis_lhs = tmp_2;
            return tmp1_elvis_lhs == null ? false : tmp1_elvis_lhs;
          }
        }
      }
    }
  };
  SnapshotIdSet.prototype.set_7 = function (bit) {
    var offset = bit - this._lowerBound | 0;
    var tmp;
    if (offset >= 0) {
      Companion_getInstance_10();
      tmp = offset < 64;
    } else {
      tmp = false;
    }
    if (tmp) {
      var mask = (new Long(1, 0)).shl_4(offset);
      if (this._lowerSet.and(mask).equals(new Long(0, 0))) {
        return new SnapshotIdSet(this._upperSet, this._lowerSet.or(mask), this._lowerBound, this._belowBound);
      }} else {
      var tmp_0;
      Companion_getInstance_10();
      if (offset >= 64) {
        Companion_getInstance_10();
        tmp_0 = offset < imul(64, 2);
      } else {
        {
          tmp_0 = false;
        }
      }
      if (tmp_0) {
        var tmp_1 = new Long(1, 0);
        Companion_getInstance_10();
        var mask_0 = tmp_1.shl_4(offset - 64 | 0);
        if (this._upperSet.and(mask_0).equals(new Long(0, 0))) {
          return new SnapshotIdSet(this._upperSet.or(mask_0), this._lowerSet, this._lowerBound, this._belowBound);
        }} else {
        Companion_getInstance_10();
        if (offset >= imul(64, 2)) {
          if (!this.get_72(bit)) {
            var newUpperSet = this._upperSet;
            var newLowerSet = this._lowerSet;
            var newLowerBound = this._lowerBound;
            var newBelowBound = null;
            var tmp_2 = bit + 1 | 0;
            Companion_getInstance_10();
            var tmp_3 = tmp_2 / 64 | 0;
            Companion_getInstance_10();
            var targetLowerBound = imul(tmp_3, 64);
            $l$break: while (newLowerBound < targetLowerBound) {
              if (!newLowerSet.equals(new Long(0, 0))) {
                if (newBelowBound == null) {
                  var tmp0_apply_0 = ArrayList_init_$Create$();
                  var tmp0_safe_receiver_2 = this._belowBound;
                  if (tmp0_safe_receiver_2 == null)
                    null;
                  else {
                    var indexedObject = tmp0_safe_receiver_2;
                    var inductionVariable = 0;
                    var last_2 = indexedObject.length;
                    while (inductionVariable < last_2) {
                      var element_2_3_4 = indexedObject[inductionVariable];
                      inductionVariable = inductionVariable + 1 | 0;
                      tmp0_apply_0.add_39(element_2_3_4);
                      Unit_getInstance();
                    }
                    Unit_getInstance();
                  }
                  Unit_getInstance();
                  newBelowBound = tmp0_apply_0;
                }Companion_getInstance_10();
                var tmp1_repeat_0 = 64;
                var inductionVariable_0 = 0;
                if (inductionVariable_0 < tmp1_repeat_0)
                  do {
                    var index_2 = inductionVariable_0;
                    inductionVariable_0 = inductionVariable_0 + 1 | 0;
                    if (!newLowerSet.and((new Long(1, 0)).shl_4(index_2)).equals(new Long(0, 0))) {
                      newBelowBound.add_39(index_2 + newLowerBound | 0);
                      Unit_getInstance();
                    }}
                   while (inductionVariable_0 < tmp1_repeat_0);
              }if (newUpperSet.equals(new Long(0, 0))) {
                newLowerBound = targetLowerBound;
                newLowerSet = new Long(0, 0);
                break $l$break;
              }newLowerSet = newUpperSet;
              newUpperSet = new Long(0, 0);
              var tmp_4 = newLowerBound;
              Companion_getInstance_10();
              newLowerBound = tmp_4 + 64 | 0;
            }
            var tmp_5 = newUpperSet;
            var tmp_6 = newLowerSet;
            var tmp_7 = newLowerBound;
            var tmp0_safe_receiver = newBelowBound;
            var tmp1_elvis_lhs = tmp0_safe_receiver == null ? null : toIntArray(tmp0_safe_receiver);
            return (new SnapshotIdSet(tmp_5, tmp_6, tmp_7, tmp1_elvis_lhs == null ? this._belowBound : tmp1_elvis_lhs)).set_7(bit);
          }} else {
          {
            var tmp2_elvis_lhs = this._belowBound;
            var tmp_8;
            if (tmp2_elvis_lhs == null) {
              var tmp2_intArrayOf_0 = new Int32Array([bit]);
              return new SnapshotIdSet(this._upperSet, this._lowerSet, this._lowerBound, tmp2_intArrayOf_0);
            } else {
              tmp_8 = tmp2_elvis_lhs;
            }
            var array = tmp_8;
            var location = binarySearch(array, bit);
            if (location < 0) {
              var insertLocation = -(location + 1 | 0) | 0;
              var newSize = array.length + 1 | 0;
              var newBelowBound_0 = new Int32Array(newSize);
              var tmp3_copyInto_0 = 0;
              var tmp4_copyInto_0 = 0;
              var tmp_9 = array;
              arrayCopy_0(tmp_9, newBelowBound_0, tmp3_copyInto_0, tmp4_copyInto_0, insertLocation);
              Unit_getInstance();
              var tmp5_copyInto_0 = insertLocation + 1 | 0;
              var tmp6_copyInto_0 = newSize - 1 | 0;
              var tmp_10 = array;
              arrayCopy_0(tmp_10, newBelowBound_0, tmp5_copyInto_0, insertLocation, tmp6_copyInto_0);
              Unit_getInstance();
              newBelowBound_0[insertLocation] = bit;
              return new SnapshotIdSet(this._upperSet, this._lowerSet, this._lowerBound, newBelowBound_0);
            }}
        }
      }
    }
    return this;
  };
  SnapshotIdSet.prototype.clear_10 = function (bit) {
    var offset = bit - this._lowerBound | 0;
    var tmp;
    if (offset >= 0) {
      Companion_getInstance_10();
      tmp = offset < 64;
    } else {
      tmp = false;
    }
    if (tmp) {
      var mask = (new Long(1, 0)).shl_4(offset);
      if (!this._lowerSet.and(mask).equals(new Long(0, 0))) {
        return new SnapshotIdSet(this._upperSet, this._lowerSet.and(mask.inv_0()), this._lowerBound, this._belowBound);
      }} else {
      var tmp_0;
      Companion_getInstance_10();
      if (offset >= 64) {
        Companion_getInstance_10();
        tmp_0 = offset < imul(64, 2);
      } else {
        {
          tmp_0 = false;
        }
      }
      if (tmp_0) {
        var tmp_1 = new Long(1, 0);
        Companion_getInstance_10();
        var mask_0 = tmp_1.shl_4(offset - 64 | 0);
        if (!this._upperSet.and(mask_0).equals(new Long(0, 0))) {
          return new SnapshotIdSet(this._upperSet.and(mask_0.inv_0()), this._lowerSet, this._lowerBound, this._belowBound);
        }} else {
        if (offset < 0) {
          var array = this._belowBound;
          if (!(array == null)) {
            var location = binarySearch(array, bit);
            if (location >= 0) {
              var newSize = array.length - 1 | 0;
              if (newSize === 0) {
                return new SnapshotIdSet(this._upperSet, this._lowerSet, this._lowerBound, null);
              }var newBelowBound = new Int32Array(newSize);
              if (location > 0) {
                var tmp0_copyInto_0 = 0;
                var tmp1_copyInto_0 = 0;
                var tmp_2 = array;
                arrayCopy_0(tmp_2, newBelowBound, tmp0_copyInto_0, tmp1_copyInto_0, location);
                Unit_getInstance();
              }if (location < newSize) {
                var tmp2_copyInto_0 = location + 1 | 0;
                var tmp3_copyInto_0 = newSize + 1 | 0;
                var tmp_3 = array;
                arrayCopy_0(tmp_3, newBelowBound, location, tmp2_copyInto_0, tmp3_copyInto_0);
                Unit_getInstance();
              }return new SnapshotIdSet(this._upperSet, this._lowerSet, this._lowerBound, newBelowBound);
            }}} else {
        }
      }
    }
    return this;
  };
  SnapshotIdSet.prototype.andNot = function (bits) {
    if (bits === Companion_getInstance_31()._EMPTY_3)
      return this;
    if (this === Companion_getInstance_31()._EMPTY_3)
      return Companion_getInstance_31()._EMPTY_3;
    var tmp;
    if (bits._lowerBound === this._lowerBound ? bits._belowBound === this._belowBound : false) {
      tmp = new SnapshotIdSet(this._upperSet.and(bits._upperSet.inv_0()), this._lowerSet.and(bits._lowerSet.inv_0()), this._lowerBound, this._belowBound);
    } else {
      var accumulator_1 = this;
      var tmp0_iterator_2 = bits.iterator_66();
      while (tmp0_iterator_2.hasNext_41()) {
        var element_3 = tmp0_iterator_2.next_46();
        var tmp0__anonymous__4 = accumulator_1;
        accumulator_1 = tmp0__anonymous__4.clear_10(element_3);
      }
      tmp = accumulator_1;
    }
    return tmp;
  };
  SnapshotIdSet.prototype.or_3 = function (bits) {
    if (bits === Companion_getInstance_31()._EMPTY_3)
      return this;
    if (this === Companion_getInstance_31()._EMPTY_3)
      return bits;
    var tmp;
    if (bits._lowerBound === this._lowerBound ? bits._belowBound === this._belowBound : false) {
      tmp = new SnapshotIdSet(this._upperSet.or(bits._upperSet), this._lowerSet.or(bits._lowerSet), this._lowerBound, this._belowBound);
    } else {
      var tmp_0;
      if (this._belowBound == null) {
        var accumulator_1 = bits;
        var tmp0_iterator_2 = this.iterator_66();
        while (tmp0_iterator_2.hasNext_41()) {
          var element_3 = tmp0_iterator_2.next_46();
          var tmp0__anonymous__4 = accumulator_1;
          accumulator_1 = tmp0__anonymous__4.set_7(element_3);
        }
        tmp_0 = accumulator_1;
      } else {
        var accumulator_1_0 = this;
        var tmp0_iterator_2_0 = bits.iterator_66();
        while (tmp0_iterator_2_0.hasNext_41()) {
          var element_3_0 = tmp0_iterator_2_0.next_46();
          var tmp1__anonymous__4 = accumulator_1_0;
          accumulator_1_0 = tmp1__anonymous__4.set_7(element_3_0);
        }
        tmp_0 = accumulator_1_0;
      }
      tmp = tmp_0;
    }
    return tmp;
  };
  SnapshotIdSet.prototype.iterator_66 = function () {
    return sequence(_no_name_provided_$factory_182(this, null)).iterator_66();
  };
  SnapshotIdSet.prototype.lowest = function (default_0) {
    var belowBound = this._belowBound;
    if (!(belowBound == null))
      return belowBound[0];
    if (!this._lowerSet.equals(new Long(0, 0)))
      return this._lowerBound + lowestBitOf(this._lowerSet) | 0;
    if (!this._upperSet.equals(new Long(0, 0))) {
      Companion_getInstance_10();
      return (this._lowerBound + 64 | 0) + lowestBitOf(this._upperSet) | 0;
    }return default_0;
  };
  SnapshotIdSet.prototype.toString = function () {
    var tmp = anyToString(this);
    var tmp0_mapTo_0_1 = ArrayList_init_$Create$_0(collectionSizeOrDefault(this, 10));
    var tmp0_iterator_1_2 = this.iterator_66();
    while (tmp0_iterator_1_2.hasNext_41()) {
      var item_2_3 = tmp0_iterator_1_2.next_46();
      tmp0_mapTo_0_1.add_39(item_2_3.toString());
      Unit_getInstance();
    }
    return '' + tmp + ' [' + fastJoinToString$default(tmp0_mapTo_0_1, null, null, null, 0, null, null, 63, null) + ']';
  };
  SnapshotIdSet.$metadata$ = {
    simpleName: 'SnapshotIdSet',
    kind: 'class',
    interfaces: [Iterable]
  };
  function binarySearch(_this_, value_0) {
    var low = 0;
    var high = _this_.length - 1 | 0;
    while (low <= high) {
      var mid = (low + high | 0) >>> 1;
      var midVal = _this_[mid];
      if (value_0 > midVal)
        low = mid + 1 | 0;
      else if (value_0 < midVal)
        high = mid - 1 | 0;
      else
        return mid;
    }
    return -(low + 1 | 0) | 0;
  }
  function lowestBitOf(bits) {
    var b = bits;
    var base = 0;
    if (b.and(new Long(-1, 0)).equals(new Long(0, 0))) {
      base = base + 32 | 0;
      b = b.shr_4(32);
    }if (b.and(new Long(65535, 0)).equals(new Long(0, 0))) {
      base = base + 16 | 0;
      b = b.shr_4(16);
    }if (b.and(new Long(255, 0)).equals(new Long(0, 0))) {
      base = base + 8 | 0;
      b = b.shr_4(8);
    }if (b.and(new Long(15, 0)).equals(new Long(0, 0))) {
      base = base + 4 | 0;
      b = b.shr_4(4);
    }if (!b.and(new Long(1, 0)).equals(new Long(0, 0)))
      return base;
    if (!b.and(new Long(2, 0)).equals(new Long(0, 0)))
      return base + 1 | 0;
    if (!b.and(new Long(4, 0)).equals(new Long(0, 0)))
      return base + 2 | 0;
    if (!b.and(new Long(8, 0)).equals(new Long(0, 0)))
      return base + 3 | 0;
    return -1;
  }
  function _no_name_provided_$factory_182(this$0, resultContinuation) {
    var i = new _no_name_provided__211(this$0, resultContinuation);
    var l = function (p1, $cont) {
      return i.invoke_366(p1, $cont);
    };
    l.$arity = 1;
    return l;
  }
  function SnapshotMutableState() {
  }
  SnapshotMutableState.$metadata$ = {
    simpleName: 'SnapshotMutableState',
    kind: 'interface',
    interfaces: [MutableState]
  };
  function unsupported_0() {
    throw UnsupportedOperationException_init_$Create$();
  }
  function CompositionData() {
  }
  CompositionData.$metadata$ = {
    simpleName: 'CompositionData',
    kind: 'interface',
    interfaces: []
  };
  function CompositionGroup() {
  }
  CompositionGroup.$metadata$ = {
    simpleName: 'CompositionGroup',
    kind: 'interface',
    interfaces: [CompositionData]
  };
  var LocalInspectionTables;
  function _no_name_provided__212() {
  }
  _no_name_provided__212.prototype.invoke_523 = function () {
    return null;
  };
  _no_name_provided__212.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided_$factory_183() {
    var i = new _no_name_provided__212();
    return function () {
      return i.invoke_523();
    };
  }
  var nextHash;
  var DefaultMonotonicFrameClock;
  function AtomicReference(value_0) {
    this._value_9 = value_0;
    this._$stable_10 = 8;
  }
  AtomicReference.prototype.get_60 = function () {
    return this._value_9;
  };
  AtomicReference.prototype.set_8 = function (value_0) {
    this._value_9 = value_0;
  };
  AtomicReference.prototype.getAndSet = function (value_0) {
    var oldValue = this._value_9;
    this._value_9 = value_0;
    return oldValue;
  };
  AtomicReference.prototype.compareAndSet_1 = function (expect, newValue) {
    var tmp;
    if (equals_0(expect, this._value_9)) {
      this._value_9 = newValue;
      tmp = true;
    } else {
      tmp = false;
    }
    return tmp;
  };
  AtomicReference.$metadata$ = {
    simpleName: 'AtomicReference',
    kind: 'class',
    interfaces: []
  };
  function identityHashCode_0(instance) {
    if (instance == null) {
      return 0;
    }var hashCode_1 = instance['kotlinIdentityHashcodeValue$'];
    if (hashCode_1 != null) {
      return hashCode_1;
    }var tmp0_subject = typeof instance;
    var tmp;
    switch (tmp0_subject) {
      case 'object':
      case 'function':
        tmp = memoizeIdentityHashCode(instance);
        break;
      default:throw IllegalArgumentException_init_$Create$_0('' + 'identity hash code for ' + typeof instance + ' is not supported');
    }
    return tmp;
  }
  function SnapshotThreadLocal() {
    this._value_10 = null;
  }
  SnapshotThreadLocal.prototype.get_60 = function () {
    return this._value_10;
  };
  SnapshotThreadLocal.prototype.set_9 = function (value_0) {
    this._value_10 = value_0;
  };
  SnapshotThreadLocal.$metadata$ = {
    simpleName: 'SnapshotThreadLocal',
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__213($onFrame, $safe_2_2) {
    this._$onFrame = $onFrame;
    this._$safe_2_2 = $safe_2_2;
  }
  _no_name_provided__213.prototype.invoke_368 = function (it) {
    var duration_5 = toDuration(it, DurationUnit_MILLISECONDS_getInstance());
    var result_6 = this._$onFrame(Duration__toLongNanoseconds_impl(duration_5));
    var tmp0_success_0_1_7 = Companion_getInstance_2();
    this._$safe_2_2.resumeWith_21(_Result___init__impl_(result_6));
  };
  _no_name_provided__213.prototype.invoke_1115 = function (p1) {
    this.invoke_368((!(p1 == null) ? typeof p1 === 'number' : false) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__213.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function $withFrameNanosCOROUTINE$4(_this_, onFrame, resultContinuation) {
    CoroutineImpl_0.call(this, resultContinuation);
    this.__this__1 = _this_;
    this._onFrame_0 = onFrame;
  }
  $withFrameNanosCOROUTINE$4.prototype.doResume_17 = function () {
    var suspendResult = this._result_0;
    $sm: do
      try {
        var tmp = this._state_2;
        switch (tmp) {
          case 0:
            this._exceptionState = 2;
            this._state_2 = 1;
            var tmp_0 = this;
            tmp_0._tmp0__anonymous__1_10_0 = this;
            this._safe_2_21 = SafeContinuation_init_$Create$(intercepted(this._tmp0__anonymous__1_10_0));
            var tmp_1 = window;
            tmp_1.requestAnimationFrame(_no_name_provided_$factory_184(this._onFrame_0, this._safe_2_21));
            Unit_getInstance();
            suspendResult = this._safe_2_21.getOrThrow();
            if (suspendResult === _get_COROUTINE_SUSPENDED_()) {
              return suspendResult;
            }
            continue $sm;
          case 1:
            return suspendResult;
          case 2:
            throw this._exception_0;
        }
      } catch ($p) {
        if (this._exceptionState === 2) {
          throw $p;
        } else {
          this._state_2 = this._exceptionState;
          this._exception_0 = $p;
        }
      }
     while (true);
  };
  $withFrameNanosCOROUTINE$4.$metadata$ = {
    simpleName: '$withFrameNanosCOROUTINE$4',
    kind: 'class',
    interfaces: []
  };
  function MonotonicClockImpl() {
  }
  MonotonicClockImpl.prototype.withFrameNanos_1 = function (onFrame, $cont) {
    var tmp = new $withFrameNanosCOROUTINE$4(this, onFrame, $cont);
    tmp._result_0 = Unit_getInstance();
    tmp._exception_0 = null;
    return tmp.doResume_17();
  };
  MonotonicClockImpl.$metadata$ = {
    simpleName: 'MonotonicClockImpl',
    kind: 'class',
    interfaces: [MonotonicFrameClock]
  };
  function memoizeIdentityHashCode(instance) {
    var tmp0 = nextHash;
    nextHash = tmp0 + 1 | 0;
    var value_0 = tmp0;
    var descriptor = new Object();
    descriptor.value = value_0;
    descriptor.writable = false;
    descriptor.configurable = false;
    descriptor.enumerable = false;
    Object.defineProperty(instance, 'kotlinIdentityHashcodeValue$', descriptor);
    return value_0;
  }
  function createSnapshotMutableState(value_0, policy) {
    return new SnapshotMutableStateImpl(value_0, policy);
  }
  function _no_name_provided_$factory_184($onFrame, $safe_2_2) {
    var i = new _no_name_provided__213($onFrame, $safe_2_2);
    return function (p1) {
      i.invoke_368(p1);
      return Unit_getInstance();
    };
  }
  function Trace() {
    Trace_instance = this;
  }
  Trace.prototype.beginSection = function (name) {
    return null;
  };
  Trace.prototype.endSection = function (token) {
  };
  Trace.$metadata$ = {
    simpleName: 'Trace',
    kind: 'object',
    interfaces: []
  };
  var Trace_instance;
  function Trace_getInstance() {
    if (Trace_instance == null)
      new Trace();
    return Trace_instance;
  }
  function ComposableLambda() {
  }
  ComposableLambda.$metadata$ = {
    simpleName: 'ComposableLambda',
    kind: 'interface',
    interfaces: []
  };
  function trackWrite($this) {
    if ($this._tracked) {
      var scope = $this._scope_0;
      if (!(scope == null)) {
        scope.invalidate_2();
        $this._scope_0 = null;
      }var scopes = $this._scopes;
      if (!(scopes == null)) {
        var inductionVariable = 0;
        var last_2 = scopes._get_size__52();
        if (inductionVariable < last_2)
          do {
            var index = inductionVariable;
            inductionVariable = inductionVariable + 1 | 0;
            var item = scopes.get_72(index);
            item.invalidate_2();
          }
           while (inductionVariable < last_2);
        scopes.clear_9();
      }}}
  function trackRead($this, composer) {
    if ($this._tracked) {
      var scope = composer._get_recomposeScope__0();
      if (!(scope == null)) {
        composer.recordUsed_0(scope);
        var lastScope = $this._scope_0;
        if (replacableWith(lastScope, scope)) {
          $this._scope_0 = scope;
        } else {
          var lastScopes = $this._scopes;
          if (lastScopes == null) {
            var newScopes = ArrayList_init_$Create$();
            $this._scopes = newScopes;
            newScopes.add_39(scope);
            Unit_getInstance();
          } else {
            var inductionVariable = 0;
            var last_2 = lastScopes._get_size__52();
            if (inductionVariable < last_2)
              do {
                var index = inductionVariable;
                inductionVariable = inductionVariable + 1 | 0;
                var scopeAtIndex = lastScopes.get_72(index);
                if (replacableWith(scopeAtIndex, scope)) {
                  lastScopes.set_3(index, scope);
                  Unit_getInstance();
                  return Unit_getInstance();
                }}
               while (inductionVariable < last_2);
            lastScopes.add_39(scope);
            Unit_getInstance();
          }
        }
      }}}
  function invoke$invoke(receiver, p0, p1) {
    receiver.invoke_912(p0, p1);
    Unit_getInstance();
  }
  function _no_name_provided__214($boundThis) {
    this._$boundThis_1 = $boundThis;
  }
  _no_name_provided__214.prototype.invoke_892 = function (p0, p1) {
    return invoke$invoke(this._$boundThis_1, p0, p1);
  };
  _no_name_provided__214.prototype.invoke_915 = function (p1, p2) {
    var tmp = (!(p1 == null) ? isInterface(p1, Composer) : false) ? p1 : THROW_CCE();
    this.invoke_892(tmp, (!(p2 == null) ? typeof p2 === 'number' : false) ? p2 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__214.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__215(this$0, $p1, $changed) {
    this._this$0_39 = this$0;
    this._$p1 = $p1;
    this._$changed = $changed;
  }
  _no_name_provided__215.prototype.invoke_892 = function (nc, _anonymous_parameter_1_) {
    this._this$0_39.invoke_377(this._$p1, nc, this._$changed | 1);
    Unit_getInstance();
  };
  _no_name_provided__215.prototype.invoke_915 = function (p1, p2) {
    var tmp = (!(p1 == null) ? isInterface(p1, Composer) : false) ? p1 : THROW_CCE();
    this.invoke_892(tmp, (!(p2 == null) ? typeof p2 === 'number' : false) ? p2 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__215.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function ComposableLambdaImpl(key_0, tracked) {
    this._key_4 = key_0;
    this._tracked = tracked;
    this.__block = null;
    this._scope_0 = null;
    this._scopes = null;
  }
  ComposableLambdaImpl.prototype.update_1 = function (block) {
    if (!equals_0(this.__block, block)) {
      var oldBlockNull = this.__block == null;
      this.__block = block;
      if (!oldBlockNull) {
        trackWrite(this);
      }}};
  ComposableLambdaImpl.prototype.invoke_912 = function (c, changed) {
    var c_0 = c.startRestartGroup_0(this._key_4);
    trackRead(this, c_0);
    var dirty = changed | (c_0.changed_0(this) ? differentBits(0) : sameBits(0));
    var tmp = this.__block;
    var result = ((!(tmp == null) ? typeof tmp === 'function' : false) ? tmp : THROW_CCE())(c_0, dirty);
    var tmp0_safe_receiver = c_0.endRestartGroup_0();
    if (tmp0_safe_receiver == null)
      null;
    else {
      tmp0_safe_receiver.updateScope_0(_no_name_provided_$factory_185(this));
      Unit_getInstance();
    }
    Unit_getInstance();
    return result;
  };
  ComposableLambdaImpl.prototype.invoke_377 = function (p1, c, changed) {
    var c_0 = c.startRestartGroup_0(this._key_4);
    trackRead(this, c_0);
    var dirty = changed | (c_0.changed_0(this) ? differentBits(1) : sameBits(1));
    var tmp = this.__block;
    var result = ((!(tmp == null) ? typeof tmp === 'function' : false) ? tmp : THROW_CCE())(p1, c_0, dirty);
    var tmp0_safe_receiver = c_0.endRestartGroup_0();
    if (tmp0_safe_receiver == null)
      null;
    else {
      tmp0_safe_receiver.updateScope_0(_no_name_provided_$factory_186(this, p1, changed));
      Unit_getInstance();
    }
    Unit_getInstance();
    return result;
  };
  ComposableLambdaImpl.$metadata$ = {
    simpleName: 'ComposableLambdaImpl',
    kind: 'class',
    interfaces: [ComposableLambda]
  };
  function _no_name_provided_$factory_185($boundThis) {
    var i = new _no_name_provided__214($boundThis);
    return function (p1, p2) {
      i.invoke_892(p1, p2);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_186(this$0, $p1, $changed) {
    var i = new _no_name_provided__215(this$0, $p1, $changed);
    return function (p1, p2) {
      i.invoke_892(p1, p2);
      return Unit_getInstance();
    };
  }
  function DomNodeWrapper(node_0) {
    this._node_1 = node_0;
    this._currentListeners = emptyList();
    this._$stable_11 = 8;
  }
  DomNodeWrapper.prototype._get_node__0 = function () {
    return this._node_1;
  };
  DomNodeWrapper.prototype.updateEventListeners_0 = function (list) {
    var tmp = this._get_node__0();
    var tmp0_elvis_lhs = tmp instanceof HTMLElement ? tmp : null;
    var tmp_0;
    if (tmp0_elvis_lhs == null) {
      return Unit_getInstance();
    } else {
      tmp_0 = tmp0_elvis_lhs;
    }
    var htmlElement = tmp_0;
    var tmp0_forEach_0 = this._currentListeners;
    var tmp0_iterator_1 = tmp0_forEach_0.iterator_66();
    while (tmp0_iterator_1.hasNext_41()) {
      var element_2 = tmp0_iterator_1.next_46();
      htmlElement.removeEventListener(element_2._event, element_2);
    }
    this._currentListeners = list;
    var tmp1_forEach_0 = this._currentListeners;
    var tmp0_iterator_1_0 = tmp1_forEach_0.iterator_66();
    while (tmp0_iterator_1_0.hasNext_41()) {
      var element_2_0 = tmp0_iterator_1_0.next_46();
      htmlElement.addEventListener(element_2_0._event, element_2_0);
    }
  };
  DomNodeWrapper.prototype.insert_1 = function (index, nodeWrapper) {
    var length = this._get_node__0().childNodes.length;
    if (index < length) {
      var tmp = this._get_node__0();
      var tmp_0 = nodeWrapper._get_node__0();
      var tmp0_get_0 = this._get_node__0().childNodes;
      tmp.insertBefore(tmp_0, ensureNotNull(tmp0_get_0[index]));
      Unit_getInstance();
    } else {
      this._get_node__0().appendChild(nodeWrapper._get_node__0());
      Unit_getInstance();
    }
  };
  DomNodeWrapper.prototype.remove_49 = function (index, count_0) {
    var inductionVariable = 0;
    if (inductionVariable < count_0)
      do {
        var index_2 = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        var tmp = this._get_node__0();
        var tmp0_get_0_4 = this._get_node__0().childNodes;
        tmp.removeChild(ensureNotNull(tmp0_get_0_4[index]));
        Unit_getInstance();
      }
       while (inductionVariable < count_0);
  };
  DomNodeWrapper.prototype.move_3 = function (from, to_0, count_0) {
    if (from === to_0) {
      return Unit_getInstance();
    }var inductionVariable = 0;
    if (inductionVariable < count_0)
      do {
        var i = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        var fromIndex = from > to_0 ? from + i | 0 : from;
        var toIndex = from > to_0 ? to_0 + i | 0 : (to_0 + count_0 | 0) - 2 | 0;
        var tmp = this._get_node__0();
        var tmp0_get_0 = this._get_node__0().childNodes;
        var child = tmp.removeChild(ensureNotNull(tmp0_get_0[fromIndex]));
        var tmp_0 = this._get_node__0();
        var tmp1_get_0 = this._get_node__0().childNodes;
        tmp_0.insertBefore(child, ensureNotNull(tmp1_get_0[toIndex]));
        Unit_getInstance();
      }
       while (inductionVariable < count_0);
  };
  DomNodeWrapper.$metadata$ = {
    simpleName: 'DomNodeWrapper',
    kind: 'class',
    interfaces: []
  };
  function DomApplier(root) {
    AbstractApplier.call(this, root);
    this._$stable_12 = 0;
  }
  DomApplier.prototype.insertTopDown_1 = function (index, instance) {
  };
  DomApplier.prototype.insertTopDown_2 = function (index, instance) {
    return this.insertTopDown_1(index, instance instanceof DomNodeWrapper ? instance : THROW_CCE());
  };
  DomApplier.prototype.insertBottomUp_1 = function (index, instance) {
    this._get_current__2().insert_1(index, instance);
  };
  DomApplier.prototype.insertBottomUp_2 = function (index, instance) {
    return this.insertBottomUp_1(index, instance instanceof DomNodeWrapper ? instance : THROW_CCE());
  };
  DomApplier.prototype.remove_49 = function (index, count_0) {
    this._get_current__2().remove_49(index, count_0);
  };
  DomApplier.prototype.move_3 = function (from, to_0, count_0) {
    this._get_current__2().move_3(from, to_0, count_0);
  };
  DomApplier.$metadata$ = {
    simpleName: 'DomApplier',
    kind: 'class',
    interfaces: []
  };
  function DomElementWrapper(node_0) {
    DomNodeWrapper.call(this, node_0);
    this._node_2 = node_0;
    this._currentAttrs = emptyMap();
    this._$stable_13 = 8;
  }
  DomElementWrapper.prototype._get_node__0 = function () {
    return this._node_2;
  };
  DomElementWrapper.prototype.updateAttrs = function (attrs) {
    var tmp0_forEach_0 = this._currentAttrs;
    var tmp0_iterator_1 = tmp0_forEach_0._get_entries__5().iterator_66();
    while (tmp0_iterator_1.hasNext_41()) {
      var element_2 = tmp0_iterator_1.next_46();
      this._node_2.removeAttribute(element_2._get_key__35());
    }
    this._currentAttrs = attrs;
    var tmp1_forEach_0 = this._currentAttrs;
    var tmp0_iterator_1_0 = tmp1_forEach_0._get_entries__5().iterator_66();
    while (tmp0_iterator_1_0.hasNext_41()) {
      var element_2_0 = tmp0_iterator_1_0.next_46();
      if (!(element_2_0._get_value__33() == null)) {
        var tmp = element_2_0._get_key__35();
        var tmp0_elvis_lhs_4 = element_2_0._get_value__33();
        this._node_2.setAttribute(tmp, tmp0_elvis_lhs_4 == null ? '' : tmp0_elvis_lhs_4);
      }}
  };
  DomElementWrapper.prototype.updateProperties = function (list) {
    var tmp0_isNotEmpty_0 = this._node_2.className;
    if (charSequenceLength(tmp0_isNotEmpty_0) > 0)
      this._node_2.className = '';
    else {
    }
    var tmp0_iterator_1 = list.iterator_66();
    while (tmp0_iterator_1.hasNext_41()) {
      var element_2 = tmp0_iterator_1.next_46();
      element_2._first(this._node_2, element_2._second);
    }
  };
  DomElementWrapper.prototype.updateStyleDeclarations = function (style) {
    this._node_2.removeAttribute('style');
    var tmp0_safe_receiver = style;
    var tmp1_safe_receiver = tmp0_safe_receiver == null ? null : tmp0_safe_receiver._get_properties__2();
    if (tmp1_safe_receiver == null)
      null;
    else {
      var tmp0_iterator_1 = tmp1_safe_receiver.iterator_66();
      while (tmp0_iterator_1.hasNext_41()) {
        var element_2 = tmp0_iterator_1.next_46();
        var name_4 = element_2.component1_4();
        var value_5 = element_2.component2_4();
        setProperty(_get_attributeStyleMap_(this._node_2), name_4, value_5);
      }
      Unit_getInstance();
    }
    Unit_getInstance();
    var tmp2_safe_receiver = style;
    var tmp3_safe_receiver = tmp2_safe_receiver == null ? null : tmp2_safe_receiver._get_variables__2();
    if (tmp3_safe_receiver == null)
      null;
    else {
      var tmp0_iterator_1_0 = tmp3_safe_receiver.iterator_66();
      while (tmp0_iterator_1_0.hasNext_41()) {
        var element_2_0 = tmp0_iterator_1_0.next_46();
        var name_4_0 = element_2_0.component1_4();
        var value_5_0 = element_2_0.component2_4();
        setVariable(this._node_2.style, name_4_0, value_5_0);
      }
      Unit_getInstance();
    }
    Unit_getInstance();
  };
  DomElementWrapper.$metadata$ = {
    simpleName: 'DomElementWrapper',
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__216() {
  }
  _no_name_provided__216.prototype.invoke_422 = function () {
    GlobalSnapshotManager_getInstance()._commitPending = false;
    Companion_getInstance_30().sendApplyNotifications();
  };
  _no_name_provided__216.prototype.invoke_523 = function () {
    this.invoke_422();
    return Unit_getInstance();
  };
  _no_name_provided__216.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function synchronize($this) {
    var tmp0_forEach_0 = $this._scheduledCallbacks;
    var tmp0_iterator_1 = tmp0_forEach_0.iterator_66();
    while (tmp0_iterator_1.hasNext_41()) {
      var element_2 = tmp0_iterator_1.next_46();
      element_2();
    }
    $this._scheduledCallbacks.clear_9();
    $this._isSynchronizeScheduled = false;
  }
  function schedule($this, block) {
    $this._scheduledCallbacks.add_39(block);
    Unit_getInstance();
    if (!$this._isSynchronizeScheduled) {
      $this._isSynchronizeScheduled = true;
      launch$default($this._scheduleScope, null, null, _no_name_provided_$factory_188(null), 3, null);
      Unit_getInstance();
    }}
  function _no_name_provided__217() {
  }
  _no_name_provided__217.prototype.invoke_918 = function (it) {
    if (!GlobalSnapshotManager_getInstance()._commitPending) {
      GlobalSnapshotManager_getInstance()._commitPending = true;
      var tmp = GlobalSnapshotManager_getInstance();
      schedule(tmp, _no_name_provided_$factory_189());
    }};
  _no_name_provided__217.prototype.invoke_1115 = function (p1) {
    this.invoke_918(isObject(p1) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__217.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__218(resultContinuation) {
    CoroutineImpl_0.call(this, resultContinuation);
  }
  _no_name_provided__218.prototype.invoke_389 = function ($this$launch, $cont) {
    var tmp = this.create_13($this$launch, $cont);
    tmp._result_0 = Unit_getInstance();
    tmp._exception_0 = null;
    return tmp.doResume_17();
  };
  _no_name_provided__218.prototype.doResume_17 = function () {
    var suspendResult = this._result_0;
    $sm: do
      try {
        var tmp = this._state_2;
        if (tmp === 0) {
          this._exceptionState = 1;
          synchronize(GlobalSnapshotManager_getInstance());
          return Unit_getInstance();
        } else if (tmp === 1) {
          throw this._exception_0;
        }} catch ($p) {
        throw $p;
      }
     while (true);
  };
  _no_name_provided__218.prototype.create_13 = function ($this$launch, completion) {
    var i = new _no_name_provided__218(completion);
    i._$this$launch = $this$launch;
    return i;
  };
  _no_name_provided__218.$metadata$ = {
    kind: 'class',
    interfaces: [],
    suspendArity: 1
  };
  function GlobalSnapshotManager() {
    GlobalSnapshotManager_instance = this;
    this._started = false;
    this._commitPending = false;
    this._removeWriteObserver = null;
    var tmp = this;
    var tmp_0 = new JsMicrotasksDispatcher();
    tmp._scheduleScope = CoroutineScope_0(tmp_0.plus_43(SupervisorJob$default(null, 1, null)));
    var tmp_1 = this;
    tmp_1._globalWriteObserver = _no_name_provided_$factory_187();
    var tmp_2 = this;
    tmp_2._scheduledCallbacks = ArrayList_init_$Create$();
    this._isSynchronizeScheduled = false;
  }
  GlobalSnapshotManager.prototype.ensureStarted_1 = function () {
    if (!this._started) {
      this._started = true;
      this._removeWriteObserver = Companion_getInstance_30().registerGlobalWriteObserver(this._globalWriteObserver);
    }};
  GlobalSnapshotManager.$metadata$ = {
    simpleName: 'GlobalSnapshotManager',
    kind: 'object',
    interfaces: []
  };
  var GlobalSnapshotManager_instance;
  function GlobalSnapshotManager_getInstance() {
    if (GlobalSnapshotManager_instance == null)
      new GlobalSnapshotManager();
    return GlobalSnapshotManager_instance;
  }
  function _no_name_provided_$factory_187() {
    var i = new _no_name_provided__217();
    return function (p1) {
      i.invoke_918(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_188(resultContinuation) {
    var i = new _no_name_provided__218(resultContinuation);
    var l = function (p1, $cont) {
      return i.invoke_389(p1, $cont);
    };
    l.$arity = 1;
    return l;
  }
  function _no_name_provided_$factory_189() {
    var i = new _no_name_provided__216();
    return function () {
      i.invoke_422();
      return Unit_getInstance();
    };
  }
  function _no_name_provided__219($block) {
    this._$block_4 = $block;
  }
  _no_name_provided__219.prototype.invoke_383 = function (it) {
    this._$block_4.run_4();
  };
  _no_name_provided__219.prototype.invoke_1115 = function (p1) {
    this.invoke_383(p1 instanceof Unit ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__219.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function JsMicrotasksDispatcher() {
    CoroutineDispatcher.call(this);
  }
  JsMicrotasksDispatcher.prototype.dispatch_9 = function (context, block) {
    var tmp = Promise.resolve(Unit_getInstance());
    tmp.then(_no_name_provided_$factory_190(block));
    Unit_getInstance();
  };
  JsMicrotasksDispatcher.$metadata$ = {
    simpleName: 'JsMicrotasksDispatcher',
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided_$factory_190($block) {
    var i = new _no_name_provided__219($block);
    return function (p1) {
      i.invoke_383(p1);
      return Unit_getInstance();
    };
  }
  function renderComposable$composable(rootElementId, content) {
    return renderComposable$composable_0(ensureNotNull(document.getElementById(rootElementId)), content);
  }
  function renderComposable$composable_0(root, content) {
    GlobalSnapshotManager_getInstance().ensureStarted_1();
    var context = DefaultMonotonicFrameClock.plus_43(new JsMicrotasksDispatcher());
    var recomposer = new Recomposer(context);
    var composition = ControlledComposition_0(new DomApplier(new DomNodeWrapper(root)), recomposer);
    var scope = new _no_name_provided__220();
    composition.setContent$composable_0(_no_name_provided_$factory_192(composableLambdaInstance(-985533425, true, _no_name_provided_$factory_191(content, scope))));
    var tmp = CoroutineScope_0(context);
    var tmp_0 = CoroutineStart_UNDISPATCHED_getInstance();
    launch$default(tmp, null, tmp_0, _no_name_provided_$factory_193(recomposer, null), 1, null);
    Unit_getInstance();
    return composition;
  }
  function _no_name_provided__220() {
  }
  _no_name_provided__220.$metadata$ = {
    kind: 'class',
    interfaces: [DOMScope]
  };
  function _no_name_provided__221($boundThis) {
    this._$boundThis_2 = $boundThis;
  }
  _no_name_provided__221.prototype.invoke_912 = function (p0, p1) {
    return this._$boundThis_2.invoke_912(p0, p1);
  };
  _no_name_provided__221.prototype.invoke_915 = function (p1, p2) {
    var tmp = (!(p1 == null) ? isInterface(p1, Composer) : false) ? p1 : THROW_CCE();
    return this.invoke_912(tmp, (!(p2 == null) ? typeof p2 === 'number' : false) ? p2 : THROW_CCE());
  };
  _no_name_provided__221.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__222($content, $scope) {
    this._$content = $content;
    this._$scope = $scope;
  }
  _no_name_provided__222.prototype.invoke_914 = function ($composer, $changed) {
    var $composer_0 = $composer;
    if (!(($changed & 11 ^ 2) === 0) ? true : !$composer_0._get_skipping__0()) {
      this._$content(this._$scope, $composer_0, 0);
    } else
      $composer_0.skipToGroupEnd_2();
  };
  _no_name_provided__222.prototype.invoke_915 = function (p1, p2) {
    var tmp = (p1 == null ? true : isInterface(p1, Composer)) ? p1 : THROW_CCE();
    this.invoke_914(tmp, (!(p2 == null) ? typeof p2 === 'number' : false) ? p2 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__222.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__223($recomposer, resultContinuation) {
    this._$recomposer = $recomposer;
    CoroutineImpl_0.call(this, resultContinuation);
  }
  _no_name_provided__223.prototype.invoke_389 = function ($this$launch, $cont) {
    var tmp = this.create_13($this$launch, $cont);
    tmp._result_0 = Unit_getInstance();
    tmp._exception_0 = null;
    return tmp.doResume_17();
  };
  _no_name_provided__223.prototype.doResume_17 = function () {
    var suspendResult = this._result_0;
    $sm: do
      try {
        var tmp = this._state_2;
        switch (tmp) {
          case 0:
            this._exceptionState = 2;
            this._state_2 = 1;
            suspendResult = this._$recomposer.runRecomposeAndApplyChanges(this);
            if (suspendResult === _get_COROUTINE_SUSPENDED_()) {
              return suspendResult;
            }
            continue $sm;
          case 1:
            suspendResult;
            return Unit_getInstance();
          case 2:
            throw this._exception_0;
        }
      } catch ($p) {
        if (this._exceptionState === 2) {
          throw $p;
        } else {
          this._state_2 = this._exceptionState;
          this._exception_0 = $p;
        }
      }
     while (true);
  };
  _no_name_provided__223.prototype.create_13 = function ($this$launch, completion) {
    var i = new _no_name_provided__223(this._$recomposer, completion);
    i._$this$launch_0 = $this$launch;
    return i;
  };
  _no_name_provided__223.$metadata$ = {
    kind: 'class',
    interfaces: [],
    suspendArity: 1
  };
  function _no_name_provided_$factory_191($content, $scope) {
    var i = new _no_name_provided__222($content, $scope);
    return function (p1, p2) {
      i.invoke_914(p1, p2);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_192($boundThis) {
    var i = new _no_name_provided__221($boundThis);
    return function (p1, p2) {
      return i.invoke_912(p1, p2);
    };
  }
  function _no_name_provided_$factory_193($recomposer, resultContinuation) {
    var i = new _no_name_provided__223($recomposer, resultContinuation);
    var l = function (p1, $cont) {
      return i.invoke_389(p1, $cont);
    };
    l.$arity = 1;
    return l;
  }
  var setInputValue;
  function target(_this_, value_0) {
    return _this_.attr('target', value_0._targetStr);
  }
  function href(_this_, value_0) {
    return _this_.attr('href', value_0);
  }
  function alt(_this_, value_0) {
    return _this_.attr('alt', value_0);
  }
  function src(_this_, value_0) {
    return _this_.attr('src', value_0);
  }
  function _no_name_provided__224() {
  }
  _no_name_provided__224.prototype.invoke_390 = function (e, v) {
    e.value = v;
  };
  _no_name_provided__224.prototype.invoke_915 = function (p1, p2) {
    var tmp = p1 instanceof HTMLInputElement ? p1 : THROW_CCE();
    this.invoke_390(tmp, (!(p2 == null) ? typeof p2 === 'string' : false) ? p2 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__224.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided_$factory_194() {
    var i = new _no_name_provided__224();
    return function (p1, p2) {
      i.invoke_390(p1, p2);
      return Unit_getInstance();
    };
  }
  function setInputValue$init$() {
    return _no_name_provided_$factory_194();
  }
  var setClassList;
  function Companion_33() {
    Companion_instance_32 = this;
    this._CLASS = 'class';
    this._ID = 'id';
    this._HIDDEN = 'hidden';
    this._TITLE = 'title';
    this._DIR = 'dir';
    this._DRAGGABLE = 'draggable';
    this._CONTENT_EDITABLE = 'contenteditable';
    this._LANG = 'lang';
    this._TAB_INDEX = 'tabindex';
    this._SPELLCHECK = 'spellcheck';
  }
  Companion_33.$metadata$ = {
    simpleName: 'Companion',
    kind: 'object',
    interfaces: []
  };
  var Companion_instance_32;
  function Companion_getInstance_32() {
    if (Companion_instance_32 == null)
      new Companion_33();
    return Companion_instance_32;
  }
  function AttrsBuilder() {
    Companion_getInstance_32();
    EventsListenerBuilder.call(this);
    var tmp = this;
    tmp._attributesMap = LinkedHashMap_init_$Create$();
    this._styleBuilder = new StyleBuilderImpl();
    var tmp_0 = this;
    tmp_0._propertyUpdates = ArrayList_init_$Create$();
    this._refEffect = null;
    this._$stable_14 = 8;
  }
  AttrsBuilder.prototype.style_0 = function (builder) {
    var tmp0_apply_0 = this._styleBuilder;
    builder(tmp0_apply_0);
    Unit_getInstance();
  };
  AttrsBuilder.prototype.classes = function (classes) {
    return this.prop(setClassList, classes);
  };
  AttrsBuilder.prototype.id_0 = function (value_0) {
    Companion_getInstance_32();
    return this.attr('id', value_0);
  };
  AttrsBuilder.prototype.contentEditable_0 = function (value_0) {
    Companion_getInstance_32();
    return this.attr('contenteditable', value_0.toString());
  };
  AttrsBuilder.prototype.attr = function (attr, value_0) {
    if (value_0 == null) {
      this._attributesMap.remove_44(attr);
      Unit_getInstance();
    } else {
      var tmp0_set_0 = this._attributesMap;
      tmp0_set_0.put_6(attr, value_0);
      Unit_getInstance();
    }
    return this;
  };
  AttrsBuilder.prototype.prop = function (update, value_0) {
    var tmp = to(update, value_0);
    this._propertyUpdates.add_39(tmp instanceof Pair ? tmp : THROW_CCE());
    Unit_getInstance();
  };
  AttrsBuilder.prototype.collect = function () {
    return this._attributesMap;
  };
  AttrsBuilder.$metadata$ = {
    simpleName: 'AttrsBuilder',
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__225() {
  }
  _no_name_provided__225.prototype.invoke_392 = function (e, classList) {
    (function () {
      var $externalVarargReceiverTmp = e.classList;
      return $externalVarargReceiverTmp.add.apply($externalVarargReceiverTmp, [].concat([].slice.call(classList.slice())));
    }.call(this));
  };
  _no_name_provided__225.prototype.invoke_915 = function (p1, p2) {
    var tmp = p1 instanceof HTMLElement ? p1 : THROW_CCE();
    this.invoke_392(tmp, (!(p2 == null) ? isArray(p2) : false) ? p2 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__225.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided_$factory_195() {
    var i = new _no_name_provided__225();
    return function (p1, p2) {
      i.invoke_392(p1, p2);
      return Unit_getInstance();
    };
  }
  function setClassList$init$() {
    return _no_name_provided_$factory_195();
  }
  function Companion_34() {
    Companion_instance_33 = this;
    this._COPY = 'copy';
    this._CUT = 'cut';
    this._PASTE = 'paste';
    this._CONTEXTMENU = 'contextmenu';
    this._CLICK = 'click';
    this._DBLCLICK = 'dblclick';
    this._FOCUS = 'focus';
    this._BLUR = 'blur';
    this._FOCUSIN = 'focusin';
    this._FOCUSOUT = 'focusout';
    this._KEYDOWN = 'keydown';
    this._KEYUP = 'keyup';
    this._MOUSEDOWN = 'mousedown';
    this._MOUSEUP = 'mouseup';
    this._MOUSEENTER = 'mouseenter';
    this._MOUSELEAVE = 'mouseleave';
    this._MOUSEMOVE = 'mousemove';
    this._MOUSEOUT = 'mouseout';
    this._MOUSEOVER = 'mouseover';
    this._WHEEL = 'wheel';
    this._SCROLL = 'scroll';
    this._SELECT = 'select';
    this._TOUCHCANCEL = 'touchcancel';
    this._TOUCHEND = 'touchend';
    this._TOUCHMOVE = 'touchmove';
    this._TOUCHSTART = 'touchstart';
    this._ANIMATIONCANCEL = 'animationcancel';
    this._ANIMATIONEND = 'animationend';
    this._ANIMATIONITERATION = 'animationiteration';
    this._ANIMATIONSTART = 'animationstart';
    this._BEFOREINPUT = 'beforeinput';
    this._INPUT = 'input';
    this._CHANGE = 'change';
    this._INVALID = 'invalid';
    this._SEARCH = 'search';
    this._DRAG = 'drag';
    this._DROP = 'drop';
    this._DRAGSTART = 'dragstart';
    this._DRAGEND = 'dragend';
    this._DRAGOVER = 'dragover';
    this._DRAGENTER = 'dragenter';
    this._DRAGLEAVE = 'dragleave';
  }
  Companion_34.$metadata$ = {
    simpleName: 'Companion',
    kind: 'object',
    interfaces: []
  };
  var Companion_instance_33;
  function Companion_getInstance_33() {
    if (Companion_instance_33 == null)
      new Companion_34();
    return Companion_instance_33;
  }
  function EventsListenerBuilder() {
    Companion_getInstance_33();
    var tmp = this;
    tmp._listeners = ArrayList_init_$Create$();
    this._$stable_15 = 8;
  }
  EventsListenerBuilder.prototype.onClick_0 = function (options, listener) {
    Companion_getInstance_33();
    this._listeners.add_39(new MouseEventListener('click', options, listener));
    Unit_getInstance();
  };
  EventsListenerBuilder.prototype.onClick$default_0 = function (options, listener, $mask0, $handler) {
    if (!(($mask0 & 1) === 0))
      options = Companion_getInstance_34()._DEFAULT_0;
    return this.onClick_0(options, listener);
  };
  EventsListenerBuilder.prototype.collectListeners_0 = function () {
    return this._listeners;
  };
  EventsListenerBuilder.$metadata$ = {
    simpleName: 'EventsListenerBuilder',
    kind: 'class',
    interfaces: []
  };
  function Blank() {
    Blank_instance = this;
    ATarget.call(this, '_blank');
    this._$stable_16 = 0;
  }
  Blank.$metadata$ = {
    simpleName: 'Blank',
    kind: 'object',
    interfaces: []
  };
  var Blank_instance;
  function Blank_getInstance() {
    if (Blank_instance == null)
      new Blank();
    return Blank_instance;
  }
  function ATarget(targetStr) {
    this._targetStr = targetStr;
    this._$stable_17 = 0;
  }
  ATarget.$metadata$ = {
    simpleName: 'ATarget',
    kind: 'class',
    interfaces: []
  };
  function WrappedEventListener(event, options, listener) {
    this._event = event;
    this._options = options;
    this._listener = listener;
    this._$stable_18 = 0;
  }
  WrappedEventListener.prototype._get_listener__0 = function () {
    return this._listener;
  };
  WrappedEventListener.prototype.handleEvent_1 = function (event) {
    var tmp = new WrappedEventImpl(event);
    this._listener(isInterface(tmp, GenericWrappedEvent) ? tmp : THROW_CCE());
  };
  WrappedEventListener.prototype.handleEvent = function (event) {
    return this.handleEvent_1(event);
  };
  WrappedEventListener.$metadata$ = {
    simpleName: 'WrappedEventListener',
    kind: 'class',
    interfaces: []
  };
  function Companion_35() {
    Companion_instance_34 = this;
    this._DEFAULT_0 = new Options();
  }
  Companion_35.$metadata$ = {
    simpleName: 'Companion',
    kind: 'object',
    interfaces: []
  };
  var Companion_instance_34;
  function Companion_getInstance_34() {
    if (Companion_instance_34 == null)
      new Companion_35();
    return Companion_instance_34;
  }
  function Options() {
    Companion_getInstance_34();
    this._$stable_19 = 0;
  }
  Options.$metadata$ = {
    simpleName: 'Options',
    kind: 'class',
    interfaces: []
  };
  function MouseEventListener(event, options, listener) {
    WrappedEventListener.call(this, event, options, listener);
  }
  MouseEventListener.prototype.handleEvent_1 = function (event) {
    var tmp = this._get_listener__0();
    tmp(new WrappedMouseEvent(event instanceof MouseEvent ? event : THROW_CCE()));
  };
  MouseEventListener.prototype.handleEvent = function (event) {
    return this.handleEvent_1(event);
  };
  MouseEventListener.$metadata$ = {
    simpleName: 'MouseEventListener',
    kind: 'class',
    interfaces: []
  };
  var cssTypedOMPolyfill;
  function Companion_36() {
    Companion_instance_35 = this;
  }
  Companion_36.prototype.invoke_402 = function (value_0) {
    return value_0;
  };
  Companion_36.prototype.invoke_395 = function (value_0) {
    return value_0;
  };
  Companion_36.prototype.invoke_396 = function (value_0) {
    return value_0;
  };
  Companion_36.$metadata$ = {
    simpleName: 'Companion',
    kind: 'object',
    interfaces: []
  };
  var Companion_instance_35;
  function Companion_getInstance_35() {
    if (Companion_instance_35 == null)
      new Companion_36();
    return Companion_instance_35;
  }
  function _get_attributeStyleMap_(_this_) {
    var tmp0_unsafeCast_0 = _this_.attributeStyleMap;
    return tmp0_unsafeCast_0;
  }
  function insertRule(_this_, cssRule, index) {
    var tmp;
    if (!(index == null)) {
      var tmp0_unsafeCast_0 = _this_.insertRule(cssRule, index);
      tmp = tmp0_unsafeCast_0;
    } else {
      var tmp1_unsafeCast_0 = _this_.insertRule(cssRule);
      tmp = tmp1_unsafeCast_0;
    }
    return tmp;
  }
  function CSSBuilder() {
  }
  CSSBuilder.$metadata$ = {
    simpleName: 'CSSBuilder',
    kind: 'interface',
    interfaces: [CSSStyleRuleBuilder, GenericStyleSheetBuilder]
  };
  function CSSBuilderImpl(root, self_0, rulesHolder) {
    CSSRuleBuilderImpl.call(this);
    this._root_0 = root;
    this._self = self_0;
    this._$$delegate_0 = rulesHolder;
    this._$stable_20 = 8;
  }
  CSSBuilderImpl.prototype._get_self__1 = function () {
    return this._self;
  };
  CSSBuilderImpl.prototype.add_64 = function (cssRule) {
    this._$$delegate_0.add_64(cssRule);
  };
  CSSBuilderImpl.prototype.add_65 = function (selector, style) {
    this._$$delegate_0.add_65(selector, style);
  };
  CSSBuilderImpl.prototype._get_cssRules__12 = function () {
    return this._$$delegate_0._get_cssRules__12();
  };
  CSSBuilderImpl.prototype.style_2 = function (selector, cssRule) {
    var tmp0_container = buildCSS(this._root_0, selector, cssRule);
    var style = tmp0_container.component1_4();
    var rules = tmp0_container.component2_4();
    var tmp0_iterator_1 = rules.iterator_66();
    while (tmp0_iterator_1.hasNext_41()) {
      var element_2 = tmp0_iterator_1.next_46();
      this.add_64(element_2);
    }
    this.add_65(selector, style);
  };
  CSSBuilderImpl.prototype.style_43 = function (selector, cssRule) {
    return this.style_2(selector, cssRule);
  };
  CSSBuilderImpl.prototype.buildRules = function (rulesBuild) {
    var tmp0_apply_0 = new CSSBuilderImpl(this._root_0, this._self, new StyleSheetBuilderImpl());
    rulesBuild(tmp0_apply_0);
    return tmp0_apply_0._get_cssRules__12();
  };
  CSSBuilderImpl.prototype.buildRules_5 = function (rulesBuild) {
    return this.buildRules(rulesBuild);
  };
  CSSBuilderImpl.$metadata$ = {
    simpleName: 'CSSBuilderImpl',
    kind: 'class',
    interfaces: [CSSBuilder, CSSRulesHolder]
  };
  var auto;
  function Companion_37() {
    Companion_instance_36 = this;
  }
  Companion_37.prototype.invoke_399 = function (value_0) {
    return value_0;
  };
  Companion_37.$metadata$ = {
    simpleName: 'Companion',
    kind: 'object',
    interfaces: []
  };
  var Companion_instance_36;
  function Companion_getInstance_36() {
    if (Companion_instance_36 == null)
      new Companion_37();
    return Companion_instance_36;
  }
  function auto$init$() {
    var tmp0_unsafeCast_0 = new CSSKeywordValue('auto');
    return tmp0_unsafeCast_0;
  }
  function media(_this_, query, rulesBuild) {
    var rules = _this_.buildRules_5(rulesBuild);
    _this_.add_64(new CSSMediaRuleDeclaration(query, rules));
  }
  function Invertible() {
  }
  Invertible.$metadata$ = {
    simpleName: 'Invertible',
    kind: 'interface',
    interfaces: [CSSMediaQuery]
  };
  function Combinable() {
  }
  Combinable.$metadata$ = {
    simpleName: 'Combinable',
    kind: 'interface',
    interfaces: [CSSMediaQuery]
  };
  function Atomic() {
  }
  Atomic.$metadata$ = {
    simpleName: 'Atomic',
    kind: 'interface',
    interfaces: [Invertible, Combinable]
  };
  function MediaFeature(name, value_0) {
    this._name_3 = name;
    this._value_11 = value_0;
    this._$stable_21 = 8;
  }
  MediaFeature.prototype.equals = function (other) {
    var tmp;
    if (other instanceof MediaFeature) {
      tmp = this._name_3 === other._name_3 ? toString_1(this._value_11) === toString_1(other._value_11) : false;
    } else {
      {
        tmp = false;
      }
    }
    return tmp;
  };
  MediaFeature.prototype.toString = function () {
    var tmp0_safe_receiver = this._value_11;
    var tmp;
    if (tmp0_safe_receiver == null) {
      tmp = null;
    } else {
      tmp = '' + ': ' + this._value_11 + ')';
    }
    var tmp1_elvis_lhs = tmp;
    return '' + '(' + this._name_3 + (tmp1_elvis_lhs == null ? '' : tmp1_elvis_lhs);
  };
  MediaFeature.prototype.hashCode = function () {
    var result = getStringHashCode(this._name_3);
    result = imul(result, 31) + (this._value_11 == null ? 0 : hashCode(this._value_11)) | 0;
    return result;
  };
  MediaFeature.$metadata$ = {
    simpleName: 'MediaFeature',
    kind: 'class',
    interfaces: [CSSMediaQuery, Atomic]
  };
  function CSSMediaQuery() {
  }
  CSSMediaQuery.$metadata$ = {
    simpleName: 'CSSMediaQuery',
    kind: 'interface',
    interfaces: []
  };
  function maxWidth(_this_, value_0) {
    return new MediaFeature('max-width', Companion_getInstance_35().invoke_396(value_0));
  }
  function CSSMediaRuleDeclaration(query, rules) {
    CSSGroupingRuleDeclaration.call(this, rules);
    this._query = query;
    this._$stable_22 = 8;
  }
  CSSMediaRuleDeclaration.prototype._get_header__2 = function () {
    return '' + '@media ' + this._query;
  };
  CSSMediaRuleDeclaration.prototype.equals = function (other) {
    var tmp;
    if (other instanceof CSSMediaRuleDeclaration) {
      tmp = equals_0(this._get_rules__0(), other._get_rules__0()) ? equals_0(this._query, other._query) : false;
    } else {
      {
        tmp = false;
      }
    }
    return tmp;
  };
  CSSMediaRuleDeclaration.$metadata$ = {
    simpleName: 'CSSMediaRuleDeclaration',
    kind: 'class',
    interfaces: []
  };
  var Position_Static_instance;
  var Position_Relative_instance;
  var Position_Absolute_instance;
  var Position_Sticky_instance;
  var Position_Fixed_instance;
  var Position_entriesInitialized;
  function Position_initEntries() {
    if (Position_entriesInitialized)
      return Unit_getInstance();
    Position_entriesInitialized = true;
    Position_Static_instance = new Position('Static', 0, 'static');
    Position_Relative_instance = new Position('Relative', 1, 'relative');
    Position_Absolute_instance = new Position('Absolute', 2, 'absolute');
    Position_Sticky_instance = new Position('Sticky', 3, 'sticky');
    Position_Fixed_instance = new Position('Fixed', 4, 'fixed');
  }
  function Position(name, ordinal, value_0) {
    Enum.call(this, name, ordinal);
    this._value_12 = value_0;
  }
  Position.$metadata$ = {
    simpleName: 'Position',
    kind: 'class',
    interfaces: []
  };
  function display(_this_, displayStyle) {
    _this_.property_4('display', Companion_getInstance_35().invoke_402(displayStyle._value_13));
  }
  var DisplayStyle_Block_instance;
  var DisplayStyle_Inline_instance;
  var DisplayStyle_InlineBlock_instance;
  var DisplayStyle_Flex_instance;
  var DisplayStyle_LegacyInlineFlex_instance;
  var DisplayStyle_Grid_instance;
  var DisplayStyle_LegacyInlineGrid_instance;
  var DisplayStyle_FlowRoot_instance;
  var DisplayStyle_None_instance;
  var DisplayStyle_Contents_instance;
  var DisplayStyle_Table_instance;
  var DisplayStyle_TableRow_instance;
  var DisplayStyle_ListItem_instance;
  var DisplayStyle_Inherit_instance;
  var DisplayStyle_Initial_instance;
  var DisplayStyle_Unset_instance;
  var DisplayStyle_entriesInitialized;
  function DisplayStyle_initEntries() {
    if (DisplayStyle_entriesInitialized)
      return Unit_getInstance();
    DisplayStyle_entriesInitialized = true;
    DisplayStyle_Block_instance = new DisplayStyle('Block', 0, 'block');
    DisplayStyle_Inline_instance = new DisplayStyle('Inline', 1, 'inline');
    DisplayStyle_InlineBlock_instance = new DisplayStyle('InlineBlock', 2, 'inline-block');
    DisplayStyle_Flex_instance = new DisplayStyle('Flex', 3, 'flex');
    DisplayStyle_LegacyInlineFlex_instance = new DisplayStyle('LegacyInlineFlex', 4, 'inline-flex');
    DisplayStyle_Grid_instance = new DisplayStyle('Grid', 5, 'grid');
    DisplayStyle_LegacyInlineGrid_instance = new DisplayStyle('LegacyInlineGrid', 6, 'inline-grid');
    DisplayStyle_FlowRoot_instance = new DisplayStyle('FlowRoot', 7, 'flow-root');
    DisplayStyle_None_instance = new DisplayStyle('None', 8, 'none');
    DisplayStyle_Contents_instance = new DisplayStyle('Contents', 9, 'contents');
    DisplayStyle_Table_instance = new DisplayStyle('Table', 10, 'table');
    DisplayStyle_TableRow_instance = new DisplayStyle('TableRow', 11, 'table-row');
    DisplayStyle_ListItem_instance = new DisplayStyle('ListItem', 12, 'list-item');
    DisplayStyle_Inherit_instance = new DisplayStyle('Inherit', 13, 'inherit');
    DisplayStyle_Initial_instance = new DisplayStyle('Initial', 14, 'initial');
    DisplayStyle_Unset_instance = new DisplayStyle('Unset', 15, 'unset');
  }
  function DisplayStyle(name, ordinal, value_0) {
    Enum.call(this, name, ordinal);
    this._value_13 = value_0;
  }
  DisplayStyle.$metadata$ = {
    simpleName: 'DisplayStyle',
    kind: 'class',
    interfaces: []
  };
  function flexDirection(_this_, flexDirection_0) {
    _this_.property_4('flex-direction', Companion_getInstance_35().invoke_402(flexDirection_0._value_14));
  }
  var FlexDirection_Row_instance;
  var FlexDirection_RowReverse_instance;
  var FlexDirection_Column_instance;
  var FlexDirection_ColumnReverse_instance;
  var FlexDirection_entriesInitialized;
  function FlexDirection_initEntries() {
    if (FlexDirection_entriesInitialized)
      return Unit_getInstance();
    FlexDirection_entriesInitialized = true;
    FlexDirection_Row_instance = new FlexDirection('Row', 0, 'row');
    FlexDirection_RowReverse_instance = new FlexDirection('RowReverse', 1, 'row-reverse');
    FlexDirection_Column_instance = new FlexDirection('Column', 2, 'column');
    FlexDirection_ColumnReverse_instance = new FlexDirection('ColumnReverse', 3, 'column-reverse');
  }
  function FlexDirection(name, ordinal, value_0) {
    Enum.call(this, name, ordinal);
    this._value_14 = value_0;
  }
  FlexDirection.$metadata$ = {
    simpleName: 'FlexDirection',
    kind: 'class',
    interfaces: []
  };
  function height(_this_, value_0) {
    height_0(_this_, Companion_getInstance_36().invoke_399(value_0));
  }
  function margin(_this_, value_0) {
    var tmp0_value_0 = toString_2(value_0);
    _this_.property_4('margin', Companion_getInstance_35().invoke_402(tmp0_value_0));
  }
  function CSSBorder() {
    this._width = null;
    this._style = null;
    this._color = null;
    this._$stable_23 = 8;
  }
  CSSBorder.prototype.width_0 = function (size_0) {
    this._width = Companion_getInstance_35().invoke_396(size_0);
  };
  CSSBorder.prototype.style_5 = function (style) {
    this._style = Companion_getInstance_35().invoke_402(style._get_name__18());
  };
  CSSBorder.prototype.color_0 = function (color_1) {
    this._color = color_1.styleValue_3();
  };
  CSSBorder.prototype.equals = function (other) {
    var tmp;
    if (other instanceof CSSBorder) {
      tmp = toString_2(this.styleValue_3()) === toString_2(other.styleValue_3());
    } else {
      {
        tmp = false;
      }
    }
    return tmp;
  };
  CSSBorder.prototype.styleValue_3 = function () {
    var values_0 = listOfNotNull([this._width, this._style, this._color]);
    var tmp = Companion_getInstance_35();
    return tmp.invoke_402(joinToString$default_1(values_0, ' ', null, null, 0, null, null, 62, null));
  };
  CSSBorder.$metadata$ = {
    simpleName: 'CSSBorder',
    kind: 'class',
    interfaces: [CustomStyleValue]
  };
  function borderRadius(_this_, topLeft, topRightAndBottomLeft, bottomRight) {
    _this_.property_4('border-radius', Companion_getInstance_35().invoke_402('' + topLeft + ' ' + topRightAndBottomLeft + ' ' + bottomRight));
  }
  function color(_this_, value_0) {
    _this_.property_4('color', Companion_getInstance_35().invoke_402(value_0));
  }
  function padding(_this_, value_0) {
    var tmp0_value_0 = toString_2(value_0);
    _this_.property_4('padding', Companion_getInstance_35().invoke_402(tmp0_value_0));
  }
  function backgroundColor(_this_, value_0) {
    _this_.property_4('background-color', value_0.styleValue_3());
  }
  function flexShrink(_this_, value_0) {
    _this_.property_4('flex-shrink', Companion_getInstance_35().invoke_395(value_0));
  }
  function justifyContent(_this_, justifyContent_0) {
    _this_.property_4('justify-content', Companion_getInstance_35().invoke_402(justifyContent_0._value_15));
  }
  var JustifyContent_Center_instance;
  var JustifyContent_Start_instance;
  var JustifyContent_End_instance;
  var JustifyContent_FlexStart_instance;
  var JustifyContent_FlexEnd_instance;
  var JustifyContent_Left_instance;
  var JustifyContent_Right_instance;
  var JustifyContent_Normal_instance;
  var JustifyContent_SpaceBetween_instance;
  var JustifyContent_SpaceAround_instance;
  var JustifyContent_SpaceEvenly_instance;
  var JustifyContent_Stretch_instance;
  var JustifyContent_Inherit_instance;
  var JustifyContent_Initial_instance;
  var JustifyContent_Unset_instance;
  var JustifyContent_SafeCenter_instance;
  var JustifyContent_UnsafeCenter_instance;
  var JustifyContent_entriesInitialized;
  function JustifyContent_initEntries() {
    if (JustifyContent_entriesInitialized)
      return Unit_getInstance();
    JustifyContent_entriesInitialized = true;
    JustifyContent_Center_instance = new JustifyContent('Center', 0, 'center');
    JustifyContent_Start_instance = new JustifyContent('Start', 1, 'start');
    JustifyContent_End_instance = new JustifyContent('End', 2, 'end');
    JustifyContent_FlexStart_instance = new JustifyContent('FlexStart', 3, 'flex-start');
    JustifyContent_FlexEnd_instance = new JustifyContent('FlexEnd', 4, 'flex-end');
    JustifyContent_Left_instance = new JustifyContent('Left', 5, 'left');
    JustifyContent_Right_instance = new JustifyContent('Right', 6, 'right');
    JustifyContent_Normal_instance = new JustifyContent('Normal', 7, 'normal');
    JustifyContent_SpaceBetween_instance = new JustifyContent('SpaceBetween', 8, 'space-between');
    JustifyContent_SpaceAround_instance = new JustifyContent('SpaceAround', 9, 'space-around');
    JustifyContent_SpaceEvenly_instance = new JustifyContent('SpaceEvenly', 10, 'space-evenly');
    JustifyContent_Stretch_instance = new JustifyContent('Stretch', 11, 'stretch');
    JustifyContent_Inherit_instance = new JustifyContent('Inherit', 12, 'inherit');
    JustifyContent_Initial_instance = new JustifyContent('Initial', 13, 'initial');
    JustifyContent_Unset_instance = new JustifyContent('Unset', 14, 'unset');
    JustifyContent_SafeCenter_instance = new JustifyContent('SafeCenter', 15, 'safe center');
    JustifyContent_UnsafeCenter_instance = new JustifyContent('UnsafeCenter', 16, 'unsafe center');
  }
  function JustifyContent(name, ordinal, value_0) {
    Enum.call(this, name, ordinal);
    this._value_15 = value_0;
  }
  JustifyContent.$metadata$ = {
    simpleName: 'JustifyContent',
    kind: 'class',
    interfaces: []
  };
  function flexWrap(_this_, flexWrap_0) {
    _this_.property_4('flex-wrap', Companion_getInstance_35().invoke_402(flexWrap_0._value_16));
  }
  var FlexWrap_Wrap_instance;
  var FlexWrap_Nowrap_instance;
  var FlexWrap_WrapReverse_instance;
  var FlexWrap_entriesInitialized;
  function FlexWrap_initEntries() {
    if (FlexWrap_entriesInitialized)
      return Unit_getInstance();
    FlexWrap_entriesInitialized = true;
    FlexWrap_Wrap_instance = new FlexWrap('Wrap', 0, 'wrap');
    FlexWrap_Nowrap_instance = new FlexWrap('Nowrap', 1, 'nowrap');
    FlexWrap_WrapReverse_instance = new FlexWrap('WrapReverse', 2, 'wrap-reverse');
  }
  function FlexWrap(name, ordinal, value_0) {
    Enum.call(this, name, ordinal);
    this._value_16 = value_0;
  }
  FlexWrap.$metadata$ = {
    simpleName: 'FlexWrap',
    kind: 'class',
    interfaces: []
  };
  function color_0(_this_, value_0) {
    _this_.property_4('color', value_0.styleValue_3());
  }
  function width(_this_, value_0) {
    width_0(_this_, Companion_getInstance_36().invoke_399(value_0));
  }
  function marginTop(_this_, value_0) {
    var tmp0_value_0 = toString_2(value_0);
    _this_.property_4('margin-top', Companion_getInstance_35().invoke_402(tmp0_value_0));
  }
  function borderRadius_0(_this_, r) {
    _this_.property_4('border-radius', Companion_getInstance_35().invoke_402(toString_2(r)));
  }
  function border(_this_, width_1, style, color_1) {
    var tmp0_apply_0_2 = new CSSBorder();
    var tmp0_safe_receiver_4 = width_1;
    if (tmp0_safe_receiver_4 == null)
      null;
    else {
      tmp0_apply_0_2.width_0(tmp0_safe_receiver_4);
      Unit_getInstance();
    }
    Unit_getInstance();
    var tmp1_safe_receiver_5 = style;
    if (tmp1_safe_receiver_5 == null)
      null;
    else {
      tmp0_apply_0_2.style_5(tmp1_safe_receiver_5);
      Unit_getInstance();
    }
    Unit_getInstance();
    var tmp2_safe_receiver_6 = color_1;
    if (tmp2_safe_receiver_6 == null)
      null;
    else {
      tmp0_apply_0_2.color_0(tmp2_safe_receiver_6);
      Unit_getInstance();
    }
    Unit_getInstance();
    var border_1 = tmp0_apply_0_2;
    _this_.property_4('border', border_1.styleValue_3());
  }
  function border$default(_this_, width_1, style, color_1, $mask0, $handler) {
    if (!(($mask0 & 1) === 0))
      width_1 = null;
    if (!(($mask0 & 2) === 0))
      style = null;
    if (!(($mask0 & 4) === 0))
      color_1 = null;
    return border(_this_, width_1, style, color_1);
  }
  function alignItems(_this_, alignItems_0) {
    _this_.property_4('align-items', Companion_getInstance_35().invoke_402(alignItems_0._value_17));
  }
  var AlignItems_Normal_instance;
  var AlignItems_Stretch_instance;
  var AlignItems_Center_instance;
  var AlignItems_Start_instance;
  var AlignItems_End_instance;
  var AlignItems_FlexStart_instance;
  var AlignItems_FlexEnd_instance;
  var AlignItems_Baseline_instance;
  var AlignItems_SafeCenter_instance;
  var AlignItems_UnsafeCenter_instance;
  var AlignItems_Inherit_instance;
  var AlignItems_Initial_instance;
  var AlignItems_Unset_instance;
  var AlignItems_entriesInitialized;
  function AlignItems_initEntries() {
    if (AlignItems_entriesInitialized)
      return Unit_getInstance();
    AlignItems_entriesInitialized = true;
    AlignItems_Normal_instance = new AlignItems('Normal', 0, 'normal');
    AlignItems_Stretch_instance = new AlignItems('Stretch', 1, 'stretch');
    AlignItems_Center_instance = new AlignItems('Center', 2, 'center');
    AlignItems_Start_instance = new AlignItems('Start', 3, 'start');
    AlignItems_End_instance = new AlignItems('End', 4, 'end');
    AlignItems_FlexStart_instance = new AlignItems('FlexStart', 5, 'flex-start');
    AlignItems_FlexEnd_instance = new AlignItems('FlexEnd', 6, 'flex-end');
    AlignItems_Baseline_instance = new AlignItems('Baseline', 7, 'baseline');
    AlignItems_SafeCenter_instance = new AlignItems('SafeCenter', 8, 'safe center');
    AlignItems_UnsafeCenter_instance = new AlignItems('UnsafeCenter', 9, 'unsafe center');
    AlignItems_Inherit_instance = new AlignItems('Inherit', 10, 'inherit');
    AlignItems_Initial_instance = new AlignItems('Initial', 11, 'initial');
    AlignItems_Unset_instance = new AlignItems('Unset', 12, 'unset');
  }
  function AlignItems(name, ordinal, value_0) {
    Enum.call(this, name, ordinal);
    this._value_17 = value_0;
  }
  AlignItems.$metadata$ = {
    simpleName: 'AlignItems',
    kind: 'class',
    interfaces: []
  };
  function position(_this_, position_0) {
    _this_.property_4('position', Companion_getInstance_35().invoke_402(position_0._value_12));
  }
  function top(_this_, value_0) {
    top_0(_this_, Companion_getInstance_36().invoke_399(value_0));
  }
  function marginLeft(_this_, value_0) {
    var tmp0_value_0 = toString_2(value_0);
    _this_.property_4('margin-left', Companion_getInstance_35().invoke_402(tmp0_value_0));
  }
  function fontSize(_this_, value_0) {
    _this_.property_4('font-size', Companion_getInstance_35().invoke_396(value_0));
  }
  function backgroundColor_0(_this_, value_0) {
    _this_.property_4('background-color', Companion_getInstance_35().invoke_402(value_0));
  }
  function flexGrow(_this_, value_0) {
    _this_.property_4('flex-grow', Companion_getInstance_35().invoke_395(value_0));
  }
  function backgroundColor_1(_this_, value_0) {
    _this_.property_4('background-color', value_0);
  }
  function height_0(_this_, value_0) {
    _this_.property_4('height', value_0);
  }
  function width_0(_this_, value_0) {
    _this_.property_4('width', value_0);
  }
  function top_0(_this_, value_0) {
    _this_.property_4('top', value_0);
  }
  function Position_Relative_getInstance() {
    Position_initEntries();
    return Position_Relative_instance;
  }
  function DisplayStyle_InlineBlock_getInstance() {
    DisplayStyle_initEntries();
    return DisplayStyle_InlineBlock_instance;
  }
  function DisplayStyle_Flex_getInstance() {
    DisplayStyle_initEntries();
    return DisplayStyle_Flex_instance;
  }
  function DisplayStyle_LegacyInlineFlex_getInstance() {
    DisplayStyle_initEntries();
    return DisplayStyle_LegacyInlineFlex_instance;
  }
  function FlexDirection_Row_getInstance() {
    FlexDirection_initEntries();
    return FlexDirection_Row_instance;
  }
  function FlexDirection_Column_getInstance() {
    FlexDirection_initEntries();
    return FlexDirection_Column_instance;
  }
  function JustifyContent_Center_getInstance() {
    JustifyContent_initEntries();
    return JustifyContent_Center_instance;
  }
  function JustifyContent_SpaceEvenly_getInstance() {
    JustifyContent_initEntries();
    return JustifyContent_SpaceEvenly_instance;
  }
  function FlexWrap_Wrap_getInstance() {
    FlexWrap_initEntries();
    return FlexWrap_Wrap_instance;
  }
  function AlignItems_Center_getInstance() {
    AlignItems_initEntries();
    return AlignItems_Center_instance;
  }
  function CSSStyleRuleBuilder() {
  }
  CSSStyleRuleBuilder.$metadata$ = {
    simpleName: 'CSSStyleRuleBuilder',
    kind: 'interface',
    interfaces: [StyleBuilder]
  };
  function CSSRuleDeclaration() {
    this._$stable_24 = 0;
  }
  CSSRuleDeclaration.$metadata$ = {
    simpleName: 'CSSRuleDeclaration',
    kind: 'class',
    interfaces: []
  };
  function CSSStyleRuleDeclaration(selector, style) {
    CSSRuleDeclaration.call(this);
    this._selector = selector;
    this._style_0 = style;
    this._$stable_25 = 8;
  }
  CSSStyleRuleDeclaration.prototype._get_header__2 = function () {
    return toString_2(this._selector);
  };
  CSSStyleRuleDeclaration.prototype.toString = function () {
    return '' + 'CSSStyleRuleDeclaration(selector=' + this._selector + ', style=' + this._style_0 + ')';
  };
  CSSStyleRuleDeclaration.prototype.hashCode = function () {
    var result = hashCode(this._selector);
    result = imul(result, 31) + hashCode(this._style_0) | 0;
    return result;
  };
  CSSStyleRuleDeclaration.prototype.equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof CSSStyleRuleDeclaration))
      return false;
    else {
    }
    var tmp0_other_with_cast = other instanceof CSSStyleRuleDeclaration ? other : THROW_CCE();
    if (!this._selector.equals(tmp0_other_with_cast._selector))
      return false;
    if (!equals_0(this._style_0, tmp0_other_with_cast._style_0))
      return false;
    return true;
  };
  CSSStyleRuleDeclaration.$metadata$ = {
    simpleName: 'CSSStyleRuleDeclaration',
    kind: 'class',
    interfaces: []
  };
  function CSSRuleBuilderImpl() {
    StyleBuilderImpl.call(this);
    this._$stable_26 = 0;
  }
  CSSRuleBuilderImpl.$metadata$ = {
    simpleName: 'CSSRuleBuilderImpl',
    kind: 'class',
    interfaces: [CSSStyleRuleBuilder]
  };
  function CSSGroupingRuleDeclaration(rules) {
    CSSRuleDeclaration.call(this);
    this._rules = rules;
    this._$stable_27 = 8;
  }
  CSSGroupingRuleDeclaration.prototype._get_rules__0 = function () {
    return this._rules;
  };
  CSSGroupingRuleDeclaration.$metadata$ = {
    simpleName: 'CSSGroupingRuleDeclaration',
    kind: 'class',
    interfaces: []
  };
  function buildCSSStyleRule(cssRule) {
    var builder = new CSSRuleBuilderImpl();
    cssRule(builder);
    return builder;
  }
  function _get_percent_(_this_) {
    var tmp0_unsafeCast_0 = CSS.percent(_this_);
    return tmp0_unsafeCast_0;
  }
  function _get_px_(_this_) {
    var tmp0_unsafeCast_0 = CSS.px(_this_);
    return tmp0_unsafeCast_0;
  }
  function _get_pc_(_this_) {
    var tmp0_unsafeCast_0 = CSS.pc(_this_);
    return tmp0_unsafeCast_0;
  }
  function Named(value_0) {
    Color.call(this);
    this._value_18 = value_0;
    this._$stable_28 = 0;
  }
  Named.prototype.toString = function () {
    return this._value_18;
  };
  Named.prototype.hashCode = function () {
    return getStringHashCode(this._value_18);
  };
  Named.prototype.equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof Named))
      return false;
    else {
    }
    var tmp0_other_with_cast = other instanceof Named ? other : THROW_CCE();
    if (!(this._value_18 === tmp0_other_with_cast._value_18))
      return false;
    return true;
  };
  Named.$metadata$ = {
    simpleName: 'Named',
    kind: 'class',
    interfaces: []
  };
  function RGBA(r, g, b, a) {
    Color.call(this);
    this._r = r;
    this._g = g;
    this._b_3 = b;
    this._a_3 = a;
    this._$stable_29 = 0;
  }
  RGBA.prototype.toString = function () {
    return '' + 'rgba(' + this._r + ', ' + this._g + ', ' + this._b_3 + ', ' + this._a_3 + ')';
  };
  RGBA.prototype.hashCode = function () {
    var result = hashCode(this._r);
    result = imul(result, 31) + hashCode(this._g) | 0;
    result = imul(result, 31) + hashCode(this._b_3) | 0;
    result = imul(result, 31) + hashCode(this._a_3) | 0;
    return result;
  };
  RGBA.prototype.equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof RGBA))
      return false;
    else {
    }
    var tmp0_other_with_cast = other instanceof RGBA ? other : THROW_CCE();
    if (!equals_0(this._r, tmp0_other_with_cast._r))
      return false;
    if (!equals_0(this._g, tmp0_other_with_cast._g))
      return false;
    if (!equals_0(this._b_3, tmp0_other_with_cast._b_3))
      return false;
    if (!equals_0(this._a_3, tmp0_other_with_cast._a_3))
      return false;
    return true;
  };
  RGBA.$metadata$ = {
    simpleName: 'RGBA',
    kind: 'class',
    interfaces: []
  };
  function Companion_38() {
    Companion_instance_37 = this;
  }
  Companion_38.prototype.invoke_402 = function (name) {
    return new Named(name);
  };
  Companion_38.$metadata$ = {
    simpleName: 'Companion',
    kind: 'object',
    interfaces: []
  };
  var Companion_instance_37;
  function Companion_getInstance_37() {
    if (Companion_instance_37 == null)
      new Companion_38();
    return Companion_instance_37;
  }
  function Color() {
    Companion_getInstance_37();
    this._$stable_30 = 0;
  }
  Color.prototype.styleValue_3 = function () {
    return Companion_getInstance_35().invoke_402(toString_2(this));
  };
  Color.$metadata$ = {
    simpleName: 'Color',
    kind: 'class',
    interfaces: [CustomStyleValue]
  };
  function StyleBuilder() {
  }
  StyleBuilder.prototype.invoke_403 = function (_this__0, value_0) {
    var tmp0_safe_receiver = (!(value_0 == null) ? isInterface(value_0, CustomStyleValue) : false) ? value_0 : null;
    var tmp1_elvis_lhs = tmp0_safe_receiver == null ? null : tmp0_safe_receiver.styleValue_3();
    var tmp;
    if (tmp1_elvis_lhs == null) {
      var tmp0_value_0 = toString_1(value_0);
      tmp = Companion_getInstance_35().invoke_402(tmp0_value_0);
    } else {
      tmp = tmp1_elvis_lhs;
    }
    this.variable_2(_this__0._name_4, tmp);
  };
  StyleBuilder.$metadata$ = {
    simpleName: 'StyleBuilder',
    kind: 'interface',
    interfaces: []
  };
  function CSSVariables() {
  }
  CSSVariables.$metadata$ = {
    simpleName: 'CSSVariables',
    kind: 'interface',
    interfaces: []
  };
  function CSSStyleVariable(name) {
    this._name_4 = name;
    this._$stable_31 = 0;
  }
  CSSStyleVariable.prototype.toString = function () {
    return '' + 'CSSStyleVariable(name=' + this._name_4 + ')';
  };
  CSSStyleVariable.prototype.hashCode = function () {
    return getStringHashCode(this._name_4);
  };
  CSSStyleVariable.prototype.equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof CSSStyleVariable))
      return false;
    else {
    }
    var tmp0_other_with_cast = other instanceof CSSStyleVariable ? other : THROW_CCE();
    if (!(this._name_4 === tmp0_other_with_cast._name_4))
      return false;
    return true;
  };
  CSSStyleVariable.$metadata$ = {
    simpleName: 'CSSStyleVariable',
    kind: 'class',
    interfaces: [CSSVariable]
  };
  function variable(_this_) {
    return new _no_name_provided__226();
  }
  function Companion_39() {
    Companion_instance_38 = this;
  }
  Companion_39.prototype.invoke_404 = function (value_0) {
    return value_0;
  };
  Companion_39.$metadata$ = {
    simpleName: 'Companion',
    kind: 'object',
    interfaces: []
  };
  var Companion_instance_38;
  function Companion_getInstance_38() {
    if (Companion_instance_38 == null)
      new Companion_39();
    return Companion_instance_38;
  }
  function value(_this_, fallback) {
    var tmp = Companion_getInstance_38();
    var tmp0_safe_receiver = fallback;
    var tmp_0;
    if (tmp0_safe_receiver == null) {
      tmp_0 = null;
    } else {
      var tmp0_safe_receiver_3 = (!(fallback == null) ? isInterface(fallback, CustomStyleValue) : false) ? fallback : null;
      var tmp1_elvis_lhs_2 = tmp0_safe_receiver_3 == null ? null : tmp0_safe_receiver_3.styleValue_3();
      tmp_0 = tmp1_elvis_lhs_2 == null ? Companion_getInstance_35().invoke_402(toString_2(fallback)) : tmp1_elvis_lhs_2;
    }
    return tmp.invoke_404(variableValue(_this_._name_4, tmp_0));
  }
  function value$default(_this_, fallback, $mask0, $handler) {
    if (!(($mask0 & 1) === 0))
      fallback = null;
    return value(_this_, fallback);
  }
  function CustomStyleValue() {
  }
  CustomStyleValue.$metadata$ = {
    simpleName: 'CustomStyleValue',
    kind: 'interface',
    interfaces: []
  };
  function StyleBuilderImpl() {
    var tmp = this;
    tmp._properties = ArrayList_init_$Create$();
    var tmp_0 = this;
    tmp_0._variables = ArrayList_init_$Create$();
    this._$stable_32 = 0;
  }
  StyleBuilderImpl.prototype._get_properties__2 = function () {
    return this._properties;
  };
  StyleBuilderImpl.prototype._get_variables__2 = function () {
    return this._variables;
  };
  StyleBuilderImpl.prototype.property_4 = function (propertyName, value_0) {
    this._get_properties__2().add_39(new StylePropertyDeclaration(propertyName, value_0));
    Unit_getInstance();
  };
  StyleBuilderImpl.prototype.variable_2 = function (variableName, value_0) {
    this._get_variables__2().add_39(new StylePropertyDeclaration(variableName, value_0));
    Unit_getInstance();
  };
  StyleBuilderImpl.prototype.equals = function (other) {
    var tmp;
    if (!(other == null) ? isInterface(other, StyleHolder) : false) {
      tmp = nativeEquals(this._get_properties__2(), other._get_properties__2()) ? nativeEquals(this._get_variables__2(), other._get_variables__2()) : false;
    } else {
      {
        tmp = false;
      }
    }
    return tmp;
  };
  StyleBuilderImpl.$metadata$ = {
    simpleName: 'StyleBuilderImpl',
    kind: 'class',
    interfaces: [StyleBuilder, StyleHolder]
  };
  function CSSVariable() {
  }
  CSSVariable.$metadata$ = {
    simpleName: 'CSSVariable',
    kind: 'interface',
    interfaces: []
  };
  function variableValue(variableName, fallback) {
    var tmp = Companion_getInstance_35();
    var tmp0_safe_receiver = fallback;
    var tmp_0;
    if (tmp0_safe_receiver == null) {
      tmp_0 = null;
    } else {
      tmp_0 = '' + ', ' + tmp0_safe_receiver;
    }
    var tmp1_elvis_lhs = tmp_0;
    return tmp.invoke_402('' + 'var(--' + variableName + (tmp1_elvis_lhs == null ? '' : tmp1_elvis_lhs) + ')');
  }
  function StyleHolder() {
  }
  StyleHolder.$metadata$ = {
    simpleName: 'StyleHolder',
    kind: 'interface',
    interfaces: []
  };
  function StylePropertyDeclaration(name, value_0) {
    this._name_5 = name;
    this._value_19 = value_0;
    this._$stable_33 = 8;
  }
  StylePropertyDeclaration.prototype.component1_4 = function () {
    return this._name_5;
  };
  StylePropertyDeclaration.prototype.component2_4 = function () {
    return this._value_19;
  };
  StylePropertyDeclaration.prototype.toString = function () {
    return '' + 'StylePropertyDeclaration(name=' + this._name_5 + ', value=' + this._value_19 + ')';
  };
  StylePropertyDeclaration.prototype.hashCode = function () {
    var result = getStringHashCode(this._name_5);
    result = imul(result, 31) + hashCode(this._value_19) | 0;
    return result;
  };
  StylePropertyDeclaration.prototype.equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof StylePropertyDeclaration))
      return false;
    else {
    }
    var tmp0_other_with_cast = other instanceof StylePropertyDeclaration ? other : THROW_CCE();
    if (!(this._name_5 === tmp0_other_with_cast._name_5))
      return false;
    if (!equals_0(this._value_19, tmp0_other_with_cast._value_19))
      return false;
    return true;
  };
  StylePropertyDeclaration.$metadata$ = {
    simpleName: 'StylePropertyDeclaration',
    kind: 'class',
    interfaces: []
  };
  function nativeEquals(_this_, properties) {
    if (!(_this_._get_size__52() === properties._get_size__52()))
      return false;
    var index = 0;
    var tmp$ret$0;
    l$ret$1: do {
      var tmp;
      if (isInterface(_this_, Collection)) {
        tmp = _this_.isEmpty_44();
      } else {
        {
          tmp = false;
        }
      }
      if (tmp) {
        tmp$ret$0 = true;
        break l$ret$1;
      } else {
      }
      var tmp0_iterator_1 = _this_.iterator_66();
      while (tmp0_iterator_1.hasNext_41()) {
        var element_2 = tmp0_iterator_1.next_46();
        var tmp0_5 = index;
        index = tmp0_5 + 1 | 0;
        var otherProp_4 = properties.get_72(tmp0_5);
        if (!(element_2._name_5 === otherProp_4._name_5 ? toString_2(element_2._value_19) === toString_2(otherProp_4._value_19) : false)) {
          tmp$ret$0 = false;
          break l$ret$1;
        } else {
        }
      }
      tmp$ret$0 = true;
    }
     while (false);
    return tmp$ret$0;
  }
  function _no_name_provided__226() {
  }
  _no_name_provided__226.prototype.getValue_3 = function (_anonymous_parameter_0_, property) {
    return new CSSStyleVariable(property.callableName);
  };
  _no_name_provided__226.prototype.getValue_4 = function (thisRef, property) {
    return this.getValue_3((thisRef == null ? true : isObject(thisRef)) ? thisRef : THROW_CCE(), property);
  };
  _no_name_provided__226.$metadata$ = {
    kind: 'class',
    interfaces: [ReadOnlyProperty]
  };
  function _no_name_provided__227($selector) {
    this._$selector = $selector;
  }
  _no_name_provided__227.prototype.getValue_3 = function (_anonymous_parameter_0_, _anonymous_parameter_1_) {
    return this._$selector._className;
  };
  _no_name_provided__227.prototype.getValue_4 = function (thisRef, property) {
    return this.getValue_3((thisRef == null ? true : isObject(thisRef)) ? thisRef : THROW_CCE(), property);
  };
  _no_name_provided__227.$metadata$ = {
    kind: 'class',
    interfaces: [ReadOnlyProperty]
  };
  function StyleSheet_init_$Init$(rulesHolder, $mask0, $marker, $this) {
    if (!(($mask0 & 1) === 0))
      rulesHolder = new CSSRulesHolderState();
    StyleSheet_0.call($this, rulesHolder);
    return $this;
  }
  function StyleSheet_init_$Create$(rulesHolder, $mask0, $marker) {
    return StyleSheet_init_$Init$(rulesHolder, $mask0, $marker, Object.create(StyleSheet_0.prototype));
  }
  function Companion_40() {
    Companion_instance_39 = this;
    this._counter_0 = 0;
  }
  Companion_40.$metadata$ = {
    simpleName: 'Companion',
    kind: 'object',
    interfaces: []
  };
  var Companion_instance_39;
  function Companion_getInstance_39() {
    if (Companion_instance_39 == null)
      new Companion_40();
    return Companion_instance_39;
  }
  function CSSHolder(cssBuilder) {
    this._cssBuilder = cssBuilder;
  }
  CSSHolder.prototype.provideDelegate = function (sheet, property) {
    var sheetName = '' + getKClassFromExpression_0(sheet)._get_simpleName__4() + '-';
    var selector = className('' + sheetName + property.callableName);
    var tmp0_container = buildCSS(selector, selector, this._cssBuilder);
    var properties = tmp0_container.component1_4();
    var rules = tmp0_container.component2_4();
    sheet.add_65(selector, properties);
    var tmp0_iterator_1 = rules.iterator_66();
    while (tmp0_iterator_1.hasNext_41()) {
      var element_2 = tmp0_iterator_1.next_46();
      sheet.add_64(element_2);
    }
    return new _no_name_provided__227(selector);
  };
  CSSHolder.$metadata$ = {
    simpleName: 'CSSHolder',
    kind: 'class',
    interfaces: []
  };
  function StyleSheet_0(rulesHolder) {
    Companion_getInstance_39();
    this._rulesHolder = rulesHolder;
    var tmp = this;
    tmp._boundClasses = LinkedHashMap_init_$Create$();
    this._$stable_34 = 0;
  }
  StyleSheet_0.prototype.add_64 = function (cssRule) {
    this._rulesHolder.add_64(cssRule);
  };
  StyleSheet_0.prototype.add_65 = function (selector, style) {
    this._rulesHolder.add_65(selector, style);
  };
  StyleSheet_0.prototype._get_cssRules__12 = function () {
    return this._rulesHolder._get_cssRules__12();
  };
  StyleSheet_0.prototype.style_45 = function (cssRule) {
    return new CSSHolder(cssRule);
  };
  StyleSheet_0.prototype.buildRules_12 = function (rulesBuild) {
    var tmp0_apply_0 = StyleSheet_init_$Create$(null, 1, null);
    rulesBuild(tmp0_apply_0);
    return tmp0_apply_0._get_cssRules__12();
  };
  StyleSheet_0.prototype.buildRules_5 = function (rulesBuild) {
    return this.buildRules_12(rulesBuild);
  };
  StyleSheet_0.prototype.style_43 = function (selector, cssRule) {
    return this.style_42(selector, cssRule);
  };
  StyleSheet_0.$metadata$ = {
    simpleName: 'StyleSheet',
    kind: 'class',
    interfaces: [StyleSheetBuilder, CSSRulesHolder]
  };
  function CSSRulesHolderState() {
    var tmp = this;
    var tmp_0 = emptyList();
    tmp._cssRules$delegate = mutableStateOf$default(tmp_0, null, 2, null);
    this._$stable_35 = 0;
  }
  CSSRulesHolderState.prototype._set_cssRules_ = function (_set___) {
    var tmp0_setValue_0 = this._cssRules$delegate;
    var tmp1_setValue_0 = cssRules$factory();
    return tmp0_setValue_0._set_value__6(_set___);
  };
  CSSRulesHolderState.prototype._get_cssRules__12 = function () {
    var tmp0_getValue_0 = this._cssRules$delegate;
    var tmp1_getValue_0 = cssRules$factory_0();
    return tmp0_getValue_0._get_value__33();
  };
  CSSRulesHolderState.prototype.add_64 = function (cssRule) {
    var tmp0_this = this;
    tmp0_this._set_cssRules_(plus_0(tmp0_this._get_cssRules__12(), cssRule));
  };
  CSSRulesHolderState.$metadata$ = {
    simpleName: 'CSSRulesHolderState',
    kind: 'class',
    interfaces: [CSSRulesHolder]
  };
  function buildCSS(thisClass, thisContext, cssRule) {
    var styleSheet = new StyleSheetBuilderImpl();
    var builder = new CSSBuilderImpl(thisClass, thisContext, styleSheet);
    cssRule(builder);
    return to(builder, styleSheet._get_cssRules__12());
  }
  function cssRules$factory() {
    return getPropertyCallableRef('cssRules', 1, KMutableProperty1, function (receiver) {
      return receiver._get_cssRules__12();
    }, function (receiver_0, value_0) {
      return receiver_0._set_cssRules_(value_0);
    });
  }
  function cssRules$factory_0() {
    return getPropertyCallableRef('cssRules', 1, KMutableProperty1, function (receiver) {
      return receiver._get_cssRules__12();
    }, function (receiver_0, value_0) {
      return receiver_0._set_cssRules_(value_0);
    });
  }
  function GenericStyleSheetBuilder() {
  }
  GenericStyleSheetBuilder.prototype.style_11 = function (_this__0, cssRule) {
    this.style_43(_this__0, cssRule);
  };
  GenericStyleSheetBuilder.prototype.style_12 = function (_this__0, cssRule) {
    this.style_43(new Raw(_this__0), cssRule);
  };
  GenericStyleSheetBuilder.$metadata$ = {
    simpleName: 'GenericStyleSheetBuilder',
    kind: 'interface',
    interfaces: [CSSRulesHolder]
  };
  function StyleSheetBuilder() {
  }
  StyleSheetBuilder.prototype.style_42 = function (selector, cssRule) {
    this.add_65(selector, buildCSSStyleRule(cssRule));
  };
  StyleSheetBuilder.$metadata$ = {
    simpleName: 'StyleSheetBuilder',
    kind: 'interface',
    interfaces: [CSSRulesHolder, GenericStyleSheetBuilder]
  };
  function CSSRulesHolder() {
  }
  CSSRulesHolder.prototype.add_65 = function (selector, style) {
    this.add_64(new CSSStyleRuleDeclaration(selector, style));
  };
  CSSRulesHolder.$metadata$ = {
    simpleName: 'CSSRulesHolder',
    kind: 'interface',
    interfaces: []
  };
  function StyleSheetBuilderImpl() {
    var tmp = this;
    tmp._cssRules = ArrayList_init_$Create$();
    this._$stable_36 = 8;
  }
  StyleSheetBuilderImpl.prototype._get_cssRules__12 = function () {
    return this._cssRules;
  };
  StyleSheetBuilderImpl.prototype.add_64 = function (cssRule) {
    this._get_cssRules__12().add_39(cssRule);
    Unit_getInstance();
  };
  StyleSheetBuilderImpl.prototype.buildRules_12 = function (rulesBuild) {
    var tmp0_apply_0 = new StyleSheetBuilderImpl();
    rulesBuild(tmp0_apply_0);
    return tmp0_apply_0._get_cssRules__12();
  };
  StyleSheetBuilderImpl.prototype.buildRules_5 = function (rulesBuild) {
    return this.buildRules_12(rulesBuild);
  };
  StyleSheetBuilderImpl.prototype.style_43 = function (selector, cssRule) {
    return this.style_42(selector, cssRule);
  };
  StyleSheetBuilderImpl.$metadata$ = {
    simpleName: 'StyleSheetBuilderImpl',
    kind: 'class',
    interfaces: [StyleSheetBuilder]
  };
  var Operator_Equals_instance;
  var Operator_ListContains_instance;
  var Operator_Hyphened_instance;
  var Operator_Prefixed_instance;
  var Operator_Suffixed_instance;
  var Operator_Contains_instance;
  var Operator_entriesInitialized;
  function Operator_initEntries() {
    if (Operator_entriesInitialized)
      return Unit_getInstance();
    Operator_entriesInitialized = true;
    Operator_Equals_instance = new Operator('Equals', 0, '=');
    Operator_ListContains_instance = new Operator('ListContains', 1, '~=');
    Operator_Hyphened_instance = new Operator('Hyphened', 2, '|=');
    Operator_Prefixed_instance = new Operator('Prefixed', 3, '^=');
    Operator_Suffixed_instance = new Operator('Suffixed', 4, '$=');
    Operator_Contains_instance = new Operator('Contains', 5, '*=');
  }
  function Attribute_init_$Init$(name, value_0, operator, caseSensitive, $mask0, $marker, $this) {
    if (!(($mask0 & 2) === 0))
      value_0 = null;
    if (!(($mask0 & 4) === 0))
      operator = Operator_Equals_getInstance();
    if (!(($mask0 & 8) === 0))
      caseSensitive = true;
    Attribute.call($this, name, value_0, operator, caseSensitive);
    return $this;
  }
  function Attribute_init_$Create$(name, value_0, operator, caseSensitive, $mask0, $marker) {
    return Attribute_init_$Init$(name, value_0, operator, caseSensitive, $mask0, $marker, Object.create(Attribute.prototype));
  }
  function Operator(name, ordinal, value_0) {
    Enum.call(this, name, ordinal);
    this._value_20 = value_0;
  }
  Operator.$metadata$ = {
    simpleName: 'Operator',
    kind: 'class',
    interfaces: []
  };
  function Operator_Equals_getInstance() {
    Operator_initEntries();
    return Operator_Equals_instance;
  }
  function Operator_Contains_getInstance() {
    Operator_initEntries();
    return Operator_Contains_instance;
  }
  function Companion_41() {
    Companion_instance_40 = this;
    this._anyLink = new PseudoClass('any-link');
    this._link = new PseudoClass('link');
    this._visited = new PseudoClass('visited');
    this._localLink = new PseudoClass('local-link');
    this._target = new PseudoClass('target');
    this._targetWithin = new PseudoClass('target-within');
    this._scope_1 = new PseudoClass('scope');
    this._hover = new PseudoClass('hover');
    this._active = new PseudoClass('active');
    this._focus = new PseudoClass('focus');
    this._focusVisible = new PseudoClass('focus-visible');
    this._playing = new PseudoClass('playing');
    this._paused = new PseudoClass('paused');
    this._autofill = new PseudoClass('autofill');
    this._enabled = new PseudoClass('enabled');
    this._disabled = new PseudoClass('disabled');
    this._readOnly = new PseudoClass('read-only');
    this._readWrite = new PseudoClass('read-write');
    this._placeholderShown = new PseudoClass('placeholder-shown');
    this._default = new PseudoClass('default');
    this._checked = new PseudoClass('checked');
    this._indeterminate = new PseudoClass('indeterminate');
    this._blank = new PseudoClass('blank');
    this._valid = new PseudoClass('valid');
    this._invalid_0 = new PseudoClass('invalid');
    this._inRange = new PseudoClass('in-range');
    this._outOfRange = new PseudoClass('out-of-range');
    this._required = new PseudoClass('required');
    this._optional = new PseudoClass('optional');
    this._userInvalid = new PseudoClass('user-invalid');
    this._root_1 = new PseudoClass('root');
    this._empty = new PseudoClass('empty');
    this._first_2 = new PseudoClass('first');
    this._firstChild = new PseudoClass('first-child');
    this._lastChild = new PseudoClass('last-child');
    this._onlyChild = new PseudoClass('only-child');
    this._firstOfType = new PseudoClass('first-of-type');
    this._lastOfType = new PseudoClass('last-of-type');
    this._onlyOfType = new PseudoClass('only-of-type');
    this._host = new PseudoClass('host');
    this._defined = new PseudoClass('defined');
    this._left_2 = new PseudoClass('left');
    this._right_0 = new PseudoClass('right');
  }
  Companion_41.$metadata$ = {
    simpleName: 'Companion',
    kind: 'object',
    interfaces: []
  };
  var Companion_instance_40;
  function Companion_getInstance_40() {
    if (Companion_instance_40 == null)
      new Companion_41();
    return Companion_instance_40;
  }
  function Raw(selector) {
    CSSSelector.call(this);
    this._selector_0 = selector;
    this._$stable_37 = 0;
  }
  Raw.prototype.toString = function () {
    return this._selector_0;
  };
  Raw.prototype.hashCode = function () {
    return getStringHashCode(this._selector_0);
  };
  Raw.prototype.equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof Raw))
      return false;
    else {
    }
    var tmp0_other_with_cast = other instanceof Raw ? other : THROW_CCE();
    if (!(this._selector_0 === tmp0_other_with_cast._selector_0))
      return false;
    return true;
  };
  Raw.$metadata$ = {
    simpleName: 'Raw',
    kind: 'class',
    interfaces: []
  };
  function Universal() {
    Universal_instance = this;
    CSSSelector.call(this);
    this._$stable_38 = 0;
  }
  Universal.prototype.toString = function () {
    return '*';
  };
  Universal.$metadata$ = {
    simpleName: 'Universal',
    kind: 'object',
    interfaces: []
  };
  var Universal_instance;
  function Universal_getInstance() {
    if (Universal_instance == null)
      new Universal();
    return Universal_instance;
  }
  function CSSClass(className_0) {
    CSSSelector.call(this);
    this._className = className_0;
    this._$stable_39 = 0;
  }
  CSSClass.prototype.toString = function () {
    return '' + '.' + this._className;
  };
  CSSClass.prototype.hashCode = function () {
    return getStringHashCode(this._className);
  };
  CSSClass.prototype.equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof CSSClass))
      return false;
    else {
    }
    var tmp0_other_with_cast = other instanceof CSSClass ? other : THROW_CCE();
    if (!(this._className === tmp0_other_with_cast._className))
      return false;
    return true;
  };
  CSSClass.$metadata$ = {
    simpleName: 'CSSClass',
    kind: 'class',
    interfaces: []
  };
  function Attribute(name, value_0, operator, caseSensitive) {
    CSSSelector.call(this);
    this._name_6 = name;
    this._value_21 = value_0;
    this._operator = operator;
    this._caseSensitive = caseSensitive;
    this._$stable_40 = 0;
  }
  Attribute.prototype.toString = function () {
    var tmp0_safe_receiver = this._value_21;
    var tmp;
    if (tmp0_safe_receiver == null) {
      tmp = null;
    } else {
      tmp = '' + this._operator._value_20 + this._value_21 + (!this._caseSensitive ? ' i' : '');
    }
    var tmp1_elvis_lhs = tmp;
    var valueStr = tmp1_elvis_lhs == null ? '' : tmp1_elvis_lhs;
    return '' + '[' + this._name_6 + valueStr + ']';
  };
  Attribute.prototype.hashCode = function () {
    var result = getStringHashCode(this._name_6);
    result = imul(result, 31) + (this._value_21 == null ? 0 : getStringHashCode(this._value_21)) | 0;
    result = imul(result, 31) + this._operator.hashCode() | 0;
    result = imul(result, 31) + (this._caseSensitive | 0) | 0;
    return result;
  };
  Attribute.prototype.equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof Attribute))
      return false;
    else {
    }
    var tmp0_other_with_cast = other instanceof Attribute ? other : THROW_CCE();
    if (!(this._name_6 === tmp0_other_with_cast._name_6))
      return false;
    if (!(this._value_21 == tmp0_other_with_cast._value_21))
      return false;
    if (!this._operator.equals(tmp0_other_with_cast._operator))
      return false;
    if (!(this._caseSensitive === tmp0_other_with_cast._caseSensitive))
      return false;
    return true;
  };
  Attribute.$metadata$ = {
    simpleName: 'Attribute',
    kind: 'class',
    interfaces: []
  };
  function Combine(selectors) {
    CSSSelector.call(this);
    this._selectors = selectors;
    this._$stable_41 = 8;
  }
  Combine.prototype.toString = function () {
    return joinToString$default_1(this._selectors, '', null, null, 0, null, null, 62, null);
  };
  Combine.prototype.hashCode = function () {
    return hashCode(this._selectors);
  };
  Combine.prototype.equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof Combine))
      return false;
    else {
    }
    var tmp0_other_with_cast = other instanceof Combine ? other : THROW_CCE();
    if (!equals_0(this._selectors, tmp0_other_with_cast._selectors))
      return false;
    return true;
  };
  Combine.$metadata$ = {
    simpleName: 'Combine',
    kind: 'class',
    interfaces: []
  };
  function PseudoClass(name) {
    Companion_getInstance_40();
    CSSSelector.call(this);
    this._name_7 = name;
    this._$stable_42 = 0;
  }
  PseudoClass.prototype.equals = function (other) {
    var tmp;
    if (other instanceof PseudoClass) {
      tmp = this._name_7 === other._name_7 ? this.argsStr() == other.argsStr() : false;
    } else {
      {
        tmp = false;
      }
    }
    return tmp;
  };
  PseudoClass.prototype.argsStr = function () {
    return null;
  };
  PseudoClass.prototype.toString = function () {
    var tmp0_safe_receiver = this.argsStr();
    var tmp;
    if (tmp0_safe_receiver == null) {
      tmp = null;
    } else {
      tmp = '' + '(' + tmp0_safe_receiver + ')';
    }
    var tmp1_elvis_lhs = tmp;
    return '' + ':' + this._name_7 + (tmp1_elvis_lhs == null ? '' : tmp1_elvis_lhs);
  };
  PseudoClass.$metadata$ = {
    simpleName: 'PseudoClass',
    kind: 'class',
    interfaces: []
  };
  function CSSSelector() {
    this._$stable_43 = 0;
  }
  CSSSelector.prototype.equals = function (other) {
    return toString_2(this) === toString_1(other);
  };
  CSSSelector.$metadata$ = {
    simpleName: 'CSSSelector',
    kind: 'class',
    interfaces: []
  };
  function hover(selector) {
    return plus_6(selector, hover_0());
  }
  function className(className_0) {
    return new CSSClass(className_0);
  }
  function plus_6(_this_, selector) {
    return combine([_this_, selector]);
  }
  function hover_0() {
    return Companion_getInstance_40()._hover;
  }
  function combine(selectors) {
    return new Combine(toMutableList(selectors));
  }
  function TagElement$composable(tagName, applyAttrs, content, $composer, $changed) {
    var $composer_0 = $composer;
    $composer_0 = $composer_0.startRestartGroup_0(-1128050830);
    sourceInformation($composer_0, 'C(TagElement$composable)P(2)');
    var $dirty = $changed;
    if (($changed & 14) === 0)
      $dirty = $dirty | ($composer_0.changed_0(tagName) ? 4 : 2);
    if (($changed & 112) === 0)
      $dirty = $dirty | ($composer_0.changed_0(applyAttrs) ? 32 : 16);
    if (($changed & 896) === 0)
      $dirty = $dirty | ($composer_0.changed_0(content) ? 256 : 128);
    if (!(($dirty & 731 ^ 146) === 0) ? true : !$composer_0._get_skipping__0()) {
      var tmp0_remember$composable_0 = $composer_0;
      var tmp1_remember$composable_0 = 0;
      var $composer_1 = tmp0_remember$composable_0;
      $composer_1.startReplaceableGroup_0(884179566);
      sourceInformation($composer_1, 'C(remember$composable):Composables.kt#9igjgp');
      var tmp1_cache_0_3 = $composer_1;
      var tmp2_cache_0_4 = false;
      var tmp0_let_0_5 = tmp1_cache_0_3.rememberedValue_0();
      var tmp;
      if (tmp2_cache_0_4 ? true : tmp0_let_0_5 === Companion_getInstance_25()._Empty) {
        var tmp$ret$0;
        l$ret$1: do {
          var tmp0_return_8 = new ElementScopeImpl();
          tmp$ret$0 = tmp0_return_8;
          break l$ret$1;
        }
         while (false);
        var value_2_2_6 = tmp$ret$0;
        tmp1_cache_0_3.updateRememberedValue_0(value_2_2_6);
        tmp = value_2_2_6;
      } else {
        tmp = tmp0_let_0_5;
      }
      var tmp_0 = tmp;
      var tmp0_2 = (tmp_0 == null ? true : isObject(tmp_0)) ? tmp_0 : THROW_CCE();
      $composer_1.endReplaceableGroup_0();
      var scope = tmp0_2;
      var tmp2_remember$composable_0 = $composer_0;
      var tmp3_remember$composable_0 = 0;
      var $composer_1_0 = tmp2_remember$composable_0;
      $composer_1_0.startReplaceableGroup_0(884179566);
      sourceInformation($composer_1_0, 'C(remember$composable):Composables.kt#9igjgp');
      var tmp1_cache_0_3_0 = $composer_1_0;
      var tmp2_cache_0_4_0 = false;
      var tmp0_let_0_5_0 = tmp1_cache_0_3_0.rememberedValue_0();
      var tmp_1;
      if (tmp2_cache_0_4_0 ? true : tmp0_let_0_5_0 === Companion_getInstance_25()._Empty) {
        var tmp$ret$2;
        l$ret$3: do {
          var tmp0_return_8_0 = DisposableEffectHolder_init_$Create$(null, 1, null);
          tmp$ret$2 = tmp0_return_8_0;
          break l$ret$3;
        }
         while (false);
        var value_2_2_6_0 = tmp$ret$2;
        tmp1_cache_0_3_0.updateRememberedValue_0(value_2_2_6_0);
        tmp_1 = value_2_2_6_0;
      } else {
        tmp_1 = tmp0_let_0_5_0;
      }
      var tmp_2 = tmp_1;
      var tmp0_2_0 = (tmp_2 == null ? true : isObject(tmp_2)) ? tmp_2 : THROW_CCE();
      $composer_1_0.endReplaceableGroup_0();
      var refEffect = tmp0_2_0;
      var tmp6_ComposeDomNode$composable_0 = _no_name_provided_$factory_196(tagName, scope);
      var tmp_3 = $composer_0;
      var tmp7_ComposeDomNode$composable_0 = _no_name_provided_$factory_198(composableLambda(tmp_3, -819892294, true, _no_name_provided_$factory_197(applyAttrs, refEffect)));
      var tmp8_ComposeDomNode$composable_0 = $composer_0;
      var tmp9_ComposeDomNode$composable_0 = 64 | 7168 & $dirty << 3;
      var $composer_1_1 = tmp8_ComposeDomNode$composable_0;
      var tmp_4 = $composer_1_1._get_applier__0();
      if (!(tmp_4 instanceof DomApplier)) {
        var tmp4_error_0 = 'Invalid applier';
        throw IllegalStateException_init_$Create$_0(toString_2(tmp4_error_0));
      } else {
      }
      $composer_1_1.startNode_2();
      if ($composer_1_1._get_inserting__0()) {
        $composer_1_1.createNode_0(tmp6_ComposeDomNode$composable_0);
      } else {
        $composer_1_1.useNode_0();
      }
      var tmp5_apply_0 = _SkippableUpdater___init__impl_($composer_1_1);
      tmp7_ComposeDomNode$composable_0(new SkippableUpdater(tmp5_apply_0), $composer_1_1, 112 & tmp9_ComposeDomNode$composable_0 >> 3);
      var tmp0_group_2 = tmp5_apply_0;
      Unit_getInstance();
      $composer_1_1.startReplaceableGroup_0(2058660585);
      var tmp0_safe_receiver_4 = content;
      if (tmp0_safe_receiver_4 == null) {
        $composer_1_1.startReplaceableGroup_0(2067000608);
        $composer_1_1.endReplaceableGroup_0();
      } else {
        $composer_1_1.startReplaceableGroup_0(-2011532543);
        tmp0_safe_receiver_4(scope, $composer_1_1, 8 & tmp9_ComposeDomNode$composable_0 >> 3 | 14 & tmp9_ComposeDomNode$composable_0 >> 3 | 112 & tmp9_ComposeDomNode$composable_0 >> 6);
        $composer_1_1.endReplaceableGroup_0();
        Unit_getInstance();
      }
      Unit_getInstance();
      $composer_1_1.endReplaceableGroup_0();
      $composer_1_1.endNode_0();
      DisposableEffect$composable(null, _no_name_provided_$factory_199(refEffect, scope), $composer_0, 6);
    } else
      $composer_0.skipToGroupEnd_2();
    var tmp0_safe_receiver = $composer_0.endRestartGroup_0();
    if (tmp0_safe_receiver === null)
      null;
    else {
      tmp0_safe_receiver.updateScope_0(_no_name_provided_$factory_200(tagName, applyAttrs, content, $changed));
    }
  }
  function DisposableEffectHolder_init_$Init$(effect, $mask0, $marker, $this) {
    if (!(($mask0 & 1) === 0))
      effect = null;
    DisposableEffectHolder.call($this, effect);
    return $this;
  }
  function DisposableEffectHolder_init_$Create$(effect, $mask0, $marker) {
    return DisposableEffectHolder_init_$Init$(effect, $mask0, $marker, Object.create(DisposableEffectHolder.prototype));
  }
  function DisposableEffectHolder(effect) {
    this._effect_0 = effect;
    this._$stable_44 = 8;
  }
  DisposableEffectHolder.$metadata$ = {
    simpleName: 'DisposableEffectHolder',
    kind: 'class',
    interfaces: []
  };
  function TagElement$composable$lambda_0($tagName, $applyAttrs, $content, $$changed, $composer, $force) {
    return TagElement$composable($tagName, $applyAttrs, $content, $composer, $$changed | 1);
  }
  function _no_name_provided__228($tagName, $scope) {
    this._$tagName = $tagName;
    this._$scope_0 = $scope;
  }
  _no_name_provided__228.prototype.invoke_523 = function () {
    var tmp = document.createElement(this._$tagName);
    var tmp0_also_0 = new DomElementWrapper(tmp instanceof HTMLElement ? tmp : THROW_CCE());
    var tmp0_unsafeCast_0_2 = tmp0_also_0._node_2;
    this._$scope_0._set_element_(tmp0_unsafeCast_0_2);
    return tmp0_also_0;
  };
  _no_name_provided__228.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__229($boundThis) {
    this._$boundThis_3 = $boundThis;
  }
  _no_name_provided__229.prototype.invoke_406 = function (p0, p1, p2) {
    return this._$boundThis_3.invoke_377(new SkippableUpdater(p0), p1, p2);
  };
  _no_name_provided__229.prototype.invoke_917 = function (p1, p2, p3) {
    var tmp = p1 instanceof SkippableUpdater ? p1._composer : THROW_CCE();
    var tmp_0 = (!(p2 == null) ? isInterface(p2, Composer) : false) ? p2 : THROW_CCE();
    return this.invoke_406(tmp, tmp_0, (!(p3 == null) ? typeof p3 === 'number' : false) ? p3 : THROW_CCE());
  };
  _no_name_provided__229.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__230() {
  }
  _no_name_provided__230.prototype.invoke_408 = function (p0, p1) {
    return p0.updateAttrs(p1);
  };
  _no_name_provided__230.prototype.invoke_915 = function (p1, p2) {
    var tmp = p1 instanceof DomElementWrapper ? p1 : THROW_CCE();
    this.invoke_408(tmp, (!(p2 == null) ? isInterface(p2, Map_0) : false) ? p2 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__230.prototype._get_name__18 = function () {
    return 'updateAttrs';
  };
  _no_name_provided__230.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__231() {
  }
  _no_name_provided__231.prototype.invoke_410 = function (p0, p1) {
    return p0.updateEventListeners_0(p1);
  };
  _no_name_provided__231.prototype.invoke_915 = function (p1, p2) {
    var tmp = p1 instanceof DomElementWrapper ? p1 : THROW_CCE();
    this.invoke_410(tmp, (!(p2 == null) ? isInterface(p2, List) : false) ? p2 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__231.prototype._get_name__18 = function () {
    return 'updateEventListeners';
  };
  _no_name_provided__231.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__232() {
  }
  _no_name_provided__232.prototype.invoke_412 = function (p0, p1) {
    return p0.updateProperties(p1);
  };
  _no_name_provided__232.prototype.invoke_915 = function (p1, p2) {
    var tmp = p1 instanceof DomElementWrapper ? p1 : THROW_CCE();
    this.invoke_412(tmp, (!(p2 == null) ? isInterface(p2, List) : false) ? p2 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__232.prototype._get_name__18 = function () {
    return 'updateProperties';
  };
  _no_name_provided__232.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__233() {
  }
  _no_name_provided__233.prototype.invoke_414 = function (p0, p1) {
    return p0.updateStyleDeclarations(p1);
  };
  _no_name_provided__233.prototype.invoke_915 = function (p1, p2) {
    var tmp = p1 instanceof DomElementWrapper ? p1 : THROW_CCE();
    this.invoke_414(tmp, (p2 == null ? true : isInterface(p2, StyleHolder)) ? p2 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__233.prototype._get_name__18 = function () {
    return 'updateStyleDeclarations';
  };
  _no_name_provided__233.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__234($applyAttrs, $refEffect) {
    this._$applyAttrs = $applyAttrs;
    this._$refEffect = $refEffect;
  }
  _no_name_provided__234.prototype.invoke_416 = function ($this$ComposeDomNode, $composer, $changed) {
    var $composer_0 = $composer;
    var $dirty = $changed;
    if (($changed & 14) === 0)
      $dirty = $dirty | ($composer_0.changed_0(_SkippableUpdater___get_composer__impl_($this$ComposeDomNode)) ? 4 : 2);
    if (!(($dirty & 91 ^ 18) === 0) ? true : !$composer_0._get_skipping__0()) {
      var tmp0_also_0 = new AttrsBuilder();
      this._$applyAttrs(tmp0_also_0);
      var attrsApplied = tmp0_also_0;
      this._$refEffect._effect_0 = attrsApplied._refEffect;
      var attrsCollected = attrsApplied.collect();
      var events = attrsApplied.collectListeners_0();
      _SkippableUpdater___get_composer__impl_($this$ComposeDomNode).startReplaceableGroup_0(509942095);
      var tmp1__anonymous__1 = _Updater___init__impl_(_SkippableUpdater___get_composer__impl_($this$ComposeDomNode));
      Updater__set_impl(tmp1__anonymous__1, attrsCollected, _no_name_provided_$factory_201());
      Updater__set_impl(tmp1__anonymous__1, events, _no_name_provided_$factory_202());
      Updater__set_impl(tmp1__anonymous__1, attrsApplied._propertyUpdates, _no_name_provided_$factory_203());
      Updater__set_impl(tmp1__anonymous__1, attrsApplied._styleBuilder, _no_name_provided_$factory_204());
      _SkippableUpdater___get_composer__impl_($this$ComposeDomNode).endReplaceableGroup_0();
    } else
      $composer_0.skipToGroupEnd_2();
  };
  _no_name_provided__234.prototype.invoke_917 = function (p1, p2, p3) {
    var tmp = p1 instanceof SkippableUpdater ? p1._composer : THROW_CCE();
    var tmp_0 = (p2 == null ? true : isInterface(p2, Composer)) ? p2 : THROW_CCE();
    this.invoke_416(tmp, tmp_0, (!(p3 == null) ? typeof p3 === 'number' : false) ? p3 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__234.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__1_4() {
  }
  _no_name_provided__1_4.prototype.dispose_2_0 = function () {
    Unit_getInstance();
  };
  _no_name_provided__1_4.prototype.dispose_15 = function () {
    return this.dispose_2_0();
  };
  _no_name_provided__1_4.$metadata$ = {
    simpleName: '<no name provided>_1',
    kind: 'class',
    interfaces: [DisposableEffectResult]
  };
  function _no_name_provided__235($refEffect, $scope) {
    this._$refEffect_0 = $refEffect;
    this._$scope_1 = $scope;
  }
  _no_name_provided__235.prototype.invoke_418 = function ($this$DisposableEffect) {
    var tmp0_safe_receiver = this._$refEffect_0._effect_0;
    var tmp1_elvis_lhs = tmp0_safe_receiver == null ? null : tmp0_safe_receiver($this$DisposableEffect, this._$scope_1._get_element__0());
    var tmp;
    if (tmp1_elvis_lhs == null) {
      tmp = new _no_name_provided__1_4();
    } else {
      tmp = tmp1_elvis_lhs;
    }
    return tmp;
  };
  _no_name_provided__235.prototype.invoke_1115 = function (p1) {
    return this.invoke_418(p1 instanceof DisposableEffectScope ? p1 : THROW_CCE());
  };
  _no_name_provided__235.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__236($tagName, $applyAttrs, $content, $$changed) {
    this._$tagName_0 = $tagName;
    this._$applyAttrs_0 = $applyAttrs;
    this._$content_0 = $content;
    this._$$changed = $$changed;
  }
  _no_name_provided__236.prototype.invoke_892 = function (p0, p1) {
    return TagElement$composable$lambda_0(this._$tagName_0, this._$applyAttrs_0, this._$content_0, this._$$changed, p0, p1);
  };
  _no_name_provided__236.prototype.invoke_915 = function (p1, p2) {
    var tmp = (!(p1 == null) ? isInterface(p1, Composer) : false) ? p1 : THROW_CCE();
    this.invoke_892(tmp, (!(p2 == null) ? typeof p2 === 'number' : false) ? p2 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__236.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided_$factory_196($tagName, $scope) {
    var i = new _no_name_provided__228($tagName, $scope);
    return function () {
      return i.invoke_523();
    };
  }
  function _no_name_provided_$factory_197($applyAttrs, $refEffect) {
    var i = new _no_name_provided__234($applyAttrs, $refEffect);
    return function (p1, p2, p3) {
      i.invoke_416(p1._composer, p2, p3);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_198($boundThis) {
    var i = new _no_name_provided__229($boundThis);
    return function (p1, p2, p3) {
      return i.invoke_406(p1._composer, p2, p3);
    };
  }
  function _no_name_provided_$factory_199($refEffect, $scope) {
    var i = new _no_name_provided__235($refEffect, $scope);
    return function (p1) {
      return i.invoke_418(p1);
    };
  }
  function _no_name_provided_$factory_200($tagName, $applyAttrs, $content, $$changed) {
    var i = new _no_name_provided__236($tagName, $applyAttrs, $content, $$changed);
    return function (p1, p2) {
      i.invoke_892(p1, p2);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_201() {
    var i = new _no_name_provided__230();
    var l = function (p1, p2) {
      i.invoke_408(p1, p2);
      return Unit_getInstance();
    };
    l.callableName = i._get_name__18();
    return l;
  }
  function _no_name_provided_$factory_202() {
    var i = new _no_name_provided__231();
    var l = function (p1, p2) {
      i.invoke_410(p1, p2);
      return Unit_getInstance();
    };
    l.callableName = i._get_name__18();
    return l;
  }
  function _no_name_provided_$factory_203() {
    var i = new _no_name_provided__232();
    var l = function (p1, p2) {
      i.invoke_412(p1, p2);
      return Unit_getInstance();
    };
    l.callableName = i._get_name__18();
    return l;
  }
  function _no_name_provided_$factory_204() {
    var i = new _no_name_provided__233();
    var l = function (p1, p2) {
      i.invoke_414(p1, p2);
      return Unit_getInstance();
    };
    l.callableName = i._get_name__18();
    return l;
  }
  function ElementScope() {
  }
  ElementScope.$metadata$ = {
    simpleName: 'ElementScope',
    kind: 'interface',
    interfaces: [DOMScope]
  };
  function DomEffectScope() {
  }
  DomEffectScope.$metadata$ = {
    simpleName: 'DomEffectScope',
    kind: 'interface',
    interfaces: []
  };
  function DOMScope() {
  }
  DOMScope.$metadata$ = {
    simpleName: 'DOMScope',
    kind: 'interface',
    interfaces: []
  };
  function ElementScopeImpl() {
    ElementScopeBase.call(this);
    this._$stable_45 = 8;
  }
  ElementScopeImpl.prototype._set_element_ = function (_set___) {
    this._element_0 = _set___;
  };
  ElementScopeImpl.prototype._get_element__0 = function () {
    var tmp = this._element_0;
    if (!(tmp == null))
      return tmp;
    else {
      throwUninitializedPropertyAccessException('element');
    }
  };
  ElementScopeImpl.$metadata$ = {
    simpleName: 'ElementScopeImpl',
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__237($changed, $effect, $effectHolder, this$0) {
    this._$changed_0 = $changed;
    this._$effect_0 = $effect;
    this._$effectHolder = $effectHolder;
    this._this$0_40 = this$0;
  }
  _no_name_provided__237.prototype.invoke_422 = function () {
    if (this._$changed_0)
      this._$effect_0(this._$effectHolder, this._this$0_40._get_element__0());
  };
  _no_name_provided__237.prototype.invoke_523 = function () {
    this.invoke_422();
    return Unit_getInstance();
  };
  _no_name_provided__237.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function ElementScopeBase() {
    this._nextDisposableDomEffectKey = 0;
    this._$stable_46 = 8;
  }
  ElementScopeBase.prototype.DomSideEffect$composable_3 = function (key_0, effect, $composer, $changed) {
    var $composer_0 = $composer;
    $composer_0.startReplaceableGroup_0(610961982);
    sourceInformation($composer_0, 'C(DomSideEffect$composable)P(1)');
    var changed = $composer_0.changed_0(key_0);
    var tmp0_remember$composable_0 = $composer_0;
    var tmp1_remember$composable_0 = 14 & $changed;
    var $composer_1 = tmp0_remember$composable_0;
    $composer_1.startReplaceableGroup_0(884179877);
    sourceInformation($composer_1, 'C(remember$composable)P(1):Composables.kt#9igjgp');
    var tmp1_cache_0_3 = $composer_1;
    var tmp2_cache_0_4 = $composer_1.changed_0(key_0);
    var tmp0_let_0_5 = tmp1_cache_0_3.rememberedValue_0();
    var tmp;
    if (tmp2_cache_0_4 ? true : tmp0_let_0_5 === Companion_getInstance_25()._Empty) {
      var tmp$ret$0;
      l$ret$1: do {
        var tmp0_return_8 = new DomDisposableEffectHolder(this);
        tmp$ret$0 = tmp0_return_8;
        break l$ret$1;
      }
       while (false);
      var value_2_2_6 = tmp$ret$0;
      tmp1_cache_0_3.updateRememberedValue_0(value_2_2_6);
      tmp = value_2_2_6;
    } else {
      tmp = tmp0_let_0_5;
    }
    var tmp_0 = tmp;
    var tmp0_2 = (tmp_0 == null ? true : isObject(tmp_0)) ? tmp_0 : THROW_CCE();
    $composer_1.endReplaceableGroup_0();
    var effectHolder = tmp0_2;
    SideEffect$composable(_no_name_provided_$factory_205(changed, effect, effectHolder, this), $composer_0, 0);
    $composer_0.endReplaceableGroup_0();
  };
  ElementScopeBase.prototype.DomSideEffect$composable_4 = function (effect, $composer, $changed) {
    var $composer_0 = $composer;
    $composer_0.startReplaceableGroup_0(610962377);
    sourceInformation($composer_0, 'C(DomSideEffect$composable)');
    var tmp0_this = this;
    var tmp1 = tmp0_this._nextDisposableDomEffectKey;
    tmp0_this._nextDisposableDomEffectKey = tmp1 + 1 | 0;
    this.DomSideEffect$composable_3(tmp1, effect, $composer_0, 512 | 112 & $changed << 3);
    $composer_0.endReplaceableGroup_0();
  };
  ElementScopeBase.$metadata$ = {
    simpleName: 'ElementScopeBase',
    kind: 'class',
    interfaces: [ElementScope]
  };
  function DomDisposableEffectHolder(elementScope) {
    this._elementScope = elementScope;
    this._onDispose_0 = null;
  }
  DomDisposableEffectHolder.prototype.onRemembered_1 = function () {
  };
  DomDisposableEffectHolder.prototype.onForgotten_1 = function () {
    var tmp0_safe_receiver = this._onDispose_0;
    if (tmp0_safe_receiver == null)
      null;
    else
      tmp0_safe_receiver(this._elementScope._get_element__0());
    Unit_getInstance();
  };
  DomDisposableEffectHolder.prototype.onAbandoned_1 = function () {
  };
  DomDisposableEffectHolder.prototype.onDispose_0 = function (disposeEffect) {
    this._onDispose_0 = disposeEffect;
  };
  DomDisposableEffectHolder.$metadata$ = {
    simpleName: 'DomDisposableEffectHolder',
    kind: 'class',
    interfaces: [RememberObserver, DomEffectScope]
  };
  function _no_name_provided_$factory_205($changed, $effect, $effectHolder, this$0) {
    var i = new _no_name_provided__237($changed, $effect, $effectHolder, this$0);
    return function () {
      i.invoke_422();
      return Unit_getInstance();
    };
  }
  function Text$composable(value_0, $composer, $changed) {
    var $composer_0 = $composer;
    $composer_0 = $composer_0.startRestartGroup_0(-1487822462);
    sourceInformation($composer_0, 'C(Text$composable)');
    var $dirty = $changed;
    if (($changed & 14) === 0)
      $dirty = $dirty | ($composer_0.changed_0(value_0) ? 4 : 2);
    if (!(($dirty & 11 ^ 2) === 0) ? true : !$composer_0._get_skipping__0()) {
      var tmp0_ComposeNode$composable_0 = _no_name_provided_$factory_206();
      var tmp1_ComposeNode$composable_0 = $composer_0;
      var tmp2_ComposeNode$composable_0 = 0;
      var $composer_1 = tmp1_ComposeNode$composable_0;
      $composer_1.startReplaceableGroup_0(-440990300);
      sourceInformation($composer_1, 'C(ComposeNode$composable):Composables.kt#9igjgp');
      var tmp = $composer_1._get_applier__0();
      if (!(tmp instanceof DomApplier))
        invalidApplier();
      else {
      }
      $composer_1.startNode_2();
      if ($composer_1._get_inserting__0()) {
        var tmp_0 = $composer_1;
        tmp_0.createNode_0(_no_name_provided_$factory_207(tmp0_ComposeNode$composable_0));
      } else {
        $composer_1.useNode_0();
      }
      var tmp3__anonymous__3 = _Updater___init__impl_($composer_1);
      Updater__set_impl(tmp3__anonymous__3, value_0, _no_name_provided_$factory_208());
      $composer_1.endNode_0();
      $composer_1.endReplaceableGroup_0();
    } else
      $composer_0.skipToGroupEnd_2();
    var tmp0_safe_receiver = $composer_0.endRestartGroup_0();
    if (tmp0_safe_receiver === null)
      null;
    else {
      tmp0_safe_receiver.updateScope_0(_no_name_provided_$factory_209(value_0, $changed));
    }
  }
  function A$composable(href_0, attrs, content, $composer, $changed, $default) {
    var href_1 = {_v: href_0};
    var attrs_0 = {_v: attrs};
    var content_0 = {_v: content};
    var $composer_0 = $composer;
    $composer_0 = $composer_0.startRestartGroup_0(172475853);
    sourceInformation($composer_0, 'C(A$composable)P(2)');
    var $dirty = $changed;
    if (!(($default & 1) === 0))
      $dirty = $dirty | 6;
    else if (($changed & 14) === 0)
      $dirty = $dirty | ($composer_0.changed_0(href_1._v) ? 4 : 2);
    if (($changed & 112) === 0)
      $dirty = $dirty | ((($default & 2) === 0 ? $composer_0.changed_0(attrs_0._v) : false) ? 32 : 16);
    if (!(($default & 4) === 0))
      $dirty = $dirty | 384;
    else if (($changed & 896) === 0)
      $dirty = $dirty | ($composer_0.changed_0(content_0._v) ? 256 : 128);
    if (!(($dirty & 731 ^ 146) === 0) ? true : !$composer_0._get_skipping__0()) {
      if (($changed & 1) === 0 ? true : $composer_0._get_defaultsInvalid__0()) {
        $composer_0.startDefaults_0();
        if (!(($default & 1) === 0))
          href_1._v = null;
        if (!(($default & 2) === 0)) {
          attrs_0._v = _no_name_provided_$factory_210();
          $dirty = $dirty & -113;
        }if (!(($default & 4) === 0))
          content_0._v = null;
        $composer_0.endDefaults_0();
      } else {
        $composer_0.skipCurrentGroup_0();
        if (!(($default & 2) === 0))
          $dirty = $dirty & -113;
      }
      var tmp0_remember$composable_0 = href_1._v;
      var tmp1_remember$composable_0 = attrs_0._v;
      var tmp2_remember$composable_0 = _no_name_provided_$factory_211(href_1, attrs_0);
      var tmp3_remember$composable_0 = $composer_0;
      var tmp4_remember$composable_0 = 14 & $dirty | 112 & $dirty;
      var $composer_1 = tmp3_remember$composable_0;
      $composer_1.startReplaceableGroup_0(884180255);
      sourceInformation($composer_1, 'C(remember$composable)P(1,2):Composables.kt#9igjgp');
      var tmp1_cache_0_3 = $composer_1;
      var tmp2_cache_0_4 = !!($composer_1.changed_0(tmp0_remember$composable_0) | $composer_1.changed_0(tmp1_remember$composable_0));
      var tmp0_let_0_5 = tmp1_cache_0_3.rememberedValue_0();
      var tmp;
      if (tmp2_cache_0_4 ? true : tmp0_let_0_5 === Companion_getInstance_25()._Empty) {
        var value_2_2_6 = tmp2_remember$composable_0();
        tmp1_cache_0_3.updateRememberedValue_0(value_2_2_6);
        tmp = value_2_2_6;
      } else {
        tmp = tmp0_let_0_5;
      }
      var tmp_0 = tmp;
      var tmp0_2 = (tmp_0 == null ? true : isObject(tmp_0)) ? tmp_0 : THROW_CCE();
      $composer_1.endReplaceableGroup_0();
      TagElement$composable('a', tmp0_2, content_0._v, $composer_0, 6 | 896 & $dirty);
    } else
      $composer_0.skipToGroupEnd_2();
    var tmp0_safe_receiver = $composer_0.endRestartGroup_0();
    if (tmp0_safe_receiver === null)
      null;
    else {
      tmp0_safe_receiver.updateScope_0(_no_name_provided_$factory_212(href_1, attrs_0, content_0, $changed, $default));
    }
  }
  function Div$composable(attrs, content, $composer, $changed, $default) {
    var attrs_0 = {_v: attrs};
    var content_0 = {_v: content};
    var $composer_0 = $composer;
    $composer_0 = $composer_0.startRestartGroup_0(-739444197);
    sourceInformation($composer_0, 'C(Div$composable)');
    var $dirty = $changed;
    if (($changed & 14) === 0)
      $dirty = $dirty | ((($default & 1) === 0 ? $composer_0.changed_0(attrs_0._v) : false) ? 4 : 2);
    if (!(($default & 2) === 0))
      $dirty = $dirty | 48;
    else if (($changed & 112) === 0)
      $dirty = $dirty | ($composer_0.changed_0(content_0._v) ? 32 : 16);
    if (!(($dirty & 91 ^ 18) === 0) ? true : !$composer_0._get_skipping__0()) {
      if (($changed & 1) === 0 ? true : $composer_0._get_defaultsInvalid__0()) {
        $composer_0.startDefaults_0();
        if (!(($default & 1) === 0)) {
          attrs_0._v = _no_name_provided_$factory_213();
          $dirty = $dirty & -15;
        }if (!(($default & 2) === 0))
          content_0._v = null;
        $composer_0.endDefaults_0();
      } else {
        $composer_0.skipCurrentGroup_0();
        if (!(($default & 1) === 0))
          $dirty = $dirty & -15;
      }
      TagElement$composable('div', attrs_0._v, content_0._v, $composer_0, 6 | 112 & $dirty << 3 | 896 & $dirty << 3);
    } else
      $composer_0.skipToGroupEnd_2();
    var tmp0_safe_receiver = $composer_0.endRestartGroup_0();
    if (tmp0_safe_receiver === null)
      null;
    else {
      tmp0_safe_receiver.updateScope_0(_no_name_provided_$factory_214(attrs_0, content_0, $changed, $default));
    }
  }
  function Main$composable(attrs, content, $composer, $changed, $default) {
    var attrs_0 = {_v: attrs};
    var content_0 = {_v: content};
    var $composer_0 = $composer;
    $composer_0 = $composer_0.startRestartGroup_0(1104546755);
    sourceInformation($composer_0, 'C(Main$composable)');
    var $dirty = $changed;
    if (($changed & 14) === 0)
      $dirty = $dirty | ((($default & 1) === 0 ? $composer_0.changed_0(attrs_0._v) : false) ? 4 : 2);
    if (!(($default & 2) === 0))
      $dirty = $dirty | 48;
    else if (($changed & 112) === 0)
      $dirty = $dirty | ($composer_0.changed_0(content_0._v) ? 32 : 16);
    if (!(($dirty & 91 ^ 18) === 0) ? true : !$composer_0._get_skipping__0()) {
      if (($changed & 1) === 0 ? true : $composer_0._get_defaultsInvalid__0()) {
        $composer_0.startDefaults_0();
        if (!(($default & 1) === 0)) {
          attrs_0._v = _no_name_provided_$factory_215();
          $dirty = $dirty & -15;
        }if (!(($default & 2) === 0))
          content_0._v = null;
        $composer_0.endDefaults_0();
      } else {
        $composer_0.skipCurrentGroup_0();
        if (!(($default & 1) === 0))
          $dirty = $dirty & -15;
      }
      TagElement$composable('main', attrs_0._v, content_0._v, $composer_0, 6 | 112 & $dirty << 3 | 896 & $dirty << 3);
    } else
      $composer_0.skipToGroupEnd_2();
    var tmp0_safe_receiver = $composer_0.endRestartGroup_0();
    if (tmp0_safe_receiver === null)
      null;
    else {
      tmp0_safe_receiver.updateScope_0(_no_name_provided_$factory_216(attrs_0, content_0, $changed, $default));
    }
  }
  function Section$composable(attrs, content, $composer, $changed, $default) {
    var attrs_0 = {_v: attrs};
    var content_0 = {_v: content};
    var $composer_0 = $composer;
    $composer_0 = $composer_0.startRestartGroup_0(478437069);
    sourceInformation($composer_0, 'C(Section$composable)');
    var $dirty = $changed;
    if (($changed & 14) === 0)
      $dirty = $dirty | ((($default & 1) === 0 ? $composer_0.changed_0(attrs_0._v) : false) ? 4 : 2);
    if (!(($default & 2) === 0))
      $dirty = $dirty | 48;
    else if (($changed & 112) === 0)
      $dirty = $dirty | ($composer_0.changed_0(content_0._v) ? 32 : 16);
    if (!(($dirty & 91 ^ 18) === 0) ? true : !$composer_0._get_skipping__0()) {
      if (($changed & 1) === 0 ? true : $composer_0._get_defaultsInvalid__0()) {
        $composer_0.startDefaults_0();
        if (!(($default & 1) === 0)) {
          attrs_0._v = _no_name_provided_$factory_217();
          $dirty = $dirty & -15;
        }if (!(($default & 2) === 0))
          content_0._v = null;
        $composer_0.endDefaults_0();
      } else {
        $composer_0.skipCurrentGroup_0();
        if (!(($default & 1) === 0))
          $dirty = $dirty & -15;
      }
      TagElement$composable('section', attrs_0._v, content_0._v, $composer_0, 6 | 112 & $dirty << 3 | 896 & $dirty << 3);
    } else
      $composer_0.skipToGroupEnd_2();
    var tmp0_safe_receiver = $composer_0.endRestartGroup_0();
    if (tmp0_safe_receiver === null)
      null;
    else {
      tmp0_safe_receiver.updateScope_0(_no_name_provided_$factory_218(attrs_0, content_0, $changed, $default));
    }
  }
  function P$composable(attrs, content, $composer, $changed, $default) {
    var attrs_0 = {_v: attrs};
    var content_0 = {_v: content};
    var $composer_0 = $composer;
    $composer_0 = $composer_0.startRestartGroup_0(66400040);
    sourceInformation($composer_0, 'C(P$composable)');
    var $dirty = $changed;
    if (($changed & 14) === 0)
      $dirty = $dirty | ((($default & 1) === 0 ? $composer_0.changed_0(attrs_0._v) : false) ? 4 : 2);
    if (!(($default & 2) === 0))
      $dirty = $dirty | 48;
    else if (($changed & 112) === 0)
      $dirty = $dirty | ($composer_0.changed_0(content_0._v) ? 32 : 16);
    if (!(($dirty & 91 ^ 18) === 0) ? true : !$composer_0._get_skipping__0()) {
      if (($changed & 1) === 0 ? true : $composer_0._get_defaultsInvalid__0()) {
        $composer_0.startDefaults_0();
        if (!(($default & 1) === 0)) {
          attrs_0._v = _no_name_provided_$factory_219();
          $dirty = $dirty & -15;
        }if (!(($default & 2) === 0))
          content_0._v = null;
        $composer_0.endDefaults_0();
      } else {
        $composer_0.skipCurrentGroup_0();
        if (!(($default & 1) === 0))
          $dirty = $dirty & -15;
      }
      TagElement$composable('p', attrs_0._v, content_0._v, $composer_0, 6 | 112 & $dirty << 3 | 896 & $dirty << 3);
    } else
      $composer_0.skipToGroupEnd_2();
    var tmp0_safe_receiver = $composer_0.endRestartGroup_0();
    if (tmp0_safe_receiver === null)
      null;
    else {
      tmp0_safe_receiver.updateScope_0(_no_name_provided_$factory_220(attrs_0, content_0, $changed, $default));
    }
  }
  function Pre$composable(attrs, content, $composer, $changed, $default) {
    var attrs_0 = {_v: attrs};
    var content_0 = {_v: content};
    var $composer_0 = $composer;
    $composer_0 = $composer_0.startRestartGroup_0(609138209);
    sourceInformation($composer_0, 'C(Pre$composable)');
    var $dirty = $changed;
    if (($changed & 14) === 0)
      $dirty = $dirty | ((($default & 1) === 0 ? $composer_0.changed_0(attrs_0._v) : false) ? 4 : 2);
    if (!(($default & 2) === 0))
      $dirty = $dirty | 48;
    else if (($changed & 112) === 0)
      $dirty = $dirty | ($composer_0.changed_0(content_0._v) ? 32 : 16);
    if (!(($dirty & 91 ^ 18) === 0) ? true : !$composer_0._get_skipping__0()) {
      if (($changed & 1) === 0 ? true : $composer_0._get_defaultsInvalid__0()) {
        $composer_0.startDefaults_0();
        if (!(($default & 1) === 0)) {
          attrs_0._v = _no_name_provided_$factory_221();
          $dirty = $dirty & -15;
        }if (!(($default & 2) === 0))
          content_0._v = null;
        $composer_0.endDefaults_0();
      } else {
        $composer_0.skipCurrentGroup_0();
        if (!(($default & 1) === 0))
          $dirty = $dirty & -15;
      }
      TagElement$composable('pre', attrs_0._v, content_0._v, $composer_0, 6 | 112 & $dirty << 3 | 896 & $dirty << 3);
    } else
      $composer_0.skipToGroupEnd_2();
    var tmp0_safe_receiver = $composer_0.endRestartGroup_0();
    if (tmp0_safe_receiver === null)
      null;
    else {
      tmp0_safe_receiver.updateScope_0(_no_name_provided_$factory_222(attrs_0, content_0, $changed, $default));
    }
  }
  function Span$composable(attrs, content, $composer, $changed, $default) {
    var attrs_0 = {_v: attrs};
    var content_0 = {_v: content};
    var $composer_0 = $composer;
    $composer_0 = $composer_0.startRestartGroup_0(-1070719700);
    sourceInformation($composer_0, 'C(Span$composable)');
    var $dirty = $changed;
    if (($changed & 14) === 0)
      $dirty = $dirty | ((($default & 1) === 0 ? $composer_0.changed_0(attrs_0._v) : false) ? 4 : 2);
    if (!(($default & 2) === 0))
      $dirty = $dirty | 48;
    else if (($changed & 112) === 0)
      $dirty = $dirty | ($composer_0.changed_0(content_0._v) ? 32 : 16);
    if (!(($dirty & 91 ^ 18) === 0) ? true : !$composer_0._get_skipping__0()) {
      if (($changed & 1) === 0 ? true : $composer_0._get_defaultsInvalid__0()) {
        $composer_0.startDefaults_0();
        if (!(($default & 1) === 0)) {
          attrs_0._v = _no_name_provided_$factory_223();
          $dirty = $dirty & -15;
        }if (!(($default & 2) === 0))
          content_0._v = null;
        $composer_0.endDefaults_0();
      } else {
        $composer_0.skipCurrentGroup_0();
        if (!(($default & 1) === 0))
          $dirty = $dirty & -15;
      }
      TagElement$composable('span', attrs_0._v, content_0._v, $composer_0, 6 | 112 & $dirty << 3 | 896 & $dirty << 3);
    } else
      $composer_0.skipToGroupEnd_2();
    var tmp0_safe_receiver = $composer_0.endRestartGroup_0();
    if (tmp0_safe_receiver === null)
      null;
    else {
      tmp0_safe_receiver.updateScope_0(_no_name_provided_$factory_224(attrs_0, content_0, $changed, $default));
    }
  }
  function Footer$composable(attrs, content, $composer, $changed, $default) {
    var attrs_0 = {_v: attrs};
    var content_0 = {_v: content};
    var $composer_0 = $composer;
    $composer_0 = $composer_0.startRestartGroup_0(2137514146);
    sourceInformation($composer_0, 'C(Footer$composable)');
    var $dirty = $changed;
    if (($changed & 14) === 0)
      $dirty = $dirty | ((($default & 1) === 0 ? $composer_0.changed_0(attrs_0._v) : false) ? 4 : 2);
    if (!(($default & 2) === 0))
      $dirty = $dirty | 48;
    else if (($changed & 112) === 0)
      $dirty = $dirty | ($composer_0.changed_0(content_0._v) ? 32 : 16);
    if (!(($dirty & 91 ^ 18) === 0) ? true : !$composer_0._get_skipping__0()) {
      if (($changed & 1) === 0 ? true : $composer_0._get_defaultsInvalid__0()) {
        $composer_0.startDefaults_0();
        if (!(($default & 1) === 0)) {
          attrs_0._v = _no_name_provided_$factory_225();
          $dirty = $dirty & -15;
        }if (!(($default & 2) === 0))
          content_0._v = null;
        $composer_0.endDefaults_0();
      } else {
        $composer_0.skipCurrentGroup_0();
        if (!(($default & 1) === 0))
          $dirty = $dirty & -15;
      }
      TagElement$composable('footer', attrs_0._v, content_0._v, $composer_0, 6 | 112 & $dirty << 3 | 896 & $dirty << 3);
    } else
      $composer_0.skipToGroupEnd_2();
    var tmp0_safe_receiver = $composer_0.endRestartGroup_0();
    if (tmp0_safe_receiver === null)
      null;
    else {
      tmp0_safe_receiver.updateScope_0(_no_name_provided_$factory_226(attrs_0, content_0, $changed, $default));
    }
  }
  function Img$composable(src_0, alt_0, attrs, content, $composer, $changed, $default) {
    var alt_1 = {_v: alt_0};
    var attrs_0 = {_v: attrs};
    var content_0 = {_v: content};
    var $composer_0 = $composer;
    $composer_0 = $composer_0.startRestartGroup_0(587195073);
    sourceInformation($composer_0, 'C(Img$composable)P(3)');
    var $dirty = $changed;
    if (!(($default & 1) === 0))
      $dirty = $dirty | 6;
    else if (($changed & 14) === 0)
      $dirty = $dirty | ($composer_0.changed_0(src_0) ? 4 : 2);
    if (!(($default & 2) === 0))
      $dirty = $dirty | 48;
    else if (($changed & 112) === 0)
      $dirty = $dirty | ($composer_0.changed_0(alt_1._v) ? 32 : 16);
    if (($changed & 896) === 0)
      $dirty = $dirty | ((($default & 4) === 0 ? $composer_0.changed_0(attrs_0._v) : false) ? 256 : 128);
    if (!(($default & 8) === 0))
      $dirty = $dirty | 3072;
    else if (($changed & 7168) === 0)
      $dirty = $dirty | ($composer_0.changed_0(content_0._v) ? 2048 : 1024);
    if (!(($dirty & 5851 ^ 1170) === 0) ? true : !$composer_0._get_skipping__0()) {
      if (($changed & 1) === 0 ? true : $composer_0._get_defaultsInvalid__0()) {
        $composer_0.startDefaults_0();
        if (!(($default & 2) === 0))
          alt_1._v = '';
        if (!(($default & 4) === 0)) {
          attrs_0._v = _no_name_provided_$factory_227();
          $dirty = $dirty & -897;
        }if (!(($default & 8) === 0))
          content_0._v = null;
        $composer_0.endDefaults_0();
      } else {
        $composer_0.skipCurrentGroup_0();
        if (!(($default & 4) === 0))
          $dirty = $dirty & -897;
      }
      var tmp0_remember$composable_0 = alt_1._v;
      var tmp1_remember$composable_0 = attrs_0._v;
      var tmp2_remember$composable_0 = _no_name_provided_$factory_228(src_0, alt_1, attrs_0);
      var tmp3_remember$composable_0 = $composer_0;
      var tmp4_remember$composable_0 = 14 & $dirty | 112 & $dirty | 896 & $dirty;
      var $composer_1 = tmp3_remember$composable_0;
      $composer_1.startReplaceableGroup_0(884180712);
      sourceInformation($composer_1, 'C(remember$composable)P(1,2,3):Composables.kt#9igjgp');
      var tmp1_cache_0_3 = $composer_1;
      var tmp2_cache_0_4 = !!(!!($composer_1.changed_0(src_0) | $composer_1.changed_0(tmp0_remember$composable_0)) | $composer_1.changed_0(tmp1_remember$composable_0));
      var tmp0_let_0_5 = tmp1_cache_0_3.rememberedValue_0();
      var tmp;
      if (tmp2_cache_0_4 ? true : tmp0_let_0_5 === Companion_getInstance_25()._Empty) {
        var value_2_2_6 = tmp2_remember$composable_0();
        tmp1_cache_0_3.updateRememberedValue_0(value_2_2_6);
        tmp = value_2_2_6;
      } else {
        tmp = tmp0_let_0_5;
      }
      var tmp_0 = tmp;
      var tmp0_2 = (tmp_0 == null ? true : isObject(tmp_0)) ? tmp_0 : THROW_CCE();
      $composer_1.endReplaceableGroup_0();
      TagElement$composable('img', tmp0_2, content_0._v, $composer_0, 6 | 896 & $dirty >> 3);
    } else
      $composer_0.skipToGroupEnd_2();
    var tmp0_safe_receiver = $composer_0.endRestartGroup_0();
    if (tmp0_safe_receiver === null)
      null;
    else {
      tmp0_safe_receiver.updateScope_0(_no_name_provided_$factory_229(src_0, alt_1, attrs_0, content_0, $changed, $default));
    }
  }
  function H1$composable(attrs, content, $composer, $changed, $default) {
    var attrs_0 = {_v: attrs};
    var content_0 = {_v: content};
    var $composer_0 = $composer;
    $composer_0 = $composer_0.startRestartGroup_0(-2018886255);
    sourceInformation($composer_0, 'C(H1$composable)');
    var $dirty = $changed;
    if (($changed & 14) === 0)
      $dirty = $dirty | ((($default & 1) === 0 ? $composer_0.changed_0(attrs_0._v) : false) ? 4 : 2);
    if (!(($default & 2) === 0))
      $dirty = $dirty | 48;
    else if (($changed & 112) === 0)
      $dirty = $dirty | ($composer_0.changed_0(content_0._v) ? 32 : 16);
    if (!(($dirty & 91 ^ 18) === 0) ? true : !$composer_0._get_skipping__0()) {
      if (($changed & 1) === 0 ? true : $composer_0._get_defaultsInvalid__0()) {
        $composer_0.startDefaults_0();
        if (!(($default & 1) === 0)) {
          attrs_0._v = _no_name_provided_$factory_230();
          $dirty = $dirty & -15;
        }if (!(($default & 2) === 0))
          content_0._v = null;
        $composer_0.endDefaults_0();
      } else {
        $composer_0.skipCurrentGroup_0();
        if (!(($default & 1) === 0))
          $dirty = $dirty & -15;
      }
      TagElement$composable('h1', attrs_0._v, content_0._v, $composer_0, 6 | 112 & $dirty << 3 | 896 & $dirty << 3);
    } else
      $composer_0.skipToGroupEnd_2();
    var tmp0_safe_receiver = $composer_0.endRestartGroup_0();
    if (tmp0_safe_receiver === null)
      null;
    else {
      tmp0_safe_receiver.updateScope_0(_no_name_provided_$factory_231(attrs_0, content_0, $changed, $default));
    }
  }
  function Button$composable(attrs, content, $composer, $changed, $default) {
    var attrs_0 = {_v: attrs};
    var content_0 = {_v: content};
    var $composer_0 = $composer;
    $composer_0 = $composer_0.startRestartGroup_0(-778924103);
    sourceInformation($composer_0, 'C(Button$composable)');
    var $dirty = $changed;
    if (($changed & 14) === 0)
      $dirty = $dirty | ((($default & 1) === 0 ? $composer_0.changed_0(attrs_0._v) : false) ? 4 : 2);
    if (!(($default & 2) === 0))
      $dirty = $dirty | 48;
    else if (($changed & 112) === 0)
      $dirty = $dirty | ($composer_0.changed_0(content_0._v) ? 32 : 16);
    if (!(($dirty & 91 ^ 18) === 0) ? true : !$composer_0._get_skipping__0()) {
      if (($changed & 1) === 0 ? true : $composer_0._get_defaultsInvalid__0()) {
        $composer_0.startDefaults_0();
        if (!(($default & 1) === 0)) {
          attrs_0._v = _no_name_provided_$factory_232();
          $dirty = $dirty & -15;
        }if (!(($default & 2) === 0))
          content_0._v = null;
        $composer_0.endDefaults_0();
      } else {
        $composer_0.skipCurrentGroup_0();
        if (!(($default & 1) === 0))
          $dirty = $dirty & -15;
      }
      TagElement$composable('button', attrs_0._v, content_0._v, $composer_0, 6 | 112 & $dirty << 3 | 896 & $dirty << 3);
    } else
      $composer_0.skipToGroupEnd_2();
    var tmp0_safe_receiver = $composer_0.endRestartGroup_0();
    if (tmp0_safe_receiver === null)
      null;
    else {
      tmp0_safe_receiver.updateScope_0(_no_name_provided_$factory_233(attrs_0, content_0, $changed, $default));
    }
  }
  function Text$composable$lambda_1($value, $$changed, $composer, $force) {
    return Text$composable($value, $composer, $$changed | 1);
  }
  function A$composable$lambda_2($href, $attrs) {
    return _no_name_provided_$factory_234($href, $attrs);
  }
  function A$composable$lambda_3($href, $attrs, $content, $$changed, $$default, $composer, $force) {
    return A$composable($href._v, $attrs._v, $content._v, $composer, $$changed | 1, $$default);
  }
  function Div$composable$lambda_4($attrs, $content, $$changed, $$default, $composer, $force) {
    return Div$composable($attrs._v, $content._v, $composer, $$changed | 1, $$default);
  }
  function Main$composable$lambda_5($attrs, $content, $$changed, $$default, $composer, $force) {
    return Main$composable($attrs._v, $content._v, $composer, $$changed | 1, $$default);
  }
  function Section$composable$lambda_6($attrs, $content, $$changed, $$default, $composer, $force) {
    return Section$composable($attrs._v, $content._v, $composer, $$changed | 1, $$default);
  }
  function P$composable$lambda_7($attrs, $content, $$changed, $$default, $composer, $force) {
    return P$composable($attrs._v, $content._v, $composer, $$changed | 1, $$default);
  }
  function Pre$composable$lambda_17($attrs, $content, $$changed, $$default, $composer, $force) {
    return Pre$composable($attrs._v, $content._v, $composer, $$changed | 1, $$default);
  }
  function Span$composable$lambda_18($attrs, $content, $$changed, $$default, $composer, $force) {
    return Span$composable($attrs._v, $content._v, $composer, $$changed | 1, $$default);
  }
  function Footer$composable$lambda_19($attrs, $content, $$changed, $$default, $composer, $force) {
    return Footer$composable($attrs._v, $content._v, $composer, $$changed | 1, $$default);
  }
  function Img$composable$lambda_20($src, $alt, $attrs) {
    return _no_name_provided_$factory_235($src, $alt, $attrs);
  }
  function Img$composable$lambda_21($src, $alt, $attrs, $content, $$changed, $$default, $composer, $force) {
    return Img$composable($src, $alt._v, $attrs._v, $content._v, $composer, $$changed | 1, $$default);
  }
  function H1$composable$lambda_22($attrs, $content, $$changed, $$default, $composer, $force) {
    return H1$composable($attrs._v, $content._v, $composer, $$changed | 1, $$default);
  }
  function Button$composable$lambda_23($attrs, $content, $$changed, $$default, $composer, $force) {
    return Button$composable($attrs._v, $content._v, $composer, $$changed | 1, $$default);
  }
  function _no_name_provided__238() {
  }
  _no_name_provided__238.prototype.invoke_523 = function () {
    return new DomNodeWrapper(document.createTextNode(''));
  };
  _no_name_provided__238.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__239($tmp0_ComposeNode$composable_0) {
    this._$tmp0_ComposeNode$composable_0 = $tmp0_ComposeNode$composable_0;
  }
  _no_name_provided__239.prototype.invoke_523 = function () {
    return this._$tmp0_ComposeNode$composable_0();
  };
  _no_name_provided__239.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__240() {
  }
  _no_name_provided__240.prototype.invoke_426 = function ($this$set, value_0) {
    var tmp = $this$set._get_node__0();
    (tmp instanceof Text ? tmp : THROW_CCE()).data = value_0;
  };
  _no_name_provided__240.prototype.invoke_915 = function (p1, p2) {
    var tmp = p1 instanceof DomNodeWrapper ? p1 : THROW_CCE();
    this.invoke_426(tmp, (!(p2 == null) ? typeof p2 === 'string' : false) ? p2 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__240.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__241($value, $$changed) {
    this._$value_2 = $value;
    this._$$changed_0 = $$changed;
  }
  _no_name_provided__241.prototype.invoke_892 = function (p0, p1) {
    return Text$composable$lambda_1(this._$value_2, this._$$changed_0, p0, p1);
  };
  _no_name_provided__241.prototype.invoke_915 = function (p1, p2) {
    var tmp = (!(p1 == null) ? isInterface(p1, Composer) : false) ? p1 : THROW_CCE();
    this.invoke_892(tmp, (!(p2 == null) ? typeof p2 === 'number' : false) ? p2 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__241.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__242() {
  }
  _no_name_provided__242.prototype.invoke_586 = function ($this$null) {
    return Unit_getInstance();
  };
  _no_name_provided__242.prototype.invoke_1115 = function (p1) {
    this.invoke_586(p1 instanceof AttrsBuilder ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__242.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__243($href, $attrs) {
    this._$href = $href;
    this._$attrs = $attrs;
  }
  _no_name_provided__243.prototype.invoke_523 = function () {
    return A$composable$lambda_2(this._$href, this._$attrs);
  };
  _no_name_provided__243.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__244($href, $attrs, $content, $$changed, $$default) {
    this._$href_0 = $href;
    this._$attrs_0 = $attrs;
    this._$content_1 = $content;
    this._$$changed_1 = $$changed;
    this._$$default = $$default;
  }
  _no_name_provided__244.prototype.invoke_892 = function (p0, p1) {
    return A$composable$lambda_3(this._$href_0, this._$attrs_0, this._$content_1, this._$$changed_1, this._$$default, p0, p1);
  };
  _no_name_provided__244.prototype.invoke_915 = function (p1, p2) {
    var tmp = (!(p1 == null) ? isInterface(p1, Composer) : false) ? p1 : THROW_CCE();
    this.invoke_892(tmp, (!(p2 == null) ? typeof p2 === 'number' : false) ? p2 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__244.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__245() {
  }
  _no_name_provided__245.prototype.invoke_890 = function ($this$null) {
    return Unit_getInstance();
  };
  _no_name_provided__245.prototype.invoke_1115 = function (p1) {
    this.invoke_890(p1 instanceof AttrsBuilder ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__245.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__246($attrs, $content, $$changed, $$default) {
    this._$attrs_1 = $attrs;
    this._$content_2 = $content;
    this._$$changed_2 = $$changed;
    this._$$default_0 = $$default;
  }
  _no_name_provided__246.prototype.invoke_892 = function (p0, p1) {
    return Div$composable$lambda_4(this._$attrs_1, this._$content_2, this._$$changed_2, this._$$default_0, p0, p1);
  };
  _no_name_provided__246.prototype.invoke_915 = function (p1, p2) {
    var tmp = (!(p1 == null) ? isInterface(p1, Composer) : false) ? p1 : THROW_CCE();
    this.invoke_892(tmp, (!(p2 == null) ? typeof p2 === 'number' : false) ? p2 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__246.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__247() {
  }
  _no_name_provided__247.prototype.invoke_890 = function ($this$null) {
    return Unit_getInstance();
  };
  _no_name_provided__247.prototype.invoke_1115 = function (p1) {
    this.invoke_890(p1 instanceof AttrsBuilder ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__247.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__248($attrs, $content, $$changed, $$default) {
    this._$attrs_2 = $attrs;
    this._$content_3 = $content;
    this._$$changed_3 = $$changed;
    this._$$default_1 = $$default;
  }
  _no_name_provided__248.prototype.invoke_892 = function (p0, p1) {
    return Main$composable$lambda_5(this._$attrs_2, this._$content_3, this._$$changed_3, this._$$default_1, p0, p1);
  };
  _no_name_provided__248.prototype.invoke_915 = function (p1, p2) {
    var tmp = (!(p1 == null) ? isInterface(p1, Composer) : false) ? p1 : THROW_CCE();
    this.invoke_892(tmp, (!(p2 == null) ? typeof p2 === 'number' : false) ? p2 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__248.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__249() {
  }
  _no_name_provided__249.prototype.invoke_632 = function ($this$null) {
    return Unit_getInstance();
  };
  _no_name_provided__249.prototype.invoke_1115 = function (p1) {
    this.invoke_632(p1 instanceof AttrsBuilder ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__249.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__250($attrs, $content, $$changed, $$default) {
    this._$attrs_3 = $attrs;
    this._$content_4 = $content;
    this._$$changed_4 = $$changed;
    this._$$default_2 = $$default;
  }
  _no_name_provided__250.prototype.invoke_892 = function (p0, p1) {
    return Section$composable$lambda_6(this._$attrs_3, this._$content_4, this._$$changed_4, this._$$default_2, p0, p1);
  };
  _no_name_provided__250.prototype.invoke_915 = function (p1, p2) {
    var tmp = (!(p1 == null) ? isInterface(p1, Composer) : false) ? p1 : THROW_CCE();
    this.invoke_892(tmp, (!(p2 == null) ? typeof p2 === 'number' : false) ? p2 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__250.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__251() {
  }
  _no_name_provided__251.prototype.invoke_654 = function ($this$null) {
    return Unit_getInstance();
  };
  _no_name_provided__251.prototype.invoke_1115 = function (p1) {
    this.invoke_654(p1 instanceof AttrsBuilder ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__251.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__252($attrs, $content, $$changed, $$default) {
    this._$attrs_4 = $attrs;
    this._$content_5 = $content;
    this._$$changed_5 = $$changed;
    this._$$default_3 = $$default;
  }
  _no_name_provided__252.prototype.invoke_892 = function (p0, p1) {
    return P$composable$lambda_7(this._$attrs_4, this._$content_5, this._$$changed_5, this._$$default_3, p0, p1);
  };
  _no_name_provided__252.prototype.invoke_915 = function (p1, p2) {
    var tmp = (!(p1 == null) ? isInterface(p1, Composer) : false) ? p1 : THROW_CCE();
    this.invoke_892(tmp, (!(p2 == null) ? typeof p2 === 'number' : false) ? p2 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__252.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__253() {
  }
  _no_name_provided__253.prototype.invoke_517 = function ($this$null) {
    return Unit_getInstance();
  };
  _no_name_provided__253.prototype.invoke_1115 = function (p1) {
    this.invoke_517(p1 instanceof AttrsBuilder ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__253.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__254($attrs, $content, $$changed, $$default) {
    this._$attrs_5 = $attrs;
    this._$content_6 = $content;
    this._$$changed_6 = $$changed;
    this._$$default_4 = $$default;
  }
  _no_name_provided__254.prototype.invoke_892 = function (p0, p1) {
    return Pre$composable$lambda_17(this._$attrs_5, this._$content_6, this._$$changed_6, this._$$default_4, p0, p1);
  };
  _no_name_provided__254.prototype.invoke_915 = function (p1, p2) {
    var tmp = (!(p1 == null) ? isInterface(p1, Composer) : false) ? p1 : THROW_CCE();
    this.invoke_892(tmp, (!(p2 == null) ? typeof p2 === 'number' : false) ? p2 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__254.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__255() {
  }
  _no_name_provided__255.prototype.invoke_864 = function ($this$null) {
    return Unit_getInstance();
  };
  _no_name_provided__255.prototype.invoke_1115 = function (p1) {
    this.invoke_864(p1 instanceof AttrsBuilder ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__255.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__256($attrs, $content, $$changed, $$default) {
    this._$attrs_6 = $attrs;
    this._$content_7 = $content;
    this._$$changed_7 = $$changed;
    this._$$default_5 = $$default;
  }
  _no_name_provided__256.prototype.invoke_892 = function (p0, p1) {
    return Span$composable$lambda_18(this._$attrs_6, this._$content_7, this._$$changed_7, this._$$default_5, p0, p1);
  };
  _no_name_provided__256.prototype.invoke_915 = function (p1, p2) {
    var tmp = (!(p1 == null) ? isInterface(p1, Composer) : false) ? p1 : THROW_CCE();
    this.invoke_892(tmp, (!(p2 == null) ? typeof p2 === 'number' : false) ? p2 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__256.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__257() {
  }
  _no_name_provided__257.prototype.invoke_890 = function ($this$null) {
    return Unit_getInstance();
  };
  _no_name_provided__257.prototype.invoke_1115 = function (p1) {
    this.invoke_890(p1 instanceof AttrsBuilder ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__257.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__258($attrs, $content, $$changed, $$default) {
    this._$attrs_7 = $attrs;
    this._$content_8 = $content;
    this._$$changed_8 = $$changed;
    this._$$default_6 = $$default;
  }
  _no_name_provided__258.prototype.invoke_892 = function (p0, p1) {
    return Footer$composable$lambda_19(this._$attrs_7, this._$content_8, this._$$changed_8, this._$$default_6, p0, p1);
  };
  _no_name_provided__258.prototype.invoke_915 = function (p1, p2) {
    var tmp = (!(p1 == null) ? isInterface(p1, Composer) : false) ? p1 : THROW_CCE();
    this.invoke_892(tmp, (!(p2 == null) ? typeof p2 === 'number' : false) ? p2 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__258.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__259() {
  }
  _no_name_provided__259.prototype.invoke_652 = function ($this$null) {
    return Unit_getInstance();
  };
  _no_name_provided__259.prototype.invoke_1115 = function (p1) {
    this.invoke_652(p1 instanceof AttrsBuilder ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__259.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__260($src, $alt, $attrs) {
    this._$src = $src;
    this._$alt = $alt;
    this._$attrs_8 = $attrs;
  }
  _no_name_provided__260.prototype.invoke_523 = function () {
    return Img$composable$lambda_20(this._$src, this._$alt, this._$attrs_8);
  };
  _no_name_provided__260.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__261($src, $alt, $attrs, $content, $$changed, $$default) {
    this._$src_0 = $src;
    this._$alt_0 = $alt;
    this._$attrs_9 = $attrs;
    this._$content_9 = $content;
    this._$$changed_9 = $$changed;
    this._$$default_7 = $$default;
  }
  _no_name_provided__261.prototype.invoke_892 = function (p0, p1) {
    return Img$composable$lambda_21(this._$src_0, this._$alt_0, this._$attrs_9, this._$content_9, this._$$changed_9, this._$$default_7, p0, p1);
  };
  _no_name_provided__261.prototype.invoke_915 = function (p1, p2) {
    var tmp = (!(p1 == null) ? isInterface(p1, Composer) : false) ? p1 : THROW_CCE();
    this.invoke_892(tmp, (!(p2 == null) ? typeof p2 === 'number' : false) ? p2 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__261.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__262() {
  }
  _no_name_provided__262.prototype.invoke_734 = function ($this$null) {
    return Unit_getInstance();
  };
  _no_name_provided__262.prototype.invoke_1115 = function (p1) {
    this.invoke_734(p1 instanceof AttrsBuilder ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__262.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__263($attrs, $content, $$changed, $$default) {
    this._$attrs_10 = $attrs;
    this._$content_10 = $content;
    this._$$changed_10 = $$changed;
    this._$$default_8 = $$default;
  }
  _no_name_provided__263.prototype.invoke_892 = function (p0, p1) {
    return H1$composable$lambda_22(this._$attrs_10, this._$content_10, this._$$changed_10, this._$$default_8, p0, p1);
  };
  _no_name_provided__263.prototype.invoke_915 = function (p1, p2) {
    var tmp = (!(p1 == null) ? isInterface(p1, Composer) : false) ? p1 : THROW_CCE();
    this.invoke_892(tmp, (!(p2 == null) ? typeof p2 === 'number' : false) ? p2 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__263.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__264() {
  }
  _no_name_provided__264.prototype.invoke_862 = function ($this$null) {
    return Unit_getInstance();
  };
  _no_name_provided__264.prototype.invoke_1115 = function (p1) {
    this.invoke_862(p1 instanceof AttrsBuilder ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__264.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__265($attrs, $content, $$changed, $$default) {
    this._$attrs_11 = $attrs;
    this._$content_11 = $content;
    this._$$changed_11 = $$changed;
    this._$$default_9 = $$default;
  }
  _no_name_provided__265.prototype.invoke_892 = function (p0, p1) {
    return Button$composable$lambda_23(this._$attrs_11, this._$content_11, this._$$changed_11, this._$$default_9, p0, p1);
  };
  _no_name_provided__265.prototype.invoke_915 = function (p1, p2) {
    var tmp = (!(p1 == null) ? isInterface(p1, Composer) : false) ? p1 : THROW_CCE();
    this.invoke_892(tmp, (!(p2 == null) ? typeof p2 === 'number' : false) ? p2 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__265.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__266($href, $attrs) {
    this._$href_1 = $href;
    this._$attrs_12 = $attrs;
  }
  _no_name_provided__266.prototype.invoke_586 = function ($this$TagElement) {
    href($this$TagElement, this._$href_1._v);
    Unit_getInstance();
    this._$attrs_12._v($this$TagElement);
  };
  _no_name_provided__266.prototype.invoke_1115 = function (p1) {
    this.invoke_586(p1 instanceof AttrsBuilder ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__266.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__267($src, $alt, $attrs) {
    this._$src_1 = $src;
    this._$alt_1 = $alt;
    this._$attrs_13 = $attrs;
  }
  _no_name_provided__267.prototype.invoke_652 = function ($this$TagElement) {
    alt(src($this$TagElement, this._$src_1), this._$alt_1._v);
    Unit_getInstance();
    this._$attrs_13._v($this$TagElement);
  };
  _no_name_provided__267.prototype.invoke_1115 = function (p1) {
    this.invoke_652(p1 instanceof AttrsBuilder ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__267.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided_$factory_206() {
    var i = new _no_name_provided__238();
    return function () {
      return i.invoke_523();
    };
  }
  function _no_name_provided_$factory_207($tmp0_ComposeNode$composable_0) {
    var i = new _no_name_provided__239($tmp0_ComposeNode$composable_0);
    return function () {
      return i.invoke_523();
    };
  }
  function _no_name_provided_$factory_208() {
    var i = new _no_name_provided__240();
    return function (p1, p2) {
      i.invoke_426(p1, p2);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_209($value, $$changed) {
    var i = new _no_name_provided__241($value, $$changed);
    return function (p1, p2) {
      i.invoke_892(p1, p2);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_210() {
    var i = new _no_name_provided__242();
    return function (p1) {
      i.invoke_586(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_211($href, $attrs) {
    var i = new _no_name_provided__243($href, $attrs);
    return function () {
      return i.invoke_523();
    };
  }
  function _no_name_provided_$factory_212($href, $attrs, $content, $$changed, $$default) {
    var i = new _no_name_provided__244($href, $attrs, $content, $$changed, $$default);
    return function (p1, p2) {
      i.invoke_892(p1, p2);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_213() {
    var i = new _no_name_provided__245();
    return function (p1) {
      i.invoke_890(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_214($attrs, $content, $$changed, $$default) {
    var i = new _no_name_provided__246($attrs, $content, $$changed, $$default);
    return function (p1, p2) {
      i.invoke_892(p1, p2);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_215() {
    var i = new _no_name_provided__247();
    return function (p1) {
      i.invoke_890(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_216($attrs, $content, $$changed, $$default) {
    var i = new _no_name_provided__248($attrs, $content, $$changed, $$default);
    return function (p1, p2) {
      i.invoke_892(p1, p2);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_217() {
    var i = new _no_name_provided__249();
    return function (p1) {
      i.invoke_632(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_218($attrs, $content, $$changed, $$default) {
    var i = new _no_name_provided__250($attrs, $content, $$changed, $$default);
    return function (p1, p2) {
      i.invoke_892(p1, p2);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_219() {
    var i = new _no_name_provided__251();
    return function (p1) {
      i.invoke_654(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_220($attrs, $content, $$changed, $$default) {
    var i = new _no_name_provided__252($attrs, $content, $$changed, $$default);
    return function (p1, p2) {
      i.invoke_892(p1, p2);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_221() {
    var i = new _no_name_provided__253();
    return function (p1) {
      i.invoke_517(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_222($attrs, $content, $$changed, $$default) {
    var i = new _no_name_provided__254($attrs, $content, $$changed, $$default);
    return function (p1, p2) {
      i.invoke_892(p1, p2);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_223() {
    var i = new _no_name_provided__255();
    return function (p1) {
      i.invoke_864(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_224($attrs, $content, $$changed, $$default) {
    var i = new _no_name_provided__256($attrs, $content, $$changed, $$default);
    return function (p1, p2) {
      i.invoke_892(p1, p2);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_225() {
    var i = new _no_name_provided__257();
    return function (p1) {
      i.invoke_890(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_226($attrs, $content, $$changed, $$default) {
    var i = new _no_name_provided__258($attrs, $content, $$changed, $$default);
    return function (p1, p2) {
      i.invoke_892(p1, p2);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_227() {
    var i = new _no_name_provided__259();
    return function (p1) {
      i.invoke_652(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_228($src, $alt, $attrs) {
    var i = new _no_name_provided__260($src, $alt, $attrs);
    return function () {
      return i.invoke_523();
    };
  }
  function _no_name_provided_$factory_229($src, $alt, $attrs, $content, $$changed, $$default) {
    var i = new _no_name_provided__261($src, $alt, $attrs, $content, $$changed, $$default);
    return function (p1, p2) {
      i.invoke_892(p1, p2);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_230() {
    var i = new _no_name_provided__262();
    return function (p1) {
      i.invoke_734(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_231($attrs, $content, $$changed, $$default) {
    var i = new _no_name_provided__263($attrs, $content, $$changed, $$default);
    return function (p1, p2) {
      i.invoke_892(p1, p2);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_232() {
    var i = new _no_name_provided__264();
    return function (p1) {
      i.invoke_862(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_233($attrs, $content, $$changed, $$default) {
    var i = new _no_name_provided__265($attrs, $content, $$changed, $$default);
    return function (p1, p2) {
      i.invoke_892(p1, p2);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_234($href, $attrs) {
    var i = new _no_name_provided__266($href, $attrs);
    return function (p1) {
      i.invoke_586(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_235($src, $alt, $attrs) {
    var i = new _no_name_provided__267($src, $alt, $attrs);
    return function (p1) {
      i.invoke_652(p1);
      return Unit_getInstance();
    };
  }
  function setProperty(styleMap, name, value_0) {
    (function () {
      var $externalVarargReceiverTmp = styleMap;
      return $externalVarargReceiverTmp.set.apply($externalVarargReceiverTmp, [name].concat([value_0]));
    }.call(this));
  }
  function setVariable(style, name, value_0) {
    style.setProperty('' + '--' + name, toString_2(value_0));
  }
  function setCSSRules(sheet, cssRules) {
    var tmp0_iterator_1 = cssRules.iterator_66();
    while (tmp0_iterator_1.hasNext_41()) {
      var element_2 = tmp0_iterator_1.next_46();
      addRule(sheet, element_2);
    }
  }
  function clearCSSRules(sheet) {
    var tmp0_unsafeCast_0_1 = sheet.cssRules;
    var tmp0_repeat_0 = tmp0_unsafeCast_0_1.length;
    var inductionVariable = 0;
    if (inductionVariable < tmp0_repeat_0)
      do {
        var index_2 = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        var tmp0_deleteRule_0_4 = 0;
        sheet.deleteRule(tmp0_deleteRule_0_4);
      }
       while (inductionVariable < tmp0_repeat_0);
  }
  function addRule(_this_, cssRuleDeclaration) {
    var cssRule = addRule_0(_this_, '' + cssRuleDeclaration._get_header__2() + ' {}');
    fillRule(cssRuleDeclaration, cssRule);
  }
  function addRule_0(_this_, cssRule) {
    var tmp0_unsafeCast_0_1 = _this_.cssRules;
    var cssRuleIndex = insertRule(_this_, cssRule, tmp0_unsafeCast_0_1.length);
    var tmp0_unsafeCast_0_1_0 = _this_.cssRules;
    var tmp0_get_0 = tmp0_unsafeCast_0_1_0;
    var tmp0_unsafeCast_0_1_1 = tmp0_get_0[cssRuleIndex];
    return tmp0_unsafeCast_0_1_1;
  }
  function fillRule(cssRuleDeclaration, cssRule) {
    var tmp0_subject = cssRuleDeclaration;
    if (tmp0_subject instanceof CSSStyleRuleDeclaration) {
      var cssStyleRule = cssRule;
      var tmp0_forEach_0 = cssRuleDeclaration._style_0._get_properties__2();
      var tmp0_iterator_1 = tmp0_forEach_0.iterator_66();
      while (tmp0_iterator_1.hasNext_41()) {
        var element_2 = tmp0_iterator_1.next_46();
        var name_4 = element_2.component1_4();
        var value_5 = element_2.component2_4();
        var tmp0_unsafeCast_0_1_6 = cssStyleRule.styleMap;
        setProperty(tmp0_unsafeCast_0_1_6, name_4, value_5);
      }
      var tmp1_forEach_0 = cssRuleDeclaration._style_0._get_variables__2();
      var tmp0_iterator_1_0 = tmp1_forEach_0.iterator_66();
      while (tmp0_iterator_1_0.hasNext_41()) {
        var element_2_0 = tmp0_iterator_1_0.next_46();
        var name_4_0 = element_2_0.component1_4();
        var value_5_0 = element_2_0.component2_4();
        setVariable(cssStyleRule.style, name_4_0, value_5_0);
      }
    } else {
      if (tmp0_subject instanceof CSSGroupingRuleDeclaration) {
        var cssGroupingRule = cssRule;
        var tmp2_forEach_0 = cssRuleDeclaration._rules;
        var tmp0_iterator_1_1 = tmp2_forEach_0.iterator_66();
        while (tmp0_iterator_1_1.hasNext_41()) {
          var element_2_1 = tmp0_iterator_1_1.next_46();
          addRule_1(cssGroupingRule, element_2_1);
        }
      } else {
      }
    }
  }
  function addRule_1(_this_, cssRuleDeclaration) {
    var cssRule = addRule_2(_this_, '' + cssRuleDeclaration._get_header__2() + ' {}');
    fillRule(cssRuleDeclaration, cssRule);
  }
  function addRule_2(_this_, cssRule) {
    var cssRuleIndex = _this_.insertRule(cssRule, _this_.cssRules.length);
    var tmp0_get_0 = _this_.cssRules;
    var tmp0_unsafeCast_0_1 = tmp0_get_0[cssRuleIndex];
    return tmp0_unsafeCast_0_1;
  }
  function WrappedMouseEvent(nativeEvent) {
    this._nativeEvent = nativeEvent;
    this._$stable_47 = 0;
  }
  WrappedMouseEvent.$metadata$ = {
    simpleName: 'WrappedMouseEvent',
    kind: 'class',
    interfaces: [GenericWrappedEvent]
  };
  function GenericWrappedEvent() {
  }
  GenericWrappedEvent.$metadata$ = {
    simpleName: 'GenericWrappedEvent',
    kind: 'interface',
    interfaces: []
  };
  function WrappedEvent() {
  }
  WrappedEvent.$metadata$ = {
    simpleName: 'WrappedEvent',
    kind: 'interface',
    interfaces: [GenericWrappedEvent]
  };
  function WrappedEventImpl(nativeEvent) {
    this._nativeEvent_0 = nativeEvent;
    this._$stable_48 = 0;
  }
  WrappedEventImpl.$metadata$ = {
    simpleName: 'WrappedEventImpl',
    kind: 'class',
    interfaces: [WrappedEvent]
  };
  function loadAce() {
    ace.config.setModuleUrl('ace/mode/clisp', require('file-loader?esModule=false!./mode-clisp.js'));
  }
  function Layout$composable(content, $composer, $changed) {
    var $composer_0 = $composer;
    $composer_0 = $composer_0.startRestartGroup_0(-1719915537);
    sourceInformation($composer_0, 'C(Layout$composable)');
    var $dirty = $changed;
    if (($changed & 14) === 0)
      $dirty = $dirty | ($composer_0.changed_0(content) ? 4 : 2);
    if (!(($dirty & 11 ^ 2) === 0) ? true : !$composer_0._get_skipping__0()) {
      var tmp = _no_name_provided_$factory_236();
      var tmp_0 = $composer_0;
      Div$composable(tmp, _no_name_provided_$factory_238(composableLambda(tmp_0, -819895225, true, _no_name_provided_$factory_237(content, $dirty))), $composer_0, 0, 0);
    } else
      $composer_0.skipToGroupEnd_2();
    var tmp0_safe_receiver = $composer_0.endRestartGroup_0();
    if (tmp0_safe_receiver === null)
      null;
    else {
      tmp0_safe_receiver.updateScope_0(_no_name_provided_$factory_239(content, $changed));
    }
  }
  function MainContentLayout$composable(content, $composer, $changed) {
    var $composer_0 = $composer;
    $composer_0 = $composer_0.startRestartGroup_0(1506841232);
    sourceInformation($composer_0, 'C(MainContentLayout$composable)');
    var $dirty = $changed;
    if (($changed & 14) === 0)
      $dirty = $dirty | ($composer_0.changed_0(content) ? 4 : 2);
    if (!(($dirty & 11 ^ 2) === 0) ? true : !$composer_0._get_skipping__0()) {
      var tmp = _no_name_provided_$factory_240();
      var tmp_0 = $composer_0;
      Main$composable(tmp, _no_name_provided_$factory_242(composableLambda(tmp_0, -819894987, true, _no_name_provided_$factory_241(content, $dirty))), $composer_0, 0, 0);
    } else
      $composer_0.skipToGroupEnd_2();
    var tmp0_safe_receiver = $composer_0.endRestartGroup_0();
    if (tmp0_safe_receiver === null)
      null;
    else {
      tmp0_safe_receiver.updateScope_0(_no_name_provided_$factory_243(content, $changed));
    }
  }
  function ContainerInSection$composable(sectionThemeStyleClass, content, $composer, $changed, $default) {
    var sectionThemeStyleClass_0 = {_v: sectionThemeStyleClass};
    var $composer_0 = $composer;
    $composer_0 = $composer_0.startRestartGroup_0(-18639475);
    sourceInformation($composer_0, 'C(ContainerInSection$composable)P(1)');
    var $dirty = $changed;
    if (!(($default & 1) === 0))
      $dirty = $dirty | 6;
    else if (($changed & 14) === 0)
      $dirty = $dirty | ($composer_0.changed_0(sectionThemeStyleClass_0._v) ? 4 : 2);
    if (!(($default & 2) === 0))
      $dirty = $dirty | 48;
    else if (($changed & 112) === 0)
      $dirty = $dirty | ($composer_0.changed_0(content) ? 32 : 16);
    if (!(($dirty & 91 ^ 18) === 0) ? true : !$composer_0._get_skipping__0()) {
      if (!(($default & 1) === 0))
        sectionThemeStyleClass_0._v = null;
      var tmp0_remember$composable_0 = sectionThemeStyleClass_0._v;
      var tmp1_remember$composable_0 = _no_name_provided_$factory_244(sectionThemeStyleClass_0);
      var tmp2_remember$composable_0 = $composer_0;
      var tmp3_remember$composable_0 = 14 & $dirty;
      var $composer_1 = tmp2_remember$composable_0;
      $composer_1.startReplaceableGroup_0(884179877);
      sourceInformation($composer_1, 'C(remember$composable)P(1):Composables.kt#9igjgp');
      var tmp1_cache_0_3 = $composer_1;
      var tmp2_cache_0_4 = $composer_1.changed_0(tmp0_remember$composable_0);
      var tmp0_let_0_5 = tmp1_cache_0_3.rememberedValue_0();
      var tmp;
      if (tmp2_cache_0_4 ? true : tmp0_let_0_5 === Companion_getInstance_25()._Empty) {
        var value_2_2_6 = tmp1_remember$composable_0();
        tmp1_cache_0_3.updateRememberedValue_0(value_2_2_6);
        tmp = value_2_2_6;
      } else {
        tmp = tmp0_let_0_5;
      }
      var tmp_0 = tmp;
      var tmp0_2 = (tmp_0 == null ? true : isObject(tmp_0)) ? tmp_0 : THROW_CCE();
      $composer_1.endReplaceableGroup_0();
      var tmp_1 = $composer_0;
      Section$composable(tmp0_2, _no_name_provided_$factory_246(composableLambda(tmp_1, -819895394, true, _no_name_provided_$factory_245(content, $dirty))), $composer_0, 0, 0);
    } else
      $composer_0.skipToGroupEnd_2();
    var tmp0_safe_receiver = $composer_0.endRestartGroup_0();
    if (tmp0_safe_receiver === null)
      null;
    else {
      tmp0_safe_receiver.updateScope_0(_no_name_provided_$factory_247(sectionThemeStyleClass_0, content, $changed, $default));
    }
  }
  function Layout$composable$lambda_0($content, $$changed, $composer, $force) {
    return Layout$composable($content, $composer, $$changed | 1);
  }
  function MainContentLayout$composable$lambda_1($content, $$changed, $composer, $force) {
    return MainContentLayout$composable($content, $composer, $$changed | 1);
  }
  function ContainerInSection$composable$lambda_2($sectionThemeStyleClass) {
    return _no_name_provided_$factory_248($sectionThemeStyleClass);
  }
  function ContainerInSection$composable$lambda_3($sectionThemeStyleClass, $content, $$changed, $$default, $composer, $force) {
    return ContainerInSection$composable($sectionThemeStyleClass._v, $content, $composer, $$changed | 1, $$default);
  }
  function _no_name_provided__268() {
  }
  _no_name_provided__268.prototype.invoke_850 = function ($this$style) {
    display($this$style, DisplayStyle_Flex_getInstance());
    flexDirection($this$style, FlexDirection_Column_getInstance());
    height($this$style, _get_percent_(100));
    margin($this$style, _get_px_(0));
    $this$style.property_4('box-sizing', Companion_getInstance_35().invoke_402('border-box'));
  };
  _no_name_provided__268.prototype.invoke_1115 = function (p1) {
    this.invoke_850((!(p1 == null) ? isInterface(p1, StyleBuilder) : false) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__268.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__269() {
  }
  _no_name_provided__269.prototype.invoke_890 = function ($this$Div) {
    $this$Div.style_0(_no_name_provided_$factory_249());
  };
  _no_name_provided__269.prototype.invoke_1115 = function (p1) {
    this.invoke_890(p1 instanceof AttrsBuilder ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__269.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__270($boundThis) {
    this._$boundThis_4 = $boundThis;
  }
  _no_name_provided__270.prototype.invoke_874 = function (p0, p1, p2) {
    return this._$boundThis_4.invoke_377(p0, p1, p2);
  };
  _no_name_provided__270.prototype.invoke_917 = function (p1, p2, p3) {
    var tmp = (!(p1 == null) ? isInterface(p1, ElementScope) : false) ? p1 : THROW_CCE();
    var tmp_0 = (!(p2 == null) ? isInterface(p2, Composer) : false) ? p2 : THROW_CCE();
    return this.invoke_874(tmp, tmp_0, (!(p3 == null) ? typeof p3 === 'number' : false) ? p3 : THROW_CCE());
  };
  _no_name_provided__270.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__271($content, $$dirty) {
    this._$content_12 = $content;
    this._$$dirty = $$dirty;
  }
  _no_name_provided__271.prototype.invoke_882 = function ($this$Div, $composer, $changed) {
    var $composer_0 = $composer;
    if (!(($changed & 81 ^ 16) === 0) ? true : !$composer_0._get_skipping__0()) {
      this._$content_12($composer_0, 14 & this._$$dirty);
    } else
      $composer_0.skipToGroupEnd_2();
  };
  _no_name_provided__271.prototype.invoke_917 = function (p1, p2, p3) {
    var tmp = (!(p1 == null) ? isInterface(p1, ElementScope) : false) ? p1 : THROW_CCE();
    var tmp_0 = (p2 == null ? true : isInterface(p2, Composer)) ? p2 : THROW_CCE();
    this.invoke_882(tmp, tmp_0, (!(p3 == null) ? typeof p3 === 'number' : false) ? p3 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__271.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__272($content, $$changed) {
    this._$content_13 = $content;
    this._$$changed_12 = $$changed;
  }
  _no_name_provided__272.prototype.invoke_892 = function (p0, p1) {
    return Layout$composable$lambda_0(this._$content_13, this._$$changed_12, p0, p1);
  };
  _no_name_provided__272.prototype.invoke_915 = function (p1, p2) {
    var tmp = (!(p1 == null) ? isInterface(p1, Composer) : false) ? p1 : THROW_CCE();
    this.invoke_892(tmp, (!(p2 == null) ? typeof p2 === 'number' : false) ? p2 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__272.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__273() {
  }
  _no_name_provided__273.prototype.invoke_850 = function ($this$style) {
    var tmp0_value_0 = 'auto';
    $this$style.property_4('flex', Companion_getInstance_35().invoke_402(tmp0_value_0));
    var tmp1_value_0 = 'border-box';
    $this$style.property_4('box-sizing', Companion_getInstance_35().invoke_402(tmp1_value_0));
  };
  _no_name_provided__273.prototype.invoke_1115 = function (p1) {
    this.invoke_850((!(p1 == null) ? isInterface(p1, StyleBuilder) : false) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__273.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__274() {
  }
  _no_name_provided__274.prototype.invoke_890 = function ($this$Main) {
    $this$Main.style_0(_no_name_provided_$factory_250());
  };
  _no_name_provided__274.prototype.invoke_1115 = function (p1) {
    this.invoke_890(p1 instanceof AttrsBuilder ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__274.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__275($boundThis) {
    this._$boundThis_5 = $boundThis;
  }
  _no_name_provided__275.prototype.invoke_728 = function (p0, p1, p2) {
    return this._$boundThis_5.invoke_377(p0, p1, p2);
  };
  _no_name_provided__275.prototype.invoke_917 = function (p1, p2, p3) {
    var tmp = (!(p1 == null) ? isInterface(p1, ElementScope) : false) ? p1 : THROW_CCE();
    var tmp_0 = (!(p2 == null) ? isInterface(p2, Composer) : false) ? p2 : THROW_CCE();
    return this.invoke_728(tmp, tmp_0, (!(p3 == null) ? typeof p3 === 'number' : false) ? p3 : THROW_CCE());
  };
  _no_name_provided__275.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__276($content, $$dirty) {
    this._$content_14 = $content;
    this._$$dirty_0 = $$dirty;
  }
  _no_name_provided__276.prototype.invoke_730 = function ($this$Main, $composer, $changed) {
    var $composer_0 = $composer;
    if (!(($changed & 81 ^ 16) === 0) ? true : !$composer_0._get_skipping__0()) {
      this._$content_14($composer_0, 14 & this._$$dirty_0);
    } else
      $composer_0.skipToGroupEnd_2();
  };
  _no_name_provided__276.prototype.invoke_917 = function (p1, p2, p3) {
    var tmp = (!(p1 == null) ? isInterface(p1, ElementScope) : false) ? p1 : THROW_CCE();
    var tmp_0 = (p2 == null ? true : isInterface(p2, Composer)) ? p2 : THROW_CCE();
    this.invoke_730(tmp, tmp_0, (!(p3 == null) ? typeof p3 === 'number' : false) ? p3 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__276.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__277($content, $$changed) {
    this._$content_15 = $content;
    this._$$changed_13 = $$changed;
  }
  _no_name_provided__277.prototype.invoke_892 = function (p0, p1) {
    return MainContentLayout$composable$lambda_1(this._$content_15, this._$$changed_13, p0, p1);
  };
  _no_name_provided__277.prototype.invoke_915 = function (p1, p2) {
    var tmp = (!(p1 == null) ? isInterface(p1, Composer) : false) ? p1 : THROW_CCE();
    this.invoke_892(tmp, (!(p2 == null) ? typeof p2 === 'number' : false) ? p2 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__277.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__278($sectionThemeStyleClass) {
    this._$sectionThemeStyleClass = $sectionThemeStyleClass;
  }
  _no_name_provided__278.prototype.invoke_523 = function () {
    return ContainerInSection$composable$lambda_2(this._$sectionThemeStyleClass);
  };
  _no_name_provided__278.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__279($boundThis) {
    this._$boundThis_6 = $boundThis;
  }
  _no_name_provided__279.prototype.invoke_728 = function (p0, p1, p2) {
    return this._$boundThis_6.invoke_377(p0, p1, p2);
  };
  _no_name_provided__279.prototype.invoke_917 = function (p1, p2, p3) {
    var tmp = (!(p1 == null) ? isInterface(p1, ElementScope) : false) ? p1 : THROW_CCE();
    var tmp_0 = (!(p2 == null) ? isInterface(p2, Composer) : false) ? p2 : THROW_CCE();
    return this.invoke_728(tmp, tmp_0, (!(p3 == null) ? typeof p3 === 'number' : false) ? p3 : THROW_CCE());
  };
  _no_name_provided__279.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__280() {
  }
  _no_name_provided__280.prototype.invoke_890 = function ($this$Div) {
    $this$Div.classes([WtContainer_getInstance()._get_wtContainer_(), WtOffsets_getInstance()._get_wtTopOffset96_()]);
  };
  _no_name_provided__280.prototype.invoke_1115 = function (p1) {
    this.invoke_890(p1 instanceof AttrsBuilder ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__280.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__281($boundThis) {
    this._$boundThis_7 = $boundThis;
  }
  _no_name_provided__281.prototype.invoke_874 = function (p0, p1, p2) {
    return this._$boundThis_7.invoke_377(p0, p1, p2);
  };
  _no_name_provided__281.prototype.invoke_917 = function (p1, p2, p3) {
    var tmp = (!(p1 == null) ? isInterface(p1, ElementScope) : false) ? p1 : THROW_CCE();
    var tmp_0 = (!(p2 == null) ? isInterface(p2, Composer) : false) ? p2 : THROW_CCE();
    return this.invoke_874(tmp, tmp_0, (!(p3 == null) ? typeof p3 === 'number' : false) ? p3 : THROW_CCE());
  };
  _no_name_provided__281.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__282($content, $$dirty) {
    this._$content_16 = $content;
    this._$$dirty_1 = $$dirty;
  }
  _no_name_provided__282.prototype.invoke_882 = function ($this$Div, $composer, $changed) {
    var $composer_0 = $composer;
    if (!(($changed & 81 ^ 16) === 0) ? true : !$composer_0._get_skipping__0()) {
      this._$content_16($composer_0, 14 & this._$$dirty_1 >> 3);
    } else
      $composer_0.skipToGroupEnd_2();
  };
  _no_name_provided__282.prototype.invoke_917 = function (p1, p2, p3) {
    var tmp = (!(p1 == null) ? isInterface(p1, ElementScope) : false) ? p1 : THROW_CCE();
    var tmp_0 = (p2 == null ? true : isInterface(p2, Composer)) ? p2 : THROW_CCE();
    this.invoke_882(tmp, tmp_0, (!(p3 == null) ? typeof p3 === 'number' : false) ? p3 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__282.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__283($content, $$dirty) {
    this._$content_17 = $content;
    this._$$dirty_2 = $$dirty;
  }
  _no_name_provided__283.prototype.invoke_730 = function ($this$Section, $composer, $changed) {
    var $composer_0 = $composer;
    if (!(($changed & 81 ^ 16) === 0) ? true : !$composer_0._get_skipping__0()) {
      var tmp = _no_name_provided_$factory_251();
      var tmp_0 = $composer_0;
      Div$composable(tmp, _no_name_provided_$factory_253(composableLambda(tmp_0, -819895297, true, _no_name_provided_$factory_252(this._$content_17, this._$$dirty_2))), $composer_0, 0, 0);
    } else
      $composer_0.skipToGroupEnd_2();
  };
  _no_name_provided__283.prototype.invoke_917 = function (p1, p2, p3) {
    var tmp = (!(p1 == null) ? isInterface(p1, ElementScope) : false) ? p1 : THROW_CCE();
    var tmp_0 = (p2 == null ? true : isInterface(p2, Composer)) ? p2 : THROW_CCE();
    this.invoke_730(tmp, tmp_0, (!(p3 == null) ? typeof p3 === 'number' : false) ? p3 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__283.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__284($sectionThemeStyleClass, $content, $$changed, $$default) {
    this._$sectionThemeStyleClass_0 = $sectionThemeStyleClass;
    this._$content_18 = $content;
    this._$$changed_14 = $$changed;
    this._$$default_10 = $$default;
  }
  _no_name_provided__284.prototype.invoke_892 = function (p0, p1) {
    return ContainerInSection$composable$lambda_3(this._$sectionThemeStyleClass_0, this._$content_18, this._$$changed_14, this._$$default_10, p0, p1);
  };
  _no_name_provided__284.prototype.invoke_915 = function (p1, p2) {
    var tmp = (!(p1 == null) ? isInterface(p1, Composer) : false) ? p1 : THROW_CCE();
    this.invoke_892(tmp, (!(p2 == null) ? typeof p2 === 'number' : false) ? p2 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__284.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__285($sectionThemeStyleClass) {
    this._$sectionThemeStyleClass_1 = $sectionThemeStyleClass;
  }
  _no_name_provided__285.prototype.invoke_632 = function ($this$Section) {
    if (!(this._$sectionThemeStyleClass_1._v == null)) {
      $this$Section.classes([WtSections_getInstance()._get_wtSection_(), this._$sectionThemeStyleClass_1._v]);
    } else {
      $this$Section.classes([WtSections_getInstance()._get_wtSection_()]);
    }
  };
  _no_name_provided__285.prototype.invoke_1115 = function (p1) {
    this.invoke_632(p1 instanceof AttrsBuilder ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__285.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided_$factory_236() {
    var i = new _no_name_provided__269();
    return function (p1) {
      i.invoke_890(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_237($content, $$dirty) {
    var i = new _no_name_provided__271($content, $$dirty);
    return function (p1, p2, p3) {
      i.invoke_882(p1, p2, p3);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_238($boundThis) {
    var i = new _no_name_provided__270($boundThis);
    return function (p1, p2, p3) {
      return i.invoke_874(p1, p2, p3);
    };
  }
  function _no_name_provided_$factory_239($content, $$changed) {
    var i = new _no_name_provided__272($content, $$changed);
    return function (p1, p2) {
      i.invoke_892(p1, p2);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_240() {
    var i = new _no_name_provided__274();
    return function (p1) {
      i.invoke_890(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_241($content, $$dirty) {
    var i = new _no_name_provided__276($content, $$dirty);
    return function (p1, p2, p3) {
      i.invoke_730(p1, p2, p3);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_242($boundThis) {
    var i = new _no_name_provided__275($boundThis);
    return function (p1, p2, p3) {
      return i.invoke_728(p1, p2, p3);
    };
  }
  function _no_name_provided_$factory_243($content, $$changed) {
    var i = new _no_name_provided__277($content, $$changed);
    return function (p1, p2) {
      i.invoke_892(p1, p2);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_244($sectionThemeStyleClass) {
    var i = new _no_name_provided__278($sectionThemeStyleClass);
    return function () {
      return i.invoke_523();
    };
  }
  function _no_name_provided_$factory_245($content, $$dirty) {
    var i = new _no_name_provided__283($content, $$dirty);
    return function (p1, p2, p3) {
      i.invoke_730(p1, p2, p3);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_246($boundThis) {
    var i = new _no_name_provided__279($boundThis);
    return function (p1, p2, p3) {
      return i.invoke_728(p1, p2, p3);
    };
  }
  function _no_name_provided_$factory_247($sectionThemeStyleClass, $content, $$changed, $$default) {
    var i = new _no_name_provided__284($sectionThemeStyleClass, $content, $$changed, $$default);
    return function (p1, p2) {
      i.invoke_892(p1, p2);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_248($sectionThemeStyleClass) {
    var i = new _no_name_provided__285($sectionThemeStyleClass);
    return function (p1) {
      i.invoke_632(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_249() {
    var i = new _no_name_provided__268();
    return function (p1) {
      i.invoke_850(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_250() {
    var i = new _no_name_provided__273();
    return function (p1) {
      i.invoke_850(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_251() {
    var i = new _no_name_provided__280();
    return function (p1) {
      i.invoke_890(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_252($content, $$dirty) {
    var i = new _no_name_provided__282($content, $$dirty);
    return function (p1, p2, p3) {
      i.invoke_882(p1, p2, p3);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_253($boundThis) {
    var i = new _no_name_provided__281($boundThis);
    return function (p1, p2, p3) {
      return i.invoke_874(p1, p2, p3);
    };
  }
  function EditorBlock$composable(id, $composer, $changed, $default) {
    var id_0 = {_v: id};
    var $composer_0 = $composer;
    $composer_0 = $composer_0.startRestartGroup_0(-690503517);
    sourceInformation($composer_0, 'C(EditorBlock$composable)');
    var $dirty = $changed;
    if (!(($default & 1) === 0))
      $dirty = $dirty | 6;
    else if (($changed & 14) === 0)
      $dirty = $dirty | ($composer_0.changed_0(id_0._v) ? 4 : 2);
    if (!(($dirty & 11 ^ 2) === 0) ? true : !$composer_0._get_skipping__0()) {
      if (!(($default & 1) === 0))
        id_0._v = 'editor';
      var tmp = _no_name_provided_$factory_254();
      var tmp_0 = $composer_0;
      Pre$composable(tmp, _no_name_provided_$factory_256(composableLambda(tmp_0, -819894302, true, _no_name_provided_$factory_255(id_0, $dirty))), $composer_0, 0, 0);
    } else
      $composer_0.skipToGroupEnd_2();
    var tmp0_safe_receiver = $composer_0.endRestartGroup_0();
    if (tmp0_safe_receiver === null)
      null;
    else {
      tmp0_safe_receiver.updateScope_0(_no_name_provided_$factory_257(id_0, $changed, $default));
    }
  }
  function EditorBlock$composable$lambda_0($id, $$changed, $$default, $composer, $force) {
    return EditorBlock$composable($id._v, $composer, $$changed | 1, $$default);
  }
  function _no_name_provided__286() {
  }
  _no_name_provided__286.prototype.invoke_850 = function ($this$style) {
    var tmp0_value_0 = 'auto';
    $this$style.property_4('overflow', Companion_getInstance_35().invoke_402(tmp0_value_0));
    height($this$style, _get_px_(300));
    display($this$style, DisplayStyle_Flex_getInstance());
  };
  _no_name_provided__286.prototype.invoke_1115 = function (p1) {
    this.invoke_850((!(p1 == null) ? isInterface(p1, StyleBuilder) : false) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__286.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__287() {
  }
  _no_name_provided__287.prototype.invoke_517 = function ($this$Pre) {
    $this$Pre.style_0(_no_name_provided_$factory_258());
  };
  _no_name_provided__287.prototype.invoke_1115 = function (p1) {
    this.invoke_517(p1 instanceof AttrsBuilder ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__287.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__288($boundThis) {
    this._$boundThis_8 = $boundThis;
  }
  _no_name_provided__288.prototype.invoke_519 = function (p0, p1, p2) {
    return this._$boundThis_8.invoke_377(p0, p1, p2);
  };
  _no_name_provided__288.prototype.invoke_917 = function (p1, p2, p3) {
    var tmp = (!(p1 == null) ? isInterface(p1, ElementScope) : false) ? p1 : THROW_CCE();
    var tmp_0 = (!(p2 == null) ? isInterface(p2, Composer) : false) ? p2 : THROW_CCE();
    return this.invoke_519(tmp, tmp_0, (!(p3 == null) ? typeof p3 === 'number' : false) ? p3 : THROW_CCE());
  };
  _no_name_provided__288.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__289() {
  }
  _no_name_provided__289.prototype.invoke_850 = function ($this$style) {
    var tmp0_value_0 = "'JetBrains Mono', monospace";
    $this$style.property_4('font-family', Companion_getInstance_35().invoke_402(tmp0_value_0));
    var tmp1_value_0 = 4;
    $this$style.property_4('tab-size', Companion_getInstance_35().invoke_395(tmp1_value_0));
    var tmp2_value_0 = '100%';
    $this$style.property_4('width', Companion_getInstance_35().invoke_402(tmp2_value_0));
    var tmp3_value_0 = '100%';
    $this$style.property_4('height', Companion_getInstance_35().invoke_402(tmp3_value_0));
    backgroundColor($this$style, Companion_getInstance_37().invoke_402('transparent'));
  };
  _no_name_provided__289.prototype.invoke_1115 = function (p1) {
    this.invoke_850((!(p1 == null) ? isInterface(p1, StyleBuilder) : false) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__289.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function invoke$lambda_0($id) {
    return _no_name_provided_$factory_260($id);
  }
  function _no_name_provided__290($id) {
    this._$id = $id;
  }
  _no_name_provided__290.prototype.invoke_523 = function () {
    return invoke$lambda_0(this._$id);
  };
  _no_name_provided__290.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__291($id) {
    this._$id_0 = $id;
  }
  _no_name_provided__291.prototype.invoke_890 = function ($this$Div) {
    $this$Div.id_0(this._$id_0._v);
    Unit_getInstance();
    $this$Div.style_0(_no_name_provided_$factory_261());
  };
  _no_name_provided__291.prototype.invoke_1115 = function (p1) {
    this.invoke_890(p1 instanceof AttrsBuilder ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__291.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__292($id, $$dirty) {
    this._$id_1 = $id;
    this._$$dirty_3 = $$dirty;
  }
  _no_name_provided__292.prototype.invoke_526 = function ($this$Pre, $composer, $changed) {
    var $composer_0 = $composer;
    if (!(($changed & 81 ^ 16) === 0) ? true : !$composer_0._get_skipping__0()) {
      var tmp0_remember$composable_0 = this._$id_1._v;
      var tmp1_remember$composable_0 = _no_name_provided_$factory_259(this._$id_1);
      var tmp2_remember$composable_0 = $composer_0;
      var tmp3_remember$composable_0 = 14 & this._$$dirty_3;
      var $composer_1 = tmp2_remember$composable_0;
      $composer_1.startReplaceableGroup_0(884179877);
      sourceInformation($composer_1, 'C(remember$composable)P(1):Composables.kt#9igjgp');
      var tmp1_cache_0_3 = $composer_1;
      var tmp2_cache_0_4 = $composer_1.changed_0(tmp0_remember$composable_0);
      var tmp0_let_0_5 = tmp1_cache_0_3.rememberedValue_0();
      var tmp;
      if (tmp2_cache_0_4 ? true : tmp0_let_0_5 === Companion_getInstance_25()._Empty) {
        var value_2_2_6 = tmp1_remember$composable_0();
        tmp1_cache_0_3.updateRememberedValue_0(value_2_2_6);
        tmp = value_2_2_6;
      } else {
        tmp = tmp0_let_0_5;
      }
      var tmp_0 = tmp;
      var tmp0_2 = (tmp_0 == null ? true : isObject(tmp_0)) ? tmp_0 : THROW_CCE();
      $composer_1.endReplaceableGroup_0();
      Div$composable(tmp0_2, Unit_getInstance(), $composer_0, 0, 2);
    } else
      $composer_0.skipToGroupEnd_2();
  };
  _no_name_provided__292.prototype.invoke_917 = function (p1, p2, p3) {
    var tmp = (!(p1 == null) ? isInterface(p1, ElementScope) : false) ? p1 : THROW_CCE();
    var tmp_0 = (p2 == null ? true : isInterface(p2, Composer)) ? p2 : THROW_CCE();
    this.invoke_526(tmp, tmp_0, (!(p3 == null) ? typeof p3 === 'number' : false) ? p3 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__292.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__293($id, $$changed, $$default) {
    this._$id_2 = $id;
    this._$$changed_15 = $$changed;
    this._$$default_11 = $$default;
  }
  _no_name_provided__293.prototype.invoke_892 = function (p0, p1) {
    return EditorBlock$composable$lambda_0(this._$id_2, this._$$changed_15, this._$$default_11, p0, p1);
  };
  _no_name_provided__293.prototype.invoke_915 = function (p1, p2) {
    var tmp = (!(p1 == null) ? isInterface(p1, Composer) : false) ? p1 : THROW_CCE();
    this.invoke_892(tmp, (!(p2 == null) ? typeof p2 === 'number' : false) ? p2 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__293.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided_$factory_254() {
    var i = new _no_name_provided__287();
    return function (p1) {
      i.invoke_517(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_255($id, $$dirty) {
    var i = new _no_name_provided__292($id, $$dirty);
    return function (p1, p2, p3) {
      i.invoke_526(p1, p2, p3);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_256($boundThis) {
    var i = new _no_name_provided__288($boundThis);
    return function (p1, p2, p3) {
      return i.invoke_519(p1, p2, p3);
    };
  }
  function _no_name_provided_$factory_257($id, $$changed, $$default) {
    var i = new _no_name_provided__293($id, $$changed, $$default);
    return function (p1, p2) {
      i.invoke_892(p1, p2);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_258() {
    var i = new _no_name_provided__286();
    return function (p1) {
      i.invoke_850(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_259($id) {
    var i = new _no_name_provided__290($id);
    return function () {
      return i.invoke_523();
    };
  }
  function _no_name_provided_$factory_260($id) {
    var i = new _no_name_provided__291($id);
    return function (p1) {
      i.invoke_890(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_261() {
    var i = new _no_name_provided__289();
    return function (p1) {
      i.invoke_850(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided__294() {
  }
  _no_name_provided__294.prototype.invoke_890 = function ($this$Div) {
    $this$Div.classes([WtContainer_getInstance()._get_wtContainer_()]);
  };
  _no_name_provided__294.prototype.invoke_1115 = function (p1) {
    this.invoke_890(p1 instanceof AttrsBuilder ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__294.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__295() {
  }
  _no_name_provided__295.prototype.invoke_850 = function ($this$style) {
    var tmp0_value_0 = '24px 0';
    $this$style.property_4('padding', Companion_getInstance_35().invoke_402(tmp0_value_0));
  };
  _no_name_provided__295.prototype.invoke_1115 = function (p1) {
    this.invoke_850((!(p1 == null) ? isInterface(p1, StyleBuilder) : false) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__295.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__296() {
  }
  _no_name_provided__296.prototype.invoke_632 = function ($this$Section) {
    $this$Section.classes([WtSections_getInstance()._get_wtSectionBgGrayDark_()]);
    $this$Section.style_0(_no_name_provided_$factory_278());
  };
  _no_name_provided__296.prototype.invoke_1115 = function (p1) {
    this.invoke_632(p1 instanceof AttrsBuilder ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__296.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__297() {
  }
  _no_name_provided__297.prototype.invoke_864 = function ($this$Span) {
    $this$Span.classes([WtTexts_getInstance()._get_wtText3_(), WtTexts_getInstance()._get_wtTextPale_()]);
  };
  _no_name_provided__297.prototype.invoke_1115 = function (p1) {
    this.invoke_864(p1 instanceof AttrsBuilder ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__297.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__298() {
  }
  _no_name_provided__298.prototype.invoke_864 = function ($this$Span) {
    $this$Span.classes([WtTexts_getInstance()._get_wtText3_(), WtTexts_getInstance()._get_wtTextPale_()]);
  };
  _no_name_provided__298.prototype.invoke_1115 = function (p1) {
    this.invoke_864(p1 instanceof AttrsBuilder ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__298.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__299($boundThis) {
    this._$boundThis_9 = $boundThis;
  }
  _no_name_provided__299.prototype.invoke_874 = function (p0, p1, p2) {
    return this._$boundThis_9.invoke_377(p0, p1, p2);
  };
  _no_name_provided__299.prototype.invoke_917 = function (p1, p2, p3) {
    var tmp = (!(p1 == null) ? isInterface(p1, ElementScope) : false) ? p1 : THROW_CCE();
    var tmp_0 = (!(p2 == null) ? isInterface(p2, Composer) : false) ? p2 : THROW_CCE();
    return this.invoke_874(tmp, tmp_0, (!(p3 == null) ? typeof p3 === 'number' : false) ? p3 : THROW_CCE());
  };
  _no_name_provided__299.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__300() {
  }
  _no_name_provided__300.prototype.invoke_882 = function ($this$Div, $composer, $changed) {
    var $composer_0 = $composer;
    if (!(($changed & 81 ^ 16) === 0) ? true : !$composer_0._get_skipping__0()) {
      CopyrightInFooter$composable($composer_0, 0);
    } else
      $composer_0.skipToGroupEnd_2();
  };
  _no_name_provided__300.prototype.invoke_917 = function (p1, p2, p3) {
    var tmp = (!(p1 == null) ? isInterface(p1, ElementScope) : false) ? p1 : THROW_CCE();
    var tmp_0 = (p2 == null ? true : isInterface(p2, Composer)) ? p2 : THROW_CCE();
    this.invoke_882(tmp, tmp_0, (!(p3 == null) ? typeof p3 === 'number' : false) ? p3 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__300.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__301($boundThis) {
    this._$boundThis_10 = $boundThis;
  }
  _no_name_provided__301.prototype.invoke_728 = function (p0, p1, p2) {
    return this._$boundThis_10.invoke_377(p0, p1, p2);
  };
  _no_name_provided__301.prototype.invoke_917 = function (p1, p2, p3) {
    var tmp = (!(p1 == null) ? isInterface(p1, ElementScope) : false) ? p1 : THROW_CCE();
    var tmp_0 = (!(p2 == null) ? isInterface(p2, Composer) : false) ? p2 : THROW_CCE();
    return this.invoke_728(tmp, tmp_0, (!(p3 == null) ? typeof p3 === 'number' : false) ? p3 : THROW_CCE());
  };
  _no_name_provided__301.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__302() {
  }
  _no_name_provided__302.prototype.invoke_730 = function ($this$Section, $composer, $changed) {
    var $composer_0 = $composer;
    if (!(($changed & 81 ^ 16) === 0) ? true : !$composer_0._get_skipping__0()) {
      Div$composable(_no_name_provided_$factory_276(), ComposableSingletons$FooterKt_getInstance()._lambda_1_0, $composer_0, 0, 0);
    } else
      $composer_0.skipToGroupEnd_2();
  };
  _no_name_provided__302.prototype.invoke_917 = function (p1, p2, p3) {
    var tmp = (!(p1 == null) ? isInterface(p1, ElementScope) : false) ? p1 : THROW_CCE();
    var tmp_0 = (p2 == null ? true : isInterface(p2, Composer)) ? p2 : THROW_CCE();
    this.invoke_730(tmp, tmp_0, (!(p3 == null) ? typeof p3 === 'number' : false) ? p3 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__302.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__303($boundThis) {
    this._$boundThis_11 = $boundThis;
  }
  _no_name_provided__303.prototype.invoke_728 = function (p0, p1, p2) {
    return this._$boundThis_11.invoke_377(p0, p1, p2);
  };
  _no_name_provided__303.prototype.invoke_917 = function (p1, p2, p3) {
    var tmp = (!(p1 == null) ? isInterface(p1, ElementScope) : false) ? p1 : THROW_CCE();
    var tmp_0 = (!(p2 == null) ? isInterface(p2, Composer) : false) ? p2 : THROW_CCE();
    return this.invoke_728(tmp, tmp_0, (!(p3 == null) ? typeof p3 === 'number' : false) ? p3 : THROW_CCE());
  };
  _no_name_provided__303.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__304() {
  }
  _no_name_provided__304.prototype.invoke_730 = function ($this$Footer, $composer, $changed) {
    var $composer_0 = $composer;
    if (!(($changed & 81 ^ 16) === 0) ? true : !$composer_0._get_skipping__0()) {
      Section$composable(_no_name_provided_$factory_277(), ComposableSingletons$FooterKt_getInstance()._lambda_2_0, $composer_0, 0, 0);
    } else
      $composer_0.skipToGroupEnd_2();
  };
  _no_name_provided__304.prototype.invoke_917 = function (p1, p2, p3) {
    var tmp = (!(p1 == null) ? isInterface(p1, ElementScope) : false) ? p1 : THROW_CCE();
    var tmp_0 = (p2 == null ? true : isInterface(p2, Composer)) ? p2 : THROW_CCE();
    this.invoke_730(tmp, tmp_0, (!(p3 == null) ? typeof p3 === 'number' : false) ? p3 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__304.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__305($boundThis) {
    this._$boundThis_12 = $boundThis;
  }
  _no_name_provided__305.prototype.invoke_836 = function (p0, p1, p2) {
    return this._$boundThis_12.invoke_377(p0, p1, p2);
  };
  _no_name_provided__305.prototype.invoke_917 = function (p1, p2, p3) {
    var tmp = (!(p1 == null) ? isInterface(p1, ElementScope) : false) ? p1 : THROW_CCE();
    var tmp_0 = (!(p2 == null) ? isInterface(p2, Composer) : false) ? p2 : THROW_CCE();
    return this.invoke_836(tmp, tmp_0, (!(p3 == null) ? typeof p3 === 'number' : false) ? p3 : THROW_CCE());
  };
  _no_name_provided__305.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__306() {
  }
  _no_name_provided__306.prototype.invoke_838 = function ($this$Span, $composer, $changed) {
    var $composer_0 = $composer;
    if (!(($changed & 81 ^ 16) === 0) ? true : !$composer_0._get_skipping__0()) {
      Text$composable('Copyright \xA9 2016-2021  Surreal Development LLC', $composer_0, 6);
    } else
      $composer_0.skipToGroupEnd_2();
  };
  _no_name_provided__306.prototype.invoke_917 = function (p1, p2, p3) {
    var tmp = (!(p1 == null) ? isInterface(p1, ElementScope) : false) ? p1 : THROW_CCE();
    var tmp_0 = (p2 == null ? true : isInterface(p2, Composer)) ? p2 : THROW_CCE();
    this.invoke_838(tmp, tmp_0, (!(p3 == null) ? typeof p3 === 'number' : false) ? p3 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__306.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__307($boundThis) {
    this._$boundThis_13 = $boundThis;
  }
  _no_name_provided__307.prototype.invoke_836 = function (p0, p1, p2) {
    return this._$boundThis_13.invoke_377(p0, p1, p2);
  };
  _no_name_provided__307.prototype.invoke_917 = function (p1, p2, p3) {
    var tmp = (!(p1 == null) ? isInterface(p1, ElementScope) : false) ? p1 : THROW_CCE();
    var tmp_0 = (!(p2 == null) ? isInterface(p2, Composer) : false) ? p2 : THROW_CCE();
    return this.invoke_836(tmp, tmp_0, (!(p3 == null) ? typeof p3 === 'number' : false) ? p3 : THROW_CCE());
  };
  _no_name_provided__307.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__308() {
  }
  _no_name_provided__308.prototype.invoke_838 = function ($this$Span, $composer, $changed) {
    var $composer_0 = $composer;
    if (!(($changed & 81 ^ 16) === 0) ? true : !$composer_0._get_skipping__0()) {
      Text$composable('Developed with Jetpack Compose for Web', $composer_0, 6);
    } else
      $composer_0.skipToGroupEnd_2();
  };
  _no_name_provided__308.prototype.invoke_917 = function (p1, p2, p3) {
    var tmp = (!(p1 == null) ? isInterface(p1, ElementScope) : false) ? p1 : THROW_CCE();
    var tmp_0 = (p2 == null ? true : isInterface(p2, Composer)) ? p2 : THROW_CCE();
    this.invoke_838(tmp, tmp_0, (!(p3 == null) ? typeof p3 === 'number' : false) ? p3 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__308.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__309($boundThis) {
    this._$boundThis_14 = $boundThis;
  }
  _no_name_provided__309.prototype.invoke_874 = function (p0, p1, p2) {
    return this._$boundThis_14.invoke_377(p0, p1, p2);
  };
  _no_name_provided__309.prototype.invoke_917 = function (p1, p2, p3) {
    var tmp = (!(p1 == null) ? isInterface(p1, ElementScope) : false) ? p1 : THROW_CCE();
    var tmp_0 = (!(p2 == null) ? isInterface(p2, Composer) : false) ? p2 : THROW_CCE();
    return this.invoke_874(tmp, tmp_0, (!(p3 == null) ? typeof p3 === 'number' : false) ? p3 : THROW_CCE());
  };
  _no_name_provided__309.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__310() {
  }
  _no_name_provided__310.prototype.invoke_882 = function ($this$Div, $composer, $changed) {
    var $composer_0 = $composer;
    if (!(($changed & 81 ^ 16) === 0) ? true : !$composer_0._get_skipping__0()) {
      Span$composable(_no_name_provided_$factory_279(), ComposableSingletons$FooterKt_getInstance()._lambda_4, $composer_0, 0, 0);
      Span$composable(_no_name_provided_$factory_280(), ComposableSingletons$FooterKt_getInstance()._lambda_5, $composer_0, 0, 0);
    } else
      $composer_0.skipToGroupEnd_2();
  };
  _no_name_provided__310.prototype.invoke_917 = function (p1, p2, p3) {
    var tmp = (!(p1 == null) ? isInterface(p1, ElementScope) : false) ? p1 : THROW_CCE();
    var tmp_0 = (p2 == null ? true : isInterface(p2, Composer)) ? p2 : THROW_CCE();
    this.invoke_882(tmp, tmp_0, (!(p3 == null) ? typeof p3 === 'number' : false) ? p3 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__310.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__311($boundThis) {
    this._$boundThis_15 = $boundThis;
  }
  _no_name_provided__311.prototype.invoke_708 = function (p0, p1, p2) {
    return this._$boundThis_15.invoke_377(p0, p1, p2);
  };
  _no_name_provided__311.prototype.invoke_917 = function (p1, p2, p3) {
    var tmp = (!(p1 == null) ? isInterface(p1, ElementScope) : false) ? p1 : THROW_CCE();
    var tmp_0 = (!(p2 == null) ? isInterface(p2, Composer) : false) ? p2 : THROW_CCE();
    return this.invoke_708(tmp, tmp_0, (!(p3 == null) ? typeof p3 === 'number' : false) ? p3 : THROW_CCE());
  };
  _no_name_provided__311.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__312() {
  }
  _no_name_provided__312.prototype.invoke_710 = function ($this$Img, $composer, $changed) {
    var $composer_0 = $composer;
    if (!(($changed & 81 ^ 16) === 0) ? true : !$composer_0._get_skipping__0()) {
      Unit_getInstance();
    } else
      $composer_0.skipToGroupEnd_2();
  };
  _no_name_provided__312.prototype.invoke_917 = function (p1, p2, p3) {
    var tmp = (!(p1 == null) ? isInterface(p1, ElementScope) : false) ? p1 : THROW_CCE();
    var tmp_0 = (p2 == null ? true : isInterface(p2, Composer)) ? p2 : THROW_CCE();
    this.invoke_710(tmp, tmp_0, (!(p3 == null) ? typeof p3 === 'number' : false) ? p3 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__312.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function ComposableSingletons$FooterKt() {
    ComposableSingletons$FooterKt_instance = this;
    var tmp = this;
    tmp._lambda_1_0 = _no_name_provided_$factory_263(composableLambdaInstance(-985533449, false, _no_name_provided_$factory_262()));
    var tmp_0 = this;
    tmp_0._lambda_2_0 = _no_name_provided_$factory_265(composableLambdaInstance(-985533633, false, _no_name_provided_$factory_264()));
    var tmp_1 = this;
    tmp_1._lambda_3 = _no_name_provided_$factory_267(composableLambdaInstance(-985534319, false, _no_name_provided_$factory_266()));
    var tmp_2 = this;
    tmp_2._lambda_4 = _no_name_provided_$factory_269(composableLambdaInstance(-985532959, false, _no_name_provided_$factory_268()));
    var tmp_3 = this;
    tmp_3._lambda_5 = _no_name_provided_$factory_271(composableLambdaInstance(-985533434, false, _no_name_provided_$factory_270()));
    var tmp_4 = this;
    tmp_4._lambda_6 = _no_name_provided_$factory_273(composableLambdaInstance(-985533170, false, _no_name_provided_$factory_272()));
    var tmp_5 = this;
    tmp_5._lambda_7 = _no_name_provided_$factory_275(composableLambdaInstance(-985532633, false, _no_name_provided_$factory_274()));
  }
  ComposableSingletons$FooterKt.$metadata$ = {
    simpleName: 'ComposableSingletons$FooterKt',
    kind: 'object',
    interfaces: []
  };
  var ComposableSingletons$FooterKt_instance;
  function ComposableSingletons$FooterKt_getInstance() {
    if (ComposableSingletons$FooterKt_instance == null)
      new ComposableSingletons$FooterKt();
    return ComposableSingletons$FooterKt_instance;
  }
  function PageFooter$composable($composer, $changed) {
    var $composer_0 = $composer;
    $composer_0 = $composer_0.startRestartGroup_0(-1614955382);
    sourceInformation($composer_0, 'C(PageFooter$composable)');
    if (!($changed === 0) ? true : !$composer_0._get_skipping__0()) {
      Footer$composable(_no_name_provided_$factory_281(), ComposableSingletons$FooterKt_getInstance()._lambda_3, $composer_0, 0, 0);
    } else
      $composer_0.skipToGroupEnd_2();
    var tmp0_safe_receiver = $composer_0.endRestartGroup_0();
    if (tmp0_safe_receiver === null)
      null;
    else {
      tmp0_safe_receiver.updateScope_0(_no_name_provided_$factory_282($changed));
    }
  }
  function CopyrightInFooter$composable($composer, $changed) {
    var $composer_0 = $composer;
    $composer_0 = $composer_0.startRestartGroup_0(-1629796620);
    if (!($changed === 0) ? true : !$composer_0._get_skipping__0()) {
      Div$composable(_no_name_provided_$factory_283(), ComposableSingletons$FooterKt_getInstance()._lambda_6, $composer_0, 0, 0);
    } else
      $composer_0.skipToGroupEnd_2();
    var tmp0_safe_receiver = $composer_0.endRestartGroup_0();
    if (tmp0_safe_receiver === null)
      null;
    else {
      tmp0_safe_receiver.updateScope_0(_no_name_provided_$factory_284($changed));
    }
  }
  function PageFooter$composable$lambda_0($$changed, $composer, $force) {
    return PageFooter$composable($composer, $$changed | 1);
  }
  function CopyrightInFooter$composable$lambda_1($$changed, $composer, $force) {
    return CopyrightInFooter$composable($composer, $$changed | 1);
  }
  function _no_name_provided__313() {
  }
  _no_name_provided__313.prototype.invoke_850 = function ($this$style) {
    flexShrink($this$style, 0);
    var tmp0_value_0 = 'border-box';
    $this$style.property_4('box-sizing', Companion_getInstance_35().invoke_402(tmp0_value_0));
  };
  _no_name_provided__313.prototype.invoke_1115 = function (p1) {
    this.invoke_850((!(p1 == null) ? isInterface(p1, StyleBuilder) : false) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__313.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__314() {
  }
  _no_name_provided__314.prototype.invoke_890 = function ($this$Footer) {
    $this$Footer.style_0(_no_name_provided_$factory_285());
  };
  _no_name_provided__314.prototype.invoke_1115 = function (p1) {
    this.invoke_890(p1 instanceof AttrsBuilder ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__314.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__315($$changed) {
    this._$$changed_16 = $$changed;
  }
  _no_name_provided__315.prototype.invoke_892 = function (p0, p1) {
    return PageFooter$composable$lambda_0(this._$$changed_16, p0, p1);
  };
  _no_name_provided__315.prototype.invoke_915 = function (p1, p2) {
    var tmp = (!(p1 == null) ? isInterface(p1, Composer) : false) ? p1 : THROW_CCE();
    this.invoke_892(tmp, (!(p2 == null) ? typeof p2 === 'number' : false) ? p2 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__315.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__316() {
  }
  _no_name_provided__316.prototype.invoke_850 = function ($this$style) {
    justifyContent($this$style, JustifyContent_SpaceEvenly_getInstance());
    flexWrap($this$style, FlexWrap_Wrap_getInstance());
    var tmp0_value_0 = '0px 12px';
    $this$style.property_4('padding', Companion_getInstance_35().invoke_402(tmp0_value_0));
  };
  _no_name_provided__316.prototype.invoke_1115 = function (p1) {
    this.invoke_850((!(p1 == null) ? isInterface(p1, StyleBuilder) : false) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__316.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__317() {
  }
  _no_name_provided__317.prototype.invoke_890 = function ($this$Div) {
    $this$Div.classes([WtRows_getInstance()._get_wtRow_(), WtRows_getInstance()._get_wtRowSizeM_(), WtRows_getInstance()._get_wtRowSmAlignItemsCenter_(), WtOffsets_getInstance()._get_wtTopOffset48_()]);
    $this$Div.style_0(_no_name_provided_$factory_286());
  };
  _no_name_provided__317.prototype.invoke_1115 = function (p1) {
    this.invoke_890(p1 instanceof AttrsBuilder ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__317.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__318($$changed) {
    this._$$changed_17 = $$changed;
  }
  _no_name_provided__318.prototype.invoke_892 = function (p0, p1) {
    return CopyrightInFooter$composable$lambda_1(this._$$changed_17, p0, p1);
  };
  _no_name_provided__318.prototype.invoke_915 = function (p1, p2) {
    var tmp = (!(p1 == null) ? isInterface(p1, Composer) : false) ? p1 : THROW_CCE();
    this.invoke_892(tmp, (!(p2 == null) ? typeof p2 === 'number' : false) ? p2 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__318.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided_$factory_262() {
    var i = new _no_name_provided__300();
    return function (p1, p2, p3) {
      i.invoke_882(p1, p2, p3);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_263($boundThis) {
    var i = new _no_name_provided__299($boundThis);
    return function (p1, p2, p3) {
      return i.invoke_874(p1, p2, p3);
    };
  }
  function _no_name_provided_$factory_264() {
    var i = new _no_name_provided__302();
    return function (p1, p2, p3) {
      i.invoke_730(p1, p2, p3);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_265($boundThis) {
    var i = new _no_name_provided__301($boundThis);
    return function (p1, p2, p3) {
      return i.invoke_728(p1, p2, p3);
    };
  }
  function _no_name_provided_$factory_266() {
    var i = new _no_name_provided__304();
    return function (p1, p2, p3) {
      i.invoke_730(p1, p2, p3);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_267($boundThis) {
    var i = new _no_name_provided__303($boundThis);
    return function (p1, p2, p3) {
      return i.invoke_728(p1, p2, p3);
    };
  }
  function _no_name_provided_$factory_268() {
    var i = new _no_name_provided__306();
    return function (p1, p2, p3) {
      i.invoke_838(p1, p2, p3);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_269($boundThis) {
    var i = new _no_name_provided__305($boundThis);
    return function (p1, p2, p3) {
      return i.invoke_836(p1, p2, p3);
    };
  }
  function _no_name_provided_$factory_270() {
    var i = new _no_name_provided__308();
    return function (p1, p2, p3) {
      i.invoke_838(p1, p2, p3);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_271($boundThis) {
    var i = new _no_name_provided__307($boundThis);
    return function (p1, p2, p3) {
      return i.invoke_836(p1, p2, p3);
    };
  }
  function _no_name_provided_$factory_272() {
    var i = new _no_name_provided__310();
    return function (p1, p2, p3) {
      i.invoke_882(p1, p2, p3);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_273($boundThis) {
    var i = new _no_name_provided__309($boundThis);
    return function (p1, p2, p3) {
      return i.invoke_874(p1, p2, p3);
    };
  }
  function _no_name_provided_$factory_274() {
    var i = new _no_name_provided__312();
    return function (p1, p2, p3) {
      i.invoke_710(p1, p2, p3);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_275($boundThis) {
    var i = new _no_name_provided__311($boundThis);
    return function (p1, p2, p3) {
      return i.invoke_708(p1, p2, p3);
    };
  }
  function _no_name_provided_$factory_276() {
    var i = new _no_name_provided__294();
    return function (p1) {
      i.invoke_890(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_277() {
    var i = new _no_name_provided__296();
    return function (p1) {
      i.invoke_632(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_278() {
    var i = new _no_name_provided__295();
    return function (p1) {
      i.invoke_850(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_279() {
    var i = new _no_name_provided__297();
    return function (p1) {
      i.invoke_864(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_280() {
    var i = new _no_name_provided__298();
    return function (p1) {
      i.invoke_864(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_281() {
    var i = new _no_name_provided__314();
    return function (p1) {
      i.invoke_890(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_282($$changed) {
    var i = new _no_name_provided__315($$changed);
    return function (p1, p2) {
      i.invoke_892(p1, p2);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_283() {
    var i = new _no_name_provided__317();
    return function (p1) {
      i.invoke_890(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_284($$changed) {
    var i = new _no_name_provided__318($$changed);
    return function (p1, p2) {
      i.invoke_892(p1, p2);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_285() {
    var i = new _no_name_provided__313();
    return function (p1) {
      i.invoke_850(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_286() {
    var i = new _no_name_provided__316();
    return function (p1) {
      i.invoke_850(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided__319() {
  }
  _no_name_provided__319.prototype.invoke_890 = function ($this$Div) {
    $this$Div.classes([WtRows_getInstance()._get_wtRow_(), WtRows_getInstance()._get_wtRowSizeM_()]);
  };
  _no_name_provided__319.prototype.invoke_1115 = function (p1) {
    this.invoke_890(p1 instanceof AttrsBuilder ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__319.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__320() {
  }
  _no_name_provided__320.prototype.invoke_890 = function ($this$Div) {
    $this$Div.classes([WtContainer_getInstance()._get_wtContainer_()]);
  };
  _no_name_provided__320.prototype.invoke_1115 = function (p1) {
    this.invoke_890(p1 instanceof AttrsBuilder ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__320.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__321() {
  }
  _no_name_provided__321.prototype.invoke_890 = function ($this$Div) {
    $this$Div.classes(['surrealdev-logo', '_logo-surrealdev', '_size-3']);
  };
  _no_name_provided__321.prototype.invoke_1115 = function (p1) {
    this.invoke_890(p1 instanceof AttrsBuilder ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__321.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__322() {
  }
  _no_name_provided__322.prototype.invoke_586 = function ($this$A) {
    target($this$A, Blank_getInstance());
    Unit_getInstance();
  };
  _no_name_provided__322.prototype.invoke_1115 = function (p1) {
    this.invoke_586(p1 instanceof AttrsBuilder ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__322.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__323() {
  }
  _no_name_provided__323.prototype.invoke_850 = function ($this$style) {
    var tmp0_value_0 = _get_px_(8);
    $this$style.property_4('padding-left', Companion_getInstance_35().invoke_396(tmp0_value_0));
    var tmp1_value_0 = _get_px_(8);
    $this$style.property_4('padding-right', Companion_getInstance_35().invoke_396(tmp1_value_0));
  };
  _no_name_provided__323.prototype.invoke_1115 = function (p1) {
    this.invoke_850((!(p1 == null) ? isInterface(p1, StyleBuilder) : false) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__323.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__324() {
  }
  _no_name_provided__324.prototype.invoke_652 = function ($this$Img) {
    $this$Img.style_0(_no_name_provided_$factory_310());
  };
  _no_name_provided__324.prototype.invoke_1115 = function (p1) {
    this.invoke_652(p1 instanceof AttrsBuilder ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__324.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__325() {
  }
  _no_name_provided__325.prototype.invoke_860 = function (it) {
    window.alert('Oops! This feature is yet to be implemented');
  };
  _no_name_provided__325.prototype.invoke_1115 = function (p1) {
    this.invoke_860(p1 instanceof WrappedMouseEvent ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__325.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__326() {
  }
  _no_name_provided__326.prototype.invoke_862 = function ($this$Button) {
    $this$Button.classes([WtTexts_getInstance()._get_wtButton_(), WtTexts_getInstance()._get_wtLangButton_()]);
    $this$Button.onClick$default_0(null, _no_name_provided_$factory_312(), 1, null);
  };
  _no_name_provided__326.prototype.invoke_1115 = function (p1) {
    this.invoke_862(p1 instanceof AttrsBuilder ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__326.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__327($boundThis) {
    this._$boundThis_16 = $boundThis;
  }
  _no_name_provided__327.prototype.invoke_874 = function (p0, p1, p2) {
    return this._$boundThis_16.invoke_377(p0, p1, p2);
  };
  _no_name_provided__327.prototype.invoke_917 = function (p1, p2, p3) {
    var tmp = (!(p1 == null) ? isInterface(p1, ElementScope) : false) ? p1 : THROW_CCE();
    var tmp_0 = (!(p2 == null) ? isInterface(p2, Composer) : false) ? p2 : THROW_CCE();
    return this.invoke_874(tmp, tmp_0, (!(p3 == null) ? typeof p3 === 'number' : false) ? p3 : THROW_CCE());
  };
  _no_name_provided__327.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__328() {
  }
  _no_name_provided__328.prototype.invoke_882 = function ($this$Div, $composer, $changed) {
    var $composer_0 = $composer;
    if (!(($changed & 81 ^ 16) === 0) ? true : !$composer_0._get_skipping__0()) {
      Logo$composable($composer_0, 0);
    } else
      $composer_0.skipToGroupEnd_2();
  };
  _no_name_provided__328.prototype.invoke_917 = function (p1, p2, p3) {
    var tmp = (!(p1 == null) ? isInterface(p1, ElementScope) : false) ? p1 : THROW_CCE();
    var tmp_0 = (p2 == null ? true : isInterface(p2, Composer)) ? p2 : THROW_CCE();
    this.invoke_882(tmp, tmp_0, (!(p3 == null) ? typeof p3 === 'number' : false) ? p3 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__328.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__329($boundThis) {
    this._$boundThis_17 = $boundThis;
  }
  _no_name_provided__329.prototype.invoke_874 = function (p0, p1, p2) {
    return this._$boundThis_17.invoke_377(p0, p1, p2);
  };
  _no_name_provided__329.prototype.invoke_917 = function (p1, p2, p3) {
    var tmp = (!(p1 == null) ? isInterface(p1, ElementScope) : false) ? p1 : THROW_CCE();
    var tmp_0 = (!(p2 == null) ? isInterface(p2, Composer) : false) ? p2 : THROW_CCE();
    return this.invoke_874(tmp, tmp_0, (!(p3 == null) ? typeof p3 === 'number' : false) ? p3 : THROW_CCE());
  };
  _no_name_provided__329.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__330() {
  }
  _no_name_provided__330.prototype.invoke_882 = function ($this$Div, $composer, $changed) {
    var $composer_0 = $composer;
    if (!(($changed & 81 ^ 16) === 0) ? true : !$composer_0._get_skipping__0()) {
      Div$composable(_no_name_provided_$factory_305(), ComposableSingletons$HeaderKt_getInstance()._lambda_1_1, $composer_0, 0, 0);
    } else
      $composer_0.skipToGroupEnd_2();
  };
  _no_name_provided__330.prototype.invoke_917 = function (p1, p2, p3) {
    var tmp = (!(p1 == null) ? isInterface(p1, ElementScope) : false) ? p1 : THROW_CCE();
    var tmp_0 = (p2 == null ? true : isInterface(p2, Composer)) ? p2 : THROW_CCE();
    this.invoke_882(tmp, tmp_0, (!(p3 == null) ? typeof p3 === 'number' : false) ? p3 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__330.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__331($boundThis) {
    this._$boundThis_18 = $boundThis;
  }
  _no_name_provided__331.prototype.invoke_728 = function (p0, p1, p2) {
    return this._$boundThis_18.invoke_377(p0, p1, p2);
  };
  _no_name_provided__331.prototype.invoke_917 = function (p1, p2, p3) {
    var tmp = (!(p1 == null) ? isInterface(p1, ElementScope) : false) ? p1 : THROW_CCE();
    var tmp_0 = (!(p2 == null) ? isInterface(p2, Composer) : false) ? p2 : THROW_CCE();
    return this.invoke_728(tmp, tmp_0, (!(p3 == null) ? typeof p3 === 'number' : false) ? p3 : THROW_CCE());
  };
  _no_name_provided__331.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__332() {
  }
  _no_name_provided__332.prototype.invoke_730 = function ($this$Section, $composer, $changed) {
    var $composer_0 = $composer;
    if (!(($changed & 81 ^ 16) === 0) ? true : !$composer_0._get_skipping__0()) {
      Div$composable(_no_name_provided_$factory_306(), ComposableSingletons$HeaderKt_getInstance()._lambda_2_1, $composer_0, 0, 0);
    } else
      $composer_0.skipToGroupEnd_2();
  };
  _no_name_provided__332.prototype.invoke_917 = function (p1, p2, p3) {
    var tmp = (!(p1 == null) ? isInterface(p1, ElementScope) : false) ? p1 : THROW_CCE();
    var tmp_0 = (p2 == null ? true : isInterface(p2, Composer)) ? p2 : THROW_CCE();
    this.invoke_730(tmp, tmp_0, (!(p3 == null) ? typeof p3 === 'number' : false) ? p3 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__332.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__333($boundThis) {
    this._$boundThis_19 = $boundThis;
  }
  _no_name_provided__333.prototype.invoke_874 = function (p0, p1, p2) {
    return this._$boundThis_19.invoke_377(p0, p1, p2);
  };
  _no_name_provided__333.prototype.invoke_917 = function (p1, p2, p3) {
    var tmp = (!(p1 == null) ? isInterface(p1, ElementScope) : false) ? p1 : THROW_CCE();
    var tmp_0 = (!(p2 == null) ? isInterface(p2, Composer) : false) ? p2 : THROW_CCE();
    return this.invoke_874(tmp, tmp_0, (!(p3 == null) ? typeof p3 === 'number' : false) ? p3 : THROW_CCE());
  };
  _no_name_provided__333.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__334() {
  }
  _no_name_provided__334.prototype.invoke_882 = function ($this$Div, $composer, $changed) {
    var $composer_0 = $composer;
    if (!(($changed & 81 ^ 16) === 0) ? true : !$composer_0._get_skipping__0()) {
      Unit_getInstance();
    } else
      $composer_0.skipToGroupEnd_2();
  };
  _no_name_provided__334.prototype.invoke_917 = function (p1, p2, p3) {
    var tmp = (!(p1 == null) ? isInterface(p1, ElementScope) : false) ? p1 : THROW_CCE();
    var tmp_0 = (p2 == null ? true : isInterface(p2, Composer)) ? p2 : THROW_CCE();
    this.invoke_882(tmp, tmp_0, (!(p3 == null) ? typeof p3 === 'number' : false) ? p3 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__334.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__335($boundThis) {
    this._$boundThis_20 = $boundThis;
  }
  _no_name_provided__335.prototype.invoke_612 = function (p0, p1, p2) {
    return this._$boundThis_20.invoke_377(p0, p1, p2);
  };
  _no_name_provided__335.prototype.invoke_917 = function (p1, p2, p3) {
    var tmp = (!(p1 == null) ? isInterface(p1, ElementScope) : false) ? p1 : THROW_CCE();
    var tmp_0 = (!(p2 == null) ? isInterface(p2, Composer) : false) ? p2 : THROW_CCE();
    return this.invoke_612(tmp, tmp_0, (!(p3 == null) ? typeof p3 === 'number' : false) ? p3 : THROW_CCE());
  };
  _no_name_provided__335.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__336() {
  }
  _no_name_provided__336.prototype.invoke_614 = function ($this$A, $composer, $changed) {
    var $composer_0 = $composer;
    if (!(($changed & 81 ^ 16) === 0) ? true : !$composer_0._get_skipping__0()) {
      Div$composable(_no_name_provided_$factory_307(), ComposableSingletons$HeaderKt_getInstance()._lambda_4_0, $composer_0, 0, 0);
    } else
      $composer_0.skipToGroupEnd_2();
  };
  _no_name_provided__336.prototype.invoke_917 = function (p1, p2, p3) {
    var tmp = (!(p1 == null) ? isInterface(p1, ElementScope) : false) ? p1 : THROW_CCE();
    var tmp_0 = (p2 == null ? true : isInterface(p2, Composer)) ? p2 : THROW_CCE();
    this.invoke_614(tmp, tmp_0, (!(p3 == null) ? typeof p3 === 'number' : false) ? p3 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__336.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__337($boundThis) {
    this._$boundThis_21 = $boundThis;
  }
  _no_name_provided__337.prototype.invoke_874 = function (p0, p1, p2) {
    return this._$boundThis_21.invoke_377(p0, p1, p2);
  };
  _no_name_provided__337.prototype.invoke_917 = function (p1, p2, p3) {
    var tmp = (!(p1 == null) ? isInterface(p1, ElementScope) : false) ? p1 : THROW_CCE();
    var tmp_0 = (!(p2 == null) ? isInterface(p2, Composer) : false) ? p2 : THROW_CCE();
    return this.invoke_874(tmp, tmp_0, (!(p3 == null) ? typeof p3 === 'number' : false) ? p3 : THROW_CCE());
  };
  _no_name_provided__337.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__338() {
  }
  _no_name_provided__338.prototype.invoke_882 = function ($this$Div, $composer, $changed) {
    var $composer_0 = $composer;
    if (!(($changed & 81 ^ 16) === 0) ? true : !$composer_0._get_skipping__0()) {
      A$composable('https://www.surrealdev.com/', _no_name_provided_$factory_308(), ComposableSingletons$HeaderKt_getInstance()._lambda_5_0, $composer_0, 6, 0);
    } else
      $composer_0.skipToGroupEnd_2();
  };
  _no_name_provided__338.prototype.invoke_917 = function (p1, p2, p3) {
    var tmp = (!(p1 == null) ? isInterface(p1, ElementScope) : false) ? p1 : THROW_CCE();
    var tmp_0 = (p2 == null ? true : isInterface(p2, Composer)) ? p2 : THROW_CCE();
    this.invoke_882(tmp, tmp_0, (!(p3 == null) ? typeof p3 === 'number' : false) ? p3 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__338.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__339($boundThis) {
    this._$boundThis_22 = $boundThis;
  }
  _no_name_provided__339.prototype.invoke_708 = function (p0, p1, p2) {
    return this._$boundThis_22.invoke_377(p0, p1, p2);
  };
  _no_name_provided__339.prototype.invoke_917 = function (p1, p2, p3) {
    var tmp = (!(p1 == null) ? isInterface(p1, ElementScope) : false) ? p1 : THROW_CCE();
    var tmp_0 = (!(p2 == null) ? isInterface(p2, Composer) : false) ? p2 : THROW_CCE();
    return this.invoke_708(tmp, tmp_0, (!(p3 == null) ? typeof p3 === 'number' : false) ? p3 : THROW_CCE());
  };
  _no_name_provided__339.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__340() {
  }
  _no_name_provided__340.prototype.invoke_710 = function ($this$Img, $composer, $changed) {
    var $composer_0 = $composer;
    if (!(($changed & 81 ^ 16) === 0) ? true : !$composer_0._get_skipping__0()) {
      Unit_getInstance();
    } else
      $composer_0.skipToGroupEnd_2();
  };
  _no_name_provided__340.prototype.invoke_917 = function (p1, p2, p3) {
    var tmp = (!(p1 == null) ? isInterface(p1, ElementScope) : false) ? p1 : THROW_CCE();
    var tmp_0 = (p2 == null ? true : isInterface(p2, Composer)) ? p2 : THROW_CCE();
    this.invoke_710(tmp, tmp_0, (!(p3 == null) ? typeof p3 === 'number' : false) ? p3 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__340.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__341($boundThis) {
    this._$boundThis_23 = $boundThis;
  }
  _no_name_provided__341.prototype.invoke_700 = function (p0, p1, p2) {
    return this._$boundThis_23.invoke_377(p0, p1, p2);
  };
  _no_name_provided__341.prototype.invoke_917 = function (p1, p2, p3) {
    var tmp = (!(p1 == null) ? isInterface(p1, ElementScope) : false) ? p1 : THROW_CCE();
    var tmp_0 = (!(p2 == null) ? isInterface(p2, Composer) : false) ? p2 : THROW_CCE();
    return this.invoke_700(tmp, tmp_0, (!(p3 == null) ? typeof p3 === 'number' : false) ? p3 : THROW_CCE());
  };
  _no_name_provided__341.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__342() {
  }
  _no_name_provided__342.prototype.invoke_702 = function ($this$Button, $composer, $changed) {
    var $composer_0 = $composer;
    if (!(($changed & 81 ^ 16) === 0) ? true : !$composer_0._get_skipping__0()) {
      Img$composable('ic_lang.svg', Unit_getInstance(), _no_name_provided_$factory_309(), ComposableSingletons$HeaderKt_getInstance()._lambda_7_0, $composer_0, 6, 2);
      Text$composable('English', $composer_0, 6);
    } else
      $composer_0.skipToGroupEnd_2();
  };
  _no_name_provided__342.prototype.invoke_917 = function (p1, p2, p3) {
    var tmp = (!(p1 == null) ? isInterface(p1, ElementScope) : false) ? p1 : THROW_CCE();
    var tmp_0 = (p2 == null ? true : isInterface(p2, Composer)) ? p2 : THROW_CCE();
    this.invoke_702(tmp, tmp_0, (!(p3 == null) ? typeof p3 === 'number' : false) ? p3 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__342.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__343($boundThis) {
    this._$boundThis_24 = $boundThis;
  }
  _no_name_provided__343.prototype.invoke_874 = function (p0, p1, p2) {
    return this._$boundThis_24.invoke_377(p0, p1, p2);
  };
  _no_name_provided__343.prototype.invoke_917 = function (p1, p2, p3) {
    var tmp = (!(p1 == null) ? isInterface(p1, ElementScope) : false) ? p1 : THROW_CCE();
    var tmp_0 = (!(p2 == null) ? isInterface(p2, Composer) : false) ? p2 : THROW_CCE();
    return this.invoke_874(tmp, tmp_0, (!(p3 == null) ? typeof p3 === 'number' : false) ? p3 : THROW_CCE());
  };
  _no_name_provided__343.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__344() {
  }
  _no_name_provided__344.prototype.invoke_882 = function ($this$Div, $composer, $changed) {
    var $composer_0 = $composer;
    if (!(($changed & 81 ^ 16) === 0) ? true : !$composer_0._get_skipping__0()) {
      Button$composable(_no_name_provided_$factory_311(), ComposableSingletons$HeaderKt_getInstance()._lambda_8, $composer_0, 0, 0);
    } else
      $composer_0.skipToGroupEnd_2();
  };
  _no_name_provided__344.prototype.invoke_917 = function (p1, p2, p3) {
    var tmp = (!(p1 == null) ? isInterface(p1, ElementScope) : false) ? p1 : THROW_CCE();
    var tmp_0 = (p2 == null ? true : isInterface(p2, Composer)) ? p2 : THROW_CCE();
    this.invoke_882(tmp, tmp_0, (!(p3 == null) ? typeof p3 === 'number' : false) ? p3 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__344.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function ComposableSingletons$HeaderKt() {
    ComposableSingletons$HeaderKt_instance = this;
    var tmp = this;
    tmp._lambda_1_1 = _no_name_provided_$factory_288(composableLambdaInstance(-985533692, false, _no_name_provided_$factory_287()));
    var tmp_0 = this;
    tmp_0._lambda_2_1 = _no_name_provided_$factory_290(composableLambdaInstance(-985534296, false, _no_name_provided_$factory_289()));
    var tmp_1 = this;
    tmp_1._lambda_3_0 = _no_name_provided_$factory_292(composableLambdaInstance(-985534220, false, _no_name_provided_$factory_291()));
    var tmp_2 = this;
    tmp_2._lambda_4_0 = _no_name_provided_$factory_294(composableLambdaInstance(-985533115, false, _no_name_provided_$factory_293()));
    var tmp_3 = this;
    tmp_3._lambda_5_0 = _no_name_provided_$factory_296(composableLambdaInstance(-985533742, false, _no_name_provided_$factory_295()));
    var tmp_4 = this;
    tmp_4._lambda_6_0 = _no_name_provided_$factory_298(composableLambdaInstance(-985533845, false, _no_name_provided_$factory_297()));
    var tmp_5 = this;
    tmp_5._lambda_7_0 = _no_name_provided_$factory_300(composableLambdaInstance(-985533251, false, _no_name_provided_$factory_299()));
    var tmp_6 = this;
    tmp_6._lambda_8 = _no_name_provided_$factory_302(composableLambdaInstance(-985533429, false, _no_name_provided_$factory_301()));
    var tmp_7 = this;
    tmp_7._lambda_9 = _no_name_provided_$factory_304(composableLambdaInstance(-985532975, false, _no_name_provided_$factory_303()));
  }
  ComposableSingletons$HeaderKt.$metadata$ = {
    simpleName: 'ComposableSingletons$HeaderKt',
    kind: 'object',
    interfaces: []
  };
  var ComposableSingletons$HeaderKt_instance;
  function ComposableSingletons$HeaderKt_getInstance() {
    if (ComposableSingletons$HeaderKt_instance == null)
      new ComposableSingletons$HeaderKt();
    return ComposableSingletons$HeaderKt_instance;
  }
  function Header$composable($composer, $changed) {
    var $composer_0 = $composer;
    $composer_0 = $composer_0.startRestartGroup_0(2089832105);
    sourceInformation($composer_0, 'C(Header$composable)');
    if (!($changed === 0) ? true : !$composer_0._get_skipping__0()) {
      Section$composable(_no_name_provided_$factory_313(), ComposableSingletons$HeaderKt_getInstance()._lambda_3_0, $composer_0, 0, 0);
    } else
      $composer_0.skipToGroupEnd_2();
    var tmp0_safe_receiver = $composer_0.endRestartGroup_0();
    if (tmp0_safe_receiver === null)
      null;
    else {
      tmp0_safe_receiver.updateScope_0(_no_name_provided_$factory_314($changed));
    }
  }
  function Logo$composable($composer, $changed) {
    var $composer_0 = $composer;
    $composer_0 = $composer_0.startRestartGroup_0(2015322826);
    if (!($changed === 0) ? true : !$composer_0._get_skipping__0()) {
      Div$composable(_no_name_provided_$factory_315(), ComposableSingletons$HeaderKt_getInstance()._lambda_6_0, $composer_0, 0, 0);
    } else
      $composer_0.skipToGroupEnd_2();
    var tmp0_safe_receiver = $composer_0.endRestartGroup_0();
    if (tmp0_safe_receiver === null)
      null;
    else {
      tmp0_safe_receiver.updateScope_0(_no_name_provided_$factory_316($changed));
    }
  }
  function Header$composable$lambda_0($$changed, $composer, $force) {
    return Header$composable($composer, $$changed | 1);
  }
  function Logo$composable$lambda_2($$changed, $composer, $force) {
    return Logo$composable($composer, $$changed | 1);
  }
  function _no_name_provided__345() {
  }
  _no_name_provided__345.prototype.invoke_632 = function ($this$Section) {
    $this$Section.classes([WtSections_getInstance()._get_wtSectionBgGrayDark_()]);
  };
  _no_name_provided__345.prototype.invoke_1115 = function (p1) {
    this.invoke_632(p1 instanceof AttrsBuilder ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__345.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__346($$changed) {
    this._$$changed_18 = $$changed;
  }
  _no_name_provided__346.prototype.invoke_892 = function (p0, p1) {
    return Header$composable$lambda_0(this._$$changed_18, p0, p1);
  };
  _no_name_provided__346.prototype.invoke_915 = function (p1, p2) {
    var tmp = (!(p1 == null) ? isInterface(p1, Composer) : false) ? p1 : THROW_CCE();
    this.invoke_892(tmp, (!(p2 == null) ? typeof p2 === 'number' : false) ? p2 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__346.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__347() {
  }
  _no_name_provided__347.prototype.invoke_890 = function ($this$Div) {
    $this$Div.classes([WtCols_getInstance()._get_wtColInline_()]);
  };
  _no_name_provided__347.prototype.invoke_1115 = function (p1) {
    this.invoke_890(p1 instanceof AttrsBuilder ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__347.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__348($$changed) {
    this._$$changed_19 = $$changed;
  }
  _no_name_provided__348.prototype.invoke_892 = function (p0, p1) {
    return Logo$composable$lambda_2(this._$$changed_19, p0, p1);
  };
  _no_name_provided__348.prototype.invoke_915 = function (p1, p2) {
    var tmp = (!(p1 == null) ? isInterface(p1, Composer) : false) ? p1 : THROW_CCE();
    this.invoke_892(tmp, (!(p2 == null) ? typeof p2 === 'number' : false) ? p2 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__348.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided_$factory_287() {
    var i = new _no_name_provided__328();
    return function (p1, p2, p3) {
      i.invoke_882(p1, p2, p3);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_288($boundThis) {
    var i = new _no_name_provided__327($boundThis);
    return function (p1, p2, p3) {
      return i.invoke_874(p1, p2, p3);
    };
  }
  function _no_name_provided_$factory_289() {
    var i = new _no_name_provided__330();
    return function (p1, p2, p3) {
      i.invoke_882(p1, p2, p3);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_290($boundThis) {
    var i = new _no_name_provided__329($boundThis);
    return function (p1, p2, p3) {
      return i.invoke_874(p1, p2, p3);
    };
  }
  function _no_name_provided_$factory_291() {
    var i = new _no_name_provided__332();
    return function (p1, p2, p3) {
      i.invoke_730(p1, p2, p3);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_292($boundThis) {
    var i = new _no_name_provided__331($boundThis);
    return function (p1, p2, p3) {
      return i.invoke_728(p1, p2, p3);
    };
  }
  function _no_name_provided_$factory_293() {
    var i = new _no_name_provided__334();
    return function (p1, p2, p3) {
      i.invoke_882(p1, p2, p3);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_294($boundThis) {
    var i = new _no_name_provided__333($boundThis);
    return function (p1, p2, p3) {
      return i.invoke_874(p1, p2, p3);
    };
  }
  function _no_name_provided_$factory_295() {
    var i = new _no_name_provided__336();
    return function (p1, p2, p3) {
      i.invoke_614(p1, p2, p3);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_296($boundThis) {
    var i = new _no_name_provided__335($boundThis);
    return function (p1, p2, p3) {
      return i.invoke_612(p1, p2, p3);
    };
  }
  function _no_name_provided_$factory_297() {
    var i = new _no_name_provided__338();
    return function (p1, p2, p3) {
      i.invoke_882(p1, p2, p3);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_298($boundThis) {
    var i = new _no_name_provided__337($boundThis);
    return function (p1, p2, p3) {
      return i.invoke_874(p1, p2, p3);
    };
  }
  function _no_name_provided_$factory_299() {
    var i = new _no_name_provided__340();
    return function (p1, p2, p3) {
      i.invoke_710(p1, p2, p3);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_300($boundThis) {
    var i = new _no_name_provided__339($boundThis);
    return function (p1, p2, p3) {
      return i.invoke_708(p1, p2, p3);
    };
  }
  function _no_name_provided_$factory_301() {
    var i = new _no_name_provided__342();
    return function (p1, p2, p3) {
      i.invoke_702(p1, p2, p3);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_302($boundThis) {
    var i = new _no_name_provided__341($boundThis);
    return function (p1, p2, p3) {
      return i.invoke_700(p1, p2, p3);
    };
  }
  function _no_name_provided_$factory_303() {
    var i = new _no_name_provided__344();
    return function (p1, p2, p3) {
      i.invoke_882(p1, p2, p3);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_304($boundThis) {
    var i = new _no_name_provided__343($boundThis);
    return function (p1, p2, p3) {
      return i.invoke_874(p1, p2, p3);
    };
  }
  function _no_name_provided_$factory_305() {
    var i = new _no_name_provided__319();
    return function (p1) {
      i.invoke_890(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_306() {
    var i = new _no_name_provided__320();
    return function (p1) {
      i.invoke_890(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_307() {
    var i = new _no_name_provided__321();
    return function (p1) {
      i.invoke_890(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_308() {
    var i = new _no_name_provided__322();
    return function (p1) {
      i.invoke_586(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_309() {
    var i = new _no_name_provided__324();
    return function (p1) {
      i.invoke_652(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_310() {
    var i = new _no_name_provided__323();
    return function (p1) {
      i.invoke_850(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_311() {
    var i = new _no_name_provided__326();
    return function (p1) {
      i.invoke_862(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_312() {
    var i = new _no_name_provided__325();
    return function (p1) {
      i.invoke_860(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_313() {
    var i = new _no_name_provided__345();
    return function (p1) {
      i.invoke_632(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_314($$changed) {
    var i = new _no_name_provided__346($$changed);
    return function (p1, p2) {
      i.invoke_892(p1, p2);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_315() {
    var i = new _no_name_provided__347();
    return function (p1) {
      i.invoke_890(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_316($$changed) {
    var i = new _no_name_provided__348($$changed);
    return function (p1, p2) {
      i.invoke_892(p1, p2);
      return Unit_getInstance();
    };
  }
  var CodeRunState_STABNDY_instance;
  var CodeRunState_COMPILING_instance;
  var CodeRunState_RUNNING_instance;
  var CodeRunState_entriesInitialized;
  function CodeRunState_initEntries() {
    if (CodeRunState_entriesInitialized)
      return Unit_getInstance();
    CodeRunState_entriesInitialized = true;
    CodeRunState_STABNDY_instance = new CodeRunState('STABNDY', 0);
    CodeRunState_COMPILING_instance = new CodeRunState('COMPILING', 1);
    CodeRunState_RUNNING_instance = new CodeRunState('RUNNING', 2);
  }
  function CodeRunState(name, ordinal) {
    Enum.call(this, name, ordinal);
  }
  CodeRunState.$metadata$ = {
    simpleName: 'CodeRunState',
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__349() {
  }
  _no_name_provided__349.prototype.invoke_864 = function ($this$Span) {
    $this$Span.classes([AppStylesheet_getInstance()._get_composeTitleTag_()]);
  };
  _no_name_provided__349.prototype.invoke_1115 = function (p1) {
    this.invoke_864(p1 instanceof AttrsBuilder ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__349.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__350() {
  }
  _no_name_provided__350.prototype.invoke_850 = function ($this$style) {
    display($this$style, DisplayStyle_InlineBlock_getInstance());
    var tmp0_value_0 = 'nowrap';
    $this$style.property_4('white-space', Companion_getInstance_35().invoke_402(tmp0_value_0));
  };
  _no_name_provided__350.prototype.invoke_1115 = function (p1) {
    this.invoke_850((!(p1 == null) ? isInterface(p1, StyleBuilder) : false) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__350.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__351() {
  }
  _no_name_provided__351.prototype.invoke_864 = function ($this$Span) {
    $this$Span.classes([WtTexts_getInstance()._get_wtHero_()]);
    $this$Span.style_0(_no_name_provided_$factory_355());
  };
  _no_name_provided__351.prototype.invoke_1115 = function (p1) {
    this.invoke_864(p1 instanceof AttrsBuilder ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__351.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__352() {
  }
  _no_name_provided__352.prototype.invoke_850 = function ($this$style) {
    display($this$style, DisplayStyle_InlineBlock_getInstance());
    var tmp0_value_0 = 'nowrap';
    $this$style.property_4('white-space', Companion_getInstance_35().invoke_402(tmp0_value_0));
  };
  _no_name_provided__352.prototype.invoke_1115 = function (p1) {
    this.invoke_850((!(p1 == null) ? isInterface(p1, StyleBuilder) : false) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__352.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__353() {
  }
  _no_name_provided__353.prototype.invoke_864 = function ($this$Span) {
    $this$Span.classes([WtTexts_getInstance()._get_wtSubtitle2_()]);
    $this$Span.style_0(_no_name_provided_$factory_357());
  };
  _no_name_provided__353.prototype.invoke_1115 = function (p1) {
    this.invoke_864(p1 instanceof AttrsBuilder ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__353.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__354() {
  }
  _no_name_provided__354.prototype.invoke_850 = function ($this$style) {
    width($this$style, _get_px_(24));
    height($this$style, _get_px_(24));
  };
  _no_name_provided__354.prototype.invoke_1115 = function (p1) {
    this.invoke_850((!(p1 == null) ? isInterface(p1, StyleBuilder) : false) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__354.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__355() {
  }
  _no_name_provided__355.prototype.invoke_652 = function ($this$Img) {
    $this$Img.style_0(_no_name_provided_$factory_359());
  };
  _no_name_provided__355.prototype.invoke_1115 = function (p1) {
    this.invoke_652(p1 instanceof AttrsBuilder ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__355.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__356() {
  }
  _no_name_provided__356.prototype.invoke_654 = function ($this$P) {
    $this$P.classes([WtTexts_getInstance()._get_wtText3_()]);
  };
  _no_name_provided__356.prototype.invoke_1115 = function (p1) {
    this.invoke_654(p1 instanceof AttrsBuilder ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__356.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__357() {
  }
  _no_name_provided__357.prototype.invoke_890 = function ($this$Div) {
    $this$Div.classes([WtCols_getInstance()._get_wtColInline_()]);
  };
  _no_name_provided__357.prototype.invoke_1115 = function (p1) {
    this.invoke_890(p1 instanceof AttrsBuilder ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__357.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__358() {
  }
  _no_name_provided__358.prototype.invoke_890 = function ($this$Div) {
    $this$Div.classes([WtCols_getInstance()._get_wtColAutoFill_()]);
  };
  _no_name_provided__358.prototype.invoke_1115 = function (p1) {
    this.invoke_890(p1 instanceof AttrsBuilder ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__358.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__359($boundThis) {
    this._$boundThis_25 = $boundThis;
  }
  _no_name_provided__359.prototype.invoke_836 = function (p0, p1, p2) {
    return this._$boundThis_25.invoke_377(p0, p1, p2);
  };
  _no_name_provided__359.prototype.invoke_917 = function (p1, p2, p3) {
    var tmp = (!(p1 == null) ? isInterface(p1, ElementScope) : false) ? p1 : THROW_CCE();
    var tmp_0 = (!(p2 == null) ? isInterface(p2, Composer) : false) ? p2 : THROW_CCE();
    return this.invoke_836(tmp, tmp_0, (!(p3 == null) ? typeof p3 === 'number' : false) ? p3 : THROW_CCE());
  };
  _no_name_provided__359.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__360() {
  }
  _no_name_provided__360.prototype.invoke_838 = function ($this$Span, $composer, $changed) {
    var $composer_0 = $composer;
    if (!(($changed & 81 ^ 16) === 0) ? true : !$composer_0._get_skipping__0()) {
      Text$composable('Technology preview', $composer_0, 6);
    } else
      $composer_0.skipToGroupEnd_2();
  };
  _no_name_provided__360.prototype.invoke_917 = function (p1, p2, p3) {
    var tmp = (!(p1 == null) ? isInterface(p1, ElementScope) : false) ? p1 : THROW_CCE();
    var tmp_0 = (p2 == null ? true : isInterface(p2, Composer)) ? p2 : THROW_CCE();
    this.invoke_838(tmp, tmp_0, (!(p3 == null) ? typeof p3 === 'number' : false) ? p3 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__360.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__361($boundThis) {
    this._$boundThis_26 = $boundThis;
  }
  _no_name_provided__361.prototype.invoke_836 = function (p0, p1, p2) {
    return this._$boundThis_26.invoke_377(p0, p1, p2);
  };
  _no_name_provided__361.prototype.invoke_917 = function (p1, p2, p3) {
    var tmp = (!(p1 == null) ? isInterface(p1, ElementScope) : false) ? p1 : THROW_CCE();
    var tmp_0 = (!(p2 == null) ? isInterface(p2, Composer) : false) ? p2 : THROW_CCE();
    return this.invoke_836(tmp, tmp_0, (!(p3 == null) ? typeof p3 === 'number' : false) ? p3 : THROW_CCE());
  };
  _no_name_provided__361.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__362() {
  }
  _no_name_provided__362.prototype.invoke_838 = function ($this$Span, $composer, $changed) {
    var $composer_0 = $composer;
    if (!(($changed & 81 ^ 16) === 0) ? true : !$composer_0._get_skipping__0()) {
      Text$composable('Web', $composer_0, 6);
      Span$composable(_no_name_provided_$factory_353(), ComposableSingletons$IntroSectionKt_getInstance()._lambda_1_2, $composer_0, 0, 0);
    } else
      $composer_0.skipToGroupEnd_2();
  };
  _no_name_provided__362.prototype.invoke_917 = function (p1, p2, p3) {
    var tmp = (!(p1 == null) ? isInterface(p1, ElementScope) : false) ? p1 : THROW_CCE();
    var tmp_0 = (p2 == null ? true : isInterface(p2, Composer)) ? p2 : THROW_CCE();
    this.invoke_838(tmp, tmp_0, (!(p3 == null) ? typeof p3 === 'number' : false) ? p3 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__362.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__363($boundThis) {
    this._$boundThis_27 = $boundThis;
  }
  _no_name_provided__363.prototype.invoke_700 = function (p0, p1, p2) {
    return this._$boundThis_27.invoke_377(p0, p1, p2);
  };
  _no_name_provided__363.prototype.invoke_917 = function (p1, p2, p3) {
    var tmp = (!(p1 == null) ? isInterface(p1, ElementScope) : false) ? p1 : THROW_CCE();
    var tmp_0 = (!(p2 == null) ? isInterface(p2, Composer) : false) ? p2 : THROW_CCE();
    return this.invoke_700(tmp, tmp_0, (!(p3 == null) ? typeof p3 === 'number' : false) ? p3 : THROW_CCE());
  };
  _no_name_provided__363.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__364() {
  }
  _no_name_provided__364.prototype.invoke_702 = function ($this$H1, $composer, $changed) {
    var $composer_0 = $composer;
    if (!(($changed & 81 ^ 16) === 0) ? true : !$composer_0._get_skipping__0()) {
      Text$composable('Chia Lisp', $composer_0, 6);
      Span$composable(_no_name_provided_$factory_354(), ComposableSingletons$IntroSectionKt_getInstance()._lambda_2_2, $composer_0, 0, 0);
    } else
      $composer_0.skipToGroupEnd_2();
  };
  _no_name_provided__364.prototype.invoke_917 = function (p1, p2, p3) {
    var tmp = (!(p1 == null) ? isInterface(p1, ElementScope) : false) ? p1 : THROW_CCE();
    var tmp_0 = (p2 == null ? true : isInterface(p2, Composer)) ? p2 : THROW_CCE();
    this.invoke_702(tmp, tmp_0, (!(p3 == null) ? typeof p3 === 'number' : false) ? p3 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__364.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__365($boundThis) {
    this._$boundThis_28 = $boundThis;
  }
  _no_name_provided__365.prototype.invoke_836 = function (p0, p1, p2) {
    return this._$boundThis_28.invoke_377(p0, p1, p2);
  };
  _no_name_provided__365.prototype.invoke_917 = function (p1, p2, p3) {
    var tmp = (!(p1 == null) ? isInterface(p1, ElementScope) : false) ? p1 : THROW_CCE();
    var tmp_0 = (!(p2 == null) ? isInterface(p2, Composer) : false) ? p2 : THROW_CCE();
    return this.invoke_836(tmp, tmp_0, (!(p3 == null) ? typeof p3 === 'number' : false) ? p3 : THROW_CCE());
  };
  _no_name_provided__365.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__366() {
  }
  _no_name_provided__366.prototype.invoke_838 = function ($this$Span, $composer, $changed) {
    var $composer_0 = $composer;
    if (!(($changed & 81 ^ 16) === 0) ? true : !$composer_0._get_skipping__0()) {
      Text$composable('Programs', $composer_0, 6);
    } else
      $composer_0.skipToGroupEnd_2();
  };
  _no_name_provided__366.prototype.invoke_917 = function (p1, p2, p3) {
    var tmp = (!(p1 == null) ? isInterface(p1, ElementScope) : false) ? p1 : THROW_CCE();
    var tmp_0 = (p2 == null ? true : isInterface(p2, Composer)) ? p2 : THROW_CCE();
    this.invoke_838(tmp, tmp_0, (!(p3 == null) ? typeof p3 === 'number' : false) ? p3 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__366.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__367($boundThis) {
    this._$boundThis_29 = $boundThis;
  }
  _no_name_provided__367.prototype.invoke_874 = function (p0, p1, p2) {
    return this._$boundThis_29.invoke_377(p0, p1, p2);
  };
  _no_name_provided__367.prototype.invoke_917 = function (p1, p2, p3) {
    var tmp = (!(p1 == null) ? isInterface(p1, ElementScope) : false) ? p1 : THROW_CCE();
    var tmp_0 = (!(p2 == null) ? isInterface(p2, Composer) : false) ? p2 : THROW_CCE();
    return this.invoke_874(tmp, tmp_0, (!(p3 == null) ? typeof p3 === 'number' : false) ? p3 : THROW_CCE());
  };
  _no_name_provided__367.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__368() {
  }
  _no_name_provided__368.prototype.invoke_882 = function ($this$Div, $composer, $changed) {
    var $composer_0 = $composer;
    if (!(($changed & 81 ^ 16) === 0) ? true : !$composer_0._get_skipping__0()) {
      Span$composable(_no_name_provided_$factory_356(), ComposableSingletons$IntroSectionKt_getInstance()._lambda_4_1, $composer_0, 0, 0);
    } else
      $composer_0.skipToGroupEnd_2();
  };
  _no_name_provided__368.prototype.invoke_917 = function (p1, p2, p3) {
    var tmp = (!(p1 == null) ? isInterface(p1, ElementScope) : false) ? p1 : THROW_CCE();
    var tmp_0 = (p2 == null ? true : isInterface(p2, Composer)) ? p2 : THROW_CCE();
    this.invoke_882(tmp, tmp_0, (!(p3 == null) ? typeof p3 === 'number' : false) ? p3 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__368.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__369($boundThis) {
    this._$boundThis_30 = $boundThis;
  }
  _no_name_provided__369.prototype.invoke_874 = function (p0, p1, p2) {
    return this._$boundThis_30.invoke_377(p0, p1, p2);
  };
  _no_name_provided__369.prototype.invoke_917 = function (p1, p2, p3) {
    var tmp = (!(p1 == null) ? isInterface(p1, ElementScope) : false) ? p1 : THROW_CCE();
    var tmp_0 = (!(p2 == null) ? isInterface(p2, Composer) : false) ? p2 : THROW_CCE();
    return this.invoke_874(tmp, tmp_0, (!(p3 == null) ? typeof p3 === 'number' : false) ? p3 : THROW_CCE());
  };
  _no_name_provided__369.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__370() {
  }
  _no_name_provided__370.prototype.invoke_882 = function ($this$Div, $composer, $changed) {
    var $composer_0 = $composer;
    if (!(($changed & 81 ^ 16) === 0) ? true : !$composer_0._get_skipping__0()) {
      EditorBlock$composable(Unit_getInstance(), $composer_0, 0, 1);
    } else
      $composer_0.skipToGroupEnd_2();
  };
  _no_name_provided__370.prototype.invoke_917 = function (p1, p2, p3) {
    var tmp = (!(p1 == null) ? isInterface(p1, ElementScope) : false) ? p1 : THROW_CCE();
    var tmp_0 = (p2 == null ? true : isInterface(p2, Composer)) ? p2 : THROW_CCE();
    this.invoke_882(tmp, tmp_0, (!(p3 == null) ? typeof p3 === 'number' : false) ? p3 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__370.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__371($boundThis) {
    this._$boundThis_31 = $boundThis;
  }
  _no_name_provided__371.prototype.invoke_836 = function (p0, p1, p2) {
    return this._$boundThis_31.invoke_377(p0, p1, p2);
  };
  _no_name_provided__371.prototype.invoke_917 = function (p1, p2, p3) {
    var tmp = (!(p1 == null) ? isInterface(p1, ElementScope) : false) ? p1 : THROW_CCE();
    var tmp_0 = (!(p2 == null) ? isInterface(p2, Composer) : false) ? p2 : THROW_CCE();
    return this.invoke_836(tmp, tmp_0, (!(p3 == null) ? typeof p3 === 'number' : false) ? p3 : THROW_CCE());
  };
  _no_name_provided__371.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__372() {
  }
  _no_name_provided__372.prototype.invoke_838 = function ($this$Span, $composer, $changed) {
    var $composer_0 = $composer;
    if (!(($changed & 81 ^ 16) === 0) ? true : !$composer_0._get_skipping__0()) {
      Text$composable('Args: ', $composer_0, 6);
    } else
      $composer_0.skipToGroupEnd_2();
  };
  _no_name_provided__372.prototype.invoke_917 = function (p1, p2, p3) {
    var tmp = (!(p1 == null) ? isInterface(p1, ElementScope) : false) ? p1 : THROW_CCE();
    var tmp_0 = (p2 == null ? true : isInterface(p2, Composer)) ? p2 : THROW_CCE();
    this.invoke_838(tmp, tmp_0, (!(p3 == null) ? typeof p3 === 'number' : false) ? p3 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__372.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__373($boundThis) {
    this._$boundThis_32 = $boundThis;
  }
  _no_name_provided__373.prototype.invoke_836 = function (p0, p1, p2) {
    return this._$boundThis_32.invoke_377(p0, p1, p2);
  };
  _no_name_provided__373.prototype.invoke_917 = function (p1, p2, p3) {
    var tmp = (!(p1 == null) ? isInterface(p1, ElementScope) : false) ? p1 : THROW_CCE();
    var tmp_0 = (!(p2 == null) ? isInterface(p2, Composer) : false) ? p2 : THROW_CCE();
    return this.invoke_836(tmp, tmp_0, (!(p3 == null) ? typeof p3 === 'number' : false) ? p3 : THROW_CCE());
  };
  _no_name_provided__373.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__374() {
  }
  _no_name_provided__374.prototype.invoke_838 = function ($this$Span, $composer, $changed) {
    var $composer_0 = $composer;
    if (!(($changed & 81 ^ 16) === 0) ? true : !$composer_0._get_skipping__0()) {
      Text$composable('Actions:', $composer_0, 6);
    } else
      $composer_0.skipToGroupEnd_2();
  };
  _no_name_provided__374.prototype.invoke_917 = function (p1, p2, p3) {
    var tmp = (!(p1 == null) ? isInterface(p1, ElementScope) : false) ? p1 : THROW_CCE();
    var tmp_0 = (p2 == null ? true : isInterface(p2, Composer)) ? p2 : THROW_CCE();
    this.invoke_838(tmp, tmp_0, (!(p3 == null) ? typeof p3 === 'number' : false) ? p3 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__374.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__375($boundThis) {
    this._$boundThis_33 = $boundThis;
  }
  _no_name_provided__375.prototype.invoke_700 = function (p0, p1, p2) {
    return this._$boundThis_33.invoke_377(p0, p1, p2);
  };
  _no_name_provided__375.prototype.invoke_917 = function (p1, p2, p3) {
    var tmp = (!(p1 == null) ? isInterface(p1, ElementScope) : false) ? p1 : THROW_CCE();
    var tmp_0 = (!(p2 == null) ? isInterface(p2, Composer) : false) ? p2 : THROW_CCE();
    return this.invoke_700(tmp, tmp_0, (!(p3 == null) ? typeof p3 === 'number' : false) ? p3 : THROW_CCE());
  };
  _no_name_provided__375.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__376() {
  }
  _no_name_provided__376.prototype.invoke_702 = function ($this$Button, $composer, $changed) {
    var $composer_0 = $composer;
    if (!(($changed & 81 ^ 16) === 0) ? true : !$composer_0._get_skipping__0()) {
      Text$composable('Compile', $composer_0, 6);
    } else
      $composer_0.skipToGroupEnd_2();
  };
  _no_name_provided__376.prototype.invoke_917 = function (p1, p2, p3) {
    var tmp = (!(p1 == null) ? isInterface(p1, ElementScope) : false) ? p1 : THROW_CCE();
    var tmp_0 = (p2 == null ? true : isInterface(p2, Composer)) ? p2 : THROW_CCE();
    this.invoke_702(tmp, tmp_0, (!(p3 == null) ? typeof p3 === 'number' : false) ? p3 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__376.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__377($boundThis) {
    this._$boundThis_34 = $boundThis;
  }
  _no_name_provided__377.prototype.invoke_700 = function (p0, p1, p2) {
    return this._$boundThis_34.invoke_377(p0, p1, p2);
  };
  _no_name_provided__377.prototype.invoke_917 = function (p1, p2, p3) {
    var tmp = (!(p1 == null) ? isInterface(p1, ElementScope) : false) ? p1 : THROW_CCE();
    var tmp_0 = (!(p2 == null) ? isInterface(p2, Composer) : false) ? p2 : THROW_CCE();
    return this.invoke_700(tmp, tmp_0, (!(p3 == null) ? typeof p3 === 'number' : false) ? p3 : THROW_CCE());
  };
  _no_name_provided__377.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__378() {
  }
  _no_name_provided__378.prototype.invoke_702 = function ($this$Button, $composer, $changed) {
    var $composer_0 = $composer;
    if (!(($changed & 81 ^ 16) === 0) ? true : !$composer_0._get_skipping__0()) {
      Text$composable('Run', $composer_0, 6);
    } else
      $composer_0.skipToGroupEnd_2();
  };
  _no_name_provided__378.prototype.invoke_917 = function (p1, p2, p3) {
    var tmp = (!(p1 == null) ? isInterface(p1, ElementScope) : false) ? p1 : THROW_CCE();
    var tmp_0 = (p2 == null ? true : isInterface(p2, Composer)) ? p2 : THROW_CCE();
    this.invoke_702(tmp, tmp_0, (!(p3 == null) ? typeof p3 === 'number' : false) ? p3 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__378.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__379($boundThis) {
    this._$boundThis_35 = $boundThis;
  }
  _no_name_provided__379.prototype.invoke_700 = function (p0, p1, p2) {
    return this._$boundThis_35.invoke_377(p0, p1, p2);
  };
  _no_name_provided__379.prototype.invoke_917 = function (p1, p2, p3) {
    var tmp = (!(p1 == null) ? isInterface(p1, ElementScope) : false) ? p1 : THROW_CCE();
    var tmp_0 = (!(p2 == null) ? isInterface(p2, Composer) : false) ? p2 : THROW_CCE();
    return this.invoke_700(tmp, tmp_0, (!(p3 == null) ? typeof p3 === 'number' : false) ? p3 : THROW_CCE());
  };
  _no_name_provided__379.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__380() {
  }
  _no_name_provided__380.prototype.invoke_702 = function ($this$Button, $composer, $changed) {
    var $composer_0 = $composer;
    if (!(($changed & 81 ^ 16) === 0) ? true : !$composer_0._get_skipping__0()) {
      Text$composable('Stop Compile', $composer_0, 6);
    } else
      $composer_0.skipToGroupEnd_2();
  };
  _no_name_provided__380.prototype.invoke_917 = function (p1, p2, p3) {
    var tmp = (!(p1 == null) ? isInterface(p1, ElementScope) : false) ? p1 : THROW_CCE();
    var tmp_0 = (p2 == null ? true : isInterface(p2, Composer)) ? p2 : THROW_CCE();
    this.invoke_702(tmp, tmp_0, (!(p3 == null) ? typeof p3 === 'number' : false) ? p3 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__380.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__381($boundThis) {
    this._$boundThis_36 = $boundThis;
  }
  _no_name_provided__381.prototype.invoke_874 = function (p0, p1, p2) {
    return this._$boundThis_36.invoke_377(p0, p1, p2);
  };
  _no_name_provided__381.prototype.invoke_917 = function (p1, p2, p3) {
    var tmp = (!(p1 == null) ? isInterface(p1, ElementScope) : false) ? p1 : THROW_CCE();
    var tmp_0 = (!(p2 == null) ? isInterface(p2, Composer) : false) ? p2 : THROW_CCE();
    return this.invoke_874(tmp, tmp_0, (!(p3 == null) ? typeof p3 === 'number' : false) ? p3 : THROW_CCE());
  };
  _no_name_provided__381.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__382() {
  }
  _no_name_provided__382.prototype.invoke_882 = function ($this$Div, $composer, $changed) {
    var $composer_0 = $composer;
    if (!(($changed & 81 ^ 16) === 0) ? true : !$composer_0._get_skipping__0()) {
      Text$composable('Unused..', $composer_0, 6);
    } else
      $composer_0.skipToGroupEnd_2();
  };
  _no_name_provided__382.prototype.invoke_917 = function (p1, p2, p3) {
    var tmp = (!(p1 == null) ? isInterface(p1, ElementScope) : false) ? p1 : THROW_CCE();
    var tmp_0 = (p2 == null ? true : isInterface(p2, Composer)) ? p2 : THROW_CCE();
    this.invoke_882(tmp, tmp_0, (!(p3 == null) ? typeof p3 === 'number' : false) ? p3 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__382.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__383($boundThis) {
    this._$boundThis_37 = $boundThis;
  }
  _no_name_provided__383.prototype.invoke_708 = function (p0, p1, p2) {
    return this._$boundThis_37.invoke_377(p0, p1, p2);
  };
  _no_name_provided__383.prototype.invoke_917 = function (p1, p2, p3) {
    var tmp = (!(p1 == null) ? isInterface(p1, ElementScope) : false) ? p1 : THROW_CCE();
    var tmp_0 = (!(p2 == null) ? isInterface(p2, Composer) : false) ? p2 : THROW_CCE();
    return this.invoke_708(tmp, tmp_0, (!(p3 == null) ? typeof p3 === 'number' : false) ? p3 : THROW_CCE());
  };
  _no_name_provided__383.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__384() {
  }
  _no_name_provided__384.prototype.invoke_710 = function ($this$Img, $composer, $changed) {
    var $composer_0 = $composer;
    if (!(($changed & 81 ^ 16) === 0) ? true : !$composer_0._get_skipping__0()) {
      Unit_getInstance();
    } else
      $composer_0.skipToGroupEnd_2();
  };
  _no_name_provided__384.prototype.invoke_917 = function (p1, p2, p3) {
    var tmp = (!(p1 == null) ? isInterface(p1, ElementScope) : false) ? p1 : THROW_CCE();
    var tmp_0 = (p2 == null ? true : isInterface(p2, Composer)) ? p2 : THROW_CCE();
    this.invoke_710(tmp, tmp_0, (!(p3 == null) ? typeof p3 === 'number' : false) ? p3 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__384.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__385($boundThis) {
    this._$boundThis_38 = $boundThis;
  }
  _no_name_provided__385.prototype.invoke_874 = function (p0, p1, p2) {
    return this._$boundThis_38.invoke_377(p0, p1, p2);
  };
  _no_name_provided__385.prototype.invoke_917 = function (p1, p2, p3) {
    var tmp = (!(p1 == null) ? isInterface(p1, ElementScope) : false) ? p1 : THROW_CCE();
    var tmp_0 = (!(p2 == null) ? isInterface(p2, Composer) : false) ? p2 : THROW_CCE();
    return this.invoke_874(tmp, tmp_0, (!(p3 == null) ? typeof p3 === 'number' : false) ? p3 : THROW_CCE());
  };
  _no_name_provided__385.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__386() {
  }
  _no_name_provided__386.prototype.invoke_882 = function ($this$Div, $composer, $changed) {
    var $composer_0 = $composer;
    if (!(($changed & 81 ^ 16) === 0) ? true : !$composer_0._get_skipping__0()) {
      Img$composable('ic_info.svg', Unit_getInstance(), _no_name_provided_$factory_358(), ComposableSingletons$IntroSectionKt_getInstance()._lambda_13, $composer_0, 6, 2);
    } else
      $composer_0.skipToGroupEnd_2();
  };
  _no_name_provided__386.prototype.invoke_917 = function (p1, p2, p3) {
    var tmp = (!(p1 == null) ? isInterface(p1, ElementScope) : false) ? p1 : THROW_CCE();
    var tmp_0 = (p2 == null ? true : isInterface(p2, Composer)) ? p2 : THROW_CCE();
    this.invoke_882(tmp, tmp_0, (!(p3 == null) ? typeof p3 === 'number' : false) ? p3 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__386.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__387($boundThis) {
    this._$boundThis_39 = $boundThis;
  }
  _no_name_provided__387.prototype.invoke_716 = function (p0, p1, p2) {
    return this._$boundThis_39.invoke_377(p0, p1, p2);
  };
  _no_name_provided__387.prototype.invoke_917 = function (p1, p2, p3) {
    var tmp = (!(p1 == null) ? isInterface(p1, ElementScope) : false) ? p1 : THROW_CCE();
    var tmp_0 = (!(p2 == null) ? isInterface(p2, Composer) : false) ? p2 : THROW_CCE();
    return this.invoke_716(tmp, tmp_0, (!(p3 == null) ? typeof p3 === 'number' : false) ? p3 : THROW_CCE());
  };
  _no_name_provided__387.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__388() {
  }
  _no_name_provided__388.prototype.invoke_718 = function ($this$P, $composer, $changed) {
    var $composer_0 = $composer;
    if (!(($changed & 81 ^ 16) === 0) ? true : !$composer_0._get_skipping__0()) {
      Text$composable('Currently for experimentation only! Do not rely on for production.\nThis is a browser based compiler. Run times may be long.', $composer_0, 6);
    } else
      $composer_0.skipToGroupEnd_2();
  };
  _no_name_provided__388.prototype.invoke_917 = function (p1, p2, p3) {
    var tmp = (!(p1 == null) ? isInterface(p1, ElementScope) : false) ? p1 : THROW_CCE();
    var tmp_0 = (p2 == null ? true : isInterface(p2, Composer)) ? p2 : THROW_CCE();
    this.invoke_718(tmp, tmp_0, (!(p3 == null) ? typeof p3 === 'number' : false) ? p3 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__388.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__389($boundThis) {
    this._$boundThis_40 = $boundThis;
  }
  _no_name_provided__389.prototype.invoke_874 = function (p0, p1, p2) {
    return this._$boundThis_40.invoke_377(p0, p1, p2);
  };
  _no_name_provided__389.prototype.invoke_917 = function (p1, p2, p3) {
    var tmp = (!(p1 == null) ? isInterface(p1, ElementScope) : false) ? p1 : THROW_CCE();
    var tmp_0 = (!(p2 == null) ? isInterface(p2, Composer) : false) ? p2 : THROW_CCE();
    return this.invoke_874(tmp, tmp_0, (!(p3 == null) ? typeof p3 === 'number' : false) ? p3 : THROW_CCE());
  };
  _no_name_provided__389.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__390() {
  }
  _no_name_provided__390.prototype.invoke_882 = function ($this$Div, $composer, $changed) {
    var $composer_0 = $composer;
    if (!(($changed & 81 ^ 16) === 0) ? true : !$composer_0._get_skipping__0()) {
      P$composable(_no_name_provided_$factory_360(), ComposableSingletons$IntroSectionKt_getInstance()._lambda_15, $composer_0, 0, 0);
    } else
      $composer_0.skipToGroupEnd_2();
  };
  _no_name_provided__390.prototype.invoke_917 = function (p1, p2, p3) {
    var tmp = (!(p1 == null) ? isInterface(p1, ElementScope) : false) ? p1 : THROW_CCE();
    var tmp_0 = (p2 == null ? true : isInterface(p2, Composer)) ? p2 : THROW_CCE();
    this.invoke_882(tmp, tmp_0, (!(p3 == null) ? typeof p3 === 'number' : false) ? p3 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__390.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__391($boundThis) {
    this._$boundThis_41 = $boundThis;
  }
  _no_name_provided__391.prototype.invoke_874 = function (p0, p1, p2) {
    return this._$boundThis_41.invoke_377(p0, p1, p2);
  };
  _no_name_provided__391.prototype.invoke_917 = function (p1, p2, p3) {
    var tmp = (!(p1 == null) ? isInterface(p1, ElementScope) : false) ? p1 : THROW_CCE();
    var tmp_0 = (!(p2 == null) ? isInterface(p2, Composer) : false) ? p2 : THROW_CCE();
    return this.invoke_874(tmp, tmp_0, (!(p3 == null) ? typeof p3 === 'number' : false) ? p3 : THROW_CCE());
  };
  _no_name_provided__391.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__392() {
  }
  _no_name_provided__392.prototype.invoke_882 = function ($this$Div, $composer, $changed) {
    var $composer_0 = $composer;
    if (!(($changed & 81 ^ 16) === 0) ? true : !$composer_0._get_skipping__0()) {
      Div$composable(_no_name_provided_$factory_361(), ComposableSingletons$IntroSectionKt_getInstance()._lambda_14, $composer_0, 0, 0);
      Div$composable(_no_name_provided_$factory_362(), ComposableSingletons$IntroSectionKt_getInstance()._lambda_16, $composer_0, 0, 0);
    } else
      $composer_0.skipToGroupEnd_2();
  };
  _no_name_provided__392.prototype.invoke_917 = function (p1, p2, p3) {
    var tmp = (!(p1 == null) ? isInterface(p1, ElementScope) : false) ? p1 : THROW_CCE();
    var tmp_0 = (p2 == null ? true : isInterface(p2, Composer)) ? p2 : THROW_CCE();
    this.invoke_882(tmp, tmp_0, (!(p3 == null) ? typeof p3 === 'number' : false) ? p3 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__392.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__393($boundThis) {
    this._$boundThis_42 = $boundThis;
  }
  _no_name_provided__393.prototype.invoke_728 = function (p0, p1, p2) {
    return this._$boundThis_42.invoke_377(p0, p1, p2);
  };
  _no_name_provided__393.prototype.invoke_917 = function (p1, p2, p3) {
    var tmp = (!(p1 == null) ? isInterface(p1, ElementScope) : false) ? p1 : THROW_CCE();
    var tmp_0 = (!(p2 == null) ? isInterface(p2, Composer) : false) ? p2 : THROW_CCE();
    return this.invoke_728(tmp, tmp_0, (!(p3 == null) ? typeof p3 === 'number' : false) ? p3 : THROW_CCE());
  };
  _no_name_provided__393.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__394() {
  }
  _no_name_provided__394.prototype.invoke_730 = function ($this$TagElement, $composer, $changed) {
    var $composer_0 = $composer;
    if (!(($changed & 81 ^ 16) === 0) ? true : !$composer_0._get_skipping__0()) {
      Unit_getInstance();
    } else
      $composer_0.skipToGroupEnd_2();
  };
  _no_name_provided__394.prototype.invoke_917 = function (p1, p2, p3) {
    var tmp = (!(p1 == null) ? isInterface(p1, ElementScope) : false) ? p1 : THROW_CCE();
    var tmp_0 = (p2 == null ? true : isInterface(p2, Composer)) ? p2 : THROW_CCE();
    this.invoke_730(tmp, tmp_0, (!(p3 == null) ? typeof p3 === 'number' : false) ? p3 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__394.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function ComposableSingletons$IntroSectionKt() {
    ComposableSingletons$IntroSectionKt_instance = this;
    var tmp = this;
    tmp._lambda_1_2 = _no_name_provided_$factory_318(composableLambdaInstance(-985532464, false, _no_name_provided_$factory_317()));
    var tmp_0 = this;
    tmp_0._lambda_2_2 = _no_name_provided_$factory_320(composableLambdaInstance(-985532584, false, _no_name_provided_$factory_319()));
    var tmp_1 = this;
    tmp_1._lambda_3_1 = _no_name_provided_$factory_322(composableLambdaInstance(-985533033, false, _no_name_provided_$factory_321()));
    var tmp_2 = this;
    tmp_2._lambda_4_1 = _no_name_provided_$factory_324(composableLambdaInstance(-985531553, false, _no_name_provided_$factory_323()));
    var tmp_3 = this;
    tmp_3._lambda_5_1 = _no_name_provided_$factory_326(composableLambdaInstance(-985532407, false, _no_name_provided_$factory_325()));
    var tmp_4 = this;
    tmp_4._lambda_6_1 = _no_name_provided_$factory_328(composableLambdaInstance(-985531265, false, _no_name_provided_$factory_327()));
    var tmp_5 = this;
    tmp_5._lambda_7_1 = _no_name_provided_$factory_330(composableLambdaInstance(-985538105, false, _no_name_provided_$factory_329()));
    var tmp_6 = this;
    tmp_6._lambda_8_0 = _no_name_provided_$factory_332(composableLambdaInstance(-985536482, false, _no_name_provided_$factory_331()));
    var tmp_7 = this;
    tmp_7._lambda_9_0 = _no_name_provided_$factory_334(composableLambdaInstance(-985541886, false, _no_name_provided_$factory_333()));
    var tmp_8 = this;
    tmp_8._lambda_10 = _no_name_provided_$factory_336(composableLambdaInstance(-985541710, false, _no_name_provided_$factory_335()));
    var tmp_9 = this;
    tmp_9._lambda_11 = _no_name_provided_$factory_338(composableLambdaInstance(-985541368, false, _no_name_provided_$factory_337()));
    var tmp_10 = this;
    tmp_10._lambda_12 = _no_name_provided_$factory_340(composableLambdaInstance(-985541543, false, _no_name_provided_$factory_339()));
    var tmp_11 = this;
    tmp_11._lambda_13 = _no_name_provided_$factory_342(composableLambdaInstance(-985540678, false, _no_name_provided_$factory_341()));
    var tmp_12 = this;
    tmp_12._lambda_14 = _no_name_provided_$factory_344(composableLambdaInstance(-985540849, false, _no_name_provided_$factory_343()));
    var tmp_13 = this;
    tmp_13._lambda_15 = _no_name_provided_$factory_346(composableLambdaInstance(-985540905, false, _no_name_provided_$factory_345()));
    var tmp_14 = this;
    tmp_14._lambda_16 = _no_name_provided_$factory_348(composableLambdaInstance(-985541111, false, _no_name_provided_$factory_347()));
    var tmp_15 = this;
    tmp_15._lambda_17 = _no_name_provided_$factory_350(composableLambdaInstance(-985540790, false, _no_name_provided_$factory_349()));
    var tmp_16 = this;
    tmp_16._lambda_18 = _no_name_provided_$factory_352(composableLambdaInstance(-985540599, false, _no_name_provided_$factory_351()));
  }
  ComposableSingletons$IntroSectionKt.$metadata$ = {
    simpleName: 'ComposableSingletons$IntroSectionKt',
    kind: 'object',
    interfaces: []
  };
  var ComposableSingletons$IntroSectionKt_instance;
  function ComposableSingletons$IntroSectionKt_getInstance() {
    if (ComposableSingletons$IntroSectionKt_instance == null)
      new ComposableSingletons$IntroSectionKt();
    return ComposableSingletons$IntroSectionKt_instance;
  }
  function CodeEditorArea$composable(codeContext, $composer, $changed) {
    var $composer_0 = $composer;
    $composer_0 = $composer_0.startRestartGroup_0(1065890336);
    sourceInformation($composer_0, 'C(CodeEditorArea$composable)');
    var tmp = $composer_0;
    ContainerInSection$composable(Unit_getInstance(), _no_name_provided_$factory_364(composableLambda(tmp, -819894864, true, _no_name_provided_$factory_363(codeContext))), $composer_0, 0, 1);
    var tmp0_safe_receiver = $composer_0.endRestartGroup_0();
    if (tmp0_safe_receiver === null)
      null;
    else {
      tmp0_safe_receiver.updateScope_0(_no_name_provided_$factory_365(codeContext, $changed));
    }
  }
  function EditorWithRunner$composable(codeContext, $composer, $changed) {
    var $composer_0 = $composer;
    $composer_0 = $composer_0.startRestartGroup_0(24186503);
    var tmp = _no_name_provided_$factory_366();
    var tmp_0 = $composer_0;
    Div$composable(tmp, _no_name_provided_$factory_368(composableLambda(tmp_0, -819893160, true, _no_name_provided_$factory_367(codeContext))), $composer_0, 0, 0);
    var tmp0_safe_receiver = $composer_0.endRestartGroup_0();
    if (tmp0_safe_receiver === null)
      null;
    else {
      tmp0_safe_receiver.updateScope_0(_no_name_provided_$factory_369(codeContext, $changed));
    }
  }
  function CodeRunner$composable(codeContext, $composer, $changed) {
    var $composer_0 = $composer;
    $composer_0 = $composer_0.startRestartGroup_0(-494491410);
    var tmp = _no_name_provided_$factory_370();
    var tmp_0 = $composer_0;
    Div$composable(tmp, _no_name_provided_$factory_372(composableLambda(tmp_0, -819893687, true, _no_name_provided_$factory_371(codeContext))), $composer_0, 0, 0);
    var tmp0_safe_receiver = $composer_0.endRestartGroup_0();
    if (tmp0_safe_receiver === null)
      null;
    else {
      tmp0_safe_receiver.updateScope_0(_no_name_provided_$factory_373(codeContext, $changed));
    }
  }
  function ResultBox$composable(name, value_0, $composer, $changed) {
    var $composer_0 = $composer;
    $composer_0 = $composer_0.startRestartGroup_0(-1381822710);
    var $dirty = $changed;
    if (($changed & 14) === 0)
      $dirty = $dirty | ($composer_0.changed_0(name) ? 4 : 2);
    if (($changed & 112) === 0)
      $dirty = $dirty | ($composer_0.changed_0(value_0) ? 32 : 16);
    if (!(($dirty & 91 ^ 18) === 0) ? true : !$composer_0._get_skipping__0()) {
      var tmp = _no_name_provided_$factory_374();
      var tmp_0 = $composer_0;
      Div$composable(tmp, _no_name_provided_$factory_376(composableLambda(tmp_0, -819891513, true, _no_name_provided_$factory_375(name, value_0, $dirty))), $composer_0, 0, 0);
    } else
      $composer_0.skipToGroupEnd_2();
    var tmp0_safe_receiver = $composer_0.endRestartGroup_0();
    if (tmp0_safe_receiver === null)
      null;
    else {
      tmp0_safe_receiver.updateScope_0(_no_name_provided_$factory_377(name, value_0, $changed));
    }
  }
  function ChiaCodeResult$composable(codeContext, $composer, $changed) {
    var $composer_0 = $composer;
    $composer_0 = $composer_0.startRestartGroup_0(-276009812);
    var tmp0_remember$composable_0 = $composer_0;
    var tmp1_remember$composable_0 = 0;
    var $composer_1 = tmp0_remember$composable_0;
    $composer_1.startReplaceableGroup_0(884179566);
    sourceInformation($composer_1, 'C(remember$composable):Composables.kt#9igjgp');
    var tmp1_cache_0_3 = $composer_1;
    var tmp2_cache_0_4 = false;
    var tmp0_let_0_5 = tmp1_cache_0_3.rememberedValue_0();
    var tmp;
    if (tmp2_cache_0_4 ? true : tmp0_let_0_5 === Companion_getInstance_25()._Empty) {
      var tmp$ret$0;
      l$ret$1: do {
        var tmp0_return_8 = mutableStateOf$default('', null, 2, null);
        tmp$ret$0 = tmp0_return_8;
        break l$ret$1;
      }
       while (false);
      var value_2_2_6 = tmp$ret$0;
      tmp1_cache_0_3.updateRememberedValue_0(value_2_2_6);
      tmp = value_2_2_6;
    } else {
      tmp = tmp0_let_0_5;
    }
    var tmp_0 = tmp;
    var tmp0_2 = (tmp_0 == null ? true : isObject(tmp_0)) ? tmp_0 : THROW_CCE();
    $composer_1.endReplaceableGroup_0();
    var lastProgHex$delegate = tmp0_2;
    var tmp2_remember$composable_0 = $composer_0;
    var tmp3_remember$composable_0 = 0;
    var $composer_1_0 = tmp2_remember$composable_0;
    $composer_1_0.startReplaceableGroup_0(884179566);
    sourceInformation($composer_1_0, 'C(remember$composable):Composables.kt#9igjgp');
    var tmp1_cache_0_3_0 = $composer_1_0;
    var tmp2_cache_0_4_0 = false;
    var tmp0_let_0_5_0 = tmp1_cache_0_3_0.rememberedValue_0();
    var tmp_1;
    if (tmp2_cache_0_4_0 ? true : tmp0_let_0_5_0 === Companion_getInstance_25()._Empty) {
      var tmp$ret$2;
      l$ret$3: do {
        var tmp0_return_8_0 = mutableStateOf$default('', null, 2, null);
        tmp$ret$2 = tmp0_return_8_0;
        break l$ret$3;
      }
       while (false);
      var value_2_2_6_0 = tmp$ret$2;
      tmp1_cache_0_3_0.updateRememberedValue_0(value_2_2_6_0);
      tmp_1 = value_2_2_6_0;
    } else {
      tmp_1 = tmp0_let_0_5_0;
    }
    var tmp_2 = tmp_1;
    var tmp0_2_0 = (tmp_2 == null ? true : isObject(tmp_2)) ? tmp_2 : THROW_CCE();
    $composer_1_0.endReplaceableGroup_0();
    var lastProgSexpOut$delegate = tmp0_2_0;
    var tmp4_remember$composable_0 = $composer_0;
    var tmp5_remember$composable_0 = 0;
    var $composer_1_1 = tmp4_remember$composable_0;
    $composer_1_1.startReplaceableGroup_0(884179566);
    sourceInformation($composer_1_1, 'C(remember$composable):Composables.kt#9igjgp');
    var tmp1_cache_0_3_1 = $composer_1_1;
    var tmp2_cache_0_4_1 = false;
    var tmp0_let_0_5_1 = tmp1_cache_0_3_1.rememberedValue_0();
    var tmp_3;
    if (tmp2_cache_0_4_1 ? true : tmp0_let_0_5_1 === Companion_getInstance_25()._Empty) {
      var tmp$ret$4;
      l$ret$5: do {
        var tmp0_return_8_1 = mutableStateOf$default('', null, 2, null);
        tmp$ret$4 = tmp0_return_8_1;
        break l$ret$5;
      }
       while (false);
      var value_2_2_6_1 = tmp$ret$4;
      tmp1_cache_0_3_1.updateRememberedValue_0(value_2_2_6_1);
      tmp_3 = value_2_2_6_1;
    } else {
      tmp_3 = tmp0_let_0_5_1;
    }
    var tmp_4 = tmp_3;
    var tmp0_2_1 = (tmp_4 == null ? true : isObject(tmp_4)) ? tmp_4 : THROW_CCE();
    $composer_1_1.endReplaceableGroup_0();
    var lastProgOutput$delegate = tmp0_2_1;
    var tmp6_remember$composable_0 = $composer_0;
    var tmp7_remember$composable_0 = 0;
    var $composer_1_2 = tmp6_remember$composable_0;
    $composer_1_2.startReplaceableGroup_0(884179566);
    sourceInformation($composer_1_2, 'C(remember$composable):Composables.kt#9igjgp');
    var tmp1_cache_0_3_2 = $composer_1_2;
    var tmp2_cache_0_4_2 = false;
    var tmp0_let_0_5_2 = tmp1_cache_0_3_2.rememberedValue_0();
    var tmp_5;
    if (tmp2_cache_0_4_2 ? true : tmp0_let_0_5_2 === Companion_getInstance_25()._Empty) {
      var tmp$ret$6;
      l$ret$7: do {
        var tmp_6 = CodeRunState_STABNDY_getInstance();
        var tmp0_return_8_2 = mutableStateOf$default(tmp_6, null, 2, null);
        tmp$ret$6 = tmp0_return_8_2;
        break l$ret$7;
      }
       while (false);
      var value_2_2_6_2 = tmp$ret$6;
      tmp1_cache_0_3_2.updateRememberedValue_0(value_2_2_6_2);
      tmp_5 = value_2_2_6_2;
    } else {
      tmp_5 = tmp0_let_0_5_2;
    }
    var tmp_7 = tmp_5;
    var tmp0_2_2 = (tmp_7 == null ? true : isObject(tmp_7)) ? tmp_7 : THROW_CCE();
    $composer_1_2.endReplaceableGroup_0();
    var codeRunState$delegate = tmp0_2_2;
    ResultBox$composable('Serialized', ChiaCodeResult$composable$lambda_7(lastProgSexpOut$delegate), $composer_0, 6);
    ResultBox$composable('Run Output', ChiaCodeResult$composable$lambda_9(lastProgOutput$delegate), $composer_0, 6);
    var tmp_8 = _no_name_provided_$factory_378();
    var tmp_9 = $composer_0;
    Div$composable(tmp_8, _no_name_provided_$factory_380(composableLambda(tmp_9, -819888563, true, _no_name_provided_$factory_379(codeRunState$delegate, codeContext, lastProgOutput$delegate, lastProgSexpOut$delegate, lastProgHex$delegate))), $composer_0, 0, 0);
    var tmp0_safe_receiver = $composer_0.endRestartGroup_0();
    if (tmp0_safe_receiver === null)
      null;
    else {
      tmp0_safe_receiver.updateScope_0(_no_name_provided_$factory_381(codeContext, $changed));
    }
  }
  function ComposeWebStatusMessage$composable($composer, $changed) {
    var $composer_0 = $composer;
    $composer_0 = $composer_0.startRestartGroup_0(315297629);
    if (!($changed === 0) ? true : !$composer_0._get_skipping__0()) {
      Div$composable(_no_name_provided_$factory_382(), ComposableSingletons$IntroSectionKt_getInstance()._lambda_17, $composer_0, 0, 0);
    } else
      $composer_0.skipToGroupEnd_2();
    var tmp0_safe_receiver = $composer_0.endRestartGroup_0();
    if (tmp0_safe_receiver === null)
      null;
    else {
      tmp0_safe_receiver.updateScope_0(_no_name_provided_$factory_383($changed));
    }
  }
  function Hr$composable(attrs, $composer, $changed, $default) {
    var attrs_0 = {_v: attrs};
    var $composer_0 = $composer;
    $composer_0 = $composer_0.startRestartGroup_0(1251853802);
    sourceInformation($composer_0, 'C(Hr$composable)');
    var $dirty = $changed;
    if (($changed & 14) === 0)
      $dirty = $dirty | ((($default & 1) === 0 ? $composer_0.changed_0(attrs_0._v) : false) ? 4 : 2);
    if (!(($dirty & 11 ^ 2) === 0) ? true : !$composer_0._get_skipping__0()) {
      if (($changed & 1) === 0 ? true : $composer_0._get_defaultsInvalid__0()) {
        $composer_0.startDefaults_0();
        if (!(($default & 1) === 0)) {
          attrs_0._v = _no_name_provided_$factory_384();
          $dirty = $dirty & -15;
        }$composer_0.endDefaults_0();
      } else {
        $composer_0.skipCurrentGroup_0();
        if (!(($default & 1) === 0))
          $dirty = $dirty & -15;
      }
      TagElement$composable('hr', attrs_0._v, ComposableSingletons$IntroSectionKt_getInstance()._lambda_18, $composer_0, 6 | 112 & $dirty << 3);
    } else
      $composer_0.skipToGroupEnd_2();
    var tmp0_safe_receiver = $composer_0.endRestartGroup_0();
    if (tmp0_safe_receiver === null)
      null;
    else {
      tmp0_safe_receiver.updateScope_0(_no_name_provided_$factory_385(attrs_0, $changed, $default));
    }
  }
  function CodeEditorArea$composable$lambda_0($codeContext, $$changed, $composer, $force) {
    return CodeEditorArea$composable($codeContext, $composer, $$changed | 1);
  }
  function EditorWithRunner$composable$lambda_2($codeContext, $$changed, $composer, $force) {
    return EditorWithRunner$composable($codeContext, $composer, $$changed | 1);
  }
  function CodeRunner$composable$lambda_3($codeContext, $$changed, $composer, $force) {
    return CodeRunner$composable($codeContext, $composer, $$changed | 1);
  }
  function ResultBox$composable$lambda_4($name, $value, $$changed, $composer, $force) {
    return ResultBox$composable($name, $value, $composer, $$changed | 1);
  }
  function ChiaCodeResult$composable$lambda_6(lastProgHex$delegate, value_0) {
    var tmp0_setValue_0 = null;
    var tmp1_setValue_0 = getLocalDelegateReference('lastProgHex', KMutableProperty0, true, function () {
      return THROW_ISE();
    });
    return lastProgHex$delegate._set_value__6(value_0);
  }
  function ChiaCodeResult$composable$lambda_7(lastProgSexpOut$delegate) {
    var tmp0_getValue_0 = null;
    var tmp1_getValue_0 = getLocalDelegateReference('lastProgSexpOut', KMutableProperty0, true, function () {
      return THROW_ISE();
    });
    return lastProgSexpOut$delegate._get_value__33();
  }
  function ChiaCodeResult$composable$lambda_8(lastProgSexpOut$delegate, value_0) {
    var tmp0_setValue_0 = null;
    var tmp1_setValue_0 = getLocalDelegateReference('lastProgSexpOut', KMutableProperty0, true, function () {
      return THROW_ISE();
    });
    return lastProgSexpOut$delegate._set_value__6(value_0);
  }
  function ChiaCodeResult$composable$lambda_9(lastProgOutput$delegate) {
    var tmp0_getValue_0 = null;
    var tmp1_getValue_0 = getLocalDelegateReference('lastProgOutput', KMutableProperty0, true, function () {
      return THROW_ISE();
    });
    return lastProgOutput$delegate._get_value__33();
  }
  function ChiaCodeResult$composable$lambda_10(lastProgOutput$delegate, value_0) {
    var tmp0_setValue_0 = null;
    var tmp1_setValue_0 = getLocalDelegateReference('lastProgOutput', KMutableProperty0, true, function () {
      return THROW_ISE();
    });
    return lastProgOutput$delegate._set_value__6(value_0);
  }
  function ChiaCodeResult$composable$lambda_11(codeRunState$delegate) {
    var tmp0_getValue_0 = null;
    var tmp1_getValue_0 = getLocalDelegateReference('codeRunState', KMutableProperty0, true, function () {
      return THROW_ISE();
    });
    return codeRunState$delegate._get_value__33();
  }
  function ChiaCodeResult$composable$lambda_12(codeRunState$delegate, value_0) {
    var tmp0_setValue_0 = null;
    var tmp1_setValue_0 = getLocalDelegateReference('codeRunState', KMutableProperty0, true, function () {
      return THROW_ISE();
    });
    return codeRunState$delegate._set_value__6(value_0);
  }
  function ChiaCodeResult$composable$lambda_13($codeContext, $$changed, $composer, $force) {
    return ChiaCodeResult$composable($codeContext, $composer, $$changed | 1);
  }
  function ComposeWebStatusMessage$composable$lambda_14($$changed, $composer, $force) {
    return ComposeWebStatusMessage$composable($composer, $$changed | 1);
  }
  function Hr$composable$lambda_15($attrs, $$changed, $$default, $composer, $force) {
    return Hr$composable($attrs._v, $composer, $$changed | 1, $$default);
  }
  function _no_name_provided__395($boundThis) {
    this._$boundThis_43 = $boundThis;
  }
  _no_name_provided__395.prototype.invoke_912 = function (p0, p1) {
    return this._$boundThis_43.invoke_912(p0, p1);
  };
  _no_name_provided__395.prototype.invoke_915 = function (p1, p2) {
    var tmp = (!(p1 == null) ? isInterface(p1, Composer) : false) ? p1 : THROW_CCE();
    return this.invoke_912(tmp, (!(p2 == null) ? typeof p2 === 'number' : false) ? p2 : THROW_CCE());
  };
  _no_name_provided__395.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__396() {
  }
  _no_name_provided__396.prototype.invoke_734 = function ($this$H1) {
    $this$H1.classes([WtTexts_getInstance()._get_wtHero_()]);
  };
  _no_name_provided__396.prototype.invoke_1115 = function (p1) {
    this.invoke_734(p1 instanceof AttrsBuilder ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__396.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__397() {
  }
  _no_name_provided__397.prototype.invoke_890 = function ($this$Div) {
    $this$Div.classes([WtContainer_getInstance()._get_wtContainer_()]);
  };
  _no_name_provided__397.prototype.invoke_1115 = function (p1) {
    this.invoke_890(p1 instanceof AttrsBuilder ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__397.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__398($boundThis) {
    this._$boundThis_44 = $boundThis;
  }
  _no_name_provided__398.prototype.invoke_874 = function (p0, p1, p2) {
    return this._$boundThis_44.invoke_377(p0, p1, p2);
  };
  _no_name_provided__398.prototype.invoke_917 = function (p1, p2, p3) {
    var tmp = (!(p1 == null) ? isInterface(p1, ElementScope) : false) ? p1 : THROW_CCE();
    var tmp_0 = (!(p2 == null) ? isInterface(p2, Composer) : false) ? p2 : THROW_CCE();
    return this.invoke_874(tmp, tmp_0, (!(p3 == null) ? typeof p3 === 'number' : false) ? p3 : THROW_CCE());
  };
  _no_name_provided__398.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__399($codeContext) {
    this._$codeContext = $codeContext;
  }
  _no_name_provided__399.prototype.invoke_882 = function ($this$Div, $composer, $changed) {
    var $composer_0 = $composer;
    if (!(($changed & 81 ^ 16) === 0) ? true : !$composer_0._get_skipping__0()) {
      EditorWithRunner$composable(this._$codeContext, $composer_0, 8);
    } else
      $composer_0.skipToGroupEnd_2();
  };
  _no_name_provided__399.prototype.invoke_917 = function (p1, p2, p3) {
    var tmp = (!(p1 == null) ? isInterface(p1, ElementScope) : false) ? p1 : THROW_CCE();
    var tmp_0 = (p2 == null ? true : isInterface(p2, Composer)) ? p2 : THROW_CCE();
    this.invoke_882(tmp, tmp_0, (!(p3 == null) ? typeof p3 === 'number' : false) ? p3 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__399.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__400() {
  }
  _no_name_provided__400.prototype.invoke_890 = function ($this$Div) {
    $this$Div.classes([WtCols_getInstance()._get_wtCol10_(), WtCols_getInstance()._get_wtColMd8_(), WtCols_getInstance()._get_wtColSm12_(), WtOffsets_getInstance()._get_wtTopOffsetSm12_()]);
  };
  _no_name_provided__400.prototype.invoke_1115 = function (p1) {
    this.invoke_890(p1 instanceof AttrsBuilder ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__400.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__401($boundThis) {
    this._$boundThis_45 = $boundThis;
  }
  _no_name_provided__401.prototype.invoke_874 = function (p0, p1, p2) {
    return this._$boundThis_45.invoke_377(p0, p1, p2);
  };
  _no_name_provided__401.prototype.invoke_917 = function (p1, p2, p3) {
    var tmp = (!(p1 == null) ? isInterface(p1, ElementScope) : false) ? p1 : THROW_CCE();
    var tmp_0 = (!(p2 == null) ? isInterface(p2, Composer) : false) ? p2 : THROW_CCE();
    return this.invoke_874(tmp, tmp_0, (!(p3 == null) ? typeof p3 === 'number' : false) ? p3 : THROW_CCE());
  };
  _no_name_provided__401.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__402($codeContext) {
    this._$codeContext_0 = $codeContext;
  }
  _no_name_provided__402.prototype.invoke_882 = function ($this$Div, $composer, $changed) {
    var $composer_0 = $composer;
    if (!(($changed & 81 ^ 16) === 0) ? true : !$composer_0._get_skipping__0()) {
      H1$composable(_no_name_provided_$factory_392(), ComposableSingletons$IntroSectionKt_getInstance()._lambda_3_1, $composer_0, 0, 0);
      var tmp = _no_name_provided_$factory_393();
      var tmp_0 = $composer_0;
      Div$composable(tmp, _no_name_provided_$factory_395(composableLambda(tmp_0, -819895838, true, _no_name_provided_$factory_394(this._$codeContext_0))), $composer_0, 0, 0);
    } else
      $composer_0.skipToGroupEnd_2();
  };
  _no_name_provided__402.prototype.invoke_917 = function (p1, p2, p3) {
    var tmp = (!(p1 == null) ? isInterface(p1, ElementScope) : false) ? p1 : THROW_CCE();
    var tmp_0 = (p2 == null ? true : isInterface(p2, Composer)) ? p2 : THROW_CCE();
    this.invoke_882(tmp, tmp_0, (!(p3 == null) ? typeof p3 === 'number' : false) ? p3 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__402.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__403() {
  }
  _no_name_provided__403.prototype.invoke_890 = function ($this$Div) {
    $this$Div.classes([WtRows_getInstance()._get_wtRow_(), WtRows_getInstance()._get_wtRowSizeM_(), WtRows_getInstance()._get_wtRowSmAlignItemsCenter_()]);
  };
  _no_name_provided__403.prototype.invoke_1115 = function (p1) {
    this.invoke_890(p1 instanceof AttrsBuilder ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__403.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__404($boundThis) {
    this._$boundThis_46 = $boundThis;
  }
  _no_name_provided__404.prototype.invoke_874 = function (p0, p1, p2) {
    return this._$boundThis_46.invoke_377(p0, p1, p2);
  };
  _no_name_provided__404.prototype.invoke_917 = function (p1, p2, p3) {
    var tmp = (!(p1 == null) ? isInterface(p1, ElementScope) : false) ? p1 : THROW_CCE();
    var tmp_0 = (!(p2 == null) ? isInterface(p2, Composer) : false) ? p2 : THROW_CCE();
    return this.invoke_874(tmp, tmp_0, (!(p3 == null) ? typeof p3 === 'number' : false) ? p3 : THROW_CCE());
  };
  _no_name_provided__404.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__405($codeContext) {
    this._$codeContext_1 = $codeContext;
  }
  _no_name_provided__405.prototype.invoke_882 = function ($this$Div, $composer, $changed) {
    var $composer_0 = $composer;
    if (!(($changed & 81 ^ 16) === 0) ? true : !$composer_0._get_skipping__0()) {
      var tmp = _no_name_provided_$factory_389();
      var tmp_0 = $composer_0;
      Div$composable(tmp, _no_name_provided_$factory_391(composableLambda(tmp_0, -819895788, true, _no_name_provided_$factory_390(this._$codeContext_1))), $composer_0, 0, 0);
    } else
      $composer_0.skipToGroupEnd_2();
  };
  _no_name_provided__405.prototype.invoke_917 = function (p1, p2, p3) {
    var tmp = (!(p1 == null) ? isInterface(p1, ElementScope) : false) ? p1 : THROW_CCE();
    var tmp_0 = (p2 == null ? true : isInterface(p2, Composer)) ? p2 : THROW_CCE();
    this.invoke_882(tmp, tmp_0, (!(p3 == null) ? typeof p3 === 'number' : false) ? p3 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__405.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__406($codeContext) {
    this._$codeContext_2 = $codeContext;
  }
  _no_name_provided__406.prototype.invoke_914 = function ($composer, $changed) {
    var $composer_0 = $composer;
    if (!(($changed & 11 ^ 2) === 0) ? true : !$composer_0._get_skipping__0()) {
      var tmp = _no_name_provided_$factory_386();
      var tmp_0 = $composer_0;
      Div$composable(tmp, _no_name_provided_$factory_388(composableLambda(tmp_0, -819895008, true, _no_name_provided_$factory_387(this._$codeContext_2))), $composer_0, 0, 0);
    } else
      $composer_0.skipToGroupEnd_2();
  };
  _no_name_provided__406.prototype.invoke_915 = function (p1, p2) {
    var tmp = (p1 == null ? true : isInterface(p1, Composer)) ? p1 : THROW_CCE();
    this.invoke_914(tmp, (!(p2 == null) ? typeof p2 === 'number' : false) ? p2 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__406.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__407($codeContext, $$changed) {
    this._$codeContext_3 = $codeContext;
    this._$$changed_20 = $$changed;
  }
  _no_name_provided__407.prototype.invoke_892 = function (p0, p1) {
    return CodeEditorArea$composable$lambda_0(this._$codeContext_3, this._$$changed_20, p0, p1);
  };
  _no_name_provided__407.prototype.invoke_915 = function (p1, p2) {
    var tmp = (!(p1 == null) ? isInterface(p1, Composer) : false) ? p1 : THROW_CCE();
    this.invoke_892(tmp, (!(p2 == null) ? typeof p2 === 'number' : false) ? p2 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__407.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__408() {
  }
  _no_name_provided__408.prototype.invoke_890 = function ($this$Div) {
    $this$Div.classes([WtRows_getInstance()._get_wtRow_(), WtRows_getInstance()._get_wtRowSizeM_()]);
  };
  _no_name_provided__408.prototype.invoke_1115 = function (p1) {
    this.invoke_890(p1 instanceof AttrsBuilder ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__408.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__409($boundThis) {
    this._$boundThis_47 = $boundThis;
  }
  _no_name_provided__409.prototype.invoke_874 = function (p0, p1, p2) {
    return this._$boundThis_47.invoke_377(p0, p1, p2);
  };
  _no_name_provided__409.prototype.invoke_917 = function (p1, p2, p3) {
    var tmp = (!(p1 == null) ? isInterface(p1, ElementScope) : false) ? p1 : THROW_CCE();
    var tmp_0 = (!(p2 == null) ? isInterface(p2, Composer) : false) ? p2 : THROW_CCE();
    return this.invoke_874(tmp, tmp_0, (!(p3 == null) ? typeof p3 === 'number' : false) ? p3 : THROW_CCE());
  };
  _no_name_provided__409.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__410() {
  }
  _no_name_provided__410.prototype.invoke_890 = function ($this$Div) {
    $this$Div.classes([WtCols_getInstance()._get_wtCol9_(), WtCols_getInstance()._get_wtColMd9_(), WtCols_getInstance()._get_wtColSm12_()]);
  };
  _no_name_provided__410.prototype.invoke_1115 = function (p1) {
    this.invoke_890(p1 instanceof AttrsBuilder ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__410.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__411($boundThis) {
    this._$boundThis_48 = $boundThis;
  }
  _no_name_provided__411.prototype.invoke_874 = function (p0, p1, p2) {
    return this._$boundThis_48.invoke_377(p0, p1, p2);
  };
  _no_name_provided__411.prototype.invoke_917 = function (p1, p2, p3) {
    var tmp = (!(p1 == null) ? isInterface(p1, ElementScope) : false) ? p1 : THROW_CCE();
    var tmp_0 = (!(p2 == null) ? isInterface(p2, Composer) : false) ? p2 : THROW_CCE();
    return this.invoke_874(tmp, tmp_0, (!(p3 == null) ? typeof p3 === 'number' : false) ? p3 : THROW_CCE());
  };
  _no_name_provided__411.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__412($codeContext) {
    this._$codeContext_4 = $codeContext;
  }
  _no_name_provided__412.prototype.invoke_882 = function ($this$Div, $composer, $changed) {
    var $composer_0 = $composer;
    if (!(($changed & 81 ^ 16) === 0) ? true : !$composer_0._get_skipping__0()) {
      ComposeWebStatusMessage$composable($composer_0, 0);
      CodeRunner$composable(this._$codeContext_4, $composer_0, 8);
    } else
      $composer_0.skipToGroupEnd_2();
  };
  _no_name_provided__412.prototype.invoke_917 = function (p1, p2, p3) {
    var tmp = (!(p1 == null) ? isInterface(p1, ElementScope) : false) ? p1 : THROW_CCE();
    var tmp_0 = (p2 == null ? true : isInterface(p2, Composer)) ? p2 : THROW_CCE();
    this.invoke_882(tmp, tmp_0, (!(p3 == null) ? typeof p3 === 'number' : false) ? p3 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__412.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__413($codeContext) {
    this._$codeContext_5 = $codeContext;
  }
  _no_name_provided__413.prototype.invoke_882 = function ($this$Div, $composer, $changed) {
    var $composer_0 = $composer;
    if (!(($changed & 81 ^ 16) === 0) ? true : !$composer_0._get_skipping__0()) {
      var tmp = _no_name_provided_$factory_396();
      var tmp_0 = $composer_0;
      Div$composable(tmp, _no_name_provided_$factory_398(composableLambda(tmp_0, -819892810, true, _no_name_provided_$factory_397(this._$codeContext_5))), $composer_0, 0, 0);
    } else
      $composer_0.skipToGroupEnd_2();
  };
  _no_name_provided__413.prototype.invoke_917 = function (p1, p2, p3) {
    var tmp = (!(p1 == null) ? isInterface(p1, ElementScope) : false) ? p1 : THROW_CCE();
    var tmp_0 = (p2 == null ? true : isInterface(p2, Composer)) ? p2 : THROW_CCE();
    this.invoke_882(tmp, tmp_0, (!(p3 == null) ? typeof p3 === 'number' : false) ? p3 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__413.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__414($codeContext, $$changed) {
    this._$codeContext_6 = $codeContext;
    this._$$changed_21 = $$changed;
  }
  _no_name_provided__414.prototype.invoke_892 = function (p0, p1) {
    return EditorWithRunner$composable$lambda_2(this._$codeContext_6, this._$$changed_21, p0, p1);
  };
  _no_name_provided__414.prototype.invoke_915 = function (p1, p2) {
    var tmp = (!(p1 == null) ? isInterface(p1, Composer) : false) ? p1 : THROW_CCE();
    this.invoke_892(tmp, (!(p2 == null) ? typeof p2 === 'number' : false) ? p2 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__414.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__415() {
  }
  _no_name_provided__415.prototype.invoke_850 = function ($this$style) {
    marginTop($this$style, _get_px_(24));
    backgroundColor($this$style, new RGBA(39, 40, 44, 0.05));
    borderRadius_0($this$style, _get_px_(8));
    var tmp0_value_0 = "'JetBrains Mono', monospace";
    $this$style.property_4('font-family', Companion_getInstance_35().invoke_402(tmp0_value_0));
  };
  _no_name_provided__415.prototype.invoke_1115 = function (p1) {
    this.invoke_850((!(p1 == null) ? isInterface(p1, StyleBuilder) : false) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__415.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__416() {
  }
  _no_name_provided__416.prototype.invoke_890 = function ($this$Div) {
    $this$Div.style_0(_no_name_provided_$factory_399());
  };
  _no_name_provided__416.prototype.invoke_1115 = function (p1) {
    this.invoke_890(p1 instanceof AttrsBuilder ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__416.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__417($boundThis) {
    this._$boundThis_49 = $boundThis;
  }
  _no_name_provided__417.prototype.invoke_874 = function (p0, p1, p2) {
    return this._$boundThis_49.invoke_377(p0, p1, p2);
  };
  _no_name_provided__417.prototype.invoke_917 = function (p1, p2, p3) {
    var tmp = (!(p1 == null) ? isInterface(p1, ElementScope) : false) ? p1 : THROW_CCE();
    var tmp_0 = (!(p2 == null) ? isInterface(p2, Composer) : false) ? p2 : THROW_CCE();
    return this.invoke_874(tmp, tmp_0, (!(p3 == null) ? typeof p3 === 'number' : false) ? p3 : THROW_CCE());
  };
  _no_name_provided__417.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__418() {
  }
  _no_name_provided__418.prototype.invoke_850 = function ($this$style) {
    var tmp0_value_0 = '12px 16px';
    $this$style.property_4('padding', Companion_getInstance_35().invoke_402(tmp0_value_0));
  };
  _no_name_provided__418.prototype.invoke_1115 = function (p1) {
    this.invoke_850((!(p1 == null) ? isInterface(p1, StyleBuilder) : false) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__418.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__419() {
  }
  _no_name_provided__419.prototype.invoke_850 = function ($this$style) {
    height($this$style, _get_px_(1));
    var tmp = _get_px_(0);
    border$default($this$style, tmp, null, null, 6, null);
    backgroundColor($this$style, new RGBA(39, 40, 44, 0.15));
  };
  _no_name_provided__419.prototype.invoke_1115 = function (p1) {
    this.invoke_850((!(p1 == null) ? isInterface(p1, StyleBuilder) : false) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__419.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__420() {
  }
  _no_name_provided__420.prototype.invoke_850 = function ($this$style) {
    height($this$style, _get_px_(1));
    var tmp = _get_px_(0);
    border$default($this$style, tmp, null, null, 6, null);
    backgroundColor($this$style, new RGBA(39, 40, 44, 0.15));
  };
  _no_name_provided__420.prototype.invoke_1115 = function (p1) {
    this.invoke_850((!(p1 == null) ? isInterface(p1, StyleBuilder) : false) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__420.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__421() {
  }
  _no_name_provided__421.prototype.invoke_850 = function ($this$style) {
    var tmp0_value_0 = '12px 16px';
    $this$style.property_4('padding', Companion_getInstance_35().invoke_402(tmp0_value_0));
    display($this$style, DisplayStyle_Flex_getInstance());
    flexDirection($this$style, FlexDirection_Row_getInstance());
    alignItems($this$style, AlignItems_Center_getInstance());
  };
  _no_name_provided__421.prototype.invoke_1115 = function (p1) {
    this.invoke_850((!(p1 == null) ? isInterface(p1, StyleBuilder) : false) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__421.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__422() {
  }
  _no_name_provided__422.prototype.invoke_850 = function ($this$style) {
    var tmp0_value_0 = _get_px_(8);
    $this$style.property_4('margin-right', Companion_getInstance_35().invoke_396(tmp0_value_0));
  };
  _no_name_provided__422.prototype.invoke_1115 = function (p1) {
    this.invoke_850((!(p1 == null) ? isInterface(p1, StyleBuilder) : false) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__422.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__423() {
  }
  _no_name_provided__423.prototype.invoke_850 = function ($this$style) {
    var tmp0_value_0 = _get_px_(8);
    $this$style.property_4('margin-right', Companion_getInstance_35().invoke_396(tmp0_value_0));
    var tmp1_value_0 = 'anywhere';
    $this$style.property_4('overflow-wrap', Companion_getInstance_35().invoke_402(tmp1_value_0));
    width($this$style, _get_pc_(100));
  };
  _no_name_provided__423.prototype.invoke_1115 = function (p1) {
    this.invoke_850((!(p1 == null) ? isInterface(p1, StyleBuilder) : false) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__423.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__424($codeContext, $element) {
    this._$codeContext_7 = $codeContext;
    this._$element = $element;
  }
  _no_name_provided__424.prototype.invoke_790 = function (it) {
    this._$codeContext_7.updateArgs(this._$element.innerText);
  };
  _no_name_provided__424.prototype.invoke_1115 = function (p1) {
    this.invoke_790(p1 instanceof InputEvent ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__424.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__425($codeContext) {
    this._$codeContext_8 = $codeContext;
  }
  _no_name_provided__425.prototype.invoke_792 = function ($this$DomSideEffect, element) {
    element.innerText = this._$codeContext_8._args;
    element.oninput = _no_name_provided_$factory_417(this._$codeContext_8, element);
  };
  _no_name_provided__425.prototype.invoke_915 = function (p1, p2) {
    var tmp = (!(p1 == null) ? isInterface(p1, DomEffectScope) : false) ? p1 : THROW_CCE();
    this.invoke_792(tmp, p2 instanceof HTMLSpanElement ? p2 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__425.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__426() {
  }
  _no_name_provided__426.prototype.invoke_864 = function ($this$Span) {
    $this$Span.classes([WtTexts_getInstance()._get_wtText2_()]);
    $this$Span.style_0(_no_name_provided_$factory_414());
  };
  _no_name_provided__426.prototype.invoke_1115 = function (p1) {
    this.invoke_864(p1 instanceof AttrsBuilder ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__426.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__427() {
  }
  _no_name_provided__427.prototype.invoke_864 = function ($this$Span) {
    $this$Span.classes([WtTexts_getInstance()._get_wtText2_()]);
    $this$Span.style_0(_no_name_provided_$factory_415());
    $this$Span.contentEditable_0(true);
    Unit_getInstance();
  };
  _no_name_provided__427.prototype.invoke_1115 = function (p1) {
    this.invoke_864(p1 instanceof AttrsBuilder ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__427.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__428($boundThis) {
    this._$boundThis_50 = $boundThis;
  }
  _no_name_provided__428.prototype.invoke_836 = function (p0, p1, p2) {
    return this._$boundThis_50.invoke_377(p0, p1, p2);
  };
  _no_name_provided__428.prototype.invoke_917 = function (p1, p2, p3) {
    var tmp = (!(p1 == null) ? isInterface(p1, ElementScope) : false) ? p1 : THROW_CCE();
    var tmp_0 = (!(p2 == null) ? isInterface(p2, Composer) : false) ? p2 : THROW_CCE();
    return this.invoke_836(tmp, tmp_0, (!(p3 == null) ? typeof p3 === 'number' : false) ? p3 : THROW_CCE());
  };
  _no_name_provided__428.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__429($codeContext) {
    this._$codeContext_9 = $codeContext;
  }
  _no_name_provided__429.prototype.invoke_838 = function ($this$Span, $composer, $changed) {
    var $composer_0 = $composer;
    var $dirty = $changed;
    if (($changed & 14) === 0)
      $dirty = $dirty | ($composer_0.changed_0($this$Span) ? 4 : 2);
    if (!(($dirty & 91 ^ 18) === 0) ? true : !$composer_0._get_skipping__0()) {
      $this$Span.DomSideEffect$composable_4(_no_name_provided_$factory_416(this._$codeContext_9), $composer_0, 112 & $dirty << 3);
    } else
      $composer_0.skipToGroupEnd_2();
  };
  _no_name_provided__429.prototype.invoke_917 = function (p1, p2, p3) {
    var tmp = (!(p1 == null) ? isInterface(p1, ElementScope) : false) ? p1 : THROW_CCE();
    var tmp_0 = (p2 == null ? true : isInterface(p2, Composer)) ? p2 : THROW_CCE();
    this.invoke_838(tmp, tmp_0, (!(p3 == null) ? typeof p3 === 'number' : false) ? p3 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__429.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__430() {
  }
  _no_name_provided__430.prototype.invoke_890 = function ($this$Div) {
    $this$Div.style_0(_no_name_provided_$factory_406());
  };
  _no_name_provided__430.prototype.invoke_1115 = function (p1) {
    this.invoke_890(p1 instanceof AttrsBuilder ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__430.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__431() {
  }
  _no_name_provided__431.prototype.invoke_890 = function ($this$Hr) {
    $this$Hr.style_0(_no_name_provided_$factory_407());
  };
  _no_name_provided__431.prototype.invoke_1115 = function (p1) {
    this.invoke_890(p1 instanceof AttrsBuilder ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__431.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__432() {
  }
  _no_name_provided__432.prototype.invoke_890 = function ($this$Hr) {
    $this$Hr.style_0(_no_name_provided_$factory_408());
  };
  _no_name_provided__432.prototype.invoke_1115 = function (p1) {
    this.invoke_890(p1 instanceof AttrsBuilder ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__432.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__433() {
  }
  _no_name_provided__433.prototype.invoke_890 = function ($this$Div) {
    $this$Div.style_0(_no_name_provided_$factory_409());
  };
  _no_name_provided__433.prototype.invoke_1115 = function (p1) {
    this.invoke_890(p1 instanceof AttrsBuilder ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__433.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__434($boundThis) {
    this._$boundThis_51 = $boundThis;
  }
  _no_name_provided__434.prototype.invoke_874 = function (p0, p1, p2) {
    return this._$boundThis_51.invoke_377(p0, p1, p2);
  };
  _no_name_provided__434.prototype.invoke_917 = function (p1, p2, p3) {
    var tmp = (!(p1 == null) ? isInterface(p1, ElementScope) : false) ? p1 : THROW_CCE();
    var tmp_0 = (!(p2 == null) ? isInterface(p2, Composer) : false) ? p2 : THROW_CCE();
    return this.invoke_874(tmp, tmp_0, (!(p3 == null) ? typeof p3 === 'number' : false) ? p3 : THROW_CCE());
  };
  _no_name_provided__434.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__435($codeContext) {
    this._$codeContext_10 = $codeContext;
  }
  _no_name_provided__435.prototype.invoke_882 = function ($this$Div, $composer, $changed) {
    var $composer_0 = $composer;
    if (!(($changed & 81 ^ 16) === 0) ? true : !$composer_0._get_skipping__0()) {
      Span$composable(_no_name_provided_$factory_410(), ComposableSingletons$IntroSectionKt_getInstance()._lambda_7_1, $composer_0, 0, 0);
      var tmp = _no_name_provided_$factory_411();
      var tmp_0 = $composer_0;
      Span$composable(tmp, _no_name_provided_$factory_413(composableLambda(tmp_0, -819890333, true, _no_name_provided_$factory_412(this._$codeContext_10))), $composer_0, 0, 0);
    } else
      $composer_0.skipToGroupEnd_2();
  };
  _no_name_provided__435.prototype.invoke_917 = function (p1, p2, p3) {
    var tmp = (!(p1 == null) ? isInterface(p1, ElementScope) : false) ? p1 : THROW_CCE();
    var tmp_0 = (p2 == null ? true : isInterface(p2, Composer)) ? p2 : THROW_CCE();
    this.invoke_882(tmp, tmp_0, (!(p3 == null) ? typeof p3 === 'number' : false) ? p3 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__435.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__436($codeContext) {
    this._$codeContext_11 = $codeContext;
  }
  _no_name_provided__436.prototype.invoke_882 = function ($this$Div, $composer, $changed) {
    var $composer_0 = $composer;
    if (!(($changed & 81 ^ 16) === 0) ? true : !$composer_0._get_skipping__0()) {
      Div$composable(_no_name_provided_$factory_400(), ComposableSingletons$IntroSectionKt_getInstance()._lambda_6_1, $composer_0, 0, 0);
      Hr$composable(_no_name_provided_$factory_401(), $composer_0, 0, 0);
      Hr$composable(_no_name_provided_$factory_402(), $composer_0, 0, 0);
      var tmp = _no_name_provided_$factory_403();
      var tmp_0 = $composer_0;
      Div$composable(tmp, _no_name_provided_$factory_405(composableLambda(tmp_0, -819893923, true, _no_name_provided_$factory_404(this._$codeContext_11))), $composer_0, 0, 0);
      ChiaCodeResult$composable(this._$codeContext_11, $composer_0, 8);
    } else
      $composer_0.skipToGroupEnd_2();
  };
  _no_name_provided__436.prototype.invoke_917 = function (p1, p2, p3) {
    var tmp = (!(p1 == null) ? isInterface(p1, ElementScope) : false) ? p1 : THROW_CCE();
    var tmp_0 = (p2 == null ? true : isInterface(p2, Composer)) ? p2 : THROW_CCE();
    this.invoke_882(tmp, tmp_0, (!(p3 == null) ? typeof p3 === 'number' : false) ? p3 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__436.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__437($codeContext, $$changed) {
    this._$codeContext_12 = $codeContext;
    this._$$changed_22 = $$changed;
  }
  _no_name_provided__437.prototype.invoke_892 = function (p0, p1) {
    return CodeRunner$composable$lambda_3(this._$codeContext_12, this._$$changed_22, p0, p1);
  };
  _no_name_provided__437.prototype.invoke_915 = function (p1, p2) {
    var tmp = (!(p1 == null) ? isInterface(p1, Composer) : false) ? p1 : THROW_CCE();
    this.invoke_892(tmp, (!(p2 == null) ? typeof p2 === 'number' : false) ? p2 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__437.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__438() {
  }
  _no_name_provided__438.prototype.invoke_850 = function ($this$style) {
    var tmp0_value_0 = '12px 16px';
    $this$style.property_4('padding', Companion_getInstance_35().invoke_402(tmp0_value_0));
    display($this$style, DisplayStyle_Flex_getInstance());
    flexDirection($this$style, FlexDirection_Row_getInstance());
    alignItems($this$style, AlignItems_Center_getInstance());
  };
  _no_name_provided__438.prototype.invoke_1115 = function (p1) {
    this.invoke_850((!(p1 == null) ? isInterface(p1, StyleBuilder) : false) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__438.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__439() {
  }
  _no_name_provided__439.prototype.invoke_890 = function ($this$Div) {
    $this$Div.style_0(_no_name_provided_$factory_418());
  };
  _no_name_provided__439.prototype.invoke_1115 = function (p1) {
    this.invoke_890(p1 instanceof AttrsBuilder ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__439.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__440($boundThis) {
    this._$boundThis_52 = $boundThis;
  }
  _no_name_provided__440.prototype.invoke_874 = function (p0, p1, p2) {
    return this._$boundThis_52.invoke_377(p0, p1, p2);
  };
  _no_name_provided__440.prototype.invoke_917 = function (p1, p2, p3) {
    var tmp = (!(p1 == null) ? isInterface(p1, ElementScope) : false) ? p1 : THROW_CCE();
    var tmp_0 = (!(p2 == null) ? isInterface(p2, Composer) : false) ? p2 : THROW_CCE();
    return this.invoke_874(tmp, tmp_0, (!(p3 == null) ? typeof p3 === 'number' : false) ? p3 : THROW_CCE());
  };
  _no_name_provided__440.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__441() {
  }
  _no_name_provided__441.prototype.invoke_850 = function ($this$style) {
    var tmp0_value_0 = _get_px_(8);
    $this$style.property_4('margin-right', Companion_getInstance_35().invoke_396(tmp0_value_0));
  };
  _no_name_provided__441.prototype.invoke_1115 = function (p1) {
    this.invoke_850((!(p1 == null) ? isInterface(p1, StyleBuilder) : false) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__441.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__442() {
  }
  _no_name_provided__442.prototype.invoke_850 = function ($this$style) {
    var tmp0_value_0 = _get_px_(8);
    $this$style.property_4('margin-right', Companion_getInstance_35().invoke_396(tmp0_value_0));
    var tmp1_value_0 = 'anywhere';
    $this$style.property_4('overflow-wrap', Companion_getInstance_35().invoke_402(tmp1_value_0));
  };
  _no_name_provided__442.prototype.invoke_1115 = function (p1) {
    this.invoke_850((!(p1 == null) ? isInterface(p1, StyleBuilder) : false) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__442.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__443() {
  }
  _no_name_provided__443.prototype.invoke_864 = function ($this$Span) {
    $this$Span.classes([WtTexts_getInstance()._get_wtText2_()]);
    $this$Span.style_0(_no_name_provided_$factory_425());
  };
  _no_name_provided__443.prototype.invoke_1115 = function (p1) {
    this.invoke_864(p1 instanceof AttrsBuilder ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__443.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__444($boundThis) {
    this._$boundThis_53 = $boundThis;
  }
  _no_name_provided__444.prototype.invoke_836 = function (p0, p1, p2) {
    return this._$boundThis_53.invoke_377(p0, p1, p2);
  };
  _no_name_provided__444.prototype.invoke_917 = function (p1, p2, p3) {
    var tmp = (!(p1 == null) ? isInterface(p1, ElementScope) : false) ? p1 : THROW_CCE();
    var tmp_0 = (!(p2 == null) ? isInterface(p2, Composer) : false) ? p2 : THROW_CCE();
    return this.invoke_836(tmp, tmp_0, (!(p3 == null) ? typeof p3 === 'number' : false) ? p3 : THROW_CCE());
  };
  _no_name_provided__444.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__445($name) {
    this._$name = $name;
  }
  _no_name_provided__445.prototype.invoke_838 = function ($this$Span, $composer, $changed) {
    var $composer_0 = $composer;
    if (!(($changed & 81 ^ 16) === 0) ? true : !$composer_0._get_skipping__0()) {
      Text$composable('' + this._$name + ':', $composer_0, 0);
    } else
      $composer_0.skipToGroupEnd_2();
  };
  _no_name_provided__445.prototype.invoke_917 = function (p1, p2, p3) {
    var tmp = (!(p1 == null) ? isInterface(p1, ElementScope) : false) ? p1 : THROW_CCE();
    var tmp_0 = (p2 == null ? true : isInterface(p2, Composer)) ? p2 : THROW_CCE();
    this.invoke_838(tmp, tmp_0, (!(p3 == null) ? typeof p3 === 'number' : false) ? p3 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__445.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__446() {
  }
  _no_name_provided__446.prototype.invoke_864 = function ($this$Span) {
    $this$Span.classes([WtTexts_getInstance()._get_wtText3_()]);
    $this$Span.style_0(_no_name_provided_$factory_426());
  };
  _no_name_provided__446.prototype.invoke_1115 = function (p1) {
    this.invoke_864(p1 instanceof AttrsBuilder ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__446.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__447($boundThis) {
    this._$boundThis_54 = $boundThis;
  }
  _no_name_provided__447.prototype.invoke_836 = function (p0, p1, p2) {
    return this._$boundThis_54.invoke_377(p0, p1, p2);
  };
  _no_name_provided__447.prototype.invoke_917 = function (p1, p2, p3) {
    var tmp = (!(p1 == null) ? isInterface(p1, ElementScope) : false) ? p1 : THROW_CCE();
    var tmp_0 = (!(p2 == null) ? isInterface(p2, Composer) : false) ? p2 : THROW_CCE();
    return this.invoke_836(tmp, tmp_0, (!(p3 == null) ? typeof p3 === 'number' : false) ? p3 : THROW_CCE());
  };
  _no_name_provided__447.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__448($value, $$dirty) {
    this._$value_3 = $value;
    this._$$dirty_4 = $$dirty;
  }
  _no_name_provided__448.prototype.invoke_838 = function ($this$Span, $composer, $changed) {
    var $composer_0 = $composer;
    if (!(($changed & 81 ^ 16) === 0) ? true : !$composer_0._get_skipping__0()) {
      Text$composable(this._$value_3, $composer_0, 14 & this._$$dirty_4 >> 3);
    } else
      $composer_0.skipToGroupEnd_2();
  };
  _no_name_provided__448.prototype.invoke_917 = function (p1, p2, p3) {
    var tmp = (!(p1 == null) ? isInterface(p1, ElementScope) : false) ? p1 : THROW_CCE();
    var tmp_0 = (p2 == null ? true : isInterface(p2, Composer)) ? p2 : THROW_CCE();
    this.invoke_838(tmp, tmp_0, (!(p3 == null) ? typeof p3 === 'number' : false) ? p3 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__448.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__449($name, $value, $$dirty) {
    this._$name_0 = $name;
    this._$value_4 = $value;
    this._$$dirty_5 = $$dirty;
  }
  _no_name_provided__449.prototype.invoke_882 = function ($this$Div, $composer, $changed) {
    var $composer_0 = $composer;
    if (!(($changed & 81 ^ 16) === 0) ? true : !$composer_0._get_skipping__0()) {
      var tmp = _no_name_provided_$factory_419();
      var tmp_0 = $composer_0;
      Span$composable(tmp, _no_name_provided_$factory_421(composableLambda(tmp_0, -819891604, true, _no_name_provided_$factory_420(this._$name_0))), $composer_0, 0, 0);
      var tmp_1 = _no_name_provided_$factory_422();
      var tmp_2 = $composer_0;
      Span$composable(tmp_1, _no_name_provided_$factory_424(composableLambda(tmp_2, -819891375, true, _no_name_provided_$factory_423(this._$value_4, this._$$dirty_5))), $composer_0, 0, 0);
    } else
      $composer_0.skipToGroupEnd_2();
  };
  _no_name_provided__449.prototype.invoke_917 = function (p1, p2, p3) {
    var tmp = (!(p1 == null) ? isInterface(p1, ElementScope) : false) ? p1 : THROW_CCE();
    var tmp_0 = (p2 == null ? true : isInterface(p2, Composer)) ? p2 : THROW_CCE();
    this.invoke_882(tmp, tmp_0, (!(p3 == null) ? typeof p3 === 'number' : false) ? p3 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__449.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__450($name, $value, $$changed) {
    this._$name_1 = $name;
    this._$value_5 = $value;
    this._$$changed_23 = $$changed;
  }
  _no_name_provided__450.prototype.invoke_892 = function (p0, p1) {
    return ResultBox$composable$lambda_4(this._$name_1, this._$value_5, this._$$changed_23, p0, p1);
  };
  _no_name_provided__450.prototype.invoke_915 = function (p1, p2) {
    var tmp = (!(p1 == null) ? isInterface(p1, Composer) : false) ? p1 : THROW_CCE();
    this.invoke_892(tmp, (!(p2 == null) ? typeof p2 === 'number' : false) ? p2 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__450.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__451() {
  }
  _no_name_provided__451.prototype.invoke_850 = function ($this$style) {
    var tmp0_value_0 = '12px 16px';
    $this$style.property_4('padding', Companion_getInstance_35().invoke_402(tmp0_value_0));
    display($this$style, DisplayStyle_Flex_getInstance());
    flexDirection($this$style, FlexDirection_Row_getInstance());
    alignItems($this$style, AlignItems_Center_getInstance());
  };
  _no_name_provided__451.prototype.invoke_1115 = function (p1) {
    this.invoke_850((!(p1 == null) ? isInterface(p1, StyleBuilder) : false) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__451.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__452() {
  }
  _no_name_provided__452.prototype.invoke_890 = function ($this$Div) {
    $this$Div.style_0(_no_name_provided_$factory_427());
  };
  _no_name_provided__452.prototype.invoke_1115 = function (p1) {
    this.invoke_890(p1 instanceof AttrsBuilder ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__452.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__453($boundThis) {
    this._$boundThis_55 = $boundThis;
  }
  _no_name_provided__453.prototype.invoke_874 = function (p0, p1, p2) {
    return this._$boundThis_55.invoke_377(p0, p1, p2);
  };
  _no_name_provided__453.prototype.invoke_917 = function (p1, p2, p3) {
    var tmp = (!(p1 == null) ? isInterface(p1, ElementScope) : false) ? p1 : THROW_CCE();
    var tmp_0 = (!(p2 == null) ? isInterface(p2, Composer) : false) ? p2 : THROW_CCE();
    return this.invoke_874(tmp, tmp_0, (!(p3 == null) ? typeof p3 === 'number' : false) ? p3 : THROW_CCE());
  };
  _no_name_provided__453.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__454() {
  }
  _no_name_provided__454.prototype.invoke_850 = function ($this$style) {
    var tmp0_value_0 = _get_px_(8);
    $this$style.property_4('margin-right', Companion_getInstance_35().invoke_396(tmp0_value_0));
  };
  _no_name_provided__454.prototype.invoke_1115 = function (p1) {
    this.invoke_850((!(p1 == null) ? isInterface(p1, StyleBuilder) : false) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__454.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__455($codeContext, $lastProgOutput$delegate, $lastProgSexpOut$delegate, $lastProgHex$delegate, $codeRunState$delegate) {
    this._$codeContext_13 = $codeContext;
    this._$lastProgOutput$delegate = $lastProgOutput$delegate;
    this._$lastProgSexpOut$delegate = $lastProgSexpOut$delegate;
    this._$lastProgHex$delegate = $lastProgHex$delegate;
    this._$codeRunState$delegate = $codeRunState$delegate;
  }
  _no_name_provided__455.prototype.invoke_860 = function (it) {
    invoke$compile(this._$codeContext_13, this._$lastProgOutput$delegate, this._$lastProgSexpOut$delegate, this._$lastProgHex$delegate, this._$codeRunState$delegate);
    Unit_getInstance();
  };
  _no_name_provided__455.prototype.invoke_1115 = function (p1) {
    this.invoke_860(p1 instanceof WrappedMouseEvent ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__455.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__456($codeContext, $lastProgOutput$delegate, $lastProgSexpOut$delegate, $lastProgHex$delegate, $codeRunState$delegate) {
    this._$codeContext_14 = $codeContext;
    this._$lastProgOutput$delegate_0 = $lastProgOutput$delegate;
    this._$lastProgSexpOut$delegate_0 = $lastProgSexpOut$delegate;
    this._$lastProgHex$delegate_0 = $lastProgHex$delegate;
    this._$codeRunState$delegate_0 = $codeRunState$delegate;
  }
  _no_name_provided__456.prototype.invoke_860 = function (it) {
    invoke$runWithArgs(this._$codeContext_14, this._$lastProgOutput$delegate_0, this._$lastProgSexpOut$delegate_0, this._$lastProgHex$delegate_0, this._$codeRunState$delegate_0);
    Unit_getInstance();
  };
  _no_name_provided__456.prototype.invoke_1115 = function (p1) {
    this.invoke_860(p1 instanceof WrappedMouseEvent ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__456.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__457($codeContext, $lastProgOutput$delegate, $lastProgSexpOut$delegate, $lastProgHex$delegate, $codeRunState$delegate) {
    this._$codeContext_15 = $codeContext;
    this._$lastProgOutput$delegate_1 = $lastProgOutput$delegate;
    this._$lastProgSexpOut$delegate_1 = $lastProgSexpOut$delegate;
    this._$lastProgHex$delegate_1 = $lastProgHex$delegate;
    this._$codeRunState$delegate_1 = $codeRunState$delegate;
  }
  _no_name_provided__457.prototype.invoke_862 = function ($this$Button) {
    $this$Button.onClick$default_0(null, _no_name_provided_$factory_441(this._$codeContext_15, this._$lastProgOutput$delegate_1, this._$lastProgSexpOut$delegate_1, this._$lastProgHex$delegate_1, this._$codeRunState$delegate_1), 1, null);
  };
  _no_name_provided__457.prototype.invoke_1115 = function (p1) {
    this.invoke_862(p1 instanceof AttrsBuilder ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__457.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__458($codeContext, $lastProgOutput$delegate, $lastProgSexpOut$delegate, $lastProgHex$delegate, $codeRunState$delegate) {
    this._$codeContext_16 = $codeContext;
    this._$lastProgOutput$delegate_2 = $lastProgOutput$delegate;
    this._$lastProgSexpOut$delegate_2 = $lastProgSexpOut$delegate;
    this._$lastProgHex$delegate_2 = $lastProgHex$delegate;
    this._$codeRunState$delegate_2 = $codeRunState$delegate;
  }
  _no_name_provided__458.prototype.invoke_862 = function ($this$Button) {
    $this$Button.onClick$default_0(null, _no_name_provided_$factory_442(this._$codeContext_16, this._$lastProgOutput$delegate_2, this._$lastProgSexpOut$delegate_2, this._$lastProgHex$delegate_2, this._$codeRunState$delegate_2), 1, null);
  };
  _no_name_provided__458.prototype.invoke_1115 = function (p1) {
    this.invoke_862(p1 instanceof AttrsBuilder ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__458.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__459($codeContext) {
    this._$codeContext_17 = $codeContext;
  }
  _no_name_provided__459.prototype.invoke_860 = function (it) {
    var tmp0_safe_receiver = this._$codeContext_17._runnerJob_0;
    if (tmp0_safe_receiver == null)
      null;
    else {
      tmp0_safe_receiver.cancel$default_13(null, 1, null);
      Unit_getInstance();
    }
    Unit_getInstance();
  };
  _no_name_provided__459.prototype.invoke_1115 = function (p1) {
    this.invoke_860(p1 instanceof WrappedMouseEvent ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__459.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__460($codeContext) {
    this._$codeContext_18 = $codeContext;
  }
  _no_name_provided__460.prototype.invoke_862 = function ($this$Button) {
    $this$Button.onClick$default_0(null, _no_name_provided_$factory_444(this._$codeContext_18), 1, null);
  };
  _no_name_provided__460.prototype.invoke_1115 = function (p1) {
    this.invoke_862(p1 instanceof AttrsBuilder ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__460.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function invoke$compile($codeContext, lastProgOutput$delegate, lastProgSexpOut$delegate, lastProgHex$delegate, codeRunState$delegate) {
    $codeContext._lastProgramCode = $codeContext._code;
    var tmp = MainScope();
    var def = async$default(tmp, null, null, _no_name_provided_$factory_436($codeContext, lastProgOutput$delegate, lastProgSexpOut$delegate, lastProgHex$delegate, codeRunState$delegate, null), 3, null);
    ChiaCodeResult$composable$lambda_12(codeRunState$delegate, CodeRunState_COMPILING_getInstance());
    $codeContext._runnerJob_0 = _get_job_(def);
    return def;
  }
  function invoke$runWithArgs($codeContext, lastProgOutput$delegate, lastProgSexpOut$delegate, lastProgHex$delegate, codeRunState$delegate) {
    var tmp = MainScope();
    var def = async$default(tmp, null, null, _no_name_provided_$factory_437($codeContext, lastProgOutput$delegate, lastProgSexpOut$delegate, lastProgHex$delegate, codeRunState$delegate, null), 3, null);
    ChiaCodeResult$composable$lambda_12(codeRunState$delegate, CodeRunState_COMPILING_getInstance());
    $codeContext._runnerJob_0 = _get_job_(def);
    return def;
  }
  function _no_name_provided__461() {
  }
  _no_name_provided__461.prototype.invoke_864 = function ($this$Span) {
    $this$Span.classes([WtTexts_getInstance()._get_wtText2_()]);
    $this$Span.style_0(_no_name_provided_$factory_438());
  };
  _no_name_provided__461.prototype.invoke_1115 = function (p1) {
    this.invoke_864(p1 instanceof AttrsBuilder ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__461.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__462() {
  }
  _no_name_provided__462.prototype.invoke_890 = function ($this$Div) {
    $this$Div.id_0('actionsContainer');
    Unit_getInstance();
  };
  _no_name_provided__462.prototype.invoke_1115 = function (p1) {
    this.invoke_890(p1 instanceof AttrsBuilder ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__462.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__463($boundThis) {
    this._$boundThis_56 = $boundThis;
  }
  _no_name_provided__463.prototype.invoke_874 = function (p0, p1, p2) {
    return this._$boundThis_56.invoke_377(p0, p1, p2);
  };
  _no_name_provided__463.prototype.invoke_917 = function (p1, p2, p3) {
    var tmp = (!(p1 == null) ? isInterface(p1, ElementScope) : false) ? p1 : THROW_CCE();
    var tmp_0 = (!(p2 == null) ? isInterface(p2, Composer) : false) ? p2 : THROW_CCE();
    return this.invoke_874(tmp, tmp_0, (!(p3 == null) ? typeof p3 === 'number' : false) ? p3 : THROW_CCE());
  };
  _no_name_provided__463.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__464($codeContext, $lastProgOutput$delegate, $lastProgSexpOut$delegate, $lastProgHex$delegate, $codeRunState$delegate) {
    this._$codeContext_19 = $codeContext;
    this._$lastProgOutput$delegate_3 = $lastProgOutput$delegate;
    this._$lastProgSexpOut$delegate_3 = $lastProgSexpOut$delegate;
    this._$lastProgHex$delegate_3 = $lastProgHex$delegate;
    this._$codeRunState$delegate_3 = $codeRunState$delegate;
  }
  _no_name_provided__464.prototype.invoke_882 = function ($this$Div, $composer, $changed) {
    var $composer_0 = $composer;
    if (!(($changed & 81 ^ 16) === 0) ? true : !$composer_0._get_skipping__0()) {
      Button$composable(_no_name_provided_$factory_439(this._$codeContext_19, this._$lastProgOutput$delegate_3, this._$lastProgSexpOut$delegate_3, this._$lastProgHex$delegate_3, this._$codeRunState$delegate_3), ComposableSingletons$IntroSectionKt_getInstance()._lambda_9_0, $composer_0, 0, 0);
      Button$composable(_no_name_provided_$factory_440(this._$codeContext_19, this._$lastProgOutput$delegate_3, this._$lastProgSexpOut$delegate_3, this._$lastProgHex$delegate_3, this._$codeRunState$delegate_3), ComposableSingletons$IntroSectionKt_getInstance()._lambda_10, $composer_0, 0, 0);
    } else
      $composer_0.skipToGroupEnd_2();
  };
  _no_name_provided__464.prototype.invoke_917 = function (p1, p2, p3) {
    var tmp = (!(p1 == null) ? isInterface(p1, ElementScope) : false) ? p1 : THROW_CCE();
    var tmp_0 = (p2 == null ? true : isInterface(p2, Composer)) ? p2 : THROW_CCE();
    this.invoke_882(tmp, tmp_0, (!(p3 == null) ? typeof p3 === 'number' : false) ? p3 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__464.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__465() {
  }
  _no_name_provided__465.prototype.invoke_890 = function ($this$Div) {
    $this$Div.id_0('actionsContainer');
    Unit_getInstance();
  };
  _no_name_provided__465.prototype.invoke_1115 = function (p1) {
    this.invoke_890(p1 instanceof AttrsBuilder ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__465.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__466($boundThis) {
    this._$boundThis_57 = $boundThis;
  }
  _no_name_provided__466.prototype.invoke_874 = function (p0, p1, p2) {
    return this._$boundThis_57.invoke_377(p0, p1, p2);
  };
  _no_name_provided__466.prototype.invoke_917 = function (p1, p2, p3) {
    var tmp = (!(p1 == null) ? isInterface(p1, ElementScope) : false) ? p1 : THROW_CCE();
    var tmp_0 = (!(p2 == null) ? isInterface(p2, Composer) : false) ? p2 : THROW_CCE();
    return this.invoke_874(tmp, tmp_0, (!(p3 == null) ? typeof p3 === 'number' : false) ? p3 : THROW_CCE());
  };
  _no_name_provided__466.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__467($codeContext) {
    this._$codeContext_20 = $codeContext;
  }
  _no_name_provided__467.prototype.invoke_882 = function ($this$Div, $composer, $changed) {
    var $composer_0 = $composer;
    if (!(($changed & 81 ^ 16) === 0) ? true : !$composer_0._get_skipping__0()) {
      Button$composable(_no_name_provided_$factory_443(this._$codeContext_20), ComposableSingletons$IntroSectionKt_getInstance()._lambda_11, $composer_0, 0, 0);
    } else
      $composer_0.skipToGroupEnd_2();
  };
  _no_name_provided__467.prototype.invoke_917 = function (p1, p2, p3) {
    var tmp = (!(p1 == null) ? isInterface(p1, ElementScope) : false) ? p1 : THROW_CCE();
    var tmp_0 = (p2 == null ? true : isInterface(p2, Composer)) ? p2 : THROW_CCE();
    this.invoke_882(tmp, tmp_0, (!(p3 == null) ? typeof p3 === 'number' : false) ? p3 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__467.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__468() {
  }
  _no_name_provided__468.prototype.invoke_890 = function ($this$Div) {
    $this$Div.id_0('actionsContainer');
    Unit_getInstance();
  };
  _no_name_provided__468.prototype.invoke_1115 = function (p1) {
    this.invoke_890(p1 instanceof AttrsBuilder ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__468.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__469($codeContext, $lastProgOutput$delegate, $lastProgSexpOut$delegate, $lastProgHex$delegate, $codeRunState$delegate, resultContinuation) {
    this._$codeContext_21 = $codeContext;
    this._$lastProgOutput$delegate_4 = $lastProgOutput$delegate;
    this._$lastProgSexpOut$delegate_4 = $lastProgSexpOut$delegate;
    this._$lastProgHex$delegate_4 = $lastProgHex$delegate;
    this._$codeRunState$delegate_4 = $codeRunState$delegate;
    CoroutineImpl_0.call(this, resultContinuation);
  }
  _no_name_provided__469.prototype.invoke_881 = function ($this$async, $cont) {
    var tmp = this.create_13($this$async, $cont);
    tmp._result_0 = Unit_getInstance();
    tmp._exception_0 = null;
    return tmp.doResume_17();
  };
  _no_name_provided__469.prototype.doResume_17 = function () {
    var suspendResult = this._result_0;
    $sm: do
      try {
        var tmp = this._state_2;
        if (tmp === 0) {
          this._exceptionState = 1;
          var tmp_0;
          try {
            var result = Clvmc_getInstance().compileFromText(this._$codeContext_21._code);
            this._$codeContext_21._program = _Program___init__impl_(result);
            ChiaCodeResult$composable$lambda_10(this._$lastProgOutput$delegate_4, '' + 'Compile Result: ' + dissasemble(result));
            ChiaCodeResult$composable$lambda_8(this._$lastProgSexpOut$delegate_4, result._get_hex_());
            ChiaCodeResult$composable$lambda_6(this._$lastProgHex$delegate_4, assemble(this._$codeContext_21._code)._get_hex_());
            ChiaCodeResult$composable$lambda_12(this._$codeRunState$delegate_4, CodeRunState_STABNDY_getInstance());
            tmp_0 = true;
          } catch ($p) {
            var tmp_1;
            if ($p instanceof Exception) {
              ChiaCodeResult$composable$lambda_6(this._$lastProgHex$delegate_4, '');
              ChiaCodeResult$composable$lambda_8(this._$lastProgSexpOut$delegate_4, '');
              ChiaCodeResult$composable$lambda_10(this._$lastProgOutput$delegate_4, '');
              var tmp0_elvis_lhs = $p.message;
              ChiaCodeResult$composable$lambda_10(this._$lastProgOutput$delegate_4, tmp0_elvis_lhs == null ? 'Unknown Null' : tmp0_elvis_lhs);
              ChiaCodeResult$composable$lambda_12(this._$codeRunState$delegate_4, CodeRunState_STABNDY_getInstance());
              tmp_1 = false;
            } else {
              {
                throw $p;
              }
            }
            tmp_0 = tmp_1;
          }
          return tmp_0;
        } else if (tmp === 1) {
          throw this._exception_0;
        }} catch ($p_0) {
        throw $p_0;
      }
     while (true);
  };
  _no_name_provided__469.prototype.create_13 = function ($this$async, completion) {
    var i = new _no_name_provided__469(this._$codeContext_21, this._$lastProgOutput$delegate_4, this._$lastProgSexpOut$delegate_4, this._$lastProgHex$delegate_4, this._$codeRunState$delegate_4, completion);
    i._$this$async = $this$async;
    return i;
  };
  _no_name_provided__469.$metadata$ = {
    kind: 'class',
    interfaces: [],
    suspendArity: 1
  };
  function _no_name_provided__470($codeContext, $lastProgOutput$delegate, $lastProgSexpOut$delegate, $lastProgHex$delegate, $codeRunState$delegate, resultContinuation) {
    this._$codeContext_22 = $codeContext;
    this._$lastProgOutput$delegate_5 = $lastProgOutput$delegate;
    this._$lastProgSexpOut$delegate_5 = $lastProgSexpOut$delegate;
    this._$lastProgHex$delegate_5 = $lastProgHex$delegate;
    this._$codeRunState$delegate_5 = $codeRunState$delegate;
    CoroutineImpl_0.call(this, resultContinuation);
  }
  _no_name_provided__470.prototype.invoke_881 = function ($this$async, $cont) {
    var tmp = this.create_13($this$async, $cont);
    tmp._result_0 = Unit_getInstance();
    tmp._exception_0 = null;
    return tmp.doResume_17();
  };
  _no_name_provided__470.prototype.doResume_17 = function () {
    var suspendResult = this._result_0;
    $sm: do
      try {
        var tmp = this._state_2;
        switch (tmp) {
          case 0:
            this._exceptionState = 6;
            if (!(this._$codeContext_22._code === this._$codeContext_22._lastProgramCode)) {
              this._state_2 = 1;
              suspendResult = invoke$compile(this._$codeContext_22, this._$lastProgOutput$delegate_5, this._$lastProgSexpOut$delegate_5, this._$lastProgHex$delegate_5, this._$codeRunState$delegate_5).await_1(this);
              if (suspendResult === _get_COROUTINE_SUSPENDED_()) {
                return suspendResult;
              }continue $sm;
            } else {
              this._state_2 = 3;
              continue $sm;
            }

            break;
          case 1:
            var ARGUMENT = suspendResult;
            if (!ARGUMENT) {
              return false;
            } else {
              this._state_2 = 2;
              continue $sm;
            }

            break;
          case 2:
            this._state_2 = 3;
            continue $sm;
          case 3:
            var TRY_RESULT;
            this._exceptionState = 4;
            var argExp = assemble(this._$codeContext_22._args);
            var tmp_0 = this._$codeContext_22._program;
            var result = Program__run_impl(ensureNotNull(tmp_0 == null ? null : new Program(tmp_0))._sexp_0, argExp);
            ChiaCodeResult$composable$lambda_10(this._$lastProgOutput$delegate_5, '' + 'Run Result: ' + dissasemble(result));
            ChiaCodeResult$composable$lambda_12(this._$codeRunState$delegate_5, CodeRunState_STABNDY_getInstance());
            TRY_RESULT = true;
            this._exceptionState = 6;
            this._state_2 = 5;
            continue $sm;
          case 4:
            this._exceptionState = 6;
            var tmp_1 = this._exception_0;
            if (tmp_1 instanceof Exception) {
              var e = this._exception_0;
              var tmp0_elvis_lhs = e.message;
              ChiaCodeResult$composable$lambda_10(this._$lastProgOutput$delegate_5, tmp0_elvis_lhs == null ? 'Unknown Null' : tmp0_elvis_lhs);
              ChiaCodeResult$composable$lambda_12(this._$codeRunState$delegate_5, CodeRunState_STABNDY_getInstance());
              TRY_RESULT = false;
              this._state_2 = 5;
              continue $sm;
            } else {
              {
                throw this._exception_0;
              }
            }

            break;
          case 5:
            this._exceptionState = 6;
            return TRY_RESULT;
          case 6:
            throw this._exception_0;
        }
      } catch ($p) {
        if (this._exceptionState === 6) {
          throw $p;
        } else {
          this._state_2 = this._exceptionState;
          this._exception_0 = $p;
        }
      }
     while (true);
  };
  _no_name_provided__470.prototype.create_13 = function ($this$async, completion) {
    var i = new _no_name_provided__470(this._$codeContext_22, this._$lastProgOutput$delegate_5, this._$lastProgSexpOut$delegate_5, this._$lastProgHex$delegate_5, this._$codeRunState$delegate_5, completion);
    i._$this$async_0 = $this$async;
    return i;
  };
  _no_name_provided__470.$metadata$ = {
    kind: 'class',
    interfaces: [],
    suspendArity: 1
  };
  function _no_name_provided__471($codeRunState$delegate, $codeContext, $lastProgOutput$delegate, $lastProgSexpOut$delegate, $lastProgHex$delegate) {
    this._$codeRunState$delegate_6 = $codeRunState$delegate;
    this._$codeContext_23 = $codeContext;
    this._$lastProgOutput$delegate_6 = $lastProgOutput$delegate;
    this._$lastProgSexpOut$delegate_6 = $lastProgSexpOut$delegate;
    this._$lastProgHex$delegate_6 = $lastProgHex$delegate;
  }
  _no_name_provided__471.prototype.invoke_882 = function ($this$Div, $composer, $changed) {
    var $composer_0 = $composer;
    if (!(($changed & 81 ^ 16) === 0) ? true : !$composer_0._get_skipping__0()) {
      Span$composable(_no_name_provided_$factory_428(), ComposableSingletons$IntroSectionKt_getInstance()._lambda_8_0, $composer_0, 0, 0);
      var tmp0_subject = ChiaCodeResult$composable$lambda_11(this._$codeRunState$delegate_6);
      if (tmp0_subject.equals(CodeRunState_STABNDY_getInstance())) {
        $composer_0.startReplaceableGroup_0(-812888600);
        var tmp = _no_name_provided_$factory_429();
        var tmp_0 = $composer_0;
        Div$composable(tmp, _no_name_provided_$factory_431(composableLambda(tmp_0, -819902629, true, _no_name_provided_$factory_430(this._$codeContext_23, this._$lastProgOutput$delegate_6, this._$lastProgSexpOut$delegate_6, this._$lastProgHex$delegate_6, this._$codeRunState$delegate_6))), $composer_0, 0, 0);
        $composer_0.endReplaceableGroup_0();
      } else if (tmp0_subject.equals(CodeRunState_COMPILING_getInstance())) {
        $composer_0.startReplaceableGroup_0(-812888100);
        var tmp_1 = _no_name_provided_$factory_432();
        var tmp_2 = $composer_0;
        Div$composable(tmp_1, _no_name_provided_$factory_434(composableLambda(tmp_2, -819903161, true, _no_name_provided_$factory_433(this._$codeContext_23))), $composer_0, 0, 0);
        $composer_0.endReplaceableGroup_0();
      } else {
        $composer_0.startReplaceableGroup_0(-812887763);
        Div$composable(_no_name_provided_$factory_435(), ComposableSingletons$IntroSectionKt_getInstance()._lambda_12, $composer_0, 0, 0);
        $composer_0.endReplaceableGroup_0();
      }
    } else
      $composer_0.skipToGroupEnd_2();
  };
  _no_name_provided__471.prototype.invoke_917 = function (p1, p2, p3) {
    var tmp = (!(p1 == null) ? isInterface(p1, ElementScope) : false) ? p1 : THROW_CCE();
    var tmp_0 = (p2 == null ? true : isInterface(p2, Composer)) ? p2 : THROW_CCE();
    this.invoke_882(tmp, tmp_0, (!(p3 == null) ? typeof p3 === 'number' : false) ? p3 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__471.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__472($codeContext, $$changed) {
    this._$codeContext_24 = $codeContext;
    this._$$changed_24 = $$changed;
  }
  _no_name_provided__472.prototype.invoke_892 = function (p0, p1) {
    return ChiaCodeResult$composable$lambda_13(this._$codeContext_24, this._$$changed_24, p0, p1);
  };
  _no_name_provided__472.prototype.invoke_915 = function (p1, p2) {
    var tmp = (!(p1 == null) ? isInterface(p1, Composer) : false) ? p1 : THROW_CCE();
    this.invoke_892(tmp, (!(p2 == null) ? typeof p2 === 'number' : false) ? p2 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__472.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__473() {
  }
  _no_name_provided__473.prototype.invoke_890 = function ($this$Div) {
    $this$Div.classes([WtRows_getInstance()._get_wtRow_(), WtRows_getInstance()._get_wtRowSizeXs_(), WtOffsets_getInstance()._get_wtTopOffset24_()]);
  };
  _no_name_provided__473.prototype.invoke_1115 = function (p1) {
    this.invoke_890(p1 instanceof AttrsBuilder ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__473.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__474($$changed) {
    this._$$changed_25 = $$changed;
  }
  _no_name_provided__474.prototype.invoke_892 = function (p0, p1) {
    return ComposeWebStatusMessage$composable$lambda_14(this._$$changed_25, p0, p1);
  };
  _no_name_provided__474.prototype.invoke_915 = function (p1, p2) {
    var tmp = (!(p1 == null) ? isInterface(p1, Composer) : false) ? p1 : THROW_CCE();
    this.invoke_892(tmp, (!(p2 == null) ? typeof p2 === 'number' : false) ? p2 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__474.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__475() {
  }
  _no_name_provided__475.prototype.invoke_890 = function ($this$null) {
    return Unit_getInstance();
  };
  _no_name_provided__475.prototype.invoke_1115 = function (p1) {
    this.invoke_890(p1 instanceof AttrsBuilder ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__475.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__476($attrs, $$changed, $$default) {
    this._$attrs_14 = $attrs;
    this._$$changed_26 = $$changed;
    this._$$default_12 = $$default;
  }
  _no_name_provided__476.prototype.invoke_892 = function (p0, p1) {
    return Hr$composable$lambda_15(this._$attrs_14, this._$$changed_26, this._$$default_12, p0, p1);
  };
  _no_name_provided__476.prototype.invoke_915 = function (p1, p2) {
    var tmp = (!(p1 == null) ? isInterface(p1, Composer) : false) ? p1 : THROW_CCE();
    this.invoke_892(tmp, (!(p2 == null) ? typeof p2 === 'number' : false) ? p2 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__476.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function CodeRunState_STABNDY_getInstance() {
    CodeRunState_initEntries();
    return CodeRunState_STABNDY_instance;
  }
  function CodeRunState_COMPILING_getInstance() {
    CodeRunState_initEntries();
    return CodeRunState_COMPILING_instance;
  }
  function _no_name_provided_$factory_317() {
    var i = new _no_name_provided__360();
    return function (p1, p2, p3) {
      i.invoke_838(p1, p2, p3);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_318($boundThis) {
    var i = new _no_name_provided__359($boundThis);
    return function (p1, p2, p3) {
      return i.invoke_836(p1, p2, p3);
    };
  }
  function _no_name_provided_$factory_319() {
    var i = new _no_name_provided__362();
    return function (p1, p2, p3) {
      i.invoke_838(p1, p2, p3);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_320($boundThis) {
    var i = new _no_name_provided__361($boundThis);
    return function (p1, p2, p3) {
      return i.invoke_836(p1, p2, p3);
    };
  }
  function _no_name_provided_$factory_321() {
    var i = new _no_name_provided__364();
    return function (p1, p2, p3) {
      i.invoke_702(p1, p2, p3);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_322($boundThis) {
    var i = new _no_name_provided__363($boundThis);
    return function (p1, p2, p3) {
      return i.invoke_700(p1, p2, p3);
    };
  }
  function _no_name_provided_$factory_323() {
    var i = new _no_name_provided__366();
    return function (p1, p2, p3) {
      i.invoke_838(p1, p2, p3);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_324($boundThis) {
    var i = new _no_name_provided__365($boundThis);
    return function (p1, p2, p3) {
      return i.invoke_836(p1, p2, p3);
    };
  }
  function _no_name_provided_$factory_325() {
    var i = new _no_name_provided__368();
    return function (p1, p2, p3) {
      i.invoke_882(p1, p2, p3);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_326($boundThis) {
    var i = new _no_name_provided__367($boundThis);
    return function (p1, p2, p3) {
      return i.invoke_874(p1, p2, p3);
    };
  }
  function _no_name_provided_$factory_327() {
    var i = new _no_name_provided__370();
    return function (p1, p2, p3) {
      i.invoke_882(p1, p2, p3);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_328($boundThis) {
    var i = new _no_name_provided__369($boundThis);
    return function (p1, p2, p3) {
      return i.invoke_874(p1, p2, p3);
    };
  }
  function _no_name_provided_$factory_329() {
    var i = new _no_name_provided__372();
    return function (p1, p2, p3) {
      i.invoke_838(p1, p2, p3);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_330($boundThis) {
    var i = new _no_name_provided__371($boundThis);
    return function (p1, p2, p3) {
      return i.invoke_836(p1, p2, p3);
    };
  }
  function _no_name_provided_$factory_331() {
    var i = new _no_name_provided__374();
    return function (p1, p2, p3) {
      i.invoke_838(p1, p2, p3);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_332($boundThis) {
    var i = new _no_name_provided__373($boundThis);
    return function (p1, p2, p3) {
      return i.invoke_836(p1, p2, p3);
    };
  }
  function _no_name_provided_$factory_333() {
    var i = new _no_name_provided__376();
    return function (p1, p2, p3) {
      i.invoke_702(p1, p2, p3);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_334($boundThis) {
    var i = new _no_name_provided__375($boundThis);
    return function (p1, p2, p3) {
      return i.invoke_700(p1, p2, p3);
    };
  }
  function _no_name_provided_$factory_335() {
    var i = new _no_name_provided__378();
    return function (p1, p2, p3) {
      i.invoke_702(p1, p2, p3);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_336($boundThis) {
    var i = new _no_name_provided__377($boundThis);
    return function (p1, p2, p3) {
      return i.invoke_700(p1, p2, p3);
    };
  }
  function _no_name_provided_$factory_337() {
    var i = new _no_name_provided__380();
    return function (p1, p2, p3) {
      i.invoke_702(p1, p2, p3);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_338($boundThis) {
    var i = new _no_name_provided__379($boundThis);
    return function (p1, p2, p3) {
      return i.invoke_700(p1, p2, p3);
    };
  }
  function _no_name_provided_$factory_339() {
    var i = new _no_name_provided__382();
    return function (p1, p2, p3) {
      i.invoke_882(p1, p2, p3);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_340($boundThis) {
    var i = new _no_name_provided__381($boundThis);
    return function (p1, p2, p3) {
      return i.invoke_874(p1, p2, p3);
    };
  }
  function _no_name_provided_$factory_341() {
    var i = new _no_name_provided__384();
    return function (p1, p2, p3) {
      i.invoke_710(p1, p2, p3);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_342($boundThis) {
    var i = new _no_name_provided__383($boundThis);
    return function (p1, p2, p3) {
      return i.invoke_708(p1, p2, p3);
    };
  }
  function _no_name_provided_$factory_343() {
    var i = new _no_name_provided__386();
    return function (p1, p2, p3) {
      i.invoke_882(p1, p2, p3);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_344($boundThis) {
    var i = new _no_name_provided__385($boundThis);
    return function (p1, p2, p3) {
      return i.invoke_874(p1, p2, p3);
    };
  }
  function _no_name_provided_$factory_345() {
    var i = new _no_name_provided__388();
    return function (p1, p2, p3) {
      i.invoke_718(p1, p2, p3);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_346($boundThis) {
    var i = new _no_name_provided__387($boundThis);
    return function (p1, p2, p3) {
      return i.invoke_716(p1, p2, p3);
    };
  }
  function _no_name_provided_$factory_347() {
    var i = new _no_name_provided__390();
    return function (p1, p2, p3) {
      i.invoke_882(p1, p2, p3);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_348($boundThis) {
    var i = new _no_name_provided__389($boundThis);
    return function (p1, p2, p3) {
      return i.invoke_874(p1, p2, p3);
    };
  }
  function _no_name_provided_$factory_349() {
    var i = new _no_name_provided__392();
    return function (p1, p2, p3) {
      i.invoke_882(p1, p2, p3);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_350($boundThis) {
    var i = new _no_name_provided__391($boundThis);
    return function (p1, p2, p3) {
      return i.invoke_874(p1, p2, p3);
    };
  }
  function _no_name_provided_$factory_351() {
    var i = new _no_name_provided__394();
    return function (p1, p2, p3) {
      i.invoke_730(p1, p2, p3);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_352($boundThis) {
    var i = new _no_name_provided__393($boundThis);
    return function (p1, p2, p3) {
      return i.invoke_728(p1, p2, p3);
    };
  }
  function _no_name_provided_$factory_353() {
    var i = new _no_name_provided__349();
    return function (p1) {
      i.invoke_864(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_354() {
    var i = new _no_name_provided__351();
    return function (p1) {
      i.invoke_864(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_355() {
    var i = new _no_name_provided__350();
    return function (p1) {
      i.invoke_850(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_356() {
    var i = new _no_name_provided__353();
    return function (p1) {
      i.invoke_864(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_357() {
    var i = new _no_name_provided__352();
    return function (p1) {
      i.invoke_850(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_358() {
    var i = new _no_name_provided__355();
    return function (p1) {
      i.invoke_652(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_359() {
    var i = new _no_name_provided__354();
    return function (p1) {
      i.invoke_850(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_360() {
    var i = new _no_name_provided__356();
    return function (p1) {
      i.invoke_654(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_361() {
    var i = new _no_name_provided__357();
    return function (p1) {
      i.invoke_890(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_362() {
    var i = new _no_name_provided__358();
    return function (p1) {
      i.invoke_890(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_363($codeContext) {
    var i = new _no_name_provided__406($codeContext);
    return function (p1, p2) {
      i.invoke_914(p1, p2);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_364($boundThis) {
    var i = new _no_name_provided__395($boundThis);
    return function (p1, p2) {
      return i.invoke_912(p1, p2);
    };
  }
  function _no_name_provided_$factory_365($codeContext, $$changed) {
    var i = new _no_name_provided__407($codeContext, $$changed);
    return function (p1, p2) {
      i.invoke_892(p1, p2);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_366() {
    var i = new _no_name_provided__408();
    return function (p1) {
      i.invoke_890(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_367($codeContext) {
    var i = new _no_name_provided__413($codeContext);
    return function (p1, p2, p3) {
      i.invoke_882(p1, p2, p3);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_368($boundThis) {
    var i = new _no_name_provided__409($boundThis);
    return function (p1, p2, p3) {
      return i.invoke_874(p1, p2, p3);
    };
  }
  function _no_name_provided_$factory_369($codeContext, $$changed) {
    var i = new _no_name_provided__414($codeContext, $$changed);
    return function (p1, p2) {
      i.invoke_892(p1, p2);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_370() {
    var i = new _no_name_provided__416();
    return function (p1) {
      i.invoke_890(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_371($codeContext) {
    var i = new _no_name_provided__436($codeContext);
    return function (p1, p2, p3) {
      i.invoke_882(p1, p2, p3);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_372($boundThis) {
    var i = new _no_name_provided__417($boundThis);
    return function (p1, p2, p3) {
      return i.invoke_874(p1, p2, p3);
    };
  }
  function _no_name_provided_$factory_373($codeContext, $$changed) {
    var i = new _no_name_provided__437($codeContext, $$changed);
    return function (p1, p2) {
      i.invoke_892(p1, p2);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_374() {
    var i = new _no_name_provided__439();
    return function (p1) {
      i.invoke_890(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_375($name, $value, $$dirty) {
    var i = new _no_name_provided__449($name, $value, $$dirty);
    return function (p1, p2, p3) {
      i.invoke_882(p1, p2, p3);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_376($boundThis) {
    var i = new _no_name_provided__440($boundThis);
    return function (p1, p2, p3) {
      return i.invoke_874(p1, p2, p3);
    };
  }
  function _no_name_provided_$factory_377($name, $value, $$changed) {
    var i = new _no_name_provided__450($name, $value, $$changed);
    return function (p1, p2) {
      i.invoke_892(p1, p2);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_378() {
    var i = new _no_name_provided__452();
    return function (p1) {
      i.invoke_890(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_379($codeRunState$delegate, $codeContext, $lastProgOutput$delegate, $lastProgSexpOut$delegate, $lastProgHex$delegate) {
    var i = new _no_name_provided__471($codeRunState$delegate, $codeContext, $lastProgOutput$delegate, $lastProgSexpOut$delegate, $lastProgHex$delegate);
    return function (p1, p2, p3) {
      i.invoke_882(p1, p2, p3);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_380($boundThis) {
    var i = new _no_name_provided__453($boundThis);
    return function (p1, p2, p3) {
      return i.invoke_874(p1, p2, p3);
    };
  }
  function _no_name_provided_$factory_381($codeContext, $$changed) {
    var i = new _no_name_provided__472($codeContext, $$changed);
    return function (p1, p2) {
      i.invoke_892(p1, p2);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_382() {
    var i = new _no_name_provided__473();
    return function (p1) {
      i.invoke_890(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_383($$changed) {
    var i = new _no_name_provided__474($$changed);
    return function (p1, p2) {
      i.invoke_892(p1, p2);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_384() {
    var i = new _no_name_provided__475();
    return function (p1) {
      i.invoke_890(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_385($attrs, $$changed, $$default) {
    var i = new _no_name_provided__476($attrs, $$changed, $$default);
    return function (p1, p2) {
      i.invoke_892(p1, p2);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_386() {
    var i = new _no_name_provided__403();
    return function (p1) {
      i.invoke_890(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_387($codeContext) {
    var i = new _no_name_provided__405($codeContext);
    return function (p1, p2, p3) {
      i.invoke_882(p1, p2, p3);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_388($boundThis) {
    var i = new _no_name_provided__404($boundThis);
    return function (p1, p2, p3) {
      return i.invoke_874(p1, p2, p3);
    };
  }
  function _no_name_provided_$factory_389() {
    var i = new _no_name_provided__400();
    return function (p1) {
      i.invoke_890(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_390($codeContext) {
    var i = new _no_name_provided__402($codeContext);
    return function (p1, p2, p3) {
      i.invoke_882(p1, p2, p3);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_391($boundThis) {
    var i = new _no_name_provided__401($boundThis);
    return function (p1, p2, p3) {
      return i.invoke_874(p1, p2, p3);
    };
  }
  function _no_name_provided_$factory_392() {
    var i = new _no_name_provided__396();
    return function (p1) {
      i.invoke_734(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_393() {
    var i = new _no_name_provided__397();
    return function (p1) {
      i.invoke_890(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_394($codeContext) {
    var i = new _no_name_provided__399($codeContext);
    return function (p1, p2, p3) {
      i.invoke_882(p1, p2, p3);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_395($boundThis) {
    var i = new _no_name_provided__398($boundThis);
    return function (p1, p2, p3) {
      return i.invoke_874(p1, p2, p3);
    };
  }
  function _no_name_provided_$factory_396() {
    var i = new _no_name_provided__410();
    return function (p1) {
      i.invoke_890(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_397($codeContext) {
    var i = new _no_name_provided__412($codeContext);
    return function (p1, p2, p3) {
      i.invoke_882(p1, p2, p3);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_398($boundThis) {
    var i = new _no_name_provided__411($boundThis);
    return function (p1, p2, p3) {
      return i.invoke_874(p1, p2, p3);
    };
  }
  function _no_name_provided_$factory_399() {
    var i = new _no_name_provided__415();
    return function (p1) {
      i.invoke_850(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_400() {
    var i = new _no_name_provided__430();
    return function (p1) {
      i.invoke_890(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_401() {
    var i = new _no_name_provided__431();
    return function (p1) {
      i.invoke_890(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_402() {
    var i = new _no_name_provided__432();
    return function (p1) {
      i.invoke_890(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_403() {
    var i = new _no_name_provided__433();
    return function (p1) {
      i.invoke_890(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_404($codeContext) {
    var i = new _no_name_provided__435($codeContext);
    return function (p1, p2, p3) {
      i.invoke_882(p1, p2, p3);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_405($boundThis) {
    var i = new _no_name_provided__434($boundThis);
    return function (p1, p2, p3) {
      return i.invoke_874(p1, p2, p3);
    };
  }
  function _no_name_provided_$factory_406() {
    var i = new _no_name_provided__418();
    return function (p1) {
      i.invoke_850(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_407() {
    var i = new _no_name_provided__419();
    return function (p1) {
      i.invoke_850(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_408() {
    var i = new _no_name_provided__420();
    return function (p1) {
      i.invoke_850(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_409() {
    var i = new _no_name_provided__421();
    return function (p1) {
      i.invoke_850(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_410() {
    var i = new _no_name_provided__426();
    return function (p1) {
      i.invoke_864(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_411() {
    var i = new _no_name_provided__427();
    return function (p1) {
      i.invoke_864(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_412($codeContext) {
    var i = new _no_name_provided__429($codeContext);
    return function (p1, p2, p3) {
      i.invoke_838(p1, p2, p3);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_413($boundThis) {
    var i = new _no_name_provided__428($boundThis);
    return function (p1, p2, p3) {
      return i.invoke_836(p1, p2, p3);
    };
  }
  function _no_name_provided_$factory_414() {
    var i = new _no_name_provided__422();
    return function (p1) {
      i.invoke_850(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_415() {
    var i = new _no_name_provided__423();
    return function (p1) {
      i.invoke_850(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_416($codeContext) {
    var i = new _no_name_provided__425($codeContext);
    return function (p1, p2) {
      i.invoke_792(p1, p2);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_417($codeContext, $element) {
    var i = new _no_name_provided__424($codeContext, $element);
    return function (p1) {
      i.invoke_790(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_418() {
    var i = new _no_name_provided__438();
    return function (p1) {
      i.invoke_850(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_419() {
    var i = new _no_name_provided__443();
    return function (p1) {
      i.invoke_864(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_420($name) {
    var i = new _no_name_provided__445($name);
    return function (p1, p2, p3) {
      i.invoke_838(p1, p2, p3);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_421($boundThis) {
    var i = new _no_name_provided__444($boundThis);
    return function (p1, p2, p3) {
      return i.invoke_836(p1, p2, p3);
    };
  }
  function _no_name_provided_$factory_422() {
    var i = new _no_name_provided__446();
    return function (p1) {
      i.invoke_864(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_423($value, $$dirty) {
    var i = new _no_name_provided__448($value, $$dirty);
    return function (p1, p2, p3) {
      i.invoke_838(p1, p2, p3);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_424($boundThis) {
    var i = new _no_name_provided__447($boundThis);
    return function (p1, p2, p3) {
      return i.invoke_836(p1, p2, p3);
    };
  }
  function _no_name_provided_$factory_425() {
    var i = new _no_name_provided__441();
    return function (p1) {
      i.invoke_850(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_426() {
    var i = new _no_name_provided__442();
    return function (p1) {
      i.invoke_850(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_427() {
    var i = new _no_name_provided__451();
    return function (p1) {
      i.invoke_850(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_428() {
    var i = new _no_name_provided__461();
    return function (p1) {
      i.invoke_864(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_429() {
    var i = new _no_name_provided__462();
    return function (p1) {
      i.invoke_890(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_430($codeContext, $lastProgOutput$delegate, $lastProgSexpOut$delegate, $lastProgHex$delegate, $codeRunState$delegate) {
    var i = new _no_name_provided__464($codeContext, $lastProgOutput$delegate, $lastProgSexpOut$delegate, $lastProgHex$delegate, $codeRunState$delegate);
    return function (p1, p2, p3) {
      i.invoke_882(p1, p2, p3);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_431($boundThis) {
    var i = new _no_name_provided__463($boundThis);
    return function (p1, p2, p3) {
      return i.invoke_874(p1, p2, p3);
    };
  }
  function _no_name_provided_$factory_432() {
    var i = new _no_name_provided__465();
    return function (p1) {
      i.invoke_890(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_433($codeContext) {
    var i = new _no_name_provided__467($codeContext);
    return function (p1, p2, p3) {
      i.invoke_882(p1, p2, p3);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_434($boundThis) {
    var i = new _no_name_provided__466($boundThis);
    return function (p1, p2, p3) {
      return i.invoke_874(p1, p2, p3);
    };
  }
  function _no_name_provided_$factory_435() {
    var i = new _no_name_provided__468();
    return function (p1) {
      i.invoke_890(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_436($codeContext, $lastProgOutput$delegate, $lastProgSexpOut$delegate, $lastProgHex$delegate, $codeRunState$delegate, resultContinuation) {
    var i = new _no_name_provided__469($codeContext, $lastProgOutput$delegate, $lastProgSexpOut$delegate, $lastProgHex$delegate, $codeRunState$delegate, resultContinuation);
    var l = function (p1, $cont) {
      return i.invoke_881(p1, $cont);
    };
    l.$arity = 1;
    return l;
  }
  function _no_name_provided_$factory_437($codeContext, $lastProgOutput$delegate, $lastProgSexpOut$delegate, $lastProgHex$delegate, $codeRunState$delegate, resultContinuation) {
    var i = new _no_name_provided__470($codeContext, $lastProgOutput$delegate, $lastProgSexpOut$delegate, $lastProgHex$delegate, $codeRunState$delegate, resultContinuation);
    var l = function (p1, $cont) {
      return i.invoke_881(p1, $cont);
    };
    l.$arity = 1;
    return l;
  }
  function _no_name_provided_$factory_438() {
    var i = new _no_name_provided__454();
    return function (p1) {
      i.invoke_850(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_439($codeContext, $lastProgOutput$delegate, $lastProgSexpOut$delegate, $lastProgHex$delegate, $codeRunState$delegate) {
    var i = new _no_name_provided__457($codeContext, $lastProgOutput$delegate, $lastProgSexpOut$delegate, $lastProgHex$delegate, $codeRunState$delegate);
    return function (p1) {
      i.invoke_862(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_440($codeContext, $lastProgOutput$delegate, $lastProgSexpOut$delegate, $lastProgHex$delegate, $codeRunState$delegate) {
    var i = new _no_name_provided__458($codeContext, $lastProgOutput$delegate, $lastProgSexpOut$delegate, $lastProgHex$delegate, $codeRunState$delegate);
    return function (p1) {
      i.invoke_862(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_441($codeContext, $lastProgOutput$delegate, $lastProgSexpOut$delegate, $lastProgHex$delegate, $codeRunState$delegate) {
    var i = new _no_name_provided__455($codeContext, $lastProgOutput$delegate, $lastProgSexpOut$delegate, $lastProgHex$delegate, $codeRunState$delegate);
    return function (p1) {
      i.invoke_860(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_442($codeContext, $lastProgOutput$delegate, $lastProgSexpOut$delegate, $lastProgHex$delegate, $codeRunState$delegate) {
    var i = new _no_name_provided__456($codeContext, $lastProgOutput$delegate, $lastProgSexpOut$delegate, $lastProgHex$delegate, $codeRunState$delegate);
    return function (p1) {
      i.invoke_860(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_443($codeContext) {
    var i = new _no_name_provided__460($codeContext);
    return function (p1) {
      i.invoke_862(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_444($codeContext) {
    var i = new _no_name_provided__459($codeContext);
    return function (p1) {
      i.invoke_860(p1);
      return Unit_getInstance();
    };
  }
  var startCode;
  function CodeContext_init_$Init$(code, runnerJob, lastProgramCode, args, program, $mask0, $marker, $this) {
    if (!(($mask0 & 1) === 0))
      code = startCode;
    if (!(($mask0 & 2) === 0))
      runnerJob = null;
    if (!(($mask0 & 4) === 0))
      lastProgramCode = '';
    if (!(($mask0 & 8) === 0))
      args = '(hello 0x351A85E8CBF8C6BC1619C6653B1930F975A806574FBEB6D06C61F33AB4BEA82B 2)';
    if (!(($mask0 & 16) === 0))
      program = null;
    CodeContext.call($this, code, runnerJob, lastProgramCode, args, program);
    return $this;
  }
  function CodeContext_init_$Create$(code, runnerJob, lastProgramCode, args, program, $mask0, $marker) {
    return CodeContext_init_$Init$(code, runnerJob, lastProgramCode, args, program, $mask0, $marker, Object.create(CodeContext.prototype));
  }
  function CodeContext(code, runnerJob, lastProgramCode, args, program) {
    this._code = code;
    this._runnerJob_0 = runnerJob;
    this._lastProgramCode = lastProgramCode;
    this._args = args;
    this._program = program;
    this._$stable_49 = 8;
  }
  CodeContext.prototype.updateArgs = function (args) {
    this._args = args;
  };
  CodeContext.prototype.toString = function () {
    var tmp = this._code;
    var tmp_0 = this._runnerJob_0;
    var tmp_1 = this._lastProgramCode;
    var tmp_2 = this._args;
    var tmp_3 = this._program;
    return '' + 'CodeContext(code=' + tmp + ', runnerJob=' + tmp_0 + ', lastProgramCode=' + tmp_1 + ', args=' + tmp_2 + ', program=' + (tmp_3 == null ? null : new Program(tmp_3)) + ')';
  };
  CodeContext.prototype.hashCode = function () {
    var result = getStringHashCode(this._code);
    result = imul(result, 31) + (this._runnerJob_0 == null ? 0 : hashCode(this._runnerJob_0)) | 0;
    result = imul(result, 31) + getStringHashCode(this._lastProgramCode) | 0;
    result = imul(result, 31) + getStringHashCode(this._args) | 0;
    var tmp = imul(result, 31);
    var tmp_0;
    var tmp_1 = this._program;
    if ((tmp_1 == null ? null : new Program(tmp_1)) == null) {
      tmp_0 = 0;
    } else {
      {
        tmp_0 = Program__hashCode_impl(this._program);
      }
    }
    result = tmp + tmp_0 | 0;
    return result;
  };
  CodeContext.prototype.equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof CodeContext))
      return false;
    else {
    }
    var tmp0_other_with_cast = other instanceof CodeContext ? other : THROW_CCE();
    if (!(this._code === tmp0_other_with_cast._code))
      return false;
    if (!equals_0(this._runnerJob_0, tmp0_other_with_cast._runnerJob_0))
      return false;
    if (!(this._lastProgramCode === tmp0_other_with_cast._lastProgramCode))
      return false;
    if (!(this._args === tmp0_other_with_cast._args))
      return false;
    var tmp = this._program;
    var tmp_0 = tmp == null ? null : new Program(tmp);
    var tmp_1 = tmp0_other_with_cast._program;
    if (!equals_0(tmp_0, tmp_1 == null ? null : new Program(tmp_1)))
      return false;
    else {
    }
    return true;
  };
  CodeContext.$metadata$ = {
    simpleName: 'CodeContext',
    kind: 'class',
    interfaces: []
  };
  function main() {
    var codeContext = CodeContext_init_$Create$(null, null, null, null, null, 31, null);
    renderComposable$composable('root', _no_name_provided_$factory_446(composableLambdaInstance(-985533008, true, _no_name_provided_$factory_445(codeContext))));
    Unit_getInstance();
    loadAce();
    var edit = ace.edit('editor');
    edit.setTheme('ace/theme/chrome');
    edit.setValue(codeContext._code);
    Unit_getInstance();
    edit.session.setMode('ace/mode/clisp');
    var tmp = edit.session;
    tmp.on('change', _no_name_provided_$factory_447(codeContext, edit));
  }
  function _no_name_provided__477($boundThis) {
    this._$boundThis_58 = $boundThis;
  }
  _no_name_provided__477.prototype.invoke_894 = function (p0, p1, p2) {
    return this._$boundThis_58.invoke_377(p0, p1, p2);
  };
  _no_name_provided__477.prototype.invoke_917 = function (p1, p2, p3) {
    var tmp = (!(p1 == null) ? isInterface(p1, DOMScope) : false) ? p1 : THROW_CCE();
    var tmp_0 = (!(p2 == null) ? isInterface(p2, Composer) : false) ? p2 : THROW_CCE();
    return this.invoke_894(tmp, tmp_0, (!(p3 == null) ? typeof p3 === 'number' : false) ? p3 : THROW_CCE());
  };
  _no_name_provided__477.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__478($tmp0_safe_receiver_9_15) {
    this._$tmp0_safe_receiver_9_15 = $tmp0_safe_receiver_9_15;
  }
  _no_name_provided__478.prototype.invoke_896 = function (it) {
    clearCSSRules(this._$tmp0_safe_receiver_9_15);
  };
  _no_name_provided__478.prototype.invoke_1115 = function (p1) {
    this.invoke_896(p1 instanceof Element ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__478.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__479($tmp1_Style$composable_0_3) {
    this._$tmp1_Style$composable_0_3 = $tmp1_Style$composable_0_3;
  }
  _no_name_provided__479.prototype.invoke_898 = function ($this$DomSideEffect, style) {
    var tmp0_safe_receiver_9_15 = style.sheet;
    if (tmp0_safe_receiver_9_15 == null)
      null;
    else {
      setCSSRules(tmp0_safe_receiver_9_15, this._$tmp1_Style$composable_0_3);
      $this$DomSideEffect.onDispose_0(_no_name_provided_$factory_455(tmp0_safe_receiver_9_15));
      Unit_getInstance();
    }
    Unit_getInstance();
  };
  _no_name_provided__479.prototype.invoke_915 = function (p1, p2) {
    var tmp = (!(p1 == null) ? isInterface(p1, DomEffectScope) : false) ? p1 : THROW_CCE();
    this.invoke_898(tmp, p2 instanceof HTMLStyleElement ? p2 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__479.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__480($boundThis) {
    this._$boundThis_59 = $boundThis;
  }
  _no_name_provided__480.prototype.invoke_912 = function (p0, p1) {
    return this._$boundThis_59.invoke_912(p0, p1);
  };
  _no_name_provided__480.prototype.invoke_915 = function (p1, p2) {
    var tmp = (!(p1 == null) ? isInterface(p1, Composer) : false) ? p1 : THROW_CCE();
    return this.invoke_912(tmp, (!(p2 == null) ? typeof p2 === 'number' : false) ? p2 : THROW_CCE());
  };
  _no_name_provided__480.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__481($codeContext) {
    this._$codeContext_25 = $codeContext;
  }
  _no_name_provided__481.prototype.invoke_914 = function ($composer, $changed) {
    var $composer_0 = $composer;
    if (!(($changed & 11 ^ 2) === 0) ? true : !$composer_0._get_skipping__0()) {
      CodeEditorArea$composable(this._$codeContext_25, $composer_0, 8);
    } else
      $composer_0.skipToGroupEnd_2();
  };
  _no_name_provided__481.prototype.invoke_915 = function (p1, p2) {
    var tmp = (p1 == null ? true : isInterface(p1, Composer)) ? p1 : THROW_CCE();
    this.invoke_914(tmp, (!(p2 == null) ? typeof p2 === 'number' : false) ? p2 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__481.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__482() {
  }
  _no_name_provided__482.prototype.invoke_906 = function ($this$null) {
    return Unit_getInstance();
  };
  _no_name_provided__482.prototype.invoke_1115 = function (p1) {
    this.invoke_906(p1 instanceof AttrsBuilder ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__482.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__483($applyAttrs_1_7) {
    this._$applyAttrs_1_7 = $applyAttrs_1_7;
  }
  _no_name_provided__483.prototype.invoke_906 = function ($this$TagElement) {
    this._$applyAttrs_1_7._v($this$TagElement);
  };
  _no_name_provided__483.prototype.invoke_1115 = function (p1) {
    this.invoke_906(p1 instanceof AttrsBuilder ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__483.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__484($boundThis) {
    this._$boundThis_60 = $boundThis;
  }
  _no_name_provided__484.prototype.invoke_908 = function (p0, p1, p2) {
    return this._$boundThis_60.invoke_377(p0, p1, p2);
  };
  _no_name_provided__484.prototype.invoke_917 = function (p1, p2, p3) {
    var tmp = (!(p1 == null) ? isInterface(p1, ElementScope) : false) ? p1 : THROW_CCE();
    var tmp_0 = (!(p2 == null) ? isInterface(p2, Composer) : false) ? p2 : THROW_CCE();
    return this.invoke_908(tmp, tmp_0, (!(p3 == null) ? typeof p3 === 'number' : false) ? p3 : THROW_CCE());
  };
  _no_name_provided__484.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__485($tmp1_Style$composable_0_3, $tmp3_Style$composable_0_5) {
    this._$tmp1_Style$composable_0_3_0 = $tmp1_Style$composable_0_3;
    this._$tmp3_Style$composable_0_5 = $tmp3_Style$composable_0_5;
  }
  _no_name_provided__485.prototype.invoke_910 = function ($this$TagElement, $composer, $changed) {
    var $composer_6_12 = $composer;
    var $dirty_7_13 = $changed;
    if (($changed & 14) === 0)
      $dirty_7_13 = $dirty_7_13 | ($composer_6_12.changed_0($this$TagElement) ? 4 : 2);
    if (!(($dirty_7_13 & 91 ^ 18) === 0) ? true : !$composer_6_12._get_skipping__0()) {
      $this$TagElement.DomSideEffect$composable_3(this._$tmp1_Style$composable_0_3_0, _no_name_provided_$factory_454(this._$tmp1_Style$composable_0_3_0), $composer_6_12, 14 & this._$tmp3_Style$composable_0_5 >> 3 | 896 & $dirty_7_13 << 6);
    } else
      $composer_6_12.skipToGroupEnd_2();
  };
  _no_name_provided__485.prototype.invoke_917 = function (p1, p2, p3) {
    var tmp = (!(p1 == null) ? isInterface(p1, ElementScope) : false) ? p1 : THROW_CCE();
    var tmp_0 = (p2 == null ? true : isInterface(p2, Composer)) ? p2 : THROW_CCE();
    this.invoke_910(tmp, tmp_0, (!(p3 == null) ? typeof p3 === 'number' : false) ? p3 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__485.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__486($boundThis) {
    this._$boundThis_61 = $boundThis;
  }
  _no_name_provided__486.prototype.invoke_912 = function (p0, p1) {
    return this._$boundThis_61.invoke_912(p0, p1);
  };
  _no_name_provided__486.prototype.invoke_915 = function (p1, p2) {
    var tmp = (!(p1 == null) ? isInterface(p1, Composer) : false) ? p1 : THROW_CCE();
    return this.invoke_912(tmp, (!(p2 == null) ? typeof p2 === 'number' : false) ? p2 : THROW_CCE());
  };
  _no_name_provided__486.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__487($codeContext) {
    this._$codeContext_26 = $codeContext;
  }
  _no_name_provided__487.prototype.invoke_914 = function ($composer, $changed) {
    var $composer_0 = $composer;
    if (!(($changed & 11 ^ 2) === 0) ? true : !$composer_0._get_skipping__0()) {
      Header$composable($composer_0, 0);
      var tmp = $composer_0;
      MainContentLayout$composable(_no_name_provided_$factory_457(composableLambda(tmp, -819895335, true, _no_name_provided_$factory_456(this._$codeContext_26))), $composer_0, 0);
      PageFooter$composable($composer_0, 0);
    } else
      $composer_0.skipToGroupEnd_2();
  };
  _no_name_provided__487.prototype.invoke_915 = function (p1, p2) {
    var tmp = (p1 == null ? true : isInterface(p1, Composer)) ? p1 : THROW_CCE();
    this.invoke_914(tmp, (!(p2 == null) ? typeof p2 === 'number' : false) ? p2 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__487.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__488($codeContext) {
    this._$codeContext_27 = $codeContext;
  }
  _no_name_provided__488.prototype.invoke_916 = function ($this$renderComposable, $composer, $changed) {
    var $composer_0 = $composer;
    if (!(($changed & 81 ^ 16) === 0) ? true : !$composer_0._get_skipping__0()) {
      var tmp0_Style$composable_0 = AppStylesheet_getInstance();
      var tmp1_Style$composable_0 = $composer_0;
      var tmp2_Style$composable_0 = 8;
      var $composer_1 = tmp1_Style$composable_0;
      $composer_1.startReplaceableGroup_0(1730466132);
      sourceInformation($composer_1, 'C(Style$composable)');
      var tmp0_Style$composable_0_2 = null;
      var tmp1_Style$composable_0_3 = tmp0_Style$composable_0._get_cssRules__12();
      var tmp2_Style$composable_0_4 = $composer_1;
      var tmp3_Style$composable_0_5 = 0;
      var tmp4_Style$composable_0_6 = 1;
      var applyAttrs_1_7 = {_v: Unit_getInstance()};
      var $composer_2_8 = tmp2_Style$composable_0_4;
      $composer_2_8.startReplaceableGroup_0(54007851);
      sourceInformation($composer_2_8, 'C(Style$composable)');
      if (!((tmp4_Style$composable_0_6 & 1) === 0)) {
        applyAttrs_1_7._v = _no_name_provided_$factory_448();
      }var tmp = _no_name_provided_$factory_449(applyAttrs_1_7);
      var tmp_0 = $composer_2_8;
      TagElement$composable('style', tmp, _no_name_provided_$factory_451(composableLambda(tmp_0, -819895361, true, _no_name_provided_$factory_450(tmp1_Style$composable_0_3, tmp3_Style$composable_0_5))), $composer_2_8, 6);
      $composer_2_8.endReplaceableGroup_0();
      $composer_1.endReplaceableGroup_0();
      var tmp_1 = $composer_0;
      Layout$composable(_no_name_provided_$factory_453(composableLambda(tmp_1, -819895380, true, _no_name_provided_$factory_452(this._$codeContext_27))), $composer_0, 0);
    } else
      $composer_0.skipToGroupEnd_2();
  };
  _no_name_provided__488.prototype.invoke_917 = function (p1, p2, p3) {
    var tmp = (!(p1 == null) ? isInterface(p1, DOMScope) : false) ? p1 : THROW_CCE();
    var tmp_0 = (p2 == null ? true : isInterface(p2, Composer)) ? p2 : THROW_CCE();
    this.invoke_916(tmp, tmp_0, (!(p3 == null) ? typeof p3 === 'number' : false) ? p3 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__488.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__489($codeContext, $edit) {
    this._$codeContext_28 = $codeContext;
    this._$edit = $edit;
  }
  _no_name_provided__489.prototype.invoke_918 = function (it) {
    this._$codeContext_28._code = this._$edit.getValue();
    Unit_getInstance();
  };
  _no_name_provided__489.prototype.invoke_1115 = function (p1) {
    this.invoke_918(isObject(p1) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__489.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided_$factory_445($codeContext) {
    var i = new _no_name_provided__488($codeContext);
    return function (p1, p2, p3) {
      i.invoke_916(p1, p2, p3);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_446($boundThis) {
    var i = new _no_name_provided__477($boundThis);
    return function (p1, p2, p3) {
      return i.invoke_894(p1, p2, p3);
    };
  }
  function _no_name_provided_$factory_447($codeContext, $edit) {
    var i = new _no_name_provided__489($codeContext, $edit);
    return function (p1) {
      i.invoke_918(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_448() {
    var i = new _no_name_provided__482();
    return function (p1) {
      i.invoke_906(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_449($applyAttrs_1_7) {
    var i = new _no_name_provided__483($applyAttrs_1_7);
    return function (p1) {
      i.invoke_906(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_450($tmp1_Style$composable_0_3, $tmp3_Style$composable_0_5) {
    var i = new _no_name_provided__485($tmp1_Style$composable_0_3, $tmp3_Style$composable_0_5);
    return function (p1, p2, p3) {
      i.invoke_910(p1, p2, p3);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_451($boundThis) {
    var i = new _no_name_provided__484($boundThis);
    return function (p1, p2, p3) {
      return i.invoke_908(p1, p2, p3);
    };
  }
  function _no_name_provided_$factory_452($codeContext) {
    var i = new _no_name_provided__487($codeContext);
    return function (p1, p2) {
      i.invoke_914(p1, p2);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_453($boundThis) {
    var i = new _no_name_provided__486($boundThis);
    return function (p1, p2) {
      return i.invoke_912(p1, p2);
    };
  }
  function _no_name_provided_$factory_454($tmp1_Style$composable_0_3) {
    var i = new _no_name_provided__479($tmp1_Style$composable_0_3);
    return function (p1, p2) {
      i.invoke_898(p1, p2);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_455($tmp0_safe_receiver_9_15) {
    var i = new _no_name_provided__478($tmp0_safe_receiver_9_15);
    return function (p1) {
      i.invoke_896(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_456($codeContext) {
    var i = new _no_name_provided__481($codeContext);
    return function (p1, p2) {
      i.invoke_914(p1, p2);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_457($boundThis) {
    var i = new _no_name_provided__480($boundThis);
    return function (p1, p2) {
      return i.invoke_912(p1, p2);
    };
  }
  function AppCSSVariables() {
    AppCSSVariables_instance = this;
    this._wtColorGreyLight$delegate = variable(this);
    this._wtColorGreyDark$delegate = variable(this);
    this._wtOffsetTopUnit$delegate = variable(this);
    this._wtHorizontalLayoutGutter$delegate = variable(this);
    this._wtFlowUnit$delegate = variable(this);
    this._wtHeroFontSize$delegate = variable(this);
    this._wtHeroLineHeight$delegate = variable(this);
    this._wtSubtitle2FontSize$delegate = variable(this);
    this._wtSubtitle2LineHeight$delegate = variable(this);
    this._wtH2FontSize$delegate = variable(this);
    this._wtH2LineHeight$delegate = variable(this);
    this._wtH3FontSize$delegate = variable(this);
    this._wtH3LineHeight$delegate = variable(this);
    this._wtColCount$delegate = variable(this);
    this._$stable_50 = 0;
  }
  AppCSSVariables.prototype._get_wtColorGreyLight_ = function () {
    return this._wtColorGreyLight$delegate.getValue_4(this, wtColorGreyLight$factory());
  };
  AppCSSVariables.prototype._get_wtColorGreyDark_ = function () {
    return this._wtColorGreyDark$delegate.getValue_4(this, wtColorGreyDark$factory());
  };
  AppCSSVariables.prototype._get_wtOffsetTopUnit_ = function () {
    return this._wtOffsetTopUnit$delegate.getValue_4(this, wtOffsetTopUnit$factory());
  };
  AppCSSVariables.prototype._get_wtHorizontalLayoutGutter_ = function () {
    return this._wtHorizontalLayoutGutter$delegate.getValue_4(this, wtHorizontalLayoutGutter$factory());
  };
  AppCSSVariables.prototype._get_wtFlowUnit_ = function () {
    return this._wtFlowUnit$delegate.getValue_4(this, wtFlowUnit$factory());
  };
  AppCSSVariables.prototype._get_wtHeroFontSize_ = function () {
    return this._wtHeroFontSize$delegate.getValue_4(this, wtHeroFontSize$factory());
  };
  AppCSSVariables.prototype._get_wtHeroLineHeight_ = function () {
    return this._wtHeroLineHeight$delegate.getValue_4(this, wtHeroLineHeight$factory());
  };
  AppCSSVariables.prototype._get_wtSubtitle2FontSize_ = function () {
    return this._wtSubtitle2FontSize$delegate.getValue_4(this, wtSubtitle2FontSize$factory());
  };
  AppCSSVariables.prototype._get_wtSubtitle2LineHeight_ = function () {
    return this._wtSubtitle2LineHeight$delegate.getValue_4(this, wtSubtitle2LineHeight$factory());
  };
  AppCSSVariables.prototype._get_wtH2FontSize_ = function () {
    return this._wtH2FontSize$delegate.getValue_4(this, wtH2FontSize$factory());
  };
  AppCSSVariables.prototype._get_wtH2LineHeight_ = function () {
    return this._wtH2LineHeight$delegate.getValue_4(this, wtH2LineHeight$factory());
  };
  AppCSSVariables.prototype._get_wtH3FontSize_ = function () {
    return this._wtH3FontSize$delegate.getValue_4(this, wtH3FontSize$factory());
  };
  AppCSSVariables.prototype._get_wtH3LineHeight_ = function () {
    return this._wtH3LineHeight$delegate.getValue_4(this, wtH3LineHeight$factory());
  };
  AppCSSVariables.prototype._get_wtColCount_ = function () {
    return this._wtColCount$delegate.getValue_4(this, wtColCount$factory());
  };
  AppCSSVariables.$metadata$ = {
    simpleName: 'AppCSSVariables',
    kind: 'object',
    interfaces: [CSSVariables]
  };
  var AppCSSVariables_instance;
  function AppCSSVariables_getInstance() {
    if (AppCSSVariables_instance == null)
      new AppCSSVariables();
    return AppCSSVariables_instance;
  }
  function _no_name_provided__490() {
  }
  _no_name_provided__490.prototype.invoke_1114 = function ($this$null) {
    top($this$null, _get_px_(-16));
  };
  _no_name_provided__490.prototype.invoke_1115 = function (p1) {
    this.invoke_1114((!(p1 == null) ? isInterface(p1, CSSBuilder) : false) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__490.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__491($this_style) {
    this._$this_style = $this_style;
  }
  _no_name_provided__491.prototype.invoke_1078 = function ($this$media) {
    var tmp = this._$this_style._get_self__1();
    $this$media.style_11(tmp, _no_name_provided_$factory_465());
  };
  _no_name_provided__491.prototype.invoke_1115 = function (p1) {
    this.invoke_1078((!(p1 == null) ? isInterface(p1, GenericStyleSheetBuilder) : false) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__491.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__492() {
  }
  _no_name_provided__492.prototype.invoke_936 = function ($this$null) {
    $this$null.invoke_403(AppCSSVariables_getInstance()._get_wtOffsetTopUnit_(), _get_px_(16));
    $this$null.invoke_403(AppCSSVariables_getInstance()._get_wtFlowUnit_(), _get_px_(16));
  };
  _no_name_provided__492.prototype.invoke_1115 = function (p1) {
    this.invoke_936((!(p1 == null) ? isInterface(p1, CSSStyleRuleBuilder) : false) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__492.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__493() {
  }
  _no_name_provided__493.prototype.invoke_1114 = function ($this$style) {
    var tmp0_value_0 = _get_percent_(100);
    $this$style.property_4('max-width', Companion_getInstance_35().invoke_396(tmp0_value_0));
  };
  _no_name_provided__493.prototype.invoke_1115 = function (p1) {
    this.invoke_1114((!(p1 == null) ? isInterface(p1, CSSBuilder) : false) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__493.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__494() {
  }
  _no_name_provided__494.prototype.invoke_1114 = function ($this$style) {
    var tmp0_value_0 = '5px 12px';
    $this$style.property_4('padding', Companion_getInstance_35().invoke_402(tmp0_value_0));
    var tmp1_value_0 = 'normal';
    $this$style.property_4('letter-spacing', Companion_getInstance_35().invoke_402(tmp1_value_0));
    var tmp2_value_0 = 400;
    $this$style.property_4('font-weight', Companion_getInstance_35().invoke_395(tmp2_value_0));
    var tmp3_value_0 = _get_px_(24);
    $this$style.property_4('line-height', Companion_getInstance_35().invoke_396(tmp3_value_0));
    position($this$style, Position_Relative_getInstance());
    top($this$style, _get_px_(-32));
    marginLeft($this$style, _get_px_(8));
    fontSize($this$style, _get_px_(15));
    backgroundColor($this$style, new RGBA(39, 40, 44, 0.05));
    color_0($this$style, new RGBA(39, 40, 44, 0.7));
    borderRadius($this$style, _get_px_(4), _get_px_(4), _get_px_(4));
    var tmp = maxWidth($this$style, _get_px_(640));
    media($this$style, tmp, _no_name_provided_$factory_464($this$style));
  };
  _no_name_provided__494.prototype.invoke_1115 = function (p1) {
    this.invoke_1114((!(p1 == null) ? isInterface(p1, CSSBuilder) : false) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__494.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__495() {
  }
  _no_name_provided__495.prototype.invoke_936 = function ($this$null) {
    var tmp0_value_0 = 'system-ui,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Droid Sans,Helvetica Neue,Arial,sans-serif';
    $this$null.property_4('font-family', Companion_getInstance_35().invoke_402(tmp0_value_0));
  };
  _no_name_provided__495.prototype.invoke_1115 = function (p1) {
    this.invoke_936((!(p1 == null) ? isInterface(p1, CSSStyleRuleBuilder) : false) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__495.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__496() {
  }
  _no_name_provided__496.prototype.invoke_936 = function ($this$null) {
    $this$null.invoke_403(AppCSSVariables_getInstance()._get_wtColorGreyLight_(), Companion_getInstance_37().invoke_402('#f4f4f4'));
    $this$null.invoke_403(AppCSSVariables_getInstance()._get_wtColorGreyDark_(), Companion_getInstance_37().invoke_402('#323236'));
    $this$null.invoke_403(AppCSSVariables_getInstance()._get_wtOffsetTopUnit_(), _get_px_(24));
    margin($this$null, _get_px_(0));
  };
  _no_name_provided__496.prototype.invoke_1115 = function (p1) {
    this.invoke_936((!(p1 == null) ? isInterface(p1, CSSStyleRuleBuilder) : false) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__496.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__497() {
  }
  _no_name_provided__497.prototype.invoke_934 = function ($this$media) {
    var tmp = Universal_getInstance();
    $this$media.style_11(tmp, _no_name_provided_$factory_466());
  };
  _no_name_provided__497.prototype.invoke_1115 = function (p1) {
    this.invoke_934((!(p1 == null) ? isInterface(p1, GenericStyleSheetBuilder) : false) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__497.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__498() {
  }
  _no_name_provided__498.prototype.invoke_936 = function ($this$null) {
    var tmp = AppCSSVariables_getInstance()._get_wtHorizontalLayoutGutter_();
    $this$null.property_4('margin-right', value$default(tmp, null, 1, null));
    var tmp_0 = AppCSSVariables_getInstance()._get_wtHorizontalLayoutGutter_();
    $this$null.property_4('margin-left', value$default(tmp_0, null, 1, null));
    var tmp_1 = AppCSSVariables_getInstance()._get_wtColCount_();
    var tmp_2 = value$default(tmp_1, null, 1, null);
    var tmp_3 = AppCSSVariables_getInstance()._get_wtHorizontalLayoutGutter_();
    var tmp0_value_0 = '' + 'calc(8.33333%*' + tmp_2 + ' - ' + value$default(tmp_3, null, 1, null) + '*2)';
    $this$null.property_4('flex-basis', Companion_getInstance_35().invoke_402(tmp0_value_0));
    var tmp_4 = AppCSSVariables_getInstance()._get_wtColCount_();
    var tmp_5 = value$default(tmp_4, null, 1, null);
    var tmp_6 = AppCSSVariables_getInstance()._get_wtHorizontalLayoutGutter_();
    var tmp1_value_0 = '' + 'calc(8.33333%*' + tmp_5 + ' - ' + value$default(tmp_6, null, 1, null) + '*2)';
    $this$null.property_4('max-width', Companion_getInstance_35().invoke_402(tmp1_value_0));
    var tmp2_value_0 = 'border-box';
    $this$null.property_4('box-sizing', Companion_getInstance_35().invoke_402(tmp2_value_0));
  };
  _no_name_provided__498.prototype.invoke_1115 = function (p1) {
    this.invoke_936((!(p1 == null) ? isInterface(p1, CSSStyleRuleBuilder) : false) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__498.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function AppStylesheet() {
    AppStylesheet_instance = this;
    StyleSheet_init_$Init$(null, 1, null, this);
    var tmp = this;
    tmp._composeLogo$delegate = this.style_45(_no_name_provided_$factory_458()).provideDelegate(this, composeLogo$factory());
    var tmp_0 = this;
    tmp_0._composeTitleTag$delegate = this.style_45(_no_name_provided_$factory_459()).provideDelegate(this, composeTitleTag$factory());
    this.style_12('label, a, button', _no_name_provided_$factory_460());
    var tmp_1 = Universal_getInstance();
    this.style_11(tmp_1, _no_name_provided_$factory_461());
    var tmp_2 = maxWidth(this, _get_px_(640));
    media(this, tmp_2, _no_name_provided_$factory_462());
    var tmp_3 = Operator_Contains_getInstance();
    var tmp_4 = Attribute_init_$Create$('class', 'wtCol', tmp_3, false, 8, null);
    this.style_11(tmp_4, _no_name_provided_$factory_463());
    this._$stable_51 = 0;
  }
  AppStylesheet.prototype._get_composeLogo_ = function () {
    return this._composeLogo$delegate.getValue_4(this, composeLogo$factory_0());
  };
  AppStylesheet.prototype._get_composeTitleTag_ = function () {
    return this._composeTitleTag$delegate.getValue_4(this, composeTitleTag$factory_0());
  };
  AppStylesheet.prototype.style_43 = function (selector, cssRule) {
    return this.style_42(selector, cssRule);
  };
  AppStylesheet.$metadata$ = {
    simpleName: 'AppStylesheet',
    kind: 'object',
    interfaces: []
  };
  var AppStylesheet_instance;
  function AppStylesheet_getInstance() {
    if (AppStylesheet_instance == null)
      new AppStylesheet();
    return AppStylesheet_instance;
  }
  function wtColorGreyLight$factory() {
    return getPropertyCallableRef('wtColorGreyLight', 1, KProperty1, function (receiver) {
      return receiver._get_wtColorGreyLight_();
    }, null);
  }
  function wtColorGreyDark$factory() {
    return getPropertyCallableRef('wtColorGreyDark', 1, KProperty1, function (receiver) {
      return receiver._get_wtColorGreyDark_();
    }, null);
  }
  function wtOffsetTopUnit$factory() {
    return getPropertyCallableRef('wtOffsetTopUnit', 1, KProperty1, function (receiver) {
      return receiver._get_wtOffsetTopUnit_();
    }, null);
  }
  function wtHorizontalLayoutGutter$factory() {
    return getPropertyCallableRef('wtHorizontalLayoutGutter', 1, KProperty1, function (receiver) {
      return receiver._get_wtHorizontalLayoutGutter_();
    }, null);
  }
  function wtFlowUnit$factory() {
    return getPropertyCallableRef('wtFlowUnit', 1, KProperty1, function (receiver) {
      return receiver._get_wtFlowUnit_();
    }, null);
  }
  function wtHeroFontSize$factory() {
    return getPropertyCallableRef('wtHeroFontSize', 1, KProperty1, function (receiver) {
      return receiver._get_wtHeroFontSize_();
    }, null);
  }
  function wtHeroLineHeight$factory() {
    return getPropertyCallableRef('wtHeroLineHeight', 1, KProperty1, function (receiver) {
      return receiver._get_wtHeroLineHeight_();
    }, null);
  }
  function wtSubtitle2FontSize$factory() {
    return getPropertyCallableRef('wtSubtitle2FontSize', 1, KProperty1, function (receiver) {
      return receiver._get_wtSubtitle2FontSize_();
    }, null);
  }
  function wtSubtitle2LineHeight$factory() {
    return getPropertyCallableRef('wtSubtitle2LineHeight', 1, KProperty1, function (receiver) {
      return receiver._get_wtSubtitle2LineHeight_();
    }, null);
  }
  function wtH2FontSize$factory() {
    return getPropertyCallableRef('wtH2FontSize', 1, KProperty1, function (receiver) {
      return receiver._get_wtH2FontSize_();
    }, null);
  }
  function wtH2LineHeight$factory() {
    return getPropertyCallableRef('wtH2LineHeight', 1, KProperty1, function (receiver) {
      return receiver._get_wtH2LineHeight_();
    }, null);
  }
  function wtH3FontSize$factory() {
    return getPropertyCallableRef('wtH3FontSize', 1, KProperty1, function (receiver) {
      return receiver._get_wtH3FontSize_();
    }, null);
  }
  function wtH3LineHeight$factory() {
    return getPropertyCallableRef('wtH3LineHeight', 1, KProperty1, function (receiver) {
      return receiver._get_wtH3LineHeight_();
    }, null);
  }
  function wtColCount$factory() {
    return getPropertyCallableRef('wtColCount', 1, KProperty1, function (receiver) {
      return receiver._get_wtColCount_();
    }, null);
  }
  function composeLogo$factory() {
    return getPropertyCallableRef('composeLogo', 1, KProperty1, function (receiver) {
      return receiver._get_composeLogo_();
    }, null);
  }
  function composeTitleTag$factory() {
    return getPropertyCallableRef('composeTitleTag', 1, KProperty1, function (receiver) {
      return receiver._get_composeTitleTag_();
    }, null);
  }
  function composeLogo$factory_0() {
    return getPropertyCallableRef('composeLogo', 1, KProperty1, function (receiver) {
      return receiver._get_composeLogo_();
    }, null);
  }
  function composeTitleTag$factory_0() {
    return getPropertyCallableRef('composeTitleTag', 1, KProperty1, function (receiver) {
      return receiver._get_composeTitleTag_();
    }, null);
  }
  function _no_name_provided_$factory_458() {
    var i = new _no_name_provided__493();
    return function (p1) {
      i.invoke_1114(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_459() {
    var i = new _no_name_provided__494();
    return function (p1) {
      i.invoke_1114(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_460() {
    var i = new _no_name_provided__495();
    return function (p1) {
      i.invoke_936(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_461() {
    var i = new _no_name_provided__496();
    return function (p1) {
      i.invoke_936(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_462() {
    var i = new _no_name_provided__497();
    return function (p1) {
      i.invoke_934(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_463() {
    var i = new _no_name_provided__498();
    return function (p1) {
      i.invoke_936(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_464($this_style) {
    var i = new _no_name_provided__491($this_style);
    return function (p1) {
      i.invoke_1078(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_465() {
    var i = new _no_name_provided__490();
    return function (p1) {
      i.invoke_1114(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_466() {
    var i = new _no_name_provided__492();
    return function (p1) {
      i.invoke_936(p1);
      return Unit_getInstance();
    };
  }
  function mediaMaxWidth(_this_, value_0, cssSelector, rulesBuild) {
    var tmp = maxWidth(_this_, value_0);
    media(_this_, tmp, _no_name_provided_$factory_467(cssSelector, rulesBuild));
  }
  function forMaxWidth(_this_, value_0, builder) {
    mediaMaxWidth(_this_, value_0, _this_._get_self__1(), builder);
  }
  function _no_name_provided__499() {
  }
  _no_name_provided__499.prototype.invoke_1114 = function ($this$forMaxWidth) {
    $this$forMaxWidth.invoke_403(AppCSSVariables_getInstance()._get_wtColCount_(), 3);
  };
  _no_name_provided__499.prototype.invoke_1115 = function (p1) {
    this.invoke_1114((!(p1 == null) ? isInterface(p1, CSSBuilder) : false) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__499.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__500() {
  }
  _no_name_provided__500.prototype.invoke_1114 = function ($this$forMaxWidth) {
    $this$forMaxWidth.invoke_403(AppCSSVariables_getInstance()._get_wtColCount_(), 4);
  };
  _no_name_provided__500.prototype.invoke_1115 = function (p1) {
    this.invoke_1114((!(p1 == null) ? isInterface(p1, CSSBuilder) : false) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__500.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__501() {
  }
  _no_name_provided__501.prototype.invoke_1114 = function ($this$forMaxWidth) {
    $this$forMaxWidth.invoke_403(AppCSSVariables_getInstance()._get_wtColCount_(), 8);
  };
  _no_name_provided__501.prototype.invoke_1115 = function (p1) {
    this.invoke_1114((!(p1 == null) ? isInterface(p1, CSSBuilder) : false) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__501.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__502() {
  }
  _no_name_provided__502.prototype.invoke_1114 = function ($this$forMaxWidth) {
    $this$forMaxWidth.invoke_403(AppCSSVariables_getInstance()._get_wtColCount_(), 9);
  };
  _no_name_provided__502.prototype.invoke_1115 = function (p1) {
    this.invoke_1114((!(p1 == null) ? isInterface(p1, CSSBuilder) : false) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__502.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__503() {
  }
  _no_name_provided__503.prototype.invoke_1114 = function ($this$forMaxWidth) {
    $this$forMaxWidth.invoke_403(AppCSSVariables_getInstance()._get_wtColCount_(), 10);
  };
  _no_name_provided__503.prototype.invoke_1115 = function (p1) {
    this.invoke_1114((!(p1 == null) ? isInterface(p1, CSSBuilder) : false) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__503.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__504() {
  }
  _no_name_provided__504.prototype.invoke_1114 = function ($this$forMaxWidth) {
    $this$forMaxWidth.invoke_403(AppCSSVariables_getInstance()._get_wtColCount_(), 11);
  };
  _no_name_provided__504.prototype.invoke_1115 = function (p1) {
    this.invoke_1114((!(p1 == null) ? isInterface(p1, CSSBuilder) : false) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__504.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__505() {
  }
  _no_name_provided__505.prototype.invoke_1114 = function ($this$forMaxWidth) {
    $this$forMaxWidth.invoke_403(AppCSSVariables_getInstance()._get_wtColCount_(), 6);
  };
  _no_name_provided__505.prototype.invoke_1115 = function (p1) {
    this.invoke_1114((!(p1 == null) ? isInterface(p1, CSSBuilder) : false) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__505.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__506() {
  }
  _no_name_provided__506.prototype.invoke_1114 = function ($this$forMaxWidth) {
    $this$forMaxWidth.invoke_403(AppCSSVariables_getInstance()._get_wtColCount_(), 12);
  };
  _no_name_provided__506.prototype.invoke_1115 = function (p1) {
    this.invoke_1114((!(p1 == null) ? isInterface(p1, CSSBuilder) : false) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__506.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__507() {
  }
  _no_name_provided__507.prototype.invoke_1114 = function ($this$forMaxWidth) {
    $this$forMaxWidth.invoke_403(AppCSSVariables_getInstance()._get_wtColCount_(), 12);
  };
  _no_name_provided__507.prototype.invoke_1115 = function (p1) {
    this.invoke_1114((!(p1 == null) ? isInterface(p1, CSSBuilder) : false) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__507.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__508() {
  }
  _no_name_provided__508.prototype.invoke_1114 = function ($this$forMaxWidth) {
    $this$forMaxWidth.invoke_403(AppCSSVariables_getInstance()._get_wtColCount_(), 6);
  };
  _no_name_provided__508.prototype.invoke_1115 = function (p1) {
    this.invoke_1114((!(p1 == null) ? isInterface(p1, CSSBuilder) : false) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__508.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__509() {
  }
  _no_name_provided__509.prototype.invoke_1114 = function ($this$forMaxWidth) {
    $this$forMaxWidth.invoke_403(AppCSSVariables_getInstance()._get_wtColCount_(), 0);
    flexGrow($this$forMaxWidth, 1);
    var tmp0_value_0 = _get_percent_(100);
    $this$forMaxWidth.property_4('max-width', Companion_getInstance_35().invoke_396(tmp0_value_0));
  };
  _no_name_provided__509.prototype.invoke_1115 = function (p1) {
    this.invoke_1114((!(p1 == null) ? isInterface(p1, CSSBuilder) : false) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__509.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__510() {
  }
  _no_name_provided__510.prototype.invoke_1114 = function ($this$style) {
    $this$style.invoke_403(AppCSSVariables_getInstance()._get_wtColCount_(), 2);
  };
  _no_name_provided__510.prototype.invoke_1115 = function (p1) {
    this.invoke_1114((!(p1 == null) ? isInterface(p1, CSSBuilder) : false) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__510.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__511() {
  }
  _no_name_provided__511.prototype.invoke_1114 = function ($this$style) {
    $this$style.invoke_403(AppCSSVariables_getInstance()._get_wtColCount_(), 3);
  };
  _no_name_provided__511.prototype.invoke_1115 = function (p1) {
    this.invoke_1114((!(p1 == null) ? isInterface(p1, CSSBuilder) : false) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__511.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__512() {
  }
  _no_name_provided__512.prototype.invoke_1114 = function ($this$style) {
    $this$style.invoke_403(AppCSSVariables_getInstance()._get_wtColCount_(), 4);
  };
  _no_name_provided__512.prototype.invoke_1115 = function (p1) {
    this.invoke_1114((!(p1 == null) ? isInterface(p1, CSSBuilder) : false) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__512.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__513() {
  }
  _no_name_provided__513.prototype.invoke_1114 = function ($this$style) {
    $this$style.invoke_403(AppCSSVariables_getInstance()._get_wtColCount_(), 5);
  };
  _no_name_provided__513.prototype.invoke_1115 = function (p1) {
    this.invoke_1114((!(p1 == null) ? isInterface(p1, CSSBuilder) : false) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__513.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__514() {
  }
  _no_name_provided__514.prototype.invoke_1114 = function ($this$style) {
    $this$style.invoke_403(AppCSSVariables_getInstance()._get_wtColCount_(), 6);
  };
  _no_name_provided__514.prototype.invoke_1115 = function (p1) {
    this.invoke_1114((!(p1 == null) ? isInterface(p1, CSSBuilder) : false) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__514.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__515() {
  }
  _no_name_provided__515.prototype.invoke_1114 = function ($this$style) {
    $this$style.invoke_403(AppCSSVariables_getInstance()._get_wtColCount_(), 9);
  };
  _no_name_provided__515.prototype.invoke_1115 = function (p1) {
    this.invoke_1114((!(p1 == null) ? isInterface(p1, CSSBuilder) : false) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__515.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__516() {
  }
  _no_name_provided__516.prototype.invoke_1114 = function ($this$style) {
    $this$style.invoke_403(AppCSSVariables_getInstance()._get_wtColCount_(), 10);
  };
  _no_name_provided__516.prototype.invoke_1115 = function (p1) {
    this.invoke_1114((!(p1 == null) ? isInterface(p1, CSSBuilder) : false) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__516.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__517() {
  }
  _no_name_provided__517.prototype.invoke_1114 = function ($this$style) {
    var tmp = _get_px_(1000);
    forMaxWidth($this$style, tmp, _no_name_provided_$factory_488());
  };
  _no_name_provided__517.prototype.invoke_1115 = function (p1) {
    this.invoke_1114((!(p1 == null) ? isInterface(p1, CSSBuilder) : false) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__517.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__518() {
  }
  _no_name_provided__518.prototype.invoke_1114 = function ($this$style) {
    var tmp = _get_px_(1000);
    forMaxWidth($this$style, tmp, _no_name_provided_$factory_489());
  };
  _no_name_provided__518.prototype.invoke_1115 = function (p1) {
    this.invoke_1114((!(p1 == null) ? isInterface(p1, CSSBuilder) : false) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__518.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__519() {
  }
  _no_name_provided__519.prototype.invoke_1114 = function ($this$style) {
    var tmp = _get_px_(1000);
    forMaxWidth($this$style, tmp, _no_name_provided_$factory_490());
  };
  _no_name_provided__519.prototype.invoke_1115 = function (p1) {
    this.invoke_1114((!(p1 == null) ? isInterface(p1, CSSBuilder) : false) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__519.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__520() {
  }
  _no_name_provided__520.prototype.invoke_1114 = function ($this$style) {
    var tmp = _get_px_(1000);
    forMaxWidth($this$style, tmp, _no_name_provided_$factory_491());
  };
  _no_name_provided__520.prototype.invoke_1115 = function (p1) {
    this.invoke_1114((!(p1 == null) ? isInterface(p1, CSSBuilder) : false) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__520.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__521() {
  }
  _no_name_provided__521.prototype.invoke_1114 = function ($this$style) {
    var tmp = _get_px_(1000);
    forMaxWidth($this$style, tmp, _no_name_provided_$factory_492());
  };
  _no_name_provided__521.prototype.invoke_1115 = function (p1) {
    this.invoke_1114((!(p1 == null) ? isInterface(p1, CSSBuilder) : false) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__521.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__522() {
  }
  _no_name_provided__522.prototype.invoke_1114 = function ($this$style) {
    var tmp = _get_px_(1000);
    forMaxWidth($this$style, tmp, _no_name_provided_$factory_493());
  };
  _no_name_provided__522.prototype.invoke_1115 = function (p1) {
    this.invoke_1114((!(p1 == null) ? isInterface(p1, CSSBuilder) : false) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__522.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__523() {
  }
  _no_name_provided__523.prototype.invoke_1114 = function ($this$style) {
    var tmp = _get_px_(1000);
    forMaxWidth($this$style, tmp, _no_name_provided_$factory_494());
  };
  _no_name_provided__523.prototype.invoke_1115 = function (p1) {
    this.invoke_1114((!(p1 == null) ? isInterface(p1, CSSBuilder) : false) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__523.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__524() {
  }
  _no_name_provided__524.prototype.invoke_1114 = function ($this$style) {
    var tmp = _get_px_(1000);
    forMaxWidth($this$style, tmp, _no_name_provided_$factory_495());
  };
  _no_name_provided__524.prototype.invoke_1115 = function (p1) {
    this.invoke_1114((!(p1 == null) ? isInterface(p1, CSSBuilder) : false) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__524.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__525() {
  }
  _no_name_provided__525.prototype.invoke_1114 = function ($this$style) {
    var tmp = _get_px_(640);
    forMaxWidth($this$style, tmp, _no_name_provided_$factory_496());
  };
  _no_name_provided__525.prototype.invoke_1115 = function (p1) {
    this.invoke_1114((!(p1 == null) ? isInterface(p1, CSSBuilder) : false) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__525.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__526() {
  }
  _no_name_provided__526.prototype.invoke_1114 = function ($this$style) {
    var tmp = _get_px_(1276);
    forMaxWidth($this$style, tmp, _no_name_provided_$factory_497());
  };
  _no_name_provided__526.prototype.invoke_1115 = function (p1) {
    this.invoke_1114((!(p1 == null) ? isInterface(p1, CSSBuilder) : false) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__526.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__527() {
  }
  _no_name_provided__527.prototype.invoke_1114 = function ($this$style) {
    var tmp = _get_px_(640);
    forMaxWidth($this$style, tmp, _no_name_provided_$factory_498());
  };
  _no_name_provided__527.prototype.invoke_1115 = function (p1) {
    this.invoke_1114((!(p1 == null) ? isInterface(p1, CSSBuilder) : false) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__527.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__528() {
  }
  _no_name_provided__528.prototype.invoke_1114 = function ($this$style) {
    $this$style.invoke_403(AppCSSVariables_getInstance()._get_wtColCount_(), 0);
    flexGrow($this$style, 1);
    var tmp0_value_0 = _get_percent_(100);
    $this$style.property_4('max-width', Companion_getInstance_35().invoke_396(tmp0_value_0));
  };
  _no_name_provided__528.prototype.invoke_1115 = function (p1) {
    this.invoke_1114((!(p1 == null) ? isInterface(p1, CSSBuilder) : false) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__528.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__529() {
  }
  _no_name_provided__529.prototype.invoke_1114 = function ($this$style) {
    $this$style.invoke_403(AppCSSVariables_getInstance()._get_wtColCount_(), 0);
    var tmp0_value_0 = _get_percent_(100);
    $this$style.property_4('max-width', Companion_getInstance_35().invoke_396(tmp0_value_0));
    var tmp1_value_0 = 'auto';
    $this$style.property_4('flex-basis', Companion_getInstance_35().invoke_402(tmp1_value_0));
  };
  _no_name_provided__529.prototype.invoke_1115 = function (p1) {
    this.invoke_1114((!(p1 == null) ? isInterface(p1, CSSBuilder) : false) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__529.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function WtCols() {
    WtCols_instance = this;
    StyleSheet_0.call(this, AppStylesheet_getInstance());
    var tmp = this;
    tmp._wtCol2$delegate = this.style_45(_no_name_provided_$factory_468()).provideDelegate(this, wtCol2$factory());
    var tmp_0 = this;
    tmp_0._wtCol3$delegate = this.style_45(_no_name_provided_$factory_469()).provideDelegate(this, wtCol3$factory());
    var tmp_1 = this;
    tmp_1._wtCol4$delegate = this.style_45(_no_name_provided_$factory_470()).provideDelegate(this, wtCol4$factory());
    var tmp_2 = this;
    tmp_2._wtCol5$delegate = this.style_45(_no_name_provided_$factory_471()).provideDelegate(this, wtCol5$factory());
    var tmp_3 = this;
    tmp_3._wtCol6$delegate = this.style_45(_no_name_provided_$factory_472()).provideDelegate(this, wtCol6$factory());
    var tmp_4 = this;
    tmp_4._wtCol9$delegate = this.style_45(_no_name_provided_$factory_473()).provideDelegate(this, wtCol9$factory());
    var tmp_5 = this;
    tmp_5._wtCol10$delegate = this.style_45(_no_name_provided_$factory_474()).provideDelegate(this, wtCol10$factory());
    var tmp_6 = this;
    tmp_6._wtColMd3$delegate = this.style_45(_no_name_provided_$factory_475()).provideDelegate(this, wtColMd3$factory());
    var tmp_7 = this;
    tmp_7._wtColMd4$delegate = this.style_45(_no_name_provided_$factory_476()).provideDelegate(this, wtColMd4$factory());
    var tmp_8 = this;
    tmp_8._wtColMd8$delegate = this.style_45(_no_name_provided_$factory_477()).provideDelegate(this, wtColMd8$factory());
    var tmp_9 = this;
    tmp_9._wtColMd9$delegate = this.style_45(_no_name_provided_$factory_478()).provideDelegate(this, wtColMd9$factory());
    var tmp_10 = this;
    tmp_10._wtColMd10$delegate = this.style_45(_no_name_provided_$factory_479()).provideDelegate(this, wtColMd10$factory());
    var tmp_11 = this;
    tmp_11._wtColMd11$delegate = this.style_45(_no_name_provided_$factory_480()).provideDelegate(this, wtColMd11$factory());
    var tmp_12 = this;
    tmp_12._wtColMd6$delegate = this.style_45(_no_name_provided_$factory_481()).provideDelegate(this, wtColMd6$factory());
    var tmp_13 = this;
    tmp_13._wtColMd12$delegate = this.style_45(_no_name_provided_$factory_482()).provideDelegate(this, wtColMd12$factory());
    var tmp_14 = this;
    tmp_14._wtColSm12$delegate = this.style_45(_no_name_provided_$factory_483()).provideDelegate(this, wtColSm12$factory());
    var tmp_15 = this;
    tmp_15._wtColLg6$delegate = this.style_45(_no_name_provided_$factory_484()).provideDelegate(this, wtColLg6$factory());
    var tmp_16 = this;
    tmp_16._wtColSmAutoFill$delegate = this.style_45(_no_name_provided_$factory_485()).provideDelegate(this, wtColSmAutoFill$factory());
    var tmp_17 = this;
    tmp_17._wtColAutoFill$delegate = this.style_45(_no_name_provided_$factory_486()).provideDelegate(this, wtColAutoFill$factory());
    var tmp_18 = this;
    tmp_18._wtColInline$delegate = this.style_45(_no_name_provided_$factory_487()).provideDelegate(this, wtColInline$factory());
    this._$stable_52 = 0;
  }
  WtCols.prototype._get_wtCol2_ = function () {
    return this._wtCol2$delegate.getValue_4(this, wtCol2$factory_0());
  };
  WtCols.prototype._get_wtCol3_ = function () {
    return this._wtCol3$delegate.getValue_4(this, wtCol3$factory_0());
  };
  WtCols.prototype._get_wtCol4_ = function () {
    return this._wtCol4$delegate.getValue_4(this, wtCol4$factory_0());
  };
  WtCols.prototype._get_wtCol5_ = function () {
    return this._wtCol5$delegate.getValue_4(this, wtCol5$factory_0());
  };
  WtCols.prototype._get_wtCol6_ = function () {
    return this._wtCol6$delegate.getValue_4(this, wtCol6$factory_0());
  };
  WtCols.prototype._get_wtCol9_ = function () {
    return this._wtCol9$delegate.getValue_4(this, wtCol9$factory_0());
  };
  WtCols.prototype._get_wtCol10_ = function () {
    return this._wtCol10$delegate.getValue_4(this, wtCol10$factory_0());
  };
  WtCols.prototype._get_wtColMd3_ = function () {
    return this._wtColMd3$delegate.getValue_4(this, wtColMd3$factory_0());
  };
  WtCols.prototype._get_wtColMd4_ = function () {
    return this._wtColMd4$delegate.getValue_4(this, wtColMd4$factory_0());
  };
  WtCols.prototype._get_wtColMd8_ = function () {
    return this._wtColMd8$delegate.getValue_4(this, wtColMd8$factory_0());
  };
  WtCols.prototype._get_wtColMd9_ = function () {
    return this._wtColMd9$delegate.getValue_4(this, wtColMd9$factory_0());
  };
  WtCols.prototype._get_wtColMd10_ = function () {
    return this._wtColMd10$delegate.getValue_4(this, wtColMd10$factory_0());
  };
  WtCols.prototype._get_wtColMd11_ = function () {
    return this._wtColMd11$delegate.getValue_4(this, wtColMd11$factory_0());
  };
  WtCols.prototype._get_wtColMd6_ = function () {
    return this._wtColMd6$delegate.getValue_4(this, wtColMd6$factory_0());
  };
  WtCols.prototype._get_wtColMd12_ = function () {
    return this._wtColMd12$delegate.getValue_4(this, wtColMd12$factory_0());
  };
  WtCols.prototype._get_wtColSm12_ = function () {
    return this._wtColSm12$delegate.getValue_4(this, wtColSm12$factory_0());
  };
  WtCols.prototype._get_wtColLg6_ = function () {
    return this._wtColLg6$delegate.getValue_4(this, wtColLg6$factory_0());
  };
  WtCols.prototype._get_wtColSmAutoFill_ = function () {
    return this._wtColSmAutoFill$delegate.getValue_4(this, wtColSmAutoFill$factory_0());
  };
  WtCols.prototype._get_wtColAutoFill_ = function () {
    return this._wtColAutoFill$delegate.getValue_4(this, wtColAutoFill$factory_0());
  };
  WtCols.prototype._get_wtColInline_ = function () {
    return this._wtColInline$delegate.getValue_4(this, wtColInline$factory_0());
  };
  WtCols.prototype.style_43 = function (selector, cssRule) {
    return this.style_42(selector, cssRule);
  };
  WtCols.$metadata$ = {
    simpleName: 'WtCols',
    kind: 'object',
    interfaces: []
  };
  var WtCols_instance;
  function WtCols_getInstance() {
    if (WtCols_instance == null)
      new WtCols();
    return WtCols_instance;
  }
  function _no_name_provided__530($cssSelector, $rulesBuild) {
    this._$cssSelector = $cssSelector;
    this._$rulesBuild = $rulesBuild;
  }
  _no_name_provided__530.prototype.invoke_1000 = function ($this$media) {
    $this$media.style_11(this._$cssSelector, this._$rulesBuild);
  };
  _no_name_provided__530.prototype.invoke_1115 = function (p1) {
    this.invoke_1000((!(p1 == null) ? isInterface(p1, GenericStyleSheetBuilder) : false) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__530.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function wtCol2$factory() {
    return getPropertyCallableRef('wtCol2', 1, KProperty1, function (receiver) {
      return receiver._get_wtCol2_();
    }, null);
  }
  function wtCol3$factory() {
    return getPropertyCallableRef('wtCol3', 1, KProperty1, function (receiver) {
      return receiver._get_wtCol3_();
    }, null);
  }
  function wtCol4$factory() {
    return getPropertyCallableRef('wtCol4', 1, KProperty1, function (receiver) {
      return receiver._get_wtCol4_();
    }, null);
  }
  function wtCol5$factory() {
    return getPropertyCallableRef('wtCol5', 1, KProperty1, function (receiver) {
      return receiver._get_wtCol5_();
    }, null);
  }
  function wtCol6$factory() {
    return getPropertyCallableRef('wtCol6', 1, KProperty1, function (receiver) {
      return receiver._get_wtCol6_();
    }, null);
  }
  function wtCol9$factory() {
    return getPropertyCallableRef('wtCol9', 1, KProperty1, function (receiver) {
      return receiver._get_wtCol9_();
    }, null);
  }
  function wtCol10$factory() {
    return getPropertyCallableRef('wtCol10', 1, KProperty1, function (receiver) {
      return receiver._get_wtCol10_();
    }, null);
  }
  function wtColMd3$factory() {
    return getPropertyCallableRef('wtColMd3', 1, KProperty1, function (receiver) {
      return receiver._get_wtColMd3_();
    }, null);
  }
  function wtColMd4$factory() {
    return getPropertyCallableRef('wtColMd4', 1, KProperty1, function (receiver) {
      return receiver._get_wtColMd4_();
    }, null);
  }
  function wtColMd8$factory() {
    return getPropertyCallableRef('wtColMd8', 1, KProperty1, function (receiver) {
      return receiver._get_wtColMd8_();
    }, null);
  }
  function wtColMd9$factory() {
    return getPropertyCallableRef('wtColMd9', 1, KProperty1, function (receiver) {
      return receiver._get_wtColMd9_();
    }, null);
  }
  function wtColMd10$factory() {
    return getPropertyCallableRef('wtColMd10', 1, KProperty1, function (receiver) {
      return receiver._get_wtColMd10_();
    }, null);
  }
  function wtColMd11$factory() {
    return getPropertyCallableRef('wtColMd11', 1, KProperty1, function (receiver) {
      return receiver._get_wtColMd11_();
    }, null);
  }
  function wtColMd6$factory() {
    return getPropertyCallableRef('wtColMd6', 1, KProperty1, function (receiver) {
      return receiver._get_wtColMd6_();
    }, null);
  }
  function wtColMd12$factory() {
    return getPropertyCallableRef('wtColMd12', 1, KProperty1, function (receiver) {
      return receiver._get_wtColMd12_();
    }, null);
  }
  function wtColSm12$factory() {
    return getPropertyCallableRef('wtColSm12', 1, KProperty1, function (receiver) {
      return receiver._get_wtColSm12_();
    }, null);
  }
  function wtColLg6$factory() {
    return getPropertyCallableRef('wtColLg6', 1, KProperty1, function (receiver) {
      return receiver._get_wtColLg6_();
    }, null);
  }
  function wtColSmAutoFill$factory() {
    return getPropertyCallableRef('wtColSmAutoFill', 1, KProperty1, function (receiver) {
      return receiver._get_wtColSmAutoFill_();
    }, null);
  }
  function wtColAutoFill$factory() {
    return getPropertyCallableRef('wtColAutoFill', 1, KProperty1, function (receiver) {
      return receiver._get_wtColAutoFill_();
    }, null);
  }
  function wtColInline$factory() {
    return getPropertyCallableRef('wtColInline', 1, KProperty1, function (receiver) {
      return receiver._get_wtColInline_();
    }, null);
  }
  function wtCol2$factory_0() {
    return getPropertyCallableRef('wtCol2', 1, KProperty1, function (receiver) {
      return receiver._get_wtCol2_();
    }, null);
  }
  function wtCol3$factory_0() {
    return getPropertyCallableRef('wtCol3', 1, KProperty1, function (receiver) {
      return receiver._get_wtCol3_();
    }, null);
  }
  function wtCol4$factory_0() {
    return getPropertyCallableRef('wtCol4', 1, KProperty1, function (receiver) {
      return receiver._get_wtCol4_();
    }, null);
  }
  function wtCol5$factory_0() {
    return getPropertyCallableRef('wtCol5', 1, KProperty1, function (receiver) {
      return receiver._get_wtCol5_();
    }, null);
  }
  function wtCol6$factory_0() {
    return getPropertyCallableRef('wtCol6', 1, KProperty1, function (receiver) {
      return receiver._get_wtCol6_();
    }, null);
  }
  function wtCol9$factory_0() {
    return getPropertyCallableRef('wtCol9', 1, KProperty1, function (receiver) {
      return receiver._get_wtCol9_();
    }, null);
  }
  function wtCol10$factory_0() {
    return getPropertyCallableRef('wtCol10', 1, KProperty1, function (receiver) {
      return receiver._get_wtCol10_();
    }, null);
  }
  function wtColMd3$factory_0() {
    return getPropertyCallableRef('wtColMd3', 1, KProperty1, function (receiver) {
      return receiver._get_wtColMd3_();
    }, null);
  }
  function wtColMd4$factory_0() {
    return getPropertyCallableRef('wtColMd4', 1, KProperty1, function (receiver) {
      return receiver._get_wtColMd4_();
    }, null);
  }
  function wtColMd8$factory_0() {
    return getPropertyCallableRef('wtColMd8', 1, KProperty1, function (receiver) {
      return receiver._get_wtColMd8_();
    }, null);
  }
  function wtColMd9$factory_0() {
    return getPropertyCallableRef('wtColMd9', 1, KProperty1, function (receiver) {
      return receiver._get_wtColMd9_();
    }, null);
  }
  function wtColMd10$factory_0() {
    return getPropertyCallableRef('wtColMd10', 1, KProperty1, function (receiver) {
      return receiver._get_wtColMd10_();
    }, null);
  }
  function wtColMd11$factory_0() {
    return getPropertyCallableRef('wtColMd11', 1, KProperty1, function (receiver) {
      return receiver._get_wtColMd11_();
    }, null);
  }
  function wtColMd6$factory_0() {
    return getPropertyCallableRef('wtColMd6', 1, KProperty1, function (receiver) {
      return receiver._get_wtColMd6_();
    }, null);
  }
  function wtColMd12$factory_0() {
    return getPropertyCallableRef('wtColMd12', 1, KProperty1, function (receiver) {
      return receiver._get_wtColMd12_();
    }, null);
  }
  function wtColSm12$factory_0() {
    return getPropertyCallableRef('wtColSm12', 1, KProperty1, function (receiver) {
      return receiver._get_wtColSm12_();
    }, null);
  }
  function wtColLg6$factory_0() {
    return getPropertyCallableRef('wtColLg6', 1, KProperty1, function (receiver) {
      return receiver._get_wtColLg6_();
    }, null);
  }
  function wtColSmAutoFill$factory_0() {
    return getPropertyCallableRef('wtColSmAutoFill', 1, KProperty1, function (receiver) {
      return receiver._get_wtColSmAutoFill_();
    }, null);
  }
  function wtColAutoFill$factory_0() {
    return getPropertyCallableRef('wtColAutoFill', 1, KProperty1, function (receiver) {
      return receiver._get_wtColAutoFill_();
    }, null);
  }
  function wtColInline$factory_0() {
    return getPropertyCallableRef('wtColInline', 1, KProperty1, function (receiver) {
      return receiver._get_wtColInline_();
    }, null);
  }
  function _no_name_provided_$factory_467($cssSelector, $rulesBuild) {
    var i = new _no_name_provided__530($cssSelector, $rulesBuild);
    return function (p1) {
      i.invoke_1000(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_468() {
    var i = new _no_name_provided__510();
    return function (p1) {
      i.invoke_1114(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_469() {
    var i = new _no_name_provided__511();
    return function (p1) {
      i.invoke_1114(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_470() {
    var i = new _no_name_provided__512();
    return function (p1) {
      i.invoke_1114(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_471() {
    var i = new _no_name_provided__513();
    return function (p1) {
      i.invoke_1114(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_472() {
    var i = new _no_name_provided__514();
    return function (p1) {
      i.invoke_1114(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_473() {
    var i = new _no_name_provided__515();
    return function (p1) {
      i.invoke_1114(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_474() {
    var i = new _no_name_provided__516();
    return function (p1) {
      i.invoke_1114(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_475() {
    var i = new _no_name_provided__517();
    return function (p1) {
      i.invoke_1114(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_476() {
    var i = new _no_name_provided__518();
    return function (p1) {
      i.invoke_1114(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_477() {
    var i = new _no_name_provided__519();
    return function (p1) {
      i.invoke_1114(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_478() {
    var i = new _no_name_provided__520();
    return function (p1) {
      i.invoke_1114(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_479() {
    var i = new _no_name_provided__521();
    return function (p1) {
      i.invoke_1114(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_480() {
    var i = new _no_name_provided__522();
    return function (p1) {
      i.invoke_1114(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_481() {
    var i = new _no_name_provided__523();
    return function (p1) {
      i.invoke_1114(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_482() {
    var i = new _no_name_provided__524();
    return function (p1) {
      i.invoke_1114(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_483() {
    var i = new _no_name_provided__525();
    return function (p1) {
      i.invoke_1114(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_484() {
    var i = new _no_name_provided__526();
    return function (p1) {
      i.invoke_1114(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_485() {
    var i = new _no_name_provided__527();
    return function (p1) {
      i.invoke_1114(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_486() {
    var i = new _no_name_provided__528();
    return function (p1) {
      i.invoke_1114(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_487() {
    var i = new _no_name_provided__529();
    return function (p1) {
      i.invoke_1114(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_488() {
    var i = new _no_name_provided__499();
    return function (p1) {
      i.invoke_1114(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_489() {
    var i = new _no_name_provided__500();
    return function (p1) {
      i.invoke_1114(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_490() {
    var i = new _no_name_provided__501();
    return function (p1) {
      i.invoke_1114(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_491() {
    var i = new _no_name_provided__502();
    return function (p1) {
      i.invoke_1114(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_492() {
    var i = new _no_name_provided__503();
    return function (p1) {
      i.invoke_1114(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_493() {
    var i = new _no_name_provided__504();
    return function (p1) {
      i.invoke_1114(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_494() {
    var i = new _no_name_provided__505();
    return function (p1) {
      i.invoke_1114(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_495() {
    var i = new _no_name_provided__506();
    return function (p1) {
      i.invoke_1114(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_496() {
    var i = new _no_name_provided__507();
    return function (p1) {
      i.invoke_1114(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_497() {
    var i = new _no_name_provided__508();
    return function (p1) {
      i.invoke_1114(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_498() {
    var i = new _no_name_provided__509();
    return function (p1) {
      i.invoke_1114(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided__531() {
  }
  _no_name_provided__531.prototype.invoke_1114 = function ($this$null) {
    var tmp0_value_0 = _get_percent_(100);
    $this$null.property_4('max-width', Companion_getInstance_35().invoke_396(tmp0_value_0));
    var tmp1_value_0 = _get_px_(16);
    $this$null.property_4('padding-left', Companion_getInstance_35().invoke_396(tmp1_value_0));
    var tmp2_value_0 = _get_px_(16);
    $this$null.property_4('padding-right', Companion_getInstance_35().invoke_396(tmp2_value_0));
  };
  _no_name_provided__531.prototype.invoke_1115 = function (p1) {
    this.invoke_1114((!(p1 == null) ? isInterface(p1, CSSBuilder) : false) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__531.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__532() {
  }
  _no_name_provided__532.prototype.invoke_1114 = function ($this$null) {
    var tmp0_value_0 = _get_px_(996);
    $this$null.property_4('max-width', Companion_getInstance_35().invoke_396(tmp0_value_0));
    var tmp1_value_0 = _get_px_(22);
    $this$null.property_4('padding-left', Companion_getInstance_35().invoke_396(tmp1_value_0));
    var tmp2_value_0 = _get_px_(22);
    $this$null.property_4('padding-right', Companion_getInstance_35().invoke_396(tmp2_value_0));
  };
  _no_name_provided__532.prototype.invoke_1115 = function (p1) {
    this.invoke_1114((!(p1 == null) ? isInterface(p1, CSSBuilder) : false) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__532.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__533() {
  }
  _no_name_provided__533.prototype.invoke_1114 = function ($this$null) {
    var tmp0_value_0 = _get_percent_(100);
    $this$null.property_4('max-width', Companion_getInstance_35().invoke_396(tmp0_value_0));
    var tmp1_value_0 = _get_px_(22);
    $this$null.property_4('padding-left', Companion_getInstance_35().invoke_396(tmp1_value_0));
    var tmp2_value_0 = _get_px_(22);
    $this$null.property_4('padding-right', Companion_getInstance_35().invoke_396(tmp2_value_0));
  };
  _no_name_provided__533.prototype.invoke_1115 = function (p1) {
    this.invoke_1114((!(p1 == null) ? isInterface(p1, CSSBuilder) : false) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__533.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__534($this_style) {
    this._$this_style_0 = $this_style;
  }
  _no_name_provided__534.prototype.invoke_1078 = function ($this$media) {
    var tmp = this._$this_style_0._get_self__1();
    $this$media.style_11(tmp, _no_name_provided_$factory_503());
  };
  _no_name_provided__534.prototype.invoke_1115 = function (p1) {
    this.invoke_1078((!(p1 == null) ? isInterface(p1, GenericStyleSheetBuilder) : false) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__534.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__535($this_style) {
    this._$this_style_1 = $this_style;
  }
  _no_name_provided__535.prototype.invoke_1078 = function ($this$media) {
    var tmp = this._$this_style_1._get_self__1();
    $this$media.style_11(tmp, _no_name_provided_$factory_504());
  };
  _no_name_provided__535.prototype.invoke_1115 = function (p1) {
    this.invoke_1078((!(p1 == null) ? isInterface(p1, GenericStyleSheetBuilder) : false) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__535.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__536($this_style) {
    this._$this_style_2 = $this_style;
  }
  _no_name_provided__536.prototype.invoke_1078 = function ($this$media) {
    var tmp = this._$this_style_2._get_self__1();
    $this$media.style_11(tmp, _no_name_provided_$factory_505());
  };
  _no_name_provided__536.prototype.invoke_1115 = function (p1) {
    this.invoke_1078((!(p1 == null) ? isInterface(p1, GenericStyleSheetBuilder) : false) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__536.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__537() {
  }
  _no_name_provided__537.prototype.invoke_1114 = function ($this$style) {
    var tmp0_value_0 = 'auto';
    $this$style.property_4('margin-left', Companion_getInstance_35().invoke_402(tmp0_value_0));
    var tmp1_value_0 = 'auto';
    $this$style.property_4('margin-right', Companion_getInstance_35().invoke_402(tmp1_value_0));
    var tmp2_value_0 = 'border-box';
    $this$style.property_4('box-sizing', Companion_getInstance_35().invoke_402(tmp2_value_0));
    var tmp3_value_0 = _get_px_(22);
    $this$style.property_4('padding-left', Companion_getInstance_35().invoke_396(tmp3_value_0));
    var tmp4_value_0 = _get_px_(22);
    $this$style.property_4('padding-right', Companion_getInstance_35().invoke_396(tmp4_value_0));
    var tmp5_value_0 = _get_px_(1276);
    $this$style.property_4('max-width', Companion_getInstance_35().invoke_396(tmp5_value_0));
    var tmp = maxWidth($this$style, _get_px_(640));
    media($this$style, tmp, _no_name_provided_$factory_500($this$style));
    var tmp_0 = maxWidth($this$style, _get_px_(1276));
    media($this$style, tmp_0, _no_name_provided_$factory_501($this$style));
    var tmp_1 = maxWidth($this$style, _get_px_(1000));
    media($this$style, tmp_1, _no_name_provided_$factory_502($this$style));
  };
  _no_name_provided__537.prototype.invoke_1115 = function (p1) {
    this.invoke_1114((!(p1 == null) ? isInterface(p1, CSSBuilder) : false) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__537.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function WtContainer() {
    WtContainer_instance = this;
    StyleSheet_0.call(this, AppStylesheet_getInstance());
    var tmp = this;
    tmp._wtContainer$delegate = this.style_45(_no_name_provided_$factory_499()).provideDelegate(this, wtContainer$factory());
    this._$stable_53 = 8;
  }
  WtContainer.prototype._get_wtContainer_ = function () {
    return this._wtContainer$delegate.getValue_4(this, wtContainer$factory_0());
  };
  WtContainer.prototype.style_43 = function (selector, cssRule) {
    return this.style_42(selector, cssRule);
  };
  WtContainer.$metadata$ = {
    simpleName: 'WtContainer',
    kind: 'object',
    interfaces: []
  };
  var WtContainer_instance;
  function WtContainer_getInstance() {
    if (WtContainer_instance == null)
      new WtContainer();
    return WtContainer_instance;
  }
  function wtContainer$factory() {
    return getPropertyCallableRef('wtContainer', 1, KProperty1, function (receiver) {
      return receiver._get_wtContainer_();
    }, null);
  }
  function wtContainer$factory_0() {
    return getPropertyCallableRef('wtContainer', 1, KProperty1, function (receiver) {
      return receiver._get_wtContainer_();
    }, null);
  }
  function _no_name_provided_$factory_499() {
    var i = new _no_name_provided__537();
    return function (p1) {
      i.invoke_1114(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_500($this_style) {
    var i = new _no_name_provided__534($this_style);
    return function (p1) {
      i.invoke_1078(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_501($this_style) {
    var i = new _no_name_provided__535($this_style);
    return function (p1) {
      i.invoke_1078(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_502($this_style) {
    var i = new _no_name_provided__536($this_style);
    return function (p1) {
      i.invoke_1078(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_503() {
    var i = new _no_name_provided__531();
    return function (p1) {
      i.invoke_1114(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_504() {
    var i = new _no_name_provided__532();
    return function (p1) {
      i.invoke_1114(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_505() {
    var i = new _no_name_provided__533();
    return function (p1) {
      i.invoke_1114(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided__538() {
  }
  _no_name_provided__538.prototype.invoke_1114 = function ($this$null) {
    marginTop($this$null, _get_px_(12));
  };
  _no_name_provided__538.prototype.invoke_1115 = function (p1) {
    this.invoke_1114((!(p1 == null) ? isInterface(p1, CSSBuilder) : false) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__538.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__539($this_style) {
    this._$this_style_3 = $this_style;
  }
  _no_name_provided__539.prototype.invoke_1078 = function ($this$media) {
    var tmp = this._$this_style_3._get_self__1();
    $this$media.style_11(tmp, _no_name_provided_$factory_512());
  };
  _no_name_provided__539.prototype.invoke_1115 = function (p1) {
    this.invoke_1078((!(p1 == null) ? isInterface(p1, GenericStyleSheetBuilder) : false) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__539.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__540() {
  }
  _no_name_provided__540.prototype.invoke_1114 = function ($this$null) {
    marginTop($this$null, _get_px_(24));
  };
  _no_name_provided__540.prototype.invoke_1115 = function (p1) {
    this.invoke_1114((!(p1 == null) ? isInterface(p1, CSSBuilder) : false) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__540.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__541($this_style) {
    this._$this_style_4 = $this_style;
  }
  _no_name_provided__541.prototype.invoke_1078 = function ($this$media) {
    var tmp = this._$this_style_4._get_self__1();
    $this$media.style_11(tmp, _no_name_provided_$factory_514());
  };
  _no_name_provided__541.prototype.invoke_1115 = function (p1) {
    this.invoke_1078((!(p1 == null) ? isInterface(p1, GenericStyleSheetBuilder) : false) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__541.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__542() {
  }
  _no_name_provided__542.prototype.invoke_1114 = function ($this$style) {
    marginTop($this$style, _get_px_(96));
    var tmp0_value_0 = '' + 'calc(4*' + value(AppCSSVariables_getInstance()._get_wtOffsetTopUnit_(), _get_px_(24)) + ')';
    $this$style.property_4('margin-top', Companion_getInstance_35().invoke_402(tmp0_value_0));
  };
  _no_name_provided__542.prototype.invoke_1115 = function (p1) {
    this.invoke_1114((!(p1 == null) ? isInterface(p1, CSSBuilder) : false) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__542.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__543() {
  }
  _no_name_provided__543.prototype.invoke_1114 = function ($this$style) {
    marginTop($this$style, _get_px_(24));
    var tmp0_value_0 = '' + 'calc(1*' + value(AppCSSVariables_getInstance()._get_wtOffsetTopUnit_(), _get_px_(24)) + ')';
    $this$style.property_4('margin-top', Companion_getInstance_35().invoke_402(tmp0_value_0));
  };
  _no_name_provided__543.prototype.invoke_1115 = function (p1) {
    this.invoke_1114((!(p1 == null) ? isInterface(p1, CSSBuilder) : false) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__543.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__544() {
  }
  _no_name_provided__544.prototype.invoke_1114 = function ($this$style) {
    marginTop($this$style, _get_px_(48));
  };
  _no_name_provided__544.prototype.invoke_1115 = function (p1) {
    this.invoke_1114((!(p1 == null) ? isInterface(p1, CSSBuilder) : false) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__544.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__545() {
  }
  _no_name_provided__545.prototype.invoke_1114 = function ($this$style) {
    var tmp = maxWidth($this$style, _get_px_(640));
    media($this$style, tmp, _no_name_provided_$factory_511($this$style));
  };
  _no_name_provided__545.prototype.invoke_1115 = function (p1) {
    this.invoke_1114((!(p1 == null) ? isInterface(p1, CSSBuilder) : false) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__545.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__546() {
  }
  _no_name_provided__546.prototype.invoke_1114 = function ($this$style) {
    var tmp = maxWidth($this$style, _get_px_(640));
    media($this$style, tmp, _no_name_provided_$factory_513($this$style));
  };
  _no_name_provided__546.prototype.invoke_1115 = function (p1) {
    this.invoke_1114((!(p1 == null) ? isInterface(p1, CSSBuilder) : false) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__546.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function WtOffsets() {
    WtOffsets_instance = this;
    StyleSheet_0.call(this, AppStylesheet_getInstance());
    var tmp = this;
    tmp._wtTopOffset96$delegate = this.style_45(_no_name_provided_$factory_506()).provideDelegate(this, wtTopOffset96$factory());
    var tmp_0 = this;
    tmp_0._wtTopOffset24$delegate = this.style_45(_no_name_provided_$factory_507()).provideDelegate(this, wtTopOffset24$factory());
    var tmp_1 = this;
    tmp_1._wtTopOffset48$delegate = this.style_45(_no_name_provided_$factory_508()).provideDelegate(this, wtTopOffset48$factory());
    var tmp_2 = this;
    tmp_2._wtTopOffsetSm12$delegate = this.style_45(_no_name_provided_$factory_509()).provideDelegate(this, wtTopOffsetSm12$factory());
    var tmp_3 = this;
    tmp_3._wtTopOffsetSm24$delegate = this.style_45(_no_name_provided_$factory_510()).provideDelegate(this, wtTopOffsetSm24$factory());
    this._$stable_54 = 0;
  }
  WtOffsets.prototype._get_wtTopOffset96_ = function () {
    return this._wtTopOffset96$delegate.getValue_4(this, wtTopOffset96$factory_0());
  };
  WtOffsets.prototype._get_wtTopOffset24_ = function () {
    return this._wtTopOffset24$delegate.getValue_4(this, wtTopOffset24$factory_0());
  };
  WtOffsets.prototype._get_wtTopOffset48_ = function () {
    return this._wtTopOffset48$delegate.getValue_4(this, wtTopOffset48$factory_0());
  };
  WtOffsets.prototype._get_wtTopOffsetSm12_ = function () {
    return this._wtTopOffsetSm12$delegate.getValue_4(this, wtTopOffsetSm12$factory_0());
  };
  WtOffsets.prototype._get_wtTopOffsetSm24_ = function () {
    return this._wtTopOffsetSm24$delegate.getValue_4(this, wtTopOffsetSm24$factory_0());
  };
  WtOffsets.prototype.style_43 = function (selector, cssRule) {
    return this.style_42(selector, cssRule);
  };
  WtOffsets.$metadata$ = {
    simpleName: 'WtOffsets',
    kind: 'object',
    interfaces: []
  };
  var WtOffsets_instance;
  function WtOffsets_getInstance() {
    if (WtOffsets_instance == null)
      new WtOffsets();
    return WtOffsets_instance;
  }
  function wtTopOffset96$factory() {
    return getPropertyCallableRef('wtTopOffset96', 1, KProperty1, function (receiver) {
      return receiver._get_wtTopOffset96_();
    }, null);
  }
  function wtTopOffset24$factory() {
    return getPropertyCallableRef('wtTopOffset24', 1, KProperty1, function (receiver) {
      return receiver._get_wtTopOffset24_();
    }, null);
  }
  function wtTopOffset48$factory() {
    return getPropertyCallableRef('wtTopOffset48', 1, KProperty1, function (receiver) {
      return receiver._get_wtTopOffset48_();
    }, null);
  }
  function wtTopOffsetSm12$factory() {
    return getPropertyCallableRef('wtTopOffsetSm12', 1, KProperty1, function (receiver) {
      return receiver._get_wtTopOffsetSm12_();
    }, null);
  }
  function wtTopOffsetSm24$factory() {
    return getPropertyCallableRef('wtTopOffsetSm24', 1, KProperty1, function (receiver) {
      return receiver._get_wtTopOffsetSm24_();
    }, null);
  }
  function wtTopOffset96$factory_0() {
    return getPropertyCallableRef('wtTopOffset96', 1, KProperty1, function (receiver) {
      return receiver._get_wtTopOffset96_();
    }, null);
  }
  function wtTopOffset24$factory_0() {
    return getPropertyCallableRef('wtTopOffset24', 1, KProperty1, function (receiver) {
      return receiver._get_wtTopOffset24_();
    }, null);
  }
  function wtTopOffset48$factory_0() {
    return getPropertyCallableRef('wtTopOffset48', 1, KProperty1, function (receiver) {
      return receiver._get_wtTopOffset48_();
    }, null);
  }
  function wtTopOffsetSm12$factory_0() {
    return getPropertyCallableRef('wtTopOffsetSm12', 1, KProperty1, function (receiver) {
      return receiver._get_wtTopOffsetSm12_();
    }, null);
  }
  function wtTopOffsetSm24$factory_0() {
    return getPropertyCallableRef('wtTopOffsetSm24', 1, KProperty1, function (receiver) {
      return receiver._get_wtTopOffsetSm24_();
    }, null);
  }
  function _no_name_provided_$factory_506() {
    var i = new _no_name_provided__542();
    return function (p1) {
      i.invoke_1114(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_507() {
    var i = new _no_name_provided__543();
    return function (p1) {
      i.invoke_1114(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_508() {
    var i = new _no_name_provided__544();
    return function (p1) {
      i.invoke_1114(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_509() {
    var i = new _no_name_provided__545();
    return function (p1) {
      i.invoke_1114(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_510() {
    var i = new _no_name_provided__546();
    return function (p1) {
      i.invoke_1114(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_511($this_style) {
    var i = new _no_name_provided__539($this_style);
    return function (p1) {
      i.invoke_1078(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_512() {
    var i = new _no_name_provided__538();
    return function (p1) {
      i.invoke_1114(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_513($this_style) {
    var i = new _no_name_provided__541($this_style);
    return function (p1) {
      i.invoke_1078(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_514() {
    var i = new _no_name_provided__540();
    return function (p1) {
      i.invoke_1114(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided__547() {
  }
  _no_name_provided__547.prototype.invoke_1114 = function ($this$null) {
    $this$null.invoke_403(AppCSSVariables_getInstance()._get_wtHorizontalLayoutGutter_(), _get_px_(8));
  };
  _no_name_provided__547.prototype.invoke_1115 = function (p1) {
    this.invoke_1114((!(p1 == null) ? isInterface(p1, CSSBuilder) : false) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__547.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__548($this_style) {
    this._$this_style_5 = $this_style;
  }
  _no_name_provided__548.prototype.invoke_1078 = function ($this$media) {
    var tmp = this._$this_style_5._get_self__1();
    $this$media.style_11(tmp, _no_name_provided_$factory_520());
  };
  _no_name_provided__548.prototype.invoke_1115 = function (p1) {
    this.invoke_1078((!(p1 == null) ? isInterface(p1, GenericStyleSheetBuilder) : false) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__548.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__549() {
  }
  _no_name_provided__549.prototype.invoke_1114 = function ($this$null) {
    alignItems($this$null, AlignItems_Center_getInstance());
  };
  _no_name_provided__549.prototype.invoke_1115 = function (p1) {
    this.invoke_1114((!(p1 == null) ? isInterface(p1, CSSBuilder) : false) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__549.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__550() {
  }
  _no_name_provided__550.prototype.invoke_1114 = function ($this$style) {
    $this$style.invoke_403(AppCSSVariables_getInstance()._get_wtHorizontalLayoutGutter_(), _get_px_(0));
    display($this$style, DisplayStyle_Flex_getInstance());
    flexWrap($this$style, FlexWrap_Wrap_getInstance());
    var tmp = AppCSSVariables_getInstance()._get_wtHorizontalLayoutGutter_();
    var tmp0_value_0 = '' + 'calc(-1*' + value$default(tmp, null, 1, null) + ')';
    $this$style.property_4('margin-right', Companion_getInstance_35().invoke_402(tmp0_value_0));
    var tmp_0 = AppCSSVariables_getInstance()._get_wtHorizontalLayoutGutter_();
    var tmp1_value_0 = '' + 'calc(-1*' + value$default(tmp_0, null, 1, null) + ')';
    $this$style.property_4('margin-left', Companion_getInstance_35().invoke_402(tmp1_value_0));
    $this$style.property_4('box-sizing', Companion_getInstance_35().invoke_402('border-box'));
  };
  _no_name_provided__550.prototype.invoke_1115 = function (p1) {
    this.invoke_1114((!(p1 == null) ? isInterface(p1, CSSBuilder) : false) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__550.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__551() {
  }
  _no_name_provided__551.prototype.invoke_1114 = function ($this$style) {
    $this$style.invoke_403(AppCSSVariables_getInstance()._get_wtHorizontalLayoutGutter_(), _get_px_(16));
    var tmp = maxWidth($this$style, _get_px_(640));
    media($this$style, tmp, _no_name_provided_$factory_519($this$style));
  };
  _no_name_provided__551.prototype.invoke_1115 = function (p1) {
    this.invoke_1114((!(p1 == null) ? isInterface(p1, CSSBuilder) : false) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__551.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__552() {
  }
  _no_name_provided__552.prototype.invoke_1114 = function ($this$style) {
    $this$style.invoke_403(AppCSSVariables_getInstance()._get_wtHorizontalLayoutGutter_(), _get_px_(6));
  };
  _no_name_provided__552.prototype.invoke_1115 = function (p1) {
    this.invoke_1114((!(p1 == null) ? isInterface(p1, CSSBuilder) : false) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__552.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__553() {
  }
  _no_name_provided__553.prototype.invoke_1114 = function ($this$style) {
    var tmp = $this$style._get_self__1();
    $this$style.style_11(tmp, _no_name_provided_$factory_521());
  };
  _no_name_provided__553.prototype.invoke_1115 = function (p1) {
    this.invoke_1114((!(p1 == null) ? isInterface(p1, CSSBuilder) : false) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__553.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function WtRows() {
    WtRows_instance = this;
    StyleSheet_0.call(this, AppStylesheet_getInstance());
    var tmp = this;
    tmp._wtRow$delegate = this.style_45(_no_name_provided_$factory_515()).provideDelegate(this, wtRow$factory());
    var tmp_0 = this;
    tmp_0._wtRowSizeM$delegate = this.style_45(_no_name_provided_$factory_516()).provideDelegate(this, wtRowSizeM$factory());
    var tmp_1 = this;
    tmp_1._wtRowSizeXs$delegate = this.style_45(_no_name_provided_$factory_517()).provideDelegate(this, wtRowSizeXs$factory());
    var tmp_2 = this;
    tmp_2._wtRowSmAlignItemsCenter$delegate = this.style_45(_no_name_provided_$factory_518()).provideDelegate(this, wtRowSmAlignItemsCenter$factory());
    this._$stable_55 = 0;
  }
  WtRows.prototype._get_wtRow_ = function () {
    return this._wtRow$delegate.getValue_4(this, wtRow$factory_0());
  };
  WtRows.prototype._get_wtRowSizeM_ = function () {
    return this._wtRowSizeM$delegate.getValue_4(this, wtRowSizeM$factory_0());
  };
  WtRows.prototype._get_wtRowSizeXs_ = function () {
    return this._wtRowSizeXs$delegate.getValue_4(this, wtRowSizeXs$factory_0());
  };
  WtRows.prototype._get_wtRowSmAlignItemsCenter_ = function () {
    return this._wtRowSmAlignItemsCenter$delegate.getValue_4(this, wtRowSmAlignItemsCenter$factory_0());
  };
  WtRows.prototype.style_43 = function (selector, cssRule) {
    return this.style_42(selector, cssRule);
  };
  WtRows.$metadata$ = {
    simpleName: 'WtRows',
    kind: 'object',
    interfaces: []
  };
  var WtRows_instance;
  function WtRows_getInstance() {
    if (WtRows_instance == null)
      new WtRows();
    return WtRows_instance;
  }
  function wtRow$factory() {
    return getPropertyCallableRef('wtRow', 1, KProperty1, function (receiver) {
      return receiver._get_wtRow_();
    }, null);
  }
  function wtRowSizeM$factory() {
    return getPropertyCallableRef('wtRowSizeM', 1, KProperty1, function (receiver) {
      return receiver._get_wtRowSizeM_();
    }, null);
  }
  function wtRowSizeXs$factory() {
    return getPropertyCallableRef('wtRowSizeXs', 1, KProperty1, function (receiver) {
      return receiver._get_wtRowSizeXs_();
    }, null);
  }
  function wtRowSmAlignItemsCenter$factory() {
    return getPropertyCallableRef('wtRowSmAlignItemsCenter', 1, KProperty1, function (receiver) {
      return receiver._get_wtRowSmAlignItemsCenter_();
    }, null);
  }
  function wtRow$factory_0() {
    return getPropertyCallableRef('wtRow', 1, KProperty1, function (receiver) {
      return receiver._get_wtRow_();
    }, null);
  }
  function wtRowSizeM$factory_0() {
    return getPropertyCallableRef('wtRowSizeM', 1, KProperty1, function (receiver) {
      return receiver._get_wtRowSizeM_();
    }, null);
  }
  function wtRowSizeXs$factory_0() {
    return getPropertyCallableRef('wtRowSizeXs', 1, KProperty1, function (receiver) {
      return receiver._get_wtRowSizeXs_();
    }, null);
  }
  function wtRowSmAlignItemsCenter$factory_0() {
    return getPropertyCallableRef('wtRowSmAlignItemsCenter', 1, KProperty1, function (receiver) {
      return receiver._get_wtRowSmAlignItemsCenter_();
    }, null);
  }
  function _no_name_provided_$factory_515() {
    var i = new _no_name_provided__550();
    return function (p1) {
      i.invoke_1114(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_516() {
    var i = new _no_name_provided__551();
    return function (p1) {
      i.invoke_1114(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_517() {
    var i = new _no_name_provided__552();
    return function (p1) {
      i.invoke_1114(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_518() {
    var i = new _no_name_provided__553();
    return function (p1) {
      i.invoke_1114(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_519($this_style) {
    var i = new _no_name_provided__548($this_style);
    return function (p1) {
      i.invoke_1078(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_520() {
    var i = new _no_name_provided__547();
    return function (p1) {
      i.invoke_1114(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_521() {
    var i = new _no_name_provided__549();
    return function (p1) {
      i.invoke_1114(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided__554() {
  }
  _no_name_provided__554.prototype.invoke_1114 = function ($this$style) {
    var tmp0_value_0 = 'border-box';
    $this$style.property_4('box-sizing', Companion_getInstance_35().invoke_402(tmp0_value_0));
    var tmp1_value_0 = _get_px_(96);
    $this$style.property_4('padding-bottom', Companion_getInstance_35().invoke_396(tmp1_value_0));
    var tmp2_value_0 = _get_px_(1);
    $this$style.property_4('padding-top', Companion_getInstance_35().invoke_396(tmp2_value_0));
    var tmp3_value_0 = '' + 'calc(4*' + value(AppCSSVariables_getInstance()._get_wtOffsetTopUnit_(), _get_px_(24)) + ')';
    $this$style.property_4('padding-bottom', Companion_getInstance_35().invoke_402(tmp3_value_0));
    backgroundColor_0($this$style, '#fff');
  };
  _no_name_provided__554.prototype.invoke_1115 = function (p1) {
    this.invoke_1114((!(p1 == null) ? isInterface(p1, CSSBuilder) : false) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__554.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__555() {
  }
  _no_name_provided__555.prototype.invoke_1114 = function ($this$style) {
    backgroundColor_0($this$style, '#f4f4f4');
    var tmp = AppCSSVariables_getInstance()._get_wtColorGreyLight_();
    backgroundColor_1($this$style, value$default(tmp, null, 1, null));
  };
  _no_name_provided__555.prototype.invoke_1115 = function (p1) {
    this.invoke_1114((!(p1 == null) ? isInterface(p1, CSSBuilder) : false) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__555.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__556() {
  }
  _no_name_provided__556.prototype.invoke_1114 = function ($this$style) {
    backgroundColor_0($this$style, '#323236');
    var tmp = AppCSSVariables_getInstance()._get_wtColorGreyDark_();
    backgroundColor_1($this$style, value$default(tmp, null, 1, null));
  };
  _no_name_provided__556.prototype.invoke_1115 = function (p1) {
    this.invoke_1114((!(p1 == null) ? isInterface(p1, CSSBuilder) : false) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__556.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function WtSections() {
    WtSections_instance = this;
    StyleSheet_0.call(this, AppStylesheet_getInstance());
    var tmp = this;
    tmp._wtSection$delegate = this.style_45(_no_name_provided_$factory_522()).provideDelegate(this, wtSection$factory());
    var tmp_0 = this;
    tmp_0._wtSectionBgGrayLight$delegate = this.style_45(_no_name_provided_$factory_523()).provideDelegate(this, wtSectionBgGrayLight$factory());
    var tmp_1 = this;
    tmp_1._wtSectionBgGrayDark$delegate = this.style_45(_no_name_provided_$factory_524()).provideDelegate(this, wtSectionBgGrayDark$factory());
    this._$stable_56 = 0;
  }
  WtSections.prototype._get_wtSection_ = function () {
    return this._wtSection$delegate.getValue_4(this, wtSection$factory_0());
  };
  WtSections.prototype._get_wtSectionBgGrayLight_ = function () {
    return this._wtSectionBgGrayLight$delegate.getValue_4(this, wtSectionBgGrayLight$factory_0());
  };
  WtSections.prototype._get_wtSectionBgGrayDark_ = function () {
    return this._wtSectionBgGrayDark$delegate.getValue_4(this, wtSectionBgGrayDark$factory_0());
  };
  WtSections.prototype.style_43 = function (selector, cssRule) {
    return this.style_42(selector, cssRule);
  };
  WtSections.$metadata$ = {
    simpleName: 'WtSections',
    kind: 'object',
    interfaces: []
  };
  var WtSections_instance;
  function WtSections_getInstance() {
    if (WtSections_instance == null)
      new WtSections();
    return WtSections_instance;
  }
  function wtSection$factory() {
    return getPropertyCallableRef('wtSection', 1, KProperty1, function (receiver) {
      return receiver._get_wtSection_();
    }, null);
  }
  function wtSectionBgGrayLight$factory() {
    return getPropertyCallableRef('wtSectionBgGrayLight', 1, KProperty1, function (receiver) {
      return receiver._get_wtSectionBgGrayLight_();
    }, null);
  }
  function wtSectionBgGrayDark$factory() {
    return getPropertyCallableRef('wtSectionBgGrayDark', 1, KProperty1, function (receiver) {
      return receiver._get_wtSectionBgGrayDark_();
    }, null);
  }
  function wtSection$factory_0() {
    return getPropertyCallableRef('wtSection', 1, KProperty1, function (receiver) {
      return receiver._get_wtSection_();
    }, null);
  }
  function wtSectionBgGrayLight$factory_0() {
    return getPropertyCallableRef('wtSectionBgGrayLight', 1, KProperty1, function (receiver) {
      return receiver._get_wtSectionBgGrayLight_();
    }, null);
  }
  function wtSectionBgGrayDark$factory_0() {
    return getPropertyCallableRef('wtSectionBgGrayDark', 1, KProperty1, function (receiver) {
      return receiver._get_wtSectionBgGrayDark_();
    }, null);
  }
  function _no_name_provided_$factory_522() {
    var i = new _no_name_provided__554();
    return function (p1) {
      i.invoke_1114(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_523() {
    var i = new _no_name_provided__555();
    return function (p1) {
      i.invoke_1114(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_524() {
    var i = new _no_name_provided__556();
    return function (p1) {
      i.invoke_1114(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided__557() {
  }
  _no_name_provided__557.prototype.invoke_1114 = function ($this$null) {
    $this$null.invoke_403(AppCSSVariables_getInstance()._get_wtHeroFontSize_(), _get_px_(42));
    $this$null.invoke_403(AppCSSVariables_getInstance()._get_wtHeroLineHeight_(), _get_px_(48));
  };
  _no_name_provided__557.prototype.invoke_1115 = function (p1) {
    this.invoke_1114((!(p1 == null) ? isInterface(p1, CSSBuilder) : false) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__557.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__558($this_style) {
    this._$this_style_6 = $this_style;
  }
  _no_name_provided__558.prototype.invoke_1078 = function ($this$media) {
    var tmp = this._$this_style_6._get_self__1();
    $this$media.style_11(tmp, _no_name_provided_$factory_544());
  };
  _no_name_provided__558.prototype.invoke_1115 = function (p1) {
    this.invoke_1078((!(p1 == null) ? isInterface(p1, GenericStyleSheetBuilder) : false) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__558.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__559() {
  }
  _no_name_provided__559.prototype.invoke_1114 = function ($this$null) {
    $this$null.invoke_403(AppCSSVariables_getInstance()._get_wtSubtitle2FontSize_(), _get_px_(24));
    $this$null.invoke_403(AppCSSVariables_getInstance()._get_wtSubtitle2LineHeight_(), _get_px_(32));
  };
  _no_name_provided__559.prototype.invoke_1115 = function (p1) {
    this.invoke_1114((!(p1 == null) ? isInterface(p1, CSSBuilder) : false) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__559.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__560($this_style) {
    this._$this_style_7 = $this_style;
  }
  _no_name_provided__560.prototype.invoke_1078 = function ($this$media) {
    var tmp = this._$this_style_7._get_self__1();
    $this$media.style_11(tmp, _no_name_provided_$factory_546());
  };
  _no_name_provided__560.prototype.invoke_1115 = function (p1) {
    this.invoke_1078((!(p1 == null) ? isInterface(p1, GenericStyleSheetBuilder) : false) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__560.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__561() {
  }
  _no_name_provided__561.prototype.invoke_1114 = function ($this$null) {
    var tmp0_value_0 = '#167dff';
    $this$null.property_4('border-bottom-color', Companion_getInstance_35().invoke_402(tmp0_value_0));
  };
  _no_name_provided__561.prototype.invoke_1115 = function (p1) {
    this.invoke_1114((!(p1 == null) ? isInterface(p1, CSSBuilder) : false) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__561.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__562() {
  }
  _no_name_provided__562.prototype.invoke_1114 = function ($this$null) {
    $this$null.invoke_403(AppCSSVariables_getInstance()._get_wtH2FontSize_(), _get_px_(24));
    $this$null.invoke_403(AppCSSVariables_getInstance()._get_wtH2LineHeight_(), _get_px_(32));
  };
  _no_name_provided__562.prototype.invoke_1115 = function (p1) {
    this.invoke_1114((!(p1 == null) ? isInterface(p1, CSSBuilder) : false) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__562.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__563($this_style) {
    this._$this_style_8 = $this_style;
  }
  _no_name_provided__563.prototype.invoke_1078 = function ($this$media) {
    var tmp = this._$this_style_8._get_self__1();
    $this$media.style_11(tmp, _no_name_provided_$factory_549());
  };
  _no_name_provided__563.prototype.invoke_1115 = function (p1) {
    this.invoke_1078((!(p1 == null) ? isInterface(p1, GenericStyleSheetBuilder) : false) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__563.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__564() {
  }
  _no_name_provided__564.prototype.invoke_1114 = function ($this$null) {
    backgroundColor($this$null, new RGBA(22, 125, 255, 0.8));
  };
  _no_name_provided__564.prototype.invoke_1115 = function (p1) {
    this.invoke_1114((!(p1 == null) ? isInterface(p1, CSSBuilder) : false) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__564.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__565() {
  }
  _no_name_provided__565.prototype.invoke_1114 = function ($this$null) {
    backgroundColor($this$null, new RGBA(255, 255, 255, 0.1));
  };
  _no_name_provided__565.prototype.invoke_1115 = function (p1) {
    this.invoke_1114((!(p1 == null) ? isInterface(p1, CSSBuilder) : false) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__565.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__566() {
  }
  _no_name_provided__566.prototype.invoke_1114 = function ($this$null) {
    backgroundColor($this$null, new RGBA(39, 40, 44, 0.7));
  };
  _no_name_provided__566.prototype.invoke_1115 = function (p1) {
    this.invoke_1114((!(p1 == null) ? isInterface(p1, CSSBuilder) : false) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__566.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__567() {
  }
  _no_name_provided__567.prototype.invoke_1114 = function ($this$null) {
    var tmp0_value_0 = _get_px_(8);
    $this$null.property_4('margin-right', Companion_getInstance_35().invoke_396(tmp0_value_0));
    var tmp1_value_0 = _get_px_(8);
    $this$null.property_4('margin-left', Companion_getInstance_35().invoke_396(tmp1_value_0));
  };
  _no_name_provided__567.prototype.invoke_1115 = function (p1) {
    this.invoke_1114((!(p1 == null) ? isInterface(p1, CSSBuilder) : false) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__567.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__568() {
  }
  _no_name_provided__568.prototype.invoke_1114 = function ($this$null) {
    backgroundColor($this$null, new RGBA(255, 255, 255, 0.1));
    var tmp0_value_0 = '24px';
    $this$null.property_4('border-radius', Companion_getInstance_35().invoke_402(tmp0_value_0));
  };
  _no_name_provided__568.prototype.invoke_1115 = function (p1) {
    this.invoke_1114((!(p1 == null) ? isInterface(p1, CSSBuilder) : false) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__568.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__569($this_style) {
    this._$this_style_9 = $this_style;
  }
  _no_name_provided__569.prototype.invoke_1078 = function ($this$media) {
    var tmp = this._$this_style_9._get_self__1();
    $this$media.style_11(tmp, _no_name_provided_$factory_555());
  };
  _no_name_provided__569.prototype.invoke_1115 = function (p1) {
    this.invoke_1078((!(p1 == null) ? isInterface(p1, GenericStyleSheetBuilder) : false) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__569.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__570() {
  }
  _no_name_provided__570.prototype.invoke_1114 = function ($this$style) {
    color($this$style, '#27282c');
    fontSize($this$style, _get_px_(60));
    $this$style.property_4('font-size', value(AppCSSVariables_getInstance()._get_wtHeroFontSize_(), _get_px_(60)));
    var tmp0_value_0 = _get_px_(-1.5);
    $this$style.property_4('letter-spacing', Companion_getInstance_35().invoke_396(tmp0_value_0));
    var tmp1_value_0 = 900;
    $this$style.property_4('font-weight', Companion_getInstance_35().invoke_395(tmp1_value_0));
    var tmp2_value_0 = _get_px_(64);
    $this$style.property_4('line-height', Companion_getInstance_35().invoke_396(tmp2_value_0));
    $this$style.property_4('line-height', value(AppCSSVariables_getInstance()._get_wtHeroLineHeight_(), _get_px_(64)));
    var tmp = maxWidth($this$style, _get_px_(640));
    media($this$style, tmp, _no_name_provided_$factory_543($this$style));
    var tmp3_value_0 = 'Gotham SSm A,Gotham SSm B,system-ui,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Droid Sans,Helvetica Neue,Arial,sans-serif';
    $this$style.property_4('font-family', Companion_getInstance_35().invoke_402(tmp3_value_0));
  };
  _no_name_provided__570.prototype.invoke_1115 = function (p1) {
    this.invoke_1114((!(p1 == null) ? isInterface(p1, CSSBuilder) : false) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__570.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__571() {
  }
  _no_name_provided__571.prototype.invoke_1114 = function ($this$style) {
    color($this$style, '#27282c');
    fontSize($this$style, _get_px_(28));
    $this$style.property_4('font-size', value(AppCSSVariables_getInstance()._get_wtSubtitle2FontSize_(), _get_px_(28)));
    var tmp0_value_0 = 'normal';
    $this$style.property_4('letter-spacing', Companion_getInstance_35().invoke_402(tmp0_value_0));
    var tmp1_value_0 = 300;
    $this$style.property_4('font-weight', Companion_getInstance_35().invoke_395(tmp1_value_0));
    var tmp2_value_0 = _get_px_(40);
    $this$style.property_4('line-height', Companion_getInstance_35().invoke_396(tmp2_value_0));
    $this$style.property_4('line-height', value(AppCSSVariables_getInstance()._get_wtSubtitle2LineHeight_(), _get_px_(40)));
    var tmp = maxWidth($this$style, _get_px_(640));
    media($this$style, tmp, _no_name_provided_$factory_545($this$style));
    var tmp3_value_0 = 'Gotham SSm A,Gotham SSm B,system-ui,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Droid Sans,Helvetica Neue,Arial,sans-serif';
    $this$style.property_4('font-family', Companion_getInstance_35().invoke_402(tmp3_value_0));
  };
  _no_name_provided__571.prototype.invoke_1115 = function (p1) {
    this.invoke_1114((!(p1 == null) ? isInterface(p1, CSSBuilder) : false) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__571.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__572() {
  }
  _no_name_provided__572.prototype.invoke_1114 = function ($this$style) {
    color_0($this$style, new RGBA(39, 40, 44, 0.7));
    fontSize($this$style, _get_px_(18));
    var tmp0_value_0 = 'normal';
    $this$style.property_4('letter-spacing', Companion_getInstance_35().invoke_402(tmp0_value_0));
    var tmp1_value_0 = 400;
    $this$style.property_4('font-weight', Companion_getInstance_35().invoke_395(tmp1_value_0));
    var tmp2_value_0 = _get_px_(28);
    $this$style.property_4('line-height', Companion_getInstance_35().invoke_396(tmp2_value_0));
    var tmp3_value_0 = 'system-ui,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Droid Sans,Helvetica Neue,Arial,sans-serif';
    $this$style.property_4('font-family', Companion_getInstance_35().invoke_402(tmp3_value_0));
  };
  _no_name_provided__572.prototype.invoke_1115 = function (p1) {
    this.invoke_1114((!(p1 == null) ? isInterface(p1, CSSBuilder) : false) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__572.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__573() {
  }
  _no_name_provided__573.prototype.invoke_1114 = function ($this$style) {
    color_0($this$style, new RGBA(255, 255, 255, 0.6));
  };
  _no_name_provided__573.prototype.invoke_1115 = function (p1) {
    this.invoke_1114((!(p1 == null) ? isInterface(p1, CSSBuilder) : false) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__573.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__574() {
  }
  _no_name_provided__574.prototype.invoke_1114 = function ($this$style) {
    color_0($this$style, new RGBA(39, 40, 44, 0.7));
    fontSize($this$style, _get_px_(15));
    var tmp0_value_0 = 'normal';
    $this$style.property_4('letter-spacing', Companion_getInstance_35().invoke_402(tmp0_value_0));
    var tmp1_value_0 = 400;
    $this$style.property_4('font-weight', Companion_getInstance_35().invoke_395(tmp1_value_0));
    var tmp2_value_0 = _get_px_(24);
    $this$style.property_4('line-height', Companion_getInstance_35().invoke_396(tmp2_value_0));
    var tmp3_value_0 = 'system-ui,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Droid Sans,Helvetica Neue,Arial,sans-serif';
    $this$style.property_4('font-family', Companion_getInstance_35().invoke_402(tmp3_value_0));
  };
  _no_name_provided__574.prototype.invoke_1115 = function (p1) {
    this.invoke_1114((!(p1 == null) ? isInterface(p1, CSSBuilder) : false) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__574.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__575() {
  }
  _no_name_provided__575.prototype.invoke_1114 = function ($this$style) {
    color_0($this$style, new RGBA(39, 40, 44, 0.7));
    fontSize($this$style, _get_px_(12));
    var tmp0_value_0 = 'normal';
    $this$style.property_4('letter-spacing', Companion_getInstance_35().invoke_402(tmp0_value_0));
    var tmp1_value_0 = 400;
    $this$style.property_4('font-weight', Companion_getInstance_35().invoke_395(tmp1_value_0));
    var tmp2_value_0 = _get_px_(16);
    $this$style.property_4('line-height', Companion_getInstance_35().invoke_396(tmp2_value_0));
    var tmp3_value_0 = 'system-ui,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Droid Sans,Helvetica Neue,Arial,sans-serif';
    $this$style.property_4('font-family', Companion_getInstance_35().invoke_402(tmp3_value_0));
  };
  _no_name_provided__575.prototype.invoke_1115 = function (p1) {
    this.invoke_1114((!(p1 == null) ? isInterface(p1, CSSBuilder) : false) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__575.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__576() {
  }
  _no_name_provided__576.prototype.invoke_1114 = function ($this$style) {
    color_0($this$style, new RGBA(255, 255, 255, 0.3));
  };
  _no_name_provided__576.prototype.invoke_1115 = function (p1) {
    this.invoke_1114((!(p1 == null) ? isInterface(p1, CSSBuilder) : false) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__576.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__577() {
  }
  _no_name_provided__577.prototype.invoke_1114 = function ($this$style) {
    color_0($this$style, new RGBA(255, 255, 255, 0.6));
  };
  _no_name_provided__577.prototype.invoke_1115 = function (p1) {
    this.invoke_1114((!(p1 == null) ? isInterface(p1, CSSBuilder) : false) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__577.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__578() {
  }
  _no_name_provided__578.prototype.invoke_1114 = function ($this$style) {
    color_0($this$style, new RGBA(255, 255, 255, 0.6));
  };
  _no_name_provided__578.prototype.invoke_1115 = function (p1) {
    this.invoke_1114((!(p1 == null) ? isInterface(p1, CSSBuilder) : false) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__578.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__579() {
  }
  _no_name_provided__579.prototype.invoke_1114 = function ($this$style) {
    var tmp0_value_0 = '1px solid transparent';
    $this$style.property_4('border-bottom', Companion_getInstance_35().invoke_402(tmp0_value_0));
    var tmp1_value_0 = 'none';
    $this$style.property_4('text-decoration', Companion_getInstance_35().invoke_402(tmp1_value_0));
    color($this$style, '#167dff');
    var tmp = hover($this$style._get_self__1());
    $this$style.style_11(tmp, _no_name_provided_$factory_547());
  };
  _no_name_provided__579.prototype.invoke_1115 = function (p1) {
    this.invoke_1114((!(p1 == null) ? isInterface(p1, CSSBuilder) : false) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__579.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__580() {
  }
  _no_name_provided__580.prototype.invoke_1114 = function ($this$style) {
    color($this$style, '#27282c');
    fontSize($this$style, _get_px_(31));
    $this$style.property_4('font-size', value(AppCSSVariables_getInstance()._get_wtH2FontSize_(), _get_px_(31)));
    var tmp0_value_0 = _get_px_(-0.5);
    $this$style.property_4('letter-spacing', Companion_getInstance_35().invoke_396(tmp0_value_0));
    var tmp1_value_0 = 700;
    $this$style.property_4('font-weight', Companion_getInstance_35().invoke_395(tmp1_value_0));
    var tmp2_value_0 = _get_px_(40);
    $this$style.property_4('line-height', Companion_getInstance_35().invoke_396(tmp2_value_0));
    $this$style.property_4('line-height', value(AppCSSVariables_getInstance()._get_wtH2LineHeight_(), _get_px_(40)));
    var tmp = maxWidth($this$style, _get_px_(640));
    media($this$style, tmp, _no_name_provided_$factory_548($this$style));
    var tmp3_value_0 = 'Gotham SSm A,Gotham SSm B,system-ui,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Droid Sans,Helvetica Neue,Arial,sans-serif';
    $this$style.property_4('font-family', Companion_getInstance_35().invoke_402(tmp3_value_0));
  };
  _no_name_provided__580.prototype.invoke_1115 = function (p1) {
    this.invoke_1114((!(p1 == null) ? isInterface(p1, CSSBuilder) : false) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__580.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__581() {
  }
  _no_name_provided__581.prototype.invoke_1114 = function ($this$style) {
    color($this$style, '#fff');
  };
  _no_name_provided__581.prototype.invoke_1115 = function (p1) {
    this.invoke_1114((!(p1 == null) ? isInterface(p1, CSSBuilder) : false) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__581.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__582() {
  }
  _no_name_provided__582.prototype.invoke_1114 = function ($this$style) {
    color($this$style, '#27282c');
    fontSize($this$style, _get_px_(21));
    $this$style.property_4('font-size', value(AppCSSVariables_getInstance()._get_wtH3FontSize_(), _get_px_(20)));
    var tmp0_value_0 = 'normal';
    $this$style.property_4('letter-spacing', Companion_getInstance_35().invoke_402(tmp0_value_0));
    var tmp1_value_0 = 700;
    $this$style.property_4('font-weight', Companion_getInstance_35().invoke_395(tmp1_value_0));
    var tmp2_value_0 = _get_px_(28);
    $this$style.property_4('line-height', Companion_getInstance_35().invoke_396(tmp2_value_0));
    $this$style.property_4('line-height', value(AppCSSVariables_getInstance()._get_wtH3LineHeight_(), _get_px_(28)));
    var tmp3_value_0 = 'system-ui,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Droid Sans,Helvetica Neue,Arial,sans-serif';
    $this$style.property_4('font-family', Companion_getInstance_35().invoke_402(tmp3_value_0));
  };
  _no_name_provided__582.prototype.invoke_1115 = function (p1) {
    this.invoke_1114((!(p1 == null) ? isInterface(p1, CSSBuilder) : false) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__582.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__583() {
  }
  _no_name_provided__583.prototype.invoke_1114 = function ($this$style) {
    color($this$style, '#fff');
  };
  _no_name_provided__583.prototype.invoke_1115 = function (p1) {
    this.invoke_1114((!(p1 == null) ? isInterface(p1, CSSBuilder) : false) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__583.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__584() {
  }
  _no_name_provided__584.prototype.invoke_1114 = function ($this$style) {
    color($this$style, 'white');
    backgroundColor_0($this$style, '#167dff');
    fontSize($this$style, _get_px_(15));
    display($this$style, DisplayStyle_InlineBlock_getInstance());
    var tmp0_value_0 = 'none';
    $this$style.property_4('text-decoration', Companion_getInstance_35().invoke_402(tmp0_value_0));
    var tmp1_value_0 = '24px';
    $this$style.property_4('border-radius', Companion_getInstance_35().invoke_402(tmp1_value_0));
    var tmp2_value_0 = '12px 32px';
    $this$style.property_4('padding', Companion_getInstance_35().invoke_402(tmp2_value_0));
    var tmp3_value_0 = _get_px_(24);
    $this$style.property_4('line-height', Companion_getInstance_35().invoke_396(tmp3_value_0));
    var tmp4_value_0 = 400;
    $this$style.property_4('font-weight', Companion_getInstance_35().invoke_395(tmp4_value_0));
    var tmp5_value_0 = 'fit-content';
    $this$style.property_4('width', Companion_getInstance_35().invoke_402(tmp5_value_0));
    var tmp = hover($this$style._get_self__1());
    $this$style.style_11(tmp, _no_name_provided_$factory_550());
  };
  _no_name_provided__584.prototype.invoke_1115 = function (p1) {
    this.invoke_1114((!(p1 == null) ? isInterface(p1, CSSBuilder) : false) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__584.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__585() {
  }
  _no_name_provided__585.prototype.invoke_1114 = function ($this$style) {
    display($this$style, DisplayStyle_LegacyInlineFlex_getInstance());
    justifyContent($this$style, JustifyContent_Center_getInstance());
    alignItems($this$style, AlignItems_Center_getInstance());
    backgroundColor($this$style, Companion_getInstance_37().invoke_402('transparent'));
    var tmp = _get_px_(0);
    border$default($this$style, tmp, null, null, 6, null);
    var tmp0_value_0 = 'none';
    $this$style.property_4('outline', Companion_getInstance_35().invoke_402(tmp0_value_0));
    var tmp_0 = hover($this$style._get_self__1());
    $this$style.style_11(tmp_0, _no_name_provided_$factory_551());
  };
  _no_name_provided__585.prototype.invoke_1115 = function (p1) {
    this.invoke_1114((!(p1 == null) ? isInterface(p1, CSSBuilder) : false) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__585.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__586() {
  }
  _no_name_provided__586.prototype.invoke_1114 = function ($this$style) {
    color($this$style, 'white');
    backgroundColor_0($this$style, '#27282c');
    var tmp = hover($this$style._get_self__1());
    $this$style.style_11(tmp, _no_name_provided_$factory_552());
  };
  _no_name_provided__586.prototype.invoke_1115 = function (p1) {
    this.invoke_1114((!(p1 == null) ? isInterface(p1, CSSBuilder) : false) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__586.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__587() {
  }
  _no_name_provided__587.prototype.invoke_1114 = function ($this$style) {
    var tmp0_value_0 = _get_px_(16);
    $this$style.property_4('margin-right', Companion_getInstance_35().invoke_396(tmp0_value_0));
    marginLeft($this$style, _get_px_(16));
    padding($this$style, _get_px_(12));
    backgroundColor_0($this$style, 'transparent');
    display($this$style, DisplayStyle_LegacyInlineFlex_getInstance());
    var tmp = hover($this$style._get_self__1());
    $this$style.style_11(tmp, _no_name_provided_$factory_553());
    var tmp_0 = maxWidth($this$style, _get_px_(640));
    media($this$style, tmp_0, _no_name_provided_$factory_554($this$style));
  };
  _no_name_provided__587.prototype.invoke_1115 = function (p1) {
    this.invoke_1114((!(p1 == null) ? isInterface(p1, CSSBuilder) : false) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__587.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function WtTexts() {
    WtTexts_instance = this;
    StyleSheet_0.call(this, AppStylesheet_getInstance());
    var tmp = this;
    tmp._wtHero$delegate = this.style_45(_no_name_provided_$factory_525()).provideDelegate(this, wtHero$factory());
    var tmp_0 = this;
    tmp_0._wtSubtitle2$delegate = this.style_45(_no_name_provided_$factory_526()).provideDelegate(this, wtSubtitle2$factory());
    var tmp_1 = this;
    tmp_1._wtText1$delegate = this.style_45(_no_name_provided_$factory_527()).provideDelegate(this, wtText1$factory());
    var tmp_2 = this;
    tmp_2._wtText1ThemeDark$delegate = this.style_45(_no_name_provided_$factory_528()).provideDelegate(this, wtText1ThemeDark$factory());
    var tmp_3 = this;
    tmp_3._wtText2$delegate = this.style_45(_no_name_provided_$factory_529()).provideDelegate(this, wtText2$factory());
    var tmp_4 = this;
    tmp_4._wtText3$delegate = this.style_45(_no_name_provided_$factory_530()).provideDelegate(this, wtText3$factory());
    var tmp_5 = this;
    tmp_5._wtTextPale$delegate = this.style_45(_no_name_provided_$factory_531()).provideDelegate(this, wtTextPale$factory());
    var tmp_6 = this;
    tmp_6._wtText2ThemeDark$delegate = this.style_45(_no_name_provided_$factory_532()).provideDelegate(this, wtText2ThemeDark$factory());
    var tmp_7 = this;
    tmp_7._wtText3ThemeDark$delegate = this.style_45(_no_name_provided_$factory_533()).provideDelegate(this, wtText3ThemeDark$factory());
    var tmp_8 = this;
    tmp_8._wtLink$delegate = this.style_45(_no_name_provided_$factory_534()).provideDelegate(this, wtLink$factory());
    var tmp_9 = this;
    tmp_9._wtH2$delegate = this.style_45(_no_name_provided_$factory_535()).provideDelegate(this, wtH2$factory());
    var tmp_10 = this;
    tmp_10._wtH2ThemeDark$delegate = this.style_45(_no_name_provided_$factory_536()).provideDelegate(this, wtH2ThemeDark$factory());
    var tmp_11 = this;
    tmp_11._wtH3$delegate = this.style_45(_no_name_provided_$factory_537()).provideDelegate(this, wtH3$factory());
    var tmp_12 = this;
    tmp_12._wtH3ThemeDark$delegate = this.style_45(_no_name_provided_$factory_538()).provideDelegate(this, wtH3ThemeDark$factory());
    var tmp_13 = this;
    tmp_13._wtButton$delegate = this.style_45(_no_name_provided_$factory_539()).provideDelegate(this, wtButton$factory());
    var tmp_14 = this;
    tmp_14._wtLangButton$delegate = this.style_45(_no_name_provided_$factory_540()).provideDelegate(this, wtLangButton$factory());
    var tmp_15 = this;
    tmp_15._wtButtonContrast$delegate = this.style_45(_no_name_provided_$factory_541()).provideDelegate(this, wtButtonContrast$factory());
    var tmp_16 = this;
    tmp_16._wtSocialButtonItem$delegate = this.style_45(_no_name_provided_$factory_542()).provideDelegate(this, wtSocialButtonItem$factory());
    this._$stable_57 = 0;
  }
  WtTexts.prototype._get_wtHero_ = function () {
    return this._wtHero$delegate.getValue_4(this, wtHero$factory_0());
  };
  WtTexts.prototype._get_wtSubtitle2_ = function () {
    return this._wtSubtitle2$delegate.getValue_4(this, wtSubtitle2$factory_0());
  };
  WtTexts.prototype._get_wtText1_ = function () {
    return this._wtText1$delegate.getValue_4(this, wtText1$factory_0());
  };
  WtTexts.prototype._get_wtText1ThemeDark_ = function () {
    return this._wtText1ThemeDark$delegate.getValue_4(this, wtText1ThemeDark$factory_0());
  };
  WtTexts.prototype._get_wtText2_ = function () {
    return this._wtText2$delegate.getValue_4(this, wtText2$factory_0());
  };
  WtTexts.prototype._get_wtText3_ = function () {
    return this._wtText3$delegate.getValue_4(this, wtText3$factory_0());
  };
  WtTexts.prototype._get_wtTextPale_ = function () {
    return this._wtTextPale$delegate.getValue_4(this, wtTextPale$factory_0());
  };
  WtTexts.prototype._get_wtText2ThemeDark_ = function () {
    return this._wtText2ThemeDark$delegate.getValue_4(this, wtText2ThemeDark$factory_0());
  };
  WtTexts.prototype._get_wtText3ThemeDark_ = function () {
    return this._wtText3ThemeDark$delegate.getValue_4(this, wtText3ThemeDark$factory_0());
  };
  WtTexts.prototype._get_wtLink_ = function () {
    return this._wtLink$delegate.getValue_4(this, wtLink$factory_0());
  };
  WtTexts.prototype._get_wtH2_ = function () {
    return this._wtH2$delegate.getValue_4(this, wtH2$factory_0());
  };
  WtTexts.prototype._get_wtH2ThemeDark_ = function () {
    return this._wtH2ThemeDark$delegate.getValue_4(this, wtH2ThemeDark$factory_0());
  };
  WtTexts.prototype._get_wtH3_ = function () {
    return this._wtH3$delegate.getValue_4(this, wtH3$factory_0());
  };
  WtTexts.prototype._get_wtH3ThemeDark_ = function () {
    return this._wtH3ThemeDark$delegate.getValue_4(this, wtH3ThemeDark$factory_0());
  };
  WtTexts.prototype._get_wtButton_ = function () {
    return this._wtButton$delegate.getValue_4(this, wtButton$factory_0());
  };
  WtTexts.prototype._get_wtLangButton_ = function () {
    return this._wtLangButton$delegate.getValue_4(this, wtLangButton$factory_0());
  };
  WtTexts.prototype._get_wtButtonContrast_ = function () {
    return this._wtButtonContrast$delegate.getValue_4(this, wtButtonContrast$factory_0());
  };
  WtTexts.prototype._get_wtSocialButtonItem_ = function () {
    return this._wtSocialButtonItem$delegate.getValue_4(this, wtSocialButtonItem$factory_0());
  };
  WtTexts.prototype.style_43 = function (selector, cssRule) {
    return this.style_42(selector, cssRule);
  };
  WtTexts.$metadata$ = {
    simpleName: 'WtTexts',
    kind: 'object',
    interfaces: []
  };
  var WtTexts_instance;
  function WtTexts_getInstance() {
    if (WtTexts_instance == null)
      new WtTexts();
    return WtTexts_instance;
  }
  function wtHero$factory() {
    return getPropertyCallableRef('wtHero', 1, KProperty1, function (receiver) {
      return receiver._get_wtHero_();
    }, null);
  }
  function wtSubtitle2$factory() {
    return getPropertyCallableRef('wtSubtitle2', 1, KProperty1, function (receiver) {
      return receiver._get_wtSubtitle2_();
    }, null);
  }
  function wtText1$factory() {
    return getPropertyCallableRef('wtText1', 1, KProperty1, function (receiver) {
      return receiver._get_wtText1_();
    }, null);
  }
  function wtText1ThemeDark$factory() {
    return getPropertyCallableRef('wtText1ThemeDark', 1, KProperty1, function (receiver) {
      return receiver._get_wtText1ThemeDark_();
    }, null);
  }
  function wtText2$factory() {
    return getPropertyCallableRef('wtText2', 1, KProperty1, function (receiver) {
      return receiver._get_wtText2_();
    }, null);
  }
  function wtText3$factory() {
    return getPropertyCallableRef('wtText3', 1, KProperty1, function (receiver) {
      return receiver._get_wtText3_();
    }, null);
  }
  function wtTextPale$factory() {
    return getPropertyCallableRef('wtTextPale', 1, KProperty1, function (receiver) {
      return receiver._get_wtTextPale_();
    }, null);
  }
  function wtText2ThemeDark$factory() {
    return getPropertyCallableRef('wtText2ThemeDark', 1, KProperty1, function (receiver) {
      return receiver._get_wtText2ThemeDark_();
    }, null);
  }
  function wtText3ThemeDark$factory() {
    return getPropertyCallableRef('wtText3ThemeDark', 1, KProperty1, function (receiver) {
      return receiver._get_wtText3ThemeDark_();
    }, null);
  }
  function wtLink$factory() {
    return getPropertyCallableRef('wtLink', 1, KProperty1, function (receiver) {
      return receiver._get_wtLink_();
    }, null);
  }
  function wtH2$factory() {
    return getPropertyCallableRef('wtH2', 1, KProperty1, function (receiver) {
      return receiver._get_wtH2_();
    }, null);
  }
  function wtH2ThemeDark$factory() {
    return getPropertyCallableRef('wtH2ThemeDark', 1, KProperty1, function (receiver) {
      return receiver._get_wtH2ThemeDark_();
    }, null);
  }
  function wtH3$factory() {
    return getPropertyCallableRef('wtH3', 1, KProperty1, function (receiver) {
      return receiver._get_wtH3_();
    }, null);
  }
  function wtH3ThemeDark$factory() {
    return getPropertyCallableRef('wtH3ThemeDark', 1, KProperty1, function (receiver) {
      return receiver._get_wtH3ThemeDark_();
    }, null);
  }
  function wtButton$factory() {
    return getPropertyCallableRef('wtButton', 1, KProperty1, function (receiver) {
      return receiver._get_wtButton_();
    }, null);
  }
  function wtLangButton$factory() {
    return getPropertyCallableRef('wtLangButton', 1, KProperty1, function (receiver) {
      return receiver._get_wtLangButton_();
    }, null);
  }
  function wtButtonContrast$factory() {
    return getPropertyCallableRef('wtButtonContrast', 1, KProperty1, function (receiver) {
      return receiver._get_wtButtonContrast_();
    }, null);
  }
  function wtSocialButtonItem$factory() {
    return getPropertyCallableRef('wtSocialButtonItem', 1, KProperty1, function (receiver) {
      return receiver._get_wtSocialButtonItem_();
    }, null);
  }
  function wtHero$factory_0() {
    return getPropertyCallableRef('wtHero', 1, KProperty1, function (receiver) {
      return receiver._get_wtHero_();
    }, null);
  }
  function wtSubtitle2$factory_0() {
    return getPropertyCallableRef('wtSubtitle2', 1, KProperty1, function (receiver) {
      return receiver._get_wtSubtitle2_();
    }, null);
  }
  function wtText1$factory_0() {
    return getPropertyCallableRef('wtText1', 1, KProperty1, function (receiver) {
      return receiver._get_wtText1_();
    }, null);
  }
  function wtText1ThemeDark$factory_0() {
    return getPropertyCallableRef('wtText1ThemeDark', 1, KProperty1, function (receiver) {
      return receiver._get_wtText1ThemeDark_();
    }, null);
  }
  function wtText2$factory_0() {
    return getPropertyCallableRef('wtText2', 1, KProperty1, function (receiver) {
      return receiver._get_wtText2_();
    }, null);
  }
  function wtText3$factory_0() {
    return getPropertyCallableRef('wtText3', 1, KProperty1, function (receiver) {
      return receiver._get_wtText3_();
    }, null);
  }
  function wtTextPale$factory_0() {
    return getPropertyCallableRef('wtTextPale', 1, KProperty1, function (receiver) {
      return receiver._get_wtTextPale_();
    }, null);
  }
  function wtText2ThemeDark$factory_0() {
    return getPropertyCallableRef('wtText2ThemeDark', 1, KProperty1, function (receiver) {
      return receiver._get_wtText2ThemeDark_();
    }, null);
  }
  function wtText3ThemeDark$factory_0() {
    return getPropertyCallableRef('wtText3ThemeDark', 1, KProperty1, function (receiver) {
      return receiver._get_wtText3ThemeDark_();
    }, null);
  }
  function wtLink$factory_0() {
    return getPropertyCallableRef('wtLink', 1, KProperty1, function (receiver) {
      return receiver._get_wtLink_();
    }, null);
  }
  function wtH2$factory_0() {
    return getPropertyCallableRef('wtH2', 1, KProperty1, function (receiver) {
      return receiver._get_wtH2_();
    }, null);
  }
  function wtH2ThemeDark$factory_0() {
    return getPropertyCallableRef('wtH2ThemeDark', 1, KProperty1, function (receiver) {
      return receiver._get_wtH2ThemeDark_();
    }, null);
  }
  function wtH3$factory_0() {
    return getPropertyCallableRef('wtH3', 1, KProperty1, function (receiver) {
      return receiver._get_wtH3_();
    }, null);
  }
  function wtH3ThemeDark$factory_0() {
    return getPropertyCallableRef('wtH3ThemeDark', 1, KProperty1, function (receiver) {
      return receiver._get_wtH3ThemeDark_();
    }, null);
  }
  function wtButton$factory_0() {
    return getPropertyCallableRef('wtButton', 1, KProperty1, function (receiver) {
      return receiver._get_wtButton_();
    }, null);
  }
  function wtLangButton$factory_0() {
    return getPropertyCallableRef('wtLangButton', 1, KProperty1, function (receiver) {
      return receiver._get_wtLangButton_();
    }, null);
  }
  function wtButtonContrast$factory_0() {
    return getPropertyCallableRef('wtButtonContrast', 1, KProperty1, function (receiver) {
      return receiver._get_wtButtonContrast_();
    }, null);
  }
  function wtSocialButtonItem$factory_0() {
    return getPropertyCallableRef('wtSocialButtonItem', 1, KProperty1, function (receiver) {
      return receiver._get_wtSocialButtonItem_();
    }, null);
  }
  function _no_name_provided_$factory_525() {
    var i = new _no_name_provided__570();
    return function (p1) {
      i.invoke_1114(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_526() {
    var i = new _no_name_provided__571();
    return function (p1) {
      i.invoke_1114(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_527() {
    var i = new _no_name_provided__572();
    return function (p1) {
      i.invoke_1114(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_528() {
    var i = new _no_name_provided__573();
    return function (p1) {
      i.invoke_1114(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_529() {
    var i = new _no_name_provided__574();
    return function (p1) {
      i.invoke_1114(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_530() {
    var i = new _no_name_provided__575();
    return function (p1) {
      i.invoke_1114(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_531() {
    var i = new _no_name_provided__576();
    return function (p1) {
      i.invoke_1114(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_532() {
    var i = new _no_name_provided__577();
    return function (p1) {
      i.invoke_1114(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_533() {
    var i = new _no_name_provided__578();
    return function (p1) {
      i.invoke_1114(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_534() {
    var i = new _no_name_provided__579();
    return function (p1) {
      i.invoke_1114(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_535() {
    var i = new _no_name_provided__580();
    return function (p1) {
      i.invoke_1114(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_536() {
    var i = new _no_name_provided__581();
    return function (p1) {
      i.invoke_1114(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_537() {
    var i = new _no_name_provided__582();
    return function (p1) {
      i.invoke_1114(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_538() {
    var i = new _no_name_provided__583();
    return function (p1) {
      i.invoke_1114(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_539() {
    var i = new _no_name_provided__584();
    return function (p1) {
      i.invoke_1114(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_540() {
    var i = new _no_name_provided__585();
    return function (p1) {
      i.invoke_1114(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_541() {
    var i = new _no_name_provided__586();
    return function (p1) {
      i.invoke_1114(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_542() {
    var i = new _no_name_provided__587();
    return function (p1) {
      i.invoke_1114(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_543($this_style) {
    var i = new _no_name_provided__558($this_style);
    return function (p1) {
      i.invoke_1078(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_544() {
    var i = new _no_name_provided__557();
    return function (p1) {
      i.invoke_1114(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_545($this_style) {
    var i = new _no_name_provided__560($this_style);
    return function (p1) {
      i.invoke_1078(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_546() {
    var i = new _no_name_provided__559();
    return function (p1) {
      i.invoke_1114(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_547() {
    var i = new _no_name_provided__561();
    return function (p1) {
      i.invoke_1114(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_548($this_style) {
    var i = new _no_name_provided__563($this_style);
    return function (p1) {
      i.invoke_1078(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_549() {
    var i = new _no_name_provided__562();
    return function (p1) {
      i.invoke_1114(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_550() {
    var i = new _no_name_provided__564();
    return function (p1) {
      i.invoke_1114(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_551() {
    var i = new _no_name_provided__565();
    return function (p1) {
      i.invoke_1114(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_552() {
    var i = new _no_name_provided__566();
    return function (p1) {
      i.invoke_1114(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_553() {
    var i = new _no_name_provided__568();
    return function (p1) {
      i.invoke_1114(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_554($this_style) {
    var i = new _no_name_provided__569($this_style);
    return function (p1) {
      i.invoke_1078(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_555() {
    var i = new _no_name_provided__567();
    return function (p1) {
      i.invoke_1114(p1);
      return Unit_getInstance();
    };
  }
  AbstractMap.prototype._get_entries__5 = Map_0.prototype._get_entries__5;
  CombinedContext.prototype.plus_43 = CoroutineContext.prototype.plus_43;
  AbstractCoroutineContextElement.prototype.get_62 = Element_0.prototype.get_62;
  AbstractCoroutineContextElement.prototype.fold_27 = Element_0.prototype.fold_27;
  AbstractCoroutineContextElement.prototype.minusKey_28 = Element_0.prototype.minusKey_28;
  AbstractCoroutineContextElement.prototype.plus_43 = CoroutineContext.prototype.plus_43;
  UByteIterator.prototype.hasNext_41 = Iterator_2.prototype.hasNext_41;
  UIntIterator.prototype.hasNext_41 = Iterator_2.prototype.hasNext_41;
  ULongIterator.prototype.hasNext_41 = Iterator_2.prototype.hasNext_41;
  IntIterator.prototype.hasNext_41 = Iterator_2.prototype.hasNext_41;
  AbstractMutableList.prototype.get_72 = List.prototype.get_72;
  AbstractMutableMap.prototype._get_entries__5 = MutableMap.prototype._get_entries__5;
  InternalHashCodeMap.prototype.createJsMap_0 = InternalMap.prototype.createJsMap_0;
  KClassImpl.prototype._get_simpleName__4 = KClass.prototype._get_simpleName__4;
  KClassImpl.prototype.isInstance_4 = KClass.prototype.isInstance_4;
  Companion_12.prototype.tryFromFloat$default_2 = Creator.prototype.tryFromFloat$default_2;
  Companion_12.prototype.tryFromDouble$default_2 = Creator.prototype.tryFromDouble$default_2;
  BigDecimal.prototype.plus_70 = CommonBigNumberOperations.prototype.plus_70;
  BigDecimal.prototype.minus_39 = CommonBigNumberOperations.prototype.minus_39;
  Companion_14.prototype.tryFromFloat$default_2 = Creator.prototype.tryFromFloat$default_2;
  Companion_14.prototype.tryFromDouble$default_2 = Creator.prototype.tryFromDouble$default_2;
  BigInteger.prototype._get_isNegative__0 = BigNumber.prototype._get_isNegative__0;
  BigInteger.prototype.plus_4 = CommonBigNumberOperations.prototype.plus_4;
  BigInteger.prototype.plus_70 = CommonBigNumberOperations.prototype.plus_70;
  BigInteger.prototype.minus_2 = CommonBigNumberOperations.prototype.minus_2;
  BigInteger.prototype.minus_39 = CommonBigNumberOperations.prototype.minus_39;
  BigInteger.prototype.times_0 = CommonBigNumberOperations.prototype.times_0;
  BigInteger.prototype.times_39 = CommonBigNumberOperations.prototype.times_39;
  BigInteger.prototype.div_1 = CommonBigNumberOperations.prototype.div_1;
  BigInteger.prototype.div_33 = CommonBigNumberOperations.prototype.div_33;
  BigInteger.prototype.rem_1 = CommonBigNumberOperations.prototype.rem_1;
  BigInteger.prototype.rem_32 = CommonBigNumberOperations.prototype.rem_32;
  _no_name_provided__61.prototype.tryFromFloat$default_2 = Creator.prototype.tryFromFloat$default_2;
  _no_name_provided__61.prototype.tryFromDouble$default_2 = Creator.prototype.tryFromDouble$default_2;
  ModularBigInteger.prototype.plus_4 = CommonBigNumberOperations.prototype.plus_4;
  ModularBigInteger.prototype.minus_2 = CommonBigNumberOperations.prototype.minus_2;
  ModularBigInteger.prototype.times_0 = CommonBigNumberOperations.prototype.times_0;
  JobSupport.prototype.invokeOnCompletion$default_10 = Job.prototype.invokeOnCompletion$default_10;
  JobSupport.prototype.cancel$default_13 = Job.prototype.cancel$default_13;
  JobSupport.prototype.plus_43 = CoroutineContext.prototype.plus_43;
  JobSupport.prototype.get_62 = Element_0.prototype.get_62;
  JobSupport.prototype.fold_27 = Element_0.prototype.fold_27;
  JobSupport.prototype.minusKey_28 = Element_0.prototype.minusKey_28;
  AbstractCoroutine.prototype.invokeOnCompletion$default_10 = Job.prototype.invokeOnCompletion$default_10;
  AbstractCoroutine.prototype.cancel$default_13 = Job.prototype.cancel$default_13;
  AbstractCoroutine.prototype.plus_43 = CoroutineContext.prototype.plus_43;
  AbstractCoroutine.prototype.get_62 = Element_0.prototype.get_62;
  AbstractCoroutine.prototype.fold_27 = Element_0.prototype.fold_27;
  AbstractCoroutine.prototype.minusKey_28 = Element_0.prototype.minusKey_28;
  StandaloneCoroutine.prototype.invokeOnCompletion$default_10 = Job.prototype.invokeOnCompletion$default_10;
  StandaloneCoroutine.prototype.cancel$default_13 = Job.prototype.cancel$default_13;
  StandaloneCoroutine.prototype.plus_43 = CoroutineContext.prototype.plus_43;
  StandaloneCoroutine.prototype.get_62 = Element_0.prototype.get_62;
  StandaloneCoroutine.prototype.fold_27 = Element_0.prototype.fold_27;
  StandaloneCoroutine.prototype.minusKey_28 = Element_0.prototype.minusKey_28;
  LazyStandaloneCoroutine.prototype.invokeOnCompletion$default_10 = Job.prototype.invokeOnCompletion$default_10;
  LazyStandaloneCoroutine.prototype.cancel$default_13 = Job.prototype.cancel$default_13;
  LazyStandaloneCoroutine.prototype.plus_43 = CoroutineContext.prototype.plus_43;
  LazyStandaloneCoroutine.prototype.get_62 = Element_0.prototype.get_62;
  LazyStandaloneCoroutine.prototype.fold_27 = Element_0.prototype.fold_27;
  LazyStandaloneCoroutine.prototype.minusKey_28 = Element_0.prototype.minusKey_28;
  ScopeCoroutine.prototype.invokeOnCompletion$default_10 = Job.prototype.invokeOnCompletion$default_10;
  ScopeCoroutine.prototype.cancel$default_13 = Job.prototype.cancel$default_13;
  ScopeCoroutine.prototype.plus_43 = CoroutineContext.prototype.plus_43;
  ScopeCoroutine.prototype.get_62 = Element_0.prototype.get_62;
  ScopeCoroutine.prototype.fold_27 = Element_0.prototype.fold_27;
  ScopeCoroutine.prototype.minusKey_28 = Element_0.prototype.minusKey_28;
  DispatchedCoroutine.prototype.invokeOnCompletion$default_10 = Job.prototype.invokeOnCompletion$default_10;
  DispatchedCoroutine.prototype.cancel$default_13 = Job.prototype.cancel$default_13;
  DispatchedCoroutine.prototype.plus_43 = CoroutineContext.prototype.plus_43;
  DispatchedCoroutine.prototype.get_62 = Element_0.prototype.get_62;
  DispatchedCoroutine.prototype.fold_27 = Element_0.prototype.fold_27;
  DispatchedCoroutine.prototype.minusKey_28 = Element_0.prototype.minusKey_28;
  DeferredCoroutine.prototype.invokeOnCompletion$default_10 = Job.prototype.invokeOnCompletion$default_10;
  DeferredCoroutine.prototype.cancel$default_13 = Job.prototype.cancel$default_13;
  DeferredCoroutine.prototype.plus_43 = CoroutineContext.prototype.plus_43;
  DeferredCoroutine.prototype.get_62 = Element_0.prototype.get_62;
  DeferredCoroutine.prototype.fold_27 = Element_0.prototype.fold_27;
  DeferredCoroutine.prototype.minusKey_28 = Element_0.prototype.minusKey_28;
  LazyDeferredCoroutine.prototype.invokeOnCompletion$default_10 = Job.prototype.invokeOnCompletion$default_10;
  LazyDeferredCoroutine.prototype.cancel$default_13 = Job.prototype.cancel$default_13;
  LazyDeferredCoroutine.prototype.plus_43 = CoroutineContext.prototype.plus_43;
  LazyDeferredCoroutine.prototype.get_62 = Element_0.prototype.get_62;
  LazyDeferredCoroutine.prototype.fold_27 = Element_0.prototype.fold_27;
  LazyDeferredCoroutine.prototype.minusKey_28 = Element_0.prototype.minusKey_28;
  SchedulerTask.prototype.run_4 = Runnable.prototype.run_4;
  CancellableContinuationImpl.prototype.cancel$default_8 = CancellableContinuation.prototype.cancel$default_8;
  CoroutineDispatcher.prototype.get_62 = ContinuationInterceptor.prototype.get_62;
  CoroutineDispatcher.prototype.fold_27 = Element_0.prototype.fold_27;
  CoroutineDispatcher.prototype.minusKey_28 = ContinuationInterceptor.prototype.minusKey_28;
  CoroutineDispatcher.prototype.plus_43 = CoroutineContext.prototype.plus_43;
  EventLoop.prototype.plus_43 = CoroutineContext.prototype.plus_43;
  EventLoop.prototype.get_62 = ContinuationInterceptor.prototype.get_62;
  EventLoop.prototype.fold_27 = Element_0.prototype.fold_27;
  EventLoop.prototype.minusKey_28 = ContinuationInterceptor.prototype.minusKey_28;
  AwaitContinuation.prototype.cancel$default_8 = CancellableContinuation.prototype.cancel$default_8;
  JobImpl.prototype.invokeOnCompletion$default_10 = Job.prototype.invokeOnCompletion$default_10;
  JobImpl.prototype.cancel$default_13 = Job.prototype.cancel$default_13;
  JobImpl.prototype.plus_43 = CoroutineContext.prototype.plus_43;
  JobImpl.prototype.get_62 = Element_0.prototype.get_62;
  JobImpl.prototype.fold_27 = Element_0.prototype.fold_27;
  JobImpl.prototype.minusKey_28 = Element_0.prototype.minusKey_28;
  MainCoroutineDispatcher.prototype.plus_43 = CoroutineContext.prototype.plus_43;
  MainCoroutineDispatcher.prototype.get_62 = ContinuationInterceptor.prototype.get_62;
  MainCoroutineDispatcher.prototype.fold_27 = Element_0.prototype.fold_27;
  MainCoroutineDispatcher.prototype.minusKey_28 = ContinuationInterceptor.prototype.minusKey_28;
  SupervisorJobImpl.prototype.invokeOnCompletion$default_10 = Job.prototype.invokeOnCompletion$default_10;
  SupervisorJobImpl.prototype.cancel$default_13 = Job.prototype.cancel$default_13;
  SupervisorJobImpl.prototype.plus_43 = CoroutineContext.prototype.plus_43;
  SupervisorJobImpl.prototype.get_62 = Element_0.prototype.get_62;
  SupervisorJobImpl.prototype.fold_27 = Element_0.prototype.fold_27;
  SupervisorJobImpl.prototype.minusKey_28 = Element_0.prototype.minusKey_28;
  Unconfined.prototype.plus_43 = CoroutineContext.prototype.plus_43;
  Unconfined.prototype.get_62 = ContinuationInterceptor.prototype.get_62;
  Unconfined.prototype.fold_27 = Element_0.prototype.fold_27;
  Unconfined.prototype.minusKey_28 = ContinuationInterceptor.prototype.minusKey_28;
  UndispatchedCoroutine.prototype.invokeOnCompletion$default_10 = Job.prototype.invokeOnCompletion$default_10;
  UndispatchedCoroutine.prototype.cancel$default_13 = Job.prototype.cancel$default_13;
  UndispatchedCoroutine.prototype.plus_43 = CoroutineContext.prototype.plus_43;
  UndispatchedCoroutine.prototype.get_62 = Element_0.prototype.get_62;
  UndispatchedCoroutine.prototype.fold_27 = Element_0.prototype.fold_27;
  UndispatchedCoroutine.prototype.minusKey_28 = Element_0.prototype.minusKey_28;
  JsMainDispatcher.prototype.plus_43 = CoroutineContext.prototype.plus_43;
  JsMainDispatcher.prototype.get_62 = ContinuationInterceptor.prototype.get_62;
  JsMainDispatcher.prototype.fold_27 = Element_0.prototype.fold_27;
  JsMainDispatcher.prototype.minusKey_28 = ContinuationInterceptor.prototype.minusKey_28;
  UnconfinedEventLoop.prototype.plus_43 = CoroutineContext.prototype.plus_43;
  UnconfinedEventLoop.prototype.get_62 = ContinuationInterceptor.prototype.get_62;
  UnconfinedEventLoop.prototype.fold_27 = Element_0.prototype.fold_27;
  UnconfinedEventLoop.prototype.minusKey_28 = ContinuationInterceptor.prototype.minusKey_28;
  SetTimeoutBasedDispatcher.prototype.plus_43 = CoroutineContext.prototype.plus_43;
  SetTimeoutBasedDispatcher.prototype.get_62 = ContinuationInterceptor.prototype.get_62;
  SetTimeoutBasedDispatcher.prototype.fold_27 = Element_0.prototype.fold_27;
  SetTimeoutBasedDispatcher.prototype.minusKey_28 = ContinuationInterceptor.prototype.minusKey_28;
  NodeDispatcher.prototype.plus_43 = CoroutineContext.prototype.plus_43;
  NodeDispatcher.prototype.get_62 = ContinuationInterceptor.prototype.get_62;
  NodeDispatcher.prototype.fold_27 = Element_0.prototype.fold_27;
  NodeDispatcher.prototype.minusKey_28 = ContinuationInterceptor.prototype.minusKey_28;
  SetTimeoutDispatcher.prototype.plus_43 = CoroutineContext.prototype.plus_43;
  SetTimeoutDispatcher.prototype.get_62 = ContinuationInterceptor.prototype.get_62;
  SetTimeoutDispatcher.prototype.fold_27 = Element_0.prototype.fold_27;
  SetTimeoutDispatcher.prototype.minusKey_28 = ContinuationInterceptor.prototype.minusKey_28;
  WindowDispatcher.prototype.plus_43 = CoroutineContext.prototype.plus_43;
  WindowDispatcher.prototype.get_62 = ContinuationInterceptor.prototype.get_62;
  WindowDispatcher.prototype.fold_27 = Element_0.prototype.fold_27;
  WindowDispatcher.prototype.minusKey_28 = ContinuationInterceptor.prototype.minusKey_28;
  FieldExt.prototype._get_extension__4 = Field.prototype._get_extension__4;
  FieldExt.prototype.invertImp_5 = Field.prototype.invertImp_5;
  FieldExt.prototype.modSqrtImp_5 = Field.prototype.modSqrtImp_5;
  FieldExt.prototype.companion_2 = Field.prototype.companion_2;
  _no_name_provided__114.prototype.asAtom_0 = Op.prototype.asAtom_0;
  AbstractApplier.prototype.onBeginChanges_1 = Applier.prototype.onBeginChanges_1;
  AbstractApplier.prototype.onEndChanges_1 = Applier.prototype.onEndChanges_1;
  AbstractApplier.prototype.insertTopDown_2 = Applier.prototype.insertTopDown_2;
  AbstractApplier.prototype.insertBottomUp_2 = Applier.prototype.insertBottomUp_2;
  AbstractApplier.prototype.remove_49 = Applier.prototype.remove_49;
  AbstractApplier.prototype.move_3 = Applier.prototype.move_3;
  BroadcastFrameClock.prototype._get_key__35 = MonotonicFrameClock.prototype._get_key__35;
  BroadcastFrameClock.prototype.get_62 = Element_0.prototype.get_62;
  BroadcastFrameClock.prototype.fold_27 = Element_0.prototype.fold_27;
  BroadcastFrameClock.prototype.minusKey_28 = Element_0.prototype.minusKey_28;
  BroadcastFrameClock.prototype.plus_43 = CoroutineContext.prototype.plus_43;
  StructuralEqualityPolicy.prototype.merge = SnapshotMutationPolicy.prototype.merge;
  TrieNodeBaseIterator.prototype.next_46 = Iterator_2.prototype.next_46;
  MonotonicClockImpl.prototype._get_key__35 = MonotonicFrameClock.prototype._get_key__35;
  MonotonicClockImpl.prototype.get_62 = Element_0.prototype.get_62;
  MonotonicClockImpl.prototype.fold_27 = Element_0.prototype.fold_27;
  MonotonicClockImpl.prototype.minusKey_28 = Element_0.prototype.minusKey_28;
  MonotonicClockImpl.prototype.plus_43 = CoroutineContext.prototype.plus_43;
  DomApplier.prototype.onBeginChanges_1 = Applier.prototype.onBeginChanges_1;
  DomApplier.prototype.onEndChanges_1 = Applier.prototype.onEndChanges_1;
  JsMicrotasksDispatcher.prototype.plus_43 = CoroutineContext.prototype.plus_43;
  JsMicrotasksDispatcher.prototype.get_62 = ContinuationInterceptor.prototype.get_62;
  JsMicrotasksDispatcher.prototype.fold_27 = Element_0.prototype.fold_27;
  JsMicrotasksDispatcher.prototype.minusKey_28 = ContinuationInterceptor.prototype.minusKey_28;
  CSSRuleBuilderImpl.prototype.invoke_403 = StyleBuilder.prototype.invoke_403;
  CSSBuilderImpl.prototype.invoke_403 = StyleBuilder.prototype.invoke_403;
  CSSBuilderImpl.prototype.style_11 = GenericStyleSheetBuilder.prototype.style_11;
  StyleSheet_0.prototype.style_42 = StyleSheetBuilder.prototype.style_42;
  StyleSheet_0.prototype.style_11 = GenericStyleSheetBuilder.prototype.style_11;
  CSSRulesHolderState.prototype.add_65 = CSSRulesHolder.prototype.add_65;
  StyleSheetBuilderImpl.prototype.style_42 = StyleSheetBuilder.prototype.style_42;
  StyleSheetBuilderImpl.prototype.style_11 = GenericStyleSheetBuilder.prototype.style_11;
  StyleSheetBuilderImpl.prototype.add_65 = CSSRulesHolder.prototype.add_65;
  AppStylesheet.prototype.style_42 = StyleSheetBuilder.prototype.style_42;
  AppStylesheet.prototype.style_11 = GenericStyleSheetBuilder.prototype.style_11;
  AppStylesheet.prototype.style_12 = GenericStyleSheetBuilder.prototype.style_12;
  WtCols.prototype.style_42 = StyleSheetBuilder.prototype.style_42;
  WtCols.prototype.style_11 = GenericStyleSheetBuilder.prototype.style_11;
  WtContainer.prototype.style_42 = StyleSheetBuilder.prototype.style_42;
  WtContainer.prototype.style_11 = GenericStyleSheetBuilder.prototype.style_11;
  WtOffsets.prototype.style_42 = StyleSheetBuilder.prototype.style_42;
  WtOffsets.prototype.style_11 = GenericStyleSheetBuilder.prototype.style_11;
  WtRows.prototype.style_42 = StyleSheetBuilder.prototype.style_42;
  WtRows.prototype.style_11 = GenericStyleSheetBuilder.prototype.style_11;
  WtSections.prototype.style_42 = StyleSheetBuilder.prototype.style_42;
  WtSections.prototype.style_11 = GenericStyleSheetBuilder.prototype.style_11;
  WtTexts.prototype.style_42 = StyleSheetBuilder.prototype.style_42;
  WtTexts.prototype.style_11 = GenericStyleSheetBuilder.prototype.style_11;
  _stableSortingIsSupported = null;
  output = output$init$();
  functionClasses = functionClasses$init$();
  REPLACEMENT_BYTE_SEQUENCE = REPLACEMENT_BYTE_SEQUENCE$init$();
  buf = new ArrayBuffer(8);
  bufFloat64 = bufFloat64$init$();
  bufInt32 = bufInt32$init$();
  lowIndex = lowIndex$init$();
  highIndex = 1 - lowIndex | 0;
  ZERO = fromInt(0);
  ONE = fromInt(1);
  NEG_ONE = fromInt(-1);
  MAX_VALUE = new Long(-1, 2147483647);
  MIN_VALUE = new Long(0, -2147483648);
  TWO_PWR_24_ = fromInt(16777216);
  propertyRefClassMetadataCache = propertyRefClassMetadataCache$init$();
  chosenArithmetic = BigInteger63Arithmetic_getInstance();
  COMPLETING_ALREADY = new Symbol('COMPLETING_ALREADY');
  COMPLETING_WAITING_CHILDREN = new Symbol('COMPLETING_WAITING_CHILDREN');
  COMPLETING_RETRY = new Symbol('COMPLETING_RETRY');
  TOO_LATE_TO_CANCEL = new Symbol('TOO_LATE_TO_CANCEL');
  SEALED = new Symbol('SEALED');
  EMPTY_NEW = new Empty(false);
  EMPTY_ACTIVE = new Empty(true);
  NONE = new Symbol('NONE');
  PENDING = new Symbol('PENDING');
  NULL = new Symbol('NULL');
  UNDEFINED = new Symbol('UNDEFINED');
  REUSABLE_CLAIMED = new Symbol('REUSABLE_CLAIMED');
  counter = 0;
  DEBUG = false;
  CURRY_OBJ_CODE_S = '(a (q #a 4 (c 2 (c 5 (c 7 0)))) (c (q (c (q . 2) (c (c (q . 1) 5) (c (a 6 (c 2 (c 11 (q 1)))) 0))) #a (i 5 (q 4 (q . 4) (c (c (q . 1) 9) (c (a 6 (c 2 (c 13 (c 11 0)))) 0))) (q . 11)) 1) 1))';
  CURRY_OBJ_CODE = assemble(CURRY_OBJ_CODE_S);
  UNCURRY_PATTERN_FUNCTION = assemble('(a (q . (: . function)) (: . core))');
  UNCURRY_PATTERN_CORE = assemble('(c (q . (: . parm)) (: . core))');
  ATOM_MATCH = ATOM_MATCH$init$();
  SEXP_MATCH = SEXP_MATCH$init$();
  testScope = MainScope();
  testCoroutineContext = testScope._get_coroutineContext__8();
  removeCurrentGroupInstance = removeCurrentGroupInstance$init$();
  endGroupInstance = endGroupInstance$init$();
  startRootGroup = startRootGroup$init$();
  invocation = new OpaqueKey('provider');
  provider = new OpaqueKey('provider');
  compositionLocalMap = new OpaqueKey('compositionLocalMap');
  providerValues = new OpaqueKey('providerValues');
  providerMaps = new OpaqueKey('providers');
  reference = new OpaqueKey('reference');
  PendingApplyNoModifications = new Object();
  EmptyCompositionLocalMap = persistentHashMapOf();
  InternalDisposableEffectScope = new DisposableEffectScope();
  ProduceAnotherFrame = new Object();
  FramePending = new Object();
  liveLiteralCache = HashMap_init_$Create$();
  isLiveLiteralsEnabled = false;
  emptyLambda = emptyLambda$init$();
  threadSnapshot = new SnapshotThreadLocal();
  lock = new Object();
  openSnapshots = Companion_getInstance_31()._EMPTY_3;
  nextSnapshotId = 1;
  applyObservers = applyObservers$init$();
  globalWriteObservers = globalWriteObservers$init$();
  currentGlobalSnapshot = currentGlobalSnapshot$init$();
  snapshotInitializer = currentGlobalSnapshot.get_60();
  LocalInspectionTables = staticCompositionLocalOf(_no_name_provided_$factory_183());
  nextHash = 1;
  DefaultMonotonicFrameClock = new MonotonicClockImpl();
  setInputValue = setInputValue$init$();
  setClassList = setClassList$init$();
  cssTypedOMPolyfill = CSSTypedOMPolyfill.default(window);
  auto = auto$init$();
  startCode = trimIndent('\n                (mod (password new_puzhash amount)\n                    (defconstant CREATE_COIN 51)\n                \n                    (if (= (sha256 password) (q . 0x2cf24dba5fb0a30e26e83b2ac5b9e29e1b161e5c1fa7425e73043362938b9824))\n                        (list (list CREATE_COIN new_puzhash amount))\n                        (x)\n                    )\n                )\n            ');
  var $kotlinx = _.kotlinx || (_.kotlinx = {});
  var $kotlinx$atomicfu = $kotlinx.atomicfu || ($kotlinx.atomicfu = {});
  $kotlinx$atomicfu.atomic$ref$ = atomic_2;
  $kotlinx$atomicfu.atomic$boolean$ = atomic_4;
  $kotlinx$atomicfu.atomic$int$ = atomic_6;
  $kotlinx$atomicfu.atomic$long$ = atomic_8;
  main();
  return _;
}));
